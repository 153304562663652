<template>
    <v-dialog v-model="approve_override_dialog" persistent max-width="25%" scrollable>
        <v-card>
            <v-row class="m-0">
                <v-col cols="11">
                    <v-card-title>
                        <span class="headline">Confirm Override {{ this.heading }}</span>
                    </v-card-title>
                </v-col>

                <v-col cols="1" class="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="$emit('closeConfirmOverride', override_user_id, false)">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card-text>
                <div class="row">
                    <div id="pass-container" class="col-sm-12 col-md-12">
                        <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                        <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override_user_password" autocomplete="new-password" autofocus>
                        <!-- <input :type="eye_open === false ? 'password' : 'text'" class="form-control" id="override_user_password" placeholder="Password" v-model="override_user_password" autocomplete="new-password" autofocus> -->
                        <span v-for="errors in errors.override_user_password" class="text-warning" :key="errors">{{errors}}</span>
                        <!-- <div class="eye-container">
                            <v-tooltip>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        fab
                                        small
                                        icon
                                        text
                                        @click="viewPassword"
                                    >
                                        <v-icon>{{ eye_open === true ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}}</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </div> -->
                    </div>
                </div>
                <div class="border-top">
                    <div class="card-body py-4 d-flex justify-content-center">
                        <v-btn @click="fromSOA || fromPL ? approveOverrideAccounting() : approveOverride()">Confirm</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
export default {
    data(){
        return {
            override_user_id: -1,
            override_user_password: '',
            override_authenticated: false,
            errors: [],
            // eye_open: false,
        }
    },
    props: [
        'approve_override_dialog',
        'departments_allowed',
        'heading',
        'fromSOA',
        'activeUserHeadId',
        'fromPL',
        'module_name'
    ],
    computed:{
        ...mapGetters([
            'GET_CONFIRM_OVERRIDE'
        ])
    },
    methods: {
        approveOverride(){
            if (!this.override_user_password) {
                this.override_user_id = -1
                this.approve_override_dialog = false
            } else {
                let payload = {
                    departments_allowed:        this.departments_allowed,
                    override_user_password:     this.override_user_password,
                    module_name:this.module_name
                }
                this.$store.dispatch('confirmOverride',payload).then(response => {
                    let data = this.GET_CONFIRM_OVERRIDE

                    this.override_user_password = ''

                    if (data.error) {
                        this.override_user_id = -1
                        swal.fire("", data.error, "warning")
                        return false
                    }

                    this.override_user_id = data.user_id
                    this.override_authenticated = true

                    this.$emit('closeConfirmOverride', this.override_user_id, this.override_authenticated)
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        approveOverrideAccounting(){
            if (!this.override_user_password) {
                this.override_user_id = -1
                this.approve_override_dialog = false
            } else {
                let payload = {
                    departments_allowed:        this.departments_allowed,
                    override_user_password:     this.override_user_password,
                }
                this.$store.dispatch('confirmOverrideSOA',payload).then(response => {
                    let data = this.GET_CONFIRM_OVERRIDE

                    this.override_user_password = ''

                    if (data.error) {
                        this.override_user_id = -1
                        swal.fire("", data.error, "warning")
                        return false
                    }

                    this.override_user_id = data.user_id
                    this.override_authenticated = true

                    this.$emit('closeConfirmOverride', this.override_user_id, this.override_authenticated)
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        // viewPassword() {
        //     this.eye_open = !this.eye_open;
        // }
    },
}
</script>

<style scoped>
    /* #pass-container {
        position: relative;
    }
    .eye-container {
        position: absolute;
        top: 46px;
        right: 16px;
    } */
</style>

