<template>
    <div>
        <v-row class="ma-2">
            <v-col class="d-flex no-block align-items-center">
                <h4 class="page-title" style="color: black">Bank</h4>
            </v-col>
        </v-row>
        <v-container class="container">
            <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" class="col-12 pb-0" show-arrows>
                <v-tab ripple>Bank</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless style="background-color:unset">
                <v-tab-item>
                    <banks-table-component />
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </div>
</template>

<script>
import BanksTableComponent from '../../Accounting/Views/BanksComponent/BanksTableComponent.vue';

export default {
    data() {
        return {
            tab: null
        }
    },
    components: {
        BanksTableComponent
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {

    }
}
</script>

<style>

</style>
