<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Create User</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field outlined dense label="Name" v-model="form.name" :rules="error.name" required :disabled="disable_view"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Email"
                type="email"
                v-model="form.email"
                :rules="error.email"
                required
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Username"
                v-model="form.username"
                :rules="error.username"
                required
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field outlined dense label="Mobile No." type="text" max="11" v-model="form.mobile" :disabled="disable_view"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Password"
                type="password"
                v-model="form.password"
                :rules="error.password"
                required
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Confirm Password"
                type="password"
                v-model="form.password_confirmation"
                :rules="error.password_confirmation"
                required
                :disabled="disable_view"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" sm="6">
              <v-autocomplete
                outlined
                dense
                label="Department"
                :items="GET_LIST_DEPARTMENT"
                :disabled="disable_view"
                v-model="form.department_id"
                :rules="error.department_id"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                label="Job Title"
                v-model="form.job_title"
                :rules="error.job_title"
                required
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3">
              <v-switch v-model="form.is_active" label="active" color="primary" input-value="1" :disabled="disable_view"></v-switch>
            </v-col>
            <v-col cols="12" lg="3">
              <v-switch v-model="form.is_locked" label="locked" color="primary" input-value="1" :disabled="disable_view"></v-switch>
            </v-col>
            <v-col cols="12" lg="3">
              <v-switch v-model="form.is_manager" label="manager" color="primary" input-value="1" :disabled="disable_view"></v-switch>
            </v-col>
            <v-col cols="12" lg="3">
              <v-switch v-model="form.is_super_admin" label="super admin" color="primary" input-value="1" :disabled="disable_view"></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch v-model="form.changepassword_on_next" label="User must change password at next logon" color="primary" input-value="1" :disabled="disable_view"></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field type="number" hide-spin-buttons outlined dense label="token expiration time" :disabled="disable_view" v-model="form.token_expiration_time" @keyup="convertTohrs()">
                <template #prepend-inner > 
                  <span class="pt-1">min : </span>
              </template>
                <template #append > 
                  <span class="pt-1">hrs: {{ convertTohrs() }}</span>
              </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View'">
            <v-btn class="float-center" @click="actions == 'Submit' ? Addnewuser() : EditUser()" :disabled="disable_view">
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
export default {
  data() {
    return {
      valid: false,
      dialog: false,
      disable_view:false,
      access: {
        department_code: 'MD',
        module: 'users',
        action: 'create'
      },
      form: {
          name: null,
          email: null,
          username: null,
          mobile: null,
          password: null,
          password_confirmation: null,
          role_id: null,
          department_id: null,
          job_title: null,
          is_active: 1,
          is_locked: 0,
          is_manager: 0,
          is_super_admin: 0,
          changepassword_on_next: 1,
          token_expiration_time:600
      },
      error: {
        name: [v => !!v || 'Name is required'],
        email: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
        username: [v => !!v || 'Username is required'],
        mobile: [v => !!v || 'Mobile is required'],
        password: [
          v => !!v || 'Password is required'
          // (v) => v.length >= 8 || "Password must be greater than 8",
        ],
        password_confirmation: [
          v => !!v || 'Password is required',
          // (v) => v.length >= 8 || "Password must be greater than 8",
          v => v === this.form.password || 'The password does not match.'
        ],
        department_id: [v => !!v || 'Department is required'],
        job_title: [v => !!v || 'Job Title is required'],
      },
    };
  },
   computed: {
    ...mapGetters(['GET_LIST_DEPARTMENT', 'SELECTED_USER', 'ACTION', 'NEW_USER', 'GET_ERROR_USER']),
    actions() {
      if (this.ACTION == 'Submit') {
        this.disable_view = false;
        return 'Submit'
      } else if(this.ACTION == 'Update'){
        this.disable_view = false;
       this.getAllUserEdit();
        return 'Update'
      } else if(this.ACTION == 'View'){
        this.disable_view = true;
       this.getAllUserEdit();
        return 'View'
      }
    },
  },
  methods: {
    getAllUserEdit(){
        (this.error = {
          name: [v => !!v || 'Name is required'],
          email: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
          username: [v => !!v || 'Username is required'],
          mobile: [v => !!v || 'Mobile is required'],
          department_id: [v => !!v || 'Department is required'],
          job_title: [v => !!v || 'Job Title is required'],
        }),(this.form = {
            id:this.SELECTED_USER.id,
            name: this.SELECTED_USER.name,
            email: this.SELECTED_USER.email,
            username: this.SELECTED_USER.username,
            mobile: this.SELECTED_USER.mobile_no,
            role_id: this.SELECTED_USER.role_id,
            password: this.SELECTED_USER.password,
            password_confirmation: this.SELECTED_USER.password,
            department_id: this.SELECTED_USER.department_id,
            job_title: this.SELECTED_USER.job_title,
            is_active: this.SELECTED_USER.active,
            is_locked: this.SELECTED_USER.locked,
            is_manager: this.SELECTED_USER.manager,
            is_super_admin: this.SELECTED_USER.super_admin,
            changepassword_on_next: this.SELECTED_USER.changepassword_on_next,
            token_expiration_time: this.SELECTED_USER.token_expiration_time,
          });
    },
    Addnewuser() {
      this.$refs.form.validate();
      const name_swal_text = this.form.name ? '' : 'Name';
      const email_swal_text = this.form.email ? '' : 'Email';
      const username_swal_text = this.form.username ? '' : 'Username';
      // const password_swal_text = this.form.password ? '' : 'Password';
      // const cpassword_swal_text = this.form.password_confirmation ? '' : 'Confirm Password';
      const dept_swal_text = this.form.department_id ? '' : 'Department';
      const jtitle_swal_text = this.form.job_title ? '' : 'Job Title';
      const password_confirm_swal_text =
        this.form.password != this.form.password_confirmation ? 'Password not match' : '';
      const email_swal_text_validate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)

      const array_text = [
        name_swal_text,
        email_swal_text,
        username_swal_text,
        // password_swal_text,
        // cpassword_swal_text,
        dept_swal_text,
        jtitle_swal_text,
      ];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if (
        this.form.name  &&
        this.form.email  &&
        this.form.username  &&
        // this.form.password  && this.form.password_confirmation  &&
        this.form.department_id  &&
        this.form.job_title
      ) {
        this.$store.dispatch('NewUser', this.form);
        this.resetFields();
      } else {
        swal.fire({
          text:
          password_confirm_swal_text ||
            (`Fill Required Fields (${filtered})`),
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    EditUser() {
      this.$refs.form.validate();
      const name_swal_text = this.form.name ? '' : 'Name';
      const email_swal_text = this.form.email ? '' : 'Email';
      const username_swal_text = this.form.username ? '' : 'Username';
      const dept_swal_text = this.form.department_id ? '' : 'Department';
      const jtitle_swal_text = this.form.job_title ? '' : 'Job Title';
      const email_swal_text_validate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      const array_text = [
        name_swal_text,
        email_swal_text,
        username_swal_text,
        dept_swal_text,
        jtitle_swal_text,
      ];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if(this.form.is_manager === true){
        this.form.is_manager = 1
      }else{
        this.form.is_manager = 0
      }
      if (this.form.name && this.form.email && this.form.username && this.form.department_id && this.form.job_title) {
        this.$store.dispatch('EditUser',this.form);
      } else {
        swal.fire({
          text: !email_swal_text_validate ? 'Invalid Email' : `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    resetFields(){
        this.form = {
          name: null,
          email: null,
          username: null,
          mobile: null,
          password: null,
          password_confirmation: null,
          role_id: null,
          department_id: null,
          job_title: null,
          is_active: 1,
          is_locked: 0,
          is_manager: 0,
          is_super_admin: 0,
          changepassword_on_next:1,
          token_expiration_time:600
        };
    },
    closeDialog() {
      this.$store.commit('DIALOG', false);
      this.resetFields();
      this.$refs.form.reset();

    },
    convertTohrs(){
        let num = this.form.token_expiration_time?parseInt(this.form.token_expiration_time):0;
        let hr = num != NaN ?num / 60:0;
        return parseInt(hr);
    }
  },
  mounted() {
    this.$store.dispatch('getDepartment')
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
  },

  watch: {
    GET_ERROR_USER: {
      handler(val) {
        if (val) {
          swal.fire({
            text: 'The username has already been taken.',
            icon: 'error',
          });
          this.$store.commit('ERROR_USER', false);
        }
      }
    }
  }
};
</script>
