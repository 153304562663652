<template>
    <v-row class="tabs-body-color ma-0 pa-0">
        <v-btn class="my-3" @click="openAddEditDialog()" v-if="hasAccess('create_classification')">Add</v-btn>
        <v-container class="ma-0 pa-0">
            <v-dialog v-model="component.dialog" width="30%">
                <v-card>
                    <v-row class="m-0">
                        <v-col cols="11">
                            <v-card-title>
                                <span class="text-lg font-semibold" v-text="`Add New ${component.display_name}`"></span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="1" class="pull-right-2 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeAddEditDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="component.data.code"
                                    outlined
                                    dense
                                    hide-details
                                    label="Code"
                                    aria-required
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="component.data.name"
                                    outlined
                                    dense
                                    hide-details
                                    label="Name"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox
                                    v-model="component.data.deduct_from_sales"
                                    outlined
                                    dense
                                    hide-details
                                    :true-value="1"
                                    :false-value="0"
                                    :input-value="1"
                                    label="Deduct From Sales"
                                >
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn v-if="action == 'add'" @click="save()">Submit</v-btn>
                        <v-btn v-else-if="action == 'edit'" @click="update()">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <CreditMemoClassificationTableComponent ref="refreshTable" @showEditDialog="showEditDialog" @hasAccess="hasAccess" :origin="origin" :display_name="component.display_name"></CreditMemoClassificationTableComponent>
        </v-container>
    </v-row>
</template>

<script>
import CreditMemoClassificationTableComponent from '../CreditMemoClassification/CreditMemoClassificationTableComponent.vue'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    components: {
        CreditMemoClassificationTableComponent
    },
    props: [
        'origin'
    ],
    data() {
        return {
            component: {
                display_name: 'Credit Memo Classification',
                dialog: false,
                data: {
                    code: null,
                    name: null,
                    origin: null,
                    deduct_from_sales: 0,
                    company_id: 1,
                },
                origin: [
                    { value: 1, text: 'Origin' },
                    { value: 2, text: 'International' },
                ],
                vuex: {
                    view: {
                        dispatch: 'creditMemoClassification',
                        action: 'show',
                        url: 'credit-memo-classification',
                    },
                    save: {
                        dispatch: 'creditMemoClassification',
                        action: 'save',
                        url: 'credit-memo-classification',
                    },
                    update: {
                        dispatch: 'creditMemoClassification',
                        action: 'update',
                        url: 'credit-memo-classification',
                    },
                },
                id: '',
            },
            action: '',
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
    },
    methods: {
        openAddEditDialog(){
            this.action = 'add'
            this.resetFields(this.component.data)
            this.component.dialog = true;
        },
        closeAddEditDialog(){
            this.component.dialog = false;
        },
        showEditDialog(id){
            this.action = 'edit'
            this.component.id = id

            if(id != null && id > 0){
                let payload = {
                    id: this.component.id,
                    action: this.component.vuex.view.action,
                    url: this.component.vuex.view.url,
                }

                this.$store.dispatch(this.component.vuex.view.dispatch, payload).then(response => {
                    this.component.data = response.data
                    this.component.dialog = true
                })
            }
        },
        save(){
            this.component.data.origin = this.origin
            if(this.isCompleteData(this.component.data)){
                let payload = {
                    data: this.component.data,
                    action: this.component.vuex.save.action,
                    url: this.component.vuex.save.url,
                }

                this.$store.dispatch(this.component.vuex.save.dispatch, payload).then(response => {
                    swal.fire(response.data.msg, '', response.data.icon)
                    this.closeAddEditDialog();
                    this.$refs.refreshTable.getAll();
                })
            }
            else{
                swal.fire('Please Fill out Information','','warning')
            }
        },
        update(){
            if (this.isCompleteData(this.component.data)) {
                let payload = {
                    id: this.component.id,
                    data: this.component.data,
                    action: this.component.vuex.update.action,
                    url: this.component.vuex.update.url,
                }

                this.$store.dispatch(this.component.vuex.update.dispatch, payload).then(response => {
                    swal.fire(response.data.msg, '', response.data.icon)
                    this.closeAddEditDialog();
                    this.resetFields(this.component.data)
                    this.$refs.refreshTable.getAll();
                })
            }
            else{
                swal.fire('Please Fill out Information','','warning')
            }
        },
        resetFields(object){
            for (const key in object) {
                let val = ''
                if (typeof object[key] == 'string') val = '';
                if (typeof object[key] == 'boolean') val = false;
                if (typeof object[key] == 'object') val = [];
                if (typeof object[key] == 'number') val = 0;
                if (typeof object[key] == 'null') val = null;
                object[key] = val
            }
        },
        isCompleteData(data){
            let hasMissingData = false
            for(const key in data) {
                if(data[key] === "") {
                    hasMissingData = true
                }
            }
            return !hasMissingData
        },
        hasAccess(action_code) {
            return this.USER_ACCESS.find(e => e.actions_code == action_code) !== undefined
        },
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS'
        ]),
    }
}
</script>
