<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center" v-if="isActive_tab !='stocks_inquiry'">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2" @click="AddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                 <v-col cols="3" class="d-flex justify-content-end" v-if="isActive_tab !='stocks_inquiry'">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2"
                            @click="ExportToExcel()"
                            :loading="loading.button"
                        >
                        <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
            <StocksInquiryTableComponent v-if="isActive_tab == 'stocks_inquiry'"></StocksInquiryTableComponent>
            </v-row>
        </v-container>

    </div>
</template>
<script>
import StocksInquiryTableComponent from '@/views/main/modules/Sales/StocksInquiry/StocksInquiryTableComponent.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        StocksInquiryTableComponent,
    },
    props:["isActive_tab"],
    data() {
        return {
            loading:{
                button: false
            }
        }
    },
    computed: {
        ...mapGetters(['GET_EXPORT_FILTERS','GET_EXPORT_HEADERS'])
    },
    mounted() {
    },
    methods: {
        AddDialog(){
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Submit");
        },
    },

};
</script>
