<template>
  <div class="w-100">
    <v-app id="load_bar" v-if="!loaded">
      <v-text-field color="info" loading disabled></v-text-field>
    </v-app>

    <v-app id="my_vue_app">
      <v-card v-show="loaded">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
          <v-icon @click="searchInput()">mdi-magnify</v-icon>
        </v-card-title>

        <v-container fluid>
          <v-data-table
            :headers="headers"
            :items="stockTransfers"
            :search="search"
            :options.sync="options"
            :server-items-length="totalStockTransfers"
            :items-per-page="10"
            class="w-100"
          >
            <template v-slot:[`item.remarks`]="{ item }">
              <span style="font-size: 10px !important">
                {{ item.remarks }}
              </span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <span class="text-nowrap">
                {{ item.created_at | formatDateTime }}
              </span>
            </template>

            <template v-slot:[`item.status_text`]="{ item }">
              <span
                class="badge bg-warning"
                v-if="item.status_text == 'pending'"
                >{{ item.status_text }}</span
              >
              <span
                class="badge bg-primary"
                v-else-if="item.status_text == 'approved'"
                >{{ item.status_text }}</span
              >
              <span
                class="badge bg-success"
                v-else-if="item.status_text == 'completed'"
                >{{ item.status_text }}</span
              >
              <span
                class="badge bg-warning"
                v-else-if="item.status_text == 'rejected'"
                >{{ item.status_text }}</span
              >
              <span
                class="badge bg-danger"
                v-else-if="item.status_text == 'canceled'"
                >{{ item.status_text }}</span
              >
              <span
                class="badge bg-dark"
                v-else-if="item.status_text == 'deleted'"
                >{{ item.status_text }}</span
              >
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon color="orange" v-if="userAccess.view">
                <v-icon
                  class="btn-action"
                  small
                  @click="viewStockTransfer(item.id)"
                  >mdi-eye</v-icon
                >
              </v-btn>
              <v-btn
                text
                icon
                color="blue"
                v-if="userAccess.edit && item.status == 0"
              >
                <v-icon
                  class="btn-action"
                  small
                  @click="editStockTransfer(item.id)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
              <v-btn
                text
                icon
                color="red"
                v-if="userAccess.delete && item.status == 0"
              >
                <v-icon
                  class="btn-action"
                  small
                  @click="deleteStockTransfer(item.id)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-app>

    <!-- VIEW MODAL-->
    <StockTransfersViewComponentVue
      :viewStockTransferId="viewStockTransferId"
      @closeView="closeView"
      @changeStockTransferStatus="changeStockTransferStatus"
      :service="service"
    ></StockTransfersViewComponentVue>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StockTransfersViewComponentVue from "../../../../layouts/dialogs/Operations/StockTransfer/StockTransfersViewComponent.vue";
import swal from "sweetalert2";

export default {
  components: {
    StockTransfersViewComponentVue,
  },
  props: ["stockTransfersActionResult", "service"],
  data() {
    return {
      actionResult: {
        model: "StockTransfers",
        action: "",
      },
      loaded: false,
      totalStockTransfers: 0,
      options: {},
      stockTransfers: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 10,
      search: "",
      awaitingSearch: false,
      headers: [
        { text: "Id", align: "left", value: "id" },
        // { text: 'SRF Num', align: 'left', value: 'srf_num', },
        { text: "Source", align: "left", value: "source_name" },
        { text: "Destination", align: "left", value: "destination_name" },
        { text: "Remarks", align: "left", value: "remarks" },
        { text: "Date Created", align: "left", value: "created_at" },
        { text: "User", align: "left", value: "user_name" },
        { text: "Status", align: "left", value: "status_text" },
        { text: "Actions", align: "center", value: "action", sortable: false },
      ],

      viewStockTransferId: "",

      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      userAccess: {
        view: false,
        edit: false,
        delete: false,
      },
    };
  },
  methods: {
    getAllStockTransfers() {
      this.loaded = false;
      let payload = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        search: this.search,
        service: this.service,
      };
      this.$store
        .dispatch("getAllWarehouseTransfer", payload)
        .then((response) => {
          this.stockTransfers = this.GET_ALL_WAREHOUSE_TRANSFER.data;
          this.loaded = true;
          this.totalStockTransfers = this.GET_ALL_WAREHOUSE_TRANSFER.total;
        });
    },
    viewStockTransfer(id) {
      this.viewStockTransferId = id;
    },
    editStockTransfer(id) {
      this.$emit("showEditModal", id);
    },
    deleteStockTransfer(id) {
      swal
        .fire({
          title: "",
          text: "Are you sure you want to Delete?",
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: "Confirm",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "397373",
          cancelButtonColor: "grey",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let payload = {
              id: id,
            };

            this.$store
              .dispatch("destroyStockTransfer", payload)
              .then((response) => {
                this.getAllStockTransfers();
                swal.fire("", response.data, "success");
              })
              .catch((error) => {
                swal.fire("An Error Occured", error, "error");
              });
          }
        });
    },
    closeView() {
      this.viewStockTransferId = 0;
    },
    changeStockTransferStatus() {
      this.viewStockTransferId = 0;
      this.getAllStockTransfers();
    },
    searchInput() {
      this.getAllStockTransfers({ query: this.search });
    },
    addKeyListener() {
      window.addEventListener(
        "keyup",
        function (e) {
          if (e.keyCode == 13) {
            this.searchInput();
            $("input").blur();
          }
        }.bind(this)
      );
    },
    // checkAccess(dep, mod, axn){
    //     axios.get('/checkAccess',{
    //         params: {
    //             department_code: dep,
    //             module: mod,
    //             action: axn,
    //         }
    //     }).then(response => {
    //         this.userAccess[axn] =  response.data;
    //     });
    // },
  },
  mounted: function () {

  },
  computed: {
    ...mapGetters(["GET_ALL_WAREHOUSE_TRANSFER", "USER_ACCESS"]),
  },
  watch: {
    stockTransfersActionResult: function (val) {
      if (this.stockTransfersActionResult == "success") {
        this.getAllStockTransfers();
      }
    },
    options: {
      handler() {
        this.getAllStockTransfers();
      },
      deep: true,
    },
    USER_ACCESS: {
      handler(val) {
        this.userAccess.edit = false;
        this.userAccess.delete = false;
        this.userAccess.view = false;
        if (val != "fail") {
          val.forEach((e) => {
            if (e.actions_code == "ED") {
              this.userAccess.edit = true;
            }
            if (e.actions_code == "VW") {
              this.userAccess.view = true;
            }
            if (e.actions_code == "DL") {
              this.userAccess.delete = true;
            }
          });
        }
      },
    },
    /*search: {
            handler () {
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        this.getAllStockTransfers({ query: this.search });
                        this.awaitingSearch = false;
                    }, 2000); // 1 sec delay
                }
                this.awaitingSearch = true;
            },
            deep: true,
        },*/
  },
};
</script>
