<template>
    <v-form v-model="valid" @submit.prevent="ACTION == 'Submit' ? addNew() : update()">
        <v-card>
            <v-row class="px-3 py-2" dense no-gutters> <!-- Dialog header -->
                <v-col cols="11">
                    <v-card-title class="pa-0 pl-3">
                        <span class="text-h5">Sub Cluster Information</span>
                    </v-card-title>
                </v-col>
                <v-col cols="1">
                    <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="pa-0 ma-0"></v-divider>
            <v-row class="px-3 py-2" dense no-gutters>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="form.cluster_id"
                        :items="GET_CLUSTER_SELECTION"
                        :rules="rules.cluster_id"
                        :readonly="readonly"
                        label="Main Cluster"
                        dense
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="form.code"
                        :rules="rules.code"
                        :readonly="readonly"
                        label="Code"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="form.name"
                        :rules="rules.name"
                        :readonly="readonly"
                        label="Name"
                        dense
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-divider class="px-0 ma-0" v-if="!readonly"></v-divider>
            <v-card-actions class="text-center" v-if="!readonly">
                <v-row class="ma-1">
                    <v-col cols="12">
                        <v-btn
                            :disabled="!valid"
                            type="submit"
                        >
                            <span>{{ ACTION }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'

export default {
    data() {
        return {
            valid: true,
            readonly: false,

            form: {
                id: null,
                cluster_id: null,
                code: null,
                name: null,
            },
            rules: {
                cluster_id: [
                    value => {
                        if (value) return true
                        return 'Cluster is required'
                    },
                ],
                code: [
                    value => {
                        if (value) return true
                        return 'Code is required'
                    },
                ],
                name: [
                    value => {
                        if (value) return true
                        return 'Name is required'
                    },
                ],
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_EDIT_CLUSTERS',
            'GET_CLUSTER_SELECTION',
            'NEW_CLUSTER',
            'ACTION',
            'DIALOGS',
            'USERACCOUNT_LOAD',
        ]),
    },
    methods: {
        closeDialog() {
            this.$store.commit('DIALOG', false)
            this.$store.commit('EDIT_CLUSTERS', {})
        },
        addNew() {
            this.$store.dispatch('addSubCluster', this.form).then(response => {
                this.$store.commit('NEW_CLUSTER', true)
                this.closeDialog()
            }).catch(error => {
                swal.fire({
                    title: 'ERROR!',
                    icon: 'danger',
                })
            })
        },
        update() {
            this.$store.dispatch('updateSubCluster', this.form).then(response => {
                this.$store.commit('NEW_CLUSTER', true)
                this.closeDialog()
            }).catch(error => {
                swal.fire({
                    title: 'ERROR!',
                    icon: 'danger',
                })
            })
        },
    },
    watch: {
        'ACTION': {
            handler(val) {
                this.readonly = (val === 'View');
            },
            immediate: true,
        },
        'GET_EDIT_CLUSTERS': {
            handler() {
                this.form.id = this.GET_EDIT_CLUSTERS.id
                this.form.cluster_id = this.GET_EDIT_CLUSTERS.cluster_id
                this.form.code = this.GET_EDIT_CLUSTERS.code
                this.form.name = this.GET_EDIT_CLUSTERS.name
            }
        },
        immediate: true,
    },
}
</script>
<style lang='scss' scoped>
</style>
