<template>
    <div>
        <div class="py-2" v-if="userAccess.create">
            <v-btn @click="showAddEditBankDialog()">
               <v-icon>mdi-plus</v-icon> Add
            </v-btn>
        </div>

        <v-dialog v-model="addEditBankDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">{{ `${dialogAction == 'edit' ? 'Edit' : 'Add'} Bank` }}</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddEditBankDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="px-2">
                    <div>
                        <div>Code: <span style="color:red">*</span></div>
                        <div>
                            <v-text-field v-model="code" dense outlined />
                        </div>
                    </div>

                    <div>
                        <div>Name: <span style="color:red">*</span></div>
                        <div>
                            <v-text-field v-model="name" dense outlined />
                        </div>
                    </div>

                    <div>
                        <div>Allowed Payment <span style="color:red">*</span></div>
                        <div>
                            <v-autocomplete
                            small-chips
                            multiple
                            :items="GET_PAYMENT_TYPES"
                            v-model="payments.selected"
                            item-value="id"
                            item-text="name"
                            dense
                            outlined />
                        </div>
                    </div>

                    <div>
                        <div>Description:</div>
                        <div>
                            <v-textarea v-model="description" dense outlined />
                        </div>
                    </div>

                    <div>
                        <div>Address: </div>
                        <div>
                            <v-textarea v-model="address" dense outlined />
                        </div>
                    </div>
                </div>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4">
                    <v-btn v-if="dialogAction == 'edit'" :loading="updateOnFlight" @click="update()">Update</v-btn>
                    <v-btn v-else :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            code: '',
            name: '',
            description: '',
            address: '',
            bankDetails: null,
            submitOnFlight: false,
            updateOnFlight: false,
            userAccess:{
                create:false
            },
            payments:{
                selection:[],
                selected:[]
            }
        }
    },
    validations: {
        code: {
            required
        },
        name: {
            required
        },
    },
    watch: {
        bankId() {
            if(!!this.bankId) {
                this.getBankDetails();
            }
        },
        bankDetails() {
            if(!!this.bankDetails) {
                const bank = this.bankDetails;
                this.code = bank.code;
                this.name = bank.name;
                this.description = bank.description;
                this.address = bank.address;
                this.payments.selected = bank.bank_payment_types.map(e =>{ return e.payment_type_id})
            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        }
    },
    computed: {
        addEditBankDialog() {
            return this.$store.state.accounting.bank.addEditBankDialog;
        },
        dialogAction() {
            return this.$store.state.accounting.bank.dialogAction;
        },
        bankId() {
            return this.$store.state.accounting.bank.bankId;
        },
        ...mapGetters([
            'USER_ACCESS',
            'GET_PAYMENT_TYPES'
        ])
    },
    methods: {
        clearFields() {
            this.code = "";
            this.name = "";
            this.description = "";
            this.address = "";
            this.payments.selected = [];
        },
        showAddEditBankDialog() {
            this.$store.commit('bankShowAddEditDialog');
        },
        closeAddEditBankDialog() {
            this.$store.commit('bankCloseAddEditDialog');
            this.$store.commit('bankClearAction');
            this.$store.commit('bankClearId');
            this.clearFields();
        },
        getBankDetails() {
            let payload = {
                url: `getBankDetails/${this.bankId}`
            }
            this.$store.dispatch('banksGet',payload).then(response=>{
                this.bankDetails = response.data;
            });
        },
        submit() {
            this.submitOnFlight = true;
            this.$v.$touch();

            if(!this.$v.$error) {
                swal.fire({
                    title: 'Are you sure you want to Add Bank?',
                    text: 'Make sure that all information are correct',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            code: this.code,
                            name: this.name,
                            description: this.description,
                            address: this.address,
                            url:'createBank',
                            payments:this.payments.selected
                        }
                        this.$store.dispatch('banksPost',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error');
                                this.submitOnFlight = false;
                            } else {
                                this.closeAddEditBankDialog();
                                this.clearFields();
                                this.$store.commit('bankSuccessAddEditTrue');
                                this.submitOnFlight = false;
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('bankSuccessAddEditFalse');
                                    }
                                });
                            }
                        });
                    }
                });
            } else {
                swal.fire('','Please Fill Required Fields','warning').then(confirm=>{
                    this.submitOnFlight = false;
                });
            }
        },
        update() {
            this.updateOnFlight = true;
            this.$v.$touch();

            if(!this.$v.$error) {
                swal.fire({
                    title: 'Are you sure you want to Update Bank?',
                    text: 'Make sure that all information are correct',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            bankId: this.bankId,
                            code: this.code,
                            name: this.name,
                            description: this.description,
                            address: this.address,
                            url: 'updateBank',
                            payments:this.payments.selected
                        }
                        this.$store.dispatch('banksPost',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error');
                                this.updateOnFlight = false;
                            } else {
                                this.closeAddEditBankDialog();
                                this.clearFields();
                                this.$store.commit('bankSuccessAddEditTrue');
                                this.updateOnFlight = false;
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('bankSuccessAddEditFalse');
                                    }
                                });
                            }
                        });
                    }
                });
            } else {
                swal.fire('','Please Fill Required Fields','warning');
                this.updateOnFlight = false;
            }
        },
    }
}
</script>

<style>

</style>
