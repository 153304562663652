<template>
    <v-container class="pa-2 ma-0">
        <v-row class="ma-1">
            <v-col cols="12" class="d-flex no-block align-items-center">
                <h4 class="page-title" style="color: black">Credit Memo (Change Date)</h4>
            </v-col>
        </v-row>

        <v-app id="item-tabs" class="mt-5">
            <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" show-arrows>
                <v-tab v-for="(tb,i) in tabs" :key=i ripple @click="status=tb">
                    {{tb}}
                </v-tab>
            </v-tabs>
            <CreditMemoHistoryComponent :status="status"></CreditMemoHistoryComponent>
        </v-app>

    </v-container>
</template>
<script>

import CreditMemoHistoryComponent from './CreditMemoHistoryComponent.vue';
export default {
    components: {
        CreditMemoHistoryComponent
    },
    props: [
        'origin',
    ],
    async mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
        this.$store.dispatch('getAllCreditMemoSelection',{url:'getAllCreditMemoSelection'})
        this.$store.dispatch('getAllCustomerWithCMSelection',{url:'getAllCustomerWithCMSelection'})
    },
    data() {
        return {
            tab: null,
            tabs:['all','saved','approved'],
            status:'all',
        }
    },
    methods: {

    },
    watch: {
        origin: {
            handler() {
                this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
            }
        },
    }
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
