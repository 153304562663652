<template>
    <v-container fluid class="pa-3">
        <ComponentHeader
            :button="true"
            :title="'Items Which Container Last Arrived 2 Months or More'"
            :type="'Inventory'"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray default-container ma-0">
            <v-row no-gutters class="pb-1">
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_type_ids"
                        :items="dropdowns.item_types.list"
                        :loading="loading.item_types"
                        :disabled="loading.item_types"
                        class="d-inline-block w-100 small"
                        label="Discount Type"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_category_ids"
                        :items="dropdowns.item_categories.list"
                        :loading="loading.item_categories"
                        :disabled="loading.item_categories"
                        class="d-inline-block w-100 small"
                        label="Category"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_models_ids"
                        :items="dropdowns.item_models.list"
                        :loading="loading.item_models"
                        :disabled="loading.item_models"
                        class="d-inline-block w-100 small"
                        label="Model"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.date_option"
                        :items="dropdowns.date_option.list"
                        class="d-inline-block w-100 small"
                        label="Select Date Range"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="12" md="3" class="text-right">
                    <v-btn class="mr-1" @click="resetFields()">Clear</v-btn>
                    <v-btn @click="getItemsWhichContainerLastArrived(1)">Export</v-btn>
                    <v-btn class="ml-1" @click="clearUrlParams(); getItemsWhichContainerLastArrived()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col sm="12" md="2" v-if="toggles.custom_date">
                    <v-menu
                        v-model="toggles.date_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                v-on="on"
                                label="Date"
                                append-icon="mdi-calendar-clock"
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="toggles.date_to = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row dense no-gutters>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-data-table
                                :headers="datatable.headers"
                                :items="datatable.items.list"
                                :options.sync="datatable.options"
                                :server-items-length="datatable.items.count"
                                :loading="toggles.loaded"
                                class="w-100"
                                dense
                                disable-sort
                                @update:page="getItemsWhichContainerLastArrived()"
                                @update:items-per-page="getItemsWhichContainerLastArrived()"
                            >
                                
                                <template v-slot:item.selling_price="{ item }">
                                    <span class="text-nowrap">
                                        {{thousandSeprator(item.selling_price)}}
                                    </span>
                                </template>
                                <template v-slot:item.image_path="{ item }">
                                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
                                </template>
                                <template v-slot:item.total_stocks="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.total_stocks) }}
                                    </span>
                                </template>
                                <template v-slot:item.has_stocks="{ item }">
                                    <span class="text-nowrap">
                                        <span :class="`badge badge-${item.has_stocks.toLowerCase() == 'yes' ? 'primary': 'danger'}`" >{{item.has_stocks}}</span>
                                    </span>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-card>
                    <ItemsViewComponent
                        :viewItemId="datatable.items.selected"
                        @closeView="closeView"
                    ></ItemsViewComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue"
import moment from 'moment'
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

const ExcelJS = require('exceljs')
const FileSaver = require('file-saver')

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
        ItemsViewComponent,
    },
    data: () => ({
        urlParams: {
            date_option: null,
            date_from: null,
            date_to: null,
            type_ids: [],
            category_ids: [],
            model_ids: [],
        },
        defaults: {
            date_option: 3,
            item_type_ids: [],
            item_category_ids: [],
            allowable: 0,
            for_export: 0,
        },
        toggles: {
            loaded: false,
            date_to: false,
            custom_date: false,
        },
        loading: {
            item_types: true,
            item_categories: true,
            item_models : true
        },
        filters: {},
        dropdowns: {
            date_option: {
                list: [
                    { value: 1, text: 'Past Week' },
                    { value: 2, text: 'Past Month' },
                    { value: 3, text: 'Past 2 Months' },
                    { value: 4, text: 'Past Quarter'},
                ],
                allowable: 0,
            },
            item_types: {
                list: [],
            },
            item_categories: {
                list: [],
            },
            item_models: {
                list: [],
            },
        },
        datatable: {
            items: {
                list: [],
                count: 0,
                selected: 0,
            },
            headers: [
                { text: 'IMAGE', align: 'start', value: 'image_path', },
                { text: 'MODEL', align: 'start', value: 'model', },
                { text: 'NAME', align: 'start', value: 'name', },
                { text: 'TYPE', align: 'start', value: 'type' },
                { text: 'UOM', align: 'start', value: 'uom' },
                { text: 'CATEGORY', align: 'start', value: 'category' },
                { text: 'SIZE', align: 'end', value: 'size', },
                { text: 'SELLING PRICE', align: 'end', value: 'selling_price', },
                { text: 'TOTAL STOCKS', align: 'end', value: 'total_stocks', },
                { text: 'TOTAL SKUS', align: 'end', value: 'total_category_skus', },
                { text: 'HAS STOCKS', align: 'center', value: 'has_stocks', },
                { text: 'Action', align: 'center', value: 'action', },
            ],
            options: {},
        },
    }),
    async mounted(){
        this.resetFields()
        await this.storeUrlParams()
        await this.loadDropdowns()
        await this.setDefaultValues()
    },
    computed: {
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
            'GET_ITEMS'
        ])
    },
    methods: {
        viewItem(item_id) {
            this.datatable.items.selected = item_id
        },
        closeView() {
            this.datatable.items.selected = 0
        },
        async storeUrlParams() {
            const params = new URLSearchParams(window.location.search)

            Object.assign(this.urlParams, {
                date_option: params.get('ddSelection') == null ? this.defaults.date_option : params.get('ddSelection'),
                type_ids: params.get('type_id') == null ? this.defaults.item_type_ids : JSON.parse(params.get('type_id')),
                category_ids: params.get('category_id') == null ? this.defaults.item_category_ids : JSON.parse(params.get('category_id')),
                model_ids: params.get('category_id') == null ? this.defaults.item_model_ids : JSON.parse(params.get('category_id')),
            })

            Object.assign(this.filters, {
                date_option: this.urlParams.date_option,
                item_type_ids: this.urlParams.type_ids,
                item_category_ids: this.urlParams.category_ids,
                item_model_ids: this.urlParams.model_ids,
            })
        },
        async loadDropdowns() {
            await this.$store.dispatch('getItemTypes')
            await this.$store.dispatch('getItemCategories')
            await this.$store.dispatch('getItems')
        },
        async setDefaultValues() {
            Object.assign(this.filters, {
                item_type_ids:
                    this.dropdowns.item_types.list.filter(f => {
                        return [1, 2].includes(f.value)
                    }).map(m => parseInt(m.value))
            })
        },
        clearUrlParams(){
            Object.assign(this.urlParams, {
                type_ids: [],
                category_ids: [],
                model_ids :[]
            })
        },
        async getItemsWhichContainerLastArrived(for_export = 0) {
            this.toggles.loaded = true
            this.filters.for_export = for_export
            this.datatable.items.list = []

            let current_date = moment().format('YYYY-MM-DD')
            let date_range = this.dropdowns.date_option.list.find(el=> el.value == this.filters.date_option).text

            switch (this.filters.date_option) {
                case 1:
                    this.filters.date_filters = 7
                    break
                case 2:
                    this.filters.date_filters = 30
                    break
                case 3:
                    this.filters.date_filters = 60
                    break
                case 4:
                    this.filters.date_filters = 90
                    break
            }

            let payload = {
                pagination: this.datatable.options,
                filters: this.filters,
                url: 'getItemsWhichContainerLastArrived',
            }

            await this.$store.dispatch('reports', payload).then(async response => {
                if (for_export == 0) {
                    let data = response.data.data
                    this.datatable.items.list = data.data
                    this.datatable.items.count = data.total
                } else {
                    let data = response.data
                    const workbook = new ExcelJS.Workbook()
                    const rawDataSheetName = `Items Which Container Last Arrived ${date_range} as of ${current_date}`
                    let worksheet = workbook.addWorksheet(rawDataSheetName)

                    Swal.fire('Exporting File Please Wait')
                    Swal.showLoading()

                    const columns = [
                        { header: 'IMAGE', key: '', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } },  },
                        { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'DESCRIPTION', key: 'description', width: 57, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } },  },
                        { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } },  },
                        { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'SIZE', key: 'size', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                        { header: 'SRP', key: 'selling_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' },  },
                        { header: 'TOTAL STOCKS', key: 'total_stocks', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }},
                        { header: 'TOTAL SKUS', key: 'total_category_skus', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }},
                        { header: 'HAS STOCKS', key: 'has_stocks', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }},
                    ]

                        workbook.getWorksheet(rawDataSheetName)
                        
                        worksheet.columns = columns

                        let pos = 1

                        data.forEach((e,i)=>{
                            e.model = `(${e.model})\n${e.name}`
                            e.selling_price = parseFloat(e.selling_price)
                            e.total_stocks = parseInt(e.total_stocks)
                            worksheet.addRow(e)

                            let finalImage = workbook.addImage({
                                base64: e.base64img,
                                extension: 'jpg',
                            })

                            worksheet.addImage(finalImage, {
                                tl: { col: 0, row: pos + 0.1},
                                br: { col: 1, row: worksheet.rowCount },
                                editAs: 'twoCell'
                            })

                            worksheet.getRow(pos + 1).height = 250

                            pos++
                        })

                        worksheet.getRow(1).font = { bold: true };
                        worksheet.getRow(1).height = 30;
                        let borderStyles = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" }
                        };

                        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                            row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                                cell.border = borderStyles;
                            });
                        });

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        const filename = `Items Which Container Last Arrived ${date_range} as of ${current_date}.xlsx`
                        FileSaver.saveAs(blob, filename)
                    })
                }
                Swal.close()
                this.toggles.loaded = false
            })
        },
        resetFields(){
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
    },
    watch: {
        'GET_ITEM_TYPES': {
            handler(val) {
                this.dropdowns.item_types.list = val
                this.loading.item_types = false
            }
        },
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.dropdowns.item_categories.list = val
                this.loading.item_categories = false
            }
        },
        'GET_ITEMS': {
            handler(val) {
                this.dropdowns.item_models.list = val
                this.loading.item_models = false
            }
        },
    }
}
</script>
<style>
    .card-body{
        padding: 3px !important
    }
</style>
