<template>
    <v-container class="container">
        <v-row dense class="mx-auto">
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-autocomplete
                    v-model="item_type_ids"
                    :items="item_type_list"
                    :loading="loadview.item_type_selection"
                    :disabled="loadview.item_type_selection"
                    label="Discount Type"
                    dense
                    outlined
                    hide-details
                    multiple
                    small-chips
                    clearable
                    deletable-chips
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-autocomplete
                    v-model="category_ids"
                    :items="category_list"
                    :loading="loadview.item_category_selection"
                    :disabled="loadview.item_category_selection"
                    label="Category Type"
                    dense
                    outlined
                    hide-details
                    multiple
                    small-chips
                    clearable
                    deletable-chips
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                <v-autocomplete
                    v-model="status_id"
                    :items="statusOption"
                    label="Item Status"
                    dense
                    outlined
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col class="d-flex justify-content-end" style="gap: 5px;">
                <v-btn @click="getAllItems()">Filter</v-btn>
                <v-btn @click="openExportDialog()">Export</v-btn>
                <v-btn @click="openImportDialog()">Import</v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-auto m-0 d-flex flex-row align-items-center">
            <v-switch
                v-model="showOnlyNoCost"
                :true-value="true"
                :false-value="false"
                :input-value="false"
                label="Show Only No Cost"
                dense
                hide-details
            ></v-switch>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                    v-model="search"
                    label="Search Model / Name"
                    append-icon="mdi-magnify"
                    single-line
                    dense
                    hide-details
                    @keydown.enter="getAllItems()"
                    @click:append="getAllItems()"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="itemsPerPage"
                :loading="loading"
                :disable-sort="true"
                :footer-props="{
                    showFirstLastPage: true,
                }"
            >
                <template v-slot:[`item.image_path`]="{ item }">
                    <v-img class="img-thumbnail" width="60" height="60" :src="item.image_path == null ? no_image : env+item.image_path" eager contain></v-img>
                </template>

                <template v-slot:[`item.exchange_rate`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.exchange_rate | currency }}
                    </span>
                </template>

                <template v-slot:[`item.cost_in_php`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.cost_in_php | currency }}
                    </span>
                </template>

                <template v-slot:[`item.cost_in_foreign`]="{ item }">
                    <span class="text-nowrap">
                        &#x0024;{{ item.cost_in_foreign | currency }}
                    </span>
                </template>

                <template v-slot:[`item.freight_percentage`]="{ item }">
                    <span class="text-nowrap">
                        {{ parseFloat(item.freight_percentage) + '%' }}
                    </span>
                </template>

                <template v-slot:[`item.cost_updated_at`]="{ item }">
                    <span class="text-nowrap">
                        {{ item.cost_updated_at | formatDate }}
                    </span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="blue">
                        <v-icon class="btn-action" small @click="openEditDialog(item)">mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>

        <v-dialog v-model="importDialog" persistent max-width="400">
            <v-card>
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span class="headline">Import Costing Excel File</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-0">
                        <v-btn text icon color="gray" class="float-right" @click="closeImportDialog()" :loading="excelLoading">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="ma-0 pa-0"></v-divider>
                <v-card-text>
                    <v-file-input
                        :loading="excelLoading"
                        :disabled="excelLoading"
                        show-size
                        v-model="excelFile"
                        truncate-length="50"
                        placeholder="Choose excel file"
                        prepend-icon="mdi-file-excel"
                    ></v-file-input>
                    <br>
                    <!-- <span v-if="excelLoading">{{ `Batch ${batch.current} of ${batch.total}` }}</span> -->
                </v-card-text>

                <v-divider></v-divider>

                <div style="text-align:center" class="pb-4">
                    <v-btn :loading="excelLoading" @click="importExcel()">Import</v-btn>
                </div>

            </v-card>
        </v-dialog>

        <v-dialog v-model="exportDialog" persistent max-width="400">
            <v-card style="overflow-x: hidden;">
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span class="headline">Export Excel File</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="2" class="text-right">
                        <v-btn text icon color="gray" @click="closeExportDialog()" :loading="excelLoading">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="ma-0 pa-0" />

                <v-row class="pa-4">
                    <v-spacer/>
                    <v-col cols="4" class="text-center">
                        <v-checkbox label="SRP" v-model="include_srp"></v-checkbox>
                    </v-col>
                    <v-spacer/>
                    <v-col cols="4" class="text-center">
                        <v-checkbox label="SP" v-model="include_sp"></v-checkbox>
                    </v-col>
                </v-row>

                <v-divider class="ma-0 pa-0" />

                <div class="text-center pb-4 pt-4">
                    <v-btn :loading="exportLoading" :disabled="exportLoading" @click="getAllItems(1)">Export</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editCostingDialog" persistent max-width="50%" scrollable>
            <v-card>
                <v-row dense class="mx-1">
                    <v-col cols="11">
                        <v-card-title class="pb-0">
                            <span class="headline">Edit Item Costing</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-content-center align-items-center">
                        <v-btn text icon color="gray" class="float-right" @click="closeEditDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row dense class="mx-2">
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-text-field
                            v-model="item_model"
                            label="Model"
                            dense
                            outlined
                            hide-details
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                        <v-text-field
                            v-model="item_name"
                            label="Name"
                            dense
                            outlined
                            hide-details
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="mx-2">
                    <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-autocomplete
                            v-model="item_costing_size_id"
                            :items="item_costing_size_list"
                            :loading="loadview.item_costing_size_selection"
                            :disabled="loadview.item_costing_size_selection"
                            label="Size"
                            item-text="code_with_freight_percentage"
                            item-value="id"
                            dense
                            filled
                            hide-details
                            @input="onSelectItemCostingSize()"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                        <v-text-field
                            v-model="exchange_rate"
                            label="Exchange Rate"
                            dense
                            filled
                            hide-details
                            @keypress="decimalOnly"
                            @input="computeRate"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-text-field
                            v-model="cost_in_foreign"
                            label="Cost (USD)"
                            placeholder="0.00"
                            prefix="$"
                            dense
                            filled
                            hide-details
                            @keypress="decimalOnly"
                            @input="computeRate"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <v-text-field
                            v-model="cost_in_php"
                            label="Cost w/ Freight (PHP)"
                            placeholder="0.00"
                            prefix="₱"
                            dense
                            filled
                            hide-details
                            @keypress="decimalOnly"
                            @input="computeRate"
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col sm="12" md="3" cols="3">
                        <v-autocomplete
                            v-model="currency.value"
                            :items="currency.selection"
                            :loading="loading.currency_selection"
                            :disabled="loading.currency_selection"
                            outlined
                            dense
                            label="Currency"
                            item-value="id"
                            item-text="code"
                            class="d-inline-block my-4"
                            @input="onSelectCurrency()"
                        ></v-autocomplete>
                    </v-col> -->
                </v-row>
                <v-divider></v-divider>
                <div style="text-align:center" class="pb-4">
                    <v-btn @click="updateCostingItem()">Update</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver');
import * as XLSX from 'xlsx'

export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    props: [
        'refreshData',
    ],
    data () {
        return {
            loadview:{
                item_type_selection: true,
                item_category_selection: true,
                currency_selection: true,
                item_costing_size_selection: true,
            },
            loading: false,
            items: [],
            itemsPerPage: 10,
            search: '',
            showOnlyNoCost: false,
            headers: [
                { text: 'Image', width: '5%', value: 'image_path' },
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Type', width: '2%', align: 'center', value: 'type' },
                { text: 'Category', width: '9%', value: 'category' },
                { text: 'UOM', width: '4%', align: 'center', value: 'uom' },
                { text: 'Exchange Rate', width: '3%', align: 'center', value: 'exchange_rate' },
                { text: 'Cost USD', width: '6%', align: 'end', value: 'cost_in_foreign' },
                { text: 'Cost PHP', width: '8%', align: 'end', value: 'cost_in_php' },
                { text: 'Freight', width: '6%', align: 'end', value: 'freight_percentage' },
                { text: 'Actions', width: '5%', align: 'center', sortable: false, value: 'action' },
            ],
            statusOption: [
                { text: 'All', value: -1 },
                { text: 'Active', value: 1 },
                { text: 'Inactive', value: 0 },
            ],

            importDialog:false,

            excelFile:null,

            status_id: -1,

            userAccess: {
                view: false,
                edit: false,
                delete: false,
            },
            editCostingDialog: false,
            no_image:'/images/noimage.png',

            item_id:'',
            item_name:'',
            item_model: '',
            item_costing_size: '',
            currency: {
                selection: [],
                value: null,
            },
            currency_name:'',
            cost_in_php:'0.00',
            cost_in_foreign:'',
            exchange_rate:'0.00',
            freight_percentage: '0.00',

            category_ids:[],
            category_list:[],
            item_type_ids:[],
            item_type_list: [],
            item_costing_size_id: null,
            item_costing_size_list: [],

            excelLoading: false,
            batch: {
                current: 0,
                total: 0,
            },

            userAcess: {
                view: false,
                create:false,
                delete:false,
                edit: false,
                export: false,
            },
            env: process.env.VUE_APP_API_HOST + '/',
            exportDialog: false,
            include_srp: false,
            include_sp: false,
            exportLoading: false
        }
    },
    computed:{
        ...mapGetters([
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES',
            'GET_ALL_COST_ITEM',
            'GET_ACCOUNTING_ITEM',
            'GET_CURRENCY',
            'GET_ALL_ITEM_CATEGORIES',
            'USER_ACCESS',
            'GET_ALL_ITEM_COSTING_SIZE',
        ])
    },
    async mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        await this.getItemCategories()
        await this.getItemTypes()
        await this.getItemCostingSizes()
        await this.setDefaultValues()
        await this.getAllItems()
    },
    methods:{
        async setDefaultValues(){
            this.item_type_ids = this.item_type_list.filter(f => {
                return [1, 2].includes(f.value)
            }).map(m => m.value)
        },
        computeRate(){
            let res = this.cost_in_foreign * this.exchange_rate
            this.cost_in_php = res / (1 - (parseFloat(this.freight_percentage) / 100))
        },
        openEditDialog(item) {
            let payload = {
                item_id: item.item_id
            }

            this.$store.dispatch('accountingGetItem', payload).then(response => {
                this.item_name          = this.GET_ACCOUNTING_ITEM[0].name
                this.item_model         = this.GET_ACCOUNTING_ITEM[0].model
                this.item_costing_size  = this.GET_ACCOUNTING_ITEM[0].item_costing_size + ' (' + parseFloat(this.GET_ACCOUNTING_ITEM[0].freight_percentage) + '%)'
                this.item_costing_size_id = this.GET_ACCOUNTING_ITEM[0].item_costing_size_id
                this.cost_in_php        = this.GET_ACCOUNTING_ITEM[0].cost_in_php      != null ? this.GET_ACCOUNTING_ITEM[0].cost_in_php     : '0.00'
                this.exchange_rate      = this.GET_ACCOUNTING_ITEM[0].exchange_rate    != null ? this.GET_ACCOUNTING_ITEM[0].exchange_rate   : this.GET_ACCOUNTING_ITEM[0].currency[0].exchange_rate
                this.cost_in_foreign    = this.GET_ACCOUNTING_ITEM[0].cost_in_foreign  != null ? this.GET_ACCOUNTING_ITEM[0].cost_in_foreign : '0.00'
                this.currency.value     = this.GET_ACCOUNTING_ITEM[0].currency_id      != null ? this.GET_ACCOUNTING_ITEM[0].currency_id     : this.GET_ACCOUNTING_ITEM[0].currency[0].id
                this.currency_name      = this.GET_ACCOUNTING_ITEM[0].currency_name != null ? this.GET_ACCOUNTING_ITEM[0].currency_name : this.GET_ACCOUNTING_ITEM[0].currency[0].name
                this.freight_percentage = this.GET_ACCOUNTING_ITEM[0].freight_percentage
                this.item_id            = item.item_id
                this.editCostingDialog  = true;
                this.getCurrency();
            })
        },
        closeImportDialog(){
            this.importDialog = false
            this.excelFile = null
        },
        closeExportDialog(){
            this.exportDialog = false
            this.include_srp = false
            this.include_sp = false
        },
        closeEditDialog() {
            this.editCostingDialog = false;
        },
        openImportDialog(){
            this.importDialog = true
        },
        openExportDialog(){
            this.exportDialog = true
        },
        getCurrency(){
            this.$store.dispatch('getCurrency').then((response)=>{
                this.currency.selection = this.GET_CURRENCY
            })
        },
        onSelectCurrency(){
            let payload = {
                currency_id: this.currency.value
            }

            this.$store.dispatch('getExchangeRate',payload).then(response => {
                this.currency_name = this.GET_EXCHANGE_RATE[0].name
                this.exchange_rate = this.GET_EXCHANGE_RATE[0].exchange_rate
            })
        },
        onSelectItemCostingSize() {
            this.freight_percentage = JSON.parse(JSON.stringify(this.item_costing_size_list)).find(f => {
                return f.id === this.item_costing_size_id
            }).freight_percentage

            this.computeRate()
        },
        async getAllItems(for_export = 0){
            this.loading = true
            this.exportLoading = true

            let payload = {
                search            : this.search,
                item_type_id      : this.item_type_ids,
                item_category_id  : this.category_ids,
                status_id         : this.status_id,
                export            : for_export,
            }

            await this.$store.dispatch('getAllCostItem', payload).then(response => {
                let items = this.showOnlyNoCost ? this.GET_ALL_COST_ITEM.filter(f => {
                    return parseFloat(f.cost_in_foreign) == 0 || f.cost_in_foreign == '' || f.cost_in_foreign == null
                }) : this.GET_ALL_COST_ITEM

                if (for_export == 0) {
                    this.items = items
                    this.loading = false
                } else {
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('Raw Data')
                    let columns = [
                        { header: 'ID', key: 'id' },
                        { header: 'MODEL', key: 'model' },
                        { header: 'TYPE', key: 'type' },
                        { header: 'UOM', key: 'uom' },
                        { header: 'CATEGORY', key: 'category' },
                        { header: 'CURRENCY', key: 'currency' },
                        { header: 'COST (FOREIGN)', key: 'cost_in_foreign' },
                        { header: 'EXCHANGE RATE', key: 'exchange_rate' },
                        { header: 'COST (PHP)', key: 'cost_in_php' },
                        { header: 'SIZE', key: 'item_costing_size' }
                    ]

                    if (this.include_srp) {
                        columns.push({ header: 'SRP', key: 'srp', style: { numFmt: '#,##0.00' } });
                    }
                    if (this.include_sp) {
                        columns.push({ header: 'SP', key: 'sp', style: { numFmt: '#,##0.00' } });
                    }

                    worksheet.columns = columns

                    let headerStyle = {
                        style:{font:{bold: true, name: 'Comic Sans MS'}},
                    }

                    items.forEach((e,i) => {
                        if (e.cost_in_php === null) { e.cost_in_php = 0; }
                        if(e.cost_in_foreign === null){ e.cost_in_foreign = 0; }
                        if(e.exchange_rate === null){ e.exchange_rate = 0; }
                        worksheet.addRow(e);
                        // COST (FOREIGN)
                        worksheet.getCell(`G${i + 2}`).value = parseFloat(e.cost_in_foreign)
                        // EXCHANGE RATE
                        worksheet.getCell(`H${i + 2}`).value = parseFloat(e.exchange_rate)
                        // COST (PHP)
                        worksheet.getCell(`I${i + 2}`).value = parseFloat(e.cost_in_php)
                    });

                    worksheet.getRow(`0${1}`).font = { bold: true };
                    worksheet.getColumn('G').numFmt = '#,##0.00'
                    worksheet.getColumn('G').alignment = {horizontal: 'right' };
                    worksheet.getColumn('H').numFmt = '#,##0.00'
                    worksheet.getColumn('H').alignment = {horizontal: 'right' };
                    worksheet.getColumn('I').numFmt = '#,##0.00'
                    worksheet.getColumn('I').alignment = { horizontal: 'right' };

                    let sizeData = this.GET_ALL_ITEM_COSTING_SIZE.map(s=>({
                        item_costing_size: s.code,
                        freight_percentage: s.freight_percentage
                    }));

                    const sizeGuideWorkSheet = workbook.addWorksheet('Size Guide');
                    sizeGuideWorkSheet.columns = [
                        { header: 'Code', key: 'item_costing_size' },
                        { header: 'Freight %', key: 'freight_percentage' },
                    ];

                    sizeData.forEach((data, index) => {
                        const rowIndex = index + 2;
                        sizeGuideWorkSheet.addRow(data, `A${rowIndex}`);
                    });
                    sizeGuideWorkSheet.getRow(`0${1}`).font = { bold: true };

                    workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    FileSaver.saveAs(blob, `ItemCostingToImport.xlsx`);
                });
                }
            }).catch(e => {
                swal.fire('Error !', '', 'error')
                console.log(e)
            })

            this.loading = false
            this.exportLoading = false
            this.closeExportDialog();
        },
        alertWarnings(message, errors){
            let that = this;
            let errorMessages = [];

            Object.keys(errors).forEach((key) => {

                if (key === 'authorization') {
                    //Close Recent Dialog || All Dialog
                }

                errors[key].forEach(message => {
                    errorMessages.push(`- ${message}`);
                })
            })

            swal.fire({
                title: message,
                text: errorMessages.join("\n"),
                icon: "warning",
                dangerMode: true,
            });
        },
        async importExcel() {
            let warnings = []
            let excel = this.excelFile

            if(!excel) {
                warnings.push("File is required.");
            } else {
                if (_.indexOf(['xls', 'xlsx'], excel.name.split('.').pop().toLowerCase()) === -1) {
                    warnings.push("File must be type of excel spreadsheet.");
                }
            }

            if (warnings.length) {
                this.alertWarnings('Warning', {
                    'validation warning' : warnings
                });
                return false;
            }

            new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = (e) => {
                    let data = new Uint8Array(e.target.result)
                    let workbook = XLSX.read(data)
                    let sheet = workbook.Sheets[workbook.SheetNames[0]]
                    let result = XLSX.utils.sheet_to_json(sheet, { header: 1 })
                    result.shift()

                    if (result.some(row => !row[9])) {
                        swal.fire({
                            title: 'Error',
                            text: 'Missing data in some cells inside SIZE column',
                            icon: 'error'
                        });
                        this.excelLoading = false;
                        return;
                    }

                    resolve(result)
                }
                reader.readAsArrayBuffer(excel)
            }).then(async r => {
                this.excelLoading = true

                let alert = {
                    title: '',
                    text: '',
                    icon: 'success',
                    status: 200,
                }

                await this.$store.dispatch('importItemCosting', { data: r }).then(response => {
                    if (response.data.status == 200) {
                        alert.title = response.data.message;
                    } else {
                        alert.title = response.data.message;
                        alert.text = response.data.error;
                        alert.icon = 'danger';
                    }
                }).catch(error => {
                    this.alertWarnings(error.response.data.message, error.response.data.errors);
                });

                swal.fire(alert);

                this.getAllItems()
                this.excelLoading = false
                this.excelFile = null
                this.importDialog = false
            })
        },
        async getItemTypes(){
            await this.$store.dispatch('getItemTypes').then(response => {
                this.item_type_list = this.GET_ITEM_TYPES
                this.setDefaultValues()
            });
        },
        async getItemCategories(){
            await this.$store.dispatch('getAllItemCategories').then(response => {
                this.category_list = this.GET_ALL_ITEM_CATEGORIES.map(e=>{return {text:e.name,value:e.id}})
            });
        },
        async getItemCostingSizes() {
            await this.$store.dispatch('getAllItemCostingSizes').then(response => {
                this.item_costing_size_list = this.GET_ALL_ITEM_COSTING_SIZE
            })
        },
        updateCostingItem() {
            swal.fire({
                title: 'Are you sure you want to Update Costing?',
                text: 'Make sure that all information are correct',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        item_id: this.item_id,
                        currency_id: this.currency.value,
                        exchange_rate: this.exchange_rate,
                        cost_in_foreign: this.cost_in_foreign,
                        cost_in_php: this.cost_in_php,
                        item_costing_size_id: this.item_costing_size_id,
                    }

                    this.$store.dispatch('updateItemCosting', payload).then(response => {
                        if (!!response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.closeEditDialog();
                            this.getAllItems();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        },
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                    });
                }
            },
        },
        'GET_ITEM_TYPES':{
            handler(val){
            this.loadview.item_type_selection = false
            }
        },
        'GET_ALL_ITEM_CATEGORIES':{
            handler(val){
                this.loadview.item_category_selection = false
            }
        },
        'GET_ALL_ITEM_COSTING_SIZE': {
            handler(val) {
                this.loadview.item_costing_size_selection = false
            }
        },
        'GET_CURRENCY':{
            handler(val){
                this.loadview.currency_selection = false
            }
        },
        'refreshData': {
            handler(val) {
                if (val) {
                    this.getAllItems()
                }
            }
        }
    }
};
</script>
