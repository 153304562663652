<template>
    <v-container class="ma-0 pa-2">
        <v-dialog v-model="loading.dialog" width="500" persistent>
            <v-card>
                <v-card-title>
                    <span style="font-size: 12px"></span>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear indeterminate color="green"></v-progress-linear>
                    Exporting Data
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card-title>
            <v-row>
                <v-col md="16" class="d-flex no-block align-items-center">
                    <h4 class="page-title" style="color: black">Warehouse Inventory</h4>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn @click="hakotExportReports()" class="mx-3">HAKOT</v-btn>
                    <v-btn @click="exportWarehouseInventory()">INVENTORY</v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-app id="my_vue_app" class="mx-2 mt-5">
            <v-card class="">
                <v-row class="ma-2">
                    <v-col cols="2">
                        <v-autocomplete
                            v-model="category_id"
                            :items="category_list"
                            :loading="loading.category_selection"
                            :disabled="loading.category_selection"
                            label="Select Category"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            color="#b3d4fc"
                            class="w-100"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-btn @click="filterItems()">Filter</v-btn>
                    </v-col>
                    <v-col cols="9" class="text-right">
                        <v-chip small class="mx-1" color="black" outlined @click="getAllItems('default')">default</v-chip>
                        <v-chip small class="mx-1" color="primary" @click="getAllItems('out_of_stock_default')">low level stock (default)</v-chip>
                        <v-chip small class="mx-1" color="yellow" @click="getAllItems('low_level_stock')">low level stock</v-chip>
                        <v-chip small class="mx-1" color="red" @click="getAllItems('out_of_stock')">out of stock</v-chip>
                    </v-col>
                </v-row>
                <v-row class="ma-2">
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                        <v-text-field
                            v-model="search"
                            label="Search Model/Name/Part#"
                            single-line
                            hide-details
                            append-icon="mdi-magnify"
                            @click:append="searchInput()"
                            @keydown.enter="searchInput()"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-container fluid>
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :items-per-page="itemsPerPage"
                            :expanded.sync="expanded"
                            :loading="!loaded"
                            :footer-props="{
                                itemsPerPageOptions: itemsPerPageOptions,
                                showFirstLastPage: true,
                            }"
                            class="w-100"
                            disable-sort
                            show-expand
                            fixed-header
                            @item-expanded="loadDetails"
                        >
                            <template v-slot:[`item.model`]="{ item }" :class="freezeheader">
                                <v-chip small v-if="item.active == 0" dark>{{ item.model }}</v-chip>
                                <v-chip small v-else-if="item.default_warehouse_stocks <= 5 && item.non_default_warehouse_stocks > 5" color="primary">{{ item.model }}</v-chip>
                                <v-chip small v-else-if="item.default_warehouse_stocks <= 5 && item.non_default_warehouse_stocks >= 1 && item.non_default_warehouse_stocks <= 5" color="yellow">{{ item.model }}</v-chip>
                                <v-chip small v-else-if="item.default_warehouse_stocks <= 3 && item.non_default_warehouse_stocks == 0" color="red" text-color="white">{{ item.model }}</v-chip>
                                <v-chip small v-else color="black" outlined>{{ item.model }}</v-chip>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="p-0">
                                    <v-simple-table dense dark>
                                        <template v-slot:default>
                                            <tbody>
                                                <span v-for="(warehouse_item_stock, i) in item.warehouse_item_stocks" :key="i">
                                                    <tr>
                                                        <td v-if="i == 0" class="side-borders-white" style="width: 75px"><center>S</center></td>
                                                        <td v-if="i == 0" class="side-borders-white" style="width: 75px"><center>T_SO</center></td>
                                                        <td v-if="i == 0" class="side-borders-white" style="width: 75px"><center>U_SO</center></td>
                                                        <td v-if="i == 0" class="side-borders-white" style="width: 75px"><center>P_SO</center></td>
                                                        <td v-if="i == 0" class="side-borders-white" style="width: 75px"><center>PLL</center></td>
                                                        <td v-if="i == 0" class="side-borders-white" style="width: 75px"><center>IS</center></td>
                                                        <td v-if="i == 0" class="side-borders-white" style="width: 75px"><center>SC</center></td>
                                                    </tr>
                                                    <tr>
                                                        <td v-if="i == 0" class="side-borders-white text-center">{{ item.total_stocks }}</td>
                                                        <td v-if="i == 0" class="side-borders-white text-center">{{ parseInt(item.unserved_so) + parseInt(item.partial_so) }}</td>
                                                        <td v-if="i == 0" class="side-borders-white text-center">{{ item.unserved_so }}</td>
                                                        <td v-if="i == 0" class="side-borders-white text-center"><center>{{ item.partial_so }}</center></td>
                                                        <!-- <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <td v-if="i == 0" class="side-borders-white text-center" v-bind="attrs" v-on="on" @click="redirecttoSalesOrder(item.model)"><center>{{ item.partial_so }}</center></td>
                                                            </template>
                                                            <span v-if="item.pending_items.length > 0">
                                                                <tr v-for="(pending_item,i) in item.pending_items" :key="i" >
                                                                    <template v-if="i < 5">
                                                                        <td>{{pending_item}}</td>
                                                                </template>
                                                                </tr>
                                                            </span>
                                                        </v-tooltip> -->
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <td v-if="i == 0" class="side-borders-white text-center" v-bind="attrs" v-on="on"><center>{{ item.loading_lists }}</center></td>
                                                            </template>
                                                            <span v-if="item.loading_list_containers.length > 0">
                                                                <tr>
                                                                    <td class="px-1">ETD</td>
                                                                    <td class="px-1">C#</td>
                                                                    <td class="px-1">Qty</td>
                                                                </tr>
                                                                <tr v-for="(cont, i) in item.loading_list_containers" :key="i">
                                                                    <td class="px-1 text-center">{{ cont.etd }}</td>
                                                                    <td class="px-1 text-center">{{ cont.container_num }}</td>
                                                                    <td class="px-1 text-center">{{ cont.total }}</td>
                                                                </tr>
                                                            </span>
                                                            <span v-else>No PLLs</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <td v-if="i == 0" class="side-borders-white" v-bind="attrs" v-on="on" @click="redirect(item.model)"><center>{{ item.incoming_stocks }}</center></td>
                                                            </template>
                                                            <span v-if="item.incoming_stocks_containers.length > 0">
                                                                <tr>
                                                                    <td class="px-1">Date</td>
                                                                    <td class="px-1">C#</td>
                                                                    <td class="px-1">Qty</td>
                                                                </tr>
                                                                <tr v-for="(item, i) in item.incoming_stocks_containers" :key="i">
                                                                    <td class="px-1 text-center">{{ item.created_at }}</td>
                                                                    <td class="px-1 text-center">{{ item.container_num }}</td>
                                                                    <td class="px-1 text-center">{{ item.quantity }}</td>
                                                                </tr>
                                                            </span>
                                                            <span v-else>No IS</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <td v-if="i == 0" class="side-borders-white" v-bind="attrs" v-on="on" @click="redirect(item.model)"><center>{{ item.stock_cards }}</center></td>
                                                            </template>
                                                            <span v-if="item.stock_card_packing_lists.length > 0">
                                                                <tr>
                                                                    <td class="px-1">DR#</td>
                                                                    <td class="px-1">Date</td>
                                                                    <td class="px-1">Qty</td>
                                                                </tr>
                                                                <tr v-for="(item, i) in item.stock_card_packing_lists" :key="i">
                                                                    <td class="px-1 text-center">{{ item.packing_list_num }}</td>
                                                                    <td class="px-1 text-center">{{ item.packing_list_date }}</td>
                                                                    <td class="px-1 text-center">{{ item.quantity }}</td>
                                                                </tr>
                                                            </span>
                                                            <span v-else>No SC</span>
                                                        </v-tooltip>
                                                    </tr>
                                                </span>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-container>
            </v-card>
        </v-app>
</v-container>
</template>

<script>






import { saveAs } from "file-saver";
import { mapGetters } from "vuex";
import moment from "moment";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import warehouse from '@/store/modules/Masterdata/warehouse';
const ExcelJS = require("exceljs");

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    data() {
        return {
            expanded: [],
            actionResult: {
                model: "Items",
                action: "",
            },
            loaded: false,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            search: null,
            headers: [],
            totalItems: 0,
            viewItemId: "",
            userAccess: {
                view: false,
            },
            excelReportHeaders: [],
            item_id: [],
            Excelalphabet: [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ],
            loading: {
                text: "",
                bufferValue: 100,
                value: 0,
                dialog: false,
                category_selection: true
            },
            filter: null,
            category_list: [],
            category_id: -1,
        };
    },

    methods: {
        async exportWarehouseInventory() {
            this.loading.dialog = true;
            const workbook = new ExcelJS.Workbook();
            let counter = 0;
            let excelHeaders = [];
            let worksheet = "";
            let payload = {
                category_id: this.category_id,
                items: this.item_id,
                warehouses:this.warehouse_user
            };
            await this.$store.dispatch("exportPendingSoListReport", payload).then(async (response) => {
                for (let x in this.GET_PENDING_SO_LIST_REPORT) {
                    worksheet = workbook.addWorksheet(x);

                    for (let fields_arr of this.GET_PENDING_SO_LIST_REPORT[x]) {
                        if (counter == 1) {
                            for (let fields in fields_arr) {
                                excelHeaders.push({
                                    header: fields.replaceAll("_", " ").replaceAll("dash", "-"),
                                    key: fields,
                                });
                            }
                        }
                        counter++;
                    }

                    worksheet.columns = excelHeaders;
                    this.GET_PENDING_SO_LIST_REPORT[x].forEach((e) => {
                        e.Total_stocks = parseInt(e.Total_stocks);
                        for (let key in e) {
                            if (Number.isInteger(parseInt(e[key]))) {
                                e[key] = parseInt(e[key]);
                            }
                        }
                        worksheet.addRow(e);
                        for (
                            let alphabet_array = 6;
                            alphabet_array <= this.Excelalphabet.length;
                            alphabet_array++
                        ) {
                            worksheet.getColumn(alphabet_array).numFmt = "#,##0";
                        }
                    });
                }

                if (this.item_id.length > 0) {
                    this.excelReportHeaders = excelHeaders;
                    return false
                }

                await workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: "applicationi/xlsx" });
                    saveAs(blob, `WarehouseInventory.xlsx`);
                });

                this.loading.dialog = false;
            });
        },

        async hakotExportReports() {
            this.loading.dialog = true;
            let dates = [moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
            let items = {}
            const workbook = new ExcelJS.Workbook();
            items.worksheet1 = workbook.addWorksheet('Sheet 1')
            items.worksheet2 = workbook.addWorksheet('Sheet 2')
            await this.$store.dispatch('inventoryGet', {
                url: 'operations-report/sheet1',
                dates: dates,
                warehouses:this.warehouse_user
            }).then(response => {
                items.firstResponse = response.data
                items.first = [...new Set(response.data.map(obj => obj.item_id))];
            })

            await this.$store.dispatch('inventoryGet', {
                url: 'operations-report/sheet2',
                dates: dates,
                warehouses:this.warehouse_user
            }).then(response => {
                items.secondResponse = response.data
                items.second = [...new Set(response.data.map(obj => obj.item_id))];
            })

            let item_ids = items.first.concat(items.second)

            const unique = (value, index, self) => {
                return self.indexOf(value) === index
            }

            this.item_id = item_ids.filter(unique)
            await this.exportWarehouseInventory()
            let stocks = this.GET_PENDING_SO_LIST_REPORT

            stocks = stocks.active.concat(stocks.inactive)

            items.firstResponse = items.firstResponse.map(e => {
                Object.assign(e, stocks.find(x => x.Model === e.model))
                return e
            })

            items.secondResponse = items.secondResponse.map(e => {
                Object.assign(e, stocks.find(x => x.Model === e.model))
                return e
            })

            let excelHeaders = []

            items.headers = Object.keys(items.firstResponse[0])

            items.headers.forEach((e, i) => {
                if (e != 'model' && e != 'item_id') {
                    let object = {}
                    if (i > 5 || e.includes('quantity')) {
                        object = { header: this.upperCaseFirstLetter(e.replace("_", " ").replace("dash", "-")), key: e, style: { numFmt: "#,##0" } }
                    } else if (e.includes('created_at') || e.includes('delivery_date')) {
                        object = { header: this.upperCaseFirstLetter(e.replace("_", " ").replace("dash", "-")), key: e, style: { numFmt: 'mm/dd/yyyy' } }
                    } else {
                        object = { header: this.upperCaseFirstLetter(e.replace("_", " ").replace("dash", "-")), key: e, }
                    }
                    excelHeaders.push(object);
                }
            })

            items.worksheet1.columns = excelHeaders

            excelHeaders = []

            items.headers = Object.keys(items.secondResponse[0])

            items.headers.forEach((e, i) => {
                if (e != 'model' && e != 'item_id') {
                    let object = {}
                    if (i > 5 || e.includes('quantity')) {
                        object = { header: this.upperCaseFirstLetter(e.replace("_", " ").replace("dash", "-")), key: e, style: { numFmt: "#,##0" } }
                    } else if (e.includes('packing_list_date')) {
                        object = { header: this.upperCaseFirstLetter(e.replace("_", " ").replace("dash", "-")), key: e, style: { numFmt: 'mm/dd/yyyy' } }
                    } else {
                        object = { header: this.upperCaseFirstLetter(e.replace("_", " ").replace("dash", "-")), key: e, style: { numFmt: "#,##0" } }
                    }
                    excelHeaders.push(object);
                }
            })

            items.worksheet2.columns = excelHeaders

            items.firstResponse.map(e => {

                e.created_at = !e.created_at ?
                '' : this.formatDate(e.created_at)

                // e.delivery_date = !e.delivery_date ?
                // '' : this.formatDate(e.delivery_date)

                items.worksheet1.addRow(e)
                return e
            })

            items.secondResponse.map(e => {
                e.packing_list_date = e.packing_list_date == null ? null : new Date(Date.parse(e.packing_list_date))
                items.worksheet2.addRow(e)
                return e
            })

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "applicationi/xlsx" });
                saveAs(blob, `Warehouse Inventory Report.xlsx`);
                this.loading.dialog = false;
            });
        },
        filterItems() {
            this.getAllItems();
        },
        async getItemCategories() {
            await this.$store.dispatch("getAllItemCategories").then((response) => {
                this.category_list = this.GET_ALL_ITEM_CATEGORIES.map((e) => {
                    return { value: e.id, text: e.name };
                });
                this.category_list.push({ value: -1, text: "All Categories" });
            });
            this.loading.category_selection = false;
        },
        filterTable(filter) {
            this.filter = filter;

            if (this.options.page == 1) {
                this.getAllItems();
            } else {
                this.options.page = 1;
            }
        },
        async loadDetails(item) {
            if (item.value) {
                let payload = {
                    id: item.item.id,
                    from: "sales",
                };
                this.$store.dispatch("getAllWarehouseItemStocks", payload).then((response) => {
                    var data = this.GET_WAREHOUSE_ITEM_STOCK;
                    item.item.warehouse_item_stocks = data.item_stocks;
                    item.item.total_stocks = data.total_stocks;
                    item.item.unserved_so = data.unserved_so;
                    item.item.partial_so = data.partial_so;
                    item.item.pending_items = data.pending_items;
                    item.item.loading_lists = data.loading_lists;
                    item.item.loading_list_containers = data.loading_list_containers;
                    item.item.incoming_stocks = data.incoming_stocks;
                    item.item.incoming_stocks_containers = data.incoming_stocks_containers;
                    item.item.partial_so_items = data.partial_so_items;
                    item.item.stock_cards = data.stock_cards;
                    item.item.stock_card_packing_lists = data.stock_card_packing_lists;
                    this.$forceUpdate();
                });
            }
        },
       async getAllItems(filter = null) {

            this.loaded = false;
            this.items = []
            this.expanded = [];
            this.filter = typeof filter !== 'string' ? filter : null
            await this.getWarehouseUser()
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
                filter: this.filter,
                warehouse_id: this.warehouse_user,
                category_id: this.category_id,
                from: "inventory",
            };
            this.$store.dispatch("getAllItems", payload).then((response) => {
                this.items = this.GET_ALL_ITEMS.data;
                this.loaded = true;
                this.totalItems = this.GET_ALL_ITEMS.total;
                let warehousesCode = this.GET_ALL_ITEMS.warehouses;
                this.headers = [
                    { text: "Model", align: "start", width: "5%", value: "model" },
                    { text: "Name", align: "start", width: "20%", value: "name" },
                    {
                        text: "Category",
                        align: "start",
                        width: "7%",
                        value: "item_category_text",
                    },
                    {
                        text: "UOM",
                        align: "start",
                        width: "3%",
                        value: "item_uom_text",
                        sortable: false,
                    },
                    {
                        text: "Type",
                        align: "start",
                        width: "2%",
                        value: "item_type_text",
                        sortable: false,
                    },
                ];

                warehousesCode.forEach((e) => {
                    let obj = {
                        text: e,
                        sortable: false,
                        value: e,
                        align: "end",
                    };
                    this.headers.push(obj);
                });
            });
        },
        searchInput() {
            this.getAllItems({
                page: 1,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
            });
        },
        checkAccess() {
            this.$store
                .dispatch("checkAccessUser", this.$router.currentRoute.params.id)
                .then((response) => {
                    let val = response;
                    this.userAccess.view = false;
                    if (val != "fail") {
                        val.forEach((e) => {
                            if (e.actions_code == "view") {
                                this.userAccess.view = true;
                            }
                        });
                    }
                });
        },
        async getWarehouseUser(){
            let name = this.USERACCOUNT_LOAD.name

            await this.$store.dispatch("warehouseUser", {
                url: "warehouseUser",
                method: "get",
                search_user: name
            }).then(response=>{
                this.warehouse_user = response.data.data[0].warehouses
            })
            let warehouses = []
            this.warehouse_user.forEach(e => {
                warehouses.push(e.id)
            })

            return this.warehouse_user = warehouses
        }
    },

    async mounted() {
        await this.getItemCategories();
        this.checkAccess();

        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    },
    computed: {
        ...mapGetters([
            "GET_ALL_ITEMS",
            "GET_WAREHOUSE_ITEM_STOCK",
            "GET_PENDING_SO_LIST_REPORT",
            "GET_ALL_ITEM_CATEGORIES",
            "WAREHOUSE_USER",
            "USERACCOUNT_LOAD"
        ]),
    },
    watch: {
        options: {
            handler() {
                this.getAllItems();
            },
            deep: true,
        },
        successUpdate() {
            if (this.successUpdate) {
                this.search = null;
                this.getAllItems();
            }
        },
        GET_ALL_ITEM_CATEGORIES: {
            handler(val) {
                this.loading.category_selection = false;
            },
        },
    },
};
</script>
<style scoped>
.white-bg {
    background-color: white;
    color: black;
}
.side-borders-white {
    border-style: none solid;
    border-color: white;
    width: 20px;
}

th, td {
    padding: 0px 8px 0px 8px;
}
</style>
