<template>
  <v-container fluid>
    <v-card-title>
      <v-row>
        <v-col md="16" class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">VENDOR</h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-container>
      <v-row>
        <template>
          <v-tabs v-model="tab" color="cyan" dark slider-color="cyan"  background-color="#424242"
            class="mx-2" show-arrows>
            <v-tabs-slider color="cyan"></v-tabs-slider>
            <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)" class="ml-3">
              <span> {{ item }}</span>
            </v-tab>
          </v-tabs>
        </template>
      </v-row>
    </v-container>
    <v-container class="ma-0 pa-2">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(table, index) in component_vendor" :key="index">
          <keep-alive>
            <component :is="table.components" :component_vendor="component_vendor" :stat_text="table.status_text" :active="table.is_active"></component>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <dialogs :cp="components" :scrollable="true" :width="dialog_width"></dialogs>
  </v-container>
</template>
<script>
import vendor from './tables/VendorComponent'
import Dialogs from '../../layouts/dialogs/Dialog.vue'
import vendorDialog from '../../layouts/dialogs/Masterdata/VendorComponent.vue'
import vendortypeDialog from '../../layouts/dialogs/Masterdata/VendorTypeComponent.vue'
import vendorclassification from './tables/VendorTypeComponent.vue'
export default {
  components: {
    vendor,
    vendorclassification,
    vendorDialog,
    vendortypeDialog,
    Dialogs,
  },
  data() {
    return {
      components: '',
      vendor: vendorDialog,
      vendorType: vendortypeDialog,

      tab: null,
      items: ['ACTIVE', 'INACTIVE', 'ALL', 'VENDOR TYPE'],
      component_vendor: [
        {
          components: vendor,
          status_text: [1],
          is_active: false,
        },
        {
          components: vendor,
          status_text: [0],
          is_active: true,
        },
        {
          components: vendor,
          status_text: [0, 1],
        },
        {
          components: vendorclassification,
        },
      ],
      dialogType: '',
      dialog_width: ''
    };
  },
  computed: {},
  mounted() {
    this.tabs(0);
    this.$store.dispatch('getCountries')
    this.$store.dispatch('getRegions')
    this.$store.dispatch('getProvince')
    this.$store.dispatch('getCities')
    this.$store.dispatch('getVendortype')
    this.$store.commit('LINK_ACTIVE',window.location.pathname.split("/")[3])
  },
  methods: {
    tabs(key) {
      if (key < 3) {
        this.components = this.vendor;
        this.dialog_width = '60%'
      } else if (key == 3) {
        this.components = this.vendorType;
        this.dialog_width = '40%'
      }
    },
  },
};
</script>
