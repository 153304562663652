<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Vendor Information</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn
              text
              icon
              small
              color="gray"
              class="float-right"
              @click="closeDialog()"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-container fluid class="overflow-auto">
        <v-row class="pl-4">
          <v-radio-group row mandatory v-model="type" :disabled="diable_view" >
            <v-radio label="Business" value="bus"></v-radio>
            <v-radio label="Individual" value="ind"></v-radio>
          </v-radio-group>

        </v-row>
        <v-row class="pa-4">
          <v-col sm="6" v-if="type == 'bus'">
            <v-text-field
              v-model="company_name"
              label="Company Name"
              outlined
              dense
              required
              :disabled="diable_view"
              :rules="errors.company_name"
            >

            </v-text-field>
          </v-col>
          <v-col sm="6">
            <v-autocomplete
              :items="GET_ALL_VENDOR_TYPE"
              outlined
              dense
              v-model="vendorTypeId"
              label="Vendor Type"
              :disabled="diable_view"
              :rules="errors.vendor_type_id"
            >
            </v-autocomplete>
          </v-col>
          <v-container fluid class="pa-0 ma-0">
          <v-card elevation="1" class="pa-2">
            <v-card-subtitle class="p-0">Owner's Name</v-card-subtitle>
            <v-row class="my-2 px-3">
              <v-col md="4">
                <v-text-field
                  label="Lastname"
                  outlined
                  dense
                  v-model="last_name"
                  :rules="errors.last_name"
                  :disabled="diable_view"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field
                  label="Firstname"
                  outlined
                  dense
                  v-model="first_name"
                  :rules="errors.first_name"
                  :disabled="diable_view"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-text-field
                  label="Middle Name"
                  outlined
                  dense
                  v-model="middle_name"
                  :disabled="diable_view"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-row class="pl-4">
          <v-radio-group row mandatory v-model="origin" :disabled="diable_view" @change="origins()">
            <v-radio label="Local" :value=1></v-radio>
            <v-radio label="International" :value=2></v-radio>
          </v-radio-group>
        </v-row>
          <v-card class="bg-ingco-alt py-5 my-2" elevation="0">
            <v-card-subtitle class="p-0">
              <span class="float-right mx-2">
                <v-btn
                  fab
                  dark
                  small
                  color="primary"
                  style="height: 20px; width: 20px"
                  @click="addBillingAddress()"
                  :disabled="diable_view"
                >
                  <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="primary"
                  style="height: 20px; width: 20px"
                  @click="removeBillingAddress()"
                  :disabled="diable_view"
                >
                  <v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
                </v-btn>
              </span>
              <span class="pl-2 text-white">Company Address</span>
            </v-card-subtitle>

            <hr class="m-2 border-white" />
            <v-container fluid>
            <v-row v-for="(company_address, i) in counter" :key="i">
              <v-col sm="3">
                <v-autocomplete
                  :items="GET_ALL_COUNTRIES"
                  background-color="white"
                  dense
                  v-model="country_code[i].code"
                  placeholder="Select Country"
                  :isDisabled="true"
                  @input="updateRegions(i, 'billing')"
                  outlined
                  :disabled="diable_view  || isLocal"
                ></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-autocomplete
                  :items="reg_list[i].list"
                  background-color="white"
                  dense
                  v-model="reg_code[i].code"
                  placeholder="Select Region"
                  @input="updateProvinces(i, 'billing')"
                  outlined
                  :disabled="diable_view"
                ></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-autocomplete
                  :items="province_list[i].list"
                  background-color="white"
                  dense
                  v-model="province_code[i].code"
                  placeholder="Select Province"
                  @input="updateCities(i, 'billing')"
                  outlined
                  :disabled="diable_view"
                ></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-autocomplete
                  :items="city_list[i].list"
                  background-color="white"
                  dense
                  v-model="city_code[i].code"
                  placeholder="Select City / Municipality"
                  @input="selectRegion(i, 'billing')"
                  outlined
                  :disabled="diable_view"
                ></v-autocomplete>
              </v-col>
              <v-col class="col-sm-12 col-md-6">
                <label
                  :for="'company_address_' + i"
                  class="float-left control-label col-form-label"
                  >Address</label
                >
                <input
                  type="text"
                  class="form-control"
                  :id="'company_address_' + i"
                  placeholder="Address"
                  v-model="comp_address[i].address"
                  :disabled="diable_view"
                />
              </v-col>
              <v-col class="col-sm-6 col-md-3" v-if="type == 'bus'">
                <label
                  :for="'shipping_contact_person_' + i"
                  class="float-left control-label col-form-label"
                  >Contact Person</label
                >
                <input
                  type="text"
                  class="form-control"
                  :id="'shipping_contact_person_' + i"
                  placeholder="Contact Person"
                  v-model="contact_person[i].person"
                  :disabled="diable_view"
                />
              </v-col>
              <v-col class="col-sm-6 col-md-3">
                <label
                  :for="'shipping_contact_number_' + i"
                  class="float-left control-label col-form-label"
                  >Contact Number</label
                >
                <input
                  type="text"
                  class="form-control"
                  :id="'shipping_contact_number_' + i"
                  placeholder="Contact Number"
                  v-model="contact_number[i].ct_num"
                  :disabled="diable_view"
                />
              </v-col>
              <hr class="m-2 w-100 border-white" />
            </v-row>
            </v-container>
          </v-card>
          <v-container fluid>
            <v-row>
          <v-col class="py-3">
            <v-text-field
              outlined
              label="Payee"
              dense
              v-model="payee"
              :rules="errors.payee"
              :disabled="diable_view"
            >
            </v-text-field>
          </v-col>
          <v-col class="py-3">
            <v-text-field
              outlined
              label="Account Number"
              dense
              v-model="account_number"
              :rules="errors.account_number"
              :disabled="diable_view"
            >
            </v-text-field>
          </v-col>
          <v-col class="py-3">
            <v-text-field
              v-model="terms"
              outlined
              label="Terms"
              dense
              :rules="errors.terms"
              :disabled="diable_view"
            >
            </v-text-field>
          </v-col>
        </v-row>
        </v-container>
          <v-row>
            <v-col sm="1">
              <v-switch
                v-model="is_active"
                label="Active"
                color="primary"
                input-value="1"
                :disabled="diable_view"
              >
              </v-switch>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <v-card-title class="text-center border">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View'">
            <v-btn
              class="float-center"
              @click="actions == 'Submit' ? addNewVendor() : editVendor()" :disabled="diable_view"
            >
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
export default {
  data() {
    return {
      valid: false,
      diable_view:false,
      terms: null,
      errorAlert: false,
      errorMessage: "",
      editVendorId: "",
      add_edit_customer_dialog: false,
      type: "bus",
      origin:1,
      company_name: "",
      last_name: "",
      first_name: "",
      middle_name: "",
      shipping_addresses: [],
      isLocal:true,
      shipping_address: {
        country_id: "",
        reg_code: "",
        prov_code: "",
        citymun_code: "",
        address: "",
        contact_person: "",
        contact_number: "",
        region_list: [],
        province_list: [],
        city_list: [],
      },

      company_addresses: [],
      company_address: {
        country_id: "",
        reg_code: 0,
        prov_code: 0,
        citymun_code: 0,
        address: "",
        contact_person: "",
        contact_number: "",
        region_list: [],
        province_list: [],
        city_list: [],
      },
      balance_total: "",
      other_contacts: {
        sales: {
          email: "",
          mobile_1: "",
          mobile_2: "",
          tel_1: "",
          tel_2: "",
          tel_3: "",
          fax: "",
          contact_person: "",
          contact_number: "",
        },
        accounting: {
          email: "",
          mobile_1: "",
          mobile_2: "",
          tel_1: "",
          tel_2: "",
          tel_3: "",
          fax: "",
          contact_person: "",
          contact_number: "",
        },
      },
      payee: "",
      account_number: "",
      tel_1: "",
      tel_2: "",
      tel_3: "",
      fax: "",
      mobile_1: "",
      mobile_2: "",
      mobile_3: "",
      email: "",
      salesman_id: "",
      valid_ids: [],
      tin: "",
      sss: "",
      philhealth: "",
      pagibig: "",
      remarks: "",
      is_special: 0,
      is_vip: 0,
      credit_limit: "",
      vat_rate: "",
      brand_discounts: [],
      brand_discount: {
        brand_id: "",
        brand_name: "",
        discounts: [],
      },
      brand_id: "",
      brand_list: [],
      add_brand_discount_dialog: false,
      is_active: 1,
      is_store: 0,
      is_print_no_price: 1,
      approved_by_sales: 1,
      approved_by_acct: 1,
      approved_by_exec: 0,

      countries: [],
      regions: [],
      provinces: [],
      cities: [],
      customer_list: [],
      salesman_list: [],
      max_discounts_count: 999,
      max_shipping_address_count: 5,
      max_company_address_count: 5,
      errors: [],
      action: "",
      actionResult: {
        model: "Customer",
        action: "",
      },
      userAccess: {
        create: false,
      },

      affiliate_customer_id: null,

      classification_list: [],
      classification_id: null,

      sales_target_amount: {
        monthly: 0,
        quarterly: 0,
        semi_annual: 0,
        annual: 0,
      },
      vendorTypeList: [],
      vendorTypeId: null,
      vendorName: {},
      item: [],
      counter: [],
      country_code: [
        {
          code: "608",
        },
      ],
      reg_code: [
        {
          code: "",
        },
      ],
      province_code: [
        {
          code: "",
        },
      ],
      city_code: [
        {
          code: "",
        },
      ],
      comp_address: [
        {
          address: "",
        },
      ],
      contact_person: [
        {
          person: "",
        },
      ],
      contact_number: [
        {
          ct_num: "",
        },
      ],
      comp_id: [],
      reg_list: [],
      province_list: [],
      city_list: [],
      count: 0,
      addCompAddress: [],
      tmp: "true",
      address_trash: [],
      vendor_id: [],
      errors: {
        company_name: [(v) => !!v || "The comapny name is required"],
        vendor_type_id: [(v) => !!v || "The vendor is required"],
        last_name: [(v) => !!v || "The last name is required"],
        first_name: [(v) => !!v || "The first name is required"],
        payee: [(v) => !!v || "The payee is required"],
        account_number: [(v) => !!v || "The account number is required"],
        terms: [(v) => !!v || "The terms is required"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "GET_ALL_COUNTRIES",
      "GET_ALL_REGIONS",
      "GET_ALL_PROVINCE",
      "GET_ALL_CITIES",
      "GET_ALL_VENDOR_TYPE",
      "GET_EDIT_VENDORS",
      "ACTION",
      "DIALOGS",
      "NEW_VENDOR",
    ]),
    actions() {

      if (this.ACTION == "Submit") {
        this.diable_view = false;

        return "Submit";
      } else if(this.ACTION == "Update"){
        this.diable_view = false;
        this.getVendorEdit();
        return "Update";
      }else if(this.ACTION == "View"){
        this.diable_view = true;
        this.getVendorEdit();
        return "View";
      }
    },
  },
  mounted() {
    if (this.counter.length == 0) {
      this.addBillingAddress();
    }

  },
  methods: {
    origins(){
      console.log('test')
      if(this.origin == 1){
        this.isLocal = true
        this.counter.forEach((e,i)=>{
          this.country_code[i].code = '608';
          this.updateRegions(i, 'billing')
        })
      }else{
        this.isLocal = false
      }
    },
    addsubdialogs() {
      this.$store.commit("SUB_DIALOG", true);
    },
    getVendorEdit(){
        this.tmp == "true"
        // this.vendor_id = [];
        // this.counter = [];

        this.count = 0;
        if (this.tmp == "true" && this.DIALOGS) {
          this.customer_id = this.GET_EDIT_VENDORS.item.id;
          this.type = this.GET_EDIT_VENDORS.item.type;
          this.company_name = this.GET_EDIT_VENDORS.item.vendor_name;
          this.last_name = this.GET_EDIT_VENDORS.item.last_name;
          this.first_name = this.GET_EDIT_VENDORS.item.first_name;
          this.middle_name = this.GET_EDIT_VENDORS.item.middle_name;
          this.vendorTypeId = this.GET_EDIT_VENDORS.item.vendor_type_id;
          this.payee = this.GET_EDIT_VENDORS.item.payee;
          this.terms = this.GET_EDIT_VENDORS.item.terms;
          this.account_number = this.GET_EDIT_VENDORS.item.account_number;
          this.GET_EDIT_VENDORS.item.vendor_address.forEach((e, key) => {
            this.vendor_id[key] = e.id;
            this.comp_address[key] = { address: e.address };
            this.contact_number[key] = { ct_num: e.contact_number };
            this.country_code[key] = { code: e.country_code.toString() };
            this.reg_code[key] = { code: e.region_code.toString() };
            this.province_code[key] = { code: e.province_code.toString() };
            this.city_code[key] = { code: e.city_code.toString() };
            this.contact_person[key] = {
              person:
                e.contact_person != null ? e.contact_person.toString() : "",
            };
            this.comp_id[key] = e.id;
            this.reg_list.push({ list: this.GET_ALL_REGIONS });
            this.province_list.push({ list: this.GET_ALL_PROVINCE });
            this.city_list.push({ list: this.GET_ALL_CITIES });
            this.counter[key] = key;
            this.count++;
          });
          if (this.counter.length == 0) {
            this.addBillingAddress();
          }
        }
    },
    closeDialog() {
       this.tmp = "false";
      this.address_trash = [];
      this.$store.commit("DIALOG", false);
      this.$store.commit("EDIT_VENDORS",[])
      if (this.DIALOGS == false) {
        this.counter = [];
      }

    },
    addBillingAddress() {
      if (this.counter.length < this.max_company_address_count) {
        this.tmp = "true";
        let count = 0;
        this.counter.push(this.count++);
        this.counter.forEach((e, key) => {
          count = key;
        });
        this.comp_address[count] = { address: null };
        this.contact_number[count] = { ct_num: null };
        this.country_code[count] = { code: "608" };
        this.reg_code[count] = { code: "" };
        this.province_code[count] = { code: "" };
        this.city_code[count] = { code: "" };
        this.contact_person[count] = { person: "" };
        this.reg_list.push({ list: this.GET_ALL_REGIONS });
        this.province_list.push({ list: this.GET_ALL_PROVINCE });
        this.city_list.push({ list: this.GET_ALL_CITIES });
      }
    },
    removeBillingAddress() {
      this.tmp = "false";
      if (this.counter.length > 0) {
        const deleteaddress = this.counter.pop();
        this.address_trash.push(this.vendor_id[deleteaddress]);
      }
    },
    updateRegions(key, address_type) {
      if (address_type == "billing") {
        if (this.country_code[key].code == "608") {
          this.reg_code[key].code = this.GET_ALL_REGIONS.regCode;
          this.province_code[key].code = this.GET_ALL_PROVINCE.provCode;
          this.city_code[key].code = this.GET_ALL_CITIES.citymunCode;
          this.reg_list[key].list = this.GET_ALL_REGIONS;
          this.province_list[key].list = this.GET_ALL_PROVINCE;
          this.city_list[key].list = this.GET_ALL_CITIES;
        } else {
          this.reg_code[key].code = "";
          this.province_code[key].code = "";
          this.city_code[key].code = "";
          this.reg_list[key].list = [];
          this.province_list[key].list = [];
          this.city_list[key].list = [];
        }
      }
    },
    updateProvinces(key, address_type) {
      if (address_type == "billing") {
        if (this.reg_code[key].code != null && this.reg_code[key].code != "") {
          const regCode = this.reg_code[key].code;
          this.province_list[key].list = this.GET_ALL_PROVINCE.filter(function (
            province
          ) {
            return province.regCode == regCode;
          });

          if (this.province_code[key].code != "") {
            const selectedProvCode = this.province_code[key].code;
            var result = this.province_list[key].list.find((province) => {
              return province.value == selectedProvCode;
            });

            if (result == null) {
              this.province_code[key].code = "";
              this.updateCities(key, "billing");
            }
          } else if (this.city_code[key].code != "") {
            const selectedCityMunCode = this.city_code[key].code;
            var result = this.city_list[key].list.find((city) => {
              return city.value == selectedCityMunCode;
            });

            if (result != null) {
              this.province_code[key].code = result.provCode;
              this.updateCities(key, "billing");
            }
          }
        } else {
          this.province_list[key].list = "";
          this.province_code[key].code = "";
          this.updateCities(key, "billing");
        }
      }
    },
    updateCities(key, address_type) {
      if (address_type == "billing") {
        if (
          this.province_code[key].code != null &&
          this.province_code[key].code != ""
        ) {
          const provCode = this.province_code[key].code;
          this.city_list[key].list = this.GET_ALL_CITIES.filter(function (
            city
          ) {
            return city.provCode == provCode;
          });
          const selectedCityCode = this.city_code[key].code;
          const result = this.city_list[key].list.find((city) => {
            return city.value == selectedCityCode;
          });
          if (result == null) {
            this.city_code[key].code = "";
          }
        } else {
          this.city_list[key].list = [];
          this.city_code[key].code = "";
        }
      }

      this.selectRegion(key, address_type);
    },
    selectRegion(key, address_type) {
      if (address_type == "billing") {
        if (
          this.city_code[key].code != null &&
          this.city_code[key].code != ""
        ) {
          const citymun_code = this.city_code[key].code;
          const selectedCity = this.GET_ALL_CITIES.filter(function (city) {
            return city.value == citymun_code;
          });
          if (selectedCity.length > 0) {
            this.reg_code[key].code = selectedCity[0].regDesc;
            this.updateProvinces(key, "billing");
          }
        } else if (
          this.province_code[key].code != null &&
          this.province_code[key].code != ""
        ) {
          const prov_code = this.province_code[key].code;
          const selectedProv = this.GET_ALL_PROVINCE.filter(function (prov) {
            return prov.value == prov_code;
          });
          if (selectedProv.length > 0) {
            this.reg_code[key].code = selectedProv[0].regCode;
          }
        }
      }
    },
    addNewVendor() {
      this.$refs.form.validate();
      const cpname_swal_text = this.company_name ? "" : "Company Name";
      const vendor_swal_text = this.vendorTypeId ? "" : "Vendor";
      const fname_swal_text = this.first_name ? "" : "First Name";
      const lname_swal_text = this.last_name ? "" : "Last Name";
      const payee_swal_text = this.payee ? "" : "Payee";
      const acctn_swal_text = this.account_number ? "" : "Account Number";
      const terms_swal_text = this.terms ? "" : "Terms";
      const array_text = [
        cpname_swal_text,
        vendor_swal_text,
        fname_swal_text,
        lname_swal_text,
        payee_swal_text,
        acctn_swal_text,
        terms_swal_text,
      ];
      const filtered = array_text.filter(function (el) {
        return el.replace(",", "");
      });
      if (
        this.company_name &&
        this.vendorTypeId &&
        this.first_name &&
        this.last_name &&
        this.payee &&
        this.account_number &&
        this.terms
      ) {
        const vendorData = {
          company_name: this.company_name,
          type: this.type,
          terms: this.terms,
          first_name: this.first_name.toUpperCase(),
          middle_name: this.middle_name.toUpperCase(),
          last_name: this.last_name.toUpperCase(),
          payee: this.payee,
          account_number: this.account_number,
          vendor_type: this.vendorTypeId,
          account_number: this.account_number,
          is_active: this.is_active,
          balance_total: this.balance_total,
          origin:this.origin
        };
        this.$store.dispatch("addVendor", vendorData).then((response) => {
          const vendor_address_Data = {
            customer_id: response,
            address_count: this.counter,
            vendor_id: this.vendor_id,
            countries: this.country_code,
            regions: this.reg_code,
            province: this.province_code,
            cities: this.city_code,
            address: this.comp_address,
            contact_number: this.contact_number,
            contact_person: this.contact_person,
            trash: this.address_trash,
          };
          this.$store.dispatch("addVendorAddress", vendor_address_Data);
          this.$store.commit("NEW_VENDOR", true);
          this.reset()
          this.closeDialog()
          this.tmp = "true";
        });
      } else {
        swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    },
    editVendor() {
      this.$refs.form.validate();
      const cpname_swal_text = this.company_name ? "" : "Company Name";
      const vendor_swal_text = this.vendorTypeId ? "" : "Vendor";
      const fname_swal_text = this.first_name ? "" : "First Name";
      const lname_swal_text = this.last_name ? "" : "Last Name";
      const payee_swal_text = this.payee ? "" : "Payee";
      const acctn_swal_text = this.account_number ? "" : "Account Number";
      const terms_swal_text = this.terms ? "" : "Terms";
      const array_text = [
        cpname_swal_text,
        vendor_swal_text,
        fname_swal_text,
        lname_swal_text,
        payee_swal_text,
        acctn_swal_text,
        terms_swal_text,
      ];
      const filtered = array_text.filter(function (el) {
        return el.replace(",", "");
      });
      if (
        this.company_name &&
        this.vendorTypeId &&
        this.first_name &&
        this.last_name &&
        this.payee &&
        this.account_number &&
        this.terms
      ) {
        const vendorData = {
          customer_id: this.customer_id,
          company_name: this.company_name,
          type: this.type,
          terms: this.terms,
          first_name: this.first_name.toUpperCase(),
          middle_name: this.middle_name.toUpperCase(),
          last_name: this.last_name.toUpperCase(),
          payee: this.payee,
          account_number: this.account_number,
          vendor_type: this.vendorTypeId,
          account_number: this.account_number,
          is_active: this.is_active,
          balance_total: this.balance_total,
          origin:this.origin
        };
        this.$store.dispatch("editVendor", vendorData).then(response=>{
             const vendor_address_Data = {
          customer_id: this.customer_id,
          vendor_id: this.vendor_id,
          address_count: this.counter,
          countries: this.country_code,
          regions: this.reg_code,
          province: this.province_code,
          cities: this.city_code,
          address: this.comp_address,
          contact_number: this.contact_number,
          contact_person: this.contact_person,
          trash: this.address_trash,
        };
        this.$store.dispatch("addVendorAddress", vendor_address_Data);
        this.$store.commit("NEW_VENDOR", true);
         this.tmp = "true";
         this.vendor_id = [];
        this.counter = [];
        });

      } else {
        swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    },
    reset(){
      this.errorAlert = false;
            this.errors = [];
            this.type = 0;
            this.origin = 1;
            this.company_name = "";
            this.last_name = "";
            this.first_name = "";
            this.middle_name = "";
            this.payee = "";
            this.account_number = "";
            this.remarks = "";
            this.is_special = 0;
            this.is_vip = 0;
            this.credit_limit = "";
            this.vat_rate = "";
            this.is_active = 1;
            this.approved_by_sales = 1;
            this.approved_by_acct = 1;
            this.approved_by_exec = 0;
            this.brand_id = "";
            this.brand_discounts = [];
            this.vendorTypeId = null;
            this.balance_total = "";
            this.terms = "";
            if (this.counter.length == 0) {
              this.addBillingAddress();
            }
    }
  },
  watch: {
    'origin':{
      handler(){
        if(this.ACTION != 'Update'){
          this.origins();
        }
      },
      deep:true
    },
    DIALOGS: {
      handler(val) {
        if (val) {
          this.$store.dispatch('getCountries')
          this.$store.dispatch('getRegions')
          this.$store.dispatch('getProvince')
          this.$store.dispatch('getCities')
          this.$store.dispatch('getVendortype')
          if (this.tmp == "false") {
            this.errorAlert = false;
            this.errors = [];
            this.type = 0;
            this.origin = 1;
            this.company_name = "";
            this.last_name = "";
            this.first_name = "";
            this.middle_name = "";
            this.payee = "";
            this.account_number = "";
            this.remarks = "";
            this.is_special = 0;
            this.is_vip = 0;
            this.credit_limit = "";
            this.vat_rate = "";
            this.is_active = 1;
            this.approved_by_sales = 1;
            this.approved_by_acct = 1;
            this.approved_by_exec = 0;
            this.brand_id = "";
            this.brand_discounts = [];
            this.vendorTypeId = null;
            this.balance_total = "";
            this.terms = "";
            if (this.counter.length == 0) {
              this.addBillingAddress();
            }
          }
        }
      },
    },
  },
};
</script>

<style></style>
