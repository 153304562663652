<template>
    <div class="w-100 p-1">
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>

        <v-app id="my_vue_app">
            <v-card v-show="loaded">
                <v-card-title>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="3">
                            <v-text-field
                                v-model="search"
                                label="Search"
                                single-line
                                hide-details
                                append-icon="mdi-magnify"
                                @keydown.enter="searchInput()"
                                @click:append="searchInput()"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-container fluid>
                    <v-data-table
                        :headers="headers"
                        :items="Bundles"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalBundles"
                        :items-per-page="10"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions
                        }"
                        class="w-100"
                    >

                    <template v-slot:[`item.customer_name`]="{ item }">
                        <span style="font-size:10px !important;">
                            {{ item.customer_name  }}
                        </span>
                    </template>

                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.created_at | formatDate }}
                        </span>
                    </template>

                    <template v-slot:[`item.approve_date`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.approve_date | formatDate }}
                        </span>
                    </template>

                    <template v-slot:[`item.acc_approved_date`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.acc_approved_date | formatDate }}
                        </span>
                    </template>

                    <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status_text=='saved'">{{ item.status_text }}</span>
                        <span class="badge badge-for-approval" v-if="item.status_text=='for approval'">{{ item.status_text }}</span>
                        <span class="badge for-accounting" v-if="item.status_text=='for accounting'">{{ item.status_text }}</span>
                        <span class="badge badge-primary" v-else-if="item.status_text=='approved'">{{ item.status_text }}</span>
                        <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                        <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                        <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                        <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                        <span class="badge badge-purple" v-else-if="item.status_text=='converted'">{{ item.status_text }}</span>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="editBundle(item.id)">mdi-square-edit-outline</v-icon>
                            </v-btn>
                            <v-btn text icon color="red">
                                <v-icon class="btn-action" small @click="deleteBundle(item.id)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </template>

                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    props: [
        'itemBundlesActionResult'
    ],
    data () {
        return {
            actionResult : {
                model : 'Bundles',
                action : ''
            },
            loaded: false,
            totalBundles: 0,
            options: {},
            Bundles: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            headers: [
                { text: 'Model', align: 'left', value: 'model', },
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewBundleId: '',
        }
    },
    computed:{
        ...mapGetters([
            'GET_ALL_BUNDLES',
            'GET_DESTROY_ITEM_BUNDLE'
        ])
    },
    methods:{
        getAllBundles(){
            this.loaded = false
            let payload = {
                page:           this.options.page,
                itemsPerPage:   this.options.itemsPerPage,
                sortBy:         this.options.sortBy,
                sortDesc:       this.options.sortDesc,
                search:         this.search,
            }
            this.$store.dispatch('getAllBundles',payload).then(response => {
                this.Bundles        = this.GET_ALL_BUNDLES.data
                this.loaded         = true
                this.totalBundles   = this.GET_ALL_BUNDLES.total
            })
        },
        viewBundle(id){
            this.viewBundleId = id
        },
        editBundle(id){
            this.$emit('showEditModal', id);
        },
        deleteBundle(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('destroyItemBundle',id).then(response => {
                        let data = this.GET_DESTROY_ITEM_BUNDLE;
                        this.getAllBundles()
                        swal.fire('',data.msg, "success");
                    }).catch(error => {
                        swal.fire('','An Error Occured', "success");

                    })
                }
            });
        },
        closeView(action = false){
            this.viewBundleId = 0
        },
        searchInput(){
            this.getAllBundles({ query: this.search });
        },
        addKeyListener(){
            window.addEventListener("keyup", function(e) {
                if(e.keyCode == 13){
                    this.searchInput();
                    $("input").blur();

                }
            }.bind(this));
        },
    },
    watch: {
        itemBundlesActionResult: function(val){
            if(this.itemBundlesActionResult=='success'){
                this.getAllBundles()
            }
        },
        options: {
            handler () {
                this.getAllBundles()
            },
            deep: true,
        },
    }
};
</script>
