<template>
  <v-container fluid>
    <v-card-title>
      <v-container fluid class="pa-0 ma-0">
        <v-row class="mb-1">
            <div class="row" v-if="userAccess.create">
              <div class="col-12 d-flex no-block align-items-center">
                  <div class="btn-toolbar">
                      <v-btn @click="adddialogs()">
                          <v-icon>mdi-plus</v-icon>
                          Add
                      </v-btn>
                  </div>
              </div>
            </div>
        </v-row>
    </v-container>
    </v-card-title>
    <v-dialog v-model="dialog" max-width="20%" persistent>
      <v-card>
          <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
            <span class="text-h5">Vehicle Type</span>
            <v-row class="m-0">
              <v-col cols="pull-right-10 p-2">
                <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
        <v-container fluid class="py-5">
          <v-row>
            <v-col sm="12" col="12">
              <v-text-field
                outlined
                dense
                v-model="form.name"
                label="Vehicle Type Name"
                height="10"
                required
                :disabled="disable_view"
              >
              </v-text-field>
            </v-col>
            <v-col sm="12" col="12">
              <v-text-field outlined dense v-model="form.code" label="Code" required
                :disabled="disable_view"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View'">
            <v-btn class="float-center" @click="actions == 'Add' ? addVehiclesType() : UpdateVehiclesType()"  :disabled="disable_view">
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      </v-card>

    </v-dialog>
    <vehiclesTypeTable @showEditDialog="showEditDialog" ref="tableVehicleType"></vehiclesTypeTable>
  </v-container>
</template>
<script>
import vehiclesTypeTable from './tables/VehicleTypeComponent.vue'
import vehiclesTypeDialog from '../../layouts/dialogs/Masterdata/VehiclesTypeComponent.vue'
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
export default {
  components: {
    vehiclesTypeTable,
    vehiclesTypeDialog,
  },
  data() {
    return {
      dialog:false,
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      form:{
        id:null,
        name:'',
        code:''
      },
      disable_view:false,
      actions:null
    }
  },
  mounted() {
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    this.$store.dispatch('getAllVehicleTypes')
    this.$store.commit('LINK_ACTIVE',window.location.pathname.split("/")[3])
  },
  methods: {
    // adddialogs() {
    //   this.$store.commit('DIALOG', true);
    //   this.$store.commit('ACTION', 'Submit')
    // }
    adddialogs(){
      this.dialog = true
      this.actions = 'Add'
    },
    showEditDialog(item,action){
      if(action == 'View') this.disable_view = true
      else this.disable_view = false
      this.dialog = true
      this.form = item
      this.actions = action
    },
    closeDialog(){
      this.dialog = false
      this.actions = null
      this.resetFields()
    },
    addVehiclesType(){
      if (this.form.code && this.form.name) {
        this.$store.dispatch('addNewVehiclesType', this.form).then(response=>{
          Swal.fire({
            text: `Save Successfully`,
            icon: 'success',
            allowOutsideClick: false,
          });
          this.closeDialog();
          this.$refs.tableVehicleType.getAll();
        });
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    UpdateVehiclesType(){
      if (this.form.code && this.form.name) {
        this.$store.dispatch('UpdateVehiclesType', {
          id: this.form.id,
          payload: this.form,
        }).then(response=>{
          Swal.fire({
            text: `Update Successfully`,
            icon: 'success',
            allowOutsideClick: false,
          });
          this.closeDialog();
        });
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    resetFields() {
      this.form = {
          name: null,
          code: null,
        }
    },
  },
  computed: {
    ...mapGetters(['DIALOGS', 'USER_ACCESS']),
  },
  watch: {
    USER_ACCESS: {
      handler(val) {
         this.userAccess.create = false;
         if(val != "fail"){
        val.forEach(e => {
          if (e.actions_code == 'create') {
            this.userAccess.create = true;
          }
        });
         }
      },
    },
  },
}
</script>
