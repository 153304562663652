import StatementOfAccount from "@/views/main/modules/Accounting/StatementOfAccount/StatementOfAccountsGroupComponent"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/statement-of-account/:id",
    name: "acctstatementofaccount",
    component: StatementOfAccount,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Statement Of Accounts',
    }
}]

export default routes;
