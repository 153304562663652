<template>
  <div>
    <v-app id="view_client_modal">
      <v-dialog v-model="adjustment_view_dialog" persistent max-width="80%" scrollable>
        <v-card>
          <v-row class="m-0">
            <v-col>
              <v-card-title>
                <span class="headline">Stock Adjustment Details</span>
              </v-card-title>
            </v-col>
            <v-col cols="pull-right-10 p-2">
                <v-btn
                  text
                  icon
                  color="gray"
                  class="float-right"
                  @click="clearAdjustment();$emit('closeView');"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row class="m-0">
              <v-container fluid>
                <v-row>
                  <div class="col-sm-12 col-md-8 text-left text-dark">
                    <div class="row ml-2">
                      <div class="col-sm-12 col-md-4 p-0">Reference #: {{ reference_num }}</div>
                      <div class="col-sm-12 col-md-4 p-0">Warehouse: {{ warehouse_name }}</div>
                      <div class="col-sm-12 col-md-4 p-0">Requestor Name: {{ requestor_name }}</div>
                    </div>
                    <div class="row ml-2">
                      <div class="col-sm-12 col-md-4 p-0">Remarks: {{ remarks }}</div>
                      <div class="col-sm-12 col-md-4 p-0">Created By: {{ created_by_name }}</div>
                      <div class="col-sm-12 col-md-4 p-0">Reference IIF#: {{ iif_ref_no }}</div>
                    </div>
                  </div>
                </v-row>
                <v-row>
                    <div class="col-sm-12 col-md-12 p-0 text-right">
                      <BaseFilesViewerVue :stockAdjustmentId="viewAdjustmentId" :delete="status == 0"/>
                    </div>
                </v-row>
              </v-container>
            </v-row>

            <v-data-table
              :headers="adjustmentItemHeaders"
              :items="adjustmentItems"
              :items-per-page="500"
              hide-default-footer
              class="w-100"
            >
              <template v-slot:item.qty_type="{ item }" class="text-align-center">
                <span class="badge bg-danger" v-if="item.qty_type == 0">SUBTRACT</span>
                <span class="badge bg-success text-white" v-else-if="item.qty_type == 1">ADD</span>
              </template>
            </v-data-table>

            <div class="border-top">
              <div class="card-body py-4 d-flex justify-content-center">
                <span class="m-1" v-if="status == 3 && userAccess.confirm"><v-btn @click="updateAdjustmentStatus(1,'confirmed')" >Confirm</v-btn></span>
                <span class="m-1" v-if="status == 0 && userAccess.approve"><v-btn @click="updateAdjustmentStatus(2,'for_approval')" >Warehouse Approve</v-btn></span>
                <span class="m-1" v-if="status == 2 && userAccess.exec_approve"><v-btn @click="updateAdjustmentStatus(3,'for_exec_approval')" >Exec Approve</v-btn></span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
import BaseFilesViewerVue from '@/views/main/modules/Accounting/Base/BaseFilesViewer.vue'
export default {
  components:{
    BaseFilesViewerVue
  },
  props: ['viewAdjustmentId','resetFilter'],
  mounted() {
    // this.checkAccess('IT', 'stock_adjustment', 'confirm')
  },
  data() {
    return {
      reference_num: '',
      warehouse_name: '',
      requestor_name: '',
      created_by_name: '',
      iif_ref_no: '',
      remarks: '',
      status: '',

      adjustmentItems: [],
      adjustmentItemHeaders: [
        { text: 'Model', align: 'start', value: 'model' },
        { text: 'Name', align: 'start', value: 'item_name' },
        { text: 'UOM', align: 'start', value: 'uom' },
        { text: 'Type', align: 'start', value: 'type' },
        { text: 'Category', align: 'start', value: 'category' },
        { text: 'Transact Type', align: 'start', value: 'qty_type' },
        { text: 'Quantity', align: 'start', value: 'quantity' },
        // { text: 'Stocks', align: 'left', value: 'remain_stocks', },
      ],

      userAccess: {
        confirm: false,
      },

      loaded: false,
      adjustment_view_dialog: false,
    };
  },
  mounted() {
    // this.$store.dispatch('updateStockAdjustment');
    this.checkAccess()
  },
  computed: {
    ...mapGetters(['UPDATE_STOCK_ADJUSTMENT', 'GET_ALL_STOCK']),
  },
  methods: {
    checkAccess(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
            let val = response;
            this.userAccess.confirm = false;
            this.userAccess.exec_approve = false;
            if(val != "fail"){
            val.forEach((e) => {
                if (e.actions_code == "confirm"){
                    this.userAccess.confirm = true;
                }
                else if(e.actions_code == "approve"){
                  this.userAccess.approve = true;
                }
                else if(e.actions_code == "exec_approve"){
                  this.userAccess.exec_approve = true;
                }
            });
            }
        })
    },
    clearAdjustment() {
      this.$store.commit('RESET_TABLE', true)
      this.adjustment_view_dialog = false;
    },
    resetFields() {
      (this.reference_num = ''),
      (this.warehouse_name = ''),
      (this.requestor_name = ''),
      (this.created_by_name = ''),
      (this.remarks = ''),
      (this.adjustmentItems = []);
      (this.iif_ref_no = '');
    },
    updateAdjustmentStatus(newStatus, status) {
      this.errors = [];
      swal
        .fire({
          title: '',
          text: 'Are you sure you want to '+ status +' Stock Adjustment?',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: 'Confirm',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: 'grey',
          reverseButtons: true,
        })

        .then(result => {
          if (result.isConfirmed) {
            const payload = {
              status: newStatus,
              adjustment_id: this.viewAdjustmentId
            };
            this.$store.dispatch('updateStockAdjustment', payload).then(response => {
              const data = response.data
              if (data.error) {
                swal.fire('', data.error, 'warning');
                return false
              }
              this.resetFields();
              swal.fire('', data.msg, 'success')
              this.adjustment_view_dialog = false
              this.$store.commit('DIALOG', false);
              this.$store.dispatch('getAllStockAdjustment');
              this.$emit('closeView', 'updateStatusSuccess')
            });
          }
        });
    },
  },
  watch: {
    viewAdjustmentId: function (val) {
      this.errors = [];

      if (val != -1) {
        const payload = {
          item_id: this.viewAdjustmentId
        }
        if (this.viewAdjustmentId != null && this.viewAdjustmentId > 0) {
          this.$store
            .dispatch('getStockAdjustment', payload)
            .then(response => {
              let data = response.data[0]
              this.adjustment_view_dialog = true;
              this.reference_num = data.reference_num;
              this.requestor_name = data.requestor_name;
              this.created_by_name = data.created_by_name;
              this.warehouse_name = data.warehouse_name;
              this.remarks = data.remarks;
              this.status = data.status;
              this.adjustmentItems = data.adjustmentItems;
              this.iif_ref_no = data.iif_ref_no;
            })
            .catch(error => {});
        }
      }
    },

  },
};
</script>

<style></style>
