<template>
  <v-container fluid>
    <v-card-title class="pa-3">
        <v-row>
          <!-- <buttons
            v-if="userAccess.create"
            :action="buttons.actions.create"
            :button_icon="buttons.icons.create"
            :color="buttons.colors.create"
            @click="adddialogs"
          >
          </buttons> -->

          <div class="row mb-1 mt-1" v-if="userAccess.create && GET_STOCK_MENU != 'saved' && GET_STOCK_MENU != 'for_approval' && GET_STOCK_MENU != 'confirmed' && GET_STOCK_MENU != 'for_exec_approval'">
            <div class="col-12 d-flex no-block align-items-center">
              <div class="btn-toolbar">
                <v-btn data-modal-id="addContainerModal" @click="adddialogs()" >
                  <v-icon>mdi-plus</v-icon>Add
                </v-btn>
              </div>
            </div>
          </div>
        </v-row>
    </v-card-title>
    <usersTable :selectedAction="selectedAction" ref="usersTable"></usersTable>
    <dialogs :selectedAction="selectedAction" :cp="components" :scrollable="true" :retain-focus="false" :width="'75%'"></dialogs>
  </v-container>
</template>
<script>
import { computed } from "vue";
import usersTable from "./tables/StockAdjustmentTable.vue";
import batchAddtionDialog from "../../layouts/dialogs/IT/StockAdjustmentDialog.vue";
// import buttons from '../../layouts/Buttons/DialogButton.vue';
// import buttons_components from '../../../../assets/js/components-js.js';
import Dialogs from "../../layouts/dialogs/Dialog.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  components: {
    // buttons,
    usersTable,
    // buttons_components,
    Dialogs,
  },
  data() {
    return {
      color: "orange",
      action: "add",
      dialog_text: false,
      dialog_icon: "mdi-plus",
      components: batchAddtionDialog,
      // buttons:buttons_components.buttons,
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      selectedAction: "",
      resetFilter:null,
    };
  },

  mounted() {
    this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    this.$store.dispatch("getAllWarehouses");
    this.$store.dispatch("getAllUsersSelect");
  },
  methods: {
    adddialogs() {
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "Submit");
      this.selectedAction = "Add";
    },

  },
  computed: {
    ...mapGetters(["DIALOGS", "USER_ACCESS", "USERACCOUNT_LOAD", 'GET_STOCK_MENU']),
  },
  watch: {
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          val.forEach((e) => {
            if (e.actions_code == "create") {
              this.userAccess.create = true;
            }
            if (e.actions_code == "edit") {
              this.userAccess.edit = true;
            }
            if (e.actions_code == "view") {
              this.userAccess.view = true;
            }
            if (e.actions_code == "delete") {
              this.userAccess.delete = true;
            }
          });
        }
      },
    },
  },
};
</script>
