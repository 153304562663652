import Payments from "@/views/main/modules/Accounting/Payments/PaymentsView.vue"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/payments/:id",
    name: "acctpayments",
    component: Payments,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Payments',
    }
}]

export default routes;
