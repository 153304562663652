<template>
    <v-container fluid>
        <ComponentHeader :button="true" :title="report.title" :type="report.type"></ComponentHeader>
        <v-row dense no-gutters>
            <v-col>
                <v-card>
                    <v-card-text class="bg-bluish-gray">
                        <v-row class="px-1">
                            <v-col cols="12" sm="12" md="9" lg="10" xl="10">
                                <v-row dense>
                                    <v-col cols="12" sm="12" md="9" lg="3" xl="3">
                                        <v-autocomplete
                                            v-model="filters.customer_id"
                                            :items="dropdowns.customers.list"
                                            :loading="loading.customers"
                                            :disabled="loading.customers"
                                            background-color="white"
                                            placeholder="Customer"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            auto-select-first
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="2" xl="2">
                                        <v-autocomplete
                                            v-model="filters.salesman_id"
                                            :items="dropdowns.salesman.list"
                                            :loading="loading.salesman"
                                            :disabled="loading.salesman"
                                            background-color="white"
                                            placeholder="Salesman"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            auto-select-first
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" sm="4" md="3" lg="2" xl="2">
                                        <v-autocomplete
                                            v-model="filters.group_by"
                                            :items="dropdowns.group_by.list"
                                            background-color="white"
                                            label="Group By"
                                            dense
                                            outlined
                                            hide-details
                                            auto-select-first
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="2" lg="2" xl="2">
                                        <v-autocomplete
                                            v-model="filters.sort_by"
                                            :items="dropdowns.sort_by.list"
                                            background-color="white"
                                            label="Sort By"
                                            dense
                                            outlined
                                            hide-details
                                            auto-select-first
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="3" lg="2" xl="2">
                                        <v-autocomplete
                                            v-model="filters.data_type"
                                            :items="dropdowns.data_type.list"
                                            background-color="white"
                                            label="Data Type"
                                            dense
                                            outlined
                                            hide-details
                                            auto-select-first
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                                        <v-text-field
                                            v-model="filters.data_limit"
                                            background-color="white"
                                            label="Limit"
                                            dense
                                            outlined
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="2" lg="2" xl="2">
                                        <v-checkbox
                                            v-model="filters.exclude_sp"
                                            :true-value="1"
                                            :false-value="1"
                                            label="Exclude SP"
                                            color="white"
                                            dense
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="6" sm="6" md="3" lg="2" xl="2">
                                        <v-menu
                                            v-model="toggles.date_from"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="filters.date_from"
                                                    v-on="on"
                                                    append-icon="mdi-calendar-clock"
                                                    label="Date From"
                                                    readonly
                                                    hide-details
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.date_from" @input="toggles.date_from = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="3" lg="2" xl="2">
                                        <v-menu
                                            v-model="toggles.date_to"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="filters.date_to"
                                                    v-on="on"
                                                    append-icon="mdi-calendar-clock"
                                                    label="Date To"
                                                    readonly
                                                    hide-details
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="filters.date_to" @input="toggles.date_to = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="3" lg="2" xl="2">
                                <v-row>
                                    <v-col :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-center' : 'justify-content-end' )">
                                        <v-btn :loading="loading.reset" small @click="resetFields()">Clear</v-btn>
                                        <v-btn class="mx-1" small @click="getData(1)">Export</v-btn>
                                        <v-btn small @click="getData(0)">Filter</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense no-gutters>
            <v-col>
                <v-card>
                    <v-card-text>
                        <ReportsCardsComponent :totals="totals" :cards="cards" style="width:100%;"></ReportsCardsComponent>
                        <v-expansion-panels v-model="expansion_panels.items.selected" multiple>
                            <v-expansion-panel v-for="(item) in expansion_panels.items.list" :key="item.id">
                                <v-expansion-panel-header>
                                    <v-row>
                                        <v-col cols="1">
                                            <strong>{{ item.name }}</strong>
                                        </v-col>
                                        <v-col cols="1">
                                            {{ 'Count: ' + item.item_count }}
                                        </v-col>
                                        <v-col cols="1">
                                            <span class="w-30 d-flex">
                                                <strong>
                                                    {{ 'Total ' + data_type_label + ': ' }}
                                                </strong>
                                            </span>
                                        </v-col>
                                        <v-col cols="1" class="d-flex justify-content-end">
                                            <span style="width: 20%; flex: 1; text-align: right; white-space: nowrap;">
                                                {{ getTwoDecimals(item.total, data_type_decimal) }}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-data-table
                                        :headers="datatable.headers"
                                        :items="item.data"
                                        :options.sync="item.options"
                                        :server-items-length="item.count"
                                        :loading="loading.datatable"
                                        class="w-100"
                                        dense
                                        disable-sort
                                        @update:page="getData(0)"
                                        @update:items-per-page="getData(0)"
                                    >
                                        <template v-slot:item.base64img="{ item }">
                                            <v-img class="img-thumbnail ma-auto" width="50" height="50" :src="item.base64img" eager contain></v-img>
                                        </template>
                                        <template v-slot:item.selling_price="{ item }">
                                            <span class="text-nowrap">
                                                {{ item.selling_price | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:item.total_stocks="{ item }">
                                            <span class="text-nowrap">
                                                {{ thousandSeprator(item.total_stocks) }}
                                            </span>
                                        </template>
                                        <template v-slot:item.total_value="{ item }">
                                            <span class="text-nowrap" v-if="filters.data_type == 'quantity'">
                                                {{ thousandSeprator(item.total_value) }}
                                            </span>
                                            <span class="text-nowrap" v-else-if="filters.data_type == 'total_amount'">
                                                {{ getTwoDecimals(item.total_value) }}
                                            </span>
                                        </template>
                                        <template v-slot:item.action="{ item }">
                                            <v-btn text icon color="orange">
                                                <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                    <ItemsViewComponent
                                        :viewItemId="datatable.items.selected"
                                        :viewSpecialPrice="false"
                                        :viewCost="false"
                                        @closeView="closeView"
                                    ></ItemsViewComponent>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import ReportsCardsComponent from '@/views/main/layouts/Reports/ReportsCardsComponent.vue'
import ItemsViewComponent from '@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import swal from 'sweetalert2'
const ExcelJS = require("exceljs")
const FileSaver = require("file-saver")

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
        ReportBodyComponent,
        ItemsViewComponent,
        ReportsCardsComponent,
    },
    data: () => ({
        report: {
            title: 'Top/Poor Selling Items',
            type: 'Sales',
            filename: 'Top Poor Selling Items',
        },
        toggles: {
            loaded: false,
            date_from: false,
            date_to: false,
        },
        loading: {
            customers: true,
            salesman: true,
            datatable: false,
            line_chart: false,
        },
        defaults: {
            group_by: 'category_id',
            sort_by: 'desc',
            data_type: 'total_amount',
            data_limit: 50,
            exclude_sp: 1,
            customer_id: null,
            salesman_id: null,
            date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
            date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
            for_export: 0,
        },
        filters: {},
        dropdowns: {
            group_by: {
                list: [
                    { value: 'category_id', text: 'Item Category' },
                    { value: 'type_id', text: 'Discount Type' },
                ],
            },
            sort_by: {
                list: [
                    { value: 'desc', text: 'Top' },
                    { value: 'asc', text: 'Poor' },
                ],
            },
            data_type: {
                list: [
                    { value: 'total_amount', text: 'Amount' },
                    { value: 'quantity', text: 'Quantity' },
                ],
            },
            customers: {
                list: [],
            },
            salesman: {
                list: [],
            },
        },
        cards: [
            { title: `Total`, icon: 'mdi-cart', icon_class: 'total-amount', cols: 12, sm: 12, md: 3, lg: 2, xl: 2, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total' },
            { title: 'Count', icon: 'mdi-cart', icon_class: 'total-amount', cols: 12, sm: 12, md: 3, lg: 2, xl: 2, prefix: '', suffix: '', filter: 'thousandSeprator', value: 'group_by_count' },
            { title: 'Items', icon: 'mdi-cart', icon_class: 'total-amount', cols: 12, sm: 12, md: 3, lg: 2, xl: 2, prefix: '', suffix: '', filter: 'thousandSeprator', value: 'item_count' },
        ],
        expansion_panels: {
            items: {
                list: [],
                count: 0,
                selected: [],
            }
        },
        datatable: {
            items: {
                list: [],
                count: 0,
                selected: 0,
            },
            headers: [
                { text: 'IMAGE', align: 'center', value: 'base64img', },
                { text: 'MODEL', align: 'start', value: 'model', },
                { text: 'NAME', align: 'start', value: 'name', },
                { text: 'UOM', align: 'start', value: 'uom' },
                { text: 'TYPE', align: 'start', value: 'type' },
                { text: 'CATEGORY', align: 'start', value: 'category', },
                { text: 'SRP', align: 'end', value: 'selling_price', },
                { text: 'STOCKS', align: 'end', value: 'total_stocks', },
                { text: 'TOTAL', align: 'end', value: 'total_value', },
                { text: 'ACTION', align: 'center', value: 'action' },
            ],
            options: {},
        },
        totals: {
            total: 0,
            group_by_count: 0,
            item_count: 0,
        },
    }),
    async mounted(){
        this.resetFields()
        await this.setDefaultValues()
        await this.loadDropdowns()
    },
    computed: {
        ...mapGetters([
            'GET_SALESMAN_SELECTION',
            'GET_CUSTOMERS_SELECTION',
        ]),
        data_type_label() {
            return this.filters.data_type == 'quantity' ? 'Quantity' : 'Amount'
        },
        data_type_decimal() {
            return this.filters.data_type == 'quantity' ? 0 : 2
        },
    },
    methods: {
        async setDefaultValues() {
            Object.assign(this.filters, this.defaults)
        },
        async loadDropdowns() {
            await this.$store.dispatch('getAllsalesmanSelection')
            await this.$store.dispatch('getAllcustomersSelection')
        },
        async getData(for_export = 0) {

            if(for_export == 0){
                swal.fire('Filtering Please wait.')
                swal.showLoading()
            }

            if(for_export == 1){
                swal.fire('Exporting Files Please wait.')
                swal.showLoading()
            }

            this.datatable.items.list = []
            this.filters.for_export = for_export

            let payload = {
                pagination: this.datatable.options,
                filters: this.filters,
                url: 'top-poor-selling-items',
            }
            this.$store.dispatch('reports', payload).then(async response => {
                let data = response.data
                for await (let e of data) {
                    e.total = e.data.reduce((a, v) => a + parseFloat(v.total_value), 0)
                    e.item_count = e.data.length
                }

                data = data.sort((a, b) => {
                    return b.total - a.total
                })

                data = data.filter(f => {
                    return f.total !== 0
                })

                this.expansion_panels.items.list = data
                this.totals.total = data.reduce((a, v) => a + v.total, 0)
                this.totals.group_by_count = data.length
                this.totals.item_count = data.reduce((a, v) => a + v.item_count, 0)

                if(for_export == 1){
                    await this.exportTopPoorSellingItems();
                }
                swal.close()

            }).catch(e=>{
                console.log(e)
            })
        },
        viewItem(item_id) {
            this.datatable.items.selected = item_id
        },
        closeView() {
            this.datatable.items.selected = 0
        },
        resetFields(){
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
        async exportTopPoorSellingItems(){
            let date = new Date();
            let dateToday = date.toISOString().split('T')[0];
            const workbook = new ExcelJS.Workbook();
            let columns = [
                { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'MODEL', key: 'model', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'SRP', key: 'selling_price', width: 18,style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }, pricelist: true, raw_data: true },
                { header: 'TOTAL VALUE', key: 'total_value', width: 18,style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }, pricelist: true, raw_data: true },
                { header: 'TOTAL STOCKS', key: 'total_stocks', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },numFmt: '#,##0' }, pricelist: false, raw_data: true },
            ]
            let borderStyles = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
            };

            for await (let category of this.expansion_panels.items.list) {
                let worksheet = workbook.addWorksheet(category.name);
                worksheet.columns = columns;

                let pos = 1;
                for await (let item of category.data) {
                    item.model = item.model;
                    item.selling_price = parseFloat(item.selling_price);
                    item.total_value = parseFloat(item.total_value);
                    item.total_stocks = parseFloat(item.total_stocks);
                    worksheet.addRow(item);

                    await this.addImageToWorksheet(workbook, worksheet, item, pos);
                    pos++;
                }
                worksheet.getRow(1).height = 30;

                worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                    row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                        cell.border = borderStyles;
                    });
                });

                worksheet.getRow(`0${1}`).font = { bold: true };
            }

            workbook.xlsx.writeBuffer().then((data) => {
                const file_name = `TopPoorSelling  - ${dateToday}.xlsx`
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                FileSaver.saveAs(blob, file_name);
            });
        },
        async addImageToWorksheet(workbook, worksheet, item, pos){
            let height = 80;
            let width = 60;
            let aspectRatio = null;

            worksheet.getRow(pos + 1).height = height;

            let image = await new Promise((resolve) => {
                let img = new Image();
                img.src = item.base64img;
                img.onload = () => resolve(img);
            });

            aspectRatio = image.naturalWidth / image.naturalHeight;
            let imageHeight = aspectRatio * width;

            let finalImage = workbook.addImage({
                base64: item.base64img,
                extension: 'jpg',
            });

            worksheet.addImage(finalImage, {
                tl: { col: 0.1, row: pos + 0.1 },
                br: { col: 1, row: worksheet.rowCount },
                ext: { width: width, height: height },
                editAs: 'twoCell',
            });

            let row = worksheet.getRow(worksheet.rowCount);
            row.width = width
            if (imageHeight > worksheet.getRow(pos + 1).height) {
                worksheet.getRow(pos + 1).height = imageHeight;
            }
        }
    },
    watch: {
        'GET_CUSTOMERS_SELECTION':{
            handler(val){
                this.dropdowns.customers.list = val
                this.loading.customers = false
            }
        },
        'GET_SALESMAN_SELECTION':{
            handler(val){
                this.dropdowns.salesman.list = val
                this.loading.salesman = false

            }
        },
    },
}
</script>
<style>
.total-amount {
    background-color: #28a745;
}
</style>
