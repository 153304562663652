<template>
  <v-container class="pa-0 ma-0">
    <v-col>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" @change="getAllUsers()" @click:append="getAllUsers()" label="Search" single-line hide-details append-icon="mdi-magnify"> </v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
          }"
          loading-text="Loading...Please wait"
        >
          <template v-slot:[`item.active`]="{ item }">
            <span class="badge bg-primary text-white" v-if="item.active">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
          </template>

          <template v-slot:[`item.locked`]="{ item }">
            <span class="badge bg-primary text-white" v-if="item.locked">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
          </template>

          <template v-slot:[`item.manager`]="{ item }">
            <span class="badge bg-primary text-white" v-if="item.manager">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
          </template>

          <template v-slot:[`item.super_admin`]="{ item }">
            <span class="badge bg-primary text-white" v-if="item.super_admin">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <!-- <buttons
              v-if="userAccess.view"
              :action="buttons.actions.view"
              :button_icon="buttons.icons.view"
              :color="buttons.colors.view"
              :button_text="buttons.type.text"
              :icon="buttons.type.icon"
              @click="editdialogs(item)"
            >
            </buttons>
            <buttons
              v-if="userAccess.edit"
              :action="buttons.actions.edit"
              :button_icon="buttons.icons.edit"
              :color="buttons.colors.edit"
              :button_text="buttons.type.text"
              :icon="buttons.type.icon"
              @click="editdialogs(item)"
            >
            </buttons>
            <buttons
              v-if="userAccess.delete"
              :action="buttons.actions.delete"
              :button_icon="buttons.icons.delete"
              :color="buttons.colors.delete"
              :button_text="buttons.type.text"
              :icon="buttons.type.icon"
              @click="deleteUser(item.id)"
            >
            </buttons> -->
            <v-btn text icon color="orange" v-if="userAccess.view" >
              <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
            </v-btn>
            <v-btn text icon color="blue" v-if="userAccess.edit" >
                <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
            </v-btn>
            <v-btn text icon color="red" v-if="userAccess.delete" >
                <v-icon class="btn-action" small @click="deleteUser(item.id)">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-container>
</template>
<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
export default {
  components: {
    // buttons,
    // buttons_components,
  },
  data() {
    return {
      // buttons: buttons_components.buttons,
      options:{},
      users: [],
      search: '',
      totalItems: null,
      loading: false,
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Username', align: 'left', value: 'username' },
        { text: 'Department', align: 'left', value: 'department.name' },
        { text: 'Job Title', align: 'left', value: 'job_title' },
        { text: 'Active', align: 'left', value: 'active' },
        { text: 'Locked', align: 'left', value: 'locked' },
        { text: 'Manager', align: 'left', value: 'manager' },
        { text: 'Super Admin', align: 'left', value: 'super_admin' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      viewUserId: '',
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          },
        ],
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      selectedId: null,
    }
  },
  mounted() {
    this.getAllUsers();
    this.$store.dispatch('getDepartment');

  },
  computed: {
    ...mapGetters(['GET_ALL_USERS', 'GET_LIST_DEPARTMENT', 'USER_ACCESS', 'NEW_USER', 'GET_USERS_LOADED']),
  },
  methods: {
    viewdialogs(item) {
      this.selectedId = { ...item };
      this.$store.commit("SELECTED_USER", this.selectedId);
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "View");
    },
    getAllUsers(){
        this.loading = true
        this.users = []

        // Check if search is not empty
        if (this.search.trim() !== '') {
            // Set page to 1 if search is not empty
            this.options.page = 1;
        }

        this.$store.dispatch('getAllUsersTable'
        ,{
          page:         this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          search:       this.search,
        }).then((res)=>{
          this.users = res.data
          this.totalItems = res.total
          this.loading     = false
        }
        )
    },
    editdialogs(item) {
      this.selectedId = { ...item };
      this.$store.commit('SELECTED_USER', this.selectedId);
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'Update')
    },
    deleteUser(id) {
      console.log(id);
      swal
        .fire({
          title: '',
          text: 'Are you sure you want to Delete?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: '#397373'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('deleteUser', id).then(success => {
              swal.fire({
                title: 'Success',
                icon: 'success',
                timer: 1500,
              });
              this.getAllUsers();
            })
          }
        });
    }
  },
  watch: {
    NEW_USER: {
      handler(val) {
        if (val) {
          this.$store.commit('DIALOG', false);
          swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
          this.getAllUsers()
          this.$store.commit('NEW_USER', false);
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
        if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
           this.userAccess.create = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
      }
    },
    'options': {
      handler(val) {
          this.getAllUsers()
      }
    }
  },
};
</script>
