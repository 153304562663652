<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
      <v-icon>fas fa-search</v-icon>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="GET_VEHICLES_TYPE_LIST"
      :search="search"
      :items-per-page="15"

      loading-text="Loading...Please wait"
      class="w-100"
      dense
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text icon color="orange" v-if="userAccess.view" >
          <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
        </v-btn>
        <v-btn text icon color="blue" v-if="userAccess.edit" >
            <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn text icon color="red" v-if="userAccess.delete" >
            <v-icon class="btn-action" small @click="deleteDepartment(item.id)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
export default {
  components: {
    // buttons,
    // buttons_components,
  },
  data() {
    return {
      search: '',
      // buttons: buttons_components.buttons,
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Code', align: 'left', value: 'code' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false }
        ,
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getAll()
  },
  computed: {
    ...mapGetters(['GET_VEHICLES_TYPE_LIST', 'USER_ACCESS', 'NEW_VEHICLES_TYPE', 'GET_VEHICLES_TYPE_LOADED']),
  },
  methods: {
    getAll(){
      this.$store.dispatch('getVehiclesType')
    },
    viewdialogs(item) {
      
      this.$emit('showEditDialog',item,'View')
      // this.$store.commit('DIALOG', true);
      // this.$store.commit('ACTION', 'View')
    },
    editdialogs(item) {
      this.$emit('showEditDialog',item,'Edit')
      // this.$store.commit('DIALOG', true);
      // this.$store.commit('ACTION', 'Update')
    },
    deleteDepartment(item) {
      swal
        .fire({
          title: '',
          text: 'Are you sure you want to Delete?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: '#397373'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('deletevehiclestype', item).then(success => {
              swal.fire({
                title: 'Success',
                icon: 'success',
                timer: 1500,
              });
            })
          }
        });
    }
  },
  watch: {
    // NEW_VEHICLES_TYPE: {
    //   handler(val) {
    //     if (val) {
    //       this.$store.dispatch('getVehiclesType')
    //       // this.$store.commit('DIALOG', false);
    //       swal.fire({
    //         title: 'Success',
    //         icon: 'success',
    //         timer: 1500,
    //         showConfirmButton: false,
    //       });
    //       this.$store.commit('NEW_VEHICLES_TYPE', false);
    //     }
    //   },
    // },
    USER_ACCESS: {
      handler(val) {
       if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
      }
    },
  },
};
</script>
