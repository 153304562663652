<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="py-2">
                <v-col v-if="userAccess.create">
                    <v-btn @click="adddialog()">
                        <v-icon>mdi-plus</v-icon>Add
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="pa-2">
                        <v-row dense no-gutters>
                            <v-spacer></v-spacer>
                            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                <v-text-field
                                    v-model="search"
                                    label="Search Sub-Cluster"
                                    single-line
                                    hide-details
                                    append-icon="mdi-magnify"
                                    @click:append-icon="getAllSubClusters()"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense no-gutters>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="clusters"
                                    :search="search"
                                    :loading="!loaded"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                    }"
                                    disable-sort
                                >
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn text icon color="orange" v-if="userAccess.view">
                                            <v-icon class="btn-action" small @click="viewSubCluster(item)">mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="blue" v-if="userAccess.edit">
                                            <v-icon class="btn-action" small @click="editSubCluster(item)">mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="red" v-if="userAccess.delete">
                                            <v-icon class="btn-action" small @click="deleteSubCluster(item.id)">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
    props: [
        'component_cluster'
    ],
    data() {
        return {
            loaded: false,
            options: {},
            itemsPerPage: 10,
            search: null,
            clusters: [],
            headers: [
                { text: 'Code', align: 'start', width: '15%', value: 'code' },
                { text: 'Name', align: 'start', value: 'name' },
                { text: 'Actions', align: 'center', width: '10%', value: 'action' },
            ],
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
        }
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getAllSubClusters()
    },
    methods: {
        getAllSubClusters() {
            const payload = {
                search: this.search,
            }

            this.$store.dispatch('getSubClusters', payload).then(response => {
                this.loaded = true
                this.clusters = response.data
                this.$store.commit('NEW_CLUSTER', false)
            })
        },
        viewSubCluster(item) {
            this.$store.dispatch('getClusterSelection');
            this.$store.commit('ACTION', 'View')
            this.$store.commit('EDIT_CLUSTERS', item)
            this.$store.commit('DIALOG', true)
        },
        editSubCluster(item) {
            this.$store.dispatch('getClusterSelection');
            this.$store.commit('ACTION', 'Update')
            this.$store.commit('EDIT_CLUSTERS', item)
            this.$store.commit('DIALOG', true)
        },
        deleteSubCluster(item) {
            const params = {
                id: item,
            }
            swal.fire({
                icon: 'warning',
                text: 'Are you sure you want to Delete?',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: '#397373',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('deleteSubCluster', params).then((success) => {
                        swal.fire({
                            text: 'Delete Cluster Successful!',
                            icon: 'success',
                        })

                        for (let x in this.component_cluster) {
                            this.$store.commit('NEW_CLUSTER', true)
                        }
                    })
                }
            })
        },
        adddialog() {
            console.log('called');
            this.$store.dispatch('getClusterSelection');
            this.$store.commit('DIALOG', true)
            this.$store.commit('ACTION', 'Submit')
        },
    },
    computed: {
        ...mapGetters([
            'DIALOGS',
            'GET_ALL_CLUSTERS',
            'ACTION',
            'USER_ACCESS',
            'NEW_CLUSTER',
        ]),
    },
    watch: {
        options: {
            handler() {
                this.getAllSubClusters()
            },
            deep: true,
        },
        NEW_CLUSTER: {
            handler(val) {
                if (val) {
                    this.clusters = []
                    this.getAllSubClusters()
                    swal.fire({
                        title: 'Success',
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                    })

                }
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != 'fail') {
                    this.userAccess.create = false
                    this.userAccess.edit = false
                    this.userAccess.delete = false
                    this.userAccess.view = false
                    val.forEach(e => {
                        if (e.actions_code == 'create') {
                            this.userAccess.create = true
                        } else if (e.actions_code == 'edit') {
                            this.userAccess.edit = true
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true
                        }
                    })
                }
            },
        },
    },
}
</script>
