<template>
    <v-row :class=setClass style="background-color: white" dense no-gutters>
        <v-col
            v-for="(item) in cards"
            :key="item.value"
            :cols="item.cols"
            :sm="item.sm"
            :md="item.md"
            :lg="item.lg"
            :xl="item.xl"
        >
            <v-card class="total-cards" elevation="2">
                <v-card-text class="d-flex justify-content-start align-items-center">
                    <div :class="`card-icon ${item.icon_class}`">
                        <v-icon class="text-white" style="font-size: 40px">{{ item.icon }}</v-icon>
                    </div>
                    <div class="card-details">
                        <p style="line-height: 12px;">{{ item.title }}</p>
                        <b style="color: black">
                            <span v-html="item.prefix"></span>
                            {{ item.filter ? $options.filters[item.filter](totals[item.value]) : totals[item.value] }}
                            <span v-html="item.suffix"></span>
                        </b>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        cards: {
            default: () => {
                return [{}]
            },
            type: Array,
        },
        totals: {
            default: () => {
                return {}
            },
            type: Object,
        },
        setClass: {
            default: () => {
                return 'mb-3'
            },
            type: String,
        },
    },
    data() {
        return {
            defaultCardAttributes: {
                title: 'No Title',
                icon: 'mdi-alert-circle',
                icon_class: 'No Class',
                cols: 12,
                sm: 12,
                md: 6,
                lg: 6,
                xl: 6,
                prefix: '',
                suffix: '',
                filter: 'currency',
                value: 'No Value',
            }
        };
    },
    created() {
        this.cardAttributeChecker()
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3])
    },
    methods: {
        upperCaseFirstLetter(str) {
            const arr = str.split(" ")
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
            }
            str = arr.join(" ")
            return str
        },
        cardAttributeChecker() {
            let c = Object.keys(this.defaultCardAttributes)
            let errors = []

            this.cards.forEach((e, i) => {
                let card_keys = Object.keys(e)
                let missing_key = card_keys.filter(x => !c.includes(x)).concat(c.filter(x => !card_keys.includes(x)))

                if (!!missing_key.length) {
                    errors.push({ error: missing_key, index: i })
                }

                if (!!errors.length) {
                    errors.forEach(element => {
                        element.error.forEach(x => {
                            for (let [key, value] of Object.entries(this.defaultCardAttributes)) {
                                if (x == key) {
                                    let obj = Object.assign({ [x]: value }, this.cards[element.index])
                                    this.cards[element.index] = obj
                                }
                            }
                        })
                    })
                }
            })
        }
    },
    watch: {
        options: {
            handler(val) {
                this.list(val.page, val.itemsPerPage);
            },
            deep: true,
        },
    },
};
</script>


<style lang="scss" scoped>
.card-icon {
    height: 70px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.total-amount {
    background-color: #28a745;
}

.total-amount-booked {
    background-color: #1976d2;
}

.total-customer {
    background-color: #ffc107;
}

.total-calls {
    background-color: #5f4fa5;
}

.total-productive-calls {
    background-color: #a9a6b4;
}

.total-so {
    background-color: #17d68d;
}

.total-pending-so {
    background-color: #a7d5e7;
}

.total-target {
    background-color: #e48170;
}

.total-overdue {
    background-color: #d62000;
}

.card-details {
    height: 70px;
    padding-left: 9px;
    padding-top: 20px;
    // width: 50%;
    // background-color: #28a745;
    line-height: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.total-cards {
    // width: 90%;
    margin: 4px;
}

.v-btn {
    margin-right: 2px;
}</style>
