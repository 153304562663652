<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center" v-if="userAccess.create">
                    <div class="btn-toolbar">
                        <v-btn class="mb-2" @click="showAddEditContainerDiscrepancyDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <ContainerDiscrepancyTableComponentVue :component_details="component_details" :tab_name="tab_name" ref="containerDiscrepancyTable"
                    :containerDiscrepancyActionResult="action" @showEditModal="showEditModal"
                ></ContainerDiscrepancyTableComponentVue>
            </v-row>
        </v-container>
        <v-dialog v-model="add_edit_container_discrepancy_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">{{ component_details.title }}</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right"
                            @click="closeAddEditContainerDiscrepancyDialog();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="m-0">
                    <v-col>
                        <v-card class="ma-2">
                            <v-card-subtitle>
                                Container Details
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row class="ma-0 pb-1" >
                                    <v-col xs="12" sm="12" md="6" lg="3">
                                        <v-autocomplete
                                            v-model="container_discrepancies.discrepancy_type_id"
                                            :items="GET_ALL_DISCREPANCY_SELECTION"
                                            dense
                                            outlined
                                            hide-details
                                            label="Select Discrepancy Type"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="3">
                                        <v-autocomplete
                                            v-model="container_discrepancies.container_id"
                                            :items="GET_ALL_CONTAINERS_SELECTION"
                                            dense
                                            outlined
                                            hide-details
                                            label="Select Container"
                                            @change="getContainerItems()"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="3">
                                        <v-autocomplete
                                            v-model="container_discrepancies.warehouse_id"
                                            :items="GET_ALL_WAREHOUSES"
                                            dense
                                            outlined
                                            hide-details
                                            label="Warehouse"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col xs="12" sm="12" md="6" lg="3" class="text-right">
                                        <div class="d-flex flex-row justify-content-end">
                                            <FileUploadComponent v-if="container_discrepancies.status == 0" class="pr-3"  ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" /> 
                                            <span  v-if="container_discrepancies.status == 0" style="padding-top:20px">Upload File</span>
                                            <!-- <BaseFilesViewer class="mt-1" :containerDiscrepancyId="container_discrepancies.id"/> -->
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 pb-2 border-bottom" >
                                    <v-col xs="12" sm="12" md="6" lg="3">
                                        <v-textarea
                                            v-model="container_discrepancies.remarks"
                                            dense
                                            outlined
                                            hide-details
                                            rows="2"
                                            label="Remarks"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="6" lg="3" v-if="container_discrepancies.discrepancy_type_id == 2 && container_discrepancies.status == 1">
                                        <v-textarea
                                            v-model="container_discrepancies.reference_doc"
                                            dense
                                            outlined
                                            hide-details
                                            rows="2"
                                            label="Reference Doc"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 py-2">
                                    <v-col cols="12" class="text-right">
                                        <v-btn 
                                            v-if="container_discrepancies.status == 0"
                                            fab dark small color="primary" style="height: 20px; width: 20px"
                                            @click="addContainerDiscrepancyItem()">
                                            <v-icon dark small style="font-size: 12px">
                                                mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col xs="12" sm="12" md="12" lg="12">
                                        <v-simple-table fixed-header dense class="overflow-y-auto" style="border: 1px solid #e0e0e0; max-height: 400px">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th width="10%" class="text-center">MODEL</th>
                                                        <th width="20%" class="text-center">NAME</th>
                                                        <th width="6%" class="text-center">TYPE</th>
                                                        <th width="6%" class="text-center">UOM</th>
                                                        <th width="6%" class="text-center">QTY RECEIVED (MB)</th>
                                                        <th width="6%" class="text-center">QTY/MB</th>
                                                        <th width="6%" class="text-center">MB QTY</th>
                                                        <th width="6%" class="text-center">UOM QTY</th>
                                                        <th width="6%" class="text-center">DISCREPANCY (UOM)</th>
                                                        <th v-if="container_discrepancies.discrepancy_type_id == 2 && container_discrepancies.status == 1" width="6%" class="text-center">RECEIVED (UOM)</th>
                                                        <th v-if="!(container_discrepancies.discrepancy_type_id == 2 && container_discrepancies.status == 1)" width="3%" class="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in container_discrepancies.container_discrepancy_items" :key="item.id"
                                                        class="font-weight-medium text-gray py-2">
                                                        <td>
                                                            <v-autocomplete class="my-1" 
                                                                :items="container_item_list"
                                                                item-value="item_id"
                                                                item-text="model"
                                                                outlined 
                                                                dense
                                                                v-model="item.item_id"
                                                                @change="getItemDetails(item.item_id,index)"
                                                                :disabled="isDisabledFields"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 text-left"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                disabled
                                                                v-model="item.name"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                disabled
                                                                v-model="item.item_type_name"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                disabled
                                                                v-model="item.item_uom_name"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                disabled
                                                                v-model="item.quantity_received"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                disabled
                                                                v-model="item.quantity_master_box_conv"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                disabled
                                                                v-model="item.quantity_master_box"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                disabled
                                                                v-model="item.quantity"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                v-model="item.discrepancy"
                                                                :disabled="isDisabledFields"
                                                            />
                                                        </td>
                                                        <td v-if="container_discrepancies.discrepancy_type_id == 2 && container_discrepancies.status == 1">
                                                            <v-text-field class="my-1 centered-input"  
                                                                outlined 
                                                                dense
                                                                hide-details
                                                                v-model="item.received_quantity"
                                                            />
                                                        </td>
                                                        <td v-if="!(container_discrepancies.discrepancy_type_id == 2 && container_discrepancies.status == 1)">
                                                            <v-btn fab dark small color="red" class="ml-1"
                                                                v-if="container_discrepancies.status == 0"
                                                                style="height: 20px; width: 20px" @click="removeContainerDiscrepancyItem(index)">
                                                                <v-icon dark small style="font-size: 12px">
                                                                    mdi-minus
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-row class="m-0">
                        <v-col class="d-flex justify-content-center">
                            <v-btn 
                            class="text-center"
                            @click="submitContainerDiscrepancy()"
                            > {{ action == 'add' ? 'Submit' : 'Update' }} </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import swal from "sweetalert2";
import $ from "jquery";
import { mapGetters } from "vuex";
import { computed } from 'vue';
import ContainerDiscrepancyTableComponentVue from './ContainerDiscrepancyTableComponent.vue';
import BaseFilesViewer from "../../Accounting/Base/BaseFilesViewer.vue";
import FileUploadComponent from "@/views/main/Shared/FileUploadComponent.vue";
export default {
    mixins: [

    ],
    components: {
        ContainerDiscrepancyTableComponentVue,
        BaseFilesViewer,
        FileUploadComponent,
    },
    props: [
        "tab_name",
        "component_details",
    ],
    data() {
        return {
            add_edit_container_discrepancy_dialog:false,
            container_item_list:[],
            container_discrepancies:{
                container_discrepancy_items:[],
                container_id:0,
                discrepancy_type_id:0,
                id:0,
                remarks:'',
                status:'',
                warehouse_id:0,
                reference_doc:'',
            },
            action:'',
            uploadedFiles:null,
            userAccess:{
                create:false
            }
        }
    },
    methods:{
        showAddEditContainerDiscrepancyDialog(){
            this.resetFields()
            this.action = 'add'
            this.add_edit_container_discrepancy_dialog = true
            this.addContainerDiscrepancyItem()
        },
        closeAddEditContainerDiscrepancyDialog(){
            this.add_edit_container_discrepancy_dialog = false
            this.resetFields()
        },
        getContainerItems(){
            if(this.action == 'add'){
                this.container_discrepancies.container_discrepancy_items = []
                this.addContainerDiscrepancyItem()
            }
            let container = _.find(this.GET_ALL_CONTAINERS_SELECTION, { 'value': this.container_discrepancies.container_id });
            let container_items= container.container_items
            this.container_discrepancies.warehouse_id = container.warehouse_id
            this.container_item_list = container_items
        },
        addContainerDiscrepancyItem(){
            this.container_discrepancies.container_discrepancy_items.push({
                id:'',
                model:'',
                name:'',
                item_type_name:'',
                item_uom_name:'',
                quantity:0,
                quantity_master_box:0,
                quantity_master_box_conv:0,
                quantity_received:0,
                discrepancy:0,
                received_quantity:0
            })
        },
        getItemDetails(id,index){
            let item_list = _.find(this.container_item_list, {'item_id':id});    
            Object.assign(item_list,{
                discrepancy:this.container_discrepancies.container_discrepancy_items[index].discrepancy,
                received_quantity:this.container_discrepancies.container_discrepancy_items[index].received_quantity,
            })         
            this.container_discrepancies.container_discrepancy_items[index] = item_list
        },
        removeContainerDiscrepancyItem(index){
            this.container_discrepancies.container_discrepancy_items = _.remove(this.container_discrepancies.container_discrepancy_items, (e,i)=>{
                if(i != index){
                    return e
                }
            })
            if(this.container_discrepancies.container_discrepancy_items.length < 1){
                this.addContainerDiscrepancyItem()
            }
        },
        resetFields(){
            Object.assign(this.$data, this.$options.data.call(this));
            this.checkAccess();
        },
        submitContainerDiscrepancy(){
            let swal_data = {
                title:'',
                text:'',
                icon:''
            }
            if(this.container_discrepancies.container_id == 0){
                swal_data.title = 'Please Input Container'
                swal_data.icon = 'warning' 
                swal.fire(swal_data) 
                return false
            }
            if(this.container_discrepancies.discrepancy_type_id == 0){
                swal_data.title = 'Please Input Discrepancy Type'
                swal_data.icon = 'warning' 
                swal.fire(swal_data) 
                return false
            }

            if (this.uploadedFiles == null && this.action == 'add') {
                swal.fire({
                    title: "Please Upload Images or Files",
                    icon: "warning",
                });
                return false
            }

            if(this.container_discrepancies.warehouse_id == 0 && (this.container_discrepancies.discrepancy_type_id == 2 && this.container_discrepancies.status == 1 )){
                swal_data.title = 'Please Select Warehouse'
                swal_data.icon = 'warning' 
                swal.fire(swal_data) 
                return false
            }
            
            const data = new FormData();

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for (let i = 0; i < _.compact(files).length; i++) {
                    data.append("files[]", files[i]);
                }
            }
            data.append('container_discrepancy_items',JSON.stringify(this.container_discrepancies.container_discrepancy_items))

            data.append('container_id',this.container_discrepancies.container_id)
            data.append('discrepancy_type_id',this.container_discrepancies.discrepancy_type_id)
            data.append('id',this.container_discrepancies.id)
            data.append('remarks',this.container_discrepancies.remarks)
            data.append('warehouse_id',this.container_discrepancies.warehouse_id)
            data.append('reference_doc',this.container_discrepancies.reference_doc)
            
            let payload = {
                params: data,
                config: config,
            };

            let action_name = this.action == 'add' ? 'createContainerDiscrepancy' : 'updateContainerDiscrepancy'

            this.$store.dispatch(action_name,payload).then(response=>{
                swal.fire(response.data.msg,'',response.data.icon)
                if(response.data.icon == 'success'){
                    this.$refs.containerDiscrepancyTable.getAllContainerDiscrepancy();
                    this.closeAddEditContainerDiscrepancyDialog();
                }
            })
        },
        showEditModal(item){
            this.action = 'edit'
            let container = _.find(this.GET_ALL_CONTAINERS_SELECTION, { 'value': item.container_id });
            let container_items= container.container_items
            this.container_item_list = container_items

            this.container_discrepancies = item
        
            this.container_discrepancies.container_discrepancy_items.forEach((e,i)=>{
                this.getItemDetails(e.item_id,i)
            })
            this.add_edit_container_discrepancy_dialog = true 
        },
        uploadedData(data) {
            if (!data) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = null;
            this.$refs.fileUpload.reset();
        },
        checkAccess(){
            this.userAccess.create = false;
            this.USER_ACCESS.forEach(e => {
                if (e.actions_code == 'create') {
                    this.userAccess.create = true;
                }
            });
            
        }
    },
    computed:{
        ...mapGetters([
            'GET_ALL_CONTAINERS_SELECTION',
            'GET_ALL_DISCREPANCY_SELECTION',
            'GET_ALL_WAREHOUSES',
            'USER_ACCESS',
        ]),
        isDisabledFields(){
            if(this.container_discrepancies.status == 0){
                return false
            }
            return true
        }
    },
    mounted(){
       this.checkAccess();
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.checkAccess()
                }
            },
        }
    }
};
</script>
<style scoped>
.promo_note {
    font-size: x-small;
    color: red;
}

.dialog {
    overflow: unset;
}
.centered-input input {
  text-align: center
}
</style>
