<template>
    <v-container fluid>
        <ComponentHeader :button="true" :title="'Sales By Customer Detailed (Comparison)'" :type="'Sales'"></ComponentHeader>
        <v-container class="bg-bluish-gray default-container">
            <v-row class="pa-2">
                <v-col class="col-sm-12 col-md-3">
                    <v-autocomplete outlined dense :items="customer_list"
                        v-model="customer_id"
                        background-color="white"
                        item-value="value"
                        item-text="text"
                        placeholder="Select Customer"
                        class="small"
                    >
                    </v-autocomplete>
                    <v-autocomplete outlined dense :items="GET_ITEM_SELECTION_DEFAULT"
                        v-model="item_id"
                        background-color="white"
                        item-value="value"
                        item-text="text"
                        placeholder="Select Model"
                        class="small"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col class="col-sm-12 col-md-3">
                    <v-autocomplete outlined dense :items="customer_list"
                        v-model="compared_customer_id"
                        background-color="white"
                        item-value="value"
                        item-text="text"
                        placeholder="Compare To Customer"
                        class="small"
                    >
                    </v-autocomplete>
                    <v-autocomplete outlined dense :items="salesman_list"
                        v-model="salesman_id"
                        background-color="white"
                        item-value="value"
                        item-text="text"
                        placeholder="Select Salesman"
                        class="small"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col class="text-right">
                    <v-btn small class="ml-1" v-on:click="resetFields()">Clear</v-btn>
                    <v-btn small class="ml-1" v-on:click="exportToExcel()">Export</v-btn>
                    <v-btn small class="ml-1" v-on:click="getSalesByCustomerDetailed('true')">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <!-- <v-col cols="1" class="mx-1" v-if="GET_USER_COSTING">
                    <v-checkbox
                        v-model="show_cost"
                        label="SC"
                        color="white"
                        :true-value="1"
                        :false-value="null"
                        hide-details
                    ></v-checkbox>
                </v-col> -->
                <v-col lg="1" class="mx-1">
                    <v-checkbox
                        v-model="confirmed"
                        label="confirmed"
                        color="white"
                        true-value="1"
                        false-value="0"
                        hide-details
                    ></v-checkbox>
                </v-col>
                <v-col lg="1" class="mx-1">
                    <v-checkbox
                        v-model="unconfirmed"
                        label="unconfirmed"
                        color="white"
                        true-value="1"
                        false-value="0"
                        hide-details
                    ></v-checkbox>
                </v-col>
                <v-col lg="3" class="mx-1">
                    <v-menu
                        v-model="date_from_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                outlined
                                dense
                                label="Date From"
                                background-color="white"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="date_from_menu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col lg="3" class="mx-1">
                    <v-menu
                        v-model="date_to_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                outlined
                                dense
                                v-model="date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                label="Date To"
                                background-color="white"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="date_to_menu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-container>
        <v-container class="bg-white default-container">
            <v-row class="mx-2">
                <ReportsCardsComponent :totals="totals" :cards="cards" style="width:100%;"></ReportsCardsComponent>
            </v-row>
            <v-row class="pa-2">
                <v-col class="mx-2 mb-2">
                    <v-card>
                        <v-row class="mx-2 mb-2">
                            <v-col cols="12">
                                <v-data-table
                                ref="tableRef"
                                :headers="headers"
                                :items="sales_by_customer_detailed.original.data.data"
                                dense
                                item-key="id"
                                show-expand
                                single-expand
                                @item-expanded="loadDetails"
                                :loading="loader.compared"
                                :options.sync="options"
                                :server-items-length="sales_by_customer_detailed.original.total"
                                :items-per-page="10"
                                >
                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.packing_list_date`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.packing_list_date | formatDate }}
                                    </span>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-data-table :headers="expand_header"
                                        :items="expanded_items"
                                        class="w-100"
                                        dense
                                        dark
                                        >
                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.total_amount | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.price`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1; {{ item.price | currency }}
                                            </span>
                                        </template>
                                        </v-data-table>
                                    </td>
                                </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col class="mx-2 mb-2">
                    <v-card>
                        <v-row class="mx-2 mb-2">
                            <v-col cols="12">
                                <v-data-table
                                ref="comparedTable"
                                :headers="compared_headers"
                                :items="sales_by_customer_detailed.compared.data"
                                dense
                                item-key="id"
                                show-expand
                                single-expand
                                :loading="loader.compared"
                                :options.sync="options"
                                :items-per-page="10"
                                @item-expanded="loadDetails"
                                >
                                <template v-slot:[`item.compared_total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.compared_total_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.packing_list_date`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.packing_list_date | formatDate }}
                                    </span>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-data-table :headers="compared_expand_header"
                                        :items="item.item_pl"
                                        class="w-100"
                                        dense
                                        dark
                                        >
                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.total_amount | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1; {{ item.amount | currency }}
                                            </span>
                                        </template>
                                        </v-data-table>
                                    </td>
                                </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-card>

                </v-col>
            </v-row>

        </v-container>

    </v-container>

</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import moment from 'moment';
import { mapGetters } from 'vuex';
import ReportsCardsComponent from '@/views/main/layouts/Reports/ReportsCardsComponent.vue';
import swal from 'sweetalert2';
export default {
    data(){
        return{
            customer_list:[],
            salesman_list:[],
            model_list:[],
            confirmed:'1',
            unconfirmed: '1',
            date_from:moment().clone().startOf('month').format('YYYY-MM-DD'),
            date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
            date_from_menu: false,
            date_to_menu: false,
            show_cost: null,
            headers: [
                { text: 'DR#', align: 'left', value: 'packing_list_num', },
                { text: 'Date', align: 'left', value: 'packing_list_date', },
                { text: 'Promo', align: 'left', value: 'promo', },
                { text: 'Amount', align: 'right', width: '10%', value: 'total_amount', },
            ],
            compared_headers: [
                { text: 'DR#', align: 'left', value: 'packing_list_num', },
                { text: 'Date', align: 'left', value: 'packing_list_date', },
                { text: 'Promo', align: 'left', value: 'promo', },
                { text: 'Amount', align: 'right', width: '10%', value: 'compared_total_amount', },
            ],
            expand_header: [
                { text: 'Model', value:'model', type:'string' },
                { text: 'Name', value:'name', type:'string' },
                { text: 'Quantity', value:'quantity', type:'quantity' },,
                { text: 'Price', value:'price', type:'price' },
                { text: 'Total Amount', value:'total_amount', type:'price' },
            ],
            compared_expand_header: [
                { text: 'Model', value:'view_item.model', type:'string' },
                { text: 'Name', value:'view_item.name', type:'string' },
                { text: 'Quantity', value:'quantity', type:'quantity' },,
                { text: 'Price', value:'amount', type:'price' },
                { text: 'Total Amount', value:'total_amount', type:'price' },
            ],
            expanded_items:[],
            customer_id:null,
            salesman_id:null,
            item_id:null,
            sales_by_customer_detailed:{
                original:{
                    data:[],
                    gross_sales_total_amount:0,
                    net_sales_total_amount:0,
                    adjustments_total_amount:0,
                    total:0
                },
                compared:{
                    data:[],
                    gross_sales_total_amount:0,
                    net_sales_total_amount:0,
                    adjustments_total_amount:0,
                },
            },
            compared_customer_id:null,
            loader:{
                compared:false
            },
            options: {},
            totals: {
                gross_sales_total_amount: 0,
                net_sales_total_amount: 0,
                compared_net_sales_total_amount: 0,
                difference: 0,
            },
            cards: [
                { title: 'Gross Sales', icon: 'mdi-cart', icon_class: 'total-amount-booked', sm: 6, md: 3, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'gross_sales_total_amount' },
                { title: 'Net Sales ()', icon: 'mdi-cash', icon_class: 'total-amount', sm: 6, md: 3, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'net_sales_total_amount' },
                { title: 'Net Sales ()', icon: 'mdi-cash', icon_class: 'total-amount', sm: 6, md: 3, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'compared_net_sales_total_amount' },
                { title: 'Difference', icon: 'mdi-cash', icon_class: 'total-customer', sm: 6, md: 3, lg: 3, prefix: '&#x20b1', suffix: '', filter: 'currency', value: 'difference' },
            ],
            compared_customer_name:null
        }
    },
    mounted(){
        this.$store.dispatch('getAllcustomersSelection').then(response=>{
            this.customer_list = response
        })
        this.$store.dispatch('getAllsalesmanSelection').then(response=>{
            this.salesman_list = response
        })
        this.$store.dispatch('getAllitemSelectionDefault')

    },
    components:{
        ComponentHeader,
        ReportsCardsComponent
    },
    computed:{
        ...mapGetters([
            'GET_ITEM_SELECTION_DEFAULT'
        ])
    },
    methods:{
        getCustomers(){
            this.customers_list = this.GET_CUSTOMERS_SELECTION
            this.customers_list.push({
                value: 'all',
                text: 'Select All'
            })
        },
        getSalesByCustomerDetailed(is_compared){
            if(this.customer_id === null || this.compared_customer_id === null){
                swal.fire('Please Select Customer','Select One Customer and a Customer you want to compare to','warning')
                return false
            }
            this.loader.compared = true
            if(is_compared){
                this.sales_by_customer_detailed.original.data = []
                this.sales_by_customer_detailed.compared.data = []
            }
            let payload = {
                salesman_id: this.salesman_id,
                customer_id: this.customer_id,
                confirmed:  this.confirmed,
                unconfirmed: this.unconfirmed,
                confirm: this.confirmed,
                date_from: this.date_from,
                date_to: this.date_to,
                item_id: this.item_id,
                filename:'Sales By Customer Detailed',
                url: 'sales-by-customer-detailed',
                expand_url:'/detail_id',
                show_cost: this.show_cost,
                page:this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                compared_customer_id:this.compared_customer_id,
                is_compared:is_compared
            }
            this.$store.dispatch('reports', payload).then(response => {
                this.sales_by_customer_detailed.original = response.data
                if(is_compared){
                    this.sales_by_customer_detailed.compared.data = response.data.compared_data
                }
                this.totals.net_sales_total_amount = response.data.net_sales_total_amount
                this.totals.gross_sales_total_amount = response.data.gross_sales_total_amount
                this.totals.compared_net_sales_total_amount =  _.sumBy(this.sales_by_customer_detailed.compared.data,'compared_total_amount')
                this.totals.difference = this.totals.net_sales_total_amount - this.totals.compared_net_sales_total_amount
                this.getCompanyName(this.customer_id,this.compared_customer_id);
                this.loader.compared = false
            })
        },
        loadDetails(item){
            this.$refs.comparedTable.expansion = this.$refs.tableRef.expansion
            this.expanded_items = []
            let payload = {
                url:`getPackingListItems/${item.item.id}`
            }
            this.$store.dispatch('getPL',payload).then(response=>{
                this.expanded_items = response.data

            })
        },
        getCompanyName(customer_id,compared_customer_id){
            let first_customer = _.find(this.customer_list,{'value':customer_id});
            let second_customer = _.find(this.customer_list,{'value':compared_customer_id});

            this.cards[1].title = `Net Sales (${first_customer.text})`
            this.cards[2].title = `Net Sales (${second_customer.text})`
            this.compared_customer_name = second_customer.text
        },
        exportToExcel(){
            if(this.customer_id === null || this.compared_customer_id === null){
                swal.fire('Please Select Customer','Select One Customer and a Customer you want to compare to','warning')
                return false
            }
            const ExcelJS = require('exceljs')
            const workbook = new ExcelJS.Workbook();

            const worksheet = workbook.addWorksheet('Raw Data')
            worksheet.columns = [
                { header: 'CUSTOMER A', key: 'customer_name' },
                { header: 'CUSTOMER B', key: 'compared_customer_name' },
                { header: 'DR Num', key: 'packing_list_num' },
                { header: 'PO Num', key: 'customer_po_num' },
                { header: 'DR DATE', key: 'packing_list_date' },
                { header: 'REMARKS', key: 'remarks' },
                { header: 'SALESMAN', key: 'salesman_name' },
                { header: 'TERMS', key: 'terms' },
                { header: '(A) DR AMOUNT',style:{ numFmt: "#,##0.00" }, key: 'pl_total_amount' },
                { header: '(B) DR AMOUNT',style:{ numFmt: "#,##0.00" }, key: 'b_pl_total_amount' },
                { header: 'ITEM NAME', key: 'item_name' },
                { header: 'ITEM MODEL', key: 'item_model' },
                { header: 'UOM', key: 'item_uom_text' },
                { header: 'FOC', key: 'foc' },
                { header: 'QTY', key: 'quantity' },
                { header: 'SRP AMOUNT',style:{ numFmt: "#,##0.00" }, key: 'srp' },
                { header: '(A) DISC AMOUNT', style:{ numFmt: "#,##0.00" },key: 'disc_price' },
                { header: '(B) DISC AMOUNT', style:{ numFmt: "#,##0.00" },key: 'b_disc_price' },
                { header: '(A) ITEM TOTAL AMOUNT', key: 'total_amount' ,style:{ numFmt: '#,##0.00' }},
                { header: '(B) ITEM TOTAL AMOUNT', style:{ numFmt: "#,##0.00" }, key: 'b_total_amount'},
                { header: 'TYPE', key: 'item_type_text' },
                { header: 'CATEGORY', key: 'item_category_text' },
                { header: 'CONFIRMED', key: 'confirmed' },
                { header: 'PRINTED', key: 'printed' },
            ]
            let data = []
            this.sales_by_customer_detailed.compared.data.forEach(e => {
                e.packing_list_items.forEach((x,i)=>{
                    data.push({
                        customer_name:e.customer_name,
                        compared_customer_name:this.compared_customer_name,
                        packing_list_num:e.packing_list_num,
                        customer_po_num:e.customer_po_num,
                        packing_list_date:e.packing_list_date,
                        remarks:e.remarks,
                        salesman_name:e.salesman_name,
                        terms:e.terms,
                        pl_total_amount:Number(e.total_amount),
                        b_pl_total_amount:Number(e.compared_total_amount),
                        item_name:x.item_name,
                        item_model:x.item_model,
                        item_uom_text:x.item_uom_text,
                        item_type_text:x.item_type_text,
                        item_category_text:x.item_category_text,
                        printed:e.printed == 1 ? "TRUE" : "FALSE",
                        foc:x.foc == 0 ? "FALSE" : "TRUE",
                        quantity:x.quantity,
                        srp:Number(x.srp),
                        b_amount:Number(e.item_pl[i].amount),
                        disc_price:Number(x.disc_price),
                        b_disc_price:Number(e.item_pl[i].disc_price),
                        total_amount:Number(x.total_amount),
                        b_total_amount:Number(e.item_pl[i].total_amount),
                        confirmed:e.status == 1 ? "TRUE" : "FALSE",
                    })
                });
            })

            worksheet.addRows(data)
            worksheet.getRow(`0${1}`).font = { bold: true }

            let exportName = `Sales By Customer Detailed (Comparison) ${this.date_from} - ${this.date_to}`

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                saveAs(blob, `${exportName}.xlsx`);
            });
        },
        resetFields(){
            console.log(this.$options);
            Object.assign(this.$data, this.$options.data.call(this));

            this.$store.dispatch('getAllcustomersSelection').then(response=>{
                this.customer_list = response
            })
            this.$store.dispatch('getAllsalesmanSelection').then(response=>{
                this.salesman_list = response
            })
            this.$store.dispatch('getAllitemSelectionDefault')
        }
    },
    watch:{
        options: {
            handler () {
                if(!!this.customer_id && !!this.compared_customer_id){
                    this.getSalesByCustomerDetailed(null)
                }
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.card-icon {
  height: 70px;
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.total-amount {
  background-color: #28a745;
}
</style>
