var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-card',[_c('v-card-title',{staticClass:"pr-0 pt-0 pl-3 pb-0 border-bottom"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.SELECTED_USER_ROLE.name)+" - "+_vm._s(_vm.GET_USER_DETAILS.name))]),_c('v-row',{staticClass:"m-0"},[_c('v-col',{attrs:{"cols":"pull-right-10 p-2"}},[_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":"","small":"","color":"gray"},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)],1)],1),_c('v-card-text',{staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"d-flex justify-content-around align-items-end"},_vm._l((_vm.GET_ROLE_ACCESS.department),function(department,i){return _c('span',{key:i,staticClass:"m-3"},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.SIDENAV_LOAD.find(e=>e.icon_id == department.icon).icon))]),_vm._v(" "+_vm._s(department.menubar_desc)+" "),_c('v-switch',{ref:"module",refInFor:true,attrs:{"input-value":department.access,"switch_access":{
                          department_id: department.id,
                          user_id: _vm.GET_USER_DETAILS.id,
                          side_menu: department.side_menu_id,
                          access_type: 1,
                          actions_id: i,
                          actions_code: 'view',
                          main_status: department.access,
                          },"color":"#2337C6"},on:{"change":function($event){return _vm.onChangeSwitchMain(i, department.access)}},model:{value:(department.access),callback:function ($$v) {_vm.$set(department, "access", $$v)},expression:"department.access"}})],1)}),0),_c('div',{staticStyle:{"max-height":"600px","overflow-y":"scroll","padding":"0"}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.GET_ROLE_ACCESS.data),function(access,i){return _c('v-expansion-panel',{key:i,attrs:{"accordion":"","value":i}},[_c('v-expansion-panel-header',[_c('b',[_vm._v(" "+_vm._s(access.menu_link_desc))])]),_c('v-expansion-panel-content',[_c('v-row',_vm._l((access.actions),function(actions,index){return _c('v-col',{key:index,attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(actions.actions)+" "),_c('v-switch',{ref:"actions",refInFor:true,attrs:{"input-value":actions.access,"switch_access":{
                                          id: access.id,
                                          department_id: _vm.SELECTED_USER_ROLE.department_id,
                                          job_title_id: _vm.SELECTED_USER_ROLE.job_title_id,
                                          active: actions.active,
                                          side_bar_menu_links: access.id,
                                          user_id: _vm.GET_USER_DETAILS.id,
                                          actions_id: actions.id,
                                          actions_code: actions.code,
                                          access_type: 2,
                                          child_status: actions.access,
                                      },"color":"#2337C6","dense":""},on:{"change":function($event){return _vm.onChangeSwitch(actions.id, actions.access)}},model:{value:(actions.access),callback:function ($$v) {_vm.$set(actions, "access", $$v)},expression:"actions.access"}})],1)}),1)],1)],1)}),1)],1)]),_c('v-card-actions',{staticClass:"text-center"},[_c('v-row',{staticClass:"ma-1"},[_c('v-col',{attrs:{"lg":"2"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"value":"1","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"pt-2"},[_vm._v(" Show All ")])]},proxy:true}]),model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),(!_vm.GET_ROLE_LOADED)?_c('v-col',{attrs:{"lg":"8"}},[_c('v-btn',{staticClass:"float-center",on:{"click":function($event){return _vm.SubmitAccess()}}},[_c('span',[_vm._v("Submit")])])],1):_vm._e(),_c('v-spacer')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }