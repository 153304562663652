<template>
  <v-data-table
    :headers="headers"
    :items="departments.users"
    :search="search"
    :items-per-page="5"
    class="w-100"
    :loading="!GET_DEPARTMENT_ACCESS_LOADED"
    loading-text="Loading... Please wait"
  >
    <template v-slot:[`item.active`]="{ item }">
      <span class="badge bg-primary text-center text-white" v-if="item.active">Yes</span>
      <span class="badge bg-danger text-center text-white" v-else>No</span>
    </template>

    <template v-slot:[`item.locked`]="{ item }">
      <span class="badge bg-primary text-center text-white" v-if="item.locked">Yes</span>
      <span class="badge bg-danger text-center text-white" v-else>No</span>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <v-switch
        v-model="item.access"
        label=""
        color="primary"
        input-value="1"
        @change="updateAccess(item.access, item.id, departments.department_id)"
      ></v-switch>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: ['departments', 'search'],
  data() {
    return {
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Username', align: 'left', value: 'username' },
        { text: 'Department', align: 'left', value: 'department_name' },
        { text: 'Job Title', align: 'left', value: 'job_title' },
        { text: 'Active', align: 'center', value: 'active' },
        { text: 'Locked', align: 'center', value: 'locked' },
        { text: 'Access', align: 'left', value: 'action', sortable: false },
      ],
      payload: false,
    };
  },
  mounted() {},
  methods: {
  },
  computed: {
    ...mapGetters(['GET_DEPARTMENT_ACCESS_LOADED'])
  }
};
</script>
