<template>
<v-hover v-model="isHover">
    <template v-slot="{ hover }">
    <v-card>
        <v-container class="picture">
            <embed class="img-fluid w-100 h-100" :src="uploadedImage"/>
        </v-container>
        <v-fade-transition style="opacity:0.5;">
        <v-overlay v-if="hover" absolute :color="hover ? 'white' : ''"  class="d-flex flex-row justify-content-center align-items-center">
                <v-row>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }" >
                        <v-btn
                            style="opacity:1;"
                            class="ma-2"
                            color="blue-grey"
                            fab
                            v-bind="attrs"
                            v-on="on"
                        >
                        <label for="upload-file"><span class="upload-file"><v-icon x-large>mdi-progress-upload</v-icon></span></label>
                        </v-btn>
                        </template>
                        <span>Upload</span>
                    </v-tooltip>

                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ma-2"
                                color="blue-grey"
                                fab
                                @click="openCamera"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon x-large>mdi-camera</v-icon>
                            </v-btn>
                        </template>
                        <span>Camera Capture</span>
                    </v-tooltip> -->
                </v-row>
            <input  class="d-none" id="upload-file" type="file" ref="fileInput" @change="uploadImage" accept="image/png, image/jpeg"/>
        </v-overlay>
        </v-fade-transition>
    </v-card>
    </template>
</v-hover>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex';
import { watch } from 'vue';
export default {
    data() {
        return {
            isHover: false,
            name: '',
            files: '',
            success: '',
            attachments: [],
            images: null,
            uploadedImage:null
        }
    },
    computed:{
        ...mapGetters(['GET_SELECTED_SALESMAN_HEAD'])
    },
    watch: {
        attachments() {
            if(this.attachments.length > 0) {
                this.attachments = [];
            }
        },
    },
    methods: {
        clearAttachments() {
            this.attachments = [];
            this.images = null;
        },
        uploadImage(event) {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				this.uploadedImage = reader.result

                this.$emit('uploadedImage', {
                    uploadedImage: this.uploadedImage,
                    filename: event.target.files[0].name
                });
			})
			const file = event.target.files[0];
			let url = reader.readAsDataURL(file);
		},
        openCamera(e){
             this.$emit('openCamera',true);
        },
        onChange(e) {
            let selectedFiles = [];

            if(e) {
                selectedFiles = e;
            }

            let fileTypes = ['jpg','jpeg','png','csv','txt','xlx','xls','xlsx','pdf','zip'];


            if(selectedFiles.length == 0) {
                return false;
            } else {
                for(let i=0;i<selectedFiles.length;i++) {
                    this.attachments.push(selectedFiles[i])
                }

                let fileSizeChecker = _.compact(this.attachments).every(x=> {
                    return x.size < 8192000;
                });

                let fileTypeChecker = _.compact(this.attachments).every(x=> {
                    let fileName = x.name;
                    let fileNameLength = fileName.split('.').length;
                    let ext = fileName.split('.')[fileNameLength - 1];
                    return fileTypes.includes(ext);
                });

                // Emit Data
                if(!fileSizeChecker) {
                    swal.fire("", 'Each File size not more than 8MB', "warning").then(confirm=>{
                        if(confirm) {
                            this.attachments = [];
                        }
                    });

                    this.$emit('uploadedData', false);
                } else if(!fileTypeChecker) {
                    swal.fire("", 'Each File must be jpg, jpeg, png, csv, txt, xlx, xls, xlsx, pdf and zip type', "warning").then(confirm=>{
                        if(confirm) {
                            this.attachments = [];
                        }
                    });

                    this.$emit('uploadedData', false);
                } else {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    this.$emit('uploadedData', {
                        attachments: this.attachments,
                        config: config
                    });
                }
            }
        },
        reset() {
            this.$refs.inputUploader.reset();
            this.clearAttachments();
        },
        getSelectedImg(){
            this.uploadedImage = this.GET_SELECTED_SALESMAN_HEAD.salesman_profile_base64
        }
    },
    mounted(){
        this.getSelectedImg();
    }
    
}
</script>

<style>
.picture{
    display: block;
    width: 335px;
    height: 335px;
}
</style>
