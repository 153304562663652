<template>
<v-container class="pa-2 ma-0">
    <v-row class="ma-1">
        <v-col cols="12" class="d-flex no-block align-items-center">
            <h4 class="page-title" style="color: black">Debit Memo</h4>
        </v-col>
    </v-row>

        <v-app id="item-tabs" class="mt-5">
            <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12" show-arrows>
                <v-tab ripple>ALL DM</v-tab>
                <v-tab ripple>ON PROCESS</v-tab>
                <v-tab ripple>APPROVAL - ACCT</v-tab>
                <v-tab ripple>APPROVAL - EXEC</v-tab>
                <v-tab ripple>APPROVED</v-tab>
                <v-tab ripple>CANCELED</v-tab>

                <v-spacer></v-spacer>
                <v-divider vertical></v-divider>
                <v-tab ripple>Type</v-tab>

                <v-tabs-items v-model="tab" touchless>
                    <!-- ALL DM TAB -->
                    <v-tab-item>
                        <DebitMemoComponentVue :print="print"></DebitMemoComponentVue>
                    </v-tab-item>

                    <!-- ON PROCESS -->
                    <v-tab-item>
                        <DebitMemoComponentVue :status_text="'for_processing'" :print="print"></DebitMemoComponentVue>
                    </v-tab-item>

                    <!-- APPROVAL - ACCT -->
                    <v-tab-item>
                        <DebitMemoComponentVue :status_text="'for_acct_approval'" :print="print"></DebitMemoComponentVue>
                    </v-tab-item>

                    <!-- APPROVAL - EXEC -->
                    <v-tab-item>
                        <DebitMemoComponentVue :status_text="'for_exec_approval'" :print="print"></DebitMemoComponentVue>
                    </v-tab-item>

                    <!-- APPROVED -->
                    <v-tab-item>
                        <DebitMemoComponentVue :status_text="'approved'" :print="print"></DebitMemoComponentVue>
                    </v-tab-item>

                    <!-- CANCELED -->
                    <v-tab-item>
                        <DebitMemoComponentVue :status_text="'canceled'" :print="print"></DebitMemoComponentVue>
                    </v-tab-item>

                    <v-tab-item>
                        <DebitMemoTypeComponent></DebitMemoTypeComponent>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-app>



</v-container>
</template>
<script>
    import DebitMemoComponentVue from './DebitMemoComponent.vue';
    import DebitMemoTypeComponent from '../DebitMemoType/DebitMemoTypeComponent.vue';

    export default {
        components: {
            DebitMemoComponentVue,
            DebitMemoTypeComponent
        },
        async mounted(){
            this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
            this.$store.dispatch('getAllCustomers')
            this.$store.dispatch('getDebitMemoTypes')
        },
        data(){
            return {
                tab: null,

                print:{
                    debit_memo_num:'',
                    soa_month_year:'',
                    debit_memo_date:'',
                    customer_name:'',
                    billing_address_text:'',
                    billing_contact_person:'',
                    billing_contact_number:'',
                    debit_memo_items:[],
                    remarks:'',
                    total_amount:0,
                    created_by_name:'',
                    acct_approved_by_name:'',
                    exec_approved_by_name:'',
                }
            }
        }
    };
</script>
<style>
    #group-tab{
        padding: 0 !important;
    }
</style>
