<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Customer Classification</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="py-5">
          <v-row >
            <v-col>
              <v-text-field
                v-model="form.code"
                required
                :rules="errors.code"
                label="Code"
                outlined
                dense
               :disabled="disabled_view"

              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.name"
                required
                :rules="errors.name"
                label="Name"
                outlined
                dense
               :disabled="disabled_view"

              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="form.description" label="Description" outlined dense
               :disabled="disabled_view"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View' ">
            <v-btn class="float-center" @click="actions == 'Submit' ? addClassification() : UpdateVendorType()" :disabled="disabled_view">
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      valid: false,
      form: {
        code: '',
        name: '',
        description: ''
      },
      item: '',
      errors: {
        code: [v => !!v || 'The code is required'],
        name: [v => !!v || 'The name is required'],
        description: [v => !!v || 'The description is required'],
      },
      disabled_view:false,
    }
  },
  computed: {
    ...mapGetters(['ACTION', 'DIALOGS', 'SELECTED_CLASSIFICATION', 'NEW_CLASSIFICATION']),
    actions() {
      if (this.ACTION == 'Submit') {
        this.disabled_view = false;
        return 'Submit'
      } else if (this.ACTION == 'Update') {
        this.disabled_view = false;
        this.getAllClassficationsEdit();
        return 'Update'
      }else if (this.ACTION == 'View') {
        this.disabled_view = true;
        this.getAllClassficationsEdit();
        return 'View'
      }
    },
  },
  methods: {
    getAllClassficationsEdit(){
        this.form = {
          code: this.SELECTED_CLASSIFICATION.code,
          name: this.SELECTED_CLASSIFICATION.name,
          description: this.SELECTED_CLASSIFICATION.description,
        }
        this.item = this.SELECTED_CLASSIFICATION;
    },
    closeDialog() {
      this.resetfields()
      this.$store.commit('DIALOG', false);
      this.$store.commit('ACTION', '')
      this.resetfields();
    },
    addClassification() {
      this.$refs.form.validate()
      const code_swal_text = this.form.code ? '' : 'Code';
      const name_swal_text = this.form.name ? '' : 'Name';
      const desc_swal_text = this.form.description ? '' : 'Description'

      const array_text = [code_swal_text, name_swal_text, desc_swal_text]
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if (this.form.code && this.form.name) {
        this.$store.dispatch('addclassification', this.form);

      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    UpdateVendorType() {
      this.$refs.form.validate();
      const code_swal_text = this.form.code ? '' : 'Code';
      const name_swal_text = this.form.name ? '' : 'Name';
      const desc_swal_text = this.form.description ? '' : 'Description'

      const array_text = [code_swal_text, name_swal_text, desc_swal_text]
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if (this.form.code && this.form.name) {
        this.$store.dispatch('updateclassification', {
          id: this.item.id,
          payload: this.form,
        });
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    resetfields() {
      this.form = {
        code: '',
        name: '',
        description: ''
      };
    }
  },
  watch: {

    NEW_CLASSIFICATION: {
      handler(val) {
        this.$store.commit('DIALOG', false);
        Swal.fire({
          title: 'Success',
          icon: 'success',
          timer: 1500,
        });
        this.resetfields()
        // this.$store.dispatch('getAllClassification')
        this.$store.commit('NEW_CLASSIFICATION', false);
      }
    },
  },
};
</script>
