<template>
  <v-card elevation="20" class="">
    <!-- <buttons
      v-if="cp_text != 'is_inactive'"
      :action="buttons.actions.create"
      :button_icon="buttons.icons.create"
      :color="buttons.colors.create"
      @click="adddialog"
    ></buttons> -->
    <v-row class="mb-2">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="12" md="4" class="mr-3 d-flex justify-content-end">
            <v-text-field
                v-model="search"
                id="searchBar"
                label="Search Customer Name"
                append-icon="mdi-magnify"
                single-line
                hide-details
                @change="searchInput()"
                @click:append-icon="searchInput()"
            ></v-text-field>
        </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="item"
        :search="search"
        :options.sync="options"
        :items-per-page="10"
        :server-items-length="totalCustomers"
        :multi-sort="true"
        :loading="!loaded"
        class="w-100"
        loading-text="Loading... Please wait"
    >
        <template v-slot:item.vip="{ item }">
            <span class="badge bg-primary text-white" v-if="item.vip">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.special="{ item }">
            <span class="badge bg-primary text-white" v-if="item.special">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.is_active="{ item }">
            <span class="badge bg-primary text-white" v-if="item.is_active">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.is_store="{ item }">
            <span class="badge bg-primary text-white" v-if="item.is_store">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.is_mother_customer="{ item }">
            <span class="badge bg-primary text-white" v-if="item.is_mother_customer">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.action="{ item, index }">
        <!-- <buttons
            v-if="userAccess.view"
            :action="buttons.actions.view"
            :button_icon="buttons.icons.view"
            :color="buttons.colors.view"
            :button_text="buttons.type.text"
            :icon="buttons.type.icon"
            @click="editdialogs(item)"
        ></buttons>
        <buttons
            v-if="userAccess.edit"
            :action="buttons.actions.edit"
            :button_icon="buttons.icons.edit"
            :color="buttons.colors.edit"
            :button_text="buttons.type.text"
            :icon="buttons.type.icon"
            @click="editdialogs(item)"
        ></buttons>
        <buttons
            v-if="userAccess.delete"
            :action="buttons.actions.delete"
            :button_icon="buttons.icons.delete"
            :color="buttons.colors.delete"
            :button_text="buttons.type.text"
            :icon="buttons.type.icon"
            @click="deleteCustomer(item.id)"
        ></buttons> -->
            <v-btn text icon color="orange" v-if="userAccess.view" :disabled="disable.button && disable.index == index" >
                <v-icon class="btn-action" small @click="viewdialogs(item, index)">mdi-eye</v-icon>
            </v-btn>
            <v-btn text icon color="blue" v-if="userAccess.edit" >
                <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
            </v-btn>
            <v-btn text icon color="red" v-if="userAccess.delete" >
                <v-icon class="btn-action" small @click="deleteCustomer(item.id)">mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
        <CustomerApprovalComponentVue/>
</v-card>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import swal from 'sweetalert2';
// import buttons from "../../../layouts/Buttons/DialogButton.vue";
// import buttons_components from "../../../../../assets/js/components-js.js";
import CustomerApprovalComponentVue from '@/views/main/layouts/dialogs/Masterdata/CustomerApprovalComponent.vue';
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver');

export default {
  props: ["cp_text","component_customer"],
  components: { CustomerApprovalComponentVue },
  data() {
    return {
        // buttons: buttons_components.buttons,
        loaded: false,
        totalCustomers: 0,
        options: {},
        customers: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 10,
        search: "",
        awaitingSearch: false,
        headers: [
        { text: "Company", align: "left", value: "company_name" },
        { text: "Owner", align: "left", value: "full_name" },
        { text: "Salesman", align: "left", value: "salesman_names" },
        { text: "VIP", align: "left", value: "vip" },
        { text: "Special", align: "left", value: "special" },
        { text: "Store", align: "left", value: "is_store" },
        { text: "Mother", align: "left", value: "is_mother_customer" },
        { text: "Active", align: "left", value: "is_active" },
        { text: "Actions", align: "center", value: "action", sortable: false },
        ],
        userAccess: {
            view: false,
            create: false,
            edit: false,
            delete: false,
            edit_approve_sales: false,
            edit_approve_acct: false,
            edit_approve_exec: false,
            export_customer:false
        },
        status_text: this.cp_text,
        selected: {},
        item: [],
        disable:{
            button: false,
            index: 0,
        }
    }
  },
  mounted() {
    this.$store.dispatch(
      "checkAccessUser",
      this.$router.currentRoute.params.id
    );
    this.$store.dispatch("discounttype")
    this.$store.dispatch("getBranddiscount")
    this.getAllCustomers()
  },
  methods: {
    getAllCustomers() {
      this.item = [];
      const payload = {
        search: this.search,
        status_text: this.status_text,
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
      };
      this.loaded = this.load;
      this.$store
        .dispatch("getAllTempCustomer", payload)
        .then((response) => {
          this.loaded = true;
          let data = response.data.data;
          this.item = data.data;
          this.totalCustomers = data.total;
          this.$store.commit("NEW_CUSTOMER", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchInput() {
        this.options.page = 1
        this.getAllCustomers({ query: this.search });
    },
    async viewdialogs(item, index = -1) {
        this.$store.commit("EDIT_CUSTOMER", {});
        this.disable.button = true
        this.disable.index = index;
        if(item.action == 'update'){
            await this.$store.dispatch('getCustomerChanges',{id:item.customer_id, temp_id: item.id}).then(async (res)=>{
                this.$store.commit("EDIT_CUSTOMER", item);
                this.$store.commit("APPROVAL_DIALOG", true);
                this.$store.commit("ACTION", "View");
                this.disable.button = false
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                console.log(err)
                this.disable.button = false
            })
        }else{
            this.$store.commit("EDIT_CUSTOMER", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "View");
            this.disable.button = false
        }
    },
    editdialogs(item) {
        this.$store.commit("EDIT_CUSTOMER", {});
        this.$store.commit("EDIT_CUSTOMER", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Update");
    },
    deleteCustomer(id) {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to Delete?",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#397373",
        cancelButtonColor: "#397373",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("deletecustomer", {
              id: id,
              status_text: this.status_text,
              temp: 1,
            })
            .then((success) => {
              Swal.fire({
                text: "Delete Customer Successful!",
                icon: "success",
              });
                for(let x in this.component_customer){
                   this.$store.commit("NEW_CUSTOMER", true);
                }
            });
        }
      });
    },
    adddialog() {
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Submit");
    },
  },
  computed: {
    ...mapGetters([
      "DIALOGS",
      "GET_ALL_CUSTOMER",
      "TOTAL_CUSTOMER",
      "ACTION",
      "USER_ACCESS",
      "NEW_CUSTOMER",
      "GET_ACTIVE_TAB"
    ]),
  },
  watch: {
    options: {
      handler() {
        this.getAllCustomers();
      },
      deep: true,
    },
    NEW_CUSTOMER: {
      handler(val) {
        if (val) {
          this.item = [];
          this.loaded = false;
          this.$store.commit("DIALOG", false);
          Swal.fire({
            title: "SUCCESS",
            icon: "success",
            timer: 1500,
          });
          this.getAllCustomers();
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
          if(val != "fail"){
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.create = false;
            this.userAccess.edit_approve_sales = false;
            this.userAccess.edit_approve_acct = false;
            this.userAccess.edit_approve_exec = false;
            this.userAccess.export_customer = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }else if (e.actions_code == 'create'){
            this.userAccess.create = true;
          }else if (e.actions_code == 'edit_approve_sales'){
            this.userAccess.edit_approve_sales = true;
          }else if (e.actions_code == 'edit_approve_acct'){
            this.userAccess.edit_approve_acct = true;
          }else if (e.actions_code == 'edit_approve_exec'){
            this.userAccess.edit_approve_exec = true;
          }else if (e.actions_code == 'export_customer'){
            this.userAccess.export_customer = true;
          }
        });
        }
      },
    },
    GET_ACTIVE_TAB:{
        deep: true,
        handler(val){
            if(val == 'for_approval'){
                this.getAllCustomers()
            }
        }
    }
  },
};
</script>
