<template>
  <v-row>
    <v-container class="second-tabs-body-color">
      <!-- <buttons class="ma-2"
                v-if="userAccess.create"
                :action="buttons.actions.create"
                :button_icon="buttons.icons.create"
                :color="buttons.colors.create"
                @click="showAddContainer()"
                >
            </buttons> -->
      <div class="row mt-1" v-if="ACCESS_INBOUND.includes('create_container')">
        <div class="col-12 d-flex no-block align-items-center">
          <div class="btn-toolbar">
            <v-btn
              data-modal-id="addContainerModal"
              @click="showAddContainer()"
            >
              <v-icon>mdi-plus</v-icon>Add

              <!-- ^^ Add Container -->
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
    <v-row class="ma-0">
      <ContainersTableComponent
        ref="child"
        :containerActionResult="action"
        :is_pending="is_pending"
        :is_approved="is_approved"
        :is_receiving="is_receiving"
        :radioGroup="radioGroup"
        @showEditModal="showEditModal"
        :user_access="userAccess"
      >
      </ContainersTableComponent>
    </v-row>
    <v-app id="addEditContainerModal">
      <v-dialog
        v-model="add_edit_container_dialog"
        persistent
        max-width="90%"
        scrollable
      >
        <v-card>
          <v-row class="m-0">
            <v-col>
              <v-card-title>
                <span class="headline">{{ container_title }}</span>
              </v-card-title>
            </v-col>
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="
                  closeAddEditDialog();
                  resetFields();
                  $emit('closeModal');
                "
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-container style="background-color: white; overflow-y: scroll">
            <v-col>
              <v-card-text v-if="loaded == true">
                <form
                  class="form-horizontal"
                  action="/containers"
                  method="post"
                  @submit.prevent="onSubmit"
                >
                    <span>
                    <v-row>
                        <v-col class="pa-0">
                            <FileUploadComponentVue v-if="action == 'Add' || action == 'Edit' " ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                            <ContainerBaseFileViewerVue class="pa-0" v-if="action == 'Edit' " :container_id="container_id" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-text-field
                                v-model="container_num"
                                :disabled="is_receiving"
                                label="Container #"
                                dense
                                outlined
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="warehouse_id"
                                :items="GET_ALL_WAREHOUSES"
                                label="Warehouse"
                                outlined
                                dense
                                auto-select-first
                                hide-details
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" class="pl-2">
                            <v-text-field
                                v-model="broker"
                                label="Broker"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                v-model="remarks"
                                label="Remarks"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                        <v-col v-if="false">
                        <v-checkbox
                            label="MB"
                            true-value="1"
                            false-value="0"
                            v-model="received_type"
                        >
                        </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row class="pt-2">
                        <v-col>
                            <v-card outlined class="p-2" :loading="loading.items">
                                <div class="row mt-4" v-if="!loading.items">
                                    <div class="float-left mx-2 pa-0">
                                        <v-btn
                                            v-if="!disabled_batch_addition"
                                            :disabled="disabled_batch_addition"
                                            dark
                                            small
                                            color="primary"
                                            @click="openBatchDialog()"
                                        >
                                        <span>{{ batch_addition_text.title }}</span>
                                        </v-btn>
                                        <v-tooltip bottom color="#488363">
                                                <template v-slot:activator="{ on, attrs }" >
                                                    <v-btn
                                                        @click="importExcel()"
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        style="opacity:1; cursor: pointer;"
                                                        class="ma-2"
                                                        color="#488363"
                                                        text
                                                    >
                                                        <v-icon size="30">mdi-file-import</v-icon>
                                                    </v-btn>
                                                    <input
                                                        @change="handleUpdateFileChange"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="d-none"
                                                        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                        type="file"
                                                        id="upload-file"
                                                        ref="file"
                                                    >
                                            </template>
                                            <span>Import Excel</span>
                                        </v-tooltip>
                                    </div>
                                    <v-dialog
                                        v-model="batch_addition.dialog"
                                        persistent
                                        max-width="35%"
                                        max-height="35%"
                                        scrollable
                                    >
                                        <v-card>
                                        <v-row class="m-0">
                                            <v-col>
                                            <v-card-title>
                                                <span class="headline">{{
                                                batch_addition_text.title
                                                }}</span>
                                            </v-card-title>
                                            </v-col>
                                            <v-col cols="pull-right-10 p-2">
                                            <v-btn
                                                text
                                                icon
                                                color="gray"
                                                class="float-right"
                                                @click="
                                                closeBatchAdditionDialog();
                                                dataFromExcel = '';
                                                "
                                            >
                                                <v-icon>mdi-close-circle</v-icon>
                                            </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-card-text class="ma-0">
                                            <v-row v-if="hasError" class="pt-4 ml-2">
                                                <v-col cols="6" class="d-flex align-items-center">
                                                    <span style="color: red">Red :  </span>
                                                        Model not found in the system
                                                </v-col>
                                                <v-col cols="6" class="d-flex align-items-center pb-5">
                                                    <v-switch color="red" dense  v-model="withNotFound" :true-value="1" :false-value="0" label="Show models not found" hide-details/>
                                                </v-col>
                                            </v-row>
                                            <div
                                            class="pt-4 pa-2"
                                            style="
                                                height: auto;
                                                width: 100%;
                                                overflow-y: hidden;
                                                overflow-x: hidden;
                                            "
                                            >
                                            <v-row
                                                class="mx-0 pa-0 ma-0"
                                                v-for="(
                                                text_area_field, i
                                                ) in text_area_fields_temp"
                                                :key="i"
                                            >
                                                <div class="col-4 pa-0 ma-0">
                                                    <v-text-field
                                                        :class="{
                                                        notInModel: text_area_fields_temp[i].error,
                                                        }"
                                                        class="InModel"
                                                        :background-color="text_area_fields_temp[i].error ? '#f55246' :'none'"
                                                        dense
                                                        outlined
                                                        v-model="
                                                        text_area_fields_temp[i].dataFromExcel
                                                        "
                                                        rows="1"
                                                        small
                                                        color="primary"
                                                        label="Model"
                                                        :autofocus="batch_addition.dialog"
                                                        @paste="onPaste"

                                                        @click="$event.target.focus()"
                                                    >
                                                    </v-text-field>
                                                </div>
                                                <div class="col-3 py-0 ma-0">
                                                    <v-text-field
                                                        :class="{
                                                        notInModel: text_area_fields_temp[i].error,
                                                        }"
                                                        class="InModel"
                                                        :background-color="text_area_fields_temp[i].error ? '#f55246' :'none'"
                                                        dense
                                                        outlined
                                                        label="Quantity"
                                                        v-model="
                                                        text_area_fields_temp[i].dataFromExcel2
                                                        "
                                                        rows="1"
                                                        small
                                                        color="primary"
                                                    >
                                                    </v-text-field>
                                                </div>
                                                <div class="col-4 py-0 ma-0">
                                                    <v-text-field
                                                        :class="{
                                                        notInModel: text_area_fields_temp[i].error,
                                                        }"
                                                        class="InModel"
                                                        :background-color="text_area_fields_temp[i].error ? '#f55246' :'none'"
                                                        dense
                                                        outlined
                                                        label="PO Number"
                                                        v-model="
                                                        text_area_fields_temp[i].dataFromExcel3
                                                        "
                                                        rows="1"
                                                        small
                                                        color="primary"
                                                    >
                                                    </v-text-field>
                                                </div>
                                                <div class="col-1 ma-0">
                                                    <v-btn
                                                        fab
                                                        dark
                                                        small
                                                        color="red"
                                                        style="height: 20px; width: 20px"
                                                        @click="clearItem(i)"
                                                    >
                                                        <v-icon
                                                        dark
                                                        small
                                                        style="font-size: 12px"
                                                        >
                                                        mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-row>
                                            <v-row>
                                                <v-col class="ml-3">
                                                <v-btn
                                                    dark
                                                    small
                                                    color="primary"
                                                    style="height: 20; width: 40px"
                                                    @click="manualAddItem()"
                                                >
                                                    <span> Add </span>
                                                </v-btn>
                                                </v-col>
                                            </v-row>
                                            </div>
                                            <v-row>
                                            <div class="col-12">
                                                <v-btn
                                                :disabled="isDisabledButton"
                                                class="float-right ml-2"
                                                @click="submittedBtn()"
                                                >
                                                Submit
                                                </v-btn>
                                                <v-btn
                                                class="float-right"
                                                dark
                                                color="secondary"
                                                @click="clearFields()"
                                                >
                                                Clear
                                                </v-btn>
                                            </div>
                                            </v-row>
                                        </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                    <div class="col-sm-12 col-md-12 mt-2">
                                        <v-simple-table class="dynamic_list_table">
                                            <template v-slot:default>
                                                <thead class="mb-4">
                                                    <tr v-if="!is_receiving">
                                                        <th class="text-center mx-2" style="font-weight:bold !important" width="10%">
                                                            PO Num
                                                        </th>
                                                        <th class="text-center mx-2" width="5%">
                                                            UOM Qty
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            Qty/MB
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            MB Qty
                                                        </th>
                                                        <th class="text-center" width="15%">
                                                            Model
                                                        </th>
                                                        <th class="text-center mx-2" width="40%">
                                                            Name
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            UOM
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            Type
                                                        </th>
                                                        <th
                                                            class="text-center mx-2"
                                                            width="5%"
                                                        ></th>
                                                    </tr>
                                                    <tr v-else>
                                                        <th class="text-center mx-2" width="8%">
                                                            {{
                                                            received_type == "1"
                                                                ? "Receive MB Qty"
                                                                : "Receive UOM Qty"
                                                            }}
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            MB Qty
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            UOM Qty
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            MB Conv
                                                        </th>
                                                        <th class="text-center mx-2" width="20%">
                                                            Model
                                                        </th>
                                                        <th class="text-center mx-2" width="40%">
                                                            Name
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            UOM
                                                        </th>
                                                        <th class="text-center mx-2" width="7%">
                                                            Type
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </template>
                                        </v-simple-table>
                                        <v-card class="pa-0 overflow-auto" style="max-height:400px;" elevation="0" :loading="loading.batch">
                                            <v-simple-table class="dynamic_list_table">
                                                <template v-slot:default>
                                                    <span v-if="!is_receiving">
                                                        <tbody>
                                                            <tr
                                                            v-for="(
                                                                container_item, i
                                                            ) in container_items"
                                                            :key="i"
                                                            >
                                                            <td width="10%">
                                                                <v-text-field
                                                                v-model="container_items[i].po_num"
                                                                :id="'quantity_' + i"
                                                                class="alignText mt-2 centered-input"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="5%">
                                                                <v-text-field
                                                                hide-details
                                                                dense
                                                                outlined
                                                                class="alignText mt-2 centered-input"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="container_items[i].quantity"
                                                                @blur="computeMBQty(i)"
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td v-if="isViewEdit" width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText centered-input mt-2"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="
                                                                    container_items[i].item.master_box_qty
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td v-else width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText centered-input mt-2"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="
                                                                    container_items[i].master_box_qty
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="7%">
                                                                <v-text-field
                                                                :background-color="container_item.bg_color"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                readonly
                                                                outlined
                                                                class="alignText centered-input mt-2"
                                                                :id="'quantity_' + i"
                                                                v-model="
                                                                    container_items[i].quantity_master_box
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="15%">
                                                                <v-text-field
                                                                :background-color="container_items[i].with_duplicate ? container_items[i].color : 'grey lighten-2'"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                v-model="container_items[i].model"
                                                                option-value="value"
                                                                option-text="text"
                                                                placeholder="Model"
                                                                class="alignText centered-input mt-2"
                                                                :isError="
                                                                    container_items[i].active === 0
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="40%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="small mt-2"
                                                                v-model="container_items[i].name"
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td v-if="isViewEdit" width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText centered-input small mt-2"
                                                                v-model="
                                                                    container_items[i].item.item_uom_text
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td v-else width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText centered-input small mt-2"
                                                                v-model="
                                                                    container_items[i].item_uom_text
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText centered-input small mt-2"
                                                                v-model="
                                                                    container_items[i].item_type_text
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="5%">
                                                                <v-btn
                                                                fab
                                                                dark
                                                                small
                                                                color="primary"
                                                                style="height: 20px; width: 20px"
                                                                @click="removeOrderByIndex(i)"
                                                                >
                                                                <v-icon
                                                                    dark
                                                                    small
                                                                    style="font-size: 12px"
                                                                >
                                                                    mdi-minus
                                                                </v-icon>
                                                                </v-btn>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                    </span>
                                                    <span v-else>
                                                        <tbody>
                                                            <tr
                                                            v-for="(
                                                                container_item, i
                                                            ) in container_items"
                                                            :key="i"
                                                            >
                                                            <td width="8%">
                                                                <v-text-field
                                                                hide-details
                                                                dense
                                                                outlined
                                                                class="alignText mt-2"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="
                                                                    container_items[i].quantity_received
                                                                "
                                                                @input="computeMBQty(i)"
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText mt-2"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="
                                                                    container_items[i].quantity_master_box
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText mt-2"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="container_items[i].quantity"
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td v-if="isViewEdit" width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText mt-2"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="
                                                                    container_items[i].item.master_box_qty
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>

                                                            <td v-else width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText mt-2"
                                                                placeholder="0"
                                                                :id="'quantity_' + i"
                                                                v-model="
                                                                    container_items[i].master_box_qty
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="20%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="small mt-2"
                                                                v-model="container_items[i].model"
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="40%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="small mt-2"
                                                                v-model="container_items[i].name"
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText small mt-2"
                                                                v-model="
                                                                    container_items[i].item_uom_text
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            <td width="7%">
                                                                <v-text-field
                                                                background-color="grey lighten-2"
                                                                color="black"
                                                                hide-details
                                                                dense
                                                                outlined
                                                                readonly
                                                                class="alignText small mt-2"
                                                                v-model="
                                                                    container_items[i].item_type_text
                                                                "
                                                                >
                                                                </v-text-field>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                    </span>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                    </span>
                  <div class="border-top">
                    <div class="card-body py-4 d-flex justify-content-center">
                      <v-btn
                        :loading="waitForProcess"
                        :disabled="hasDuplicates"
                        @click="addNewContainer()"
                        v-if="action == 'Add'"
                      >
                        Submit</v-btn
                      >
                      <v-btn
                        :loading="waitForProcess"
                        :disabled="hasDuplicates"
                        @click="editContainer()"
                        v-else-if="action == 'Edit'"
                      >
                        Update</v-btn
                      >
                    </div>
                  </div>
                </form>
              </v-card-text>

              <v-card-text v-else-if="loaded == false">
                <div class="text-center">
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-col>
          </v-container>
        </v-card>
      </v-dialog>
    </v-app>
  </v-row>
</template>

<script>
// import usersTable from './tables/StockAdjustmentTable.vue';
// import batchAddtionDialog from '../../layouts/dialogs/IT/StockAdjustmentDialog.vue';
// import Dialogs from '../../layouts/dialogs/Dialog.vue'
// import buttons from '@/views/main/layouts/Buttons/DialogButton.vue';
// import buttons_components from '@/assets/js/components-js.js';
import ContainersTableComponent from "./tables/ContainersTableComponent.vue";
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
import ContainerBaseFileViewerVue from '@/views/main/modules/Operations/Containers/base/ContainerBaseFileViewer.vue';
import * as XLSX from 'xlsx';

export default {
  props: ["is_pending", "is_receiving", "is_approved", "radioGroup"],
  components: {
    // buttons,
    // buttons_components,
    ContainersTableComponent,
    FileUploadComponentVue,
    ContainerBaseFileViewerVue
  },
  data() {
    return {
        color: "orange",
        // buttons:buttons_components.buttons,
        action: "",
        batch_addition: {
            dialog: false,
            data: [],
            excel_item_id: null,
            errors: [],
        },
        dataFromExcel: "",
        dataFromExcel2: "",
        editContainerId: "",
        add_edit_container_dialog: false,
        container_num: "",
        loading_list_id: "",
        loading_lists: [],
        warehouse_id: "",
        warehouses: [],
        broker: "",
        remarks: "",
        container_items: [],
        text_area_fields: [],
        received_type: "1",
        text_area_field: {
            dataFromExcel: "",
            dataFromExcel2: "",
        },
        container_item: {
            item_id: "",
            quantity: "",
            quantity_entered: "",
            remarks: "",
            packing_type: "",
            id: "",
            active: "",
            model: "",
            name: "",
            type_id: "",
            item_type_text: "",
            uom_id: "",
            item_uom_text: "",
            master_box_qty: "",
            small_box_qty: "",
            quantity_master_box: "",
            loading_list_item_id: "",
        },
        scanner: {
            item_model: "",
            item_barcode: 0,
            quantity: 0,
        },
        autoOpenCamera: 0,
        date_created: "",
        user: "",
        items_list: [],
        status: "",
        max_items_count: 999,
        errors: [],
        action: "",
        actionResult: {
            model: "Container",
            action: "",
        },
        loaded: false,
        userAccess:{
            create:false
        },

        packing_type_list: [
            { value: 1, text: "UOM" },
            { value: 2, text: "MB" },
            { value: 3, text: "SB" },
        ],

        waitForProcess: false,
        po_id: {
            selection: [],
            selected: "",
        },
        received_date: null,
        selected_po_number: "",
        isDone: true,
        isViewEdit: false,
        hasError: true,
        isDisabledButton: true,
        listOfNotInModels: [],
        counterBatch: 0,
        container_item_id: "",
        batch_addition_text: {
            title: "Batch Addition",
            quantity_text: "Quantity",
        },
        receiving_data: [],
        container_title: "Container",
        disabled_batch_addition: false,
        loading: {
            warehouse_selection: true,
            items: false,
            batch: false
        },
        uploadedFiles: null,
        container_id: '',
        withNotFound: 0,
        temp_text_fields:[],
        importContainerWithPO: null,
        containersData: [],
        imported: 0,
        hasDuplicates: false,
        text_area_fields_temp: [],
    };
  },
  methods: {
    onScanSuccess(decodedText, decodedResult) {},
    calculateFinalQty(index) {
      if (this.container_items[index].item_id != "") {
        let multiplier = 0;
        switch (this.container_items[index].packing_type) {
          case 1:
            multiplier = 1;
            break;
          case 2:
            multiplier = this.container_items[index].item.master_box_qty;
            break;
          case 3:
            multiplier = this.container_items[index].item.small_box_qty;
            break;
        }
        let quantity_entered =
          this.container_items[index].quantity_entered == ""
            ? 0
            : parseInt(this.container_items[index].quantity_entered);
        this.container_items[index].quantity = quantity_entered * multiplier;
      }
    },
    checkMBMoq(index) {
      let mb_qty = 0;
      if (this.isViewEdit) {
        mb_qty =
          this.container_items[index].quantity /
          this.container_items[index].item.master_box_qty;
      } else {
        mb_qty =
          this.container_items[index].quantity /
          this.container_items[index].master_box_qty;
      }

      if (mb_qty % 1 != 0) {
        swal
          .fire(
            "",`UOM qty does not convert to MB qty properly (Result: ${mb_qty}). \n\nPlease check item MB conversion or UOM qty entered`,
            "warning"
          )
        //   .then((result) => {
            this.container_items[index].quantity_master_box = 0;
            return 0;
        //   });
      } else {
        return mb_qty;
      }
    },
    myFunction() {
    //   this.text_area_fields.splice(0, 1);
      this.batch_addition.dialog = true;
    },
    closeBatchAdditionDialog() {
      this.batch_addition.dialog = false;
    },
    closeAddEditDialog() {
        this.add_edit_container_dialog = false;
        this.isViewEdit = false;
        this.text_area_fields_temp = [];
        this.hasDuplicates = false;
    },
    openBatchDialog() {
      this.batch_addition.dialog = true;
      this.withNotFound = 0;
      this.isDone = true;
      this.text_area_fields_temp = [];

      if (this.text_area_fields_temp.length < 1) {
        this.text_area_fields_temp.push({
          dataFromExcel: "",
          dataFromExcel2: "",
          error: false,
        });
      }
      this.batch_addition.data = [];
      this.listOfNotInModels = [];
      this.hasError = false;
    },
    async onPaste(evt) {
        // this.batch_addition.data = [];
        // this.text_area_fields = []
        var pastedText = evt.clipboardData.getData("Text");
        let master_box_qty = [];
        if (!pastedText) {
            return false;
        } else {
            var result = []
            var rows = pastedText.split("\r\n");
            for (let y = 0; y < rows.length; y++) {
                let cells = rows[y].split("\t");
                if (cells[0] !== '') {
                    result.push({
                        dataFromExcel: cells[0],
                        dataFromExcel2: cells[1],
                        dataFromExcel3: cells[2] ?? null,
                        error: false,
                    });
                    if (this.is_receiving) {
                        this.receiving_data.push(Object.assign({}, {
                            model: cells[0].trim(),
                            qty: cells[1].trim(),
                            po_num: cells[2] ? cells[2].trim() : null,
                            id: "",
                            skip: false,
                        }));
                    } else {
                        this.batch_addition.data.push(Object.assign({}, {
                            model: cells[0].trim(),
                            qty: cells[1].trim(),
                            po_num: cells[2] ? cells[2].trim() : null,
                            id: "",
                            skip: false,
                        }));
                    }
                }
            }
            let payload = {
                items: result.map((e) => {
                    return e.dataFromExcel;
                }),
                isChecking: true,
            };
            await this.$store.dispatch("getItemsByModel", payload).then((response) => {
                response.data = this.GET_ITEMS_BY_MODELS.map((e) => {
                    return e.model;
                });
                master_box_qty = this.GET_ITEMS_BY_MODELS.map((x) => {
                    return {
                        model: x.model,
                        master_box_qty: x.master_box_qty
                    };
                });

                // let invalid_mb = 0;

                result.forEach((e, i) => {
                    master_box_qty.forEach((q , index)=>{
                        if (response.data.includes(e.dataFromExcel.toUpperCase())) {
                            if(e.dataFromExcel == q.model){
                                if((e.dataFromExcel2 / q.master_box_qty) % 1 != 0){
                                    // invalid_mb++
                                    e.invalid_mb = '#f55246'
                                }
                            }
                        } else {
                            this.listOfNotInModels.push(e.dataFromExcel.toUpperCase());
                            result[i].error = true;
                        }
                    });
                })
                // if(invalid_mb > 0){
                //     swal.fire(
                //         'There are items that have MB conversion error.',
                //         'Please see items highlighted in red.',
                //         'warning'
                //     )
                // }
                if (this.listOfNotInModels.length > 0) {
                    this.isDisabledButton = true;
                    this.hasError = true;
                }else{
                    this.hasError = false;
                    this.isDisabledButton = false;
                }
            });
            this.text_area_fields_temp = result
            this.text_area_fields.push(...result)
            this.isDone = false;
            this.closeBatchAdditionDialog();
            setTimeout(this.myFunction, 1);
        }
    },
    submittedBtn() {
      if (this.isViewEdit == true && !this.is_receiving) {
        for (var i = 0; i < this.text_area_fields.length; i++) {
          this.container_items.push({
            container_id: this.editContainerId,
            id: this.container_num,
            item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
            quantity: parseInt(
              this.text_area_fields[i].dataFromExcel2
                .toString()
                .replace(",", "")
            ),
            quantity_entered: parseInt(
              this.text_area_fields[i].dataFromExcel2
                .toString()
                .replace(",", "")
            ),
            remarks: "",
            packing_type: "",
            item: {
              id: "",
              active: "",
              model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
              name: "",
              type_id: "",
              item_type_text: "",
              uom_id: "",
              item_uom_text: "",
              master_box_qty: "",
              small_box_qty: "",
            },
            quantity_master_box: "",
            quantity_entered: parseInt(
              this.text_area_fields[i].dataFromExcel2
                .toString()
                .replace(",", "")
            ),
            remarks: "",
            uom_id: "",
            quantity_master_box_conv: "",
            model: this.text_area_fields[i].dataFromExcel,
            loading_list_item_id: "",
            item_uom_text: "",
            item_type_text: "",
            po_num: this.text_area_fields[i].dataFromExcel3 ?? null,
            uploaded_mb_qty: this.text_area_fields[i].dataFromExcel4,
            import: this.text_area_fields[i].dataFromExcel5 ?? 0,
          });
        }
        this.fetchAllData();
        this.counterBatch++;
        this.batch_addition.dialog = false;
      } else if (this.isViewEdit == false && !this.is_receiving) {
        this.container_items = []
        this.isViewEdit = false;
        if (this.container_items.length < this.max_items_count) {
          if (this.counterBatch == 0) {
            this.container_items.pop();
          }
          for (var i = 0; i < this.text_area_fields.length; i++) {
            this.container_items.push({
                item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                quantity: parseInt(
                    this.text_area_fields[i].dataFromExcel2
                    .toString()
                    .replace(",", "")
                ),
                quantity_entered: "",
                quantity_received: "",
                remarks: "",
                packing_type: "",
                id: "",
                active: "",
                model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                name: "",
                type_id: "",
                item_type_text: "",
                uom_id: "",
                item_uom_text: "",
                master_box_qty: "",
                small_box_qty: "",
                quantity_master_box: "",
                loading_list_item_id: "",
                po_num: this.text_area_fields[i].dataFromExcel3 ?? null,
                uploaded_mb_qty: this.text_area_fields[i].dataFromExcel4 ?? null,
                import: this.text_area_fields[i].dataFromExcel5 ?? 0,
            });
          }
          this.fetchAllData();
          this.counterBatch++;
          this.batch_addition.dialog = false;
        //   this.disabled_batch_addition = true;
        }
      }
      if (this.is_receiving) {
        let raw_model = this.text_area_fields.map((e) => {
          return e.dataFromExcel.toUpperCase();
        });
        let counter = 0;
        let x = 0;
        let container_models = this.container_items.map((e) => {
          return e.model;
        });
        try {
            while (x < this.text_area_fields.length) {
                this.container_items.forEach((e) => {
                if (counter == this.text_area_fields.length) {
                    this.batch_addition.dialog = false;
                    x = this.text_area_fields.length;
                } else if (
                    container_models.includes(
                    this.text_area_fields[counter].dataFromExcel.toUpperCase()
                    )
                ) {
                    if (raw_model.find((element) => element === e.model)) {
                        if (
                            e.model ==
                            this.text_area_fields[counter].dataFromExcel.toUpperCase()
                        ) {
                            e.quantity_received =
                            this.text_area_fields[counter].dataFromExcel2;
                            counter++;
                            x++;
                        }
                    }
                } else {
                    x = this.text_area_fields.length + 1;
                }
                });
                if (x === this.text_area_fields.length + 1) {
                    swal.fire(
                        "Item not found in this container",
                        "Please check your item",
                        "error"
                    );
                    break;
                }
            }
            this.batch_addition.dialog = false;
        } catch (e) {
            swal.fire("Someting went wrong", e + "", "error");
            console.log(e);
        }
      }
    },
    computeMBQty(index) {
        let arr = [];
        if (this.is_receiving) {
            // if (this.container_items[index].item_id != '') {
            //     if (this.container_items[index].quantity_received > this.container_items[index].quantity_master_box) {
            //         this.container_items[index].quantity_received = this.container_items[index].quantity_master_box
            //     }
            // }
        } else {
            let mb_qty = this.checkMBMoq(index);
            if (this.container_items[index].item_id != null) {
                if(mb_qty != 0){
                    this.container_items[index].bg_color = this.isViewEdit ? '' : 'grey lighten-2'
                }
                else{
                    this.container_items[index].bg_color = '#f55246'
                }
                this.container_items[index].quantity_master_box = mb_qty;
                arr = this.container_items;
                this.container_items = [];
                this.container_items = arr;
            }
            else {
            this.container_items[index].quantity_master_box = 0;
            }
        }
    },
    showEditModal(value, is_receiving = false) {
        this.add_edit_container_dialog = true;
        this.loading.items = true;

        this.action = "Edit";
        this.editContainerId = value;
        this.container_id = value;
        this.text_area_fields.length = 0;
        this.batch_addition.data = [];
        this.listOfNotInModels = [];
        this.isViewEdit = true;
        this.disabled_batch_addition = false;
        if (this.is_receiving) {
            this.batch_addition_text.quantity_text = "Receive MB Quantity";
            this.container_title = "Container (Receiving)";
        }
        if (this.editContainerId != null && this.editContainerId > 0) {
            this.$store
            .dispatch("viewContainers", this.editContainerId)
            .then((response) => {
                let data = response.data;

                this.container_item_id = data.id;
                this.container_num = data.container_num;
                this.warehouse_id = data.warehouse_id;
                this.loading_list_id = data.loading_list_id;
                this.broker = data.broker;
                this.remarks = data.remarks;
                this.container_items = data.container_items;
                this.selected_po_number = data.po_id;
                this.status = data.status;
                this.received_date = data.received_date;

                this.container_items.forEach((e)=>{
                    if( e.po_num != null && e.import == 1 && (e.uploaded_mb_qty != e.quantity_master_box_conv) ){
                        e.with_duplicate = true;
                        e.color = "#f69f1a"
                        swal.fire(
                            "There are items whose MB Qty do not match the uploaded data!",
                            "Please see items highlighted in orange.",
                            "warning"
                        )
                    }
                })

                if (
                !!this.po_id.selection.find(
                    (e) => e.value === this.selected_po_number
                )
                ) {
                this.selected_po_number = this.po_id.selection.find(
                    (e) => e.value === this.selected_po_number
                );
                this.po_id.selected = this.selected_po_number.value;
                }
                this.loading.items = false
                // this.add_edit_container_dialog = true;
            })
            .catch((error) => {
                console.log(error);
                this.loading.items = false
            });
        }
    },
    removeItem() {
      if (this.container_items.length > 1) {
        this.container_items.pop();
      }
    },
    clearFields() {
      this.isDone = true;
      this.text_area_fields_temp = [];
      if (this.text_area_fields_temp.length < 1) {
        this.text_area_fields_temp.push({
          dataFromExcel: "",
          dataFromExcel2: "",
          error: false,
        });
      }
      this.batch_addition.data = [];
      this.listOfNotInModels = [];
      this.isDisabledButton = false;
      this.hasError = false;
    },
    clearItem(i) {
      if (this.text_area_fields.length == 1) {
        this.clearFields();
      } else {

        if (!this.listOfNotInModels.length > 1) {
            this.isDisabledButton = false;
        }

        this.text_area_fields = this.text_area_fields.filter((e, index) => {
            if (i != index) {
                return e
            }
        });

        this.text_area_fields_temp = this.text_area_fields_temp.filter((e, index) => {
            if (i != index) {
                return e
            }
        });

        if(this.text_area_fields.filter(e=>e.error == true).length > 0 || this.text_area_fields_temp.filter(e=>e.error == true).length > 0){
            this.isDisabledButton = true;
            this.hasError = true;
        }else{
            this.isDisabledButton = false;
            this.hasError = false;
        }
      }
    },
    removeOrderByIndex(index) {
      if (this.container_items.length == 1) {
        this.container_items = [];
        this.container_items.push({
          item_id: "",
          quantity: "",
          quantity_entered: "",
          quantity_received: "",
          remarks: "",
          packing_type: "",
          id: "",
          active: "",
          model: "",
          name: "",
          type_id: "",
          item_type_text: "",
          uom_id: "",
          item_uom_text: "",
          master_box_qty: "",
          small_box_qty: "",
          quantity_master_box: "",
          loading_list_item_id: "",
        });
        return false;
      } else {
        let deleted_model = this.container_items[index].model
        this.container_items.splice(index, 1);
        const pos = this.text_area_fields.map(e => e.model).indexOf(deleted_model);
        this.text_area_fields.splice(pos, 1);

        let models = this.container_items.map((e) => {
            return e.model.toUpperCase();
        });

        let filteredModel = models.filter(function (model, pos) {
            return models.indexOf(model) != pos;
        });

        if (filteredModel.length == 0) {
            this.hasDuplicates = false
        }
        this.container_items.forEach((e)=>{
            if(e.model == deleted_model){
                if(this.container_items.filter((x)=> x.model == e.model).length == 1){
                    e.with_duplicate = false;
                    if(e.po_num != null && e.import == 1 && (e.uploaded_mb_qty != ( this.isViewEdit == true ? e.item.master_box_qty : e.master_box_qty ))){
                        e.with_duplicate = true;
                        e.color = "#f69f1a"
                    }
                }
                else{
                    e.with_duplicate = true
                }
            }
        })
      }
    },
    addItem() {
      if (this.container_items.length < this.max_items_count) {
        this.container_items.push({
          item_id: "",
          quantity: "",
          quantity_entered: "",
          quantity_received: "",
          remarks: "",
          packing_type: "",
          id: "",
          active: "",
          model: "",
          name: "",
          type_id: "",
          item_type_text: "",
          uom_id: "",
          item_uom_text: "",
          master_box_qty: "",
          small_box_qty: "",
          quantity_master_box: "",
          loading_list_item_id: "",
        });
      }
    },
    showAddContainer() {
      this.container_items = [];
      this.isDone = true;
      this.text_area_fields.length = 0;
      this.batch_addition.data = [];
      this.listOfNotInModels = [];
      this.isViewEdit = false;
      this.counterBatch = 0;
      this.disabled_batch_addition = false;
      if (this.po_id.selection.find((e) => e.value === this.po_id.selected)) {
        this.selected_po_number = this.po_id.selection.find(
          (e) => e.value === this.po_id.selected
        ).text;
      }
      this.add_edit_container_dialog = true;
      this.action = "Add";
    },
    getWarehouseItemStock(item_id, warehouse_id, index) {
      let payload = {
        item_id: item_id,
        warehouse_id: warehouse_id,
      };
      this.$store
        .dispatch("getWarehouseItemStocks", payload)
        .then((response) => {
            let data = response.data;
            if (data != null) {
                if (this.container_items.length > 0) {
                this.container_items[index].stock = data;
                }
            } else {
                this.container_items[index].stock = 0;
            }
        });
    },
    async addNewContainer() {
      this.waitForProcess = true;
      this.errors = [];
      this.counterBatch = 0;
      let payload = {
        container_num: this.container_num,
        warehouse_id: this.warehouse_id,
        loading_list_id: this.loading_list_id,
        broker: this.broker,
        remarks: this.remarks,
        container_items: this.container_items,
        po_id: this.po_id.selected,
        imported: this.imported
      };
      await this.$store
        .dispatch("addNewContainers", payload)
        .then(async (response) => {
          let data = this.ADD_NEW_CONTAINERS;
          if (data.error) {
            swal.fire("", data.error, "warning");
            this.waitForProcess = false;
            return false;
          }
          this.resetFields();
          this.add_edit_container_dialog = false;
          this.action = "success";
          swal.fire("", data.msg, "success");
          this.waitForProcess = false;

            // Upload Container Files
            const formData = new FormData();

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            formData.append('container_id', response.data.container_id);

            if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for (let i = 0; i < _.compact(files).length; i++) {
                    formData.append("files[]", files[i]);
                }
            }

            let payload = {
                config: config,
                data: formData,
            }

            await this.$store.dispatch('uploadContainerFiles', payload)

          this.$refs.child.getAllContainers();
        })
        .catch((error) => {
          if (error) {
            this.errors = error;
            this.waitForProcess = false;
          }
          this.waitForProcess = false;
        });
    },
    editContainer() {
      this.waitForProcess = true;
      this.errors = [];
      let not_received = [];
      this.container_items.forEach((e) => {
        if (e.quantity_received == 0) {
          not_received.push(e.model);
        }
      });
      if (not_received.length > 0 && this.is_receiving) {
        swal
          .fire({
            title: "Some items has 0 Receive MB Quantity",
            text:
              "" +
              not_received.join("\n") +
              "\n\n Do you still want to continue? \n",
            icon: "warning",
            buttons: true,
          })
          .then((confirm) => {
            if (confirm) {
              this.saveEditContainer();
              this.$refs.child.getAllContainers();
            } else {
              this.waitForProcess = false;
              return false;
            }
          });
      } else {
        this.saveEditContainer();
      }
    },
    resetFields() {
      this.errors = [];
      this.container_num = "";
      this.warehouse_id = "";
      this.loading_list_id = "";
      this.container_items = [];
      this.broker = "";
      this.remarks = "";
      this.user = "";
      this.action = "";
      this.po_id.selected = "";
    },
    addKeyListener() {
      window.addEventListener(
        "keyup",
        function (e) {
          var keypressed = String.fromCharCode(e.keyCode);
          if (e.ctrlKey && e.keyCode == 32) {
            this.addItem();
            $("input").blur();
          } else if (keypressed == "-") {
            this.removeItem();
            $("input").blur();
          }
        }.bind(this)
      );
    },
    async fetchAllData() {
        this.loading.batch = true;
        if (this.is_receiving) {
            return false;
        }
        if (this.isViewEdit == true) {
            // let this = this;
            let duplicate_models = [];
            try {
            let dataCounter = 0;
            let models = this.text_area_fields.map((e) => {
                return e.model;
            });

            let new_models = this.container_items.map((e) => {
                return e.model;
            });

            let mb_qty = this.container_items.map((e) => {
                return e.master_box_qty;
            });

            let filteredModel = new_models.filter(function (model, pos) {
                return new_models.indexOf(model) != pos;
            });

            duplicate_models.push(filteredModel);
            let showItemsAsString = duplicate_models.join("\r\n");

            if (filteredModel.length > 0) {
                this.hasDuplicates = true;
                swal
                .fire({
                    title: "Warning",
                    html:
                    "Duplicate models detected <br> NOTE: Please Check Items highlighted in violet. <br>" +
                    showItemsAsString.split(",").join("\r\n"),
                    showConfirmButton: true,
                    icon: 'warning'
                })
            }
            let payload = {
                items: new_models,
                isChecking: false,
            };
            await this.$store.dispatch("getItemsByModel", payload).then((response) => {
                let designatedIndex = this.container_items.length - this.GET_ITEMS_BY_MODELS.length;
                let notInModelsCount = this.text_area_fields.length - this.GET_ITEMS_BY_MODELS.length;
                let notFoundModels = [];
                for ( let x = designatedIndex - notInModelsCount; x < this.container_items.length; x++ ) {
                    // let data = this.GET_ITEMS_BY_MODELS[dataCounter];

                    let data = this.GET_ITEMS_BY_MODELS.find((d)=>d.model == this.container_items[x].model)

                    if(data){
                        this.container_items[x].container_id = this.editContainerId;
                        this.container_items[x].id = this.container_item_id;
                        this.container_items[x].item_id = data.item_id;
                        this.container_items[x].quantity_entered =
                            this.container_items[x].quantity;
                        this.container_items[x].remarks = this.remarks;
                        this.container_items[x].item_type_text = data.item_type_text;
                        this.container_items[x].pending_quantity_received =
                            this.container_items[x].quantity;
                        this.container_items[x].model = data.model;
                        this.container_items[x].name = data.name;
                        this.container_items[x].quantity_master_box_conv =
                            this.container_items[x].quantity;
                        this.container_items[x].received_type = 1;
                        this.container_items[x].uom_id = data.uom_id;

                        this.container_items[x].item.active = data.active;
                        this.container_items[x].item.model = data.model;
                        this.container_items[x].item.name = data.name;
                        this.container_items[x].item.item_type_text = data.item_type_text;
                        this.container_items[x].item.type_id = data.type_id;
                        this.container_items[x].item.uom_id = data.uom_id;
                        this.container_items[x].item.item_uom_text = data.item_uom_text;
                        this.container_items[x].item.master_box_qty = data.master_box_qty;
                        this.container_items[x].item.small_box_qty = data.small_box_qty;

                        if(this.container_items[x].po_num != null && this.container_items[x].import == 1 && (this.container_items[x].uploaded_mb_qty != data.master_box_qty) ){
                            this.container_items[x].with_duplicate = true;
                            this.container_items[x].color = "#f69f1a"
                            swal.fire(
                                "There are items whose MB Qty do not match the uploaded data!",
                                "Please see items highlighted in orange.",
                                "warning"
                            )
                        }

                        if(this.container_items.filter((y)=> y.model == data.model).length > 1){
                            this.container_items.forEach((d)=>{
                                if(d.model == data.model){
                                    d.with_duplicate = true;
                                    d.color = "#6e7cdf"

                                }
                            })
                        }

                        this.container_items[x].item.id = data.item_id;
                        if (this.container_items[x].item_id != "") {
                            let quo =
                            this.container_items[x].quantity / data.master_box_qty;
                            data.quantity_master_box = quo;
                        } else {
                            data.quantity_master_box = 0;
                        }
                        this.container_items[x].quantity_master_box = data.quantity_master_box;
                        this.container_items[x].po_num = this.container_items[x].po_num ? this.container_items[x].po_num : (this.text_area_fields[x] ? this.text_area_fields[x].dataFromExcel3 : null);
                        this.container_items[x].uploaded_mb_qty = this.container_items[x].uploaded_mb_qty? this.container_items[x].uploaded_mb_qty : (this.text_area_fields[x]? this.text_area_fields[x].dataFromExcel4 : null);
                        this.container_items[x].import = this.container_items[x].import ? this.container_items[x].import : (this.text_area_fields[x] ? this.text_area_fields[x].dataFromExcel5 : 0);
                    }
                    else{
                    //     this.container_items.splice(x, 1);
                        notFoundModels.push(this.container_items[x].model)
                    }
                    this.text_area_fields = [];
                    dataCounter++;
                }

                if(notFoundModels.length != 0){
                    let showNotFoundAsString = notFoundModels.join("\r\n");
                    showNotFoundAsString.split(",").join("\r\n"),

                    swal.fire({
                        title: "The following model/s are not found in the system.",
                        html: showNotFoundAsString,
                        icon: "warning"
                    });

                    this.container_items.forEach((d, i)=>{
                        if(notFoundModels.includes(d.model)){
                            this.container_items.splice(i, 1)
                        }
                    })
                }

                this.loading.batch = false;
            });
            } catch (err) {
            console.log(err);
            }
        } else {
            this.isViewEdit = false;
            // let this = this;
            let duplicate_models = [];
            let notFoundModels = [];

            try {
            let models = this.text_area_fields.map((e) => {
                return e.dataFromExcel.toUpperCase();
            });
            let mb_qty = this.container_items.map((e) => {
                return e.master_box_qty;
            });
            let filteredModel = models.filter(function (model, pos) {
                return models.indexOf(model) != pos;
            });
            duplicate_models.push(filteredModel);
            let showItemsAsString = duplicate_models.join("\r\n");
            if (filteredModel.length > 0) {
                this.hasDuplicates = true;
                swal
                .fire({
                    title: "Warning",
                    html:
                        "Duplicate models detected <br> NOTE: Please Check Items highlighted in violet. <br>" +
                    showItemsAsString.split(",").join("\r\n"),
                    icon: 'warning',
                    buttons: true,
                });
            }
            let quantities = this.container_items.map((e) => {
                return e.quantity;
            });
            let quantity_master_boxes = this.container_items.map((e) => {
                return e.quantity_master_box;
            });
            let payload = {
                items: models,
                isChecking: false,
            };
            let arr = this.container_items;
            //   this.container_items = [];
                this.$store.dispatch("getItemsByModel", payload).then((response) => {
                    this.container_items = [];
                    this.container_items.push(...this.text_area_fields.filter((e, i) => {
                        let itemData = this.GET_ITEMS_BY_MODELS.find((d)=>d.model == e.dataFromExcel)
                        if(itemData){
                            e.quantity_entered = e.quantity;
                            e.remarks = this.remarks;
                            e.item_type_text = itemData.item_type_text;
                            e.pending_quantity_received = e.quantity;
                            e.model = itemData.model;
                            e.name = itemData.name;
                            e.quantity_master_box_conv = e.quantity;
                            e.received_type = 1;
                            e.uom_id = itemData.uom_id;

                            e.active = itemData.active;
                            e.model = itemData.model;
                            e.name = itemData.name;
                            e.item_type_text = itemData.item_type_text;
                            e.type_id = itemData.type_id;
                            e.uom_id = itemData.uom_id;
                            e.item_uom_text = itemData.item_uom_text;
                            e.master_box_qty = itemData.master_box_qty;
                            e.small_box_qty = itemData.small_box_qty;

                            e.name = itemData.name;
                            e.quantity = quantities[i];
                            e.model = models[i];
                            e.quantity_master_box = quantity_master_boxes[i];
                            e.po_num = this.text_area_fields[i].dataFromExcel3 ?? null
                            e.uploaded_mb_qty = this.text_area_fields[i].dataFromExcel4 ?? null
                            e.import = this.text_area_fields[i].dataFromExcel5 ?? 0
                            e.with_duplicate = false;

                            if( e.dataFromExcel5 == 1 && e.po_num != null && e.import == 1 && (e.uploaded_mb_qty != e.master_box_qty)){
                                e.with_duplicate = true;
                                e.color = "#f69f1a"
                                swal.fire(
                                    "There are items whose MB Qty do not match the uploaded data!",
                                    "Please see items highlighted in orange.",
                                    "warning"
                                )
                            }

                            if(this.text_area_fields.filter((y)=> y.dataFromExcel == e.dataFromExcel).length > 1){
                                e.with_duplicate = true;
                                e.color = '#6e7cdf';
                                // this.container_items[i].with_duplicate = true;
                            }
                            if (e.quantity % itemData.master_box_qty != 0) {
                                swal
                                .fire(
                                    'There are items that have MB conversion error.',
                                    'Please see items highlighted in red.',
                                    'warning'
                                )
                                //   .then((result) => {
                                this.container_items = []
                                this.container_items = arr;
                                e.quantity = 0;
                                e.quantity_master_box = 0;
                                //   });
                                e.bg_color = '#f55246'
                                e.item_id = itemData.item_id;
                                return e;
                            }
                            else {
                                if (e.item_id != "") {
                                    let quo = e.quantity / itemData.master_box_qty;
                                    e.quantity_master_box = quo;
                                } else {
                                    e.quantity_master_box = 0;
                                }
                                    e.bg_color = 'grey lighten-2'
                                    e.quantity = e.quantity;
                                    e.id = e.id;
                                    e.item_id = e.model;
                                    e.master_box_qty = e.master_box_qty;
                                e.item_id = itemData.item_id;
                                return e;
                            }
                        }
                        else{
                            notFoundModels.push(e.dataFromExcel)
                        }
                    }))
                    console.log(notFoundModels)
                    if(notFoundModels.length != 0){
                        let showNotFoundAsString = notFoundModels.join("\r\n");
                        showNotFoundAsString.split(",").join("\r\n"),

                        swal.fire({
                            title: "The following model/s are not found in the system.",
                            html: showNotFoundAsString,
                            icon: "warning"
                        });
                    }
                    this.loading.batch = false;
                });
            }
            catch (err) {
                console.log(err);
                this.loading.batch = false;
            }
        }
    },
    manualAddItem() {
        this.text_area_fields_temp.push({
            dataFromExcel: "",
            dataFromExcel2: "",
            error: false,
        });
        this.text_area_fields.push({
            dataFromExcel: "",
            dataFromExcel2: "",
            error: false,
        });
    },
    async saveEditContainer() {
      let params = {
        id: this.editContainerId,
        container_num: this.container_num,
        warehouse_id: this.warehouse_id,
        broker: this.broker,
        remarks: this.remarks,
        container_items: this.container_items,
        po_id: this.po_id.selected,
        received_type: parseInt(this.received_type),
        imported: this.imported
      };
      await this.$store
        .dispatch("editContainers", params)
        .then(async (response) => {
          let data = this.EDIT_CONTAINERS;
          if (data.error) {
            swal.fire("", data.error, "warning");
            return false;
          }
          this.resetFields();
          this.add_edit_container_dialog = false;
          this.editContainerId = 0;
          this.action = "success";
          swal.fire("", "Container Update Successful", "success");

           // Upload Container Files
            const formData = new FormData();

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            formData.append('container_id', response.container_id);

            if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for (let i = 0; i < _.compact(files).length; i++) {
                    formData.append("files[]", files[i]);
                }
            }

            let payload = {
                config: config,
                data: formData,
            }

            await this.$store.dispatch('uploadContainerFiles', payload)

          this.waitForProcess = false;
        })
        .catch((error) => {
            console.log(error)
          if (error.response.status == 422) {
            this.errors = error.this.EDIT_CONTAINERS.errors;
          }
        });
    },
    getWarehouses(no_virtual = false) {
      // let payload = {
      // }
      // this.$store.dispatch('getAllWarehouses',payload).then((response)=>{
      //     this.warehouses = this.GET_ALL_WAREHOUSES
      // });
    },
    uploadedData(data) {
        if (!data) {
            this.uploadedFiles = null;
        }
        this.uploadedFiles = data;
    },
    clearFileUpload(confirm) {
        if (confirm) {
            this.uploadedFiles = null;
        }
        this.uploadedFiles = null;
        this.$refs.fileUpload.reset();
    },
    handleUpdateFileChange() {
        // this.batch_addition.data = [];
        // this.text_area_fields = [];
        this.loaded = false;
        let that = this;
        that.importContainerWithPO = that.$refs.file.files[0];
        let fileReader = new FileReader();

        fileReader.readAsArrayBuffer(that.importContainerWithPO);
        fileReader.onload = (e) => {
            that.arrayBuffer = fileReader.result;
            let data = new Uint8Array(that.arrayBuffer);
            let arr = new Array();
            for (let i = 0; i != data.length; ++i)
                arr[i] = String.fromCharCode(data[i]);
            let bstr = arr.join("");

            let workbook = XLSX.read(bstr, { type: "binary" });

            let firstSheetName = workbook.SheetNames[0];

            let worksheet = workbook.Sheets[firstSheetName];

            if (workbook.Sheets['Table 1'] == undefined) {
                swal.fire({
                    icon: 'error',
                    title: 'Sheet "' + sheetName + '" not found'
                });
                that.loaded = true;
                return false;
            }

            let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });

            arraylist.forEach((e, i) => {
                if (e['ITEM NO.']) {
                    this.text_area_fields.push({
                        dataFromExcel: e['ITEM NO.'],
                        dataFromExcel2: e['QTY'],
                        dataFromExcel3: e['PO NO.'],
                        dataFromExcel4: e['QTY'] / e['CTNS'],
                        dataFromExcel5: 1,
                    });
                    this.batch_addition.data.push({
                        model: e['ITEM NO.'],
                        qty: e['QTY'],
                        po_num: e['PO NO.'],
                        uploaded_mb_qty: e['QTY'] / e['CTNS'],
                        import: 1,
                        id: "",
                        skip: false,
                    });
                }
            });

            this.loaded = true;

            this.submittedBtn();
        };
    },
    importExcel(){
        this.$refs.file.click();
    }

  },
  computed: {
    ...mapGetters([
      "GET_ITEMS_BY_MODELS",
      "ADD_NEW_CONTAINERS",
      "EDIT_CONTAINERS",
      "USER_ACCESS",
      "GET_ALL_WAREHOUSES",
      'ACCESS_INBOUND'
    ]),
  },
  watch: {
    add_edit_container_dialog: function () {
      if (this.add_edit_container_dialog == true) {
        this.loaded = true;

        if (this.container_items.length == 0) {
          this.addItem();
        }
      }
    },
    warehouse_id: function (val) {
        // if (val != null && val != "" && this.container_items.length > 0) {
        //     this.container_items.forEach((item, index) => {
        //     if (item.item_id != null && item.item_id != "") {
        //         this.getWarehouseItemStock(item.item_id, this.warehouse_id, index);
        //     }
        //     });
        // }
    },
    GET_ALL_WAREHOUSES: {
      handler(val) {
        this.dealersList = val.data;
        this.loading.warehouse_selection = false;
      },
    },
    withNotFound:{
        handler(val){
            if(val == 1){
                this.temp_text_fields = this.text_area_fields_temp;
                this.text_area_fields = this.text_area_fields.filter(x=>x.error == true);
                this.text_area_fields_temp = this.text_area_fields_temp.filter(x=>x.error == true);
            }
            else{
                this.text_area_fields = this.temp_text_fields;
                this.text_area_fields_temp = this.temp_text_fields;
            }
        }
    },
    importContainerWithPO:{
        handler(val){
            this.imported = val ? 1 : 0;
        }
    },
  },
  mounted() {
    // this.$store.dispatch('getWarehouseItemStocks');
    // this.getWarehouses();
    this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id).then(response=>{
        if(response != "fail"){
            response.forEach((e) => {
                    if(e.actions_code == 'create'){
                        this.userAccess.create  = true
                    }
                });
            }

    });
  },
};
</script>

<style scoped>
    .centered-input >>> input {
        text-align: center
    }
</style>
