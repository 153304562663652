<template>

<v-container fluid>
    <ComponentHeader
        class="ma-n4"
        :button="true"
        title='Debit Memo Detailed'
        type='Accounting'
    ></ComponentHeader>
    <v-row>
    <v-app id="my_vue_app" style="width:100%" class="ml-3 mr-3 bg-bluish-gray pa-3">
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <v-autocomplete
                    :items="GET_CUSTOMERS_SELECTION"
                    :loading="loading.customer_selection"
                    :disabled="loading.customer_selection"
                    v-model="selectedCustomers"
                    label="Select Customers"
                    @change="onSelectCustomers"
                    return-object
                    class="d-inline-block w-100"
                    dense
                    outlined
                    background-color="white"
                    multiple
                    >
                </v-autocomplete>
            </div>
            <!-- <div class="col-sm-12 col-md-2">
                <v-menu
                    v-model="debitMemoList.datatable.filters.soa_month.menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="debitMemoList.datatable.filters.soa_month.values"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            v-on="on"
                            style="display: block"
                            label="SOA Month"
                            background-color="white"
                        ></v-text-field>
                    </template>
                    <v-date-picker type="month" v-model="debitMemoList.datatable.filters.soa_month.values" @click:month="debitMemoList.datatable.filters.soa_month.menu = false"></v-date-picker>
                </v-menu>
            </div> -->
            <div class="col-sm-12 col-md-2">
                <v-menu
                    v-model="date_from_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                    class="mx-4"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="debitMemoList.datatable.filters.soa_month.date_from"
                            readonly
                            outlined
                            dense
                            background-color="white"
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                            label="Date From"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="debitMemoList.datatable.filters.soa_month.date_from"
                        type="month"
                        @input="date_from_menu = false"
                    ></v-date-picker>
                </v-menu>
            </div>
            <div class="col-sm-12 col-md-2">
                <v-menu
                    v-model="date_to_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="debitMemoList.datatable.filters.soa_month.date_to"
                            readonly
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                            outlined
                            dense
                            background-color="white"
                            label="Date To"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="debitMemoList.datatable.filters.soa_month.date_to"
                        type="month"
                        @input="date_to_menu = false"
                    ></v-date-picker>
                </v-menu>
            </div>
            <div class="col-sm-12 col-md-4 text-right">
                <v-btn  class="ml-1" small v-on:click="resetFields()">Clear</v-btn>
                <v-btn  class="ml-1" small v-on:click="exportDebitMemoDetailed()">Excel</v-btn>
                <v-btn  class="ml-1" small v-on:click="getDebitMemoDetailed()">Filter</v-btn>
            </div>
        </div>

            <div class="w-100 mt-2"  v-if="dataItems != ''">
            <v-card v-show="loaded">

                <v-container fluid>
                    <div class="row">
                        <!-- <div class="col-12 col-sm-6 col-md-3">
                            <div class="info-box mb-3">
                                <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Customers</span>
                                    <span class="info-box-number">{{ customer_count }}</span>
                                </div>
                            </div>
                        </div> -->
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="ml-2">
                            <v-card
                                class="total-cards"
                                elevation="2"
                            >
                                <v-card-text
                                    class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-0">Customers</p>
                                        <b style="color:black">{{ customer_count }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <div class="clearfix hidden-md-up"></div>
                    </div>

                    <div class="container" v-for="(d, i) in dataItems" :key="i" v-show="loaded">
                        <div class="row">
                            <div class="col-12 d-flex no-block align-items-cnter">
                                <h6 class="report-title">{{ d.company_name }} - {{ d.item_count }} Items</h6>
                            </div>
                        </div>

                        <v-app id="my_vue_app">
                            <v-card>
                                <v-container fluid>
                                    <v-data-table
                                        :headers="headers"
                                        :items="d.data_items"
                                        :items-per-page="10"
                                        show-expand
                                        class="w-100"
                                        @item-expanded="loadDetails"
                                        dense
                                    >
                                    <template v-slot:[`item.total_amount`] = "{item}">
                                        {{ item.total_amount | currency }}
                                    </template>

                                    <template v-slot:[`item.status`]="{ item }">
                                        <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                                        <span class="badge badge-for-accounting" v-if="item.status_text=='for accounting'">{{ item.status_text }}</span>
                                        <span class="badge badge-for-exec" v-else-if="item.status_text=='for exec'">{{ item.status_text }}</span>
                                        <span class="badge badge-approved" v-else-if="item.status_text=='approved'">{{ item.status_text }}</span>
                                        <span class="badge badge-completed" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                                        <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                                        <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                                        <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                                    </template>

                                    <template v-slot:[`item.printed`]="{ item }">
                                        <span class="badge badge-primary" v-if="item.printed">Yes</span>
                                        <span class="badge badge-danger" v-else>No</span>
                                    </template>

                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length" class="p-0">
                                            <v-simple-table dense dark >
                                                <template v-slot:default>
                                                <thead>
                                                    <td width="60%">Particulars</td>
                                                    <td width="40%" class="text-right">Total Amount</td>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(debit, i) in item.debit_items" :key="i">
                                                        <td>{{ debit.particular }}</td>
                                                        <td class="text-right">{{ debit.expand_total | currency }}</td>
                                                    </tr>
                                                </tbody>

                                                </template>
                                            </v-simple-table>
                                        </td>
                                    </template>

                                  </v-data-table>
                                </v-container>
                            </v-card>
                        </v-app>
                    </div>
                </v-container>
            </v-card>
        </div>
    </v-app>
    <v-app id="load_bar" v-if="!loaded" style="width:100%" class="ml-3 mr-3">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
</v-row>
</v-container>

</template>

<script>
    import moment from 'moment';
    import SharedFunctionsComponentVue from '../../../Shared/SharedFunctionsComponent.vue';
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    import { mapGetters } from 'vuex';
    const ExcelJS = require('exceljs')
    const FileSaver = require('file-saver');
    import swal from 'sweetalert2';
    export default {
        mixins: [ SharedFunctionsComponentVue ],
    data(){
        return {
            debitMemoList: {
                    datatable: {
                        filters: {
                            soa_month: {
                                date_to: null,
                                date_from: null,
                            },
                        },
                    }
            },
            date_to_menu:false,
            date_from_menu:false,
            customers_list:[],
            customersList: [],
            customer_count:'',
            selectedCustomers: [],

            headers: [
                    {text: 'DM #', width:"8%", value: 'debit_memo_num'},
                    {text: 'DM DATE', width:"8%", value: 'debit_memo_date'},
                    {text: 'REMARKS', width:"15%", value: 'remarks'},
                    {text: 'STATUS', width:"5%", value: 'status'},
                    {text: 'PROCESSED BY',  width:"10%", value: 'created_by_name'},
                    {text: 'ACCT APPROVED BY', width:"10%", value: 'acct_approved_by_name'},
                    {text: 'EXEC APPROVED BY', width:"10%", value: 'exec_approved_by_name'},
                    {text: 'SOA MONTH', width:"8%", align: 'center', value: 'soa_month_year'},
                    {text: 'TOTAL DM AMOUNT', width:"10", align: 'right', value: 'total_amount'},
                    {text: 'PRINTED', width:"5%" ,align: 'center', value: 'printed'},
                ],

            dataItems:[],

            loaded: true,
            options: {},
            itemsPerPageOptions: [5, 10 ,15, 50, 100, 250],
            itemsPerPage: 10,
            loading:{
                customer_selection : true
            }
            }
        },
        components: {
            ComponentHeader,
        },
        mounted: function (){
            this.getCustomers()
            this.$store.dispatch('getAllcustomersSelection')
        },
        computed:{
            ...mapGetters([
                'GET_CUSTOMERS_SELECTION'
            ])
        },
        methods: {

            getCustomers(){
                this.customers_list = this.GET_CUSTOMERS_SELECTION
                this.customers_list.push({
                    value: 'all',
                    text: 'Select All'
                })
            },

            loadDetails(item){
                if(item.value){
                    let payload = {
                        id:item.item.id,
                        params:{}
                    }
                    this.$store.dispatch('getItemDebitMemo',payload).then(response => {
                        var data = response.data
                        item.item.debit_items   = data.debit_memo
                        this.$forceUpdate()
                    })
                }
            },
            onSelectCustomers (items) {
                    this.selectedCustomers = items
                },

            async exportDebitMemoDetailed(){
                this.loaded = false
                let payload = {
                        customer_id:        this.selectedCustomers.map(e=>{return e.value}),
                        memo_type_id:       this.memo_type_id,
                        soa_month_value:    this.debitMemoList.datatable.filters.soa_month.values,
                        date_from :         this.debitMemoList.datatable.filters.soa_month.date_from,
                        date_to :           this.debitMemoList.datatable.filters.soa_month.date_to,
                        url:                "exportDebitMemoDetailed"
                    }
                let data = [];
                const workbook = new ExcelJS.Workbook();
                await this.$store.dispatch('reports',payload).then(response => {

                    const worksheet = workbook.addWorksheet(`Debit Memo Detailed Report`);
                    data = response.data
                        worksheet.columns = [
                            {header:'Customer Name',key:'customer_company_name'},
                            {header:'Debit  Memo #	',key:'debit_memo_num'},
                            {header:'Debit  Memo Date',key:'debit_memo_date'},
                            {header:'Remarks',key:'remarks'},
                            {header:'Particulars',key:'particular'},
                            {header:'Total Amount',key:'total_amount'},
                            {header:'Line DM Amount',key:'line_dm_amount'},
                            {header:'Status',key:'status_text'},
                            {header:'Processed By',key:'created_by_name'},
                            {header:'Acct Approved By',key:'acct_approved_by_name'},
                            {header:'Exec Approved By',key:'exec_approved_by_name'},
                            {header:'SOA Month',key:'soa_month_year'},
                            {header:'Printed',key:'printed'},
                        ]
                        data.forEach((e,i) => {
                            e.data_items.forEach(x=>{
                                x.total_amount = Number (x.total_amount);
                                x.line_dm_amount = Number (x.line_dm_amount);
                                x.printed = x.printed == 1 ? 'YES' : 'NO'
                                worksheet.addRow(x);
                            })
                        });
                        worksheet.getRow(`0${1}`).font = { bold: true }
                        worksheet.getColumn('F').numFmt = '#,##0.00';
                        worksheet.getColumn('G').numFmt = '#,##0.00';
                        }).catch(e => {
                            swal.fire('Error !', '', 'error')
                            console.log(e)
                            this.loaded = true
                        })
                        this.loaded = true

                        await workbook.xlsx.writeBuffer().then((data) => {
                            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                            FileSaver.saveAs(blob, `Debit Memo Detailed Report.xlsx`);
                        });
            },

            getDebitMemoDetailed(){
                    this.loaded = false
                    let payload = {
                        customer_id:        this.selectedCustomers.map(e=>{return e.value}),
                        memo_type_id:       this.memo_type_id,
                        soa_month_value:    this.debitMemoList.datatable.filters.soa_month.values,
                        date_from :         this.debitMemoList.datatable.filters.soa_month.date_from,
                        date_to :           this.debitMemoList.datatable.filters.soa_month.date_to,
                        url:                "getDebitMemoDetailed"
                    }
                    this.$store.dispatch('reports',payload).then(response => {
                        this.dataItems              = response.data.customers
                        this.customer_count         = response.data.customer_count
                        this.loaded                 = true
                    });
                },
                resetFields(){
                    this.customers_list     = []
                    this.customersList      = []
                    this.customer_count     = ''
                    this.selectedCustomers  = []
                    this.dataItems          = []
                    this.debitMemoList.datatable.filters.soa_month.values = null

                    this.getCustomers()

                },
        },watch:{
            'GET_CUSTOMERS_SELECTION':{
            handler(val){
                this.dealersList = val.data
                this.loading.customer_selection = false
            }
        },
    }

}

</script>
