import CreditMemoDetailedComponent from '@/views/main/modules/Reports/AccountingReports/CreditMemoDetailedComponent.vue'
import DebitMemoDetailedComponent from '@/views/main/modules/Reports/AccountingReports/DebitMemoDetailedComponent.vue'
import AccountReceivablesCollectionComponent from '@/views/main/modules/Reports/AccountingReports/AccountReceivablesCollectionComponent.vue'
import NetArReportComponent from '@/views/main/modules/Reports/AccountingReports/NetArReportComponent.vue'
import ChequeReportComponent from '@/views/main/modules/Reports/AccountingReports/ChequeReportComponent.vue'
import ChequeDepositComponent from '@/views/main/modules/Reports/AccountingReports/ChequeDepositComponent.vue'
import BankTransactionComponent from '@/views/main/modules/Reports/AccountingReports/BankTransactionComponent.vue'
import BouncedChequeComponent from  '@/views/main/modules/Reports/AccountingReports/BouncedChequeComponent.vue'
import CreditMemoChinaDiscrepancyReport from  '@/views/main/modules/Reports/AccountingReports/CreditMemoChinaDiscrepancyReportComponent.vue'
import UnconfirmedPackingList from  '@/views/main/modules/Reports/AccountingReports/UnconfirmedPackingList.vue'
import repository from "@/api/credentials";

const routes = [{
    path: 'reports/accounting/credit-memo-detailed/:id',
    name: 'credit-memo-detailed-component',
    component: CreditMemoDetailedComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Credit Memo Detailed Report',
    }
},
{
    path: 'reports/accounting/debit-memo-detailed/:id',
    name: 'debit-memo-detailed-component',
    component: DebitMemoDetailedComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Debit Memo Detailed Report',
    }
},
{
    path: 'reports/accounting/soa-payment-status-report/:id',
    name: 'soa-payment-status-report',
    component: AccountReceivablesCollectionComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'SOA Payment Status Report',
    }
},
{
    path: 'reports/accounting/net-ar-report/:id',
    name: 'net-ar-report',
    component: NetArReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Net A/R Report',
    }
},
{
    path: 'reports/accounting/cheque-report/:id',
    name: 'cheque-report',
    component: ChequeReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Collection Report',
    }
},
{
    path: 'reports/accounting/cheque-deposit-report',
    name: 'cheque-deposit-report',
    component: ChequeDepositComponent,
    // beforeEnter: (to, from, next) => {
    //     repository.checkAccessUser(to.params.id).then((response) => {
    //         if (response.data == 'fail') {
    //             next('/no-access')
    //         } else {
    //             next()
    //         }
    //     }).catch(() => {

    //     })
    // },
    meta: {
        requiresAuth: true,
        title: 'Cheque Deposit Report',
    }
},
{
    path: 'reports/accounting/bank-transaction-report/:id',
    name: 'bank-transaction-report',
    component: BankTransactionComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Cheque Deposit Report',
    }
},
{
    path: 'reports/accounting/bounced-cheque-report/:id',
    name: 'bounced-cheque-report',
    component: BouncedChequeComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Bounced Cheque Report',
    }
},
{
    path: 'reports/accounting/credit-memo-china-discrepancy-report/:id',
    name: 'credit-memo-china-discrepancy-report',
    component: CreditMemoChinaDiscrepancyReport,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Credit Memo China Discrepancy Report',
    }
},
{
    path: 'reports/accounting/unconfirmed-packing-list-greater-5days/:id',
    name: 'unconfirmed-packing-list-greater-5days',
    component: UnconfirmedPackingList,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Unconfirmed Delivery Receipt (> 5days)',
    }
},
]

export default routes;
