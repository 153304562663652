<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Add Salesman Position</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn
              text
              icon
              small
              color="gray"
              class="float-right"
              @click="closeDialog()"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                placeholder="Title"
                required
                v-model="form.title"
                :rules="errors.name"
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                placeholder="Description"
                required
                :rules="errors.description"
                v-model="form.description"
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View'">
            <v-btn
              class="float-center"
              @click="
                actions == 'Submit'
                  ? addSalesmanPosition()
                  : EditSalesmanPosition()
              "
               :disabled="disable_view"
            >
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
export default {
  data() {
    return {
      valid: false,
      dialog: false,
      form: {
        title: "",
        description: "",
      },
      items: "",
      errors: {
        name: [(v) => !!v || "The Title field is required"],
        description: [(v) => !!v || "The Description field is required"],
      },
      disable_view:false,
    };
  },
  methods: {
    addSalesmanPosition() {
      this.$refs.form.validate();
      const title_swal_text = this.form.title ? "" : "Title";
      const desc_swal_text = this.form.description ? "" : "description";
      const array_text = [title_swal_text, desc_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(",", "");
      });
      if (this.form.title && this.form.description) {
        {
          swal
            .fire({
              text: "Are you sure you want to create salesman position?",
              icon: "warning",
              showCancelButton: true,
              allowOutsideClick: false,
            })
            .then((confirm) => {
              if (confirm.isConfirmed) {
                this.$store.dispatch("addsalesmanposition", this.form);
              }
            });
        }
      } else {
        swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    },
    EditSalesmanPosition() {
      this.$refs.form.validate();
      const title_swal_text = this.form.title ? "" : "Title";
      const desc_swal_text = this.form.description ? "" : "description";
      const array_text = [title_swal_text, desc_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(",", "");
      });
      if (this.form.title && this.form.description) {
        {
          swal
            .fire({
              text: "Are you sure you want to edit this position?",
              icon: "question",
              showCancelButton: true,
              allowOutsideClick: false,
            })
            .then((confirm) => {
              if (confirm.isConfirmed) {
                this.$store.dispatch("editsalesmanposition", {
                  id: this.items.id,
                  payload: this.form,
                });
              }
            });
        }
      } else {
        swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    },
    closeDialog() {
      this.$store.commit("SALESMAN_POSITION_DIALOG", false);
      this.$store.commit("DIALOG", false);
      this.resetfields();
    },
    resetfields() {
      this.form = "";
    },
    getAllSalesmanpositionEdit(){
        this.form = {
          title: this.SELECTED_SALESMAN_POSITION.title,
          description: this.SELECTED_SALESMAN_POSITION.description,
        };
        this.items = this.SELECTED_SALESMAN_POSITION;
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(["SELECTED_SALESMAN_POSITION", "ACTION"]),
    actions() {
      if (this.ACTION == "Submit") {
        this.form = {
          title: null,
          description: null,
        };
        return "Submit";
      } else if (this.ACTION == "Update"){
      this.getAllSalesmanpositionEdit();
        return "Update";
      }else if (this.ACTION == "View"){
        this.disable_view = true;
      this.getAllSalesmanpositionEdit();
        return "View";
      }
    },
  },
};
</script>
