<template>
    <v-container fluid>

        <ComponentHeader
            :button="true"
            :title="'Trucks Per Day'"
            :type="'Inventory'"
        ></ComponentHeader>


        <v-container fluid class="container bg-bluish-gray default-container">
            <div class="row">
                    <div class="col-sm-6 col-md-3" id="date_from">

                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_from" class="text-warning" :key="errors">{{errors}}</span>
                </div>
                <div class="col-sm-6 col-md-3" id="date_to" style="z-index:1000">

                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                outlined
                                    dense
                                    background-color="white"
                                    label="Date To"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_to" class="text-warning" :key="errors">{{errors}}</span>
                </div>
                <div class="col-sm-12 col-md-6 text-right">
                    <v-btn small class="ml-1" @click="resetFields()" :loading="!loaded">Clear</v-btn>
                    <v-btn small class="ml-1" @click="exportTrucksPerDayReport()" :loading="!loaded">Excel</v-btn>
                    <v-btn small class="ml-1" @click="getAllData()" :loading="!loaded">Filter</v-btn>
                </div>
            </div>

            <div class="w-100"  >
                <v-card v-show="loaded">

                    <v-container fluid>
                        <div class="container" v-for="(data, i) in data_handler" :key="i" v-show="loaded">
                            <div class="row">
                                <div class="col-12 d-flex no-block align-items-center">
                                    <h6 class="report-title">{{ data.every_date }}</h6>
                                </div>
                                <div class="col-12 d-flex no-block align-items-center">
                                    <h6 class="report-title">Total Dispatch: {{ data.item_count }}</h6>
                                </div>
                            </div>

                            <v-app id="my_vue_app">
                                <v-card>
                                    <v-container fluid>
                                        <v-data-table
                                            :headers="tableHeaders"
                                            :items="data.data_items"
                                            :items-per-page="10"
                                            class="w-100"
                                            dense
                                        >
                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap" >
                                                {{ item.total_amount | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.action`]="{ item }">
                                            <v-btn text icon color="orange">
                                                <v-icon class="btn-action" small @click="viewDispatch(item.id)">fas fa-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        </v-data-table>
                                    </v-container>
                                </v-card>
                            </v-app>
                        </div>
                    </v-container>
                </v-card>
            </div>
            <v-app id="view_client_modal">
                <v-row justify="center">
                    <v-dialog v-model="dispatch_dialog" persistent max-width="98%" scrollable>
                        <v-card>
                            <v-row class="m-0">
                                <v-card-title>
                                    <span class="headline">Dispatch Details - {{ vehicle_name }}</span>
                                </v-card-title>
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn text icon color="gray" class="float-right" @click="clearDispatchItems(); $emit('closeView');">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card-text>
                                <v-data-table
                                    :headers="dispatchItemsHeaders"
                                    :items="dispatchItems"
                                    :items-per-page="500"
                                    hide-default-footer
                                    class="w-100"
                                >
                                    <template v-slot:[`item.amount`]="{ item }">
                                        <span class="text-nowrap">
                                            {{ item.amount | currency }}
                                        </span>
                                    </template>

                                </v-data-table >

                                <v-divider></v-divider>

                                <v-row class="m-0">
                                    <v-container fluid>
                                        <v-row>
                                            <div class="col-sm-12 col-md-8 text-left">
                                                <div class="row ml-2">

                                                </div>
                                                <div class="row ml-2">

                                                </div>

                                            </div>
                                            <div class="col-sm-12 col-md-4 pull-right text-right" v-if="amount_visible">
                                                <h5>Total Amount: &#x20b1;{{ total_amount | currency }}</h5>
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-row>

                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-app>
        </v-container>

    </v-container>

</template>

<script>
  import moment from 'moment';
  import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
  import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
  const ExcelJS = require('exceljs')
  const FileSaver = require('file-saver');

  export default {
    mixins: [ SharedFunctionsComponentVue ],
        data(){
            return {
                errors: [],
                data_handler: [],
                specific_day: [],
                list:[],
                loaded: true,
                dispatch_items:[],
                date_from: moment().format('YYYY-MM-DD'),
                date_to: moment().format('YYYY-MM-DD'),
                table_count:0,
                menu2: false,
                menu3: false,
                total_amount:'',
                vehicle_name:'',

                dispatch_dialog: false,

                amount_visible: false,

                tableHeaders: [
                    { text: 'ID',  value: 'id', },
                    { text: 'Vehicle',  value: 'vehicle', },
                    { text: 'Driver', value: 'driver_name', },
                    { text: 'Dispatcher', value: 'dispatcher_name', },
                    { text: 'Helper', value: 'helper', },
                    { text: 'Dispatch Date', value: 'dispatch_date', },

                ],

                dispatchItems: [],
                dispatchItemsHeaders: [
                    { text: 'Customer Name', align: 'left', value: 'customer_name', },
                    { text: 'DR #', align: 'left', value: 'pl_num', },
                    { text: 'CF #', align: 'left', value: 'cf_num', },
                    { text: 'SO #', align: 'left', value: 'so_num', },
                    { text: 'DR Date', align: 'left', value: 'pl_date', },
                    { text: 'Amount', align: 'right', value: 'amount', },
                ],
            }
        },
        mounted: function (){

        },
        components:{
          ComponentHeader
        },
        methods: {
            getAllData() {
                this.loaded = false

                this.$store.dispatch('reports',{
                        date_from:          this.date_from,
                        date_to:            this.date_to,
                        url:                'getTrucksPerDay'
                }).then(response => {
                    this.data_handler     = response.data.dispatches
                    this.amount_visible   = response.data.visible
                    this.loaded           = true
                    this.customHeaders()
                });
            },
            customHeaders(){
                if(this.amount_visible){
                    this.tableHeaders.push({ text: 'Total Amount', width:'10%', align:'right', value: 'total_amount',  }, { text: 'Action', value: 'action', })
                }else{
                    this.tableHeaders.push({ text: 'Action', value: 'action', })
                }
            },
            exportTrucksPerDayReport(){

                this.$store.dispatch(`reports`,{
                    date_from : this.date_from,
                    date_to:this.date_to,
                    url:'exportTrucksPerDayReport'
                }).then(async response =>{

                    let sheet1 = response.data.sheet1
                    let sheet2 = response.data.sheet2
                    let workbook = new ExcelJS.Workbook()
                    let worksheet1 = workbook.addWorksheet('Dispatches')
                    let worksheet2 = workbook.addWorksheet('Dispatches Details')

                    worksheet1.coluns = this.ConverToExcelJsColumns(sheet1[0])
                    worksheet2.columns = this.ConverToExcelJsColumns(sheet2[0])

                    sheet1.forEach(e => {
                        worksheet1.addRow(e)
                    });

                    sheet2.forEach(e => {
                        worksheet2.addRow(e)
                    });

                    await workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        FileSaver.saveAs(blob, `Truck Per Day ${this.date_from}-${this.date_to}.xlsx`);
                    });
                });
            },

            clearDispatchItems(){
                this.dispatch_dialog = false
            },

            resetFields(){
                this.errors                 = []
                this.date_from              = moment().format('YYYY-MM-DD')
                this.date_to                = moment().format('YYYY-MM-DD')
                this.data_handler           = []
            },

            viewDispatch(id){
                axios.post('/viewTrucksPerDay',{
                    id: id,
                }).then(response=>{
                    this.dispatchItems    = response.data.dispatch_details;
                    this.total_amount     = response.data.total_amount;
                    this.vehicle_name     = response.data.vehicle_name;
                    this.dispatch_dialog  = true

                })
            }
        }

 }


</script>
