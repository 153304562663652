<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
      <v-icon>fas fa-search</v-icon>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="GET_VEHICLES_LIST"
        :search="search"
        :items-per-page="15"
        :loading="!GET_VEHICLES_LOADED"
        :footer-props="{
            showFirstLastPage: true,
        }"
        loading-text="Loading...Please wait"
        class="w-100"
        dense
    >
      <template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
          v-if="userAccess.view"
          :action="buttons.actions.view"
          :button_icon="buttons.icons.view"
          :color="buttons.colors.view"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        >
        </buttons>
        <buttons
          v-if="userAccess.edit"
          :action="buttons.actions.edit"
          :button_icon="buttons.icons.edit"
          :color="buttons.colors.edit"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        >
        </buttons>
        <buttons
          v-if="userAccess.delete"
          :action="buttons.actions.delete"
          :button_icon="buttons.icons.delete"
          :color="buttons.colors.delete"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="deleteDepartment(item.id)"
        >
        </buttons> -->
        <v-btn text icon color="orange" v-if="userAccess.view" >
          <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
        </v-btn>
        <v-btn text icon color="blue" v-if="userAccess.edit" >
            <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn text icon color="red" v-if="userAccess.delete" >
            <v-icon class="btn-action" small @click="deleteDepartment(item.id)">mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.tpl="{ item }">
            <v-avatar
            :color="item.tpl == 1 ? 'success' : 'error'"
            size="15"
            ></v-avatar>
      </template>


    </v-data-table>
  </v-card>
</template>
<script>
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
export default {
  components: {
    // buttons,
    // buttons_components,
  },
  data() {
    return {
      search: '',
      // buttons: buttons_components.buttons,
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Code', align: 'left', value: 'code' },
        { text: 'Length (MM)', align: 'left', value: 'length' },
        { text: 'Width (MM)', align: 'left', value: 'width' },
        { text: 'Height (MM)', align: 'left', value: 'height' },
        { text: 'CBM (MM)', align: 'left', value: 'cbm' },
        // { text: 'TPL', align: 'center', value: 'tpl'},
        { text: 'Actions', align: 'center', value: 'action', sortable: false }
        ,
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch('getVehicles')
  },
  computed: {
    ...mapGetters(['GET_VEHICLES_LIST', 'USER_ACCESS', 'NEW_VEHICLES', 'GET_VEHICLES_LOADED']),
  },
  methods: {
    viewdialogs(item) {
      this.$store.commit('SELECTED_VEHICLE', item);
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'View')
    },
    editdialogs(item) {
      this.$store.commit('SELECTED_VEHICLE', item);
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'Update')
    },
    deleteDepartment(item) {
      swal
        .fire({
          title: '',
          text: 'Are you sure you want to Delete?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: '#397373'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('deletevehicles', item).then(success => {
              swal.fire({
                title: 'Success',
                icon: 'success',
                timer: 1500,
              });
            })
          }
        });
    }
  },
  watch: {
    NEW_VEHICLES: {
      handler(val) {
        if (val) {
          this.$store.dispatch('getVehicles')
          this.$store.commit('DIALOG', false);
          swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
          this.$store.commit('NEW_VEHICLES', false);
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
       if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
      }
    },
  },
};
</script>
