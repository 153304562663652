<template>
    <v-container fluid>
        <ComponentHeader class="ma-n4" :button="true" title='Bounced Cheque Report' type='Accounting'></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                <v-row dense class="my-2">
                    <v-col sm="12" md="6">
                        <v-autocomplete 
                            :items="GET_CUSTOMERS_SELECTION" 
                            :loading="!GET_CUSTOMERS_SELECTION"
                            :disabled="!GET_CUSTOMERS_SELECTION" 
                            v-model="filters.customers" 
                            label="Select Customers"
                            @change="onSelectCustomers"     
                            class="d-inline-block w-100" 
                            outlined 
                            dense 
                            multiple
                            background-color="white" 
                            small-chips 
                            hide-details 
                            clearable
                            deletable-chips
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete
                            dense
                            outlined
                            :items="banks.selection"
                            v-model="banks.selected"
                            item-value="id"
                            item-text="name"
                            label="Banks"
                            background-color="white" 
                            multiple
                            small-chips 
                            hide-details 
                            clearable
                            deletable-chips
                        />
                    </v-col>
                    <v-col sm="12" md="4" class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="renderPDF()">Print</v-btn>
                        <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                        <v-btn small class="ml-1" @click="getAllBouncedCheque()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @click:month="menu.date_from = false" :max="minDate"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @click:month="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-app>
        </v-row>
        <v-row class="w-100 pa-2 bg-bluish-gray ml-0">
            <v-card :class="'w-100 mt-2'">
                <v-card-text>
                    <div>
                        <v-data-table
                            :headers="bounced_cheque.headers"
                            :items="bounced_cheque.items"
                            :loading="bounced_cheque.loading"
                            :items-per-page="15"
                            item-key="period"
                            dense
                        >
                        <template v-slot:[`item.check_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.check_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.soa_month`]="{ item }">
                            <span class="text-nowrap">
                               {{ formatMonthSoa(item.soa_month) }}
                            </span>
                        </template>
                        <template v-slot:[`item.new_check_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.new_check_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.remaining_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.remaining_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.first_status_id`]="{ item }">
                            <span class="badge badge-primary" v-if="item.first_status_id==7">confirmed</span>
                            <span class="badge badge-warning" v-else-if="item.first_status_id==8">exhausted</span>
                            <span class="badge badge-danger" v-else-if="item.first_status_id==6">cancelled</span>
                            <span class="badge badge-purple" v-else-if="item.first_status_id==5">bounce</span>
                            <span class="badge badge-success" v-else-if="item.first_status_id==9">allocated</span>
                            <span class="badge badge-warning" v-else-if="item.first_status_id==10">partial</span>
                            <span class="badge white--text badge-info" v-else-if="item.first_status_id==11">for confirmation</span>
                            <span class="badge white--text bg-danger" v-else-if="item.first_status_id == 3" >rejected</span>
                            <span class="badge white--text bg-secondary" v-else-if="item.first_status_id == 0">saved</span>
                            <span class="badge white--text badge-dark" v-else-if="item.first_status_id==12">replaced</span>
                        </template>
                        <template v-slot:[`item.second_status_id`]="{ item }">
                            <span class="badge badge-primary" v-if="item.second_status_id==7">confirmed</span>
                            <span class="badge badge-warning" v-else-if="item.second_status_id==8">exhausted</span>
                            <span class="badge badge-danger" v-else-if="item.second_status_id==6">cancelled</span>
                            <span class="badge badge-purple" v-else-if="item.second_status_id==5">bounce</span>
                            <span class="badge badge-success" v-else-if="item.second_status_id==9">allocated</span>
                            <span class="badge badge-warning" v-else-if="item.second_status_id==10">partial</span>
                            <span class="badge white--text badge-info" v-else-if="item.second_status_id==11">for confirmation</span>
                            <span class="badge white--text bg-danger" v-else-if="item.second_status_id == 3" >rejected</span>
                            <span class="badge white--text bg-secondary" v-else-if="item.second_status_id == 0">saved</span>
                            <span class="badge white--text badge-dark" v-else-if="item.second_status_id==12">replaced</span>
                        </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-row>
        <div style="display:none;">
                <table id="export_details">
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center">PAYMENT DETAILS</th>
                            <th colspan="3" class="text-center">FIRST DEPOSIT</th>
                            <th colspan="3" class="text-center">SECOND DEPOSIT</th>
                            <th colspan="4" class="text-center">CHECK REPLACEMENT DETAILS</th>
                        </tr>
                        <tr>
                            <th>CUSTOMER NAME</th>
                            <th>SOA MONTH</th>
                            <th>CHECK AMOUNT</th>
                            <th>CHECK NUMBER</th>
                            <th>CHECK DATE</th>
                            <th>DEPOSIT DATE</th>
                            <th>STATUS</th>
                            <th>REASON</th>
                            <th>RE-DEPOSIT DATE</th>
                            <th>STATUS</th>
                            <th>NEW MOP</th>
                            <th>CHECK AMOUNT</th>
                            <th>CHECK NUMBER</th>
                            <th>CHECK DATE</th>
                            <th>BALANCE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(data,key,index) in export_data" :key="index">
                            <td>{{ data.company_name }}</td>
                            <td>{{ formatMonthSoa(data.soa_month) }}</td>
                            <td>{{ thousandSeprator(data.check_amount) | currency }}</td>
                            <td>{{ data.check_number }}</td>
                            <td>{{ data.check_date }}</td>
                            <td>{{ data.deposit_date | formatDate }}</td>
                            <td>{{ data.first_status }}</td>
                            <td>{{ data.bounce_reason }}</td>
                            <td>{{ data.re_deposit_date | formatDate }}</td>
                            <td>{{ data.second_status }}</td>
                            <td>{{ data.new_mop }}</td>
                            <td>{{ thousandSeprator(data.new_check_amount) | currency }}</td>
                            <td>{{ data.new_check_number }}</td>
                            <td>{{ data.new_check_date | formatDate }}</td>
                            <td>{{ thousandSeprator(data.remaining_amount) | currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
import swal from 'sweetalert2';
import { jsPDF } from "jspdf";
const ExcelJS = require('exceljs')
export default {
    data() {
        return {
            payments:{
                selected: '',
                selection: [],
            },
            bounced_cheque: {
                headers: [
                    { text: 'Customer Name', value: 'company_name', align: 'start', width: '20%' },
                    { text: 'SOA Month', value: 'soa_month', align: 'center', width: '5%' },
                    { text: 'Check Amount', value: 'check_amount' , align: 'end', width: '5%' },
                    { text: 'Check Number', value: 'check_number' , align: 'center', width: '5%' },
                    { text: 'Check Date', value: 'check_date' , align: 'center', width: '10%' },
                    { text: 'Deposit Date', value: 'deposit_date', align: 'center', width: '10%' },
                    { text: 'Status', value: 'first_status_id' , align: 'center', width: '5%' }, 
                    { text: 'Reason', value: 'bounce_reason' , align: 'start' },
                    { text: 'Re-deposit', value: 're_deposit_date', align: 'center', width: '10%' },
                    { text: 'Status', value: 'second_status_id' , align: 'center', width: '5%' },
                    { text: 'New MOP', value: 'new_mop', align: 'center', width: '5%' },
                    { text: 'Check Amount', value: 'new_check_amount' , align: 'end', width: '5%' },
                    { text: 'Check Number', value: 'new_check_number' , align: 'center', width: '7%' },
                    { text: 'Check Date', value: 'new_check_date' , align: 'center', width: '8%' },
                    { text: 'Balance', value: 'remaining_amount', align: 'end' },
                ],
                items: [],
                expanded: [],
                loading: false,
                with_old_data:[],
            },
            customers_list: [],
            menu: {
                date_from: false,
                date_to: false,
            },
            filters: {
                date_from: moment().startOf('week').add(1, 'days').format('YYYY-MM-DD'),
                date_to:  moment().endOf('week').add(-1, 'days').format('YYYY-MM-DD'),
                customers: [],
            },
            banks:{
                selection:[],
                selected:''
            },
            minDate: moment().format('YYYY-MM-DD'),
            export_data:[],
        }
    },
    mixins: [SharedFunctionsComponent],
    components: {
        ComponentHeader
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.getBanks();
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            'USERACCOUNT_LOAD'
        ]),
    },
    methods: {
        getBanks() {
            this.$store.dispatch('getBanks').then(response=>{
                this.banks.selection = response.data
            })
        },
        resetFields() {
            this.filters.customers = [];
            this.banks.selected = '';
            this.bounced_cheque.items = [];
        },
        getCustomers() {
            this.customers_list = this.GET_CUSTOMERS_SELECTION
        },
        getAllBouncedCheque() {
            swal.fire({
                title: 'Filtering Please wait...',
                icon: 'info',
                allowOutsideClick: false,
            });
            swal.showLoading();
            let payload = {
                date_range:[this.filters.date_from,this.filters.date_to],
                bank_id:this.banks.selected,
                customer_ids:this.filters.customers,
                url:'getAllBounceChequeReport'
            }
            this.$store.dispatch('reportsPost',payload).then(response=>{
                this.bounced_cheque.items = response.data
                this.bounced_cheque.items.forEach(e=>{
                    e.check_amount = parseFloat(e.check_amount)
                    e.new_check_amount = parseFloat(e.new_check_amount)
                    e.remaining_amount = parseFloat(e.remaining_amount)
                })
                this.export_data = this.bounced_cheque.items
                swal.close()
                swal.fire({
                    icon: 'success',
                    title: 'Filtering Successfully!',
                    allowOutsideClick: false,
                });
            })
        },
        onSelectCustomers(items) {
            this.filters.customers = items
        },
        renderPDF(){   
            var doc = new jsPDF({
                orientation: 'l', 
                unit: 'mm', 
                // format: 'letter'
            });

            let left = 10;
            let top = 5;
            const imgWidth = 40;
            const imgHeight = 12;
            let host = window.location.origin;
            // for (let i = 0; i < Object.keys(this.export_data).length; i++){

                const headerContent = function (data){
                    doc.addImage(`${host}/images/ingcoph-logo.png`, 'PNG', left, top, imgWidth, imgHeight);
                    doc.setTextColor('red').setFont(undefined,'normal').setFontSize(8).text(7,24,title).setTextColor('black')
                }
                let title = '( '+ moment(this.filters.date_from).format('MM/DD/YYYY') + ' - '+moment(this.filters.date_to).format('MM/DD/YYYY') + ' )'
                
                doc.autoTable({
                    html: '#export_details',
                    theme: 'grid',
                    margin: {top: 25, left: 6, right: 3},
                    headStyles: {
                        halign:'center',
                        fontSize: 9,
                        fillColor: 'yellow',
                        textColor: '#000000',
                        lineWidth: 0.1
                    },
                    columnStyles: {
                        0: {cellWidth: 30, halign:'center', fontSize: 8, textColor: '#000000'}, 
                        1: {cellWidth: 20, halign:'center', fontSize: 8, textColor: '#000000'}, 
                        2: {cellWidth: 20, halign:'right', fontSize: 8, textColor: '#000000'}, 
                        3: {cellWidth: 18, halign:'center', fontSize: 8, textColor: '#000000'}, 
                        4: {cellWidth: 18, halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 18, halign:'center', fontSize: 8, textColor: '#000000'},
                        6: {cellWidth: 17, halign:'center', fontSize: 8, textColor: '#000000'},
                        7: {cellWidth: 18, halign:'center', fontSize: 8, textColor: '#000000'},
                        8: {cellWidth: 18, halign:'center', fontSize: 8, textColor: '#000000'},
                        9: {cellWidth: 17, halign:'center', fontSize: 8, textColor: '#000000'},
                        10: {cellWidth: 20, halign:'center', fontSize: 8, textColor: '#000000'},
                        11: {cellWidth: 18, halign:'right', fontSize: 8, textColor: '#000000'},
                        12: {cellWidth: 18, halign:'center', fontSize: 8, textColor: '#000000'},
                        13: {cellWidth: 18, halign:'center', fontSize: 8, textColor: '#000000'},
                        14: {cellWidth: 20, halign:'right', fontSize: 8, textColor: '#000000'},
                    },
                    didDrawPage: function (data) {
                        headerContent({ title });
                    },
                });

            let pageCount = doc.internal.getNumberOfPages();

            for(let i = 0; i < pageCount; i++) { 
                doc.setPage(i); 
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(9).setFont(undefined, 'normal').text('Page: ' + pageCurrent + ' of ' + pageCount, doc.internal.pageSize.width - 25,15 );
            }
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        },
        async exportToExcel(){
            swal.fire({
                title: 'Exporting File Please wait...',
                icon: 'info',
                allowOutsideClick: false,
            });
            swal.showLoading();
            let that = this
            that.bounced_cheque.loading
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Raw Data')

            worksheet.columns = [
                {header:'Customer Name',key:'company_name', width: 40, style: { alignment: { horizontal: 'center' } } } ,
                {header:'SOA Month', key:'soa_month', width: 20, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Check Amount',key:'check_amount', width: 15, style: { numFmt: '#,##0.00' } } ,
                {header:'Check Number',key:'check_number', width: 20, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Check Date',key:'check_date', width: 15, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Deposit Date',key:'deposit_date', width: 15, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Status',key:'first_status', width: 15, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Reason',key:'bounce_reason', width: 20, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Re-Deposit Date',key:'re_deposit_date', width: 15, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Status',key:'second_status', width: 15, style: { alignment: { horizontal: 'center' } } } ,
                {header:'New MOP',key:'new_mop', width: 20, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Check Amount',key:'new_check_amount', width: 15, style: { numFmt: '#,##0.00' } } ,
                {header:'Check Number',key:'new_check_number', width: 15, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Check Date',key:'new_check_date', width: 15, style: { alignment: { horizontal: 'center' } } } ,
                {header:'Balance',key:'remaining_amount', width: 15, style: { numFmt: '#,##0.00' } } 
            ]
            this.export_data.forEach(e=>{
                if(isNaN(e.check_amount)){
                    e.check_amount = 0
                }
                if(isNaN(e.new_check_amount)){
                    e.new_check_amount = 0
                }
                if(isNaN(e.remaining_amount)){
                    e.remaining_amount = 0
                }
                worksheet.addRow(e);
            })
            worksheet.getRow(`0${1}`).font = { bold: true }
            that.loaded = true
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                saveAs(blob, `Bounced Cheque Report ${that.filters.date_from}-${that.filters.date_to}.xlsx`);
            });
            swal.close()
            swal.fire({
                icon: 'success',
                title: 'Export Successfully!',
                allowOutsideClick: false,
            });
        },
        formatMonthSoa(value) {
            if (value) {
                return moment(String(value)).format('MMM YYYY')
            }
        },
    },
}
</script>

<style scoped>

</style>
