<template>
    <span>
        <v-btn @click="showFilesViewerDialog()">View Files</v-btn>
        <v-dialog v-model="filesViewerDialog" max-width="50%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Files Viewer</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeFilesViewerDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />

                <v-data-table
                    class="pa-4"
                    :headers="filesHeader"
                    :items="files"
                    :loading="loading"
                    :no-data-text="noDataText"
                >
                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showFile(item); newImagePath()">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue">
                                <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                            </v-btn>
                            <v-btn v-if="withDelete" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteFile(item)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>
                <template v-slot:item.created_at="{ item }">
                    <td class="px-0">
                        {{ date(item) }}
                    </td>
                </template>
                </v-data-table>
            </v-card>
        </v-dialog>

        <v-dialog v-model="viewFile" persistent max-width="80%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ itemName }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />

                <div class="iframe-container">
                    {{ itemPath }}
                    <iframe :src="sourceFrame" frameborder="0" class="responsive-iframe"></iframe>
                </div>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import moment from 'moment';
export default {
    data() {
        return {
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by_name' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            files: [],
            custPaymentId: '',
            credMemoId: '',
            credMemoChinaId: '',
            debMemoId: '',
            loading: true,
            noDataText: '',
            viewFile: false,
            itemPath: '',
            itemName: '',
            withDelete: false,
            folder_name:'',
            sourceFrame:'',
            conDiscrepancyId:'',
            venPaymentDetailId:'',
            stAdjustmentId:''
        }
    },
    props: ['creditMemoId', 'debitMemoId', 'customerPaymentDetailId', 'rfId', 'delete','vendorPaymentDetailId', 'creditMemoChinaId','containerDiscrepancyId','stockAdjustmentId'],
    watch: {
        files() {
            if(this.files.length > 0) {
                this.loading = false;
            } else {
                this.loading = false,
                this.noDataText = 'No Uploaded Files';
            }
        },
        credMemoId() {
            if(!!this.credMemoId) {
                this.getCreditMemoFiles();
            }
        },
        debMemoId() {
            if(!!this.debMemoId) {
                this.getDebitMemoFiles();
            }
        },
        custPaymentId() {
            if(!!this.custPaymentId) {
                this.getCustomerPaymentDetailFiles();
            }
        },
        venPaymentDetailId(){
            if(!!this.venPaymentDetailId){
                this.getVendorPaymentDetailFiles();
            }
        },
        credMemoChinaId() {
            if(!!this.credMemoChinaId) {
                this.getCreditMemoChinaFiles();
            }
        },
        conDiscrepancyId() {
            if(!!this.conDiscrepancyId) {
                this.getContainerDiscrepancyFiles();
            }
        },
        stAdjustmentId() {
            if(!!this.stAdjustmentId) {
                this.getStockAdjustmentFiles();
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMER_PAYMENT_DETAIL_FILE',
            'GET_CREDIT_MEMO_FILES',
            'GET_DEBIT_MEMO_FILES',
            'GET_CREDIT_MEMO_CHINA_FILES',
            'GET_CONTAINER_DISCREPANCY_FILES',
            'GET_STOCK_ADJUSTMENT_FILES',
        ]),
        filesViewerDialog() {
            return this.$store.state.accounting.files.filesViewerDialog;
        },
    },
    methods: {
        clearFields() {
            this.loading = true;
            this.files = [];
            this.noDataText = '';
            this.withDelete = false;
            this.custPaymentId = '';
            this.credMemoId = '';
            this.credMemoChinaId = '';
            this.debMemoId = '';
            this.venPaymentDetailId = '';
            this.conDiscrepancyId = '';
            this.stAdjustmentId = '';
        },
        showFilesViewerDialog() {
            this.$store.commit('filesShow');

            this.id = this.customerPaymentDetailId;

            this.$emit('openFilesViewer', true);

            if(!!this.creditMemoId) {
                this.folder_name = 'credit_memo_uploads'
                if(!!this.containerDiscrepancyId){
                    this.folder_name = 'container_discrepancy_uploads'
                    this.conDiscrepancyId = this.containerDiscrepancyId;
                }
                this.credMemoId = this.creditMemoId;
            } else if(!!this.debitMemoId) {
                this.folder_name = 'debit_memo_uploads'
                this.debMemoId = this.debitMemoId;
            } else if(!!this.customerPaymentDetailId) {
                this.folder_name = 'customer_payment_uploads'
                this.custPaymentId = this.customerPaymentDetailId;
            } else if(!!this.vendorPaymentDetailId){
                this.folder_name = 'vendor_payment_uploads'
            } else if(!!this.creditMemoChinaId) {
                this.folder_name = 'credit_memo_china_uploads'
                this.credMemoChinaId = this.creditMemoChinaId;
            } else if(!!this.containerDiscrepancyId) {
                this.folder_name = 'container_discrepancy_uploads'
                this.conDiscrepancyId = this.containerDiscrepancyId;
            } else if(!!this.stockAdjustmentId) {
                this.folder_name = 'stock_adjustment_uploads'
                this.stAdjustmentId = this.stockAdjustmentId;
            }

            if(this.delete) {
                if(!!this.rfId) {
                    this.withDelete = false;
                } else {
                    this.withDelete = true;
                }
            } else {
                this.withDelete = false;
            }
        },
        closeFilesViewerDialog() {
            this.$store.commit('filesClose');
            this.clearFields();

            this.$emit('closeFilesViewer', true);
        },
        getCustomerPaymentDetailFiles() {
            this.$store.dispatch('getCustomerPaymentDetailFiles',this.custPaymentId).then(response=>{
                this.files = this.GET_CUSTOMER_PAYMENT_DETAIL_FILE;
            });
        },
        getVendorPaymentDetailFiles(){
            let payload = {
                url:`getVendorPaymentDetailFiles/${this.vendorPaymentDetailId}`
            }
            this.$store.dispatch('getAcctsPayable',payload).then(response=>{
                this.files = response.data
            });
        },
        getCreditMemoFiles() {
            this.$store.dispatch('getCreditMemoFiles',this.creditMemoId).then(response=>{
                this.files = this.GET_CREDIT_MEMO_FILES;
            });
        },
        getDebitMemoFiles() {
            this.$store.dispatch('getDebitMemoFiles',this.debitMemoId).then(response=>{
                this.files = this.GET_DEBIT_MEMO_FILES;
            });
        },
        date(item) {
            if(!!item) {
                return moment(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        showFile(file) {
            this.viewFile = true;
            this.itemPath = file.path;
            this.itemName = file.name
            this.$store.commit('filesClose');
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let uri = ''
                    file.name = file.name.replaceAll(' ','%20')
                    file.name = file.name.replaceAll('#','%23')
                    file.name = file.name.replaceAll('&','%24')
                    if(!!this.rfId){
                        uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${file.name}`
                    }
                    else if(!!this.containerDiscrepancyId){
                        uri = `${process.env.VUE_APP_API_HOST}/img/operations?image_name=${file.name}&folder_name=${this.folder_name}`
                    }
                    else if(!!this.stockAdjustmentId){
                        uri = `${process.env.VUE_APP_API_HOST}/img/audit?image_name=${file.name}&folder_name=${this.folder_name}`
                    }
                    else{
                        uri = `${process.env.VUE_APP_API_HOST}/img/accounting?image_name=${file.name}&folder_name=${this.folder_name}`
                    }

                    fetch(uri)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        anchor.download = file.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blob);
                    })
                    .catch(() => console.log('An error in downloadin the file'));
                        }
                    });
        },
        deleteFile(file) {
            if(!!this.custPaymentId) {
                swal.fire({
                    text: 'Are you sure you want to delete?',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            fileId: file.id
                        }
                        this.$store.dispatch('deleteCustomerPaymentDetailFile',payload).then(response=>{
                            this.getCustomerPaymentDetailFiles();
                            swal.fire("", response.data.msg, "success");
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        })
                    }
                });
            }

            if(!!this.credMemoId) {
                swal.fire({
                    text: 'Are you sure you want to delete?',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            fileId: file.id
                        }
                        this.$store.dispatch('deleteCreditMemoFile',payload).then(response=>{
                            this.getCreditMemoFiles();
                            swal.fire("", response.data.msg, "success");
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        })
                    }
                });
            }

            if(!!this.debMemoId) {
                swal.fire({
                    text: 'Are you sure you want to delete?',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            fileId: file.id
                        }
                        this.$store.dispatch('deleteDebitMemoFile',payload).then(response=>{
                            this.getDebitMemoFiles();
                            swal.fire("", response.data.msg, "success");
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        })
                    }
                });
            }

            if(!!this.creditMemoChinaId) {
                swal.fire({
                    text: 'Are you sure you want to delete?',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            fileId: file.id
                        }
                        this.$store.dispatch('deleteCreditMemoChinaFile',payload).then(response=>{
                            this.getCreditMemoChinaFiles();
                            swal.fire("", response.data.msg, "success");
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        })
                    }
                });
            }

            if(!!this.stockAdjustmentId) {
                swal.fire({
                    text: 'Are you sure you want to delete?',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result) => {
                    if(result.isConfirmed) {
                        let payload = {
                            fileId: file.id
                        }
                        this.$store.dispatch('deleteStockAdjustmentFile',payload).then(response=>{
                            this.getStockAdjustmentFiles();
                            swal.fire("", response.data.msg, "success");
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        })
                    }
                });
            }
        },
        closeViewFile() {
            this.itemPath = "";
            this.viewFile = false;
            this.$store.commit('filesShow');
        },
        async newImagePath(path){
            let uri = ''
            this.itemName = this.itemName.replaceAll(' ','%20')
            this.itemName = this.itemName.replaceAll('#','%23')
            this.itemName = this.itemName.replaceAll('&','%26')
            if(!!this.rfId){
                uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${this.itemName}`
            }
            else if(!!this.containerDiscrepancyId){
                uri = `${process.env.VUE_APP_API_HOST}/img/operations?image_name=${this.itemName}&folder_name=${this.folder_name}`
            }
            else if(!!this.stockAdjustmentId){
                uri = `${process.env.VUE_APP_API_HOST}/img/audit?image_name=${this.itemName}&folder_name=${this.folder_name}`
            }
            else{
                uri = `${process.env.VUE_APP_API_HOST}/img/accounting?image_name=${this.itemName}&folder_name=${this.folder_name}`
            }
            fetch(uri)
            .then(resp => resp.blob())
            .then(blobobject => {
                const blob = window.URL.createObjectURL(blobobject);
                this.sourceFrame = blob;
            })
        },
        getCreditMemoChinaFiles() {
            this.$store.dispatch('getCreditMemoChinaFiles',this.creditMemoChinaId).then(response=>{
                this.files = this.GET_CREDIT_MEMO_CHINA_FILES;
            });
        },
        getContainerDiscrepancyFiles () {
            this.$store.dispatch('getContainerDiscrepancyFiles',this.containerDiscrepancyId).then(response=>{
                this.files = this.GET_CONTAINER_DISCREPANCY_FILES;
            });
        },
        getStockAdjustmentFiles() {
            this.$store.dispatch('getStockAdjustmentFiles',this.stockAdjustmentId).then(response=>{
                this.files = this.GET_STOCK_ADJUSTMENT_FILES;
            });
        },
    }
}
</script>

<style scoped>
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
</style>
