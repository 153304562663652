<template>
    <v-container fluid>
        <ComponentHeader :button="true" :title="'Daily Warehouse Inventory'" :type="'Inventory'"></ComponentHeader>

        <v-container class="bg-bluish-gray default-container">
            <v-dialog v-model="loading.dialog" width="500">
                <v-card>
                    <!-- <v-progress-circular
                        :rotate="360"
                        :size="300"
                        :width="30"
                        :value="loading.value"
                        color="green"
                    >
                        {{ loading.text }}
                    </v-progress-circular> -->
                    <v-card-title>
                        <span style="font-size:12px">Processing: {{ loading.text }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-progress-linear v-model="loading.value" :buffer-value="loading.bufferValue" color="green"></v-progress-linear>
                        {{ loading.value }}
                    </v-card-text>
                </v-card>
            </v-dialog>
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <v-autocomplete
                        dense
                        outlined
                        multiple
                        small-chips
                        deletable-chips
                        background-color="white"
                        :items="GET_ALL_WAREHOUSES"
                        :loading="loading.warehouse_selection"
                        :disabled="loading.warehouse_selection"
                        v-model="filters.warehouses.selected"
                        label="Select Warehouses"
                        return-object
                        class="small"
                    ></v-autocomplete>
                </div>
                <div class="col-md-5 col-sm-12"></div>
                <div class="col-sm-12 col-md-3 text-right float-right">
                    <v-btn small class="ml-1" @click="resetFields()" :loading="!loaded">Clear</v-btn>
                    <v-btn small class="ml-1" @click="exportToExcel()" :loading="!loaded">Excel</v-btn>
                    <v-btn small class="ml-1" @click="list()" :loading="!loaded">Filter</v-btn>
                </div>
            </div>
            <v-row class="pa-2">
                <v-col lg="2">
                    <v-autocomplete
                        dense
                        outlined
                        multiple
                        small-chips
                        deletable-chips
                        background-color="white"
                        :items="GET_ITEM_TYPES"
                        :loading="loading.item_type_selection"
                        :disabled="loading.item_type_selection"
                        v-model="filters.types.selected"
                        return-object
                        label="Select Discount Type"
                        class="small"
                    ></v-autocomplete>
                </v-col>
                <v-col lg="2">
                    <v-autocomplete
                        dense
                        outlined
                        multiple
                        small-chips
                        deletable-chips
                        background-color="white"
                        :items="filters.categories.selection"
                        :loading="loading.category_selection"
                        :disabled="loading.category_selection"
                        v-model="filters.categories.selected"
                        return-object
                        item-value="id"
                        item-text="name"
                        label="Select Item Categories"
                        class="small"
                    ></v-autocomplete>
                </v-col>
                <v-col lg="2">
                    <v-autocomplete
                        dense
                        outlined
                        multiple
                        small-chips
                        deletable-chips
                        background-color="white"
                        :items="GET_ITEM_SELECTION_DEFAULT"
                        :loading="loading.item_selection_default"
                        :disabled="loading.item_selection_default"
                        v-model="filters.items.selected"
                        return-object
                        label="Select Item Model"
                        class="small"
                    ></v-autocomplete>
                </v-col>
                <v-col class="col-md-1"></v-col>
            </v-row>
            <div class="row">
                <div class="col-sm-4 col-md-2" id="date_from">
                    <v-menu
                        v-model="filters.date.menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_from"
                                readonly
                                outlined
                                dense
                                background-color="white"
                                label="Date From"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date.date_from" @input="filters.date.menu2 = false"></v-date-picker>
                    </v-menu>
                </div>

                <div class="col-sm-4 col-md-2" id="date_to">
                    <v-menu
                        v-model="filters.date.menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                        z-index="100"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_to"
                                readonly
                                outlined
                                dense
                                background-color="white"
                                label="Date To"
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filters.date.date_to"
                            @input="filters.date.menu3 = false"
                            style="z-index:100"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-sm-4 col-md-2 d-flex justify-content-center align-items-centers">
                    <v-checkbox v-model="filters.active" input-value="1" false-value="0" label="Active"></v-checkbox>
                </div>
            </div>
            <div class="container bg-white">
                <div class="row p-40 bg-dark">
                    <v-data-table
                        :loading="!loaded"
                        :items="dataTable.items"
                        :headers="dataTable.headers"
                        :options.sync="dataTable.pagination.options"
                        class="w-100"
                        dense
                        :footer-props="{
                            showFirstLastPage: true,
                            itemsPerPageOptions: [5, 10, 25, 100, 500]
                        }"
                        :server-items-length="dataTable.pagination.totalItems"
                    ></v-data-table>
                </div>
            </div>
        </v-container>
        <!-- <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app> -->

    </v-container>
</template>

<script>
const ExcelJS = require("exceljs");
import { saveAs } from 'file-saver';
import moment from 'moment';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';

export default {
    mixins: [SharedFunctionsComponentVue],
    data() {
        return {
            loaded: true,
            filters: {
                active: 1,
                filter: 1,
                date: {
                    date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                    date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                    menu2: false,
                    menu3: false,
                    errors: [],
                },
                items: {
                    selected: [],
                    selection: [],
                },
                categories: {
                    selected: [],
                    selection: [],
                },
                types: {
                    selected: [],
                    selection: [],
                },
                warehouses: {
                    selected: [],
                    selection: [],
                }
            },

            dataTable: {
                headers: [
                    { text: 'Warehouse', value: 'warehouses' },
                    { text: 'Model', value: 'model' },
                    { text: 'Name', value: 'name' },
                    { text: 'UOM', value: 'uom' },
                    { text: 'Category', value: 'categories' },
                    { text: 'Type', value: 'type' },
                    { text: 'Qty', value: 'quantity' },
                    { text: 'Date', value: 'date' },
                ],
                items: [],
                pagination: {
                    options: {},
                    totalItems: 0,
                }
            },
            loading: {
                value: 0,
                text: '',
                dialog: false,
                bufferValue: 100,
                percent: 0,

                item_selection_default: true,
                item_type_selection: true,
                category_selection: true,
                warehouse_selection: true,
                initialLoad: true,
            }
        }
    },
    components: {
        ComponentHeader
    },

    computed: {
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ALL_WAREHOUSES'
        ])
    },

    methods: {
        list(page = 1) {
            let that = this

            that.loaded = false

            this.$store.dispatch('reports', {
                date_from: that.filters.date.date_from,
                date_to: that.filters.date.date_to,
                categories: that.filters.categories.selected.map(e => { return e.id }),
                types: that.filters.types.selected.map(e => { return e.value }),
                items: that.filters.items.selected.map(e => { return e.value }),
                page: page,
                warehouses: that.filters.warehouses.selected.map(e => { return e.value }),
                itemsPerPage: that.dataTable.pagination.options.itemsPerPage,
                items: that.filters.items.selected.map(e => { return e.value }),
                active: that.filters.active,
                url: 'daily-warehouse-inventory/list'
            }).then(response => {
                if (response.data.data.total < 1) {
                    Swal.fire('No Data !', '- No data found', 'warning')
                }
                that.dataTable.items = response.data.data.data
                that.dataTable.pagination.totalItems = response.data.data.total
                that.loaded = true
            }).catch(e => {
                Swal.fire('Error !', 'An error occured', 'error')
                that.loaded = true
            })
        },

        async exportToExcel() {
            let that = this

            let dateFrom = new Date(this.filters.date.date_from)
            let dateTo = new Date(this.filters.date.date_to)
            let date_difference = 0
            let loopCounter = 0
            let warehouseCounter = 1
            let selectedWarehouses = that.filters.warehouses.selected.length > 0 ? that.filters.warehouses.selected : that.GET_ALL_WAREHOUSES

            that.loaded = false
            date_difference = dateTo.getTime() - dateFrom.getTime()
            date_difference = date_difference / (1000 * 3600 * 24);

            //set up exceljs
            let workbook = new ExcelJS.Workbook();
            let arrayWorksheet = []

            that.loading.dialog = true

            while (loopCounter <= date_difference) {
                let data = []
                let headers = []
                arrayWorksheet[loopCounter] = workbook.addWorksheet(moment(that.filters.date.date_from).add(loopCounter, 'day').format('YYYY-MM-DD'));

                for (let index in selectedWarehouses) {
                    await this.$store.dispatch('reports', {
                        categories: that.filters.categories.selected.map(e => { return e.id }),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        items: that.filters.items.selected.map(e => { return e.value }),
                        date: dateFrom,
                        days: loopCounter,
                        warehouse: selectedWarehouses[index].value,
                        withItems: index == 0 ? true : false,
                        active: that.filters.active,
                        url: 'daily-warehouse-inventory/export'

                    }).then(response => {

                        if (!!response.data.items[0]) {
                            headers.length > 0 ? headers.push(Object.keys(response.data.items[0])[0]) : headers = Object.keys(response.data.items[0])
                            response.data.items.forEach((items, index) => {
                                if (!!data[index]) {
                                    data[index] = data[index].concat(Object.values(items))
                                } else {
                                    data.push(Object.values(items));
                                }
                            })
                        }

                    }).catch(e => {
                        Swal.fire('Error !', 'An error occured', 'error')
                        console.log(e)
                        that.loaded = true
                    })

                    that.loading.text = moment(that.filters.date.date_from).add(loopCounter, 'day').format('YYYY-MM-DD') + '(' + selectedWarehouses[index].text + ')'

                    let num1 = (date_difference + 1) * selectedWarehouses.length
                    that.loading.value = Math.ceil((warehouseCounter / num1) * 100) + "%"
                    warehouseCounter++;
                }

                arrayWorksheet[loopCounter].addRow(headers, "bold");

                data.forEach(items => {
                    arrayWorksheet[loopCounter].addRow(Object.values(items),);
                })
                loopCounter++
            }

            Swal.fire('Completed !', '', 'success')
            that.loading.dialog = false
            that.loading.value = 0

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                saveAs(blob, `Daily-Warehouse-Inventory-Report ${that.filters.date.date_from}-${that.filters.date.date_to}.xlsx`);
            });

            that.loaded = true
        },

        resetFields() {
            let that = this
            that.filters.items.selected = []
            that.filters.categories.selected = []
            that.filters.warehouses.selected = []
            that.filters.types.selected = that.filters.types.selected.filter(e => { return e.value != 3 })
            that.filters.date.date_from = moment().clone().startOf('month').format('YYYY-MM-DD')
            that.filters.date.date_to = moment().clone().endOf('month').format('YYYY-MM-DD')
        },

        thousandSeprator(amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
    },
    mounted() {
        this.$store.dispatch('getAllitemSelectionDefault')
        this.$store.dispatch('getAllItemCategories');
        this.$store.dispatch('getItemTypes');
        this.$store.dispatch('getAllWarehouses');
    },
    watch: {
        'dataTable.pagination.options': {
            handler(val) {
                if(!this.loading.initialLoad){
                    this.list(val.page)
                }else{
                    this.loading.initialLoad = false
                }
            },
            deep: true
        },
        'filters.types.selected': {
            handler(val) {
                this.getItems()
            },
            deep: true,
        },
        'filters.categories.selected': {
            handler(val) {
                this.getItems()
            },
            deep: true
        },
        'GET_ITEM_TYPES': {
            handler(val) {
                this.filters.types.selection = val.data
                this.loading.item_type_selection = false
            }
        },
        'GET_ALL_ITEM_CATEGORIES': {
            handler(val) {
                this.filters.categories.selection = val.map(e => { return { text: e.name, value: e.id } })
                this.loading.category_selection = false
            }
        },
        'GET_ITEM_SELECTION_DEFAULT': {
            handler(val) {
                this.filters.items.selection = val
                this.loading.item_selection_default = false
            }
        },
        'GET_ALL_WAREHOUSES': {
            handler(val) {
                this.filters.warehouses.selection = val.map(e => { return { text: e.text, value: e.value } })
                this.loading.warehouse_selection = false
            }
        }
    }
};
</script>
<style>
.col {
    padding: 2px !important;
}

.card-body {
    padding: 3px !important;
}

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
}
</style>
