<template>
<div class="w-100 p-1">
    <v-app id="my_vue_app">

        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                >
                </v-text-field>
                <v-icon>fas fa-search</v-icon>
            </v-card-title>

            <v-container fluid>
                <v-data-table
                    :headers="headers"
                    :items="itemTypes"
                    :search="search"
                    :items-per-page="5"
                    :loading="loaded"
                    class="w-100"
                >

                <template v-slot:[`item.active`]="{ item }">
                    <span class="badge bg-primary" style="color:white;" v-if="item.active">Yes</span>
                    <span class="badge bg-danger" style="color:white;" v-else>No</span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="blue" v-if="userAccess.includes('discountType_edit')">
                        <v-icon class="btn-action" small @click="editItemType(item.id)">mdi-square-edit-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" v-if="userAccess.includes('discountType_delete')">
                        <v-icon class="btn-action" small @click="deleteItemType(item.id)">mdi-delete</v-icon>
                    </v-btn>
                </template>

                </v-data-table>
            </v-container>
        </v-card>
    </v-app>

</div>
</template>

<script>
//import ItemTypesViewComponent from './ItemTypesViewComponent.vue'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    components: {
        //ItemTypesViewComponent
    },
    props: [
        'itemTypesActionResult',
        'userAccess'
    ],
    data () {
        return {
            actionResult : {
                model : 'ItemTypes',
                action : ''
            },
            loaded: false,
            itemTypes: [],
            itemsPerPageOptions: [5, 10 ,15],
            itemsPerPage: 10,
            search: '',
            headers: [
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Active', align: 'left', value: 'active', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],

        }
    },
    mounted() {
        this.getAllItemTypes()

    },
    computed:{
        ...mapGetters([
            'GET_ALL_ITEM_TYPES'
        ])
    },
    methods:{
        getAllItemTypes(){
            this.loaded = true
            this.itemTypes = [];
            this.$store.dispatch('getAllItemTypes').then((res)=>{
                this.itemTypes = res.data
                this.loaded = false
            });
        },
        editItemType(id){
            this.$emit('showEditModal', id);
        },
        deleteItemType(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('destroyItemType',id).then(response => {
                        this.getAllItemTypes();
                        swal.fire('','Item Type Deleted Successfully',"success");
                    }).catch(error => {
                        swal.fire("An Error Occured", error, "error");
                    })
                }
            });
        },
    },
    watch: {
        itemTypesActionResult: function(val){
            if(this.itemTypesActionResult=='success'){
                this.getAllItemTypes();
            }
        }
    }
};
</script>
