<template>
  <v-container class="pa-0 ma-0">
    <v-col>
      <v-card>
        <v-col class="d-flex no-block align-items-center justify-content-start pa-2">
          <!-- <buttons
            v-if="userAccess.create"
            :action="buttons.actions.create"
            :button_icon="buttons.icons.create"
            :color="buttons.colors.create"
            @click="adddialog"
          ></buttons> -->
        <div class="row" v-if="userAccess.create">
            <div class="col-8 d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn @click="adddialog()">
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </v-btn>
                </div>
            </div>
            <div class="col-4 d-flex no-block align-items-center">
              <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              append-icon="mdi-magnify"
              > </v-text-field>
              <v-icon>fas fa-search</v-icon>
            </div>
        </div>
        </v-col>
        <v-data-table
          :headers="headers"
          :items="GET_SALESMAN_LIST"
          :search="search"
          :items-per-page="15"
          :loading="!GET_SALESMAN_LOADED"
          :footer-props="{
            showFirstLastPage: true,
          }"
          class="w-100"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.action`]="{ item }">
            <!-- <buttons
              v-if="userAccess.view"
              :action="buttons.actions.view"
              :button_icon="buttons.icons.view"
              :color="buttons.colors.view"
              :button_text="buttons.type.text"
              :icon="buttons.type.icon"
              @click="salesmanDialog(item)"
            >
            </buttons>
            <buttons
              v-if="userAccess.edit"
              :action="buttons.actions.edit"
              :button_icon="buttons.icons.edit"
              :color="buttons.colors.edit"
              :button_text="buttons.type.text"
              :icon="buttons.type.icon"
              @click="salesmanDialog(item)"
            >
            </buttons>
            <buttons
              v-if="userAccess.delete"
              :action="buttons.actions.delete"
              :button_icon="buttons.icons.delete"
              :color="buttons.colors.delete"
              :button_text="buttons.type.text"
              :icon="buttons.type.icon"
              @click="deleteSalesmam(item.id)"
            >
            </buttons> -->
            <v-btn text icon color="orange" v-if="userAccess.view" >
              <v-icon class="btn-action" small @click="viewsalesmanDialog(item)">mdi-eye</v-icon>
            </v-btn>
            <v-btn text icon color="blue" v-if="userAccess.edit" >
                <v-icon class="btn-action" small @click="salesmanDialog(item)">mdi-pencil</v-icon>
            </v-btn>
            <v-btn text icon color="red" v-if="userAccess.delete" >
                <v-icon class="btn-action" small @click="deleteSalesmam(item.id)">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <dialogs v-if="SALESMAN_DIALOG" :cp="component" :width="800 "></dialogs>
  </v-container>
</template>
<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import salesmansDialog from '../../../layouts/dialogs/Masterdata/SalesmanComponent.vue'
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
import Dialogs from '../../../layouts/dialogs/Dialog.vue'
export default {
  components: {
    // buttons,
    // buttons_components,
    salesmansDialog,
    Dialogs,
  },
  data() {
    return {
      component: salesmansDialog,
      // buttons: buttons_components.buttons,
      loaded: false,
      users: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 15,
      search: '',
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'User', align: 'left', value: 'user.name' },
        { text: 'Position', align: 'left', value: 'salesman_position.title' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      viewUserId: '',
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          },
        ],
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      salesman: false,
    };
  },
  mounted() {
    this.$store.dispatch('getSalesman')
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
  },
  computed: {
    ...mapGetters(['GET_SALESMAN_LIST', 'USER_ACCESS', 'NEW_SALESMAN', 'SALESMAN_DIALOG', 'GET_SALESMAN_LOADED']),
  },
  methods: {
    adddialog(e) {
      // e.preventDefault();
      this.$store.commit('SALESMAN_DIALOG', true)
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'Submit')
      this.$store.commit('SELECTED_SALESMAN_HEAD', '')
    },
    viewsalesmanDialog(item) {
      this.$store.commit('SALESMAN_DIALOG', true);
      this.$store.commit('SELECTED_SALESMAN_HEAD', item)
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'View')
    },
    salesmanDialog(item) {
      this.$store.commit('SALESMAN_DIALOG', true);
      this.$store.commit('SELECTED_SALESMAN_HEAD', item)
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'Update')
    },
    deleteSalesmam(id) {
      swal
        .fire({
          title: '',
          text: 'Are you sure you want to Delete?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: '#397373'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('deleteSalesman', id).then(success => {
              swal.fire({
                title: 'Success',
                icon: 'success',
                timer: 1500,
              });
            })
          }
        });
    }
  },
  watch: {
    NEW_SALESMAN: {
      handler(val) {
        if (val) {
          this.$store.dispatch('getSalesman')
          this.$store.commit('SALESMAN_DIALOG', false);
          swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
          this.$store.commit('NEW_SALESMAN', false)
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
        if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
           this.userAccess.create = false;
        val.forEach(e => {
          if (e.actions_code == 'create') {
            this.userAccess.create = true;
          }
          else if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
      }
    },
  },
};
</script>
