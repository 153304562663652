import PackingListHistory from "@/views/main/modules/Accounting/PackingListHistory/PackingListHistoryGroupComponent.vue"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/packing-list-history/:id",
    name: "acctpackinglisthistory",
    component: PackingListHistory,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Delivery Receipt History',
    }
}]

export default routes;
