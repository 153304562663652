<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Vehicle Information</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="py-5">
          <v-row>
            <v-col sm="6" col="12">
              <v-text-field
                outlined
                dense
                v-model="form.name"
                label="Vehicle name"
                height="10"
                :rules="error.name"
                required
                :disabled="disable_view"
              >
              </v-text-field>
            </v-col>
            <v-col sm="6" col="12">
              <v-text-field outlined dense v-model="form.code" :rules="error.code" label="Code" required
                :disabled="disable_view"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View'">
            <v-btn class="float-center" @click="actions == 'Submit' ? addVehiclesType() : UpdateVehiclesType()"  :disabled="disable_view">
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>

import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
  props: ['action'],
  data() {
    return {
      valid: true,
      form: {
        name: null,
        code: null,
      },
      item: '',
      error: {
        name: [v => !!v || 'Vehicle Type Name is required'],
        code: [v => !!v || 'Code is required'],
      },
      disable_view:false,
    }
  },
  computed: {
    ...mapGetters(['DIALOGS', 'ACTION', , 'GET_SELECTED_VEHICLE_TYPE','GET_VEHICLE_TYPES']),
    actions() {
      if (this.ACTION == 'Submit') {
        this.disable_view = false;
        this.form = {
          name: null,
          code: null,
        }
        return 'Submit';
      } else if(this.ACTION == 'Update'){
        this.disable_view = false;
        this.getVehiclesEdit();
        return 'Update'
      } else if(this.ACTION == 'View'){
        this.disable_view = true;
        this.getVehiclesEdit();
        return 'View'
      }
    },
  },
  mounted() {
    this.getVehicleTypes();
  },
  methods: {
    closeDialog() {
      this.$store.commit('DIALOG', false);
    },
    getVehiclesEdit(){
        this.form = {
          name: this.GET_SELECTED_VEHICLE_TYPE.name,
          code: this.GET_SELECTED_VEHICLE_TYPE.code,
        }
        this.item = this.GET_SELECTED_VEHICLE_TYPE;
    },
    addVehiclesType() {
      this.$refs.form.validate();
      const code_swal_text = this.form.code ? '' : 'Code'
      const vendor_swal_text = this.form.name ? '' : 'Vehicle Type Name'
      const array_text = [vendor_swal_text, code_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '')
      });
      if (this.form.code && this.form.name) {
        this.$store.dispatch('addNewVehiclesType', this.form);
        this.resetFields()
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    UpdateVehiclesType() {
      this.$refs.form.validate();
      const code_swal_text = this.form.code ? '' : 'Code'
      const vehicle_swal_text = this.form.name ? '' : 'Vehicle Name'
      const array_text = [vehicle_swal_text, code_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '')
      });
      if (this.form.code && this.form.name) {
        this.$store.dispatch('UpdateVehiclesType', {
          id: this.item.id,
          payload: this.form,
        });
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    resetFields() {
      this.form = {
          name: null,
          code: null,
        }
    },
    getVehicleTypes(){
      this.$store.dispatch('getAllVehicleTypes')
    }
  },
 
};
</script>

<style>
.checkbox-label label{
  margin-bottom: 0px !important;
}
</style>