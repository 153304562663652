<template>
  <v-container fluid>
    <v-container v-for="(department, i) in GET_ALL_DEPARTMENT_ROLES" :key="i">
      <v-row class="py-2">
        <v-col class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">{{ department.name }}</h4>
        </v-col>
      </v-row>

        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
            <v-icon>fas fa-search</v-icon>
          </v-card-title>
          <v-container fluid>
            <v-switch
              v-model="isRoles"
              label="Roles"
              hide-details
            ></v-switch>
            <roles-table :department="department" :search="search" :isRoles="isRoles" ></roles-table>
          </v-container>
        </v-card>

    </v-container>
    <dialogs :isRoles="isRoles" :cp="components" :width="'30%'" :scrollable="false"></dialogs>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import RolesTable from './tables/RolesComponent.vue'
import rolesDialog from '../../layouts/dialogs/Masterdata/RolesComponents.vue'
import Dialogs from '../../layouts/dialogs/Dialog.vue'
export default {
  components: { RolesTable, rolesDialog, Dialogs },
  data() {
    return {
      actionResult: {
        model: 'Roles',
        action: ''
      },
      search: '',
      loaded: false,
      isRoles: false,
      users: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 10,
      access_role_id: '',
      has_access: false,
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          },
        ],
      ],
      components: rolesDialog,
    }
  },
  mounted() {
    this.getAllDepartmentroles();
    this.$store.commit('LINK_ACTIVE',window.location.pathname.split("/")[3])
  },
  computed: {
    ...mapGetters(['GET_ALL_DEPARTMENT_ROLES','USERACCOUNT_LOAD']),

  },
  methods:{
    getAllDepartmentroles(){
    let payload = {
        isRoles: this.isRoles
    }
    this.$store.dispatch('getAllDepartmentroles',payload)
    }
  },
  watch: {
    rolesActionResult: function (val) {
      if (this.rolesActionResult == 'success') {
        this.GET_ALL_DEPARTMENT_ROLES;
      }
    },
    isRoles:{
      handler(val){
        this.getAllDepartmentroles();
        this.$store.commit('IS_ROLES',val)
      }
    },
  },
};
</script>
