<template>
    <v-col class="tab-page-wrapper">
        <v-app id="viewItemModal">
            <v-dialog v-model="view_item_dialog" persistent max-width="90%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Item Information</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="view_item_dialog = false; resetFields(); $emit('closeView');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text>
                        <v-row class="m-0 p-2">
                            <v-container fluid>
                                <v-row>
                                    <v-col md="4" class="text-left">
                                        <v-img fluid responsive lazy :src="checkImagePath(items)"></v-img>
                                    </v-col>
                                    <v-col md="4" class="text-left embed-responsive embed-responsive-4by3">
                                        <iframe class="embed-responsive-item" :src="items.video_link" frameborder="0"
                                            allowfullscreen ng-show="showvideo"></iframe>
                                    </v-col>
                                    <v-col md="4" class="text-center">
                                        <v-row class="ml-1 p-3"
                                            v-if="items.suggested_items !== null || (typeof items.suggested_items !== 'undefined')">
                                            <h3>Alternate Models</h3>
                                            <v-col cols="12">
                                                <v-data-table
                                                    :items="items.suggested_items"
                                                    :headers="suggested_items_headers"
                                                    :server-items-length="items.suggested_items?.length"
                                                    hide-default-footer
                                                    disable-sort
                                                >
                                                    <template v-slot:[`item.image_path`]="{ item }">
                                                        <v-img :src="checkImagePath(item)" height="60px" width="60px"></v-img>
                                                    </template>
                                                    <template v-slot:[`item.selling_price`]="{ item }">
                                                        {{ item.selling_price | currency }}
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                        <v-row class="ml-1 p-3"
                                            v-if="items.substitution_items !== null || (typeof items.substitution_items !== 'undefined')">
                                            <h3>New Models</h3>
                                            <v-col cols="12">
                                                <v-data-table
                                                    :items="items.substitution_items"
                                                    :headers="substitution_items_headers"
                                                    :server-items-length="items.substitution_items?.length"
                                                    hide-default-footer
                                                    disable-sort
                                                >
                                                    <template v-slot:[`item.image_path`]="{ item }">
                                                        <v-img :src="checkImagePath(item)" height="60px" width="60px"></v-img>
                                                    </template>
                                                    <template v-slot:[`item.selling_price`]="{ item }">
                                                        {{ item.selling_price | currency }}
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col sm="12" md="12" class="d-flex justify-end col-sm-12 col-md-12 pull-right text-right">
                                                        <div class="mb-2 mt-2">
                                                            <v-btn class="ma-2" @click="openItemExclusiveDialog()">Exclusive Customers</v-btn>
                                                        </div>
                                                        <div class="mb-2 mt-2">
                                                            <v-btn class="ma-2"  @click="showPCDialog"> Parts Compatibility </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-row> -->
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>

                        <v-card-text>
                            <v-row>
                                <v-col sm="12" md="4">
                                    <label for="model" class="float-left control-label col-form-label">Model</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="model"
                                        placeholder="Model" v-model="items.model" readonly></v-text-field>
                                </v-col>

                                <v-col sm="12" md="4">
                                    <label for="name" class="float-left control-label col-form-label">Item Name</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="name"
                                        placeholder="Item Name" v-model="items.name" readonly></v-text-field>
                                </v-col>

                                <v-col sm="12" md="4">
                                    <label for="barcode" class="float-left control-label col-form-label">Barcode</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="barcode"
                                        placeholder="Barcode" v-model="items.barcode" readonly></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col sm="12" md="4">
                                    <v-textarea label="Description" auto-grow v-model="items.description" readonly>
                                    </v-textarea>
                                </v-col>
                                <v-col sm="12" md="4">
                                    <label for="video_link" class="float-left control-label col-form-label">Video
                                        Link</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="video_link"
                                        placeholder="Video Link" v-model="items.video_link" readonly></v-text-field>
                                </v-col>
                                <v-col sm="12" md="4">
                                    <label for="part_num" class="float-left control-label col-form-label">Part
                                        No.</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="part_num"
                                        placeholder="Part No." v-model="items.part_num" readonly></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col sm="12" md="3">
                                    <label for="type_id" class="float-left control-label col-form-label">Discount
                                        Type</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="type_id" placeholder=""
                                        v-model="items.item_type_text" readonly></v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="category_id"
                                        class="float-left control-label col-form-label">Category</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="category_id"
                                        placeholder="" v-model="items.item_category_text" readonly></v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="uom_id" class="float-left control-label col-form-label">UOM</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="uom_id" placeholder=""
                                        v-model="items.item_uom_text" readonly></v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="size_id" class="float-left control-label col-form-label">Size</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="size_id" placeholder="0"
                                        v-model="items.item_size_text" readonly></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col sm="12" md="3">
                                    <label for="master_box_qty" class="float-left control-label col-form-label">Master
                                        Box Quantity</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="master_box_qty"
                                        placeholder="0" v-model="items.master_box_qty" @keypress="numberOnly" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="master_box_barcode"
                                        class="float-left control-label col-form-label">Master Box Barcode</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="master_box_barcode"
                                        placeholder="Master Box Barcode" v-model="items.master_box_barcode" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="small_box_qty" class="float-left control-label col-form-label">Small Box
                                        Quantity</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="small_box_qty"
                                        placeholder="0" v-model="items.small_box_qty" @keypress="numberOnly" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="small_box_barcode" class="float-left control-label col-form-label">Small
                                        Box Barcode</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="small_box_barcode"
                                        placeholder="Small Box Barcode" v-model="items.small_box_barcode" readonly>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col sm="12" md="3">
                                    <label for="selling_price" class="float-left control-label col-form-label">Selling
                                        Price</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="selling_price"
                                        placeholder="0.00" v-model="items.selling_price" @keypress="decimalOnly"
                                        readonly></v-text-field>
                                </v-col>
                                <v-col sm="12" md="3" v-if="viewSpecialPrice">
                                    <label for="sp_price" class="float-left control-label col-form-label">SP
                                        Price</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="sp_price"
                                        placeholder="0.00" v-model="items.sp_price" @keypress="decimalOnly" readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="moq" class="float-left control-label col-form-label">Minimum Order
                                        Quantity</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="moq" placeholder="0"
                                        v-model="items.moq" @keypress="numberOnly" readonly></v-text-field>
                                </v-col>
                                <v-col sm="12" md="2" v-if="viewCost">
                                    <label for="cost_in_php" class="float-left control-label col-form-label">Cost
                                        (PHP)</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="cost" placeholder="0.00"
                                        v-model="items.cost_in_php" @keypress="decimalOnly" readonly></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>

                            <!-- <v-row>
                                <v-col sm="12" md="2">
                                    <label for="cbm" class="float-left control-label col-form-label">CBM</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="cbm" placeholder="0"
                                        v-model="items.cbm" @keypress="decimalOnly" readonly></v-text-field>
                                </v-col>
                                <v-col sm="12" md="3">
                                    <label for="weight" class="float-left control-label col-form-label">Weight
                                        (kgs)</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="weight" placeholder="0"
                                        v-model="items.weight" @keypress="decimalOnly" readonly></v-text-field>
                                </v-col>
                                <v-col md="3">
                                    <div class="d-flex flex-column">
                                         <label for="stock_warning_qty" class="float-left control-label col-form-label">Stock Alert</label>
                                         <v-text-field dense outlined hide-details class="w-40" id="stock_warning_qty" placeholder="0" v-model="items.stock_warning_qty" readonly></v-text-field>
                                         <span v-for="errors in errors.stock_warning_qty" class="text-warning" :key="errors">{{ errors }}</span>
                                    </div>
                                 </v-col>
                            </v-row> -->

                            <!-- <v-row>
                                 <v-col sm="12" md="3">
                                    <label for="moq" class="float-left control-label col-form-label">Minimum Purchase
                                        Order Quantity</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="moq" placeholder="0"
                                        v-model="items.moq_po" @keypress="numberOnly" readonly></v-text-field>
                                </v-col>
                                 <v-col md="3">
                                        <label for="limit_order" class="float-left control-label col-form-label">Limit Order</label>
                                        <v-text-field dense outlined hide-details class="w-100" id="limit_order" v-model="items.limit_order" @keypress="numberOnly" readonly></v-text-field>
                                        <span v-for="errors in errors.limit_order" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                    <v-col md="3">
                                        <label for="discontinue_order_date" class="float-left control-label col-form-label">Discontinue Order Date</label>
                                            <v-text-field v-model="items.discontinue_order_date" class="w-100" append-icon="mdi-calendar-month" dense outlined hide-details readonly></v-text-field>
                                        <span v-for="errors in errors.discontinue_order_date" class="text-warning" :key="errors">{{ errors }}</span>
                                    </v-col>
                                <v-col sm="12" md="6">
                                    <label for="remarks" class="float-left control-label col-form-label">Remarks</label>
                                    <v-text-field dense outlined hide-details class="w-100" id="remarks" placeholder="Remarks"
                                        v-model="items.remarks" readonly></v-text-field>
                                </v-col>
                            </v-row> -->

                            <v-row>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                    <v-switch v-model="items.foc" label="foc" color="primary" input-value="1" readonly
                                        disabled></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                    <v-switch v-model="items.phased_out" label="phased out" color="primary"
                                        :input-value="1" :true-value="1" :false-value="0" readonly disabled></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                    <v-switch v-model="items.exclude_in_pricelist" label="exclude_in_pricelist" color="primary"
                                        :input-value="1" :true-value="1" :false-value="0" readonly disabled></v-switch>
                                </v-col>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                    <v-switch v-model="items.active" label="active" color="primary" :true-value="1"
                                        :false-value="0" :input-value="1" readonly disabled></v-switch>
                                </v-col>
                            </v-row>

                            <!-- <v-row>
                                <v-col sm="12" md="4">
                                    <div class="col-sm-12 float-left control-label col-form-label"
                                        v-if="items.substitution_items !== null || (typeof items.substitution_items !== 'undefined') || items.substitution_items != []">
                                        New Models</div>
                                    <span
                                        v-if="items.substitution_items !== null || (typeof items.substitution_items !== 'undefined') || items.substitution_items != []">
                                        <span v-for="(item, i) in items.substitution_items" :key="i">
                                            <v-chip color="teal darken-1" text-color="white" label>{{ item.text }}
                                            </v-chip>
                                        </span>
                                    </span>
                                    <span v-else>
                                        <i>No New Models</i>
                                    </span>
                                </v-col>
                            </v-row> -->
                        </v-card-text>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog  v-model="partsCompatibility.dialog" persistent width="50%">
                <v-card>
                    <v-card-title class="subtitle-2">
                        <v-col>
                            <span>PARTS COMPATIBILITY</span>
                        </v-col>
                        <v-spacer />
                        <v-col class="d-flex justify-end">
                            <v-btn
                                color="gray"
                                text
                                icon
                                @click="partsCompatibility.dialog = false"
                            >
                                <v-icon>
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-card-title>
                    <v-divider class="mt-0 mx-5" light/>

                    <v-card-text>
                        <v-data-table
                            :headers="partsCompatibility.headers"
                            :items="partsCompatibility.items"
                            :no-data-text="`No Compatibility Parts`"
                            :loading="partsCompatibility.loading"
                        >

                        </v-data-table>
                    </v-card-text>

                    <!-- <v-card-actions>
                        <v-spacer />

                        <v-btn
                            color="green darken-1"
                            text
                        >
                        </v-btn>
                    </v-card-actions> -->
                </v-card>
            </v-dialog>
        </v-app>
        <ItemExclusiveComponent
            @closeItemExclusiveDialog="closeItemExclusiveDialog"
            :action="'View'"
            :item_id="viewItemId"
            :showItemExclusiveModal="showItemExclusiveModal">
        </ItemExclusiveComponent>
    </v-col>

</template>

<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import ItemExclusiveComponent from './ItemExclusiveComponent.vue';

export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            view_item_dialog: false,
            items: [],
            errors: [],
            actionResult: {
                model: 'Item',
                action: ''
            },
            showItemExclusiveModal: false,
            suggested_items_headers: [
                { text: 'Image', width: '7%', value: 'image_path' },
                { text: 'Model', width: '15%', value: 'text' },
                { text: 'Name', value: 'name' },
                { text: 'SRP', width: '15%', value: 'selling_price' },
            ],
            substitution_items_headers: [
                { text: 'Image', width: '7%', value: 'image_path' },
                { text: 'Model', width: '15%', value: 'text' },
                { text: 'Name', value: 'name' },
                { text: 'SRP', width: '15%', value: 'selling_price' },
            ],
            partsCompatibility: {
                dialog: false,
                headers: [
                    {text:'Model', width:'35%', value:'model', sortable:false},
                    {text:'Name', width:'35%', value:'name', sortable:false},
                    {text:'Category', width:'20%', value:'category', sortable:false},
                    {text:'Part Number', width:'30%', value:'part_num', sortable:false},
                    {text:'SRP', width:'20%', value:'selling_price', sortable:false}
                ],
                items: [],
                loading: false,
            },

        }
    },
    components:{
        ItemExclusiveComponent,
    },
    props: [
        'viewItemId',
        'viewSpecialPrice',
        'viewCost',
    ],
    methods: {
        resetFields() {
            this.errors = []
            // this.items = []
        },
        checkImagePath(item) {
            if (item.image_path == null) {
                // return URL+'images/items/no-image.png'
                item.image_path = process.env.VUE_APP_API_HOST + '/images/items/no-image.png'
                return item.image_path
            }
            return process.env.VUE_APP_API_HOST + '/' + item.image_path;
        },
        openItemExclusiveDialog() {
            this.showItemExclusiveModal = true;
        },
        closeItemExclusiveDialog() {
            this.showItemExclusiveModal = false;
        },
        showPCDialog() {
            this.partsCompatibility.dialog = true;
            this.getPartsCompatibility();
        },
        getPartsCompatibility() {
            let itemId = this.GET_SHOW_ITEM.id;
            this.partsCompatibility.loading = true;
            this.$store.dispatch('getPartsCompatibility', {item_id:itemId}).then( response => {
                this.partsCompatibility.items = this.GET_PARTS_COMPATIBILITY;
                this.partsCompatibility.loading = false;
            }).catch( e => {
                console.error(e);
                this.partsCompatibility.loading = false;
            });
        }
    },
    computed: {
        ...mapGetters(['GET_SHOW_ITEM','GET_PARTS_COMPATIBILITY'])
    },
    watch: {
        viewItemId: function (val) {
            if (val != -1) {
                if (this.viewItemId != null && this.viewItemId > 0) {

                    this.$store.dispatch('showItem', this.viewItemId).then(response => {
                        if (this.GET_SHOW_ITEM.error) {
                            swal.fire("", "View Failed", "warning");
                            return false
                        }
                        this.items = this.GET_SHOW_ITEM
                        this.view_item_dialog = true
                    }).catch(error => {
                        // if(error.this.GET_SHOW_ITEM.status == 422){
                        //     this.errors = error.this.GET_SHOW_ITEM.data.errors
                        // }
                    });
                }
            }
        },
    },
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
}

.item_custom_button {
    width: 100% !important;
    height: 100% !important;
    display: inline-table;
    white-space: normal;
    padding: 1px !important;
    font-size: smaller !important;
}

.item-input-field {
    margin-top: 32px;
}
</style>
