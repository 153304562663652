<template>
  <v-container fluid>

          <ComponentHeader
            :button="true"
            :title="'Shipment Per Item Reports'"
            :type="'Inventory'"
        ></ComponentHeader>
        <v-container fluid class="bg-bluish-gray">
            <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>
                <v-row class="pa-2">
                    <v-col lg="3">

                        <v-autocomplete :items="GET_ITEM_SELECTION_DEFAULT"
                        :loading="loading.item_selection"
                        :disabled="loading.item_selection"
                        outlined
                        dense
                        chips
                        deletable-chips
                        background-color="white"
                        multiple
                        small-chips
                        return-object
                        v-model="item_ids"
                            item-value="value"
                            item-text="text"
                            label="Select Model"
                            class="small"
                            @select="onSelectItems"
                        >
                        </v-autocomplete>
                        <span v-for="errors in errors.item_ids" class="text-warning" :key="errors">{{errors}}</span>
                    </v-col>
                    <v-col lg="4">

                        <v-autocomplete :items="GET_ITEM_SELECTION_DEFAULT"
                        :loading="loading.item_selection"
                        :disabled="loading.item_selection"
                        outlined
                        dense
                        chips
                        deletable-chips
                        background-color="white"
                        multiple
                        small-chips
                        return-object
                        v-model="item_ids"
                            item-value="value"
                            item-text="name"
                            label="Select Name"
                            class="small"
                            @select="onSelectItems"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="exportShipmentPerItem()">Export</v-btn>
                        <v-btn small class="ml-1"  @click="getAllItems()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row class="pa-2">
                    <v-col lg="2">

                        <v-autocomplete :items="GET_ITEM_TYPES"
                        :loading="loading.item_type_selection"
                        :disabled="loading.item_type_selection"
                        outlined
                        dense
                        chips
                        deletable-chips
                        background-color="white"
                        multiple
                        small-chips
                        return-object
                        v-model="item_type_ids"
                            item-value="value"
                            item-text="text"
                            label="Select Disc Type"
                            class="small"
                            @select="onSelectTypes"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="3">

                        <v-autocomplete :items="GET_ALL_ITEM_CATEGORIES"
                        :loading="loading.category_selection"
                        :disabled="loading.category_selection"
                        outlined
                        dense
                        chips
                        deletable-chips
                        background-color="white"
                        multiple
                        small-chips
                        return-object
                        v-model="item_category_ids"
                            item-value="id"
                            item-text="name"
                            label="Select Item Category"
                            class="small"
                            @select="onSelectCategories"
                        >
                        </v-autocomplete>
                        <span v-for="errors in errors.item_category_ids" class="text-warning" :key="errors">{{errors}}</span>
                    </v-col>
                    <v-col  lg="3" id="date_from">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date_from"
                                    readonly
                                    outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_from" class="text-warning" :key="errors">{{errors}}</span>
                    </v-col>
                    <v-col lg="3" id="date_to">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="date_to"
                                    readonly
                                    outlined
                                    dense
                                    background-color="white"
                                    label="Date To"
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_to" class="text-warning" :key="errors">{{errors}}</span>
                    </v-col>
                </v-row>
                <v-row class="pa-2">
                    <v-col lg="3">

                            <v-autocomplete
                            :items="GET_ALL_WAREHOUSES"
                            :loading="loading.warehouse_selection"
                            :disabled="loading.warehouse_selection"
                            v-model="item_warehouse_ids"
                            outlined
                            dense
                            chips
                            deletable-chips
                            background-color="white"
                            multiple
                            small-chips
                            return-object
                            item-value="value"
                            item-text="text"
                            @select="getSelectedWarehouses"
                            label="Select Warehouse"
                            class="small"
                            >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="3">

                            <v-autocomplete
                            :items="status_list"
                            v-model="status_ids"
                            outlined
                            dense
                            chips
                            deletable-chips
                            background-color="white"
                            multiple
                            small-chips
                            return-object
                            item-value="value"
                            item-text="text"
                            @select="getSelectedStatus"
                            label="Select Status"
                            class="small"
                            >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="pa-2">
                <div class="w-100">
                    <v-card v-show="loaded">
                        <v-container fluid>
                            <v-data-table
                                :loading="!loaded"
                                :headers="headers"
                                :items="items"
                                :options.sync="options"
                                :server-items-length="totalItems"
                                :items-per-page="10"
                                :expanded.sync="expanded"
                                :footer-props="{
                                    itemsPerPageOptions: itemsPerPageOptions
                                }"
                                show-expand
                                class="w-100"
                                @item-expanded="loadDetails"
                                dense
                            >

                            <template v-slot:[`item.image_path`]="{ item }">
                                <v-img class="img-thumbnail" width="60" height="60" :src="item.image_path == null ? no_image : item.image_path" eager contain></v-img>
                            </template>

                            <template v-slot:[`item.selling_price`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.selling_price | currency }}
                                </span>
                            </template>

                            <template v-slot:[`item.sp_price`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.sp_price | currency }}
                                </span>
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="p-0">
                                    <v-simple-table dense dark >
                                        <template v-slot:default>
                                            <thead>
                                                <th>Container #</th>
                                                <th>Warehouse</th>
                                                <th>Broker</th>
                                                <th>Date Created</th>
                                                <th>Received By</th>
                                                <th>Receive Date</th>
                                                <th>Confirm Date</th>
                                                <th style="text-align:center">Status</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(containers, i) in item.containers" :key="i">
                                                    <td width="8%">{{ containers.container_num }}</td>
                                                    <td width="15%">{{ containers.name }}</td>
                                                    <td width="10%">{{ containers.broker }}</td>
                                                    <td width="20%">{{ containers.date_created | formatDate }}</td>
                                                    <td width="20%">{{ containers.received_by }}</td>
                                                    <td width="15%">{{ containers.received_date | formatDate }}</td>
                                                    <td width="15%">{{ containers.confirm_date | formatDate }}</td>
                                                    <td ><v-chip
                                                        class="ma-2"
                                                        :color="containers.status == 1 ? color = 'green' : 'red'"
                                                        text-color="white"
                                                        align="center"
                                                        x-small
                                                        >
                                                        {{ containers.status_text }}
                                                        </v-chip></td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </td>
                            </template>

                            </v-data-table>
                        </v-container>
                    </v-card>
                </div>
            </v-row>
        </v-container>

  </v-container>
</template>

<script>
import moment from 'moment'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue';
const ExcelJS = require('exceljs')
      const FileSaver = require('file-saver');

export default {
    mixins: [ SharedFunctionsComponentVue ],
    data(){
        return {
            expanded: [],
            errors: [],
            loaded: true,
            color:'',

            date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
            date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),

            items_list: [],
            item_ids: [],
            item_type_list: [],
            item_type_ids: [],
            item_category_list: [],
            item_category_ids: [],
            item_warehouse_list:[],
            item_warehouse_ids: [],
            status_ids: [],

            status_list: [
                { text: 'PENDING', value: '0' },
                { text: 'RECEIVE', value: '-1'},
                { text: 'APPROVED', value: '1' },
            ],

            row: null,
            menu2: false,
            menu3: false,

            no_image:'/images/noimage.png',

            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10 ,15, 50, 100, 250],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            headers: [
                { text: 'Image', align: 'left', value: 'image_path'},
                { text: 'Model', align: 'left', value: 'model', },
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'UOM', align: 'left', value: 'item_uom_text', },
                { text: 'Type', align: 'left', value: 'item_type_text', },
                { text: 'Category', align: 'left', value: 'item_category_text', },
                { text: 'SRP', align: 'right', value: 'selling_price', },
            ],

            initialLoad: false,

            loading:{
                    item_selection          : true,
                    item_type_selection     : true,
                    category_selection      : true,
                    warehouse_selection     : true,
            }
        }
    },
    components: {
        ComponentHeader
    },

    mounted(){
        this.$store.dispatch('getAllitemSelectionDefault');
        this.$store.dispatch('getAllItemCategories');
        this.$store.dispatch('getItemTypes');
        this.$store.dispatch('getAllWarehouses');
    },

    methods: {
        getSelectedStatus(item){
            this.status_ids = item
        },
        getSelectedWarehouses(item){
            this.item_warehouse_ids = item
        },
        getSelectedStatus(item){
            this.status_ids = item
        },
        onSelectItems(item){
            this.item_ids = item
        },
        onSelectTypes(item){
            this.item_type_ids = item
        },
        onSelectCategories(item){
            this.item_category_ids = item
        },
        loadDetails(item){
            this.$store.dispatch('reportExpansion',{
                    warehouse_ids:  JSON.stringify(this.item_warehouse_ids),
                    status_ids:     JSON.stringify(this.status_ids),
                    url:'getItemWarehouse/'+item.item.id
            }).then(response => {
                var data = response.data
                item.item.containers = data
                this.$forceUpdate()
            })
        },
        exportShipmentPerItem(){
            this.loaded = false
            this.$store.dispatch(`reports`,{
                item_id : JSON.stringify(this.item_ids),
                item_type_ids : JSON.stringify(this.item_type_ids),
                item_category_ids : JSON.stringify(this.item_category_ids),
                warehouse_ids : JSON.stringify(this.item_warehouse_ids),
                date_from : this.date_from,
                date_to : this.date_to,
                status_ids : JSON.stringify(this.status_ids),
                url:'exportShipmentPerItem'
            }).then(response => {

            if(!response.data[0]){
                this.loaded = true
                swal.fire('Error!','- An error occured','error')
                return false
            }
                let headers = this.ConverToExcelJsColumns(response.data)
                let workbook = new ExcelJS.Workbook()
                let worksheet = workbook.addWorksheet('sheet1')

                worksheet.columns = headers

                response.data.forEach(e => {
                    worksheet.addRow(e)
                });

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    FileSaver.saveAs(blob, `ShipmentPerItemReport ${this.date_from} - ${this.date_to}.xlsx`);
                    this.loaded = true
                }).catch(e=>{
                console.log(e)
                this.loaded = true
            });
        }).catch(e=>{
            console.log(e)
        })

            // })/?item_ids=${}&item_type_ids=${}&item_category_ids=${}&warehouse_ids=${}&date_from=${this.date_from}&date_to=${this.date_to}&status_ids=${}`, '_blank');
        },
        getAllItems(){
            this.loaded = false
            this.expanded = []

            this.$store.dispatch('reports',{
                page:               this.options.page,
                itemsPerPage:       this.options.itemsPerPage,
                sortBy:             this.options.sortBy,
                sortDesc:           this.options.sortDesc,
                search:             this.search,
                date_from:          this.date_from,
                date_to:            this.date_to,
                item_ids:           JSON.stringify(this.item_ids),
                item_type_ids:      JSON.stringify(this.item_type_ids),
                item_category_ids:  JSON.stringify(this.item_category_ids),
                warehouse_ids:      JSON.stringify(this.item_warehouse_ids),
                status_ids:         JSON.stringify(this.status_ids),
                url: 'getAllItemsContainer'
            }).then(response => {
                this.items        = response.data.items.data
                this.loaded       = true
                this.totalItems   = response.data.items.total
                this.initialLoad = true
            })
        },
        resetFields(){
            this.errors                 = []
            this.date_from              = moment().clone().startOf('month').format('YYYY-MM-DD')
            this.date_to                = moment().clone().endOf('month').format('YYYY-MM-DD')
            this.item_id                = ''
            this.expanded               = []
            this.item_ids               = []
            this.item_type_ids          = []
            this.item_category_ids      = []
            this.item_warehouse_ids     = []
            this.status_ids             = []
            this.items                  = []
        },
        searchInput(){
            this.getAllItems({ query: this.search });
        },
    },

    computed:{
            ...mapGetters([
                'GET_ITEM_SELECTION_DEFAULT',
                'GET_ALL_ITEM_CATEGORIES',
                'GET_ITEM_TYPES',
                'GET_ALL_WAREHOUSES'
                // 'GET_BRAND_DISCOUNT'
            ]),
    },

    watch: {
        options: {
            handler () {
                if(this.initialLoad == true){
                    this.getAllItems()
                }
            },
            deep: true,
        },
        'GET_ITEM_TYPES':{
            handler(val){
                this.item_type_list = val.data
                this.loading.item_type_selection    = false
            }
        },
        'GET_ALL_ITEM_CATEGORIES':{
            handler(val){
                this.item_category_list = val
                this.loading.category_selection     = false
            }
        },
        'GET_ITEM_SELECTION_DEFAULT':{
            handler(val){
                this.items_list = val
                this.loading.item_selection = false
            }
        },
        'GET_ALL_WAREHOUSES':{
          handler(val){
                  this.item_warehouse_list = val
                  this.loading.warehouse_selection = false
          }
        }
    }
};
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
