<template>
<div class="page-wrapper">

    <div class="container">
        <!-- <div class="row">
            <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title" style="color: black">Stock Card</h4>
            </div>
        </div> -->

        <div class="row">
           <stock-cards-table-component
                :stockCardsActionResult="action"
                :unconfirmed="unconfirmed"
                @showEditModal="showEditModal"
            >
            </stock-cards-table-component>
        </div>
    </div>

    <v-app id="addEditStockCardModal">
        <v-dialog v-model="add_edit_stockcard_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Stock Card</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_stockcard_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text v-if="loaded==true">
                    <form class="form-horizontal" action="/stockcards" method="post" @submit.prevent="onSubmit">
                        <div class="card-body">
                            <div class="row m-0 text-dark" v-if="action=='Edit'">
                                <div class="col-sm-12 col-md-4 text-left">
                                    <div class="d-flex flex-column">
                                        <span>SC#: {{ this.stock_card.stock_card_num }}</span>
                                        <span>Customer: {{ this.stock_card.customer_name }}</span>
                                        <span>Sales Order No: {{ this.stock_card.sales_order_num }}</span>
                                        <span>Issuer: {{ this.stock_card.issuer_name }}</span>
                                        <span>Date Issued: {{ this.stock_card.created_at | formatDateTime }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-8 text-right">
                                    <v-row class="d-flex justify-end">
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="num_of_box"
                                                outlined
                                                dense
                                                id="num_of_box"
                                                label="No. of boxes"
                                                class="w-100"
                                                >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-autocomplete :items="GET_ALL_WAREHOUSES"
                                                :loading="loading.warehouse_selection"
                                                :disabled="loading.warehouse_selection"
                                                outlined
                                                dense
                                                v-model="stock_card.warehouse_id"
                                                item-value="value"
                                                item-text="name"
                                                label="Select Warehouse"

                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="d-flex justify-end">
                                        <v-col cols="6">
                                            <v-textarea
                                            label="Remarks"
                                            no-resize
                                            rows="2"
                                            v-model="remarks"
                                            height=39
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                            label="SC Date"
                                            v-model="stock_card.stock_card_date"
                                            v-mask="'####-##-##'"
                                            height=39
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>

                        <v-card
                            outlined
                            class="p-2"
                        >
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <v-simple-table dense
                                        class="dynamic_list_table">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center mx-2" width="7%">MOQ</th>
                                                    <th class="text-center mx-2" width="7%">Qty to Ship</th>
                                                    <th class="text-center mx-2" width="7%">Qty Remaining</th>
                                                    <th class="text-center mx-2" width="7%">Qty DR</th>
                                                    <th class="text-center mx-2" width="7%">UOM</th>
                                                    <th class="text-center mx-2" width="10%">Model</th>
                                                    <th class="text-center mx-2" width="22%">Name</th>
                                                    <th class="text-center mx-2" width="4%">Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(scListItem, i) in stockCardItems"
                                                    :key="i"
                                                >
                                                    <td>
                                                        <input type="text" class="form-control text-center" v-model="stockCardItems[i].moq" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center" v-model="stockCardItems[i].quantity_served" @keypress="numberOnly"
                                                            @input="changeQuantity(i)" @blur="checkMoq(i)">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center" v-model="stockCardItems[i].packing_list_qty_remaining" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center" v-model="stockCardItems[i].packing_list_qty" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="stockCardItems[i].item_uom_text" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="stockCardItems[i].model" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="stockCardItems[i].name" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="stockCardItems[i].item_type_text" readonly>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </div>
                        </v-card>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn @click="addNewStockCard()" v-if="action=='Add'">Submit</v-btn>
                                <v-btn @click="editStockCard()" v-else-if="action=='Edit'">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
                <v-card-text v-else-if="loaded==false">
                    <div class="text-center">
                        <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>
    </v-app>

</div>
</template>

<script>
    import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
    import StockCardsTableComponent from '@/views/main/modules/Operations/StockCards/StockCardTableComponent.vue'
    import { VueMaskDirective } from 'v-mask'
    import { mapGetters } from 'vuex'
    import swal from 'sweetalert2';
    export default {
        mixins: [SharedFunctionsComponent],
        data(){
            return {
                stock_card: '',

                editStockCardId: '',
                add_edit_stockcard_dialog: false,

                stock_card_num: '',
                stock_card_date: '',

                user_id: '',
                status: '',
                created_at: '',
                issuer_name: '',

                stockCardItems: [],
                order: {
                    quantity_served: '',
                    quantity: '',
                    item_id: '',
                    type: '',
                    item: {
                        id: '',
                        model: '',
                        name: '',
                        description: '',
                        type_id: '',
                        category_id: '',
                        uom_id: '',
                        item_uom_text: '',
                        selling_price: '',
                        sp_price: '',
                        moq: ''

                    },
                },

                remarks: '',
                shipping_address_id: 0,
                shipping_address_list: [],

                items_list: [],
                type_list: [],
                warehouses_list: [],
                errors: [],
                action: '',
                actionResult : {
                    model : 'Stock Card',
                    action : ''
                },
                loaded: false,
                menu2: false,
                userAccess: {
                    create: false,
                },

                loading:{
                    warehouse_selection: true
                },
                num_of_box: '',
            }
        },
        components: {
            StockCardsTableComponent,
            VueMaskDirective
        },
        props: [
            'unconfirmed',
        ],
        methods: {
            checkMoq(key){
                if(this.stockCardItems[key].quantity_served != '' && this.stockCardItems[key].moq != ''){
                    var moq = parseInt(this.stockCardItems[key].moq);
                    if(moq != null && moq > 0){
                        if(this.stockCardItems[key].quantity_served % moq > 0){

                            var upperQty = this.stockCardItems[key].quantity_served
                            var lowerQty = this.stockCardItems[key].quantity_served
                            while(upperQty % moq > 0){
                                upperQty++
                            }
                            while(lowerQty % moq > 0){
                                lowerQty--
                            }
                            var suggestion = ''
                            if(lowerQty == 0){
                                suggestion = "\n Suggestion: "+upperQty
                            }else{
                                suggestion = "\n Suggestion: "+upperQty+" or "+ lowerQty
                            }
                            swal("", "Quantity should be divisible by "+moq+suggestion, "warning");
                            this.stockCardItems[key].quantity_served = ''
                        }
                    }
                }
            },
            removeOrderByIndex(index){
                this.stockCardItems.splice(index, 1)
            },
            changeQuantity(key){
                if(parseInt(this.stockCardItems[key].quantity_served) > parseInt(this.stockCardItems[key].packing_list_qty_remaining)){
                    this.stockCardItems[key].quantity_served = this.stockCardItems[key].packing_list_qty_remaining
                }
            },
            selectModel(key, model_id, is_editable = false) {
                if(model_id != null && model_id != ''){
                    let  params = {
                    method:'post',
                    url:'masterdata/getItemEditInfo/'+model_id,
                }
            this.$store.dispatch('StockCardsGet',params).then(response => {
                let data = response.data

                this.stockCardItems[key].item.name          = data.name
                this.stockCardItems[key].item.model         = data.model
                this.stockCardItems[key].item.sp_price      = data.sp_price
                this.stockCardItems[key].item.selling_price = data.selling_price
                this.stockCardItems[key].item.type_id       = data.type_id
                this.stockCardItems[key].type               = data.item_type_text
                this.stockCardItems[key].item.item_uom_text = data.item_uom_text
                this.stockCardItems[key].item.moq           = data.moq

                if(is_editable){
                    this.stockCardItems[key].is_editable = true
                }
            })

                }
                $("input").blur();
            },
            showAddStockCard(){
                this.add_edit_stockcard_dialog = true
                this.action                    = 'Add'
            },
            showEditModal(value){
                this.action = 'Edit'
                this.editStockCardId = value

                if(this.editStockCardId != null && this.editStockCardId > 0){
                    let  params = {
                    method:'post',
                    url:'inventory/viewStockCard/'+this.editStockCardId,
                }
                this.$store.dispatch('StockCardsGet',params).then(response => {
                let data = response.data

                if(data.error){
                    swal("", data.error, "warning");
                    return false
                }

                this.stock_card            = data.stock_card
                this.stockCardItems        = data.stock_card.stock_card_items
                this.num_of_box            = data.stock_card.number_of_boxes

                this.loaded = true
                this.add_edit_stockcard_dialog = true
                            }).catch(error => {

                    });
                }
            },
            editStockCard(){
                this.errors = []
                let  params = {
                    method:'post',
                    url:'inventory/stockCards/'+this.editStockCardId,
                    sc_id:          this.stock_card.id,
                    stockCardItems: this.stockCardItems,
                    warehouse_id:   this.stock_card.warehouse_id,
                    stock_card_date:  this.stock_card.stock_card_date,
                    num_of_box: this.num_of_box,
                }
            this.$store.dispatch('StockCardsPut',params).then(response => {
                let data = response.data
                    if(data.error){
                        swal.fire({
                            text:data.error,
                            icon:"warning",
                        })
                        // swal("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_stockcard_dialog = false
                    this.editStockCardId           = 0
                    this.action = 'success'
                    swal.fire({
                        text:'Edit Stock Card Successful!',
                        icon:'success',
                        timer:2000
                    });
                    // swal("", "Edit Stock Card Successful!", "success")
            }).catch(error => {
                console.log(error)
                    if(error.response.status == 422){
                        this.errors = error.response.data.errors
                    }
                });
            },
            resetFields(){
                this.errors                 = []
                this.stock_card_num         = ''
                this.stock_card_date        = ''
                this.user_id                = ''
                this.status                 = ''
                this.created_at             = ''
                this.issuer_name            = ''
                this.stockCardItems         = []
                this.remarks                = ''
                this.shipping_address_list  = []
                this.num_of_box             = ''
            },
            addKeyListener(){
                window.addEventListener("keyup", function(e) {
                    var keypressed = String.fromCharCode(e.keyCode)
                    if(e.ctrlKey && e.keyCode == 32){
                       // this.addOrderItem()
                        $("input").blur();

                    }else if(keypressed == '-'){
                      //  this.removeOrderItem()
                        $("input").blur();
                    }

                }.bind(this));
            },
            getWarehouses(){
                // this.$store.dispatch('serviceGetWarehouses').then(response => {
                //     this.warehouses_list = response.data
                // });
            },
            async checkAccess(){
                this.$store.dispatch("checkAccessUser",this.$router.currentRoute.params.id).then(response=>{
                    if(response != "fail"){
                        this.userAccess.create = false;
                        response.forEach(e => {
                            if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                            }
                        });
                    }
                });
            },
        },
        mounted: function (){
            this.getWarehouses()
            this.addKeyListener()
             $('input').attr('autocomplete','off');
        },
        computed:{
            ...mapGetters([
                'GET_ALL_WAREHOUSES'
            ])
        },
        watch:{
            add_edit_stockcard_dialog:function(){
                if(this.add_edit_stockcard_dialog == true){
                    if(this.action == 'Add'){
                        this.stockCardItems = []
                        this.loaded = true
                    }
                }
            },
            customer_id:function(value){
                if(this.add_edit_stockcard_dialog == true){
                    this.loaded = false
                    if(value > 0){
                        this.$store.dispatch('showCustomers',value).then(response => {
                            let data = response.data

                            this.shipping_address_list          = data.all_shipping_addresses
                        }).catch(error => {

                        });
                    }
                }
            },
            'GET_ALL_WAREHOUSES':{
            handler(val){
                this.loading.warehouse_selection = false
            }
        },
        },

    };
</script>
