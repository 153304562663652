<template>
  <div>
    <v-app id="load_bar" v-if="!loaded">
      <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
      <v-dialog
        v-model="sales_quotation_items_dialog"
        persistent
        max-width="100%"
        scrollable
      >
        <v-card>
          <v-row class="m-0">
            <v-card-title>
              <span class="headline">{{
                `${!!service ? "Spare Parts" : "Sales"} Quotation Details`
              }}</span>
            </v-card-title>
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="
                  clearSalesQuotationItems();
                  $emit('closeView');
                "
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row class="m-0">
              <v-container fluid>
                <v-row>
                  <div class="col-sm-12 col-md-8 text-left text-dark">
                    <div class="row ml-2">
                      <div class="col-sm-12 col-md-8 p-0">
                        {{
                          `${
                            !!service ? "Spare Parts" : "Sales"
                          } Quotation No: ${sales_quotation.quote_num}`
                        }}
                      </div>
                      <div class="col-sm-12 col-md-4 p-0">
                        Issuer: {{ sales_quotation.issuer_name }}
                      </div>
                    </div>
                    <div class="row ml-2">
                      <div class="col-sm-12 col-md-8 p-0">
                        Customer: {{ sales_quotation.customer_name }}
                      </div>
                      <div class="col-sm-12 col-md-4 p-0">
                        Date Issued:
                        {{ sales_quotation.created_at | formatDateTime }}
                      </div>
                    </div>
                    <div class="row ml-2">
                      <div class="col-sm-12 col-md-8 p-0">
                        Remarks: {{ sales_quotation.remarks }}
                      </div>
                      <div
                        class="col-sm-12 col-md-4 p-0"
                        v-if="sales_quotation.convert_date != null"
                      >
                        Convert Date:
                        {{ sales_quotation.convert_date | formatDateTime }}
                      </div>
                    </div>
                    <div class="row ml-2">
                      <div class="col-sm-12 col-md-12 p-0">
                        Notes: {{ sales_quotation.notes }}
                      </div>
                    </div>
                    <div
                      class="row ml-2 mt-2"
                      v-if="
                        discounts.length > 0 && sales_quotation.promo_id == ''
                      "
                    >
                      <div class="col-sm-12 col-md-8">
                        <div class="row p-0">
                          <div
                            class="col-sm-12 col-md-6 py-0"
                            v-for="(disc, index) in itemTypeDiscounts"
                            :key="index"
                          >
                            <span>{{ disc }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4 p-0"></div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4 pull-right text-right">
                    <div class="mb-2">
                      <v-btn @click="renderPDF('download')">Download PDF</v-btn>
                    </div>
                  </div>
                </v-row>
              </v-container>
            </v-row>

            <v-data-table
              :headers="selectedHeaders"
              :items="salesQuotationItems"
              :items-per-page="500"
              hide-default-footer
              class="w-100"
              :expanded.sync="expanded_details.expanded"
              show-expand
              item-key="id"
            >
              <template v-slot:[`item.foc`]="{ item }">
                <v-tooltip :disabled="!item.foc" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <td
                      class="side-borders-white px-0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span v-if="item.foc == '1'">Yes</span>
                      <span v-else-if="item.foc == '0'">No</span>
                    </td>
                  </template>
                  <span>{{ item.foc_remarks }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.display_disc_price`]="{ item }">
                <span class="text-nowrap">
                  &#x20b1;{{ item.display_disc_price | currency }}
                </span>
              </template>

              <template v-slot:[`item.display_srp`]="{ item }">
                <span class="text-nowrap">
                  &#x20b1;{{ item.display_srp | currency }}
                </span>
              </template>

              <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                <td v-if="item.original_item_id != 0" class="text-start">
                  <v-btn text icon x-small color="black"
                        @click="expand(!isExpanded)"
                        class="v-data-table__expand-icon"
                        :class="{'v-data-table__expand-icon--active' : isExpanded}">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </td>
             </template>

              <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="p-0">
                      <v-data-table
                          :headers="expanded_details.headers"
                          :items="[item.original_item]"
                          dark
                          dense
                          hide-default-footer
                          fixed-header>

                          <template v-slot:[`item.selling_price`]="{ item }">
                              <span class="text-nowrap">
                                  &#x20b1;{{ item.selling_price | currency }}
                              </span>
                          </template>

                          <template v-slot:[`item.discounted_price`]="{ item }">
                              <span class="text-nowrap">
                                &#x20b1; {{ item.discounted_price | currency}}
                              </span>
                          </template>

                          <template v-slot:[`item.total_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_amount | currency }}
                            </span>
                          </template>

                          <template v-slot:[`item.foc`]="{ item }">
                            <span class="text-nowrap">
                              <span v-if="item.foc == '1'">Yes</span>
                             <span v-else-if="item.foc == '0'">No</span>
                            </span>
                          </template>

                          <template v-slot:[`item.display_final_price`]="{ item }">
                            <span class="text-nowrap">
                              &#x20b1;{{ item.discounted_price | currency }}
                            </span>
                          </template>

                          <template v-slot:[`item.display_total_amount`]="{ item }">
                            <span class="text-nowrap">
                              &#x20b1;{{ (item.discounted_price * item.quantity ) | currency }}
                            </span>
                          </template>

                      </v-data-table>
                  </td>
              </template>

              <template v-slot:[`item.display_final_price`]="{ item }">
                <span class="text-nowrap">
                  &#x20b1;{{ item.display_final_price | currency }}
                </span>
              </template>

              <template v-slot:[`item.display_total_amount`]="{ item }">
                <span class="text-nowrap">
                  &#x20b1;{{ item.display_total_amount | currency }}
                </span>
              </template>
            </v-data-table>

            <v-row class="m-0">
              <v-container fluid>
                <v-row>
                  <div class="col-sm-12 col-md-8 text-left">
                    <div class="card-body py-4 d-flex">
                      <!-- <span class="m-1"><v-btn @click="checkCreditLimit" :disabled="sales_quotation.status != 0" v-if="userAccess.convert_to_sales_order && !service">Convert to SO</v-btn></span> -->
                      <span class="m-1"
                        ><v-btn
                          @click="convertToSalesOrder()"
                          :disabled="sales_quotation.status != 0"
                          v-if="userAccess.convert_to_sales_order && !this.sales_quotation.sc_repair_form_id"
                          :loading="loading"
                          >Convert to SO</v-btn
                        ></span
                      >
                      <span class="m-1"
                        ><v-btn
                          @click="
                            print_no_price = false;
                            print_with_image = false;
                            renderPDF('print');
                          "
                          >Print</v-btn
                        ></span
                      >
                      <span class="m-1"
                        ><v-btn
                          @click="
                            print_no_price = true;
                            print_with_image = false;
                            renderPDF('print');
                          "
                          >Print No Price</v-btn
                        ></span
                      >
                      <span class="m-1"
                        ><v-btn
                          @click="
                            print_no_price = false;
                            print_with_image = true;
                            renderPDF('print');
                          "
                          >Print With Image</v-btn
                        ></span
                      >
                    </div>
                  </div>
                  <br/>
                  <div
                    class="col-sm-12 col-md-4 pull-right text-right text-dark"
                  >
                    Total Amount: &#x20b1;{{
                      sales_quotation.total_amount | currency
                    }}
                    <br/>
                     Total Amount with Stocks: &#x20b1;{{ sales_quotation.total_amount_with_stocks |
                              currency
                      }}
                     <br/>
                      Total Amount without Stocks: &#x20b1;{{ sales_quotation.total_amount_without_stocks
                              | currency
                      }}
                  </div>
                </v-row>
              </v-container>
            </v-row>
          </v-card-text>

          <v-container fluid style="display: none">
            <!-- <div id="div_logo" >
                        <div style="font-size: 12px; line-height: 1.9">
                            <p v-if="service==0">
                                <span style="font-size: 18px; font-weight: bold;">
                                    D GREAT EAST INDUSTRIAL SUPPLY CORPORATION
                                </span>
                                <br>420 Del Monte Ave, Brgy. Siena, Quezon City
                                <br>Cell No: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016
                                <br>Tel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539
                                <br>
                            </p>
                            <p v-else>
                                <span style="font-size: 18px; font-weight: bold;">
                                    D GREAT EAST INDUSTRIAL SUPPLY CORPORATION
                                </span>
                                <br>420 Del Monte Ave, Brgy. Siena, Quezon City
                                <br>Cell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016
                                <br>Tel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539
                                <br>Service Mobile / Viber No.: +63 919 078 0019 / +63 927 414 5539
                                <br>Spare Parts Mobile / Viber No.: +63 995 095 3153
                            </p>
                        </div>
                    </div> -->
            <div
              id="div_sales_quote_num"
              style="font-size: 12px; line-height: 1.9"
            >
              <div
                style="
                  border-top: 1px dashed #cfcfcf;
                  padding: 2px;
                  padding-bottom: -4px;
                  text-align: center;
                  border-bottom: none;
                "
              >
                &nbsp;
              </div>
              <p>
                <span style="font-size: 18px; font-weight: bold"
                  >Sales Quotation</span
                ><br /><b>SQ# {{ sales_quotation.quote_num }}</b>
              </p>
            </div>

            <div>
              <table id="customer_details">
                <tbody>
                  <tr>
                    <td>Customer:</td>
                    <td>{{ sales_quotation.customer_name | uppercase }}</td>
                    <td>Date:</td>
                    <td>{{ sales_quotation.created_at | formatDate }}</td>
                  </tr>
                  <tr>
                    <td>Address:</td>
                    <td>
                      {{ sales_quotation.shipping_address_text | uppercase }}
                    </td>
                    <td>Terms:</td>
                    <td>
                      {{
                        sales_quotation.terms == 0
                          ? "CASH"
                          : sales_quotation.terms + " days"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Remarks:</td>
                    <td>{{ sales_quotation.remarks }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table id="items_details">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>QTY</th>
                    <th>UNITS</th>
                    <th>MODEL</th>
                    <th>DESCRIPTION</th>
                    <th>UNIT PRICE</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in salesQuotationItems" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.item.item_uom_text }}</td>
                    <td>{{ item.item.model }}</td>
                    <td>{{ item.item.name }}</td>
                    <td v-if="item.total_amount > 0">
                      {{ item.display_srp | currency }}
                    </td>
                    <td v-else>FOC</td>
                    <td v-if="item.total_amount > 0">
                        {{ computePrintOutItemTotal(item.srp, item.quantity) | currency }}
                    </td>
                    <td v-else>FOC</td>
                  </tr>
                  <tr>
                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table id="items_details_without_price">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>QTY</th>
                    <th>UNITS</th>
                    <th>MODEL</th>
                    <th>DESCRIPTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in salesQuotationItems" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.item.item_uom_text }}</td>
                    <td>{{ item.item.model }}</td>
                    <td>{{ item.item.name }}</td>
                  </tr>
                  <tr>
                    <td colspan="5">***** NOTHING FOLLOWS *****</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table id="items_details_with_image">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>IMAGE</th>
                    <th>QTY</th>
                    <th>UNITS</th>
                    <th>MODEL / DESCRIPTION</th>
                    <th>UNIT PRICE</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in salesQuotationItems" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td :id="'item' + item.id"></td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.item.item_uom_text }}</td>
                    <td>{{ '(' + item.item.model + ') ' + item.item.name }}</td>
                    <td v-if="item.total_amount > 0">
                      {{ item.display_srp | currency }}
                    </td>
                    <td v-else>FOC</td>
                    <td v-if="item.total_amount > 0">
                      {{ computePrintOutItemTotal(item.srp, item.quantity) | currency }}
                    </td>
                    <td v-else>FOC</td>
                  </tr>
                  <tr>
                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table id="total_details">
                <tbody>
                  <tr>
                    <td>
                      <p>
                        {{
                          this.itemTypeDiscountsPDF[0] != null
                            ? this.itemTypeDiscountsPDF[0]
                            : ""
                        }}
                      </p>
                    </td>
                    <td>
                      <p>GROSS SALES:</p>
                    </td>
                    <td>
                      <p>PHP</p>
                    </td>
                    <td>
                      <p>{{ sales_quotation.gross_amount | currency }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        {{
                          this.itemTypeDiscountsPDF[1] != null
                            ? this.itemTypeDiscountsPDF[1]
                            : ""
                        }}
                      </p>
                    </td>
                    <td>
                      <p>Less - Discount:</p>
                    </td>
                    <td>
                      <p></p>
                    </td>
                    <td>
                      <p>{{ sales_quotation.discount_amount | currency }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        {{
                          this.itemTypeDiscountsPDF[2] != null
                            ? this.itemTypeDiscountsPDF[2]
                            : ""
                        }}
                      </p>
                    </td>
                    <td>
                      <p>TOTAL SALES:</p>
                    </td>
                    <td>
                      <p>PHP</p>
                    </td>
                    <td>
                      <p>
                        {{ sales_quotation.display_total_amount | currency }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table id="quotation_only">
                <tbody>
                  <tr>
                    <td><span>Note: QUOTATION ONLY</span></td>
                    <td></td>
                    <td>
                      <span>No. of Items: {{ this.no_of_items }}</span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table id="substitution_items">
                <tbody>
                  <tr>
                     <td> <span v-for="(subs,i) in salesQuotationSubstitutionItems" :key="i">
                        {{ '*'+subs.original_item.model+' was replaced with '+subs.item.model+'*' }}
                        </span>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div>
                        <table id="num_of_items">
                            <tbody>
                                <tr>
                                    <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
            <div>
              <table id="footer">
                <tbody>
                  <tr>
                    <td>{{ sales_quotation.issuer_name }}<br />___________</td>
                    <!-- <td><br>___________</td> -->
                  </tr>
                  <tr>
                    <td>Issued By</td>
                    <!-- <td>Checked By</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </v-container>

          <ConfirmOverrideComponent
            :approve_override_dialog="override.dialog"
            :departments_allowed="override.departments_allowed"
            :heading="override.heading"
            @closeConfirmOverride="closeConfirmOverride"
          ></ConfirmOverrideComponent>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>
<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import ConfirmOverrideComponent from "@/views/main/Utils/ConfirmOverrideComponent.vue";
import jsPDF from "jspdf";
import { mapGetters } from "vuex";
import swal from 'sweetalert2'
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent2.vue';
export default {
  mixins: [SharedFunctionsComponent,PusherFunctionComponent],
  components: {
    ConfirmOverrideComponent,
  },
  props: ["viewSalesQuotationId"],
  mounted() {
    this.checkAccess();
    this.callPusher();
  },
  data() {
    return {
      override: {
        dialog: false,
        heading: "",
        departments_allowed: ["AC", "EX", "IT"],
        authenticated: false,
        action: "",
      },

      selectedHeaders: [],
      salesQuotationItems: [],
      salesQuotationItemsHeaders: [
        {
          text: "Model",
          align: "left",
          value: "item.model",
          width: "15%",
          sortable: false,
        },
        {
          text: "Name",
          align: "left",
          value: "item.name",
          width: "35%",
          sortable: false,
        },
        {
          text: "Type",
          align: "left",
          value: "item.item_type_text",
          sortable: false,
        },
        { text: "FOC", align: "left", value: "foc", sortable: false },
        { text: "SRP", align: "left", value: "display_srp", sortable: false },
        {
          text: "Disc Price",
          align: "left",
          value: "display_disc_price",
          sortable: false,
        },
        { text: "Qty", align: "left", value: "quantity", sortable: false },
        {
          text: "UOM",
          align: "left",
          value: "item.item_uom_text",
          sortable: false,
        },
        {
          text: "Price",
          align: "right",
          value: "display_final_price",
          width: "15%",
          sortable: false,
        },
        {
          text: "Total",
          align: "right",
          value: "display_total_amount",
          width: "15%",
          sortable: false,
        },
      ],

      stockCardSalesQuotationItemsHeaders: [
        {
          text: "Model",
          align: "left",
          value: "item.model",
          width: "15%",
          sortable: false,
        },
        {
          text: "Name",
          align: "left",
          value: "item.name",
          width: "35%",
          sortable: false,
        },
        {
          text: "Type",
          align: "left",
          value: "item.item_type_text",
          sortable: false,
        },
        { text: "FOC", align: "left", value: "foc", sortable: false },
        { text: "SRP", align: "left", value: "display_srp", sortable: false },
        {
          text: "Disc Price",
          align: "left",
          value: "display_disc_price",
          sortable: false,
        },
        { text: "Qty", align: "left", value: "quantity", sortable: false },
        {
          text: "SC Qty",
          align: "left",
          value: "sc_quantity",
          sortable: false,
        },
        {
          text: "UOM",
          align: "left",
          value: "item.item_uom_text",
          sortable: false,
        },
        {
          text: "Price",
          align: "right",
          value: "display_final_price",
          width: "15%",
          sortable: false,
        },
        {
          text: "Total",
          align: "right",
          value: "display_total_amount",
          width: "15%",
          sortable: false,
        },
      ],

      loaded: true,
      sales_quotation_items_dialog: false,
      sales_quotation: "",
      discounts: [],
      itemTypeDiscounts: [],
      itemTypeDiscountsPDF: [],
      type_list: [],
      errors: [],
      print_no_price: false,
      print_with_image: false,
      userAccess: {
        convert_to_sales_order: false,
      },
      service: false,
      salesQuotationSubstitutionItems:[],
      expanded_details:{
        headers:[
          {
            text: "(Old) Model",
            align: "left",
            value: "model",
            width: "15%",
            sortable: false,
          },
          {
            text: "(Old) Name",
            align: "left",
            value: "name",
            width: "35%",
            sortable: false,
          },
          {
            text: "(Old) Type",
            align: "left",
            value: "item_type_text",
            sortable: false,
          },
          { text: "(Old) FOC", align: "left", value: "foc", sortable: false },
          { text: "(Old) SRP", align: "left", value: "selling_price", sortable: false },
          {
            text: "(Old) Disc Price",
            align: "left",
            value: "discounted_price",
            sortable: false,
          },
          { text: "(Old) Qty", align: "left", value: "quantity", sortable: false },
          {
            text: "(Old) UOM",
            align: "left",
            value: "item_uom_text",
            sortable: false,
          },
          {
            text: "(Old) Price",
            align: "right",
            value: "display_final_price",
            width: "15%",
            sortable: false,
          },
          {
            text: "(Old) Total",
            align: "right",
            value: "display_total_amount",
            width: "15%",
            sortable: false,
          },
        ],
        items:[],
        loading:false,
        expanded: [],
        customer_discount:[]
      },
      loading:false
    };
  },
  methods: {
    async getBase64Image(src, callback) {
        let img = new Image();
        img.src = src
        img.crossOrigin = "Anonymous"
        let imageHandler = new Promise(resolve => {
            img.onload = function () {
                let canvas = document.createElement("canvas");
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(this, 0, 0);

                let dataUrl = canvas.toDataURL("image/jpg");
                resolve(dataUrl);
            }
        })
        return imageHandler
    },
    checkAccess() {
      this.$store.dispatch(
        "checkAccessUser",
        this.$router.currentRoute.params.id
      );
    },
    closeConfirmOverride(user_id, authenticated = false) {
      this.override.dialog = false;
      if (authenticated) {
        this.override.authenticated = true;
        // if (this.override.action == 'credit_limit') {
        //     this.checkCreditTerms()
        // } else {
        this.convertToSalesOrder();
        // }
      }
    },
    checkCreditLimit() {
      this.$store
        .dispatch("checkCreditLimit", {
          params: {
            customer_id: this.sales_quotation.customer_id,
            total_amount: this.sales_quotation.total_amount,
          },
        })
        .then((response) => {
          if (response.data.status == "over") {
            let over_amount =
              parseFloat(response.data.running_amount) +
              parseFloat(response.data.current_amount) -
              parseFloat(response.data.credit_limit_amount);
            swal.fire({
              title: `This order will exceed remaining credit limit by ${this.getTwoDecimals(
                over_amount
              )}. Approve?`,
              icon: "warning",
              showConfirmButton:true,
        showCancelButton  :  true,
        reverseButtons:true,
        allowOutsideClick :  false,
        confirmButtonColor: '#397373',
        cancelButtonColor : 'grey',
        confirmButtonText:'Confirm',
        showCloseButton:true,
            }).then((answer) => {
              if (answer.isConfirmed) {
                this.override.heading = "Allow Over Credit Limit";
                this.override.action = "credit_limit";
                this.override.dialog = true;
              }
            });
          } else {
            this.checkCreditTerms();
          }
        });
    },
    checkCreditTerms() {
      this.$store
        .dispatch("checkCreditTerms", {
          params: {
            customer_id: this.sales_quotation.customer_id,
          },
        })
        .then((response) => {
          if (response.data.status == "over") {
            let unpaid_amount = parseFloat(response.data.unpaid_amount);
            swal.fire({
              title: `Customer still has unpaid overdue accounts amounting to ${this.getTwoDecimals(
                unpaid_amount
              )}. Approve?`,
              icon: "warning",
              showConfirmButton:true,
        showCancelButton  :  true,
        reverseButtons:true,
        allowOutsideClick :  false,
        confirmButtonColor: '#397373',
        cancelButtonColor : 'grey',
        confirmButtonText:'Confirm',
        showCloseButton:true,
            }).then((sagot) => {
              if (sagot.isConfirmed) {
                this.override.heading = "Allow Over Credit Terms";
                this.override.action = "";
                this.override.dialog = true;
              }
            });
          }
        });
    },
    convertToSalesOrder() {
        if(this.sales_quotation.override_amount_approval == 1 &&  this.override.authenticated == false){
                this.override.dialog = true
                this.override.heading = 'Amount'
                return
            }
            this.loading = true

      if (this.sales_quotation.status == 0) {
        this.errors = [];
        swal.fire({
          title: "",
          text: "Are you sure you want to Convert this to SO?",
          icon: "warning",
          showConfirmButton:true,
        showCancelButton  :  true,
        reverseButtons:true,
        allowOutsideClick :  false,
        confirmButtonColor: '#397373',
        cancelButtonColor : 'grey',
        confirmButtonText:'Confirm',
        showCloseButton:true,
        }).then((willConvert) => {
          if (willConvert.isConfirmed) {
            this.$store.dispatch("convertToSalesOrder", this.viewSalesQuotationId)
              .then((response) => {
                if(!!response.data.message){
                  swal.fire(response.data.message,'','warning').then(ok=>{
                    this.loading = false
                    return false;
                  })
                }
                window.Echo.channel('smod-channel').listen('ConvertToSOMessage',(e)=>{
                  let data = e.data.find(el => el.userID == this.USERACCOUNT_LOAD.id);
                  let check_unique_id = (e.data[0].unique_key.toString().replace(/"/g,'') == this.viewSalesQuotationId)
                  if(check_unique_id && data && (data.message.msg || data.message.error) ){
                      if(data.message.error){
                          swal.fire("", data.message.error, "warning");
                          this.loading = false
                          return false;
                      }
                      this.resetFields();
                      this.sales_quotation_items_dialog = false;
                      swal.fire("", data.message.msg, "success");
                      this.$emit("closeView", "convertSuccess");
                      this.loading = false
                  }
                });
              }).catch(err=>{
                console.log(err)
                this.loading = false
              });
          }
          else{
            this.loading = false
          }
        });
      }
    },
    async renderPDF(pdfAction) {
      var doc = new jsPDF("p", "mm", "letter");
      let table_height = 0 ;
      //var logo = new Image();
      //logo.src = '../images/logo_small.png';
      //doc.addImage(logo, 'png', 8, 8, 38, 28, '','FAST');

      //doc.fromHTML($("#div_logo").get(0), 50,-4,{'width':600});
      // doc.fromHTML($("#div_logo").get(0), 7,-4,{'width':600});
      // doc.fromHTML($("#div_sales_quote_num").get(0), 162,4,{'width':400});
      doc.setFont(undefined, 'bold').setFontSize(13).text(162, 13, "Sales Quotation")
      doc.setFont(undefined, 'bold').setFontSize(11).text(162, 18, "SQ#: "+this.sales_quotation.quote_num)
      doc
        .setFont(undefined, "bold")
        .setFontSize(13)
        .text(6, 13, "D GREAT EAST INDUSTRIAL SUPPLY CORPORATION\n");
      // doc
      //   .setFont(undefined, "normal")
      //   .setFontSize(9)
      //   .text(
      //     8,
      //     18,
      //     "420 Del Monte Ave, Brgy. Siena, Quezon City\n\nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \n\nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539"
      //   );
      if (this.service == 1) {
          doc.setFont(undefined, 'normal').setFontSize(10).text(6, 18, "2 D Arellano St. Bagong Barrio, Caloocan City \nLandline:7002-3394\nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph")
      } else {
          doc.setFont(undefined, 'normal').setFontSize(10).text(6, 18, "420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 935 713 2293 / +63 917 170 0979 / +63 919 078 0016 \nTel. No.: +63 2 8727 1771 / +63 2 8535 9968 / +63 2 7091 4539")
      }
        let customer_details_top = this.service ? 50 : 37;

      doc.autoTable({
        html: "#customer_details",
        theme: "plain",
        margin: { top: customer_details_top, left: 6, right: 6 },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 134 },
          2: { cellWidth: 16 },
          3: { cellWidth: 25 },
        },
      });

    if (!this.print_no_price && !this.print_with_image) {
        doc.autoTable({
          html: "#items_details",
          startY: doc.autoTable.previous.finalY + 3,
          margin: { left: 6, right: 6 },
          theme: "grid",
          headStyles: {
            halign: "center",
            fillColor: "#ffffff",
            textColor: "#000000",
            lineWidth: 0.2,
            lineColor: "#000000",
          },
          columnStyles: {
            0: { cellWidth: 9, halign: "center", textColor: "#000000" },
            1: { cellWidth: 13, halign: "center", textColor: "#000000" },
            2: { cellWidth: 14, halign: "center", textColor: "#000000" },
            3: { cellWidth: 29, halign: "center", textColor: "#000000" },
            4: { halign: "left", textColor: "#000000" },
            5: { cellWidth: 25, halign: "right", textColor: "#000000" },
            6: { cellWidth: 25, halign: "right", textColor: "#000000" },
          },
          bodyStyles: {
            lineColor: "#000000",
          },
           didDrawPage:(d) => table_height = d.cursor.y,
        });
      } else if (!!this.print_no_price && !this.print_with_image) {
        doc.autoTable({
          html: "#items_details_without_price",
          startY: doc.autoTable.previous.finalY + 3,
          margin: { left: 6, right: 6 },
          theme: "grid",
          headStyles: {
            halign: "center",
            fillColor: "#ffffff",
            textColor: "#000000",
            lineWidth: 0.2,
            lineColor: "#000000",
          },
          columnStyles: {
            0: { cellWidth: 11, halign: "center", textColor: "#000000" },
            1: { cellWidth: 17, halign: "center", textColor: "#000000" },
            2: { cellWidth: 17, halign: "center", textColor: "#000000" },
            3: { cellWidth: 27, halign: "center", textColor: "#000000" },
            4: { halign: "left", textColor: "#000000" },
          },
          bodyStyles: {
            lineColor: "#000000",
          },
          didDrawPage: async (d) => table_height = d.cursor.y,
        });
    } else {
        for (let e of this.salesQuotationItems) {
            let current_x = 0
            let current_y = 0
            let base64 = ''

            // let itemImage = new Image()
            // itemImage.src = `${process.env.VUE_APP_API_HOST}/img/items?image=${e.item.model}`
            // itemImage.crossOrigin = 'Anonymous'

            // fetch(itemImage)
            // .then(resp => resp.blob())
            // .then(blobobject => {
            //     const blob = window.URL.createObjectURL(blobobject)
            //     const anchor = document.createElement('itema' + e.id)
            //     anchor.style.display = 'none'
            //     anchor.href = blob
            //     window.URL.revokeObjectURL(blob)
            //     document.getElementById('item' + e.id).appendChild(anchor)

            //     current_x = document.getElementById('item' + e.id).offsetLeft
            //     current_y = document.getElementById('item' + e.id).offsetTop
            // })

            // itemImage.onload = function () {
            //     doc.addImage(itemImage, 'JPEG', current_x, current_y)
            // }

            const anchor = document.createElement('itema' + e.id)
            await this.getBase64Image(`${process.env.VUE_APP_API_HOST}/img/items?image=${e.item.model}`, (res) => {
                anchor.setAttribute('data', res)

                current_x = document.getElementById('item' + e.id).offsetLeft
                current_y = document.getElementById('item' + e.id).offsetTop
            }).then(response => {
                base64 = response

                doc.addImage(base64, 'JPEG', 10, 10, 10, 10)
            })
        }
        this.salesQuotationItems.forEach(e => {

        })

        doc.autoTable({
          html: "#items_details_with_image",
          startY: doc.autoTable.previous.finalY + 3,
          margin: { left: 6, right: 6 },
          theme: "grid",
          headStyles: {
            halign: "center",
            fillColor: "#ffffff",
            textColor: "#000000",
            lineWidth: 0.2,
            lineColor: "#000000",
          },
          columnStyles: {
            0: { cellWidth: 9, halign: "center", textColor: "#000000" },
            1: { cellWidth: 20, halign: "center", textColor: "#000000" },
            2: { cellWidth: 13, halign: "center", textColor: "#000000" },
            3: { cellWidth: 14, halign: "center", textColor: "#000000" },
            4: { halign: "left", textColor: "#000000" },
            5: { cellWidth: 25, halign: "right", textColor: "#000000" },
            6: { cellWidth: 25, halign: "right", textColor: "#000000" },
          },
          bodyStyles: {
            lineColor: "#000000",
          },
           didDrawPage:(d) => table_height = d.cursor.y,
        });
      }

      if(table_height > 200){
        doc.addPage()
      }

      doc.autoTable({
        html: "#substitution_items",
        theme: "plain",
        startY: 195,
        pageBreak: "auto",
        // startY: 695,
        margin: { top: 37, left: 6, right: 6 },
        columnStyles: {
          0: { cellWidth: 110, textColor: "#000000", fontSize:8, },
        },
        didParseCell: function (data) {
            var rows = data.table.body;
            data.cell.styles.setFont = ['Helvetica', 'italic'];
        }
      });

      if (this.print_no_price == false) {
        doc.setPage(3)
        doc.autoTable({
          html: "#total_details",
          theme: "plain",
          startY: 207,
          // startY: 707,
          margin: { top: 37, left: 6, right: 6 },
          columnStyles: {
            0: { cellWidth: 110, textColor: "#000000" },
            1: { cellWidth: 40, halign: "right", textColor: "#000000" },
            2: { cellWidth: 12, textColor: "#000000" },
            3: { cellWidth: 35, halign: "right", textColor: "#000000" },
          },
        });
      }
      doc.setPage(3)
      doc.autoTable({
        html: "#quotation_only",
        theme: "plain",
        startY: 215,
        margin: { top: 37, left: 6, right: 6 },
        columnStyles: {
          0: { cellWidth: 45, textColor: "#000000" },
          1: { cellWidth: 7, halign: "right", textColor: "#000000" },
          2: { cellWidth: 40, textColor: "#000000" },
          3: { cellWidth: 35, halign: "right", textColor: "#000000" },
        },
      });

      // doc.autoTable({
      //     html: '#num_of_items',
      //     theme: 'plain',
      //     startY: doc.autoTable.previous.finalY,
      //     margin: {top: 37, left: 6, right: 6},
      //     columnStyles: {
      //         0: {cellWidth: 110, textColor: '#000000'},
      //         1: {cellWidth: 40, halign:'right', textColor: '#000000'},
      //         2: {cellWidth: 12, textColor: '#000000'},
      //         3: {cellWidth: 35, halign:'right', textColor: '#000000'},
      //     }
      // });

      var footer_y = doc.autoTable.previous.finalY + 3;
      if (this.print_no_price == true) {
        footer_y = 242;
      } else {
        footer_y = 245;
      }

      doc.autoTable({
        html: "#footer",
        theme: "plain",
        // startY: footer_y,
        margin: { left: 6, right: 6 },
        columnStyles: {
          0: { cellWidth: 40, halign: "center", textColor: "#000000" },
          1: { cellWidth: 40, halign: "center", textColor: "#000000" },
        },
        bodyStyles: {
          lineColor: "#000000",
        },
      });

      let finalY = doc.lastAutoTable.finalY; // The y position on the page
      doc.setFontSize(7);
      doc.setTextColor("#000000");
      doc.text(190, finalY, "Page 1 of 1");

      if (pdfAction == "download") {
        doc.save("SalesQuotation " + this.sales_quotation.quote_num + ".pdf");
      } else if (pdfAction == "print") {
        doc.autoPrint();
        window.open(doc.output("bloburl"), "_blank");
      }
    },
    clearSalesQuotationItems() {
      this.resetFields();
      this.sales_quotation_items_dialog = false;
      this.loading = false
    },
    resetFields() {
      this.salesQuotationItems = [];
      this.sales_quotation = "";
      this.discounts = [];
      this.itemTypeDiscounts = [];
      this.itemTypeDiscountsPDF = [];
      this.loading = false;
    },
    getItemTypes() {
      this.$store.dispatch("getItemTypes").then((response) => {
        this.type_list = response.data;
        this.getItemTypeDiscounts();
        this.getItemTypeDiscountsPDF();
      });
    },
    getItemTypeDiscounts() {
      this.itemTypeDiscounts = [];

      if (this.type_list.length > 0) {
        this.type_list.forEach((type) => {
          var name = type.text
          var str = type.text + ': '
          var isEmpty = true;

          if (this.discounts.length > 0) {
            this.discounts.forEach((discount, index) => {
              if (
                discount[name.toLowerCase()] != null &&
                discount[name.toLowerCase()] != "" &&
                discount[name.toLowerCase()] > 0
              ) {
                if (index > 0) {
                  str = str + "/";
                }
                str = str + discount[name.toLowerCase()].toString();
                isEmpty = false;
              }
            });
            if (isEmpty) {
              str = "";
            } else {
              if (
                this.salesQuotationItems[0].item.item_type_text == type.text
              ) {
                this.itemTypeDiscounts.push(str);
              }
            }
          }
        });
      }
    },
    getItemTypeDiscountsPDF() {
      this.itemTypeDiscountsPDF = [];
      if (this.type_list.length > 0) {
        this.type_list.forEach((type) => {
          var name = type.text
          var str = '(' + type.text + ') LESS: '
          var isEmpty = true;

          if (this.discounts.length > 0) {
            this.discounts.forEach((discount, index) => {
              if (
                discount[name.toLowerCase()] != null &&
                discount[name.toLowerCase()] != "" &&
                discount[name.toLowerCase()] > 0
              ) {
                if (index > 0) {
                  str = str + " / ";
                }
                str = str + discount[name.toLowerCase()].toString()
                isEmpty = false;
              }
            });
            if (isEmpty) {
              str = "";
            } else {
              if (
                this.salesQuotationItems[0].item.item_type_text == type.text
              ) {
                this.itemTypeDiscountsPDF.push(str);
              }
            }
          }
        });
      }
    },
    computePrintOutItemTotal(srp, qty) {
      return Math.round(parseFloat(srp) * qty).toFixed(2);
    },
    addFieldsInOriginalItem(){
      this.salesQuotationItems.forEach(e=>{
        if(e.original_item_id != 0){
          Object.assign(e.original_item,{
            quantity:e.quantity,
            discounted_price:this.computeOriginaDiscountedPrice(e.original_item.selling_price,e.original_item.item_type_text,3)
          })
        }
      })
    },
    computeOriginaDiscountedPrice(srp, type, brand_id){
      var brand_discount = this.expanded_details.customer_discount
      if (brand_discount.length > 0) {
          if(typeof brand_discount[0].discounts == 'string'){
              brand_discount[0].discounts = JSON.parse(brand_discount[0].discounts)
          }
          brand_discount[0].discounts.forEach((discount) => {
              if (discount[type.toLowerCase()] != null) {
                  var discount_rate = parseFloat(discount[type.toLowerCase()]);

                  if (discount_rate != null && discount_rate > 0) {
                      srp =
                          parseFloat(srp) - parseFloat(srp) * (discount_rate / 100);
                  }
              }
          });
      }
      return srp
    },
  },
  watch: {
    viewSalesQuotationId: function (val) {
      if (val != -1) {
        if (
          this.viewSalesQuotationId != null &&
          this.viewSalesQuotationId > 0
        ) {
          this.$store
            .dispatch("viewSalesQuotation", this.viewSalesQuotationId)
            .then((response) => {
              let data = response.data;

              if (data.error) {
                swal.fire("", data.error, "warning");
                return false;
              }

              this.sales_quotation = data.sales_quotation;
              this.salesQuotationItems = data.sales_quotation.sales_quotation_items;
              this.salesQuotationSubstitutionItems = this.salesQuotationItems.filter(e=>{
                if(e.original_item_id != 0){
                  return e
                }
              })

              this.expanded_details.customer_discount = JSON.parse(data.sales_quotation.discounts)
              this.addFieldsInOriginalItem()

              if (data.sales_quotation.discounts != "") {
                this.discounts = JSON.parse(data.sales_quotation.discounts);
                if (this.discounts != null) {
                  this.discounts = this.discounts[0].discounts;
                }
              }

              if (data.sales_quotation.stock_card) {
                this.selectedHeaders = this.stockCardSalesQuotationItemsHeaders;
              } else {
                this.selectedHeaders = this.salesQuotationItemsHeaders;
              }

              this.getItemTypes();
              this.sales_quotation_items_dialog = true;

              if (!!data.sales_quotation) {
                this.service = data.sales_quotation.service;
              }
            })
            .catch((error) => {
              console.log(error)
              if (error.response.status == 422) {
                this.errors = error.response.data.errors;
              }
            });
        }
      }
    },
    USER_ACCESS: {
      handler(val) {
        if (val != "fail") {
          this.userAccess.convert_to_sales_order = false;
          val.forEach((e) => {
            if (e.actions_code == "convert_to_sales_order") {
              this.userAccess.convert_to_sales_order = true;
            }
          });
        }
      },
    },
  },
  computed: {
    no_of_items: function () {
      if (
        this.salesQuotationItems != null &&
        this.salesQuotationItems.length > 0
      ) {
        var count = 0;
        this.salesQuotationItems.forEach((item) => {
          count += item.quantity;
        });
        return this.thousandSeprator(count);
      } else {
        return 0;
      }
    },
    ...mapGetters(["USER_ACCESS","USERACCOUNT_LOAD"]),
  },
};
</script>
<style>
.v-avatar {
  margin: 15px 50px !important;
}
#client_modal_card {
  min-height: 160px !important;
}
</style>
