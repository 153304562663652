<template>
    <v-container class="pa-2 ma-0">
        <h4 class="page-title" style="color: black">Promos</h4>
        <div class="row mx-0 mt-5" v-if="userAccess.create">
            <div class="col-12">
                <v-btn data-modal-id="addPromoModal" v-on:click="showAddPromo()">
                    <v-icon>mdi-plus</v-icon>Add
                </v-btn>
            </div>
        </div>

        <div class="row mx-2">
            <!-- Promos Table -->
            <PromosTableComponentVue :promosActionResult="action" @showEditModal="showEditModal"></PromosTableComponentVue>
        </div>


    <v-app id="addEditPromoModal">
        <v-dialog v-model="add_edit_promo_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Promo Information</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_promo_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text>
                    <form class="form-horizontal" action="/promos" method="post" v-on:submit.prevent="onSubmit">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-3">
                                    <!-- <label for="name" class="float-left control-label col-form-label">Promo Name</label> -->
                                    <v-text-field
                                        class="w-100"
                                        id="name"
                                        label="Promo Name"
                                        v-model="name"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                    <span v-for="errors in errors.name" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <!-- <label for="code" class="float-left control-label col-form-label">Code</label> -->
                                    <v-text-field
                                        class="w-100"
                                        id="code"
                                        label="Code"
                                        v-model="code"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                    <span v-for="errors in errors.code" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                                <div class="col-sm-6 col-md-2 mt-2" id="date_start">
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        min-width="290px"

                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="date_start"
                                                readonly
                                                append-icon="mdi-calendar-clock"
                                                v-on="on"
                                                label="Start Date"
                                                dense
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_start" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                    <span v-for="errors in errors.date_start" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                                <div class="col-sm-6 col-md-2 mt-2" id="date_end">
                                    <v-menu
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        min-width="290px"

                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="date_end"
                                                readonly
                                                append-icon="mdi-calendar-clock"
                                                v-on="on"
                                                label="Start Date"
                                                hide-details
                                                dense
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_end" @input="menu3 = false"></v-date-picker>
                                    </v-menu>
                                    <span v-for="errors in errors.date_end" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                                <div class="col-sm-6 col-md-1">
                                    <v-switch
                                        v-model="is_active"
                                        label="active"
                                        color="primary"
                                        input-value="1"
                                    ></v-switch>
                                </div>
                            </div>
                            <!-- Start Batch Addition -->
                            <v-card
                                outlined
                                class="p-2"
                            >
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="float-left mx-2">
                                            <v-btn dark small color="primary" @click="openBatchDialog();">
                                                <span>Batch Addition</span>
                                            </v-btn>
                                        </div>
                                        <v-dialog
                                            v-model="batch_addition.dialog"
                                            persistent
                                            max-width="50%"
                                            max-height="30%"
                                            scrollable
                                        >
                                        <v-card>
                                            <v-row class="m-0">
                                            <v-col>
                                                <v-card-title>
                                                <span class="headline">Add Promo</span>
                                                </v-card-title>
                                            </v-col>
                                            <v-col cols="pull-right-10 p-2">
                                                <v-btn
                                                text
                                                icon
                                                color="gray"
                                                class="float-right"
                                                @click="closeBatchAdditionDialog(); dataFromExcel = '';"
                                                >
                                                <v-icon>mdi-close-circle</v-icon>
                                                </v-btn>
                                            </v-col>
                                            </v-row>
                                            <v-card-text class="ma-0">
                                            <span v-if="hasError">
                                                <span style="color: red">Red : </span>Model not
                                                found in the system</span
                                            >
                                            <div
                                                class="pt-4 pa-2"
                                                style="
                                                height: auto;
                                                width: 100%;
                                                overflow-y: auto;
                                                overflow-x: hidden;
                                                "
                                            >
                                                <v-row
                                                class="mx-0 pa-0 ma-0"
                                                v-for="(
                                                    text_area_field, i
                                                ) in text_area_fields"
                                                :key="i"
                                                >
                                                <div class="col-3 pa-0 ma-0">
                                                    <v-text-field
                                                    :class="{
                                                        notInModel: text_area_fields[i].error,
                                                    }"
                                                    class="InModel"
                                                    dense
                                                    outlined
                                                    v-model="
                                                        text_area_fields[i].dataFromExcel
                                                    "
                                                    rows="1"
                                                    small
                                                    color="primary"
                                                    label="Model"
                                                    :autofocus="batch_addition.dialog"
                                                    @paste="onPaste"
                                                    @click="$event.target.focus()"
                                                    >
                                                    </v-text-field>
                                                </div>
                                                <div class="col-3 py-0 ma-0">
                                                    <v-text-field
                                                    :class="{
                                                        notInModel: text_area_fields[i].error,
                                                    }"
                                                    class="InModel"
                                                    dense
                                                    outlined
                                                    label="Quantity"
                                                    v-model="
                                                        text_area_fields[i].dataFromExcel2
                                                    "
                                                    rows="1"
                                                    small
                                                    color="primary"
                                                    >
                                                    </v-text-field>
                                                </div>
                                                <div class="col-2 pa-0 ma-0">
                                                    <v-text-field
                                                    :class="{
                                                        notInModel: text_area_fields[i].error,
                                                    }"
                                                    class="InModel"
                                                    dense
                                                    outlined
                                                    v-model="
                                                        text_area_fields[i].dataFromExcel3
                                                    "
                                                    rows="1"
                                                    small
                                                    color="primary"
                                                    label="Price"
                                                    @paste="onPaste"
                                                    @click="$event.target.focus()"
                                                    >
                                                    </v-text-field>
                                                </div>
                                                <div class="col-2 pa-0 ml-2">
                                                    <v-text-field
                                                    :class="{
                                                        notInModel: text_area_fields[i].error,
                                                    }"
                                                    class="InModel"
                                                    dense
                                                    outlined
                                                    v-model="
                                                        text_area_fields[i].dataFromExcel4
                                                    "
                                                    rows="1"
                                                    small
                                                    color="primary"
                                                    label="Is Cash"
                                                    @paste="onPaste"
                                                    @click="$event.target.focus()"
                                                    >
                                                    </v-text-field>
                                                </div>
                                                <div class="col-1 ma-0">
                                                    <v-btn
                                                    fab
                                                    dark
                                                    small
                                                    color="red"
                                                    style="height: 20px; width: 20px"
                                                    @click="clearItem(i)"
                                                    >
                                                    <v-icon
                                                        dark
                                                        small
                                                        style="font-size: 12px"
                                                    >
                                                        mdi-minus
                                                    </v-icon>
                                                    </v-btn>
                                                </div>
                                                </v-row>
                                                <v-row>
                                                <!-- <v-col class="ml-3">
                                                    <v-btn
                                                    dark
                                                    small
                                                    color="primary"
                                                    style="height: 20; width: 40px"
                                                    @click="manualAddItem()"
                                                    >
                                                    <span> Add </span>
                                                    </v-btn>
                                                </v-col> -->
                                                </v-row>
                                            </div>
                                            <v-row>
                                                <div class="col-12">
                                                <v-btn
                                                    :disabled="isDisabledButton"
                                                    class="float-right ml-2"
                                                    @click="submittedBtn()"
                                                >
                                                    Submit
                                                </v-btn>
                                                <v-btn
                                                    class="float-right"
                                                    dark
                                                    color="secondary"
                                                    @click="clearFields()"
                                                >
                                                    Clear
                                                </v-btn>
                                                </div>
                                            </v-row>
                                            </v-card-text>
                                        </v-card>
                                        </v-dialog>
                                        <!-- <div class="float-right mx-2">
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                color="primary"
                                                style="height:20px; width:20px;"
                                                @click="addItem()"
                                            >
                                                <v-icon dark small style="font-size:12px">
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                color="primary"
                                                style="height:20px; width:20px;"
                                                @click="removeItem()"
                                            >
                                                <v-icon dark small style="font-size:12px">
                                                    mdi-minus
                                                </v-icon>
                                            </v-btn>
                                        </div> -->
                                    </div>

                                    <div class="col-sm-12 col-md-12" style="overflow-y:scroll; height: 350px;">
                                        <v-simple-table dense
                                            class="dynamic_list_table"    >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center mx-2" width="20%">Model</th>
                                                        <th class="text-center mx-2" width="45%">Name</th>
                                                        <th class="text-center mx-2" width="10%">Qty From</th>
                                                        <!--<th class="text-center mx-2" width="10%">Qty To</th>-->
                                                        <th class="text-center mx-2" width="10%">Lowest Price</th>
                                                        <th class="text-center mx-2" width="1%">Cash Only</th>
                                                        <th class="text-center mx-2" width="1%"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(promo_item, i) in promo_items" :key="i"
                                                    >
                                                        <td>
                                                            <span>
                                                                <!-- <v-autocomplete :items="items_list"
                                                                    v-model="promo_items[i].item_id"
                                                                    item-value="value"
                                                                    item-text="text"
                                                                    label="Model"
                                                                    :loading="loading.items_selection"
                                                                    :disabled="loading.items_selection"
                                                                    class="w-100"
                                                                    outlined
                                                                    dense
                                                                    cache-items
                                                                >
                                                                </v-autocomplete> -->
                                                                <v-text-field
                                                                    v-model="promo_items[i].item_model"
                                                                    label="Model"
                                                                    outlined
                                                                    dense
                                                                    disabled
                                                                    background-color="grey lighten-2"
                                                                    color="black"
                                                                    hide-details
                                                                >
                                                                </v-text-field>
                                                            </span>
                                                            <!--<span v-else>
                                                                <input type="text" class="form-control text-center small" v-model="promo_items[i].item_model" readonly>
                                                            </span>-->
                                                        </td>
                                                        <td>
                                                            <span>
                                                                <!-- <v-autocomplete :items="items_list"
                                                                    v-model="promo_items[i].item_id"
                                                                    item-value="value"
                                                                    item-text="name"
                                                                    label="Name"
                                                                    :loading="loading.items_selection"
                                                                    :disabled="loading.items_selection"
                                                                    class="w-100"
                                                                    outlined
                                                                    dense
                                                                    cache-items
                                                                >
                                                                </v-autocomplete> -->
                                                                <v-text-field
                                                                    v-model="promo_items[i].item_name"
                                                                    label="Name"
                                                                    outlined
                                                                    dense
                                                                    disabled
                                                                    background-color="grey lighten-2"
                                                                    color="black"
                                                                    hide-details
                                                                >
                                                                </v-text-field>
                                                            </span>
                                                            <!--<span v-else>
                                                                <input type="text" class="form-control text-center small" v-model="promo_items[i].item_name" readonly>
                                                            </span>-->
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                class="centered-input w-100"
                                                                :id="'quantity_from_'+i"
                                                                placeholder="0"
                                                                v-model="promo_items[i].quantity_from"
                                                                @keypress="numberOnly"/>
                                                        </td>
                                                        <!--<td>
                                                            <input type="text" class="form-control text-center" :id="'quantity_to_'+i" placeholder="0" v-model="promo_items[i].quantity_to" @keypress="numberOnly">
                                                        </td>-->
                                                        <td>
                                                            <v-text-field
                                                            dense
                                                            outlined
                                                            class="right-input w-100"
                                                            :id="'lowestprice_'+i"
                                                            placeholder="0.00"
                                                            v-model="promo_items[i].lowest_price"
                                                            @keypress="decimalOnly"/>
                                                        </td>
                                                        <td class="align-center justify-center">
                                                            <v-checkbox
                                                                class="ml-4"
                                                                v-model="promo_items[i].cash_only"
                                                                label=""
                                                                color="primary"
                                                                input-value="1"
                                                            ></v-checkbox>
                                                        </td>
                                                        <td>
                                                            <v-btn
                                                                fab
                                                                dark
                                                                small
                                                                color="primary"
                                                                style="height:20px; width:20px;"
                                                                @click="removeItemByIndex(i)"
                                                            >
                                                                <v-icon dark small style="font-size:12px">
                                                                    mdi-minus
                                                                </v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-card>
                           <br>
                           <v-card
                                outlined
                                class="p-2"
                            >
                                <div class="row" style="overflow-y:scroll; height: 350px;">
                                    <div class="col-sm-12 col-md-12">
                                        <v-simple-table dense
                                            class="dynamic_list_table"    >
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" width="45%">Customer</th>
                                                        <th class="text-center" width="10%">Disc %</th>
                                                       <!-- <th class="text-center mx-2" width="10%">Cash Price</th>-->
                                                        <th style="text-align:center;" class="text-center mx-2" width="10%">Disabled</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="pa-0 ma-0">
                                                    <tr class="ma-0 pa-0"
                                                        v-for="(customer_promo, i) in customer_promos" :key="i"
                                                    >
                                                        <td>
                                                            <v-text-field
                                                                outlined
                                                                dense
                                                                class="centered-input"
                                                                v-model="customer_promos[i].customer_name"
                                                                readonly
                                                                background-color="grey lighten-2"
                                                            />
                                                        </td>
                                                        <td>
                                                            <v-text-field
                                                                outlined
                                                                dense
                                                                class="right-input"
                                                                :id="'discount_'+i" placeholder="0"
                                                                v-model="customer_promos[i].discount_rate"
                                                                @keypress="decimalOnly"
                                                            />
                                                        </td>
                                                      <!--  <td>
                                                            <v-checkbox
                                                                v-model="customer_promos[i].cash_price"
                                                                label=""
                                                                color="primary"
                                                                input-value="1"
                                                                class="align-center justify-center"
                                                            ></v-checkbox>
                                                        </td>-->
                                                        <td class="d-flex justify-content-center align-items-center h-auto">
                                                            <v-switch
                                                                v-model="customer_promos[i].disabled"
                                                                label=""
                                                                color="red"
                                                                input-value="1"
                                                                class="text-right"
                                                            ></v-switch>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-card>
                        </div>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewPromo()" v-if="action=='Add'">Submit</v-btn>
                                <v-btn  v-on:click="editPromo()" v-else-if="action=='Edit'">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

    </v-container>
</template>

<script>
    // import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    // import PromosTableComponent from './PromosTableComponent.vue'
    import PromosTableComponentVue from './PromosTableComponent.vue';
    import swal from 'sweetalert2';
    export default {
        mixins: [SharedFunctionsComponentVue],
        data(){
            return {
                editPromoId: '',
                add_edit_promo_dialog: false,

                name: '',
                code: '',
                date_start: '',
                date_end: '',
                is_active: 1,
                promo_items: [],
                promo_item:{
                    item_id: '',
                    item_name: '',
                    item_model: '',
                    quantity_from: '',
                    quantity_to: '',
                    lowest_price: '',
                    cash_only: '',
                },
                customer_promos: [],
                customer_promo:{
                    customer_id: '',
                    customer_name: '',
                    discount_rate: '',
                    cash_price: '',
                    disabled: '',
                },

                errors: [],
                action: '',
                actionResult : {
                    model : 'Promo',
                    action : ''
                },

                row: null,
                menu2: false,
                menu3: false,

                items_list: [],
                customers_list: [],
                userAccess: {
                    create: false,
                },

                loading:{
                    items_selection : true
                },
                batch_addition: {
                    dialog: false,
                    data: [],
                    excel_item_id: null,
                    errors: [],
                },
                dataFromExcel: "",
                dataFromExcel2: "",
                text_area_field: {
                    dataFromExcel: "",
                    dataFromExcel2: "",
                    dataFromExcel3: "",
                    dataFromExcel4: "",
                },
                text_area_fields:[],
                listOfNotInModels:[],
                hasError:false,
                isDisabledButton:false,
                isDone:false,
                batchCounter:0,
            }
        },
        components: {
            PromosTableComponentVue
        },
        mounted: function (){
            this.checkAccess();
            this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        },
        methods: {
            openBatchDialog(){
                this.batch_addition.dialog = true;
                // this.isDone = true;
                this.text_area_fields = [];

                if (this.text_area_fields.length < 1) {
                    this.text_area_fields.push({
                        dataFromExcel: "",
                        dataFromExcel2: "",
                        dataFromExcel3: "",
                        dataFromExcel4: "",
                        error: false,
                    });
                }
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.hasError = false;
            },
            closeBatchAdditionDialog() {
                this.batch_addition.dialog = false;
            },
            onPaste(evt) {
                this.batch_addition.data = [];
                var pastedText = evt.clipboardData.getData("Text");
                if (!pastedText) {
                    return false;
                } else {
                    var rows = pastedText.split("\r\n");
                    var cells = "";
                    for (var y in rows) {
                    if (y != rows.length - 1) {
                        cells = rows[y].split("\t");
                        this.text_area_fields.push({
                        dataFromExcel: cells[0],
                        dataFromExcel2: cells[1],
                        dataFromExcel3: cells[2],
                        dataFromExcel4: cells[3],
                        error: false,
                        });
                        if (y == rows.length - 1) {
                        this.text_area_fields.push({
                            dataFromExcel: cells[0],
                            dataFromExcel2: cells[1],
                            dataFromExcel3: cells[2],
                            dataFromExcel4: cells[3],
                            error: false,
                        });
                        }
                        this.batch_addition.data.push(
                            Object.assign(
                            {},
                            {
                                model: cells[0].trim(),
                                qty: cells[1].trim(),
                                price: cells[2].trim(),
                                isCash: cells[3].trim(),
                                // foc: cells[2],
                                id: "",
                                skip: false,
                            }
                            )
                        );
                    }
                    }
                    let payload = {
                    items: this.text_area_fields.map((e) => {
                        return e.dataFromExcel;
                    }),
                    isChecking: true,
                    };
                    this.$store.dispatch("getItemsByModel", payload).then((response) => {
                    response.data = response.data.map((e) => {
                        return e.model;
                    });
                    this.text_area_fields.forEach((e, i) => {
                        if (response.data.includes(e.dataFromExcel.toUpperCase())) {
                        } else {
                        this.listOfNotInModels.push(e.dataFromExcel.toUpperCase());
                        this.text_area_fields[i].error = true;
                        }
                    });
                    if (this.listOfNotInModels.length > 0) {
                        this.isDisabledButton = true;
                        this.hasError = true;
                    }
                    });
                    this.isDone = false;
                    this.closeBatchAdditionDialog();
                    setTimeout(this.myFunction, 1);
                }
            },
            submittedBtn(){
                if(this.batchCounter == 0){
                    this.promo_items.pop();
                }
                this.text_area_fields.forEach(e=>{
                    this.promo_items.push({
                        cash_only:Number(e.dataFromExcel4),
                        item_id:'',
                        lowest_price:e.dataFromExcel3,
                        item_model:e.dataFromExcel.toUpperCase(),
                        item_name:'',
                        quantity_from:e.dataFromExcel2,
                    })
                })
                let models = this.promo_items.map(e=>{
                    return e.item_model
                })
                let payload = {
                    items: models,
                    isChecking: false,
                };
                this.$store.dispatch("getItemsByModel", payload).then(response=>{
                    this.promo_items.forEach((e,i)=>{
                        e.item_name = response.data[i].name
                        e.item_id = response.data[i].item_id
                    })
                    this.closeBatchAdditionDialog();
                    this.batchCounter++
                })

            },
            myFunction() {
                this.text_area_fields.splice(0, 1);
                this.batch_addition.dialog = true;
            },
            clearFields() {
                this.isDone = true;
                this.text_area_fields = [];
                if (this.text_area_fields.length < 1) {
                    this.text_area_fields.push({
                    dataFromExcel: "",
                    dataFromExcel2: "",
                    dataFromExcel3: "",
                    dataFromExcel4: "",
                    error: false,
                    });
                }
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.isDisabledButton = false;
                this.hasError = false;
            },
            clearItem(i) {
                let temp = [];
                if (this.text_area_fields.length == 1) {
                    this.clearFields();
                } else {
                    if (this.listOfNotInModels.length > 1) {
                    } else {
                    this.isDisabledButton = false;
                    }
                    this.text_area_fields.forEach((e, index) => {
                    if (i != index) {
                        temp.push(e);
                        let checkingOfError = this.listOfNotInModels.includes(
                        this.text_area_fields[i].dataFromExcel
                        );
                        if (checkingOfError) {
                        this.listOfNotInModels.pop();
                        }
                    }
                    });
                    this.text_area_fields = temp;
                }
            },
            checkAccess(){
                this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                    let val = response;
                    this.userAccess.create = false;
                    if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "create"){
                            this.userAccess.create = true;
                        }
                    });
                    }
                })
            },
            removeItemByIndex(index){
                this.promo_items.splice(index, 1)
            },
            removeItem(){
                if(this.promo_items.length > 1){
                    this.promo_items.pop();
                }
            },
            addItem(){
                this.promo_items.push({
                    item_id: '',
                    name: '',
                    model: '',
                    quantity_from: '',
                    quantity_to: '',
                    lowest_price: '',
                    cash_only: '',
                })
            },
            showAddPromo(){
                this.add_edit_promo_dialog     = true
                this.action                    = 'Add'
            },
            addNewPromo(){
                this.errors = []
                let payload = {
                    name:            this.name,
                    code:            this.code,
                    promo_items:     this.promo_items,
                    customer_promos: this.customer_promos,
                    date_start:      this.date_start,
                    date_end:        this.date_end,
                    active:          this.is_active,
                    url:             'promos'
                }
                this.$store.dispatch('postPromos',payload).then(response => {
                    let data = response.data
                    if(data.error){
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_promo_dialog     = false
                    this.action                    = 'success'
                    swal.fire(response.data.msg, {icon: "success",});
                }).catch(error => {
                    if(error.response.status == 422){
                        this.errors = error.response.data.errors
                    }
                });
            },
            showEditModal(value){
                this.batchCounter = 1
                this.action = 'Edit'
                this.editPromoId = value

                if(this.editPromoId != null && this.editPromoId > 0){
                    let payload = {
                        url:    'getPromo/'+this.editPromoId
                    }

                    this.$store.dispatch('getPromos',payload).then(response => {
                        let data = response.data

                        this.name                      = data.promo.name
                        this.code                      = data.promo.code
                        this.promo_items               = data.promo.promo_items
                        this.customer_promos           = data.promo.customer_promos
                        this.date_start                = data.promo.date_start
                        this.date_end                  = data.promo.date_end
                        this.is_active                 = data.promo.active

                        this.add_edit_promo_dialog = true
                    }).catch(error => {

                    });
                }
            },
            editPromo(){
                this.errors = []
                let payload = {
                    name:            this.name,
                    code:            this.code,
                    promo_items:     this.promo_items,
                    customer_promos: this.customer_promos,
                    date_start:      this.date_start,
                    date_end:        this.date_end,
                    active:          this.is_active,
                    url:             'promos/'+this.editPromoId
                }
                this.$store.dispatch('putPromos',payload).then(response => {
                    let data = response.data
                    if(data.error){
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_promo_dialog = false
                    this.editPromoId           = 0
                    this.action                = 'success'
                    swal.fire(response.data.msg, {icon: "success",});
                }).catch(error => {
                    if(error.response.status == 422){
                        this.errors = error.response.data.errors
                    }
                    this.action = 'Edit'
                });
            },
            getItems(){

                // this.$store.dispatch('getItems').then(response => {
                //     this.items_list = response.data
                // });
                // this.loading.items_selection = false
            },
            getCustomers(){
                this.$store.dispatch('getAllCustomers').then(response => {
                    this.customers_list = response.data
                    if(this.customers_list.length > 0){
                        this.customers_list.forEach((customer)=>{
                            this.customer_promos.push({
                                customer_id: customer.value,
                                customer_name: customer.text,
                                discount_rate: '',
                                cash_price: 1,
                                disabled: 0
                            })
                        })
                    }
                });
            },
            resetFields(){
                this.batchCounter = 0
                this.errors          = []
                this.name            = ''
                this.code            = ''
                this.date_start       = ''
                this.date_end         = ''
                this.is_active       = 1,
                this.promo_items     = []
                this.customer_promos = []
            },
        },
        watch:{
            add_edit_promo_dialog:function(){
                if(this.add_edit_promo_dialog == true){
                    this.getItems();
                    if(this.action == 'Add'){
                        this.getCustomers();
                    }

                    if(this.promo_items.length == 0){
                        this.addItem();
                    }
                }
            },
        },

    };
</script>
<style>
.centered-input input {
  text-align: center;
}
.right-input input {
  text-align: right;
}

</style>
