<template>
  <v-container fluid>
    <v-container v-for="(department, i) in GET_ALL_DEPARTMENT_ACCESS" :key="i">
      <v-row>
        <v-col class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">Department User Access: {{ department.name }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
            <v-icon>mdi-magnify</v-icon>
          </v-card-title>

          <v-container fluid>
            <department-access-table :departments="department" :search="search"></department-access-table>
          </v-container>
        </v-card>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import departmentAccessTable from './tables/DepartmentAccessComponent.vue'
export default {
  components: { departmentAccessTable },
  data() {
    return {
      actionResult: {
        model: 'Users',
        action: ''
      },
      user_id: '',
      department_id: '',
      user_access: [],
      loaded: false,
      users: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 10,
      search: '',
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          },
        ],
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getAllUsersSelect')
    this.$store.dispatch('getAllDepartmentAccess')
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    this.$store.commit('LINK_ACTIVE',window.location.pathname.split("/")[3])
  },
  computed: {
    ...mapGetters(['GET_ALL_DEPARTMENT_ACCESS']),
  },
};
</script>
