<template>
  <v-app>
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center>
        <v-flex>
          <v-card
            class="mx-auto"
            max-width="374"
            elevation="20"
            bg
            fill-height
            grid-list-md
            text-xs-center
          >
            <v-toolbar
              elevation="0"
              class="ma-0 pa-0 bg-transparent"
              height="94"
              src="@/assets/ingcoph_bg/ingcoph_login.jpg"
            >
                <template #img="{ props }">
                    <v-img :src="props.src" :height="props.height" contain></v-img>
                </template>
            </v-toolbar>
            <v-card-text>
              <v-form
                @submit="loginSubmit"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="form.username"
                  :rules="usernamerules"
                  :append-icon="error != '' ? 'mdi-account-alert' : ''"
                  type="text"
                  label="Username"
                  required
                  outlined
                  dense
                  @keypress="error = ''"
                ></v-text-field>
                <v-text-field
                  v-model="form.password"
                  :rules="passwordrules"
                  @keypress="error = ''"
                  type="password"
                  label="Password"
                  requireds
                  outlined
                  dense
                ></v-text-field>
                <!-- <v-checkbox label="Remember" v-model="form.remember"></v-checkbox> -->
                <v-btn
                  @click="forgotPass()"
                  class="ma-0 pa-0"
                  color="blue"
                  text
                  >
                  Forgot Password?
                </v-btn>
                <v-card-actions class="py-5">
                  <span class="text-danger">{{ error }}</span>
                  <v-spacer />
                  <div class="btn-toolbar">
                    <v-btn
                      :loading="loading"
                      type="submit"
                      elevation="2"
                    >
                      Login
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from 'moment';
export default {
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        username: null,
        password: null,
        remember: null,
      },
      error: "",
      passwordrules: [(v) => !!v || "password is required"],
      usernamerules: [(v) => !!v || "username is required"],
      logged_time:0,
      current_date: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters(["credentials"]),
    _getLogged(){
      let lastLoggedDate = localStorage.getItem("last_logged_date");
      if (this.current_date != lastLoggedDate) {
        localStorage.removeItem("logged_times");
        localStorage.removeItem("seen_today");
      }

      this.logged_time = 0 ;
      let log = localStorage.getItem("logged_times")

        if(log === null || log.length === 0 || log === 0){
          this.logged_time = 1
        }else {
          this.logged_time = JSON.parse(localStorage.getItem("logged_times")) + 1;
        }
    }
  },
  methods: {
    loginSubmit(e) {
      e.preventDefault();
      this.$refs.form.validate();
      this.loading = true;
      if (this.form.username == null && this.form.password == null) {
        this.loading = false;
      } else {
        this.$store
          .dispatch("login", this.form)
          .then((success) => {
            this.$store.dispatch("renderSideNav");
            if (success.status == 400) {
              this.loading = false;
              this.error = success.message;
            } else {
              let last_visit = localStorage.getItem("last_visit");
              localStorage.setItem("token", success.access_token);
              localStorage.setItem("logged_times",this.logged_time);
              localStorage.setItem("last_logged_date",this.current_date);
              if (localStorage.getItem("last_visit")) {
                this.$router.push({ path: last_visit }).catch((err) => {
                  this.loading = false;
                });
              } else {
                this.$router.push({ path: "/" }).catch((err) => {
                  this.loading = false;
                });
              }
            }
          })
          .catch((error) => {
            console.log(error)
            this.error = error.response.data.message;
            this.loading = false;
          });
      }
    },
    forgotPass() {
      this.$router.push({ path: '/forgotpassword' }).catch((err) => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this._getLogged
  }
}
</script>
<style src="../../assets/css/custom.css"></style>
