    <template>
    <v-container>

        <ComponentHeader
            :button="true"
            :title="'Sales Order Per Item'"
            :type="'Sales'"
        ></ComponentHeader>
        <v-row>
        <div class="container bg-bluish-gray default-container m-3">

        <v-dialog v-model="loading.dialog" width="500">
            <v-card>
                <v-card-title >
                    <span style="font-size:12px">
                        Processing: {{ loading.text }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear
                        v-model="loading.value"
                        :buffer-value="loading.bufferValue"
                        color="green"
                    >
                    </v-progress-linear>
                    {{Math.ceil(loading.value)}}%
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-app id="my_vue_app" class="bg-bluish-gray">
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <v-autocomplete
                        outlined
                        dense
                        :items="GET_CUSTOMERS_SELECTION"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection"
                        v-model="filters.customers.selected"
                        background-color="white"
                        item-value="value"
                        item-text="text"
                        class="d-inline-block w-100"
                        placeholder="Select Customer"
                    >
                    </v-autocomplete>
                </div>
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="filters.status.selection"
                        :loading="!filters.status.selection"
                        :disabled="!filters.status.selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        v-model="filters.status.selected"
                        label="Select Status"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-3"></div>
                <div class="col-sm-12 col-md-3 text-right float-right">
                    <v-btn  @click="resetFields()" :loading="!loaded">Clear</v-btn>
                    <v-btn  @click="exportToExcel()" :loading="!loaded">Excel</v-btn>
                    <v-btn @click="list()" :loading="!loaded">Filter</v-btn>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_TYPES"
                        :loading="loading.item_type_selection"
                        :disabled="loading.item_type_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        v-model="filters.types.selected"
                        label="Select Types"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_CATEGORIES"
                        :loading="loading.category_selection"
                        :disabled="loading.category_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        v-model="filters.categories.selected"
                        label="Select Category"
                        class="d-inline-block w-100">
                    </v-autocomplete>

                </div>
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_SELECTION_DEFAULT"
                        :loading="loading.item_selection"
                        :disabled="loading.item_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        v-model="filters.items.selected"
                        label="Select Item Model"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row">
                <!-- <div class="col-md-2">
                    <v-autocomplete
                        :items="filters.grouping.selection"
                        v-model="filters.grouping.selected"
                        item-value="value"
                        item-text="text"
                        class="d-inline-block w-100"
                        placeholder="Select Grouping"
                    >

                    </v-autocomplete>
                </div> -->
                <div class="col-sm-4 col-md-2" id="date_from">
                    <v-menu
                        v-model="filters.date.menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date.date_from" @input="filters.date.menu2 = false"></v-date-picker>
                    </v-menu>
                </div>

                <div class="col-sm-4 col-md-2" id="date_to">
                    <v-menu
                        v-model="filters.date.menu3"
                        :close-on-content-click = "false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                        z-index="100"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date.date_to" @input="filters.date.menu3 = false"  style="z-index:100"></v-date-picker>
                    </v-menu>
                </div>
            </div>
            <v-app id="load_bar" v-if="!loaded">
                <v-text-field color="info" loading disabled></v-text-field>
            </v-app>
            <div class="container bg-white" v-show="dataTable.items.length>0">
                <div class="row p-40 bg-dark">
                    <v-data-table
                        :items="dataTable.items"
                        :headers="dataTable.headers"
                        :options.sync="dataTable.pagination.options"
                        class="w-100"
                        :server-items-length="dataTable.pagination.totalItems"
                        dense
                    >
                        <template v-slot:[`item.so_discounts`]="{ item }">
                            <p>PT:{{item.so_discounts.textConcertionPt}}</p>
                            <p>HT:{{item.so_discounts.textConcertionHt}}</p>
                            <p>SP:{{item.so_discounts.textConcertionSp}}</p>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <span class="badge badge-saved" v-if="item.status=='Saved'">{{ item.status }}</span>
                            <span class="badge badge-for-sales" v-if="item.status=='For Sales'">{{ item.status }}</span>
                            <span class="badge badge-for-accounting" v-if="item.status=='For Accounting'">{{ item.status }}</span>
                            <span class="badge badge-approved" v-else-if="item.status=='Approved'">{{ item.status }}</span>
                            <span class="badge badge-completed" v-else-if="item.status=='Completed'">{{ item.status }}</span>
                            <span class="badge badge-warning" v-else-if="item.status=='Rejected'">{{ item.status }}</span>
                            <span class="badge badge-danger" v-else-if="item.status=='Canceled'">{{ item.status }}</span>
                            <span class="badge badge-dark" v-else-if="item.status=='Deleted'">{{ item.status }}</span>
                            <span class="badge badge-purple" v-else-if="item.status=='Converted'">{{ item.status }}</span>
                            <span class="badge badge-info" v-else-if="item.status=='Exec Approved'">{{ item.status }}</span>
                            <span class="badge badge-pending" v-else-if="item.status=='Partial'">{{ item.status }}</span>
                            <span class="badge badge-for-exec" v-else-if="item.status=='For Exec Approval'">{{ item.status }}</span>
                            <span class="badge badge-warning" v-else-if="item.status=='Stockcard'">{{ item.status }}</span>
                            <!-- <span class="badge badge-for-credit-limit" v-else-if="item.status_text=='for credit limit'">{{ item.status_text }}</span>
                            <span class="badge badge-for-credit-terms" v-else-if="item.status_text=='for credit terms'">{{ item.status_text }}</span> -->
                        </template>
                    </v-data-table>
                </div>
            </div>
        </v-app>
    </div>
        </v-row>
    </v-container>


    </template>

    <script>
    const ExcelJS = require('exceljs');
    import moment from 'moment';
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
    import { saveAs } from 'file-saver';
    import swal from 'sweetalert2'
    import { mapGetters } from 'vuex';
    export default {
        data(){
            return {
                loaded:true,
                filters:{
                    filter:1,
                    date:{
                        date_from:moment().clone().startOf('month').format('YYYY-MM-DD'),
                        date_to:moment().clone().endOf('month').format('YYYY-MM-DD'),
                        menu2: false,
                        menu3: false,
                        errors:[],
                    },
                    status:{
                        selected:[],
                        selection:[
                            {text:'Saved',value:0},
                            {text:'For Sales',value:1},
                            {text:'For Accounting',value:2},
                            {text:'Approved',value:3},
                            {text:'Completed',value:4},
                            {text:'Rejected',value:4},
                            {text:'Canceled',value:4},
                            {text:'Deleted',value:4},
                            {text:'Converted',value:4},
                            {text:'Exec Approved',value:4},
                            {text:'Partial',value:4},
                            {text:'For Exec Approval',value:4},
                            {text:'Stockcard',value:4},
                            {text:'For Cleanup',value:4},
                            {text:'For Credit Limit',value:4},
                            {text:'For Credit Terms',value:4},
                        ],
                    },
                    customers:{
                        selected:'',
                        selection:[],
                    },
                    items:{
                        selected:[],
                        selection:[],
                    },
                    categories:{
                        selected:[],
                        selection:[],
                    },
                    types:{
                        selected:[],
                        selection:[],
                    },
                    grouping:{
                        selection:[
                            {text:'By Customers',value:0},
                            {text:'By Items',value:1},
                            {text:'By SO',value:2},
                        ],
                        selected:null,
                    }
                },
                dataTable:{
                    headers:[],
                    items:[],
                    pagination:{
                        options:{},
                        totalItems:0,
                    }
                },
                loading: {
                    text: '',
                    bufferValue: 100,
                    value: 0,
                    dialog:false,

                    customer_selection          : true,
                    item_type_selection         : true,
                    category_selection          : true,
                    item_selection              : true,
                    initialLoad                 : true,

                },
            }
        },
        components: {
            SharedFunctionsComponent,ComponentHeader
        },

        mounted(){
                this.$store.dispatch('getAllitemSelectionDefault')
                this.$store.dispatch('getItemTypes')
                this.$store.dispatch('getItemCategories')
                this.$store.dispatch('getAllcustomersSelection')
        },
        computed:{
            ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ITEM_CATEGORIES',
            'GET_CUSTOMERS_SELECTION'
            ])
        },
        methods: {
            selectedStatus(val){
                this.filters.status.selected = val
            },
            selectedTypes(val){
                this.filters.types.selected = val
            },
            selectedCategories(val){
                this.filters.categories.selected = val
            },

            selectedItems(val){
                this.filters.items.selected = val
            },

            list(page = 1){
                let that = this

                that.loaded = false
                that.dataTable.items = []
                that.dataTableHeaders()

                this.$store.dispatch('reports',{
                    date_from :     that.filters.date.date_from,
                    date_to :       that.filters.date.date_to,
                    status :        that.filters.status.selected.map(e=>{return e.value}),
                    categories :    that.filters.categories.selected.map(e=>{return e.value}),
                    types :         that.filters.types.selected.map(e=>{return e.value}),
                    page:           page,
                    itemsPerPage:   that.dataTable.pagination.options.itemsPerPage,
                    grouping:       that.filters.grouping.selected,
                    statuses:       that.filters.status.selection.map(e=>{return e.text}),
                    customers:      that.filters.customers.selected,
                    url:'sales-order/per-items/list'

                }).then(response=>{

                    if(response.data.data.total < 1){
                        swal.fire('No Data !','- No data found','warning')
                    }
                    that.dataTable.items = response.data.data.data
                    that.dataTable.pagination.totalItems = response.data.data.total
                    that.loaded = true
                }).catch(e=>{
                    console.log(e)
                    swal.fire('Error !' ,'An error occured','error')
                    that.loaded = true
                })
            },

            dataTableHeaders(){
                let that = this

                if(that.filters.grouping.selected == 0){
                    that.dataTable.headers = [
                        {text:'Customer name',value:'customer_name'},
                        {text:'SO qty',value:'so_quantity'},
                        {text:'SO#',value:'so_number'},
                        {text:'Status',value:'status'},
                    ]
                }else if(that.filters.grouping.selected == 1){
                        that.dataTable.headers = [
                        {text:'Model',value:'model'},
                        {text:'Name',value:'name'},
                        {text:'Category',value:'uom'},
                        {text:'UOM',value:'uom'},
                        {text:'Type',value:'type'},
                        {text:'Total Qty Order',value:'total_quantity'},
                        {text:'Total Qty Served',value:'total_quantity_served'},
                        {text:'Total Qty Canceled',value:'total_quantity_canceled'},
                        {text:'Total Qty Pending',value:'total_quantity_pending'},
                        {text:'Status',value:'status'},
                    ]
                }else if(that.filters.grouping.selected == 2){
                    that.dataTable.headers = [
                        {text:'SO#',value:'so_number'},
                        {text:'Total Qty Order',value:'total_quantity'},
                        {text:'Total Qty Served',value:'total_quantity_served'},
                        {text:'Total Qty Canceled',value:'total_quantity_canceled'},
                        {text:'Total Qty Pending',value:'total_quantity_pending'},
                        {text:'SO DT',value:'discount_type'},
                        {text:'Status',value:'status'},
                    ]
                }else{
                    that.dataTable.headers = [
                        {text:'Customers',width:'17%',value:'company_name'},
                        {text:'SO#',width:'6%',value:'order_num'},
                        {text:'SO Disc',width:'7%',align:"left",value:'so_discounts'},
                        {text:'Model',width:'10%',value:'model'},
                        {text:'UOM',width:'7%',value:'uom'},
                        {text:'Category',width:'11%',value:'category'},
                        {text:'Type',width:'6%',value:'type'},
                        {text:'Status',width:'7%',value:'status'},
                        {text:'Qty',width:'7%',value:'quantity_ordered'},
                        {text:'Served',width:'7%',value:'quantity_served'},
                        {text:'Canelled',width:'7%',value:'quantity_canceled'},
                        {text:'Pending',width:'7%',value:'quantity_pending'},
                    ]
                }
            },

            async exportToExcel() {
                let that = this
                let workbook = new ExcelJS.Workbook();
                let style = { alignment: { vertical: 'middle', horizontal: 'center' }, width: 200 };
                let loopThruMonth = true;
                let beginningDate = moment(that.filters.date.date_from)
                let endDate = moment(that.filters.date.date_to)
                let counter = 1;
                let worksheet = workbook.addWorksheet('RawData')

                that.loading.bufferValue = endDate.diff(beginningDate, 'months')
                that.loading.dialog = true
                that.loaded = false

                while (loopThruMonth) {
                    let date_from = ''
                    let date_to = ''

                    if (beginningDate.format('YYYY-MM-DD') == this.filters.date.date_from) {
                        date_from = beginningDate.format('YYYY-MM-DD')
                        date_to = beginningDate.endOf('month').format('YYYY-MM-DD')

                    } else if (beginningDate.format('YYYY-MM') == endDate.format('YYYY-MM')) {
                        date_from = beginningDate.startOf('month').format('YYYY-MM-DD')
                        date_to = endDate.format('YYYY-MM-DD')

                    } else {
                        date_from = beginningDate.startOf('month').format('YYYY-MM-DD')
                        date_to = beginningDate.endOf('month').format('YYYY-MM-DD')
                    }

                    that.loading.text = beginningDate.format('MMM')
                    that.loading.value = (counter /that.loading.bufferValue) * 100
                    await this.$store.dispatch(`reports`, {
                        date_from:date_from,
                        date_to:date_to,
                        status:JSON.stringify(that.filters.status.selected.map(e=>{return e.value})),
                        types:JSON.stringify(that.filters.types.selected),
                        categories:JSON.stringify(that.filters.categories.selected),
                        customer:that.filters.customers.selected,
                        grouping:JSON.stringify(that.filters.grouping.selected),
                        item:JSON.stringify(that.filters.items.selected),
                        filter:that.filters.filter,
                        url:'sales-order/per-items/export'
                    }).then(response => {
                        worksheet.columns = [
                            { header: 'Customers', key: 'company_name',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'SO Num', key: 'order_num',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'PT', key: 'textConcertionPt',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'HT', key: 'textConcertionHt',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'SP', key: 'textConcertionSp',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Model', key: 'model',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Name', key: 'name',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'UOM', key: 'uom',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Category', key: 'category',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Type', key: 'type',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Order Qty', key: 'quantity_ordered',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Served Qty', key: 'quantity_served',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Canceled Qty', key: 'quantity_canceled',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Status', key: 'status',style: JSON.parse(JSON.stringify(style))  },
                            { header: 'Created Date', key: 'created_at',style: JSON.parse(JSON.stringify(style))  },
                        ]
                        response.data.data.forEach((e,i) => {
                            worksheet.addRow(e)
                            worksheet.getCell(`N${i + 2}`).value = that.filters.status.selection[e.status].text
                        });
                    }).catch(e => {
                        swal.fire('Error !','','error')
                        console.log(e)
                    })

                    if (beginningDate.format('YYYY-MM') == endDate.format('YYYY-MM')) {
                        loopThruMonth = false
                    }

                    beginningDate = beginningDate.add(1, 'month')
                    counter++;
                }
                that.loaded = true
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    saveAs(blob, `Sales Orders ${that.filters.date.date_from}-${that.filters.date.date_to}.xlsx`);
                });

                that.loading.dialog = false
                that.loading.value = 0
                swal.fire('Success!','','success')
            },

            thousandSeprator(amount) {
                if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return amount;
                }
            },
            upperCaseFirstLetter(str){
                    const arr = str.split(" ");
                    for (var i = 0; i < arr.length; i++) {
                        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                    str = arr.join(" ");
                    return str
                },

            resetFields(){
                let that = this
                that.filters.status.selected = []
                that.filters.items.selected = []
                that.filters.categories.selected = []
                that.filters.customers.selected = ''
                that.filters.types.selected = that.filters.types.selected.filter(e=>{return e.value != 3})
                that.filters.date.date_from = moment().clone().startOf('month').format('YYYY-MM-DD')
                that.filters.date.date_to = moment().clone().endOf('month').format('YYYY-MM-DD')
            }
        },
        watch:{
            'dataTable.pagination.options':{
                handler(val){
                    if(!this.loading.initialLoad){
                        this.list(val.page)
                    }else{
                        this.loading.initialLoad = false
                    }
                },
                deep:true
            },
            'GET_ITEM_TYPES':{
                    handler(val){
                        this.filters.types.selection = val
                        this.filters.types.selected = val.filter(e=>{
                            if(e.value != 3){
                                return e.value
                            }
                        })
                        this.loading.item_type_selection = false
                    }
                },
                'GET_ITEM_SELECTION_DEFAULT':{
                    handler(val){
                        this.filters.items.selection = val
                        this.loading.item_selection = false
                    }
                },
                'GET_ITEM_CATEGORIES':{
                    handler(val){
                        this.filters.categories.selection = val.data
                        this.loading.category_selection = false
                    }
                },
                'GET_CUSTOMERS_SELECTION':{
                    handler(val){
                        this.filters.customers.selection = val
                        this.loading.customer_selection = false
                    }
                },
        }
    };
    </script>
    <style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
    </style>
