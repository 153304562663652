<template>
    <td class="pa-2 border-0" v-if="columnDetails.type == 'string' || columnDetails.type == 'numberOnly'" ><v-text-field dense outlined :value="columnData[columnDetails.column_name]" readonly></v-text-field></td>
    <td class="pa-2 border-0" v-else-if="columnDetails.type == 'numberDecimal'" ><v-text-field dense outlined readonly> {{ columnData[columnDetails.column_name]  }} </v-text-field></td>
    <td class="pa-2 border-0" v-else-if="columnDetails.type == 'boolean'" ><v-text-field dense outlined :value="columnData[columnDetails.column_name] == 0 ? 'NO' : 'YES'" readonly></v-text-field></td>
    <td class="pa-2 border-0" v-else-if="columnDetails.type == 'json_encoded'" ><v-text-field dense outlined :value="checkJsonEncodedData()" readonly></v-text-field></td>
    <!-- <td class="pa-2 pt-8 border-0" v-else-if="columnDetails.type == 'arrayObject'">
        <span v-for="(arrObjDetails, i) in columnData[columnDetails.column_name]" :key="i">
            <span v-for="(details, key) in arrObjDetails" :key="key">
                <v-text-field
                    v-if="!notIncludedArrObjectKeys.includes(key)
                    &&
                    checkValues(key, i, columnDetails.column_name)"
                    dense outlined
                    :value="details"
                    readonly>
                </v-text-field>
            </span>
        </span>
    </td> -->
</template>
<script>
import { mapGetters } from 'vuex'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins: [SharedFunctionsComponentVue],
    props: {
        columnDetails: {
            default: function () {
                return {}
            },
            type: Object,
        },
        columnData: {
            default: function () {
                return {}
            },
            type: Object,
        },
        baseData: {
            default: function () {
                return {}
            },
            type: Object,
        },
        notIncludedArrObjectKeys: {
            default: function (){
                return []
            },
            type: Array,
        },
        oldKeyObj: {
            default: function (){
                return ''
            },
            type: String,
        },
        newKeyObj: {
            default: function (){
                return ''
            },
            type: String,
        },
    },
    data(){
        return{
            checkJsonEncodedData(){
                let data_value = '';
                data_value =  JSON.parse(this.columnData[this.columnDetails.column_name]) ?? ''

                return data_value[this.columnDetails.json_key]
            },
            checkValues(key, index, column){

                let oldVal = this.baseData[this.oldKeyObj][column][index][key];
                let newVal = this.baseData[this.newKeyObj][column][index][key];

                if(oldVal != newVal){
                    return true;
                }
                else{
                    return false;
                }
            }
        }
    },
    computed:{

    },
    mounted(){

    },
    methods:{

    },
    watch:{
        columnDetails:{
            handler(val){
            }
        },
        columnData:{
            handler(val){
            }
        },
        notIncludedArrObjectKeys:{
            handler(val){
            }
        }
    }
}
</script>
<style scoped>

</style>
