<template>
    <v-container class="ma-0 pa-0">
        <v-data-table
            :headers="component.headers"
            :items="component.items"
            :loading="loading"
        >
            <template v-slot:item.deduct_from_sales="{ item }">
                <span class="badge badge-primary" v-if="item.deduct_from_sales">Yes</span>
                <span class="badge badge-danger" v-else>No</span>
            </template>
            <template v-slot:item.action="{ item }">
                <span class="text-nowrap">
                    <v-btn text icon color="orange" v-if="$emit('hasAccess', 'view_classification')">
                        <v-icon class="btn-action" small
                            @click="view(item.id)">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn text icon color="blue" v-if="$emit('hasAccess', 'edit_classification')">
                        <v-icon class="btn-action" small
                            @click="edit(item.id)">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" v-if="$emit('hasAccess', 'delete_classification')">
                        <v-icon class="btn-action" small @click="deletion(item.id)">mdi-delete</v-icon>
                    </v-btn>
                </span>
            </template>
        </v-data-table>
        <CreditMemoClassificationViewComponent @close='close' :id="component.id" :display_name="display_name"></CreditMemoClassificationViewComponent>
    </v-container>
</template>

<script>
import CreditMemoClassificationViewComponent from './CreditMemoClassificationViewComponent.vue';
import swal from 'sweetalert2';

export default {
    components: {
        CreditMemoClassificationViewComponent,
    },
    props: [
        'origin',
        'display_name',
    ],
    data() {
        return {
            component: {
                headers: [
                    { text: 'CODE', value: 'code' },
                    { text: 'NAME', value: 'name' },
                    { text: 'DEDUCT FROM SALES', value: 'deduct_from_sales' },
                    { text: 'ACTION', align:'center', value: 'action' },
                ],
                items: [],
                vuex: {
                    get_all: {
                        dispatch: 'creditMemoClassification',
                        action: 'get',
                        url: 'credit-memo-classification',
                    },
                    delete: {
                        dispatch: 'creditMemoClassification',
                        action: 'delete',
                        url: 'credit-memo-classification',
                    },
                },
                id: '',
            },
            loading: false,
        }
    },
    mounted(){
        this.getAll()
    },
    methods: {
        getAll(){
            this.loading = true
            this.component.items = []

            let payload = {
                origin: this.origin,
                action: this.component.vuex.get_all.action,
                url: this.component.vuex.get_all.url,
            }

            this.$store.dispatch(this.component.vuex.get_all.dispatch,payload).then(response => {
               this.component.items = response.data
               this.loading = false
            })
        },
        view(id){
            this.component.id = id
        },
        close(){
            this.component.id = 0
        },
        edit(id){
            this.$emit('showEditDialog', id)
        },
        deletion(id){
            swal.fire({
                title: '',
                text: 'Are you sure you want to Delete?',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        id: id,
                        action: this.component.vuex.delete.action,
                        url: this.component.vuex.delete.url,
                    }

                    this.$store.dispatch(this.component.vuex.delete.dispatch, payload).then(response => {
                        swal.fire(response.data.msg, '', response.data.icon)
                        this.getAll()
                    })
                }
            });
        },
    },
}
</script>
