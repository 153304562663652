<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Vendor Type</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.code"
                :rules="errors.code"
                label="Code"
                required
                outlined
                dense
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.vendorType"
                :rules="errors.vendorType"
                label="Vendor Type"
                outlined
                dense
                :disabled="disable_view"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="form.description" label="Description" outlined dense :disabled="disable_view"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions != 'View'">
            <v-btn class="float-center" @click="actions == 'Submit' ? addVendorType() : UpdateVendorType()" :disabled="disable_view">
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      valid: false,
      form: {
        code: '',
        vendorType: '',
        description: ''
      },
      item: '',
      errors: {
        code: [v => !!v || 'The code is required'],
        vendorType: [v => !!v || 'The vendor is required'],
        description: [v => !!v || 'The description is required'],
      },
      disable_view:false,
    };
  },
  computed: {
    ...mapGetters(['ACTION', 'DIALOGS', 'NEW_vendortype', 'SELECTED_VENDOR_TYPE']),
    actions() {
      if (this.ACTION == 'Submit') {
        this.disable_view = false;
       this.resetfields();
        return 'Submit'
      } else if (this.ACTION == 'Update') {
        this.disable_view = false;
      this.getVendorTypeEdit();
        return 'Update'
      }else if (this.ACTION == 'View') {
        this.disable_view = true;
      this.getVendorTypeEdit();
        return 'View'
      }
    },
  },
  methods: {
    getVendorTypeEdit(){
        this.form = {
          code: this.SELECTED_VENDOR_TYPE.code,
          vendorType: this.SELECTED_VENDOR_TYPE.name,
          description: this.SELECTED_VENDOR_TYPE.description,
        };
        this.item = this.SELECTED_VENDOR_TYPE;
    },
    closeDialog() {
      this.$store.commit('DIALOG', false);
      this.$store.commit('ACTION', '')
      this.resetfields();
    },
    addVendorType() {
      this.$refs.form.validate();
      const code_swal_text = this.form.code ? '' : 'Code'
      const vendor_swal_text = this.form.name ? '' : 'Vendor Type'
      const array_text = [vendor_swal_text, code_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '')
      });
      if (this.form.code && this.form.vendorType) {
        this.$store.dispatch('addVendorType', this.form);

      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    UpdateVendorType() {
      this.$refs.form.validate();
      const code_swal_text = this.form.code ? '' : 'Code'
      const vendor_swal_text = this.form.name ? '' : 'Vendor Type'
      const array_text = [vendor_swal_text, code_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '')
      });
      if (this.form.code && this.form.vendorType) {
        this.$store.dispatch('editVendorType', {
          id: this.item.id,
          payload: this.form,
        });
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    resetfields() {
      this.form = {
        code: '',
        vendorType: '',
        description: ''
      };
    }
  },
  watch: {},
};
</script>
