<template>
  <v-container class="pa-0 ma-0">
    <v-col>
      <v-card>
        <v-card-title>
          <v-container>
         <v-row>
          <!-- <buttons
          v-if="userAccess.create"
                :action="buttons.actions.create"
                :button_icon="buttons.icons.create"
                :color="buttons.colors.create"
                @click="adddialogs"
              ></buttons> -->
        <div class="row" v-if="userAccess.create">
            <div class="col-12 d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn @click="adddialogs()">
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </v-btn>
                </div>
            </div>
        </div>
         </v-row>
          <v-row>
            <v-col v-if="userAccess.edit">
              <v-checkbox v-model="enableDisableDrag" style="display: inline-block" dense />
              <span style="font-size: 14px" class="px-1">Enable Drag</span>
              <span v-if="enableDisableDrag" class="px-1"
                ><v-btn @click="saveCurrentPositions()" small>Save</v-btn></span
              >
              <span v-if="enableDisableDrag"><v-btn @click="getSalesmanPositions()" small>Refresh</v-btn></span>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
        </v-card-title>
        <v-simple-table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Head</th>
              <th scope="col">Rank</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <draggable v-model="salesmanPositions" tag="tbody" :disabled="!enableDisableDrag">
            <tr v-for="(item, index) in salesmanPositions" :key="item.title" :style="tableRowStyle(item.title)">
              <td scope="row">{{ item.title }}</td>
              <td>{{ item.description }}</td>
              <td>{{ parentName(item) }}</td>
              <td>{{ index + 1 }}</td>
              <td
                v-if="item.title != 'Salesman' && item.title != 'National Manager' && item.title != 'Sales Coordinator'"
                class="px-0"
              >
                <!-- <buttons
                  v-if="userAccess.edit"
                  :action="buttons.actions.edit"
                  :button_icon="buttons.icons.edit"
                  :color="buttons.colors.edit"
                  :button_text="buttons.type.text"
                  :icon="buttons.type.icon"
                  @click="editdialogs(item)"
                >
                </buttons>
                <buttons
                  v-if="userAccess.delete"
                  :action="buttons.actions.delete"
                  :button_icon="buttons.icons.delete"
                  :color="buttons.colors.delete"
                  :button_text="buttons.type.text"
                  :icon="buttons.type.icon"
                  @click="deleteSalesmanPosition(item)"
                >
                </buttons> -->
                <v-btn text icon color="orange" v-if="userAccess.edit" >
                    <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.edit" >
                    <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete" >
                    <v-icon class="btn-action" small @click="deleteSalesmanPosition(item.id)">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </v-simple-table>
      </v-card>
    </v-col>
    <dialogs v-if="SALESMAN_POSITION_DIALOG" :cp="components"></dialogs>
  </v-container>
</template>
<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import salesmanpositionsDialog from '../../../layouts/dialogs/Masterdata/SalesmanPositionComponent.vue'
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
import Dialogs from '../../../layouts/dialogs/Dialog.vue'
import draggable from 'vuedraggable'
export default {
  components: {
    // buttons,
    // buttons_components,
    draggable,
    salesmanpositionsDialog,
    Dialogs,
  },
  data() {
    return {
      components: salesmanpositionsDialog,
      // buttons: buttons_components.buttons,
      loaded: false,
      search: '',
      headers: [
        { text: 'Title', align: 'left', value: 'title' },
        { text: 'Description', align: 'left', value: 'description' },
        { text: 'Head', align: 'left', value: 'parent_position_ids' },
        { text: 'Rank', align: 'left', value: 'rank' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      dragging: false,
      salesmanPositions: null,
      enableDisableDrag: false,
      itemMove: false,
      ranks: [],
      newData: [],
      salesmanposition: false,
    };
  },
  mounted() {
    this.enableDisableDrag = false;
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    this.getSalesmanPositions()
  },
  computed: {
    ...mapGetters(['GET_SALESMAN_POSITION_LIST', 'USER_ACCESS', 'NEW_SALESMAN_POSITION', 'SALESMAN_POSITION_DIALOG']),
  },
  methods: {
    getSalesmanPositions() {
      this.$store.dispatch('getSalesmanposition').then(response => {
        this.salesmanPositions = response;
      })
    },
    viewdialogs(item) {
      this.$store.commit('SALESMAN_POSITION_DIALOG', true);
      this.$store.commit('SELECTED_SALESMAN_POSITION', item)
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'View')
    },
    editdialogs(item) {
      this.$store.commit('SALESMAN_POSITION_DIALOG', true);
      this.$store.commit('SELECTED_SALESMAN_POSITION', item)
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'Update')
    },
    parentName(item) {
      if (!!item && !!item.parent_position_ids) {
        const parentIds = JSON.parse(item.parent_position_ids)
        if (parentIds.length == 0) {
          return ''
        } else {
          const headsTitle = [];
          parentIds.forEach(pId => {
            const parent = this.salesmanPositions.find(x => x.id == pId);
            if (parent) {
              headsTitle.push(parent.title);
            }
          });
          return headsTitle.toLocaleString().replaceAll(',', ', ')
        }
      }
      return ''
    },
    tableRowStyle(title) {
      let defaultStyle = 'cursor:move'
      if (title == 'National Manager' || title == 'Salesman' || title == 'Sales Coordinator') {
        defaultStyle = defaultStyle.concat(';pointer-events:none')
      }
      return defaultStyle;
    },
    deleteSalesmanPosition(item) {
      if (item) {
        swal
          .fire({
            text: 'Are you sure you want to delete salesman position?',
            icon: 'warning',
            showCancelButton: true,
          })
          .then(confirm => {
            if (confirm.isConfirmed) {
              this.$store.dispatch('deleteSalesmanPosition', item.id).then(response => {
                this.getSalesmanPositions();
                swal.fire({
                  text: 'Deleted Sucess',
                  icon: 'success',
                  timer: 1500,
                });
              })
            }
          })
          .catch(e => {
            swal.fire({ text: e.data.error, icon: 'error' });
          })
      }
    },
    saveCurrentPositions() {
      swal
        .fire({
          text: 'Are you sure you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
        })
        .then(confirm => {
          if (confirm.isConfirmed) {
            this.$store
              .dispatch('updateallsalesmanposition', {
                salesmanPositions: this.salesmanPositions,
              })
              .then(() => {
                this.enableDisableDrag = false;
                swal.fire({
                  title: 'Success',
                  icon: 'success',
                  timer: 1500,
                  showConfirmButton: false,
                });
              })
          }
        });
    },
    adddialogs() {
      this.$store.commit('SALESMAN_POSITION_DIALOG', true);
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'Submit');
    }
  },

  watch: {
    NEW_SALESMAN_POSITION: {
      handler(val) {
        if (val) {
          this.$store.commit('SALESMAN_POSITION_DIALOG', false);
          this.getSalesmanPositions()
          swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });

          this.$store.commit('NEW_SALESMAN_POSITION', false)
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
          if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
           this.userAccess.create = false;
        val.forEach(e => {
          if (e.actions_code == 'create') {
            this.userAccess.create = true;

           }
           else if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
      }
    },
    salesmanPositions() {
      if (this.salesmanPositions.length > 0) {
        this.loading = false;
        if (this.enableDisableDrag) {
          this.salesmanPositions.forEach((x, i) => {
            if (x.rank == 1) {
            } else {
              x.rank = i + 1;
              if (x.rank == 2) {
                x.parent_position_ids = `[${this.salesmanPositions[0].id}]`;
              } else {
                const num = x.rank
                const filtered = this.salesmanPositions.filter(x => x.rank < num).reverse();
                x.parent_position_ids = `[${filtered.map(x => {
                  return x.id;
                })}]`
              }
            }
          });
        }
      } else {
        setTimeout(() => {
          this.loading = false;
          this.noDataText = 'No Data'
        }, 10000);
      }
    },
  },
};
</script>
