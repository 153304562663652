<template>
    <v-container fluid>
        <ComponentHeader :button="true" title='Item Movement' type='Inventory'></ComponentHeader>
        <v-container fluid class="bg-bluish-gray">
            <v-row dense no-gutters>
                <v-col cols="3">
                    <v-autocomplete
                        v-model="filters.item_id"
                        :items="GET_ITEMS"
                        background-color="white"
                        label="Select Model"
                        dense
                        outlined
                        hide-details
                        clearable
                        auto-select-first
                    ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                    <v-autocomplete
                        v-model="filters.warehouse_id"
                        :items="GET_ALL_WAREHOUSES"
                        background-color="white"
                        label="Select Warehouse"
                        dense
                        outlined
                        hide-details
                        clearable
                        auto-select-first
                    ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="3" class="d-flex justify-end">
                    <v-btn small class="ml-1" @click="getItemMovement('xlsx')">Excel</v-btn>
                    <v-btn small class="ml-1" @click="getItemMovement()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row dense no-gutters>
                <!-- <v-col cols="2">
                    <v-autocomplete
                        v-model="filters.item_type_id"
                        :items="GET_ITEM_TYPES"
                        background-color="white"
                        label="Select Item Type"
                        dense
                        outlined
                        hide-details
                        clearable
                        auto-select-first
                    ></v-autocomplete>
                </v-col> -->
                <!-- <v-col cols="3">
                    <v-autocomplete
                        v-model="filters.item_category_id"
                        :items="GET_ITEM_CATEGORIES"
                        background-color="white"
                        label="Select Item Category"
                        dense
                        outlined
                        hide-details
                        clearable
                        auto-select-first
                    ></v-autocomplete> -->
                    <v-spacer></v-spacer>
            </v-row>
            <v-row dense no-gutters class="my-3">
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="dataTable.headers"
                            :items="dataTable.items"
                            :loading="dataTable.loading"
                            disable-sort
                            disable-pagination
                            dense
                            hide-default-footer
                        >
                            <template #item.in_qty="{ item }">
                                {{ thousandSeprator(item.in_qty) }}
                            </template>
                            <template #item.out_qty="{ item }">
                                {{ thousandSeprator(item.out_qty) }}
                            </template>
                            <template #item.running_bal="{ item }">
                                {{ thousandSeprator(item.running_bal) }}
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import swal from 'sweetalert2'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'

const ExcelJS = require('exceljs')
const FileSaver = require('file-saver')

export default {
    data() {
        return {
            filters: {
                item_id: null,
                warehouse_id: null,
                // item_type_id: null,
                // item_category_id: null,
            },
            dataTable: {
                headers: [
                    { text: 'Doc Date', align: 'center', value: 'transact_date' },
                    { text: 'Doc Num', align: 'left', value: 'transact_num' },
                    { text: 'Doc Type', align: 'center', value: 'transact_type_name' },
                    { text: 'Customer', align: 'left', value: 'customer_name' },
                    { text: 'User', align: 'left', value: 'user_name' },
                    { text: 'Warehouse', align: 'center', value: 'warehouse_code' },
                    { text: 'Model', align: 'left', value: 'model' },
                    { text: 'Name', align: 'left', value: 'name' },
                    { text: 'UOM', align: 'center', value: 'uom' },
                    { text: 'Type', align: 'center', value: 'type' },
                    { text: 'Category', align: 'left', value: 'category' },
                    { text: 'Qty In', align: 'right', value: 'in_qty' },
                    { text: 'Qty Out', align: 'right', value: 'out_qty' },
                    { text: 'Balance', align: 'right', value: 'running_balance' },
                ],
                items: [],
                loading: false,
            },
            exportData: {
                filename: 'Item Movement',
                file_ext: 'xlsx',
                headers: [
                    { header: 'Doc Date', key: 'transact_date', style: { numFmt: 'm/d/yyyy' } },
                    { header: 'Doc Num', key: 'transact_num' },
                    { header: 'Doc Type', key: 'transact_type_name' },
                    { header: 'Customer', key: 'customer_name' },
                    { header: 'User', key: 'user_name' },
                    { header: 'Warehouse', key: 'warehouse_code' },
                    { header: 'Model', key: 'model' },
                    { header: 'Name', key: 'name' },
                    { header: 'UOM', key: 'uom' },
                    { header: 'Type', key: 'type' },
                    { header: 'Category', key: 'category' },
                    { header: 'Qty In', key: 'in_qty', style: { numFmt: '#,##0' } },
                    { header: 'Qty Out', key: 'out_qty', style: { numFmt: '#,##0' } },
                    { header: 'Balance', key: 'running_balance', style: { numFmt: '#,##0' } },
                    { header: 'System Date', key: 'created_at', style: { numFmt: 'm/d/yyyy hh:mm' } },
                ],
            },
        }
    },
    mixins: [
        SharedFunctionsComponent,
    ],
    components: {
        ComponentHeader,
    },
    mounted() {
        this.$store.dispatch('getItems', {
            active: [0, 1],
        })
        this.$store.dispatch('getAllWarehouses')
        // this.$store.dispatch('getItemTypes')
        // this.$store.dispatch('getItemCategories')
    },
    computed: {
        ...mapGetters([
            'GET_ITEMS',
            'GET_ALL_WAREHOUSES',
            // 'GET_ITEM_TYPES',
            // 'GET_ITEM_CATEGORIES',
        ])
    },
    methods: {
        async getItemMovement(type = 'list') {
            if (this.filters.item_id == null || this.filters.warehouse_id == null) {
                swal.fire({
                    title: 'Must Select Item and Warehouse!',
                    icon: 'warning',
                })

                return
            }

            this.dataTable.loading = true
            this.dataTable.items = []

            let payload = {
                item_id: this.filters.item_id,
                warehouse_id: this.filters.warehouse_id,
                // item_type_id: this.item_type_id,
                // item_category_id: this.item_category_id,
                url: "getItemMovement",
            }

            await this.$store.dispatch('reports', payload).then(response => {
                let data = response.data.reverse()

                if (type == 'xlsx') {
                    let workbook = new ExcelJS.Workbook()
                    let worksheet = workbook.addWorksheet('Raw Data')

                    worksheet.columns = this.exportData.headers
                    data.forEach(e => {
                        e.transact_date = this.formatDate(e.transact_date)
                        e.created_at = this.formatDate(e.created_at)
                        worksheet.addRow(e)
                    })

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        FileSaver.saveAs(blob, `${this.exportData.filename} Report ${moment().format('YYYY-MM-DD')}.${this.exportData.file_ext}`)
                    })
                }

                this.dataTable.items = data
                this.dataTable.loading = false
            }).catch(error => {
                console.log(error)
                this.dataTable.loading = false
            })
        },
    },
}
</script>
