<template>
    <div class="w-100 p-3">
      <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
      </v-app>

      <v-app id="my_vue_app">
        <v-card v-show="loaded" elevation="4">
          <v-card-title>
            <v-row dense no-gutters tag="div" style="align-items: right">
              <v-col md="5"> </v-col>
              <v-col md="3">
                <v-text-field dense v-model="search" label="Search Inbound" single-line hide-details> </v-text-field>
              </v-col>
              <v-col md="3">
                <v-text-field dense v-model="searchItem" label="Search Item Name/Model" single-line hide-details>
                </v-text-field>
              </v-col>
              <v-col md="1" style="text-align: end">
                <v-icon @click="searchInput()">mdi-magnify</v-icon>
              </v-col>
            </v-row>
          </v-card-title>

          <v-container fluid>
            <v-data-table
                :headers="headers"
                :items="Inbound"
                :search="search"
                :options.sync="options"
                :server-items-length="totalInbound"
                :items-per-page="10"
                :footer-props="{
                    showFirstLastPage: true,
                }"
                class="w-100"
            >
              <template v-slot:[`item.created_at`]="{ item }">
                <span class="text-nowrap">
                  {{ item.created_at | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.confirm_date`]="{ item }">
                <span class="text-nowrap">
                  {{ item.confirm_date | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.received_date`]="{ item }">
                <span class="text-nowrap">
                  {{ item.received_date | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.status_text`]="{ item }">
                <span class="badge bg-warning" v-if="item.status_text == 'pending'">{{ item.status_text }}</span>
                <span class="badge bg-primary" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span>
                <span class="badge bg-success" v-else-if="item.status_text == 'completed'">{{ item.status_text }}</span>
                <span class="badge bg-danger" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                <span class="badge bg-danger" v-else-if="item.status_text == 'cancelled'">{{ item.status_text }}</span>
                <span class="badge bg-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-btn text icon color="orange" v-if="ACCESS_INBOUND.includes('view_inbound')">
                  <v-icon class="btn-action" small @click="viewInbound(item.id)">mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="ACCESS_INBOUND.includes('edit_inbound') && item.status == 0 && !is_receiving">
                  <v-icon class="btn-action" small @click="editInbound(item.id)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="ACCESS_INBOUND.includes('receive_inbound') && item.status == 0 && is_receiving && item.received_date === null">
                  <v-icon class="btn-action" small @click="editInbound(item.id, true)">mdi-clipboard-check</v-icon>
                </v-btn>
                <v-btn text icon color="red" v-if="ACCESS_INBOUND.includes('delete_inbound') && item.status == 0 && !is_receiving">
                  <v-icon class="btn-action" small @click="deleteInbound(item.id)">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>
      <InboundViewComponent
        :viewInboundId="viewInboundId"
        :searchText="searchItem"
        :is_receiving="is_receiving"
        @closeView="closeView"
        @changeInboundStatus="changeInboundStatus"
        :user_access="user_access"
      >
      </InboundViewComponent>
    </div>
  </template>

  <script>
  import InboundViewComponent from '../../../../layouts/dialogs/Operations/Inbound/InboundViewComponent.vue'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex';
  export default {
    components: {
      InboundViewComponent,
    },
    props: ['InboundActionResult', 'is_pending', 'is_receiving', 'is_approved', 'radioGroup','user_access'],
    mounted: function () {
      this.getSearchFromUrl();
    },
    computed:{
      ...mapGetters([
        'USER_ACCESS','ACCESS_INBOUND','SELECTED_FILTER'
      ])
    },
    data() {
      return {
        actionResult: {
          model: 'Inbound',
          action: '',
        },
        loaded: false,
        totalInbound: 0,
        options: {},
        Inbound: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 10,
        search: '',
        searchItem: null,
        awaitingSearch: false,
        radioValue: '',
        headers: [
          { text: 'Inbound Num', align: 'left', value: 'inbound_num' },
          { text: 'Warehouse', align: 'left', value: 'warehouse_name' },
          { text: 'Broker', align: 'left', value: 'broker' },
          { text: 'Date Created', align: 'left', value: 'created_at' },
          { text: 'Created By', align: 'left', value: 'created_by_name' },
          { text: 'Received By', align: 'left', value: 'received_by_name' },
          { text: 'Receive Date', align: 'left', value: 'received_date' },
          { text: 'Confirm Date', align: 'left', value: 'confirm_date' },
          { text: 'Status', align: 'left', value: 'status_text' },
          { text: 'Actions', align: 'center', value: 'action', sortable: false },
        ],

        viewInboundId: '',

        userAccess: {
          view: false,
          edit: false,
          delete: false,
          receive: false,
        },
      };
    },
    methods: {
      getAllInbound() {

        this.loaded = false;
        const payload = {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          search: this.search,
          searchItem: this.searchItem,
          is_pending: this.is_pending,
          is_receiving: this.is_receiving,
          is_approved: this.is_approved,
          radio_value: this.radioGroup,
        }
        this.$store.dispatch('getAllInbound', payload).then(response => {

          this.Inbound = response.data.data;
          this.loaded = true;
          this.totalInbound = response.data.total;

        });
      },
      getSearchFromUrl() {
        if (document.location.href.includes('search-model=')) {
          let item = document.location.href.split('search-model=');
          item = item[1].split('&')
          this.searchItem = item[0];
          this.searchInput();
        }
      },
      viewInbound(id) {
        this.viewInboundId = id;
      },
      editInbound(id, is_receiving = false) {
        this.$emit('showEditModal', id, is_receiving);
      },
      deleteInbound(id) {
        swal
          .fire({
            title: '',
            text: 'Are you sure you want to Delete?',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            showCloseButton: true,
          confirmButtonColor: '397373',
            cancelButtonColor: 'grey',
            reverseButtons: true,
        })
          .then(result => {
            if (result.isConfirmed) {
              this.$store
                .dispatch('deleteInbound', id)
                .then(response => {
                  swal.fire('', 'Inbound Deleted Successfully', 'success');
                this.getAllInbound()
              }).catch(error => {
                  swal.fire('An Error Occured', error, 'error')
              })
            }
          });
      },
      closeView() {
        this.viewInboundId = 0;
      },
      changeInboundStatus() {
        this.viewInboundId = 0;
        this.getAllInbound();
      },
      searchInput() {
        this.getAllInbound({ query: this.search });
      },
      addKeyListener() {
        window.addEventListener(
          'keyup',
          function (e) {
            if (e.keyCode == 13) {
            this.searchInput();
            $('input').blur()
                  }
          }).bind(this)
      },

    },
    watch: {
      radioGroup: function (val) {
        this.getAllInbound();
      },
      InboundActionResult: function (val) {
        if (this.InboundActionResult == 'success') {
          this.getAllInbound();
        }
      },
      options: {
        handler() {
          this.getAllInbound();
        },
        deep: true,
      },
      'SELECTED_FILTER':{
        handler(val){
           this.getAllInbound(val)
        }
      }
    },
  };
  </script>
