import MasterdataUsers from '@/views/main/modules/MasterData/UsersComponent.vue'
import masterdataDepartment from '@/views/main/modules/MasterData/DepartmentsComponent.vue'
import masterdataDepartmentAccess from '@/views/main/modules/MasterData/DepartmentAccessComponent.vue'
import masterdataRoles from '@/views/main/modules/MasterData/RolesComponent.vue'
import masterdataVehicles from '@/views/main/modules/MasterData/VehiclesGroupComponent.vue'
import masterdataWarehouse from '@/views/main/modules/MasterData/WarehouseComponent.vue'
import masterdataSalesman from '@/views/main/modules/MasterData/SalesmanComponent.vue'
import masterdataCustomer from '@/views/main/modules/MasterData/CustomerComponent.vue'
import masterdataVendor from '@/views/main/modules/MasterData/VendorComponent.vue'
import masterdataCluster from '@/views/main/modules/MasterData/ClusterComponent.vue'
import repository from '@/api/credentials'

const routes = [{
    path: '/masterdata/users-list/:id',
    name: 'masterdatausers',
    props: true,
    component: MasterdataUsers,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Users',
    },
},
{
    path: '/masterdata/department/:id',
    name: 'masterdatadepartments',
    component: masterdataDepartment,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Departments',
    },
},
{
    path: '/masterdata/department-access/:id',
    name: 'masterdatadepartmentaccess',
    component: masterdataDepartmentAccess,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Department Access',
    },
},
{
    path: '/masterdata/roles/:id',
    name: 'masterdataroles',
    component: masterdataRoles,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })

    },
    meta: {
        requiresAuth: true,
        title: 'Roles',
    },
},
{
    path: '/masterdata/vehicles/:id',
    name: 'masterdatavehicles',
    component: masterdataVehicles,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Vehicles',
    },
},
{
    path: '/masterdata/warehouse/:id',
    name: 'masterdatawarehouse',
    component: masterdataWarehouse,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Warehouses',
    },
},
{
    path: '/masterdata/salesman/:id',
    name: 'masterdatasalesman',
    component: masterdataSalesman,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Salesman',
    },
},
{
    path: '/masterdata/customer/:id',
    name: 'masterdatacustomer',
    component: masterdataCustomer,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customers',
    },
},
{
    path: '/masterdata/vendor/:id',
    name: 'masterdatavendor',
    component: masterdataVendor,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Vendors',
    },
},
{
    path: '/masterdata/cluster/:id',
    name: 'masterdatacluster',
    component: masterdataCluster,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Clusters',
    },
}]

export default routes;
