<template>
<v-container fluid>

        <ComponentHeader
            :button="true"
            :title="'Daily Movement Inventory'"
            :type="'Inventory'"
        ></ComponentHeader>

    <v-container fluid class="bg-bluish-gray  default-container">

        <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>

                <v-row class="pa-2">
                    <v-col lg="5">
                        <v-autocomplete :items="GET_ITEM_SELECTION_DEFAULT"
                            :loading="loading.item_selection_default"
                            :disabled="loading.item_selection_default"
                            outlined
                            dense
                            background-color="white"
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            return-object
                            v-model="item_ids"
                            item-value="value"
                            item-text="text"
                            label="Select Name"
                            class="small"
                        >
                        </v-autocomplete>
                        <span v-for="errors in errors.item_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn small class="ml-1" v-on:click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" v-on:click="exportDailyMovement()">Excel</v-btn>
                        <v-btn small class="ml-1" v-on:click="getDailyMovement()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row class="pa-2">
                    <v-col lg="3">
                        <v-autocomplete :items="GET_ITEM_TYPES"
                            :loading="loading.item_type_selection"
                            :disabled="loading.item_type_selection"
                            outlined
                            dense
                            background-color="white"
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            return-object
                            v-model="item_type_ids"
                            label="Select Disc Type"
                            class="small"
                        ></v-autocomplete>
                        <span v-for="errors in errors.item_type_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                    <v-col lg="3">
                        <v-autocomplete
                            :items="GET_ALL_ITEM_CATEGORIES"
                            :loading="loading.category_selection"
                            :disabled="loading.category_selection"
                            outlined
                            dense
                            background-color="white"
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            return-object
                            v-model="category_ids"
                            item-value="id"
                            item-text="name"
                            label="Select Category"
                            class="small"
                        ></v-autocomplete>
                        <span v-for="errors in errors.item_category_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                </v-row>
                <v-row class="row pa-2">
                    <v-col lg="3">
                        <v-autocomplete
                            :items="GET_ALL_WAREHOUSES"
                            :loading="loading.warehouse_selection"
                            :disabled="loading.warehouse_selection"
                            v-model="item_warehouse_ids"
                            dense
                            multiple
                            outlined
                            background-color="white"
                            return-object
                            chips
                            deletable-chips
                            small-chips
                            label="Select Warehouse"
                            class="small"
                        ></v-autocomplete>
                        <span v-for="errors in errors.warehouse_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                        <v-col lg="3" id="date_from">

                                <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date_from"
                                        readonly
                                        outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            <span v-for="errors in errors.date_from" class="text-warning" :key="errors">{{
                                errors
                            }}</span>
                            </v-col>
                            <v-col lg="3" id="date_to">

                                <v-menu
                                v-model="menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date_to"
                                        readonly
                                        outlined
                                    dense
                                    background-color="white"
                                    label="Date To"
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            <span v-for="errors in errors.date_to" class="text-warning" :key="errors">{{
                                errors
                            }}</span>
                        </v-col>
                    </v-row>

                    <div class="w-100"  >
                        <v-card v-show="loaded">

                            <v-container fluid>
                                <div class="container" v-for="(data, i) in data_handler" :key="i" v-show="loaded">
                                    <div class="row">
                                        <div class="col-12 d-flex no-block align-items-center">
                                            <h6 class="report-title">{{ data.every_date }}</h6>
                                        </div>
                                    </div>

                                    <v-app id="my_vue_app">
                                        <v-card>
                                            <v-container fluid>
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="data.data_items"
                                                    :items-per-page="10"
                                                    class="w-100"
                                                    dense
                                                    disable-sort
                                                >
                                                </v-data-table>
                                            </v-container>
                                        </v-card>
                                    </v-app>
                                </div>
                            </v-container>
                        </v-card>
                    </div>

    </v-container>
</v-container>


</template>

<script>
import moment from 'moment';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
const ExcelJS = require("exceljs");

export default {
  mixins:[SharedFunctionsComponentVue],
    data(){
        return {
            errors: [],
            loaded: true,

            items_list: [],
            item_ids: [],

            item_warehouse_list:[],
            item_warehouse_ids: [],

            item_type_list: [],
            item_type_ids: [],

            category_list:[],
            category_ids:[],

            data_handler:[],

            row: null,

            date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
            date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
            menu2: false,
            menu3: false,

            options: {},
            items: [],
            itemsPerPageOptions: [5, 10 ,15, 50, 100, 250, 500, 1000],
            itemsPerPage: 10,
            headers: [
                { text: 'Model', width:"10%", align: 'left', value: 'model', },
                { text: 'Name', width:"30%", align: 'left', value: 'name', },
                { text: 'Type', width:"5%",align: 'left', value: 'type', },
                { text: 'Category', align: 'left', value: 'category', },
                { text: 'Doc #', width:"12%", align: 'left', value: 'transact_num', },
                { text: 'Doc Date', width:"10%", align: 'left', value: 'transact_date', },
                { text: 'Warehouse', width:"20%", align: 'left', value: 'warehouse', },
                { text: 'Qty In', width:"6%", align: 'left', value: 'in_qty_number', },
                { text: 'Qty Out', width:"6%", align: 'left', value: 'out_qty_number', },
                { text: 'Created At', width:"10%", align: 'right', value: 'created_at', },
            ],

            initialLoad: false,

            loading:{
                item_selection_default      : true,
                item_type_selection         : true,
                category_selection          : true,
                warehouse_selection         : true
            }


        }
    },
    components: {
        ComponentHeader
    },
    mounted: function (){
        this.$store.dispatch('getAllitemSelectionDefault')
        this.$store.dispatch('getAllItemCategories');
        this.$store.dispatch('getItemTypes');
        this.$store.dispatch('getAllWarehouses');
    },
    computed:{
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ALL_ITEM_CATEGORIES',
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ALL_WAREHOUSES',
        ])
    },
    methods: {
        getDailyMovement(){
            this.loaded = false

            this.$store.dispatch('reports',{
                page:               this.options.page,
                itemsPerPage:       this.options.itemsPerPage,
                sortBy:             this.options.sortBy,
                sortDesc:           this.options.sortDesc,
                item_id:            this.item_ids.map(e=>{return e.value}),
                warehouse_id:       this.item_warehouse_ids.map(e=>{return e.value}),
                category_id:        this.category_ids.map(e=>{return e.id}),
                type_id:            this.item_type_ids.map(e=>{return e.value}),
                date_from:          this.date_from,
                date_to:            this.date_to,
                url: 'getDailyMovement'
            }).then(response => {
                this.data_handler   = response.data
                this.loaded         = true
                this.errors         = []
                this.initialLoad = true

            }).catch(error => {
                if(error.response.status == 422){
                    this.errors = error.response.data.errors
                }
            })
        },
        exportDailyMovement(){
          let that = this

          this.loaded = false
          this.$store.dispatch('reports',{
              page:               this.options.page,
              itemsPerPage:       this.options.itemsPerPage,
              sortBy:             this.options.sortBy,
              sortDesc:           this.options.sortDesc,
              item_id:            this.item_ids.map(e=>{return e.value}),
              warehouse_id:       this.item_warehouse_ids.map(e=>{return e.value}),
              category_id:        this.category_ids.map(e=>{return e.id}),
              type_id:            this.item_type_ids.map(e=>{return e.value}),
              date_from:          this.date_from,
              date_to:            this.date_to,
              exportToExcel:      true,
              url:                'getDailyMovement',

            }).then(response => {
                let workbook = new ExcelJS.Workbook()
                let worksheet = [];
                response.data.forEach(e=>{
                    worksheet[e.every_date] = workbook.addWorksheet(e.every_date)
                    if(e.data_items){
                        let headers = this.ConverToExcelJsColumns(e.data_items)
                        worksheet[e.every_date].columns = headers
                    }
                    e.data_items.forEach(element=>{
                        worksheet[e.every_date].addRow(element)
                    })
                })

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    saveAs(blob, `Daily-Movement-Report ${that.date_from}-${that.date_to}.xlsx`);
                });
                this.initialLoad = true
                this.loaded = true
            }).catch(error => {
              this.loaded = true
              console.log(error)
            })
        },

        resetFields(){
            this.errors                 = []
            this.item_ids               = []
            this.item_warehouse_ids     = []
            this.category_ids           = []
            this.item_type_ids          = []
            this.date_from              = moment().clone().startOf('month').format('YYYY-MM-DD')
            this.date_to                = moment().clone().endOf('month').format('YYYY-MM-DD')
            this.data_handler           = []
        },
    },
    watch:{
      'GET_ITEM_TYPES':{
          handler(val){
              this.item_type_list = val.data
              this.loading.item_type_selection = false
          }
        },
      'GET_ALL_ITEM_CATEGORIES':{
          handler(val){
              this.category_list = val
              this.loading.category_selection = false
          }
      },
      'GET_ITEM_SELECTION_DEFAULT':{
          handler(val){
              this.items_list = val
              this.loading.item_selection_default = false
          }
      },
      'GET_ALL_WAREHOUSES':{
          handler(val){
              this.item_warehouse_list = val
              this.loading.warehouse_selection = false
          }
      },
    }
};
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
