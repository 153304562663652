<template>
    <v-card-title>
        <v-row no-gutters>
            <v-col cols="12" sm="8" md="10" lg="10" xl="10">
                <h3 class="page-title" style="color: black">{{ title }}</h3>
            </v-col>
            <v-col cols="12" sm="4" md="2" lg="2" xl="2" v-if="!['xs'].includes($vuetify.breakpoint.name)">
                <v-autocomplete
                    v-if="!!type"
                    v-model="selected"
                    :items="$store.state.SalesReports.reports[type]"
                    :label="type+' Reports'"
                    background-color="white"
                    item-text="title"
                    item-value="route"
                    outlined
                    dense
                    @input="selectedReport(selected)"
                ></v-autocomplete>
            </v-col>
        </v-row>
    </v-card-title>
</template>
<script>
export default {
    props:{
        button: {
            default: false
        },
        title: String,
        type: {
            default: '',
            type: String,
        },
    },
    data(){
        return{
            selected: '',
        }
    },
    methods:{
        selectedReport(route){
            this.$router.push({
                name: route
            })
        }
    },
}
</script>
<style lang="scss" scoped>
</style>
