<template>
    <v-container class="container">
        <v-row class="ma-2">
            <v-col class="d-flex no-block align-items-center">
                <h4 class="page-title" style="color: black">Accounting Users</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-4 mb-2" v-if="userAccess.create">
                <v-btn data-modal-id="addStatementOfAccountModal" v-on:click="showAddDialog()">
                    <span>
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </span>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog.add_edit_dialog" persistent max-width="50%" scrollable class="pa-4">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Manage User</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="dialog.add_edit_dialog = false; resetFields();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-card-text v-if="dialog.loaded">
                    <v-row class="mx-0 mt-2">
                        <v-col class="col-12">
                            <v-card class="mx-2 pa-4">
                                <v-autocomplete
                                    :items="action == 'add' ? filterUserSelection : selection.users.items"
                                    v-model="selection.users.selected"
                                    item-value="value"
                                    item-text="text"
                                    dense
                                    outlined
                                    label="Select User"
                                    clearable
                                    :disabled="action == 'edit' ? true : false"
                                    @change="removeSelectedUser(selection.users.selected)"
                                >
                                <template #append >
                                    <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                </template>
                                </v-autocomplete>
                                <v-autocomplete
                                    class="mt-2"
                                    :items="action == 'add' ? selection.head.items : selection.users.items"
                                    v-model="selection.head.selected"
                                    item-value="value"
                                    item-text="text"
                                    dense
                                    outlined
                                    label="Assign Head"
                                    clearable
                                    :disabled="!selection.users.selected"
                                    hide-details
                                >
                                <template #append >
                                    <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                </template>
                                </v-autocomplete>
                                <v-row class="mx-0 mt-0">
                                    <v-col class="col-4">
                                        <v-autocomplete class="mt-2"
                                            :items="selection.classifications.items"
                                            v-model="selection.classifications.selected"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            label="Classifications"
                                            clearable
                                            :disabled="!selection.users.selected"
                                            @change="filterByClassifications()"
                                            @click:clear="clearFields('add_classification')"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-8">
                                        <v-autocomplete class="mt-2"
                                            style="max-height:200px; overflow-y: auto;"
                                            multiple
                                            :items="action == 'add' && hasValue == 0? selection.customers.items : editFilterCustomer"
                                            v-model="selection.customers.selected"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            label="Assign Customer"
                                            clearable
                                            :disabled="!selection.users.selected"
                                            return-object
                                            small-chips
                                            @change="triggerSelectAll()"
                                            deletable-chips
                                            @click:clear="clearFields('add_customer')"
                                        >
                                        <template #append >
                                            <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-checkbox
                                    class=""
                                    v-model="isTeamLeader"
                                    :label="'Team Leader'"
                                    color="blue"
                                    hide-details
                                >
                                </v-checkbox>
                                <v-card-actions class="d-flex justify-end mt-2">
                                    <v-btn
                                        small
                                        @click="action == 'add' ? addToList() : editList()"
                                        :disabled="!selection.customers.selected"
                                    >
                                    Add
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <v-alert v-if="alert.isSuccessfullyAdded"
                                dense
                                text
                                class="mt-2 mx-2"
                                :type="alert.type"
                                transition="scale-transition">
                                    {{alert.msg}}
                            </v-alert>
                            <v-card v-if="action == 'add'" class="mt-2 mx-2" style="overflow-y: auto">
                                <v-card-subtitle>Customer's List
                                    <v-btn v-if="action == 'add' && hasValue == 1" text small color="red" @click="clearFields('clear_list');">Clear</v-btn>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="search" dense label="Search" class="mb-2" style="float:right"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                                <v-data-table
                                    :headers="headers"
                                    :items="customersList"
                                    :search="search"
                                    :items-per-page="10"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                    }"
                                    dense
                                    class="w-100"
                                >
                                </v-data-table>
                            </v-card>
                            <v-card v-else class="mt-2 mx-2" style="overflow-y: auto">
                                <v-card-subtitle>Customer's List
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="search" dense label="Search" class="mb-2" style="float:right"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                                <v-data-table
                                    :headers="headers"
                                    :items="customersList"
                                    :search="search"
                                    :items-per-page="10"
                                    :footer-props="{
                                        showFirstLastPage: true,
                                    }"
                                    dense
                                    class="w-100"
                                >
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn text icon color="red" >
                                            <v-icon class="btn-action" small @click="deleteCustomer(item.value)">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                        <!-- <v-col class="col-6">
                            <v-card class="mx-2 pa-4">
                                <v-autocomplete
                                    :items="action == 'add' ? selection.head.items : selection.users.items"
                                    v-model="selection.head.selected"
                                    item-value="value"
                                    item-text="text"
                                    dense
                                    outlined
                                    label="Assign Head"
                                    clearable
                                    :disabled="!selection.users.selected"
                                >
                                <template #append >
                                    <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                </template>
                                </v-autocomplete>
                            </v-card>
                        </v-col> -->
                    </v-row>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text-center">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        color="secondary"
                        text
                        @click="dialog.add_edit_dialog = false; resetFields();"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        :disabled="!selection.head.selected || !selection.users.selected"
                        @click="action == 'add' ? addNewData() : updateData()"
                    >
                        {{action == "add" ? "Submit" : "Update"}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row class="mt-5">
            <v-col class="col-12">
                <v-card class="mx-4">
                    <v-row class="pt-2 pl-5">
                        <v-col class="col-2">
                            <v-autocomplete
                                :items="selection.users.items"
                                v-model="searchUserSelection"
                                item-value="value"
                                item-text="text"
                                dense
                                outlined
                                label="Select User"
                                clearable
                                @click:clear="clearFields('user')"
                            />
                        </v-col>
                        <v-col class="col-2">
                            <v-autocomplete
                                :items="selection.users.items"
                                v-model="searchHeadSelection"
                                item-value="value"
                                item-text="text"
                                dense
                                outlined
                                label="Select Head"
                                clearable
                                @click:clear="clearFields('head')"
                            />
                        </v-col>
                        <v-col>
                            <v-btn text @click="clearFilter()">Clear</v-btn>
                            <v-btn class="mx-4" style="float:right" @click="exportToExcel()">Export</v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="pl-5 pb-5">
                        <v-col class="col-4">
                            <v-autocomplete
                                :items="selection.customers.items"
                                v-model="searchCustomerSelection"
                                item-value="value"
                                item-text="text"
                                dense
                                outlined
                                label="Select Customer"
                                clearable
                                @click:clear="clearFields('customer')"
                                />
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="mx-4 pt-5">
                    <v-data-table
                        v-model="dataTable"
                        :headers="accountUserList.datatable.headers"
                        :items="accountUserList.data"
                        :loading="!accountUserList.datatable.loaded"
                        :options.sync="options"
                        :items-per-page="itemsPerPage"
                        :server-items-length="accountUserList.datatable.total"
                        :footer-props="{
                            showFirstLastPage: true,
                        }"
                        class="w-100"
                    >
                    <template v-slot:[`item.head_name`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.head_name == null ? "N/A" : item.head_name}}
                        </span>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange"  v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="viewUser(item.id,item.head_id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit">
                                <v-icon class="btn-action" small  @click="editUser(item.id,item.head_id)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete">
                                <v-icon class="btn-action" small  @click="deleteData(item.id,item.name)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog.viewUsers" persistent max-width="75%" scrollable class="pa-4">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Information</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="dialog.viewUsers = false; resetFields();">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-card-text v-if="dialog.loaded">
                    <v-row class="mt-1 ml-2">
                        <v-col>
                            <span>
                                <span class="font-weight-bold">Name:</span>
                                {{selectedUser.name}}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-1 ml-2">
                        <v-col>
                            <span>
                                <span class="font-weight-bold">Position:</span>
                                {{selectedUser.job_title}}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 mt-3">
                        <v-card class="mt-2 mx-2 w-100">
                            <v-card-subtitle>Member's List Total: {{acctManagementMembers.length > 0 ? acctManagementMembers.length : 0}} </v-card-subtitle>
                            <v-chip v-for="(item,i) in acctManagementMembers" :key=i
                                class="ml-2 mr-2 mt-1 mb-1"
                                :color="memberChipColor"
                                text-color="white"
                                small
                                >
                                {{item}}
                            </v-chip>
                        </v-card>
                    </v-row>
                    <v-row class="mx-0 mt-2">
                        <v-card class="mt-2 mx-2 w-100 mb-2">
                            <v-card-subtitle>Customer's List Total: {{acctManagement.length}} </v-card-subtitle>
                                <v-row class="mx-2">
                                    <v-col>
                                        <v-text-field v-model="chipSearch" dense label="Search" class="mb-2" style="float:left"></v-text-field>
                                    </v-col>
                                </v-row>
                                <div class="pa-2" style="column-count:4">
                                    <div v-for="(item,i) in searching" :key=i>
                                        {{item}}
                                    </div>
                                </div>
                        </v-card>
                    </v-row>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text-center">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
           action:'',
           dialog:{
                add_edit_dialog:false,
                viewUsers:false,
                loaded:false
           },
           selection:{
                users:{
                    items:[],
                    selected:'',
                },
                customers:{
                    items:[],
                    selected:'',
                },
                head:{
                    items:[],
                    selected:'',
                },
                classifications:{
                    items:[],
                    selected:'',
                },
           },
           headers:[
                {text: 'Customers',value:'text'},
           ],
           action_header: { text: "Action", align: "center", value: "action", sortable: false },
           dataTable:[],
           accountUserList:{
                datatable:{
                    search:'',
                    headers: [
                        {text: 'USER', value: 'name'},
                        {text: 'POSITION', value: 'job_title'},
                        {text: 'HEAD', value: 'head_name'},
                        {text: 'ACTIONS', align: 'center', value: 'action'},
                    ],
                    loaded:true,
                    total:0,
                },
                data:[],
                existingUser:[],
           },
            options:{},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            viewUserAccess:[],
            acctManagement:[],
            selectedUser:[],
            customersList:[],
            acctManagementMembers:[],
            memberChipColor:"green",
            searchUserSelection:'',
            searchCustomerSelection:'',
            searchHeadSelection:'',
            userAccess:{
                view:false,
                edit:false,
                create:false,
                delete:false
            },
            searchInArrayField:'',
            search:'',
            chipSearch:'',
            hasValue:0,
            alert:{
                isSuccessfullyAdded:false,
                msg:'',
                type:'',
            },
            displayHeaderOnce:false,
            isTeamLeader:0
        }
    },
    async mounted() {
        await this.getAllData()
        await this.getCustomers()
        this.getAccountingUsers()
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods:{
        showAddDialog(){
            this.dialog.add_edit_dialog = true
            this.action = 'add'
            this.dialog.loaded = true
            this.getCustomers()
            this.getAccountingUsers()
            this.getClassifications()
        },
        getClassifications(){
            this.$store.dispatch("getClassification")
        },
        getAccountingUsers(){
            this.$store.dispatch('getAccountingUsers').then(response=>{
                this.selection.users.items = response
            })
        },
        getCustomers(){
            this.$store.dispatch('getAllCustomers').then(response=>{
                // console.log(response.data)
                // this.selection.customers.items = response.data
                // this.selection.customers.items.unshift({
                //     value: 'all',
                //     text: 'SELECT ALL'
                // })
            })
        },
        removeSelectedUser(user_id){
           this.selection.head.items = this.selection.users.items.filter(e=>{
                return e.value != user_id
           })
           this.selection.head.items.unshift({
                value:0,
                text:"N/A",
           })
        },
        addNewData(){
            let payload = {
                user_id: this.selection.users.selected,
                customer_id: this.customersList.map(e=>{return e.value}),
                head_id: this.selection.head.selected,
                team_head: this.isTeamLeader,
                url:'addNewData'
            }
            this.$store.dispatch('postAU',payload).then(response=>{
                swal.fire(response.data.icon.toUpperCase(),response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.dialog.add_edit_dialog = false
                }
                this.resetFields();
                this.getAllData();
                this.getCustomers();
            })
        },
        resetFields(){
            this.selection.users.selected = ''
            this.selection.customers.selected = ''
            this.selection.head.selected = ''
            this.selection.classifications.selected = ''
            this.customersList = []
        },
        getAllData(){
            this.accountUserList.datatable.loaded = false
            this.accountUserList.data = [];
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                user_id: this.searchUserSelection,
                customer_id: this.searchCustomerSelection,
                head_id: this.searchHeadSelection,
                url: 'getAllData'
            }
            this.$store.dispatch('getAU',payload).then(response => {
                this.accountUserList.data = response.data.data.data
                this.accountUserList.datatable.loaded = true
                this.accountUserList.datatable.total = response.data.data.total
                this.accountUserList.existingUser = response.data.all_user
            }).catch(error => {
                console.log(error)
            })
        },
        viewUser(id,head_id){
            this.dialog.viewUsers = true;
            this.dialog.loaded = false;
            let payload = {
                head_id: id,
                active_id: head_id,
                url: 'viewUsers'
            }
            this.$store.dispatch('getAU',payload).then(response=>{
                let data = response.data.under_user

                this.acctManagement = data.map(e=>{
                    return e.company_name;
                })
                this.acctManagement = _.uniqBy(this.acctManagement)
                this.acctManagementMembers = data.map(e=>{
                    return e.name;
                })
            })
            let user_payload = {
                head_id: id,
                url: 'getSelectedUser'
            }
            this.$store.dispatch('getAU',user_payload).then(response=>{
                this.selectedUser = response.data[0];
                this.acctManagementMembers = this.acctManagementMembers.filter(e=>{
                    return e != this.selectedUser.name;
                })
                this.acctManagementMembers = _.uniqBy(this.acctManagementMembers)

                if(this.acctManagementMembers.length == 0){
                    this.acctManagementMembers.length = 0;
                    this.memberChipColor = "grey"
                    this.acctManagementMembers = ["You dont have any members yet"]
                }
                else{
                    this.memberChipColor = "green"
                }
                this.dialog.loaded = true;
            })
        },
        removeChip(item){
            if(!!item){
                this.customersList = this.customersList.filter(e=>{
                    return e != item
                })
            }
            if(this.action == "edit"){
                this.selection.customers.selected = ''
                this.editFilterCustomer.push(item)
            }
            else{
                this.selection.customers.items.unshift(item)
            }
        },
        editUser(id,head_id,acct_user_id){
            if(!this.displayHeaderOnce){
                this.headers.splice(1, 0, this.action_header)
                this.displayHeaderOnce = true;
            }
            this.showAddDialog();
            this.action = 'edit'
            this.selection.users.selected = id
            this.selection.head.selected = head_id
            this.dialog.loaded = false
            let payload = {
                head_id: id,
                active_id: head_id,
                url: 'viewUsers',
                method:'edit'
            }
            this.$store.dispatch('getAU',payload).then(response=>{
                let data = response.data.under_user
                this.isTeamLeader = data[0].team_head;
                this.customersList = data.map(e=>{
                    return {'text':e.company_name,'value':e.cust_id}
                })
                this.dialog.loaded = true
            })
        },
        updateData(){
            let payload = {
                user_id: this.selection.users.selected,
                customer_id: this.customersList.map(e=>{return e.value}),
                head_id: this.selection.head.selected,
                team_head: this.isTeamLeader,
                url:'updateData'
            }
            this.$store.dispatch('postAU',payload).then(response=>{
                swal.fire(response.data.icon.toUpperCase(),response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.dialog.add_edit_dialog = false
                }
                this.resetFields();
                this.getAllData();
                this.getCustomers();
            })
        },
        deleteData(id,name){
            swal.fire({
                title: "Delete",
                text: `Are you sure you want to delete ${name}'s access ?`,
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        user_id: id,
                        url: 'deleteData'
                    }
                    this.$store.dispatch('postAU',payload).then(response => {
                        this.getAllData();
                        swal.fire('',"Delete Successful!","success");
                    }).catch(error => {
                        swal.fire("An Error Occured", error, "error");
                    })
                }
            });
        },
        clearFields(dropdown) {
            if(dropdown == "user"){
                this.searchUserSelection = ''
            }
            else if(dropdown == "customer"){
                this.searchCustomerSelection = ''
            }
            else if(dropdown == "head"){
                this.searchHeadSelection = ''
            }
            else if(dropdown == "add_customer"){
                this.selection.customers.items.unshift({
                    value: 'all',
                    text: 'SELECT ALL'
                })
                this.selection.customers.selected = []
            }
            else if(dropdown == "add_classification"){
                this.selection.classifications.selected = ''
                this.filterByClassifications();
            }
            else if(dropdown == "clear_list"){
                this.hasValue = 0
                this.customersList = []
            }
            this.getAllData();
        },
        clearFilter(){
            this.searchUserSelection = ''
            this.searchCustomerSelection = ''
            this.searchHeadSelection = ''
            this.getAllData()
        },
        filterByClassifications(){
            let payload = {
                classification_id:this.selection.classifications.selected != '' ? this.selection.classifications.selected : '' ,
                url:'filterCustomerByClassifications'
            }
            this.$store.dispatch('getAU',payload).then(response=>{
                this.selection.customers.items = response.data
                this.selection.customers.items.unshift({
                    value: 'all',
                    text: 'SELECT ALL'
                })
            })
        },
        triggerSelectAll(){
            let temp_id = this.selection.customers.selected.map(e=>e.value);
            if(temp_id.includes('all')){
                this.selection.customers.items.shift();
                if(this.customersList.length == 0){
                    this.selection.customers.selected = this.selection.customers.items
                }
                else{
                    this.selection.customers.selected = this.editFilterCustomer
                }
            }
        },
        deleteCustomer(id){
            this.customersList= this.customersList.filter(e=> e.value != id);
        },
        addToList(){
            if(this.customersList.length == 0){
                this.customersList = this.selection.customers.selected
                this.selection.customers.items = this.editFilterCustomer
                this.hasValue = 1
            }
            else{
                this.customersList.push(...this.selection.customers.selected)
            }
            if(this.selection.customers.selected.length > 0){
                this.triggerAlert('Added Successfully','success');
            }
            else{
                this.triggerAlert('Please select customer','warning');
            }
            this.selection.customers.selected = []
        },
        editList(){
            this.customersList.push(...this.selection.customers.selected);
            this.customersList = _.uniqBy(this.customersList);
            this.triggerAlert();
        },
        triggerAlert(msg,type){
            this.alert.isSuccessfullyAdded = true
            this.alert.msg = msg
            this.alert.type = type
            setTimeout(() => {
                this.alert.isSuccessfullyAdded = false;
            }, 2000);
        },
        async exportToExcel(){
                let that = this
                const ExcelJS = require('exceljs')
                const workbook = new ExcelJS.Workbook();
                let temp = [];
                return new Promise((resolve, reject) => {
                    this.accountUserList.data.forEach(async (e,i)=>{
                        this.accountUserList.datatable.loaded = false;
                        let payload = {
                            head_id: e.id,
                            active_id: e.head_id,
                            url: 'viewUsers'
                        }
                        await this.$store.dispatch('getAU',payload).then(response=>{
                            temp.push(...response.data.under_user)
                        })
                        if(this.accountUserList.data.length - 1 == i) resolve()
                    })
                }).then(res=>{
                    const worksheet = workbook.addWorksheet('Raw Data')
                    // let filterUserAndId = temp.map(e=>{
                    //     return {name:e.name,id:e.id};
                    // })

                    // filterUserAndId = filterUserAndId.map(JSON.stringify);
                    // let uniqueSet = new Set(filterUserAndId);
                    // let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

                    // let columns = uniqueArray.map(e=>{
                    //     return {header:e.name,key:e.id}
                    // })

                    worksheet.columns = [
                        {header:'USER',key:'name'},
                        {header:'HEAD',key:'head_name'},
                        {header:'CUSTOMER NAME',key:'company_name'},
                    ]
                    temp.forEach(e => {
                        worksheet.addRow(e);
                    });
                    worksheet.getRow(`0${1}`).font = { bold: true }
                    this.accountUserList.datatable.loaded = true;

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        saveAs(blob, `Accounting User.xlsx`);
                    });
                })

            },
    },
    computed:{
        filterUserSelection(){
            let temp = []
            this.selection.users.items.forEach(e=>{
                if (!this.accountUserList.existingUser.includes(e.value)){
                    temp.push(e)
                }
            })
            return temp;
        },
        editFilterCustomer(){
            let existingCustomer = this.customersList.map(e=>{
                return Number(e.value)
            })
            let temp = []
            this.selection.customers.items.forEach(e=>{
                if (!existingCustomer.includes(e.value)){
                    temp.push(e)
                }
            })
            return temp;
        },
        searching () {
            if (!this.chipSearch) return this.acctManagement
            const search = this.chipSearch.toLowerCase()
            return this.acctManagement.filter(item => {
                const text = item.toLowerCase()
                return text.indexOf(search) > -1
            })
        },
        ...mapGetters([
            'USER_ACCESS',
            'GET_CLASSIFICATIONS_DROPDOWN',
            'GET_CUSTOMERS_LIST_SELECTION'
        ]),
    },
    watch:{
        options: {
            handler() {
                this.getAllData()
            },
            deep: true,
        },
        searchUserSelection() {
            if(!!this.searchUserSelection) {
                this.getAllData();
            } else {
                this.searchUserSelection = '';
            }
        },
        searchCustomerSelection() {
            if(!!this.searchCustomerSelection) {
                this.getAllData();
            } else {
                this.searchCustomerSelection = '';
            }
        },
        searchHeadSelection() {
            if(!!this.searchHeadSelection) {
                this.getAllData();
            } else {
                this.searchHeadSelection = '';
            }
        },
        GET_CUSTOMERS_LIST_SELECTION:{
            handler(val){
                // this.loading.customer_selection = false
                this.selection.customers.items
            },
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                    });
                }
            }
        },
        GET_CLASSIFICATIONS_DROPDOWN:{
            handler(val){
                this.selection.classifications.items = val.data
            }
        }
    }
};
</script>
