import ItStockAdjustment from '@/views/main/modules/IT/StockAdjustmentGroupComponent.vue'
import repository from '@/api/credentials'

const routes = [{
    path: '/it/stock-adjustment/:id',
    name: 'itstockadjustment',
    component: ItStockAdjustment,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Stock Adjustment',
    },
}]

export default routes;
