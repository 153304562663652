<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title" style="color: black">Statement of Accounts</h3>
        <v-container class="ma-0">
            <v-row class="mx-1">
                <v-col cols="3">
                    <div class="btn-toolbar">
                        <v-btn
                            v-if="userAccess.create"
                            data-modal-id="addStatementOfAccountModal"
                            class="ml-1 mb-2"
                            @click="showAddDialog()"
                        >
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" class="d-flex justify-content-end">
                    <v-btn-toggle style="font-size: 9px" dark mandatory>
                        <v-btn @click="getSelectedMode('year')"> Year </v-btn>
                        <v-btn @click="getSelectedMode('months')"> Month </v-btn>
                        <v-btn @click="getSelectedMode('week')"> Week </v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle style="font-size: 9px" dark>
                        <v-btn dark v-if="mode == 'year'" class="pa-0">
                            <v-autocomplete
                                v-model="year_range.start"
                                :items="yearRange"
                                class="text-field-width-year"
                                color="white"
                                label="Start"
                                dense
                                outlined
                                hide-details
                                @change="filterRange()"
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="year_range.end"
                                :items="yearRange"
                                class="text-field-width-year"
                                color="white"
                                label="End"
                                dense
                                outlined
                                hide-details
                                @change="filterRange()"
                            ></v-autocomplete>
                        </v-btn>
                        <v-btn dark v-if="mode == 'months'" class="pa-0">
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                min-width="290"
                                transition="scale-transition"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="selectedRange.from"
                                        v-on="on"
                                        append-icon="mdi-calendar-clock"
                                        class="text-field-width-year"
                                        color="white"
                                        label="Start Date"
                                        dense
                                        readonly
                                        solo
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="selectedRange.from"
                                    type="month"
                                    @change="filterRange()"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                color="white"
                                min-width="290px"
                                transition="scale-transition"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="selectedRange.to"
                                        v-on="on"
                                        append-icon="mdi-calendar-clock"
                                        class="text-field-width-year"
                                        color="white"
                                        label="End Date"
                                        dense
                                        readonly
                                        solo
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="selectedRange.to"
                                    type="month"
                                    @change="filterRange()"
                                ></v-date-picker>
                            </v-menu>
                        </v-btn>
                        <v-btn dark v-if="mode == 'week'" class="pa-0">
                            <v-autocomplete
                                v-model="week.year"
                                :items="yearRange"
                                class="text-field-width-year"
                                color="white"
                                label="Year"
                                dense
                                outlined
                                hide-details
                                @change="getNumberOfWeeks(week.year);"
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="week.start"
                                :items="week_range"
                                class="text-field-width-year"
                                color="white"
                                label="Week"
                                dense
                                outlined
                                hide-details
                            ></v-autocomplete>
                        </v-btn>
                        <v-btn
                            v-if="mode == 'week'"
                            height="100%"
                            dark
                            text
                            @click="filterRange()"
                        >
                            Filter
                        </v-btn>
                        <!-- :disabled="(!selectedRange.from || !selectedRange.to) && (!year_range.start || !year_range.end)" -->
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row class="mx-1">
                <v-col cols="12">
                    <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" show-arrows>
                        <v-tab
                            v-for="stats in statuses"
                            :key="stats.value"
                            ripple
                            @click="filterRange(false, stats)"
                        >
                            {{ stats.status_text }}
                        </v-tab>
                        <v-spacer></v-spacer>
                        <!-- <v-tab ripple @click="isPerPL = true">
                            Per PL
                        </v-tab> -->
                    </v-tabs>
                    <StatementOfAccountComponent
                        v-if="!isPerPL"
                        :filtered_year="date.year"
                        :filtered_month="filters.filtered_month"
                        :status_value="filters.status_value"
                        :status_text="filters.status_text"
                        :months_selection="months_selection"
                        :date_from="dateFrom"
                        :date_to="dateTo"
                        :week_count="weekCount"
                        ref="child"
                    ></StatementOfAccountComponent>
                    <!-- <PackingListsComponent
                        v-if="isPerPL"
                        :fromSoa="true"
                        :is_stockcard="0"
                    ></PackingListsComponent> -->
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import StatementOfAccountComponent from './StatementOfAccountsComponent.vue'
import PackingListsComponent from '../../Sales/PackingList/PackingListsComponent.vue';
import moment from 'moment'
import { mapGetters } from 'vuex';

export default {
    components: {
        StatementOfAccountComponent,
        PackingListsComponent
    },
    data() {
        return {
            tab: null,
            userAccess: {
                create: false,
            },
            componentKey: 0,
            date_menu: [],
            year_selected: new Date().getFullYear() + "-" + new Date().getMonth(),
            date: {
                month: '',
                year: new Date().getFullYear()
            },
            month_tab: null,
            year_tab: null,
            month_tabs: null,
            activeSubTab: 'year',
            yearRange: [],
            yearCountBackward: 5,
            yearCountForward: 1,
            filters: {
                filtered_year: moment().year(),
                filtered_month: 0,
                status_value: 10,
                status_text: "all"
            },
            rangeSelectDialog: false,
            months_selection: [
                { value: 0, name: "All" },
                { value: 1, name: "January" },
                { value: 2, name: "Febuary" },
                { value: 3, name: "March" },
                { value: 4, name: "April" },
                { value: 5, name: "May" },
                { value: 6, name: "June" },
                { value: 7, name: "July" },
                { value: 8, name: "August" },
                { value: 9, name: "September" },
                { value: 10, name: "October" },
                { value: 11, name: "November" },
                { value: 12, name: "December" },
            ],
            statuses: {
                ALL: { value: 0, status: 10, status_text: "all" },
                SAVED: { value: 1, status: 0, status_text: "saved" },
                UNPAID: { value: 2, status: 5, status_text: "unpaid" },
                // PARTIAL_O: { value:3, status:6, status_text:"partial (o)" },
                PARTIAL_U: { value: 6, status: 9, status_text: "partial (u)" },
                PAID: { value: 4, status: 7, status_text: "paid" },
                OVERDUE: { value: 5, status: 8, status_text: "overdue" },
                OVERPAYMENT: { value: 7, status: 11, status_text: "overpayment" },
            },
            selectedRange: {
                from: moment().startOf('year').format('YYYY-MM'),
                to: moment().endOf('year').format('YYYY-MM')
            },
            year_range: {
                start: moment().year(),
                end: moment().year(),
            },
            mode: 'year',
            week: {
                year: moment().year(),
                start: moment().weekYear(),
                end: 0
            },
            week_range: [],
            isPerPL: false,

            dateFrom: null,
            dateTo: null,
            weekCount: '',
        }
    },
    async mounted() {
        await this.checkAccess();
        await this.getYearRange();
        await this.getCustomers();

        this.dateFrom = new Date().getFullYear() + '-01'
        this.dateTo = new Date().getFullYear() + '-12'
    },
    computed: {
        ...mapGetters([
            'USERACCOUNT_LOAD'
        ]),
    },
    methods: {
        async checkAccess(dep, mod, axn) {
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response => {
                let val = response;
                this.userAccess.create = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            })
        },
        showAddDialog() {
            this.$refs.child.showAddStatementOfAccount();
        },
        getYear() {
            this.date.year = Number(this.year_selected.split('-')[0]);
            this.componentKey++;
        },
        activateSubTab(tab) {
            this.activeSubTab = tab
            if (tab == 'year') {
                this.filters.filtered_month = 0
                this.filters.status_text = 'all'
            }
        },
        async getYearRange() {
            let yearMin = this.date.year - this.yearCountBackward;
            let yearMax = this.date.year + this.yearCountForward;
            for (let i = yearMin; i <= yearMax; i++) {
                this.yearRange.push(i)
            }
            this.filters.filtered_year = yearMin
        },
        openRangeSelectDialog() {
            this.rangeSelectDialog = true;
        },
        async filterRange(isPerPL = true, stats = null) {
            if (!isPerPL) {
                this.isPerPL = isPerPL
                this.filters.status_value = stats.status
                this.filters.status_text = stats.status_text
            }

            if (this.mode == 'months') {
                if (!!this.selectedRange.from && this.selectedRange.to) {
                    this.dateFrom = this.selectedRange.from
                    this.dateTo = this.selectedRange.to
                    this.weekCount = ''
                }
            }
            if (this.mode == 'year') {
                if (!!this.year_range.start && !!this.year_range.end) {
                    this.dateFrom = this.year_range.start + '-01'
                    this.dateTo = this.year_range.end + '-12'
                    this.weekCount = ''
                }
                else {
                    const date = new Date();
                    let year = date.getFullYear();

                    this.dateFrom = year + '-01'
                    this.dateTo = year + '-12'
                    this.weekCount = ''
                }
            }
            if (this.mode == 'week') {
                if (!!this.week.year && !!this.week.start) {
                    let week_count = this.getDateRangeOfWeek(this.week.start, this.week.year)

                    this.dateFrom = this.week.year
                    this.dateTo = ''
                    this.weekCount = week_count
                }
            }

            // this.$refs.child.reloadTable(this.dateFrom, this.dateTo, this.mode, this.weekCount)

            this.rangeSelectDialog = false;
        },
        getSelectedMode(mode) {
            this.mode = mode

            if (mode == 'week' && this.week.year != null) {
                this.getNumberOfWeeks(this.week.year)
                this.week.start = moment().week()
            }
        },
        getNumberOfWeeks(y) {
            this.week_range = [];
            for (let i = 1; i <= moment().weeksInYear(); i++) {
                this.week_range.push(i)
            }
        },
        getDateOfISOWeek(w, y) {
            let simple = new Date(y, 0, 1 + (w - 1) * 7);
            let dow = simple.getDay();
            let ISOweekStart = simple;
            if (dow <= 4)
                ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
            else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
            return ISOweekStart;
        },
        getDateRangeOfWeek(w, y) {
            let monthNames = [];
            this.months_selection.forEach((e) => {
                if (e.value != 0) monthNames.push(e.value);
            });
            let date = this.getDateOfISOWeek(w, y);
            let monthStart = monthNames[date.getMonth()];

            date.setDate(date.getDate() + 6);

            let monthEnd = monthNames[date.getMonth()];
            return monthStart == monthEnd ? monthStart : [monthStart, monthEnd];
        },
        async getCustomers() {
            let get_head_payload = {
                head_id: this.USERACCOUNT_LOAD.id,
                url: 'getSelectedUser'
            }
            await this.$store.dispatch('getAU', get_head_payload).then(async response => {
                let d = response.data
                d = d.forEach(e => {
                    this.head_id = e.head_id
                })
                let payload = {
                    head_id: this.USERACCOUNT_LOAD.id,
                    active_id: this.head_id,
                    url: 'viewUsers'
                }
                await this.$store.dispatch('getAU', payload).then(response => {
                    let data = response.data.under_user

                    let filteredCustomer = data.map(e => {
                        return { text: e.company_name, value: e.cust_id }
                    })
                    let head_ids = _.uniqBy(data, 'id')
                    head_ids.forEach(e => {
                        if (e.id == this.USERACCOUNT_LOAD.id) {
                            this.active_user_head_id.push(e.head_id)
                        }
                    })
                    this.customerList = _.orderBy(_.uniqBy(filteredCustomer, 'value'), ['text'], ['asc']);
                    this.customerListId = this.customerList.map(e => {
                        return e.value
                    });
                })
            })
        },
    },
};
</script>
<style scoped>
#group-tab {
    padding: 0 !important;
}

.text-field-width {
    width: 100px !important;
}

.text-field-width-year {
    width: 120px !important;
    margin: 5px 2px 0px 2px !important;
}
</style>
