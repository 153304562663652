<template>
    <v-container>
        <ComponentHeader
                :button="true"
                :title="'Item Sales Details'"
                :type="'Sales'"
        ></ComponentHeader>
        <v-row>
        <div class="container bg-bluish-gray default-container m-3">
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>

        <v-dialog v-model="loading.dialog" width="500">
            <v-card>
                <v-card-title >
                    <span style="font-size:12px">
                        Processing: {{ loading.text }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-progress-linear
                        v-model="loading.value"
                        :buffer-value="loading.bufferValue"
                        color="green"
                    >
                    </v-progress-linear>
                    {{Math.ceil(loading.value)}}%
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-app id="my_vue_app"  class="bg-bluish-gray">
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_CUSTOMERS_SELECTION"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        return-object
                        v-model="filters.customers.selected"
                        label="Select Customers"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-3"></div>
                <div class="col-sm-12 col-md-5 text-right float-right pull-right">
                <v-btn  @click="resetFields()" :loading="!loaded" class="mx-2">Clear</v-btn>
                    <!-- <v-btn  @click="exportToExcel()" :loading="!loaded">Excel</v-btn> -->

                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :loading="!loaded"
                            >
                                Export
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-card
                                    class="mx-auto"
                                    max-width="400"
                                >
                                    <v-card-text>
                                        <v-chip-group
                                            v-model="filters.selectedDetails"
                                            column
                                            multiple
                                        >
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SQ
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SO
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            WPF
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            CF
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SC
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            DR
                                            </v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-btn small @click="exportToExcel()" class="pull-right float-right mx-2">Export</v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :loading="!loaded"
                            >
                                filter
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-card
                                    class="mx-auto"
                                    max-width="400"
                                >
                                    <v-card-text>
                                        <v-chip-group
                                            v-model="filters.selectedDetails"
                                            column
                                            multiple
                                        >
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SQ
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SO
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            WPF
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            CF
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            SC
                                            </v-chip>
                                            <v-chip
                                                filter
                                                outlined
                                            >
                                            DR
                                            </v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-btn @click="list()" :loading="!loaded" class="pull-right float-right mx-2">Filter</v-btn>
                                    </v-card-text>
                                </v-card>

                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_TYPES"
                        :loading="loading.item_type_selection"
                        :disabled="loading.item_type_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        return-object
                        v-model="filters.types.selected"
                        label="Select Item Types"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_CATEGORIES"
                        :loading="loading.category_selection"
                        :disabled="loading.category_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        return-object
                        v-model="filters.categories.selected"
                        label="Select Categories"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-2 col-sm-12">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_ITEM_SELECTION_DEFAULT"
                        :loading="loading.item_selection"
                        :disabled="loading.item_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        return-object
                        v-model="filters.items.selected"
                        label="Select Models"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row">
                <div class="col-sm-4 col-md-2" id="date_from">
                    <label class="float-left control-label col-form-label">Date From</label>
                    <v-menu
                        v-model="filters.date.menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date.date_from" @input="filters.date.menu2 = false"></v-date-picker>
                    </v-menu>
                </div>

                <div class="col-sm-4 col-md-2" id="date_to">
                    <label class="float-left control-label col-form-label">Date To</label>
                    <v-menu
                        v-model="filters.date.menu3"
                        :close-on-content-click = "false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                        z-index="100"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date.date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date.date_to" @input="filters.date.menu3 = false"  style="z-index:100"></v-date-picker>
                    </v-menu>
                </div>
            </div>
            <div class="container bg-white p-3 d-flex flex-column justify-content-center" v-if="filter">
                <!-- <div class="row p-40 bg-white p-3" > -->
                    <!-- <h4>{{items.title}}</h4>
                    <v-data-table
                        :items="items.items"
                        :headers="items.headers"
                        class="w-100"
                        :loading="!items.loaded"
                    >
                    </v-data-table>
                </div> -->
                    <div class="row p-40 bg-white p-3">
                        <span v-if="filters.selectedDetails.includes(1)" class="w-100">
                            <h4>{{dataTable.headers[0].title}}</h4>
                            <v-data-table
                                :items="dataTable.headers[0].items"
                                :headers="dataTable.headers[0].headers"
                                class="w-100"
                                :loading="!dataTable.headers[0].loaded"
                            >
                            </v-data-table>
                        </span>

                        <span v-if="filters.selectedDetails.includes(0)" class="w-100">
                            <h4>{{dataTable.headers[1].title}}</h4>
                            <v-data-table
                                :items="dataTable.headers[1].items"
                                :headers="dataTable.headers[1].headers"
                                class="w-100"
                                :loading="!dataTable.headers[1].loaded"
                            >
                            </v-data-table>
                        </span>

                        <span v-if="filters.selectedDetails.includes(2)" class="w-100">
                            <h4>{{dataTable.headers[2].title}}</h4>
                            <v-data-table
                                :items="dataTable.headers[2].items"
                                :headers="dataTable.headers[2].headers"
                                class="w-100"
                                :loading="!dataTable.headers[2].loaded"
                            >
                            </v-data-table>
                        </span>

                        <span v-if="filters.selectedDetails.includes(3)" class="w-100">
                            <h4>{{dataTable.headers[3].title}}</h4>
                            <v-data-table
                                :items="dataTable.headers[3].items"
                                :headers="dataTable.headers[3].headers"
                                class="w-100"
                                :loading="!dataTable.headers[3].loaded"
                            >
                            </v-data-table>
                        </span>

                        <span v-if="filters.selectedDetails.includes(4)" class="w-100">
                            <h4>{{dataTable.headers[4].title}}</h4>
                            <v-data-table
                                :items="dataTable.headers[4].items"
                                :headers="dataTable.headers[4].headers"
                                class="w-100"
                                :loading="!dataTable.headers[4].loaded"
                            >
                            </v-data-table>
                        </span>
                        <span v-if="filters.selectedDetails.includes(5)" class="w-100">
                            <h4>{{dataTable.headers[5].title}}</h4>
                            <v-data-table
                                :items="dataTable.headers[5].items"
                                :headers="dataTable.headers[5].headers"
                                class="w-100"
                                :loading="!dataTable.headers[5].loaded"
                            >
                            </v-data-table>
                        </span>
                    </div>
            </div>
        </v-app>
    </div>

        </v-row>
    </v-container>

</template>

<script>
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    const ExcelJS = require('exceljs')
    import { saveAs } from 'file-saver';
    import moment from 'moment';
    import swal from 'sweetalert2'
    import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
    import { mapGetters } from 'vuex';
    export default {
        data(){
            return {
                loaded:true,
                filters:{
                    filter:1,
                    date:{
                        date_from:moment().clone().startOf('month').format('YYYY-MM-DD'),
                        date_to:moment().clone().endOf('month').format('YYYY-MM-DD'),
                        menu2: false,
                        menu3: false,
                        errors:[],
                    },
                    customers:{
                        selected:[],
                        selection:[],
                    },
                    items:{
                        selected:[],
                        selection:[],
                    },
                    categories:{
                        selected:[],
                        selection:[],
                    },
                    types:{
                        selected:[],
                        selection:[],
                    },
                    grouping:{
                        selection:[
                            {text:'By Customers',value:0},
                            {text:'By Items',value:1},
                            {text:'By SO',value:2},
                        ],
                        selected:null,
                    },
                    selectedDetails:[0,1,2,3,4,5,6]
                },
                dataTable:{
                    headers:[
                        {
                            title:"SO",
                            headers:[
                                { text: 'SO#', value: 'order_num' },
                                { text: 'SO Date', value: 'order_date' },
                                { text: 'SQ#', value: 'quote_num' },
                                { text: 'SQ Date',value:'quote_date' },
                                { text: 'Customer',value: 'company_name' },
                                { text: 'Model',value: 'model' },
                                { text: 'Name',value: 'name'},
                                { text: 'Category',value: 'category'},
                                { text: 'UOM',value: 'uom'},
                                { text: 'Type',value: 'type'},
                                { text: 'Remarks',value: 'remarks'},
                                { text: 'Qty',value: 'qty'},
                            ],
                            items: [],
                            loaded:true,
                        },
                        {
                            title:"SQ",
                            headers:[
                                { text: 'SQ#', value: 'quote_num' },
                                { text: 'SQ Date',value: 'quote_date' },
                                { text: 'Customer',value: 'company_name' },
                                { text: 'Model',value: 'model' },
                                { text: 'Name',value: 'name'},
                                { text: 'Category',value: 'category' },
                                { text: 'UOM',value: 'uom'},
                                { text: 'Type',value: 'type'},
                                { text: 'Remarks',value: 'remarks'},
                                { text: 'Qty',value: 'qty'},
                            ],
                            items: [],
                            loaded:true,
                        },
                        {
                            title:"WPF",
                            headers:[
                                { text: 'SO#', value: 'order_num'},
                                { text: 'SO Date', value: 'order_date'},
                                { text: 'WPF#',value: 'wpf_num'},
                                { text: 'WPF Date', value: 'wpf_date'},
                                { text: 'Customer',value: 'company_name'},
                                { text: 'Model',value: 'model'},
                                { text: 'Name',value: 'name'},
                                { text: 'Category',value: 'category'},
                                { text: 'UOM',value: 'uom'},
                                { text: 'Type',value: 'type'},
                                { text: 'Remarks',value: 'remarks'},
                                { text: 'Qty',value: 'qty'},
                            ],
                            items: [],
                            loaded:true,
                        },
                        {
                            title:"CF",
                            headers:[
                                { text: 'SO#', value: 'order_num'},
                                { text: 'SO Date', value: 'order_date'},
                                { text: 'WPF#',value: 'wpf_num'},
                                { text: 'WPF Date', value: 'wpf_date'},
                                { text: 'CF#', value: 'cf_num'},
                                { text: 'CF Date', value: 'cf_date'},
                                { text: 'Customer',value: 'company_name'},
                                { text: 'Model',value: 'model'},
                                { text: 'Name',value: 'name'},
                                { text: 'Category',value: 'category'},
                                { text: 'UOM',value: 'uom'},
                                { text: 'Type',value: 'type'},
                                { text: 'Remarks',value: 'remarks'},
                                { text: 'Qty',value: 'qty'},
                            ],
                            items: [],
                            loaded:true,
                        },
                        {
                            title:"SC",
                            headers:[
                                { text: 'SO#', value: 'order_num'},
                                { text: 'SO Date', value: 'order_date'},
                                { text: 'WPF#',value: 'wpf_num'},
                                { text: 'WPF Date', value: 'wpf_date'},
                                { text: 'CF#', value: 'cf_num'},
                                { text: 'CF Date', value: 'cf_date'},
                                { text: 'SC#', value: 'sc_num'},
                                { text: 'SC Date', value: 'sc_date'},
                                { text: 'Customer',value: 'company_name'},
                                { text: 'Model',value: 'model'},
                                { text: 'Name',value: 'name'},
                                { text: 'Category',value: 'category'},
                                { text: 'UOM',value: 'uom'},
                                { text: 'Type',value: 'type'},
                                { text: 'Remarks',value: 'remarks'},
                                { text: 'Qty',value: 'qty'},
                            ],
                            items: [],
                            loaded:true,
                        },
                        {
                            title:"DR",
                            headers:[
                                { text: 'SO#', value: 'order_num'},
                                { text: 'SO Date', value: 'order_date'},
                                { text: 'WPF#',value: 'wpf_num'},
                                { text: 'WPF Date', value: 'wpf_date'},
                                { text: 'CF#', value: 'cf_num'},
                                { text: 'CF Date', value: 'cf_date'},
                                { text: 'Customer',value: 'company_name'},
                                { text: 'Model',value: 'model'},
                                { text: 'Name',value: 'name'},
                                { text: 'Category',value: 'category'},
                                { text: 'UOM',value: 'uom'},
                                { text: 'Type',value: 'type'},
                                { text: 'Remarks',value: 'remarks'},
                                { text: 'Qty',value: 'qty'},
                            ],
                            items: [],
                            loaded:true,
                        },
                    ],

                    pagination:{
                        options:{},
                        totalItems:0,
                    }
                },
                paginations: {

                    so:true,
                    paginationSo: {
                        options: {},
                        totalItems:10
                    },
                    sq:true,
                    paginationSq: {
                        options: {},
                        totalItems:10
                    },
                    wpf:false,
                    paginationWpf: {
                        options: {},
                        totalItems:10
                    },
                    cf:false,
                    paginationCf: {
                        options: {},
                        totalItems:10
                    },
                    sc:false,
                    paginationSc: {
                        options: {},
                        totalItems:10
                    },
                    pl:false,
                    paginationPl: {
                        options: {},
                        totalItems:10
                    },
                },
                loading: {
                    text: '',
                    bufferValue: 100,
                    value: 0,
                    dialog:false,

                    item_type_selection         : true,
                    item_selection              : true,
                    category_selection          : true,
                    customer_selection          : true,
                },
                filter:false,
            }
        },
        components: {
            SharedFunctionsComponent,
            ComponentHeader
        },

        mounted(){
            this.$store.dispatch('getAllcustomersSelection')
            this.$store.dispatch('getItemTypes')
            this.$store.dispatch('getItemCategories')
            this.$store.dispatch('getAllitemSelectionDefault')
        },

        methods: {

            selectedCategories(val){
                this.filters.categories.selected = val
            },

            selectedTypes(val){
                this.filters.types.selected = val
            },

            selectedItems(val){
                this.filters.items.selected = val
            },

            selectedCustomer(val) {
                this.filters.customers.selected = val
            },

            async list(page = 1) {

                let that = this
                let errors = []
                that.loaded = false

                that.dataTable.headers.forEach(e => {
                    e.loaded = false
                })

                if (that.filters.selectedDetails.includes(1)) {
                    // Sales Order
                    await this.$store.dispatch('reports',{
                        date_from :     that.filters.date.date_from,
                        date_to :       that.filters.date.date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        page:  that.paginations.paginationSo.options.page ?
                            that.paginations.paginationSo.options.page : 1,
                        itemsPerPage: that.paginations.paginationSo.options.itemsPerPage ?
                            that.paginations.paginationSo.options.itemsPerPage : 10,
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/so'
                    }).then(response => {

                        that.dataTable.headers[0].items = response.data.data
                        that.dataTable.headers[0].loaded = true
                    }).catch(e=>{
                        swal.fire('Error in SO !' ,'An error occured','error')
                        that.dataTable.headers[0].loaded = true
                    })
                    that.paginations.so = false
                }

                if (that.filters.selectedDetails.includes(0)) {
                    // Sales Quotation
                    await this.$store.dispatch('reports',{
                        date_from :     that.filters.date.date_from,
                        date_to :       that.filters.date.date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types:          that.filters.types.selected.map(e => { return e.value }),
                        page:           that.paginations.paginationSq.options.page ?
                            that.paginations.paginationSq.options.page : 1,
                        itemsPerPage:   that.paginations.paginationSq.options.itemsPerPage ?
                            that.paginations.paginationSq.options.itemsPerPage : 10,
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/sq'
                    }).then(response => {
                        that.paginations.paginationSq.totalItems    = response.data.data.total
                        that.dataTable.headers[1].items             = response.data.data.data
                        that.dataTable.headers[1].loaded = true
                    }).catch(e=>{
                        swal.fire('Error in SQ!' ,'An error occured','error')
                        that.dataTable.headers[1].loaded = true
                    })

                    that.paginations.sq = false
                }

                if (that.filters.selectedDetails.includes(2)) {
                    //Warehouse Prep
                    await this.$store.dispatch('reports',{
                        date_from :     that.filters.date.date_from,
                        date_to :       that.filters.date.date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types:          that.filters.types.selected.map(e => { return e.value }),
                        page:           that.paginations.paginationWpf.options.page ?
                            that.paginations.paginationWpf.options.page : 1,
                        itemsPerPage:   that.paginations.paginationWpf.options.itemsPerPage ?
                            that.paginations.paginationWpf.options.itemsPerPage : 10,
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/wpf'
                    }).then(response=>{
                        that.dataTable.headers[2].items = response.data.data.data
                        that.dataTable.headers[2].loaded = true
                        that.paginations.paginationSq.totalItems    = response.data.data.total
                    }).catch(e=>{
                        swal.fire('Error in WPF!' ,'An error occured','error')
                        that.dataTable.headers[2].loaded = true
                    })
                    that.paginations.wpf = false
                }

                if (that.filters.selectedDetails.includes(3)) {
                    //Checker Form
                    await this.$store.dispatch('reports',{
                        date_from :     that.filters.date.date_from,
                        date_to :       that.filters.date.date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        page:           that.paginations.paginationCf.options.page ?
                            that.paginations.paginationCf.options.page : 1,
                        itemsPerPage:   that.paginations.paginationCf.options.itemsPerPage ?
                            that.paginations.paginationCf.options.itemsPerPage : 10,
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/cf'
                    }).then(response=>{
                        that.dataTable.headers[3].items = response.data.data.data
                        that.dataTable.headers[3].loaded = true
                        that.paginations.paginationSq.totalItems = response.data.data.total
                    }).catch(e=>{
                        swal.fire('Error in CF !' ,'An error occured','error')
                        that.dataTable.headers[3].loaded = true
                    })
                    that.paginations.cf = false
                }

                if (that.filters.selectedDetails.includes(4)) {
                    //Stock Card
                    await this.$store.dispatch('reports',{
                        date_from :     that.filters.date.date_from,
                        date_to :       that.filters.date.date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        page:           that.paginations.paginationSc.options.page ?
                            that.paginations.paginationSc.options.page : 1,
                        itemsPerPage:   that.paginations.paginationSc.options.itemsPerPage ?
                            that.paginations.paginationSc.options.itemsPerPage : 10,
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/sc'
                    }).then(response => {
                        that.dataTable.headers[4].items = response.data.data.data
                        that.dataTable.headers[4].loaded = true
                        that.paginations.paginationSq.totalItems    = response.data.data.total
                    }).catch(e=>{
                        swal.fire('Error in SC !' ,'An error occured','error')
                        that.dataTable.headers[4].loaded = true
                    })
                }

                if (that.filters.selectedDetails.includes(5)) {
                    //Pakcing List
                    await this.$store.dispatch('reports',{
                        date_from :     that.filters.date.date_from,
                        date_to :       that.filters.date.date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types :         that.filters.types.selected.map(e=>{return e.value}),
                        page:           that.paginations.paginationPl.options.page ?
                            that.paginations.paginationPl.options.page : 1,
                        itemsPerPage:   that.paginations.paginationPl.options.itemsPerPage ?
                            that.paginations.paginationPl.options.itemsPerPage : 10,
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/pl'
                    }).then(response=>{
                        that.dataTable.headers[5].items = response.data.data.data
                        that.dataTable.headers[5].loaded = true
                        that.paginations.paginationSq.totalItems    = response.data.data.total
                    }).catch(e=>{
                        swal.fire('Error in DR!' ,'An error occured','error')
                        that.dataTable.headers[5].loaded = true
                    })
                    that.paginations.pl = false
                }

                that.filter = true
                that.loaded = true
            },


            async exportToExcel() {

                let that = this
                let workbook = new ExcelJS.Workbook();
                let style = { alignment: { vertical: 'middle', horizontal: 'center' }, width: 100 };
                let numberFormat = {numFmt: '@'}
                let worksheet = []
                let beginningMonthAndYear = moment(that.filters.date.date_from)
                let endMonthAndYear = moment(that.filters.date.date_to)
                let loopThruMonths = true
                let montDifferenecs = endMonthAndYear.diff(beginningMonthAndYear, 'months')
                let headerCounter = 100 / (parseInt(that.dataTable.headers.length) * (montDifferenecs + 1))

                that.loaded = false
                that.loading.dialog = true

                if (that.filters.selectedDetails.includes(0)) {
                    worksheet['sq'] =  workbook.addWorksheet('SQ');
                    worksheet['sq'].columns = [
                        { header: 'SQ#', key: 'quote_num', style: JSON.parse(JSON.stringify(style)) },
                        { header:'SQ Date',key: 'quote_date',style: JSON.parse(JSON.stringify(style)) },
                        { header:'Customer',key: 'company_name',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Salesman', key: 'salesman_name', style: JSON.parse(JSON.stringify(style)) },
                        { header:'Model',key: 'model',style: JSON.parse(JSON.stringify(style)) },
                        { header:'Name',key: 'name',style: JSON.parse(JSON.stringify(style)) },
                        { header:'Category',key: 'category',style: JSON.parse(JSON.stringify(style)) },
                        { header:'UOM',key: 'uom',style: JSON.parse(JSON.stringify(style)) },
                        { header:'Type',key: 'type',style: JSON.parse(JSON.stringify(style)) },
                        { header:'Remarks',key: 'remarks'},
                        { header:'Qty',key: 'qty',style: { numFmt: '#,##0' }},
                    ];
                }

                if (that.filters.selectedDetails.includes(1)){
                    worksheet['so'] = workbook.addWorksheet('SO');
                    worksheet['so'].columns = [
                        { header: 'SO#', key: 'order_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SO Date', key: 'order_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SQ#', key: 'quote_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SQ Date',key:'quote_date',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Customer', key: 'company_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Salesman', key: 'salesman_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Model',key: 'model',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Name',key: 'name',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Category',key: 'category',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'UOM',key: 'uom',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Type',key: 'type',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Remarks',key: 'remarks',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SO Remarks',key: 'so_remarks' },
                        { header: 'Order Qty', key: 'order_quantity',style: { numFmt: '#,##0' } },
                    ];
                }

                if (that.filters.selectedDetails.includes(2)) {
                    worksheet['wpf'] = workbook.addWorksheet('WPF');
                    worksheet['wpf'].columns = [
                        { header: 'SO#', key: 'order_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SO Date', key: 'order_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF#',key: 'wpf_num',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF Date', key: 'wpf_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Customer', key: 'company_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Salesman', key: 'salesman_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Model',key: 'model',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Name',key: 'name',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Category',key: 'category',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'UOM',key: 'uom',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Type',key: 'type',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Remarks',key: 'remarks',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SO Remarks',key: 'so_remarks' },
                        { header: 'Qty',key: 'qty',style: { numFmt: '#,##0' } },
                    ];
                }

                if (that.filters.selectedDetails.includes(3)) {
                    worksheet['cf'] = workbook.addWorksheet('CF');
                    worksheet['cf'].columns =  [
                        { header: 'SO#', key: 'order_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SO Date', key: 'order_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF#',key: 'wpf_num',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF Date', key: 'wpf_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'CF#', key: 'cf_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'CF Date', key: 'cf_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Customer',key: 'company_name',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Salesman', key: 'salesman_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Model', key: 'model', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Name',key: 'name',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Category',key: 'category',style: JSON.parse(JSON.stringify(style))},
                        { header: 'UOM',key: 'uom',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Type',key: 'type',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Remarks',key: 'remarks',style: JSON.parse(JSON.stringify(style))},
                        { header: 'SO Remarks',key: 'so_remarks' },
                        { header: 'Qty',key: 'qty',style: { numFmt: '#,##0' } },
                    ];
                }

                if (that.filters.selectedDetails.includes(4)) {
                    worksheet['sc'] = workbook.addWorksheet('SC');
                    worksheet['sc'].columns = [
                        { header: 'SO#', key: 'order_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SO Date', key: 'order_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF#',key: 'wpf_num',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF Date', key: 'wpf_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'CF#', key: 'cf_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'CF Date', key: 'cf_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SC#', key: 'sc_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SC Date', key: 'sc_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Customer', key: 'company_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Salesman', key: 'salesman_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Model',key: 'model',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Name',key: 'name',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Category',key: 'category',style: JSON.parse(JSON.stringify(style))},
                        { header: 'UOM',key: 'uom',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Type',key: 'type',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Remarks',key: 'remarks',style: JSON.parse(JSON.stringify(style))},
                        { header: 'SO Remarks',key: 'so_remarks' },
                        { header: 'Qty',key: 'qty',style: { numFmt: '#,##0' } },
                    ];
                }

                if (that.filters.selectedDetails.includes(5)) {
                    worksheet['pl'] = workbook.addWorksheet('DR');
                    worksheet['pl'].columns = [
                        { header: 'SO#', key: 'order_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'SO Date', key: 'order_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF#',key: 'wpf_num',style: JSON.parse(JSON.stringify(style)) },
                        { header: 'WPF Date', key: 'wpf_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'CF#', key: 'cf_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'CF Date', key: 'cf_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'DR#', key: 'pl_num', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'DR Date', key: 'pl_date', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Customer', key: 'company_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Salesman', key: 'salesman_name', style: JSON.parse(JSON.stringify(style)) },
                        { header: 'Model',key:'model',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Name',key:'name',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Category',key:'category',style: JSON.parse(JSON.stringify(style))},
                        { header: 'UOM',key:'uom',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Type',key:'type',style: JSON.parse(JSON.stringify(style))},
                        { header: 'Remarks',key:'remarks'},
                        { header: 'SO Remarks',key: 'so_remarks' },
                        { header: 'Qty',key:'qty',style: { numFmt: '#,##0' } },
                    ];
                }

                let counter  = 0
                while (loopThruMonths) {

                    counter++
                    let date_from = ''
                    let date_to = ''
                    if (beginningMonthAndYear.format('YYYY-MM-DD') == that.filters.date.date_from) {
                        date_from = beginningMonthAndYear.format('YYYY-MM-DD')
                        date_to = beginningMonthAndYear.endOf('month').format('YYYY-MM-DD')
                    }

                    else if (beginningMonthAndYear.format('YYYY-MM') == endMonthAndYear.format('YYYY-MM')) {
                        date_from = endMonthAndYear.startOf('month').format('YYYY-MM-DD')
                        date_to = moment(that.filters.date.date_to).format('YYYY-MM-DD')
                    }
                    else {
                        date_from = beginningMonthAndYear.startOf('month').format('YYYY-MM-DD')
                        date_to = beginningMonthAndYear.endOf('month').format('YYYY-MM-DD')
                    }

                    that.loading.text = "SQ (" + beginningMonthAndYear.format('MMM') + ")"
                    that.loading.value += headerCounter

                    if (that.filters.selectedDetails.includes(0)) {
                        // sales quotation
                        await this.$store.dispatch('reports',{
                            date_from :    date_from,
                            date_to: date_to ,
                            categories :    that.filters.categories.selected.map(e=>{return e.value}),
                            types: that.filters.types.selected.map(e => { return e.value }),
                            items: that.filters.items.selected.map(e => { return e.value }),
                            customers: that.filters.customers.selected.map(e => { return e.value }),
                            url:'item-sales-details/list/sq'

                        }).then(response => {
                        response.data.data.forEach((e,i) => {
                            worksheet['sq'].addRow(e)
                            //SQ#
                            worksheet['sq'].getCell(`A${i + 2}`).value = parseInt(e.quote_num)
                            worksheet['sq'].getCell(`A${i + 2}`).numFmt = '0'
                            //Quantity
                            worksheet['sq'].getCell(`K${i + 2}`).value = parseInt(e.qty)
                            worksheet['sq'].getCell(`K${i + 2}`).numFmt = '#,##0'
                        })
                    }).catch(e=>{
                        swal.fire('Error in SQ !', 'An error occured', 'error')
                        console.log(e)
                    })
                    }

                if (that.filters.selectedDetails.includes(1)) {
                    // Sales Order
                    that.loading.text = "SO (" + beginningMonthAndYear.format('MMM') + ")"
                    that.loading.value += headerCounter
                    await this.$store.dispatch('reports',{
                        date_to :    date_to,
                        date_from:  date_from,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types :         that.filters.types.selected.map(e=>{return e.value}),
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/so'
                    }).then(response=>{

                        response.data.data.forEach((e,i) => {
                            worksheet['so'].addRow(e)
                            //SO#
                            // worksheet['so'].getCell(`A${i + 2}`).value = parseInt(e.order_num)
                            // worksheet['so'].getCell(`A${i + 2}`).numFmt = '0'
                            //SQ#
                            worksheet['so'].getCell(`C${i + 2}`).value = parseInt(e.quote_num)
                            worksheet['so'].getCell(`C${i + 2}`).numFmt = '0'
                        })
                    }).catch(e=>{
                        swal.fire('Error in SO!', 'An error occured', 'error')
                        console.log(e)
                    })
                }

                if (that.filters.selectedDetails.includes(2)) {
                    //Warehouse Prep
                    that.loading.text = "WPF (" + beginningMonthAndYear.format('MMM') + ")"
                    that.loading.value += headerCounter
                    await this.$store.dispatch('reports',{
                        date_from :     date_from,
                        date_to :       date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/wpf'
                    }).then(response => {
                        response.data.data.forEach((e,i) => {
                            worksheet['wpf'].addRow(e)
                            //SO#
                            worksheet['wpf'].getCell(`A${i + 2}`).value = parseInt(e.order_num)
                            worksheet['wpf'].getCell(`A${i + 2}`).numFmt = '0'
                            // Quantity
                            worksheet['wpf'].getCell(`N${i + 2}`).value = parseInt(e.qty)
                            worksheet['wpf'].getCell(`N${i + 2}`).numFmt = '#,##0'
                        })
                    }).catch(e=>{
                        swal.fire('Error in WPF !' ,'An error occured','error')
                        console.log(e)
                    })
                }

                if (that.filters.selectedDetails.includes(3)) {
                    //Checker Form
                    that.loading.text = "CF (" + beginningMonthAndYear.format('MMM') + ")"
                    that.loading.value += headerCounter
                    await this.$store.dispatch('reports',{
                        date_from :     date_from,
                        date_to :       date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/cf'
                    }).then(response => {
                        response.data.data.forEach((e,i) => {
                            worksheet['cf'].addRow(e)
                            //SO#
                            worksheet['cf'].getCell(`A${i + 2}`).value = parseInt(e.order_num)
                            worksheet['cf'].getCell(`A${i + 2}`).numFmt = '0'
                            //Quantity#
                            worksheet['cf'].getCell(`P${i + 2}`).value = parseInt(e.qty)
                            worksheet['cf'].getCell(`P${i + 2}`).numFmt = '#,##0'
                        })
                    }).catch(e=>{
                        swal.fire('Error in CF !' ,'An error occured','error')
                    })
                }

                if (that.filters.selectedDetails.includes(4)) {
                    //Stock Card
                    that.loading.text = "SC (" + beginningMonthAndYear.format('MMM') + ")"
                    that.loading.value += headerCounter

                    await this.$store.dispatch('reports',{
                        date_from :     date_from,
                        date_to :       date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/sc'
                    }).then(response => {
                        response.data.data.forEach((e,i) => {
                            worksheet['sc'].addRow(e)
                            //SO#
                            worksheet['sc'].getCell(`A${i + 2}`).value = parseInt(e.order_num)
                            worksheet['sc'].getCell(`A${i + 2}`).numFmt = '0'
                        })
                    }).catch(e=>{
                        swal.fire('Error in sc !' ,'An error occured','error')
                    })
                }

                if (that.filters.selectedDetails.includes(5)) {
                    //Delivery Receipt
                    that.loading.text = "DR (" + beginningMonthAndYear.format('MMM') + ")"
                    that.loading.value += headerCounter

                    await this.$store.dispatch('reports',{
                        date_from :     date_from,
                        date_to :       date_to,
                        categories :    that.filters.categories.selected.map(e=>{return e.value}),
                        types: that.filters.types.selected.map(e => { return e.value }),
                        items: that.filters.items.selected.map(e => { return e.value }),
                        customers: that.filters.customers.selected.map(e => { return e.value }),
                        url:'item-sales-details/list/pl'
                    }).then(response => {

                        response.data.data.forEach((e,i) => {
                            worksheet['pl'].addRow(e)
                            //SO#
                            worksheet['pl'].getCell(`A${i + 2}`).value = parseInt(e.order_num)
                            worksheet['pl'].getCell(`A${i + 2}`).numFmt = '0'
                            //DR#
                            worksheet['pl'].getCell(`G${i + 2}`).value = parseInt(e.pl_num)
                            worksheet['pl'].getCell(`G${i + 2}`).numFmt = '0'
                        })
                    }).catch(e=>{
                        swal.fire('Error in DR!' ,'An error occured','error')
                    })
                }

                    if (beginningMonthAndYear.format('YYYY-MM') == endMonthAndYear.format('YYYY-MM')) {
                        loopThruMonths = false
                    }
                    beginningMonthAndYear = beginningMonthAndYear.add(1, 'month')
                }

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    saveAs(blob, `Items Sales Details ${that.filters.date.date_from}-${that.filters.date.date_to}.xlsx`);
                });

                that.loaded = true
                that.loading.dialog = false
                that.loading.value = 0
                swal.fire('Success!','','success')
            },

            thousandSeprator(amount) {
                if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return amount;
                }
            },

            resetFields(){
                let that = this
                that.filters.items.selected = []
                that.filters.categories.selected = []
                that.filters.customers.selected = ''
                that.filters.types.selected = that.filters.types.selected.filter(e=>{return e.value != 3})
                that.filters.date.date_from = moment().clone().startOf('month').format('YYYY-MM-DD')
                that.filters.date.date_to = moment().clone().endOf('month').format('YYYY-MM-DD')
            },

        },

        computed:{
            ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES',
            'GET_ITEM_SELECTION_DEFAULT',
            ])
        },

        watch:{
            'GET_ITEM_TYPES':{
                    handler(val){
                        this.filters.types.selection = val.data
                        this.loading.item_type_selection = false

                    }
                },
                'GET_ITEM_SELECTION_DEFAULT':{
                    handler(val){
                        this.filters.items.selection = val
                        this.loading.item_selection = false

                    }
                },
                'GET_ITEM_CATEGORIES':{
                    handler(val){
                        this.filters.categories.selection = val.data
                        this.loading.category_selection = false

                    }
                },
                'GET_CUSTOMERS_SELECTION':{
                    handler(val){
                        this.filters.items.selection = val
                        this.loading.customer_selection = false

                    }
                },
                'GET_CUSTOMERS_SELECTION':{
                    handler(val){
                        this.filters.items.selection = val
                        this.loading.customer_selection = false
                    }
                },
        }
    };
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
