<template>
  <v-container class="ma-0 pa-2">
    <v-card-title>
      <v-row>
        <v-col md="16" class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">Outbound</h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-row>
      <v-col md="16" class="d-flex no-block align-items-center">
        <v-radio-group color="red" row v-model="radioGroup" class="small-radio ml-3">
          <v-radio value="" color="success">
            <template v-slot:label>
              <strong>All</strong>
            </template>
          </v-radio>
          <v-radio value="<>" color="success">
            <template v-slot:label>
              <strong>Exclude SP</strong>
            </template>
          </v-radio>
          <v-radio value="=" color="success">
            <template v-slot:label>
              <strong>SP only</strong>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-container class="ma-0 pa-1">
      <v-app id="item-tabs" class="ma-0">
        <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" slider-color="cyan" dark class="col-12" show-arrows>
          <v-tab ripple>All Outbound</v-tab>
          <v-tab ripple>Pending Outbound</v-tab>
          <v-tab ripple>Receive Outbound</v-tab>
          <v-tab ripple>Approved Outbound</v-tab>

          <v-tabs-items v-model="tab" touchless>
            <!-- ALL OUTBOUND TAB -->
            <v-tab-item>
              <OutboundComponentVue :radioGroup="radioGroup"></OutboundComponentVue>
            </v-tab-item>

            <!-- SAVED OUTBOUND TAB -->
            <v-tab-item>
              <OutboundComponentVue :is_pending="true" :radioGroup="radioGroup"></OutboundComponentVue>
            </v-tab-item>

            <!-- RECEIVE OUTBOUND TAB -->
            <v-tab-item>
              <OutboundComponentVue :is_receiving="true" :radioGroup="radioGroup"></OutboundComponentVue>
            </v-tab-item>

            <!-- APPROVED OUTBOUND TAB -->
            <v-tab-item>
              <OutboundComponentVue :is_approved="true" :radioGroup="radioGroup"></OutboundComponentVue>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-app>
    </v-container>
  </v-container>
</template>
<script>
import { computed } from 'vue'
// import usersTable from './tables/StockAdjustmentTable.vue';
// import batchAddtionDialog from '../../layouts/dialogs/IT/StockAdjustmentDialog.vue';
// import buttons from '@/views/main/layouts/Buttons/DialogButton.vue'
// import buttons_components from '@/assets/js/components-js.js';
import OutboundComponentVue from './OutboundComponent.vue';
// import Dialogs from '../../layouts/dialogs/Dialog.vue'
import { mapGetters } from 'vuex';
export default {
  components: {
    // buttons,
    // usersTable,
    // buttons_components,
    // Dialogs,
    OutboundComponentVue,
  },
  data() {
    return {
      tab: null,
      radioGroup: '0',
      // color:'orange',
      // action:'add',
      // dialog_text:false,
      // dialog_icon:'mdi-plus',
      // components:batchAddtionDialog,
      // buttons:buttons_components.buttons,
      // userAccess: {
      //     view: false,
      //     create:false,
      //     edit: false,
      //     delete: false,
      // },
      // selectedAction:'',
    };
  },
  mounted() {
    this.getStatusFromUrl();
    this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    this.$store.dispatch('getWarehouseItemStocks')
    this.$store.dispatch('getAllWarehouses',{})
    this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);

  },
  methods: {
    getStatusFromUrl() {
      if (document.location.href.includes('search-model=')) {
        let status = document.location.href.split('status=');
        status = status[1].split('&');
        this.tab = parseInt(status[0]);
      }
    },
  }
};
</script>
<style>
#group-tab {
  padding: 0 !important;
}
.tab-bg {
  background-color: gray;
}
.small-radio i {
  font-size: 19px;
}
.small-radio label {
  font-size: 14px;
  padding-left: 0px;
  margin-left: -4px;
}
.small-radio .v-radio {
  padding: 0px;
}
.small-radio [class*='__ripple'] {
  left: 0;
}
</style>
