<template>
  <v-container class="ma-0 pa-2">
    <v-container class="ma-0 pa-1">
      <v-app id="item-tabs" class="ma-0">
        <v-tabs v-model="tab" id="group-tab">
          <v-tab ripple>All </v-tab>
          <v-tab ripple>Pending </v-tab>
          <v-tab ripple>Receive </v-tab>
          <v-tab ripple>Approved </v-tab>

          <v-tabs-items v-model="tab" touchless>
            <!-- ALL CONTAINERS TAB -->
            <v-tab-item>
              <containers-component :radioGroup="radioGroup"></containers-component>
            </v-tab-item>

            <!-- SAVED CONTAINERS TAB -->
            <v-tab-item>
              <containers-component :is_pending="true" :radioGroup="radioGroup"></containers-component>
            </v-tab-item>

            <!-- RECEIVE CONTAINERS TAB -->
            <v-tab-item>
              <containers-component :is_receiving="true" :radioGroup="radioGroup"></containers-component>
            </v-tab-item>

            <!-- APPROVED CONTAINERS TAB -->
            <v-tab-item>
              <containers-component :is_approved="true" :radioGroup="radioGroup"></containers-component>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-app>
    </v-container>
  </v-container>
</template>
<script>
import { computed } from 'vue'
import ContainersComponent from './ContainersComponent.vue'
import { mapGetters } from 'vuex';
export default {
    props:['radioGroup'],
    components: {
    ContainersComponent,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    this.getStatusFromUrl();
    this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
    this.$store.dispatch('getWarehouseItemStocks');
    this.$store.dispatch('getAllWarehouses',{})
    this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
  },
  methods: {
    getStatusFromUrl() {
      if (document.location.href.includes('search-model=')) {
        let status = document.location.href.split('status=');
        status = status[1].split('&');
        this.tab = parseInt(status[0]);
      }
      if (Object.keys(this.$route.query).length != 0) {
          Object.keys(this.$route.query).forEach(e=>{
              if(e == 'pending_containers'){
                  this.tab = 1
              }
          })
      }
    },
  },
};
</script>
<style>
#group-tab {
  padding: 0 !important;
}
.tab-bg {
  background-color: gray;
}
.small-radio i {
  font-size: 19px;
}
.small-radio label {
  font-size: 14px;
  padding-left: 0px;
  margin-left: -4px;
}
.small-radio .v-radio {
  padding: 0px;
}
.small-radio [class*='__ripple'] {
  left: 0;
}
</style>
