<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title" style="color: black" v-if="service=='0'">Sales Quotation</h3>
        <h3 class="page-title" style="color: black" v-if="service=='1'">Repair Quotation</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
                    <v-tab ripple>SAVED</v-tab>
                    <v-tab ripple>CONVERTED</v-tab>
                    <v-tab ripple>ALL</v-tab>

                    <v-tabs-items v-model="tab" touchless>
                        <!-- SAVED SQ TAB -->
                        <v-tab-item>
                            <sales-quotations-component :tab_name="'is_saved'" :service="service"></sales-quotations-component>
                        </v-tab-item>

                        <!-- CONVERTED SQ TAB -->
                        <v-tab-item>
                            <sales-quotations-component :tab_name="'is_converted'" :service="service"></sales-quotations-component>
                        </v-tab-item>

                        <!-- ALL SQ TAB -->
                        <v-tab-item>
                            <sales-quotations-component :tab_name="'is_all'" :service="service"></sales-quotations-component>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import SalesQuotationsComponent from './SalesQuotationsComponent.vue'
export default {
    components: {
        SalesQuotationsComponent,
    },
    props: [
        'service'
    ],
    data() {
        return {
            tab: null,
        }
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('getCustomers', {
            params: {
                filtered: true,
                filter_store: true
            }
        })
        this.$store.dispatch('getSalesmanList')
        this.$store.dispatch('getAllUsers')
        this.$store.dispatch("getCurrentSalesman");
    },
}
</script>
<style>
    #group-tab{
      padding: 0 !important;
    }
</style>
