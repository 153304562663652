<template>
    <div class="w-100">
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>

        <v-app id="my_vue_app">

            <v-card v-show="loaded">
                <v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="4" class="pt-5">
                                <v-autocomplete :items="vehicles" :loading="loading.vehicle_selection"
                                    :disabled="loading.vehicle_selection" dense outlined v-model="filters.vehicle_id"
                                    item-text="text" item-value="id" placeholder="Select Vehicle"
                                    class="d-inline-block w-100" @change="filter">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" lg="2">

                                <v-menu v-model="filters.date_from_menu" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filters.date_from" readonly
                                            append-icon="mdi-calendar-clock" label="Date From" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.date_from" @input="filters.date_from_menu = false"
                                        @change="filter"></v-date-picker>
                                </v-menu>
                                <!-- <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                            </v-col>
                            <v-col cols="12" md="4" lg="2">
                                <v-menu v-model="filters.date_to_menu" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filters.date_to" readonly label="Date To"
                                            append-icon="mdi-calendar-clock" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.date_to" @input="filters.date_to_menu = false"
                                        @change="filter"></v-date-picker>
                                </v-menu>
                                <!-- <span v-for="errors in errors.date_to" class="text-warning" :key="errors">{{
                                    errors
                                }}</span> -->
                            </v-col>
                            <v-col cols="12" sm="2" >
                                    <v-btn @click="getAllDispatches()" class="mt-3">FILTER</v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-row class="pl-2 justify-content-end">

                                        <v-text-field v-model="search" label="Search" single-line hide-details clearable
                                            append-icon="mdi-magnify" @keyup.enter="getAllDispatches()" class="mt-3">
                                        </v-text-field>



                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>


                </v-card-title>

                <v-container fluid>
                    <v-data-table :headers="headers" :items="dispatches" :search="search" :options.sync="options"
                        :server-items-length="totalDispatches" :items-per-page="10" :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions
                        }" class="w-100">
                        <template v-slot:[`item.dispatch_date`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.dispatch_date | formatDate }}
                            </span>
                        </template>
                        <template v-slot:[`item.actual_datetime`]="{ item }">

                            <span class="text-nowrap" v-if="item.actual_datetime != '0000-00-00 00:00:00'">
                                {{ item.actual_datetime | formatDate }}
                            </span>
                        </template>
                        <template v-slot:[`item.estimated_datetime`]="{ item }">
                            <span class="text-nowrap" v-if="item.estimated_datetime != '0000-00-00 00:00:00'">
                                {{ item.estimated_datetime | formatDate }}
                            </span>
                        </template>

                        <template v-slot:[`item.status_text`]="{ item }">
                            <span class="badge bg-warning" v-if="item.status_text == 'saved'">{{
                                item.status_text
                            }}</span>
                            <span class="badge bg-info text-white" v-else-if="item.status_text == 'confirmed'">{{
                                item.status_text
                            }}</span>
                            <span class="badge bg-success text-white" v-else-if="item.status_text == 'dispatched'">{{
                                item.status_text
                            }}</span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon class="btn-action" small @click="viewDispatch(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0">
                                <v-icon class="btn-action" small @click="editDispatch(item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit_confirmed && item.status == 1 && item.vehicle_is_tpl">
                                <v-icon class="btn-action" small @click="editDispatch(item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="userAccess.delete && item.status == 0">
                                <v-icon class="btn-action" small @click="deleteDispatch(item.id)">mdi-delete</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>

        <!-- VIEW MODAL-->
        <DispatchesViewComponentVue
        :viewDispatchId="viewDispatchId" @closeView="closeView" :tab_name="tab_name">
        </DispatchesViewComponentVue>

    </div>
</template>

<script>
import DispatchesViewComponentVue from '../../../../layouts/dialogs/Logistics/Dispatches/DispatchesViewComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import moment from 'moment';
export default {
    components: {
        DispatchesViewComponentVue
    },
    props: [
        'dispatchesActionResult',
        'tab_name'
    ],
    data() {
        return {
            actionResult: {
                model: 'Dispatches',
                action: ''
            },
            loaded: false,
            dispatches: [],

            vehicles: [],
            filters: {
                date_from: moment().clone().startOf("month").format("YYYY-MM-DD"),
                date_to: moment().clone().endOf("month").format("YYYY-MM-DD"),
                date_from_menu: false,
                date_to_menu: false,
                vehicle_id: '',
            },
            headers: [
                { text: 'ID', align: 'left', value: 'id', },
                { text: 'Vehicle', align: 'left', value: 'vehicle_name', },
                { text: 'Driver', align: 'left', value: 'driver_name' },
                { text: 'Dispatcher', align: 'left', value: 'dispatcher_name' },
                { text: 'Remarks', align: 'left', value: 'remarks' },
                { text: 'Additional Remarks', align: 'left', value: 'additional_remarks' },
                { text: 'Dispatch Date', align: 'left', value: 'dispatch_date' },
                { text: 'ATA', align: 'left', value: 'actual_datetime' },
                { text: 'ETA', align: 'left', value: 'estimated_datetime' },
                { text: 'Status', sortable: false, align: 'left', value: 'status_text' },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewDispatchId: '',

            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
            userAccess: {
                view: false,
                edit: false,
                edit: false,
                delete: false,
                edit_confirmed: false,
            },
            totalDispatches: 0,
            options: {},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            loading: {
                vehicle_selection: true,
            },
            user:{
                exceptions:[328,5],
                details:null
            },
        }
    },
    mounted() {

        this.getVehicles();
        // this.checkAccess('OP', 'dispatches', 'view')
        // this.checkAccess('OP', 'dispatches', 'edit')
        // this.checkAccess('OP', 'dispatches', 'delete')
        this.$store.dispatch(
            'checkAccessUser',
            this.$router.currentRoute.params.id
        );

        this.refresher
    },
    created(){
        this.getCurrentUser()
    },
    computed: {
        ...mapGetters([
            'GET_ALL_DISPATCHES',
            'USER_ACCESS',
            'GET_VEHICLES'
        ]),
        refresher() {
            this.$store.commit('DISPATCH_DATE_FROM', this.filters.date_from);
            this.$store.commit('DISPATCH_DATE_TO', this.filters.date_to);
            this.$store.commit('DISPATCH_VEHICLES_ID', this.filters.vehicle_id);
        }
    },
    methods: {
        filter() {

            this.$store.commit('DISPATCH_DATE_FROM', this.filters.date_from);
            this.$store.commit('DISPATCH_DATE_TO', this.filters.date_to);
            this.$store.commit('DISPATCH_VEHICLES_ID', this.filters.vehicle_id);

        },
        getVehicles() {
            this.$store.dispatch('getVehicle').then(response => {
                this.vehicles = this.GET_VEHICLES.map(e => { return { text: e.name + "( " + e.plate_number + " )", id: e.id } })
            })
        },
        // checkAccess(dep, mod, axn){
        //     axios.get('checkAccess',{
        //         params: {
        //             department_code: dep,
        //             module: mod,
        //             action: axn,
        //         }
        //     }).then(response => {
        //         this.userAccess[axn] =  response.data;
        //     });
        // },
        async getCurrentUser(){
            await this.$store.dispatch('currentUser',{
                url:'current-user'
            }).then(response=>{
                this.user.details = response
            })
            return;
        },
        async getAllDispatches() {
            this.loaded = false

            if(!this.user.details){
                await this.getCurrentUser();
            }

            let payload = {
                page: this.options.page,
                vehicle_id:this.filters.vehicle_id,
                date_from:this.filters.date_from,
                date_to:this.filters.date_to,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
                status: this.tab_name,
                service:this.user.details.is_service ? 1 : 0
            }
            this.$store.dispatch('getAllDispatches', payload).then(response => {
                this.dispatches = this.GET_ALL_DISPATCHES.data
                this.loaded = true
                this.totalDispatches = this.GET_ALL_DISPATCHES.total
            })
        },
        viewDispatch(id) {
            this.viewDispatchId = id
        },
        editDispatch(id) {
            this.$emit('showEditModal', id);
        },
        deleteDispatch(id) {

            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton: true,
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('destroyDispatch', id).then(response => {
                            this.getAllDispatches()
                            swal.fire('', "Delete Dispatch Successful!", "success");
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                        })
                    }
                });
        },
        closeView(action = false) {
            this.viewDispatchId = 0
            if (action == 'updateStatusSuccess') {
                this.getAllDispatches()
            }
        },
    },
    watch: {
        'GET_VEHICLES': {
            handler(val) {
                this.dealersList = val.data
                this.loading.vehicle_selection = false
            }
        },
        dispatchesActionResult: function (val) {
            if (this.dispatchesActionResult == 'success') {
                this.getAllDispatches()
            }
        },
        // search:{
        //     handler(){
        //         this.getAllDispatches()
        //     },
        //     deep: true
        // },
        options: {
            handler() {
                this.getAllDispatches()
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {

                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    this.userAccess.edit_confirmed = false;
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                        if (e.actions_code == "edit_confirmed") {
                            this.userAccess.edit_confirmed = true;
                        }
                    });
                }
            }
        },
        tab_name:{
            handler(val){
                this.getAllDispatches()
            }
        }
    }
};
</script>
