<template>
<!-- <div :class="{'page-wrapper': is_preparation || is_accounting, 'tab-page-wrapper': !is_preparation && !is_accounting}"> -->
<div class="tabs-body-color">
    <v-container>
        <!-- <div class="row" v-if="is_preparation">
            <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title" style="color: black">Delivery Receipt Preparation</h4>
            </div>
        </div> -->

        <div class="row" v-if="is_accounting">
            <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title" style="color: black">Unconfirmed Delivery Receipt</h4>
            </div>
        </div>

        <!--<div class="row">
            <div class="col-12 d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn data-modal-id="addPackingListModal" v-on:click="showAddPackingList()">
                        <i class="fas fa-plus"></i>Add
                    </v-btn>
                </div>
            </div>
        </div>-->

        <!-- <div class="row p-2" v-else-if="!is_stockcard">
            <div class="col-12 d-flex no-block align-items-center">
                <h4 class="page-title" style="color: black">Delivery Receipt</h4>
            </div>
        </div> -->

        <div class="row">
            <!-- Delivery Receipt Table -->
           <PackingListsTableComponentVue
                :packingListsActionResult="action"
                :tab_name="tab_name"
                :is_preparation="is_preparation"
                :unconfirmed="unconfirmed"
                :is_stockcard="is_stockcard"
                :is_accounting="is_accounting"
                :service="service"
                @showEditModal="showEditModal"
                :fromSoa="fromSoa"
            >
            </PackingListsTableComponentVue>
        </div>
    </v-container>

    <v-app id="addEditPackingListModal">
        <v-dialog v-model="add_edit_packinglist_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Delivery Receipt</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_packinglist_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text v-if="loaded==true">
                    <form class="form-horizontal" action="/packinglists" method="post" v-on:submit.prevent="onSubmit">
                        <div class="card-body">
                            <div class="row m-0 text-dark" v-if="action=='Edit'">
                                <div class="col-sm-12 col-md-12 text-left">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-9 p-0"></div>
                                        <div class="col-sm-12 col-md-3 p-0">Delivery Receipt No: {{ this.packing_list_num }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-9 p-0"></div>
                                        <div class="col-sm-12 col-md-3 p-0">Issuer: {{ this.issuer_name }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-9 p-0"></div>
                                        <div class="col-sm-12 col-md-3 p-0">Date Issued: {{ this.created_at | formatDateTime }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-9 p-0"></div>
                                        <div class="col-sm-12 col-md-3 p-0">Promo: {{ this.promo_name }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-3">
                                    <v-autocomplete :items="customers_list"
                                        v-model="customer_id"
                                        item-value="value"
                                        item-text="text"
                                        label="Customer"
                                        outlined
                                        dense
                                        disabled
                                    >
                                    </v-autocomplete>
                                    <span v-for="errors in errors.customer_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <v-autocomplete
                                        v-model="customer.shipping_address"
                                        :items="shipping_address_list"
                                        item-text='text'
                                        item-value='value'
                                        placeholder="Select Address"
                                        dense
                                        outlined
                                        color="#b3d4fc"
                                        class="w-100 small"
                                        id="shipping_address_text"
                                        disabled
                                    ></v-autocomplete>
                                    <span v-for="errors in errors.type_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <v-autocomplete :items="salesman_list"
                                        v-model="salesman_id"
                                        item-value="value"
                                        item-text="text"
                                        label="Salesman"
                                        dense
                                        outlined
                                        disabled
                                    >
                                    </v-autocomplete>
                                    <span v-for="errors in errors.salesman_id" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                                <div class="col-sm-12 col-md-2">
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="0" v-model="terms" disabled @keypress="numberOnly" aria-label="%" aria-describedby="basic-addon2" :readonly="promo_id != ''">
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-addon2">days</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="col-sm-12 col-md-3">
                                <label for="checked_by" class="float-left control-label col-form-label">Checked By</label>
                                <input type="text" class="form-control" v-model="checked_by">
                                <span v-for="errors in errors.checked_by" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div> -->
                            <div class="col-sm-12 col-md-3">
                                <v-text-field dense outlined label="Issued By" class="w-100" v-model="prepared_by"/>
                                <span v-for="errors in errors.prepared_by" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div>
                            <!-- <div class="col-sm-12 col-md-3">
                                <label for="prepared_by_2" class="float-left control-label col-form-label">Prepared By</label>
                                <input type="text" class="form-control" v-model="prepared_by_2">
                                <span v-for="errors in errors.prepared_by_2" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div> -->
                            <!-- <div class="col-sm-12 col-md-3">
                                <label for="noted_by" class="float-left control-label col-form-label">Noted By</label>
                                <input type="text" class="form-control" v-model="noted_by" value="Aileen O. Medidas">
                                <span v-for="errors in errors.noted_by" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="row ml-2 mt-2" v-if="customer.discounts.length > 0 && customer.is_sp == 0 && promo_id == null">
                                    <div class="col-sm-12 col-md-8">
                                        <div class="row p-0 text-dark">
                                            <div class="col-sm-12 col-md-6 py-0" v-for="(disc, index ) in itemTypeDiscounts" :key="index" v-show="orderList[0] != null && disc.length > 0 && disc.substring(0,2) == orderList[0].item.item_type_text">
                                                <span>{{ disc }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-4 p-0"></div>
                                </div>
                            </div>
                            <div class="col-sm-6 offset-md-2 col-md-1">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="DR Date"
                                            v-model="packing_list_date"
                                            v-on="on"
                                            v-mask="'####-##-##'"
                                            height = 39
                                        ></v-text-field>
                                    </template>
                                </v-menu>
                                <span v-for="errors in errors.packing_list_date" class="text-warning" v-bind:key="errors">{{errors}}</span>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <v-textarea
                                    label="Remarks"
                                    no-resize
                                    rows="1.5"
                                    v-model="remarks"
                                    class="p-0"
                                ></v-textarea>
                            </div>
                        </div>

                        <v-card
                            outlined
                            class="p-2"
                        >
                            <div class="row">
                                <!-- <div class="col-sm-12 col-md-12">
                                    <div class="float-right mx-2">
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            style="height:20px; width:20px;"
                                            @click="addOrderItem()"
                                        >
                                            <v-icon dark small style="font-size:12px">
                                                fas fa-plus
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            dark
                                            small
                                            color="primary"
                                            style="height:20px; width:20px;"
                                            @click="removeOrderItem()"
                                        >
                                            <v-icon dark small style="font-size:12px">
                                                fas fa-minus
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </div> -->
                                <div class="col-sm-12 col-md-12">
                                    <v-simple-table dense
                                        class="dynamic_list_table"    >
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-center mx-2" width="12%">Model</th>
                                                    <th class="text-center mx-2" width="22%" >Name</th>
                                                    <th class="text-center mx-2" width="9%">MOQ</th>
                                                    <th class="text-center mx-2" width="9%">Qty</th>
                                                    <th class="text-center mx-2" width="9%">UOM</th>
                                                    <th class="text-center mx-2">SRP</th>
                                                    <th class="text-center mx-2">Disc. Price</th>
                                                    <th class="text-center mx-2" width="1%">FOC</th>
                                                    <th class="text-center mx-2">Total</th>
                                                    <th class="text-center mx-2" width="7%">Type</th>
                                                    <!-- <th class="text-center mx-2" width="1%"></th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(order_item, i) in orderList"
                                                    :key="i"
                                                >
                                                    <td>
                                                        <span  v-if="action=='Add' || orderList[i].item_id == '' || orderList[i].is_editable">
                                                            <v-autocomplete :items="filtered_items_list"
                                                                v-model="orderList[i].item_id"
                                                                item-value="value"
                                                                item-text="text"
                                                                label="Model"
                                                                outlined
                                                                dense
                                                                @input="selectModel(i, orderList[i].item_id, true)"
                                                            >
                                                            </v-autocomplete>
                                                        </span>
                                                        <span v-else>
                                                            <input type="text" class="form-control text-center small" v-model="orderList[i].item.model" readonly>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span  v-if="action=='Add' || orderList[i].item_id == '' || orderList[i].is_editable">
                                                            <v-autocomplete :items="filtered_items_list"
                                                                v-model="orderList[i].item_id"
                                                                item-value="value"
                                                                item-text="name"
                                                                label="Name"
                                                                outlined
                                                                dense
                                                                @input="selectModel(i, orderList[i].item_id, true)"
                                                            >
                                                            </v-autocomplete>
                                                        </span>
                                                        <span v-else>
                                                            <input type="text" class="form-control text-center small" v-model="orderList[i].item.name" readonly>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="orderList[i].item.moq" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center" v-model="orderList[i].quantity" @keypress="numberOnly"
                                                            @input="changeQuantity(i)" disabled>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="orderList[i].item.item_uom_text" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-right small" v-model="orderList[i].item.display_srp" readonly v-if="!orderList[i].foc">
                                                        <input type="text" class="form-control text-right small" v-model="foc_price" readonly v-else>
                                                    </td>
                                                    <td v-if="orderList[i].override_amount == null || orderList[i].override_amount == 0">
                                                        <input type="text" class="form-control text-right small" v-model="orderList[i].display_disc_price" readonly v-if="!orderList[i].foc">
                                                        <input type="text" class="form-control text-right small" v-model="foc_price" readonly v-else>
                                                    </td>
                                                    <td v-else>
                                                        <input type="text" class="form-control text-right small bg-ingco" v-model="orderList[i].display_override_amount" readonly>
                                                    </td>
                                                    <td>
                                                        <v-checkbox
                                                            v-model="orderList[i].foc"
                                                            label=""
                                                            color="primary"
                                                            input-value="1"
                                                            class="align-center justify-center"
                                                            @change="changeFoc(i)"
                                                            disabled
                                                        ></v-checkbox>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-right small" v-model="orderList[i].display_total_amount" readonly>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control text-center small" v-model="orderList[i].item.item_type_text" readonly>
                                                    </td>
                                                    <!-- <td>
                                                        <v-btn
                                                            fab
                                                            dark
                                                            small
                                                            color="primary"
                                                            style="height:20px; width:20px;"
                                                            @click="removeOrderByIndex(i)"
                                                        >
                                                            <v-icon dark small style="font-size:12px">
                                                                fas fa-minus
                                                            </v-icon>
                                                        </v-btn>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </div>
                        </v-card>

                        <v-row class="m-0">
                            <v-container fluid>
                                <v-row>
                                    <div class="col-sm-12 col-md-8 text-left">
                                        <div class="row ml-2">

                                        </div>
                                        <div class="row ml-2">

                                        </div>

                                    </div>
                                    <div class="col-sm-12 col-md-4 pull-right text-right">
                                        <h5>Total Amount: &#x20b1;{{ this.order_total_amount | currency }}</h5>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-row>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewPackingList()" v-if="action=='Add'">Submit</v-btn>
                                <v-btn  v-on:click="editPackingList()" v-else-if="action=='Edit'">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
                <v-card-text v-else-if="loaded==false">
                    <div class="text-center">
                        <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </v-card-text>

                <v-container fluid style="display:none;">
                    <div>
                        <table id="items_details">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>QTY</th>
                                <th>UNITS</th>
                                <th>MODEL</th>
                                <th>DESCRIPTION</th>
                                <th>UNIT PRICE</th>
                                <th>TOTAL</th>
                            </tr>
                            </thead>
                            <tbody id='tr_items'>
                            <!--<tr v-for="(item, index ) in tempOrderList" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.quantity }}</td>
                                <td>{{ item.item.item_uom_text }}</td>
                                <td>{{ item.item.model }}</td>
                                <td>{{ item.item.name }}</td>
                                <td v-if="item.total_amount>0">{{ item.disc_price | currency }}</td><td v-else>FOC</td>
                                <td v-if="item.total_amount>0">{{ item.total_amount | currency }}</td><td v-else>FOC</td>
                            </tr> -->
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>***** NOTHING FOLLOWS *****</td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>

            </v-card>
        </v-dialog>

        <v-dialog v-model="override_amount_dialog" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="override_amount_dialog = false;">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 m-auto text-center">
                            <label for="override_amount" class="control-label col-form-label">Override Amount</label>
                            <input type="text" class="form-control" id="override_amount" placeholder="0.00" v-model="override_amount" @keypress="decimalOnly" autocomplete="off">
                            <span v-for="errors in errors.override_amount" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn  v-on:click="confirmOverrideAmount()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="approve_override_dialog" persistent max-width="50%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Confirm Override Amounts</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="approve_override_dialog = false; override_username = ''; override_user_password = ''; override_user_id = -1;">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <label for="override_username" class="float-left control-label col-form-label">Username</label>
                            <input type="text" class="form-control" id="override_username" placeholder="Username" v-model="override_username" autocomplete="new-password">
                            <span v-for="errors in errors.override_username" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override_user_password" autocomplete="new-password">
                            <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn  v-on:click="approveOverrideAmounts()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</div>
</template>

<script>
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
    import SalesFunctionsComponentVue from '@/views/main/Shared/SalesFunctionsComponent.vue'
    import PackingListsTableComponentVue from './PackingListsTableComponent.vue'
    import swal from 'sweetalert2'
    import { VueMaskDirective } from 'v-mask'
    export default {
        mixins: [SharedFunctionsComponentVue, SalesFunctionsComponentVue],
        data(){
            return {
                is_packing_list: true,
                fromShowEditModal: false,
                delivery_method: '',
                promo_name: '',
                promo_id:'',
                promo:{
                    id: '',
                    name: '',
                    code: '',
                },
                customer_promo: {
                    discount_rate: '',
                    cash_price: ''
                },
                override_username: '',
                override_user_password: '',
                override_user_id: -1,
                override_amount: '',
                override_index: '',
                editPackingListId: '',
                add_edit_packinglist_dialog: false,
                override_amount_dialog: false,
                approve_override_dialog: false,
                packing_list_num: '',
                packing_list_date: '',
                customer_id: '',
                salesman_id: '',
                user_id: '',
                remarks: '',
                discounts: '',
                terms: '',
                convert_date: '',
                status: '',
                created_at: '',
                issuer_name: '',
                foc_price: '0.00',
                checked_by: '',
                prepared_by: '',
                prepared_by_2: '',
                noted_by: '',

                tempOrderList: [],
                orderList: [],
                order: {
                    id: '',
                    quantity: '',
                    item_id: '',
                    foc: '',
                    disc_price: '',
                    total_amount: '',
                    amount: '',
                    type: '',
                    item: {
                        id: '',
                        model: '',
                        name: '',
                        description: '',
                        type_id: '',
                        category_id: '',
                        uom_id: '',
                        item_uom_text: '',
                        barcode: '',
                        selling_price: '',
                        sp_price: '',
                        srp: '',
                        override_amount: '',
                        so_quantity:''
                    },
                    is_bundle: false,
                    promos: [],
                    selected_promo: '',
                    promo_items_id: '',
                    promo_amount: '',
                },
                customer: {
                    shipping_address: 0,
                    billing_address: 0,
                    salesman_name: '',
                    remarks: '',
                    terms: '',
                    discounts: [],
                    is_sp: '',
                    is_vip: '',
                },
                discount: {
                    pt: '',
                    ht: '',
                    sp: '',
                },
                itemTypeDiscounts: [],
                max_discounts_count: 999,
                max_order_items_count: 999,
                shipping_address_list: [],
                customers_list: [],
                users_list: [],
                salesman_list: [],
                items_list: [],
                type_list: [],
                promo_list: [],
                shortlist_item_ids: [],
                shortlist_customer_ids: [],
                delivery_method_list: [],
                errors: [],
                action: '',
                actionResult : {
                    model : 'Delivery Receipt',
                    action : ''
                },
                loaded: false,
                menu2: false,
                order_total_amount: 0,
                service:this.$router.currentRoute.meta.service
            }
        },
        components: {
            PackingListsTableComponentVue,
            VueMaskDirective
        },
        props: [
            'tab_name',
            'is_preparation',
            'unconfirmed',
            'is_stockcard',
            'is_accounting',
            'fromSoa'
        ],
        methods: {
            changeQuantity(key){
                if(parseInt(this.orderList[key].quantity) > this.orderList[key].so_quantity){
                    this.orderList[key].quantity = this.orderList[key].so_quantity
                }

                this.computeTotalAmountPerItem(key)
            },
            getItems(){
                let payload = {
                    editPackingListId: this.editPackingListId
                }
                this.$store.dispatch('getItems',payload).then(response => {
                    this.items_list = response.data
                });
            },
            showAddPackingList(){
                this.add_edit_packinglist_dialog = true
                this.action                    = 'Add'
            },
            addNewPackingList(){
                if(this.checkItemsZeroSRP()){
                    //this.override_user_id == -1
                    //this.showApproveOverrideAmountsDialog()
                    this.checkOrderTableHeight()
                }else{
                    return false
                }
            },
            checkOrderTableHeight(){
                /*var limit = 155;
                var shipping_address_text = $('#shipping_address_text').parent().text();
                if(shipping_address_text != null && shipping_address_text != ''){
                    if(shipping_address_text.length > 60){
                        limit = 145;
                    }
                }

                var tableHeight =  this.computeOrderTableHeight()
                if(tableHeight > limit){
                    swal.fire("", "This will render more than 1 page. \n Please delete item(s) to proceed", "warning");
                }else{
                    if(this.action == 'Add'){
                        this.proceedSavePackingList()
                    }else if(this.action == 'Edit'){
                        this.proceedUpdatePackingList()
                    }
                }*/

                if(this.action == 'Add'){
                    this.proceedSavePackingList()
                }else if(this.action == 'Edit'){
                    this.proceedUpdatePackingList()
                }
            },
            proceedSavePackingList(){
                this.errors = []
                var shipping_address_text = $('#shipping_address_text').parent().text();
                this.checkOrderTableHeightAddItem()
                let payload = {
                    customer_id:            this.customer_id,
                    salesman_id:            this.salesman_id,
                    remarks:                this.remarks,
                    orderList:              this.orderList,
                    shipping_address_index: this.customer.shipping_address,
                    shipping_address_text:  shipping_address_text,
                    discounts:              this.customer.discounts,
                    terms:                  this.terms,
                    override_by:            this.override_user_id,
                    checked_by:             this.checked_by,
                    prepared_by:            this.prepared_by,
                    prepared_by_2:          this.prepared_by_2,
                    noted_by:               this.noted_by,
                    packing_list_date:      this.packing_list_date,
                    url:                    'packingLists'
                }
                this.$store.dispatch('postPL',payload).then(response => {
                    let data = response.data
                    if(data.error){
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_packinglist_dialog = false
                    this.action = 'success_' //use this to avoid double getAllPackingLists
                    swal.fire("", response.data.msg, "success").then((value) => {
                        location.reload();
                    });

                }).catch(error => {
                    if(error.response.status == 422){
                        this.errors = error.response.data.errors
                    }
                });
            },
            async showEditModal(value){
                this.fromShowEditModal = true
                this.action = 'Edit'
                this.editPackingListId = value
                this.getCustomers();
                this.getUsers();
                this.getSalesmanList();
                this.getItems();
                this.getItemTypes();
                this.delivery_method_list = [
                    {'value' : 0, 'text' : 'none'},
                    {'value' : 1, 'text' : 'pick-up'},
                    {'value' : 2, 'text' : 'delivery'},
                ]

                if(this.editPackingListId != null && this.editPackingListId > 0){
                    let payload = {
                        url:    'packingLists/'+this.editPackingListId
                    }
                    await this.$store.dispatch('getPL',payload).then(response => {
                        let data = response.data

                        if(data.error){
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        let packing_list = data.packing_list
                        let orderList = data.packing_list.packing_list_items

                      //  this.customer_promo = data.packing_list.customer_promo
                        if(packing_list.discounts != ''){
                            this.customer.discounts = JSON.parse(packing_list.discounts)
                        }

                        if(orderList != null && orderList.length > 0){
                            let total_amount = 0.00
                            orderList.forEach((order, index)=>{
                                this.addOrderItem()
                                this.orderList[index] = order
                                total_amount += parseFloat(order.total_amount.replace(/,/g, ''))
                                // this.orderList[index].id             = order.id
                                // this.orderList[index].quantity       = order.quantity
                                // this.orderList[index].so_quantity    = order.quantity
                                // //maybe change to unserved_quantity
                                // this.orderList[index].promo_items_id = order.promo_items_id
                                // this.orderList[index].is_bundle      = order.is_bundle
                                // this.orderList[index].promos         = order.promos
                                // this.orderList[index].selected_promo = order.selected_promo

                                // //this.changeQuantity(index)

                                // this.orderList[index].foc            = order.foc
                                // //this.changeFoc(index)

                                // // this.selectModel(index, order.item_id, false, order)
                                // this.orderList[index].item = order.item
                                // this.orderList[index].item_id = order.item_id


                                // this.orderList[index].override_amount = order.override_amount
                            })
                            this.order_total_amount = total_amount
                        }

                        /*if(packing_list.discounts != ''){
                            this.discounts                  = JSON.parse(packing_list.discounts)
                        }

                        if(this.discounts == ''){
                            this.discounts = 'oldData'
                        }*/

                        this.packing_list_num                  = packing_list.packing_list_num
                        this.customer_id                       = packing_list.customer_id
                        this.salesman_id                       = packing_list.salesman_id
                        this.customer.shipping_address         = packing_list.shipping_address_id
                        this.created_at                        = packing_list.created_at
                        this.issuer_name                       = packing_list.issuer_name
                        this.remarks                           = packing_list.remarks
                        this.override_user_id                  = packing_list.override_by
                        this.checked_by                        = packing_list.checked_by
                        this.prepared_by                       = packing_list.prepared_by
                        this.prepared_by_2                     = packing_list.prepared_by_2
                        this.noted_by                          = packing_list.noted_by
                        this.packing_list_date                 = packing_list.packing_list_date
                        this.promo_id                          = packing_list.promo_id
                        this.promo_name                        = packing_list.promo_name
                        this.delivery_method                   = packing_list.delivery_method
                        this.terms                             = packing_list.terms

                        this.add_edit_packinglist_dialog = true
                    }).catch(error => {

                    });
                }
            },
            editPackingList(){
                if(this.checkItemsZeroSRP()){
                    //this.override_user_id == -1
                    //this.showApproveOverrideAmountsDialog()
                    this.checkOrderTableHeight()
                }else{
                    return false
                }
            },
            proceedUpdatePackingList(){
                this.errors = []
                var shipping_address_text = $('#shipping_address_text').parent().text();
                let payload = {
                    customer_id:            this.customer_id,
                    salesman_id:            this.salesman_id,
                    remarks:                this.remarks,
                    orderList:              this.orderList,
                    shipping_address_index: this.customer.shipping_address,
                    shipping_address_text:  shipping_address_text,
                    discounts:              this.customer.discounts,
                    terms:                  this.terms,
                    override_by:            this.override_user_id,
                    checked_by:             this.checked_by,
                    prepared_by:            this.prepared_by,
                    prepared_by_2:          this.prepared_by_2,
                    noted_by:               this.noted_by,
                    packing_list_date:      this.packing_list_date,
                    url:                    'packingLists/'+this.editPackingListId
                }

                this.$store.dispatch('putPL',payload).then(response => {
                    let data = response.data
                    if(data.error){
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_packinglist_dialog = false
                    this.editPackingListId           = 0
                    this.action = 'success_' //use this to avoid double getAllPackingLists
                    swal.fire("", response.data.msg, "success").then((value) => {
                        location.reload();
                    });

                }).catch(error => {
                    if(error.response.status == 422){
                        this.errors = error.response.data.errors
                    }
                });
            },
            addOrderItem(){
                if(this.orderList.length < this.max_order_items_count){
                    this.orderList.push({
                        id: '',
                        quantity: '',
                        item_id: '',
                        foc: '',
                        disc_price: '',
                        total_amount: '',
                        type: '',
                        item: {
                            id: '',
                            model: '',
                            name: '',
                            description: '',
                            type_id: '',
                            category_id: '',
                            uom_id: '',
                            item_uom_text: '',
                            barcode: '',
                            selling_price: '',
                            sp_price: '',
                            srp: '',
                            override_amount: '',
                        },
                    })
                }
            },
            resetFields(){
                this.errors                 = []
                this.packing_list_num       = ''
                this.packing_list_date      = ''
                this.customer_id            = ''
                this.salesman_id            = ''
                this.user_id                = ''
                this.remarks                = ''
                this.customer.discounts     = []
                this.discounts              = ''
                this.terms                  = ''
                this.convert_date           = ''
                this.status                 = ''
                this.customer_id            = ''
                this.shipping_address_list  = []
                this.orderList              = []
                this.itemTypeDiscounts      = []
                this.type_list              = []
                this.override_user_id       = -1
                this.override_amount        = ''
                this.override_index         = ''
                this.override_username      = ''
                this.checked_by             = ''
                this.prepared_by            = ''
                this.prepared_by_2          = ''
                this.noted_by               = ''
                this.tempOrderList          = []
                this.promo_id               = ''
                this.promo_name             = ''
                this.delivery_method        = ''
            },
            addKeyListener(){
                window.addEventListener("keyup", function(e) {
                    var keypressed = String.fromCharCode(e.keyCode)
                    if(e.ctrlKey && e.keyCode == 32){
                        this.addOrderItem()
                        $("input").blur();

                    }else if(keypressed == '-'){
                        this.removeOrderItem()
                        $("input").blur();
                    }

                }.bind(this));
            },

        },
        mounted: function (){
            this.addKeyListener()
            //  $('input').attr('autocomplete','off');
        },
        watch:{
            add_edit_packinglist_dialog:function(){
                if(this.add_edit_packinglist_dialog == true){
                    if(this.action == 'Add'){
                        this.orderList = []
                        this.getCustomers();
                        this.getUsers();
                        this.getSalesmanList();
                        this.getItems();
                        this.getItemTypes();
                        this.loaded = true
                    }

                    if(this.customer.discounts.length == 0){
                        this.addDiscount();
                    }

                    if(this.orderList.length == 0){
                        this.addOrderItem();
                    }
                }
            },
            customer_id:function(value){
                if(this.add_edit_packinglist_dialog == true){
                    this.loaded = false
                    if(value > 0){
                        this.$store.dispatch('showCustomers',value).then(response => {
                            let data = response.data

                            this.shipping_address_list          = data.all_shipping_addresses
                            // this.salesman_id                    = data.salesman_id
                            // this.terms                          = data.terms
                            this.customer.is_sp                 = data.special

                            if(this.discounts != ''){
                                if(this.discounts == 'oldData'){
                                    this.customer.discounts         = []
                                    this.customer.discounts.push({
                                        pt: '',
                                        ht: '',
                                        sp: '',
                                    })
                                }else{
                                    this.customer.discounts         = this.discounts
                                }

                                this.discounts = ''
                            }else if(data.discounts != ''){
                                this.customer.discounts         = JSON.parse(data.discounts)
                            }

                            // if(this.fromShowEditModal == false && this.orderList != null && this.orderList.length > 0){
                            //     this.orderList.forEach((order, index)=>{
                            //         this.selectModel(index, order.item_id, false)
                            //     })
                            // }else{
                            //     this.fromShowEditModal = false
                            // }

                            // this.computedDiscPrice()
                            this.loaded = true

                        }).catch(error => {

                        });
                    }
                }
            },
            orderList:{
                deep: true,
                handler(){
                    if(this.orderList.length > 0){
                        this.orderList.forEach((order, index)=>{
                            // if(order.item.srp != null && order.item.srp != ''){
                            //     order.item.display_srp = this.getTwoDecimals(order.item.srp)
                            // }else(
                            //     order.item.display_srp = ''
                            // )
                            if(order.srp != null && order.srp != ''){
                                order.item.display_srp = this.getTwoDecimals(order.srp)
                            }else(
                                order.item.display_srp = ''
                            )

                            if(order.disc_price != null && order.disc_price != ''){
                                order.display_disc_price = this.getTwoDecimals(order.disc_price)
                            }else(
                                order.display_disc_price = ''
                            )

                            if(order.total_amount != null && order.total_amount != ''){
                                order.display_total_amount = this.getTwoDecimals(order.total_amount)
                            }else(
                                order.display_total_amount = ''
                            )

                            if(order.override_amount != null && order.override_amount != ''){
                                order.display_override_amount = this.getTwoDecimals(order.override_amount)
                            }else(
                                order.display_override_amount = ''
                            )
                        })
                    }
                    this.$forceUpdate()
                }
            }
        },
    };
</script>
