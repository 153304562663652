<template>
  <div class="tabs-body-color">
        <v-card class="m-10 px-3">
            <v-card-text>
                <v-row>
                    <v-col lg="5">
                        <v-autocomplete
                            v-model="filters.users.selected"
                            :items="GET_ALL_USER"
                            outlined
                            dense
                            small-chips
                            deletable-chips
                            label="Select User"
                            multiple
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col lg="5">
                        <v-autocomplete
                            v-model="filters.department.selected"
                            :items="GET_LIST_DEPARTMENT"
                            outlined
                            dense
                            item-value="value"
                            item-text="text"
                            small-chips
                            deletable-chips
                            label="Select Department"
                            multiple
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col lg="1">
                        <v-btn
                            @click="getWarehousesUsers()"
                        >
                            filter
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="12">
                        <v-data-table
                            :items="users.selection"
                            :headers="dataTable.headers"
                            :loading="!loaded"
                            :options.sync="dataTable.options"
                            :server-items-length="users.total"
                            dense
                        >
                            <template v-slot:[`item.warehouses`]="{ item }">
                                <span>

                                    <v-btn
                                        text
                                        @click="view(item)"
                                    >
                                    <v-badge
                                    :color="item.warehouses.length > 0 ? 'primary' : ''"
                                    :content="item.warehouses.length"
                                    bordered
                                >
                                    <v-icon :color="item.warehouses.length > 0 ? 'primary' : ''">mdi-warehouse</v-icon>
                                </v-badge>

                                    </v-btn>
                                </span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog width="700px" v-model="WAREHOUSE_USER.DIALOG">
            <warehouse-user-add-component :from-view="true"></warehouse-user-add-component>
        </v-dialog>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import WarehouseUserAddComponent from './WarehouseUserAddComponent.vue'
export default {
    components:{
        WarehouseUserAddComponent

    },
  data() {
    return {
      warehouses: {
        selection: null,
        selected: null,
        service: "0",
        noneService: "0",
        all: "0",
        dialog:false
      },
      users: {
        selection: [],
        selected: [],
        limit: 10,
        search: "",
        all:'0',
        total:0
      },
      filters:{
        users:{
            selection:[],
            selected:[]
        },
        department:{
            selected:[]
        }
      },

      loaded: true,
      settings: [],
      add: true,
      dataTable:{
        headers:[
            { text:'Name', value:'name'},
            { text:'Department', value:'department'},
            { text:'Job Title',value:'job_title'},
            { text:'Warehouses', value:'warehouses'}
        ],
        options:{}
      }
    };
  },
  created() {
    this.getWarehousesUsers();
    this.$store.dispatch('getAllSelectUsers').then(r=>this.filters.users.selection = r)
    this.$store.dispatch('getDepartment')
  },

  methods: {
    async getWarehousesUsers() {
      this.loaded = false;
      this.users.selection = []
      this.$store.commit('WAREHOUSE_USER_REFRESH',false)
      await this.$store
        .dispatch("warehouseUser", {
          url: "warehouseUser",
          method: "get",
          search_user:this.users.search,
          departments:this.filters.department.selected,
          users:this.filters.users.selected,
          page:this.dataTable.options.page,
          itemsPerPage:this.dataTable.options.itemsPerPage,
        })
        .then((response) => {
          this.users.selection = response.data.data.map((e) => {
            e.selected = "0";
            return e;
          });
          this.users.total = response.data.total
        })
        .catch((e) => {
          console.log(e);
        });
      this.loaded = true;
      return false;
    },
    filter(){
        this.getWarehousesUsers()
    },
    addMoreUser() {
      this.users.limit += 10;
      this.getWarehousesUsers();
    },
    resetWarehouse() {
      let warehouses = [];
      warehouses = this.warehouses.selection.map((e) => {
        e.selected = "0";
        return e;
      });
      this.$store.commit('WAREHOUSE_LIST',warehouses)
      this.warehouses = {
        selection: warehouses,
        selected: null,
        service: "0",
        noneService: "0",
        all: "0",
      };
    },
    getWarehouses(response) {
      this.warehouses.selection = response.map((e) => {
        e.selected = "0";
        return e;
      });
    },
    view(val){
        this.$store.commit('WAREHOUSE_USER_DIALOG',true)
        this.$store.commit('WAREHOUSE_USER_SELECTED',val)
    }
  },
  computed: {
    ...mapGetters(["GET_WAREHOUSE_LIST","USERS_LIST_LOAD","GET_LIST_DEPARTMENT","WAREHOUSE_USER","GET_ALL_USER"]),
  },
  watch: {
    GET_WAREHOUSE_LIST: {
      handler(val) {
        this.getWarehouses(val);
      },
      deep:true
    },
    'dataTable.options':{
        handler(val){
            this.getWarehousesUsers()
        },
        deep:true
    },
    'WAREHOUSE_USER.REFRESH':{
        handler(val){
            if(val){
                this.getWarehousesUsers()
            }
        }
    },
    "warehouses.service": {
      handler(val) {
        if (val == 0) {
          this.resetWarehouse();
        } else {
          this.warehouses.selection = this.warehouses.selection.map((e) => {
            if (e.service == 1) {
              e.selected = "1";
            }
            return e;
          });
          this.warehouses.selection = this.warehouses.selection.sort((a, b) => {
            return b.selected - a.selected;
          });
        }
      },
    },
    "warehouses.noneService": {
      handler(val) {
        if (val == 0) {
          this.resetWarehouse();
        } else {
          this.warehouses.selection = this.warehouses.selection.map((e) => {
            if (e.service != 1) {
              e.selected = "1";
            }
            return e;
          });
          this.warehouses.selection = this.warehouses.selection.sort((a, b) => {
            return b.selected - a.selected;
          });
        }
      },
    },
    "warehouses.all": {
      handler(val) {
        if (val == 0) {
          this.resetWarehouse();
        } else {
          this.warehouses.selection = this.warehouses.selection.map((e) => {
            e.selected = "1";
            return e;
          });
          this.warehouses.selection = this.warehouses.selection.sort((a, b) => {
            return a.id - b.id;
          });
        }
      },
    },

  },
};
</script>

