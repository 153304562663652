<template>
    <v-card>

        <v-container>
            <v-row>
                <v-col cols="12" lg="6">
                    <v-card-title class="d-flex justify-content-end pa-0 border-bottom border-right">
        <v-btn icon text @click="addPassword()">
            <v-icon  color="primary" >mdi-plus-circle</v-icon>
        </v-btn>
        </v-card-title>
                    <v-simple-table
                fixed-header
                height="300px"
                class="border-right"
            >
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                       Default Password
                    </th>
                    <th class="text-left">
                        Active
                    </th>
                    <th class="text-left">
                        Action
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(item,i) in form.password_list"
                    :key="i"
                    >
                    <td><v-text-field v-model="item.password" dense outlined ></v-text-field></td>
                    <td><v-switch v-model="item.active"  @change="findActive(i)" dense></v-switch></td>
                    <td>  <v-btn icon text @click="removePassword(i)">
                            <v-icon  color="red" >mdi-minus-circle</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>

                </v-col>

                <v-col cols="12" lg="6">
                 <v-text-field v-model="form.password_expiration" outlined dense label="password expiration">
                    <template #append>
                        (days)
                    </template>
                 </v-text-field>
                </v-col>
            </v-row>
            <v-container class="pa-5 border-top">
                <v-row class="d-flex justify-content-end pa-0">
                    <v-btn small dense @click="savePassword()">
                        SAVE
                    </v-btn>
                </v-row>
            </v-container>
        </v-container>

    </v-card>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        form:{
            password_list: [],
            password_trash: [],
            password_expiration:0,
        },
        disabled:false

      }
    },
    computed:{
        ...mapGetters(['GET_NEW_PASSWORD_DEFAULT','GET_GENERAL_SETTINGS'])
    },
    created(){
        if(this.form.password_list.length == 0){
                this.addPassword();
            }
    },
    mounted(){
        this.getAllPasswordlist();
    },
    methods:{
        addPassword(){
            this.form.password_list.push({
                id:'',
                password:'',
                active:0,
            })
        },
        removePassword(index){
            this.form.password_list.splice(index,1);
            if(this.form.password_list.length == 0){
                this.addPassword();
            }
        },
        getAllPasswordlist(){
            if(this.GET_GENERAL_SETTINGS.password_list.length > 0){
             this.form.password_list =this.GET_GENERAL_SETTINGS.password_list
             this.form.password_expiration = this.GET_GENERAL_SETTINGS.password_expiration
            }
        },
        savePassword(){
            if(this.form.password_list.find(e=>e.password == '')){
                swal.fire({
                    icon:'warning',
                    text:"There's have an empty password",
                })
            }else if(this.form.password_list.find(e=>e.active == true) == undefined){
                swal.fire({
                    icon:'warning',
                    text:"Please active atleast one on the switch.",
                })
            }
            else{
                this.$store.dispatch('passwordDefault',this.form);
            }
        },
        findActive(i){
        this.form.password_list.find((e,x)=>{
            if(x == i){
                e.active = true
            }else{
                e.active = false
            }
         });
        }
    },
    watch:{
        GET_NEW_PASSWORD_DEFAULT:{
            handler(val){
                if(val){
                    swal.fire({
                    icon:'success',
                    text:"Password Settings Saved!",
                    })
                    this.$store.commit('NEW_PASSWORD_DEFAULT',false);
                }
            }
        }
    }
  }
</script>
