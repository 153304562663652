<template>
<v-app>
    <v-container fluid >
        <h3 class="page-title" style="color: black">General Settings</h3>
        <v-container class="ma-0">
            <v-card >
                <v-card-text style="height:800px">
                    <v-container>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <span class="font-weight-bold">Password Settings</span>
                                <PasswordDefaultList></PasswordDefaultList>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
            </v-card>
        </v-container>
    </v-container>
</v-app>
</template>

  <script>
import PasswordDefaultList from '@/views/main/SystemSettings/OtherComponents/PasswordDefaultListComponent.vue'
  import { mapGetters } from 'vuex';
  export default {
    components:{
        PasswordDefaultList
    },
    data() {
        return {

        };
    },
    computed:{
        ...mapGetters([])
    },
    mounted(){

    },
    methods:{

    },
    watch:{

    }

  };
  </script>
