<template>
  <v-card elevation="20">
    <v-col class="d-flex no-block align-items-center justify-content-start pa-2" v-if="userAccess.create">
      <!-- <buttons
        :action="buttons.actions.create"
        :button_icon="buttons.icons.create"
        :color="buttons.colors.create"
        @click="adddialog"
      ></buttons> -->
      <div class="row" v-if="userAccess.create">
        <div class="col-12 d-flex no-block align-items-center">
            <div class="btn-toolbar">
                <v-btn @click="adddialog()">
                    <v-icon>mdi-plus</v-icon>
                    Add
                </v-btn>
            </div>
        </div>
      </div>
    </v-col>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
      <v-icon @click="searchInput()">'mdi-trash-can</v-icon>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="GET_ALL_VENDOR_TYPE_TABLE"
      :search="search"
      :items-per-page="10"
      class="w-100"
      :multi-sort="true"
      :loading="!GET_VENDOR_TYPE_LOADER"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
          v-if="userAccess.view"
          :action="buttons.actions.view"
          :button_icon="buttons.icons.view"
          :color="buttons.colors.view"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        ></buttons>
        <buttons
          v-if="userAccess.edit"
          :action="buttons.actions.edit"
          :button_icon="buttons.icons.edit"
          :color="buttons.colors.edit"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        ></buttons>
        <buttons
          v-if="userAccess.delete"
          :action="buttons.actions.delete"
          :button_icon="buttons.icons.delete"
          :color="buttons.colors.delete"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="deleteVendorType(item.id)"
        ></buttons> -->
        <v-btn text icon color="orange" v-if="userAccess.view" >
          <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
        </v-btn>
        <v-btn text icon color="blue" v-if="userAccess.edit" >
            <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn text icon color="red" v-if="userAccess.delete" >
            <v-icon class="btn-action" small @click="deleteVendorType(item.id)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
export default {
  props: ['cp_text'],
  // components: { buttons, buttons_components },
  data() {
    return {
      // buttons: buttons_components.buttons,
      loaded: false,
      totalCustomers: 0,
      options: {},
      customers: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 10,
      search: '',
      awaitingSearch: false,
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Code', align: 'left', value: 'code' },
        { text: 'Description', align: 'left', value: 'description' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          },
        ],
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      status_text: this.cp_text,
      selected: {},
      item: [],
    }
  },
  mounted() {
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    this.$store.dispatch('getVendorTypeTable')
  },
  methods: {
    viewdialogs(item) {
      this.$store.commit('EDIT_VENDOR_TYPE', item);
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'View')
    },
    editdialogs(item) {
      this.$store.commit('EDIT_VENDOR_TYPE', item);
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'Update')
    },
    adddialog() {
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'Submit')
    },
    deleteVendorType(id) {
      Swal.fire({
        text: 'Are you sure you want to Delete?',
        icon: 'warning',
        showCancelButton: true,
         confirmButtonColor: '#397373',
        cancelButtonColor: '#397373'
      }).then(confirm => {
        if (confirm.isConfirmed) {
          this.$store.dispatch('deleteVendorType', id);
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      'DIALOGS',
      'GET_ALL_VENDOR_TYPE_TABLE',
      'ACTION',
      'USER_ACCESS',
      'NEW_vendortype',
      'GET_VENDOR_TYPE_LOADER',
    ]),
  },
  watch: {
    NEW_vendortype: {
      handler(val) {
        if (val) {
          this.$store.commit('DIALOG', false);
          Swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
          this.$store.dispatch('getVendorTypeTable')
          this.$store.commit('NEW_vendortype', false);
        }
      },
      deep: true,
    },
    USER_ACCESS: {
      handler(val) {
       if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
           this.userAccess.create = false;
        val.forEach(e => {
          if (e.actions_code == 'create') {
            this.userAccess.create = true;
          } else if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
            },
      }
    },
  }
</script>
