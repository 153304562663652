<template>
    <div>
        <add-bank-component />

        <v-card >
            <v-card-title>
                <v-spacer />
                <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                >
                </v-text-field>
                <v-icon>fas fa-search</v-icon>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="banks"
                    :search="search"
                    :items-per-page="20"
                    :loading="loading"
                    :footer-props="{
                        showFirstLastPage: true,
                    }"
                >
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn text icon color="orange" v-if="userAccess.view">
                            <v-icon class="btn-action" small @click="viewBank(item.id)">mdi-eye</v-icon>
                        </v-btn>
                        <v-btn text icon color="blue" v-if="userAccess.edit">
                            <v-icon class="btn-action" small @click="editBank(item.id)">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon class="btn-action" small @click="deleteBank(item.id)">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="viewBankDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Bank Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewBankDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="px-4 pb-4">
                    <div><span>Code:</span><span>{{ code }}</span></div>
                    <div><span>Name:</span><span>{{ name }}</span></div>
                    <div><span>Description:</span><span>{{ description }}</span></div>
                    <div><span>Address:</span><span>{{ address }}</span></div>
                    <div><span>Allowed Payments:</span><span>{{ payments }}</span></div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddBankComponent from '../BanksComponent/AddBankComponent.vue';
import swal from 'sweetalert2';

export default {
    data () {
        return {
            actionResult : {
                model : 'banks',
                action : ''
            },
            loaded: false,
            banks: [],
            itemsPerPageOptions: [5, 10 ,15, 20],
            itemsPerPage: 20,
            search: '',
            headers: [
                { text: 'Code', value: 'code' },
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Address', value: 'address' },
                { text: 'Actions', value: 'action' },
            ],
            userAccess: {
                view: false,
                edit: false,
                delete: false,
            },
            loading: true,

            viewBankDialog: false,
            bankDetails: null,
            code: '',
            name: '',
            description: '',
            address: '',
            viewBankId: '',
            payments: [],
        }
    },
    components: {
        AddBankComponent
    },
    props: [],
    mounted() {
        this.getAllBanks();
        this.$store.dispatch('getPaymentTypes')
    },
    computed: {
        successAddEdit() {
            return this.$store.state.accounting.bank.successAddEdit;
        },
        bankId() {
            return this.$store.state.accounting.bank.bankId;
        },
        ...mapGetters([
            'USER_ACCESS',
            'GET_PAYMENT_TYPES'
        ])
    },
    methods:{
        clearFields() {
            this.code = "";
            this.name = "";
            this.description = "";
            this.address = "";
            this.viewBankId = "";
            this.payments = [];
        },
        getAllBanks(){
            let payload = {
                url: 'getBanks'
            }
            this.$store.dispatch('banksGet',payload).then(response => {
                this.banks = response.data
            })
        },
        viewBank(id){
            this.viewBankId = id;
            this.viewBankDialog = true;
        },
        closeViewBankDialog() {
            this.clearFields();
            this.viewBankDialog = false;
        },
        getBankDetails() {
            let payload = {
                url: `getBankDetails/${this.viewBankId}`
            }
            this.$store.dispatch('banksGet',payload).then(response=>{
                this.bankDetails = response.data;
            });
        },
        editBank(id){
            this.$store.commit('bankSetId', id);
        },
        deleteBank(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        url: 'deleteBank',
                        bankId: id
                    }
                    this.$store.dispatch('banksPost',payload).then(response => {
                        if(response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            this.getAllBanks();
                            swal.fire('', response.data.msg, 'success');
                        }
                    })
                }
            });
        },
    },
    watch: {
        banks() {
            if(this.banks.length > 0) {
                this.loading = false;
            }
            else{
                this.loading = false;
            }
        },
        successAddEdit() {
            if(this.successAddEdit) {
                this.getAllBanks();
            }
        },
        bankId() {
            if(!!this.bankId) {
                this.$store.commit('bankDialogAction', 'edit');
                this.$store.commit('bankShowAddEditDialog');
            }
        },
        viewBankId() {
            if(!!this.viewBankId) {
                this.getBankDetails();
            }
        },
        bankDetails() {
            if(!!this.bankDetails) {
                const bank = this.bankDetails;
                this.code = bank.code;
                this.name = bank.name;
                this.description = bank.description;
                this.address = bank.address;

                let payment_ids = bank.bank_payment_types.map(e=>{return e.payment_type_id})
                // let payment_
                // payment_ids.forEach(e=>{
                //     if(this.GET_PAYMENT_TYPES)
                // })
               this.GET_PAYMENT_TYPES.forEach(e=>{
                    payment_ids.forEach(x=>{
                        if(x == e.id){
                            this.payments.push(e.name)
                        }
                    })
                })

                this.payments = this.payments.toString();

            }
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "edit") {
                            this.userAccess.edit = true;
                        }
                        if (e.actions_code == "view") {
                            this.userAccess.view = true;
                        }
                        if (e.actions_code == "delete") {
                            this.userAccess.delete = true;
                        }
                    });
                }
            }
        }
    },
};
</script>
