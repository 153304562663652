<template>
    <v-container fluid>
        <ComponentHeader class="ma-n4" :button="true" title='Net A/R Report' type='Accounting'></ComponentHeader>
        <v-row>
            <v-app id="my_vue_app" style="width:100%" class="mx-4 bg-bluish-gray pa-3 px-5">
                <v-row dense class="my-2">
                    <v-col sm="12" md="5">
                        <v-autocomplete :items="GET_CUSTOMERS_SELECTION" :loading="!GET_CUSTOMERS_SELECTION"
                            :disabled="!GET_CUSTOMERS_SELECTION" v-model="filters.customers" label="Select Customers"
                            class="d-inline-block w-100" outlined dense multiple deletable-chips
                            return-object background-color="white" small-chips hide-details clearable>
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete :items="GET_CLASSIFICATIONS_SELECTION" :loading="!GET_CLASSIFICATIONS_SELECTION"
                            :disabled="!GET_CLASSIFICATIONS_SELECTION" v-model="filters.customer_classifications" label="Select Classification"
                            class="d-inline-block w-100" outlined dense multiple deletable-chips
                            return-object background-color="white" small-chips hide-details clearable>
                        </v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="2">
                        <v-autocomplete :items="GET_SALESMAN_SELECTION" :loading="!GET_SALESMAN_SELECTION"
                            :disabled="!GET_SALESMAN_SELECTION" v-model="filters.salesman" label="Select Salesman"
                            class="d-inline-block w-100" outlined dense multiple deletable-chips
                            return-object background-color="white" small-chips hide-details clearable>
                        </v-autocomplete>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col sm="12" md="2" class="text-right">
                        <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                        <v-btn small class="ml-1" @click="exportToExcel()">Excel</v-btn>
                        <v-btn small class="ml-1" @click="getAllCustomerCollections()">Filter</v-btn>
                    </v-col>
                </v-row>
                <v-row dense class="d-flex flex-row">
                    <v-col sm="12" md="2" class="d-flex flex-row">
                        <v-menu
                            v-model="menu.date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker type="month" v-model="filters.date_from" @click:month="menu.date_from = false"></v-date-picker>
                        </v-menu>
                        <v-menu
                            v-model="menu.date_to"
                            :close-on-content-click="false"

                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    prepend-inner-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-on="on"
                                    style="display: block"
                                    background-color="white"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker type="month" v-model="filters.date_to" @click:month="menu.date_to = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="12" md="1">
                        <v-checkbox
                            v-model="filters.remaining_only"
                            label="Remaining Only"
                            color="white"
                            :true-value="1"
                            :false-value="null"
                            dense
                            hide-details
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-app>
        </v-row>
        <v-row class="w-100 pa-2 bg-bluish-gray ml-0">
            <v-card class="w-100">
                <v-card-text>
                    <div class="row">
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p style="color:black" class="mb-0"><b>Total SOA Amount</b></p>
                                        <b style="color:black">&#x20b1;  {{ grand_total_soa_amount | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p style="color:black" class="mb-0"><b>Total Paid Amount</b></p>
                                        <b style="color:black">&#x20b1;  {{ grand_total_paid_amount | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p style="color:black" class="mb-0"><b>Total Collectibles</b></p>
                                        <b style="color:black">&#x20b1;  {{ grand_total_collectibles | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p style="color:black" class="mb-0"><b>Total PDCs</b></p>
                                        <b style="color:black">&#x20b1;  {{ grand_total_pdcs | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p style="color:black" class="mb-0"><b>Total Overdue Amount</b></p>
                                        <b style="color:black">&#x20b1;  {{ grand_total_overdue_amount | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                            <v-card class="total-cards" elevation="2">
                                <v-card-text class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-2 pb-2 pl-1 pr-1 rounded bg-warning">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p style="color:black" class="mb-0"><b>Customers</b></p>
                                        <b style="color:black">{{ grand_total_customers }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col> -->
                    </div>
                    <v-divider></v-divider>
                    <v-data-table
                        :headers="period.headers"
                        :items="period.items"
                        :loading="period.loading"
                        :expanded-sync="period.expanded"
                        :items-per-page="15"
                        item-key="period"
                        show-expand
                        single-expand
                        dense
                    >
                        <template v-slot:[`item.total_soa_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_soa_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.total_paid_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_paid_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.total_collectibles`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_collectibles | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.total_pdcs`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_pdcs | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.total_overdue_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_overdue_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-data-table
                                    :headers="customer_classification.headers"
                                    :items="item.customer_classifications"
                                    :loading="customer_classification.loading"
                                    :expanded-sync="customer_classification.expanded"
                                    item-key="classification_id"
                                    show-expand
                                    single-expand
                                    dense
                                >
                                    <template v-slot:[`item.total_soa_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.total_soa_amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.total_paid_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.total_paid_amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.total_collectibles`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.total_collectibles | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.total_pdcs`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.total_pdcs | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.total_overdue_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.total_overdue_amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length">
                                            <v-data-table
                                                :headers="customer.headers"
                                                :items="item.customers"
                                                :loading="customer.loading"
                                                :expanded-sync="customer.expanded"
                                                item-key="customer_id"
                                                dense
                                            >
                                                <template v-slot:[`item.total_soa_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.total_soa_amount | currency }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`item.total_paid_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.total_paid_amount | currency }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`item.total_collectibles`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.total_collectibles | currency }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`item.total_pdcs`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.total_pdcs | currency }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`item.total_overdue_amount`]="{ item }">
                                                    <span class="text-nowrap">
                                                        &#x20b1;{{ item.total_overdue_amount | currency }}
                                                    </span>
                                                </template>

                                                <template v-slot:[`item.action`]="{ item }">
                                                    <span class="text-nowrap">
                                                        <v-btn text icon color="orange">
                                                            <v-icon class="btn-action" small
                                                                @click="viewCustomerSoaAndPayments(item)">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </span>
                                                </template>
                                            </v-data-table>
                                        </td>
                                    </template>
                                </v-data-table>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>

        <v-dialog v-model="custSoaAndPaymentDialog" persistent max-width="98%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Customer SOA And Payments</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeCustSoaAndPaymentDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout row class="mx-0 px-4 pb-4">
                    <v-flex lg6 class="pr-2">
                        <div class="pb-4">
                            Statement of Accounts
                        </div>
                        <v-card>
                            <v-data-table :headers="soaHeaders" :items="soaItems">
                                <template v-slot:[`item.gross_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.gross_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.paid_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.paid_amount | currency }}
                                    </span>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-flex>

                    <v-flex lg6>
                        <div class="pb-4">
                            Payments
                        </div>
                        <v-card>
                            <v-data-table :headers="cpHeaders" :items="cpItems">
                                <template v-slot:[`item.gross_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.gross_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.wht_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.wht_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.other_adj_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.other_adj_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.net_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.net_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.allocated_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.allocated_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.remaining_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.remaining_amount | currency }}
                                    </span>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'

export default {
    data() {
        return {
            period: {
                headers: [
                    { text: 'Period', width: '15%', value: 'period' },
                    { text: 'Total SOA Amt', width: '15%', align: 'end', value: 'total_soa_amount' },
                    { text: 'Total Paid Amt', width: '15%', align: 'end', value: 'total_paid_amount' },
                    { text: 'Total Collectibles', width: '15%', align: 'end', value: 'total_collectibles' },
                    { text: 'Total PDCs', width: '15%', align: 'end', value: 'total_pdcs' },
                    { text: 'Total Overdue Amt', width: '15%', align: 'end', value: 'total_overdue_amount' },
                    { text: '', align: 'end', value: 'actions' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },

            customer_classification: {
                headers: [
                    { text: 'Classification Name', value: 'classification_name' },
                    { text: 'Total SOA Amount', width: '10%', align: 'end', value: 'total_soa_amount' },
                    { text: 'Total Paid Amt', width: '15%', align: 'end', value: 'total_paid_amount' },
                    { text: 'Total Collectibles', width: '15%', align: 'end', value: 'total_collectibles' },
                    { text: 'Total PDCs', width: '15%', align: 'end', value: 'total_pdcs' },
                    { text: 'Total Overdue Amt', width: '15%', align: 'end', value: 'total_overdue_amount' },
                    { text: 'Customer Count', width: '10%', value: 'customer_count' },
                    { text: '', value: 'actions' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },

            customer: {
                headers: [
                    { text: 'Customer Name', value: 'customer_name' },
                    { text: 'Total SOA Amount', width: '10%', align: 'end', value: 'total_soa_amount' },
                    { text: 'Total Paid Amt', width: '15%', align: 'end', value: 'total_paid_amount' },
                    { text: 'Total Collectibles', width: '15%', align: 'end', value: 'total_collectibles' },
                    { text: 'Total PDCs', width: '15%', align: 'end', value: 'total_pdcs' },
                    { text: 'Total Overdue Amt', width: '15%', align: 'end', value: 'total_overdue_amount' },
                    { text: 'SOA Count', width: '10%', value: 'soa_count' },
                    { text: 'Action', width: '10%', value: 'action' },
                ],
                items: [],
                expanded: [],
                loading: false,
            },

            custSoaAndPaymentDialog: false,

            customerId: '',

            menu: {
                date_from: false,
                date_to: false,
            },

            filters: {
                date_from: moment().startOf('year').format('YYYY-MM'),
                date_to: moment().endOf('year').format('YYYY-MM'),
                customers: [],
                customer_classifications: [],
                salesman: [],
                remaining_only: 1,
            },

            soaHeaders: [
                { text: 'SOA ID', value: 'id' },
                { text: 'Gross Amount', value: 'gross_amount' },
                { text: 'Total Amount', value: 'total_amount' },
                { text: 'Paid Amount', value: 'paid_amount' },
                { text: 'Confirmed At', value: 'confirmed_at' },
            ],
            soaItems: [],

            cpHeaders: [
                { text: 'SOA ID', value: 'soa_id' },
                { text: 'REF#', value: 'reference_number' },
                { text: 'Payment Date', value: 'payment_date' },
                { text: 'Gross Amount', value: 'gross_amount' },
                { text: 'WHT Amount', value: 'wht_amount' },
                { text: 'ADJ Amount', value: 'other_adj_amount' },
                { text: 'Net Amount', value: 'net_amount' },
                { text: 'Allocated Amount', value: 'allocated_amount' },
                { text: 'Remaining Amount', value: 'remaining_amount' },
            ],
            cpItems: [],
            exportName: `Net A/R Report ${moment().format('YYYY-MM-DD')}.csv`,
            exportFields: {
                'Customer Name': 'company_name',
                'SOA Total Amount': 'soa_total_amount',
                'SOA Total Paid Amount': 'soa_total_paid_amount',
                'SOA Total Balance': 'soa_total_balance',
                'Total Unallocated Payment': 'cp_remaining_amount',
                'With Unallocated Payment': 'with_balance',
            }
        }
    },
    components: {
        ComponentHeader
    },
    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getAllcustomerClassificationSelection')
        this.$store.dispatch('getAllsalesmanSelection')
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_CLASSIFICATIONS_SELECTION',
            "GET_SALESMAN_SELECTION",
        ]),
        grand_total_soa_amount() {
            return this.period.items.reduce((p, e) => p + e.total_soa_amount, 0)
        },
        grand_total_paid_amount() {
            return this.period.items.reduce((p, e) => p + e.total_paid_amount, 0)
        },
        grand_total_collectibles() {
            return this.period.items.reduce((p, e) => p + e.total_collectibles, 0)
        },
        grand_total_pdcs() {
            return this.period.items.reduce((p, e) => p + e.total_pdcs, 0)
        },
        grand_total_overdue_amount() {
            return this.period.items.reduce((p, e) => p + e.total_overdue_amount, 0)
        },
        grand_total_customers() {
            return 0
        },
    },
    methods: {
        resetFields() {
            this.filters.customers = [];
            this.filters.customer_classifications = [];
            this.filters.salesman = [];
            this.getAllCustomerCollections();
        },
        getAllCustomerCollections() {
            this.period.items = []
            this.period.expanded = []
            this.customer_classification.items = []
            this.customer_classification.expanded = []
            this.customer.items = []
            this.customer.expanded = []

            this.period.loading = true

            let payload = {
                selectedCustomers: this.filters.customers,
                selectedCustomerClassifications: this.filters.customer_classifications,
                selectedSalesman: this.filters.salesman,
                date_from: this.filters.date_from,
                date_to: this.filters.date_to,
                remaining_only: this.filters.remaining_only,
                url: "getAllCustomerCollections",
            }
            this.$store.dispatch('reportsPost', payload).then(response => {
                this.period.items = response.data
                this.period.loading = false
            })
        },
        viewCustomerSoaAndPayments(item) {
            this.customerId = item.customer_id;
            this.custSoaAndPaymentDialog = true;

            this.getCustomerSoas(item.customer_id, item.period);
            this.getCustomerCpds(item.customer_id, item.period);
        },
        closeCustSoaAndPaymentDialog() {
            this.customerId = '';
            this.soaItems = [];
            this.cpItems = [];
            this.custSoaAndPaymentDialog = false;
        },
        getCustomerSoas(customerId, period) {
            let payload = {
                period: period,
                url: "getCustomerSoas/" + customerId,
                remaining_only: this.filters.remaining_only,
            }
            this.$store.dispatch('reports', payload).then(response => {
                this.soaItems = response.data;
            });
        },
        getCustomerCpds(customerId, period) {
            let payload = {
                period: period,
                url: "getCustomerCpds/" + customerId,
                remaining_only: this.filters.remaining_only,
            }
            this.$store.dispatch('reports', payload).then(response => {
                this.cpItems = response.data;
            });
        },
        exportToExcel(){
            const ExcelJS = require('exceljs')
            const workbook = new ExcelJS.Workbook();

            const worksheet = workbook.addWorksheet('Raw Data')

            worksheet.columns = [
                { header: 'Period', key: 'period' },
                { header: 'Customer Name', key: 'customer_name' },
                { header: 'Total SOA Amount', align: 'end', key: 'total_soa_amount',style: { numFmt: '#,##0.00' } },
                { header: 'Total Paid Amt', align: 'end', key: 'total_paid_amount',style: { numFmt: '#,##0.00' } },
                { header: 'Total Collectibles', align: 'end', key: 'total_collectibles',style: { numFmt: '#,##0.00' } },
                { header: 'Total PDCs', align: 'end', key: 'total_pdcs',style: { numFmt: '#,##0.00' } },
                { header: 'Total Overdue Amt', align: 'end', key: 'total_overdue_amount',style: { numFmt: '#,##0.00' } },
                { header: 'SOA Count', key: 'soa_count',style: { numFmt: '#,##0' } },
            ]

            this.period.items.forEach((e,i) => {
                e.customer_classifications.forEach(x=>{
                    x.customers.forEach(y=>{
                        y.period = this.formatMonthSoa(y.period)
                        worksheet.addRow(y)
                    })
                })
            });

            worksheet.getRow(`0${1}`).font = { bold: true }

            let exportName = `NET A/R Report`
            // this.paymentVouchers.loading = false
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                saveAs(blob, `${exportName}.xlsx`);
            });
        },
        formatMonthSoa(value) {
            if (value) {
                return moment(String(value)).format('MMM YYYY')
            }
        },
    },
}
</script>

<style>

</style>
