<template>
  <v-container fluid class="d-none">
        <v-card>
        <div>
            <table id="div_logo">
                <tbody>
                    <tr>
                        <td><p><span style="font-size: 18px; font-weight: bold;">D GREAT EAST INDUSTRIAL SUPPLY CORPORATION</span></p></td>
                    </tr>
                    <tr>
                        <td>420 Del Monte Ave., Brgy Siena, Quezon City<br>Contact us ; Tel. No: (02) 8711-6742 / 7717-4059</td>
                    </tr>
                    <tr>
                        <td><p><span style="font-size: 18px; font-weight: bold;">STATEMENT OF ACCOUNT FOR CREDIT MEMO</span></p></td>
                    </tr>
                </tbody>
            </table>
        </div>
            <div>
                <table id="soa_date">
                    <tbody>
                        <tr>
                            <td>CMC Date:</td>
                            <td>{{creditMemoChinaPrintData.credit_memo_date }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-for="(n, x) in creditMemoChinaPrintData.cmPageCountChina" :key="x">
                {{ 'deductions_china_'+x }}
                <table :id="'deductions_china_'+x">
                    <thead>
                        <tr>
                            <th>CM#</th>
                            <th>CM DATE</th>
                            <th>REMARKS</th>
                            <th>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody v-if="creditMemoChinaPrintData.items.length > 0">
                        <tr v-for="(credit_memo, index) in creditMemoChinaPrintData.cm_items_array_china[x]" :key="index">
                            <td>{{ credit_memo.credit_memo_num }}</td>
                            <td>{{ credit_memo.credit_memo_date }}</td>
                            <td>{{ credit_memo.remarks }}</td>
                            <td>${{ credit_memo.total_cost | currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-for="(n, x) in creditMemoChinaPrintData.cmPageCountLocal" :key="x">
                {{ 'deductions_local_'+x }}
                <table :id="'deductions_local_'+x">
                    <thead>
                        <tr>
                            <th>CM#</th>
                            <th>CM DATE</th>
                            <th>REMARKS</th>
                            <th>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody v-if="creditMemoChinaPrintData.items.length > 0">
                        <tr v-for="(credit_memo, index) in creditMemoChinaPrintData.cm_items_array_local[x]" :key="index">
                            <td>{{ credit_memo.credit_memo_num }}</td>
                            <td>{{ credit_memo.credit_memo_date }}</td>
                            <td>{{ credit_memo.remarks }}</td>
                            <td>${{ credit_memo.total_cost | currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table id="soa_total_china">
                    <tbody>
                        <tr>
                            <td>
                                <p>TOTAL: </p>
                            </td>
                            <td>
                                <p>$ {{ creditMemoChinaPrintData.total_cost_china | currency }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table id="soa_total_local">
                    <tbody>
                        <tr>
                            <td>
                                <p>TOTAL: </p>
                            </td>
                            <td>
                                <p>$ {{ creditMemoChinaPrintData.total_cost_local | currency }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table id="soa_grand_total">
                    <tbody>
                        <tr>
                            <td>
                                <p>GRAND TOTAL: </p>
                            </td>
                            <td>
                                <p>$ {{ creditMemoChinaPrintData.total_cost | currency }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table id="footer">
                    <tbody>
                        <tr>
                            <td>{{ USERACCOUNT_LOAD.name | uppercase}}<br>________________<br><br>Prepared By</td>
                            <td><br>________________<br><br>Received By</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-card>
        </v-container>
</template>

<script>
import jsPDF from 'jspdf';
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
            // credit_memo_china:{
            //     cmPageCount: this.cmPageCount,
            //     editedCreditMemoChinaId: 0,
            //     items:[],
            //     creditMemoChinaPrintData:[],
            //     cm_items_array:[],
            // }
        }
    },
    computed:{
        ...mapGetters([
            'USERACCOUNT_LOAD'
        ])
    },
    props:['creditMemoChinaPrintData'],
    mounted(){
        this.print()
    },
    methods:{
        print(){
            let doc = new jsPDF('p', 'mm', 'letter');

                if(this.creditMemoChinaPrintData.cm_items_array_local.length > 0){
                    this.creditMemoChinaPrintData.cmPageCountLocal++
                }
                if(this.creditMemoChinaPrintData.cm_items_array_china.length > 0){
                    this.creditMemoChinaPrintData.cmPageCountChina++
                }
                for (var i = 0; i < this.creditMemoChinaPrintData.cmPageCountChina-1; i++) {
                    doc.setFontSize(9).setFont(undefined, 'normal').text('Defective Items (Service) - For Replacement',20,46 );
                    doc.autoTable({
                        html: '#div_logo',
                        theme: 'plain',
                        margin: {top: 5, left: 6, right: 6},
                        columnStyles: {
                            0: {halign:'center'},
                        },
                        didParseCell: function(cell, val) {
                            if (cell.row.index === 0 || cell.row.index === 2) {
                                if(cell.styles != null){
                                    cell.styles.fontStyle = 'bold';
                                }

                            }
                        }
                    });
                    doc.autoTable({
                        html: '#soa_date',
                        startY: doc.autoTable.previous.finalY + 2,
                        theme: 'plain',
                        margin: {top: 5, left: 20, right: 20},
                        columnStyles: {
                            0: {cellWidth: 30, halign:'left'},
                            1: {cellWidth: 60, halign:'left'},
                        },
                    });
                    doc.setFontSize(8);

                    // if(i == 0){
                    //     doc.text(20,doc.autoTable.previous.finalY+4, 'CREDIT MEMO CHINA');
                    // }

                    doc.autoTable({
                        html: '#deductions_china_'+i,
                        margin: {left: 20, right: 20},
                        theme: 'grid',
                        headStyles: {
                            halign:'center',
                            fillColor: '#ffffff',
                            textColor: '#000000',
                            lineWidth: .2,
                            lineColor: '#000000',
                        },
                        styles: {
                            fontSize: 8
                        },
                        columnStyles: {
                            0: {cellWidth: 25, halign:'center',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                            1: {cellWidth: 25, halign:'center',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                            2: {cellWidth: 80, halign:'center',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                            3: {cellWidth: 45, halign:'right',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                        },
                    });

                    if(i == this.creditMemoChinaPrintData.cmPageCountChina - 2){
                        doc.autoTable({
                            html: '#soa_total_china',
                            theme: 'plain',
                            startY: 220,
                            margin: {top: 37, left: 20, right: 20},
                            columnStyles: {
                                0: {cellWidth: 150, halign:'right', textColor: '#000000'},
                                1: {cellWidth: 30, halign:'right', textColor: '#000000'},
                            }
                        });
                    }
                    doc.autoTable({
                        html: '#footer',
                        startY: 238,
                        theme: 'plain',
                        margin: {top: 0, left: 30, right: 30},
                        columnStyles: {
                            0: {cellWidth: 40, halign:'center'},
                            1: {cellWidth: 40, halign:'center'},
                        },
                    });

                    if(i != this.creditMemoChinaPrintData.cmPageCountChina - 2){
                        doc.addPage()
                    }
                }

                for (var i = 0; i < this.creditMemoChinaPrintData.cmPageCountLocal-1; i++) {
                    if(this.creditMemoChinaPrintData.cm_items_array_china.length > 0){
                        doc.addPage()
                    }

                    doc.setFontSize(9).setFont(undefined, 'normal').text('Defective Items (Service) - Dealer Stock',20,46 );
                    doc.autoTable({
                        html: '#div_logo',
                        theme: 'plain',
                        margin: {top: 5, left: 6, right: 6},
                        columnStyles: {
                            0: {halign:'center'},
                        },
                        didParseCell: function(cell, val) {
                            if (cell.row.index === 0 || cell.row.index === 2) {
                                if(cell.styles != null){
                                    cell.styles.fontStyle = 'bold';
                                }

                            }
                        }
                    });
                    doc.autoTable({
                        html: '#soa_date',
                        startY: doc.autoTable.previous.finalY + 2,
                        theme: 'plain',
                        margin: {top: 5, left: 20, right: 20},
                        columnStyles: {
                            0: {cellWidth: 30, halign:'left'},
                            1: {cellWidth: 60, halign:'left'},
                        },
                    });
                    doc.setFontSize(8);

                    // if(i == 0){
                    //     doc.text(20,doc.autoTable.previous.finalY+4, 'CREDIT MEMO CHINA');
                    // }

                    doc.autoTable({
                        html: '#deductions_local_'+i,
                        margin: {left: 20, right: 20},
                        theme: 'grid',
                        headStyles: {
                            halign:'center',
                            fillColor: '#ffffff',
                            textColor: '#000000',
                            lineWidth: .2,
                            lineColor: '#000000',
                        },
                        styles: {
                            fontSize: 8
                        },
                        columnStyles: {
                            0: {cellWidth: 25, halign:'center',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                            1: {cellWidth: 25, halign:'center',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                            2: {cellWidth: 80, halign:'center',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                            3: {cellWidth: 45, halign:'right',fillColor: '#ffffff',textColor: '#000000',lineWidth: .2,lineColor: '#000000'},
                        },
                    });

                    if(i == this.creditMemoChinaPrintData.cmPageCountLocal - 2){
                        doc.autoTable({
                            html: '#soa_total_local',
                            theme: 'plain',
                            startY: 220,
                            margin: {top: 37, left: 20, right: 20},
                            columnStyles: {
                                0: {cellWidth: 150, halign:'right', textColor: '#000000'},
                                1: {cellWidth: 30, halign:'right', textColor: '#000000'},
                            }
                        });
                    }
                    doc.autoTable({
                        html: '#footer',
                        startY: 238,
                        theme: 'plain',
                        margin: {top: 0, left: 30, right: 30},
                        columnStyles: {
                            0: {cellWidth: 40, halign:'center'},
                            1: {cellWidth: 40, halign:'center'},
                        },
                    });

                    if(i != this.creditMemoChinaPrintData.cmPageCountLocal - 2){
                        doc.addPage()
                    }
                }

                doc.autoTable({
                    html: '#soa_grand_total',
                    theme: 'plain',
                    startY: 225,
                    margin: {top: 10, left: 20, right: 20},
                    columnStyles: {
                        0: {cellWidth: 150, halign:'right', textColor: '#000000',fontSize: 11,fontStyle: 'bold',},
                        1: {cellWidth: 30, halign:'right', textColor: '#000000',fontSize: 11,fontStyle: 'bold',},
                    }
                });
                let pageCount = doc.internal.getNumberOfPages();

                for(let i = 0; i < this.creditMemoChinaPrintData.cmPageCountLocal + this.creditMemoChinaPrintData.cmPageCountChina; i++) {
                    doc.setPage(i);
                    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                    doc.setFontSize(8).setFont(undefined, 'normal').text('NO. '+pageCurrent + '/' + pageCount, doc.internal.pageSize.width - 46,39 );
                }
                if(this.creditMemoChinaPrintData.pdfAction == 'download'){
                    doc.save('StatementOfAccount ' + this.creditMemoChinaPrintData.editedCreditMemoChinaId + '.pdf');
                }else if(this.creditMemoChinaPrintData.pdfAction == 'print'){
                    this.$emit('getBase64CmChinaSoaPrint',doc.output('datauristring', 'StatementOfAccount ' + this.creditMemoChinaPrintData.editedCreditMemoChinaId + '.pdf'))
                    this.$emit('clearCmChinaPrintData');
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                }
            }
        },

}
</script>

<style>

</style>
