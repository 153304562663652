<template>
    <div class="page-wrapper">

        <v-app id="editItemSubstitutionsModel">
            <v-dialog v-model="add_item_substitutions_dialog" persistent max-width="60%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="subtitle-2">{{ item.model + ' - ' + item.name }}</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="add_item_substitutions_dialog = false; resetFields(); $emit('closeItemSubstitutionsModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text>
                        <form class="form-horizontal" action="/substitution_items" method="post"
                            v-on:submit.prevent="onSubmit">
                            <div class="card-body">
                                <div v-if="sub_items.length > 0">
                                    <div class="sub-item-list">
                                        <div class="sub-item" v-for="sub_item in sub_items"
                                            v-dragging="{ item: sub_item, list: sub_items, group: 'sub_item' }"
                                            :key="sub_item.id">
                                            <v-chip class="ma-2 sub-item-icon" color="default" label>
                                                <v-icon left>
                                                    fas fa-sort
                                                </v-icon>
                                                {{ sub_item.model }} - {{ sub_item.name }}
                                            </v-chip>
                                        </div>
                                    </div>

                                </div>
                                <div v-else>
                                    <p>New Models is empty</p>
                                </div>
                            </div>

                            <div class="border-top">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn v-on:click="saveItemSubstitutions()" v-if="sub_items.length > 0">Submit
                                    </v-btn>
                                </div>
                            </div>
                        </form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    props: [
        'substitution_item_id'
    ],
    data() {
        return {
            add_item_substitutions_dialog: false,
            sub_items: [],
            sub_item: {
                id: '',
                model: '',
                name: '',
                ranking: ''
            },
            item: {
                model: '',
                name: '',
            },
            errors: [],
            actionResult: {
                model: 'New Models',
                action: ''
            },
        }
    },
    mounted() {
        // this.$dragging.$on('dragged', ({ value }) => {
        // })
        // this.$dragging.$on('dragend', () => {

        // })
    },
    methods: {
        saveItemSubstitutions() {
            this.errors = []

            axios.post('/postItemSubstitutionRankings', {
                item_id: this.substitution_item_id,
                sub_items: this.sub_items,
            }).then(response => {
                this.add_item_substitutions_dialog = false
                this.action = 'success'
                this.resetFields()
                swal.fire("", response.data.msg, "success");
                this.$emit('closeItemSubstitutionsModal', 'success');
            }).catch(error => {
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors
                }
            });
        },
        resetFields() {
            this.errors = []
            this.sub_item = ''
            this.sub_items = []
        },

    },
    computed: {
        ...mapGetters([
            'GET_ITEM_SUBSTITUTION'
        ])
    },
    watch: {
        substitution_item_id: function (val) {

            if (val != null && val > 0) {
                this.loaded = false
                this.$store.dispatch('getItemSubstitution', val).then(response => {
                    var data = this.GET_ITEM_SUBSTITUTION
                    this.item = data.item
                    this.sub_items = data.item_substitutions
                    this.loaded = true
                    this.add_item_substitutions_dialog = true
                })
            }
        },
    },

};
</script>
<style scoped>
.sub-item-list,
.sub_item,
.sub-item-icon {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
</style>
