<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
      <v-icon>fas fa-search</v-icon>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="GET_ALL_DEPARTMENT"
        :search="search"
        :items-per-page="15"
        :loading="!GET_DEPARMENT_LOADED"
        :footer-props="{
            showFirstLastPage: true,
        }"
        class="w-100"
        loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.user_access`]="{ item }">
        <span v-for="user_access in item.user_access" :key="user_access.id"
          ><span class="badge bg-success text-white">{{ user_access.text }}</span
          >&nbsp;</span
        >
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <span class="badge bg-primary text-white" v-if="item.active">Yes</span>
        <span class="badge bg-danger text-white" v-else>No</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
          v-if="userAccess.view"
          :action="buttons.actions.view"
          :button_icon="buttons.icons.view"
          :color="buttons.colors.view"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        ></buttons> -->
        <!-- <buttons
          v-if="userAccess.edit"
          :action="buttons.actions.edit"
          :button_icon="buttons.icons.edit"
          :color="buttons.colors.edit"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        ></buttons>
        <buttons
           v-if="userAccess.delete"
          :action="buttons.actions.delete"
          :button_icon="buttons.icons.delete"
          :color="buttons.colors.delete"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="deleteDepartment(item.id)"
        ></buttons> -->
        <v-btn text icon color="orange" v-if="userAccess.view" >
            <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
        </v-btn>
        <v-btn text icon color="blue" v-if="userAccess.edit" >
            <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn text icon color="red" v-if="userAccess.delete" >
            <v-icon class="btn-action" small @click="deleteDepartment(item.id)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
export default {
  // components: { buttons, buttons_components },
  data() {
    return {
      // buttons: buttons_components.buttons,
      loaded: false,
      departments: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 15,
      search: '',
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Code', align: 'left', value: 'code' },
        { text: 'Manager', align: 'left', value: 'user_access' },
        { text: 'Active', align: 'left', value: 'active' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      viewDepartmentId: '',
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          },
        ],
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      selected: {},
    };
  },
  mounted() {
    this.$store.dispatch('getAllDepartment')
  },
  methods: {
    viewdialogs(item) {
      this.dialog = true;
      this.selected = item
      this.$store.commit("EDIT_DEPARTMENTS", item);
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "View");
    },
    editdialogs(item) {
      this.dialog = true;
      this.selected = item
      this.$store.commit('EDIT_DEPARTMENTS', item)
      this.$store.commit('DIALOG', true)
      this.$store.commit('ACTION', 'Update')
      this.action = 'edit'
    },
    deleteDepartment(id) {
      Swal.fire({
        icon: 'warning',
        text: 'Are you sure you want to Delete?',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#397373',
        cancelButtonColor: '#397373'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('DeleteDepartment', id).then(success => {
            Swal.fire({
              text: 'Delete Department Successful!',
              icon: 'success'
            });
          })
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      'DIALOGS',
      'GET_ALL_DEPARTMENT',
      'SELECTED_DEPARTMENT',
      'DIALOGS',
      'ACTION',
      'USER_ACCESS',
      'NEW_DEPARTMENT',
      'GET_DEPARMENT_LOADED',
    ]),
  },
  watch: {
    NEW_DEPARTMENT: {
      handler(val) {
        this.$store.dispatch('getAllDepartment')
      }
    },
    USER_ACCESS: {
      handler(val) {
        if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
           this.userAccess.create = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          } else if (e.actions_code == 'create') {
            this.userAccess.create = true;
          }
        });
        }
      }
    },
  },
};
</script>
