<template>
    <div class="w-100">
        <v-app id="load_bar" v-if="!GET_SALES_QOUTATION_LOADED">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>

        <v-app id="my_vue_app">
            <v-card elevation="4" v-show="GET_SALES_QOUTATION_LOADED">
                <v-row v-if="service == '0'" class="ma-3">
                    <v-col sm="2">
                        <v-autocomplete
                            v-model="customer_id"
                            :items="customers_list"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            label="Select Customer"
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="2">
                        <v-autocomplete
                            v-model="salesman_id"
                            :items="salesman_list"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            label="Select Salesman"
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="2">
                        <v-autocomplete
                            v-model="user_id"
                            :items="users_list"
                            dense
                            outlined
                            hide-details
                            auto-select-first
                            item-value="id"
                            item-text="name"
                            label="Select User"
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="12" md="1" class="d-flex align-items-end ml-2">
                        <v-btn @click="getAllSalesQuotations()">Filter</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="2" class="text-right" v-if="tab_name == 'is_all'">
                        <a href="#" :class="`ml-1 badge badge-all ${status_id == -1 ? `selected` : `non-selected`}`" @click="status_id = -1; getAllSalesQuotations()">all</a>
                        <a href="#" :class="`ml-1 badge badge-saved ${status_id == 0 ? `selected` : `non-selected`}`" @click="status_id = 0; getAllSalesQuotations()">saved</a>
                        <a href="#" :class="`ml-1 badge badge-converted ${status_id == 1 ? `selected` : `non-selected`}`" @click="status_id = 1; getAllSalesQuotations()">converted</a>
                        <!-- <a href="#" :class="`ml-1 badge badge-warning ${status_id == 2 ? `selected` : `non-selected`}`" @click="status_id=2; getAllSalesQuotations()">rejected</a> -->
                        <!-- <a href="#" :class="`ml-1 badge badge-danger ${status_id == 3 ? `selected` : `non-selected`}`" @click="status_id=3; getAllSalesQuotations()">canceled</a> -->
                        <!-- <a href="#" :class="`ml-1 badge badge-completed ${status_id == 4 ? `selected` : `non-selected`}`" @click="status_id=4; getAllSalesQuotations()">deleted</a> -->
                    </v-col>
                </v-row>

                <v-row class="ma-3">
                    <v-col sm="6" md="2" id="date_from">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="sales_quotation_dates[0]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="sales_quotation_dates[0]" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="6" md="2" id="date_to">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="sales_quotation_dates[1]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="sales_quotation_dates[1]" @input="menu3 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="2" class="mr-2 align-items-end">
                        <v-text-field
                            v-model="search"
                            single-line
                            hide-details
                            label="Search SQ# / PO# / Remarks"
                            @keydown.enter="searchInput()"
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" class="mr-3 align-items-end">
                        <v-text-field
                            v-model="searchItem"
                            single-line
                            hide-details
                            label="Search Item Model / Name"
                            append-outer-icon="mdi-magnify"
                            @click:append-outer="searchInput()"
                            @keydown.enter="searchInput()"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-container fluid>
                    <v-data-table
                        v-model="selectedQuotes"
                        :headers="headers"
                        :items="salesQuotations"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalSalesQuotations"
                        :items-per-page="10"
                        :loading="!loaded"
                        :show-select="tab_name == 'is_saved' || (tab_name == 'is_all' && status_id != -1)"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions,
                            showFirstLastPage: true,
                        }"
                        class="w-100"
                        disable-sort
                    >
                        <template v-slot:[`item.customer_name`]="{ item }">
                            <span style="font-size:12px !important;">
                                {{ item.customer_name }}
                            </span>
                        </template>

                        <template v-slot:[`item.total_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.created_at`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.created_at | formatDateTime }}
                            </span>
                        </template>

                        <template v-slot:[`item.status_text`]="{ item }">
                            <span class="badge badge-saved" v-if="item.status_text == 'saved'">{{ item.status_text }}</span>
                            <span class="badge badge-purple" v-else-if="item.status_text == 'converted'">{{ item.status_text }}</span>
                            <span class="badge badge-warning" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                            <span class="badge badge-danger" v-else-if="item.status_text == 'canceled'">{{ item.status_text }}</span>
                            <span class="badge badge-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
                        </template>

                        <template v-slot:[`item.promo_id`]="{ item }">
                            <span class="badge badge-primary" v-if="item.promo_id != null">Yes</span>
                            <span class="badge badge-danger" v-else>No</span>
                        </template>

                        <template v-slot:[`item.stock_card`]="{ item }">
                            <span class="badge badge-primary" v-if="item.stock_card">Yes</span>
                            <span class="badge badge-danger" v-else>No</span>
                        </template>

                        <template v-slot:[`item.override_amount_approval`]="{ item }">
                            <span class="badge badge-primary" v-if="item.override_amount_approval == 1">Yes</span>
                            <span class="badge badge-danger" v-else>No</span>
                        </template>

                        <template v-slot:[`item.stock_card`]="{ item }">
                            <span class="badge badge-primary" v-if="item.stock_card">Yes</span>
                            <span class="badge badge-danger" v-else>No</span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="orange">
                                    <v-icon class="btn-action" small v-if="userAccess.view" @click="viewSalesQuotation(item.id)">mdi-eye</v-icon>
                                </v-btn>
                                <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0 && service == '0'">
                                    <v-icon class="btn-action" small @click="editSalesQuotation(item.id)">mdi-square-edit-outline</v-icon>
                                </v-btn>
                                <v-btn text icon color="red" v-if="userAccess.delete && service == '0'">
                                    <v-icon class="btn-action" small @click="deleteSalesQuotation(item.id)">mdi-trash-can</v-icon>
                                </v-btn>
                            </span>
                        </template>

                        <!-- <template v-slot:[`header.created_at`]="{ header }">
                            <v-menu
                                v-model="menu_date_range"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" @click="menu_date_range = true" :class="{ 'font-weight-bold': sales_quotation_dates.length > 0 }">{{ header.text }}</span>
                                </template>
                                <v-date-picker v-model="sales_quotation_dates" range absolute>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="sales_quotation_dates=[]; getAllSalesQuotations(); menu_date_range = false">Reset</v-btn>
                                    <v-btn color="primary" @click="menu_date_range = false; getAllSalesQuotations()">OK</v-btn>
                                    <v-btn color="primary" @click="menu_date_range = false">Cancel</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </template> -->

                        <template v-slot:[`footer.prepend`]>
                            <v-btn :loading="mass_converting" @click="massConvert()" v-if="selectedQuotes.length > 0 && (tab_name == 'is_saved' || status_id == 0)">Mass Convert To SO</v-btn>
                            <v-spacer></v-spacer>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>

        <!-- VIEW MODAL-->
        <sales-quotations-view-component
            :viewSalesQuotationId="viewSalesQuotationId"
            @closeView="closeView"
        ></sales-quotations-view-component>

        <v-dialog
            v-model="loading.mass_converting.processing"
            width="500"
            class="bg-white"
        >
        <v-card>
            <v-card-title >
                <span style="font-size:12px">
                    Converting: {{ loading.mass_converting.sq }}...
                </span>
            </v-card-title>
            <v-card-text>
                <v-progress-linear
                    v-model="loading.mass_converting.value"
                    :buffer-value="100"
                    color="success"
                    :rounded="true"
                ></v-progress-linear>
            </v-card-text>
      </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SalesQuotationsViewComponent from './SalesQuotationsViewComponent.vue'
import * as $ from 'jquery'
import moment from "moment";
import { mapGetters } from 'vuex'
import swal from 'sweetalert2';

export default {
    components: {
        SalesQuotationsViewComponent,
    },
    props: [
        'salesQuotationsActionResult',
        'service',
        'tab_name',
    ],
    data() {
        return {
            actionResult: {
                model: 'SalesQuotations',
                action: ''
            },
            loaded: false,
            totalSalesQuotations: 0,
            options: {},
            salesQuotations: [],
            customers_list: [],
            customer_id: '',
            salesman_list: [],
            salesman_id: '',
            users_list: [],
            user_id: '',
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            searchItem: '',
            headers: [
                { text: 'SQ#', align: 'left', width: '6%', value: 'quote_num', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'PO#', align: 'left', value: 'customer_po_num', },
                { text: 'Remarks', align: 'left', value: 'remarks', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                { text: 'Amount', align: 'right', value: 'total_amount', },
                { text: 'Status', align: 'left', width: '5%', value: 'status_text', },
                { text: 'Date Issued', align: 'left', width: '8%', sortable: false, value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'issuer_name', },
                { text: 'Promo', align: 'left', width: '3%', sortable: false, value: 'promo_id', },
                { text: 'SC', align: 'left', width: '3%', sortable: false, value: 'stock_card', },
                { text: 'Override', align: 'left', width: '3%', sortable: false, value: 'override_amount_approval', },
                { text: 'Actions', align: 'center', width: '6%', sortable: false, value: 'action', },
            ],

            viewSalesQuotationId: '',

            status_id: -1,

            // menu_date_range: false,
            sales_quotation_dates: [
                moment().clone().startOf('year').format('YYYY-MM-DD'),
                moment().clone().endOf('month').format('YYYY-MM-DD'),
            ],
            menu2: false,
            menu3: false,

            userAccess: {
                view: false,
                edit: false,
                delete: false,
            },
            isSalesman: false,
            salesmanTitle: '',
            salesmanChildrenIds: [],
            userChildrenIds: [],
            salesmanDetails: null,
            isSalesCoordinator: false,
            currentSalesmanId: '',
            currentSalesmanPositionId: '',

            filters: {
                customer_id: '',
                salesman_id: '',
                user_id: '',
                date_from: '',
                date_to: '',
                tab_name: '',
            },

            selectedQuotes: [],
            mass_converting: false,

            loading: {
                customer_selection: true,
                salesman_selection: true,
                user_selection: true,
                mass_converting:{
                    value:0,
                    processing:false,
                    sq:''
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_SALES_QOUTATION_LIST',
            'GET_SALES_QOUTATION_TOTAL',
            'GET_SALES_QOUTATION_LOADED',
            'GET_CURRENT_SALESMAN',
            'USER_ACCESS',
            'GET_SALES_QUOTATION_RELOAD_TABLE',
            'GET_CUSTOMERS',
            'GET_ALL_USERS',
            'GET_SALESMAN_LISTS'
        ])
    },
    methods: {

        async massConvert() {
            this.loading.mass_converting.processing = true

            for(let i in this.selectedQuotes){
                await this.$store.dispatch('convertToSalesOrder', this.selectedQuotes[i].id).then(response => {
                    this.loading.mass_converting.value = this.loading.mass_converting.value + ( 100 / this.selectedQuotes.length )
                    this.loading.mass_converting.sq = this.selectedQuotes[i].quote_num
                })
            }
            this.loading.mass_converting.processing = false
            this.selectedQuotes = [];

            this.mass_converting = false
            this.getAllSalesQuotations()
        },
        passFilterOnParent() {
            this.filters.customer_id = this.customer_id
            this.filters.salesman_id = this.salesman_id
            this.filters.user_id = this.user_id
            this.filters.date_from = this.sales_quotation_dates[0]
            this.filters.date_to = this.sales_quotation_dates[1]
            this.filters.tab_name = this.tab_name
            this.$emit('getFilters', this.filters)
        },
        getAllSalesQuotations() {
            this.salesQuotations = []
            this.loaded = false
            let params = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
                searchItem: this.searchItem,
                customer_id: this.customer_id,
                salesman_id: this.salesman_id,
                user_id: this.user_id,
                sales_quotation_dates: this.sales_quotation_dates,
                service: this.service,
                tab_name: this.tab_name,
                status_filter: this.status_id,
            }
            this.$store.dispatch('salesQuotationsGetAll', params).then(response => {
                this.salesQuotations = response.data.data
                this.loaded = true
                this.totalSalesQuotations = response.data.total
                this.passFilterOnParent()
            })
        },
        viewSalesQuotation(id) {
            this.viewSalesQuotationId = id
        },
        editSalesQuotation(id) {
            this.$emit('showEditModal', id);
        },
        deleteSalesQuotation(id) {
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true
            }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                    this.$store.dispatch('salesQuotationsDelete', id).then(response => {
                        if (response.data.status == 'success') {
                            this.getAllSalesQuotations()
                        }

                        swal.fire({ text: response.data.msg, icon: response.data.status });
                    }).catch(error => {
                        swal.fire("An Error Occured", error, "error");
                    })
                }
            });
        },
        closeView(action = false) {
            this.viewSalesQuotationId = 0
            if (action == 'convertSuccess') {
                this.getAllSalesQuotations()
            }
        },
        searchInput() {
            this.getAllSalesQuotations({
                search: this.search,
                customer_id: this.customer_id,
                salesman_id: this.salesman_id,
                user_id: this.user_id,
            });
        },
        getUsers() {
            // this.$store.dispatch('getAllUsers').then(response => {
            if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                if (_.compact(this.salesmanChildrenIds).length > 0) {
                    let lists = this.GET_ALL_USERS;
                    let filtered_list = lists.filter(x => _.compact(this.userChildrenIds).includes(x.value));
                    this.users_list = filtered_list
                }
            } else {
                this.users_list = this.GET_ALL_USERS
            }

            // });
        },
        getCustomers() {
            // this.$store.dispatch('getCustomers',{params:{
            //     filtered: true,
            //             filter_store: true
            // }}).then(response => {
            let lists = this.GET_CUSTOMERS;

            if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                if (this.isSalesCoordinator) {
                    let filtered_list = lists.filter(x => x.sales_coordinator_id == this.currentSalesmanId);
                    this.customers_list = filtered_list;
                } else {
                    let filtered_list = lists.filter(x => _.compact(this.salesmanChildrenIds).includes(x.salesman_id));
                    this.customers_list = filtered_list
                }
            } else {
                this.customers_list = lists;
            }
            // });
        },
        getSalesmanList() {
            // this.$store.dispatch('getSalesmanList').then(response => {
            if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                let lists = this.GET_SALESMAN_LISTS;
                if (this.isSalesCoordinator) {
                    let filtered_list = lists.filter(x => x.value == this.currentSalesmanId);
                    this.salesman_list = filtered_list;
                } else if (_.compact(this.salesmanChildrenIds).length > 0) {
                    let filtered_list = lists.filter(x => _.compact(this.salesmanChildrenIds).includes(x.value));
                    this.salesman_list = filtered_list

                }
            } else {
                this.salesman_list = this.GET_SALESMAN_LISTS
            }
            // });
        },
        editServiceQuotation(item) {
            this.$store.commit('setSqId', item.id);
            this.$store.commit('showSqDialog');
        }
    },
    mounted() {
        this.getCustomers()
        this.getSalesmanList()
        this.getUsers()
    },
    watch: {
        tab_name() {
            if (!!this.service) {
                this.getAllSalesQuotations();
            }
        },
        salesQuotationsActionResult(val) {
            if (this.salesQuotationsActionResult == 'success') {
                this.getAllSalesQuotations()
                this.getCustomers()
                this.getSalesmanList()
                this.getUsers()
            }
        },
        options: {
            handler() {
                this.getAllSalesQuotations()
            },
            deep: true,
        },
        salesmanDetails() {
            if (!!this.GET_CURRENT_SALESMAN) {
                this.isSalesman = this.GET_CURRENT_SALESMAN.isSalesman;
                this.salesmanTitle = this.GET_CURRENT_SALESMAN.salesmanPositionTitle;
                this.salesmanChildrenIds = this.GET_CURRENT_SALESMAN.salesmanChildrenIds;
                this.userChildrenIds = this.GET_CURRENT_SALESMAN.userChildrenIds;

                if (!!this.GET_CURRENT_SALESMAN.salesman) {
                    this.currentSalesmanId = this.GET_CURRENT_SALESMAN.salesman.id;
                    this.currentSalesmanPositionId = this.GET_CURRENT_SALESMAN.salesman.salesman_position_id;
                    this.isSalesCoordinator = !!this.GET_CURRENT_SALESMAN.salesman.sales_coordinator;
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.edit = false;
                    this.userAccess.delete = false;
                    this.userAccess.view = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        }
                    });
                }
            },
        },
        GET_SALES_QUOTATION_RELOAD_TABLE: {
            handler(val) {
                if (val) {
                    this.getAllSalesQuotations()
                }
            }
        },
        GET_CUSTOMERS: {
            handler(val) {
                let lists = val
                if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if (this.isSalesCoordinator) {
                        let filtered_list = lists.filter(x => x.sales_coordinator_id == this.currentSalesmanId);
                        this.customers_list = filtered_list;
                    } else {
                        let filtered_list = lists.filter(x => _.compact(this.salesmanChildrenIds).includes(x.salesman_id));
                        this.customers_list = filtered_list
                    }
                } else {
                    this.customers_list = lists;
                }

                this.loading.customer_selection = false
            }
        },
        GET_ALL_USERS: {
            handler(val) {
                if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if (_.compact(this.salesmanChildrenIds).length > 0) {
                        let lists = val;
                        let filtered_list = lists.filter(x => _.compact(this.userChildrenIds).includes(x.value));
                        this.users_list = filtered_list
                    }
                } else {
                    this.users_list = val
                }
                this.loading.user_selection = false
            }
        },
        GET_SALESMAN_LISTS: {
            handler(val) {
                if (this.isSalesman && this.currentSalesmanPositionId != 1) {
                    let lists = val;
                    if (this.isSalesCoordinator) {
                        let filtered_list = lists.filter(x => x.value == this.currentSalesmanId);
                        this.salesman_list = filtered_list;
                    } else if (_.compact(this.salesmanChildrenIds).length > 0) {
                        let filtered_list = lists.filter(x => _.compact(this.salesmanChildrenIds).includes(x.value));
                        this.salesman_list = filtered_list
                    }
                } else {
                    this.salesman_list = val

                }
            }
        },
        GET_CURRENT_SALESMAN: {
            handler(val) {
                if (!!this.val) {
                    this.isSalesman = this.val.isSalesman;
                    this.salesmanTitle = this.val.salesmanPositionTitle;
                    this.salesmanChildrenIds = this.val.salesmanChildrenIds;
                    this.userChildrenIds = this.val.userChildrenIds;

                    if (!!this.val.salesman) {
                        this.currentSalesmanId = this.val.salesman.id;
                        this.currentSalesmanPositionId = this.val.salesman.salesman_position_id;
                        this.isSalesCoordinator = !!this.val.salesman.sales_coordinator;
                    }
                }
            }
        },
    }
};
</script>
