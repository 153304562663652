<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar" v-if="userAccess.create && service == '0'">
                        <v-btn class="ml-1 mb-2" @click="showAddSalesQuotation()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="6" align="right">
                    <v-btn :loading="loading.export" class="mr-1 mb-2" @click="exportSalesQuotation()">Export</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <sales-quotations-table-component
                    :service="service"
                    :tab_name="tab_name"
                    :salesQuotationsActionResult="action"
                    @showEditModal="showEditModal"
                    @getFilters="getFiltersOnTable($event)"
                ></sales-quotations-table-component>
            </v-row>
        </v-container>

        <v-app id="addEditSalesQuotationModal">
            <v-dialog v-model="add_edit_salesquotation_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">{{ `${service == 1 ? "Spare Parts" : "Sales"} Quotation`}}</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="add_edit_salesquotation_dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-container fluid style="background-color: white; overflow-y: scroll">
                        <v-col>
                            <v-card-text v-if="loaded">
                                <form class="form-horizontal" action="/salesquotations" method="post" @submit.prevent="onSubmit">
                                    <div class="card-body">
                                        <v-row class="m-0 text-dark" v-if="action == 'Edit'">
                                            <v-col cols="12" class="text-left">
                                                <v-row>
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">{{ `${service == 1 ? "Spare Parts" : "Sales"} Quotation No: ${this.quote_num}` }}</v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">Issuer: {{ this.issuer_name }}</v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">Date Issued: {{ this.created_at | formatDateTime }}</v-col>
                                                </v-row>
                                                <div class="row">
                                                    <v-col sm="12" md="9" class="p-0"></v-col>
                                                    <v-col sm="12" md="3" class="p-0">Promo: {{ this.promo.name }}</v-col>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="2" class="d-flex">
                                                <!-- <label for="customer_id" class="float-left control-label col-form-label">Promo</label> -->
                                                <v-autocomplete
                                                    v-model="promo_id"
                                                    :items="promo_list"
                                                    :disabled="action == 'Edit'"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    clearable
                                                    label="Select Promo"
                                                    class="d-inline-block small"
                                                ></v-autocomplete>
                                                <span v-for="errors in errors.promo_id" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="2" class="d-flex">
                                                <!-- <label for="customer_id" class="control-label col-form-label">Delivery Method</label> -->
                                                <v-select
                                                    v-model="delivery_method"
                                                    :items="delivery_method_list"
                                                    :disabled="service == 1"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    label="Select Delivery Method"
                                                    class="d-inline-block small"
                                                ></v-select>
                                                <span v-for="errors in errors.promo_id" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="12" md="4" class="d-flex align-items-center" style="gap: 10px">
                                                <v-switch v-model="stock_card" :disabled="service == 1" hide-details dense
                                                    label="Stock Card" color="primary" input-value="1" class="m-0 d-flex align-items-center"></v-switch>
                                                <v-switch v-model="has_promo" :disabled="service === 1 || override_srp.promo_type == 1 || override_srp.promo_type == 2" hide-details dense
                                                    label="Has Promo" color="primary" :true-value="1" :false-value="0" class="m-0 d-flex align-items-center"></v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mb-2">
                                            <v-col sm="4" class="d-flex">
                                                <v-autocomplete
                                                    v-model="customer_id"
                                                    :items="filtered_customers_list"
                                                    :disabled="service == 1"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Select Customer"
                                                    class="d-inline-block small">
                                                    <template v-slot:selection="data">
                                                        <v-chip x-small rounded left :color="data.item.color"></v-chip>
                                                        <span class="ml-2">{{ data.item.text }}</span>
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <v-tooltip color="black" bottom>
                                                            <template #activator="{ on }">
                                                                <v-chip v-on="on" x-small rounded left :color="data.item.color"></v-chip>
                                                                <span class="ml-2">{{ data.item.text }}</span>
                                                            </template>
                                                            <span>{{ data.item.description }}</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-autocomplete>
                                                <span v-for="errors in errors.customer_id" class="text-warning"
                                                    :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="4" class="d-flex">
                                                <v-autocomplete v-model="customer.shipping_address"
                                                    :items="shipping_address_list" :disabled="service == 1" dense
                                                    outlined hide-details auto-select-first
                                                    label="Select Address" color="#b3d4fc" class="w-100 small"
                                                    id="shipping_address_text"></v-autocomplete>
                                                <span v-for="errors in errors.shipping_address" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="2" class="d-flex">
                                                <v-autocomplete
                                                    v-model="salesman_id"
                                                    :items="salesman_list"
                                                    :disabled="service == 1"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Select Salesman"
                                                    class="d-inline-block"
                                                ></v-autocomplete>
                                                <span v-for="errors in errors.salesman_id" class="text-warning" :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col sm="12" md="2">
                                                <v-text-field
                                                    v-model="terms"
                                                    :readonly="promo_id != ''"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    label="Terms"
                                                    aria-label="%"
                                                    aria-describedby="basic-addon2"
                                                    suffix="days"
                                                    @keypress="numberOnly"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>

                                    <v-row>
                                        <v-col sm="12" md="5">
                                            <v-row class="ml-2 mt-2" v-if="customer != '' && customer.discounts.length > 0 && promo_id == null">
                                                <v-col sm="12" md="8">
                                                    <v-row class="p-0 text-dark">
                                                        <v-col sm="12" md="6" class="py-0"
                                                            v-for="(disc, index) in itemTypeDiscounts" :key="index"
                                                            v-show="orderList[0] != null && disc.length > 0 && disc.substring(0, 2) == orderList[0].type"
                                                        >
                                                            <span>{{ disc }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-spacer></v-spacer>
                                            </v-row>
                                        </v-col>
                                        <v-col sm="6" md="3">
                                            <v-textarea v-model="notes" label="Notes" rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                        <v-col sm="6" md="1">
                                            <v-textarea v-model="customer_po_num" :disabled="service == 1" label="PO#" no-resize rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                        <v-col sm="6" md="3">
                                            <v-textarea v-model="remarks" label="Remarks" no-resize rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-card outlined class="p-2">
                                        <v-row>
                                            <v-col sm="12" md="12">
                                                <div class="float-left ma-4">
                                                    <v-btn dark small color="bg-primary"  @click="batch_addition.dialog = true">Batch Addition</v-btn>
                                                    <!-- <v-btn dark small color="bg-primary" :disabled="disableBatchAddition() || orderList.lenght == 0" @click="overrideAmounts()">Override amounts</v-btn> -->
                                                </div>
                                                <div class="float-right ma-4" >
                                                    <v-btn fab dark small color="primary" style="height: 20px; width: 20px" @click="addOrderItem()">
                                                        <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                    </v-btn>
                                                    <v-btn fab dark small color="primary" style="height: 20px; width: 20px" @click="removeOrderItem()">
                                                        <v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                            <!-- EDIT this  -->
                                            <v-col sm="12" md="12" v-if="!hasPromo()" class="d-flex">
                                                <v-checkbox v-model="override_srp.promo_type" true-value="1" false-value="0" class="mx-3" label="SP Price" :disabled="has_promo === 1">

                                                </v-checkbox>
                                                <v-checkbox v-model="override_srp.promo_type" true-value="2" false-value="0" label="Special Approval" :disabled="has_promo === 1">

                                                </v-checkbox>
                                            </v-col>

                                            <v-col sm="12" md="12">
                                                <v-simple-table dense class="dynamic_list_table">
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center mx-2" width="12%">Model</th>
                                                                <th class="text-center mx-2" width="22%">Name</th>
                                                                <th class="text-center mx-2" width="9%">MOQ</th>
                                                                <th class="text-center mx-2" width="9%">Order Qty</th>
                                                                <th class="text-center mx-2" v-if="stock_card == 1">SC Qty</th>
                                                                <th class="text-center mx-2" width="9%">UOM</th>
                                                                <th class="text-center mx-2">SRP</th>
                                                                <th class="text-center mx-2">Disc. Price</th>
                                                                <th class="text-center mx-2" width="1%">FOC</th>
                                                                <th class="text-center mx-2">Total</th>
                                                                <th class="text-center mx-2" width="7%">Type</th>
                                                                <th class="text-center mx-2" width="1%"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(order_item, i) in orderList" :key="i" :class="`${order_item.color ? order_item.color : ''}`" v-if="!rerender">
                                                                <td v-if="(action == 'Add' || orderList[i].item_id == '' || orderList[i].is_editable) && !orderList[i].is_bundle">
                                                                    <v-autocomplete
                                                                        v-model="orderList[i].item_id"
                                                                        :items="filtered_items_list"
                                                                        :disabled="service == 1"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        auto-select-first
                                                                        placeholder="Select Model"
                                                                        @input="selectModel(i, orderList[i].item_id, true); checkItemStocks(orderList[i], i);"
                                                                        @keydown.tab="getItemIdSingle($event.target.value)"
                                                                    ></v-autocomplete>
                                                                </td>
                                                                <td v-else>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.model"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.name"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.moq"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].quantity"
                                                                        :disabled="orderList[i].is_bundle || service == 1"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        class="centered-input"
                                                                        @keypress="numberOnly"
                                                                        @input="changeQuantity(i)"
                                                                        @blur="checkMoq(i)"
                                                                        @focus="$event.target.select()"
                                                                        @keydown="checkItemStocks(orderList[i], i)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td v-if="stock_card == 1">
                                                                    <v-text-field
                                                                        v-model="orderList[i].sc_quantity"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        class="centered-input"
                                                                        @keypress="numberOnly"
                                                                        @input="changeQuantitySC(i)"
                                                                        @blur="checkMoqSC(i)"
                                                                        @focus="$event.target.select()"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].item.item_uom_text"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                {{override_srp.enable}}
                                                                    <v-text-field
                                                                        v-if="!orderList[i].foc"
                                                                        v-model="orderList[i].item.display_srp"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        @change="recomputeDiscAmount(i)"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                    ></v-text-field>
                                                                    <v-text-field
                                                                        v-else
                                                                        v-model="foc_price"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                        @change="recomputeDiscAmount(i)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td v-if="orderList[i].override_amount == null || orderList[i].override_amount == 0">
                                                                    <v-text-field
                                                                        v-if="!orderList[i].foc"
                                                                        v-model="orderList[i].display_disc_price"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                        @change="recomputeDiscAmount(i,1)"
                                                                        @click="showOverrideAmountDialog(i)"

                                                                    ></v-text-field>
                                                                    <v-text-field
                                                                        v-else
                                                                        v-model="foc_price"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        :readonly="override_srp.enabled ? false :true"
                                                                        class="right-input"
                                                                        :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                        @change="recomputeDiscAmount(i,1)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td v-else>
                                                                    <v-text-field
                                                                        v-model="orderList[i].display_override_amount"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="right-input bg-ingco"
                                                                        background-color="grey lighten-2"
                                                                        @click="showOverrideAmountDialog(i)"
                                                                    ></v-text-field>
                                                                </td>
                                                                <v-tooltip :disabled="!orderList[i].foc_remarks" bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <td v-bind="attrs" v-on="on">
                                                                            <v-checkbox
                                                                                v-model="orderList[i].foc"
                                                                                color="primary"
                                                                                input-value="1"
                                                                                hide-details
                                                                                class="ma-0 pa-0"
                                                                                @change="changeFoc(i)"
                                                                            ></v-checkbox>
                                                                        </td>
                                                                    </template>
                                                                    <span>{{ orderList[i].foc_remarks }}</span>
                                                                </v-tooltip>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].display_total_amount"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="right-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-text-field
                                                                        v-model="orderList[i].type"
                                                                        dense
                                                                        outlined
                                                                        hide-details
                                                                        readonly
                                                                        class="centered-input"
                                                                        background-color="grey lighten-2"
                                                                    ></v-text-field>
                                                                </td>
                                                                <td>
                                                                    <v-btn v-if="service == 0" fab dark small color="primary"
                                                                        style="height: 20px; width: 20px"
                                                                        @click="removeOrderByIndex(i)">
                                                                        <v-icon dark small style="font-size: 12px">
                                                                            mdi-minus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-col>
                                            <v-col lg="12" class="d-flex justify-content-center align-items-center"  v-if="rerender">
                                                <v-progress-circular
                                                    :width="12"
                                                    :size="100"
                                                    color="primary"
                                                    indeterminate
                                                ></v-progress-circular>
                                            </v-col>
                                        </v-row>
                                    </v-card>

                                    <v-row class="m-2">
                                        <v-container fluid>
                                            <v-row>
                                                <v-col sm="12" md="8" class="text-left">
                                                    <v-row class="ml-2"></v-row>
                                                    <v-row class="ml-2"></v-row>
                                                </v-col>
                                                <v-col sm="12" md="4" class="pull-right text-right">
                                                    <h5>Total Amount: &#x20b1;{{ this.sales_total_amount | currency }}</h5>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-row>

                                    <v-col sm="12" md="12">
                                        <table class="table-bordered" style="font-size: 16px">
                                            <tr v-for="(e, i) in batch_addition.errors" :key="i">
                                                <th class="px-3">{{ e.title }}</th>
                                                <td class="px-4" style="color: red">{{ e.data }}</td>
                                            </tr>
                                        </table>
                                    </v-col>

                                    <div class="border-top">
                                        <div class="card-body py-4 d-flex justify-content-center">
                                            <v-btn @click="checkForFoc(action)" v-if="action == 'Add'" :loading="loading.submit">Submit</v-btn>
                                            <v-btn @click="checkForFoc(action)"
                                                v-else-if="action == 'Edit'">Update</v-btn>
                                        </div>
                                    </div>
                                </form>
                            </v-card-text>
                            <v-card-text v-else-if="loaded == false">
                                <div class="text-center">
                                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-container>
                    <v-container fluid style="display: none">
                        <div>
                            <table id="items_details">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>QTY</th>
                                        <th>UNITS</th>
                                        <th>MODEL</th>
                                        <th>DESCRIPTION</th>
                                        <th>UNIT PRICE</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody id="tr_items">
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>***** NOTHING FOLLOWS *****</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-container>
                </v-card>
            </v-dialog>

            <v-dialog v-model="showFocRemarks" persistent max-width="60%">
                <v-card class="pa-4">
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeFocRemarksDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-textarea class="ma-4" v-model="focRemarks" label="Remarks"></v-textarea>
                    </v-row>
                    <div style="text-align: center">
                        <v-btn @click="saveFocRemarks()">Save</v-btn>
                    </div>
                </v-card>
            </v-dialog>

            <v-dialog v-model="override_amount_dialog" persistent max-width="384" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="override_amount_dialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                            <v-col sm="12" md="6" class="m-auto text-center">
                                <label for="override_amount" class="control-label col-form-label">Override Amount</label>
                                <v-text-field
                                    v-model="override_amount"
                                    dense
                                    hide-details
                                    id="override_amount"
                                    placeholder="0.00"
                                    @keypress="decimalOnly">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-content-center">
                                <v-btn @click="confirmOverrideAmount()">Confirm</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="approve_override_dialog" persistent max-width="50%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Amounts</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="approve_override_dialog = false; override_username = ''; override_user_password = ''; override_user_id = -1;">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                            <v-col sm="12" md="12">
                                <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override_user_password" autocomplete="new-password" />
                                <span v-for="errors in errors.override_user_password" class="text-warning" :key="errors">{{ errors }}</span>
                            </v-col>
                        </v-row>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn @click=" approveOverrideAmounts(); departments_allowed = ['SM', 'AC', 'EX', 'IT'];">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="batch_addition.dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Copy Model and Qty From Excel</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeBatchAdditionDialog(); dataFromExcel = '';">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="p-4">
                        <v-row>
                            <v-textarea
                                v-model="dataFromExcel"
                                :autofocus="batch_addition.dialog"
                                rows="1"
                                small
                                clearable
                                color="primary"
                                label="Paste From Excel"
                                @click="$event.target.focus()"
                                @focus="dataFromExcel = null"
                                @paste="onPaste"
                            ></v-textarea>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <ConfirmOverrideComponentVue
                :approve_override_dialog="override.override_dialog"
                :departments_allowed="override.override_departments_allowed"
                :heading="override.headers"
                @closeConfirmOverride="closeConfirmOverride"
            ></ConfirmOverrideComponentVue>

            <v-dialog v-model="showOutOfStockDialog" max-width="40%" style="height: 300px; overflow: unset" content-class="unset-overflow dialog">
                <v-container class="bg-light">
                    <v-row>
                        <v-col cols="10">
                            <b>ITEM REPLACEMENT</b>
                        </v-col>
                        <v-col cols="2">
                            <v-btn text icon color="gray" class="float-right" @click="showOutOfStockDialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="7"></v-col>
                        <v-col cols="4">
                            <label class="float-left control-label col-form-label">SUGGESTED ITEMS</label>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in items_suggestion" :key="i">
                        <v-col class="col-1"></v-col>
                        <v-col class="col-4">
                            <label class="float-left control-label col-form-label">{{ item.model }}</label>
                        </v-col>
                        <v-col cols="2"></v-col>
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="item.selected"
                                :items="item.suggested_items"
                                dense
                                outlined
                                hide-details
                                placeholder="Select Model"
                                class="d-inline-block"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-content-end">
                        <v-col cols="2">
                            <v-btn small @click="showOutOfStockDialog = false">CANCEL</v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn small @click="showOutOfStockDialog = false; add_edit_salesquotation_dialog = true; getSelectedReplacement(items_suggestion);">REPLACE</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-dialog>

            <v-dialog v-model="itemSubsDialog" width="30%" persistent>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">New Models For {{ new_item_model }} </span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="itemSubsDialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="p-4">
                        <v-row>
                            <v-autocomplete
                                v-model="itemSubstitution"
                                :items="item_substitution"
                                item-value="id"
                                item-text="model"
                                label="New Models"
                                outlined
                                dense
                                auto-select-first
                            >
                            </v-autocomplete>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="text-center">
                        <v-row class="ma-1">
                            <v-col cols="12">
                                <v-btn class="float-center" @click="submitSubstitution()">
                                    <span>Submit</span>
                                </v-btn>
                            </v-col>
                        </v-row>
				    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="batchSubstitution.dialog" width="60%" persistent>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline"> New Models </span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="batchSubstitution.dialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="p-4">
                        <v-simple-table fixed-header>
                            <thead class="position-sticky">
                                <tr>
                                    <th class="text-center">MODEL</th>
                                    <th class="text-center">SRP</th>
                                    <th class="text-center">MOQ</th>
                                    <th class="text-center" width="10%">QTY</th>
                                    <th class="text-center">NEW ITEM</th>
                                    <th class="text-center">NEW ITEM SRP</th>
                                    <th class="text-center">NEW ITEM MOQ</th>
                                    <th class="text-center">ACTION</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-for="(data, i) in batchSubstitution.list" :key="i">
                                    <td class="pa-1">
                                        <v-text-field
                                            v-model="data.itemDetails.model"
                                            class="centered-input"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                        >
                                        </v-text-field>
                                    </td>
                                    <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                        <v-text-field
                                            v-model="data.itemDetails.selling_price"
                                            class="centered-input"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                            @keypress="numberOnly"
                                        >
                                        </v-text-field>
                                    </td>
                                    <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                        <v-text-field
                                            v-model="data.itemDetails.moq"
                                            class="centered-input"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                            @keypress="numberOnly"
                                        >
                                        </v-text-field>
                                    </td>
                                    <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                        <v-text-field
                                            v-model="data.qty"
                                            @blur="checkItemMoq(i, data)"
                                            :class="{
                                                notInModel: data.error,
                                            }"
                                            outlined
                                            dense
                                            hide-details
                                            class="centered-input"
                                        >
                                        </v-text-field>
                                    </td>
                                    <td v-if="data.subsitutionSelection.length != 0" class="pa-1 pt-2">
                                        <v-autocomplete
                                            v-model="data.item_sub_id"
                                            :items="data.subsitutionSelection"
                                            @change="checkItemMoq(i, data)"
                                            item-value="id"
                                            item-text="model"
                                            outlined
                                            dense
                                            auto-select-first
                                        >
                                        </v-autocomplete>
                                    </td>
                                    <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                        <v-text-field
                                            :value="getSubItemDetails(data.item_sub_id, data.subsitutionSelection, 'selling_price')"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                            class="centered-input"
                                            @keypress="numberOnly"
                                        >
                                        </v-text-field>
                                    </td>
                                    <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                        <v-text-field
                                            :value="getSubItemDetails(data.item_sub_id, data.subsitutionSelection, 'moq')"
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                            class="centered-input"
                                            @keypress="numberOnly"
                                        >
                                        </v-text-field>
                                    </td>
                                    <td v-if="data.subsitutionSelection.length != 0" class="pa-1">
                                        <v-btn
                                            text
                                            icon
                                            small
                                            color="primary"
                                            class="float-right"
                                            @click="deleteItem(i)"
                                        >
                                            <v-icon dark small>mdi-minus-circle</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-center" colspan="7" v-else>
                                        <span><i>No new models found.</i></span>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions class="text-center">
                        <v-row class="ma-1">
                            <v-col cols="12">
                                <v-btn class="float-center" @click="submitBatchSubstitution()">
                                    <span>Submit</span>
                                </v-btn>
                            </v-col>
                        </v-row>
				    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import SalesFunctionsComponent from "@/views/main/Shared/SalesFunctionsComponent.vue";
import SalesQuotationsTableComponent from "@/views/main/modules/Sales/SalesQuotations/SalesQuotationsTableComponent.vue";
import ConfirmOverrideComponentVue from "@/views/main/Utils/ConfirmOverrideComponent.vue";
import swal from "sweetalert2";
import $ from "jquery";
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent2.vue';
const ExcelJS = require("exceljs");
const FileSaver = require("file-saver");

import { mapGetters } from "vuex";
export default {
    mixins: [
        SharedFunctionsComponent,
        SalesFunctionsComponent,
        PusherFunctionComponent
    ],
    components: {
        SalesQuotationsTableComponent,
        ConfirmOverrideComponentVue,
    },
    props: [
        "service",
        "tab_name"
    ],
    data() {
        return {
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: [],
            },
            isDisabled: false,
            dataFromExcel: '',
            fromShowEditModal: false,
            stock_card: 0,
            promo_name: '',
            promo_id: null,
            promo: {
                id: '',
                name: '',
                code: '',
            },
            customer_promo: {
                discount_rate: '',
                cash_price: '',
            },
            override_username: '',
            override_user_password: '',
            override_user_id: -1,
            override_amount: '',
            override_index: '',
            editSalesQuotationId: '',
            add_edit_salesquotation_dialog: false,
            override_amount_dialog: false,
            approve_override_dialog: false,
            quote_num: '',
            quote_date: '',
            customer_id: '',
            salesman_id: '',
            user_id: '',
            remarks: '',
            discounts: '',
            terms: '',
            orig_terms: '',
            convert_date: '',
            status: '',
            created_at: '',
            issuer_name: '',
            foc_price: "0.00",
            delivery_method: 2,

            tempOrderList: [],
            orderList: [],
            order: {
                sc_quantity: '',
                quantity: '',
                item_id: '',
                foc: '',
                disc_price: '',
                total_amount: '',
                amount: '',
                type: '',
                item: {
                    id: '',
                    model: '',
                    name: '',
                    description: '',
                    type_id: '',
                    category_id: '',
                    uom_id: '',
                    item_uom_text: '',
                    barcode: '',
                    selling_price: '',
                    sp_price: '',
                    srp: '',
                    override_amount: '',
                    moq: '',
                    brand_id: '',
                    original_item_id:0
                },
                is_bundle: false,
                promos: [],
                selected_promo: '',
                promo_items_id: '',
                promo_amount: '',
                backend_promo: '',
            },
            customer: {
                shipping_address: 0,
                billing_address: 0,
                salesman_name: '',
                remarks: '',
                terms: '',
                discounts: [],
                is_sp: '',
                is_vip: '',
                override_moq: 0,
            },
            discount: {
                pt: '',
                ht: '',
                sp: '',
            },
            itemTypeDiscounts: [],
            max_discounts_count: 999,
            max_order_items_count: 999,
            shipping_address_list: [],
            customers_list: [],
            users_list: [],
            salesman_list: [],
            items_list: [],
            type_list: [],
            promo_list: [],
            shortlist_item_ids: [],
            shortlist_customer_ids: [],
            delivery_method_list: [],
            errors: [],
            action: '',
            actionResult: {
                model: "Sales Quotation",
                action: '',
            },
            loaded: false,
            userAccess: {
                create: false,
            },
            customer_po_num: '',
            departments_allowed: [],
            focRemarks: '',
            showFocRemarks: false,
            orderListIndex: '',
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ["SM", "EX", "IT"],
                override_authenticated: false,
                headers:'FOC',
                from:0
            },
            isSalesman: false,
            salesmanTitle: '',
            salesmanChildrenIds: [],
            userChildrenIds: [],
            salesmanDetails: null,
            isSalesCoordinator: false,
            currentSalesmanId: '',
            currentSalesmanPositionId: '',
            itemWithNoStock: [
                {
                    model: '',
                    suggested_items: [],
                },
            ],
            showOutOfStockDialog: false,
            selectedSQ: {
                data: {
                    suggested_items: [],
                },
                index: 0,
                selected: null,
            },
            itemToReplace: [],
            items_suggestion: {
                model: '',
                id: [],
                suggested_items: [],
                selected: [],
            },
            filterFrom_table: [],

            notes: null,
            alreadySubstitutedItem:false,
            exclusive_items:[],

            has_promo: 0,
            itemSubsDialog: false,
            itemSubstitution: '',
            batchSubstitution:{
                dialog: false,
                list: []
            },

            loading: {
                submit: false,
                export: false,
                items:false
            },
            override_srp:{
                title:'SRP',
                promo_type:0,
                enabled:false
            },
            rerender:false
        };
    },
    computed: {
        ...mapGetters([
            "GET_CURRENT_SALESMAN",
            "USER_ACCESS",
            "USERACCOUNT_LOAD"
        ]),
    },
    methods: {
        getFiltersOnTable(val) {
            this.filterFrom_table = val;
        },
        async exportSalesQuotation() {
            this.loading.export = true
            let from = "Module";
            let status_id;
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('sheet');
            if (this.filterFrom_table.tab_name == "is_saved") {
                status_id = 0;
            } else if (this.filterFrom_table.tab_name == "is_converted") {
                status_id = 1;
            } else {
                status_id = -1;
            }

            let parameters = {
                customer_id: this.filterFrom_table.customer_id,
                salesman_id: this.filterFrom_table.salesman_id,
                user_id: this.filterFrom_table.user_id,
                date_from: this.filterFrom_table.date_from,
                date_to: this.filterFrom_table.date_to,
                from: from,
                status_id: status_id,
            };

            worksheet.columns = [
                { header: 'CUSTOMER', key: 'customer_name' },
                { header: 'QUOTE NUM', key: 'quote_num' },
                { header: 'PO NUM', key: 'customer_po_num' },
                { header: 'DATE ISSUED', key: 'created_at' },
                { header: 'ISSUER', key: 'issuer_name' },
                { header: 'REMARKS', key: 'remarks' },
                { header: 'SALESMAN', key: 'salesman_name' },
                { header: 'TERMS', key: 'terms' },
                { header: 'SQ AMOUNT', style: { numFmt: '#,##0.00' }, key: 'total' },
                { header: 'STATUS', key: 'status_text' },
                { header: 'MODEL', key: 'model' },
                { header: 'NAME', key: 'name' },
                { header: 'FOC', key: 'foc' },
                { header: 'QTY', style: { numFmt: '#,##0' }, key: 'quantity' },
                { header: 'SRP', style: { numFmt: '#,##0.00' }, key: 'srp' },
                { header: 'DISC PRICE', style: { numFmt: '#,##0.00' }, key: 'amount' },
                { header: 'UOM', key: 'uom' },
                { header: 'ITEM TOTAL', style: { numFmt: '#,##0.00' }, key: 'item_total' },
                { header: 'TYPE', key: 'type' },
                { header: 'CATEGORY', key: 'category' },
                { header: 'PROMO', key: 'promo_id' },
                { header: 'SC', key: 'stock_card' },
            ]

            await this.$store.dispatch("exportSalesQuotation", parameters).then((response) => {
                let data = response.data.data
                let status = response.data.status
                data.forEach(e => {
                    e.terms = e.terms == 0 ? 'CASH' : e.terms
                    e.promo_id = e.promo_id == 0 ? 'NO' : 'YES';
                    e.foc = e.foc == 0 ? 'NO' : 'YES';
                    e.stock_card = e.stock_card == 0 ? 'NO' : 'YES';
                    e.status_text = status[e.status]
                    e.total = parseFloat(e.total)
                    e.quantity = parseInt(e.quantity)
                    e.srp = parseFloat(e.srp)
                    e.amount = parseFloat(e.amount)
                    e.item_total = parseFloat(e.item_total)

                    worksheet.addRow(e)
                })
            }).catch(err=>{
                    console.log(err)
                });

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                FileSaver.saveAs(blob, `SalesQuotationReport From ` + this.filterFrom_table.date_from + ' To ' + this.filterFrom_table.date_to + `.xlsx`);

                this.loading.export = false
            });
        },
        saveFocRemarks() {
            if (typeof this.orderListIndex == "number") {
                if (!!this.focRemarks) {
                    this.orderList[this.orderListIndex].foc_remarks = this.focRemarks; // Added
                    this.showFocRemarks = false;
                    // this.override.override_dialog = true;
                } else {
                    swal.fire('', "Please input remarks ", "warning");
                }
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                if(this.override.from == 1){
                    // this.override_srp.enabled = true
                }else{
                    if (this.action == "Edit") {
                            this.editSalesQuotation();
                        } else {
                            this.addNewSalesQuotation();
                        }
                }
            }

            this.override.override_dialog = false;
        },
        disableBatchAddition() {
            if (this.customer_id != '' && (this.promo_id == null || this.promo_id == '')) {
                return false;
            }

            return true;
        },
        closeBatchAdditionDialog() {
            this.batch_addition.data = [];
            this.batch_addition.dialog = false;
        },
        onPaste(evt) {
            this.batch_addition.data = [];
            var pastedText = evt.clipboardData.getData("Text");
            var rows = pastedText.split("\r\n");
            for (var y in rows) {
                if (y != rows.length - 1) {
                    var cells = rows[y].split("\t");
                    this.batch_addition.data.push(
                        Object.assign({}, {
                            model: cells[0],
                            qty: cells[1],
                            // foc: cells[2],
                            id: '',
                            skip: false,
                        })
                    );
                }
            }

            this.getItemId();
            this.closeBatchAdditionDialog();
        },
        checkMoqSC(key) {
            if (this.orderList[key].sc_quantity != '' && this.orderList[key].moq != '') {
                var moq = parseInt(this.orderList[key].moq);
                if (moq != null && moq > 0) {
                    if (this.orderList[key].sc_quantity % moq > 0) {
                        var upperQty = this.orderList[key].sc_quantity;
                        var lowerQty = this.orderList[key].sc_quantity;
                        while (upperQty % moq > 0) {
                            upperQty++;
                        }
                        while (lowerQty % moq > 0) {
                            lowerQty--;
                        }
                        var suggestion = '';
                        if (lowerQty == 0) {
                            suggestion = "\n Suggestion: " + upperQty;
                        } else {
                            suggestion = "\n Suggestion: " + upperQty + " or " + lowerQty;
                        }
                        swal.fire('', "Quantity should be divisible by " + moq + suggestion, "warning");
                        this.orderList[key].sc_quantity = '';
                    }
                }
            }
        },
        changeQuantitySC(key) {
            let qty = 0;

            qty = parseInt(this.orderList[key].quantity);
            if (parseInt(this.orderList[key].sc_quantity) > qty) {
                this.orderList[key].sc_quantity = qty;
            }
        },
        showAddSalesQuotation() {
            this.add_edit_salesquotation_dialog = true;
            this.action = "Add";
        },
        checkForFoc(action) {

            let container = this.orderList.map((x) => x.foc);

            let focChecker = container.includes(true);
            // let itemsChecker = this.orderList.every(x=>{
            //     return !!x.foc;
            // });

            if (!!focChecker && !this.has_promo) {
                this.override.override_dialog = true;
            } else {
                if (action == "Edit") {
                    this.editSalesQuotation();
                } else {
                    this.addNewSalesQuotation();
                }
            }
        },

        async addNewSalesQuotation() {
            let suggestions = [];

            // for (const index in this.orderList){
            //     if(this.orderList[index].color == 'bg-danger'){
            //         await axios.get(`/getItemSubstitutions/${this.orderList[index].item_id}`).then(response => {
            //             suggestions.push({
            //                 item:this.orderList[index],
            //                 suggestion:response.data.item,
            //                 index:index,
            //                 quantity:parseInt(this.orderList[index].quantity)

            //             })
            //         })
            //     }
            // }

            // if(suggestions.length > 0){
            //     let filteritem = []
            //     for (const index1 in suggestions){
            //         let handler = []
            //         for(const index2 in suggestions[index1].suggestion.suggested_items){
            //             handler.push()
            //             await axios.get(`/items/total-stocks/${suggestions[index1].suggestion.suggested_items[index2].value}`).then(response=>{
            //                 if(response.data >= suggestions[index1].quantity){
            //                     handler.push(suggestions[index1].suggestion.suggested_items[index2])
            //                 }
            //             })
            //         }
            //         filteritem.push({
            //             id:suggestions[index1].item.item_id,
            //             model:suggestions[index1].item.item.model,
            //             suggested_items:handler,
            //             selected:'',
            //             index:suggestions[index1].index,
            //             quantity:suggestions[index1].quantity
            //         })
            //     }

            //     filteritem = filteritem.filter(e=>{
            //         if(e.suggested_items.length > 0){
            //             return e
            //         }
            //     })

            //     if(filteritem.length > 0){
            //         swal.fire({
            //             title:'WARNING!',
            //             text:"Some items can not be serve based on quantity inputed.\n See item suggestions",
            //             icon:'warning',
            //             buttons:{
            //                 cancel: true,
            //                 confirm: {
            //                     text: "See Suggestion",
            //                     value: 'confirm',
            //                 },
            //                 submit: {
            //                     text: "Continue Submit",
            //                     value: 'submit',
            //                 },
            //         }
            //         }).then(button=>{
            //             if(button == 'confirm'){

            //                 this.items_suggestion = filteritem;
            //                 this.showOutOfStockDialog = true;
            //                 // this.add_edit_salesquotation_dialog = false;
            //                 return false;
            //             }else if(button == 'submit'){
            //                 if(this.checkItemsZeroSRP()){
            //                     this.override_user_id == -1
            //                     this.showApproveOverrideAmountsDialog()
            //                 } else{
            //                     return false
            //                 }
            //             }
            //         }).catch(e=>{
            //             console.log(e)
            //         })
            //     }
            // } else {
            //     this.checkOrderTableHeight()
            // }

            this.override_user_id == -1
            this.showApproveOverrideAmountsDialog()

            if (this.action == "Add") {
                this.proceedSaveQuotation();
            } else if (this.action == "Edit") {
                this.proceedUpdateQuotation();
            }
        },
        getSelectedReplacement(item) {
            item.forEach((e) => {
                this.selectModel(e.index, e.selected, true);
                this.orderList[e.index].color = '';
            });
        },
        checkOrderTableHeight() {
            // var limit = 155;
            // var shipping_address_text = $('#shipping_address_text').parent().text();
            // if(shipping_address_text != null && shipping_address_text != ''){
            //     if(shipping_address_text.length > 60){
            //         limit = 145;
            //     }
            // }

            // var tableHeight =  this.computeOrderTableHeight()
            // if(tableHeight > limit){
            //     swal.fire('', "This will render more than 1 page. \n Please delete item(s) to proceed", "warning");
            // }else{
            //     if(this.action == 'Add'){
            //         this.proceedSaveQuotation()
            //     }else if(this.action == 'Edit'){
            //         this.proceedUpdateQuotation()
            //     }

            // }

            // if (this.action == "Add") {
            //     this.proceedSaveQuotation();
            // } else
            if (this.action == "Edit") {
                this.proceedUpdateQuotation();
            }
        },
        async proceedSaveQuotation() {
            this.loading.submit = true;
            this.errors = [];
            // var shipping_address_text = $("shipping_address_text").parent().text();
            let shipping_address_text = ''
            this.shipping_address_list.forEach(e => {
                if (e.value == this.customer.shipping_address) {
                    shipping_address_text = e.text
                }
            })
            this.checkOrderTableHeightAddItem();
            let unique_key = "id" + Math.random().toString(16).slice(2)

            let parameters = {
                customer_id: this.customer_id,
                salesman_id: this.salesman_id,
                remarks: this.remarks,
                orderList: this.orderList,
                shipping_address_index: this.customer.shipping_address,
                shipping_address_text: shipping_address_text,
                discounts: this.customer.discounts,
                terms: this.terms,
                override_by: this.override_user_id,
                promo_id: this.promo_id,
                customer_po_num: this.customer_po_num,
                delivery_method: this.delivery_method,
                stock_card: this.stock_card,
                notes: this.notes,
                has_promo: this.has_promo,
                unique_key: unique_key,
                override_moq: this.customer.override_moq,
                override_amount_approval:this.override_srp.promo_type > 0 ? 1 : 0
            };
            this.$store.dispatch("storeSalesQuotations", parameters).then((response) => {
                let data = response.data;

                if(data.error){
                    swal.fire('', data.error, "warning");
                    this.loading.submit = false
                    return false;
                }
                window.Echo.channel('smod-channel').listen('SalesQuoataionMessage',(e)=>{
                    let data = e.data.find(el => el.userID == this.USERACCOUNT_LOAD.id);
                    let check_unique_id = (e.data[0].unique_key.toString().replace(/"/g,'') == unique_key)

                    if(check_unique_id && data && (data.message.msg || data.message.error) ){
                        if(data.message.error){
                            swal.fire('', data.message.error, "warning");
                            this.loading.submit = false
                            return false;
                        }
                        this.resetFields();
                        this.add_edit_salesquotation_dialog = false;
                        this.loading.submit = false
                        this.action = "success"; //use this to avoid double getAllSalesQuotations
                        swal.fire({
                            text: data.message.msg ,
                            icon: "success",
                        })
                    }
                })
            }).catch((error) => {
                console.log(error)
                this.loading.submit = false
                if(error.response.data.message == 'Item Srp must not be 0!'){
                    swal.fire(
                        "Error",
                        error.response.data.message,
                        "error"
                    )
                    return false
                }
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors;

                }
                if(error.response.status == 406){
                    console.log(error.response)
                    let items = ''
                    error.response.data.items.forEach(e=>{
                        items += `<li>${e}</li> `
                    })

                    swal.fire({
                        title:'Cannot input items due to no container arrive',
                        html:`<b>Items</b><br><ul> ${items} </ul> `,
                        icon:'error'
                    })
                }
            });
        },
        async showEditModal(value) {
            this.itemWithNoStock = [];
            this.fromShowEditModal = true;
            this.action = "Edit";
            this.editSalesQuotationId = value;
            this.getCustomers(true);
            this.getUsers();
            this.getSalesmanList();
            this.getItems();
            this.getItemsNotInPromo();
            this.getItemTypes();
            this.getPromos();
            this.delivery_method_list = [
                // {'value' : 0, 'text' : 'none'},
                { value: 1, text: "pick-up" },
                { value: 2, text: "delivery" },
            ];

            if (this.editSalesQuotationId != null && this.editSalesQuotationId > 0) {
                this.$store.dispatch("salesQuotations", this.editSalesQuotationId).then((response) => {
                    let data = response.data;

                    if (data.error) {
                        swal.fire('', data.error, "warning");
                        return false;
                    }

                    let sales_quotation = data.sales_quotation;
                    let orderList = data.sales_quotation.sales_quotation_items;

                    this.created_date = new Date(
                        Date.parse(
                            new Date(sales_quotation.created_at).toLocaleString("en-US", {
                                timeZone: "Asia/Manila",
                            })
                        )
                    );

                    this.customer.is_sp = sales_quotation.special;
                    this.customer.override_moq = sales_quotation.override_moq;

                    this.customer_promo = data.sales_quotation.customer_promo;

                    if (sales_quotation.discounts != '') {
                        this.customer.discounts = JSON.parse(sales_quotation.discounts);
                    }

                    if (orderList != null && orderList.length > 0) {
                        orderList.forEach((order, index) => {
                            this.addOrderItem();

                            this.orderList[index].id = order.id;
                            this.orderList[index].quantity = order.quantity;
                            this.orderList[index].sc_quantity = order.sc_quantity;

                            this.orderList[index].item.srp = order.srp;
                            this.orderList[index].disc_price = order.disc_price;

                            this.orderList[index].item.sp_price = order.item.sp_price;
                            this.orderList[index].item.selling_price = order.item.selling_price;

                            this.orderList[index].foc = order.foc;

                            this.orderList[index].promos = order.promos;

                            this.orderList[index].orig_sp_price = order.item.sp_price;
                            this.orderList[index].orig_selling_price = order.item.selling_price;

                            this.orderList[index].is_bundle = order.is_bundle;
                            this.orderList[index].backend_promo = order.backend_promo;

                            this.edit = true;

                            this.orderList[index].item_id = order.item_id;

                            this.orderList[index].override_amount = order.override_amount;
                            this.orderList[index].is_editable = true;
                            this.orderList[index].foc_remarks = order.foc_remarks;
                            this.orderList[index].suggested_items = order.item.suggested_items;
                            this.orderList[index].suggested_items_length = order.item.suggested_items.length;
                            this.orderList[index].total_stock_available = order.item.total_stocks
                            this.orderList[index].index = index;
                            this.orderList[index].color = '';

                            this.selectModel(index, order.item_id, false, order);
                        });
                    }

                    this.quote_num = sales_quotation.quote_num;
                    this.customer_id = sales_quotation.customer_id;
                    this.salesman_id = sales_quotation.salesman_id;
                    this.customer.shipping_address = sales_quotation.shipping_address_id;
                    this.created_at = sales_quotation.created_at;
                    this.issuer_name = sales_quotation.issuer_name;
                    this.remarks = sales_quotation.remarks;
                    this.override_user_id = sales_quotation.override_by;
                    this.promo_id = sales_quotation.promo_id;
                    this.promo_name = sales_quotation.promo_name;
                    this.customer_po_num = sales_quotation.customer_po_num;
                    this.delivery_method = sales_quotation.delivery_method;
                    this.stock_card = sales_quotation.stock_card;
                    this.notes = sales_quotation.notes;
                    this.has_promo = sales_quotation.has_promo

                    this.add_edit_salesquotation_dialog = true;
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
        async editSalesQuotation() {
            if (this.checkItemsZeroSRP()) {
                this.override_user_id == -1;
                this.showApproveOverrideAmountsDialog();
            } else {
                return false;
            }
        },
        async checkItemStocks(val, i) {
            await this.$store.dispatch(`TotalStocks`, val.item_id).then((response) => {
                val.quantity == '' ? (val.quantity = 0) : '';

                if(parseInt(response.data) == 0){
                    this.orderList[i].color = "bg-info";
                    if(!this.alreadySubstitutedItem && (this.promo_id == null || this.promo_id == '')){
                        let payload = {
                            item_id:val.item_id
                        }
                        this.$store.dispatch('getSubstitutionItemModel',payload).then(res=>{
                            let data = res.data

                            if(data){
                                this.selectModel(i, data.old_item_id, true);
                                this.orderList[i].original_item_id = data.item_id
                                swal.fire('','Some item has been replaced with new model(s).','warning');
                                this.alreadySubstitutedItem = true
                            }
                            else{
                                this.orderList[i].color = "bg-danger";
                            }
                        })
                    }
                }
                else if (parseInt(response.data) < parseInt(val.quantity)) {
                    this.orderList[i].color = "bg-danger";
                } else {
                    this.orderList[i].color = '';
                }
                this.$forceUpdate();
            });
        },
        async proceedUpdateQuotation() {
            this.errors = [];
            // var shipping_address_text = $("#shipping_address_text").parent().text();
            let shipping_address_text = ''
            this.shipping_address_list.forEach(e => {
                if (e.value == this.customer.shipping_address) {
                    shipping_address_text = e.text
                }
            })
            let params = {
                id: this.editSalesQuotationId,
                customer_id: this.customer_id,
                salesman_id: this.salesman_id,
                remarks: this.remarks,
                orderList: this.orderList,
                shipping_address_index: this.customer.shipping_address,
                shipping_address_text: shipping_address_text,
                discounts: this.customer.discounts,
                terms: this.terms,
                override_by: this.override_user_id,
                promo_id: this.promo_id,
                customer_po_num: this.customer_po_num,
                delivery_method: this.delivery_method,
                stock_card: this.stock_card,
                notes: this.notes,
                has_promo: this.has_promo,
            };
            this.$store.dispatch("salesQuotationsUpdate", params).then((response) => {
                let data = response.data;
                if (data.error) {
                    swal.fire('', data.error, "warning");
                    return false;
                }

                this.resetFields();
                this.add_edit_salesquotation_dialog = false;
                this.editSalesQuotationId = 0;
                this.action = "success"; //use this to avoid double getAllSalesQuotations
                swal.fire('', response.data.msg, "success").then((value) => {
                    location.reload();
                });
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors;
                }
            });
        },
        showSuggestionModal(val, i) {
            this.showOutOfStockDialog = true;
            this.selectedSQ.data = val;
            this.selectedSQ.index = i;
        },
        resetFields() {
            this.errors = [];
            this.quote_num = '';
            this.quote_date = '';
            this.customer_id = '';
            this.salesman_id = '';
            this.user_id = '';
            this.remarks = '';
            this.customer.discounts = [];
            this.discounts = '';
            this.terms = '';
            this.convert_date = '';
            this.status = '';
            this.shipping_address_list = [];
            this.orderList = [];
            this.itemTypeDiscounts = [];
            this.type_list = [];
            this.override_user_id = -1;
            this.override_amount = '';
            this.override_index = '';
            this.override_username = '';
            this.override_user_password = '';
            this.promo_list = [];
            this.promo_id = null;
            this.shortlist_item_ids = [];
            this.shortlist_customer_ids = [];
            this.promo = '';
            this.customer_promo = '';
            this.customer_po_num = '';
            this.stock_card = 0;
            this.delivery_method = 2;
            this.delivery_method_list = [];
            this.tempOrderList = [];
            this.batch_addition.errors = [];
            this.focRemarks = '';
            this.orderListIndex = '';
            this.showFocRemarks = false;
            this.notes = null;
            this.exclusive_items = [];
            this.has_promo = 0;
            this.loading.submit = false;
            this.loading.export = false;
            this.with_errors = false;
            this.override_srp.promo_type = '';
        },
        addKeyListener() {
            window.addEventListener("keyup", function (e) {
                var keypressed = String.fromCharCode(e.keyCode);
                if (e.ctrlKey && e.keyCode == 32) {
                    this.addOrderItem();
                    $("input").blur();
                } else if (keypressed == "-") {
                    this.removeOrderItem();
                    $("input").blur();
                }
            }.bind(this));
        },
        closeFocRemarksDialog() {
            this.orderList[this.orderListIndex].foc = false;
            this.changeFoc(this.orderListIndex);
        },
        submitSubstitution(){
            this.selectModel(this.index, this.itemSubstitution, false);
            this.itemSubsDialog = false ;
            this.resetItemSubs();
        },
        resetItemSubs(){
            this.index = 0;
            this.itemSubstitution = '';
        },
        deleteItem(i){
            this.batchSubstitution.list.splice(i,1);
        },
        async submitBatchSubstitution(){
            for await (let item of this.batchSubstitution.list) {
                if(item.item_sub_id){
                    await new Promise(async (resolve) => {
                        if(this.batchSubstitution.list.length > 1){
                            this.addOrderItem();
                        }
                        this.orderListLength =
                            this.orderList.length > 0 ? this.orderList.length : 0;
                        this.counter = this.orderListLength - 1;

                        this.orderList[this.counter].quantity = item.qty;
                        // this.orderList[this.counter].foc = item.foc

                        await this.selectModelForBatchAddition(
                            this.counter,
                            item.item_sub_id,
                            true
                        ).then((resolved) => {
                            resolve(resolved);
                        });
                    });
                }
            }

            this.batchSubstitution.dialog = false;
        },
        getSubItemDetails(id, selection, key){
            if(id){
                let srp = selection.find(x=>{
                    return x.id == id
                })
                return srp[key]
            }
            return ''
        },
        checkItemMoq(index, data){
            let qty = parseInt(data.qty);
            let moq = data.subsitutionSelection.find((e)=> e.id == data.item_sub_id).moq;

            let arr = [];
            if(qty % moq == 0){
                this.batchSubstitution.list[index].error = false;
            }
            else{
                var upperQty = qty;
                var lowerQty = qty;
                while (upperQty % moq > 0) {
                    upperQty++;
                }
                while (lowerQty % moq > 0) {
                    lowerQty--;
                }
                var suggestion = "";
                if (lowerQty == 0) {
                    suggestion = "\n Suggestion: " + upperQty ?? 0;
                } else {
                    suggestion = "\n Suggestion: " + upperQty ?? 0 + " or " + lowerQty ?? 0;
                }
                swal.fire(
                    "",
                    "Quantity should be divisible by " + moq ?? 0 + suggestion ?? 0,
                    "warning"
                );
                qty = "";
                data.error = true
                this.batchSubstitution.list[index].error = true;
            }

            this.batchSubstitution.list[index].qty = qty;
            arr = this.batchSubstitution.list;
            this.batchSubstitution.list = [];
            this.batchSubstitution.list = arr;

        },
        overrideAmounts(){
            this.override.from = 1
            this.override.override_dialog = true
            this.override.headers = 'Override Amounts'
        },
        recomputeDiscAmount(index,action = 0){
            let discount = 0;
            if(action == 0){
                let disc = []
                discount = this.orderList[index].item.display_srp;
                this.itemTypeDiscounts.forEach(e => {
                    e = e.split(':');
                    if(e[0] == this.orderList[index].type){
                        disc = e[1].split('/');
                    }
                });

                disc.forEach(e=>{
                    discount = parseInt(discount) - (parseInt(discount) * (parseInt(e)/100))
                })

                this.orderList[index].display_disc_price = discount
                this.orderList[index].disc_price = discount
                this.orderList[index].item.srp = this.orderList[index].item.display_srp

            }else if(action == 1){
                discount = this.orderList[index].display_disc_price
                // this.orderList[index].disc_price = discount
                this.orderList[index].override_amount = discount
            }

            this.orderList[index].total_amount =  discount * this.orderList[index].quantity
            this.orderList[index].amount = 1

            this.$forceUpdate();
        },

        hasPromo() {
            return this.promo_id !== null && this.promo_id !== undefined && this.promo_id !== "";
        },


    },
    mounted() {
        this.addKeyListener();
        this.callPusher();
    },
    watch: {
        add_edit_salesquotation_dialog: function () {
            if (this.add_edit_salesquotation_dialog == true) {
                this.loaded = false;
                if (this.action == "Add") {
                    this.orderList = [];
                    this.getCustomers(true);
                    this.getUsers();
                    this.getSalesmanList();
                    this.getItems();
                    this.getItemsNotInPromo();
                    this.getItemTypes();
                    this.getPromos();
                    this.delivery_method_list = [
                        // {'value' : 0, 'text' : 'none'},
                        { value: 1, text: "pick-up" },
                        { value: 2, text: "delivery" },
                    ];
                    this.created_date = new Date(Date.parse(new Date().toLocaleString("en-US", "Asia/Manila")));
                    // this.created_date = new Date()
                }

                if (this.orderList.length == 0) {
                    this.addOrderItem();
                }

                this.loaded = true;
            }

            if (this.action == "Add") {
                if (!!this.isSalesman) {
                    if (!!this.currentSalesmanId) {
                        this.salesman_id = this.currentSalesmanId;
                    }
                }
            }
        },
        customer_id: function (value) {
            if (this.add_edit_salesquotation_dialog == true) {
                this.loaded = false;
                if (value > 0) {
                    this.$store.dispatch("showCustomers", value).then((response) => {
                        let data = response.data;

                        this.shipping_address_list = data.all_shipping_addresses;
                        if (this.shipping_address_list.length > 0) {
                            this.customer.shipping_address = this.shipping_address_list[0].value
                        } else {
                            this.customer.shipping_address = ''
                        }

                        this.salesman_id = data.salesman_id;
                        this.terms = data.terms;
                        this.customer.is_sp = data.special;
                        this.orig_terms = data.terms;
                        this.customer.override_moq = data.override_moq

                        if (data.discounts != null && data.discounts != '') {
                            this.customer.discounts = JSON.parse(data.discounts);
                        }

                        if (this.fromShowEditModal == false && this.orderList != null && this.orderList.length > 0) {
                            this.orderList.forEach((order, index) => {
                                this.selectModel(index, order.item_id, false);
                            });
                        } else {
                            this.fromShowEditModal = false;
                        }

                        if (this.action == "Add") {
                            this.orderList = [];
                            this.addOrderItem();
                            this.getCustomerPromoInfo();
                        }

                        this.loaded = true;
                    }).catch((error) => {

                    });
                }
            }
        },
        promo_id: async function (value) {
            if (value != null && value > 0) {
                this.loaded = false;
                await this.$store.dispatch("getPromoShortlists", value).then((response) => {
                    let data = response.data;

                    this.shortlist_item_ids = data.item_ids;
                    this.shortlist_customer_ids = data.customer_ids;

                    if (this.action == "Add") {
                        this.orderList = [];
                        this.addOrderItem();
                        this.customer_id = '';
                        this.customer.shipping_address = ''
                        this.salesman_id = ''
                        this.terms = ''
                        this.override_srp.promo_type = 0
                    }
                }).catch((error) => {

                });
                this.loaded = true;
            }
        },
        'override_srp.promo_type':{
            handler(val){
                this.rerender = true
                if(val == 2){
                    this.override_srp.enabled = true
                }else if (val == 1){
                    this.override_srp.enabled = false
                    this.orderList.forEach((e,i)=>{
                        e.item.display_srp = e.item.selling_price
                        e.amount = e.item.sp_price
                        e.total_amount = e.amount * e.quantity
                        e.override_amount = e.item.sp_price
                    })
                }else{
                    this.override_srp.enabled = false
                        this.orderList.forEach((e,i)=>{
                        e.item.display_srp = e.item.selling_price
                        this.recomputeDiscAmount(i,0)
                    })
                }
                setTimeout(()=>{
                  this.rerender = false
                },1000)
            },
            deep:true
        },
        orderList: {
            deep: true,
            handler(val) {
                if (this.orderList.length > 0) {
                    this.orderList.forEach((order, index) => {
                        if (order.item.srp != null && order.item.srp != '') {
                            order.item.display_srp = this.getTwoDecimals(order.item.srp);
                        } else {
                            order.item.display_srp = '';
                        }

                        if (order.disc_price != null && order.disc_price != '') {
                            order.display_disc_price = this.getTwoDecimals(order.disc_price);
                        } else {
                            order.display_disc_price = '';
                        }

                        if (order.total_amount != null && order.total_amount != '') {
                            order.display_total_amount = this.getTwoDecimals(order.total_amount);
                        } else {
                            order.display_total_amount = '';
                        }

                        if (order.override_amount != null && order.override_amount != '') {
                            order.display_override_amount = this.getTwoDecimals(order.override_amount);
                        } else {
                            order.display_override_amount = '';
                        }
                    });
                }
                this.$forceUpdate();
            },
        },
        salesmanDetails() {
            if (!!this.salesmanDetails) {
                this.isSalesman = this.salesmanDetails.isSalesman;
                this.salesmanTitle = this.salesmanDetails.salesmanPositionTitle;
                this.salesmanChildrenIds = this.salesmanDetails.salesmanChildrenIds;
                this.userChildrenIds = this.salesmanDetails.userChildrenIds;

                if (!!this.salesmanDetails.salesman) {
                    this.currentSalesmanId = this.salesmanDetails.salesman.id;
                    this.isSalesCoordinator = !!this.salesmanDetails.salesman.sales_coordinator;
                    this.currentSalesmanPositionId = this.salesmanDetails.salesman.salesman_position_id;
                }
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.create = false;
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                    });
                }
            },
        },
    },
};
</script>
<style>
.promo_note {
    font-size: x-small;
    color: red;
}
.dialog {
    overflow: unset;
}

.notInModel {
    background-color: rgb(236, 68, 68);
    height: 40px;
    border-radius: 5px;
}
</style>
