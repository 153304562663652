<template>
    <v-container>
        <v-row class="ma-0 pa-0">
            <v-col v-if="origin == 'viewPayment'">
                <v-btn color="primary" @click="custPaymentDetailShowAddEditDialog(); replaceActive()">
                    Replace
                </v-btn>
            </v-col>
            <v-col v-else-if="origin == 'editSoa' || perPL" :class="!!perPL ? 'd-flex justify-content-center align-items-center' : ''">
                <v-btn :disabled="hasRemaining" @click="custPaymentDetailShowAddEditDialog()">
                    <v-icon>mdi-plus</v-icon>
                    <span></span>
                    Add Payment
                </v-btn>
            </v-col>

        </v-row>

        <v-dialog v-model="addEditCustPaymentDialog" scrollable persistent max-width="65%" retain-focus>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">{{ `${dialogAction == 'edit' ? 'Edit' : 'Add'} Customer Payment Detail` }}</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="custPaymentDetailCloseAddEditDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <v-card-text class="px-4 pb-2">
                    <!-- <v-row :class="hide_upload?'pb-2 p-2 d-flex align-items-center':'pb-2 p-2 d-none align-items-center'"> -->
                    <v-row class="pb-2 p-2 d-flex align-items-center">
                        <v-col dense cols="5" class="text-left">
                            <div style="display: flex; float:left;" class="pa-2">
                                <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload"/>
                                <v-divider class='mx-2' vertical></v-divider>
                                <BaseCameraCapture ref="cameraCapture" @capturedImages="getImages"></BaseCameraCapture>
                                <!-- <v-btn class="mx-2" v-if="repairFormId"
                                    @click="redirect('/service/inquiry?rf_id=' + repairFormId)">
                                    View Repair Form
                                </v-btn> -->
                                <BaseFilesViewerVue class="mt-1 mx-2" :customerPaymentDetailId="customerPaymentDetailId" :delete="true" @openFilesViewer="openFilesViewer" @closeFilesViewer="closeFilesViewer"/>
                            </div>
                        </v-col>
                        <!-- <div style="display:inline-block" class="pr-2">
                            Upload:
                        </div>
                        <div style="display:inline-block">

                        </div>

                        <div style="display:inline-block" class="ml-2">

                        </div> -->
                    </v-row>
                    <v-layout row class="mx-0">
                        <v-flex lg6 class="pr-2">
                            <v-card class="pa-2 mb-2">
                                <div class="pb-2">
                                    <div>
                                        <v-dialog
                                            ref="paymentDateDialog"
                                            v-model="paymentDateModal"
                                            :return-value.sync="paymentDate"
                                            persistent
                                            width="300px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="paymentDate"
                                                readonly
                                                dense outlined
                                                label="Payment Date *"
                                                class="hide-text-field-details"
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                                :disabled="isChequeDateEditOnly"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="paymentDate"
                                                scrollable
                                                :max="minDate"
                                                :disabled="isChequeDateEditOnly"
                                            >
                                            <v-spacer />
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="paymentDateModal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.paymentDateDialog.save(paymentDate)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </div>
                                </div>
                                <div class="">
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            :items="customerList"
                                            :loading="loading.customer_selection"
                                            v-model="selectedCustomer"
                                            item-value="value"
                                            item-text="text"
                                            label="Customer: *"
                                            :disabled="!!fromSoa"
                                        />
                                    </div>
                                </div>
                                <v-row class="pa-0 ma-0">
                                    <v-col>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            :items="paymentTypeLists"
                                            v-model="selectedPaymentType"
                                            item-value="id"
                                            item-text="name"
                                            label="Payment Type: *"
                                            :disabled="isChequeDateEditOnly"
                                        />
                                    </v-col>
                                    <v-col v-if="paymentTypeName == 'Deposit'">
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Deposit Type"
                                            :items="[{text:'Cash',value:1},{text:'Cheque',value:2}]"
                                            v-model="selectedDepositType"
                                            :disabled="isChequeDateEditOnly"

                                        />
                                    </v-col>
                                </v-row>
                                <div class="pb-2">
                                    <div>
                                        <v-textarea
                                        v-model="remarks"
                                        dense
                                        no-resize
                                        outlined
                                        label="Remarks: *"
                                        :disabled="isChequeDateEditOnly"
                                        />
                                    </div>
                                </div>
                            </v-card>

                            <!-- <v-card v-if="paymentTypeName == 'Cash'" class="pa-2">
                                <div style="text-align:end">Total Amount: ₱ {{ totalCashAmount | currency }}</div>
                            </v-card> -->
                        </v-flex>
                        <v-flex lg6>
                            <v-card v-if="paymentTypeName == 'Cash' || selectedDepositType == 1" class="pa-2">
                                <div v-if="selectedDepositType == 1">
                                    <v-autocomplete
                                        dense
                                        outlined
                                        label="Bank: *"
                                        :items="banks"
                                        v-model="selectedBank"
                                        item-value="id"
                                        item-text="name"
                                        :disabled="isChequeDateEditOnly"
                                    />
                                </div>
                                <div>
                                    Cash Denomination Details:
                                </div>
                                <v-divider />
                                <v-layout row class="mx-0">
                                    <v-spacer />
                                    <v-flex lg3>
                                        <div style="text-align:end">
                                            Denomination
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                    </v-flex>
                                    <v-flex lg2>
                                        <div style="text-align:end">
                                            Quantity
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                    </v-flex>
                                    <v-flex lg3>
                                        <div style="text-align:end">
                                            Amount
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-layout v-for="(cashDenomination, index) in cashDenominations" row :key="cashDenomination.id" class="mx-0 py-2">
                                    <v-spacer />
                                    <v-flex lg3>
                                        <div style="text-align:end" class="mt-2">
                                            {{ `${cashDenomination.value}` }}
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                        <div style="text-align:center" class="mt-2">
                                            x
                                        </div>
                                    </v-flex>
                                    <v-flex lg2>
                                        <div style="text-align:end">
                                            <v-text-field
                                                :ref="`c${index}`"
                                                type="number"
                                                dense
                                                outlined
                                                :min="0"
                                                v-model="cashDenomination.quantity"
                                                @change="getDenominationAmount(cashDenomination)"
                                                @focus="$event.target.select()"
                                            />
                                        </div>
                                    </v-flex>
                                    <v-flex lg1>
                                        <div style="text-align:center" class="mt-2">
                                            =
                                        </div>
                                    </v-flex>
                                    <v-flex lg3 class="mr-2">
                                        <div style="text-align:end" class="mt-2">
                                            {{ `${parseFloat(cashDenomination.value) * cashDenomination.quantity}` | currency }}
                                        </div>
                                    </v-flex>
                                </v-layout>

                                <v-divider />

                                <div style="text-align:end" class="pa-2">Amount: ₱ <span>{{ totalAmount | currency }}</span></div>
                            </v-card>

                            <v-card v-else-if="paymentTypeName != 'Cash' && !!selectedPaymentType && (paymentTypeName != 'Online' && paymentTypeName != 'Gcash') && paymentTypeName != 'Credit/Debit Card' || (paymentTypeName == 'Deposit' && selectedDepositType == 2)" class="pa-2">
                                <div>
                                    {{ `${['Cheque', 'Online', 'Gcash', 'Journal Voucher'].includes(paymentTypeName) ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />

                                <div v-if="!['Cash', 'Journal Voucher'].includes(paymentTypeName)" class="pb-2">

                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Bank: *"
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            :disabled="isChequeDateEditOnly"
                                        />
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            v-model="accountNumber"
                                            :label="['Journal Voucher'].includes(paymentTypeName) ? 'APV#' : 'Cheque/Account number *'"
                                            dense
                                            outlined
                                            :disabled="isChequeDateEditOnly"/>
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg7 class="pr-2" v-if="!['Journal Voucher'].includes(paymentTypeName)">
                                            <div>
                                                <v-text-field
                                                    v-model="accountName"
                                                    dense
                                                    label="Cheque/Account Name *"
                                                    outlined
                                                    :disabled="isChequeDateEditOnly"/>
                                            </div>
                                        </v-flex>
                                        <v-flex :lg5="!['Journal Voucher'].includes(paymentTypeName)">
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="accountDate"
                                                            readonly
                                                            dense
                                                            outlined
                                                            :label="['Journal Voucher'].includes(paymentTypeName) ? 'APV Date' : 'Cheque/Account Date *'"
                                                            class="hide-text-field-details"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            clearable
                                                            append-icon="mdi-calendar-month"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="accountDate"
                                                        scrollable
                                                    >
                                                        <v-spacer />
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="accountDateModal = false"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="$refs.accountDateDialog.save(accountDate)"
                                                        >
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2" v-if="!['Journal Voucher'].includes(paymentTypeName)">
                                    <div>
                                        <v-textarea
                                        v-model="accountDescription"
                                        dense
                                        no-resize
                                        label="Description"
                                        outlined
                                        :disabled="isChequeDateEditOnly" />
                                    </div>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            label="*Amount: ₱"
                                            style="
                                                text-align:right;
                                                padding:0 5px !important;
                                            "
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @focus="$event.target.select()"
                                            :disabled="isChequeDateEditOnly"
                                        />
                                    </div>
                                </div>

                            </v-card>
                            <v-card v-else-if="paymentTypeName == 'Online' || paymentTypeName == 'Gcash'" class="pa-2">
                                <div>
                                    {{ `${paymentTypeName == 'Online' || paymentTypeName == 'Gcash' ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />
                                <div v-if="paymentTypeName != 'Cash'" class="pb-2">
                                    <div>
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Bank: *"
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            :disabled="isChequeDateEditOnly"
                                        />
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <div class="mb-1">
                                        <v-text-field
                                            label="Account number *"
                                            v-model="accountNumber"
                                            dense
                                            outlined
                                            :disabled="isChequeDateEditOnly"/>
                                    </div>
                                    <div>
                                        <v-text-field
                                            label="Transaction Number *"
                                            v-model="transactionNumber"
                                            dense
                                            outlined
                                            :disabled="isChequeDateEditOnly" />
                                    </div>
                                </div>

                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg5>
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="online_date"
                                                        readonly
                                                        dense
                                                        outlined
                                                        label="Date *"
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        clearable
                                                        :disabled="isChequeDateEditOnly"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="online_date"
                                                        scrollable
                                                        :disabled="isChequeDateEditOnly"
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="accountDateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.accountDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2">
                                    <div>
                                        <v-textarea
                                        v-model="OnlineDescription"
                                        dense
                                        no-resize
                                        label="Description"
                                        outlined
                                        :disabled="isChequeDateEditOnly"/>
                                    </div>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            label="*Amount: ₱"
                                            style="
                                                text-align:right;
                                                padding:0 5px !important;
                                            "
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @focus="$event.target.select()"
                                            :disabled="isChequeDateEditOnly"
                                        />
                                    </div>
                                </div>
                            </v-card>

                            <v-card v-else-if="paymentTypeName == 'Credit/Debit Card'" class="pa-2">
                                <div>
                                    {{ `${paymentTypeName == 'Credit/Debit Card' ? paymentTypeName : '' } Details:` }}
                                </div>
                                <v-divider />
                                <div class="pb-2">
                                    <div class="mb-1">
                                        <v-autocomplete
                                            dense
                                            outlined
                                            label="Bank: *"
                                            :items="banks"
                                            v-model="selectedBank"
                                            item-value="id"
                                            item-text="name"
                                            :disabled="isChequeDateEditOnly"
                                        />
                                    </div>
                                    <div>
                                        <v-text-field
                                            label="Card Name *"
                                            v-model="card_name"
                                            dense
                                            outlined
                                            :disabled="isChequeDateEditOnly"/>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            label="Card Number *"
                                            v-model="card_number"
                                            dense
                                            outlined
                                            :disabled="isChequeDateEditOnly"/>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <div>
                                        <v-text-field
                                            label="Approval Code *"
                                            v-model="approval_code"
                                            dense
                                            outlined
                                            :disabled="isChequeDateEditOnly"/>
                                    </div>
                                </div>
                                <div class="pb-2">
                                    <v-layout row class="mx-0 my-1">
                                        <v-flex lg5>
                                            <div>
                                            </div>
                                            <div>
                                                <v-dialog
                                                    ref="accountDateDialog"
                                                    v-model="accountDateModal"
                                                    :return-value.sync="accountDate"
                                                    persistent
                                                    width="290px"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="card_date"
                                                        readonly
                                                        dense
                                                        outlined
                                                        label="Date *"
                                                        class="hide-text-field-details"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        clearable
                                                        :disabled="isChequeDateEditOnly"
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="card_date"
                                                        scrollable
                                                        :disabled="isChequeDateEditOnly"
                                                    >
                                                    <v-spacer />
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="accountDateModal = false"
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.accountDateDialog.save(accountDate)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="pb-2" style="text-align:end">
                                    <div style="display:inline-block">
                                        <v-text-field
                                            type="text"
                                            :min="0"
                                            v-model="totalAmount"
                                            dense
                                            outlined
                                            label="*Amount: ₱"
                                            style="
                                                text-align:right;
                                                padding:0 5px !important;
                                            "
                                            @keypress="decimalOnly"
                                            @keypress.enter="getTotalAmount()"
                                            @focus="$event.target.select()"
                                            :disabled="isChequeDateEditOnly"
                                        />
                                    </div>
                                </div>
                            </v-card>
                        </v-flex>
                    </v-layout>

                    <v-divider />

                    <v-row style="text-align:end" class="py-4 d-flex flex-column align-content-end mr-2 align-items-center">
                        <div class="pb-2 float-right">
                                <v-text-field
                                    type="text"
                                    :min="0"
                                    :max="totalAmount"
                                    v-model="whtAmount"
                                    dense
                                    outlined
                                    label="WHT Amount: ₱"
                                    style="
                                        width: 175px;
                                        padding:0 5px !important;
                                    "
                                    :disabled="totalAmount == 0"
                                    @keypress="decimalOnly"
                                    @keypress.enter="getWhtAmount()"
                                    @focus="$event.target.select()"
                                />
                        </div>
                        <div class="pb-2 float-right">
                                <v-text-field
                                    type="text"
                                    :min="0"
                                    :max="totalAmount"
                                    v-model="otherAdjAmount"
                                    dense
                                    outlined
                                    label="Other Adj Amount: ₱"
                                    style="
                                        text-align:right;
                                        width: 175px;
                                        padding:0 5px !important;
                                    "
                                    :disabled="totalAmount == 0"
                                    @keypress="decimalOnly"
                                    @keypress.enter="getOtherAdjAmount()"
                                    @focus="$event.target.select()"
                                />

                        </div>
                        <div class="pb-2">
                            Balance Amount: ₱ {{ balanceAmounts | currency}}
                        </div>
                        <div>
                            Net Amount: ₱ {{ netAmount | currency }}
                        </div>
                    </v-row>
                </v-card-text>

                <v-divider class="mt-0" />

                <div style="text-align:center" class="pb-4" >
                    <v-btn v-if="dialogAction == 'edit'" @click="update()">Update</v-btn>
                    <v-btn v-else :loading="submitOnFlight" @click="submit()">Submit</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="replaceCheckDialog" width="25%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span class="headline">Replace Reason</span>
                        </v-card-title>
                    </v-col>
                </v-row>

                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-textarea
                            rows="2"
                            dense
                            outlined
                            v-model="replaceCheck.reason"
                            label="Reason"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="submitReplaceCheck(1)">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="moveDateDialog" width="25%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="10">
                        <v-card-title>
                            <span class="headline">Move Date Reason</span>
                        </v-card-title>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-textarea
                            rows="2"
                            dense
                            outlined
                            v-model="move_date_reason"
                            label="Reason"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="moveChequeDate(1)">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import FileUploadComponentVue from '../../../../Shared/FileUploadComponent.vue'
import BaseFilesViewerVue from '../../Base/BaseFilesViewer.vue';
import BaseCameraCapture from '../../../Services/Base/BaseCameraCapture.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex'
import moment from 'moment';
export default {
    data() {
        return {
            customerList: [],
            selectedCustomer: '',
            paymentTypeLists: [],
            banks: [],
            cashDenominations: [],
            submitOnFlight: false,
            updateOnFlight: false,
            uploadedFiles: null,
            minDate: moment().format('YYYY-MM-DD'),

            accountDateModal: false,
            selectedPaymentType: '',
            selectedBank: '',
            selectedChequeBank: '',
            paymentTypeName: '',
            remarks: '',
            chequeAccountNumber: '',

            chequeName: '',
            chequeDescription: '',
            totalAmount: 0,
            chequeAmount: 0,
            chequeDate: '',
            customerPaymentDetail: null,

            whtAmount: 0,
            otherAdjAmount: 0,

            paymentDateModal: false,
            paymentDate: '',

            accountNumber: '',
            accountName: '',
            accountDate: '',
            accountDescription: '',

            online_date:'',
            transactionNumber:'',
            OnlineDescription:'',
            userAccess: {
                    create  : false,
                    edit    : false,
                    view    : false,
                    delete  : false,
                },

            hide_upload:false,
            loading:{
                customer_selection          : true,
                payment_type_selection      : true,
                banks_selection             : true
            },
            expectedDepositDate: '',
            card_name:'',
            card_number:'',
            approval_code:'',
            card_date:'',
            images:null,
            generated_soa_id:'',
            replaceCheckDialog:false,
            replaceCheck:{
                date:'',
                date_dialog:false,
                reason:''
            },
            new_cpd_id:null,
            moveDateDialog:false,
            move_date_reason:-1,
            selectedDepositType:0,
        }
    },
    async mounted() {
        await this.getPaymentTypes();
        await this.getAllCustomer();
        this.checkAccess();
    },
    components: {
        FileUploadComponentVue,
        BaseFilesViewerVue,
        BaseCameraCapture
    },
    props: ['addEdit', 'customerId', 'fromSoa','balanceAmounts','soa_id','origin','hasRemaining','perPL','soa_payload','cpd_id'],
    validations: {
        accountNumber: {
            required: requiredIf('requireCheque')
        },
        accountDate: {
            required: requiredIf('requireCheque')
        },
        paymentDate: {
            required
        },
        selectedCustomer: {
            required: requiredIf('requireCustomer')
        },
        transactionNumber: {
            required: requiredIf('requireOnline')
        },
        card_name: {
            required: requiredIf('requireCard')
        },
        card_number: {
            required: requiredIf('requireCard')
        },
        approval_code: {
            required: requiredIf('requireCard')
        },
        selectedPaymentType: {
            required
        },
        selectedBank: {
            required: requiredIf('checkPaymentType')
        },
        totalAmount: {
            required
        },
    },
    computed: {
        ...mapGetters([
            'GET_PAYMENT_TYPES',
            'GET_BANKS',
            'GET_CASH_DENOMINATIONS',
            'GET_CUSTOMER_PAYMENT_DETAIL',
            'GET_CUSTOMERS_LIST_SELECTION',
            'USER_ACCESS'
        ]),
        checkPaymentType() {
            if(this.paymentTypeName != 'Cash' && this.paymentTypeName != 'Credit/Debit Card' && this.selectedDepositType == 2) {
                return true;
            }

            return false;
        },
        requireCheque() {
            if(this.paymentTypeName == 'Cheque' || this.selectedDepositType == 2) {
                return true;
            }

            return false;
        },
        requireOnline(){
            if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                return true;
            }

            return false;
        },
        requireCustomer(){
            if(!!this.fromSoa) {
                return true;
            }
            // this.selectedCustomer = 0;
            return false;
        },
        requireCard(){
            if(this.paymentTypeName == 'Credit/Debit Card') {
                return true;
            }

            return false;
        },
        addEditCustPaymentDialog() {
            this.hide_upload = false;
            if(this.dialogAction == 'edit'){
                return this.$store.state.accounting.customerPaymentDetail.addEditCustPaymentDialog;
            }
            if(this.dialogAction == 'add'){
                return this.$store.state.accounting.customerPaymentDetail.addEditCustPaymentDialog;
            }
        },
        dialogAction() {
            return this.$store.state.accounting.customerPaymentDetail.dialogAction;
        },
        customerPaymentDetailId() {
            return this.$store.state.accounting.customerPaymentDetail.customerPaymentDetailId;
        },
        grossAmount() {
            if(this.convertTextToNumber(this.totalAmount) > 0) {
                let amount = this.convertTextToNumber(this.totalAmount) + this.convertTextToNumber(this.whtAmount);

                return this.getTwoDecimals(amount);
            }

            return this.getTwoDecimals(0);
        },
        netAmount() {
            if(this.convertTextToNumber(this.totalAmount) > 0) {
                let amount = this.convertTextToNumber(this.grossAmount) - this.convertTextToNumber(this.whtAmount) - this.convertTextToNumber(this.otherAdjAmount);

                return this.getTwoDecimals(amount);
            }

            return this.getTwoDecimals(0);
        },
        isChequeDateEditOnly(){
            return this.$store.state.accounting.custPaymentDetailEditChequeDate;
        },
        isReplaceCheque(){
            return this.$store.state.accounting.custPaymentDetailReplaceCheque;
        }
    },
    watch: {
        selectedPaymentType() {
            if(!!this.selectedPaymentType) {

                let paymentType = this.paymentTypeLists.find(x=>x.id == this.selectedPaymentType);

                this.paymentTypeName = paymentType.name;

                let payload = {
                    payment_type_id: this.selectedPaymentType,
                    url: `getBanksByPaymentType`
                }
                this.$store.dispatch('banksGet',payload).then(response=>{
                    this.banks = response.data;
                });

            }
        },
        paymentTypeName() {
            if(this.paymentTypeName == 'Cash') {
                // if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                    this.getCashDenominations();
                // }
            }
            else if(this.paymentTypeName == 'Credit/Debit Card'){
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                }
            }
            else {
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                    this.clearCashDenominations();
                }
            }
        },
        selectedDepositType(){
            if(this.paymentTypeName == 'Deposit' && this.selectedDepositType == 1){
                if(this.dialogAction == 'add') {
                    this.hide_upload = false;
                    this.getCashDenominations();
                }
            }
            else {
                if(this.dialogAction == 'add') {
                    this.hide_upload = true;
                    this.clearCashDenominations();
                }
            }
        },
        customerPaymentDetail() {
            if(!!this.customerPaymentDetail) {
                const {
                    payment_date,
                    customer_id,
                    payment_type_id,
                    remarks,
                    cash_denomination_breakdown,
                    bank_id,
                    cheque_account_number,
                    cheque_name,
                    cheque_date,
                    cheque_description,
                    account_number,
                    account_name,
                    account_date,
                    account_description,
                    total_amount,
                    wht_amount,
                    other_adj_amount,
                } = this.customerPaymentDetail;

                this.paymentDate = payment_date;
                this.selectedCustomer = customer_id;
                this.selectedPaymentType = payment_type_id;
                this.cashDenominations = JSON.parse(cash_denomination_breakdown);
                this.selectedBank = bank_id;

                let paymentType = this.paymentTypeLists.find(x=>x.id == payment_type_id);

                let paymentTypeName = !!paymentType ? paymentType.name : '';

                if(!!paymentTypeName) {
                    if(paymentTypeName == 'Cheque') {
                        this.accountNumber = cheque_account_number;
                        this.accountName = cheque_name;
                        this.accountDate = cheque_date;
                        this.accountDescription = cheque_description;
                    } else if(paymentTypeName != 'Cash' || paymentTypeName != 'Cheque') {
                        this.accountNumber = account_number;
                        this.accountName = account_name;
                        this.accountDate = account_date;
                        this.accountDescription = account_description;
                    }
                }

                this.remarks = remarks;

                this.totalAmount = this.getTwoDecimals(total_amount);
                this.whtAmount = this.getTwoDecimals(wht_amount);
                this.otherAdjAmount = this.getTwoDecimals(other_adj_amount);
            }
        },
        dialogAction() {
            if(this.dialogAction == 'edit' && !!this.customerPaymentDetailId) {
                this.getCustomerPaymentDetail();
            }
        },
        'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loading.customer_selection = false
            }
        },
        'GET_PAYMENT_TYPES':{
            handler(val){
                this.loading.payment_type_selection = false
            }
        },
        'GET_BANKS':{
            handler(val){
                this.loading.banks_selection = false
            }
        },
        accountDate:{
            handler(val){
                let dt = new Date(val); // get date
                let business_days = 2;

                let newDate = this.addBusinessDays(dt, business_days);
                let month = '' + (newDate.getMonth() + 1)
                let days = '' + newDate.getDate()
                let years = newDate.getFullYear()

                let formattedNewDate = [years,month,days].join('-');
                this.expectedDepositDate = formattedNewDate
            }
        },
        selectedCustomer:{
            handler(val){
                if(val == 275){
                    let end_user_payments_list = []
                    this.paymentTypeLists.forEach(e=>{
                        if(e.id == 2){
                        }else{
                            end_user_payments_list.push(e)
                        }
                    })
                    this.paymentTypeLists = end_user_payments_list
                }
            }
        },
    },
    methods: {
        clearFields() {
            this.selectedPaymentType = '';
            this.paymentTypeName = '';
            this.remarks = '';
            this.submitOnFlight = false;
            this.chequeName = '';
            this.chequeDate = '';
            this.selectedChequeBank = '';
            this.chequeAccountNumber = '';
            this.chequeDescription = '';
            this.chequeAmount = 0;
            this.totalAmount = 0;
            this.uploadedFiles = null;
            this.selectedCustomer = '';
            this.selectedBank = '';
            this.otherAdjAmount = 0;
            this.whtAmount = 0;
            this.paymentDate = '';
            this.chequeNumber = '';

            this.accountNumber = '';
            this.accountName = '';
            this.accountDate = '';
            this.accountDescription = '';

            this.$store.commit('custPaymentDetailClearDialogAction');
            this.$store.commit('custPaymentDetailClearId');

            this.generated_soa_id = 0
            this.replaceCheckDialog = false,
            this.replaceCheck = {
                date:'',
                date_dialog:false,
                reason:''
            },
            this.new_cpd_id = null,
            this.moveDateDialog = false,
            this.move_date_reason = -1,
            this.selectedDepositType = 0
        },
        checkAccess(){
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                let val = response;
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
                }
            })
        },
        custPaymentDetailShowAddEditDialog() {
            if(this.fromSoa) {
                this.selectedCustomer = this.customerId;
                if(this.origin == 'viewPayment'){
                    this.$store.commit('custPaymentDetailShowAddEditDialog');
                }
            }
            if (this.origin == 'editSoa' || this.perPL){
                this.$store.commit('custPaymentDetailEditChequeDate',false);
                this.$store.commit('custPaymentReplaceCheque',false);
                this.$store.commit('custPaymentDetailShowAddEditDialog');
            }
        },
        custPaymentDetailCloseAddEditDialog() {
            this.hide_upload = false;
            this.$store.commit('custPaymentDetailCloseAddEditDialog');
            this.clearFields();
            this.clearCashDenominations();
            this.clearCameraCapture();

        },
        async getAllCustomer(){
              await this.$store.dispatch('getAllCustomers').then((response)=>{ this.customerList= this.GET_CUSTOMERS_LIST_SELECTION })
        },
        async getPaymentTypes(){
            await this.$store.dispatch('getPaymentTypes').then(response=>{
                this.paymentTypeLists = this.GET_PAYMENT_TYPES
            })
        },
        async getBanks() {
            await this.$store.dispatch('getBanks').then(response=>{
                this.banks = this.GET_BANKS
            })
        },
        getCashDenominations() {
            this.$store.dispatch('getCashDenominations').then(response=>{
                let data = this.GET_CASH_DENOMINATIONS;
                let modified = [];

                if(data.length > 0) {
                    data.forEach(x=>{
                        modified.push(
                            Object.assign({
                                quantity: 0,
                                amount: 0
                            }, x)
                        )
                    });
                }

                this.cashDenominations = modified;
            })
        },
        clearCashDenominations() {
            this.cashDenominations.forEach(x=>{
                x.quantity = 0;
                x.amount = 0;
            });

            this.totalAmount = 0;
        },
        getDenominationAmount(data) {
            let cashDenomination = this.cashDenominations.find(x=>x.id == data.id);
            cashDenomination.amount = parseFloat(data.value) * parseInt(data.quantity);

            let totalAmount = this.cashDenominations.map(x=>{
                return parseFloat(x.value) * parseInt(x.quantity);
            });

            this.totalAmount = this.getTwoDecimals(_.sum(totalAmount));
        },
        uploadedData(data) {
            if(!data) {
                this.uploadedFiles = null;
            }

            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if(confirm) {
                this.uploadedFiles = null;
            }
            this.$refs.fileUpload.reset();
        },
        getChequeAmount() {
            this.totalAmount = this.chequeAmount;
        },
        async submit() {
            this.submitOnFlight = true;

            this.$v.$touch();
            let message = !!this.perPL ? 'Statement Of Account Created' : '';
            if((this.uploadedFiles == null && this.hide_upload && this.images == null) || (this.selectedDepositType == 2 && this.uploadedFiles == null && this.hide_upload && this.images == null)) {
                swal.fire('', 'Please Upload Payment Images', 'warning').then(x=>{
                    this.submitOnFlight = false;
                });
            } else if(!this.$v.$error) {
                if(this.paymentTypeName == 'Cheque' && !!this.selectedCustomer){
                    swal.fire({
                    title: 'Allocate Cheque?',
                    text: 'Do you want to allocate this cheque in Customers Credit Limit?',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Yes',
                    cancelButtonText:'No',
                    showCloseButton:true,
                    }).then(async (result)=>{
                        if(result.isConfirmed) {
                            if(this.perPL){
                                await this.createStatementOfAccount();
                            }
                            const data = new FormData();
                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }

                            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                                let files = this.uploadedFiles.attachments;

                                for(let i=0;i < _.compact(files).length;i++) {
                                    data.append('files[]', files[i]);
                                }
                            }

                            if(!!this.images && this.images.length > 0) {
                                for(let i=0;i < _.compact(this.images).length;i++) {
                                    data.append('images[]', _.compact(this.images)[i]);
                                }
                            }

                            data.append('paymentDate', this.paymentDate);
                            data.append('customerId', this.selectedCustomer);
                            data.append('paymentTypeId', this.selectedPaymentType);
                            data.append('paymentTypeName', this.paymentTypeName);
                            data.append('remarks', this.remarks);

                            data.append('bankId', this.selectedBank)

                            if(this.paymentTypeName == 'Cheque') {
                                data.append('chequeAccountNumber', this.accountNumber);
                                data.append('chequeName', this.accountName);
                                data.append('chequeDate', this.accountDate);
                                data.append('chequeDescription', this.accountDescription);
                            } else if(this.paymentTypeName == 'Deposit') {
                                data.append('accountNumber', this.accountNumber);
                                data.append('accountName', this.accountName);
                                data.append('accountDate', this.accountDate);
                                data.append('accountDescription', this.accountDescription);
                                data.append('deposit_type_id', this.selectedDepositType);
                            }
                            else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                                data.append('accountNumber', this.accountNumber);
                                data.append('transaction_number', this.transactionNumber);
                                data.append('online_date', this.online_date);
                                data.append('OnlineDescription', this.OnlineDescription);
                            }
                            else if(this.paymentTypeName == 'Credit/Debit Card') {
                                data.append('card_name', this.card_name);
                                data.append('card_number', this.card_number);
                                data.append('card_date', this.card_date);
                                data.append('approval_code', this.approval_code);
                            }

                            data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                            data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                            data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                            data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                            data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                            data.append('netAmount', this.convertTextToNumber(this.totalAmount));
                            // data.append('netAmount', this.convertTextToNumber(this.netAmount));
                            data.append('fromSoa', this.fromSoa);
                            data.append('expected_deposit_date',this.expectedDepositDate);
                            if(this.netAmount > this.balanceAmounts){
                                let limit = this.netAmount - this.getTwoDecimals(this.balanceAmounts);
                                data.append('allocatedAmount', this.convertTextToNumber(String(limit)));
                            }
                            else{
                                data.append('allocatedAmount', this.convertTextToNumber(this.totalAmount));
                            }

                            data.append('deposit', 1);
                            data.append('mode', 'Allocate');
                            data.append('soa_id', this.soa_id);
                            data.append('generated_soa_id',this.generated_soa_id)
                            data.append('is_per_pl',this.perPL);


                            let payload = {
                                params:data,
                                config: config
                            }

                            await this.$store.dispatch('createCustomerPaymentDetail',payload).then(response=>{
                                if(response.data.error) {
                                    swal.fire('',response.data.error,'error').then(confirm=>{
                                        this.submitOnFlight = false;
                                    });
                                } else {
                                    this.replaceCheque(response,message);
                                }
                            });

                        }
                        else{
                            swal.fire({
                            title: 'Are you sure you want to Add Customer Payment?',
                            text: 'Make sure that all information are correct',
                            icon: 'warning',
                            showConfirmButton:true,
                            showCancelButton  :  true,
                            reverseButtons:true,
                            allowOutsideClick :  false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor : 'grey',
                            confirmButtonText:'Confirm',
                            showCloseButton:true,
                            }).then(async (result)=>{
                                if(result.isConfirmed) {
                                    if(this.perPL){
                                        await this.createStatementOfAccount();
                                    }
                                    const data = new FormData();

                                    const config = {
                                        headers: {
                                            'content-type': 'multipart/form-data'
                                        }
                                    }

                                    if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                                        let files = this.uploadedFiles.attachments;

                                        for(let i=0;i < _.compact(files).length;i++) {
                                            data.append('files[]', files[i]);
                                        }
                                    }

                                    if(!!this.images && this.images.length > 0) {
                                        for(let i=0;i < _.compact(this.images).length;i++) {
                                            data.append('images[]', _.compact(this.images)[i]);
                                        }
                                    }

                                    data.append('paymentDate', this.paymentDate);
                                    data.append('customerId', this.selectedCustomer);
                                    data.append('paymentTypeId', this.selectedPaymentType);
                                    data.append('paymentTypeName', this.paymentTypeName);
                                    data.append('remarks', this.remarks);

                                    data.append('bankId', this.selectedBank)

                                    if(this.paymentTypeName == 'Cheque') {
                                        data.append('chequeAccountNumber', this.accountNumber);
                                        data.append('chequeName', this.accountName);
                                        data.append('chequeDate', this.accountDate);
                                        data.append('chequeDescription', this.accountDescription);
                                    } else if(this.paymentTypeName == 'Deposit') {
                                        data.append('accountNumber', this.accountNumber);
                                        data.append('accountName', this.accountName);
                                        data.append('accountDate', this.accountDate);
                                        data.append('accountDescription', this.accountDescription);
                                        data.append('deposit_type_id', this.selectedDepositType);
                                    }
                                    else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                                        data.append('accountNumber', this.accountNumber);
                                        data.append('transaction_number', this.transactionNumber);
                                        data.append('online_date', this.online_date);
                                        data.append('OnlineDescription', this.OnlineDescription);
                                    }
                                    else if(this.paymentTypeName == 'Credit/Debit Card') {
                                        data.append('card_name', this.card_name);
                                        data.append('card_number', this.card_number);
                                        data.append('card_date', this.card_date);
                                        data.append('approval_code', this.approval_code);
                                    }

                                    data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                                    data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                                    data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                                    data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                                    data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                                    // data.append('netAmount', this.convertTextToNumber(this.netAmount));
                                    data.append('netAmount', this.convertTextToNumber(this.totalAmount));
                                    data.append('fromSoa', this.fromSoa);
                                    data.append('expected_deposit_date',this.expectedDepositDate);
                                    // data.append('deposit', 0);
                                    data.append('soa_id', this.soa_id);
                                    data.append('generated_soa_id',this.generated_soa_id)
                                    data.append('is_per_pl',this.perPL);

                                    if(this.netAmount > this.balanceAmounts){
                                        let limit = this.netAmount - this.getTwoDecimals(this.balanceAmounts);
                                        data.append('allocatedAmount', this.convertTextToNumber(String(limit)));
                                    }
                                    else{
                                        data.append('allocatedAmount', this.convertTextToNumber(this.totalAmount));
                                    }
                                    let payload = {
                                        params:data,
                                        config: config
                                    }

                                    await this.$store.dispatch('createCustomerPaymentDetail',payload).then(response=>{
                                        if(response.data.error) {
                                            swal.fire('',response.data.error,'error').then(confirm=>{
                                                this.submitOnFlight = false;
                                            });
                                        } else {
                                            this.replaceCheque(response,message);
                                        }
                                    });
                                }
                                this.submitOnFlight = false;

                            });
                        }
                    })
                }
                else{
                    swal.fire({
                    title: 'Are you sure you want to Add Customer Payment?',
                    text: 'Make sure that all information are correct',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then(async (result)=>{
                    if(result.isConfirmed) {
                        if(this.perPL){
                            await this.createStatementOfAccount();
                        }
                        const data = new FormData();

                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }

                        if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                            let files = this.uploadedFiles.attachments;

                            for(let i=0;i < _.compact(files).length;i++) {
                                data.append('files[]', files[i]);
                            }
                        }

                        if(!!this.images && this.images.length > 0) {
                            for(let i=0;i < _.compact(this.images).length;i++) {
                                data.append('images[]', _.compact(this.images)[i]);
                            }
                        }

                        data.append('paymentDate', this.paymentDate);
                        data.append('customerId', this.selectedCustomer);
                        data.append('paymentTypeId', this.selectedPaymentType);
                        data.append('paymentTypeName', this.paymentTypeName);
                        data.append('remarks', this.remarks);

                        data.append('bankId', this.selectedBank)

                        if(this.paymentTypeName == 'Cheque') {
                            data.append('chequeAccountNumber', this.accountNumber);
                            data.append('chequeName', this.accountName);
                            data.append('chequeDate', this.accountDate);
                            data.append('chequeDescription', this.accountDescription);
                        } else if(this.paymentTypeName == 'Deposit') {
                            data.append('accountNumber', this.accountNumber);
                            data.append('accountName', this.accountName);
                            data.append('accountDate', this.accountDate);
                            data.append('accountDescription', this.accountDescription);
                            data.append('deposit_type_id', this.selectedDepositType);
                        }
                        else if(this.paymentTypeName == 'Online' || this.paymentTypeName == 'Gcash') {
                            data.append('accountNumber', this.accountNumber);
                            data.append('transaction_number', this.transactionNumber);
                            data.append('online_date', this.online_date);
                            data.append('OnlineDescription', this.OnlineDescription);
                        }
                        else if(this.paymentTypeName == 'Credit/Debit Card') {
                            data.append('card_name', this.card_name);
                            data.append('card_number', this.card_number);
                            data.append('card_date', this.card_date);
                            data.append('approval_code', this.approval_code);
                        }

                        data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                        data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                        data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                        data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                        data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                        data.append('netAmount', this.convertTextToNumber(this.totalAmount));
                        data.append('fromSoa', this.fromSoa);
                        data.append('expected_deposit_date',this.expectedDepositDate);
                        data.append('soa_id', this.soa_id);
                        data.append('generated_soa_id',this.generated_soa_id)
                        data.append('is_per_pl',this.perPL);

                        if(this.netAmount > this.balanceAmounts){
                            let limit = this.netAmount - this.getTwoDecimals(this.balanceAmounts);
                            data.append('allocatedAmount', this.convertTextToNumber(String(limit)));
                        }
                        else{
                            data.append('allocatedAmount', this.convertTextToNumber(this.totalAmount));
                        }

                        let payload = {
                            params:data,
                            config: config
                        }

                        await this.$store.dispatch('createCustomerPaymentDetail',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('',response.data.error,'error').then(confirm=>{
                                    this.submitOnFlight = false;
                                });
                            } else {
                                this.replaceCheque(response,message);
                            }
                        });
                    }
                    this.submitOnFlight = false;

                });
                }
            } else {
                swal.fire('','Please Fill Required Fields','warning').then(confirm=>{
                    this.submitOnFlight = false;
                });
            }
        },
        getCustomerPaymentDetail() {
            this.$store.dispatch('getCustomerPaymentDetail',this.customerPaymentDetailId).then(response=>{
                this.customerPaymentDetail = this.GET_CUSTOMER_PAYMENT_DETAIL;
                this.selectedDepositType = this.customerPaymentDetail.deposit_type_id
            });
        },
        update() {
            this.updateOnFlight = true;
            this.$v.$touch();
            if(this.move_date_reason === -1 && this.isChequeDateEditOnly){
                this.move_date_reason = null
                this.moveDateDialog = true
            }
            else{
                this.moveDateDialog = false
                if(!this.$v.$error) {
                    swal.fire({
                        title: 'Are you sure you want to Update Customer Payment?',
                        text: 'Make sure that all information are correct',
                        icon: 'warning',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result)=>{
                        if(result.isConfirmed) {
                            const data = new FormData();

                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }

                            if(!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                                let files = this.uploadedFiles.attachments;

                                for(let i=0;i < _.compact(files).length;i++) {
                                    data.append('files[]', files[i]);
                                }
                            }

                            data.append('customerPaymentDetailId', this.customerPaymentDetailId);

                            data.append('paymentDate', this.paymentDate);
                            data.append('customerId', this.selectedCustomer);
                            data.append('paymentTypeId', this.selectedPaymentType);
                            data.append('paymentTypeName', this.paymentTypeName);
                            data.append('remarks', this.remarks);

                            data.append('bankId', this.selectedBank)

                            if(this.paymentTypeName == 'Cheque') {
                                data.append('chequeAccountNumber', this.accountNumber);
                                data.append('chequeName', this.accountName);
                                data.append('chequeDate', this.accountDate);
                                data.append('chequeDescription', this.accountDescription);
                            } else if(this.paymentTypeName != 'Cheque' || this.paymentTypeName != 'Cash') {
                                data.append('accountNumber', this.accountNumber);
                                data.append('accountName', this.accountName);
                                data.append('accountDate', this.accountDate);
                                data.append('accountDescription', this.accountDescription);
                            }

                            data.append('cashDenominations', JSON.stringify(this.cashDenominations));
                            data.append('totalAmount', this.convertTextToNumber(this.totalAmount));
                            data.append('grossAmount', this.convertTextToNumber(this.grossAmount));
                            data.append('whtAmount', this.convertTextToNumber(this.whtAmount));
                            data.append('otherAdjAmount', this.convertTextToNumber(this.otherAdjAmount));
                            data.append('netAmount', this.convertTextToNumber(this.netAmount));
                            data.append('move_date_reason',this.move_date_reason);
                            let payload = {
                                params:data,
                                config:config,
                            }

                            this.$store.dispatch('updateCustomerPaymentDetail',payload).then(response=>{
                                if(response.data.error) {
                                    swal.fire('',response.data.error,'error').then(confirm=>{
                                        this.updateOnFlight = false;
                                    });
                                } else {
                                    this.replaceCheque(response,"");
                                }
                            });
                        }
                        this.updateOnFlight = false;
                    });
                } else {
                    swal.fire('','Please Fill Required Fields','warning').then(confirm=>{
                        this.updateOnFlight = false;
                    });
                }
            }

        },
        decimalOnly ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        thousandSeprator (amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        getTwoDecimals (amount) {
            var formatted_amount = ''
            if(isNaN(amount)){
                if(amount != null && amount != ''){
                    formatted_amount = parseFloat(amount.replace(/,/g, ''))
                    formatted_amount = Math.trunc(formatted_amount * Math.pow(10, 3)) / Math.pow(10, 3)
                }
            }else{
                formatted_amount = Math.trunc(amount * Math.pow(10, 3)) / Math.pow(10, 3)
            }

            if (formatted_amount == '') {
                return 0.00
            }
            return this.thousandSeprator(formatted_amount.toFixed(2))
        },
        getTotalAmount() {
            this.totalAmount = this.getTwoDecimals(this.balanceAmounts - this.totalAmount);
        },
        getWhtAmount() {
            if(parseFloat(this.whtAmount) >= this.convertTextToNumber(this.totalAmount)) {
                this.whtAmount = this.getTwoDecimals(this.totalAmount);
            } else {
                this.whtAmount = this.getTwoDecimals(this.whtAmount);
            }
        },
        getOtherAdjAmount(){
            let balanceOfTotalAndWht = this.convertTextToNumber(this.totalAmount) - this.convertTextToNumber(this.whtAmount);

            if(parseFloat(this.otherAdjAmount) >= balanceOfTotalAndWht) {
                this.otherAdjAmount = this.getTwoDecimals(balanceOfTotalAndWht);
            } else {
                this.otherAdjAmount = this.getTwoDecimals(this.otherAdjAmount);
            }
        },
        convertTextToNumber(amount) {
            if(!!amount) {
                let amt = amount.replaceAll(',','');

                return parseFloat(amt);
            }
            return parseFloat(0);
        },
        openFilesViewer() {
            if(this.addEdit) {
                this.$store.commit('custPaymentDetailCloseAddEditDialog');
            }
        },
        closeFilesViewer() {
            if(this.addEdit) {
                this.$store.commit('custPaymentDetailShowAddEditDialog');
            }
        },
        addBusinessDays(originalDate, numDaysToAdd) {
                const Sunday = 0;
                const Saturday = 6;
                let daysRemaining = numDaysToAdd;

                const newDate = originalDate;

                while (daysRemaining > 0) {
                newDate.setDate(newDate.getDate() + 1);
                    if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
                    // skip sunday & saturday
                    daysRemaining--;
                    }
                }

                return newDate;
        },
        replaceCheque(response,message){
            if(this.isReplaceCheque){
                this.new_cpd_id = response.data.new_id
                this.replaceCheckDialog = true
            }
            else{
                this.custPaymentDetailCloseAddEditDialog();
                this.$store.commit('custPaymentDetailSuccessTrue');
                this.clearCameraCapture()
                this.uploadedFiles = null;
                this.$refs.fileUpload.reset();
                swal.fire(message, response.data.msg, "success").then(ok=>{
                    if(ok) {
                        this.$store.commit('custPaymentDetailSuccessFalse');
                        this.$emit('closeDialog');
                        this.submitOnFlight = false;
                    }
                })
            }
        },
        submitReplaceCheck(replace = null){
            let payload = {
                id: 0,
                cancel_reason:this.replaceCheck.reason,
                soa_id:this.soa_id,
                bounce_date:this.replaceCheck.date,
                isReplace:replace,
                new_cpd_id:this.new_cpd_id,
                cpd_id:this.cpd_id,
            }
            this.$store.dispatch('cancelCustomerPaymentAllocations',payload).then(response => {
                swal.fire(response.data.msg,'',response.data.status)

                this.$store.commit('custPaymentDetailCloseAddEditDialog');
                this.$store.commit('custPaymentDetailSuccessTrue');
                this.$store.commit('custPaymentDetailClose');
                this.custPaymentDetailCloseAddEditDialog();
                swal.fire("", response.data.msg, "success").then((value) => {
                    this.replaceCheckDialog = false
                    this.custPaymentDetailCloseAddEditDialog();
                    this.$store.commit('custPaymentDetailSuccessTrue');
                    this.clearCameraCapture()
                    this.uploadedFiles = null;
                    this.$refs.fileUpload.reset();
                    swal.fire('Replace Check Successfully', response.data.msg, "success").then(ok=>{
                        if(ok) {
                            this.$store.commit('custPaymentDetailSuccessFalse');
                            this.$emit('closeDialog');
                            this.$store.commit('custPaymentDetailClose');
                            this.submitOnFlight = false;
                        }
                    })
                    this.$store.commit('custPaymentDetailReplaceCheque',false);
                });
            })
        },
        moveChequeDate(){
            if(this.isChequeDateEditOnly){
                this.update()
            }
        },
        // replaceCheque(new_id){
        //     if(this.origin == "viewPayment"){
        //         let p = {
        //             id:this.customerPaymentDetailId,
        //             mode: 'Replace',
        //             new_id: new_id
        //         }
        //         this.$store.dispatch('updateChequeDate',p).then(response=>{
        //             swal.fire("", response.data.msg, "success").then(ok=>{
        //                 if(ok) {
        //                     this.$store.commit('custPaymentDetailSuccessFalse');
        //                     this.submitOnFlight = false;
        //                 }
        //             })
        //         })
        //     }
        // },
        getImages(images) {
            this.images = images;
        },
        clearCameraCapture() {
            this.$refs.cameraCapture.reset();
            this.images = null;
        },
        async createStatementOfAccount(){
            let soa_items = []
            soa_items.push(
                {
                    packing_list_id: this.soa_payload.id,
                    packing_list_num: this.soa_payload.packing_list_num,
                    sales_date: this.soa_payload.packing_list_date,
                    sales_total: this.soa_payload.total_amount,
                    remarks: null,
                    bank: null,
                    override_amount: null,
                }
            )
            let discount_amount = 0
            let selected_date = this.soa_payload.packing_list_date.substring(0,7)
            let due_date = moment(this.soa_payload.packing_list_date).add(this.soa_payload.terms, 'days').format('YYYY-MM');

            let payload = {
                customer_id:        275,
                super_product:      0,
                due_date:           due_date,
                contact:            '',
                terms:              this.soa_payload.terms,
                remarks:            '',
                soa_items:          soa_items,
                override_by:        '',
                selected_date:      selected_date,
                paid_amount:        0.00,
                discount_amount:    discount_amount,
                total_amount:       this.soa_payload.total_amount,
                payment_method:     this.selectedPaymentType,
                payment_date:       this.paymentDate,
                addition_amount:    0.00,
                deduction_amount:   0.00,
                addition_remarks:   '',
                deduction_remarks:  '',
                debit_memos:        [],
                credit_memos:       [],
                cash:               false,
                is_per_pl:          true,
            }
            await this.$store.dispatch('postStatementOfAccounts',payload).then(response => {
                this.generated_soa_id = response.data.soa_id
            });

        },
        replaceActive(){
            this.$store.commit('custPaymentDetailReplaceCheque',true);
        }


    },
    // watch:{
    //     'USER_ACCESS':{
    //         handler(val){
    //             this.userAccess.create = false;
    //             this.userAccess.edit = false;
    //             this.userAccess.delete = false;
    //             this.userAccess.view = false;
    //             if(val != "fail"){
    //             val.forEach((e) => {
    //                 if (e.actions_code == "create"){
    //                     this.userAccess.create = true;
    //                 }
    //                 else if (e.actions_code == "edit"){
    //                     this.userAccess.edit = true;
    //                 }
    //                 else if (e.actions_code == "view"){
    //                     this.userAccess.view = true;
    //                 }
    //                 else if (e.actions_code == "delete"){
    //                     this.userAccess.delete = true;
    //                 }
    //             });
    //             }

    //         }
    //     },
    // }
}
</script>

<style lang="scss">
    .hide-text-field-details {
        .v-text-field__details {
            display: none;
        }
    }
</style>
