import { render, staticRenderFns } from "./BanksTableComponent.vue?vue&type=template&id=5cd677e7"
import script from "./BanksTableComponent.vue?vue&type=script&lang=js"
export * from "./BanksTableComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports