<template>
<div class="w-100 p-1">
    <v-app id="my_vue_app">

        <v-card >
            <v-card-title>
                <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                <v-icon>fas fa-search</v-icon>
            </v-card-title>

            <v-container fluid>
                <v-data-table
                    :headers="headers"
                    :items="itemBrands"
                    :search="search"
                    :items-per-page="5"
                    :loading="loaded"
                    class="w-100"
                >

                <template v-slot:[`item.active`]="{ item }">
                    <span class="badge bg-primary" style="color:white;" v-if="item.active">Yes</span>
                    <span class="badge bg-danger" style="color:white;" v-else>No</span>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="blue" v-if="userAccess.includes('brands_edit')">
                        <v-icon class="btn-action" small @click="editItemBrand(item.id)">mdi-square-edit-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" v-if="userAccess.includes('brands_delete')">
                        <v-icon class="btn-action" small @click="deleteItemBrand(item.id)">mdi-delete</v-icon>
                    </v-btn>
                </template>

                </v-data-table>
            </v-container>
        </v-card>
    </v-app>

</div>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    components: {

    },
    props: [
        'itemBrandsActionResult',
        'userAccess'
    ],
    data () {
        return {
            actionResult : {
                model : 'ItemBrands',
                action : ''
            },
            loaded: false,
            itemBrands: [],
            itemsPerPageOptions: [5, 10 ,15],
            itemsPerPage: 10,
            search: '',
            headers: [
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Active', align: 'left', value: 'active', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],

        }
    },
    mounted() {
        this.getAllItemBrands()
    },
    computed:{
        ...mapGetters([
            'GET_ALL_ITEM_BRAND'
        ])
    },
    methods:{
        getAllItemBrands(){
            this.loaded = true;
            this.itemBrands = [];
            this.$store.dispatch('getAllItemBrand').then(response => {
                this.itemBrands = response.data
                this.loaded = false
            })
        },
        editItemBrand(id){
            this.$emit('showEditModal', id);
        },
        deleteItemBrand(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('destroyItemBrand',id).then(response => {
                        this.getAllItemBrands();
                        swal.fire('','Item Brand Successfully Deleted','success');
                    }).catch(error => {
                        // console.log(error)
                        swal.fire('','Item Brand Successfully Failed','error');
                    })
                }
            });
        },
    },
    watch: {
        itemBrandsActionResult: function(val){
            if(this.itemBrandsActionResult=='success'){
                this.getAllItemBrands();
            }
        }
    }
};
</script>
