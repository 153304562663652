<template>
    <div class="tabs-body-color">
        <v-container class="ma-0">
            <v-row class="row ma-0">
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <h4 class="page-title" style="color: black">Stock Request/Receive</h4>
                </v-col>
                <v-col cols="10" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn v-on:click="toggleDialog(`requestingWarehouse`); getWarehouses(); editedIndex = -1;">
                            <i class="fas fa-plus mr-1"></i>Create Stock Request/s
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="2" class="d-flex no-block align-items-center">
                    <v-autocomplete
                        v-model="view_type"
                        :items="['Warehouse','Daily']"
                        dense
                        filled
                        label="View"
                        background-color='white'
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row class="ma-2">
                <v-col cols="12">
                    <v-switch v-model="warehouse.virtual"
                        dark
                        label="NONE VIRTUAL"
                        @change="getUserWarehouses()">
                    </v-switch>
                </v-col>
            </v-row>

            <!-- PICK REQUESTING WAREHOUSE DIALOG -->
            <v-dialog v-model="requestingWarehouse.dialog" scrollable :retainfocus="false" persistent max-width="430px" style="z-index: 70;">
                <v-card>
                    <v-progress-linear
                        v-if="loading.state"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                    <v-row class="m-0">
                        <v-col cols="10">
                            <v-card-title>
                                <span class="text-lg font-semibold" v-text="`Requesting Warehouse`"></span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`requestingWarehouse`)">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text style="max-height: 460px; border:1px solid #e0e0e0; padding: 0;">
                        <v-row v-if="loading.state" tag="div" align="center" no-gutters class="px-2" v-text="`Please wait`">
                        </v-row>
                        <v-row  v-if="!loading.state" tag="div" align="center" no-gutters class="px-2">
                            <v-col class="d-flex pr-2" cols="10">
                                <v-row dense no-gutters tag="div">
                                    <v-col dense tag="div" sm="12" class="d-flex justify-start">
                                        <div class="font-weight-medium" v-text="`Warehouse`"></div>
                                    </v-col>
                                    <v-col dense tag="div" sm="12">
                                        <v-autocomplete
                                            :disabled="loading.state"
                                            hide-details
                                            item-text="text"
                                            item-value="value"
                                            class="cycle-count-text-sm"
                                            :items="warehouse.selection"
                                            v-model="warehouse.value"
                                            outlined
                                            dense
                                            flat
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-end" >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                color="#2337C6"
                                class="ml-1 mt-1"
                                @click="createFromHakot()"

                                >
                                    <v-icon>
                                        mdi-content-paste
                                    </v-icon>
                                </v-btn>
                            </template>

                            <span>Create From Hakot Report</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                color="#2337C6"
                                class="ml-1 mt-1"
                                @click="toggleDialog('batchAddition')"

                                >
                                    <v-icon>
                                        mdi-cloud-upload
                                    </v-icon>
                                </v-btn>
                            </template>

                            <span>Upload File for batch upload</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-bind="attrs"
                                v-on="on"
                                text
                                color="#2337C6"
                                class="ml-1 mt-1"
                                @click="createRequestDialog()"

                                >
                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                            </template>

                            <span>Manual Creation</span>
                        </v-tooltip>
                        <!-- <v-btn
                            small
                            :disabled="loading.state || !warehouse.value"
                            v-on:click="toggleDialog(`requestingWarehouse`);toggleDialog(`stockRequestCreationChainer`);prepareChainer(); getItems(warehouse.value); filterServingWarehouse()">
                            <v-icon>mdi-arrow-right-bold</v-icon>
                            Next
                        </v-btn> -->

                        <!-- <v-file-input label="File input" outlined dense @change="handleChange" /> -->
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="batchAddition.dialog"
                width="1000"
                scrollable
            >
                <v-card>
                    <v-card-title class="d-flex justify-content-between">
                        <span class="text-lg font-semibold" v-text="`CREATE STOCK REQUEST `"></span>
                        <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`batchAddition`)">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <!-- Expansion -->
                        <div v-if="isVisible">
                            <span>Please Upload Files.
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="#2337C6"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-file-question
                                      </v-icon>
                                    </template>
                                    <span><img src="/images/sr-st.png"/></span>
                                  </v-tooltip>
                            </span>
                        </div>

                        <div v-else>
                            <div class="text-center d-flex pb-4">
                                <v-btn small @click="all" class="pr-2">
                                View All
                                </v-btn>
                                <v-btn small @click="none" class="ml-2">
                                Close All
                                </v-btn>
                            </div>

                            <v-expansion-panels
                                v-model="panel"
                                multiple
                            >
                                <v-expansion-panel v-for="(warehouse,index) in batchAddition.imported.data" :key="index">
                                    <v-expansion-panel-header style="position:relative;">
                                        <v-card-title :style="!!warehouse.error ? 'color:red' : ''" class="d-flex justify-content-between">
                                            <div>
                                                <span style="font-size:12px">REQUESTING: {{warehouse.warehouse}}</span><br>
                                                <span style="font-size:12px">SOURCE: {{warehouse.initial_serving_warehouse}}</span>
                                            </div>
                                        </v-card-title>
                                        <v-icon
                                            class="remove_warehouse"
                                            color="#7c7c7c"
                                            size="18"
                                            @click="removeItem(0,index,'warehouse')"
                                            >
                                            mdi-close
                                        </v-icon>
                                    </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col lg="6">
                                            <span style="color:red;font-size:10px">
                                                <v-checkbox
                                                    v-if="warehouse.allowable_butal"
                                                    v-model="warehouse.allow_butal"
                                                    label="By Uom"
                                                    @change="updateUomQuantity(index)"
                                                >
                                                </v-checkbox>
                                            </span>
                                        </v-col>
                                        <v-col lg="5">
                                            <span>
                                                <ul>
                                                    <li v-for="(error,index) in warehouse.error_message" :key="index" :style="'color:'+error.color">{{ error.title }}</li>
                                                </ul>
                                            </span>
                                        </v-col>
                                        <v-col lg="1">

                                            <v-icon
                                             @click="toggleDialog('batchAddition.addition'); batchAddition.warehouse.selectedIndex = index"
                                             class="ml-2"
                                             color="#2337C6"
                                            >mdi-plus-circle</v-icon>

                                        </v-col>
                                    </v-row>
                                    <v-row v-for="(a,i) in  warehouse.details" :key="i" >
                                        <v-col lg="6">
                                            <v-text-field
                                                v-model="a.model"
                                                dense
                                                outlined
                                                small color="#2337C6"
                                                label="Item Model"
                                                disabled
                                            >
                                                <template v-slot:prepend-inner v-if="a.error">
                                                    <v-tooltip
                                                    bottom
                                                    >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" color="#71ADFA">mdi-alert</v-icon>
                                                    </template>
                                                        Model Not Found
                                                    </v-tooltip>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col lg="3">
                                            <v-text-field
                                                v-model="a.request_type_quantity"
                                                dense
                                                outlined
                                                small color="#2337C6"
                                                :label="warehouse.allow_butal ? 'UOM' : 'Master Box'"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col lg="3" class="d-flex align-center justify-center">

                                            <v-text-field
                                                :value="!!stockRequestTotalUomComputer(a,warehouse.allow_butal ? 5 : 1,a.request_type_quantity) ? stockRequestTotalUomComputer(a,warehouse.allow_butal ? 5 : 1,a.request_type_quantity) : 0"
                                                dense
                                                outlined
                                                small color="#2337C6"
                                                :label="!warehouse.allow_butal ? 'UOM' : 'Master Box'"
                                                disabled
                                            ></v-text-field>

                                            <v-icon
                                                class="ml-2"
                                                color="red"
                                                @click="removeItem(i,index,'item')"
                                            >
                                                mdi-minus-circle
                                            </v-icon>

                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                        <!-- Expansion -->
                    </v-card-text>
                    <v-card-actions class="pa-5">
                        <v-row class="d-flex justify-content-around align-items-center">
                            <v-col lg="3">
                                <v-file-input label="File input" outlined dense @change="handleChange" />
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col lg="1">
                                <v-btn small @click="createBatch()" >Create</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- PREVIEW SELECTED REQUEST DIALOG -->
            <v-dialog v-model="previewSelectedRequest.dialog" :retainfocus="false" scrollable persistent max-width="866px" style="z-index: 100;">
                <v-card>
                    <v-progress-linear
                        v-if="loading.state"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="text-lg font-semibold" v-text="`Preview`"></span>
                            </v-card-title>
                        </v-col>

                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`previewSelectedRequest`)">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text style="max-height: 548px; border:1px solid #e0e0e0; padding: 5px;">
                        <div v-for="(preview, index) in previewSelectedRequest.data" :key="preview.name">
                            <div class="text-base mb-2 font-semibold" :class="[index > 0 ? `mt-5`: ``]" v-text="`REQUEST FOR ${preview.name}`"></div>

                            <v-simple-table dense style="border:1px solid #e0e0e0;">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left" style="width:300px; min-width:300px;">MODEL</th>
                                        <th class="text-left" style="width:300px; min-width:300px;">NAME</th>
                                        <th class="text-right"> {{preview.allow_butal == 1 ? 'Quantity (UOM)' : 'Quantity (MB)'}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(detail, index) in preview.details" :key="detail.item_id">
                                        <td><span v-text="detail.model"></span></td>
                                        <td><span v-text="detail.name"></span></td>
                                        <td class="text-right"><span v-text="detail.requested_mbox_quantity != null ? formatNumber(detail.requested_mbox_quantity): ''"></span></td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- STOCK REQUEST CREATION CHAINER DIALOG -->
            <v-dialog v-model="stockRequestCreationChainer.dialog" style="z-index: 70;">
                <div v-if="previewSelectedRequest.dialog" style="background-color: rgba(100,100,100,0.5); position:absolute; width:100%; height:100%; z-index: 80;"></div>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="text-lg font-semibold" v-text="`CREATE STOCK REQUEST : ${Lodash.find(warehouse.selection, w => w.value == warehouse.value).code} - ${Lodash.find(warehouse.selection, w => w.value == warehouse.value).text} `"></span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`stockRequestCreationChainer`)">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-progress-linear
                        v-if="itemSuggestions.loading || loading.state"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                    <v-row dense no-gutters tag="div" class="px-2 pb-3">
                        <v-col sm="5">
                            <v-chip small class="mx-1" color="white" @click="filter = 'default'; getAllItems('default')">default</v-chip>
                            <v-chip small class="mx-1" color="#2337C6" @click="filter = `out_of_stock_default`; getAllItems('out_of_stock_default')" >low level stock ({{Lodash.find(warehouse.selection, w => w.value == warehouse.value).text}})</v-chip>
                            <v-chip small class="mx-1" color="yellow" @click="filter = 'low_level_stock'; getAllItems('low_level_stock')">low level stock</v-chip>
                            <v-chip small class="mx-1 text-white" color="red" @click="filter = 'out_of_stock'; getAllItems('out_of_stock')">out of stock</v-chip>
                            <v-chip small class="mx-1 text-white" color="black" @click="filter = null; getItemChainerSuggestions(); itemSuggestions.requestedItems.selected=[]; warehouse.servingWarehouses.selected=[]" >clear</v-chip>
                        </v-col>
                        <v-col lg="2" dense no-gutters class="d-flex">
                            <v-autocomplete
                                v-model="itemSuggestions.requestedItems.selected"
                                :items="itemSuggestions.requestedItems.selection"
                                :loading="!itemSuggestions.requestedItems.loaded"
                                :disable="!itemSuggestions.requestedItems.loaded"
                                placeholder="Select Items"
                                class="d-inline-block"
                                return-object
                                multiple
                                outlined
                                dense
                                hide-details
                                >
                            </v-autocomplete>
                        </v-col>
                        <v-col lg="2" dense no-gutters class="d-flex">
                            <v-autocomplete
                                v-model="warehouse.servingWarehouses.selected"
                                :items="warehouse.servingWarehouses.selection"
                                :loading="!warehouse.servingWarehouses.loaded"
                                :disable="!warehouse.servingWarehouses.loaded"
                                placeholder="Select Warehouse"
                                class="d-inline-block"
                                return-object
                                outlined
                                multiple
                                dense
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="3">
                            <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="getItemChainerSuggestions()">
                                <!-- <v-text-field
                                    dense
                                    autocomplete="off"
                                    v-model="itemSuggestions.datatable.search"
                                    label="Search Item Name/Model"
                                    single-line
                                    hide-details>
                                </v-text-field> -->
                                <v-autocomplete
                                    v-model="itemSuggestions.datatable.filters.type_id.selected"
                                    :items="itemSuggestions.datatable.filters.type_id.selection"
                                    dense
                                    hide-details
                                    outlined
                                    multiple
                                    placeholder="Filter Type"
                                    class="text-md text-gray-700"
                                    style="font-size: 0.8rem !important;">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip x-small v-if="index <= 2">
                                        <span v-text="item.text"></span>
                                    </v-chip>
                                </template>
                                <template v-slot:prepend-item>
                                    <v-row align="center" justify="center" dense no-gutters tag="div" class="d-flex px-3 pb-1">
                                        <div>
                                            <v-icon
                                                @click="toggleFilterSelection(itemSuggestions.datatable.filters.type_id)"
                                                :color="itemSuggestions.datatable.filters.type_id.selected.length > 0 ? 'indigo darken-4' : ''">
                                                {{ filterSelectionIcon(itemSuggestions.datatable.filters.type_id.selection, itemSuggestions.datatable.filters.type_id.selected) }}
                                            </v-icon>
                                        </div>
                                        <div>
                                            <input
                                                @input="searchFilterSelection(itemSuggestions.datatable.filters.type_id)"
                                                v-model="itemSuggestions.datatable.filters.type_id.search"
                                                type="text"
                                                placeholder="Search Type"
                                                style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                                class="form-control" />
                                        </div>
                                    </v-row>
                                    <v-divider class="m-0 p-0"></v-divider>
                                </template>
                                <template v-slot:item="{ active, item, attrs, on }">
                                    <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                        <v-row dense no-gutters align="center">
                                            <input class="mr-1" type="checkbox" :checked="active" />
                                            <div class="caption" v-text="item.text"></div>
                                        </v-row>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                                <v-icon @click="getItemChainerSuggestions(); getItems(warehouse.value);">fas fa-search</v-icon>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- <v-row dense no-gutters tag="div" class="px-2">
                        <v-col lg="3">
                            <v-autocomplete
                                v-model="itemSuggestions.requestedItems.selected"
                                :items="itemSuggestions.requestedItems.selection"
                                :loading="!itemSuggestions.requestedItems.loaded"
                                :disable="!itemSuggestions.requestedItems.loaded"
                                placeholder="Select Items"
                                class="d-inline-block"
                                return-object
                                multiple
                                >
                            </v-autocomplete>
                        </v-col>
                        <v-col lg="3">
                            <v-autocomplete
                                v-model="warehouse.servingWarehouses.selected"
                                :items="warehouse.servingWarehouses.selection"
                                :loading="!warehouse.servingWarehouses.loaded"
                                :disable="!warehouse.servingWarehouses.loaded"
                                placeholder="Select Warehouse"
                                class="d-inline-block"
                                return-object
                                multiple
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row dense no-gutters tag="div" class="px-2">
                        <v-col lg="2">

                        </v-col>
                    </v-row> -->
                    <v-card-text style="border:1px solid #e0e0e0; padding: 0; overflow-y:hidden;position:relative;">
                        <div v-if="loading.state || itemSuggestions.loading" style="background-color: rgba(220,220,220,0.3); position:absolute; width:100%; height:100%; z-index: 90;"></div>
                        <v-data-table
                            v-model="itemSuggestions.selected"
                            :headers="itemSuggestions.datatable.headers"
                            :items="itemSuggestions.data"
                            :options.sync="itemSuggestions.datatable.pagination.options"
                            :items-per-page="itemSuggestions.datatable.pagination.perPageDefault"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageOptions: [5, 10, 25, 100, 500]
                            }"
                            :server-items-length="itemSuggestions.datatable.pagination.total"
                            :single-select="false"
                            item-key="item_id"
                            dense
                            hide-default-header
                            height="50vh"
                            style="border:1px solid #e0e0e0; margin: 0;">
                                <template slot="header" :headers="itemSuggestions.datatable.headers">
                                        <tr>
                                            <th v-for="header in itemSuggestions.datatable.headers"
                                                :key="header.value">
                                                <span v-if="header.allow_butal == 1 && Lodash.find(warehouse.selection, w => w.value == warehouse.value).allow_butal == 1" style="font-size:11px">
                                                    <v-checkbox
                                                        v-model="header.converted_to_uom"
                                                        @click="getItemChainerSuggestions()"
                                                    >
                                                        <template v-slot:label>
                                                            <v-text-field style="width:150px" :value="header.converted_to_uom ? header.text.replace('(MB)','(UOM)') : header.text" disabled></v-text-field>
                                                        </template>
                                                    </v-checkbox>
                                                </span>
                                                <span v-else-if="header.text.includes('REQUEST')">
                                                    <v-text-field style="width:120px;font-weight: bold;" :value="header.text" disabled></v-text-field>
                                                </span>
                                                <span v-else-if="header.text.includes('NAME')">
                                                    <v-text-field style="width:300px;border: none" :value="header.text" disabled></v-text-field>
                                                </span>
                                                <span v-else-if="header.text.includes('TYPE')">
                                                    <v-text-field style="width:50px;border: none" :value="header.text" disabled></v-text-field>
                                                </span>
                                                <span v-else-if="header.text.includes('UOM')">
                                                    <v-text-field style="width:50px;border: none" :value="header.text" disabled></v-text-field>
                                                </span>
                                                <span v-else-if="header.text.includes('MODEL')">
                                                    <v-text-field style="width:90px;border: none" :value="header.text" disabled></v-text-field>
                                                </span>
                                                <span v-else>
                                                    <v-text-field style="width:40px;border: none" :value="header.text" disabled></v-text-field>
                                                </span>
                                            </th>
                                        </tr>
                                </template>

                            <!-- <template v-slot:header="{ props }">
                                <th v-for="header in props.headers">

                                    <span>{{header}}</span>
                                </th>
                            </template> -->

                            <template v-slot:item.item_model="{ item, index }">
                                <v-chip x-small class="mx-1" :color="item.severity" v-text="`${item.item_model}`" :style="`color:${item.text_color};font-size:12px;`"></v-chip>
                            </template>

                            <template
                                v-for="(extension, extensionIndex) in itemSuggestions.extension.header"
                                v-slot:[`item.${extension.id}`]="{ item, index }">
                                <div style="position:relative;" class="d-flex justify-end warehouse-divider h-100">
                                    <span class="d-flex align-center" v-text="item[extension.id] != null  ? formatNumber(item[extension.id]) : ''"></span>
                                    <!-- <span class="d-flex align-center">{{extension}}</span> -->
                                </div>
                            </template>
                            <template
                                v-for="(extension, extensionIndex) in itemSuggestions.extension.header"
                                v-slot:[`item.MBox_${extension.id}`]="{ item, index }">
                                <div style="position:relative;" class="d-flex justify-end h-100">
                                    <span class="d-flex align-center" v-text="item.item_master_box_qty"></span>
                                    <!-- <span class="d-flex align-center">{{extension}}</span> -->
                                </div>
                            </template>
                            <template
                                v-for="(slotExtender, slotExtenderIndex) in itemSuggestions.datatable.slotExtender"
                                v-slot:[`item.${slotExtender.value}`]="{ item, index }">
                                <v-row dense no-gutters align="center" justify="start" tag="div" class="d-flex">
                                    <span>
                                        <v-checkbox
                                            hide-details
                                            dense
                                            v-model="item[slotExtender.checked]"
                                            @change="blurCheckbox(item, slotExtender.checked,
                                                Lodash.find(itemSuggestions.selected, selected => selected.item_id == item.item_id),slotExtender.value,
                                                index);"
                                            style="margin-top:0 !important; padding-top:0 !important;">
                                        </v-checkbox>
                                    </span>

                                    <span>
                                        <input
                                            :key="slotExtenderIndex"
                                            class="form-control float-left chainer-input"
                                            style="text-align: right; outline: none;cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; font-size: 0.8rem !important;line-height: 1rem; width: 83px;"
                                            type="number"
                                            min="0"
                                            :disabled="itemSuggestions.loading || loading.state || !item[slotExtender.checked]"
                                            v-model="item[slotExtender.value]"
                                            @focus="$event.target.select()"
                                            @keypress="pressQuantity($event)"
                                            @blur="blurChainQuantity(item, slotExtender.value,
                                                Lodash.find(itemSuggestions.selected, selected => selected.item_id == item.item_id),slotExtender.itemStock,index);" />
                                    </span>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-end">
                        <v-btn
                            small
                            :disabled="itemSuggestions.loading || loading.state || !warehouse.servingWarehouses.selected.length>0"
                            v-on:click="previewCreationChainer()">
                            <v-icon small color="white">mdi-eye</v-icon><span>&nbsp;Preview Selected</span>
                        </v-btn>
                        <v-btn
                            small
                            :disabled="itemSuggestions.loading || loading.state"
                            v-on:click="creationChainer()">
                            <v-icon small color="white">fas fa-plus</v-icon><span>&nbsp;Create Requests</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ITEM SUGGESTIONS DIALOG -->
            <v-dialog v-model="itemSuggestions.dialog" scrollable persistent style="z-index: 80;">
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="text-lg font-semibold" v-text="`EDIT STOCK REQUEST : ${Lodash.find(warehouse.selection, w => w.value == warehouse.value).code} - ${Lodash.find(warehouse.selection, w => w.value == warehouse.value).text}`"></span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="toggleDialog(`itemSuggestions`)">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-progress-linear
                        v-if="itemSuggestions.loading"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                    <v-row dense no-gutters tag="div" class="px-2">
                        <v-col sm="4">
                        </v-col>
                        <v-col sm="3">
                        </v-col>
                        <v-col sm="2">

                        </v-col>
                        <v-col sm="2">

                        </v-col>
                        <v-col sm="3">
                            <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="getItemSuggestions()">
                                <v-autocomplete
                                    v-model="itemSuggestions.datatable.filters.type_id.selected"
                                    :items="itemSuggestions.datatable.filters.type_id.selection"
                                    dense
                                    hide-details
                                    multiple
                                    placeholder="Filter Type"
                                    class="text-md text-gray-700"
                                    style="font-size: 0.8rem !important;">
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip x-small v-if="index <= 2">
                                            <span v-text="item.text"></span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-row align="center" justify="center" dense no-gutters tag="div" class="d-flex px-3 pb-1">
                                            <div>
                                                <v-icon
                                                    @click="toggleFilterSelection(itemSuggestions.datatable.filters.type_id)"
                                                    :color="itemSuggestions.datatable.filters.type_id.selected.length > 0 ? 'indigo darken-4' : ''">
                                                    {{ filterSelectionIcon(itemSuggestions.datatable.filters.type_id.selection, itemSuggestions.datatable.filters.type_id.selected) }}
                                                </v-icon>
                                            </div>
                                            <div>
                                                <input
                                                    @input="searchFilterSelection(itemSuggestions.datatable.filters.type_sid)"
                                                    v-model="itemSuggestions.datatable.filters.type_id.search"
                                                    type="text"
                                                    placeholder="Search Type"
                                                    style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                                    class="form-control" />
                                            </div>
                                        </v-row>
                                        <v-divider class="m-0 p-0"></v-divider>
                                    </template>
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-row dense no-gutters align="center">
                                                <input class="mr-1" type="checkbox" :checked="active" />
                                                <div class="caption" v-text="item.text"></div>
                                            </v-row>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                                <v-icon @click="getItemSuggestions()">fas fa-search</v-icon>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-card-text style="border:1px solid #e0e0e0; padding: 0;overflow-y:hidden;">
                        <v-data-table
                            v-model="itemSuggestions.selected"
                            :headers="itemSuggestions.datatable.headers"
                            :items="itemSuggestions.data"
                            :options.sync="itemSuggestions.datatable.pagination.options"
                            :items-per-page="itemSuggestions.datatable.pagination.perPageDefault"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageOptions: [5, 10, 25, 100, 500]
                            }"
                            :server-items-length="itemSuggestions.datatable.pagination.total"
                            :single-select="false"
                            item-key="item_id"
                            show-select
                            dense
                            fixed-header
                            height="50vh"
                            style="border:1px solid #e0e0e0; margin: 0;">
                            <template v-slot:item.item_model="{ item, index }">
                                <v-chip x-small :color="item.severity" :text-color="item.text_color" v-text="item.item_model"></v-chip>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-end">
                        <v-btn
                            small
                            v-on:click="addItemSuggestion(itemSuggestions.selected)">
                            <i class="fas fa-plus mr-1"></i>
                            Add Selected Item/s To Stock Request
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- STOCK TRANSFER DIALOG -->
            <v-dialog v-model="receivable.dialog" scrollable persistent style="z-index: 80;">
                <form>
                    <v-card>
                        <v-row class="m-0">
                            <v-col>
                                <v-card-title>
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <span v-if="!loading.state" class="text-lg font-semibold" v-text="`${editedStockTransfer.stock_transfer_form_number}`"></span>
                            </v-card-title>
                            </v-col>

                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="receivable.dialog = false; resetStockTransfer();">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-progress-linear
                            v-if="loading.state"
                            indeterminate
                            color="green"
                        ></v-progress-linear>
                        <v-card-text style="max-height: 760px; border:1px solid #e0e0e0;">
                            <v-row tag="div" align="center" no-gutters>
                                <v-col dense cols="6">
                                    <span v-text="`Source :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.serving_warehouse}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Acknowledged By :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.acknowledged}`"></span>
                                </v-col>
                            </v-row>

                            <v-row
                                tag="div"
                                align="center"
                                no-gutters>
                                <v-col dense cols="6">
                                    <span v-text="`Remarks :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.transfer_remarks || ''}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Confirm Received On Device :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.confirm_received_on_device || ''}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Vehicle :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state && editedStockTransfer.shipper.vehicle" class="font-weight-bold" v-text="`${editedStockTransfer.shipper.vehicle.name}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Plate # :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state && editedStockTransfer.shipper.vehicle" class="font-weight-bold" v-text="`${editedStockTransfer.shipper.vehicle.plate_number}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Driver :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>

                                    <v-chip v-if="!loading.state && editedStockTransfer.shipper.driver" class="m-1" small outlined>
                                        <v-icon x-small left color="gray">fas fa-user</v-icon>
                                        <span class="font-weight-bold" v-text="`${editedStockTransfer.shipper.driver}`"></span>
                                    </v-chip>


                                </v-col>
                                <v-col dense cols="12">
                                    <v-row dense no-gutters tag="div" align="center">
                                        <span v-text="`Helpers :`"></span>&nbsp;
                                        <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                              <div v-if="editedStockTransfer.shipper">
                                            <span
                                                v-if="!loading.state"
                                                v-for="(helper, index) in editedStockTransfer.shipper.helpers"
                                                :key="`${index}-${helper}`"
                                                class="mr-1">
                                            <v-chip class="mr-1" small outlined>
                                                <v-icon x-small left color="gray">fas fa-user</v-icon>
                                                <span v-text="helper" class="mr-1"></span>
                                            </v-chip>
                                        </span>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row tag="div" align="center" no-gutters>
                                <v-col dense cols="6">
                                    <span v-text="`Destination :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state && editedStockTransfer.requesting_warehouse" class="font-weight-bold" v-text="editedStockTransfer.requesting_warehouse"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Requested By :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state && editedStockTransfer.requested_by" class="font-weight-bold" v-text="editedStockTransfer.requested_by"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Stock Request :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="editedStockTransfer.stock_request_form_number"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Date Requested :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="Moment(editedStockTransfer.claimant.created_at).format('lll')"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Remarks :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="editedStockTransfer.remarks"></span>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <div>
                                <span class="font-weight-bold" v-text="`Items To Receive`"></span>&nbsp;
                                <span class="font-weight-light" v-text="`[${editedStockTransfer.details.length}]`"></span>
                            </div>
                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border:1px solid #e0e0e0; max-height: 283px;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">ITEM MODEL</th>
                                            <th class="text-left">ITEM NAME</th>
                                            <th class="text-right"
                                                v-if="editedStockRequest.service != 1 && editedStockTransfer.claimant.allow_butal != 1">
                                                <v-progress-circular v-if="loading.state && editedStockRequest.service != 1 " size="16" indeterminate color="gray"></v-progress-circular>
                                                <span v-if="!loading.state" v-text="`${editedStockTransfer.serving_warehouse_code} (MB)`"></span>
                                            </th>
                                            <th class="text-right">
                                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                                <span v-if="!loading.state" v-text="`${editedStockTransfer.serving_warehouse_code} (UOM)`"></span>
                                            </th>
                                            <!-- <th v-if="editedStockRequest.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right" >REM (MB)</th>
                                            <th class="text-right" >REM (UOM)</th> -->

                                            <th v-if="editedStockRequest.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right" >PREP (MB)</th>
                                            <th class="text-right" >PREP (UOM)</th>

                                            <th v-if="editedStockTransfer.request_type === 1 || editedStockTransfer.claimant.allow_butal != 1" class="text-right">REC QTY (MB)</th>
                                            <th v-else class="text-right">REC QTY (UOM)</th>

                                            <th v-if="editedStockTransfer.request_type === 1 || editedStockTransfer.claimant.allow_butal != 1" class="text-right">VARIANCE (MB)</th>
                                            <th v-else class="text-right">VARIANCE (UOM)</th>

                                            <th class="text-right">TRANSFER REMARKS</th>
                                            <th class="text-right">REMARKS</th>
                                            <th class="text-right">STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(detail, index) in editedStockTransfer.details" :key="detail.id" class="font-weight-medium text-gray">
                                            <td v-text="detail.model"></td>
                                            <td v-text="detail.name"></td>

                                            <td class="text-right" v-if="editedStockRequest.service != 1 && editedStockTransfer.claimant.allow_butal != 1" v-text="`${thousandSeprator(Math.floor(detail.initial_serving_warehouse_stocks/detail.master_box_qty))}`"></td>
                                            <td class="text-right" v-text="`${thousandSeprator(detail.initial_serving_warehouse_stocks)}`"></td>

                                            <!-- <td class="text-right" v-if="editedStockRequest.service != 1 && editedStockTransfer.claimant.allow_butal != 1" v-text="`${thousandSeprator(Math.floor(detail.remaining_uom_quantity/detail.master_box_qty))}`"></td>
                                            <td class="text-right" v-text="`${thousandSeprator(detail.remaining_uom_quantity)}`"></td> -->

                                            <td class="text-right" v-if="editedStockRequest.service != 1 && editedStockTransfer.claimant.allow_butal != 1" v-text="`${thousandSeprator(Math.floor(detail.prepared_uom_quantity/detail.master_box_qty))}`"></td>
                                            <td class="text-right" v-text="`${thousandSeprator(detail.prepared_uom_quantity)}`"></td>

                                            <td class="text-right">
                                                <input
                                                    class="form-control stock-request-items float-right"
                                                    style="text-align: right; outline: none;cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                                    type="number"
                                                    min="0"
                                                    :disabled="editedStockTransfer.latest_status === 3 || detail.status == 'Cancelled'"
                                                    v-model="detail.request_type_quantity"
                                                    @click="$event.target.focus()"
                                                    @focus="$event.target.select()"
                                                    @keypress="pressQuantity($event)"
                                                    @blur="blurQuantity(editedStockTransfer.details, index, 'received_uom_quantity')" />
                                            </td>

                                            <td class="text-right">
                                                <input
                                                    disabled
                                                    class="form-control stock-request-items float-right"
                                                    style="text-align: right; outline: none;cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                                    type="text"
                                                    min="0"
                                                    :value="thousandSeprator((detail.request_type == 0 ? detail.prepared_uom_quantity : detail.prepared_master_box_quantity) - detail.request_type_quantity)"/>
                                            </td>
                                            <!-- <td class="text-right">
                                                <input
                                                    disabled
                                                    class="form-control stock-request-items float-right"
                                                    style="text-align: right; outline: none;cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                                    type="text"
                                                    min="0"
                                                    :value="`${Math.abs(detail.prepared_uom_quantity - detail.received_uom_quantity)}`"/>
                                            </td> -->
                                            <td class="text-right" v-text="detail.transfer_remarks"></td>
                                            <td>
                                                <input
                                                    :disabled="editedStockTransfer.latest_status === 3 || (detail.request_type == 0 ? detail.prepared_uom_quantity : detail.prepared_master_box_quantity) - detail.request_type_quantity == 0 || detail.status == 'Cancelled'"
                                                    @click="$event.target.focus()"
                                                    @focus="$event.target.select()"
                                                    class="form-control stock-request-items w-100"
                                                    style="text-align: left; outline: none; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                                    type="text"
                                                    :placeholder="(detail.request_type == 0 ? detail.prepared_uom_quantity : detail.prepared_master_box_quantity) - detail.request_type_quantity == 0 ? 'No need to enter remarks':'Please enter remarks'"
                                                    v-model="detail.receivable_remarks" />
                                            </td>
                                            <td class="text-right" v-text="detail.status"></td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <!-- <div v-for="error in errors.details" class="red--text" v-bind:key="error" v-text="error"></div> -->
                        </v-card-text>
                        <v-card-actions>
                            <!-- <v-btn
                                :loading="loading.state"
                                small
                                v-on:click="printItemReceivable(editedStockTransfer)">
                                <i class="fas fa-download mr-1"></i>Receivables
                            </v-btn> -->
                            <v-btn
                                v-if="editedStockTransfer.latest_status === 2"
                                :loading="loading.state"
                                class="mx-3"
                                @click="confirmReceived(editedStockTransfer)">Confirm Received
                            </v-btn>
                            <div v-if="editedStockTransfer.latest_status === 2">
                                <!-- <base-qr-reader @scanResult="scanResult" @closeQrScannerDialog="closeQrScannerDialog" :autoOpenCamera="autoOpenCamera" /> -->
                            </div>

                        </v-card-actions>
                    </v-card>
                </form>
            </v-dialog>

            <!-- STOCK REQUEST RECEIVABLES -->
            <v-dialog v-model="receivables.dialog" scrollable persistent max-width="966px" style="z-index: 70;">
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="text-lg font-weight-medium" v-text="`Receivables :`"></span>&nbsp;
                            <span v-if="!loading.state" class="text-lg font-semibold" v-text="editedStockRequest.stock_request_form_number"></span>
                        </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeReceivables()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-progress-linear
                        v-if="loading.state"
                        indeterminate
                        color="green"
                    ></v-progress-linear>
                    <v-card-text class="p-2" style="max-height: 677px; border:1px solid #e0e0e0;">
                        <v-simple-table fixed-header dense class="overflow-y-auto"  style="border:1px solid #e0e0e0;">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">STF NUMBER</th>
                                    <th class="text-left">SERVING WAREHOUSE</th>
                                    <th class="text-left">STATUS</th>
                                    <th class="text-left">STATUS DATE</th>
                                    <th class="text-left">ACTION</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(receivable, index) in editedStockRequest.receivables" :key="receivable.id">
                                    <td>
                                        <span v-text="receivable.stock_transfer_form_number"></span>
                                    </td>
                                    <td>
                                        <span v-text="`${receivable.requesting_warehouse}`"></span>
                                    </td>
                                    <td>
                                        <span
                                            :class="`badge ${Lodash.get(STOCK_TRANSFER_STATUS_CLASS, receivable.latest_status, 'badge-pending')}`"
                                            v-text="Lodash.get(STOCK_REQUEST_RECEIVABLE_STATUS_DESCRIPTION, receivable.latest_status, 'STATUS NOT FOUND')">
                                        </span>
                                    </td>
                                    <td>
                                        <span v-text="receivable.created_at"></span>
                                    </td>
                                    <td>
                                        <v-icon
                                            v-if="[2,3].indexOf(receivable.latest_status) >= 0"
                                            class="btn-action"
                                            color="orange"
                                            small
                                            @click="viewReceivable(receivable,editedStockRequest.serving_warehouse_id)">mdi-eye</v-icon>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <!-- <div v-if="!loading.state && !editedStockRequest.receivables.length">
                            <p>No Receivables Yet</p>
                        </div> -->
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- STOCK REQUEST DIALOG -->
            <v-dialog v-model="dialog" scrollable persistent max-width="1366px" style="z-index: 50; position:relative;">
                <div v-if="itemSuggestions.dialog" style="background-color: rgba(47,47,47,0.5); position:absolute; width:100%; height:100%; z-index: 60;"></div>
                <form>
                    <v-card>
                        <v-row class="m-0">
                            <v-col>
                                <v-card-title>
                                    <span v-if="editedStockRequest.action == 'view'" class="text-lg font-semibold" v-text="editedIndex < 0 ? `Create Stock Request` : `Edit Stock Request : ${editedStockRequest.stock_request_form_number || ''}`"></span>
                                    <span v-if="editedStockRequest.action == 'edit'" class="text-lg font-semibold" v-text="editedStockRequest.stock_request_form_number"></span>
                                </v-card-title>
                            </v-col>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="dialog = false">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-progress-linear
                            v-if="loading.state"
                            indeterminate
                            color="green"
                        ></v-progress-linear>
                        <v-card-text style="max-height: 760px; border:1px solid #e0e0e0; padding:10px;">
                            <v-row tag="div" align="center" no-gutters>
                                <v-col cols='2' class="d-flex pr-2">
                                    <v-row dense no-gutters tag="div">
                                         <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                            <div class="font-weight-bold" v-text="`Expected Date`"></div>
                                        </v-col>
                                        <v-col>
                                            <v-menu v-if="editedStockRequest.status == 0"
                                            v-model="menu3"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            min-width="20px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="expected_date"
                                                    readonly
                                                    append-icon="mdi-calendar-clock"
                                                    v-on="on"
                                                    :disabled="editedStockRequest.action != 'edit'"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="expected_date" @input="menu3 = false"></v-date-picker>
                                        </v-menu>
                                        <input disabled  style="border:1px solid #e0e0e0;" type="text" class="form-control" v-model="editedStockRequest.expected_date" v-if="editedStockRequest.status > 0"/>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="d-flex pr-2" cols="3">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                            <div class="font-weight-bold" v-text="`Requesting Warehouse`"></div>
                                            <v-row no-gutters dense tag="div" align="end" justify="end" class="stock-request-text-xs">
                                                <div v-for="error in errors.warehouse_id" class="red--text" v-bind:key="error" v-text="error"></div>
                                            </v-row>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <v-autocomplete
                                                hide-details
                                                item-text="text"
                                                item-value="value"
                                                :items="warehouse.selection"
                                                v-model="warehouse.value"
                                                dense
                                                outlined
                                                flat
                                                :disabled="editedStockRequest.action != 'edit'"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="d-flex pr-2" cols="3">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                            <div class="font-weight-bold" v-text="`Initial Serving Warehouse`"></div>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <v-autocomplete
                                                hide-details
                                                item-text="text"
                                                item-value="value"
                                                :items="warehousesOtherThanRequesting"
                                                v-model="servingWarehouse.value"
                                                dense
                                                outlined
                                                flat
                                                :disabled="editedStockRequest.action != 'edit'"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="d-flex pr-2" cols="4">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                            <div class="font-weight-bold" v-text="`Remarks (OPTIONAL)`"></div>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <input :disabled="editedStockRequest.action != 'edit' && editedStockRequest.status != 0"   style="border:1px solid #e0e0e0;" type="text" class="form-control" placeholder="Remarks (OPTIONAL)" v-model="editedStockRequest.remarks">
                                        </v-col>
                                    </v-row >
                                </v-col>

                                <v-col class="d-flex pr-2" cols="1">
                                    <v-col dense tag="div" sm="12" class="d-flex justify-space-between">

                                    </v-col>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>

                            <!--<div v-if="editedIndex >= -1 && editedIndex != null">
                                <v-divider></v-divider>
                                <v-row dense no-gutters tag="div">
                                    <v-col sm="8">
                                        <v-row no-gutters dense tag="div" align="baseline" justify="start">
                                        </v-row>
                                    </v-col>
                                    <v-col sm="4">
                                        <v-row dense no-gutters tag="div" class="d-flex">
                                            <v-text-field
                                                dense
                                                height="16px"
                                                class="stock-request-text-xs text-gray"
                                                v-model="itemSuggestions.selection.datatable.search"
                                                placeholder="Search"
                                                single-line
                                                hide-details>
                                            </v-text-field>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-progress-linear
                                    v-if="itemSuggestions.loading"
                                    indeterminate
                                    color="green"
                                ></v-progress-linear>
                                <v-data-table
                                    show-select
                                    checkbox-color="primary"
                                    v-model="itemSuggestions.selection.selected"
                                    :search="itemSuggestions.selection.datatable.search"
                                    :headers="itemSuggestions.selection.datatable.headers"
                                    :items="itemSuggestions.selection.data"
                                    :single-select="false"
                                    item-key="item_id"
                                    dense
                                    style="border:1px solid #e0e0e0; margin: 0;">
                                    <template v-slot:item.item_model="{ item, index }">
                                        <v-chip x-small :color="item.severity" :text-color="item.text_color" v-text="item.item_model"></v-chip>
                                    </template>
                                </v-data-table>
                            </div>

                            <v-divider></v-divider>-->

                            <div class="px-1">
                                <span class="font-weight-bold" v-text="`Stock Requests`"></span>&nbsp;
                                <span class="font-weight-light" v-text="`[${editedStockRequest.details.length}]`"></span>
                                <span v-if="editedStockRequest.action == 'edit'">
                                    <v-checkbox v-model="editedStockRequest.allow_butal" label="Per Uom" true-value="1" false-value="0">

                                    </v-checkbox>
                                </span>
                            </div>
                            <v-simple-table fixed-header dense class="overflow-y-auto mx-1" style="border:1px solid #e0e0e0; max-height: 283px;">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">MODEL</th>
                                            <th class="text-left">NAME</th>
                                            <th class="text-left">TYPE</th>
                                            <th class="text-left">UOM</th>
                                            <th class="text-right">INITIAL SERVING STOCKS</th>
                                            <th class="text-right">M-BOX CONV</th>

                                            <th  class="text-right" v-if="editedStockRequest.action == 'view'">REQ (MB)</th>
                                            <th  class="text-right" v-if="editedStockRequest.action == 'view'">REQ (UOM)</th>
                                            <th v-if="editedStockRequest.action == 'edit'" class="text-right">{{editedStockRequest.allow_butal ? 'REC QTY' : 'REC UOM'}}</th>

                                            <th v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">REM (MB)</th>

                                            <th v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0 " class="text-right">REM (UOM)</th>

                                            <th v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">REC (MB)</th>

                                            <th v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">REC (UOM)</th>

                                         <td v-if="[STOCK_REQUEST_STATUS.COMPLETED
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">CAN(MB)</td>
                                         <td v-if="[STOCK_REQUEST_STATUS.COMPLETED
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">CAN(UOM)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(detail, index) in editedStockRequest.details" :key="detail.id" class="font-weight-medium text-gray">
                                            <td v-text="detail.model"></td>
                                            <td v-text="detail.name"></td>
                                            <td v-text="detail.type"></td>
                                            <td v-text="detail.uom"></td>
                                            <td v-text="thousandSeprator(detail.initial_serving_warehouse_stocks)" class="text-right"></td>
                                            <td class="text-right" v-text="detail.master_box_qty"></td>

                                            <td v-if="editedStockRequest.action == 'edit'">

                                                <input
                                                    class="form-control stock-request-items"
                                                    style="text-align: right; outline: none;cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 5px !important;"
                                                    type="number"
                                                    min="0"
                                                    v-model="detail.request_type_quantity"
                                                    @click="$event.target.focus()"
                                                    @focus="$event.target.select()"
                                                    @keypress="pressQuantity($event)"
                                                    @blur="blurQuantity(editedStockRequest.details, index, 'request_type_quantity')" />
                                            </td>

                                            <td v-if="editedStockRequest.action == 'edit'"><v-icon @click="editedStockRequest.details.splice(index, 1)" style="cursor: pointer;" small color="red">mdi-delete</v-icon></td>

                                            <td v-if="editedStockRequest.action != 'edit'" class="text-right" v-text="`${thousandSeprator(Math.floor(detail.requested_mbox_quantity))}`"></td>
                                            <td v-if="editedStockRequest.action != 'edit'" class="text-right" v-text="thousandSeprator(detail.requested_uom_quantity)"></td>

                                            <td v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right" v-text="`${thousandSeprator(Math.floor(detail.remaining_mbox_quantity))}`"></td>

                                            <td v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right" v-text="thousandSeprator(detail.remaining_uom_quantity)"></td>

                                            <td v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">{{thousandSeprator(Math.floor(detail.received_uom_quantity/detail.master_box_qty))}}</td>

                                            <td v-if="[STOCK_REQUEST_STATUS.COMPLETED,
                                                STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                                                STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">{{thousandSeprator(detail.received_uom_quantity)}}</td>

                                            <td v-if="[STOCK_REQUEST_STATUS.COMPLETED
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">{{thousandSeprator(Math.floor(detail.cancelled_quantity/detail.master_box_qty))}}</td>
                                            <td v-if="[STOCK_REQUEST_STATUS.COMPLETED
                                                ].indexOf(editedStockRequest.status) >= 0" class="text-right">{{thousandSeprator(detail.cancelled_quantity)}}</td>

                                        </tr>
                                    </tbody>
                                    <!-- <tbody>
                                        <tr v-for="(detail, index) in editedStockRequest.details" :key="index">
                                            <td>asdasd</td>
                                        </tr>
                                    </tbody> -->
                                </template>
                            </v-simple-table>
                            <!-- <div v-if="editedStockRequest.details.length > 0" class="font-weight-medium text-gray mx-1" style="border:1px solid #e0e0e0;">No item requested</div>
                            <div v-for="error in errors.details" class="red--text mx-1" v-bind:key="error" v-text="error"></div>
                            <v-row v-if="editedIndex != null" dense no-gutters tag="div" class="mt-2 mx-1">
                                <v-btn small :loading="loading.state" v-on:click="pickItemSuggestions()" class="mr-1"><i class="fas fa-plus mr-1"></i>Add Item/s</v-btn>
                                <v-btn small :loading="loading.state" v-on:click="editedStockRequest.details = []" class="mr-1"><i class="fas fa-trash mr-1"></i>Clear Items</v-btn>
                            </v-row> -->
                        </v-card-text>

                        <v-card-actions class="d-flex justify-center">
                            <v-btn v-if="editedStockRequest.action == 'edit'" :loading="loading.state" small v-on:click="update()"><i class="fas fa-save mr-1"></i>Update</v-btn>
                            <!-- <v-btn v-if=""editedStockRequest.action ==edit" :loading="loading.state" small v-on:click="create()">Submit</v-btn> -->

                            <v-btn
                                v-if="[STOCK_REQUEST_STATUS.FOR_APPROVAL,STOCK_REQUEST_STATUS.PREPARING].indexOf(editedStockRequest.status) >= 0 && (editedStockRequest.action == 'view')"
                                @click="printRequest(editedStockRequest)"
                                :loading="loading.state"
                                small
                            >
                                <v-icon>mdi-arrow-down-bold</v-icon> Print Request
                            </v-btn>

                            <v-btn
                                v-if="[STOCK_REQUEST_STATUS.FOR_APPROVAL].indexOf(editedStockRequest.status) >= 0 && (editedStockRequest.action == 'view') && isManager == 1"
                                @click="approve(editedStockRequest)"
                                :loading="loading.state"
                                small
                                class="d-flex items-center ml-1"><v-icon small color="white">mdi-clipboard-check</v-icon><span>&nbsp;Approve</span>
                            </v-btn>

                        </v-card-actions>
                    </v-card>
                </form>
            </v-dialog>

            <!-- STOCK REQUEST LIST -->
            <v-progress-linear
                v-if="loading.state"
                indeterminate
                color="green"
            ></v-progress-linear>
            <v-app>
                <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12" show-arrows>
                    <v-tab ripple v-for="(warehouse, index) in warehouse.vTabOptions" :key="warehouse.value" @click="getFilteredValues(warehouse,selectedStatus);" >{{warehouse.code}}</v-tab>
                    <v-tabs-items v-model="tab" >
                        <v-row no-gutters dense tag="div">
                            <v-tab-item class="w-100"  touchless v-for="(warehouse, index) in warehouse.vTabOptions" :key="warehouse.value">
                                <v-card class="w-100">
                                    <v-row>
                                        <v-tabs v-model="tab1" id="group-tab" color="gray" light slider-color="cyan" class="col-12 ma-2" show-arrows>
                                            <v-tab ripple v-for="(status, index) in  filters.status.selection" :key="status.value" @click="getFilteredValues(warehouse,status.value); selectedStatus=status.value" >{{status.text}}</v-tab>
                                            <v-tabs-items v-model="tab1">
                                                <v-tab-item class="w-100"  touchless v-for="(status, index) in  filters.status.selection" :key="status.value">
                                                    <v-row dense no-gutters tag="div" class="px-2 pt-2">
                                                        <v-col sm="3">
                                                        </v-col>

                                                        <v-col sm="3">
                                                        </v-col>

                                                        <v-col sm="3">
                                                        </v-col>

                                                        <v-col sm="3">
                                                            <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="list()">
                                                                <v-text-field
                                                                    dense
                                                                    autocomplete="off"
                                                                    v-model="search"
                                                                    label="Search SRF Number/ Remarks"
                                                                    single-line
                                                                    hide-details>
                                                                </v-text-field>
                                                                <v-icon @click="list()">fas fa-search</v-icon>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row no-gutters dense tag="div" class="mx-2 mt-2">
                                                        <v-col>
                                                            <v-data-table
                                                                :headers="stockRequests.headers"
                                                                :items="stockRequests.data"
                                                                :options.sync="pagination.options"
                                                                :items-per-page="10"
                                                                :footer-props="{
                                                                    showFirstLastPage: true,
                                                                    itemsPerPageOptions: [5, 10, 25]
                                                                }"
                                                                :server-items-length="pagination.total">
                                                                <template v-slot:item.remaining_grand_total="{item}">
                                                                    <span v-text="item.remaining_grand_total!=null? formatNumber(item.remaining_grand_total):''"></span>
                                                                </template>
                                                                <template v-slot:item.status="{ item }">
                                                                    <span
                                                                        :class="`badge ${Lodash.get(STOCK_REQUEST_STATUS_CLASS, item.status, 'badge-gray')}`"
                                                                        v-text="Lodash.get(STOCK_REQUEST_STATUS_DESCRIPTION, item.status, 'STATUS NOT FOUND')">
                                                                        </span>
                                                                </template>
                                                                <template v-slot:item.date="{ item }">
                                                                    <span
                                                                        v-text="Moment(item.date).format('lll')">
                                                                    </span>
                                                                </template>
                                                                <template v-slot:item.action="{ item }">
                                                                    <span class="text-nowrap">
                                                                        <v-btn text icon color="orange">
                                                                            <v-icon class="btn-action" small @click="view(item)">mdi-eye</v-icon>
                                                                        </v-btn>

                                                                        <v-btn
                                                                            v-if="[
                                                                                STOCK_REQUEST_STATUS.APPROVED,
                                                                                STOCK_REQUEST_STATUS.PREPARING,
                                                                                STOCK_REQUEST_STATUS.COMPLETED,
                                                                                 STOCK_REQUEST_STATUS.READY_TO_RECEIVE].indexOf(item.status) >= 0"
                                                                            text
                                                                            icon
                                                                            color="green">
                                                                            <v-icon class="btn-action" small @click="showReceivables(item)">mdi-dump-truck fa-flip-horizontal</v-icon>
                                                                        </v-btn>

                                                                        <v-btn v-if="[STOCK_TRANSFER_STATUS.FOR_APPROVAL].indexOf(item.status) >= 0"
                                                                            text icon color="blue">
                                                                            <v-icon class="btn-action" small @click="edit(item)">mdi-pencil</v-icon>
                                                                        </v-btn>

                                                                        <v-btn
                                                                            v-if="[STOCK_REQUEST_STATUS.FOR_APPROVAL, STOCK_REQUEST_STATUS.CANCELLED].indexOf(item.status) >= 0"
                                                                            text icon color="red">
                                                                            <v-icon class="btn-action" small @click="destroy(item)">mdi-delete</v-icon>
                                                                        </v-btn>
                                                                        <v-btn
                                                                            v-if="[STOCK_REQUEST_STATUS.READY_FOR_RECEIVING].indexOf(item.status) >= 0"
                                                                            text icon color="red">
                                                                            <v-icon class="btn-action" small @click="cancelPartial(item)">mdi-cancel</v-icon>
                                                                        </v-btn>
                                                                    </span>
                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-tabs>
                                    </v-row>
                                </v-card>
                            </v-tab-item>
                        </v-row>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
            <div class="row">
                <div class="col-7-lg col-12-md">
                    <v-dialog
                        v-model="inadequateItem.dialog"
                        >

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                SOME ITEMS ARE ALREADY RESERVED IN OTHER WAREHOUSES
                            </v-card-title>

                            <v-card-text>
                                <v-card-subtitle>
                                    <h4>Please remake you request and delete your current request</h4>
                                    <p><b style="color:black;">Note: Selected Item will be removed </b></p>
                                </v-card-subtitle>


                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left"> Item name </th>
                                                <th class="text-left" v-if="editedStockRequest.serve != 1 "> Req Qty (MB) </th>
                                                <th class="text-left"> Req Qty (UOM) </th>

                                                <th class="text-left" v-if="editedStockRequest.serve != 1 "> Qty Can Serve (MB) </th>
                                                <th class="text-left" > Qty Can Serve (UOM) </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="item in inadequateItem.items"
                                                :key="item.item_id"
                                            >
                                            <td>{{ item.item_model }}</td>
                                            <td v-if="editedStockRequest.serve != 1" >{{ thousandSeprator(Math.floor(item.requested_quantity/item.master_box_qty)) }}</td>
                                            <td >{{ thousandSeprator(item.requested_quantity)}}</td>

                                            <td v-if="editedStockRequest.serve != 1" >{{thousandSeprator(Math.floor(item.may_serve/item.master_box_qty)) }}</td>
                                            <td >{{thousandSeprator(item.may_serve)}}</td>

                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                @click="toggleDialog(`requestingWarehouse`);toggleDialog(`stockRequestCreationChainer`);prepareChainer();inadequateItem.dialog = false">
                                Ok
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
        </v-container>
        <batch-addition
            :width="700"
            :dialog="batchAddition.addition.dialog"
            @closeDialog="closeDialog"
            :additional-data="batchAddition.warehouse.selected"
            @addData="addData"
            :columns="[
                {
                    model:'string',
                    required:true
                },
                {
                    quantity:'Number',
                    required:true
                },
            ]"
        >
        </batch-addition>
    </div>
</template>

<script>
import moment from 'moment';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import batchAddition from '@/views/main/layouts/dialogs/Operations/General/BatchAdditionDialogComponent.vue'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import jsPDF from 'jspdf';
import Agent from '@/Agent';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { forEach } from 'jszip';
import StockRequest from '@/api/modules/Operations/StockRequest';
export default {
    name: "InventoryRequestComponent",
    mixins: [SharedFunctionsComponentVue],
    components:{
        batchAddition
    },
    props:[
        'service'
    ],
    async mounted() {
        let that = this;

        await that.getWarehouses();
        await that.getItemTypes();
        await that.getUserWarehouses();
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);

        that.filters.status.selection   = that.filters.status.data;
    },

    data () {
        return {
            validate_model: [],
            autoOpenCamera:0,

            loading : {
                title: 'Please wait',
                state: false
            },

            allow_butal:0,

            warehouse: {
                selection: [{
                    value: null,
                    text: 'DEFAULT (CA)',
                    code: 'CA'
                }],
                value: null,
                vTabOptions:[],
                virtual:false,
                servingWarehouses:{
                        selection:[],
                        selected:[],
                        data:[],
                        loaded:false
                    }
            },
            selectedwarehouse:null,
            selectedStatus:-2,
            servingWarehouse: {
                selection: [{
                    value: null,
                    text: 'NONE',
                    isDefault: 0
                }],
                value: null
            },
            dialog: false,
            tab:0,
            tab1: null,
            expected_date:moment().clone().format('YYYY-MM-DD'),
            view_type:'Warehouse',

            itemSuggestions: {
                loading: false,
                dialog: false,
                datatable : {
                    slotExtender: [],
                    search: '',
                    filters: {
                        type_id : {
                            search: '',
                            data: [],
                            selection: [],
                            selected: []
                        }
                    },
                    headers: [],
                    pagination : {
                        options: {},
                        total : 0,
                        perPageDefault: 25
                    },
                },
                data : [],
                selected : [],
                extension: {
                    header: []
                },
                requestedItems:{
                    selection:[],
                    selected:[],
                    data:[],
                    loaded:false,
                }
            },

            filter:null,

            isManager:null,

            requestingWarehouse:{
                dialog: false
            },

            previewSelectedRequest:{
                dialog: false,
                data: []
            },

            stockRequestCreationChainer:{
                dialog: false
            },

            inadequateItem:{
                dialog:false,
                items:[],
                message:''
            },

            stockRequests: {
                headers: [
                    { text: 'SRF NUMBER', value: 'stock_request_form_number', sortable: false},
                    { text: 'REQUESTED BY', value: 'created_by', sortable: false},
                    { text: 'REQUESTING WAREHOUSE', value: 'requesting_warehouse', sortable: false},
                    { text: 'SERVING WAREHOUSE', value: 'serving_warehouse', sortable: false},
                    { text: 'REMARKS', value: 'remarks', sortable: false},
                    { text: 'DATE', value: 'date', sortable: false},
                    { text: 'EXPECTED', value: 'expected_date', sortable: false},
                    { text: 'REMAINING(UOM)', value: 'remaining_grand_total', align:'right', sortable: false},
                    { text: 'STATUS', value: 'status', sortable: false},
                    { text: 'ACTIONS', value: 'action', sortable: false },
                ],
                data: [],
                status:null,
            },
            receivables: {
                dialog: false
            },
            receivable: {
                dialog: false,
                data: {},
            },
            search: '',
            filters: {
                status : {
                    search: '',
                    data: [
                        {text : 'ON GOING', value: -2},
                        {text : 'ALL STATUSES', value: -1},
                        {text : 'FOR APPROVAL', value: 0},
                        {text : 'PREPARING', value: 2},
                        {text : 'READY TO RECEIVE', value: 6},
                        {text : 'READY FOR RECEIVING', value: 7},
                        {text : 'COMPLETED', value: 3},
                        {text : 'CANCELLED', value: 4},
                        {text : 'PENDING VARIANCE', value: 5},

                    ],
                    selection: [],
                    selected: [-2]
                },
                warehouse : {
                    search: '',
                    data: [],
                    selection: [],
                    selected: [],
                }
            },
            pagination : {
                options: {},
                total: 0,
            },
            menu3:false,

            errors: [],
            editedIndex: -1,
            editedStockRequest: {
                warehouse_id: null,
                initial_serving_warehouse_id: null,
                remarks: null,
                status: 0,
                receivables: [],
                action:'',
                allowable:false,
                per_uom:false
            },

            editedStockTransfer: {
                stock_transfer_form_number: '',
                approved: 0,
                claimant: {
                    warehouse: {},
                    user: {}
                },
                details: [],
                id: null,
                latest_status: 0,
                latest_status_detail: {},
                remarks: null,
                shipper: {
                    vehicle: null,
                    driver: null,
                    helpers: []
                },
                status: [],
                warehouse_id: null,
                warehouse: {},
                user: {},
                confirm_received_on_device: null,
                selectedTransfer:null,
            },

            servingWarehouses:{
                selection:[],
                selected:[],
                data:[]
            },

            url:{
                status:null,
                warehouse_id:null,
                form_number:null,

            },

            dates:[],
            STOCK_REQUEST_STATUS:{
                    FOR_APPROVAL : 0,
                    APPROVED : 1,
                    PREPARING : 2,
                    COMPLETED : 3,
                    CANCELLED: 4,
                    PENDING_VARIANCE: 5,
                    READY_TO_RECEIVE: 6,
                    READY_FOR_RECEIVING: 7
            },
            STOCK_REQUEST_TYPE: {
                PER_UOM: 0,
                MASTER_BOX : 1,
                SMALL_BOX : 2
            },
            STOCK_REQUEST_STATUS_DESCRIPTION: {
                0 : "FOR APPROVAL",
                1 : "APPROVED",
                2 : "PREPARING",
                3 : "COMPLETED",
                4 : "CANCELLED",
                5 : "PENDING VARIANCE",
                6 : "READY TO RECEIVE",
                7 : "READY FOR RECEIVING",
            },
            STOCK_REQUEST_RECEIVABLE_STATUS_DESCRIPTION:{
                0 : "FOR APPROVAL",
                1 : "PREPARING",
                2 : "SHIPPING",
                3 : "CONFIRM RECEIVED",
                4 : "SHIPPING CANCELLED",
            },
            STOCK_REQUEST_STATUS_CLASS: {
                0 : "badge-gray",
                1 : "badge-approved",
                2 : "badge-partial",
                3 : "badge-completed",
                4 : "badge-purple",
                5 : "badge-danger",
                6 : "badge-primary",
                7 : "badge-warning"
            },
            STOCK_TRANSFER_STATUS: {
                FOR_APPROVAL : 0,
                PREPARING : 1,
                SHIPPING : 2,
                CONFIRM_RECEIVED : 3,
                SHIP_CANCELLED: 4
            },
            STOCK_TRANSFER_STATUS_CLASS: {
                0 : "badge-pending",
                1 : "badge-preparing",
                2 : "badge-submitted",
                3 : "badge-completed",
                4 : "badge-converted"
            },
            batchAddition:{
                dialog:false,
                imported:{
                    initial_serving_warehouse:'',
                    warehouse:'',
                    data:[{ warehouse : '' ,data:[
                            { model:'',quantity:'' }
                        ]}],
                    master_box:false,
                    error:0
                },
                addition:{
                    dialog:false
                },
                warehouse:{
                    selectedIndex:{},
                    detailesIndex:{}
                }
            },
            panel: [],
            items: 100,
            isVisible: true,
            from_hakot:{
                value:false,
                data:[]
            },
        }
    },

    computed:{
        ...mapGetters([
            'GET_ALL_WAREHOUSES',
            'GET_ITEM_TYPES',
            'GET_REQUEST_WAREHOUSE',
            'GET_ALL_REQUEST',
            'GET_REQUEST_ITEM_SUGGESTION',
            'GET_WAREHOUSE_ITEMS',
            'GET_REQUEST_DETAILS',
            'GET_STOCK_TRANSFER',
            'GET_TRANSFER_DETAILS',
            'GET_WAREHOUSE_INVENTORY',
        ]),
        selectedWarehousePresenter : function(){
            let that = this;

            let selected = that.warehouse.selection.filter((warehouseSelection) => {
                return warehouseSelection.value === that.warehouse.value;
            });

            return `${selected[0].text} TOTAL`;
        },

        warehousesOtherThanRequesting(){
            let that = this;
            let selection = [];
            switch(that.warehouse.value){
                case null: selection = that.servingWarehouse.selection.filter(selectionItem => selectionItem.isDefault === 0); break;
                default: selection = that.servingWarehouse.selection.filter(selectionItem => selectionItem.value !== that.warehouse.value);
            }

            return selection
        }
    },

    methods: {
        filterServingWarehouse(){
            let that = this

            that.warehouse.servingWarehouses.selection = that.warehouse.servingWarehouses.selection.filter(e=>{
                return e.value != that.warehouse.value
            })

            // that.warehouse.servingWarehouses.selected = that.warehouse.servingWarehouses.selection
        },

        getSeachFromUrl(){
            let that = this

            let status_id = new URL(location.href).searchParams.get('status')
            let warehouse_id =  new URL(location.href).searchParams.get('warehouse_id')
            let srf = new URL(location.href).searchParams.get('form')

            if(status_id != null && warehouse_id != null && srf != null)
            {
                status_id = parseInt(status_id)
                warehouse_id = parseInt(warehouse_id)
                that.search =  srf
            }else {
                return false
            }

            that.tab = that.warehouse.vTabOptions.map(object => object.value).indexOf(warehouse_id)
            that.tab1 = that.filters.status.selection.map(object => object.value).indexOf(status_id)
            that.filters.status.selected = [status_id]
            that.filters.warehouse.selected = [warehouse_id]
            this.$forceUpdate();
            that.list()
        },

        selectedAllFilterModuleOptions(selection, selected) {
            return selected.length === selection.length;
        },

        closeQrScannerDialog(confirm) {
            if(confirm) {
                // this.clearSearch();
            }
        },

        scanResult(result) {
            if(!!result){
                JSON.parse(result)
            }
        },

        selectedSomeFilterModuleOptions(selection, selected) {
            return selected.length > 0 && !this.selectedAllFilterModuleOptions(selection, selected);
        },

        filterSelectionIcon(selection, selected){
            if (this.selectedAllFilterModuleOptions(selection, selected)) return 'mdi-close-box'
            if (this.selectedSomeFilterModuleOptions(selection, selected)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

        toggleFilterSelection(filter) {
            this.$nextTick(() => {
                if (this.selectedAllFilterModuleOptions(filter.selection, filter.selected)) {
                    filter.selected = [];
                } else {
                    filter.selected = filter.selection.map(filter => filter.value);
                }
            })
        },

        searchFilterSelection(filter){
            if (!filter.search){
                filter.selection = filter.data;
            }

            filter.selection = filter.data.filter(data => {
                return data.text.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
            })

        },

        resetForm(type = null){
            let that = this;

            that.warehouse.value = null;

            that.editedStockRequest = {
                ...Object.assign({}, {
                    warehouse_id: null,
                    initial_serving_warehouse_id: null,
                    remarks: null,
                    status: 0,
                    details : [],
                    receivables : []
                })
            };
        },

        closeDialog(){
            let that = this;

            if (that.loading.state) {
                return false;
            }

            that.errors = [];
            that.dialog = false;
            that.editedIndex = -1;
            that.resetForm();
        },

        closeReceivables(){
            let that = this;

            if (that.loading.state) {
                return false;
            }

            that.errors = [];
            that.receivables.dialog = false;
            that.editedIndex = -1;
            that.resetForm();
        },

        toggleLoading(){
            this.loading.state = !this.loading.state;
        },

        toggleDialog(dialogable = null){
            let that = this;

            if (that.loading.state || !dialogable) {
                return false;
            }

            dialogable = dialogable.split('.');

            if(dialogable.length === 1 ){
                that[dialogable].dialog = !that[dialogable].dialog;
            } else {

                let path = that;

                dialogable.forEach(i => {
                    path = path[i];
                });
                path.dialog = !path.dialog;
            }
        },

        async getWarehouses(no_virtual = false){
            let that = this;
            let payload = {
                // no_virtual:no_virtual
            }
            that.warehouse.servingWarehouses.loaded = false
            await this.$store.dispatch('getAllWarehouses',payload).then((response)=>{
                that.warehouse.servingWarehouses.data = response.data

                that.servingWarehouse.selection = [{
                    value: null,
                    text: 'NONE',
                    isDefault: 0
                }].concat(response.data.reduce((selection, warehouse)=>{
                    selection.push({
                        value: warehouse.value,
                        text: warehouse.name,
                        isDefault: warehouse.default,
                        allow_butal:warehouse.allow_butal
                    });

                    return selection;
                },[]));

                let selection = response.data.reduce((selection, warehouse)=>{
                    selection.push({
                        value: warehouse.value,
                        text: warehouse.name,
                        allow_butal:warehouse.allow_butal
                    });

                    return selection;
                }, []);

                that.warehouse.servingWarehouses.selection = selection
                // that.warehouse.servingWarehouses.selected = selection
                that.filters.warehouse.selection    = selection;
                that.filters.warehouse.data         = selection;
                that.warehouse.servingWarehouses.loaded = true
            });
            that.getSeachFromUrl()
        },

        getUserWarehouses(){
            let that = this
            that.$store.dispatch('getRequestWarehouse').then(response=>{
                let selection = that.GET_REQUEST_WAREHOUSE.data.reduce((selection, warehouse)=>{
                        selection.push({
                            value: warehouse.value,
                            text: warehouse.name
                        });

                        return selection;
                    },[]);
                that.warehouse.selection = [{
                value: null,
                    text: 'NONE SELECTED'
                }].concat(this.GET_REQUEST_WAREHOUSE.data.reduce((selection, warehouse)=>{
                    selection.push({
                        value:      warehouse.value,
                        textWCode:  warehouse.code+' - '+warehouse.name,
                        text:       warehouse.name,
                        code:       warehouse.code,
                        isDefault:  warehouse.default,
                        virtual: warehouse.virtual,
                        allow_butal:    warehouse.allow_butal

                    });

                    return selection;
                },[]));

                that.warehouse.vTabOptions = this.warehouse.virtual ? this.GET_REQUEST_WAREHOUSE.data.filter(f=>{return f.virtual == 0}) : this.GET_REQUEST_WAREHOUSE.data;

                // that.selectedStatus = that.filters.status.selection.map(object => object.value).indexOf(selection[0].value)
                that.filters.warehouse.selected = [selection[0].value]
                // that.selectedStatus = selection[0].value
                that.tab1 = 0
                that.list()
            })
        },

        getFilteredValues(filter = 0 ,status = -2){
            let that = this;

            if(that.view_type == 'Warehouse'){
                that.filters.warehouse.selected  = [filter.value]
                that.date = null
            }else{
                that.date =  filter.date
                that.filters.warehouse.selected = null
            }

            that.filters.status.selected  = [status]
            this.list()
        },

        getItemTypes(){
            let that = this;

            this.$store.dispatch('getItemTypes').then((response)=>{
                let selection = this.GET_ITEM_TYPES.reduce((selection, filterItem)=>{
                    selection.push({
                        value: filterItem.value,
                        text: filterItem.text
                    });

                    return selection;
                },[]);

                that.itemSuggestions.datatable.filters.type_id.data = selection;
                that.itemSuggestions.datatable.filters.type_id.selection = selection;
                that.itemSuggestions.datatable.filters.type_id.selected = selection.filter(selection => ['HT','PT'].indexOf(selection.text) >= 0).map(selection => selection.value);
            });
        },

        async list(page = 1){
            let that = this;
            that.stockRequests.data = []
            if(that.filters.status.selected[0] == -1){
                that.filters.status.selected = [
                    that.STOCK_REQUEST_STATUS.FOR_APPROVAL,
                    that.STOCK_REQUEST_STATUS.APPROVED,
                    that.STOCK_REQUEST_STATUS.PREPARING,
                    that.STOCK_REQUEST_STATUS.COMPLETED,
                    that.STOCK_REQUEST_STATUS.CANCELLED,
                    that.STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                    that.STOCK_REQUEST_STATUS.READY_TO_RECEIVE,
                    that.STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                ]
            }

            if(that.filters.status.selected[0] == -2){
                that.filters.status.selected = [
                    that.STOCK_REQUEST_STATUS.FOR_APPROVAL,
                    that.STOCK_REQUEST_STATUS.APPROVED,
                    that.STOCK_REQUEST_STATUS.PREPARING,
                    that.STOCK_REQUEST_STATUS.CANCELLED,
                    that.STOCK_REQUEST_STATUS.PENDING_VARIANCE,
                    that.STOCK_REQUEST_STATUS.READY_TO_RECEIVE,
                    that.STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
                ]
            }
            that.pagination.options.page = page;

            if (that.loading.state) {
                return false;
            }

           let payload = {
                warehouse: that.filters.warehouse.selected,
                status: that.filters.status.selected,
                itemsPerPage:   that.pagination.options.itemsPerPage,
                srfNumber:      that.search,
                date:           that.date
           }
            this.$store.dispatch('getAllStockRequestId',payload).then(response =>{
                that.toggleLoading();
                that.pagination.total = response.data.stockRequestId.length
                let ids = this.arraySlice(response.data.stockRequestId,that.pagination.options.itemsPerPage)
                let stockRequestIds = ids[page -1]
                let payload = {
                    stockRequestIds: stockRequestIds,
                }
                if(payload.stockRequestIds == undefined){
                    that.toggleLoading();
                }
                else{
                    that.$store.dispatch('getAllRequest',payload).then((response) => {
                    that.isManager              = that.GET_ALL_REQUEST.isManager;
                    that.stockRequests.data     = response.data.stockRequestId;
                    that.toggleLoading();
                    });
                }

            })
        },

        warehouseHeaderPresenter(value = null){
            let that = this;

            let warehouses = that.warehouse.selection.filter((warehouseSelection) => {
                return warehouseSelection.value === value;
            });

            return `${warehouses[0].code}`;
        },

        pickItemSuggestions() {
            let that = this;

            that.resetItemSuggestions();
            that.toggleDialog(`itemSuggestions`);
            that.getItemSuggestions();
        },

        resetItemSuggestions(){
            let that = this;
            that.itemSuggestions.data = [];
            that.itemSuggestions.selected = [];
        },

        getItemSuggestions(page = 1){
            let that = this;

            that.itemSuggestions.datatable.pagination.options.page = page;

            if (that.itemSuggestions.loading) {
                return false;
            }

            that.itemSuggestions.loading = true;
            that.itemSuggestions.extension.header = [];

            that.itemSuggestions.datatable.headers = [];
            that.itemSuggestions.data = [];
            // that.itemSuggestions.selected = [];
            let payload = {
                selectedWarehouse:      that.warehouse.value,
                selectedItems:      that.itemSuggestions.requestedItems.selected.length == 0 ? [-1] : that.itemSuggestions.requestedItems.selected.map(e=> {return e.value}),
                page:                   page,
                itemsPerPage:           that.itemSuggestions.datatable.pagination.options.itemsPerPage
                                    || that.itemSuggestions.datatable.pagination.perPageDefault,
                search:                 that.itemSuggestions.datatable.search,
                type_id:                that.itemSuggestions.datatable.filters.type_id.selected,
            }


            that.$store.dispatch('getRequestItemSuggestion',payload).then((response) => {

                that.itemSuggestions.extension.header = that.GET_REQUEST_ITEM_SUGGESTION.extender;

                that.itemSuggestions.datatable.headers.push({ text: 'MODEL', value: 'item_model', align: 'start', sortable: false});
                that.itemSuggestions.datatable.headers.push({ text: 'NAME', value: 'item_name', align: 'start', sortable: false});
                that.itemSuggestions.datatable.headers.push({ text: 'TYPE', value: 'item_type', align: 'start', sortable: false});
                that.itemSuggestions.datatable.headers.push({ text: 'UOM', value: 'item_uom', align: 'start', sortable: false});
                that.itemSuggestions.datatable.headers.push({ text: that.warehouseHeaderPresenter(that.warehouse.value), value: 'SELECTED_WAREHOUSE_TOTAL', align: 'right', sortable: false});
                //that.itemSuggestions.datatable.headers.push({ text: 'OTHER WAREHOUSE TOTAL', value: 'OTHER_WAREHOUSE_TOTAL', align: 'right', width: "250px"});
                that.itemSuggestions.extension.header.forEach((extender)=>{
                    that.itemSuggestions.datatable.headers.push({
                        text: extender.code,
                        value: parseInt(extender.id).toString(),
                        align: 'right',
                        sortable: false
                    });
                });

                //that.itemSuggestions.datatable.headers.push({ text: 'GRAND TOTAL', value: 'GRAND_TOTAL', align: 'right', width: "150px"});
                that.itemSuggestions.data                       = that.GET_REQUEST_ITEM_SUGGESTION.item_suggestions.data;
                that.itemSuggestions.datatable.pagination.total = that.GET_REQUEST_ITEM_SUGGESTION.item_suggestions.total;

                that.itemSuggestions.loading = false;
            });
        },

        addItemSuggestion(selection = []){
            let that = this;

            selection.forEach((item) => {

                let exists = that.editedStockRequest.details.filter(detail => parseInt(detail.item_id) === parseInt(item.item_id));

                if (!exists.length) {
                    that.editedStockRequest.details.push(Object.assign({},{
                        item_id : item.item_id,
                        model: item.item_model,
                        name: item.item_name,
                        type: item.item_type,
                        uom: item.item_uom,
                        small_box_qty: item.item_small_box_qty,
                        master_box_qty: item.item_master_box_qty,
                        request_type: this.STOCK_REQUEST_TYPE.PER_UOM,
                        request_type_quantity: 0
                    }));
                }
            });

            that.itemSuggestions.selected = [];
            that.toggleDialog(`itemSuggestions`);
        },
        getItemChainerSuggestions(page = 1){
            let that = this;
            let converted_to_uom = []

            that.itemSuggestions.datatable.pagination.options.page = page;

            if (that.itemSuggestions.loading) {
                return false;
            }

            if (that.itemSuggestions.datatable.headers.length > 0) {
                converted_to_uom = that.itemSuggestions.datatable.headers.map(e => {
                    if (e.converted_to_uom) {
                        return parseInt(e.value.replace("request-for-", ''));
                    }
                })
            }
            converted_to_uom = _.compact(converted_to_uom)

            that.itemSuggestions.loading = true;
            that.itemSuggestions.extension.header = [];

            that.itemSuggestions.datatable.headers = [];
            that.itemSuggestions.datatable.slotExtender = [];

            let payload = {
                selectedWarehouse: that.warehouse.value,
                    selectedItems:      that.itemSuggestions.requestedItems.selected.length == 0 ? [-1] : that.itemSuggestions.requestedItems.selected.map(e=> {return e.value}),
                    page:               page,
                    itemsPerPage:       that.itemSuggestions.datatable.pagination.options.itemsPerPage
                        || that.itemSuggestions.datatable.pagination.perPageDefault,
                    search:             that.itemSuggestions.datatable.search,
                    type_id:            that.itemSuggestions.datatable.filters.type_id.selected,
                    filter:             that.filter,
            }

            that.$store.dispatch('getRequestItemSuggestion',payload).then((response) => {
                that.itemSuggestions.extension.header = that.GET_REQUEST_ITEM_SUGGESTION.extender;

                that.itemSuggestions.datatable.headers.push({ text: 'MODEL', value: 'item_model', align: 'left', sortable: false, width: "60px"});
                that.itemSuggestions.datatable.headers.push({ text: 'NAME', value: 'item_name', align: 'left', sortable: false, width: "300px"});
                that.itemSuggestions.datatable.headers.push({ text: 'TYPE', value: 'item_type', align: 'left', sortable: false, width: "50px"});
                that.itemSuggestions.datatable.headers.push({ text: 'UOM', value: 'item_uom', align: 'left', sortable: false, width: "50px"});
                that.itemSuggestions.datatable.headers.push({ text: that.warehouseHeaderPresenter(that.warehouse.value), value: 'SELECTED_WAREHOUSE_TOTAL', align: 'right', sortable: false, width: "80px"});
                //that.itemSuggestions.datatable.headers.push({ text: 'OTHER WAREHOUSE TOTAL', value: 'OTHER_WAREHOUSE_TOTAL', align: 'right', width: "250px"});

                that.GET_REQUEST_ITEM_SUGGESTION.item_suggestions.data.forEach(element => {
                    converted_to_uom.forEach(e => {
                        if (!!element[e]) {
                            element[e] *= element.item_master_box_qty
                        }
                    })
                })

                that.itemSuggestions.data = that.GET_REQUEST_ITEM_SUGGESTION.item_suggestions.data;
                that.itemSuggestions.datatable.pagination.total = that.GET_REQUEST_ITEM_SUGGESTION.item_suggestions.total;

                let selectedWarehouseId = that.warehouse.servingWarehouses.selected.map(e=>{
                    return e.value
                })

                let filteredWarehouseId = []
                if(selectedWarehouseId.length == 0){
                    selectedWarehouseId = that.warehouse.servingWarehouses.selection.map(e=>{
                        return e.value
                    })
                }

                that.warehouse.servingWarehouses.data.forEach(e=>{
                    if(selectedWarehouseId.includes(e.value)){
                        filteredWarehouseId.push(e)
                    }
                })

                that.itemSuggestions.loading = false;
                filteredWarehouseId.forEach((extender, index) => {
                            that.itemSuggestions.datatable.headers.push({
                                text: extender.code,
                                value: parseInt(extender.value).toString(),
                                align: 'right',
                                sortable: false,
                                class: 'warehouse-init',
                                // allow_butal: extender.allow_butal,
                                // converted_to_uom: false,
                            });

                            that.itemSuggestions.datatable.headers.push(
                            // {
                            //     text        : `UOM/MB`,
                            //     value       : `MBox_${extender.id}`,
                            //     align       : 'center',
                            //     sortable    : false,
                            //     width       : "90px"
                            // },
                            {
                                text        : `REQUEST (MB) `,
                                value       : `request-for-${extender.value}`,
                                align       : 'left',
                                sortable    : false,
                                width       : "200px",
                                allow_butal: extender.allow_butal,
                                converted_to_uom: converted_to_uom.includes(extender.value),

                                });

                            that.itemSuggestions.datatable.slotExtender.push({
                                value:      `request-for-${extender.value}`,
                                checked:    `checked-for-${extender.value}`,
                                align       : 'left',
                                itemStock: extender.value,
                            });
                    });

                //that.itemSuggestions.datatable.headers.push({ text: 'GRAND TOTAL', value: 'GRAND_TOTAL', align: 'right', width: "150px"});

                that.itemSuggestions.selected.forEach(selected => {
                    let filtered = _.find(that.GET_REQUEST_ITEM_SUGGESTION.item_suggestions.data, data => data.item_id == selected.item_id);

                    if(filtered){
                        that.itemSuggestions.extension.header.forEach((extender, index)=>{
                            filtered[`request-for-${extender.id}`] = selected[`request-for-${extender.id}`];
                            filtered[`checked-for-${extender.id}`] = selected[`checked-for-${extender.id}`];
                        });
                    }
                });
            });
        },

        getAllItems(val){
            this.filter = val
            this.getItemChainerSuggestions(this.itemSuggestions.datatable.pagination.options.page)
        },

        prepareChainer(){
            let that = this;
            // that.warehouse.servingWarehouses.selection = that.warehouse.servingWarehouses.selection.filter(e=>{
            //     return e.value != that.warehouse.value
            // })
            that.resetItemSuggestions();
            that.getItemChainerSuggestions();
        },

        previewCreationChainer(){
            let that = this

            that.previewSelectedRequest.data = [];

            that.toggleDialog(`previewSelectedRequest`);

            let selected = _.uniqBy(that.itemSuggestions.selected, 'item_id');

            let headerExtension = that.itemSuggestions.extension.header.reduce((extensions, extension)=>{
                extensions.push(Object.assign({},{
                    warehouse_id : extension.id,
                    key: `request-for-${extension.id}`,
                    code: extension.code,
                    checked:`checked-for-${extension.id}`
                }));
                return extensions;
            }, []);

            let stockRequests = [];

            let converted_to_uom  = that.itemSuggestions.datatable.headers.filter(e=>{
                if(e.converted_to_uom){
                    return e
                }
            })

            converted_to_uom = converted_to_uom.map(e => { return e.value })

            selected.forEach(item => {
                headerExtension.forEach(extension => {

                    if (item[extension.checked]) {

                        let serving = _.find(stockRequests, stockRequest => stockRequest.initial_serving_warehouse_id == extension.warehouse_id);

                        if (serving) {
                            if(serving.details.some(e=>e.item_id != item.item_id)){
                                serving.details.push(Object.assign({},{
                                    id: _.random(0,1000),
                                    item_id:                    item.item_id,
                                    model:                      item.item_model,
                                    name:                       item.item_name,
                                    requested_mbox_quantity:     item[extension.key]
                                }));
                            }
                        } else {
                                stockRequests.push(Object.assign({},{
                                name: extension.code,
                                initial_serving_warehouse_id: extension.warehouse_id,
                                allow_butal: converted_to_uom.includes(extension.key),
                                details: [Object.assign({},{
                                    id: _.random(0,1000),
                                    item_id: item.item_id,
                                    model: item.item_model,
                                    name:item.item_name,
                                    requested_mbox_quantity: item[extension.key]
                                })],
                            }));
                        }
                    }
                });
            });

            that.previewSelectedRequest.data = stockRequests;
        },

        async creationChainer(){

            let that = this;
            let item_id = 'item_id'
            let converted_to_uom = [];

            let arrayOfItems  = [...new Map(that.itemSuggestions.selected.map(item =>
                [item[item_id], item])).values()];

            that.toggleLoading();

            let warehouse_id = that.warehouse.value;
            let selected = arrayOfItems;

            selected = selected.filter(warehouses =>{
                let total = 0
                for(let request in warehouses){
                    if(request.includes('request-for-')){
                        total += warehouses[request]
                    }
                }
                return total > 0
            })

            let headerExtension = that.itemSuggestions.extension.header.reduce((extensions, extension)=>{
                extensions.push(Object.assign({},{
                    warehouse_id : extension.id,
                    key: `request-for-${extension.id}`,
                    checked: `checked-for-${extension.id}`,

                }));
                return extensions;
            }, []);

            converted_to_uom  = that.itemSuggestions.datatable.headers.filter(e=>{
                if(e.converted_to_uom){
                    return e
                }
            })

            converted_to_uom = converted_to_uom.map(e => { return e.value })

            let stockRequests = [];
            selected.forEach(item => {
                headerExtension.forEach(extension => {
                    if (item[extension.checked]) {

                        let serving = _.find(stockRequests, stockRequest => stockRequest.initial_serving_warehouse_id == extension.warehouse_id);

                        if (serving) {
                            serving.details.push(Object.assign({},{
                                item_id: item.item_id,
                                request_type_quantity:  item[extension.key],
                                requested_uom_quantity: converted_to_uom.includes(extension.key) ? item[extension.key] : item[extension.key] * item.item_master_box_qty
                            }));
                        } else {
                            stockRequests.push(Object.assign({},{
                                warehouse_id:                   warehouse_id,
                                initial_serving_warehouse_id: extension.warehouse_id,
                                allow_butal: converted_to_uom.includes(extension.key) ? 1 : 0,
                                details:                        [Object.assign({},{
                                    item_id:                 item.item_id,
                                    request_type_quantity:   item[extension.key],
                                    requested_uom_quantity:  converted_to_uom.includes(extension.key) ? item[extension.key] : item[extension.key] * item.item_master_box_qty
                                })]
                            }));
                        }
                    }
                });
            });

            let services = [];
            stockRequests.forEach(stockRequest => {
                services.push(new Promise((resolve, reject) => {
                    this.$store.dispatch('requestCreate',stockRequest).then(response => {
                        resolve(response);
                    }).catch(error => {
                        reject(error);
                    });
                }));
            });

            if (services.length) {
                await Promise.allSettled(services).then(values => {

                    let failed = values.filter(result => result.status === 'rejected').map(result => result.reason);

                    if(failed.length){
                        //Get First Failed
                        let error = failed[0];

                        that.alertWarnings(error.response.data.message, error.response.data.errors);
                        that.toggleLoading();
                    } else {

                        let text = values.reduce((numbers, response)=>{
                            numbers.push(response.value.data.stock_request_form_number);
                            return numbers;
                        }, [])

                        swal.fire({
                            title: `Stock Request${services.length > 1 ? `s` : ``} Created`,
                            text: text.join("\n"),
                            icon: "info",
                            dangerMode: false
                        });
                        that.warehouse.servingWarehouses.selected = []
                        that.itemSuggestions.requestedItems.selected = []
                        that.toggleLoading();
                        that.toggleDialog(`stockRequestCreationChainer`);
                        that.list();
                    }
                });

            } else {
                that.toggleLoading();
            }
        },

        create(){
            let that = this;

            if (that.loading.state) {
                return false;
            }

            that.toggleLoading();

            setTimeout(() => {

                if (!that.requestHasZeroTypeQuantity(that.editedStockRequest.details)) {
                    let stockRequest = Object.assign({},{
                        warehouse_id: that.warehouse.value,
                        initial_serving_warehouse_id: that.servingWarehouse.value,
                        remarks: that.editedStockRequest.remarks,
                        details: that.editedStockRequest.details.reduce((stockRequestDetails, stockRequestDetail) => {


                            stockRequestDetails.push(Object.assign({},{
                                ...stockRequestDetail,
                                requested_uom_quantity : that.stockRequestTotalUomComputer(stockRequestDetail, 1, stockRequestDetail.request_type_quantity)
                            }));

                            return stockRequestDetails;
                        },[])
                    });
                    this.$store.dispatch('requestCreate',stockRequest).then((response) => {
                        swal.fire({
                            title: `Stock Requested : ${response.data.stock_request_form_number}`,
                            text: ""
                        });

                        that.dialog = false;
                        that.resetForm();
                        that.errors = [];
                        that.toggleLoading();
                        that.list();
                    }).catch((error)=>{
                        that.errors = error.response.data.errors;

                        let errorMessages = [];

                        Object.keys(that.errors).forEach((key)=>{

                            if (key === 'authorization') {
                                that.closeDialog();
                            }

                            that.errors[key].forEach(message => {
                                errorMessages.push(`- ${message}`);
                            })
                        })

                        swal.fire({
                            title: error.response.data.message,
                            text: errorMessages.join("\n"),
                            icon: "warning",
                            dangerMode: true,
                        });
                        that.toggleLoading();
                    });
                } else {
                    swal.fire({
                        title: "Unprocessable",
                        text: `Request Type Quantity Should Have An Amount`,
                        icon: "warning",
                        dangerMode: true,
                    });

                    that.toggleLoading();
                }
            }, 400)
        },

        async getItems(selectedWarehouse){
            let that = this
            that.itemSuggestions.requestedItems.loaded = false
            let payload = {
                warehouses: [selectedWarehouse],
                type_id   : that.itemSuggestions.datatable.filters.type_id.selected
            }

            await that.$store.dispatch('getWarehouseItems',payload).then(response=>{
                that.GET_WAREHOUSE_ITEMS.forEach(e=>{
                    that.itemSuggestions.requestedItems.selection.push({text:e.item_model,value:e.item_id})
                })
                that.itemSuggestions.requestedItems.data = that.GET_WAREHOUSE_ITEMS
                that.itemSuggestions.requestedItems.loaded = true
            })
        },

        approve(stockRequest){
            swal.fire({
                title: `Confirm Stock Request Approve : ${stockRequest.stock_request_form_number}`,
                text: ``,
                icon: "warning",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed){
                    let that = this;
                    stockRequest.expected_date = that.expected_date

                    if (that.loading.state) {
                        return false;
                    }

                    that.toggleLoading();

                    if (!that.requestHasZeroTypeQuantity(that.editedStockRequest.details)) {
                        let payload = {
                            id: stockRequest.id,
                            params:{
                                remarks: stockRequest.remarks,
                                expected_date: that.expected_date
                            }
                        }
                        that.$store.dispatch('requestApprove',payload).then((response) => {
                            let message = response.data.stock_transfer
                                ? `Stock Transfer ${response.data.stock_transfer.stock_transfer_form_number} created.`
                                : ``;

                            if(response.data.isInadequate){
                                that.inadequateItem.dialog          = response.data.isInadequate
                                that.inadequateItem.items           = response.data.items_to_serve
                            }else{
                                swal.fire({
                                    title: `Stock Request Approved : ${stockRequest.stock_request_form_number}`,
                                    text: message
                                });
                            }
                            that.toggleLoading();
                            that.closeDialog();
                            that.list(that.pagination.options.page);
                            this.warehouse.value = response.data.warehouse_id
                        }).catch((error)=>{
                            that.toggleLoading();
                            that.errors = error.response.data.errors;

                            let errorMessages = [];

                            Object.keys(that.errors).forEach((key)=>{
                                that.errors[key].forEach(message => {
                                    errorMessages.push(`- ${message}`);
                                })
                            })

                            swal.fire({
                                title: error.response.data.message,
                                text: errorMessages.join("\n"),
                                icon: "warning",
                                dangerMode: true,
                            });
                        });

                    } else {
                        swal.fire({
                            title: "Unprocessable",
                            text: `Request Type Quantity must be greater than 0`,
                            icon: "warning",
                            dangerMode: true,
                        });

                        that.toggleLoading();
                    }
                }
            });
        },

        cancel(stockRequest){
            swal.fire({
                title: `Confirm Cancel : ${stockRequest.stock_request_form_number}`,
                text: stockRequest.status === 0 ? `` : `Cancelling stock request will abort stock transfer visibility.`,
                icon: "warning",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed){
                    let that = this;

                    if (that.loading.state) {
                        return false;
                    }

                    that.$store.dispatch('requestCancel',stockRequest.id).then((response) => {
                        stockRequest.status = 4;
                        swal.fire({
                            title: "Stock Request Cancelled."
                        });
                    }).catch((error)=>{
                        that.errors = error.response.data.errors;

                        let errorMessages = [];

                        Object.keys(that.errors).forEach((key)=>{
                            that.errors[key].forEach(message => {
                                errorMessages.push(`- ${message}`);
                            })
                        })

                        swal.fire({
                            title: error.response.data.message,
                            text: errorMessages.join("\n"),
                            icon: "warning",
                            dangerMode: true,
                        });
                    });
                }
            });
        },

        async edit(stockRequest){
            let that = this;
            let temp = []

            that.dialog = true;
            that.editedIndex = that.stockRequests.data.indexOf(stockRequest);
            that.warehouse.value = stockRequest.requesting_warehouse_id
            that.servingWarehouse.value = stockRequest.initial_serving_warehouse_id

            that.editedStockRequest.allowable = that.warehouse.selection.find(e=>e.value == stockRequest.initial_serving_warehouse_id).allow_butal == 1 &&
                that.warehouse.selection.find(e=>e.value == stockRequest.requesting_warehouse_id).allow_butal == 1 ? true : false

            that.toggleLoading();
                let payload = {
                    id: stockRequest.id,
                    params:{
                        serving_warehouse_id: stockRequest.serving_warehouse_id,
                        requesting_warehouse_id: stockRequest.requesting_warehouse_id
                    }
                }
                await this.$store.dispatch('requestDetails',payload).then(response =>{
                that.editedStockRequest = stockRequest
                temp = response.data.data.map(e=>{
                    e.requested_mbox_quantity = e.requested_uom_quantity / e.master_box_qty
                    e.remaining_mbox_quantity = e.remaining_uom_quantity / e.master_box_qty
                    e.received_mbox_quantity = e.received_uom_quantity / e.master_box_qty
                    e.cancelled_mbox_quantity = e.cancelled_quantity / e.master_box_qty
                    return e;
                })

                let edited = Object.assign({},{
                    ...that.editedStockRequest,
                    id:                             stockRequest.id,
                    warehouse_id :                  stockRequest.warehouse_id,
                    initial_serving_warehouse_id:   stockRequest.initial_serving_warehouse_id,
                    remarks :                       stockRequest.remarks,
                    serving_warehouse:              response.data.serving_warehouse,
                    details :                       temp,
                    serving_warehouse:              response.data.serving_warehouse,
                    requesting_warehouse_id:        stockRequest.requesting_warehouse_id,
                    requesting_warehouse:           response.data.requesting_warehouse,
                    stock_request_form_number:      stockRequest.stock_request_form_number,
                    action: 'edit',
                    per_uom:false,
                    expected_date:stockRequest.expected_date,
                    allow_butal: stockRequest.allow_butal.toString()
                });

                that.editedStockRequest = edited;
                this.expected_date = edited.expected_date
                that.toggleLoading();
            });
        },

        async view(stockRequest){
            let that = this;

            that.dialog = true;

            that.toggleLoading();
            let temp = []
            let serving_warehouse_id = null

            let payload = {
                id: stockRequest.id,
                params:{
                    serving_warehouse_id: stockRequest.serving_warehouse_id,
                    requesting_warehouse_id: stockRequest.requesting_warehouse_id
                }
            }

            that.warehouse.value = stockRequest.requesting_warehouse_id
            that.servingWarehouse.value = stockRequest.initial_serving_warehouse_id

            await this.$store.dispatch('requestDetails',payload).then(response =>{
                that.editedStockRequest = stockRequest
                temp = response.data.data.map(e=>{
                    e.requested_mbox_quantity = e.requested_uom_quantity / e.master_box_qty
                    e.remaining_mbox_quantity = e.remaining_uom_quantity / e.master_box_qty
                    e.received_mbox_quantity = e.received_uom_quantity / e.master_box_qty
                    e.cancelled_mbox_quantity = e.cancelled_quantity / e.master_box_qty
                    return e;
                })

                let edited = Object.assign({},{
                    ...that.editedStockRequest,
                    id:                             stockRequest.id,
                    warehouse_id :                  stockRequest.warehouse_id,
                    initial_serving_warehouse_id:   stockRequest.initial_serving_warehouse_id,
                    remarks :                       stockRequest.remarks,
                    serving_warehouse:              response.data.serving_warehouse,
                    details :                       temp,
                    serving_warehouse:              response.data.serving_warehouse,
                    requesting_warehouse_id:        stockRequest.requesting_warehouse_id,
                    requesting_warehouse:           response.data.requesting_warehouse,
                    stock_request_form_number:      stockRequest.stock_request_form_number,
                    action: 'view',
                    expected_date:stockRequest.expected_date,
                    per_uom:false,
                });
                that.editedStockRequest = edited;
                this.expected_date = edited.expected_date
                // that.editedIndex = null;

                that.toggleLoading();
            })

        },

        destroy(stockRequest){
            swal.fire({
                title: "Confirm Delete",
                text: `${stockRequest.stock_request_form_number}`,
                icon: "warning",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result) => {
                if(result.isConfirmed){
                    let that = this;

                    if (that.loading.state) {
                        return false;
                    }

                    this.$store.dispatch('requestDestroy',stockRequest.id).then((response) => {

                        that.list();

                        swal.fire({
                            title: "Stock Request Deleted."
                        });
                    }).catch((error)=>{
                        that.errors = error.response.data.errors;

                        let errorMessages = [];

                        Object.keys(that.errors).forEach((key)=>{
                            that.errors[key].forEach(message => {
                                errorMessages.push(`- ${message}`);
                            })
                        })

                        swal.fire({
                            title: error.response.data.message,
                            text: errorMessages.join("\n"),
                            icon: "warning",
                            dangerMode: true,
                        });
                    });
                }
            });
        },

        update(){
            let that = this;

            if (that.loading.state) {
                return false;
            }

            that.toggleLoading();

            if (!that.requestHasZeroTypeQuantity(that.editedStockRequest.details)) {
                let stockRequest = Object.assign({},{
                    warehouse_id: that.warehouse.value,
                    initial_serving_warehouse_id: that.servingWarehouse.value,
                    remarks: that.editedStockRequest.remarks,
                    expected_date:that.expected_date,
                    allow_butal:that.editedStockRequest.allow_butal,
                    details: that.editedStockRequest.details.reduce((stockRequestDetails, stockRequestDetail) => {
                        stockRequestDetails.push(Object.assign({},{
                            ...stockRequestDetail,
                            requested_uom_quantity : that.stockRequestTotalUomComputer(stockRequestDetail, that.editedStockRequest.allow_butal == 1 ? that.STOCK_REQUEST_TYPE.PER_UOM : that.STOCK_REQUEST_TYPE.MASTER_BOX, stockRequestDetail.request_type_quantity),
                            remaining_uom_quantity : that.stockRequestTotalUomComputer(stockRequestDetail, that.editedStockRequest.allow_butal == 1 ? that.STOCK_REQUEST_TYPE.PER_UOM  : that.STOCK_REQUEST_TYPE.MASTER_BOX, stockRequestDetail.request_type_quantity),
                        }));
                        return stockRequestDetails;
                    },[])
                });

                let payload = {
                    id: that.editedStockRequest.id,
                    stockRequest: stockRequest
                }
                this.$store.dispatch('requestUpdate',payload).then((response) => {
                    swal.fire({
                        title: `Stock Request Updated : ${response.data.stock_request_form_number}`,
                        text: "updated."
                    });

                    that.dialog = false;
                    that.resetForm();
                    that.errors = [];
                    that.toggleLoading();
                    that.list(that.pagination.options.page);
                }).catch((error)=>{
                    that.errors = error.response.data.errors;

                    let errorMessages = [];

                    Object.keys(that.errors).forEach((key)=>{

                        if (key === 'authorization') {
                            that.closeDialog();
                        }

                        that.errors[key].forEach(message => {
                            errorMessages.push(`- ${message}`);
                        })
                    })

                    swal.fire({
                        title: error.response.data.message,
                        text: errorMessages.join("\n"),
                        icon: "error",
                        dangerMode: true,
                    });
                    that.toggleLoading();
                });
            } else {
                swal.fire({
                    title: "Unprocessable",
                    text: `Request Type Quantity must be greater than 0`,
                    icon: "error",
                    dangerMode: true,
                });

                that.toggleLoading();
            }
        },

        resetStockTransfer(){
            let that = this;

            that.editedStockTransfer = {
                ...Object.assign({}, {
                    approved: 0,
                    claimant: {
                        warehouse: {},
                        user: {}
                    },
                    details: [],
                    id: null,
                    latest_status: 0,
                    latest_status_detail: {},
                    remarks: null,
                    shipper: {
                        vehicle: null,
                        driver: null,
                        helpers: []
                    },
                    status: [],
                    warehouse_id: null,
                    warehouse: {},
                    user: {},
                    confirm_received_on_device: null
                })
            };
        },

        async showReceivables(stockRequest){
            let that = this;

            that.receivables.dialog = true;

            that.toggleLoading();
            that.editedStockTransfer.claimant = stockRequest

            let payload = {
                stock_request:         [stockRequest.id]
            }

            await that.$store.dispatch('stockTransfer',payload).then((response)=>{

                that.editedStockRequest.receivables = that.GET_STOCK_TRANSFER.stock_transfers.data
                // that.editedStockRequest = stockRequest
                // that.isManager              = response.data.isManager
                // that.stockTransfers.data    = response.data.stock_transfers.data;
                // that.pagination.total       = response.data.stock_transfers.total;
                // that.toggleLoading();
            });

            that.toggleLoading();
        },

        async viewReceivable(stockTransfer,warehouseID){
            let that = this;
            stockTransfer.shipper = {}
            that.toggleLoading();
            that.resetStockTransfer();
            let warehouse_inventory = []
            let stockRequest = that.editedStockTransfer.claimant

            let payload = {
                id: stockTransfer.id,
                params:{
                    serving_warehouse_id:stockTransfer.serving_warehouse_id
                }
            }
            await that.$store.dispatch('transferDetails',payload).then(response=>{
                let ob = {name:'',plate_number:''}
                that.editedStockTransfer.service                    = stockTransfer.isService
                stockTransfer.details = response.data.details.filter(e=>{
                    e.remaining_master_box_quantity = e.remaining_uom_quantity / e.master_box_qty
                    e.prepared_master_box_quantity = e.prepared_uom_quantity / e.master_box_qty
                    e.received_master_box_quantity  = e.received_uom_quantity / e.master_box_qty
                    e.latest_status                 = stockTransfer.status_description
                    e.request_type                  = stockTransfer.allow_butal == 1 ? 0 : 1
                    e.request_type_quantity         = stockTransfer.isService == 1 ? e.prepared_uom_quantity : 0
                    e.remarks                       = ''
                    e.cancelled                     =  e.status == 'Cancelled' ? true : false
                    return e;
                })

                that.editedStockTransfer.shipper.driver             = response.data.shipper.length > 0 ? response.data.shipper[0].driver : ''
                that.editedStockTransfer.shipper.vehicle            = response.data.shipper.length > 0 ? response.data.shipper[0].vehicle[0] : ob
                that.editedStockTransfer.shipper.helpers            = response.data.shipper.length > 0 ? response.data.shipper[0].helpers : []


                if(that.editedStockTransfer.shipper.helpers.length > 0){
                    that.editedStockTransfer.shipper.helpers = JSON.parse(that.editedStockTransfer.shipper.helpers)
                }

                stockTransfer.shipper = that.editedStockTransfer.shipper
            })
            let payload_warehouse = {
                warehouse_id: warehouseID,
                    from:   'Receive',
                    item_ids: stockTransfer.details.reduce((item_ids, detail) => {
                        item_ids.push(detail.item_id);
                        return item_ids;
                    },[])
            }
            await this.$store.dispatch('warehouseInventory',payload_warehouse).then((response)=>{
                warehouse_inventory = that.GET_WAREHOUSE_INVENTORY
            });

            stockTransfer.details = stockTransfer.details.filter(e=>{
                e.warehouse_inventory = warehouse_inventory.find(item => item.item_id = e.item_id).quantity
                return e
            })

            that.editedStockTransfer = stockTransfer
            that.editedStockTransfer.claimant = stockRequest


            that.receivable.dialog = true;

            that.editedStockTransfer.details
            that.toggleLoading();
        },

        confirmReceived(stockTransfer){

            let that = this;

            let agent = new Agent().compact();
            let stockTransferId = stockTransfer.shipper.stock_transfer_id


            if (that.loading.state) {
                return false;
            }

            if(stockTransfer.service == 1){
                if(stockTransfer.details.some(e=>e.received_uom_quantity == 0)){
                    swal.fire({
                        title:'Warning!',
                        text:'- Please check quantity to receive',
                        icon:'warning'
                    })
                    return false
                }
            }

            let receivedQuantityGrandTotal = stockTransfer.details.reduce((sum, detail) => {
                detail.received_uom_quantity = that.stockRequestTotalUomComputer(detail, parseInt(detail.request_type), detail.request_type_quantity)
                sum += parseInt(detail.received_uom_quantity);
                return sum;
            }, 0)

            let varianceTotal = stockTransfer.details.reduce((sum, detail) =>{
                sum += parseInt(detail.variance);
                return sum;
            }, 0)

            if (receivedQuantityGrandTotal < 1) {
                that.alertWarning(`Must Receive at least 1 item.`);
                return false;
            }

            if(varianceTotal> 0){
                that.alertWarning(`Received item has varianve, please check under STOCK VARIANCE`);
            }

            swal.fire({
                title: `Confirm Receive : ${stockTransfer.stock_transfer_form_number}`,
                text: ``,
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                reverseButtons:true,
                showCancelButton  :  true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
            }).then((confirm) => {
                if (confirm.isConfirmed) {
                    that.toggleLoading();
                    stockTransfer = Object.assign({},{
                        ...stockTransfer,
                        details: stockTransfer.details.reduce((details, detail)=>{
                            details.push(Object.assign({},{
                                ...detail,
                                variance: Math.abs(detail.prepared_uom_quantity - detail.received_uom_quantity)
                            }))
                            return details;
                        }, []),
                        agent: `${agent.os.name} ${agent.os.version} ${agent.browser.name} ${agent.browser.version}`
                    });
                    let checkRemarks = false;
                    stockTransfer.details.every(item => {
                        if(!item.receivable_remarks && item.variance != 0 && item.status != 'Cancelled'){
                            checkRemarks = false
                            return false
                        }else{
                            checkRemarks = true
                            return true
                        }
                    })
                    if(!checkRemarks){
                        this.alertWarning('Please include remarks on the items with variance')
                        that.toggleLoading();
                        return false;
                    }

                    stockTransfer.details = stockTransfer.details.filter(e=>{
                        return e.status != "Cancelled"
                    })

                    let payload = {
                        id: stockTransfer.id,
                        params: stockTransfer,
                        config: {stock_transfer_id : stockTransferId}
                    }
                    this.$store.dispatch('transferConfirmReceived',payload).then((response) => {
                        swal.fire({
                            title: `Stock Transfer Received : ${response.data.stock_transfer_form_number}`,
                            text: ""
                        });

                        that.dialog = false;
                        that.errors = [];
                        that.receivable.dialog = false;
                        that.receivables.dialog = false;
                        that.editedIndex = -1;
                        that.resetForm();
                        that.resetStockTransfer();
                        that.toggleLoading();
                        that.list();
                    }).catch((error)=>{
                        that.errors = error.response.data.errors;
                        let errorMessages = [];

                        Object.keys(that.errors).forEach((key)=>{

                            if (key === 'authorization') {
                                that.closeDialog();
                            }

                            that.errors[key].forEach(message => {
                                errorMessages.push(`- ${message}`);
                            })
                        })

                        swal.fire({
                            title: error.response.data.message,
                            text: errorMessages.join("\n"),
                            icon: "error",
                            dangerMode: true,
                        });
                        that.toggleLoading();
                    });
                }
            });
        },

        alertWarning(message){
            swal.fire({
                title: "Unprocessable",
                text: message,
                icon: "error",
                dangerMode: true,
            });
        },

        printItemReceivable(stockTransfer){
            let pdf = new jsPDF('p', 'mm', 'letter');

            let formNumberSpaced = stockTransfer.stock_transfer_form_number;
            let formNumber = stockTransfer.stock_transfer_form_number.replace(" ", "").replace("#", "");
            let fileName = `${formNumber}.pdf`;

            pdf.setFontSize(12);
            pdf.text(stockTransfer.stock_transfer_form_number,160,10,'left');
            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = '/images/ingcoph-logo.png';
            pdf.addImage(ingcoPhLogo, 'png',55, 30, 90, 15);
            pdf.text("WAREHOUSE TRANSMITTAL FORM",100,53,'center')

            pdf.text(`FROM:${stockTransfer.serving_warehouse.name}`, 10, 65);
            pdf.text(`TO:${stockTransfer.requesting_warehouse.name}`, 120, 65);
            pdf.text(`DATE:${moment().clone().toNow()}`, 10, 72);
            pdf.text(`ID#:${stockTransfer.id}`, 120, 72);

            pdf.text(`TRUCK:${stockTransfer.shipper.vehicle.name}`, 10, 79);
            pdf.text(`DRIVER:${stockTransfer.shipper.driver}`, 90, 79);
            pdf.text(`PAHENANTE:${stockTransfer.shipper.helpers.toString()}`, 150, 79);

            pdf.autoTable({
                theme: 'plain',
                styles: {
                    lineWidth: 0.1,
                    lineColor: '#7E7E7E'
                },
                headStyles: {
                    fillColor: false,
                    textColor: '#7E7E7E'
                },
                bodyStyles: {
                    fillColor: false,
                    textColor: '#7E7E7E'
                },
                margin: {top: 85, left: 7, right: 6},
                columnStyles: { 0:{fontStyle:'bold'}, 2: { halign: 'right'}},
                columns: [
                    { header: 'MODEL', dataKey: 'model'},
                    { header: 'DESCRIPTION', dataKey: 'name' },
                    { header: 'QTY By PC/UNIT(MB)', dataKey: 'prepared_uom_quantity'},
                    { header: 'QTY RECEIVED PC/UNIT', dataKey: 'received_uom_quantity' },
                ],
                body: stockTransfer.details.reduce((row, detail)=>{
                    let prepared_master_box = ''
                    if(detail.prepared_uom_quantity == 0){
                        prepared_master_box = 'Cancelled'
                    }else{
                        prepared_master_box = (detail.prepared_uom_quantity / detail.master_box_qty) % 1 != 0 ? (detail.prepared_uom_quantity / detail.master_box_qty).toFixed(2) : (detail.prepared_uom_quantity / detail.master_box_qty)
                    }
                    row.push(Object.assign({},{
                        model:                  detail.model,
                        name:                   detail.name,
                        prepared_master_box:    prepared_master_box != 'Cancelled' ? detail.prepared_uom_quantity+'('+prepared_master_box+')' : 'Cancelled',
                        prepared_uom_quantity:  detail.received_uom_quantity.toString()
                    }));
                    return row;
                }, []),
            });

            for(let page = 1; page <= pdf.internal.getNumberOfPages(); page++){
                pdf.setPage(page);
                pdf.setTextColor('#7E7E7E');
                pdf.setFontSize(10);
                pdf.text(`Page ${page} of ${pdf.internal.getNumberOfPages()}`, 14, pdf.internal.pageSize.height - 7);
            }

            pdf.save(fileName);
        },

        async printRequest(stockRequest) {

            let pdf = new jsPDF('p', 'mm', 'letter');

            let formNumber = stockRequest.stock_request_form_number.replace(" ", "").replace("#", "");
            let fileName = `${formNumber}.pdf`;

            pdf.setFontSize(12);
            pdf.text(stockRequest.stock_request_form_number,160,10,'left');
            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = '/images/ingcoph-logo.png';
            pdf.addImage(ingcoPhLogo, 'png',55, 30, 90, 15);
            pdf.text("WAREHOUSE TRANSMITTAL FORM",100,53,'center')

            pdf.text(`DESTINATION:${stockRequest.requesting_warehouse.code}`, 10, 65);
            pdf.text(`SOURCE:${stockRequest.serving_warehouse.code}`, 120, 65);
            pdf.text(`DATE:${moment().format('YYYY-MM-DD')}`, 10, 72);
            pdf.text(`EXPECTED:${stockRequest.expected_date ? stockRequest.expected_date : '' }`, 10, 79);
            pdf.text(`ID#:${stockRequest.id}`, 120, 72);
            pdf.text(`REMARKS:${stockRequest.remarks ? stockRequest.remarks : ''}`, 120, 79);

            pdf.autoTable({
                theme: 'plain',
                styles: {
                    lineWidth: 0.1,
                    lineColor: '#7E7E7E'
                },
                headStyles: {
                    fillColor: false,
                    textColor: '#7E7E7E'
                },
                bodyStyles: {
                    fillColor: false,
                    textColor: 'black'
                },
                margin: {top: 85, left: 7, right: 6},
                columnStyles: { 0:{fontStyle:'bold'}, 2: { halign: 'right'}},
                columns: [
                    { header: 'MODEL', dataKey: 'model'},
                    { header: 'DESCRIPTION', dataKey: 'name' },
                    { header: 'Location', dataKey: 'item_location'},
                    { header: 'QTY By PC/UNIT(MB)', dataKey: 'request_type_quantity'},
                    { header: 'QTY BY PC/UNI(UOM)T', dataKey: 'requested_formatted' },
                    { header: 'PREP QTY ', dataKey: 'asd' },
                ],
                body: stockRequest.details.reduce((row, detail)=>{

                    row.push(Object.assign({},{
                        model:                  detail.model,
                        name:                   detail.name,
                        request_type_quantity:  this.thousandSeprator(detail.request_type_quantity),
                        requested_formatted:    detail.requested_formatted,
                        item_location: detail.item_location
                    }));
                    return row;
                }, []),
            });

            for(let page = 1; page <= pdf.internal.getNumberOfPages(); page++){
                pdf.setPage(page);
                pdf.setTextColor('#7E7E7E');
                pdf.setFontSize(10);
                pdf.text(`Page ${page} of ${pdf.internal.getNumberOfPages()}`, 14, pdf.internal.pageSize.height - 7);
            }

            pdf.save(fileName);

        },

        difference(toReceive, actual){
            let variance =  toReceive - actual;

            if (variance < 0) {
                variance = 0;
            }

            return variance;
        },

        formatNumber(num) {
            if(!num){
                return ;
            }
            let num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },

        pressQuantity(event) {
            let charCode = (event.which) ? event.which : event.keyCode;

            if(charCode < 48 || charCode > 57){
                event.preventDefault();
            } else {
                return true;
            }
        },

        blurCheckbox(item, key, chain = null,input,index){
            let value = item[key];

            if(chain){
                chain[key] = value;
            }

            !value ? this.itemSuggestions.data[index][input] = 0 : this.itemSuggestions.selected.push(item)
        },

        blurChainQuantity(item, key, chain = null, itemStock,index){

            let quantity = item[key];
            let value = (!isNaN(quantity) && quantity && (Math.abs(parseInt(quantity)) > 0)) ? Math.abs(parseInt(quantity)) : 0;

            item[key] = value;

            if(chain){
                chain[key] = value;
            }
        },

        blurQuantity(list, index, key, chain = null){
            let quantity = list[index][key];
            let value = (!isNaN(quantity) && quantity && (Math.abs(parseInt(quantity)) > 0)) ? Math.abs(parseInt(quantity)) : 0;

            list[index][key] = value;

            if(chain){
                chain[key] = value;
            }
        },

        stockRequestTotalUomComputer(item, requestType, requestQuantity){

            let total = 0;
            switch (requestType) {
                case this.STOCK_REQUEST_TYPE.PER_UOM:
                    total = requestQuantity;
                    break;
                case this.STOCK_REQUEST_TYPE.MASTER_BOX:
                    total = (requestQuantity * item.master_box_qty);
                    break;
                case 5:
                    total = requestQuantity / item.master_box_qty
                    break;
            }

            return total;
        },

        // shipping(stockRequest){
        //     return stockRequest.receivables.filter(receivable => receivable.latest_status === STOCK_TRANSFER_STATUS.SHIPPING).length;
        // },

        requestHasZeroTypeQuantity(stockRequests = []){
            return stockRequests.filter(stockRequest => stockRequest.request_type_quantity < 1).length > 0;
        },

        alertWarnings(message, errors){
            let that = this;
            let errorMessages = [];

            Object.keys(errors).forEach((key) => {

                if (key === 'authorization') {
                    //Close Recent Dialog || All Dialog
                }

                errors[key].forEach(message => {
                    errorMessages.push(`- ${message}`);
                })
            })

            swal.fire({
                title: message,
                text: errorMessages.join("\n"),
                icon: "warning",
                dangerMode: true,
            });
        },

        cancelPartial(stockRequest) {
            let that = this
            that.toggleLoading();
            swal.fire({
                buttons: true,
                text: "Are you sure you want to cancel ?",
                title: 'Cancel Request',
                icon:'warning'
            }).then(confirm => {
                if (confirm) {

                    that.$store.dispatch('requestCancelPartial',stockRequest.id).then(response => {
                        that.toggleLoading();
                        that.list();
                        swal.fire('Success !',' - Partial Request Cancelled','success')
                    }).catch((error)=>{
                        that.toggleLoading();
                        that.errors = error.response.data.errors;

                        let errorMessages = [];

                        Object.keys(that.errors).forEach((key)=>{
                            that.errors[key].forEach(message => {
                                errorMessages.push(`- ${message}`);
                            })
                        })

                        swal.fire({
                            title: error.response.data.message,
                            text: errorMessages.join("\n"),
                            icon: "warning",
                            dangerMode: true,
                        });
                    });
                }
                that.toggleLoading();
            })
        },
        async handleChange(e) {
            if(e){
                this.isVisible = false;
            }else{
                this.isVisible = true;
            }
            this.batchAddition.imported.data = e
            const wb = new ExcelJS.Workbook();
            const reader = new FileReader();
            let data = [];
            let warehouse_checker = {
                requesting:[],
                serving:[],
                both:[]
            }
            reader.readAsArrayBuffer(e)
            reader.onload = async () => {
                const buffer = reader.result;
                await wb.xlsx.load(buffer).then(async workbook => {
                    workbook.eachSheet((sheet, id) => {
                        sheet.eachRow((row, rowIndex) => {
                            if (rowIndex > 1) {
                                data.push({
                                    warehouse: row.values[1],
                                    initial_serving_warehouse: row.values[2],
                                    model: row.values[3],
                                    requested_uom_quantity: row.values[4],
                                    request_type_quantity: row.values[4],
                                })
                                warehouse_checker.requesting.push(row.values[1])
                                warehouse_checker.serving.push(row.values[2])
                                warehouse_checker.both.push({warehouse:row.values[1],initial_serving_warehouse:row.values[2]})
                            }
                        })
                    })
                    let item_models = _.groupBy(data,'model')
                    item_models = Object.keys(item_models)

                    const item_checker = await new Promise((resolve,reject)=>{
                        this.$store.dispatch('getItemsByModel', {
                            items: item_models,
                            isChecking:true
                        }).then(response => {
                            resolve(response.data)
                        }).catch(err=>{
                            reject(err)
                        });
                    })

                    const warehouses = await new Promise((resolve,reject)=>{
                        this.$store.dispatch('inventoryPost',{
                            url:'validate-warehouse',
                            warehouses: warehouse_checker.serving.concat(warehouse_checker.requesting)
                        }).then(response=>{
                             resolve(response.data)
                        }).catch(err=>{
                            reject(err)
                        })
                    })

                    let error = 0

                    let w = [...new Set(warehouse_checker.requesting)].concat([...new Set(warehouse_checker.serving)])
                    let ar = []
                    let undefined_warehouse = []
                    let clean = []

                    w.forEach(e=>{
                        if(!warehouses.find(x=>x.code == e)){
                            undefined_warehouse.push(e)
                        }
                    })

                    warehouse_checker.both = warehouse_checker.both.map(e=>JSON.stringify(e))
                    warehouse_checker.both.forEach(e=>{
                        if(!ar.includes(e)){
                            ar.push(e)
                        }
                    })

                    ar = ar.map(e=>JSON.stringify(e))

                    ar.forEach((ell,index)=>{
                        ell = JSON.parse(JSON.parse(ell))
                        let both_warehouse_checked = undefined_warehouse.includes(ell.warehouse) || undefined_warehouse.includes(ell.initial_serving_warehouse) ? true : false
                        let err = 0
                        let error_message = []
                        let allowable = 0 ;

                        if(ell.warehouse == ell.initial_serving_warehouse || both_warehouse_checked) {
                            err++
                            error++
                            error_message.push(ell.warehouse == ell.initial_serving_warehouse ? this.errorObjects(3) : this.errorObjects(1))
                        }

                        if(!!warehouses.find(x=>x.code == ell.initial_serving_warehouse) && !!warehouses.find(x=>x.code == ell.warehouse)){
                            allowable = warehouses.find(x=>x.code == ell.initial_serving_warehouse).allow_butal == 1 &&
                                    warehouses.find(x=>x.code == ell.warehouse).allow_butal == 1 ? 0:1
                        }

                        clean.push(
                            Object.assign(ell,{
                                details:data.filter(e => e.warehouse == ell.warehouse && e.initial_serving_warehouse == ell.initial_serving_warehouse).map((x,ind)=>{
                                    if(!!item_checker.find(i=>i.model == x.model)){
                                        // console.log(item_checker.find(i=>i.model == x.model))
                                        x.item_id  = item_checker.find(i=>i.model == x.model).id,
                                        x.master_box_qty  = item_checker.find(i=>i.model == x.model).master_box_qty,
                                        x.error = false,
                                        x.requested_uom_quantity = warehouses.find(x=>x.code == ell.initial_serving_warehouse).allow_butal == 1 &&
                                            warehouses.find(x=>x.code == ell.warehouse).allow_butal == 1 ? this.stockRequestTotalUomComputer(x, 1,x.request_type_quantity) :
                                            this.stockRequestTotalUomComputer(x, 5,x.request_type_quantity)
                                    }else{
                                        x.error = true
                                        err++
                                        error_message.push(this.errorObjects(2))
                                    }
                                    return x
                                }),
                                error:err,
                                error_message:error_message,
                                initial_serving_warehouse_id: !both_warehouse_checked ? warehouses.find(x=>x.code == ell.initial_serving_warehouse).id : 0,
                                warehouse_id: !both_warehouse_checked ? warehouses.find(x=>x.code == ell.warehouse).id : 0,
                                allowable_butal:allowable,
                                allow_butal:0,
                            })
                        )
                    })

                    this.batchAddition.imported.data = clean
                    this.batchAddition.imported.error = error
                })
            }
            this.panel = [...Array(this.items).keys()].map((k, i) => i)
        },
        createBatch(){
            let services = []
            let errors = 0

            if(!this.batchAddition.imported.data.length > 0){
                return false
            }

            this.batchAddition.imported.data.forEach(e=>{
                if(e.error){
                    errors++
                }

                e.details.forEach(el=>{
                    if(el.error){
                        errors++
                    }
                })
            })

            if(errors > 0){
                swal.fire('Error!','Please check your inputs','error')
                return false
            }

            this.batchAddition.imported.data.forEach(e=>{
                services.push(new Promise((resolve, reject) => {
                    this.$store.dispatch('requestCreate',e).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        console.log(error)
                        reject(error);
                    });
                }))
            })
            swal.fire({
                title: `Stock Requested`,
                text: "",
                icon:'success'
            });
            this.list()
            this.handleChange = null
            this.toggleDialog('batchAddition')
            this.toggleDialog('requestingWarehouse')
        },
        all () {
            this.panel = [...Array(this.items).keys()].map((k, i) => i)
        },
        none () {
            this.panel = []
        },
        removeItem(i,index,type){
            if(type == 'item'){
                return this.batchAddition.imported.data[index].details.splice(i, 1)
            }else{
                return this.batchAddition.imported.data.splice(index, 1)
            }
        },
        updateUomQuantity(index){
            this.batchAddition.imported.data[index].details.forEach(e=>{
                e.requested_uom_quantity = this.stockRequestTotalUomComputer(e,this.batchAddition.imported.data[index].allow_butal ? 5 : 1,e.request_type_quantity)
            })
        },
        errorObjects(type){
            switch(type){
                case 1:
                    return {
                        title:'Warehouse Not Found',
                        color:'#8271FA'
                    }
                case 2:
                    return {
                        title:'Item Not Found',
                        color:'#71ADFA'
                    }
                case 3:
                    return {
                        title:'Can\'t request at the sames warehouse',
                        color:'#FA7192'
                    }
            }
        },


        async addData(val){
            let models = []

            const model = val.map(item => {
                return item.model
            })

            let payload = {
                items: model
            }

            await this.$store.dispatch('getItemsByModel', payload).then(response => {
                models = response.data
            })

            const notExist = val.filter((elem) => {
                return !models.find(ele => ele.model == elem.model);
            });

            const unique_notExist = [...new Set(notExist.map(item => item.model))];
            let showItemsAsString = unique_notExist.join("\r\n");

            if(notExist.length > 0){
                swal.fire('Error!','Some items Does not exist '+ `<br>` +
                  showItemsAsString.split(",").join("\r\n") + `<br>` + 'Please Check your inputs.','error')
                return false
            }

            models.forEach(element => {
                element.request_type_quantity = val.find(e=>e.model == element.model).quantity
            });

            this.batchAddition.imported.data[this.batchAddition.warehouse.selectedIndex].details.filter(e=>{
                let same_item = null
                val.forEach(x=>{
                    if(e.model == x.model){
                        same_item = x
                    }
                })

                if(!!same_item){
                    e.request_type_quantity = same_item.quantity
                }
            })

            let data = this.batchAddition.imported.data[this.batchAddition.warehouse.selectedIndex].details.concat(models)
            const distinctObjects = this.getDistinctObjects(data, 'model');
            this.batchAddition.imported.data[this.batchAddition.warehouse.selectedIndex].details = distinctObjects
            this.batchAddition.addition.dialog = false

        },
        closeDialog(){
            this.batchAddition.addition.dialog = false
        },
        createRequestDialog(){
            if(!this.warehouse.value){
                swal.fire('Warning!','- Please select Warehouse','warning')
                return
            }
            this.toggleDialog(`requestingWarehouse`);
            this.toggleDialog(`stockRequestCreationChainer`);
            this.prepareChainer();
            if(!this.from_hakot.value){
                this.getItems(this.warehouse.value);
            }
            this.filterServingWarehouse()
            return
        },
        async createFromHakot(){
            this.from_hakot.value = true
            await this.$store.dispatch('inventoryGet', {
                url: 'operations-report/sheet1',
                dates: [moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
                warehouses:this.warehouse_user
            }).then(response => {
                this.warehouse.value = this.warehouse.selection.find(e=> e.isDefault == 1).value

                let data = response.data;
                data = data.filter(e=>parseInt(e.pending) > 0);
                data = _.uniqBy(data,'item_id')
                console.log(data)
                this.from_hakot.data = data
                this.createRequestDialog()
            })
        }
    },

    watch : {
        'pagination.options': {
            handler() {
                this.list(this.pagination.options.page);
            },
            deep: true,
        },
        'warehouse.value': {
            immediate: true,
            handler() {
                let that = this;
                that.editedStockRequest.details = [];
                // that.servingWarehouse.value = null;
            }
        },
        'itemSuggestions.datatable.pagination.options': {
            handler () {
                let that = this;
                that.itemSuggestions.data = [];

                if(that.stockRequestCreationChainer.dialog){
                    that. getAllItems()
                } else {
                    that.getItemSuggestions(that.itemSuggestions.datatable.pagination.options.page)
                }
            },
            deep: true,
        },
        'warehouse.virtual':{
            handler(val){
                this.getWarehouses(val)
            }
        },
        view_type:function(val){
            if(val == 'Warehouse'){
                this.getUserWarehouses()
            }else{
                this.filters.warehouse.selected = null
                this.warehouse.vTabOptions = []
                let dateBefore = []
                let dateAfter = []

                for(let i = 1; i <= 10;i++){
                    dateAfter.push({code:moment().add(i,'days').format('MM/DD'),date:moment().add(i,'days').format('YYYY-MM-DD')})
                    dateBefore.push({code:moment().subtract(i,'days').format('MM/DD'),date:moment().subtract(i,'days').format('YYYY-MM-DD')})
                }

                dateBefore = dateBefore.reverse()
                dateBefore.push({code:moment().format('MM/DD'),date:moment().format('YYYY-MM-DD')})

                this.warehouse.vTabOptions = dateBefore.concat(dateAfter)
                this.warehouse.vTabOptions.forEach((e,i)=>{
                    e.value = i
                    if(moment().format('MM/DD') == e.code){
                        this.tab = 15
                        this.date = e.date
                    }
                })

                this.tab1 = -2
                this.selectedStatus = -2
            }
        },
        'itemSuggestions.datatable.filters.type_id.selected':{
            handler(val){
                this.getItems(this.warehouse.value)
            }
        },
        'warehouse.servingWarehouses.selected':{
            handler(val){
                this.getItemChainerSuggestions()
            }
        },
        'itemSuggestions.requestedItems.selected':{
            handler(val){
                this.getItemChainerSuggestions()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.stock-request-text-xxs{
    font-size: .6rem !important;
    line-height: 0.8rem;
}

.stock-request-text-xs{
    font-size: .8rem !important;
    line-height: 1rem;
}

.stock-request-items{
    font-size: .8rem !important;
}

.chainer-input{
    padding-right: 2px !important;
}

.warehouse-divider::before{
    display: block;
    position: absolute;
    content: "";
    border-left: 1px solid rgb(235,235,235);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.abc{
    font-size: 11px;
    background-color: aquamarine;
    padding-right:1px ;
}

::v-deep .remove_warehouse{
    position: absolute !important;
    right: 50px;
    width: 20px;
    height: 20px;
}
</style>

<style>
.v-dialog, .v-dialog--active {
    overflow: visible;
}
.v-list__group__header__prepend-icon .v-icon {
    color: red;
}
</style>
