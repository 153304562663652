<template>
  <div class="page-wrapper">
    <v-container>
      <h4 class="page-title" style="color: black">Stock Transfer</h4>
      <v-row class="ma-2" v-if="userAccess.create">
        <v-col cols="12" class="d-flex no-block align-items-center">
          <div class="btn-toolbar">
            <v-btn
              data-modal-id="addStockTransferModal"
              v-on:click="showAddStockTransfer()"
            >
              <i class="fas fa-plus"></i>Add
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-3">
        <!-- StockTransfers Table -->
        <StockTransfersTableComponentVue
          :stockTransfersActionResult="action"
          @showEditModal="showEditModal"
          :service="service"
        ></StockTransfersTableComponentVue>
      </v-row>
    </v-container>

    <v-app id="addEditStockTransferModal">
      <v-dialog
        v-model="add_edit_stockTransfer_dialog"
        persistent
        max-width="90%"
        scrollable
      >
        <v-card>
          <v-row class="m-0">
            <v-col>
              <v-card-title>
                <span class="headline">Stock Transfer</span>
              </v-card-title>
            </v-col>
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="
                  add_edit_stockTransfer_dialog = false;
                  resetFields();
                  $emit('closeModal');
                "
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text>
            <form
              class="form-horizontal"
              action="/stockTransfers"
              method="post"
              v-on:submit.prevent="onSubmit"
            >
              <v-card>
                <!-- <v-row>
                                <v-col cols="12" style="display:none;">
                                    <label for="user_id" class="float-left control-label col-form-label">SRF Number</label>
                                    <v-autocomplete outlined dense :items="GET_SRF_NUMLIST"
                                        v-model="srf_num"
                                        item-value="value"
                                        item-text="text"
                                        placeholder="Select SRF Number"
                                        class="d-inline-block"
                                    >
                                    </v-autocomplete>
                                    <span v-for="errors in errors.srf_num" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </v-col>
                            </v-row> -->
                <v-row class="ma-2">
                  <v-col cols="3">
                    <span>Source: <br /></span>
                    <v-autocomplete
                      outlined
                      dense
                      :items="GET_ALL_WAREHOUSES"
                      :loading="loading.warehouse_selection"
                      :disabled="loading.warehouse_selection"
                      v-model="source_id"
                      item-value="value"
                      item-text="text"
                      placeholder="Select Source"
                      class="d-inline-block w-100"
                    >
                    </v-autocomplete>
                    <span
                      v-for="errors in errors.source_id"
                      class="text-warning"
                      v-bind:key="errors"
                      >{{ errors }}</span
                    >
                  </v-col>
                  <v-col cols="3">
                    <span>Desination: <br /></span>
                    <v-autocomplete
                      outlined
                      dense
                      :items="GET_ALL_WAREHOUSES"
                      :loading="loading.warehouse_selection"
                      :disabled="loading.warehouse_selection"
                      v-model="destination_id"
                      item-value="value"
                      item-text="text"
                      placeholder="Select Destination"
                      class="d-inline-block w-100"
                    >
                    </v-autocomplete>
                    <span
                      v-for="errors in errors.destination_id"
                      class="text-warning"
                      v-bind:key="errors"
                      >{{ errors }}</span
                    >
                  </v-col>
                  <v-col cols="6" class="pa-2">
                    <v-textarea
                      label="Remarks"
                      no-resize
                      rows="2"
                      v-model="remarks"
                      class="p-0"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-card outlined class="p-2">
                  <v-row class="row">
                    <v-col cols="12">
                      <div class="float-right mx-4">
                        <v-btn
                          class="mr-1 mt-2"
                          fab
                          dark
                          small
                          color="primary"
                          style="height: 20px; width: 20px"
                          @click="addItem()"
                        >
                          <v-icon dark small style="font-size: 12px">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          class="mr-1 mt-2"
                          fab
                          dark
                          small
                          color="primary"
                          style="height: 20px; width: 20px"
                          @click="removeItem()"
                        >
                          <v-icon dark small style="font-size: 12px">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-simple-table dense class="dynamic_list_table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <!-- <th class="text-center mx-2" width="5%"></th> -->
                              <th class="text-center mx-2" width="20%">
                                Model
                              </th>
                              <th class="text-center mx-2" width="45%">Name</th>
                              <th class="text-center mx-2" width="10%">
                                Source Stock
                              </th>
                              <th class="text-center mx-2" width="10%">
                                Requested Quantity
                              </th>
                              <th class="text-center mx-2" width="10%">
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(transfer_item, i) in transfer_items"
                              :key="i"
                            >
                              <!-- <td>
                                                            <v-checkbox
                                                                v-model="transfer_items[i].active"
                                                                label=""
                                                                color="primary"
                                                                input-value="1"
                                                                class="align-center justify-center"
                                                            ></v-checkbox>
                                                        </td> -->
                              <td>
                                <v-autocomplete
                                  outlined
                                  dense
                                  :items="GET_ITEMS"
                                  v-model="transfer_items[i].item_id"
                                  item-value="value"
                                  item-text="text"
                                  placeholder="Select Model"
                                  class="d-inline-block small w-100 mt-2"
                                  @input="
                                    changeItem(i, transfer_items[i].item_id)
                                  "
                                >
                                </v-autocomplete>
                              </td>
                              <td>
                                <v-autocomplete
                                  outlined
                                  dense
                                  :items="GET_ITEMS"
                                  v-model="transfer_items[i].item_id"
                                  item-value="value"
                                  item-text="name"
                                  placeholder="Select Name"
                                  class="d-inline-block small w-100 mt-2"
                                  @input="
                                    changeItem(i, transfer_items[i].item_id)
                                  "
                                >
                                </v-autocomplete>
                              </td>
                              <td>
                                <v-text-field
                                  dense
                                  outlined
                                  class="text-center mt-2"
                                  readonly
                                  :id="'stock_' + i"
                                  placeholder="0"
                                  v-model="transfer_items[i].stock"
                                ></v-text-field>
                              </td>
                              <td>
                                <v-text-field
                                  dense
                                  outlined
                                  class="text-center mt-2"
                                  readonly
                                  :id="'requested_qty_' + i"
                                  placeholder="0"
                                  v-model="transfer_items[i].requested_qty"
                                ></v-text-field>
                              </td>
                              <td>
                                <v-text-field
                                  dense
                                  outlined
                                  class="text-center mt-2"
                                  :id="'quantity_' + i"
                                  placeholder="0"
                                  v-model="transfer_items[i].quantity"
                                  @keypress="numberOnly"
                                  @input="checkQuantity(i)"
                                ></v-text-field>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>

              <div class="border-top">
                <div class="card-body py-4 d-flex justify-content-center">
                  <v-btn
                    v-on:click="addNewStockTransfer()"
                    v-if="action == 'Add'"
                    >Submit</v-btn
                  >
                  <v-btn
                    v-on:click="editStockTransfer()"
                    v-else-if="action == 'Edit'"
                    >Update</v-btn
                  >
                </div>
              </div>
            </form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import StockTransfersTableComponent from './StockTransfersTableComponent.vue'
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import StockTransfersTableComponentVue from "./tables/StockTransfersTableComponent.vue";
import swal from "sweetalert2";
export default {
  mixins: [SharedFunctionsComponentVue],
  data() {
    return {
      editStockTransferId: "",
      add_edit_stockTransfer_dialog: false,
      srf_num: "",
      srf_num_list: [],
      source_id: "",
      sources: [],
      destination_id: "",
      destinations: [],
      remarks: "",
      transfer_items: [],
      transfer_item: {
        item_id: "",
        name: "",
        quantity: "",
        stock: "",
        active: "1",
        requested_qty: "",
      },
      date_created: "",
      user: "",
      items_list: [],
      status: "",
      max_items_count: 100,
      errors: [],
      action: "",
      actionResult: {
        model: "Stock Transfer",
        action: "",
      },
      userAccess: {
        create: false,
      },
      loading: {
        warehouse_selection: true,
      },
    };
  },
  components: {
    // StockTransfersTableComponent,
    StockTransfersTableComponentVue
  },
  mounted: function () {
    this.$store.dispatch(
      "checkAccessUser",
      this.$router.currentRoute.params.id
    );
    this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
  },
  computed: {
    ...mapGetters([
      "GET_ITEMS",
      "GET_ALL_WAREHOUSES",
      "GET_SRF_NUMLIST",
      "GET_WAREHOUSE_ITEM_STOCKS",
      "SHOW_STOCK_TRANSFER",
      "USER_ACCESS",
    ]),
  },
  props: ["service"],
  methods: {
    // checkAccess(dep, mod, axn){
    //     axios.get('/checkAccess',{
    //         params: {
    //             department_code: dep,
    //             module: mod,
    //             action: axn,
    //         }
    //     }).then(response => {
    //         this.userAccess[axn] =  response.data;
    //     });
    // },
    checkQuantity(index) {
      if (index != null) {
        if (this.transfer_items[index] != null) {
          if (
            this.transfer_items[index].stock != "" &&
            this.transfer_items[index].quantity != ""
          ) {
            if (
              parseInt(this.transfer_items[index].quantity) >
              this.transfer_items[index].stock
            ) {
              this.transfer_items[index].quantity =
                this.transfer_items[index].stock;
            }
          } else {
            this.transfer_items[index].quantity = 0;
          }
        }
      }
    },
    removeItem() {
      if (this.transfer_items.length > 1) {
        this.transfer_items.pop();
      }
    },
    addItem() {
      if (this.transfer_items.length < this.max_items_count) {
        this.transfer_items.push({
          item_id: "",
          name: "",
          quantity: "",
          stock: "",
          active: 1,
          requested_qty: "",
        });
      }
    },
    getSrfNumList() {
      this.$store.dispatch("getSrfNumlist");
    },
    getItems() {
      this.$store.dispatch("getItems");
    },
    getWarehouses() {
      let payload = {
        no_virtual: true,
      };
      this.$store.dispatch("getAllWarehouses", payload);
    },
    showAddStockTransfer() {
      this.add_edit_stockTransfer_dialog = true;
      this.action = "Add";
    },
    getWarehouseItemStock(item_id, warehouse_id, index) {
      let payload = {
        item_id: item_id,
        warehouse_id: warehouse_id,
      };
      this.$store
        .dispatch("getWarehouseItemStocks", payload)
        .then((response) => {
          let data = this.GET_WAREHOUSE_ITEM_STOCKS;
          if (data != null) {
            this.transfer_items[index].stock = data;
          } else {
            this.transfer_items[index].stock = 0;
          }

          this.checkQuantity(index);
        });
    },
    changeItem(index, item_id) {
      if (this.source_id != "" && item_id != "") {
        this.getWarehouseItemStock(item_id, this.source_id, index);
      } else {
        this.transfer_items[index].stock = 0;
      }
    },
    addNewStockTransfer() {
      this.errors = [];
      let payload = {
        srf_num: this.srf_num,
        source_id: this.source_id,
        destination_id: this.destination_id,
        user_id: this.user_id,
        remarks: this.remarks,
        transfer_items: this.transfer_items,
      };
      this.$store
        .dispatch("storeStockTransfer", payload)
        .then((response) => {
          let data = response.data;
          if (data.error) {
            swal.fire("", data.error, "warning");
            return false;
          }
          this.resetFields();

          this.add_edit_stockTransfer_dialog = false;
          this.action = "success";

          swal.fire("", response.data.msg, "success");
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    editStockTransfer() {
      this.errors = [];
      let payload = {
        id: this.editStockTransferId,
        params: {
          srf_num: this.srf_num,
          source_id: this.source_id,
          destination_id: this.destination_id,
          user_id: this.user_id,
          remarks: this.remarks,
          transfer_items: this.transfer_items,
        },
      };

      this.$store
        .dispatch("updateStockTransfer", payload)
        .then((response) => {
          let data = response.data;
          if (data.error) {
            swal.fire("", data.error, "warning");
            return false;
          }
          this.resetFields();
          this.add_edit_stockTransfer_dialog = false;
          this.editStockTransferId = 0;
          this.action = "success";
          swal.fire("", response.data.msg, "success");
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    showEditModal(value) {
      this.action = "Edit";
      this.editStockTransferId = value;

      if (this.editStockTransferId != null && this.editStockTransferId > 0) {
        this.$store
          .dispatch("showStockTransfer", this.editStockTransferId)
          .then((response) => {
            let data = this.SHOW_STOCK_TRANSFER;

            this.srf_num = data.srf_num;
            this.source_id = data.source_id;
            this.destination_id = data.destination_id;
            this.remarks = data.remarks;
            this.transfer_items = data.warehouse_transfer_items;

            this.transfer_items.forEach((item, index) => {
              if (item.item_id != null && item.item_id != "") {
                item.active = 1;
              }
            });
            this.add_edit_stockTransfer_dialog = true;
          })
          .catch((error) => {});
      }
    },
    resetFields() {
      this.errors = [];
      this.srf_num = "";
      this.source_id = "";
      this.destination_id = "";
      this.transfer_items = [];
      this.remarks = "";
      this.user = "";
      this.action = "";
    },
    addKeyListener() {
      window.addEventListener(
        "keyup",
        function (e) {
          var keypressed = String.fromCharCode(e.keyCode);
          if (e.ctrlKey && e.keyCode == 32) {
            this.addItem();
            $("input").blur();
          } else if (keypressed == "-") {
            this.removeItem();
            $("input").blur();
          }
        }.bind(this)
      );
    },
  },
  watch: {
    add_edit_stockTransfer_dialog: function () {
      if (this.add_edit_stockTransfer_dialog == true) {
        this.getWarehouses();
        this.getItems();
        this.getSrfNumList();

        if (this.transfer_items.length == 0) {
          this.addItem();
        }
      }
    },
    source_id: function (val) {
      if (val != null && val != "" && this.transfer_items.length > 0) {
        this.transfer_items.forEach((item, index) => {
          if (item.item_id != null && item.item_id != "") {
            this.getWarehouseItemStock(item.item_id, this.source_id, index);
          }
        });
      }
    },
    USER_ACCESS: {
      handler(val) {
        this.userAccess.create = false;

        if (val != "fail") {
          val.forEach((e) => {
            if (e.actions_code == "CRT") {
              this.userAccess.create = true;
            }
          });
        }
      },
    },
    srf_num: function (val) {},
    GET_ALL_WAREHOUSES: {
      handler(val) {
        this.loading.warehouse_selection = false;
      },
    },
  },
};
</script>
<style>
.col {
  padding: 2px !important;
}

.card-body {
  padding: 3px !important;
}
</style>
