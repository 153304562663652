<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title" style="color: black">Item Costing</h3>
        <v-container class="ma-0">
            <v-app id="customer-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" class="col-12" show-arrows @change="refreshData()">
                    <v-tab ripple>Items</v-tab>
                    <v-tab ripple>Currency</v-tab>
                    <v-tab ripple>Sizes</v-tab>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <ItemCostingComponent :refreshData="refresh_data.item_costing"></ItemCostingComponent>
                        </v-tab-item>

                        <v-tab-item>
                            <CurrencyComponent :refreshData="refresh_data.currency"></CurrencyComponent>
                        </v-tab-item>

                        <v-tab-item>
                            <ItemCostingSizesComponent :refreshData="refresh_data.item_costing_sizes"></ItemCostingSizesComponent>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import CurrencyComponent from './CurrencyComponent.vue';
import ItemCostingComponent from './ItemCostingComponent.vue';
import ItemCostingSizesComponent from './ItemCostingSizesComponent.vue';

export default {
    data() {
        return {
            tab: null,
            refresh_data: {
                item_costing: false,
                currency: false,
                item_costing_sizes: false,
            },
            tab_names: [
                'item_costing',
                'currency',
                'item_costing_sizes'
            ]
        }
    },
    components: {
        CurrencyComponent,
        ItemCostingComponent,
        ItemCostingSizesComponent,
    },
    mounted() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
    },
    methods: {
        refreshData() {
            this.refresh_data[this.tab_names[this.tab]] = true
            setTimeout(() => {
                this.refresh_data[this.tab_names[this.tab]] = false
            }, 500)
        }
    }
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>

