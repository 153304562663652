<template>
  <v-container fluid>
    <v-card-title>
      <v-row>
        <v-col md="16" class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">SALESMAN</h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-container>
      <v-row>
        <template>
          <v-tabs v-model="tab" color="cyan" dark slider-color="cyan"  background-color="#424242"
            class="mx-2" show-arrows>
            <v-tabs-slider color="cyan"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item" class="ml-3">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-row>
    </v-container>
    <v-container class="ma-0 pa-2">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(table, index) in component_salesman" :key="index">
          <keep-alive>
            <component :is="table.components_child" style="max-width: 500px;"></component>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>
<script>
import position from './tables/SalesmanPositionComponent.vue'
import salesman from './tables/SalesmanComponent.vue'
export default {
  components: { position, salesman },
  data() {
    return {
      tab: null,
      items: ['Position', 'Salesman'],
      component_salesman: [
        {
          components_child: position,
        },
        {
          components_child: salesman,
        },
      ],
      salesman: ''
    };
  },
  mounted() {
    this.$store.commit('LINK_ACTIVE',window.location.pathname.split("/")[3])
  },
};
</script>
