<template>
    <v-dialog v-model="custSoaPaymentDetailDialog" persistent max-width="98%">
        <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Customer Soa Payment Details</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="custSoaPaymentDetailClose()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <div class="px-4 pb-4">
                <v-layout row class="mx-0">
                    <v-flex lg6>
                        <div>
                            <span>SOA ID: </span>
                            <span>{{ soaNumber }}</span>
                        </div>
                        <div>
                            <span>Company Name: </span>
                            <span>{{ companyName }}</span>
                        </div>
                        <div>
                            <span>Due Date: </span>
                            <span>{{ dueDate }}</span>
                        </div>
                        <div>
                            <span>Status: </span>
                            <span v-if="status=='confirmed'" class="badge bg-primary">{{ status }}</span>
                            <span v-else-if="status=='partial'" class="badge bg-warning">{{ status }}</span>
                            <span v-else-if="status=='paid'" class="badge bg-success">{{ status }}</span>
                        </div>
                    </v-flex>

                    <v-flex lg6>
                        <div>
                            <span>Date: </span>
                            <span>{{ date }}</span>
                        </div>
                    </v-flex>
                </v-layout>
            </div>

            <v-card class="pa-4 mx-4 mb-4">
                <div>
                    Payment Details
                </div>
                <v-data-table
                    :headers="headers"
                    :items="customerPaymentDetails"
                    :items-per-page="!!customerPaymentDetails ? customerPaymentDetails.length : 10"
                >
                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.payment_type_name`]="{ item }">
                        <span class="badge bg-success" v-if="item.payment_type_name=='Cash'">{{ item.payment_type_name }}</span>
                        <span class="badge bg-purple" v-else-if="item.payment_type_name=='Cheque'">{{ item.payment_type_name }}</span>
                        <span class="badge bg-warning" v-else-if="item.payment_type_name=='Deposit'">{{ item.payment_type_name }}</span>
                    </template>

                    <template v-slot:[`item.remaining_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.remaining_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.allocated_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.allocated_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ formatDate(item.created_at) }}
                        </span>
                    </template>

                    <template v-slot:[`item.allocated`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewPaymentAllocations(item)">mdi-eye</v-icon>
                            </v-btn>
                        </span>
                    </template>
                </v-data-table>
            </v-card>

            <div style="text-align:end" class="px-4 pb-4">
                <div>
                    <span>Discount Amount: </span>
                    <span>&#x20b1;{{ discountAmount | currency }}</span>
                </div>
                <div>
                    <span>Addition Amount: </span>
                    <span>&#x20b1;{{ additionAmount | currency }}</span>
                </div>
                <div>
                    <span>Deduction Amount: </span>
                    <span>&#x20b1;{{ deductionAmount | currency }}</span>
                </div>
                <div>
                    <span>Gross Amount: </span>
                    <span>&#x20b1;{{ grossAmount | currency }}</span>
                </div>
            </div>

            <div style="text-align:end" class="px-4 pb-4">
                <div>
                    <span>Total Amount: </span>
                    <span>&#x20b1;{{ totalAmount | currency }}</span>
                </div>
                <div>
                    <span>Paid Amount: </span>
                    <span>&#x20b1;{{ paidAmount | currency }}</span>
                </div>
                <div>
                    <span>Balance Amount: </span>
                    <span>&#x20b1;{{ balanceAmount | currency }}</span>
                </div>
            </div>
        </v-card>

        <v-dialog v-model="paymentAllocationsDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Payment Allocation Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closePaymentAllocationsDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mt-0" />

                <div class="px-4">
                    <div>Reference Number: {{ selectedRefNumber }}</div>
                    <v-data-table
                        :headers="paymentAllocationsHeader"
                        :items="customerPaymentAllocations"
                        :items-per-page="customerPaymentAllocations.length"
                        :loading="paymentAllocationLoading"
                        :no-data-text="noDataText"
                    >
                    <template v-slot:[`item.allocated_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.allocated_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ formatDate(item.created_at) }}
                        </span>
                    </template>
                    </v-data-table>
                </div>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
    import moment from 'moment';
export default {
    data() {
        return {
            headers: [
                { text: 'Ref#', width: '84px', value: 'reference_number' },
                { text: 'Bank', value: 'bank_name' },
                { text: 'Payment type', value: 'payment_type_name' },
                { text: 'Cheque Name', value: 'cheque_name' },
                { text: 'Cheque Date', value: 'cheque_date' },
                { text: 'Allocate WHT', value: 'allocated_wht' },
                { text: 'Allocate Amount', value: 'allocated_amount' },
                { text: 'Created At', value: 'created_at' },
                { text: 'Allocated', value: 'allocated' },
            ],
            soaCustomerPaymentDetails: null,
            customerPaymentDetails: [],
            status: '',
            soaNumber: '',
            companyName: '',
            dueDate: '',
            grossAmount: '',
            totalAmount: '',
            paidAmount: '',
            discountAmount: '',
            additionAmount: '',
            deductionAmount: '',
            balanceAmount: '',
            date: '',
            deductionRemarks: '',
            additionRemarks: '',
            statementOfAccountId: '',
            paymentAllocationsDialog: false,
            noDataText: '',
            paymentAllocationLoading: true,
            paymentAllocationsHeader: [
                { text: 'Allocated WHT', value: 'allocated_wht' },
                { text: 'Allocated Amount', value: 'allocated_amount' },
                { text: 'Allocated By', value: 'allocated_by' },
                { text: 'Date', value: 'created_at' },
            ],
            customerPaymentAllocations: [],
            selectedRefNumber: '',
        }
    },
    props: ['soaId'],
    computed: {
        custSoaPaymentDetailDialog() {
            return this.$store.state.accounting.customerSoaPaymentDetails.custSoaPaymentDetailDialog;
        }
    },
    watch: {
        soaId() {
            if(!!this.soaId) {
                this.statementOfAccountId = this.soaId;
            }
        },
        statementOfAccountId() {
            if(!!this.statementOfAccountId) {
                this.getSoaCustomerPayments();
            }
        },
        soaCustomerPaymentDetails() {
            if(!!this.soaCustomerPaymentDetails) {
                const {
                    id, customer_name, due_date,
                    paid_amount, balance_amount, total_amount, gross_amount,
                    discount_amount, addition_amount, deduction_amount,
                    created_at, customer_payment_details, addition_remarks, deduction_remarks,
                    status_text
                } = this.soaCustomerPaymentDetails;
                this.soaNumber = id;
                this.companyName = customer_name;
                this.dueDate = due_date;
                this.grossAmount = gross_amount;
                this.totalAmount = total_amount;
                this.paidAmount = paid_amount;
                this.discountAmount = discount_amount;
                this.additionAmount = addition_amount;
                this.deductionAmount = deduction_amount;
                this.balanceAmount = balance_amount;
                this.date = moment(created_at).format('YYYY-MM-DD, hh:mm:ss a');
                this.deductionRemarks = deduction_remarks;
                this.additionRemarks = addition_remarks;
                this.status = status_text;
                this.customerPaymentDetails = customer_payment_details;
            }
        },
        customerPaymentAllocations() {
            if(this.customerPaymentAllocations.length > 0) {
                this.paymentAllocationLoading = false;
            } else {
                this.paymentAllocationLoading = false;
                this.noDataText = "No Data";
            }
        }
    },
    methods: {
        clearFields() {
            this.statementOfAccountId = '';
            this.soaNumber = '';
            this.companyName = '';
            this.dueDate = '';
            this.grossAmount = '';
            this.totalAmount = '';
            this.paidAmount = '';
            this.discountAmount = '';
            this.additionAmount = '';
            this.deductionAmount = '';
            this.balanceAmount = '';
            this.date = '';
            this.deductionRemarks = '';
            this.additionRemarks = '';
            this.status = '';
            this.customerPaymentDetails = [];
            this.soaCustomerPaymentDetails = null;
        },
        custSoaPaymentDetailClose() {
            this.$emit('closeView', true);
            this.$store.commit('custSoaPaymentDetailClose');
        },
        getSoaCustomerPayments() {
            
            this.$store.dispatch('getSoaCustomerPayments',this.soaId).then(response=>{
                this.soaCustomerPaymentDetails = response.data;
            })
        },
        formatDate(date) {
            if(!!date) {
                return moment(date).format('YYYY-MM-DD, hh:mm:ss a');
            }
            return '';
        },
        viewPaymentAllocations(item) {
            this.selectedRefNumber = item.reference_number;
            this.paymentAllocationLoading = true;
            this.paymentAllocationsDialog = true;
            let payload = {
                params:{
                    soaId: this.soaId,
                    customerPaymentDetailId: item.id
                }
            }
            this.$store.dispatch('getSoaCustomerPayments',payload).then(response=>{
                this.customerPaymentAllocations = response.data;
            });
        },
        closePaymentAllocationsDialog() {
            this.paymentAllocationsDialog = false;
            this.customerPaymentAllocations = [];
            this.selectedRefNumber = '';
        }
    }
}
</script>

<style>
</style>
