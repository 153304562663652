import { render, staticRenderFns } from "./ItemsViewComponent.vue?vue&type=template&id=616ec002"
import script from "./ItemsViewComponent.vue?vue&type=script&lang=js"
export * from "./ItemsViewComponent.vue?vue&type=script&lang=js"
import style0 from "./ItemsViewComponent.vue?vue&type=style&index=0&id=616ec002&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports