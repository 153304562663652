<template>
<div class="w-100 p-1">
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>

    <v-app id="my_vue_app">
        <!-- <v-row class="pa-1" v-show="loaded">
            <v-spacer></v-spacer>
            <v-col cols="4" sm="4" md="2" lg="2" xl="2">
                <v-btn style="float:right" class="ma-2" @click="showMassPrinting()">Mass Print</v-btn>
            </v-col>
        </v-row> -->
        <v-card v-show="loaded" elevation="4">
            <div class="row mx-auto mt-1">
                <div class="col-sm-12 col-md-2">
                    <v-autocomplete :items="customers_list"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection || disabled_customer_selection"
                        id="customer_id"
                        v-model="customer_id"
                        item-value="value"
                        item-text="text"
                        label="Customer"
                        class="w-100"
                        outlined
                        dense
                    >
                    </v-autocomplete>
                </div>
                <div v-if="!service" class="col-sm-12 col-md-2">
                    <v-autocomplete
                        :items="salesman_list"
                        :loading="loading.salesman_selection"
                        :disabled="loading.salesman_selection"
                        id="salesman_id"
                        v-model="salesman_id"
                        item-value="value"
                        item-text="text"
                        label="Salesman"
                        class="w-100"
                        outlined
                        dense
                    >
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-2">
                    <v-text-field  class="w-100" outlined dense label="Issuer" v-model="prepared_by" />
                </div>
                <div class="col-sm-12 col-md-3">
                    <v-autocomplete
                        v-model="filter_statuses"
                        :items="statusOptions"
                        item-value="id"
                        item-color="color"
                        clearable
                        deletable-chips
                        multiple
                        dense
                        outlined
                        small-chips
                        label="Select Status"
                        @input="getAllPackingLists()"
                        @click:clear="getAllPackingLists()"
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip
                                x-small
                                :color="item.color"
                                :text-color="item.textColor"
                                close
                                class="my-1"
                            >
                                {{ item.text }}
                            </v-chip>
                        </template>
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-1">
                    <v-btn @click="getAllPackingLists()">Filter</v-btn>
                </div>
                <v-spacer></v-spacer>
            </div>
            <div class="row mx-auto">
                 <div class="col-sm-12 col-md-2">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_range[0]"
                                v-on="on"
                                label="Date From"
                                append-icon="mdi-calendar-clock"
                                dense
                                outlined
                                hide-details
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_range[0]" @input="menu = false"></v-date-picker>
                    </v-menu>
                </div>
                <div class="col-sm-12 col-md-2">
                     <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_range[1]"
                                v-on="on"
                                label="Date To"
                                append-icon="mdi-calendar-clock"
                                dense
                                outlined
                                hide-details
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_range[1]" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </div>
                <v-spacer></v-spacer>
                <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                    <v-text-field
                        id="searchBar"
                        v-model="search"
                        label="Search DR# / SO#"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        @keydown.enter="searchInput()"
                        @click:append="searchInput()"
                    ></v-text-field>
                </div>
            </div>

            <v-container fluid>
                <v-data-table
                    v-if="is_stockcard==0"
                    v-model="dataTablePl"
                    :headers="headers"
                    :items="packingLists"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalPackingLists"
                    :items-per-page="10"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions,
                        showFirstLastPage: true,
                    }"
                    :show-select="(filter_statuses.length == 1 && [0, 5, 6].includes(filter_statuses[0])) || ['is_pending', 'is_for_approval', 'is_for_confirm'].includes(tab_name)"
                    class="w-100"
                    show-expand
                    disable-sort
                    @input="getSelectedID"
                >
                    <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                        <v-simple-checkbox
                            :value="isSelected"
                            :disabled="(filter_statuses.length == 1 && [5].includes(filter_statuses[0])) && !item.printed"
                            @input="select($event)"
                        ></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.packing_list_date`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.packing_list_date | formatDate }}
                        </span>
                    </template>

                    <template v-slot:[`item.customer_name`]="{ item }">
                        <span style="font-size:10px !important;">
                            {{ item.customer_name  }}
                        </span>
                    </template>

                    <template v-slot:[`item.served_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.served_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.stock_card`]="{ item }">
                        <span class="badge badge-primary" v-if="item.stock_card">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.created_at | formatDateTime }}
                        </span>
                    </template>

                    <template v-slot:[`item.promo_id`]="{ item }">
                        <span class="badge badge-primary" v-if="item.promo_id != null">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.printed`]="{ item }">
                        <span class="badge badge-primary" v-if="item.printed">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <!-- <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                        <span class="badge badge-submitted" v-if="item.status_text=='submitted'">{{ item.status_text }}</span>
                        <span class="badge badge-purple" v-else-if="item.status_text=='confirmed'">{{ item.status_text }}</span>
                        <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                        <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                        <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                        <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                        <span class="badge badge-for-accounting" v-if="item.status_text == 'for approval'">{{ item.status_text }}</span> -->
                        <!-- <span class="badge badge-approved" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span> -->
                    <!-- </template> -->
                    <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status==0">pending</span>
                        <span class="badge badge-submitted" v-if="item.status==5">submitted</span>
                        <span class="badge badge-purple" v-else-if="item.status==1">confirmed</span>
                        <span class="badge badge-warning" v-else-if="item.status=='rejected'">{{ item.status_text }}</span>
                        <span class="badge badge-danger" v-else-if="item.status==3">cancelled</span>
                        <span class="badge badge-dark" v-else-if="item.status=='deleted'">{{ item.status_text }}</span>
                        <span class="badge badge-success" v-else-if="item.status=='completed'">{{ item.status_text }}</span>
                        <span class="badge badge-for-accounting" v-if="item.status==6">for approval</span>
                        <!-- <span class="badge badge-approved" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span> -->
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewPackingList(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0 && is_stockcard == 0" v-show="!is_preparation && !is_accounting">
                                <v-icon class="btn-action" small @click="editPackingList(item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete && !is_preparation && !is_accounting">
                                <v-icon class="btn-action" small @click="initiateOverride('delete', item.id, true)">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                            <v-btn text icon color="red" v-if="userAccess.cancel && !is_preparation && !is_accounting && item.status != 3">
                                <v-icon class="btn-action" small @click="initiateOverride('cancel', item.id, true)">mdi-cancel</v-icon>
                            </v-btn>
                        </span>
                    </template>

                </v-data-table>

                <v-data-table
                    :headers="headers_SC"
                    :items="packingLists"
                    :search="search"
                    :options.sync="options"
                    :server-items-length="totalPackingLists"
                    :items-per-page="10"
                    :footer-props="{
                        itemsPerPageOptions: itemsPerPageOptions
                    }"
                    class="w-100"
                    v-else
                >

                    <template v-slot:[`item.packing_list_date`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.packing_list_date | formatDate }}
                        </span>
                    </template>

                    <template v-slot:[`item.customer_name`]="{ item }">
                        <span style="font-size:10px !important;">
                            {{ item.customer_name  }}
                        </span>
                    </template>

                    <template v-slot:[`item.served_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.served_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span class="text-nowrap">
                            {{ item.created_at | formatDateTime }}
                        </span>
                    </template>

                    <template v-slot:[`item.printed`]="{ item }">
                        <span class="badge badge-primary" v-if="item.printed">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                    </template>

                    <template v-slot:[`item.status_text`]="{ item }">
                        <span class="badge badge-saved" v-if="item.status_text=='pending'">{{ item.status_text }}</span>
                        <span class="badge badge-submitted" v-if="item.status_text=='submitted'">{{ item.status_text }}</span>
                        <span class="badge badge-purple" v-else-if="item.status_text=='confirmed'">{{ item.status_text }}</span>
                        <span class="badge badge-warning" v-else-if="item.status_text=='rejected'">{{ item.status_text }}</span>
                        <span class="badge badge-danger" v-else-if="item.status_text=='canceled'">{{ item.status_text }}</span>
                        <span class="badge badge-dark" v-else-if="item.status_text=='deleted'">{{ item.status_text }}</span>
                        <span class="badge badge-success" v-else-if="item.status_text=='completed'">{{ item.status_text }}</span>
                        <span class="badge badge-for-accounting" v-if="item.status_text == 'for approval'">{{ item.status_text }}</span>
                        <!-- <span class="badge badge-approved" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span> -->
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewPackingList(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0 && is_stockcard == '0'" v-show="!is_preparation && !is_accounting">
                                <v-icon class="btn-action" small @click="editPackingList(item.id)">mdi-pencil</v-icon>
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete && !is_preparation && !is_accounting && is_stockcard == '0'">
                                <v-icon class="btn-action" small @click="currentPackingListId=item.id; override.approve_override_delete_dialog=true">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </span>
                    </template>

                </v-data-table>

                <div v-if="convertBtn && (filter_statuses.length == 1 || (['is_pending', 'is_for_approval', 'is_for_confirm'].includes(tab_name) && selected_id.length)) && !is_stockcard" class="table-footer-prepend d-flex pl-2 align-center" style="margin-top: -52px; height: 58px">
                    <span class="m-1" v-if="filter_statuses[0] == 0 || tab_name == 'is_pending'">
                        <v-btn :loading="btn_loader" @click="updatePLStatus(5)">Mass Submit</v-btn>
                    </span>
                    <span class="m-1" v-if="filter_statuses[0] == 6 || tab_name == 'is_for_approval'">
                        <v-btn :loading="btn_loader" @click="updatePLStatus(5)">Mass Approval</v-btn>
                    </span>
                    <span class="m-1" v-if="userAccess.confirm && (filter_statuses[0] == 5 || tab_name == 'is_for_confirm')">
                        <v-btn :loading="btn_loader" @click="updatePLStatus(1)">Mass Confirm</v-btn>
                    </span>
                </div>
            </v-container>
        </v-card>
        <PackingListsViewComponentVue
            :viewPackingListId="viewPackingListId"
            :is_stockcard="is_stockcard" @closeView="closeView"
            :is_preparation="is_preparation"
            :unconfirmed="unconfirmed"
            :service="service"
            :fromSoa="fromSoa"
        ></PackingListsViewComponentVue>

    <ConfirmOverrideComponentVue
        :approve_override_dialog="override.dialog"
        :departments_allowed="override.departments_allowed"
        :heading="override.heading"
        @closeConfirmOverride="closeConfirmOverride"
    ></ConfirmOverrideComponentVue>

    <PackingListsPrintingComponentVue
        :showDialog="show_mass_printing_dialog"
        :customers="customers_list"
        :service="service"
        @closeMassPrinting="closeMassPrinting"
    ></PackingListsPrintingComponentVue>
    </v-app>

    <!-- <confirm-override-component
        :approve_override_dialog="override.approve_override_delete_dialog"
        :departments_allowed="override.override_departments_allowed"
        :heading="override.override_heading"
        @closeConfirmOverride="closeConfirmOverride"
    ></confirm-override-component> -->
</div>
</template>

<script>
import PackingListsViewComponentVue from './PackingListsViewComponent.vue';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
// import ConfirmOverrideComponent from '../Utils/ConfirmOverrideComponent.vue';
import PackingListsPrintingComponentVue from './PackingListsPrintingComponent.vue';
// import PackingListsPrintingComponent from './PackingListsPrintingComponent.vue'
import moment from "moment";
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
    components: {
        PackingListsViewComponentVue,
        ConfirmOverrideComponentVue,
        PackingListsPrintingComponentVue,
    },
    props: [
        'packingListsActionResult',
        'tab_name',
        'is_preparation',
        'unconfirmed',
        'is_stockcard',
        'is_accounting',
        'service',
        'fromSoa'
    ],
    data () {
        return {
            override: {
                dialog: false,
                heading: 'Delivery Receipt',
                departments_allowed: ['AC', 'EX', 'IT'],
                authenticated: false,
                action: '',
            },
            reason: {
                text: '',
            },
            actionResult : {
                model : 'PackingLists',
                action : ''
            },
            loaded: false,
            totalPackingLists: 0,
            options: {},
            packingLists: [],
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            menu: false,
            menu2: false,
            date_range: [
                moment().clone().startOf('month').format('YYYY-MM-DD'),
                moment().clone().endOf('month').format('YYYY-MM-DD')
            ],
            awaitingSearch: false,
            headers: [
                { text: 'DR#', align: 'left', value: 'packing_list_num', },
                // { text: 'CF Num', align: 'left', value: 'checker_form_id', },
                { text: 'SO#', align: 'left', value: 'order_num', },
                { text: 'Warehouse', align: 'left', value: 'warehouse_code', },
                { text: 'DR Date', align: 'left', value: 'packing_list_date', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                // { text: 'Served Amount', align: 'right', value: 'served_amount', },
                { text: 'SC', align: 'center', value: 'stock_card' },
                { text: 'Total Amount', align: 'right', value: 'total_amount', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'issuer_name', },
                { text: 'Promo', align: 'left', value: 'promo_id', },
                { text: 'Printed', align: 'left', value: 'printed', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],
            preparationHeaders:  [
                { text: 'DR Num', align: 'left', value: 'packing_list_num', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'issuer_name', },
                { text: 'Promo', align: 'left', value: 'promo_id', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],
            headers_SC: [
                { text: 'DR Num', align: 'left', value: 'packing_list_num', },
                { text: 'SO Num', align: 'left', value: 'order_num', },
                { text: 'DR Date', align: 'left', value: 'packing_list_date', },
                { text: 'Customer', align: 'left', value: 'customer_name', },
                { text: 'Salesman', align: 'left', value: 'salesman_name', },
                // { text: 'Served Amount', align: 'right', value: 'served_amount', },
                { text: 'Status', align: 'left', value: 'status_text', },
                { text: 'Date Issued', align: 'left', value: 'created_at', },
                { text: 'Issuer', align: 'left', value: 'prepared_by', },
                { text: 'Promo', align: 'left', value: 'promo_id', },
                { text: 'Printed', align: 'left', value: 'printed', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewPackingListId: '',
            currentPackingListId: '',

            customers_list: [],
            customer_id: '',
            salesman_list: [],
            salesman_id: '',
            status_id: -1,
            prepared_by: '',

            dataTablePl: [],
            selected_id: [],
            filter_statuses: [],
            convertBtn: false,
            btn_loader: false,
            statusOptions: [
                { id: 0, text: 'Pending', color: 'badge-saved', textColor: 'white' },
                { id: 5, text: 'Submitted', color: 'badge-submitted', textColor: 'white' },
                { id: 1, text: 'Confirmed', color: 'badge-purple', textColor: 'white' },
                { id: 3, text: 'Cancelled', color: 'badge-danger', textColor: 'white' },
                { id: 6, text: 'For Approval', color: 'badge-for-accounting', textColor: 'white' },
            ],

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                cancel: false,
                confirm: false,
            },
            isSalesman: false,
            salesmanTitle: '',
            salesmanChildrenIds: [],
            userChildrenIds: [],
            salesmanDetails: null,
            isSalesCoordinator: false,
            currentSalesmanId: '',

            show_mass_printing_dialog: false,
            loading:{
                customer_selection : true,
                salesman_selection : true
            },
            disabled_customer_selection:false
        }
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_SALESMAN_SELECTION',
        ])
    },
    mounted(){
        this.getCustomers()
        this.getSalesmanList()

        if(this.is_preparation){
            this.headers = this.preparationHeaders
        }

        this.getCurrentSalesman();
        this.checkAccess();
        if(this.fromSoa){
            this.customer_id = 275
            this.disabled_customer_selection = true
            this.status_id = 1
        }
    },
    methods: {
        getSelectedID(val) {
            this.selected_id = val.map(function (e) {
                return e.id;
            });

            if (this.filter_statuses.length == 1 || ['is_pending', 'is_for_approval', 'is_for_confirm'].includes(this.tab_name)) {
                if (this.dataTablePl.length > 0) {
                    this.convertBtn = true;
                } else {
                    this.convertBtn = false;
                }
            } else if (this.filter_statuses.length > 1) {
                swal.fire("Filter one status only!", "", "warning");
                const index = this.dataTablePl.indexOf(this.dataTablePl);
                this.dataTablePl.splice(index, 1);
                this.convertBtn = false;
                return false;
            }
        },
        async updatePLStatus(newStatus) {
            this.btn_loader = true;
            let titleMsg = "Are you sure you want to update selected DRs";
            await swal.fire({
                title: titleMsg,
                text: '',
                icon: "warning",
                showConfirmButton: true,
                confirmButtonText: "Confirm",
                reverseButtons: true,
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then(async (willUpdate) => {
                if (willUpdate.isConfirmed) {
                    await this.updatePackingListStatus(newStatus)
                    this.selected_id = []
                } else {
                    this.btn_loader = false;
                }
            });
        },
        async updatePackingListStatus(newStatus){
            this.errors = []
            let ids = this.selected_id
            let length = this.selected_id.length - 1;

            ids.forEach((id, index) => {
                let payload = {
                    status: newStatus,
                    url: 'updatePackingListStatus/'+id
                }

                this.$store.dispatch('postPL',payload).then(response => {
                    let data = response.data

                    if (index == length) {
                        swal.fire("", data.msg, "success");
                        this.getAllPackingLists()
                        this.btn_loader = false
                    }
                })
            })
        },
        showMassPrinting(){
            this.show_mass_printing_dialog = true
        },
        closeMassPrinting(){
            this.show_mass_printing_dialog = false
        },
        initiateOverride(action = null, id, dialog_status){
            switch (action) {
                case 'delete':
                    this.override.heading = 'Delete Delivery Receipt'
                    this.override.departments_allowed = ['AC', 'EX', 'IT']
                    break
                case 'cancel':
                    this.override.heading = 'Cancel Delivery Receipt'
                    this.override.departments_allowed = ['AC', 'EX', 'IT']
                    break
            }

            this.override.dialog = dialog_status
            this.override.action = action
            this.currentPackingListId = id
        },
        closeConfirmOverride(user_id, authenticated = false){
            this.override.dialog = false
            if (authenticated) {
                this.override.authenticated = true
                switch (this.override.action) {
                    case 'delete':
                        this.deletePackingList(this.currentPackingListId)
                        break
                    case 'cancel':
                        this.cancelPackingList(this.currentPackingListId)
                        break
                }
            }
        },
        getAllPackingLists(){
            this.loaded = false
            let payload = {
                page:                this.options.page,
                itemsPerPage:        this.options.itemsPerPage,
                sortBy:              this.options.sortBy,
                sortDesc:            this.options.sortDesc,
                search:              this.search,
                tab_name:            this.service=="1"?"":this.tab_name,
                unconfirmed:         this.unconfirmed,
                is_stockcard:        this.is_stockcard,
                status_filter:       this.status_id,
                statuses:            this.filter_statuses,
                customer_id:         this.customer_id,
                salesman_id:         this.salesman_id,
                prepared_by:         this.prepared_by,
                service:             this.service,
                url:                 'getAll',
                fromSoa:             !!this.fromSoa ? this.fromSoa : '',
                packing_list_dates:  this.date_range
            }

            this.$store.dispatch('getPL',payload).then(response => {
                this.packingLists        = response.data.data.data
                this.loaded              = true
                this.totalPackingLists   = response.data.data.total
            })
        },
        viewPackingList(id){
            this.viewPackingListId = id
        },
        editPackingList(id){
            this.$emit('showEditModal', id);
        },
        deletePackingList(id){
            if(this.override.override_authenticated){
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Delete?",
                    icon: "warning",
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            url:   'packingLists/'+id
                        }
                        this.$store.dispatch('deletePL',payload).then(response => {
                            if (response.data.status == 'success') {
                                this.resetFields()
                                this.getAllPackingLists()
                            }

                            swal.fire(response.data.msg, {icon: response.data.status,});
                        }).catch(error => {
                            this.resetFields()
                            swal.fire("An Error Occured", error, "error");
                        })
                    }else{
                        this.resetFields()
                    }
                });
            }
        },
        cancelPackingList(id){
            this.errors = []
            this.status_action_text = 'cancel'

            swal.fire({
                title: "",
                text: "Are you sure you want to " + this.status_action_text + " Delivery Receipt?",
                icon: "warning",
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const { value: cancelReason } = swal.fire({
                        title: 'Cancel Reason Remarks',
                        input: 'text',
                        inputLabel: 'Enter Reason',
                        showConfirmButton:true,
                        confirmButtonColor: '#397373',
                        confirmButtonText:'Confirm',
                        inputValidator: (value) => {
                            if (!value) {
                            return 'You need to write something!'
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed && cancelReason != '') {
                            this.reason.text = cancelReason
                            let payload = {
                                reason: this.reason.text,
                                url:    'cancelPackingList/'+id
                            }
                            this.$store.dispatch('postPL',payload).then(response => {
                                let data = response.data

                                if(data.error){
                                    swal.fire("", data.error, "warning");
                                    return false
                                }

                                swal.fire("", data.msg, "success");

                                this.getAllPackingLists()
                            })
                        }
                    })
                }
            });
        },
        closeView(action = false){
            this.viewPackingListId = 0
            if(action == 'convertSuccess' || action == 'updateStatusSuccess' || action == 'successPayment'){
                this.getAllPackingLists()
            }
        },
        searchInput() {
            this.options.page = 1

            this.getAllPackingLists({
                page:           this.options.page,
                itemsPerPage:   this.options.itemsPerPage,
                sortBy:         this.options.sortBy,
                sortDesc:       this.options.sortDesc,
                search:         this.search,
                customer_id:    this.customer_id,
                salesman_id:    this.salesman_id,
                service:        this.service,
                prepared_by:    this.prepared_by,
                tab_name:       this.tab_name,
            });
        },
        getCustomers() {
            this.$store.dispatch('getAllCustomers').then(response => {
                let lists = this.GET_CUSTOMERS_LIST_SELECTION;
                if(this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if(this.isSalesCoordinator) {
                        let filtered_list = lists.filter(x=> x.sales_coordinator_id == this.currentSalesmanId);
                        this.customers_list = filtered_list;
                    } else {
                        let filtered_list = lists.filter(x=> _.compact(this.salesmanChildrenIds).includes(x.salesman_id));
                        this.customers_list = filtered_list
                    }
                } else {
                    this.customers_list = lists;
                }

                this.loading.customer_selection = false
            })
        },
        getSalesmanList(){
            this.$store.dispatch('getAllsalesmanSelection').then(response => {
                if(this.isSalesman && this.currentSalesmanPositionId != 1) {
                    let lists = this.GET_SALESMAN_SELECTION;
                    if(this.isSalesCoordinator) {
                        let filtered_list = lists.filter(x=> x.value == this.currentSalesmanId);
                        this.salesman_list = filtered_list;
                    } else if (_.compact(this.salesmanChildrenIds).length > 0) {
                        let filtered_list = lists.filter(x=>_.compact(this.salesmanChildrenIds).includes(x.value));
                        this.salesman_list = filtered_list
                    }
                } else {
                    this.salesman_list = response
                }

                this.loading.salesman_selection = false
            });
        },
        getUsers(){
            this.$store.dispatch('getAllUsersSelect').then(response => {
                if(this.isSalesman && this.currentSalesmanPositionId != 1) {
                    if(_.compact(this.salesmanChildrenIds).length > 0) {
                        let lists = response.data;
                        let filtered_list = lists.filter(x=>_.compact(this.userChildrenIds).includes(x.value));
                        this.users_list = filtered_list
                    }
                } else {
                    this.users_list = response.data
                }
            });
        },
        resetFields(){
            this.errors = []
        },
        getCurrentSalesman() {
            this.$store.dispatch('getCurrentSalesman').then(response=>{
                this.salesmanDetails = response.data;
            });
        },
        checkAccess(){
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                let val = response;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.confirm = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                    if (e.actions_code == "cancel"){
                        this.userAccess.cancel = true;
                    }
                    if (e.actions_code == "confirm"){
                        this.userAccess.confirm = true;
                    }
                });
                }
            })
        },
    },
    watch: {
        tab_name: function() {
            this.dataTablePl = []
            this.getAllPackingLists();
        },
        search() {
            if(!this.search && this.service == 1) {
                this.getAllPackingLists();
            }
        },
        packingListsActionResult: function(val){
            if(this.packingListsActionResult=='success'){
                this.getAllPackingLists()
                // this.getCustomers()
                // this.getSalesmanList()
                // this.getUsers()
            }
        },
        options: {
            handler () {
                this.getAllPackingLists()
                // this.getCustomers()
                // this.getSalesmanList()
                // this.getUsers()
            },
            deep: true,
        },
        salesmanDetails() {
            if(!!this.salesmanDetails) {
                this.isSalesman = this.salesmanDetails.isSalesman;
                this.salesmanTitle = this.salesmanDetails.salesmanPositionTitle;
                this.salesmanChildrenIds = this.salesmanDetails.salesmanChildrenIds;
                this.userChildrenIds = this.salesmanDetails.userChildrenIds;

                 if(!!this.salesmanDetails.salesman) {
                    this.currentSalesmanId = this.salesmanDetails.salesman.id;
                    this.isSalesCoordinator = !!this.salesmanDetails.salesman.sales_coordinator;
                    this.currentSalesmanPositionId = this.salesmanDetails.salesman.salesman_position_id;
                }
            }
        },
        USER_ACCESS: {
            handler(val) {

            }
        },
        /*search: {
            handler () {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getAllPackingLists({ query: this.search });
                    this.awaitingSearch = false;
                }, 2000); // 1 sec delay
            }
                this.awaitingSearch = true;
            },
            deep: true,
        },*/
    }
};
</script>
