<template>
  <v-container class="ma-0 pa-2">
    <v-card-title>
      <v-row>
        <v-col md="16" class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">Inbound</h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-row>
      <v-col md="16" class="d-flex no-block align-items-center">
        <v-radio-group
          color="red"
          row
          v-model="radioGroup"
          class="small-radio ml-3"
        >
          <v-radio value="" color="success">
            <template v-slot:label>
              <strong>All</strong>
            </template>
          </v-radio>
          <v-radio value="!=" color="success">
            <template v-slot:label>
              <strong>Exclude SP</strong>
            </template>
          </v-radio>
          <v-radio value="=" color="success">
            <template v-slot:label>
              <strong>SP only</strong>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-container class="ma-0 pa-1">
      <v-app id="item-tabs" class="ma-0">
        <v-tabs
            v-model="tab.main"
            id="group-tab"
            color="cyan"
            dark
            slider-color="cyan"
            background-color="#424242"
            class="col-12"
            show-arrows
        >
        <v-tab ripple v-if="ACCESS_INBOUND.includes('inbound')">LPI</v-tab>
        <v-tabs-items v-model="tab.main">
            <v-tab-item  v-if="ACCESS_INBOUND.includes('inbound')">
                <v-tabs
                    v-model="tab.inbound"
                >
                    <v-tab ripple>All </v-tab>
                    <v-tab ripple>Pending </v-tab>
                    <v-tab ripple>Receive </v-tab>
                    <v-tab ripple>Approved </v-tab>

                    <v-tabs-items v-model="tab.inbound" touchless>
                    <!-- ALL INBOUND TAB -->
                    <v-tab-item>
                        <InboundComponentVue
                            :radioGroup="radioGroup"
                        ></InboundComponentVue>
                    </v-tab-item>

                    <!-- SAVED INBOUND TAB -->
                    <v-tab-item>
                        <InboundComponentVue
                            :is_pending="true"
                            :radioGroup="radioGroup"
                        ></InboundComponentVue>
                    </v-tab-item>

                    <!-- RECEIVE INBOUND TAB -->
                    <v-tab-item>
                        <InboundComponentVue
                            :is_receiving="true"
                            :radioGroup="radioGroup"
                        ></InboundComponentVue>
                    </v-tab-item>

                    <!-- APPROVED INBOUND TAB -->
                    <v-tab-item>
                        <InboundComponentVue
                            :is_approved="true"
                            :radioGroup="radioGroup"
                        ></InboundComponentVue>
                    </v-tab-item>
                </v-tabs-items>
                </v-tabs>
            </v-tab-item>
        </v-tabs-items>

    </v-tabs>

      </v-app>
    </v-container>
  </v-container>
</template>
<script>
import InboundComponentVue from "./InboundComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    InboundComponentVue,
  },
  data() {
    return {
      tab: {
        inbound: null,
        main:null
      },
      radioGroup: "0",
      filters:'0',
      access:[]

    };
  },
  mounted() {
        this.getStatusFromUrl();
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch("getWarehouseItemStocks");
        this.$store.dispatch("getAllWarehouses", {});
        this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'ACCESS_INBOUND',
            'SELECTED_FILTER'
        ])
    },
    methods: {
        getStatusFromUrl() {
            if (document.location.href.includes("search-model=")) {
                let status = document.location.href.split("status=");
                status = status[1].split("&");
                this.tab = parseInt(status[0]);
            }
        },
    },
    watch:{
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    val.forEach((e) => {
                        let temp = []
                        temp = val.map(e=>e.actions_code)
                        this.$store.commit('ACCESS_INBOUND',temp)
                    });
                }
            }
        },
        radioGroup:{
            handler(val){
                this.$store.commit('SELECTED_FILTER',val);
            }
        }
    }
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
.tab-bg {
    background-color: gray;
}
.small-radio i {
    font-size: 19px;
}
.small-radio label {
    font-size: 14px;
    padding-left: 0px;
    margin-left: -4px;
}
.small-radio .v-radio {
    padding: 0px;
}
.small-radio [class*="__ripple"] {
    left: 0;
}
</style>
