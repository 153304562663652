import Containers from '@/views/main/modules/Operations/Containers/ContainersGroupComponent.vue'
import repository from '@/api/credentials'

const routes = [{
    path: '/operations/containers/:id',
    name: 'warehousecontainer',
    component: Containers,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Containers',
    },
}]

export default routes;
