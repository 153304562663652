<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar">
                        <v-btn class="ml-1 mb-2" @click="showAddItemCostingSize()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-row class="m-2">
                                <v-spacer></v-spacer>
                                <v-col sm="12" md="4" class="mr-3 align-items-end">
                                    <v-text-field v-model="search" label="Search"
                                        single-line hide-details append-icon="mdi-magnify"
                                        @click:append="getAllItemCostingSizes()"
                                        @keydown.enter="getAllItemCostingSizes()">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        :headers="headers"
                                        :items="GET_ALL_ITEM_COSTING_SIZE"
                                        :search="search"
                                        :items-per-page="10"
                                        :loading="loading"
                                        class="w-100"
                                    >
                                        <template v-slot:[`item.action`]="{ item }">
                                            <v-btn text icon color="blue">
                                                <v-icon class="btn-action" small @click="editItemCostingSize(item.id)">mdi-square-edit-outline</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="red">
                                                <v-icon class="btn-action" small @click="deleteItemCostingSize(item.id)">mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>

            <v-dialog v-model="add_edit_item_costing_size_dialog" persistent max-width="35%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Item Costing Size Information</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="add_edit_item_costing_size_dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text>
                        <v-row class="mt-2">
                            <v-col sm="12" md="12">
                                <div>
                                    <v-text-field v-model="code" dense label="Code" outlined />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12">
                                <div>
                                    <v-text-field v-model="name" dense label="Name" outlined />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="">
                            <v-col sm="12" md="12">
                                <div>
                                    <v-text-field @keypress="decimalOnly" v-model="freight_percentage" dense outlined
                                        label="Freight %" />
                                </div>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn @click="addNewItemCostingSize()" v-if="action == 'Add'">Submit</v-btn>
                            <v-btn @click="updateItemCostingSize()" v-else-if="action == 'Edit'">Update</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </div>


</template>

<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    props: [
        'refreshData',
    ],
    data() {
        return {
            editItemCostingSizeId: '',
            add_edit_item_costing_size_dialog: false,
            code: null,
            name: null,
            freight_percentage: null,
            search: null,
            headers: [
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Code', align: 'left', value: 'code', },
                { text: 'Freight %', align: 'left', value: 'freight_percentage', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],
            itemCostingSizes: [],
            itemsPerPageOptions: [5, 10 ,15],
            itemsPerPage: 10,
            errors: [],
            loading: false,
            action: '',
            actionResult: {
                model: 'ItemCostingSize',
                action: ''
            },
        }
    },
    mounted(){
        this.getAllItemCostingSizes()
    },
    computed: {
        ...mapGetters([
            'GET_ALL_ITEM_COSTING_SIZE',
            'GET_SHOW_ITEM_COSTING_SIZE'
        ])
    },
    methods: {
        getAllItemCostingSizes(){
            this.loading = true

            this.$store.dispatch('getAllItemCostingSizes').then(response => {
                this.itemCostingSizes = this.GET_ALL_ITEM_COSTING_SIZES
                this.loading = false
            })
        },
        showAddItemCostingSize() {
            this.add_edit_item_costing_size_dialog = true
            this.action = 'Add'
        },
        addNewItemCostingSize() {
            this.errors = []
            let payload = {
                code: this.code,
                name: this.name,
                freight_percentage: this.freight_percentage
            }
            this.$store.dispatch('storeItemCostingSize', payload).then(response => {
                this.resetFields()
                this.add_edit_item_costing_size_dialog = false
                this.action = 'success'
                swal.fire("", "Item Costing Size Created Succesfully", "success");
                this.getAllItemCostingSizes()
            }).catch(error => {
                swal.fire("", "Item Costing Size Created Failed", "error");
            });
        },
        editItemCostingSize(value) {
            this.action = 'Edit'
            this.editItemCostingSizeId = value

            if (this.editItemCostingSizeId != null && this.editItemCostingSizeId > 0) {
                this.$store.dispatch('showItemCostingSize', this.editItemCostingSizeId).then(response => {
                    let data = this.GET_SHOW_ITEM_COSTING_SIZE
                    this.code = data.code
                    this.name = data.name
                    this.freight_percentage = data.freight_percentage
                    this.add_edit_item_costing_size_dialog = true
                }).catch(error => {
                    swal.fire("", "Failed to load", "error");
                });
            }
        },
        updateItemCostingSize() {
            this.errors = []
            let payload = {
                id: this.editItemCostingSizeId,
                code: this.code,
                name: this.name,
                freight_percentage: this.freight_percentage
            }
            this.$store.dispatch('updateItemCostingSize', payload).then(response => {
                this.resetFields()
                this.add_edit_item_costing_size_dialog = false
                this.editItemCostingSizeId = 0
                this.action = 'success'
                swal.fire("", response.data.msg, "success");
            }).catch(error => {
                swal.fire("", error.response.data.message, "error");
                this.action = 'Edit'
            });
        },
        deleteItemCostingSize(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('destroyItemSizes',id).then(response => {
                        this.getAllItemCostingSizes()
                        swal.fire('',response.data,'success');
                    }).catch(error => {
                        swal.fire("An Error Occured", error, "error");
                    })
                }
            });
        },
        resetFields() {
            this.errors = []
            this.code = null
            this.name = null
            this.freight_percentage = null

        },
    },
    watch: {
        'refreshData': {
            handler(val) {
                if (val) {
                    this.getAllItemCostingSizes()
                }
            }
        }
    }
};
</script>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
}
</style>
