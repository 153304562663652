<template>
    <div>
        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="''"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>
        <MemoViewComponentVue
            :view_memo_details="viewMemoDetails"
            :memo_type="memoType"
        ></MemoViewComponentVue>
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>
        <v-app id="view_client_modal">
            <v-dialog v-model="statementOfAccount_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Statement Of Account Details</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                class="float-right"
                                color="gray"
                                icon
                                text
                                @click="clearStatementOfAccountItems(); $emit('closeView');"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row class="m-0 my-6">
                            <v-container fluid>
                                <v-row>
                                    <v-col sm="12" md="8" class="text-left text-dark">
                                        <v-row class="ml-2">
                                            <v-col sm="12" md="9" class="p-0">SOA ID#: {{ soa_id }}</v-col>
                                            <v-col sm="12" md="3" class="p-0">Terms: {{ terms }}</v-col>
                                        </v-row>
                                        <v-row class="ml-2">
                                            <v-col sm="12" md="9" class="p-0">Customer: {{ customer_name }}</v-col>
                                            <v-col sm="12" md="3" class="p-0">Due Date: {{ due_date | formatMonth }}</v-col>
                                        </v-row>
                                        <v-row class="ml-2">
                                            <v-col sm="12" md="9" class="p-0">Remarks: {{ remarks }}</v-col>
                                            <v-col sm="12" md="3" class="p-0">Status: {{ status_text }}</v-col>
                                        </v-row>
                                        <v-row class="ml-2">
                                            <v-col class="p-0">Payment Method: {{ payment_method_text }}</v-col>
                                            <v-col cols="3" class="p-0">Payment Date: {{ payment_date | formatDate }}</v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col sm="12" md="4" class="pull-right text-right">
                                        <div class="mb-2"><v-btn @click="renderPDF('download')">Download</v-btn></div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>

                        <v-card :style="soaItemCardTableStyle()" class="pa-4 mb-4">
                            <v-data-table
                                :headers="soa_itemsHeaders"
                                :items="soa_items"
                                :items-per-page="500"
                                class="w-100"
                                item-key="packing_list_id"
                                hide-default-footer
                                show-expand
                                single-expand
                                disable-pagination
                                @item-expanded="loadDetails"
                            >
                                <template v-slot:[`item.final_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.final_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:expanded-item="{ headers }">
                                    <td :colspan="headers.length" class="p-0">
                                        <v-data-table
                                            :headers="plItemsHeaders"
                                            :items="expandedItems"
                                            :loading="loading"
                                            dense
                                            dark
                                            hide-default-footer
                                            fixed-header
                                        >
                                            <template v-slot:[`item.amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.total_amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.foc`]="{ item }">
                                                <span class="text-nowrap">
                                                    {{ item.foc == 0 ? 'No' : 'Yes' }}
                                                </span>
                                            </template>
                                        </v-data-table>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-card class="pa-4">
                            <div>Payments</div>
                            <v-data-table
                                :headers="paymentHeaders"
                                :items="payments"
                            >
                                <template v-slot:[`item.allocated_wht`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.allocated_wht | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.allocated_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.allocated_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1; {{ item.total_amount | currency }}
                                    </span>
                                </template>
                                <template v-slot:[`item.created_at`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ date(item) }}
                                    </span>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <span class="text-nowrap">
                                        <v-btn
                                            color="orange"
                                            icon
                                            text
                                            x-small
                                            @click="viewCustomerPayment(item.cpd_id)"
                                        >
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="item.payment_type_name == 'Cheque' && userAccess.move_cheque_date"
                                            color="primary"
                                            icon
                                            text
                                            x-small
                                            @click="editCustomerPayment(item.cpd_id)"
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn
                                            v-if="item.status != 6 && userAccess.cancel_payment"
                                            color="red"
                                            icon
                                            text
                                            x-small
                                            @click="cancelPaymentAllocation(item.id, item.cpd_id)"
                                        >
                                            <v-icon>mdi-cancel</v-icon>
                                        </v-btn>
                                    </span>
                                </template>
                            </v-data-table>
                        </v-card>
                        <v-card class="pa-4 mt-4" v-if="credit_memos.length != 0 || debit_memos.length != 0">
                            <v-row class="pa-2">
                                <v-col cols="6">
                                    Credit Memo
                                    <v-card class="ma-1">
                                        <v-data-table
                                            :items="credit_memos"
                                            :headers="dm_cm_headers.credit_memo"
                                            dense
                                        >
                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.total_amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.action`]="{ item }">
                                                <span class="text-nowrap">
                                                    <v-btn
                                                        color="orange"
                                                        icon
                                                        text
                                                        x-small
                                                        @click="viewMemo('Credit', item)"
                                                    >
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-btn>
                                                </span>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                                <v-col cols="6">
                                    Debit Memo
                                    <v-card class="ma-1">
                                        <v-data-table
                                            dense
                                            :items="debit_memos"
                                            :headers="dm_cm_headers.debit_memo"
                                        >
                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.total_amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.action`]="{ item }">
                                                <span class="text-nowrap">
                                                    <v-btn
                                                        color="orange"
                                                        icon
                                                        text
                                                        x-small
                                                        @click="viewMemo('Debit', item)"
                                                    >
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-btn>
                                                </span>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-row class="m-0">
                            <v-container fluid>
                                <v-row>
                                    <v-col sm="12" md="4" class="text-left">
                                        <div class="card-body py-4 d-flex">
                                            <span class="m-1"><v-btn @click="renderPDF('print');">Print</v-btn></span>
                                        </div>
                                    </v-col>
                                    <v-col sm="12" md="4" class="pull-right text-right text-dark mt-3">
                                        DM Remarks:{{ addition_remarks }}

                                        <br>
                                        CM Remarks: {{ deduction_remarks }}

                                    </v-col>
                                    <v-col sm="12" md="4" class="pull-right text-right text-dark mt-3">
                                        Gross Amount: &#x20b1;{{ gross_amount | currency }}
                                        <br>
                                        DM Amount: &#x20b1;{{ addition_amount | currency }}
                                        <br>
                                        CM Amount: &#x20b1;{{ deduction_amount | currency }}
                                        <br>
                                        Discount Amount: &#x20b1;{{ discount_amount | currency }}
                                        <br>
                                        Total Amount: &#x20b1;{{ total_amount | currency }}
                                        <br>
                                        Paid Amount: &#x20b1;{{ paid_amount | currency }}
                                        <br>
                                        Bad Debt Amount: &#x20b1;{{ bad_debt_amount | currency }}
                                        <br>
                                        Balance Amount: &#x20b1;{{ balance_amount | currency }}
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>
                        <v-divider />
                        <div v-if="status_text == 'saved'">
                            <v-layout row>
                                <v-flex lg6>
                                    <div style="text-align:center" class="my-2">
                                        <v-btn @click="updateStatementOfAccountStatus(5)">Confirm</v-btn>
                                    </div>
                                </v-flex>
                                <v-flex lg6>
                                    <div style="text-align:center" class="my-2">
                                        <v-btn @click="updateStatementOfAccountStatus(2)" color="red">Reject</v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div v-else-if="!['saved', 'canceled', 'deleted', 'rejected'].includes(status_text) && (balance_amount > 0 || bad_debt_amount > 0)">
                            <v-layout row>
                                <v-flex :lg6="balance_amount > 0 && bad_debt_amount > 0" v-if="balance_amount > 0">
                                    <div style="text-align:center" class="my-2">
                                        <v-btn @click="toggleBadDebt('assign')">Mark Balance as Bad Debt</v-btn>
                                    </div>
                                </v-flex>
                                <v-flex :lg6="balance_amount > 0 && bad_debt_amount > 0" v-if="bad_debt_amount > 0">
                                    <div style="text-align:center" class="my-2">
                                        <v-btn @click="toggleBadDebt('recover')">Recover Bad Debt</v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="badDebt.dialog" max-width="20%" persistent>
                <v-card>
                    <v-row class="m-0">
                        <v-col cols="11">
                            <v-card-title>
                                <span class="headline">{{ badDebt.title }}</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="1" class="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="badDebt.dialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="8">
                                <v-text-field
                                    v-model="badDebt.amount"
                                    type="number"
                                    class="centered-input"
                                    outlined
                                    hide-spin-buttons
                                    @keypress="decimalOnly"
                                ></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn @click="confirmBadDebt()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-container fluid style="display:none;">
                <div>
                    <table id="div_logo">
                        <tbody>
                            <tr>
                                <td>
                                    <p><span style="font-size: 18px; font-weight: bold;">D GREAT EAST INDUSTRIAL SUPPLY CORPORATION</span></p>
                                </td>
                            </tr>
                            <tr>
                                <td>420 Del Monte Ave., Brgy Siena, Quezon City<br>Contact us ; Tel. No: (02) 8711-6742 / 7717-4059</td>
                            </tr>
                            <tr>
                                <td>
                                    <p><span style="font-size: 18px; font-weight: bold;">STATEMENT OF ACCOUNT</span></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="soa_date">
                        <tbody>
                            <tr>
                                <td>Due Date:</td>
                                <td>{{ due_date | formatMonth }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="customer_details">
                        <tbody>
                            <tr>
                                <td>Customer:</td>
                                <td>{{ customer_name }}</td>
                            </tr>
                            <tr>
                                <td>Address.</td>
                                <td>{{ customer_address }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-for="(n, x) in pageCount" :key="x">
                    <table :id="'items_details_' + x">
                        <thead>
                            <tr>
                                <th>DR DATE</th>
                                <th>DR NO.</th>
                                <th>REMARKS</th>
                                <th>AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index ) in soa_items_array[x]" :key="index">
                                <td>{{ item.sales_date | formatDate }}</td>
                                <td>{{ item.packing_list_num }}</td>
                                <td>{{ item.remarks }}</td>
                                <td>{{ item.final_amount | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table :id="'total_details_' + x">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <p>TOTAL:</p>
                                </td>
                                <td>
                                    <p>PHP</p>
                                </td>
                                <td>
                                    <p>{{ computedTotalPerPage(x) | currency }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="note_details">
                        <tbody>
                            <tr>
                                <td>If found correct, payment will be made on &nbsp;&nbsp; ________________<br><br>
                                    Make all checks payable to &nbsp;&nbsp;&nbsp; <b>D GREAT EAST INDUSTRIAL SUPPLY CORPORATION</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="grand_total">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <p>SOA GRAND TOTAL:</p>
                                </td>
                                <td>
                                    <p>PHP</p>
                                </td>
                                <td>
                                    <p>{{ gross_amount | currency }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-for="(n, x) in cmPageCount" :key="x">
                    <table :id="'deductions_' + x">
                        <thead>
                            <tr>
                                <th>CM#</th>
                                <th>CM DATE</th>
                                <th>REMARKS</th>
                                <th>AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody v-if="credit_memos.length > 0">
                            <tr v-for="(credit_memo, index) in cm_items_array[x]" :key="index">
                                <td>{{ credit_memo.credit_memo_num }}</td>
                                <td>{{ credit_memo.credit_memo_date }}</td>
                                <td>{{ credit_memo.remarks }}</td>
                                <td>{{ credit_memo.total_amount | currency }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td>{{ }}</td>
                                <td>{{ }}</td>
                                <td>{{ deduction_remarks }}</td>
                                <td>{{ deduction_amount | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-for="(n, x) in dmPageCount" :key="x">
                    <table :id="'additions_' + x">
                        <thead>
                            <tr>
                                <th>DM#</th>
                                <th>DM DATE</th>
                                <th>REMARKS</th>
                                <th>AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody v-if="debit_memos.length > 0">
                            <tr v-for="(debit_memo, index) in dm_items_array[x]" :key="index">
                                <td>{{ debit_memo.debit_memo_num }}</td>
                                <td>{{ debit_memo.debit_memo_date }}</td>
                                <td>{{ debit_memo.remarks }}</td>
                                <td>{{ debit_memo.total_amount | currency }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td>{{ }}</td>
                                <td>{{ }}</td>
                                <td>{{ addition_remarks }}</td>
                                <td>{{ addition_amount | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="soa_total">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <p>TOTAL AMOUNT DUE:</p>
                                </td>
                                <td>
                                    <p>PHP</p>
                                </td>
                                <td>
                                    <p>{{ computedSoaTotal() | currency }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <p>LESS DISCOUNT:</p>
                                </td>
                                <td></td>
                                <td>
                                    <p>{{ this.discount_amount | currency }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <p>AMOUNT TOTAL:</p>
                                </td>
                                <td>
                                    <p>PHP</p>
                                </td>
                                <td>
                                    <p>{{ this.total_amount | currency }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="footer">
                        <tbody>
                            <tr>
                                <td>{{ prepared_by_name }}<br>________________<br><br>Prepared By</td>
                                <td><br>________________<br><br>Received By</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-container>
            <ViewCustomerPaymentDetailComponentVue
                :customerIds="customer_id"
                :fromSoa="true"
                :addEdit="true"
                :balanceAmounts="balanceAmount"
                :soa_id="viewStatementOfAccountId"
                :view="true"
                :user_access="userAccess"
                :origin="'viewSoa'"
            />
        </v-app>
    </div>
</template>
<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
import jsPDF from 'jspdf';
import swal from 'sweetalert2';
import moment from 'moment';
import ConfirmOverrideComponentVue from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import MemoViewComponentVue from '../Base/MemoViewComponent.vue';
import ViewCustomerPaymentDetailComponentVue from '../Payments/PaymentsComponent/ViewCustomerPaymentDetailComponent.vue';
export default {
    mixins: [SharedFunctionsComponentVue],
    props: [
        'viewStatementOfAccountId',
        'balanceAmount'
    ],
    components: {
        ConfirmOverrideComponentVue,
        MemoViewComponentVue,
        ViewCustomerPaymentDetailComponentVue
    },
    mounted() {

    },
    data() {
        return {
            soa_items_array: [],
            soa_items: [],
            soa_itemsHeaders: [
                { text: 'DR#', align: 'left', value: 'packing_list_num', },
                { text: 'Sales Date', align: 'left', value: 'sales_date', },
                { text: 'Sales Total', align: 'left', value: 'final_amount', },
                { text: 'Remarks', align: 'left', value: 'remarks', },
            ],

            loaded: true,
            statementOfAccount_dialog: false,

            soa_id: '',
            customer_name: '',
            customer_address: '',
            due_date: '',
            contact: '',
            terms: '',
            remarks: '',
            status_text: '',
            payment_method_text: '',
            paid_amount: '',
            payment_date: '',
            gross_amount: '',
            total_amount: '',
            discount_amount: '',
            addition_amount: '',
            deduction_amount: '',
            addition_remarks: '',
            deduction_remarks: '',
            prepared_by_name: '',
            pageCount: 1,
            num_of_items_per_page: 17,
            bad_debt_amount: '',
            balance_amount: '',

            debit_memos: [],
            credit_memos: [],

            errors: [],

            paymentHeaders: [
                { text: 'Ref#', width: '84px', value: 'reference_number' },
                { text: 'Payment Type', value: 'payment_type_name' },
                { text: 'Bank', value: 'bank_name' },
                { text: 'Account Number', value: 'check_details' },
                { text: 'Payment Name', value: 'cheque_name' },
                { text: 'P-Type Date', value: 'payment_type_date' },
                { text: 'WHT Amount', value: 'allocated_wht' },
                { text: 'Allocate Amount', value: 'allocated_amount' },
                { text: 'Total Amount', value: 'total_amount' },
                { text: 'Allocated By', value: 'allocated_by' },
                { text: 'Created At', value: 'created_at' },
                { text: 'Actions', value: 'actions' },
            ],
            payments: [],
            pageOptions: [],
            plItemsHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Uom', value: 'code' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Amount', value: 'amount' },
                { text: 'Total Amount', value: 'total_amount' },
                { text: 'FOC', value: 'foc' }
            ],
            plItems: [],
            expandedItems: [],
            userAccess: {
                cancel_payment: false,
                move_cheque_date: false
            },
            override: {
                override_dialog: false,
                errors: [],
                override_departments_allowed: ['AC'],
                override_authenticated: false,
            },
            override_confirm: false,
            selected_payment_id: '',
            selected_cpd_id: '',
            remarks_length_per_line: 52,
            creditMemoOptions: [],
            cmPageCount: 1,
            cm_items_array: [],
            num_of_cm_items_per_page: 13,
            debitMemoOptions: [],
            dmPageCount: 1,
            dm_items_array: [],
            num_of_dm_items_per_page: 13,
            dm_cm_headers: {
                credit_memo: [
                    { text: 'CM#', value: 'credit_memo_num' },
                    { text: 'CM Date', value: 'credit_memo_date' },
                    { text: 'Remarks', value: 'remarks' },
                    { text: 'Amount', value: 'total_amount' },
                    { text: 'Action', value: 'action', align: 'center' },
                ],
                debit_memo: [
                    { text: 'DM#', value: 'debit_memo_num' },
                    { text: 'DM Date', value: 'debit_memo_date' },
                    { text: 'Remarks', value: 'remarks' },
                    { text: 'Amount', value: 'total_amount' },
                    { text: 'Action', value: 'action', align: 'center' },
                ],
            },
            loading: false,
            viewMemoDetails: [],
            memoType: '',
            customer_id: null,

            badDebt: {
                dialog: false,
                type: 'assign',
                title: null,
            },
        }
    },
    methods: {
        computedTotalPerPage(index) {
            var total = 0

            if (this.soa_items_array[index] != null) {
                this.soa_items_array[index].forEach(function (item) {

                    total += parseFloat(item.final_amount.replace(/,/g, ''));
                });
            }

            return this.thousandSeprator(total)
        },
        computedSoaTotal() {
            var total = this.gross_amount

            // if(this.discount_amount != ''){
            //     total = parseFloat(total) - parseFloat(this.discount_amount)
            // }

            if (this.addition_amount != '') {
                total = parseFloat(total) + parseFloat(this.addition_amount)
            }

            if (this.deduction_amount != '') {
                total = parseFloat(total) - parseFloat(this.deduction_amount)
            }

            return total
        },
        /*computeGrandTotal(){
            if (this.soa_items != null && this.soa_items.length > 0) {
                var grand_total = 0;
                this.soa_items.forEach(function (item) {

                    grand_total += parseFloat(item.final_amount.replace(/,/g, ''));
                });
                return this.thousandSeprator(grand_total);
            } else {
                return 0;
            }
        },*/
        updateStatementOfAccountStatus(newStatus) {
            this.errors = []
            swal.fire({
                title: "",
                text: "Are you sure you want to update Statement Of Account?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            id: this.viewStatementOfAccountId,
                            params: {
                                status: newStatus
                            }
                        }

                        this.$store.dispatch('updateSoaStatus', payload).then(response => {
                            let data = response.data

                            if (data.error) {
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.statementOfAccount_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                        });
                    }
                });

        },
        clearStatementOfAccountItems() {
            this.resetFields()
            this.statementOfAccount_dialog = false
        },
        renderPDF(pdfAction) {
            var doc = new jsPDF('p', 'mm', 'letter');
            this.pageCount++
            this.cmPageCount++
            this.dmPageCount++

            let cm_dm = false
            let hasCM = false
            let hasDM = false

            let minusPage = 0
            if (this.deduction_amount == 0.00 && this.addition_amount == 0.00) {
                cm_dm = true
                minusPage = 1
            }
            if (this.deduction_amount != 0.00) {
                hasCM = true
            }
            if (this.addition_amount != 0.00) {
                hasDM = true
            }
            let isLastPage = false
            //   var pageCount = this.soa_items.length / 1;
            //    this.pageCount = pageCount
            // this.printed_soa_items = this.soa_items

            for (var i = 0; i < this.pageCount - 1; i++) {
                // this.printed_soa_items = this.soa_items
                //        this.printed_soa_items = this.printed_soa_items.slice(i * 1, (i * 1)+1);
                doc.autoTable({
                    html: '#div_logo',
                    theme: 'plain',
                    margin: { top: 5, left: 6, right: 6 },
                    columnStyles: {
                        0: { halign: 'center' },
                    },
                    didParseCell: function (cell, data) {
                        if (cell.row.index === 0 || cell.row.index === 2) {
                            if (cell.styles != null) {
                                cell.styles.fontStyle = 'bold';
                            }

                        }
                    }
                });
                var date_y = doc.autoTable.previous.finalY + 2
                doc.autoTable({
                    html: '#soa_date',
                    startY: doc.autoTable.previous.finalY + 2,
                    theme: 'plain',
                    margin: { top: 5, left: 20, right: 20 },
                    columnStyles: {
                        0: { cellWidth: 20, halign: 'left' },
                        1: { cellWidth: 100, halign: 'left' },
                    },
                });
                doc.setFontSize(8);

                doc.autoTable({
                    html: '#customer_details',
                    startY: doc.autoTable.previous.finalY + 4,
                    theme: 'plain',
                    margin: { top: 5, left: 20, right: 20 },
                    columnStyles: {
                        0: { cellWidth: 20, halign: 'left' },
                        1: { cellWidth: 140, halign: 'left' },
                    },
                });

                doc.autoTable({
                    html: '#items_details_' + i,
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: { left: 20, right: 20 },
                    theme: 'grid',
                    headStyles: {
                        halign: 'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                    },
                    styles: {
                        fontSize: 8
                    },
                    columnStyles: {
                        0: { cellWidth: 20, halign: 'center', textColor: '#000000' },
                        1: { cellWidth: 20, halign: 'center', textColor: '#000000' },
                        2: { halign: 'center', textColor: '#000000' },
                        3: { cellWidth: 40, halign: 'right', textColor: '#000000' },
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                doc.autoTable({
                    html: '#total_details_' + i,
                    theme: 'plain',
                    startY: (i == this.pageCount - 2 && cm_dm) ? doc.autoTable.previous.finalY + 1 : 205,
                    margin: { top: 37, left: 20, right: 20 },
                    columnStyles: {
                        0: { cellWidth: 90, textColor: '#000000' },
                        1: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                        2: { cellWidth: 12, textColor: '#000000' },
                        3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                    }
                });

                if (i == this.pageCount - 2 && cm_dm) {

                    doc.autoTable({
                        html: '#soa_total',
                        theme: 'plain',
                        startY: 190,
                        margin: { top: 37, left: 20, right: 20 },
                        columnStyles: {
                            0: { cellWidth: 80, textColor: '#000000' },
                            1: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                            2: { cellWidth: 12, textColor: '#000000' },
                            3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                        }
                    });

                }

                doc.autoTable({
                    html: '#note_details',
                    startY: doc.autoTable.previous.finalY + 8,
                    theme: 'plain',
                    margin: { top: 5, left: 20, right: 20 },
                    columnStyles: {
                        0: { halign: 'left' },
                    },
                });

                doc.autoTable({
                    html: '#footer',
                    startY: doc.autoTable.previous.finalY + 8,
                    theme: 'plain',
                    margin: { top: 5, left: 30, right: 30 },
                    columnStyles: {
                        0: { cellWidth: 40, halign: 'center' },
                        1: { cellWidth: 40, halign: 'center' },
                    },
                });
            }

            if (this.deduction_amount != 0.00 || this.addition_amount != 0.00) {
                if (this.deduction_amount != 0.00) {
                    doc.addPage();
                    for (var i = 0; i < this.cmPageCount - 1; i++) {
                        doc.autoTable({
                            html: '#div_logo',
                            theme: 'plain',
                            margin: { top: 5, left: 6, right: 6 },
                            columnStyles: {
                                0: { halign: 'center' },
                            },
                            didParseCell: function (cell, data) {
                                if (cell.row.index === 0 || cell.row.index === 2) {
                                    if (cell.styles != null) {
                                        cell.styles.fontStyle = 'bold';
                                    }

                                }
                            }
                        });
                        var date_y = doc.autoTable.previous.finalY + 2
                        doc.autoTable({
                            html: '#soa_date',
                            startY: doc.autoTable.previous.finalY + 2,
                            theme: 'plain',
                            margin: { top: 5, left: 20, right: 20 },
                            columnStyles: {
                                0: { cellWidth: 20, halign: 'left' },
                                1: { cellWidth: 100, halign: 'left' },
                            },
                        });
                        doc.setFontSize(8);
                        // doc.text(170,date_y+5, 'NO.' + doc.internal.getCurrentPageInfo().pageNumber + "/" + this.pageCount);
                        doc.autoTable({
                            html: '#customer_details',
                            startY: doc.autoTable.previous.finalY + 4,
                            theme: 'plain',
                            margin: { top: 5, left: 20, right: 20 },
                            columnStyles: {
                                0: { cellWidth: 20, halign: 'left' },
                                1: { cellWidth: 140, halign: 'left' },
                            },
                        });
                        if (i == 0) {
                            doc.autoTable({
                                html: '#grand_total',
                                theme: 'plain',
                                startY: doc.autoTable.previous.finalY + 8,
                                margin: { top: 37, left: 20, right: 20 },
                                columnStyles: {
                                    0: { cellWidth: 80, textColor: '#000000' },
                                    1: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                                    2: { cellWidth: 12, textColor: '#000000' },
                                    3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                                }
                            });
                        }
                        if (this.deduction_amount != 0.00) {
                            doc.setFontSize(8);
                            if (i == 0) {
                                doc.text(20, doc.autoTable.previous.finalY + 7, 'CREDIT MEMO');
                            }
                            doc.autoTable({
                                html: '#deductions_' + i,
                                margin: { left: 20, right: 20 },
                                theme: 'grid',
                                headStyles: {
                                    halign: 'center',
                                    fillColor: '#ffffff',
                                    textColor: '#000000',
                                    lineWidth: .2,
                                    lineColor: '#000000',
                                },
                                styles: {
                                    fontSize: 8
                                },
                                columnStyles: {
                                    0: { cellWidth: 25, halign: 'center', textColor: '#000000' },
                                    1: { cellWidth: 25, halign: 'center', textColor: '#000000' },
                                    2: { cellWidth: 80, halign: 'center', textColor: '#000000' },
                                    3: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                                },
                            });

                            if (i == this.cmPageCount - 2 && !hasDM) {
                                doc.autoTable({
                                    html: '#soa_total',
                                    theme: 'plain',
                                    startY: 190,
                                    margin: { top: 37, left: 20, right: 20 },
                                    columnStyles: {
                                        0: { cellWidth: 80, textColor: '#000000' },
                                        1: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                                        2: { cellWidth: 12, textColor: '#000000' },
                                        3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                                    }
                                });
                            }
                            doc.autoTable({
                                html: '#note_details',
                                startY: 207 + 13,
                                theme: 'plain',
                                margin: { top: 3, left: 20, right: 20 },
                                columnStyles: {
                                    0: { halign: 'left' },
                                },
                            });

                            doc.autoTable({
                                html: '#footer',
                                // startY: doc.autoTable.previous.finalY + 8,
                                theme: 'plain',
                                margin: { top: 0, left: 30, right: 30 },
                                columnStyles: {
                                    0: { cellWidth: 40, halign: 'center' },
                                    1: { cellWidth: 40, halign: 'center' },
                                },
                            });

                            if (i != this.cmPageCount - 2) {
                                doc.addPage()
                            }
                        }
                    }
                }

                if (this.addition_amount != 0.00) {
                    doc.addPage();
                    for (var i = 0; i < this.dmPageCount - 1; i++) {
                        doc.autoTable({
                            html: '#div_logo',
                            theme: 'plain',
                            margin: { top: 5, left: 6, right: 6 },
                            columnStyles: {
                                0: { halign: 'center' },
                            },
                            didParseCell: function (cell, data) {
                                if (cell.row.index === 0 || cell.row.index === 2) {
                                    if (cell.styles != null) {
                                        cell.styles.fontStyle = 'bold';
                                    }

                                }
                            }
                        });
                        var date_y = doc.autoTable.previous.finalY + 2
                        doc.autoTable({
                            html: '#soa_date',
                            startY: doc.autoTable.previous.finalY + 2,
                            theme: 'plain',
                            margin: { top: 5, left: 20, right: 20 },
                            columnStyles: {
                                0: { cellWidth: 20, halign: 'left' },
                                1: { cellWidth: 100, halign: 'left' },
                            },
                        });
                        doc.setFontSize(8);
                        // doc.text(170,date_y+5, 'NO.' + doc.internal.getCurrentPageInfo().pageNumber + "/" + this.pageCount);
                        doc.autoTable({
                            html: '#customer_details',
                            startY: doc.autoTable.previous.finalY + 4,
                            theme: 'plain',
                            margin: { top: 5, left: 20, right: 20 },
                            columnStyles: {
                                0: { cellWidth: 20, halign: 'left' },
                                1: { cellWidth: 140, halign: 'left' },
                            },
                        });
                        if (i == 0) {
                            doc.autoTable({
                                html: '#grand_total',
                                theme: 'plain',
                                startY: doc.autoTable.previous.finalY + 8,
                                margin: { top: 37, left: 20, right: 20 },
                                columnStyles: {
                                    0: { cellWidth: 80, textColor: '#000000' },
                                    1: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                                    2: { cellWidth: 12, textColor: '#000000' },
                                    3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                                }
                            });
                        }
                        if (this.addition_amount != 0.00) {
                            doc.setFontSize(8);
                            if (i == 0) {
                                doc.text(20, doc.autoTable.previous.finalY + 7, 'DEBIT MEMO');
                            }
                            doc.autoTable({
                                html: '#additions_' + i,
                                margin: { left: 20, right: 20 },
                                theme: 'grid',
                                headStyles: {
                                    halign: 'center',
                                    fillColor: '#ffffff',
                                    textColor: '#000000',
                                    lineWidth: .2,
                                    lineColor: '#000000',
                                },
                                styles: {
                                    fontSize: 8
                                },
                                columnStyles: {
                                    0: { cellWidth: 25, halign: 'center', textColor: '#000000' },
                                    1: { cellWidth: 25, halign: 'center', textColor: '#000000' },
                                    2: { cellWidth: 80, halign: 'center', textColor: '#000000' },
                                    3: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                                },
                            });
                            if (i == this.dmPageCount - 2 && !hasCM) {
                                doc.autoTable({
                                    html: '#soa_total',
                                    theme: 'plain',
                                    startY: 190,
                                    margin: { top: 37, left: 20, right: 20 },
                                    columnStyles: {
                                        0: { cellWidth: 80, textColor: '#000000' },
                                        1: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                                        2: { cellWidth: 12, textColor: '#000000' },
                                        3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                                    }
                                });
                            }
                            doc.autoTable({
                                html: '#note_details',
                                startY: 207 + 13,
                                theme: 'plain',
                                margin: { top: 3, left: 20, right: 20 },
                                columnStyles: {
                                    0: { halign: 'left' },
                                },
                            });

                            doc.autoTable({
                                html: '#footer',
                                // startY: doc.autoTable.previous.finalY + 8,
                                theme: 'plain',
                                margin: { top: 0, left: 30, right: 30 },
                                columnStyles: {
                                    0: { cellWidth: 40, halign: 'center' },
                                    1: { cellWidth: 40, halign: 'center' },
                                },
                            });

                            if (i != this.dmPageCount - 2) {
                                doc.addPage()
                            }
                        }
                    }
                }
            }
            if (hasCM && hasDM) {
                doc.autoTable({
                    html: '#soa_total',
                    theme: 'plain',
                    startY: 190,
                    margin: { top: 37, left: 20, right: 20 },
                    columnStyles: {
                        0: { cellWidth: 80, textColor: '#000000' },
                        1: { cellWidth: 45, halign: 'right', textColor: '#000000' },
                        2: { cellWidth: 12, textColor: '#000000' },
                        3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                    }
                });
            }

            let pageCount = doc.internal.getNumberOfPages();

            for (let i = 0; i < pageCount; i++) {
                doc.setPage(i);
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(10).setFont(undefined, 'normal').text('ID: ' + this.soa_id, doc.internal.pageSize.width - 46, 39);
                doc.setFontSize(8).setFont(undefined, 'normal').text('NO. ' + pageCurrent + '/' + pageCount, doc.internal.pageSize.width - 46, 45);
            }
            if (pdfAction == 'download') {
                doc.save('StatementOfAccount ' + this.soa_id + '.pdf');
            } else if (pdfAction == 'print') {
                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');
            }

        },
        resetFields() {
            this.soa_items = []
            this.soa_id = ''
            this.customer_name = ''
            this.customer_address = ''
            this.terms = ''
            this.remarks = ''
            this.due_date = ''
            this.contact = ''
            this.status_text = ''
            this.prepared_by_name = ''
            this.soa_items_array = []
            this.pageOptions = []
            this.payment_method_text = ''
            this.paid_amount = ''
            this.payment_date = ''
            this.gross_amount = ''
            this.total_amount = ''
            this.discount_amount = ''
            this.addition_amount = ''
            this.deduction_amount = ''
            this.addition_remarks = ''
            this.deduction_remarks = ''
            this.creditMemoOptions = []
            this.cm_items_array = []
            this.debitMemoOptions = []
            this.dm_items_array = []
            this.viewMemoDetails = []
        },
        soaItemCardTableStyle() {
            if (this.soa_items.length > 10) {
                return 'overflow:auto;height:500px';
            }

            return '';
        },
        date(item) {
            if (!!item) {
                return moment(item.created_at).format('YYYY-MM-DD, hh:mm:ss');
            }

            return '';
        },
        paymentTotalAmount(item) {
            if (!!item) {
                return this.getTwoDecimals(parseFloat(item.total_amount) + parseFloat(item.allocated_wht));
            }
            return 0;
        },
        loadDetails(item) {
            this.loading = true
            this.expandedItems = []
            let payload = {
                id: item.item.packing_list_id
            }
            this.$store.dispatch("soaGetPlById", payload).then(response => {
                this.expandedItems = response.data
                this.loading = false
            })
        },
        cancelPaymentAllocation(id, cpd_id) {
            swal.fire({
                title: "",
                text: "Are you sure you want cancel this payment?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseButton: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.selected_payment_id = id
                        this.selected_cpd_id = cpd_id
                        this.override.override_dialog = true;
                    }
                })
        },
        async closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                this.override.override_dialog = false;
                this.override_confirm = true;
                this.statementOfAccount_dialog = false;
                swal.fire({
                    title: 'Cancel Reason Remarks',
                    input: 'text',
                    inputLabel: 'Enter Reason',
                    showConfirmButton: true,
                    confirmButtonColor: '#397373',
                    confirmButtonText: 'Confirm',
                    inputValidator: (value) => {
                        if (!value) {
                            return 'You need to write something!'
                        }
                    },
                    allowOutsideClick: () => {
                        const popup = swal.getPopup()
                        popup.classList.remove('swal2-show')
                        setTimeout(() => {
                            popup.classList.add('animate__animated', 'animate__headShake')
                        })
                        setTimeout(() => {
                            popup.classList.remove('animate__animated', 'animate__headShake')
                        }, 500)
                        return false
                    }
                }).then((result) => {
                    if (result.isConfirmed && result.value != '') {
                        let payload = {
                            id: this.selected_payment_id,
                            cancel_reason: result.value,
                            soa_id: this.viewStatementOfAccountId,
                            cpd_id: this.selected_cpd_id
                        }
                        this.$store.dispatch('cancelCustomerPaymentAllocations', payload).then(response => {
                            swal.fire(response.data.msg, '', response.data.status)
                            this.viewStatementOfAccountId = -1
                        })
                    }
                })

            } else {
                this.override.override_dialog = false;
            }
        },
        viewMemo(type, memo) {
            this.memoType = type
            this.viewMemoDetails = memo
        },
        viewCustomerPayment(id) {
            this.$store.commit('custPaymentDetailBounceFromViewing', true)
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailShow');
        },
        editCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailEditDialogAction');
            this.$store.commit('custPaymentDetailShowAddEditDialog');
            this.$store.commit('custPaymentDetailEditChequeDate', true);
        },
        toggleBadDebt(type) {
            this.badDebt.type = type
            this.badDebt.title = type.charAt(0).toUpperCase() + type.substr(1) + ' Bad Debt Amount'

            switch (type) {
                case 'assign':
                    this.badDebt.amount = Number(this.balance_amount).toFixed(2)
                    break
                case 'recover':
                    this.badDebt.amount = Number(this.bad_debt_amount).toFixed(2)
                    break
                default:
                    this.badDebt.amount = 0
                    break
            }

            this.badDebt.dialog = true
        },
        confirmBadDebt() {
            swal.fire({
                title: 'Are you sure to ' + this.badDebt.title.toLowerCase() + '?',
                icon: 'warning',
                showCancelButton: true,
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('confirmBadDebt', {
                        id: this.viewStatementOfAccountId,
                        type: this.badDebt.type,
                        amount: this.badDebt.amount,
                    }).then(response => {
                        swal.fire("", response.data.msg, "success");
                        this.badDebt.dialog = false
                        this.statementOfAccount_dialog = false
                        this.$emit('closeView', 'updateStatusSuccess')
                    }).catch(error => {
                        console.log(error)
                        this.badDebt.dialog = false
                    })
                }
            })
        },
    },
    computed: {
        ...mapGetters([
            'GET_SOA',
            'GET_SOA_PAYMENTS',
            'USER_ACCESS'
        ])
    },
    watch: {
        USER_ACCESS: {
            handler(val) {
                this.userAccess.create = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "cancel_payment") {
                            this.userAccess.cancel_payment = true
                        }
                        if (e.actions_code == "move_cheque_date") {
                            this.userAccess.move_cheque_date = true
                        }
                    });
                }
            }
        },
        viewStatementOfAccountId: function (val) {
            this.errors = []

            if (val != -1) {
                if (this.viewStatementOfAccountId != null && this.viewStatementOfAccountId > 0) {
                    swal.fire({
                        title: 'Loading Please wait...',
                        icon: 'info',
                        allowOutsideClick: false,
                    });
                    swal.showLoading();

                    this.$store.dispatch('getSoa', this.viewStatementOfAccountId).then(response => {
                        let data = this.GET_SOA

                        this.soa_id = data.soa.id
                        this.customer_name = data.soa.customer_name
                        this.customer_address = data.soa.all_shipping_addresses[0].text
                        this.terms = data.soa.terms
                        this.remarks = data.soa.remarks
                        this.due_date = data.soa.due_date
                        this.contact = data.soa.contact
                        this.status_text = data.soa.status_text
                        this.soa_items = data.soa.statement_of_account_items
                        this.prepared_by_name = data.soa.prepared_by_name
                        this.paid_amount = data.soa.paid_amount
                        this.payment_date = data.soa.payment_date
                        this.payment_method_text = data.soa.payment_method_text
                        this.gross_amount = data.soa.gross_amount
                        this.total_amount = data.soa.total_amount
                        this.discount_amount = data.soa.discount_amount
                        this.addition_amount = data.soa.addition_amount
                        this.deduction_amount = data.soa.deduction_amount
                        this.addition_remarks = data.soa.addition_remarks
                        this.deduction_remarks = data.soa.deduction_remarks
                        this.debit_memos = data.debit_memos
                        this.credit_memos = data.credit_memos
                        this.customer_id = data.soa.customer_id
                        this.bad_debt_amount = data.soa.bad_debt_amount
                        this.balance_amount = data.soa.balance_amount

                        this.statementOfAccount_dialog = true
                        var complete_soa = this.soa_items

                        if (this.credit_memos.length > 0) {

                            let itemsPerPageCounter = 1
                            let num_chars_per_page = 0
                            let current_char_count = 0
                            let item_count = 0

                            num_chars_per_page = this.num_of_cm_items_per_page * this.remarks_length_per_line

                            this.credit_memos.forEach(e => {
                                current_char_count = current_char_count + (e.remarks ? e.remarks.length : 0)
                                item_count++

                                if (current_char_count > num_chars_per_page || item_count == this.num_of_cm_items_per_page) {
                                    itemsPerPageCounter++;
                                    current_char_count = 0
                                    item_count = 0
                                }
                                this.creditMemoOptions.push({
                                    pageNum: itemsPerPageCounter,
                                    cm_items: e
                                })
                            })

                            this.cmPageCount = itemsPerPageCounter;

                            for (let i = 1; i <= this.cmPageCount; i++) {
                                let page = []
                                this.creditMemoOptions.forEach(e => {
                                    if (e.pageNum == i) {
                                        page.push(e.cm_items)
                                    }
                                })
                                this.cm_items_array.push(page)
                            }
                        }
                        if (this.debit_memos.length > 0) {

                            let itemsPerPageCounter = 1
                            let num_chars_per_page = 0
                            let current_char_count = 0
                            let item_count = 0

                            num_chars_per_page = this.num_of_dm_items_per_page * this.remarks_length_per_line

                            this.debit_memos.forEach(e => {
                                current_char_count = current_char_count + (e.remarks ? e.remarks.length : 0)
                                item_count++

                                if (current_char_count > num_chars_per_page || item_count == this.num_of_dm_items_per_page) {
                                    itemsPerPageCounter++;
                                    current_char_count = 0
                                    item_count = 0
                                }
                                this.debitMemoOptions.push({
                                    pageNum: itemsPerPageCounter,
                                    dm_items: e
                                })
                            })

                            this.dmPageCount = itemsPerPageCounter;

                            for (let i = 1; i <= this.dmPageCount; i++) {
                                let page = []
                                this.debitMemoOptions.forEach(e => {
                                    if (e.pageNum == i) {
                                        page.push(e.dm_items)
                                    }
                                })
                                this.dm_items_array.push(page)
                            }
                        }
                        if (complete_soa.length > 0) {
                            let itemsPerPageCounter = 1
                            let num_chars_per_page = 0
                            let current_char_count = 0
                            let item_count = 0

                            num_chars_per_page = this.num_of_items_per_page * this.remarks_length_per_line

                            complete_soa.forEach(e => {
                                item_count = item_count + Math.ceil((e.remarks ? e.remarks.length : 0) / this.remarks_length_per_line)

                                if (item_count >= this.num_of_items_per_page) {
                                    itemsPerPageCounter++;
                                    current_char_count = 0
                                    item_count = 0
                                }
                                this.pageOptions.push({
                                    pageNum: itemsPerPageCounter,
                                    soa_items_array: e
                                })
                            })

                            this.pageCount = itemsPerPageCounter;

                            for (let i = 1; i <= this.pageCount; i++) {
                                let page = []
                                this.pageOptions.forEach(e => {
                                    if (e.pageNum == i) {
                                        page.push(e.soa_items_array)
                                    }
                                })
                                this.soa_items_array.push(page)
                            }
                        }
                        swal.close()
                    }).catch(error => {
                        console.log(error)
                        swal.close()
                    });

                    this.$store.dispatch('getSoaPayments', this.viewStatementOfAccountId).then(response => {
                        this.payments = this.GET_SOA_PAYMENTS;
                        this.payments.forEach(x => {
                            let payment_type_date = ''
                            if (x.payment_type_id == 1) {
                                payment_type_date = x.payment_date
                            }
                            else if (x.payment_type_id == 2) {
                                payment_type_date = x.cheque_date
                            }
                            else if (x.payment_type_id == 3) {
                                payment_type_date = x.account_date === null ? x.payment_date : x.account_date
                            }
                            else if (x.payment_type_id == 4) {
                                payment_type_date = x.online_date
                            }
                            else if (x.payment_type_id == 5) {
                                payment_type_date = x.card_date
                            }
                            Object.assign(x, {
                                payment_type_date: payment_type_date
                            });
                        })
                    });
                }
            }

        }
    },

};

</script>
<style>
.v-avatar {
    margin: 15px 50px !important;
}

#client_modal_card {
    min-height: 160px !important;
}

.centered-input >>> input {
    text-align: center
}
</style>
