<template>
  <v-btn v-on="$listeners" :color="color" :text="button_text" :icon="icon" small :class="classes">
    <template v-if="action == 'view'">
      <v-icon dense>{{ button_icon }}</v-icon>
    </template>
    <template v-if="action == 'create'"> ADD </template>

    <template v-if="action == 'edit'">
      <v-icon dense>{{ button_icon }}</v-icon>
    </template>
    <template v-if="action == 'access'">
      <span class="badge bg-dark text-white">ACCESS</span>
    </template>
    <template v-if="action == 'delete'">
      <v-icon dense>{{ button_icon }}</v-icon>
    </template>
  </v-btn>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['action', 'button_icon', 'color', 'button_text', 'classes', 'icon'],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters(['ACTION']),
  },
}
</script>
