<template>
  <!-- <v-card>
    <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
      <span class="text-h5">{{ SELECTED_USER_ROLE.name }} - {{ GET_USER_DETAILS.name }}</span>
      <v-row class="m-0">
        <v-col cols="pull-right-10 p-2">
          <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container class="pa-0 ma-0">
        <v-card-text v-if="GET_ROLE_LOADED">
          <v-row >
          <v-col class="text-center">
          <v-skeleton-loader
            type="table: table-thead, table-tbody"
         ></v-skeleton-loader>
          </v-col>
        </v-row>
        </v-card-text>
        <v-simple-table height="300px" width="100%" dense v-if="!GET_ROLE_LOADED">
          <template v-slot:default>

            <tbody>
              <tr v-for="access in GET_ROLE_ACCESS.data" :key="access.id" >

                <td>{{ access.menu_link_desc }}</td>
                <td v-for="actions in access.actions" :key="actions.id">
                  <v-switch
                    v-model="actions.access"
                    :input-value="actions.access"
                    :label="actions.actions"
                    :switch_access="{
                      id: access.id,
                      department_id: SELECTED_USER_ROLE.department_id,
                      active: actions.active,
                      side_bar_menu_links: access.id,
                      user_id: GET_USER_DETAILS.id,
                      actions_id: actions.id,
                      actions_code: actions.code,
                      access_type: 2,
                      child_status: actions.access,
                    }"
                    ref="actions"
                    dense
                    @change="onChangeSwitch(actions.id, actions.access)"
                  >
                  </v-switch>
                </td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row>
          <v-col cols="1" v-for="(department, i) in GET_ROLE_ACCESS.department" :key="i">
            <v-switch
              v-model="department.access"
              :input-value="department.access"
              :label="department.menubar_desc"
              :switch_access="{
                department_id: department.id,
                user_id: GET_USER_DETAILS.id,
                side_menu: department.side_menu_id,
                access_type: 1,
                actions_id: i,
                actions_code: 'view',
                main_status: department.access,
              }"
              ref="module"
              @change="onChangeSwitchMain(i, department.access)"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="text-center">
      <v-row class="ma-1">
        <v-col cols="12" v-if="!GET_ROLE_LOADED">
          <v-btn class="float-center" @click="SubmitAccess()" >
            <span>Submit</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card> -->
    <v-app>
        <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom" >
                <span class="text-h5">{{ SELECTED_USER_ROLE.name }} - {{ GET_USER_DETAILS.name }}</span>
                <v-row class="m-0">
                  <v-col cols="pull-right-10 p-2">
                    <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
            <v-card-text style="overflow:hidden">
                <div class="d-flex justify-content-around align-items-end">
                    <span v-for="(department, i) in GET_ROLE_ACCESS.department" :key="i" class="m-3">
                      <v-icon> {{SIDENAV_LOAD.find(e=>e.icon_id == department.icon).icon}}</v-icon>
                         {{ department.menubar_desc }}
                        <v-switch
                            v-model="department.access"
                            :input-value="department.access"
                            :switch_access="{
                            department_id: department.id,
                            user_id: GET_USER_DETAILS.id,
                            side_menu: department.side_menu_id,
                            access_type: 1,
                            actions_id: i,
                            actions_code: 'view',
                            main_status: department.access,
                            }"
                            color="#2337C6"
                            ref="module"
                            @change="onChangeSwitchMain(i, department.access)"
                        ></v-switch>
                    </span>
                </div>
                <div style="max-height:600px; overflow-y:scroll; padding:0;">
                    <v-expansion-panels
                        multiple
                        v-model="panel"
                    >
                        <v-expansion-panel
                            accordion
                            v-for="(access, i) in GET_ROLE_ACCESS.data" :key="i"
                            :value="i"
                        >
                            <v-expansion-panel-header>
                               <b> {{ access.menu_link_desc }}</b>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col  v-for="(actions, index) in access.actions" :key="index" cols="3">
                                        {{ actions.actions }}
                                        <v-switch
                                        v-model="actions.access"
                                        :input-value="actions.access"

                                        :switch_access="{
                                            id: access.id,
                                            department_id: SELECTED_USER_ROLE.department_id,
                                            job_title_id: SELECTED_USER_ROLE.job_title_id,
                                            active: actions.active,
                                            side_bar_menu_links: access.id,
                                            user_id: GET_USER_DETAILS.id,
                                            actions_id: actions.id,
                                            actions_code: actions.code,
                                            access_type: 2,
                                            child_status: actions.access,
                                        }"
                                        ref="actions"
                                        color="#2337C6"
                                        dense
                                        @change="onChangeSwitch(actions.id, actions.access)"
                                            >
                                        </v-switch>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                </div>
            </v-card-text>
            <v-card-actions class="text-center">
                <v-row class="ma-1">
                    <v-col lg="2" >
                        <v-checkbox
                            class="ma-0 pa-0"
                            value="1"
                            v-model="checkbox"

                            hide-details
                            >
                            <template v-slot:label>
                                <span class="pt-2">
                                    Show All
                                </span>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col lg="8" v-if="!GET_ROLE_LOADED">
                        <v-btn class="float-center" @click="SubmitAccess()" >
                            <span>Submit</span>
                        </v-btn>
                    </v-col>
                    <v-spacer>
                    </v-spacer>
                </v-row>
              </v-card-actions>
        </v-card>
    </v-app>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      child_status: false,
      main_status: false,
      actions: [],
      child_id: [],
      mainid: [],
      switch_access: [],
      switch_access_main: [],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      panel:[],
      checkbox:false
    };
  },
  methods: {
    closeDialog() {
      this.$store.commit('ROLE_ACCESS_LOAD', '')
      this.$store.commit('DIALOG', false);
      this.reset();
    },
    SubmitAccess() {
      this.$refs.actions.forEach(e => {
        this.child_id.forEach(value => {
          value.id == e.$attrs.switch_access.actions_id ? this.switch_access.push(e.$attrs.switch_access) : ''
        });
      })
      this.$refs.module.forEach(e => {
        this.mainid.forEach(id => {
          id == e.$attrs.switch_access.actions_id ? this.switch_access_main.push(e.$attrs.switch_access) : ''
        });
      })

      if(this.switch_access.length == 0){
        Swal.fire('Warning','- Please select Action','warning')
        return false
      }
      this.$store.dispatch('submitUserAccessControl', {
          isRoles: this.GET_IS_ROLES,
          switch_access_child: this.switch_access,
          switch_access_main: this.switch_access_main,
          child_status: this.child_status,
          main_status: this.main_status,
          user_id:this.GET_USER_DETAILS.id,
          job_title_id:this.GET_JOB_TITLE_ID
        })
        .then(response => {

          this.$store.commit('SUBMIT_SUCCESS', true);
        })
    },
    onChangeSwitch(item, code) {
      if (code) {
        this.child_status = false;
      } else {
        this.child_status = true;
      }
      this.child_id.push({
        id: item,
        child_status: this.child_status == false,
      });
    },
    onChangeSwitchMain(value, status) {
      const id_ifexist = this.mainid.forEach(e => {
        return e
      });

      if (id_ifexist != value) {
        this.mainid.push(value);
      }

      if (status) {
        this.main_status = false;
      } else {
        this.main_status = true;
      }
    },
    reset() {
      this.switch_access = [];
      this.child_id = [];
      this.t = ''
      this.switch_access_main = [];
      this.mainid = [];
    },
  },
  mounted() {},
  computed: {
    ...mapGetters([
      'SELECTED_USER_ROLE',
      'USERACCOUNT_LOAD',
      'GET_ROLE_ACCESS',
      'GET_USER_DETAILS',
      'GET_HAS_ACCESS',
      'GET_SUBMIT_SUCCESS',
      'GET_MESSAGE',
      'GET_ROLE_LOADED',
      'GET_NOTIFICATION',
      'SIDENAV_LOAD',
      'GET_IS_ROLES',
      'GET_JOB_TITLE_ID'
    ]),
  },
  watch: {
    GET_SUBMIT_SUCCESS: {
      handler(val) {
        this.reset();
      }
    },
    'GET_ROLE_ACCESS.department': {
      handler(val) {
        if (val != null) {
          val.forEach(e => {
            this.main_status = e.access;
          })
        }
      },
    },
    checkbox(val){
        if(val){
            this.GET_ROLE_ACCESS.data.forEach((e,i)=>{
                this.panel.push(i)
            })
        }else{
            this.panel = []
        }
    },
    immediate: true,
  },
};
</script>
