<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title" style="color: black">Items</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                    <v-tab ripple @click="getAllItems()">Active Items</v-tab>
                    <v-tab ripple @click="getAllItems()">Inactive Items</v-tab>
                    <v-tab ripple @click="getAllItems()">SP Items</v-tab>
                    <v-tab ripple @click="getAllItems()">Inactive SP Items</v-tab>
                    <v-tab ripple>Bundles</v-tab>
                    <v-tab ripple v-if="userAccess.includes('discountType_view')">Discount Types</v-tab>
                    <v-tab ripple v-if="userAccess.includes('categories_view')">Categories</v-tab>
                    <v-tab ripple v-if="userAccess.includes('uom_view')">UOM</v-tab>
                    <v-tab ripple v-if="userAccess.includes('brands_view')">Brands</v-tab>
                    <v-tab ripple v-if="userAccess.includes('sizes_view')">Sizes</v-tab>

                    <v-tabs-items v-model="tab" touchless>
                        <!-- ACTIVE ITEMS TAB -->
                        <v-tab-item>
                            <ItemsComponent :is_inactive_items="false" :is_sp="false"></ItemsComponent>
                        </v-tab-item>

                        <!-- INACTIVE ITEMS TAB -->
                        <v-tab-item>
                            <ItemsComponent :is_inactive_items="true" :is_sp="false"></ItemsComponent>
                        </v-tab-item>

                        <!-- SP ITEMS TAB -->
                        <v-tab-item>
                            <ItemsComponent :is_inactive_items="false" :is_sp="true"></ItemsComponent>
                        </v-tab-item>

                        <!-- INACTIVE SP ITEMS TAB -->
                        <v-tab-item>
                            <ItemsComponent :is_inactive_items="true" :is_sp="true"></ItemsComponent>
                        </v-tab-item>

                        <!-- ITEM TYPES TAB -->
                        <v-tab-item v-if="userAccess.includes('discountType_view')">
                            <TypesComponentVue></TypesComponentVue>
                        </v-tab-item>

                        <!-- ITEM CATEGORIES TAB -->
                        <v-tab-item v-if="userAccess.includes('categories_view')">
                            <CategoriesComponentVue></CategoriesComponentVue>
                        </v-tab-item>

                        <!-- ITEM UOM TAB -->
                        <v-tab-item v-if="userAccess.includes('uom_view')">
                            <UomComponentVue></UomComponentVue>
                        </v-tab-item>

                        <!-- ITEM BRANDS TAB -->
                        <v-tab-item v-if="userAccess.includes('brands_view')">
                            <BrandsComponentVue></BrandsComponentVue>
                        </v-tab-item>

                        <!-- ITEM SIZES TAB -->
                        <v-tab-item v-if="userAccess.includes('sizes_view')">
                            <SizesComponentVue></SizesComponentVue>
                        </v-tab-item>

                        <!-- BUNDLES TAB -->
                        <v-tab-item>
                            <BundlesComponentVue></BundlesComponentVue>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import ItemsComponent from "./tabs/ItemsComponent.vue";
import BundlesComponentVue from "./tabs/BundlesComponent.vue";
import TypesComponentVue from "./tabs/TypesComponent.vue";
import CategoriesComponentVue from "./tabs/CategoriesComponent.vue";
import UomComponentVue from "./tabs/UomComponent.vue";
import BrandsComponentVue from "./tabs/BrandsComponent.vue";
import SizesComponentVue from "./tabs/SizesComponent.vue";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            loaded: false,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,
            tab: null,
            userAccess: []
        };
    },
    async mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        await this.$store.dispatch('getItemCategories');
        await this.$store.dispatch('getItemSize');
        await this.$store.dispatch('getItemTypes');
        await this.$store.dispatch('getItemUom');
        await this.$store.dispatch('getItemBrand');
        await this.$store.dispatch('getItems')
    },
    components: {
        ItemsComponent,
        BundlesComponentVue,
        TypesComponentVue,
        CategoriesComponentVue,
        UomComponentVue,
        BrandsComponentVue,
        SizesComponentVue,
    },
    computed: {
        ...mapGetters([
            "GET_ALL_ITEMS",
            "USER_ACCESS"
        ]),
    },
    methods: {
        getAllItems() {
            this.loaded = false;

            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
                is_inactive_items: this.is_inactive_items,
                is_sp: this.is_sp,
            };
            this.$store.dispatch("getAllItems", payload).then((response) => {
                this.items = this.GET_ALL_ITEMS.data;
                this.totalItems = this.GET_ALL_ITEMS.total;
                this.loaded = true;
            });
        },
    },
    watch: {
         USER_ACCESS:{
            handler(val){
                this.userAccess = val.map(e=>e.actions_code);
            }
        },
    }
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
