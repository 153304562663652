<template>
    <v-row>
        <v-container class="second-tabs-body-color">
            <!-- <buttons class="ma-2"
                v-if="userAccess.create"
                :action="buttons.actions.create"
                :button_icon="buttons.icons.create"
                :color="buttons.colors.create"
                @click="showAddInbound()"
                >
            </buttons> -->
            <v-flex class="row mt-1 mx-2" v-if="ACCESS_INBOUND.includes('create_inbound')">
                <v-flex class="col-12 d-flex no-block justify-space-between align-items-center">
                    <div class="btn-toolbar">
                        <v-btn data-modal-id="addContainerModal" color="#f69f1a" @click="showAddInbound()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                    <div class="btn-toolbar">
                        <v-btn
                            :loading="exportLoading"
                            color="#f69f1a"
                            @click="exportLpiToExcel"
                        >
                            <v-icon>mdi-printer-pos-wrench</v-icon>
                            <span class="ml-2">Export</span>
                        </v-btn>
                    </div>
                </v-flex>
            </v-flex>
        </v-container>
        <v-row class="ma-0">
            <InboundTableComponent ref="child"
                :containerActionResult="action"
                :is_pending="is_pending"
                :is_approved="is_approved"
                :is_receiving="is_receiving"
                :radioGroup="radioGroup"
                @showEditModal="showEditModal"
                :user_access="USER_ACCESS">
            </InboundTableComponent>
        </v-row>
        <v-app id="addEditInboundModal">
            <v-dialog
            v-model="add_edit_inbound_dialog"
            persistent max-width="90%"
            scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">{{inbound_title}}s</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                            text icon color="gray"
                            class="float-right"
                            @click="closeAddEditDialog();
                            resetFields();
                            $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-container style="background-color: white; overflow-y: scroll">
                        <v-col>
                            <v-card-text v-if="loaded == true">
                                <form class="form-horizontal"
                                action="/containers"
                                method="post"
                                @submit.prevent="onSubmit">
                                    <div class="card-body">
                                        <v-row>
                                            <v-col class="mx-4">
                                                <v-text-field
                                                    class="mt-1"
                                                    dense
                                                    outlined
                                                    id="inbound_num"
                                                    label="Inbound Num"
                                                    v-model="inbound_num"
                                                    :disabled="is_receiving">
                                                </v-text-field>
                                                <span
                                                v-for="errors in errors.inbound_num"
                                                class="text-warning"
                                                :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col class="mx-4">
                                                    <v-autocomplete
                                                        class="mt-1"
                                                        v-model='warehouse_id'
                                                        :items="GET_ALL_WAREHOUSES"
                                                        outlined
                                                        dense
                                                    >
                                                        <template v-slot:label >
                                                            Select Warehouse<span class="ml-2" style="color:red">*</span>
                                                        </template>
                                                    </v-autocomplete>
                                                <span
                                                v-for="errors in errors.warehouse_id"
                                                class="text-warning"
                                                :key="errors">{{ errors }}</span>
                                            </v-col>
                                            <v-col>
                                                <v-menu
                                                    v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            class="mt-1"
                                                            v-model="estimated_warehouse_delivery"
                                                            readonly
                                                            append-icon="mdi-calendar-clock"
                                                            outlined
                                                            dense
                                                            v-on="on"
                                                            >
                                                            <!-- label="Estimated Warehouse Delivery" -->
                                                            <template v-slot:label>
                                                                Estimated Warehouse Delivery<span class="ml-2" style="color:red">*</span>
                                                            </template>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="estimated_warehouse_delivery"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col class="mx-4">
                                                <v-textarea  class="mt-1" dense no-resize rows="2" v-model="supplier">
                                                    <template v-slot:label >
                                                        Supplier<span class="ml-2" style="color:red">*</span>
                                                    </template>
                                                </v-textarea>
                                            </v-col>
                                            <v-col class="mx-4">
                                                <v-textarea  class="mt-1" dense no-resize rows="2" v-model="remarks"
                                                    :disabled="is_receiving">
                                                    <template v-slot:label >
                                                        Remarks<span class="ml-2" style="color:red">*</span>
                                                    </template>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col md="3" class="m-4">

                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                            <v-col>
                                                <v-checkbox label="MB" true-value="1" false-value="0" v-model="received_type">
                                                </v-checkbox>
                                            </v-col>
                                        </v-row> -->
                                        <v-card outlined class="p-2">
                                            <div class="row mt-4" >
                                                <v-row class="mx-2 d-flex justify-space-between">
                                                    <div class="float-left mx-2">
                                                        <v-btn :disabled="disabled_batch_addition" dark small color="primary" @click="openBatchDialog();">
                                                            <span>{{ batch_addition_text.title }}</span>
                                                        </v-btn>
                                                    </div>
                                                    <!-- <v-spacer /> -->
                                                    <v-flex shrink class="pr-2 pt-2"><!--style="text-align:end"-->
                                                        <v-tooltip bottom  color="#f69f1a">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        color="#f69f1a"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        small
                                                                        text
                                                                        fab
                                                                        @click="showImageUpload()"
                                                                    >
                                                                        <v-icon>mdi-attachment</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Attach Image</span>
                                                        </v-tooltip>
                                                    </v-flex>
                                                </v-row>
                                                <v-dialog v-model="batch_addition.dialog" persistent max-width="30%"
                                                >
                                                    <v-card>
                                                        <v-row class="m-0">
                                                            <v-col>
                                                            <v-card-title>
                                                                <span class="headline">{{ batch_addition_text.title }}</span>
                                                            </v-card-title>
                                                            </v-col>
                                                            <v-col cols="pull-right-10 p-2">
                                                                <v-btn text icon color="gray" class="float-right"
                                                                    @click="closeBatchAdditionDialog(); dataFromExcel = ''">
                                                                    <v-icon>mdi-close-circle</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                        <v-card-text class="ma-0">
                                                            <span v-if="hasError"> <span style="color:red;">Red : </span>Model not found in the system</span>
                                                            <div class="pt-4 pa-2"
                                                                style="height:auto; width:100%; overflow-y:auto; overflow-x:hidden">
                                                                <v-row class="mx-0 pa-0 ma-0"
                                                                    v-for="(text_area_field, i) in text_area_fields" :key="i">
                                                                    <div class="col-5 pa-0 ma-0" >
                                                                        <v-text-field
                                                                            :class="{notInModel: text_area_fields[i].error}"
                                                                            class="InModel"
                                                                            dense
                                                                            outlined
                                                                            v-model="text_area_fields[i].dataFromExcel"
                                                                            rows="1"
                                                                            small color="primary"
                                                                            label="Model"
                                                                            :autofocus="batch_addition.dialog"
                                                                            @paste="onPaste"
                                                                            @change="validateInputModel(i)"
                                                                            @click="$event.target.focus()">
                                                                        </v-text-field >
                                                                    </div>
                                                                    <div class="col-5 py-0 ma-0">
                                                                        <v-text-field
                                                                            :class="{notInModel: text_area_fields[i].error}"
                                                                            class="InModel"
                                                                            dense
                                                                            outlined
                                                                            label="Quantity"
                                                                            v-model="text_area_fields[i].dataFromExcel2"
                                                                            rows="1"
                                                                            small
                                                                            color="primary"
                                                                            >
                                                                        </v-text-field >
                                                                    </div>
                                                                    <div class="col-2  ma-0">
                                                                        <v-btn
                                                                            fab
                                                                            dark
                                                                            small
                                                                            color="red"
                                                                            style="height:20px; width:20px;"
                                                                            @click="clearItem(i)">
                                                                            <v-icon
                                                                                dark
                                                                                small
                                                                                style="font-size:12px">
                                                                                mdi-minus
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col class="ml-3 mb-1">
                                                                        <v-btn
                                                                        dark
                                                                        small
                                                                        color="primary"
                                                                        style="height:20; width:40px;"
                                                                        @click="manualAddItem()">
                                                                            <span>
                                                                                Add
                                                                            </span>
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                            <v-row class="mt-2">
                                                                <div class="col-12">
                                                                    <v-btn :disabled="isDisabledButton" class="float-right ml-2" @click="submittedBtn()">
                                                                        Submit
                                                                    </v-btn>
                                                                    <v-btn class="float-right" dark color="secondary"
                                                                        @click="clearFields()">
                                                                        Clear
                                                                    </v-btn>
                                                                </div>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-dialog>

                                                <div class="col-sm-12 col-md-12 mt-4">
                                                    <v-simple-table class="dynamic_list_table">
                                                        <template v-slot:default>
                                                            <span v-if="!is_receiving">
                                                                <thead class="mb-4">
                                                                    <tr>
                                                                        <th class="text-center mx-2" width="7%">UOM Qty</th>
                                                                        <th class="text-center mx-2" width="15%">Model</th>
                                                                        <th class="text-center mx-2" width="55%">Name</th>
                                                                        <th class="text-center mx-2" width="5%">UOM</th>
                                                                        <th class="text-center mx-2" width="5%">Type</th>
                                                                        <th class="text-center mx-2" width="1%"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(inbound_item, i) in inbound_items" :key="i">
                                                                        <td>
                                                                            <v-text-field
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="inbound_items[i].quantity"
                                                                                @blur="computeMBQty(i)">
                                                                            </v-text-field>
                                                                        </td>


                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                v-model="inbound_items[i].model"
                                                                                option-value="value" option-text="text"
                                                                                placeholder="Model" class="alignText mt-2"
                                                                                :isError="inbound_items[i].active === 0">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" small mt-2"
                                                                                v-model="inbound_items[i].name" >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td v-if="isViewEdit">
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" alignText small mt-2"
                                                                                v-model="inbound_items[i].item.item_uom_text"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td v-else>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" alignText small mt-2"
                                                                                v-model="inbound_items[i].item_uom_text"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" alignText small mt-2"
                                                                                v-model="inbound_items[i].item_type_text"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                color="primary"
                                                                                style="height:20px; width:20px;"
                                                                                @click="removeOrderByIndex(i)">
                                                                                <v-icon
                                                                                    dark
                                                                                    small
                                                                                    style="font-size:12px">
                                                                                    mdi-minus
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </span>
                                                            <span v-else>
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center mx-2" width="7%">{{ received_type
                                                                                == '1' ? 'Receive MB Qty' : 'Receive UOM Qty'
                                                                        }}
                                                                        </th>

                                                                        <th class="text-center mx-2" width="7%">UOM Qty</th>
                                                                        <!-- <th class="text-center mx-2" width="7%">MB Conv</th> -->
                                                                        <th class="text-center mx-2" width="15%">Model</th>
                                                                        <th class="text-center mx-2" width="45%">Name</th>
                                                                        <th class="text-center mx-2" width="5%">UOM</th>
                                                                        <th class="text-center mx-2" width="5%">Type</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(inbound_item, i) in inbound_items" :key="i">
                                                                        <td>
                                                                            <v-text-field
                                                                                hide-details
                                                                                dense
                                                                                outlined

                                                                                class="alignText mt-2"
                                                                                placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="inbound_items[i].quantity_received"
                                                                                @input="computeMBQty(i)">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="inbound_items[i].quantity" >
                                                                            </v-text-field>
                                                                        </td>
                                                                        <!-- <td v-if="isViewEdit">
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="inbound_items[i].item.master_box_qty"
                                                                                >
                                                                            </v-text-field>
                                                                        </td>

                                                                        <td v-else>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText mt-2" placeholder="0"
                                                                                :id="'quantity_' + i"
                                                                                v-model="inbound_items[i].master_box_qty"
                                                                                >
                                                                            </v-text-field>
                                                                        </td> -->
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" small mt-2"
                                                                                v-model="inbound_items[i].model">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class=" small mt-2"
                                                                                v-model="inbound_items[i].name">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText small mt-2"
                                                                                v-model="inbound_items[i].item_uom_text">
                                                                            </v-text-field>
                                                                        </td>
                                                                        <td>
                                                                            <v-text-field
                                                                                background-color="grey lighten-2"
                                                                                color="black"
                                                                                hide-details
                                                                                dense
                                                                                outlined
                                                                                readonly
                                                                                class="alignText small mt-2"
                                                                                v-model="inbound_items[i].item_type_text">
                                                                            </v-text-field>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </span>
                                                        </template>
                                                    </v-simple-table>
                                                </div>
                                            </div>
                                        </v-card>
                                    </div>
                                    <div class="border-top">
                                        <div class="card-body py-4 d-flex justify-content-center">
                                            <v-btn :loading="waitForProcess" @click="addNewInbound()" v-if="action == 'Add'">
                                                Submit</v-btn>
                                            <v-btn :loading="waitForProcess" @click="editInbound()"
                                                v-else-if="action == 'Edit'">
                                                Update</v-btn>
                                        </div>
                                    </div>
                                </form>
                            </v-card-text>
                    <v-card-text v-else-if="loaded == false">
                        <div class="text-center">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                        </v-col>
                    </v-container>
                </v-card>
                <!-- IMAGE UPLOAD DIALOG -->
                <v-dialog v-model="imageUpload" persistent max-width="20%">
                    <v-card class="pa-4">
                        <v-btn
                            text
                            icon
                            color="gray"
                            class="float-right"
                            @click="closeImageUpload()"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                        <div class="py-2">
                            <span>Attach Image File</span><span style="color:red">  *</span>
                            <v-divider/>
                        </div>
                        <div class="py-2 mt-2">
                            <v-layout row class="mx-0">
                                <v-flex lg2 class="mr-2">
                                    <BaseCameraCaptureVue ref="cameraCapture" @capturedImages="getImages" />
                                </v-flex>
                                <div class="mt-2">
                                    <v-chip
                                        v-if="!!images && images.length > 0"
                                        color="#f69f1a"
                                        outlined
                                        close
                                        @click:close="clearCameraCapture()"
                                    >
                                        <span class="mr-2">Images Count: {{ images.length }}</span>
                                    </v-chip>
                                </div>
                            </v-layout>
                        </div>

                        <div class="py-2 mt-3">
                            <v-layout row class="mx-0">
                                <v-flex :class="{'mr-2': true, 'lg3': !!uploadedImageFile && uploadedImageFile.attachments.length > 1, 'lg6': !!uploadedImageFile && uploadedImageFile.attachments.length == 1}">
                                    <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearImageUpload"/>
                                </v-flex>
                                <v-flex v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0" class="ml-2" ></v-flex>
                                    <div class="mt-2">
                                        <v-chip
                                            v-if="!!uploadedImageFile && uploadedImageFile.attachments.length > 0"
                                            color="#f69f1a"
                                            outlined
                                            close
                                            @click:close="clearImageUpload()"
                                        >
                                            <span class="mr-2">Upload Count: {{ uploadedImageFile.attachments.length }}</span>
                                        </v-chip>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div class="py-2 mt-3">
                            <v-card-actions class="d-flex justify-content-end">
                                <v-btn  @click="closeImageUpload()">Submit</v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>
            </v-dialog>
        </v-app>
    </v-row>


</template>

<script>
    // import usersTable from './tables/StockAdjustmentTable.vue';
    // import batchAddtionDialog from '../../layouts/dialogs/IT/StockAdjustmentDialog.vue';
    // import Dialogs from '../../layouts/dialogs/Dialog.vue'
    // import buttons from '@/views/main/layouts/Buttons/DialogButton.vue';
    // import buttons_components from '@/assets/js/components-js.js';
    import InboundTableComponent from './tables/InboundTableComponent.vue'
    import { computed } from 'vue'
    import { mapGetters } from 'vuex';
    import getters from '@/store/getters';
    import swal from 'sweetalert2'
    import moment from 'moment';
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue';
    import BaseCameraCaptureVue from '@/views/main/modules/Services/Base/BaseCameraCapture.vue';
    const ExcelJS = require('exceljs');

        export default{
            mixins:[SharedFunctionsComponentVue],
            props: [
                'is_pending',
                'is_receiving',
                'is_approved',
                'radioGroup',
            ],
            components:{
                // buttons,
                // buttons_components,
                InboundTableComponent,
                FileUploadComponentVue,
                BaseCameraCaptureVue
            },
            data(){
                return{
                    color:'orange',
                    // buttons:buttons_components.buttons,
                    action:'',
                    batch_addition: {
                        dialog: false,
                        data: [],
                        excel_item_id: null,
                        errors: []
                    },
                    dataFromExcel: '',
                    dataFromExcel2: '',
                    editInboundId: '',
                    add_edit_inbound_dialog: false,
                    inbound_num: '',
                    loading_list_id: '',
                    loading_lists: [],
                    warehouse_id: '',
                    warehouses: [],
                    supplier: '',
                    remarks: '',
                    inbound_items: [],
                    text_area_fields: [],
                    received_type: '0',
                    text_area_field: {
                        dataFromExcel: '',
                        dataFromExcel2: '',
                    },
                    inbound_item: {
                        item_id: '',
                        quantity: '',
                        quantity_entered: '',
                        remarks: '',
                        packing_type: '',
                        id: '',
                        active: '',
                        model: '',
                        name: '',
                        type_id: '',
                        item_type_text: '',
                        uom_id: '',
                        item_uom_text: '',
                        master_box_qty: '',
                        small_box_qty: '',
                        quantity_master_box: '',
                        loading_list_item_id: '',
                    },
                    scanner: {
                        item_model: '',
                        item_barcode: 0,
                        quantity: 0
                    },
                    autoOpenCamera: 0,
                    date_created: '',
                    user: '',
                    items_list: [],
                    status: '',
                    max_items_count: 999,
                    errors: [],
                    action: '',
                    actionResult: {
                        model: 'Inbound',
                        action: ''
                    },
                    loaded: false,
                    userAccess:[],

                    packing_type_list: [
                        { value: 1, text: 'UOM' },
                        { value: 2, text: 'MB' },
                        { value: 3, text: 'SB' },
                    ],

                    waitForProcess: false,
                    po_id: {
                        selection: [],
                        selected: ''
                    },
                    received_date: null,
                    selected_po_number: '',
                    isDone: true,
                    isViewEdit:false,
                    hasError:false,
                    isDisabledButton:false,
                    listOfNotInModels:[],
                    counterBatch:0,
                    inbound_item_id:'',
                    batch_addition_text:{
                        title: "Batch Addition",
                        quantity_text: "Quantity"
                    },
                    receiving_data:[],
                    inbound_title: "Inbound",
                    disabled_batch_addition: false,
                    loading:{
                        warehouse_selection:true
                    },
                    estimated_warehouse_delivery:'',
                    menu2:false,
                    exportLoading: false,
                    imageUpload: false,
                    images: false,
                    uploadedImageFile: null,
                }
        },
        methods:{
            onScanSuccess(decodedText, decodedResult) {
                console.log(`Code scanned = ${decodedText}`, decodedResult);
            },
            calculateFinalQty(index) {
                if (this.inbound_items[index].item_id != '') {
                    let multiplier = 0
                    switch (this.inbound_items[index].packing_type) {
                        case 1:
                            multiplier = 1
                            break
                        case 2:
                            multiplier = this.inbound_items[index].item.master_box_qty
                            break
                        case 3:
                            multiplier = this.inbound_items[index].item.small_box_qty
                            break
                    }
                    let quantity_entered = this.inbound_items[index].quantity_entered == '' ? 0 : parseInt(this.inbound_items[index].quantity_entered)
                    this.inbound_items[index].quantity = quantity_entered * multiplier
                }
            },
            checkMBMoq(index) {

                let mb_qty = 0;
                if(this.isViewEdit){
                    mb_qty = this.inbound_items[index].quantity / this.inbound_items[index].item.master_box_qty
                }
                else{
                    mb_qty = this.inbound_items[index].quantity / this.inbound_items[index].master_box_qty
                }

                if (mb_qty % 1 != 0) {
                    swal.fire(
                        ''
                        `UOM qty does not convert to MB qty properly (Result: ${mb_qty}). \n\nPlease check item MB conversion or UOM qty entered`,
                        'warning')
                        .then(result => {
                            this.inbound_items[index].quantity = ''
                            return 0
                        })
                } else {
                    return mb_qty
                }
            },
            myFunction() {
                this.text_area_fields.splice(0, 1);
                this.batch_addition.dialog = true;
            },
            closeBatchAdditionDialog() {
                this.batch_addition.dialog = false;
            },
            closeAddEditDialog(){
                this.add_edit_inbound_dialog = false;
                this.isViewEdit = false;
                this.clearImageUpload();
                this.clearCameraCapture();
            },
            openBatchDialog() {
                this.batch_addition.dialog = true;
                this.isDone = true;
                this.text_area_fields = [];

                if(this.text_area_fields.length < 1){
                    this.text_area_fields.push({
                        dataFromExcel: '',
                        dataFromExcel2: '',
                        error:false
                    })
                }
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.hasError = false
            },
            onPaste(evt) {
                this.batch_addition.data = []
                var pastedText = evt.clipboardData.getData('Text');
                if(!pastedText){
                    return false;
                }
                else{
                    var rows = pastedText.split("\r\n")
                    var cells = '';
                    for (var y in rows) {
                        if (y != rows.length - 1) {
                            cells = rows[y].split("\t");
                            this.text_area_fields.push({
                                dataFromExcel: cells[0],
                                dataFromExcel2: cells[1],
                                error:false
                            })
                            if (y == rows.length - 1){
                                this.text_area_fields.push({
                                    dataFromExcel: cells[0],
                                    dataFromExcel2: cells[1],
                                    error:false
                                })
                            }
                            if(this.is_receiving){
                                this.receiving_data.push(Object.assign({},{
                                    model: cells[0].trim(),
                                    qty: cells[1].trim(),
                                    // foc: cells[2],
                                    id: '',
                                    skip: false,
                                }))
                            }
                            else{
                                this.batch_addition.data.push(Object.assign({}, {
                                    model: cells[0].trim(),
                                    qty: cells[1].trim(),
                                    // foc: cells[2],
                                    id: '',
                                    skip: false,
                                }))
                            }
                        }
                    }
                let payload={
                    items: this.text_area_fields.map(e=>{ return e.dataFromExcel}),
                    isChecking:true
                }
                this.$store.dispatch('getItemsByModel',payload).then(response => {
                    response.data = this.GET_ITEMS_BY_MODELS.map(e=>{ return e.model})
                    this.text_area_fields.forEach((e, i)=>{
                        if(response.data.includes(e.dataFromExcel.toUpperCase())){
                        }
                        else{
                            this.listOfNotInModels.push(e.dataFromExcel.toUpperCase());
                            this.text_area_fields[i].error = true;
                        }
                    })
                    if(this.listOfNotInModels.length > 0){
                        this.isDisabledButton = true
                        this.hasError = true
                    }
                });
                this.isDone = false;
                this.closeBatchAdditionDialog();
                setTimeout(this.myFunction, 1);
                }
            },
            submittedBtn() {
                if(this.isViewEdit == true && !this.is_receiving){
                    for (var i = 0; i < this.text_area_fields.length; i++) {
                        this.inbound_items.push({
                            inbound_id: this.editInboundId,
                            id: this.inbound_num,
                            item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                            quantity: parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                            quantity_entered:parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                            remarks: '',
                            packing_type: '',
                            item: {
                                id: '',
                                active: '',
                                model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                                name: '',
                                type_id: '',
                                item_type_text: '',
                                uom_id: '',
                                item_uom_text: '',
                                master_box_qty: '',
                                small_box_qty: '',
                            },
                            quantity_master_box: '',
                            quantity_entered: parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                            remarks:'',
                            uom_id:'',
                            quantity_master_box_conv:'',
                            model:this.text_area_fields[i].dataFromExcel,
                            loading_list_item_id: '',
                            item_uom_text: '',
                            item_type_text: '',
                        })

                    }
                    this.fetchAllData();
                    this.counterBatch++;
                    this.batch_addition.dialog = false;
                }
                else if(this.isViewEdit == false && !this.is_receiving){
                    this.isViewEdit = false;
                    if (this.inbound_items.length < this.max_items_count) {
                        if(this.counterBatch == 0){
                            this.inbound_items.pop();
                        }
                        for (var i = 0; i < this.text_area_fields.length; i++) {
                            this.inbound_items.push({
                                item_id: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                                quantity: parseInt(this.text_area_fields[i].dataFromExcel2.toString().replace(',','')),
                                quantity_entered: '',
                                quantity_received: '',
                                remarks: '',
                                packing_type: '',
                                id: '',
                                active: '',
                                model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                                name: '',
                                type_id: '',
                                item_type_text: '',
                                uom_id: '',
                                item_uom_text: '',
                                master_box_qty: '',
                                small_box_qty: '',
                                quantity_master_box: '',
                                loading_list_item_id: '',
                            })
                        }
                        this.fetchAllData();
                        this.counterBatch++;
                        this.batch_addition.dialog = false;
                        this.disabled_batch_addition = true;
                    }
                }
                if(this.is_receiving){
                    let raw_model = this.text_area_fields.map(e=>{return e.dataFromExcel.toUpperCase()});
                    let counter = 0;
                    let x = 0
                    let inbound_models = this.inbound_items.map(e=>{return e.model})
                    try{
                        while(x < this.text_area_fields.length){
                            this.inbound_items.forEach(e=>{
                                if(counter == this.text_area_fields.length){
                                    this.batch_addition.dialog = false;
                                    x = this.text_area_fields.length;
                                }
                                else if(inbound_models.includes(this.text_area_fields[counter].dataFromExcel.toUpperCase())){
                                    if(raw_model.find(element => element === e.model)){
                                        if(e.model == this.text_area_fields[counter].dataFromExcel.toUpperCase()){
                                            e.quantity_received = this.text_area_fields[counter].dataFromExcel2
                                            counter++;
                                            x++;
                                        }
                                    }
                                }
                                else{
                                    x = (this.text_area_fields.length + 1)
                                }
                            })
                            if(x === (this.text_area_fields.length + 1)){
                                swal.fire("Item not found in this inbound","Please check your item",'error')
                                break;
                            }
                        }

                        this.batch_addition.dialog = false;
                    }
                    catch(e){
                        swal.fire("Someting went wrong",e+"","error")
                        console.log(e)
                    }
                }
            },
            computeMBQty(index) {
                if (this.is_receiving) {
                    // if (this.inbound_items[index].item_id != '') {
                    //     if (this.inbound_items[index].quantity_received > this.inbound_items[index].quantity_master_box) {
                    //         this.inbound_items[index].quantity_received = this.inbound_items[index].quantity_master_box
                    //     }
                    // }
                } else {
                    if (this.inbound_items[index].item_id != '') {
                        this.inbound_items[index].quantity_master_box = this.checkMBMoq(index)
                    } else {
                        this.inbound_items[index].quantity_master_box = 0
                    }
                }
            },
            showEditModal(value, is_receiving = false) {
                this.action = 'Edit'
                this.editInboundId = value
                this.text_area_fields.length = 0;
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.isViewEdit = true;
                this.disabled_batch_addition = false;
                if(this.is_receiving){
                    this.batch_addition_text.quantity_text = "Receive MB Quantity";
                    this.inbound_title = "Inbound (Receiving)"
                }
                if (this.editInboundId != null && this.editInboundId > 0) {
                    this.$store.dispatch('viewInbound',this.editInboundId).then(response => {
                        let data = response.data

                        this.inbound_item_id = data.id
                        this.inbound_num = data.inbound_num
                        this.warehouse_id = data.warehouse_id
                        this.loading_list_id = data.loading_list_id
                        this.supplier = data.supplier
                        this.remarks = data.remarks
                        this.inbound_items = data.inbound_items
                        this.selected_po_number = data.po_id
                        this.status = data.status
                        this.received_date = data.received_date
                        this.estimated_warehouse_delivery = this.dateWithFormat(data.estimated_warehouse_delivery,'YYYY-MM-DD')

                        if (!!this.po_id.selection.find(e => e.value === this.selected_po_number)) {
                            this.selected_po_number = this.po_id.selection.find(e => e.value === this.selected_po_number)
                            this.po_id.selected = this.selected_po_number.value
                        }

                        this.add_edit_inbound_dialog = true
                    }).catch(error => {
                        console.log(error)
                    });
                }
            },
            removeItem() {
                if (this.inbound_items.length > 1) {
                    this.inbound_items.pop();
                }
            },
            clearFields() {
                this.isDone = true;
                this.text_area_fields = [];
                if(this.text_area_fields.length < 1){
                    this.text_area_fields.push({
                        dataFromExcel: '',
                        dataFromExcel2: '',
                        error:false
                    })
                }
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.isDisabledButton = false;
                this.hasError = false;
            },
            clearItem(i) {
                let temp = [];
                if(this.text_area_fields.length == 1)
                {
                    this.clearFields();
                }
                else{
                    if(this.listOfNotInModels.length > 1 ){

                    }
                    else{
                        this.isDisabledButton = false;
                    }
                    this.text_area_fields.forEach((e, index) => {
                        if (i != index) {
                            temp.push(e);
                            let checkingOfError = this.listOfNotInModels.includes(this.text_area_fields[i].dataFromExcel)
                            if(checkingOfError){
                                this.listOfNotInModels.pop();
                            }
                        }
                    })
                    this.text_area_fields = temp;
                }
            },
            removeOrderByIndex(index) {
                if(this.inbound_items.length == 1){
                    this.inbound_items = [];
                    this.inbound_items.push({
                        item_id: '',
                        quantity: '',
                        quantity_entered: '',
                        quantity_received: '',
                        remarks: '',
                        packing_type: '',
                        id: '',
                        active: '',
                        model: '',
                        name: '',
                        type_id: '',
                        item_type_text: '',
                        uom_id: '',
                        item_uom_text: '',
                        master_box_qty: '',
                        small_box_qty: '',
                        quantity_master_box: '',
                        loading_list_item_id: '',
                    })
                    return false;
                }
                else{
                    this.inbound_items.splice(index, 1)
                }
            },
            addItem() {
                if (this.inbound_items.length < this.max_items_count) {
                    this.inbound_items.push({
                        item_id: '',
                        quantity: '',
                        quantity_entered: '',
                        quantity_received: '',
                        remarks: '',
                        packing_type: '',
                        id: '',
                        active: '',
                        model: '',
                        name: '',
                        type_id: '',
                        item_type_text: '',
                        uom_id: '',
                        item_uom_text: '',
                        master_box_qty: '',
                        small_box_qty: '',
                        quantity_master_box: '',
                        loading_list_item_id: '',
                    })
                }
            },
            showAddInbound() {
                this.inbound_items = [];
                this.isDone = true;
                this.text_area_fields.length = 0;
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.isViewEdit = false;
                this.counterBatch = 0;
                this.disabled_batch_addition = false;
                if (this.po_id.selection.find(e => e.value === this.po_id.selected)) {
                    this.selected_po_number = this.po_id.selection.find(e => e.value === this.po_id.selected).text
                }
                this.add_edit_inbound_dialog = true
                this.action = 'Add'
            },
            getWarehouseItemStock(item_id, warehouse_id, index) {
                let payload = {
                    item_id: item_id,
                    warehouse_id: warehouse_id,
                }
                this.$store.dispatch('getWarehouseItemStocks',payload).then(response => {
                    let data = response.data

                    if (data != null) {
                        this.inbound_items[index].stock = data
                    }
                    else{
                        this.inbound_items[index].stock = 0
                    }
                });
            },
            closeQrScannerDialog(confirm) {
                if (confirm) {

                }
                return false
            },
            async addNewInbound() {
                if (!!this.warehouse_id) {
                    let inboundId = null;
                    if (!!this.uploadedImageFile || !!this.images) {
                        this.waitForProcess = true
                        this.errors = []
                        this.counterBatch = 0;
                        let payload = {
                            inbound_num: this.inbound_num,
                            warehouse_id: this.warehouse_id,
                            loading_list_id: this.loading_list_id,
                            supplier: this.supplier,
                            remarks: this.remarks,
                            inbound_items: this.inbound_items,
                            po_id: this.po_id.selected,
                            estimated_warehouse_delivery:this.estimated_warehouse_delivery
                        }
                        await this.$store.dispatch('addNewInbound',payload).then(response => {
                            let data = this.ADD_NEW_INBOUND
                            let inbound = data.inbound;
                            inboundId = inbound.id;
                            if (!!data.error) {
                                swal.fire("Warning", data.error, "warning");
                                this.waitForProcess = false
                                return false
                            }
                            this.resetFields()
                            this.add_edit_inbound_dialog = false
                            this.action = 'success'
                            swal.fire("", data.msg, "success");
                            this.waitForProcess = false
                            this.$refs.child.getAllInbound();
                        }).catch(error => {
                            if (error) {
                                this.errors = error
                                this.waitForProcess = false
                            }
                            this.waitForProcess = false
                        });
                        // Save inbound file
                        await this.storeImageUpload(inboundId)
                    } else {
                        swal.fire("Info", "-Need to attach an image.", "info");
                    }
                } else {
                    swal.fire("Warning", "-Need to fill required fields. ", "warning");
                }
            },
            editInbound() {
                this.waitForProcess = true
                this.errors = []
                let not_received = []
                this.inbound_items.forEach(e=>{
                    if(e.quantity_received == 0){
                        not_received.push(e.model)
                    }
                })
                if(not_received.length > 0 && this.is_receiving){
                    swal.fire({
                        title: 'Some items has 0 Receive MB Quantity',
                        text: ''+not_received.join("\n")+"\n\n Do you still want to continue? \n",
                        icon:"warning",
                        buttons: true,
                    }).then(confirm => {
                        if (confirm) {
                            this.saveEditInbound();
                            this.$refs.child.getAllInbound();
                        } else {
                            this.waitForProcess = false
                            return false
                        }
                    })
                }else{
                    this.saveEditInbound();
                }
            },
            resetFields() {
                this.errors = []
                this.inbound_num = ''
                this.warehouse_id = ''
                this.loading_list_id = ''
                this.inbound_items = []
                this.supplier = ''
                this.remarks = ''
                this.user = ''
                this.action = ''
                this.po_id.selected = ''
            },
            addKeyListener() {
                window.addEventListener("keyup", function (e) {
                    var keypressed = String.fromCharCode(e.keyCode)
                    if (e.ctrlKey && e.keyCode == 32) {
                        this.addItem()
                        $("input").blur();

                    } else if (keypressed == '-') {
                        this.removeItem()
                        $("input").blur();
                    }
                }.bind(this));
            },
            async fetchAllData() {
                if(this.is_receiving){
                    return false;
                }
                if(this.isViewEdit == true){
                    let that = this;
                    let duplicate_models = []
                    try {
                        let dataCounter = 0;
                        let models = this.text_area_fields.map(e => {
                            return e.dataFromExcel.toUpperCase();
                        });
                        let mb_qty = this.inbound_items.map(e => {
                            return e.master_box_qty;
                        });

                        let filteredModel = models.filter(function (model, pos) {
                            return models.indexOf(model) != pos;
                        });

                        duplicate_models.push(filteredModel);
                        let showItemsAsString = duplicate_models.join("\r\n");

                        if (filteredModel.length > 0) {
                            swal.fire({
                                title: 'Warning',
                                text: 'Duplicate model detected \n NOTE: Model with duplicates will be automatically removed \n \n' + showItemsAsString.split(',').join("\r\n"),
                                showConfirmButton: true,
                            }).then(result => {
                                if (result.isConfirmed) {

                                } else {
                                    return false
                                }
                            })
                        }
                        let quantities = this.inbound_items.map(e => {
                            return e.quantity;
                        });
                        let quantity_master_boxes = this.inbound_items.map(e => {
                            return e.quantity_master_box;
                        });
                        let payload = {
                            items: models,
                            isChecking:false
                        }
                        this.$store.dispatch('getItemsByModel',payload).then(response => {

                            let designatedIndex = this.inbound_items.length - this.GET_ITEMS_BY_MODELS.length;

                            for (let x = designatedIndex; x < this.inbound_items.length; x++){
                                let data = this.GET_ITEMS_BY_MODELS[dataCounter];

                                this.inbound_items[x].inbound_id               = this.editInboundId
                                this.inbound_items[x].id                         = this.inbound_item_id
                                this.inbound_items[x].item_id                    = data.item_id
                                this.inbound_items[x].quantity_entered           = that.inbound_items[x].quantity
                                this.inbound_items[x].remarks                    = this.remarks
                                this.inbound_items[x].item_type_text             = data.item_type_text
                                this.inbound_items[x].pending_quantity_received  = that.inbound_items[x].quantity
                                this.inbound_items[x].model                      = data.model
                                this.inbound_items[x].name                       = data.name
                                this.inbound_items[x].quantity_master_box_conv   = that.inbound_items[x].quantity
                                this.inbound_items[x].received_type              = 0
                                this.inbound_items[x].uom_id                     = data.uom_id

                                this.inbound_items[x].item.active                = data.active
                                this.inbound_items[x].item.model                 = data.model
                                this.inbound_items[x].item.name                  = data.name
                                this.inbound_items[x].item.item_type_text        = data.item_type_text
                                this.inbound_items[x].item.type_id               = data.type_id
                                this.inbound_items[x].item.uom_id                = data.uom_id
                                this.inbound_items[x].item.item_uom_text         = data.item_uom_text
                                this.inbound_items[x].item.master_box_qty        = data.master_box_qty
                                this.inbound_items[x].item.small_box_qty         = data.small_box_qty

                                this.inbound_items[x].item.id                    = data.item_id
                                if (this.inbound_items[x].item_id != '') {
                                        let quo = (that.inbound_items[x].quantity / data.master_box_qty)
                                        data.quantity_master_box = quo
                                    } else {
                                        data.quantity_master_box = 0
                                    }
                                this.inbound_items[x].quantity_master_box   = data.quantity_master_box
                                dataCounter++;

                            };
                        });
                    } catch (err) {
                        console.log(err)
                    }
                }
                else{
                    this.isViewEdit = false;
                    let that = this;
                    let duplicate_models = []
                    try {
                        let models = this.text_area_fields.map(e => {
                            return e.dataFromExcel.toUpperCase();
                        });
                        let mb_qty = this.inbound_items.map(e => {
                            return e.master_box_qty;
                        });
                        let filteredModel = models.filter(function (model, pos) {
                            return models.indexOf(model) != pos;
                        });
                        duplicate_models.push(filteredModel);
                        let showItemsAsString = duplicate_models.join("\r\n");
                        if (filteredModel.length > 0) {
                            swal.fire({
                                title: 'Warning',
                                text: 'Duplicate model detected \n NOTE: Model with duplicates will be automatically removed \n \n' + showItemsAsString.split(',').join("\r\n"),
                                buttons: true,
                            }).then(confirm => {
                                if (confirm) {

                                } else {
                                    return false
                                }
                            })
                        }
                        let quantities = this.inbound_items.map(e => {
                            return e.quantity;
                        });
                        let quantity_master_boxes = this.inbound_items.map(e => {
                            return e.quantity_master_box;
                        });
                        let payload = {
                            items: models,
                            isChecking:false
                        }
                        this.$store.dispatch('getItemsByModel',payload).then(response => {
                            that.inbound_items = this.GET_ITEMS_BY_MODELS.filter((e, i) => {
                                e.quantity = quantities[i];
                                e.model = models[i];
                                e.quantity_master_box = quantity_master_boxes[i]

                                that.inbound_items[i].quantity = e.quantity
                                that.inbound_items[i].id = e.id
                                that.inbound_items[i].item_id = e.model

                                return e;
                            })
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
            },
            manualAddItem(){
                this.text_area_fields.push({
                    dataFromExcel: '',
                    dataFromExcel2: '',
                    error:false
                })
            },
            saveEditInbound(){
                let params = {
                    id:this.editInboundId,
                    inbound_num: this.inbound_num,
                    warehouse_id: this.warehouse_id,
                    supplier: this.supplier,
                    remarks: this.remarks,
                    inbound_items: this.inbound_items,
                    po_id: this.po_id.selected,
                    received_type: parseInt(this.received_type),
                    estimated_warehouse_delivery:this.estimated_warehouse_delivery
                }
                this.$store.dispatch('editInbound',params).then(response => {
                    let data = this.EDIT_INBOUND
                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }
                    this.resetFields()
                    this.add_edit_inbound_dialog = false
                    this.editInboundId = 0
                    this.action = 'success'
                    swal.fire("", 'Inbound Update Successful', "success");
                    this.waitForProcess = false
                }).catch(error => {
                    if (error.response.status == 422) {
                        this.errors = error.this.EDIT_INBOUND.errors
                    }
                });

                if (!!this.uploadedImageFile || this.images) {
                    // Save inbound file
                    this.storeImageUpload(this.editInboundId);
                }

            },
            validateInputModel(index){
                if(this.listOfNotInModels.length != 0){
                    let payload = {
                        items: this.text_area_fields.map(e=>{return e.dataFromExcel.toUpperCase()}),
                        isChecking:true
                    }
                    this.$store.dispatch('getItemsByModel',payload).then(response => {
                        response.data = response.data.map(e=>{return e.model})
                        if(response.data.includes(this.text_area_fields[index].dataFromExcel.toUpperCase())){
                            this.isDisabledButton = false;
                            this.text_area_fields[index].error = false
                        }
                        else{
                            this.text_area_fields[index].error = true
                            this.isDisabledButton = true;
                        }
                    });
                }
                else{
                    let payload = {
                        items: this.text_area_fields.map(e=>{return e.dataFromExcel.toUpperCase()}),
                        isChecking:true
                    }
                    this.$store.dispatch('getItemsByModel',payload).then(response => {
                        response.data = response.data.map(e=>{return e.model})
                        if(response.data.includes(this.text_area_fields[index].dataFromExcel.toUpperCase())){
                            this.text_area_fields[index].error = false
                        }
                        else{
                            this.text_area_fields[index].error = true
                            this.listOfNotInModels.push(this.text_area_fields[index].dataFromExcel.toUpperCase())
                            this.isDisabledButton = true;
                        }
                    });
                }
            },
            getWarehouses(no_virtual = false){
                // let payload = {

                // }
                // this.$store.dispatch('getAllWarehouses',payload).then((response)=>{

                //     this.warehouses = this.GET_ALL_WAREHOUSES
                // });
            },
            exportLpiToExcel() {
                this.exportLoading = true;

                this.$store.dispatch('exportLpiToExcel',{}).then(response=>{
                    let workbook = new ExcelJS.Workbook()
                    let worksheet = workbook.addWorksheet('LPI')
                    worksheet.columns = [
                            { header: 'Inbound Num', key: 'inbound_num' },
                            { header: 'Warehouse', key: 'warehouse' },
                            { header: 'Date Created', key: 'created_date' },
                            { header: 'Created By', key: 'created_by' },
                            { header: 'Received By', key: 'received_by' },
                            { header: 'Received Date', key: 'received_date' },
                            { header: 'Confirmed Date', key: 'confirmed_date' },
                            { header: 'Status', key: 'status' },
                            { header: 'Item Model', key: 'item_model' },
                            { header: 'Item Name', key: 'item_name' },
                            { header: 'Item Uom', key: 'item_uom' },
                            { header: 'Item Type', key: 'item_type' },
                            { header: 'Quantity', key: 'quantity' },
                            { header: 'Recieved Qty', key: 'quantity_received' },
                        ];
                    this.GET_ALL_API_DETAILS.forEach(record => {
                        worksheet.addRow(record)
                    });
                    worksheet.getRow(`0${1}`).font = { bold: true };

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        saveAs(blob, 'LPI Details.xlsx');
                        this.exportLoading = false;
                    })

                }).catch(error => {
                    swal.fire('Error !', '', 'error');
                    console.log(error);
                    this.exportLoading = false;
                });
            },
            showImageUpload() {
                this.imageUpload = true;
            },
            closeImageUpload() {
                this.imageUpload = false;
            },
            getImages(images) {
                this.images = images;
            },
            uploadedData(data) {
                if(!data) {
                    this.uploadedImageFile = null;
                }

                this.uploadedImageFile = data;
            },
            clearImageUpload() {
                this.uploadedImageFile = null;
                this.$refs.fileUpload.reset();
            },
            clearCameraCapture() {
                this.$refs.cameraCapture.reset();
                this.images = null;
            },
            storeImageUpload(inboundId) {
                if (!!inboundId) {
                    const data = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    if(!!this.uploadedImageFile && this.uploadedImageFile.attachments.length > 0) {
                        let files = this.uploadedImageFile.attachments;

                        for(let i=0;i < _.compact(files).length;i++) {
                            data.append('files[]', files[i]);
                        }
                    }

                    if(!!this.images && this.images.length > 0) {
                        for(let i=0;i < _.compact(this.images).length;i++) {
                            data.append('images[]', _.compact(this.images)[i]);
                        }
                    }
                    data.append('inboundId', inboundId);
                    this.$store.dispatch('uploadInboundFile', {params:data, config: config}).then(response=>{
                        console.log(response.data.message);
                        this.$emit('successConfirm', true);
                        this.closeImageUpload();
                        this.clearCameraCapture();
                        this.clearImageUpload();
                    })
                } else {
                    console.error("Invalid Inbound ID");
                }
            }
        },
        watch: {
            add_edit_inbound_dialog: function () {
                if (this.add_edit_inbound_dialog == true) {
                    this.loaded = true

                    if (this.inbound_items.length == 0) {
                        this.addItem();
                    }
                }
            },
            warehouse_id: function (val) {
                if (val != null && val != '' && this.inbound_items.length > 0) {
                    this.inbound_items.forEach((item, index) => {
                        if (item.item_id != null && item.item_id != '') {
                            this.getWarehouseItemStock(item.item_id, this.warehouse_id, index)
                        }
                    })
                }
            },
            USER_ACCESS: {
                handler(val) {
                    if(val != "fail"){
                        val.forEach((e) => {
                            this.userAccess = val.map(e=>e.actions_code)
                        });
                    }
                }
            },
            'GET_ALL_WAREHOUSES':{
                handler(val){
                    this.loading.warehouse_selection = false
                }
            },
        },
        computed:{
            ...mapGetters([
                'GET_ITEMS_BY_MODELS',
                'ADD_NEW_INBOUND',
                'EDIT_INBOUND',
                'USER_ACCESS',
                'GET_ALL_WAREHOUSES',
                'ACCESS_INBOUND',
                'GET_ALL_API_DETAILS'
                ]),
        },
        mounted(){
            // this.$store.dispatch('getWarehouseItemStocks');
            // this.getWarehouses();
            this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        }
    }
    </script>

<style>

</style>
