<template>
    <div>
        <v-container fluid>
            <v-card-title>
                <v-container fluid class="pa-0 ma-0">
                    <v-row>
                        <v-col class="d-flex no-block align-items-center">
                            <h4 class="pl-3">Stock Adjustments</h4>
                        </v-col>
                    </v-row>
                    <v-container class="container pl-0">
                        <v-tabs v-model="tab" id="group-tab" color="cyan" background-color="#424242" dark slider-color="cyan" class="col-12 pb-0 mt-3" show-arrows>
                            <v-tab ripple @click="setMenu('all')">ALL</v-tab>
                            <v-tab ripple @click="setMenu('saved')">SAVED</v-tab>
                            <v-tab ripple @click="setMenu('for_approval')">WAREHOUSE APPROVED</v-tab>
                            <v-tab ripple @click="setMenu('for_exec_approval')">EXEC APPROVED</v-tab>
                            <v-tab ripple @click="setMenu('confirmed')">CONFIRMED</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab" touchless style="background-color:unset">
                            <v-tab-item>
                                <stock-adjustment-component class="pa-0 ma-0" />
                            </v-tab-item>

                            <v-tab-item>
                                <stock-adjustment-component class="pa-0 ma-0" />
                            </v-tab-item>

                            <v-tab-item>
                                <stock-adjustment-component class="pa-0 ma-0" />

                            </v-tab-item>

                            <v-tab-item>
                                <stock-adjustment-component class="pa-0 ma-0" />
                            </v-tab-item>

                            <v-tab-item>
                                <stock-adjustment-component class="pa-0 ma-0" />
                            </v-tab-item>
                        </v-tabs-items>
                    </v-container>
                </v-container>
            </v-card-title>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StockAdjustmentComponent from '../IT/StockAdjustmentComponent.vue';

export default {
    data() {
        return {
            tab: null,
            userAccess: {
                view_all: false,
                view_saved: false,
                view_for_approval: false,
                view_confirmed: false,
                view_exec_approval: false,
            }
        }
    },
    components: {
        StockAdjustmentComponent,
    },
    computed: {
        ...mapGetters([
            'USER_ACCESS',
        ])
    },
    mounted(){
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    methods: {
        setMenu(menu) {
          this.$store.commit('SET_STOCK_MENU', menu);
        }
    },
    watch: {
        USER_ACCESS:{
            handler(val){
                this.userAccess.view_all = false;
                this.userAccess.view_saved = false;
                this.userAccess.view_for_approval = false;
                this.userAccess.view_confirmed = false;
                this.userAccess.view_exec_approval = false;

                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "view_all") {
                            this.userAccess.view_all = true;
                        }
                        else if (e.actions_code == "view_saved") {
                            this.userAccess.view_saved = true;
                        }
                        else if (e.actions_code == "view_for_approval") {
                            this.userAccess.view_for_approval = true;
                        }
                        else if (e.actions_code == "view_confirmed") {
                            this.userAccess.view_confirmed = true;
                        }
                        else if (e.actions_code == "view_exec_approval") {
                            this.userAccess.view_confirmed = true;
                        }
                    });
                }
            }
        },
    }
}
</script>

<style>

</style>
