<template>
<v-container fluid class="pa-3">

    <h3 class="page-title" style="color: black">Dispatch</h3>
    <v-container class="ma-0">
        <v-app id="item-tabs" class="ma-0">
            <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" show-arrows v-if="!service">
                <v-tab ripple @click="getTabName(0)">For Dispatch</v-tab>
                <v-tab ripple @click="getTabName(2)">On going Delivery</v-tab>
                <v-tab ripple @click="getTabName(1)">Delivered</v-tab>
            </v-tabs>

            <DispatchesComponent :tab_name="tabName"></DispatchesComponent>
        </v-app>

    </v-container>

</v-container>
</template>

<script>
    // import PackingListsComponentVue from './PackingListsComponent.vue';
    import DispatchesComponent from './DispatchesComponent.vue';
    export default {
        data(){
            return {
                tab: null,
                tabName: 0,
                service:this.$router.currentRoute.meta.service
            }
        },
        components: {
            DispatchesComponent,
        },
        methods: {
            getTabName(tabName) {
                this.tabName = tabName;
            }
        },
        mounted(){
            this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        }
    };
</script>
<style>
    #group-tab{
        padding: 0 !important;
    }
</style>
