<template>
    <v-container fluid class="pa-3">
        <h3 class="page-title" style="color: black">Sales Orders</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
                    <v-tab ripple>Active SO</v-tab>
                    <v-tab ripple>Saved SO</v-tab>
                    <v-tab ripple>For Approval SO</v-tab>
                    <v-tab ripple>Approved SO</v-tab>
                    <v-tab ripple>For Scheduling SO</v-tab>
                    <!-- <v-tab ripple>For Preparing SO</v-tab> -->
                    <v-tab ripple>Completed SO</v-tab>
                    <!-- <v-tab ripple>Pending SO</v-tab> -->
                    <v-tab ripple>Stockcard SO</v-tab>
                    <v-tab ripple>Canceled SO</v-tab>
                    <v-tab v-if="!service" ripple>All SO</v-tab>

                    <v-tabs-items v-model="tab" touchless>
                        <!-- ALL SO TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_active'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- SAVED SO TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_saved'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- FOR APPROVAL TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_approval'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- APPROVED TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_approved'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- FOR SCHEDULING TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_scheduling'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- PREPARING TAB -->
                        <!-- <v-tab-item>
                            <sales-orders-component :status_text="'is_preparing'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item> -->

                        <!-- COMPLETED TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_completed'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- PENDING SO TAB -->
                        <!-- <v-tab-item>
                            <sales-orders-component :status_text="'is_pending'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item> -->

                        <!-- STOCKCARD SO TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_stockcard'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- CANCELED SO TAB -->
                        <v-tab-item>
                            <sales-orders-component :status_text="'is_canceled'" :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>

                        <!-- ALL SO TAB -->
                        <v-tab-item v-if="!service">
                            <sales-orders-component :service="service" :print="print"></sales-orders-component>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import SalesOrdersComponent from "./SalesOrdersComponent.vue";

export default {
    data() {
        return {
            tab: null,

            print: {
                order_num: '',
                customer_name: '',
                created_at: '',
                ship_address: '',
                so_terms: '',
                remarks: '',
                po_num: '',
                so_items: [],
                gross_amount: 0,
                disc_amount: 0,
                total_amount: 0,
                no_of_items: 0,
                issuer_name: '',
                itemTypeDiscountsPDF: [],
                approved_by_acct: 0,
            },
            service: this.$router.currentRoute.meta.service,
        };
    },
    components: {
        SalesOrdersComponent,
    },
    methods: {
        getAllSO() {
            this.loaded = false;
            let params = {
                url: "getAll",
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                search: this.search,
                is_saved: this.is_saved,
                is_approval: this.is_approval,
                is_approved: this.is_approved,
                is_preparing: this.is_preparing,
                is_completed: this.is_completed,
                is_pending: this.is_pending,
                service: this.service,
            };
            this.$store.dispatch("salesOrdersGet", params).then((response) => {
                this.salesorders = response.data.data;
                this.loaded = true;
                this.totalSO = response.data.total;
            });
        },
    },
    mounted() {
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
        this.$store.dispatch("getAllUsers");
        this.$store.dispatch("getCustomers", {});
        this.$store.dispatch("getAllsalesmanSelection", {});
    },
};
</script>
<style>
#group-tab {
    padding: 0 !important;
}
</style>
