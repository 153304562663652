<template>

<v-container fluid>

      <ComponentHeader
          :button="true"
          title='Pending Stock Card by DR'
          type='Inventory'
      ></ComponentHeader>

    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>

    <v-container fluid class="bg-bluish-gray">
        <v-row class="pa-2">
            <v-col lg="6">
                <v-autocomplete :items="customers_list"
                    :loading="loading.customer_selection"
                    :disabled="loading.customer_selection"
                    v-model="customer_id"
                    background-color="white"
                    item-value="value"
                    outlined
                    dense
                    item-text="text"
                    placeholder="Select Customer"
                    class="small"
                >
                </v-autocomplete>
            </v-col>
            <!--<div class="col-sm-12 col-md-3">
                <label for="salesman_id" class="float-left control-label col-form-label">Salesman</label>
                <v-autocomplete :list="salesman_list"
                    v-model="salesman_id"
                    option-value="value"
                    option-text="text"
                    placeholder="Select Salesman"
                    class="d-inline-block small"
                >
                </v-autocomplete>
            </div>-->
            <v-col class="text-right">
                <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn>
                <v-btn small class="ml-1" @click="exportToExcel()">EXCEL</v-btn>
                <v-btn small class="ml-1" @click="getPendingSCByPL()">Filter</v-btn>
            </v-col>
        </v-row>
        <div class="row pa-2">
            <!--<div class="col-sm-12 col-md-4">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <v-checkbox
                            v-model="confirmed"
                            label="confirmed"
                            color="white"
                            input-value="1"
                            hide-details
                        ></v-checkbox>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <v-checkbox
                            v-model="unconfirmed"
                            label="unconfirmed"
                            color="white"
                            input-value="1"
                            hide-details
                        ></v-checkbox>
                    </div>
                </div>
            </div>-->
            <v-col lg="3" id="date_from">

                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="date_from"
                            readonly
                            outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                </v-menu>
                <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
            </v-col>
            <v-col lg="3" id="date_to">
                <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="date_to"
                            readonly
                            outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                </v-menu>
                <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
            </v-col>
        </div>

        <div class="w-100">
            <v-card v-show="loaded">
                <!--<v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                    <v-icon @click="searchInput()">fas fa-search</v-icon>
                </v-card-title>-->

                <v-container fluid>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="info-box mb-3">
                                <!-- <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span> -->
                                <div class="info-box-content">
                                    <!-- <span class="info-box-text">Sales Total</span>
                                    <span class="info-box-number">&#x20b1;{{ sales_total_amount | currency }}</span> -->
                                </div>
                            </div>
                        </div>
                      <!--  <div class="col-12 col-sm-6 col-md-3">
                            <div class="info-box mb-3">
                                <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                                <div class="info-box-content">
                                    <span class="info-box-text">Customers</span>
                                    <span class="info-box-number">{{ customer_count }}</span>
                                </div>
                            </div>
                        </div>-->

                        <div class="clearfix hidden-md-up"></div>

                    </div>

                    <v-data-table
                        :headers="headers"
                        :items="stockCards"
                        class="w-100"
                        dense
                    >
                        <template v-slot:[`item.total_amount_customer`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.total_amount_customer | currency }}
                            </span>
                        </template>

                    </v-data-table>
                </v-container>
            </v-card>
            <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>
        </div>
    </v-container>

</v-container>

</template>

<script>

    import moment from 'moment';
    const ExcelJS = require('exceljs')
    import { saveAs } from 'file-saver';
    import { mapGetters } from 'vuex';
    import swal from 'sweetalert2';
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    export default {
        data(){
            return {
                errors: [],
                customer_id: '',
                customers_list: [],
                //salesman_id: '',
                //salesman_list: [],
                loaded: true,
                //confirmed: true,
                //unconfirmed: false,
                date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                sales_total_amount: '',
               // customer_count: '',
                //confirmed_count: '',
                //unconfirmed_count: '',

                row: null,
                menu2: false,
                menu3: false,

                totalSalesReport: 0,
                options: {},
                stockCards: [],
                itemsPerPageOptions: [5, 10, 20, 50, 100],
                itemsPerPage: 10,
                search: '',
                awaitingSearch: false,
                headers: [
                    { text: 'SC', align: 'left', value: 'sc', },
                    { text: 'SC date', align: 'left', value: 'sc_date', },
                    { text: 'DR', align: 'left', value: 'pl', },
                    { text: 'DR date', align: 'left', value: 'pl_date' },
                    // { text: 'SO', align: 'left', value: 'so' },
                    // { text: 'SO date', align: 'left', value: 'so_date' },
                    { text: 'Customers', align: 'center', value: 'company_name', },
                    { text: 'Model', align: 'center', value: 'model', },
                    { text: 'Name', align: 'center', value: 'name', },
                    { text: 'Category', align: 'center', value: 'category', },
                    { text: 'Type', align: 'center', value: 'type', },
                    { text: 'UOM', align: 'center', value: 'uom', },
                    { text: 'Qty', align: 'right', value: 'quantity', },
                    { text: 'SC Qty', align: 'right', value: 'total_sc_qty', },
                    { text: 'status', align: 'right', value: 'status', },
                ],
                json_data: [],
                json_meta: [
                    [
                        {
                            'key': 'charset',
                            'value': 'utf-8'
                        }
                    ]
                ],
                initialLoad: false,

                loading:{
                    customer_selection      : true
                }
            }
        },
        components: {
            ComponentHeader
        },
        mounted: function (){
            this.$store.dispatch('getAllcustomersSelection')
            this.getCustomers()
            //this.getSalesmanList()

        },
        computed:{
            ...mapGetters([
                'GET_CUSTOMERS_SELECTION'
            ])
        },
        methods: {
            getCustomers(){
                this.customers_list = this.GET_CUSTOMERS_SELECTION
                this.customers_list.push({
                    value: 'all',
                    text: 'Select All'
                })
            },
            /*getSalesmanList(){
                axios.get('/getSalesmanList',{

                }).then(response => {
                    this.salesman_list = response.data
                });
            },*/
            getPendingSCByPL(page = 1){
                this.loaded = false
                let payload = {
                    customer:       this.customer_id,
                    page:           page,
                    itemsPerPage:   this.options.itemsPerPage,
                    date_from:      this.date_from,
                    date_to:        this.date_to,
                    url:            "getPendingSCByPL",
                }
                this.$store.dispatch('reports',payload).then(response => {
                    this.stockCards = response.data.data.data
                    this.initialLoad = true
                    this.loaded = true
                }).catch(e=>{
                    swal.fire('Error !' ,' - An error occured','error')
                    this.initialLoad = true
                    this.loaded = true
                })
            },
            exportToExcel(){
                this.loaded = true
                let workbook = new ExcelJS.Workbook();

                let worksheet =  workbook.addWorksheet('SC');
                    worksheet.columns = [
                        { header:'SC#', key: 'sc',  },
                        { header:'SC Date',key: 'sc_date', },
                        { header:'DR#', key: 'pl',},
                        { header:'DR Date',key: 'pl_date', },
                        { header:'SO#', key: 'so',  },
                        { header:'SO Date',key: 'so_date', },
                        { header:'Customer',key: 'company_name', },
                        { header:'Model',key: 'model', },
                        { header:'Name',key: 'name', },
                        { header:'Category',key: 'category', },
                        { header:'UOM',key: 'uom', },
                        { header:'Type',key: 'type',},
                        { header:'SO Remarks',key: 'remarks',},
                        { header:'Qty', align: 'right', key: 'quantity',style: { numFmt: '#,##0' } },
                        { header:'SC Qty', align: 'right', key: 'total_sc_qty', style: { numFmt: '#,##0' }},
                        { header:'status', align: 'right', key: 'status', },
                    ];
                let payload = {
                    customer:    this.customer_id,
                    date_from:      this.date_from,
                    date_to:        this.date_to,
                    exportToExcel : true,
                    url:            "getPendingSCByPL"
                }
                this.$store.dispatch('reports',payload).then(response => {
                    response.data.data.forEach((e,i) => {
                        worksheet.addRow(e)
                    })
                    this.loaded = true

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        saveAs(blob, `Stock Cards ${this.date_from}-${this.date_to}.xlsx`);
                    });
                }).catch(e=>{
                    console.log(e)
                    swal.fire('Error !' ,' - An error occured','error')
                    this.initialLoad = true
                    this.loaded = true
                })


            },
            thousandSeprator(amount) {
                if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return amount;
                }
            },
            resetFields(){
                this.errors                 = []
                this.customer_id            = ''
                //this.salesman_id            = ''
                //this.confirmed              = true
                //this.unconfirmed            = false
                this.date_from              = moment().clone().startOf('month').format('YYYY-MM-DD')
                this.date_to                = moment().clone().endOf('month').format('YYYY-MM-DD')
                // this.sales_total_amount     = ''
              //  this.customer_count         = ''
                //this.confirmed_count        = ''
                //this.unconfirmed_count      = ''
            }
        },
        watch: {
            options: {
                handler () {
                    if(this.initialLoad == true){
                        this.getPendingSCByPL(this.options.page)
                    }
                },
                deep: true,
            },
            'GET_CUSTOMERS_SELECTION':{
            handler(val){
                this.loading.customer_selection = false
            },
            }
        }
    };
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
