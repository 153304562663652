<template>
  <v-card elevation="20">
    <v-container class="rounded-0">
      <v-row class="mb-1 pt-1 d-flex justify-content-start">
        <v-col cols="6">
          <v-btn @click="adddialog()" v-if="userAccess.create && ['is_active', 'all', 'is_mother'].includes(GET_ACTIVE_TAB)">
              <v-icon>mdi-plus</v-icon>Add
          </v-btn>
        </v-col>
        <v-col cols="6" class="d-flex justify-content-end">
          <v-btn @click="syncCustomers()">Sync Company Owned Stores</v-btn>
          <v-btn @click="exportCustomer()" v-if="userAccess.export_customer">Export</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-row dense no-gutters v-if="cp_text != 'is_inactive' && loaded" class="d-flex justify-content-start">
      <v-col cols="6" sm="4" md="2" class="ma-3 pt-2 pl-1">
          <v-autocomplete
            v-model="salesman"
            :items="GET_SALESMAN_SELECTION"
            label="Select Salesman"
            dense
            clearable
            outlined
          >
          </v-autocomplete>
      </v-col>
      <v-btn @click="getAllCustomers()" class="mt-5">Filter</v-btn>
      <v-spacer></v-spacer>
      <v-col class="text-right mr-3 pr-3 mt-2 pt-2" xs="12" md="6">
        <a :class="`ml-1 badge badge-saved          ${chips.includes(1)? `selected` : `non-selected`}`"       @click="selectChip(1)">All</a>
        <a :class="`ml-1 badge badge-saved          ${chips.includes(2) ? `selected` : `non-selected`}`"      @click="selectChip(2)">vip</a>
        <a :class="`ml-1 badge badge-for-exec       ${chips.includes(3) ? `selected` : `non-selected`}`"      @click="selectChip(3)">special</a>
        <a :class="`ml-1 badge badge-for-sales      ${chips.includes(4) ? `selected` : `non-selected`}`"      @click="selectChip(4)">store</a>
        <a :class="`ml-1 badge badge-for-accounting ${chips.includes(5) ? `selected` : `non-selected`}`"      @click="selectChip(5)">active</a>
      </v-col >
        <!-- <buttons
          v-if="cp_text != 'is_inactive'"
          :action="buttons.actions.create"
          :button_icon="buttons.icons.create"
          :color="buttons.colors.create"
          @click="adddialog"
        ></buttons> -->
    </v-row>
    <v-row dense class="d-flex justify-content-end">
      <v-col cols="12" sm="4" md="2" class="mr-4 pr-4">
        <v-text-field
          v-model="search"
          id="searchBar"
          label="Search Customer Name"
          append-icon="mdi-magnify"
          single-line
          hide-details
          @change="searchInput()"
          @click:append="getAllCustomers()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-data-table
      :headers="headers"
      :items="item"
      :search="search"
      :options.sync="options"
      :items-per-page="10"
      :server-items-length="totalCustomers"
      :multi-sort="true"
      :loading="!loaded"
      :footer-props="{
        showFirstLastPage: true,
      }"
      class="w-100"
      loading-text="Loading... Please wait"
      show-first-last-page
    >
        <template v-slot:item.vip="{ item }">
            <span class="badge bg-primary text-white" v-if="item.vip">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.special="{ item }">
            <span class="badge bg-primary text-white" v-if="item.special">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.is_active="{ item }">
            <span class="badge bg-primary text-white" v-if="item.is_active">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.is_store="{ item }">
            <span class="badge bg-primary text-white" v-if="item.is_store">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.is_mother_customer="{ item }">
            <span class="badge bg-primary text-white" v-if="item.is_mother_customer">Yes</span>
            <span class="badge bg-danger text-white" v-else>No</span>
        </template>

        <template v-slot:item.action="{ item }">
        <!-- <buttons
            v-if="userAccess.view"
            :action="buttons.actions.view"
            :button_icon="buttons.icons.view"
            :color="buttons.colors.view"
            :button_text="buttons.type.text"
            :icon="buttons.type.icon"
            @click="editdialogs(item)"
        ></buttons>
        <buttons
            v-if="userAccess.edit"
            :action="buttons.actions.edit"
            :button_icon="buttons.icons.edit"
            :color="buttons.colors.edit"
            :button_text="buttons.type.text"
            :icon="buttons.type.icon"
            @click="editdialogs(item)"
        ></buttons>
        <buttons
            v-if="userAccess.delete"
            :action="buttons.actions.delete"
            :button_icon="buttons.icons.delete"
            :color="buttons.colors.delete"
            :button_text="buttons.type.text"
            :icon="buttons.type.icon"
            @click="deleteCustomer(item.id)"
        ></buttons> -->
            <v-btn text icon color="orange" v-if="userAccess.view" >
                <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
            </v-btn>
            <v-btn text icon color="blue" v-if="userAccess.edit" >
                <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
            </v-btn>
            <v-btn text icon color="red" v-if="userAccess.delete" >
                <v-icon class="btn-action" small @click="deleteCustomer(item)">mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
  </v-card>
</template>
<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver');
// import buttons from '../../../layouts/Buttons/DialogButton.vue';
// import buttons_components from '../../../../../assets/js/components-js.js';

export default {
  props: ['cp_text', 'component_customer'],
  // components: { buttons, buttons_components },
  data() {
    return {
        // buttons: buttons_components.buttons,
        loaded: false,
        totalCustomers: 0,
        options: {},
        customers: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 10,
        search: '',
        awaitingSearch: false,
        headers: [
            { text: 'Company', align: 'left', value: 'company_name' },
            { text: 'Owner', align: 'left', value: 'full_name' },
            { text: 'Salesman', align: 'left', value: 'salesman_names' },
            { text: 'VIP', align: 'left', value: 'vip' },
            { text: 'Special', align: 'left', value: 'special' },
            { text: 'Store', align: 'left', value: 'is_store' },
            { text: 'Mother', align: "left", value: "is_mother_customer" },
            { text: 'Active', align: 'left', value: 'is_active' },
            { text: 'Actions', align: 'center', value: 'action', sortable: false },
        ],
        userAccess: {
            view: false,
            create: false,
            edit: false,
            delete: false,
            edit_approve_sales: false,
            edit_approve_acct: false,
            edit_approve_exec: false,
            export_customer:false
        },
        status_text: this.cp_text,
        selected: {},
        item: [],
        salesman: '',
        filters: {
          all: '',
          vip: '',
          special: '',
          store: '',
          active: '',
        },
        chips:[],
    }
  },
  mounted() {
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    this.$store.dispatch('getAllsalesmanSelection')
    this.getAllCustomers()
  },
  methods: {
    getAllCustomers() {
      this.item = [];
      const payload = {
        filters:      this.chips,
        search:       this.search,
        salesman:     this.salesman,
        status_text:  this.status_text,
        page:         this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        sortBy:       this.options.sortBy,
        sortDesc:     this.options.sortDesc,
      };
      this.loaded = this.load;
      this.$store
        .dispatch("getallcustomer", payload)
        .then((response) => {
          this.loaded = true;
          let data = response.data.data;
          this.item = data.data;
          this.totalCustomers = data.total;
          this.$store.commit("NEW_CUSTOMER", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchInput() {
        this.options.page = 1
        this.getAllCustomers({ query: this.search });
    },
    viewdialogs(item) {
        this.$store.commit("EDIT_CUSTOMER", {});
        this.$store.commit("EDIT_CUSTOMER", item);
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "View");
    },
    editdialogs(item) {
        this.$store.dispatch("getAllTempCustomer").then(response=>{
          let approval_items = this.GET_LIST_customer.filter(e => e.customer_id === item.id)
          if(approval_items.length > 0){
            swal.fire('Customer has pending update', 'Make your changes on approval tab', 'info')
          }else{
            this.$store.commit("EDIT_CUSTOMER", {});
            this.$store.commit("EDIT_CUSTOMER", item);
            this.$store.commit("DIALOG", true);
            this.$store.commit("ACTION", "Update");
          }
        })
    },
    deleteCustomer(item) {
      let warning_message = '';
      let title_message = '';
      if (item.affiliates.length > 0) {
        title_message = 'This Customer has existing affiliate customers. Do you still wish to delete it?'
        warning_message = 'If you wish to proceed the system would untag related customers'
      } else {
        warning_message = 'Are you sure you want to Delete?'
      }
      swal.fire({
        icon: "warning",
        title: title_message,
        text: warning_message,
        cancelButtonText: "Cancel",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#397373",
        cancelButtonColor: "#397373",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("deletecustomer", {
              id: item.id   ,
              status_text: this.status_text,
            })
            .then((success) => {
              swal.fire({
                text: "Delete Customer Successful!",
                icon: "success",
              });
                for(let x in this.component_customer){
                  this.$store.commit("NEW_CUSTOMER", true);
                }
            });
        }
      });
    },
    adddialog() {
      if (this.GET_ACTIVE_TAB == 'is_mother') {
        this.$store.commit('IS_MOTHER', 1);
      }
        this.$store.commit("DIALOG", true);
        this.$store.commit("ACTION", "Submit");
    },
    async exportCustomer(){
        let that = this
        that.loaded = false

        await this.$store.dispatch('exportCustomer',{
            salesman: this.salesman,
            ...this.filters
        }).then(response => {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Raw Data')

            const cols = [
                { header: 'Company Name', key: 'company_name' },
                { header: 'Owner Last Name', key: 'last_name' },
                { header: 'Owner First Name', key: 'first_name' },
                { header: 'Owner Middle Name', key: 'middle_name' },
                { header: 'Salesman', key: 'salesman' },
                { header: 'Sales Remarks', key: 'remarks' },
                { header: 'Accounting Remarks', key: 'accounting_remarks' },
                { header: 'Cluster', key: 'customer_cluster' },
                { header: 'Sub Cluster', key: 'customer_sub_cluster' },
                { header: 'Category', key: 'customer_category' },
                { header: 'Classification', key: 'customer_classification' },
                { header: 'Region', key: 'region' },
                { header: 'Province', key: 'province' },
                { header: 'City', key: 'city' },
                { header: 'Shipping Address', key: 'shipping_address' },
                { header: 'Has Multiple Shipping Addresses', key: 'with_multiple_shipping_addresses' },
                { header: 'Billing Contact Person', style: { numFmt: '@' }, key: 'billing_contact_person' },
                { header: 'Billing Contact Number', style: { numFmt: '@' }, key: 'billing_contact_number' },
                { header: 'Email', key: 'email' },
                { header: 'Tel 1', style: { numFmt: '@' }, key: 'tel_1' },
                { header: 'Tel 2', style: { numFmt: '@' }, key: 'tel_2' },
                { header: 'Tel 3 ', style: { numFmt: '@' }, key: 'tel_3' },
                { header: 'Mobile 1', style: { numFmt: '@' }, key: 'mobile_1' },
                { header: 'Mobile 2', style: { numFmt: '@' }, key: 'mobile_2' },
                { header: 'Fax', style: { numFmt: '@' }, key: 'fax' },
                { header: 'TIN', key: 'tin' },
                { header: 'Credit Limit', style: { numFmt: '#,##0.00' }, key: 'credit_limit' },
                { header: 'Terms', key: 'terms' },
                { header: 'VAT Rate (%)', style: { numFmt: '#,##0.00' }, key: 'vat_rate' },
                { header: 'Special', key: 'special' },
                { header: 'VIP', key: 'vip' },
                { header: 'Approval: Exec', key: 'approval_exec' },
                { header: 'Approval: Sales', key: 'approval_sales' },
                { header: 'Approval: Accounting', key: 'approval_acctg' },
                { header: 'Store', key: 'store' },
                { header: 'Recycle SO', key: 'recycle_so' },
                { header: 'Cancel SO On Dispatch', key: 'auto_cancel_sales_order_on_dispatch' },
                { header: 'Override MOQ', key: 'override_moq' },
                { header: 'Active', key: 'active' },
                { header: 'Has Attachments', key: 'has_attachments' },
                { header: 'Discount - PT', style: { numFmt: '@' }, key: 'discount_pt' },
                { header: 'Discount - HT', style: { numFmt: '@' }, key: 'discount_ht' },
                { header: 'Discount - SP', style: { numFmt: '@' }, key: 'discount_sp' },
                { header: 'Color Code', key: 'customer_code_name' },
                { header: 'Created At', style: { numFmt: 'mm/dd/yyyy' }, key: 'created_at' },
            ]

            worksheet.columns = cols

            response.data.forEach(e => {
                e.credit_limit = e.credit_limit == 'VIP' ? e.credit_limit : Number(e.credit_limit)
                e.vat_rate = Number(e.vat_rate)
                e.created_at = e.created_at == null ? null : new Date(Date.parse(e.created_at.replace(' ', 'T')+'.000Z'))
                worksheet.addRow(e);
            })

            const total_rows = response.data.length + 1
            const total_cols = cols.length

            worksheet.views = [
                { state: 'frozen', xSplit: 1, ySplit: 1 }
            ]

            worksheet.autoFilter = {
                from: 'A1',
                to: {
                    row: total_rows,
                    column: total_cols,
                }
            }

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                FileSaver.saveAs(blob, `Customer Masterlist as of ${new Date().toISOString().split('T')[0]}.xlsx`);
            })
        }).catch(e => {
            swal.fire('Error !', '', 'error')
            console.log(e)
        })

        that.loaded = true
    },
    selectChip(chipId) {
      const index = this.chips.indexOf(chipId);
      if (index !== -1) {
        this.chips.splice(index, 1);
        this.chips.push(1);
        } else {
          this.chips = [];
          this.chips.push(chipId);
        }

        this.filters.active     = this.chips.includes(2) ? 2: '';
        this.filters.default    = this.chips.includes(3) ? 3 : '';
        this.filters.virtual    = this.chips.includes(4) ? 4 : '';
        this.filters.allowbutal = this.chips.includes(5) ? 5 : '';
        this.filters.actual     = this.chips.includes(6) ? 6 : '';
        this.filters.all        = '';
        this.getAllCustomers();
    },
    syncCustomers() {
        this.$store.dispatch('syncCustomers').then(response => {
            for(let x in this.component_customer){
                this.$store.commit("NEW_CUSTOMER", true);
            }
        })
    },
  },
  computed: {
    ...mapGetters([
      "DIALOGS",
      "GET_ALL_CUSTOMER",
      "TOTAL_CUSTOMER",
      "ACTION",
      "USER_ACCESS",
      "NEW_CUSTOMER",
      "GET_SALESMAN_SELECTION",
      "GET_ACTIVE_TAB",
      "GET_LIST_customer"
    ]),
  },
  watch: {
    options: {
      handler() {
        this.getAllCustomers();
      },
      deep: true,
    },
    NEW_CUSTOMER: {
      handler(val) {
        if (val) {
          this.item = [];
          this.loaded = false;
          this.$store.commit("DIALOG", false);
          swal.fire({
            title: "SUCCESS",
            icon: "success",
            timer: 1500,
          });
          this.getAllCustomers();
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
          if(val != "fail"){
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.userAccess.view = false;
            this.userAccess.create = false;
            this.userAccess.edit_approve_sales = false;
            this.userAccess.edit_approve_acct = false;
            this.userAccess.edit_approve_exec = false;
            this.userAccess.export_customer = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }else if (e.actions_code == 'create'){
            this.userAccess.create = true;
          }else if (e.actions_code == 'edit_approve_sales'){
            this.userAccess.edit_approve_sales = true;
          }else if (e.actions_code == 'edit_approve_acct'){
            this.userAccess.edit_approve_acct = true;
          }else if (e.actions_code == 'edit_approve_exec'){
            this.userAccess.edit_approve_exec = true;
          }else if (e.actions_code == 'export_customer'){
            this.userAccess.export_customer = true;
          }
        });
        }
      },
    },
    GET_ACTIVE_TAB:{
        deep: true,
        handler(val){
            if(val != 'for_approval'){
                this.getAllCustomers()
            }
        }
    }
  },
};
</script>

<style>
.custom-background {
  background-color: #202020;
  border-top: 0px;
}

</style>
