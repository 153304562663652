<template>
<v-container class="pa-0 ma-0 white">
    <v-col>
        <v-row class="ma-3">
            <v-col sm="6" md="2" id="date_from">
                <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="request_date_from"
                            v-on="on"
                            label="Date From"
                            append-icon="mdi-calendar-clock"
                            dense
                            outlined
                            hide-details
                            readonly
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="request_date_from" @input="menu1 = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col sm="6" md="2" id="date_to">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="request_date_to"
                            v-on="on"
                            label="Date To"
                            append-icon="mdi-calendar-clock"
                            dense
                            outlined
                            hide-details
                            readonly
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="request_date_to"  @input="menu2 = false"></v-date-picker>
                </v-menu>
            </v-col>
                <v-col sm="6" md="2">
                        <v-autocomplete
                            v-model="filter_warehouse_id"
                            :items="GET_WAREHOUSE_LIST"
                            :label="filter_warehouse_id ? 'Warehouse' : ''"
                            placeholder="Select Warehouse"
                            outlined
                            dense
                            hide-details
                            clearable
                            auto-select-first
                        ></v-autocomplete>
                    </v-col>
                    <v-col sm="6" md="2">
                        <v-autocomplete
                            v-model="filter_requestor_id"
                            :items="GET_ALL_USER"
                            :label="filter_requestor_id ? 'Requestor' : ''"
                            placeholder="Select Requestor"
                            outlined
                            dense
                            hide-details
                            auto-select-first
                            clearable
                            @input="onSelectRequestor"
                        ></v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" @click="searchInput()" >
                        Filter
                    </v-btn>
                    <v-btn class="mr-2" @click="exportStockAdjustment()" >
                        Export
                    </v-btn>
                    <v-btn class="mr-2" @click="resetFields()" >
                        Reset
                    </v-btn>
                </v-row>
                <v-row class="ma-3 d-flex justify-content-end">
                    <v-col sm="12" md="2" class="mr-2">
                        <v-text-field
                            v-model="search"
                            @keydown.enter="searchInput()"
                            label="Search Ref# / Remarks "
                            hide-details
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" class="mr-2">
                        <v-text-field
                            v-model="search_item"
                            @keydown.enter="searchInput()"
                            @click:append="searchInput()"
                            label="Search Item Model / Name"
                            hide-details
                            clearable
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                </v-row>

        <!-- <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Search" single-line hide-details> </v-text-field>
          <v-icon>mdi-magnify</v-icon>
        </v-card-title> -->
        <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.items.list"
            :loading="loading"
            :options.sync="dataTable.options"
            :server-items-length="dataTable.items.count"
            :items-per-page="10"
            :footer-props="{
                showFirstLastPage: true,
            }"
            class="w-100"
            @update:page="searchInput()"
            @update:items-per-page="searchInput()"
        >

            <template v-slot:[`item.status_text`]="{ item }">
                <span class="badge bg-success text-white" v-if="item.status_text == 'Confirmed'">Confirmed</span>
                <span class="badge bg-warning text-white" v-if="item.status_text == 'Saved'">Saved</span>
                <span class="badge bg-info text-white" v-if="item.status_text == 'For Approval'">For Exec Approval</span>
                <span class="badge bg-primary text-white" v-if="item.status_text == 'For Confirmation'">For Confirmation</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn text icon color="orange" v-if="userAccess.view">
                    <v-icon class="btn-action" small @click="viewAdjustment(item.id)">mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0">
                    <v-icon class="btn-action" small @click="showEditStockAdjustmentDialog(item)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete && item.status == 0">
                    <v-icon class="btn-action" small @click="deleteAdjustment(item.id)">mdi-delete</v-icon>
                </v-btn>
            </template>

        </v-data-table>
    </v-col>
    <div>
        <stock_adjustment_view :viewAdjustmentId="viewAdjustmentId" @closeView="closeView" @getAllStockAdjustments="getAllStockAdjustments"></stock_adjustment_view>
        <!-- <dialogs :selectedAction="action" :cp="components" :retainfocus="false"></dialogs> -->
        <!-- <dialogs :selectedAction="action" :cp="components" :scrollable="true" :retain-focus="false" :width="'75%'"></dialogs> -->
    </div>
</v-container>
</template>

<script>
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import stock_adjustment_view from './StockAdjustmentViewTable.vue';
import buttons from '../../../layouts/Buttons/DialogButton.vue';
import buttons_components from '../../../../../assets/js/components-js.js';
import batchAddtionDialog from '../../../layouts/dialogs/IT/StockAdjustmentDialog.vue';
import dialogs from '../../../layouts/dialogs/Dialog.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import StockAdjustmentComponent from '../StockAdjustmentComponent.vue';

export default{
    mixins: [ SharedFunctionsComponentVue ],
    components:{
        buttons,
        buttons_components,
        stock_adjustment_view,
        dialogs,
        StockAdjustmentComponent
    },
    data(){
        return{
            components:batchAddtionDialog,
            buttons:buttons_components.buttons,
            loaded : false,
            users  : [],
            itemsPerPageOptions : [5, 10 ,15],
            itemsPerPage : 15,
            search : '',
            dataTable:{
                items: {
                    list: [],
                    count: 0,
                    selected: 0,
                },
                headers: [
                    { text : 'Ref#',        align : 'start',   value : 'reference_num',     sortable:false,  width: '5%'},
                    { text : 'Warehouse',   align : 'start',   value : 'warehouse_name',    sortable:false},
                    { text : 'Remarks',     align : 'start',   value : 'remarks',           sortable:false},
                    { text : 'Requestor',   align : 'start',   value : 'requestor_name',    sortable:false},
                    { text : 'Created By',  align : 'start',   value : 'created_by_name',   sortable:false},
                    { text : 'Status',      align : 'start',   value : 'status_text',       sortable:true,   width: '5%'},
                    { text : 'Processed By',align : 'start',   value : 'processed_by',      sortable:false},
                    { text : 'Process Date',align : 'start',   value : 'processed_date',    sortable:false,  width: '15%'},
                    { text : 'Exec Approved By',align : 'start',   value : 'exec_approved_by_name',      sortable:false},
                    { text : 'Actions',     align : 'center',  value : 'actions',           sortable:false,  width: '10%'},
                ],
                options:{

                }
            },
            viewUserId : '',
            json_data  : [],
            json_meta  : [
                [
                    {
                        'key'  : 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],
            userAccess: {
                view    : true,
                create  : true,
                edit    : true,
                delete  : true,
            },
            dialog: false,
            access:{
                department_code:'MD',
                module:'users',
                action:'create'
            },
            loaded:false,
            warehouse_list:[],
            user_list:[],
            requestor_select:'',
            requestor_name:'',
            filter_requestor_id:'',
            filter_warehouse_id:'',
            adjustmentItems:[],
            adjustmentItem: {
                item_id: '',
                item_name: '',
                item_uom: '',
                item_type: '',
                item_category: '',
                qty:'',
            },
            max_adjustment_items_count:999,
            viewAdjustmentId:'',
            action:'',
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: []
            },
            isDone: true,
            hasError: false,
            text_area_fields: [],
            text_area_field: {
                dataFromExcel: '',
                dataFromExcel2: '',
            },
            dataFromExcel: '',
            dataFromExcel2: '',
            isViewEdit: false,
            isDisabledButton: false,
            listOfNotInModels: [],
            listOfDuplicates:[],
            counterBatch: 0,
            max_items_count:999,
            batchCounter:0,
            lastBatchModel: [],
            duplicateFromBatch:[],
            tempAdjustmentItems:[],
            showError:false,
            showDuplicate:false,
            showDuplicateFromBatch:false,
            ShowNotInModel:{
                dataFromExcel: '',
                dataFromExcel2: '',
            },
            ShowNotInModels:[],
            ShowAllDuplicate:{
                dataFromExcel: '',
                dataFromExcel2: '',
            },
            ShowAllDuplicates:[],
            ShowAllDuplicateFromBatch:{
                dataFromExcel: '',
                dataFromExcel2: '',
            },
            ShowAllDuplicateFromBatchs:[],
            retrieved:false,
            showLoader:false,
            isSingleAdded:false,
            addEditDialog:false,
            selectedAction:'',
            loading:true,
            menu1: false,
            menu2: false,
            filter_warehouse_id: null,
            search_item: null,
            filter_requestor_id: null,
            request_date_from: null,
            request_date_to: null,
            items:[]
        }
    },
    mounted() {
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);
        this.getAllStockAdjustments()
        // this.$store.dispatch('getAllWarehouses');
        // this.$store.dispatch('getAllUsersSelect');
        if(this.adjustmentItems.length == 0){
            this.addAdjustmentItem();
        }

    },
    computed: {
        ...mapGetters([
            'GET_ALL_STOCK',
            // 'GET_ALL_WAREHOUSES',
            // 'GET_ALL_USER',
            'GET_STOCK_ADJUSMENT',
            'USER_ACCESS',
            'GET_WAREHOUSE_LIST',
            'GET_ALL_USER',
            'GET_RESET_TABLE',
            'GET_STOCK_MENU',
        ]),
    },
    methods: {
        onSelectRequestor () {
            const payload = {
            filter_requestor_id: this.filter_requestor_id,
            };
            this.$store.dispatch('getUsernameOnSelect', payload).then((response) => {
            this.requestor_name = response.data[0].name
            });
        },
        closeDialog () {
            this.$store.commit('DIALOG', false)
        },
        addAdjustmentItem () {
            if (this.adjustmentItems.length < this.max_adjustment_items_count) {
                this.adjustmentItems.push({
                    item_id: '',
                    item_name: '',
                    item_uom: '',
                    item_type: '',
                    item_category: '',
                    qty: '',
                })
            }
        },
        openBatchDialog(){
            if(this.filter_warehouse_id == '' || this.filter_requestor_id == '') {
                swal.fire(
                '',
                'Please input required fields',
                'warning'
                )

                return false;
            }
            else {

                if(this.adjustmentItems.length == 1){
                    this.lastBatchModel.length = 0;
                    this.lastBatchModel = [];
                }
                    this.batch_addition.dialog = true;
                    this.isDone = true;
                    this.text_area_fields.length = 0;
                    this.batch_addition.data = [];
                    this.listOfNotInModels = [];
                    this.listOfNotInModels.length = 0;
                    this.hasError = false;
                    this.listOfDuplicates = [];
                    this.listOfDuplicates.length = 0;
                    this.duplicateFromBatch = [];
                    this.duplicateFromBatch.length = 0;
                    this.isDisabledButton = false;
                    this.showError=false;
                    this.showDuplicate=false;
                    this.showDuplicateFromBatch=false;
                    this.ShowNotInModels=[];
                    this.ShowNotInModels.length=0;
                    this.ShowAllDuplicates=[];
                    this.ShowAllDuplicates.length=0;
                    this.ShowAllDuplicateFromBatchs=[];
                    this.ShowAllDuplicateFromBatchs.length=0;
                    this.showLoader=false;
                    this.dataFromExcel = '';
                    this.dataFromExcel2 = '';
            }
        },
        onPaste(evt) {
            let that = this;
            this.batch_addition.data = []
            var pastedText = evt.clipboardData.getData('Text');

            if (!pastedText) {
                return false;
            }
            else {
                var rows = pastedText.split("\r\n")
                var cells = '';
                if (this.text_area_fields.length > 1) {
                    this.text_area_fields.pop();
                }
                for (var y in rows) {
                    if (y != rows.length - 1) {
                        cells = rows[y].split("\t");
                        this.text_area_fields.push({
                            dataFromExcel: cells[0],
                            dataFromExcel2: cells[1],
                            error: false,
                            duplicate:false,
                            duplicateBatch:false
                        })
                        if (y == rows.length - 1) {
                            this.text_area_fields.push({
                                dataFromExcel: cells[0],
                                dataFromExcel2: cells[1],
                                error: false,
                                duplicate:false,
                                duplicateBatch:false
                            })
                        }
                        this.batch_addition.data.push(Object.assign({}, {
                            model: cells[0],
                            qty: cells[1],
                            // foc: cells[2],
                            id: '',
                            skip: false,
                        }))
                    }
                }
                that.getAllData();
            }
        },
        myFunction() {
            this.batch_addition.dialog = true;
        },
        closeBatchAdditionDialog() {
            this.batch_addition.dialog = false;
        },
        getAllData(){
            this.showError =false;
            this.showDuplicate = false;
            this.showDuplicateFromBatch =false;
            this.showLoader = true;
            let that = this;
            let checkModel = [];
            this.listOfNotInModels = [];
            this.listOfNotInModels.length = 0;
            this.listOfDuplicates = [];
            this.listOfDuplicates.length = 0;
            this.duplicateFromBatch = [];
            this.duplicateFromBatch.length = 0;
            let payload = {
                items: this.text_area_fields.map(e => { return e.dataFromExcel.toUpperCase()}),
                isChecking: true,
                forSA: 'forSA',
                filter_warehouse_id : this.filter_warehouse_id
            }
            this.$store.dispatch('getItemsByModel',payload).then(response=>{
                this.tempAdjustmentItems = response.data;

                response.data = response.data.map(e => { return e.model })
                let lowerCaseResponseModel = [];

                response.data.forEach(e=>{
                    lowerCaseResponseModel.push(e.toLowerCase());
                })
                let models = this.text_area_fields.map(e => { return e.dataFromExcel});

                if(this.batchCounter > 0){
                    checkModel = this.text_area_fields.map(e=>{

                        return e.dataFromExcel;
                    })
                    checkModel.forEach((e,i)=>{
                        if(that.lastBatchModel.includes(e)){
                            that.text_area_fields[i].duplicateBatch = true;
                            that.duplicateFromBatch.push(e);
                        }
                        else{
                            that.text_area_fields[i].duplicateBatch = false;
                        }
                    })
                        }
                    let showItemsAsString = that.duplicateFromBatch.join("\r\n");

                    if(that.duplicateFromBatch.length > 0){
                        swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'These models already exist in your adjustment  \n \n' + showItemsAsString.split(',').join("\r\n"),
                        })
                    }
                    let filteredModel = models.filter(function (e, i) {
                        if(models.indexOf(e) != i){
                            that.text_area_fields[i].duplicate = true;
                            that.listOfDuplicates.push(e);
                        }else{
                                that.text_area_fields[i].duplicate = false;
                        }
                    });

                    this.text_area_fields.forEach((e, i) => {
                        if (!response.data.includes(e.dataFromExcel) && !lowerCaseResponseModel.includes(e.dataFromExcel.toLowerCase())) {
                                this.listOfNotInModels.push(e.dataFromExcel);
                                this.text_area_fields[i].error = true;
                        }
                        else {
                            this.text_area_fields[i].error = false;
                        }
                    })
                    if (this.listOfNotInModels.length > 0 || this.listOfDuplicates.length > 0 || this.duplicateFromBatch.length > 0) {
                        this.isDisabledButton = true
                        this.hasError = true
                    }
                    else{
                        this.isDisabledButton = false
                        this.hasError = false
                    }
                    this.showLoader = false;
            })
            this.isDone = false;
            this.closeBatchAdditionDialog();
            setTimeout(this.myFunction, 1);
        },
        getSingleData(){
            let that = this;
            this.batchCounter++;
            this.closeBatchAdditionDialog();
            this.batch_addition.dialog = false;
            let models = this.tempAdjustmentItems.map(e => {
                return e.model.toUpperCase();
            });

            if (this.adjustmentItems.length < this.max_items_count) {

                if(this.counterBatch == 0 && this.isSingleAdded == false){
                    this.adjustmentItems.pop();
                    this.counterBatch++;
                    this.isSingleAdded = true;
                }
                for (var i = 0; i < 1; i++) {
                    this.adjustmentItems.push({
                        item_model: this.dataFromExcel.toUpperCase(),
                        item_id: this.tempAdjustmentItems[i].id,
                        item_name: this.tempAdjustmentItems[i].name,
                        item_uom: this.tempAdjustmentItems[i].item_uom_text,
                        item_type:  this.tempAdjustmentItems[i].item_type_text,
                        item_category:this.tempAdjustmentItems[i].item_category_text,
                        remain_stocks:this.tempAdjustmentItems[i].warehouse_stock_quantity,
                        qty: this.dataFromExcel2,
                    })
                }
                models.forEach(e=>{
                    that.lastBatchModel.push(e)
                });
            }
        },
        submittedBtn(){
            if(this.isDone == true){
                let singleDataArray = [];
                this.showError =false;
                this.showDuplicate = false;
                this.showDuplicateFromBatch =false;
                this.showLoader = true;
                let that = this;
                let checkModel = [];
                this.listOfNotInModels = [];
                this.listOfNotInModels.length = 0;
                this.listOfDuplicates = [];
                this.listOfDuplicates.length = 0;
                this.duplicateFromBatch = [];
                this.duplicateFromBatch.length = 0;
                singleDataArray.push(this.dataFromExcel);
                let payload = {
                    items: singleDataArray.map(e => { return e.toUpperCase() }),
                    isChecking: true,
                    forSA: 'forSA',
                    filter_warehouse_id : this.filter_warehouse_id
                }
                this.$store.dispatch('getItemsByModel',payload).then(response=>{
                    let listOfModels = this.adjustmentItems.map( e => {return e.item_model});
                    let checkSingleInputDuplicate = listOfModels.includes(this.dataFromExcel.toUpperCase());
                    if(response.data.length == 0){
                        swal.fire({
                            icon: 'error',
                            title: 'Item not Found',
                        })
                    }
                    else{
                        if(checkSingleInputDuplicate){
                            swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text: this.dataFromExcel+' already exist in your adjustment',
                            })
                        }
                        else{
                            this.tempAdjustmentItems = response.data;
                            this.getSingleData();
                        }
                    }
                })
            }
            else{
                let that = this;
                let models = this.tempAdjustmentItems.map(e => {
                    return e.model.toUpperCase();
                });

                if (this.adjustmentItems.length < this.max_items_count) {
                    if(this.counterBatch == 0){
                        this.adjustmentItems.pop();
                    }
                    for (var i = 0; i < this.text_area_fields.length; i++) {
                        this.adjustmentItems.push({
                            item_model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                            item_id: this.tempAdjustmentItems[i].id,
                            item_name: this.tempAdjustmentItems[i].name,
                            item_uom: this.tempAdjustmentItems[i].item_uom_text,
                            item_type:  this.tempAdjustmentItems[i].item_type_text,
                            item_category:this.tempAdjustmentItems[i].item_category_text,
                            remain_stocks:this.tempAdjustmentItems[i].warehouse_stock_quantity,
                            qty: this.text_area_fields[i].dataFromExcel2,
                        })
                    }
                    models.forEach(e=>{
                            that.lastBatchModel.push(e)
                        });
                    this.counterBatch++;
                    this.batchCounter++; // for Checking duplicates from last Batch
                    this.batch_addition.dialog = false;
                }
            }
        },
        clearItem(i) {
            let temp = [];
            let tempArr = [];
            if (this.text_area_fields.length == 1) {
                this.clearFields();
            }
            else {
                if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

                }
                else {
                    this.hasError = false;
                    this.isDisabledButton = false;
                }
                this.text_area_fields.forEach((e, index) => {
                    if (i != index) {
                        temp.push(e);

                        let checkingOfError = this.listOfNotInModels.includes(this.text_area_fields[i].dataFromExcel)
                        let checkingOfDuplicate = this.listOfDuplicates.includes(this.text_area_fields[i].dataFromExcel)
                        let checkingOfBatchDuplicate = this.duplicateFromBatch.includes(this.text_area_fields[i].dataFromExcel)

                        if (checkingOfError  && !checkingOfBatchDuplicate && !checkingOfDuplicate) {
                            this.listOfNotInModels.pop();
                        }
                        if (checkingOfDuplicate && !checkingOfError && !checkingOfBatchDuplicate ) {
                            this.listOfDuplicates.pop();
                        }
                        if(checkingOfBatchDuplicate && !checkingOfError && !checkingOfDuplicate ){
                            this.duplicateFromBatch.pop();
                        }
                    }
                })
                this.text_area_fields = temp;
            }
        },
        clearFields(){
            this.isDone = true;
            this.text_area_fields.length = 0;
            this.batch_addition.data = [];
            this.listOfNotInModels = [];
            this.listOfNotInModels.length = 0;
            this.listOfDuplicates = [];
            this.listOfDuplicates.length = 0;
            this.duplicateBatch = [];
            this.duplicateBatch.length = 0;
            this.text_area_fields = [];
            this.hasError = false;
            this.duplicateFromBatch = [];
            this.duplicateFromBatch.length = 0;
            this.isDisabledButton = false;
            this.showError=false;
            this.showDuplicate=false;
            this.showDuplicateFromBatch=false;
            this.ShowNotInModels=[];
            this.ShowNotInModels.length=0;
            this.ShowAllDuplicates=[];
            this.ShowAllDuplicates.length=0;
            this.ShowAllDuplicateFromBatchs=[];
            this.ShowAllDuplicateFromBatchs.length=0;
            this.showLoader=false;
            this.dataFromExcel = '';
            this.dataFromExcel2 = '';
        },
        addNewAdjustment(){

            if(!this.adjustmentItems[0].item_id == '' && !this.adjustmentItems[0].qty == '') {
                swal.fire({
                    title: 'Are you sure you want to Create Stock Adjustment?',
                    text: 'Make sure that all information are correct',
                    icon: 'warning',
                    showConfirmButton:true,
                    confirmButtonText:'Confirm',
                    showCancelButton  :  true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    reverseButtons:true,
                }).then((isConfirmed)=>{
                    if(isConfirmed) {
                        let payload = {
                            filter_warehouse_id:       this.filter_warehouse_id,
                            filter_requestor_id:       this.filter_requestor_id,
                            requestor_name:     this.requestor_name,
                            remarks:            this.remarks,
                            adjustmentItems:    this.adjustmentItems,
                        }
                        this.$store.dispatch('addNewStockAdjustment',payload).then(response => {
                            let data = response.data
                            if(data.error){
                                swal.fire(
                                    '',
                                    data.error,
                                    'question'
                                )
                                this.getAllStockAdjustments()
                                return false
                            }
                            this.clearFields()
                            this.getAllStockAdjustments()
                            this.$store.commit('DIALOG',false);
                            this.action = 'success'
                            swal.fire({
                                icon: 'success',
                                title: '',
                                text: response.data.msg,
                            })
                        });
                    }
                });
            }
            else {
                swal.fire(
                    '',
                    'Please Fill Required Fields',
                    'warning'
                )

            }
        },
        showNotInModel(){
            let that = this;
            if(this.ShowNotInModels.length == 0){
                if(this.showError == true){
                    this.text_area_fields.filter(function (e, i) {
                        if(e.error == true){
                            that.ShowNotInModels.push({e,i});
                        }
                    });
                }
            }
        },
        showAllDuplicate(){
            let that = this;
            if(this.ShowAllDuplicates.length == 0){
                if(this.showDuplicate == true){
                    this.text_area_fields.filter(function (e, i) {
                        if(e.duplicate == true){
                            that.ShowAllDuplicates.push({e,i});
                        }
                    });
                }
            }
        },
        showAllDuplicateFromBatch(){
            let that = this;
            if(this.ShowAllDuplicateFromBatchs.length == 0){
                if(this.showDuplicateFromBatch == true){
                    this.text_area_fields.filter(function (e, i) {
                        if(e.duplicateBatch == true){
                            that.ShowAllDuplicateFromBatchs.push({e,i});
                        }
                    });
                }
            }
        },
        deleteNotInModel(deleteModel,i){
            let that = this;
            let tempArr = [];
            if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

                }
                else {

                    this.isDisabledButton = false;
                }
            let temp = that.text_area_fields.filter(e=>{
                if(e.dataFromExcel == deleteModel){

                }
                else{
                    this.listOfNotInModels.pop();

                    return e;
                }
            })

            this.ShowNotInModels.forEach((e, index) => {
                if (i != index) {
                    tempArr.push(e);
                }
            })
            this.ShowNotInModels = tempArr;
            that.text_area_fields = temp;
        },
        deleteDuplicatesInModel(deleteModel,i){
            let that = this;
            let tempArr = [];
            if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

                }
                else {
                    this.isDisabledButton = false;
                }

            let temp = that.text_area_fields.filter(e=>{
                if(e.dataFromExcel == deleteModel){

                }
                else{
                    this.listOfDuplicates.pop();

                    return e;
                }
            })
            this.ShowAllDuplicates.forEach((e, index) => {
                if (i != index) {
                    tempArr.push(e);
                }
            })
            this.ShowAllDuplicates = tempArr;
            that.text_area_fields = temp;
        },
        deleteDuplicatesFromBatchInModel(deleteModel,i){
            let that = this;
            let tempArr = [];
            if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

            }
            else {
                this.isDisabledButton = false;
            }
            let temp = that.text_area_fields.filter(e=>{
                if(e.dataFromExcel == deleteModel){

                }
                else{
                    this.duplicateFromBatch.pop();
                    return e;
                }
            })
            this.ShowAllDuplicateFromBatchs.forEach((e, index) => {
                if (i != index) {
                    tempArr.push(e);
                }
            })
            this.ShowAllDuplicateFromBatchs = tempArr;
            that.text_area_fields = temp;
        },
        clearSingleAddition(){
            this.dataFromExcel = '';
            this.dataFromExcel2 = '';
        },
        viewAdjustment(id){
            this.viewAdjustmentId = id
        },
        closeView(action = false){
            this.viewAdjustmentId = 0
            if(action == 'updateStatusSuccess'){
                this.resetFields()
                // this.$store.dispatch('getAllStockAdjustment')
            }
        },
        showEditStockAdjustmentDialog(item) {
            this.isViewEdit = true;
            this.action = 'Edit';
            let payload = {
                item_id: item.id,
            }
            this.selectedAction = 'Edit'
            this.$store.commit("DIALOG",true);
            this.$store.commit("ACTION",'Update');
            this.$store.dispatch('getStockAdjustment',payload)
        },
        closeAddEditDialog() {
            this.clearFields();
            this.$store.commit('DIALOG',false);
        },
        deleteAdjustment(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton  :  true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                reverseButtons:true,
            }) .then((isConfirmed) => {
                let payload = {
                    item_id : id,
                }
                if (isConfirmed) {
                    this.$store.dispatch('deleteStockAdjustment',payload).then(response => {
                        swal.fire(
                            '',
                            'Stock Adjusment Deleted Successfully!',
                            'success'
                        )
                        this.getAllStockAdjustments()
                        // this.$store.dispatch('getAllStockAdjustment')
                    }).catch(error => {
                        swal.fire(
                            '',
                            'An Error Occured',
                            'error'
                        )
                        this.getAllStockAdjustments()
                    })
                }
            });
        },
        searchInput(){
            this.loading = true
            this.dataTable.items.list = []
            let payload = {
                pagination: this.dataTable.options,
                filter_warehouse_id: this.filter_warehouse_id,
                items: this.search_item,
                filter_requestor_id: this.filter_requestor_id,
                date_from: this.request_date_from,
                date_to: this.request_date_to,
                search: this.search,
                tab: this.GET_STOCK_MENU,
            }
            this.$store.dispatch('getAllStockAdjustment',payload).then(response => {
                this.dataTable.items.list = response.data.data.data
                this.dataTable.items.count = response.data.data.total
                this.loading = false
            })
        },
        resetFields(){
            this.filter_warehouse_id = ''
            this.search_item = ''
            this.filter_requestor_id = ''
            this.request_date_from = ''
            this.request_date_to = ''
            this.search = ''
            this.tab = '',
            this.getAllStockAdjustments()
        },
        getAllStockAdjustments(){
            this.loading = true;
            this.dataTable.items.list = []
            let payload = {
                pagination: this.dataTable.options,
                filter_warehouse_id: this.filter_warehouse_id,
                items: this.search_item,
                filter_requestor_id: this.filter_requestor_id,
                date_from: this.request_date_from,
                date_to: this.request_date_to,
                search: this.search,
                tab: this.GET_STOCK_MENU,
            }
            this.$store.dispatch('getAllStockAdjustment', payload).then(response =>{
                this.dataTable.items.list = response.data.data.data
                this.dataTable.items.count = response.data.data.total
                this.loading = false
            })
        },
        async exportStockAdjustment(){
            let that = this
            let export_items = []
            swal.fire('Exporting Files Please wait.')
            swal.showLoading()

            let payload = {
                    filter_warehouse_id: this.filter_warehouse_id,
                    items: this.search_item,
                    filter_requestor_id: this.filter_requestor_id,
                    date_from: this.request_date_from,
                    date_to: this.request_date_to,
                    search: this.search
            }
            await this.$store.dispatch('exportStockAdjustment', payload).then(response => {
                export_items = response.data
                swal.close()
            })

            const ExcelJS = require('exceljs')
            const workbook = new ExcelJS.Workbook();

            that.loading = true

            const worksheet = workbook.addWorksheet('Raw Data')
            worksheet.columns = [
                {header:'REF #',key:'SA#'},
                {header:'WAREHOUSE',width: 20,key:'WAREHOUSE'},
                {header:'REQUESTOR',width: 20,key:'REQUESTOR'},
                {header:'CREATED BY',width: 20,key:'CREATED_BY'},
                {header:'REMARKS',width: 20,key:'REMARKS'},
                {header:'STATUS',width: 15,key:'status_text'},
                {header:'PROCESS BY',width: 20,key:'PROCESSOR'},
                {header:'MODEL',width: 20,key:'model'},
                {header:'ITEM', width: 40,key:'NAME'},
                {header:'UOM',key:'UOM'},
                {header:'TYPE',key:'TYPE'},
                {header:'CATEGORY',width: 20,key:'CATEGORY'},
                {header:'QUANTITY',width: 20,key:'QTY'},
                {header:'ADJUSTMENT TYPE',width: 20,key:'adjustment_type'},
            ]

            export_items.forEach((e, i) => {
                e.adjustment_type = e.QTY < 0 ? 'NEGATIVE' : 'POSITIVE'
                worksheet.addRow(e)
            });

            worksheet.getRow(`0${1}`).font = { bold: true }


            // let exportName = `SOA (${selectedStatusText}) ${this.selectedCustomer}`
            let exportName = `Stock Adjustment`
            that.loading = false
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                saveAs(blob, `${exportName}.xlsx`);
            });
        },
    },
    watch:{
        GET_STOCK_ADJUSMENT:{
            handler(val){
                val.forEach((e)=>{
                    this.filter_warehouse_id    = e.filter_warehouse_id
                    this.remarks         = e.remarks
                    this.filter_requestor_id    = e.filter_requestor_id
                    this.requestor_name  = e.requestor_name
                    this.adjustmentItems = e.adjustmentItems
                    this.adjustment_id   = e.id
                    if(e.adjustmentItems != null && e.adjustmentItems.length > 0){
                        e.adjustmentItems.forEach((item, key) => {
                            e.adjustmentItems[key].item_model    = item.model
                            e.adjustmentItems[key].item_name     = item.item_name
                            e.adjustmentItems[key].item_uom      = item.uom
                            e.adjustmentItems[key].item_type     = item.type
                            e.adjustmentItems[key].item_category = item.category
                            e.adjustmentItems[key].qty           = item.qty_type == 0 ? '-'+item.quantity : item.quantity
                        })
                    }
                })
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
                }
            }
        },
        GET_RESET_TABLE:{
            handler(val){
                if(val){
                    this.resetFields()
                    this.$store.commit('RESET_TABLE', false)
                }
            }
        },
        GET_STOCK_MENU:{
            handler(val){
                this.dataTable.items = [],
                this.dataTable.count = 0,
                this.dataTable.selected = 0,
                this.resetFields()
                this.getAllStockAdjustments()
            // <StockAdjustmentComponent />

            }
        },
    }
};
</script>
<style lang="scss" scoped>
.alignText input{
    text-align:center;
}
.notInModel {
    background-color: rgb(236, 68, 68);
    height: 40px;
    border-radius: 5px;
}
.redBox {
    background-color: rgb(236, 68, 68);
    height:10px;
    width:10px
}
.yellowBox {
    background-color: rgb(238, 255, 0);
    height:10px;
    width:10px
}
.orangeBox {
    background-color: rgb(255, 153, 0);
    height:10px;
    width:10px
}
.duplicateModel{
    background-color: rgb(238, 255, 0);
    height: 40px;
    border-radius: 5px;
}
.duplicateBatchModel{
    background-color: rgb(255, 153, 0);
    height: 40px;
    border-radius: 5px;
}

.InModel {
    height: 40px;
}
.swal2-container {
    z-index: 20000 !important;
}
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td{
    font-weight: 400 !important;
}
</style>
