<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="stock_transfer_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Stock Transfer Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearStockTransferItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-8 text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Id: {{ stock_transfer.id }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">SRF Num: {{ stock_transfer.srf_num }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Source: {{ stock_transfer.source_name }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Issuer: {{ stock_transfer.user_name }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Destination: {{ stock_transfer.destination_name }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Date Issued: {{ stock_transfer.created_at | formatDateTime }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Remarks: {{ stock_transfer.remarks }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Status: {{ stock_transfer.status_text }}</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4 pull-right text-right">
                                    <div class="mb-2"><v-btn @click="renderPDF('print')">Print</v-btn></div>
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="stockTransferItemsHeaders"
                        :items="stockTransferItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                    >
                    </v-data-table >

                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <span class="m-1"><v-btn  v-on:click="approveStockTransfer()" v-if="userAccess.approve_st && stock_transfer.status_text=='pending'">Approve</v-btn></span>
                            <!-- <span class="m-1"><v-btn  v-on:click="approveStockTransfer()" v-if="stock_transfer.status_text=='pending'">Approve</v-btn></span> -->
                            <!--<span class="m-1"><v-btn  v-on:click="rejectStockTransfer()" v-if="stock_transfer.status_text=='pending'" color="error">Reject</v-btn></span>-->
                        </div>
                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>

                <v-container fluid style="display:none;">
                    <div>
                        <table id="transfer_details">
                            <tbody>
                                <tr>
                                    <td>Source:</td>
                                    <td>{{ stock_transfer.source_name }}</td>
                                    <td>Id:</td>
                                    <td>{{ stock_transfer.id }}</td>
                                </tr>
                                <tr>
                                    <td>Destination:</td>
                                    <td>{{ stock_transfer.destination_name }}</td>
                                    <td>Issuer:</td>
                                    <td>{{ stock_transfer.user_name }}</td>
                                </tr>
                                <tr>
                                    <td>Remarks:</td>
                                    <td>{{ stock_transfer.remarks }}</td>
                                    <td>Date Issued:</td>
                                    <td>{{ stock_transfer.created_at | formatDate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="items_details">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Model</th>
                                    <th>Name</th>
                                    <th>UOM</th>
                                    <th>Requested Quantity</th>
                                    <th>Transfer Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(transferItem, index ) in stockTransferItems" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ transferItem.model }}</td>
                                    <td>{{ transferItem.name }}</td>
                                    <td>{{ transferItem.uom_text }}</td>
                                    <td>{{ transferItem.requested_quantity }}</td>
                                    <td>{{ transferItem.quantity }}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>***** NOTHING FOLLOWS *****</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>


    </v-app>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import jsPDF from 'jspdf';
import swal from 'sweetalert2';
    export default {
        props: [
            'viewStockTransferId', 'service'
        ],
        mounted(){
            // this.checkAccess('OP', 'stock_transfers', 'approve_st')
            this.checkAccess()
        },
        data() {
            return {
                stockTransferItems: [],
                stockTransferItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'model', },
                    { text: 'Name', align: 'left', value: 'name', },
                    { text: 'Source Stock', align: 'center', value: 'source_item_stock', },
                    { text: 'Destination Stock', align: 'center', value: 'destination_item_stock', },
                    { text: 'Requested Quantity', align: 'center', value: 'requested_quantity', },
                    { text: 'Transfer Quantity', align: 'center', value: 'quantity', },
                ],

                loaded: true,
                stock_transfer_dialog: false,
                stock_transfer: {
                    id: '',
                    srf_num: '',
                    source_id: '',
                    destination_id: '',
                    remarks: '',
                    source_name: '',
                    destination_name: '',
                    user_name: '',
                    created_at: '',
                    status: '',
                    status_text: '',
                },
                userAccess: {
                    approve_st: false,
                },

                errors: [],
            }
        },
        methods: {
            checkAccess(){
                this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                    let val = response;
                    this.userAccess.approve_st = false;
                    if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "APR_ST"){
                            this.userAccess.approve_st = true;
                        }
                    });
                    }
                })
            },
            approveStockTransfer(){
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Approve?",
                    icon: "warning",
                    showConfirmButton: true,
                    confirmButtonText: "Confirm",
                    showCancelButton:true,
                    showCloseButton:true,
                    reverseButtons:true
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('approveStockTransfer',this.viewStockTransferId).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeStockTransferStatus')

                            this.stock_transfer_dialog = false

                            swal.fire("", response.data.msg, "success");


                        }).catch(error => {
                            
                            swal.fire("An Error Occured", error, "error");
                        })
                    }
                });
            },
            rejectStockTransfer(){
                swal.fire({
                    title: "",
                    text: "Are you sure you want to Reject?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willReject) => {
                    if (willReject) {
                        axios.post('/rejectStockTransfer/'+this.viewStockTransferId).then(response => {

                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeStockTransferStatus')

                            this.stock_transfer_dialog = false

                            swal.fire("", response.data.msg, "success");


                        }).catch(error => {
                            
                            swal.fire("An Error Occured", error, "error");
                        })
                    }
                });
            },
            clearStockTransferItems(){
                this.resetFields()
                this.stock_transfer_dialog = false
            },
            renderPDF(pdfAction){
                var doc = new jsPDF('p', 'mm', 'letter');

                doc.setFontSize(14);
                doc.text(6, 15, "Stock Transfer Details")

                doc.autoTable({
                    html: '#transfer_details',
                    theme: 'plain',
                    margin: {top: 20, left: 6, right: 6},
                    columnStyles: {
                        0: {cellWidth: 25},
                        1: {cellWidth: 120},
                        2: {cellWidth: 26},
                        3: {cellWidth: 30},
                    },
                });

                doc.autoTable({
                    html: '#items_details',
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: {left: 6, right: 6},
                    theme: 'grid',
                    headStyles: {
                        halign:'center',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                    },
                    styles: {
                        fontSize: 8
                    },
                    columnStyles: {
                        0: {cellWidth: 11, halign:'center', fontSize: 8, textColor: '#000000'},
                        1: {cellWidth: 27, halign:'center', fontSize: 7, textColor: '#000000'},
                        2: {halign:'center', fontSize: 7, textColor: '#000000'},
                        3: {cellWidth: 17, halign:'center', fontSize: 8, textColor: '#000000'},
                        4: {cellWidth: 27, halign:'center', fontSize: 8, textColor: '#000000'},
                        5: {cellWidth: 27, halign:'center', fontSize: 8, textColor: '#000000'}
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });



                if(pdfAction == 'download'){
                    doc.save('StockTransfer ' + this.stock_transfer.id + '.pdf');
                }else if(pdfAction == 'print'){
                    doc.autoPrint();
                    window.open(doc.output('bloburl'), '_blank');
                }

            },
            resetFields(){
                this.stockTransferItems = []
                this.stock_transfer    = {
                    id: '',
                    srf_num: '',
                    source_id: '',
                    destination_id: '',
                    remarks: '',
                    source_name: '',
                    destination_name: '',
                    user_name: '',
                    created_at: '',
                    status: '',
                    status_text: '',
                }
            },
        },
        computed:{
            ...mapGetters([
                'SHOW_STOCK_TRANSFER'
            ])
        },
        watch: {
            viewStockTransferId: function(val){
                this.errors = []

                if(val != -1){

                    if(this.viewStockTransferId != null && this.viewStockTransferId > 0){

                        this.$store.dispatch('showStockTransfer',this.viewStockTransferId).then(response => {
                            let data = this.SHOW_STOCK_TRANSFER
                            
                            this.stock_transfer.id                             = data.id
                            this.stock_transfer.srf_num                        = data.srf_num
                            this.stock_transfer.source_id                      = data.source_id
                            this.stock_transfer.destination_id                 = data.destination_id
                            this.stock_transfer.remarks                        = data.remarks
                            this.stock_transfer.source_name                    = data.source_name
                            this.stock_transfer.destination_name               = data.destination_name
                            this.stock_transfer.user_name                      = data.user_name
                            this.stock_transfer.created_at                     = data.created_at
                            this.stock_transfer.status                         = data.status
                            this.stock_transfer.status_text                    = data.status_text
                            this.stockTransferItems                            = data.warehouse_transfer_items

                            this.stock_transfer_dialog  = true
                        }).catch(error => {
                            if(error.response.status == 422){
                                this.errors = error.response.data.errors
                            }
                        });
                    }
                }

            }
        }

    };

</script>
<style scoped>
    .v-avatar{
        margin: 15px 50px !important;
    }
    #client_modal_card{
        min-height: 160px !important;
    }
</style>
