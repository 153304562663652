<template>
    <v-dialog v-model="exportItemModal" persistent max-width="55%">
        <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Export</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="closeExportItemModal()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-0" />

            <ItemMasterComponentVue :is_inactive_items="is_inactive_items" :is_sp="is_sp" />
        </v-card>
    </v-dialog>
</template>

<script>
import ItemMasterComponentVue from './ItemMasterComponent.vue';

export default {
    props: {
        is_inactive_items:{
            type:Boolean,
            default:()=>false
        },
        is_sp:{
            type:Boolean,
            default:()=>false
        },
        addSp:{
            type:Boolean,
            default:()=>false
        },
    },
    data() {
        return {

        }
    },
    computed: {
        exportItemModal() {
            return this.$store.state.Items.exportItemModal;
        }
    },
    components: {
        ItemMasterComponentVue
    },
    methods: {
        closeExportItemModal() {
            this.$store.commit('closeExportItemModal');
        }
    }
}
</script>

<style>

</style>
