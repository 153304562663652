<template>
  <v-container>
    <v-data-table :headers="!isRoles? headers : job_title_headers" :isRoles="isRoles" :items="!isRoles? department.users: department.job_titles" :search="search" :items-per-page="5" class="w-100">
      <template v-slot:[`item.active`]="{ item }">
        <span class="badge badge-primary text-white" v-if="item.active">Yes</span>
        <span class="badge badge-danger text-white" v-else>No</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
          :action="buttons.actions.access"
          :button_icon="buttons.icons.access"
          :color="buttons.colors.access"
          :button_text="buttons.type.text"
          @click="editAccessControl(selected, item)"
        ></buttons> -->
        <span class="badge bg-dark text-white cursor-pointer" @click="editAccessControl(selected, item)" >
            access
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
export default {
  props: ['department', 'access', 'search', 'isRoles'],
  // components: { buttons, buttons_components },
  data() {
    return {
      // buttons: buttons_components.buttons,
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Department', align: 'left', value: 'department_name' },
        { text: 'Job Title', align: 'left', value: 'job_title' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      job_title_headers :[
        { text: 'Job Title', align: 'left', value:'job_title_name'},
        { text: 'Department', align: 'left', value:'department_name'},
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      payload_access: {
        main: 'master_data',
        module: 'roles',
        type: 'view'
      },
      selected: this.department,
    }
  },
  methods: {
    editAccessControl(item, selected_user) {
      this.$store.commit('JOB_TITLE_ID',selected_user.job_title_id)
      
      this.$store.dispatch('getAccessControl', selected_user.id);
      this.$store.dispatch('getRolesAccess', {
        department_id: item.department_id,
        user_id: selected_user.id? selected_user.id : 0,
        department_id_org:selected_user.department_id,
        job_title_id: selected_user.job_title_id
      });
    
      this.$store.commit('SELECTED_USER_ROLE', {
        item: item,
        id: selected_user.id,
        users_name: this.isRoles ? selected_user.job_title_name : selected_user.name,
      });
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'Submit')
    }
  },
  mounted() {},
};
</script>
