<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row dense>
                <v-col cols="6" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar" v-if="status_text == 'is_active' && userAccess.create">
                        <v-btn data-modal-id="addSalesOrderModal" @click="showAddSalesOrder()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                        <v-btn class="ml-2" @click="toggleMassImport(true)">
                            Mass Import ING/WAD PL
                        </v-btn>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" align="right">
                    <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" depressed>Export</v-btn>
                        </template>
                        <v-list
                            color="blue-grey lighten-5"
                            outlined
                            dense
                            tile
                        >
                            <v-list-item
                                v-for="(item, index) in exportByStatus"
                                :key="index"
                                light
                                dense
                                @click="exportSalesOrder(item, status_text)"
                                >
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <sales-orders-table-component
                    :salesOrdersActionResult="action"
                    :status_text="status_text"
                    :service="service"
                    :print="print"
                    @showEditModal="showEditModal"
                    @filters="filters"
                ></sales-orders-table-component>
            </v-row>
        </v-container>

        <v-app id="addEditSalesOrderModal">
            <v-dialog v-model="add_edit_salesorder_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline ml-4">Sales Order</span>
                        </v-card-title>
                        <v-col class="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="add_edit_salesorder_dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-container fluid style="background-color: white; overflow-y: scroll">
                        <v-card-text v-if="loaded">
                            <form class="form-horizontal" action="/salesorders" method="post" @submit.prevent="onSubmit">
                                <div class="card-body">
                                    <v-row class="m-0 text-dark" v-if="action == 'Edit'">
                                        <v-col cols="12" class="text-left">
                                            <v-row>
                                                <v-col sm="12" md="9" class="p-0"></v-col>
                                                <v-col sm="12" md="3" class="p-0">Sales Order No.: {{ order_num }}</v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col sm="12" md="9" class="p-0"></v-col>
                                                <v-col sm="12" md="3" class="p-0">Issuer: {{ issuer_name }}</v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col sm="12" md="9" class="p-0"></v-col>
                                                <v-col sm="12" md="3" class="p-0">Date Issued: {{ created_at | formatDateTime }}</v-col>
                                            </v-row>
                                            <div class="row">
                                                <v-col sm="12" md="9" class="p-0"></v-col>
                                                <v-col sm="12" md="3" class="p-0">Promo: {{ promo.name }}</v-col>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col sm="2" class="d-flex">
                                            <!-- <label for="customer_id" class="float-left control-label col-form-label">Promo</label> -->
                                            <v-autocomplete
                                                v-model="promo_id"
                                                :items="promo_list"
                                                :disabled="action == 'Edit'"
                                                dense
                                                outlined
                                                hide-details
                                                auto-select-first
                                                item-value="value"
                                                item-text="text"
                                                label="Select Promo"
                                                class="d-inline-block small"
                                            ></v-autocomplete>
                                            <span v-for="errors in errors.promo_id" class="text-warning" :key="errors">{{ errors }}</span>
                                        </v-col>
                                        <v-col sm="2" class="d-flex">
                                            <!-- <label for="customer_id" class="control-label col-form-label">Delivery Method</label> -->
                                            <v-select
                                                v-model="delivery_method"
                                                :items="delivery_method_list"
                                                :disabled="service == 1"
                                                dense
                                                outlined
                                                hide-details
                                                item-value="value"
                                                item-text="text"
                                                label="Select Delivery Method"
                                                class="d-inline-block small"
                                            ></v-select>
                                            <span v-for="errors in errors.promo_id" class="text-warning" :key="errors">{{ errors }}</span>
                                        </v-col>
                                        <v-col sm="12" md="4" class="d-flex align-items-center" style="gap: 10px">
                                            <!-- <v-switch v-model="stock_card" :disabled="service == 1" hide-details dense
                                                label="Stock Card" color="primary" input-value="1" class="m-0 d-flex align-items-center"></v-switch> -->
                                            <v-switch v-model="has_promo" :disabled="service === 1 || override_srp.promo_type == 1 || override_srp.promo_type == 2" hide-details dense
                                                label="Has Promo" color="primary" :true-value="1" :false-value="0" class="m-0 d-flex align-items-center"></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col sm="4" class="d-flex">
                                            <v-autocomplete
                                                v-model="customer_id"
                                                :items="filtered_customers_list"
                                                :disabled="service == 1"
                                                dense
                                                outlined
                                                hide-details
                                                auto-select-first
                                                clearable
                                                label="Select Customer"
                                                class="d-inline-block small"
                                                @change="getSystemPackingLists()"
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip x-small rounded left :color="data.item.color"></v-chip>
                                                    <span class="ml-2">{{ data.item.text }}</span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <v-tooltip color="black" bottom>
                                                        <template #activator="{ on }">
                                                            <v-chip v-on="on" x-small rounded left :color="data.item.color"></v-chip>
                                                            <span class="ml-2">{{ data.item.text }}</span>
                                                        </template>
                                                        <span>{{ data.item.description }}</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-autocomplete>
                                            <span v-for="errors in errors.customer_id" class="text-warning"
                                                :key="errors">{{ errors }}</span>
                                        </v-col>
                                        <v-col sm="4" class="d-flex">
                                            <v-autocomplete v-model="customer.shipping_address"
                                                :items="shipping_address_list" :disabled="service == 1" dense
                                                outlined hide-details auto-select-first
                                                label="Select Address" color="#b3d4fc" class="w-100 small"
                                                id="shipping_address_text"></v-autocomplete>
                                            <span v-for="errors in errors.shipping_address" class="text-warning" :key="errors">{{ errors }}</span>
                                        </v-col>
                                        <v-col sm="2" class="d-flex">
                                            <v-autocomplete
                                                v-model="salesman_id"
                                                :items="salesman_list"
                                                :disabled="service == 1"
                                                dense
                                                outlined
                                                hide-details
                                                auto-select-first
                                                item-value="value"
                                                item-text="text"
                                                label="Select Salesman"
                                                class="d-inline-block"
                                            ></v-autocomplete>
                                            <span v-for="errors in errors.salesman_id" class="text-warning" :key="errors">{{ errors }}</span>
                                        </v-col>
                                        <v-col sm="12" md="2">
                                            <v-text-field
                                                v-model="terms"
                                                :readonly="promo_id != ''"
                                                dense
                                                outlined
                                                hide-details
                                                label="Terms"
                                                aria-label="%"
                                                aria-describedby="basic-addon2"
                                                suffix="days"
                                                @keypress="numberOnly"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <!-- <v-col sm="12" md="5">
                                            <v-row class="ml-2 mt-2" v-if="customer != '' && customer.discounts.length > 0 && promo_id == null">
                                                <v-col sm="12" md="8">
                                                    <v-row class="p-0 text-dark">
                                                        <v-col sm="12" md="6" class="py-0"
                                                            v-for="(disc, index) in itemTypeDiscounts" :key="index"
                                                            v-show="orderList[0] != null && disc.length > 0 && disc.substring(0, 2) == orderList[0].type"
                                                        >
                                                            <span>{{ disc }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-spacer></v-spacer>
                                            </v-row>
                                        </v-col> -->
                                        <v-col cols="2" class="d-flex" v-if="action == 'Add'">
                                            <v-autocomplete
                                                v-model="parent_packing_list_id_ingco"
                                                :items="parent_packing_lists_ingco"
                                                :disabled="!!parent_packing_list_id_wadfow"
                                                label="Select Parent PL# (Ingco)"
                                                class="d-inline-block"
                                                clearable
                                                dense
                                                outlined
                                                hide-details
                                                auto-select-first
                                                @change="getSystemPackingListItems('ingco', parent_packing_list_id_ingco)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" class="d-flex" v-if="action == 'Add'">
                                            <v-autocomplete
                                                v-model="parent_packing_list_id_wadfow"
                                                :items="parent_packing_lists_wadfow"
                                                :disabled="!!parent_packing_list_id_ingco"
                                                label="Select Parent PL# (Wadfow)"
                                                class="d-inline-block"
                                                clearable
                                                dense
                                                outlined
                                                hide-details
                                                auto-select-first
                                                @change="getSystemPackingListItems('wadfow', parent_packing_list_id_wadfow)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col sm="6" md="3">
                                            <v-textarea v-model="notes" label="Notes" rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                        <v-col sm="6" md="1">
                                            <v-textarea v-model="customer_po_num" :disabled="service == 1" label="PO#" no-resize rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                        <v-col sm="6" md="3">
                                            <v-textarea v-model="remarks" label="Remarks" no-resize rows="2" class="p-0"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </div>

                                <v-card outlined class="p-2">
                                    <v-row>
                                        <v-col sm="12" md="12">
                                            <div class="float-left ma-4">
                                                <v-btn dark small color="bg-primary"  @click="batch_addition.dialog = true">Batch Addition</v-btn>
                                                <!-- <v-btn dark small color="bg-primary" :disabled="disableBatchAddition() || orderList.lenght == 0" @click="overrideAmounts()">Override amounts</v-btn> -->
                                            </div>
                                            <div class="float-right ma-4" >
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px" @click="addOrderItem()">
                                                    <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                </v-btn>
                                                <v-btn fab dark small color="primary" style="height: 20px; width: 20px" @click="removeOrderItem()">
                                                    <v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                        <!-- EDIT this  -->
                                        <!-- <v-col sm="12" md="12" v-if="!hasPromo()" class="d-flex">
                                            <v-checkbox v-model="override_srp.promo_type" true-value="1" false-value="0" class="mx-3" label="SP Price" :disabled="has_promo === 1">

                                            </v-checkbox>
                                            <v-checkbox v-model="override_srp.promo_type" true-value="2" false-value="0" label="Special Approval" :disabled="has_promo === 1">

                                            </v-checkbox>
                                        </v-col> -->

                                        <v-col sm="12" md="12">
                                            <v-simple-table dense class="dynamic_list_table">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center mx-2" width="12%">Model</th>
                                                            <th class="text-center mx-2" width="22%">Name</th>
                                                            <th class="text-center mx-2" width="9%">MOQ</th>
                                                            <th class="text-center mx-2" width="9%">Order Qty</th>
                                                            <th class="text-center mx-2" v-if="stock_card == 1">SC Qty</th>
                                                            <th class="text-center mx-2" width="9%">UOM</th>
                                                            <th class="text-center mx-2">SRP</th>
                                                            <th class="text-center mx-2">Disc. Price</th>
                                                            <th class="text-center mx-2" width="1%">FOC</th>
                                                            <th class="text-center mx-2">Total</th>
                                                            <th class="text-center mx-2" width="7%">Type</th>
                                                            <th class="text-center mx-2" width="1%"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(order_item, i) in orderList" :key="i" :class="`${order_item.color ? order_item.color : ''}`" v-if="!rerender">
                                                            <td v-if="(action == 'Add' || orderList[i].item_id == '' || orderList[i].is_editable) && !orderList[i].is_bundle">
                                                                <v-autocomplete
                                                                    v-model="orderList[i].item_id"
                                                                    :items="filtered_items_list"
                                                                    :disabled="service == 1"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    auto-select-first
                                                                    placeholder="Select Model"
                                                                    @input="selectModel(i, orderList[i].item_id, true); checkItemStocks(orderList[i], i);"
                                                                    @keydown.tab="getItemIdSingle($event.target.value)"
                                                                ></v-autocomplete>
                                                            </td>
                                                            <td v-else>
                                                                <v-text-field
                                                                    v-model="orderList[i].item.model"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    readonly
                                                                    class="centered-input"
                                                                    background-color="grey lighten-2"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="orderList[i].item.name"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    readonly
                                                                    background-color="grey lighten-2"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="orderList[i].item.moq"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    readonly
                                                                    class="centered-input"
                                                                    background-color="grey lighten-2"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="orderList[i].quantity"
                                                                    :disabled="orderList[i].is_bundle || service == 1"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    class="centered-input"
                                                                    @keypress="numberOnly"
                                                                    @input="changeQuantity(i)"
                                                                    @blur="checkMoq(i)"
                                                                    @focus="$event.target.select()"
                                                                    @keydown="checkItemStocks(orderList[i], i)"
                                                                ></v-text-field>
                                                            </td>
                                                            <td v-if="stock_card == 1">
                                                                <v-text-field
                                                                    v-model="orderList[i].sc_quantity"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    class="centered-input"
                                                                    @keypress="numberOnly"
                                                                    @input="changeQuantitySC(i)"
                                                                    @blur="checkMoqSC(i)"
                                                                    @focus="$event.target.select()"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="orderList[i].item.item_uom_text"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    readonly
                                                                    class="centered-input"
                                                                    background-color="grey lighten-2"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-if="!orderList[i].foc"
                                                                    v-model="orderList[i].item.display_srp"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    :readonly="override_srp.enabled ? false :true"
                                                                    class="right-input"
                                                                    @change="recomputeDiscAmount(i)"
                                                                    :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                ></v-text-field>
                                                                <v-text-field
                                                                    v-else
                                                                    v-model="foc_price"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    :readonly="override_srp.enabled ? false :true"
                                                                    class="right-input"
                                                                    :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                    @change="recomputeDiscAmount(i)"
                                                                ></v-text-field>
                                                            </td>
                                                            <td v-if="orderList[i].override_amount == null || orderList[i].override_amount == 0">
                                                                <v-text-field
                                                                    v-if="!orderList[i].foc"
                                                                    v-model="orderList[i].display_disc_price"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    :readonly="override_srp.enabled ? false :true"
                                                                    class="right-input"
                                                                    :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                    @change="recomputeDiscAmount(i,1)"
                                                                    @click="showOverrideAmountDialog(i)"
                                                                ></v-text-field>
                                                                <v-text-field
                                                                    v-else
                                                                    v-model="foc_price"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    :readonly="override_srp.enabled ? false :true"
                                                                    class="right-input"
                                                                    :background-color="override_srp.enabled ? '' :'grey lighten-2'"
                                                                    @change="recomputeDiscAmount(i,1)"
                                                                ></v-text-field>
                                                            </td>
                                                            <td v-else>
                                                                <v-text-field
                                                                    v-model="orderList[i].display_override_amount"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    readonly
                                                                    class="right-input bg-ingco"
                                                                    background-color="grey lighten-2"
                                                                    @click="showOverrideAmountDialog(i)"
                                                                ></v-text-field>
                                                            </td>
                                                            <v-tooltip :disabled="!orderList[i].foc_remarks" bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <td v-bind="attrs" v-on="on">
                                                                        <v-checkbox
                                                                            v-model="orderList[i].foc"
                                                                            color="primary"
                                                                            input-value="1"
                                                                            hide-details
                                                                            class="ma-0 pa-0"
                                                                            @change="changeFoc(i)"
                                                                        ></v-checkbox>
                                                                    </td>
                                                                </template>
                                                                <span>{{ orderList[i].foc_remarks }}</span>
                                                            </v-tooltip>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="orderList[i].display_total_amount"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    readonly
                                                                    class="right-input"
                                                                    background-color="grey lighten-2"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-text-field
                                                                    v-model="orderList[i].type"
                                                                    dense
                                                                    outlined
                                                                    hide-details
                                                                    readonly
                                                                    class="centered-input"
                                                                    background-color="grey lighten-2"
                                                                ></v-text-field>
                                                            </td>
                                                            <td>
                                                                <v-btn v-if="service == 0" fab dark small color="primary"
                                                                    style="height: 20px; width: 20px"
                                                                    @click="removeOrderByIndex(i)">
                                                                    <v-icon dark small style="font-size: 12px">
                                                                        mdi-minus
                                                                    </v-icon>
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                        <v-col lg="12" class="d-flex justify-content-center align-items-center"  v-if="rerender">
                                            <v-progress-circular
                                                :width="12"
                                                :size="100"
                                                color="primary"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-row class="m-2">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col sm="12" md="8" class="text-left">
                                                <v-row class="ml-2"></v-row>
                                                <v-row class="ml-2"></v-row>
                                            </v-col>
                                            <v-col sm="12" md="4" class="pull-right text-right">
                                                <h5>Total Amount: &#x20b1;{{ this.sales_total_amount | currency }}</h5>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-row>

                                <v-col sm="12" md="12">
                                    <table class="table-bordered" style="font-size: 16px">
                                        <tr v-for="(e, i) in batch_addition.errors" :key="i">
                                            <th class="px-3">{{ e.title }}</th>
                                            <td class="px-4" style="color: red">{{ e.data }}</td>
                                        </tr>
                                    </table>
                                </v-col>

                                <div class="border-top">
                                    <div class="card-body py-4 d-flex justify-content-center">
                                        <v-btn @click="checkForFoc(action)" v-if="action == 'Add'" :loading="loading.submit">Submit</v-btn>
                                        <v-btn @click="checkForFoc(action)"
                                            v-else-if="action == 'Edit'">Update</v-btn>
                                    </div>
                                </div>
                            </form>
                        </v-card-text>
                        <v-card-text v-else>
                            <div class="text-center">
                                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                            </div>
                        </v-card-text>
                    </v-container>

                    <v-container fluid style="display:none;">
                        <div>
                            <table id="items_details">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>QTY</th>
                                        <th>UNITS</th>
                                        <th>MODEL</th>
                                        <th>DESCRIPTION</th>
                                        <th>UNIT PRICE</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody id='tr_items'>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>***** NOTHING FOLLOWS *****</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-container>

                </v-card>
            </v-dialog>

            <v-dialog v-model="override_amount_dialog" persistent max-width="384" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="override_amount_dialog = false;">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row>
                            <v-col sm="12" md="6" class="m-auto text-center">
                                <label for="override_amount" class="control-label col-form-label">Override Amount</label>
                                <v-text-field
                                    v-model="override_amount"
                                    dense
                                    hide-details
                                    id="override_amount"
                                    placeholder="0.00"
                                    @keypress="decimalOnly">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-content-center">
                                <v-btn @click="confirmOverrideAmount()">Confirm</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="batch_addition.dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Copy Model and Qty From Excel</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeBatchAdditionDialog(); dataFromExcel = '';">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="p-4">
                        <v-row>
                            <v-textarea
                                v-model="dataFromExcel"
                                :autofocus="batch_addition.dialog"
                                rows="1"
                                small
                                clearable
                                color="primary"
                                label="Paste From Excel"
                                @click="$event.target.focus()"
                                @focus="dataFromExcel = null"
                                @paste="onPaste"
                            ></v-textarea>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="approve_override_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Amounts</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="approve_override_dialog = false; override_username = ''; override_user_password = ''; override_user_id = -1;">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <label for="override_user_password"
                                    class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_user_password"
                                    placeholder="Password" v-model="override_user_password" autocomplete="new-password">
                                <span v-for="errors in errors.override_user_password" class="text-warning"
                                    v-bind:key="errors">{{ errors }}</span>
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn
                                    v-on:click="approveOverrideAmounts(); departments_allowed = ['SM', 'AC', 'EX', 'IT']">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="approve_override_order_dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Confirm Override Edit Sales Order</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="approve_override_order_dialog = false; override_order_username = ''; override_order_user_password = ''; override_order_user_id = -1;">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <label for="override_order_user_password"
                                    class="float-left control-label col-form-label">Password</label>
                                <input type="password" class="form-control" id="override_order_user_password"
                                    placeholder="Password" v-model="override_order_user_password"
                                    autocomplete="new-password">
                                <span v-for="errors in errors.override_order_user_password" class="text-warning"
                                    v-bind:key="errors">{{ errors }}</span>
                            </div>
                        </div>
                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn v-on:click="approveOverrideEditOrder()">Confirm</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="mass_import.dialog" persistent max-width="30%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Copy PL# From Excel</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                color="gray"
                                class="float-right"
                                text
                                icon
                                @click="toggleMassImport(false)"
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row class="mb-2 mt-1 mx-auto">
                            <v-col cols="12">
                                <v-select
                                    v-model="mass_import.selected_brand"
                                    :items="mass_import.brand_list"
                                    label="Select Ingco/Wadfow"
                                    dense
                                    outlined
                                    hide-details
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2 mt-1 mx-auto" v-if="mass_import.selected_brand">
                            <v-col>PL#</v-col>
                            <v-col cols="2" class="text-center">Action</v-col>
                        </v-row>
                        <v-row class="mx-0 pa-0 ma-0" v-if="mass_import.selected_brand" v-for="(item, index) in mass_import.data" :key="index">
                            <v-col cols="10" class="pa-0 ma-0">
                                <v-text-field
                                    v-model="item.data"
                                    :autofocus="mass_import.dialog"
                                    color="primary"
                                    dense
                                    outlined
                                    hdie-details
                                    @paste="processMassImportData"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" class="ma-0 text-center">
                                <v-btn
                                    style="height:20px; width:20px;"
                                    color="primary"
                                    fab
                                    dark
                                    small
                                    @click="clearMassImportData(index)"
                                >
                                    <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mx-0 px-0 mx-0">
                            <v-col class="d-flex justify-content-end">
                                <v-btn :disabled="!mass_import.pasted" :loading="mass_import.loading" @click="clearMassImportData()">Clear</v-btn>
                                <v-btn :disabled="!mass_import.pasted || !mass_import.data.length" :loading="mass_import.loading" @click="submitMassImportData()">Submit</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>

    </div>
</template>

<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue'
import SalesFunctionsComponent from '@/views/main/Shared/SalesFunctionsComponent.vue'
import SalesOrdersTableComponent from './SalesOrdersTableComponent.vue'
import PusherFunctionComponent from '@/views/main/Shared/PusherFunctionComponent2.vue';
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver');
import swal from 'sweetalert2';
import { mapGetters } from "vuex";
import moment from 'moment'

export default {
    mixins: [
        SharedFunctionsComponent,
        SalesFunctionsComponent,
        PusherFunctionComponent
    ],
    components: {
        SalesOrdersTableComponent,
    },
    props: [
        'status_text',
        'service',
        'print'
    ],
    data() {
        return {
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: []
            },
            dataFromExcel: '',
            fromShowEditModal: false,
            stock_card: 0,
            promo_name: '',
            promo_id: null,
            promo: {
                id: '',
                name: '',
                code: '',
            },
            customer_promo: {
                discount_rate: '',
                cash_price: ''
            },
            override_username: '',
            override_user_password: '',
            override_user_id: -1,
            override_amount: '',
            override_index: '',
            override_order_username: '',
            override_order_user_password: '',
            override_order_user_id: -1,
            editSalesOrderId: '',
            add_edit_salesorder_dialog: false,
            override_amount_dialog: false,
            approve_override_dialog: false,
            approve_override_order_dialog: false,
            quote_num: '',
            order_num: '',
            quote_date: '',
            customer_id: '',
            salesman_id: '',
            user_id: '',
            remarks: '',
            discounts: '',
            terms: '',
            orig_terms: '',
            convert_date: '',
            status: '',
            created_at: '',
            issuer_name: '',
            foc_price: '0.00',
            delivery_method: 2,
            new_so_ids: null,

            tempOrderList: [],
            orderList: [],
            order: {
                sc_quantity: '',
                quantity: '',
                item_id: '',
                foc: '',
                disc_price: '',
                total_amount: '',
                amount: '',
                type: '',
                item: {
                    id: '',
                    model: '',
                    name: '',
                    description: '',
                    type_id: '',
                    category_id: '',
                    uom_id: '',
                    item_uom_text: '',
                    barcode: '',
                    selling_price: '',
                    sp_price: '',
                    srp: '',
                    override_amount: '',
                    moq: '',
                    brand_id: '',
                },
                is_bundle: false,
                promos: [],
                selected_promo: '',
                promo_items_id: '',
                promo_amount: '',
                parent_so_item_id: null,
            },
            customer: {
                shipping_address: 0,
                billing_address: 0,
                salesman_name: '',
                remarks: '',
                terms: '',
                discounts: [],
                is_sp: '',
                is_vip: '',
            },
            discount: {
                pt: '',
                ht: '',
                sp: '',
            },
            itemTypeDiscounts: [],
            max_discounts_count: 999,
            max_order_items_count: 999,
            shipping_address_list: [],
            customers_list: [],
            users_list: [],
            salesman_list: [],
            items_list: [],
            type_list: [],
            promo_list: [],
            shortlist_item_ids: [],
            shortlist_customer_ids: [],
            items_not_in_promo: [],
            delivery_method_list: [],
            errors: [],
            action: '',
            actionResult: {
                model: 'Sales Order',
                action: ''
            },
            loaded: false,
            userAccess: {
                create: false,
            },
            customer_po_num: '',
            orderListLength: 0,
            counter: 0,
            departments_allowed: [],
            filter_data:{},
            exportByStatus: [
                {text: 'Raw Data with Stocks', value: 0},
                {text: 'Raw Data without Stocks', value: 1},
                {text: 'Summary', value: 2},
            ],
            columns: [],

            loading: {
                submit: false,
                export: false,
                items:false
            },
            notes: null,
            alreadySubstitutedItem:false,
            exclusive_items:[],
            has_promo: 0,
            override_srp:{
                title:'SRP',
                promo_type:0,
                enabled:false
            },
            rerender:false,

            parent_packing_list_id_ingco: null,
            parent_packing_list_id_wadfow: null,
            parent_packing_lists_ingco: [],
            parent_packing_lists_wadfow: [],

            mass_import: {
                dialog: false,
                loading: false,
                data: [],
                brand_list: [
                    { text: 'INGCO', value: 'ingco' },
                    { text: 'WADFOW', value: 'wadfow' },
                    // { text: 'DECAKILA', value: 'decakila' },
                ],
                selected_brand: null,
                pasted: 0,
            },
        }
    },
    methods: {
        toggleMassImport(value = true) {
            if (value) {
                if (!this.mass_import.data.length) {
                    this.pushMassImportData()
                }
            } else {
                this.clearMassImportData()
            }

            this.mass_import.dialog = value
        },
        pushMassImportData(){
            let obj = Object.assign({}, {
                data: null,
            })

            this.mass_import.data.push(obj)
        },
        clearMassImportData(index = null) {
            if (index) {
                this.mass_import.data.splice(index, 1)
            } else {
                this.mass_import.data = []
                this.mass_import.selected_brand = null
                this.pushMassImportData()
            }

            if (this.mass_import.data.length == 1) {
                if (this.mass_import.data[0].data === null) {
                    this.mass_import.pasted = 0
                }
            }
        },
        processMassImportData(evt) {
            evt.preventDefault()

            let pastedData = evt.clipboardData.getData('Text')
            if (pastedData.search(/[\r\n][\n]/g)) {
                let packing_lists = pastedData.split(/[\r\n][\n]/g)

                if (this.mass_import.data.length) {
                    this.mass_import.data.pop()
                }

                for (let e of packing_lists) {
                    this.mass_import.data.push(
                        Object.assign({}, {
                            data: e
                        })
                    )
                }

                this.mass_import.pasted = 1
            } else {
                swal.fire({
                    title: 'Pasted Data format is not supported!',
                    icon: 'warning'
                })
            }
        },
        async submitMassImportData() {
            this.mass_import.loading = true

            for await (let e of this.mass_import.data) {
                await this.$store.dispatch('salesOrdersPost', {
                    selected_brand: this.mass_import.selected_brand,
                    packing_lists: this.mass_import.data.map(m => m.data),
                    url: 'massCreateSalesOrderFromPackingList'
                })
            }

            swal.fire({
                title: 'SUCCESS',
                icon: 'success',
            })

            this.action = "success"

            this.clearMassImportData()
            this.mass_import.dialog = false
            this.mass_import.loading = false
        },
        hasPromo() {
            return this.promo_id !== null && this.promo_id !== undefined && this.promo_id !== "";
        },
        disableBatchAddition() {
            if (this.customer_id != '' && (this.promo_id == null || this.promo_id == '')) {
                return false
            } else {
                return true
            }
        },
        closeBatchAdditionDialog() {
            this.batch_addition.data = []
            this.batch_addition.dialog = false
        },
        onPaste(evt) {
            this.batch_addition.data = []
            var pastedText = evt.clipboardData.getData('Text')
            var rows = pastedText.split(/[\r\n][\n]/g)
            rows.forEach((y, index) => {
                if (y.trim() === "") return; // Skip empty rows
                let cells = y.split("\t");

                if (index !== rows.length - 1 || cells[0] !== "") {
                    this.batch_addition.data.push(Object.assign({}, {
                        model: cells[0],
                        qty: cells[1],
                        id: '',
                        skip: false,
                    }));
                }
            })
            this.getItemId()
            this.closeBatchAdditionDialog()
        },
        approveOverrideEditOrder() {
            // if(this.override_order_username == '' || this.override_order_user_password == ''){
            if (this.override_order_user_password == '') {
                this.override_order_user_id = -1
                this.approve_override_order_dialog = false
            } else {
                this.$store.dispatch('confirmOverride', {
                    departments_allowed: ['SM', 'AC', 'EX', 'IT'],
                    override_user_password: this.override_order_user_password,
                }).then(response => {
                    let data = response.data

                    // this.override_order_username = ''
                    this.override_order_user_password = ''

                    if (data.error) {
                        this.override_order_user_id = -1
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.override_order_user_id = data.user_id //get user id after entering username password
                    this.approve_override_order_dialog = false
                    this.proceedUpdateOrder()
                }).catch(error => {
                    this.override_order_user_id = -1
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                });
            }
        },
        showAddSalesOrder() {
            this.add_edit_salesorder_dialog = true
            this.action = 'Add'
        },
        addNewSalesOrder() {
            // if (this.checkItemsZeroSRP()) {
            //     this.override_user_id == -1
            //     this.showApproveOverrideAmountsDialog()
            // } else {
            //     return false
            // }
            this.proceedSaveOrder()
        },
        checkOrderTableHeight() {
            /*var limit = 155;
            var shipping_address_text = $('#shipping_address_text').parent().text();
            if(shipping_address_text != null && shipping_address_text != ''){
                if(shipping_address_text.length > 60){
                    limit = 145;
                }
            }*/

            /*var tableHeight =  this.computeOrderTableHeight()
            if(tableHeight > limit){
                swal.fire("", "This will render more than 1 page. \n Please delete item(s) to proceed", "warning");
            }else{
                if(this.action == 'Add'){
                    this.proceedSaveOrder()
                }else if(this.action == 'Edit'){
                    this.proceedUpdateOrder()
                }

            }*/
            //this.computeOrderTableHeight()

            if (this.action == 'Add') {
                this.proceedSaveOrder()
            } else if (this.action == 'Edit') {
                this.proceedUpdateOrder()
            }
        },
        async proceedSaveOrder() {
            this.loading.submit = true;
            this.errors = [];
            // var shipping_address_text = $("shipping_address_text").parent().text();
            let shipping_address_text = ''
            let noOfItems = this.PRINT_NO_OF_LINES
            this.shipping_address_list.forEach(e => {
                if (e.value == this.customer.shipping_address) {
                    shipping_address_text = e.text
                    noOfItems -= Math.ceil(shipping_address_text.length / 60)
                }
            })
            this.checkOrderTableHeightAddItem();
            let unique_key = "id" + Math.random().toString(16).slice(2)

            this.groupItems(this.orderList, noOfItems)

            let parameters = {
                customer_id: this.customer_id,
                salesman_id: this.salesman_id,
                remarks: this.remarks,
                orderList: this.orderList,
                shipping_address_index: this.customer.shipping_address,
                shipping_address_text: shipping_address_text,
                discounts: this.customer.discounts,
                terms: this.terms,
                override_by: this.override_user_id,
                promo_id: this.promo_id,
                customer_po_num: this.customer_po_num,
                delivery_method: this.delivery_method,
                stock_card: this.stock_card,
                notes: this.notes,
                has_promo: this.has_promo,
                unique_key: unique_key,
                override_moq: this.customer.override_moq,
                override_amount_approval:this.override_srp.promo_type > 0 ? 1 : 0,
                parent_packing_list_id_ingco: this.parent_packing_list_id_ingco,
                parent_packing_list_id_wadfow: this.parent_packing_list_id_wadfow,
                url: 'salesOrders',
            };
            this.$store.dispatch("salesOrdersPost", parameters).then((response) => {
                let data = response.data;

                if(data.error){
                    swal.fire('', data.error, "warning");
                    this.loading.submit = false
                    return false;
                }

                window.Echo.channel('smod-channel').listen('ConvertToSOMessage',(e)=>{
                    let data = e.data.find(el => el.userID == this.USERACCOUNT_LOAD.id);
                    let check_unique_id = (e.data[0].unique_key.toString().replace(/"/g,'') == unique_key)

                    if(check_unique_id && data && (data.message.msg || data.message.error) ){
                        if(data.message.error){
                            swal.fire('', data.message.error, "warning");
                            this.loading.submit = false
                            return false;
                        }
                        this.resetFields();
                        this.add_edit_salesorder_dialog = false;
                        this.loading.submit = false
                        this.action = "success"; //use this to avoid double getAllSalesOrders
                        swal.fire({
                            text: data.message.msg,
                            icon: "success",
                        })
                    }
                })
            }).catch((error) => {
                console.log(error)
                this.loading.submit = false
                if(error.response.data.message == 'Item Srp must not be 0!'){
                    swal.fire(
                        "Error",
                        error.response.data.message,
                        "error"
                    )
                    return false
                }
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors;

                }
                if(error.response.status == 406){
                    console.log(error.response)
                    let items = ''
                    error.response.data.items.forEach(e=>{
                        items += `<li>${e}</li> `
                    })

                    swal.fire({
                        title:'Cannot input items due to no container arrive',
                        html:`<b>Items</b><br><ul> ${items} </ul> `,
                        icon:'error'
                    })
                }
            });
        },
        editSalesOrder() {
            // if (this.checkItemsZeroSRP()) {
            //     this.override_user_id == -1
            //     this.showApproveOverrideAmountsDialog()
            // } else {
            //     return false
            // }
            this.proceedUpdateOrder()
        },
        proceedUpdateOrder() {
            this.errors = []
            var shipping_address_text = $('#shipping_address_text').parent().text();
            this.checkOrderTableHeightAddItem()

            //check if status is already approved
            if (this.status == 3 && this.override_order_user_id == -1) {
                this.approve_override_order_dialog = true
            } else {
                this.$store.dispatch('salesOrdersPut', {
                    customer_id: this.customer_id,
                    salesman_id: this.salesman_id,
                    notes: this.notes,
                    remarks: this.remarks,
                    orderList: this.orderList,
                    shipping_address_index: this.customer.shipping_address,
                    shipping_address_text: shipping_address_text,
                    discounts: this.customer.discounts,
                    terms: this.terms,
                    override_by: this.override_user_id,
                    promo_id: this.promo_id,
                    customer_po_num: this.customer_po_num,
                    delivery_method: this.delivery_method,
                    stock_card: this.stock_card,
                    new_so_ids: this.new_so_ids,
                    parent_packing_list_id_ingco: this.parent_packing_list_id_ingco,
                    parent_packing_list_id_wadfow: this.parent_packing_list_id_wadfow,
                    url: 'salesOrders/' + this.editSalesOrderId,
                }).then(response => {
                    let data = response.data
                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_salesorder_dialog = false
                    this.editSalesOrderId = 0
                    this.action = 'success' //use this to avoid double getAllSalesOrders
                    swal.fire("", response.data.msg, "success")

                }).catch(error => {
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                });
            }
        },
        resetFields() {
            this.errors = []
            this.quote_num = ''
            this.order = ''
            this.quote_date = ''
            this.customer_id = ''
            this.salesman_id = ''
            this.user_id = ''
            this.remarks = ''
            this.customer.discounts = []
            this.discounts = ''
            this.terms = ''
            this.convert_date = ''
            this.status = ''
            this.customer_id = ''
            this.shipping_address_list = []
            this.orderList = []
            this.itemTypeDiscounts = []
            this.type_list = []
            this.override_user_id = -1
            this.override_amount = ''
            this.override_index = ''
            this.override_username = ''
            this.override_user_password = ''
            this.promo_list = []
            this.promo_id = null
            this.shortlist_item_ids = []
            this.shortlist_customer_ids = []
            this.promo = ''
            this.customer_promo = ''
            this.items_not_in_promo = []
            this.customer_po_num = ''
            this.stock_card = 0
            this.delivery_method = 2
            this.tempOrderList = []
            this.override_order_username = ''
            this.override_order_user_password = ''
            this.override_order_user_id = -1
            this.batch_addition.errors = []
            this.new_so_ids = null
            this.parent_packing_list_id_ingco = null
            this.parent_packing_list_id_wadfow = null
        },
        addKeyListener() {
            window.addEventListener("keyup", function (e) {
                var keypressed = String.fromCharCode(e.keyCode)
                if (e.ctrlKey && e.keyCode == 32) {
                    this.addOrderItem()
                    $("input").blur();

                } else if (keypressed == '-') {
                    this.removeOrderItem()
                    $("input").blur();
                }

            }.bind(this));
        },
        filters(value){
            this.filter_data = value;
        },
        async exportSalesOrder(items, status_text){
            if(items.value == 0 || items.value == 1) {
                this.columns = [
                    { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                    { header: 'PARENT PL#', key: 'parent_packing_list_num', style: { numFmt: '@' } },
                    { header: 'ITEM MODEL', key: 'item_model' },
                    { header: 'ITEM NAME', key: 'item_name' },
                    // { header: 'ITEM TYPE', key: 'item_type' },
                    // { header: 'UOM', key: 'item_uom_text' },
                    { header: 'CUSTOMER', key: 'company_name' },
                    // { header: 'SALESMAN', key: 'salesman_name'},
                    { header: 'CREATED BY', key: 'created_by' },
                    { header: 'FOC', key: 'foc' },
                    { header: 'REMARKS', key: 'remarks' },
                    { header: 'STATUS', key: 'status_text' },
                    { header: 'SRP', key: 'display_srp', style: { numFmt: '#,##0.00' } },
                    // { header: 'DISC PRICE', key: 'display_disc_price', style: { numFmt: '#,##0.00' } },
                    { header: 'PRICE', key: 'display_final_price', style: { numFmt: '#,##0.00' } },
                    { header: 'QTY', key: 'quantity', style: { numFmt: '#,##0' } },
                    { header: 'SERVED QTY', key: 'quantity_served', style: { numFmt: '#,##0' } },
                    { header: 'UNSERVED QTY', key: 'unserved_qty', style: { numFmt: '#,##0' } },
                    { header: 'CANCELLED QTY', key: 'quantity_canceled', style: { numFmt: '#,##0' } },
                    { header: 'PREPARED QTY', key: 'prepared_quantity', style: { numFmt: '#,##0' } },
                    // { header: 'TOTAL', key: 'display_total_amount' },
                    { header: 'DELIVERY DATE', key: 'delivery_date' },
                    // { header: 'DATE ISSUED', key: 'sales_order_date' },
                    // { header: 'DATE APPROVED(SALES)', key: 'approve_date' },
                    // { header: 'DATE APPROVED(ACCT)', key: 'acc_approved_date' },
                    // { header: 'ISSUER', key: 'issuer_name' },
                    // { header: 'PROMO', key: 'promo_id' },
                    // { header: 'SC', key: 'stock_card' },
                ]
            } else if (items.value == 2) {
                this.columns = [
                    { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                    { header: 'SQ#', key: 'quote_num', style: { numFmt: '@' } },
                    { header: 'PARENT PL#', key: 'parent_packing_list_num', style: { numFmt: '@' } },
                    { header: 'CUSTOMER', key: 'company_name' },
                    { header: 'PO#', key: 'customer_po_num' },
                    { header: 'REMARKS', key: 'remarks' },
                    { header: 'SALESMAN', key: 'salesman_name' },
                    { header: 'AMOUNT', key: 'display_total_amount', style: { numFmt: '#,##0.00' } },
                    { header: 'STATUS', key: 'status_text' },
                    { header: 'DATE ISSUED', key: 'sales_order_date', style: { numFmt: 'm/d/yyyy' } },
                    { header: 'DATE APPROVED (ACCTG)', key: 'acc_approved_date', style: { numFmt: 'm/d/yyyy' } },
                    { header: 'ISSUER', key: 'created_by' },
                    { header: 'PROMO', key: 'promo_id' },
                    { header: 'SC', key: 'stock_card' },
                ]
            }
            await this.$store.dispatch('getWarehousesRequest', this.USERACCOUNT_LOAD.id).then(async res => {
                if (items.value == 0) {
                    if (res.data.data) {
                        res.data.data.forEach(x => {
                            this.columns.push({ header: x.code, key: x.code, style: { numFmt: "#,##0" } })
                        })
                    }
                }

                this.filter_data.url = "getAll";
                this.filter_data.export = 1;
                this.filter_data.status_text = status_text;
                this.filter_data.export_status = items.value;

                let excelData = [];
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sales Order')

                swal.fire('Exporting Data');
                swal.showLoading();

                await this.$store.dispatch("salesOrdersGet", this.filter_data).then((response) => {
                    excelData = response.data
                    worksheet.columns = this.columns

                    excelData.forEach((e, i) => {
                        if (items.value == 2) {
                            e.display_total_amount = parseFloat(e.display_total_amount)
                        } else {
                            e.foc = e.foc != 0 ? 'YES' : "NO";
                            e.delivery_date = e.delivery_date ? moment(e.delivery_date).format('MM/DD/YYYY') : 'No Delivery Date'
                            e.display_srp = parseFloat(e.display_srp)
                            e.display_disc_price = parseFloat(e.display_disc_price)
                            e.display_final_price = parseFloat(e.display_final_price)
                        }

                        worksheet.addRow(e);
                    })

                }).catch((e) => {
                    swal.fire('Error !', '', 'error')
                    console.log(e)
                });
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    FileSaver.saveAs(blob, `SalesOrder.xlsx`);
                    swal.close();
                });
            });
        },
        async checkItemStocks(val, i) {
            await this.$store.dispatch(`TotalStocks`, val.item_id).then((response) => {
                val.quantity == '' ? (val.quantity = 0) : '';

                if(parseInt(response.data) == 0){
                    this.orderList[i].color = "bg-info";
                    if(!this.alreadySubstitutedItem && (this.promo_id == null || this.promo_id == '')){
                        let payload = {
                            item_id:val.item_id
                        }
                        this.$store.dispatch('getSubstitutionItemModel',payload).then(res=>{
                            let data = res.data

                            if(data){
                                this.selectModel(i, data.old_item_id, true);
                                this.orderList[i].original_item_id = data.item_id
                                swal.fire('','Some item has been replaced with new model(s).','warning');
                                this.alreadySubstitutedItem = true
                            }
                            else{
                                this.orderList[i].color = "bg-danger";
                            }
                        })
                    }
                }
                else if (parseInt(response.data) < parseInt(val.quantity)) {
                    this.orderList[i].color = "bg-danger";
                } else {
                    this.orderList[i].color = '';
                }
                this.$forceUpdate();
            });
        },
        checkForFoc(action) {
            let container = this.orderList.map((x) => x.foc);
            // let focChecker = container.includes(1);

            // if (!this.has_promo && (!!focChecker)) {
            //     this.approve_override_order_dialog = true;
            // } else {
                if (action == "Edit") {
                    this.editSalesOrder();
                } else {
                    this.addNewSalesOrder();
                }
            // }
        },
        async showEditModal(value) {
            this.itemWithNoStock = [];
            this.fromShowEditModal = true;
            this.action = "Edit";
            this.editSalesOrderId = value;
            this.getCustomers(true);
            this.getUsers();
            this.getSalesmanList();
            this.getItems();
            this.getItemsNotInPromo();
            this.getItemTypes();
            this.getPromos();
            this.delivery_method_list = [
                // {'value' : 0, 'text' : 'none'},
                { value: 1, text: "pick-up" },
                { value: 2, text: "delivery" },
            ];

            if (this.editSalesOrderId != null && this.editSalesOrderId > 0) {
                this.$store.dispatch("salesOrdersGet", {
                    url: 'salesOrders/' + this.editSalesOrderId,
                }).then((response) => {
                    let data = response.data;

                    if (data.error) {
                        swal.fire('', data.error, "warning");
                        return false;
                    }

                    let sales_order = data.sales_order;
                    let orderList = data.sales_order.sales_order_items;

                    this.created_date = new Date(
                        Date.parse(
                            new Date(sales_order.created_at).toLocaleString("en-US", {
                                timeZone: "Asia/Manila",
                            })
                        )
                    );

                    this.customer.id = sales_order.customer_id

                    this.customer.is_sp = sales_order.special;
                    this.customer.override_moq = sales_order.override_moq;

                    this.customer_promo = data.sales_order.customer_promo;

                    if (sales_order.discounts != '') {
                        this.customer.discounts = JSON.parse(sales_order.discounts);
                    }

                    if (orderList != null && orderList.length > 0) {
                        orderList.forEach((order, index) => {
                            this.addOrderItem();

                            this.orderList[index].id = order.id;
                            this.orderList[index].quantity = order.quantity;
                            this.orderList[index].sc_quantity = order.sc_quantity;

                            this.orderList[index].item.srp = order.srp;
                            this.orderList[index].disc_price = order.disc_price;

                            this.orderList[index].item.sp_price = order.item.sp_price;
                            this.orderList[index].item.selling_price = order.item.selling_price;

                            this.orderList[index].foc = order.foc;

                            this.orderList[index].promos = order.promos;

                            this.orderList[index].orig_sp_price = order.item.sp_price;
                            this.orderList[index].orig_selling_price = order.item.selling_price;

                            this.orderList[index].is_bundle = order.is_bundle;
                            this.orderList[index].backend_promo = order.backend_promo;

                            this.edit = true;

                            this.orderList[index].item_id = order.item_id;

                            this.orderList[index].override_amount = order.override_amount;
                            this.orderList[index].is_editable = true;
                            this.orderList[index].foc_remarks = order.foc_remarks;
                            this.orderList[index].suggested_items = order.item.suggested_items;
                            this.orderList[index].suggested_items_length = order.item.suggested_items.length;
                            this.orderList[index].total_stock_available = order.item.total_stocks
                            this.orderList[index].index = index;
                            this.orderList[index].color = '';

                            this.selectModel(index, order.item_id, false, order);
                        });
                    }

                    this.order_num = sales_order.order_num;
                    this.customer_id = sales_order.customer_id;
                    this.salesman_id = sales_order.salesman_id;
                    this.customer.shipping_address = sales_order.shipping_address_id;
                    this.created_at = sales_order.created_at;
                    this.issuer_name = sales_order.issuer_name;
                    this.remarks = sales_order.remarks;
                    this.override_user_id = sales_order.override_by;
                    this.promo_id = sales_order.promo_id;
                    this.promo_name = sales_order.promo_name;
                    this.customer_po_num = sales_order.customer_po_num;
                    this.delivery_method = sales_order.delivery_method;
                    this.stock_card = sales_order.stock_card;
                    this.notes = sales_order.notes;
                    this.has_promo = sales_order.has_promo

                    this.add_edit_salesorder_dialog = true;
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
        async getSystemPackingLists() {
            for await (let name of ['ingco', 'wadfow']) {
                if (this.customer_id) {
                    this.$store.dispatch('salesOrdersGet', {
                        url: 'getSystemPackingLists',
                        customer_id: this.customer_id,
                        name: name,
                    }).then(response => {
                        this[`parent_packing_lists_${name}`] = response.data
                    })
                } else {
                    this[`parent_packing_lists_${name}`] = []
                }
            }
        },
        getSystemPackingListItems(name, packing_list_id) {
            if (packing_list_id) {
                let current_remarks = this.remarks

                let record = this[`parent_packing_lists_${name}`].find(e => e.value == this[`parent_packing_list_id_${name}`])
                this.remarks =
                    'PL#' + record.text
                    + ' | '
                    + 'CF#' + record.checker_form_id
                    + (record.remarks ? ' | ' + record.remarks : '')
                    + (current_remarks ? ' | ' + this.remarks : '')
                this.customer_po_num = record.customer_po_num

                this.$store.dispatch('salesOrdersGet', {
                    url: 'getSystemPackingListItems',
                    name: name,
                    packing_list_id_field: packing_list_id,
                    customer_id: this.customer_id,
                }).then(response => {
                    this.orderList = []
                    this.addOrderItem()

                    response.data.forEach(async (e, i, a) => {
                        await this.selectModel(i, e.item_id, true, { foc: e.foc })
                        this.orderList[i].quantity = e.quantity

                        if (a.length - 1 != i) {
                            this.addOrderItem()
                        }
                    })
                })
            }
        },
        groupItems(array, linesPerPage) {
            let clean_array = [];
            let temp = [];
            let linesRemaining = linesPerPage;
            let totalLines = 0;
            array.forEach((e, i) => {
                e.index = i;
                const modelLines = Math.ceil(e.item.model.length / 16)
                const nameLines = Math.ceil(e.item.name.length / 47);
                totalLines += Math.max(modelLines, nameLines);
                if (totalLines <= linesRemaining) {
                    temp.push(e);
                } else {
                    clean_array.push(temp);
                    temp = [e];
                    totalLines = nameLines;
                }
                this.orderList[i].limit = clean_array.length
                if (totalLines === linesPerPage || array.length === (i + 1)) {
                    clean_array.push(temp);
                    temp = [];
                    linesRemaining = linesPerPage;
                    totalLines = 0;
                }
            });
            return clean_array;
        },
    },
    mounted() {
        this.addKeyListener()
        this.$store.dispatch("credentials");
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.callPusher();
    },
    watch: {
        add_edit_salesorder_dialog: function () {
            if (this.add_edit_salesorder_dialog == true) {
                this.loaded = false
                if (this.action == 'Add') {
                    this.orderList = []
                    this.getCustomers(true)
                    this.getUsers()
                    this.getSalesmanList()
                    this.getItems()
                    this.getItemsNotInPromo()
                    this.getItemTypes()
                    this.getPromos()
                    this.delivery_method_list = [
                        // { 'value': 0, 'text': 'none' },
                        { 'value': 1, 'text': 'pick-up' },
                        { 'value': 2, 'text': 'delivery' },
                    ]
                    this.created_date = new Date(Date.parse(new Date().toLocaleString('en-US', 'Asia/Manila')))
                }

                if (this.orderList.length == 0) {
                    this.addOrderItem();
                }

                this.loaded = true
            }
        },
        customer_id: function (value) {
            if (this.add_edit_salesorder_dialog == true) {
                this.loaded = false
                if (value > 0) {
                    this.$store.dispatch('showCustomers', value).then(response => {
                        let data = response.data

                        this.shipping_address_list = data.all_shipping_addresses
                        this.salesman_id = data.salesman_id
                        this.terms = data.terms
                        this.customer.is_sp = data.special
                        this.orig_terms = data.terms

                        this.customer.id = data.id

                        if (data.discounts != null && data.discounts != '') {
                            this.customer.discounts = JSON.parse(data.discounts)
                        }

                        if (this.fromShowEditModal == false && this.orderList != null && this.orderList.length > 0) {
                            this.orderList.forEach((order, index) => {
                                this.selectModel(index, order.item_id, false)
                            })
                        } else {
                            this.fromShowEditModal = false
                        }

                        if (this.action == 'Add') {
                            this.orderList = []
                            this.addOrderItem()
                            this.getCustomerPromoInfo()
                        }

                        this.loaded = true
                    }).catch(error => {

                    });
                } else {
                    this.customer = {
                        id: 0,
                        shipping_address: 0,
                        billing_address: 0,
                        salesman_name: '',
                        remarks: '',
                        terms: '',
                        discounts: [],
                        is_sp: '',
                        is_vip: '',
                    }
                    this.loaded = true
                }
            }
        },
        promo_id: async function (value) {
            if (value != null && value > 0) {
                this.loaded = false
                await this.$store.dispatch("getPromoShortlists", value).then((response) => {
                    let data = response.data;

                    this.shortlist_item_ids = data.item_ids;
                    this.shortlist_customer_ids = data.customer_ids;

                    if (this.action == "Add") {
                        this.orderList = [];
                        this.addOrderItem();
                        this.customer_id = '';
                        this.customer.shipping_address = ''
                        this.salesman_id = ''
                        this.terms = ''
                        this.override_srp.promo_type = 0
                    }
                }).catch((error) => {

                });
                this.loaded = true;
            }
        },
        orderList: {
            deep: true,
            handler() {
                if (this.orderList.length > 0) {
                    this.orderList.forEach((order, index) => {
                        if (order.item.srp != null && order.item.srp != '') {
                            order.item.display_srp = this.getTwoDecimals(order.item.srp)
                        } else (
                            order.item.display_srp = ''
                        )

                        if (order.disc_price != null && order.disc_price != '') {
                            order.display_disc_price = this.getTwoDecimals(order.disc_price)
                        } else (
                            order.display_disc_price = ''
                        )

                        if (order.total_amount != null && order.total_amount != '') {
                            order.display_total_amount = this.getTwoDecimals(order.total_amount)
                        } else (
                            order.display_total_amount = ''
                        )

                        if (order.override_amount != null && order.override_amount != '') {
                            order.display_override_amount = this.getTwoDecimals(order.override_amount)
                        } else (
                            order.display_override_amount = ''
                        )

                    })
                }
                this.$forceUpdate()
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == 'create'){
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
    },
    computed:{
        ...mapGetters([
            "USERACCOUNT_LOAD",
            "USER_ACCESS",
        ]),
    },
};
</script>
