<template>
    <v-container>
        <v-dialog v-model="component.dialog" width="30%" persistent>
            <v-card>
                <v-row class="m-0">
                    <v-col cols="11">
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`${display_name} Details`"></span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1" class="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewDialog(); $emit('close');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text class="textSize">
                    <span><b>Code: </b> {{ component.data.code }} <br/></span>
                    <span><b>Name: </b>{{ component.data.name }}<br/></span>
                    <span><b>Origin: </b>{{ component.data.origin_name }}<br/></span>
                    <span><b>Deduct From Sales: </b>
                        <span class="badge badge-primary" v-if="component.data.deduct_from_sales">Yes</span>
                        <span class="badge badge-danger" v-else>No</span>
                        <br/>
                    </span>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    props: [
        'id',
        'display_name',
    ],
    data(){
        return {
            component: {
                dialog: false,
                data: {},
                vuex: {
                    view: {
                        dispatch: 'creditMemoClassification',
                        action: 'show',
                        url: 'credit-memo-classification',
                    },
                },
            },
        }
    },
    methods:{
        closeViewDialog(){
            this.component.dialog = false
        }
    },
    watch:{
        id:{
            handler(val){
                if(val != -1){
                    if(val != null && val > 0){
                        let payload = {
                            id: val,
                            action: this.component.vuex.view.action,
                            url: this.component.vuex.view.url,
                        }
                        this.$store.dispatch(this.component.vuex.view.dispatch, payload).then(response => {
                            this.component.data = response.data
                            this.component.dialog = true
                        })
                    }
                }
            }
        }
    }
}
</script>

<style>
    .textSize{
        font-size: 1rem;
    }
</style>
