<template>
<div class="w-100 p-1">
    <v-app id="my_vue_app">

        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                <v-icon>mdi-magnify</v-icon>
            </v-card-title>

            <v-container fluid>
                <v-data-table
                    :headers="headers"
                    :items="itemCategories"
                    :search="search"
                    :items-per-page="5"
                    :loading="loaded"
                    class="w-100"
                >

                <template v-slot:[`item.action`]="{ item }">
                    <v-btn text icon color="blue" v-if="userAccess.includes('categories_edit')">
                        <v-icon class="btn-action" small @click="editItemCategory(item.id)">mdi-square-edit-outline</v-icon>
                    </v-btn>
                    <v-btn text icon color="red" v-if="userAccess.includes('categories_delete')">
                        <v-icon class="btn-action" small @click="deleteItemCategory(item.id)">mdi-delete</v-icon>
                    </v-btn>
                </template>

                </v-data-table>
            </v-container>
        </v-card>
    </v-app>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2'

export default {
    components: {
    },
    props: [
        'itemCategoriesActionResult',
        'userAccess'
    ],
    data () {
        return {
            actionResult : {
                model : 'ItemCategories',
                action : ''
            },
            loaded: false,
            itemCategories: [],
            itemsPerPageOptions: [5, 10 ,15],
            itemsPerPage: 10,
            search: '',
            headers: [
                { text: 'Name', align: 'left', value: 'name', },
                { text: 'Description', align: 'left', value: 'description', },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            json_data: [],
            json_meta: [
                [
                    {
                        'key': 'charset',
                        'value': 'utf-8'
                    }
                ]
            ],

        }
    },
    mounted() {
        this.getAllItemCategories()
    },
    computed:{
        ...mapGetters([
            'GET_ALL_ITEM_CATEGORIES'
        ])
    },
    methods:{
        getAllItemCategories(){
            this.loaded = true
            this.itemCategories = [];
            this.$store.dispatch('getAllItemCategories').then(response => {
                this.itemCategories = response.data
                this.loaded = false
            })
        },
        editItemCategory(id){
            this.$emit('showEditModal', id);
        },
        deleteItemCategory(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton:true,
                confirmButtonText:'Confirm',
                showCancelButton:true,
                showCloseButton:true,
                confirmButtonColor: '397373',
                cancelButtonColor:'grey',
                reverseButtons:true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('destroyItemCategories',id).then(response => {
                        this.getAllItemCategories()
                        if(response.data.count === 0){
                            swal.fire('Item Category cannot be deleted','This Category is used in items','error')
                        }else{
                            swal.fire('','Item Category Deleted Successfully','success')
                        }

                    }).catch(error => {
                        swal.fire('','Item Category Deleted Failed','error')
                    })
                }
            });
        },
    },
    watch: {
        itemCategoriesActionResult: function(val){
            if(this.itemCategoriesActionResult=='success'){
                this.getAllItemCategories()
            }
        }
    }
};
</script>
