<template>
    <div>
        <AddEditCustomerPaymentDetailComponentVue :addEdit="true" :origin="'editSoa'" :perPL="false"/>
        <v-app>
            <v-card class="">
                <v-layout row class="px-4 align-items-center mt-2" >
                    <v-flex lg3 class="mr-2 ma-2">
                        <v-autocomplete
                            outlined
                            dense
                            :items="GET_CUSTOMERS_LIST_SELECTION"
                            v-model="selectedCustomer"
                            item-value="value"
                            item-text="text"
                            label="Customer"
                        />
                    </v-flex>
                    <v-flex lg3>
                        <!-- <v-btn @click="filterList()">Filter</v-btn> -->
                        <v-btn text @click="clearFilter()">Clear</v-btn>
                    </v-flex>
                    <v-spacer />
                    <v-flex align-self-start>
                        <div style="text-align:end" class="mx-3">
                            <span class="badge bg-light mr-2" style="cursor:pointer" @click="filterByStatus(10)">all</span>
                            <span class="badge white--text bg-secondary mr-2" style="cursor:pointer" @click="filterByStatus(0)">saved</span>
                            <span class="badge white--text bg-danger mr-2" style="cursor:pointer" @click="filterByStatus(3)">rejected</span>
                            <span class="badge white--text bg-dark mr-2" style="cursor:pointer" @click="filterByStatus(5)">bounced</span>
                            <span class="badge white--text bg-danger mr-2" style="cursor:pointer" @click="filterByStatus(6)">cancelled</span>
                            <span class="badge white--text bg-primary mr-2" style="cursor:pointer" @click="filterByStatus(7)">confirmed</span>
                            <span class="badge bg-warning mr-2" style="cursor:pointer" @click="filterByStatus(8)">exhausted</span>
                            <span class="badge white--text bg-success mr-2" style="cursor:pointer" @click="filterByStatus(9)">allocated</span>
                        </div>
                    </v-flex>
                </v-layout>
                <v-card-title>
                    <v-layout row class="mx-3 my-2">
                        <v-spacer />
                        <v-flex lg2>
                            <div style="text-align:end">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Ref#, SoaId"
                                    single-line
                                    hide-details
                                    clearable
                                    @keydown.enter="searchCustomerPayment()"
                                    @click:clear="clearFilter()"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="customerPayments"
                        :search="search"
                        :server-items-length="totalCustomerPayments"
                        :options.sync="options"
                        :items-per-page="20"
                        :loading="loading"
                        :footer-props="{
                            showFirstLastPage: true,
                        }"
                    >
                        <template v-slot:[`item.gross_amount`]="{ item }">
                            <td class="px-0">&#x20b1;{{ item.gross_amount | currency }}</td>
                        </template>

                        <template v-slot:[`item.wht_amount`]="{ item }">
                            <td class="px-0">&#x20b1;{{ item.wht_amount | currency }}</td>
                        </template>

                        <template v-slot:[`item.other_adj_amount`]="{ item }">
                            <td class="px-0">&#x20b1;{{ item.other_adj_amount | currency }}</td>
                        </template>

                        <template v-slot:[`item.net_amount`]="{ item }">
                            <td class="px-0">&#x20b1;{{ item.net_amount | currency }}</td>
                        </template>

                        <template v-slot:[`item.total_amount`]="{ item }">
                            <td class="px-0">&#x20b1;{{ item.total_amount | currency }}</td>
                        </template>

                        <template v-slot:[`item.remaining_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.remaining_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.allocated_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.allocated_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.payment_type_name`]="{ item }">
                            <span class="badge white--text bg-success" v-if="item.payment_type_name=='Cash'">{{ item.payment_type_name }}</span>
                            <span class="badge white--text  bg-bluish-gray " v-else-if="item.payment_type_name=='Cheque'">{{ item.payment_type_name }}</span>
                            <span class="badge bg-warning" v-else-if="item.payment_type_name=='Deposit'">{{ item.payment_type_name }}</span>
                            <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Online'">{{ item.payment_type_name }}</span>
                            <span class="badge white--text  bg-info " v-else-if="item.payment_type_name=='Gcash'">{{ item.payment_type_name }}</span>
                            <span class="badge bg-primary" v-else-if="item.payment_type_name=='Credit/Debit Card'">{{ item.payment_type_name }}</span>
                            <span class="badge bg-dark text-white" v-else-if="item.payment_type_name=='Journal Voucher'">{{ item.payment_type_name }}</span>
                        </template>

                        <template v-slot:[`item.expected_deposit_date`]="{ item }">
                            <span v-if="item.payment_type_name=='Cheque'" class="text-nowrap">
                                    {{ item.expected_deposit_date }}
                            </span>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <span class="badge white--text bg-secondary" v-if="item.status==0">saved</span>
                            <span class="badge white--text bg-info" v-else-if="item.status==1">submitted</span>
                            <span class="badge white--text bg-purple" v-else-if="item.status==2">approved</span>
                            <span class="badge white--text bg-danger" v-if="item.status==3">rejected</span>
                            <span class="badge white--text bg-dark" v-else-if="item.status==4">returned</span>
                            <span class="badge white--text bg-dark" v-else-if="item.status==5">bounced</span>
                            <span class="badge white--text bg-danger" v-else-if="item.status==6">cancelled</span>
                            <span class="badge white--text bg-primary" v-else-if="item.status==7">confirmed</span>
                            <span class="badge bg-warning" v-else-if="item.status==8">exhausted</span>
                            <span class="badge white--text bg-success" v-else-if="item.status==9">allocated</span>
                        </template>

                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewCustomerPayment(item.id)" v-if="userAccess.view">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn  text icon color="blue">
                                <v-icon class="btn-action" small @click="editCustomerPayment(item.id)" v-if="userAccess.edit">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn v-if="item.status == 0 || item.status == 1" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteCustomerPayment(item.id)" v-if="userAccess.delete">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-app>
        <ViewCustomerPaymentDetailComponentVue :view="true" :isUnidentified="isUnidentified" :user_access="userAccess"/>
    </div>
</template>

<script>

import AddEditCustomerPaymentDetailComponentVue from '../PaymentsComponent/AddEditCustomerPaymentDetailComponent.vue';
import ViewCustomerPaymentDetailComponentVue from '../PaymentsComponent/ViewCustomerPaymentDetailComponent.vue';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data () {
        return {
            customerPayments: [],
            search: '',
            headers: [
                { text: 'Reference#', value: 'reference_number' },
                { text: 'Customer Name', value: 'customer_name' },
                { text: 'Payment Type', value: 'payment_type_name' },
                { text: 'Bank', value: 'bank_name' },
                { text: 'Gross Amount', value: 'gross_amount' },
                { text: 'WHT Amount', value: 'wht_amount' },
                { text: 'ADJ Amount', value: 'other_adj_amount' },
                { text: 'Net Amount', value: 'net_amount' },
                { text: 'Remaining Amount', value: 'remaining_amount' },
                { text: 'Allocated WHT', value: 'allocated_wht' },
                { text: 'Status', value: 'status' },
                { text: 'Date', value: 'payment_date' },
                { text: 'Deposit Date', value: 'expected_deposit_date' },
                { text: 'Created By', value: 'created_by' },
                { text: 'Actions', value: 'action' },
            ],
            loading: true,
            page: 1,
            itemsPerPage: 20,
            options: {},
            totalCustomerPayments: 0,
            status: 10,
            customerList: [],
            selectedCustomer: '',
            userAccess: {
                create  : false,
                edit    : false,
                view    : false,
                delete  : false,
                cancel_payment: false,
                confirm_payment: false,
                reject_payment: false,
                edit_payment: false,
            },
            loadview:{
                customer_selection: true
            }
        }
    },
    components: {
        AddEditCustomerPaymentDetailComponentVue,
        ViewCustomerPaymentDetailComponentVue
    },
    props: ['isUnidentified'],
    async mounted() {
        await this.getCustomerPaymentDetails();
        // await this.getAllCustomer();
        // this.checkAccess();
    },
    watch: {
        customerPayments() {
            if(this.customerPayments.length > 0) {
                this.loading = false;
            } else {
                this.loading = false;
            }
            this.$store.commit('clearPaymentsMenu');
        },
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.cancel_payment = false;
                this.confirm_payment = false;
                this.reject_payment = false;
                this.edit_payment = false;

                if(val != "fail"){
                    val.forEach((e) => {
                        if (e.actions_code == "create"){
                            this.userAccess.create = true;
                        }
                        else if (e.actions_code == "edit"){
                            this.userAccess.edit = true;
                        }
                        else if (e.actions_code == "view"){
                            this.userAccess.view = true;
                        }
                        else if (e.actions_code == "delete"){
                            this.userAccess.delete = true;
                        }
                    });
                }
            }
        },
        successAddEdit() {
            if(!!this.successAddEdit) {
                this.getCustomerPaymentDetails();
            }
        },
        options(data) {
            this.loading = true;
            this.page = data.page;
            this.getCustomerPaymentDetails();
        },
        menu() {
            if(this.menu == 'details') {
                this.loading = true;
                this.status = 10;
                this.customerPayments = [];
                this.isUnidentified = false
                this.getCustomerPaymentDetails();
            }
        },
        selectedCustomer() {
            if(!!this.selectedCustomer) {
                this.getCustomerPaymentDetails();
            } else {
                this.selectedCustomer = '';
            }
        },
        'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loadview.customer_selection = false
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMER_PAYMENT_DETAILS',
            'GET_CUSTOMERS_LIST_SELECTION',
            'USER_ACCESS'
        ]),
        successAddEdit() {
            return this.$store.state.accounting.customerPaymentDetail.successAddEdit;
        },
        menu() {
           return this.$store.state.accounting.payments.menu;
        }
    },
    methods:{
        searchCustomerPayment() {
            this.selectedCustomer = '';
            this.status = '';
            this.getCustomerPaymentDetails();
        },
        clearFilter() {
            this.selectedCustomer = '';
            this.status = 10;
            this.search = '';
            this.getCustomerPaymentDetails();
        },
        // async getAllCustomer(){
        //     await this.$store.dispatch('getAllCustomers').then((response)=>{ this.customerList= this.GET_CUSTOMERS_LIST_SELECTION })
        // },
        async getCustomerPaymentDetails() {
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                status: this.status,
                customerId: this.selectedCustomer,
                search: this.search,
                isUnidentified:this.isUnidentified
            }
            await this.$store.dispatch('getCustomerPaymentDetails',payload).then(response=>{
                this.customerPayments = this.GET_CUSTOMER_PAYMENT_DETAILS.data;
                this.totalCustomerPayments = this.GET_CUSTOMER_PAYMENT_DETAILS.total;
            });
        },
        filterByStatus(status) {
            this.status = status;
            this.search = '';
            this.customerPayments = [];
            this.loading = true;
            this.getCustomerPaymentDetails();
        },
        viewCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailShow');
        },
        editCustomerPayment(id) {
            this.$store.commit('custPaymentDetailSetId', id);
            this.$store.commit('custPaymentDetailEditDialogAction');
            this.$store.commit('custPaymentDetailShowAddEditDialog');
        },
        deleteCustomerPayment(id) {
            swal.fire({
                title: 'Are you sure you want to Update Customer Payment?',
                text: 'Make sure that all information are correct',
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then(result=>{
                if(result.isConfirmed) {
                    this.$store.dispatch('deleteCustomerPaymentDetail',id).then(response=>{
                        if(!!response.data.error) {
                            swal.fire('', response.data.error, 'error');
                        } else {
                            swal.fire('', response.data.msg, 'success');
                            this.getCustomerPaymentDetails();
                        }
                    });
                }
            });
        }
    }
};
</script>
