<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="packing_list_items_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Delivery Receipt Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="clearPackingListItems(); $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-8 text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Delivery Receipt No: {{ packing_list.packing_list_num }}</div>
                                        <!-- <div class="col-sm-12 col-md-4 p-0">Issuer: {{ packing_list.issuer_name }}</div> -->
                                        <div class="col-sm-12 col-md-4 p-0">Issuer: {{ packing_list.prepared_by }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <!-- <div class="col-sm-12 col-md-8 p-0">Checker Form No: {{ packing_list.checker_form_id }}</div> -->
                                        <div class="col-sm-12 col-md-8 p-0"></div>
                                        <div class="col-sm-12 col-md-4 p-0">Date Issued: {{ packing_list.created_at | formatDateTime }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Customer: {{ packing_list.customer_name }}</div>
                                    </div>
                                    <!-- <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Checked By: {{ packing_list.checked_by }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Prepared By: {{ packing_list.prepared_by }}</div>
                                        <div class="col-sm-12 col-md-8 p-0">Noted By: {{ packing_list.noted_by }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">Prepared By: {{ packing_list.prepared_by_2 }}</div>
                                    </div> -->
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0 mr-2">Remarks: {{ packing_list.remarks }}</div>
                                        <div class="col-sm-12 col-md-4 p-0">DR Date: {{ packing_list.packing_list_date | formatDate }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-4 p-0">PO#: {{ packing_list.customer_po_num }}</div>
                                        <div class="col-sm-12 col-md-4 p-0" v-if="packing_list.convert_date != null">Convert Date: {{ packing_list.convert_date | formatDateTime }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-4 p-0"><!-- No. of Boxes: {{ num_of_box }} --></div>
                                        <div class="col-sm-12 col-md-4 p-0" v-if="packing_list.convert_date != null">Convert Date: {{ packing_list.convert_date | formatDateTime }}</div>
                                    </div>
                                    <div class="row ml-2 mt-2" v-if="itemTypeDiscounts.length > 0  && (packing_list.promo_id == '' || packing_list.promo_id == null) && (packing_list.special != 1 || (packing_list.special==1 && item_type != 'PT'))">
                                        <div class="col-sm-12 col-md-8">
                                            <div class="row p-0">
                                                <div class="col-sm-12 col-md-6 py-0" v-for="(disc, index ) in itemTypeDiscounts" :key="index">
                                                    <span>{{ disc }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 p-0"></div>
                                    </div>
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="packingListItemsHeaders"
                        :items="packingListItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                        v-if="is_stockcard=='0'"
                        :expanded.sync="expanded_details.expanded"

                        item-key="id"
                    >
                        <template v-slot:[`item.foc`]="{ item }">
                            <v-tooltip :disabled="!item.foc" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <td
                                        class="side-borders-white px-0"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <span v-if="item.foc=='1'">Yes</span>
                                        <span v-else-if="item.foc=='0'">No</span>
                                    </td>
                                </template>
                                <span>{{ item.foc_remarks }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.display_disc_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_disc_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_srp`]="{ item }">
                             <span class="text-nowrap">
                                &#x20b1; {{item.display_srp | currency}}
                            </span>
                        </template>

                        <template v-slot:[`item.display_final_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_final_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_total_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_total_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.served_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.served_amount | currency }}
                            </span>
                        </template>

                        <!-- <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                            <td v-if="item.original_item_id != 0" class="text-start">
                            <v-btn text icon x-small color="black"
                                    @click="expand(!isExpanded)"
                                    class="v-data-table__expand-icon"
                                    :class="{'v-data-table__expand-icon--active' : isExpanded}">
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                            </td>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">

                            <td :colspan="headers.length" class="p-0">
                                <v-data-table
                                    :headers="expanded_details.headers"
                                    :items="[item.original_item]"
                                    dark
                                    dense
                                    hide-default-footer
                                    fixed-header>

                                    <template v-slot:[`item.selling_price`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.selling_price | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.discounted_price`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1; {{ item.discounted_price | currency}}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.total_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.total_amount | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.foc`]="{ item }">
                                        <span class="text-nowrap">
                                        <span v-if="item.foc == '1'">Yes</span>
                                        <span v-else-if="item.foc == '0'">No</span>
                                        </span>
                                    </template>

                                    <template v-slot:[`item.display_final_price`]="{ item }">
                                        <span class="text-nowrap">
                                        &#x20b1;{{ item.discounted_price | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.display_total_amount`]="{ item }">
                                        <span class="text-nowrap">
                                        &#x20b1;{{ (item.discounted_price * item.quantity ) | currency }}
                                        </span>
                                    </template>

                                </v-data-table>
                            </td>
                        </template> -->
                    </v-data-table>

                    <v-data-table
                        :headers="packingListItemsHeaders_SC"
                        :items="packingListItems"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                        v-else
                    >
                    </v-data-table >

                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-8 text-left">
                                    <div class="card-body py-4 d-flex" v-if="packing_list.approve_pl == 0 || (packing_list.approve_pl == 1 && packing_list.approved_date != null)">
                                        <span class="m-1"><v-btn @click="convertToStockCard();" v-if="is_stockcard == 1 && pending_stock_card" :disabled="!userAccess.create || (packing_list.status != 5 && packing_list.status != 1)">CONVERT TO SC</v-btn></span>
                                        <span class="m-1"><v-btn @click="print_no_price = false; checkPrintOverride();" v-if="(!is_preparation && !unconfirmed) && is_stockcard == 0" :disabled="disablePLPrintButtons('with_price')">Print</v-btn></span>
                                        <span class="m-1"><v-btn @click="print_no_price = true; checkPrintOverride()" v-if="!unconfirmed && is_stockcard == '0'" :disabled="disablePLPrintButtons('without_price')">Print No Price</v-btn></span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4 pull-right text-right text-dark" v-if="is_stockcard==0">
                                    Total Amount: &#x20b1;{{ packing_list.total_amount | currency }}
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <span class="m-1" v-if="(!unconfirmed && ![1,3,5,6].includes(packing_list.status)) && is_stockcard == '0'"><v-btn @click="updatePackingListStatus(6)">Submit</v-btn></span>
                            <span class="m-1" v-if="(userAccess.confirm && packing_list.status == 5 && packing_list.status != 3 && packing_list.printed == 1) && is_stockcard == 0"><v-btn @click="updatePackingListStatus(1)">Confirm</v-btn></span>
                            <span class="m-1" v-if="userAccess.approve_pl && [6].includes(packing_list.status)"><v-btn @click="updatePackingListStatus(5)">Approve</v-btn></span>
                        </div>
                    </div>

                </v-card-text>

                <v-container fluid style="display:none;">  <!-- PRINT FOR DELIVERY -->
                    <div id="div_logo" >
                        <div style="font-size: 12px; line-height: 1.9">
                            <p>
                                <span style="font-size: 18px; font-weight: bold;">
                                    D GREAT EAST INDUSTRIAL SUPPLY CORPORATION
                                </span>
                                <br>420 Del Monte Ave, Brgy. Siena, Quezon City
                                <br>Cell No: +63 999 889 5739
                                <br>Tel. No.: +63 2 8711-6742 / +63 2 7717-4059
                                <br>
                            </p>
                        </div>
                    </div>
                    <div id="div_packing_list_num" style="line-height: 1.7">
                        <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
                        <p>
                            <br><b><span style="font-size: 18px">DR# {{ packing_list.packing_list_num }}</span></b>
                            <br><b><span style="font-size: 12px">SO# {{ packing_list.order_num }}</span></b>
                        </p>
                    </div>

                    <div>
                        <table id="customer_and_rfItem_details">
                            <tbody>
                            <tr>
                                <td>Customer:</td>
                                <td>{{ packing_list.customer_name | uppercase }} </td>
                                <td>Date:</td>
                                <td>{{ packing_list.packing_list_date | formatDate }} </td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{{ packing_list.shipping_address_text | uppercase }}</td>
                                <td>Terms:</td>
                                <td v-if="packing_list.promo_id != '' && packing_list.terms == 0">Cash</td>
                                <td v-else>{{ packing_list.terms }} days</td>
                            </tr>
                            <tr>
                                <td>Remarks:</td>
                                <td>{{ packing_list.remarks }}</td>
                                <td>PO:</td>
                                <td>{{ packing_list.customer_po_num }}</td>
                            </tr>
                            <tr v-if="rfHasPackingList">
                                <td>Unit:</td>
                                <td>{{ itemDetails?.item_model }} / {{ itemDetails?.item_name }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table id="items_details">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                    <th>UNIT PRICE</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in packingListItems" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item.item_uom_text }}</td>
                                    <td>{{ item.item.model }}</td>
                                    <td>{{ item.item.name }}</td>
                                    <td v-if="item.total_amount>0">{{ item.display_srp | currency }}</td><td v-else>FOC</td>
                                    <td v-if="item.total_amount>0">{{ item.quantity * item.display_srp | currency }}</td><td v-else>FOC</td>
                                </tr>
                                <tr>
                                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-for="(items, i) in print.packingListItems" :key="i">
                        <table :id="'items_details' + i">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                    <th>UNIT PRICE</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in items" :key="index">
                                    <td>{{ item.index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item.item_uom_text }}</td>
                                    <td>{{ item.item.model }}</td>
                                    <td>{{ item.item.name }}</td>
                                    <td v-if="item.total_amount>0">{{ item.display_srp | currency }}</td><td v-else>FOC</td>
                                    <td v-if="item.total_amount>0">{{ item.quantity * item.display_srp | currency }}</td><td v-else>FOC</td>
                                </tr>
                                <tr v-if="print.packingListItems.length == (i + 1)">
                                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-for="(items, i) in print.packingListItems" :key="i">
                        <table :id="'items_details_without_price' + i">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in items" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item.item_uom_text }}</td>
                                    <td>{{ item.item.model }}</td>
                                    <td>{{ item.item.name }}</td>
                                </tr>
                                <tr v-if="print.packingListItems.length == (i + 1)">
                                    <td colspan="5">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="substitution_items">
                            <tbody>
                                <tr>
                                    <!-- <td> <span v-for="(subs,i) in salesQuotationSubstitutionItems" :key="i">
                                        {{ '*'+subs.original_item.model+' was replaced with '+subs.item_by_name+'*' }}
                                        </span>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-for="(items, i) in print.packingListItems" :key="i">
                        <table :id="'total_details'+i">
                            <tbody>
                                <tr>
                                    <td>
                                        <p>GROSS SALES:</p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.gross_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Less - Discount:</p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.discount_amount | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>TOTAL SALES:</p>
                                    </td>
                                    <td>
                                        <p>{{ packing_list.display_total_amount | currency }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="num_of_items">
                            <tbody>
                                <tr v-if="!packing_list.promo_id && !print_no_price">
                                    <td>{{ itemTypeDiscounts[0] }}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><span>Note: Invoice to follow</span></td>
                                    <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span><!-- No. of Boxes: {{ this.num_of_box }} --></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="div_received_note"  style="font-size: 10px; line-height: 1.0">
                        <p style="font-size: 12px;">Received the above goods and<br>services in good order & condition</p>
                        <p style="font-size: 10px;"></p>
                        <p style="font-size: 22px;">_____________</p>
                        <p style="font-size: 10px;"><i>Signature over printed name / date</i></p>
                    </div>
                    <div>
                        <table id="footer">
                            <tbody>
                                <tr>
                                    <td>Issued By</td>
                                    <td colspan="2">Noted by</td>
                                </tr>
                                <tr>
                                    <td><br><br>{{ this.packing_list.prepared_by }}</td>
                                    <td><br><br></td>
                                    <td><br><br></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </v-container>

                <stock-card-print-component v-if="stock_card_print" :stock_card="stock_card" :stockCardItems="stockCardItems"></stock-card-print-component>
                <v-card-actions v-if="fromSoa" class="my-3">
                    <v-row>
                        <v-col cols="12" class="float-right">
                            <AddEditCustomerPaymentDetailComponent :customerId="275" :fromSoa="true" :addEdit="true" :balanceAmounts="packing_list.total_amount" :soa_id="null" :hasRemaining="false" :perPL="true" :soa_payload="packing_list" @closeDialog="clearPackingListItems(); $emit('closeView','successPayment');"></AddEditCustomerPaymentDetailComponent>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="override_print_dialog" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Confirm Override Print</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="override_print_dialog = false; override_username = ''; override_user_password = '';">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override_user_password" autocomplete="new-password">
                            <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn @click="confirmedOverridePrint()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="print_no_price_dialog" persistent max-width="30%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Confirm Override Print No Price</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="print_no_price_dialog = false; override_username = ''; override_user_password = '';">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <label for="override_user_password" class="float-left control-label col-form-label">Password</label>
                            <input type="password" class="form-control" id="override_user_password" placeholder="Password" v-model="override_user_password" autocomplete="new-password">
                            <span v-for="errors in errors.override_user_password" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="card-body py-4 d-flex justify-content-center">
                            <v-btn @click="confirmedOverridePrintNoPrice()">Confirm</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ConfirmOverrideComponent
            :approve_override_dialog="override.override_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="''"
            @closeConfirmOverride="closeConfirmOverride"
            :fromPL="true"
        ></ConfirmOverrideComponent>
    </v-app>
</div>
</template>
<script>
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue'
    import SalesFunctionsComponentVue from '@/views/main/Shared/SalesFunctionsComponent.vue'
    import jsPDF from 'jspdf';
    import swal from 'sweetalert2';
    import AddEditCustomerPaymentDetailComponent from '../../Accounting/Payments/PaymentsComponent/AddEditCustomerPaymentDetailComponent.vue';
    import ConfirmOverrideComponent from '@/views/main/Utils/ConfirmOverrideComponent.vue';
    import { mapGetters } from 'vuex'

    export default {
        mixins: [SharedFunctionsComponentVue, SalesFunctionsComponentVue],
        components: {
            // StockCardPrintComponent: () => import('../StockCards/StockCardPrintComponent.vue')
            AddEditCustomerPaymentDetailComponent,
            ConfirmOverrideComponent
        },
        props: [
            'viewPackingListId',
            'is_preparation',
            'unconfirmed',
            'is_stockcard',
            'service',
            'fromSoa',
        ],
        async mounted(){
            // await this.checkAccess('AC', 'unconfirmed_pl', 'confirm')
            // await this.checkAccess('AC', 'unconfirmed_pl', 'print_confirmed')
            // await this.checkAccess('OP', 'stock_card', 'create')
            if(this.is_preparation){
                this.packingListItemsHeaders = this.packingListPreparationItemsHeaders
            }
            this.checkAccess();
        },
        data() {
            return {
                packingListItems: [],
                packingListItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                    { text: 'FOC', align: 'left', value: 'foc', sortable: false},
                    { text: 'SRP', align: 'left', value: 'display_srp', sortable: false},
                    { text: 'Disc Price', align: 'left', value: 'display_disc_price', sortable: false},
                    { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                    { text: 'Price', align: 'right', value: 'display_final_price', width: '15%', sortable: false},
                    { text: 'Total', align: 'right', value: 'display_total_amount', width: '15%', sortable: false},
                ],
                packingListPreparationItemsHeaders: [
                    { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                    { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                ],
                packingListItemsHeaders_SC: [
                    { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                    { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                    { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                    { text: 'Qty Served', align: 'left', value: 'quantity_served', sortable: false},
                    { text: 'Qty Canceled', align: 'left', value: 'quantity_canceled', sortable: false},
                    { text: 'Qty Remaining', align: 'left', value: 'stock_card_qty_remaining', sortable: false},
                    { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                ],

                loaded: true,
                packing_list_items_dialog: false,
                packing_list: '',
                discounts: [],
                itemTypeDiscounts: [],
                itemTypeDiscountsPDF: [],
                type_list: [],
                errors: [],
                print_no_price: false,
                userAccess: {
                    confirm: false,
                    print_confirmed: false,
                    stockcard_view: false,
                    create:false,
                    approve_pl:false,
                    re_print:false,
                },
                printed: 0,
                override_print_dialog: false,
                override_username: '',
                override_user_password: '',
                print_no_price_dialog: false,
                item_type: '',

                stock_card_id: '',
                pending_stock_card: false,
                viewStockCardId: '',
                stock_card: '',
                stockCardItems: [],
                stock_card_print: false,
                renderOnFlight: false,
                num_of_box:0,
                override: {
                    override_dialog: false,
                    errors: [],
                    override_departments_allowed: ['AC'],
                    override_authenticated: false,
                },
                override_confirm: false,
                is_accounting_user:null,
                salesQuotationItems:[],
                salesQuotationSubstitutionItems:[],
                expanded_details:{
                    headers:[
                    {
                        text: "(Old) Model",
                        align: "left",
                        value: "model",
                        width: "15%",
                        sortable: false,
                    },
                    {
                        text: "(Old) Name",
                        align: "left",
                        value: "name",
                        width: "35%",
                        sortable: false,
                    },
                    {
                        text: "(Old) Type",
                        align: "left",
                        value: "item_type_text",
                        sortable: false,
                    },
                    { text: "(Old) FOC", align: "left", value: "foc", sortable: false },
                    { text: "(Old) SRP", align: "left", value: "selling_price", sortable: false },
                    {
                        text: "(Old) Disc Price",
                        align: "left",
                        value: "discounted_price",
                        sortable: false,
                    },
                    { text: "(Old) Qty", align: "left", value: "quantity", sortable: false },
                    {
                        text: "(Old) UOM",
                        align: "left",
                        value: "item_uom_text",
                        sortable: false,
                    },
                    {
                        text: "(Old) Price",
                        align: "right",
                        value: "display_final_price",
                        width: "15%",
                        sortable: false,
                    },
                    {
                        text: "(Old) Total",
                        align: "right",
                        value: "display_total_amount",
                        width: "15%",
                        sortable: false,
                    },
                    ],
                    items:[],
                    loading:false,
                    expanded: [],
                    customer_discount:[],
                },
                itemDetails: null,
                rfHasPackingList: false,
                print: {
                    packingListItems: []
                },
            }
        },
        methods: {
            printStockCard(){
                this.viewStockCardId = this.stock_card_id
            },
            checkCustomerPrintNoPrice(){
                if(this.packing_list.customer_print_no_price == 0 || this.packing_list.customer_print_no_price == null){
                    this.print_no_price_dialog = true
                }else{
                    this.print_no_price = true;
                    this.renderOnFlight = true;
                    setTimeout(()=>{
                        this.renderPDF('print');
                    }, 2000);
                }
            },
            checkAccess(){
                this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
                    let val = response;
                    this.userAccess.confirm = false;
                    this.userAccess.print_confirmed = false;
                    this.userAccess.stockcard_view = false;
                    this.userAccess.create = false;
                    this.userAccess.approve_pl = false;
                    this.userAccess.re_print = false;
                    if(val != "fail"){
                        val.forEach((e) => {
                            if (e.actions_code == "confirm"){
                                this.userAccess.confirm = true;
                            }
                            if (e.actions_code == "print_confirmed"){
                                this.userAccess.print_confirmed = true;
                            }
                            if(e.actions_code == "stockcard_view"){
                                this.userAccess.stockcard_view = true;
                            }
                            if(e.actions_code == "create"){
                                this.userAccess.create = true;
                            }
                            if(e.actions_code == "approve_pl"){
                                this.userAccess.approve_pl = true;
                            }
                            if(e.actions_code == "re_print"){
                                this.userAccess.re_print = true;
                            }
                        });
                    }
                })
            },
            disablePLPrintButtons(with_or_without_price){
                if (this.packing_list.status == 1) {
                    if (this.userAccess.print_confirmed) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (this.packing_list.status == 5) {
                    if (with_or_without_price == 'with_price') {
                        if (this.userAccess.print_confirmed) {
                            return false;
                        } else {
                            return true;
                        }
                    } else if (with_or_without_price == 'without_price') {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },
            updatePackingListStatus(newStatus){
                this.errors = []
                swal.fire({
                    title: "",
                    text: "Are you sure you want to update Delivery Receipt?",
                    icon: "warning",
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            status: newStatus,
                            url:    'updatePackingListStatus/'+this.viewPackingListId
                        }

                        this.$store.dispatch('postPL',payload).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.packing_list_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                        });
                    }
                });
            },
            printNow(pdfAction,headerAndFooter) {
                var doc = new jsPDF('p', 'mm', 'letter');
                var margin_left = 7;
                var margin_right = 9;

                this.print.packingListItems.forEach((e,i)=>{
                    doc.setFont('courier', 'bold').setFontSize(13).text(8,13,`D GREAT EAST INDUSTRIAL SUPPLY CORPORATION\n`)
                    doc.setFont('courier', 'italic').setFontSize(9).text(8,18,`420 Del Monte Ave, Brgy. Siena, Quezon City\nCell No.: +63 999 889 5739 \nTel. No.: +63 2 8711-6742 / +63 2 7717-4059`)
                    doc.setFont('courier', 'bold').setFontSize(13).text(162, 15, "DR# "+this.packing_list.packing_list_num)
                    doc.setFont('courier', 'normal').setFontSize(11).text(162, 20,  "SO# "+this.packing_list.order_num)

                    let customer_details_top = this.service == 1 || this.service == '1' ? 43 : 30

                    doc.setFont('courier', 'normal').setFontSize(9).text(126, 245, "Received the above goods and")
                    doc.setFont('courier', 'normal').setFontSize(9).text(126, 250, "services in good order & condition")
                    doc.setFont('courier', 'normal').setFontSize(9).text(126, 257, "_______________________________")
                    doc.setFont('courier', 'italic').setFontSize(8).text(126, 264, "Signature over printed name / date")

                    doc.autoTable({
                        html: '#customer_and_rfItem_details',
                        theme: 'plain',
                        styles:{
                            font: 'courier',
                            cellPadding: 0,
                        },
                        margin: {top: customer_details_top, left: margin_left, right: margin_right},
                        columnStyles: {
                            0: {cellWidth: 23},
                            1: {cellWidth: 130},
                            2: {cellWidth: 16},
                            3: {cellWidth: 25},
                        }
                    });

                    if(this.print_no_price == false){
                        doc.autoTable({
                            html: `#items_details${i}`,
                            startY: doc.autoTable.previous.finalY + 3,
                            margin: {left: margin_left, right: margin_right},
                            theme: 'grid',
                            headStyles: {
                                halign:'center',
                                fillColor: '#ffffff',
                                textColor: '#000000',
                                lineWidth: .2,
                                lineColor: '#000000',
                                fontSize: 9,
                                font: 'courier',
                                fontStyle: 'bold'
                            },
                            columnStyles: {
                                0: {cellWidth: 7, halign:'center', valign: 'middle',  textColor: '#000000', fontSize: 8},
                                1: {cellWidth: 12, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                                2: {cellWidth: 14, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                                3: {cellWidth: 31, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                                4: {cellWidth: 82, halign:'left', valign: 'middle', textColor: '#000000', fontSize: 8},
                                5: {cellWidth: 23, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                                6: {cellWidth: 25, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8}
                            },
                            bodyStyles: {
                                lineColor: '#000000',
                                font: 'courier',
                                cellPadding: 1.60,

                            }
                        });
                    }else{
                        doc.autoTable({
                            html: `#items_details_without_price${i}`,
                            startY: doc.autoTable.previous.finalY + 3,
                            margin: {left: margin_left, right: margin_right},
                            theme: 'grid',
                            headStyles: {
                                halign:'center',
                                fillColor: '#ffffff',
                                textColor: '#000000',
                                lineWidth: .2,
                                lineColor: '#000000',
                                fontSize: 9,
                                font: 'courier',
                                fontStyle: 'bold',
                                cellPadding: 0
                            },

                            columnStyles: {
                                0: {cellWidth: 11, halign:'center', textColor: '#000000', fontSize: 8},
                                1: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                                2: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                                3: {cellWidth: 27, halign:'center', textColor: '#000000', fontSize: 8},
                                4: {cellWidth: 118, halign:'left', textColor: '#000000', fontSize: 8},
                            },
                            bodyStyles: {
                                lineColor: '#000000',
                                font: 'courier',
                            }
                        });
                    }
                    let lastY = doc.lastAutoTable.finalY;
                    if(this.print_no_price == false){
                        doc.autoTable({
                            html: `#total_details${i}`,
                            theme: 'grid',
                            startY: 211,
                            styles:{
                                lineWidth: .2,
                                lineColor: '#000000',
                                font: 'courier'
                            },
                            margin: {top: 37, left: 107, right: margin_right},
                            columnStyles: {
                                // 0: { cellWidth: 100, textColor: "#000000" },
                                0: { cellWidth: 48, halign: "left", textColor: "#000000" },
                                1: { cellWidth: 48, halign: "right", textColor: "#000000" },
                                // 2: { cellWidth: 38, halign: "right", textColor: "#000000" },
                            }
                        });
                    }
                    doc.autoTable({
                        html: "#substitution_items",
                        theme: "plain",
                        startY: 195,
                        margin: { top: 37, left: 6, right: 6 },
                        styles: {
                            font: 'courier'
                        },
                        columnStyles: {
                        0: { cellWidth: 110, textColor: "#000000", fontSize:8, },
                        },
                        didParseCell: function (data) {
                            var rows = data.table.body;
                            data.cell.styles.setFont = ['Helvetica', 'italic'];
                        }
                    });

                    doc.autoTable({
                        html: '#num_of_items',
                        theme: 'plain',
                        tableWidth: 100,
                        startY: 223,
                        styles:{
                            font: 'courier',
                            fontSize: 7,
                        },
                        margin: {top: 37, left: margin_left, right: margin_right},
                        columnStyles: {
                            0: {cellWidth: 42, textColor: '#000000', cellPadding: 2},
                            1: {cellWidth: 36, textColor: '#000000'},
                            2: {cellWidth: 42, textColor: '#000000'},
                        },
                        didParseCell: function (data) {
                            // Set a fixed row height
                            let fixedRowHeight = 7; // Adjust this value as needed
                            data.cell.styles.cellPadding = { top: (fixedRowHeight - data.cell.styles.fontSize) / 2, bottom: (fixedRowHeight - data.cell.styles.fontSize) / 2 };
                        }
                    });

                    // var footer_y = doc.autoTable.previous.finalY + 1
                    // if(this.print_no_price == true){
                    //     footer_y = 218
                    // }
                    // let lastY = doc.lastAutoTable.finalY;
                    // let item = this.packingListItems.map(e => e.total_amount)
                    // let total_per_page = item.reduce((count, amount) => parseFloat(count) + parseFloat(amount), 0)
                    // total_per_page = parseFloat(total_per_page).toFixed(2)
                    // doc.setFont('courier', 'normal').setFontSize(10).text(126, lastY - 7, `TOTAL SALES PER PAGE: PHP ${this.thousandSeprator(total_per_page)}`)

                    doc.autoTable({
                        html: '#footer',
                        theme: 'grid',
                        tableWidth: 100,
                        startY: 245,
                        styles: {
                            font: 'courier',
                            fontSize: 8
                        },
                        margin: {left: margin_left, right: margin_right},
                        columnStyles: {
                            0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                            1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                            2: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                        }
                    });

                    let finalY = doc.lastAutoTable.finalY; // The y position on the page
                    doc.setTextColor('#000000');
                    doc.setFont('courier', 'bold').setFontSize(7).text(189, finalY + 7, `Page ${i + 1} of ${this.print.packingListItems.length}`)

                    let total_print_count = this.packing_list.print_with_price_counter + this.packing_list.print_without_price_counter
                    if (this.packing_list.printed == 1 && total_print_count > 0) {
                        doc.setFontSize(6);
                        doc.text(197, finalY, "R-" + total_print_count)
                    }
                    if(this.print.packingListItems.length != (i + 1) ){
                        doc.addPage(1)
                    }
                })

                if(pdfAction == 'download'){
                    // doc.save('PackingList ' + this.packing_list.packing_list_num + '.pdf');
                    doc.save('DR#' + this.packing_list.packing_list_num + ' - ' + this.packing_list.customer_name + '.pdf');
                    this.renderOnFlight = false;
                }else if(pdfAction == 'print'){
                    doc.autoPrint();

                    if (this.packing_list.terms == 0) {
                        swal.fire({
                            title: "",
                            text: "DR terms is CASH. Please make sure payment is made before releasing the items.",
                            icon: "warning",
                            showConfirmButton:true,
                            showCancelButton  :  true,
                            reverseButtons:true,
                            allowOutsideClick :  false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor : 'grey',
                            confirmButtonText:'Confirm',
                            showCloseButton:true,
                        }).then(result => {
                            if(result.isConfirmed){
                                this.triggerPrintedPL(doc);
                                this.renderOnFlight = false;
                            }
                        })
                    } else {
                        this.triggerPrintedPL(doc);
                        this.renderOnFlight = false;
                    }
                }
            },
            renderPDF(pdfAction){
                if(this.is_preparation){
                    this.print_no_price == false
                }

                this.printNow(pdfAction, this.getPdfHeaderAndFooter);
            },
            clearPackingListItems(){
                this.resetFields()
                this.packing_list_items_dialog = false
            },
            triggerPrintedPL(doc){
                let payload = {
                    is_stockcard: this.is_stockcard,
                    no_price: this.print_no_price,
                    url: 'updatePLPrinted/'+this.viewPackingListId
                }
                this.$store.dispatch('postPL',payload).then(response => {
                    let data = response.data

                    if(data.error){
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    window.open(doc.output('bloburl'), '_blank');
                    doc = null

                    if(this.service != 1) {
                        this.printStockCard()
                    }

                    this.resetFields()

                    swal.fire("", data.msg, "success");

                    this.packing_list_items_dialog = false
                    this.$emit('closeView', 'updateStatusSuccess')
                });
            },
            resetFields(){
                this.packingListItems = []
                this.packing_list = ''
                this.discounts = []
                this.itemTypeDiscounts = []
                this.printed = false
                this.override_print_dialog = false
                this.item_type = ''
                this.renderOnFlight = false;
            },
            getItemTypes(){
                this.$store.dispatch('getItemTypes').then(async response => {
                    this.type_list = response.data
                    await this.getItemTypeDiscounts()
                    await this.getItemTypeDiscountsPDF()
                });
            },
            getItemTypeDiscounts(){
                this.itemTypeDiscounts = []

                if(this.packingListItems.length > 0) {
                    this.item_type = this.packingListItems[0].item.item_type_text
                }

                if(this.type_list.length > 0){
                    this.type_list.forEach((type)=>{
                        var name = type.text
                        var str = type.text + ': '
                        var isEmpty = true;
                        if(this.discounts.length > 0){
                            this.discounts.forEach((discount, index)=>{
                                if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                    if(index > 0){
                                        str = str + ' / '
                                    }
                                    str = str + discount[name.toLowerCase()].toString()
                                    isEmpty = false
                                }
                            })
                            if(isEmpty){
                                str = ''
                            }else{
                                if(this.packingListItems[0].item.item_type_text == type.text){
                                    this.itemTypeDiscounts.push(str)
                                }
                            }
                        }
                    })
                }
            },
            getItemTypeDiscountsPDF(){
                this.itemTypeDiscountsPDF = []
                if(this.type_list.length > 0){
                    this.type_list.forEach((type)=>{
                        var name = type.name
                        var str = '('+type.name+') LESS: '
                        var isEmpty = true;
                        if(this.discounts.length > 0){
                            this.discounts.forEach((discount, index)=>{
                                if(discount[name.toLowerCase()] != null && discount[name.toLowerCase()] != '' && discount[name.toLowerCase()] > 0){
                                    if(index > 0){
                                        str = str + ' / '
                                    }
                                    // str = str + discount[name.toLowerCase()].toString() + '%'
                                    str = str + discount[name.toLowerCase()].toString()
                                    isEmpty = false
                                }
                            })
                            if(isEmpty){
                                str = ''
                            }else{
                                if(this.packingListItems[0].item.item_type_text == type.name){
                                    this.itemTypeDiscountsPDF.push(str)
                                }
                            }
                        }
                    })
                }
            },
            checkPrintOverride(){
                if(!!this.is_accounting_user){
                    this.renderPDF('print')
                }
                else{
                    // if(this.packing_list.print_without_price_counter > 0 || this.packing_list.print_with_price_counter > 0) {
                    if(this.packing_list.print_without_price_counter + this.packing_list.print_with_price_counter > 2) {
                        this.override.override_dialog = true
                    }else {
                        this.renderPDF('print')
                    }
                }
            },
            async closeConfirmOverride(user_id, authenticated = false) {
                if (authenticated) {
                    this.override.override_dialog = false;
                    this.override_confirm = true;
                    this.renderPDF('print')
                } else {
                    this.override.override_dialog = false;
                }
            },
            confirmedOverridePrint(){
                // if(this.override_username == '' || this.override_user_password == ''){
                if(this.override_user_password == ''){
                    //this.override_user_id = -1
                    this.override_print_dialog = false
                }else{
                    let payload = {
                        departments_allowed:          ['AC', 'EX', 'IT'],
                        override_username:            this.override_username,
                        override_user_password:       this.override_user_password,
                        url:                          'confirmOverridePrintPL'
                    }
                    this.$store.dispatch('postPL',payload).then(response => {
                        let data = response.data

                        // this.override_username = ''
                        this.override_user_password = ''

                        if(data.error){
                            //this.override_user_id = -1
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        //this.override_user_id = data.user_id //get user id after entering username password
                        this.override_print_dialog = false
                        this.renderOnFlight = true;
                        setTimeout(()=>{
                            this.renderPDF('print');
                        }, 2000);
                    }).catch(error => {
                        //this.override_user_id = -1

                    });
                }
            },
            confirmedOverridePrintNoPrice(){
                // if(this.override_username == '' || this.override_user_password == ''){
                if(this.override_user_password == ''){
                    //this.override_user_id = -1
                    this.print_no_price_dialog = false
                }else{
                    let payload = {
                        departments_allowed:          ['AC', 'EX', 'IT'],
                        override_username:            this.override_username,
                        override_user_password:       this.override_user_password,
                        url:                          'confirmOverridePrintPL'
                    }
                    this.$store.dispatch('postPL',payload).then(response => {
                        let data = response.data

                        // this.override_username = ''
                        this.override_user_password = ''

                        if(data.error){
                            //this.override_user_id = -1
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        //this.override_user_id = data.user_id //get user id after entering username password
                        this.print_no_price_dialog = false
                        this.print_no_price = true;
                        this.renderOnFlight = true;
                        setTimeout(()=>{
                            this.renderPDF('print');
                        }, 2000);
                    }).catch(error => {
                        //this.override_user_id = -1

                    });
                }
            },
            convertToStockCard(){
                this.errors = []
                swal.fire({
                    title: "",
                    text: "Are you sure you want to create SC?",
                    icon: "warning",
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                })
                .then((result) => {
                    if (result.isConfirmed) {

                        this.$store.dispatch('convertToStockCard',this.viewPackingListId).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.packing_list_items_dialog = false
                            this.$emit('closeView', 'convertSuccess')
                        });
                    }
                });
            },
            transferFieldsAndGetPrintData(){
                this.salesQuotationItems = this.packing_list.sales_orders.sales_quotation.sales_quotation_items

                let sq_items_with_pl = []
                this.salesQuotationSubstitutionItems = this.salesQuotationItems.filter(e=>{
                    if(e.original_item_id != 0){
                        return e
                    }
                })

                let pl_items_id = this.packingListItems.map(e=>{return e.item_id})

                this.salesQuotationItems.forEach(e=>{
                    if(pl_items_id.includes(e.item_id)){
                        sq_items_with_pl.push(e)
                    }
                })

                sq_items_with_pl.forEach((e,i)=>{
                    Object.assign(this.packingListItems[i],{
                        original_item_id:e.original_item_id,
                        original_item:e.original_item
                    })
                })
            },
            addFieldsInOriginalItem(){
                this.packingListItems.forEach(e=>{
                    if(e.original_item_id != 0){
                        Object.assign(e.original_item,{
                            quantity:e.quantity,
                            discounted_price:this.computeOriginaDiscountedPrice(e.original_item.selling_price,e.original_item.item_type_text,3)
                        })
                    }
                })
            },
            computeOriginaDiscountedPrice(srp, type, brand_id){
                var brand_discount = this.expanded_details.customer_discount
                if (brand_discount.length > 0) {
                    if(typeof brand_discount[0].discounts == 'string'){
                        brand_discount[0].discounts = JSON.parse(brand_discount[0].discounts)
                    }
                    brand_discount[0].discounts.forEach((discount) => {
                        if (discount[type.toLowerCase()] != null) {
                            var discount_rate = parseFloat(discount[type.toLowerCase()]);

                            if (discount_rate != null && discount_rate > 0) {
                                srp =
                                    parseFloat(srp) - parseFloat(srp) * (discount_rate / 100);
                            }
                        }
                    });
                }
                return srp
            },
            getPdfHeaderAndFooter(doc,packingList,isCusDetails,print_no_price){
                // Header
                doc.setFont(undefined, 'bold').setFontSize(13).text(8,13,"D GREAT EAST INDUSTRIAL SUPPLY CORPORATION\n")
                doc.setFont(undefined, 'normal').setFontSize(9).text(8,18,"420 Del Monte Ave, Brgy. Siena, Quezon City\n\nCell No.: +63 999 889 5739 \n\nTel. No.: +63 2 8711-6742 / +63 2 7717-4059")
                doc.setFont(undefined, 'bold').setFontSize(13).text(162, 15, "DR# " + packingList.packing_list_num)
                doc.setFont(undefined, 'bold').setFontSize(11).text(162, 22,  "SO# " + packingList.order_num)
                // doc.setFont(undefined, 'bold').setFontSize(11).text(162, 29,  "CF# " + packingList.checker_form_id)

                doc.autoTable({
                    html: '#customer_and_rfItem_details',
                    theme: 'plain',
                    margin: {top: isCusDetails, left: 7, right: 9},
                    columnStyles: {
                        0: {cellWidth: 20},
                        1: {cellWidth: 133},
                        2: {cellWidth: 14},
                        3: {cellWidth: 23},
                    }
                });
                //  Footer
                if(print_no_price == false){
                    doc.autoTable({
                        html: '#total_details',
                        theme: 'plain',
                        startY: 207,
                        margin: {top: 37, left: 7, right: 9},
                        columnStyles: {
                            0: {cellWidth: 115, textColor: '#000000'},
                            1: {cellWidth: 32, halign:'right', textColor: '#000000'},
                            2: {cellWidth: 11, textColor: '#000000'},
                            3: {cellWidth: 35, halign:'right', textColor: '#000000'},
                        }
                    });
                }

                doc.autoTable({
                    html: '#num_of_items',
                    theme: 'plain',
                    tableWidth: 116,
                    startY: 223,
                    margin: {top: 37, left: 7, right: 9},
                    columnStyles: {
                        0: {cellWidth: 38, textColor: '#000000'},
                        1: {cellWidth: 34, textColor: '#000000'},
                        2: {cellWidth: 38, textColor: '#000000'},
                    }
                });

                doc.setFont(undefined, 'normal').setFontSize(10).text(125,243,"Received the above goods and")
                doc.setFont(undefined, 'normal').setFontSize(10).text(125,248,"services in good order & condition")
                doc.setFont(undefined, 'normal').setFontSize(10).text(125,258,"_________________________")
                doc.setFont(undefined, 'italic').setFontSize(9).text(125,264,"Signature over printed name / date")

                doc.autoTable({
                    html: '#footer',
                    theme: 'grid',
                    tableWidth: 116,
                    startY: 240,
                    margin: {left: 7, right: 9},
                    columnStyles: {
                        0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        2: {cellWidth: 38, halign:'center', textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });
            },
            fixItemInPdf(array, linesPerPage) {
                let clean_array = [];
                let temp = [];
                let linesRemaining = linesPerPage;
                let totalLines = 0;
                array.forEach((e, i) => {
                    e.index = i;
                    const modelLines = Math.ceil(e.item.model.length / 16)
                    const nameLines = Math.ceil(e.item.name.length / 47);
                    totalLines += Math.max(modelLines, nameLines);
                    if (totalLines <= linesRemaining) {
                        temp.push(e);
                    } else {
                        clean_array.push(temp);
                        temp = [e];
                        totalLines = nameLines;
                    }
                    if (totalLines === linesPerPage || array.length === (i + 1)) {
                        clean_array.push(temp);
                        temp = [];
                        linesRemaining = linesPerPage;
                        totalLines = 0;
                    }
                });
                return clean_array;
            },
        },
        watch: {
            viewPackingListId: function(val){
                if(val != -1){

                    if(this.viewPackingListId != null && this.viewPackingListId > 0){
                        let payload = {
                            url:    'viewPackingList/'+this.viewPackingListId
                        }
                        this.$store.dispatch('getPL',payload).then(async response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }

                            this.packing_list = data.packing_list
                            this.packingListItems = data.packing_list.packing_list_items
                            this.num_of_box = data.num_of_box
                            this.itemDetails = data.item_details
                            this.rfHasPackingList = data.rf_has_packing_list

                            // this.expanded_details.customer_discount = JSON.parse(data.packing_list.discounts)
                            // this.transferFieldsAndGetPrintData()
                            // this.addFieldsInOriginalItem()

                            this.print.packingListItems = this.fixItemInPdf(this.packingListItems, this.PRINT_NO_OF_LINES);

                            if(data.packing_list.discounts != ''){
                                this.discounts = JSON.parse(data.packing_list.discounts)
                                if(this.discounts != null){
                                    this.discounts = this.discounts[0].discounts
                                }
                            }

                            this.stock_card_id = data.stock_card_id
                            this.pending_stock_card = data.pending_stock_card

                            this.packing_list_items_dialog = true
                            this.is_accounting_user = data.is_accounting_user
                            await this.getItemTypes()
                        }).catch(error => {
                            console.log(error)
                            if(error.response.status == 422){
                                this.errors = error.response.data.errors
                            }
                        });
                    }
                }
            },
            viewStockCardId: function(val){
                if(val != -1){
                    if(this.viewStockCardId != null && this.viewStockCardId > 0){

                        this.$store.dispatch('viewStockCard',this.viewStockCardId).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }

                            this.stock_card            = data.stock_card
                            this.stockCardItems        = data.stock_card.stock_card_items

                            this.stock_card_print      = true
                        }).catch(error => {

                        });
                    }
                }
            },
            stock_card_print: function(val) {
                if (val) {
                    setTimeout(() => {
                        this.stock_card_print = false
                        this.viewStockCardId = -1
                    }, 1000);
                }
            }
        },
        computed: {
            ...mapGetters([
                'PRINT_NO_OF_LINES',
            ]),
            no_of_items:function(){
                if(this.packingListItems != null && this.packingListItems.length > 0){
                    var count = 0;
                    this.packingListItems.forEach((item)=>{
                        count += item.quantity
                    })
                    return this.thousandSeprator(count)
                }else{
                    return 0
                }
            },
            no_of_warranties:function(){
                if(this.packingListItems != null && this.packingListItems.length > 0){
                    var count = 0;
                    this.packingListItems.forEach((item)=>{
                        if(item.view_item.type_id==1){
                            count += item.quantity
                        }
                    })
                    return this.thousandSeprator(count)
                }else{
                    return 0
                }
            }
        }

    };

</script>
<style>
    .v-avatar{
        margin: 15px 50px !important;
    }
    #client_modal_card{
        min-height: 160px !important;
    }
</style>
