<template>
    <v-container fluid>
        <ComponentHeader
            :button="true"
            :filterd="filters"
            :title="'Sales By Customer Delivery Receipt'"
            :type="'Sales'"
        ></ComponentHeader>
        <ReportBodyComponent
            totals
            :user-costing="{ userCosting: GET_USER_COSTING, showCost: filters.show_cost }"
            chart='pie'
            :headers="headers"
        >
            <template #first-row>
                <v-card-text>
                    <v-row>
                        <v-col lg="3" style="padding: 3px">
                            <v-autocomplete
                                v-model="filters.mother_customers.selected"
                                :items="GET_AFFLIATE_CUSTOMER"
                                :loading="loading.mother_customer_selection"
                                :disabled="loading.mother_customer_selection"
                                outlined
                                dense
                                clearable
                                background-color="white"
                                label="Select Mother Customer"
                            ></v-autocomplete>
                        </v-col>
                        <v-col lg="3" style="padding:3px">
                            <v-autocomplete
                                v-model="filters.customers.selected"
                                :items="GET_CUSTOMERS_SELECTION"
                                :loading="loading.customer_selection"
                                :disabled="loading.customer_selection"
                                outlined
                                dense
                                background-color="white"
                                label="Select Customer"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col lg="3">
                            <v-autocomplete
                                v-model="filters.salesman.selected"
                                :items="GET_SALESMAN_SELECTION"
                                :loading="loading.salesman_selection"
                                :disabled="loading.salesman_selection"
                                outlined
                                dense
                                background-color="white"
                                label="Select Salesman"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>

            <template #second-row>
                <v-card-text>
                    <v-row>
                        <v-col lg="3">
                            <v-autocomplete
                                v-model="filters.customer_category.selected"
                                :items="GET_CUSTOMER_CATEGORY_SELECTION"
                                :loading="loading.customer_category_selection"
                                :disabled="loading.customer_category_selection"
                                outlined
                                dense
                                clearable
                                background-color="white"
                                label="Select Category"
                            ></v-autocomplete>
                        </v-col>
                        <v-col lg="3">
                            <v-autocomplete
                                v-model="filters.customer_classification.selected"
                                :items="GET_CLASSIFICATIONS_SELECTION"
                                :loading="loading.customer_classification_selection"
                                :disabled="loading.customer_classification_selection"
                                outlined
                                dense
                                clearable
                                background-color="white"
                                label="Select Classification"
                            ></v-autocomplete>
                        </v-col>
                        <v-col lg="3">
                            <v-autocomplete
                                v-model="filters.customer_clusters.selected"
                                :items="GET_CLUSTER_SELECTION"
                                :loading="loading.customer_cluster_selection"
                                :disabled="loading.customer_cluster_selection"
                                outlined
                                dense
                                clearable
                                background-color="white"
                                label="Select Cluster"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>

            <template #third-row>
                <v-card-text>
                    <v-row>
                        <v-col cols="1" class="mx-1" v-if="GET_USER_COSTING">
                            <v-checkbox
                                v-model="filters.show_cost"
                                label="SC"
                                color="white"
                                :true-value="1"
                                :false-value="null"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col lg="1" class="mx-1">
                            <v-checkbox
                                v-model="filters.confirmed"
                                label="confirmed"
                                color="white"
                                true-value="1"
                                false-value="0"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col lg="1" class="mx-1">
                            <v-checkbox
                                v-model="filters.unconfirmed"
                                label="unconfirmed"
                                color="white"
                                true-value="1"
                                false-value="0"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col lg="3" class="mx-1">
                            <label class="float-left control-label col-form-label">Date From</label>
                            <v-menu
                                v-model="filters.date_from_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_from"
                                        readonly
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_from" @input="filters.date_from_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col lg="3" class="mx-1">
                            <label class="float-left control-label col-form-label">Date To</label>
                            <v-menu
                                v-model="filters.date_to_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_to"
                                        readonly
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_to" @input="filters.date_to_menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-spacer></v-spacer>
                    </v-row>
                </v-card-text>
            </template>
        </ReportBodyComponent>

    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import moment from 'moment';
import { mapGetters } from 'vuex';
    export default {
        data(){
            return{
                errors: [],
                filters:{
                    date_from: moment().clone().startOf("month").format("YYYY-MM-DD"),
                    date_to: moment().clone().endOf("month").format("YYYY-MM-DD"),
                    date_from_menu: false,
                    date_to_menu: false,
                    customers:{
                        selection:null,
                        selected:'',
                    },
                    salesman:{
                        selected:'',
                        selection:null
                    },
                     mother_customers: {
                        selection:null,
                        selected: '',
                    },
                    customer_category: {
                        election:null,
                        selected: [],
                    },
                    customer_classification: {
                        election:null,
                        selected: [],
                    },
                    customer_clusters: {
                        election:null,
                        selected: [],
                    },
                    confirmed:'1',
                    unconfirmed: '1',
                    show_cost: null,
                },
                headers: [
                    { text: 'Date', align: 'left', value: 'packing_list_date', },
                    { text: 'Customer', align: 'left', value: 'customer_name', },
                    { text: 'Remarks', align: 'left', value: 'remarks', },
                    { text: 'Salesman', align: 'left', value: 'salesman_name', },
                    { text: 'Category', align: 'left', value: 'customer_category', },
                    { text: 'Classification', align: 'left', value: 'customer_classification', },
                    { text: 'Cluster', align: 'left', value: 'customer_clusters', },
                    { text: 'Promo', align: 'left', value: 'promo', },
                    { text: 'DR#', align: 'left', value: 'packing_list_num', },
                    { text: 'Amount', align: 'right', width: '10%', value: 'total_amount', },
                ],
                selected:{
                    customers:'',
                    salesman:''
                },
                loading:{
                        customer_selection  : true,
                        salesman_selection  : true,
                        mother_customer_selection: true,
                        customer_category_selection: true,
                        customer_classification_selection: true,
                        customer_cluster_selection: true,
                },
            }
        },
        mounted() {
            this.$store.dispatch('getUserCosting')
            this.$store.dispatch('getAllcustomersSelection')
            this.$store.dispatch('getAllsalesmanSelection')
            this.$store.dispatch('getAffliatecustomer')
            this.$store.dispatch('getallcustomerCategory')
            this.$store.dispatch('getAllcustomerClassificationSelection')
            this.$store.dispatch('getClusterSelection')
            this.$store.commit('FILTER_VALUE', this.getFilters())
        },
        methods:{
            getFilters(){
                let data = {
                    mother_customer: this.filters.mother_customers.selected,
                    salesman_id: this.filters.salesman.selected,
                    customer_id: this.filters.customers.selected,
                    customer_category_id: this.filters.customer_category.selected,
                    customer_classification_id: this.filters.customer_classification.selected,
                    customer_cluster_id: this.filters.customer_clusters.selected,
                    confirmed:  this.filters.confirmed,
                    unconfirmed: this.filters.unconfirmed,
                    confirm: this.filters.confirmed,
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                    is_packing_list_report:1,
                    filename: 'Sales By Customer',
                    url: 'sales-by-customer',
                    headers: this.headers,
                    show_cost: this.filters.show_cost
                }
                return data
            }
        },

        computed:{
            ...mapGetters([
                'GET_USER_COSTING',
                'GET_SALESMAN_SELECTION',
                'GET_CUSTOMERS_SELECTION',
                'GET_SALES_REPORT_DATA',
                'GET_CUSTOMER_CATEGORY_SELECTION',
                'GET_CLASSIFICATIONS_SELECTION',
                'GET_CLUSTER_SELECTION',
                'GET_AFFLIATE_CUSTOMER'
            ]),
        },

        components:{
            ComponentHeader,
            ReportBodyComponent,
        },

        watch:{
            'filters':{
                handler(){
                    this.$store.commit('FILTER_VALUE',this.getFilters())
                },
                deep:true
            },
            'GET_CUSTOMERS_SELECTION':{
                handler(val){
                    this.filters.customers.selection = val
                    this.loading.customer_selection = false
                }
            },
            'GET_SALESMAN_SELECTION':{
                handler(val){
                    this.filters.salesman.selection = val
                    this.loading.salesman_selection = false
                }
            },
            'GET_CUSTOMER_CATEGORY_SELECTION': {
                handler(val) {
                    this.filters.salesman.selection = val
                    this.loading.customer_category_selection = false
                },
            },
            'GET_CLASSIFICATIONS_SELECTION': {
                handler(val) {
                    this.filters.salesman.selection = val
                    this.loading.customer_classification_selection = false
                },
            },
            'GET_CLUSTER_SELECTION': {
                handler(val) {
                    this.filters.salesman.selection = val
                    this.loading.customer_cluster_selection = false
                },
            },
             'GET_AFFLIATE_CUSTOMER':{
                handler(val){
                    this.loading.mother_customer_selection = false
                }
            },
            'filters.show_cost': {
                handler(val) {
                    if (val && this.GET_USER_COSTING) {
                        this.headers.splice(6, 0, {
                            text: 'Total Cost', align: 'right', width: '10%', value: 'total_cost',
                        })
                    } else {
                        let index = this.headers.findIndex(f => {
                            return f.value == 'total_cost'
                        })

                        if (index !== -1) {
                            this.headers.splice(index, 1)
                        }
                    }
                }
            }
        }
    }
</script>
