<template>
        <v-card v-model="addEditDialog">
            <v-card-title>
                <v-row>
                    <v-col cols="10">
                        <span class="text-h5">Stock Adjustment Information</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="pull-right-10 p-2">
                        <v-icon class="float-right" @click="closeDialog();">mdi-close-circle</v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-autocomplete
                                v-model="warehouse_id"
                                :items="GET_WAREHOUSE_LIST"
                                label="Select Warehouse"
                                class="mt-1"
                                outlined
                                dense
                                hide-details
                                auto-select-first
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="iif_ref_no"
                                label="IIF Ref#"
                                class="mt-1"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                v-model="requestor_id"
                                :items="GET_ALL_USER"
                                label="Requestor"
                                class="mt-1"
                                item-value="value"
                                item-text="text"
                                outlined
                                dense
                                hide-details
                                auto-select-first
                                @change="onSelectRequestor()"
                            ></v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete
                                v-model="created_by_id"
                                :items="GET_CURRENT_USER"
                                label="Created By"
                                class="mt-1"
                                outlined
                                dense
                                hide-details
                                auto-select-first
                                disabled
                            ></v-autocomplete>
                            <!-- <v-text-field
                                v-model="GET_CURRENT_USER[0].text"
                                label="Created By"
                                class="mt-1"
                                item-value="value"
                                item-text="text"
                                outlined
                                dense
                                :disabled="true"
                            ></v-text-field> -->
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="text-right">
                            <div class="d-flex justify-content-end align-item-end">
                                <FileUploadComponentVue ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                            </div>
                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-text-field
                                v-model="remarks"
                                label="Remarks"
                                class="mt-1"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="d-flex justify-content-center m-0 p-0" style="height: 60px;" v-if="userAccess.adjustment === true">
                        <v-spacer></v-spacer>
                            <span class="d-flex align-items-center" style="font-size: 18px; color: #000000;">Adjustment:</span>
                            <v-checkbox
                                v-model="adjust_stock"
                                label="Stock"
                                color="orange darken-3"
                                class="mx-2"
                                on-icon="mdi-check-circle"
                                off-icon="mdi-close-circle-outline" 
                                @click="toggleAdjustStock()">
                            </v-checkbox>
                            <v-checkbox
                                v-model="adjust_movement"
                                label="Movement"
                                color="orange darken-3"
                                on-icon="mdi-check-circle"
                                off-icon="mdi-close-circle-outline"
                                @click="toggleAdjustMovement()">
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card outlined class="pa-2">
                    <div class="mt-2 ml-2">
                        <v-btn
                            color="primary"
                            dark
                            small
                            @click="openBatchDialog();"
                        >
                            Batch Addition
                        </v-btn>
                    </div>

                    <v-dialog v-model="batch_addition.dialog" persistent max-width="30%" max-height="20%" scrollable>
                        <v-card>
                            <v-row class="m-0">
                                <v-col cols="10">
                                    <v-card-title>
                                        <span class="headline">Batch Addition</span>
                                    </v-card-title>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="pull-right-10 p-2">
                                    <v-btn
                                        class="float-right"
                                        color="gray"
                                        text
                                        icon
                                        @click="closeBatchAdditionDialog(); dataFromExcel = ''"
                                    >
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card-text v-if="!loading">
                                <span v-if="hasError">
                                    <span>
                                        <table>
                                            <tr class="m-0 p-0">
                                                <td>
                                                    <v-simple-checkbox dense v-model="showError" @click="showNotInModel()"></v-simple-checkbox>
                                                </td>
                                                <td>
                                                    <div class="redBox"></div>
                                                </td>
                                                <td>
                                                    <span> Model not found in the system</span>
                                                </td>
                                            </tr>
                                            <tr class="m-0 p-0">
                                                <td>
                                                    <v-simple-checkbox dense v-model="showDuplicate" @click="showAllDuplicate()"></v-simple-checkbox>
                                                </td>
                                                <td>
                                                    <div class="yellowBox"></div>
                                                </td>
                                                <td>
                                                    <span> Duplicate model detected</span>
                                                </td>
                                            </tr>
                                            <tr class="m-0 p-0">
                                                <td>
                                                    <v-simple-checkbox dense v-model="showDuplicateFromBatch" @click="showAllDuplicateFromBatch()">
                                                    </v-simple-checkbox>
                                                </td>
                                                <td>
                                                    <div class="orangeBox"></div>
                                                </td>
                                                <td>
                                                    <span> Model already exist</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </span>
                                </span>
                                <div class="ma-0" style="max-height:400px; width:100%; margin-bottom:10px; overflow-y:auto; overflow-x:hidden">
                                    <v-row class="mb-2 mt-1 mx-auto">
                                        <v-col cols="5">Model</v-col>
                                        <v-col cols="5">Quantity</v-col>
                                        <v-col cols="2" class="text-center">Action</v-col>
                                    </v-row>
                                    <v-row class="mx-0 pa-0 ma-0" v-if="isDone">
                                        <v-col cols="5" class="pa-0 ma-0">
                                            <v-text-field
                                                v-if="isDone"
                                                v-model="dataFromExcel"
                                                :autofocus="batch_addition.dialog"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                @paste="onPaste"
                                                @click="$event.target.focus()"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="5" class="py-0 my-0">
                                            <v-text-field
                                                v-if="isDone"
                                                v-model="dataFromExcel2"
                                                color="primary"
                                                rows="1"
                                                outlined
                                                dense
                                                small
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ma-0 text-center">
                                            <v-btn
                                                v-if="isDone"
                                                style="height:20px; width:20px;"
                                                color="primary"
                                                fab
                                                dark
                                                small
                                                @click="clearSingleAddition()"
                                            >
                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <div v-if="showError">
                                        <v-row class="mx-0 pa-0 ma-0" v-for="(ShowNotInModel, i) in  ShowNotInModels" :key="i">
                                            <v-col cols="5" class="pa-0 ma-0">
                                                <v-text-field
                                                    v-model="ShowNotInModels[i].e.dataFromExcel"
                                                    :class="{ notInModel: ShowNotInModels[i].e.error, duplicateModel: ShowNotInModels[i].e.duplicate, duplicateBatchModel: ShowNotInModels[i].e.duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                    @paste="onPaste"
                                                    @change="getAllData()"
                                                    @click="$event.target.focus()"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="py-0 ma-0">
                                                <v-text-field
                                                    v-model="ShowNotInModels[i].e.dataFromExcel2"
                                                    :class="{ notInModel: ShowNotInModels[i].e.error, duplicateModel: ShowNotInModels[i].e.duplicate, duplicateBatchModel: ShowNotInModels[i].e.duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="ma-0">
                                                <v-btn
                                                    style="height:20px; width:20px;"
                                                    color="primary"
                                                    fab
                                                    dark
                                                    small
                                                    @click="deleteNotInModel(ShowNotInModels[i].e.dataFromExcel, i)"
                                                >
                                                    <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-if="showDuplicate">
                                        <v-row class="mx-0 pa-0 ma-0" v-for="(ShowAllDuplicate, i) in  ShowAllDuplicates" :key="i">
                                            <v-col cols="5" class="pa-0 ma-0">
                                                <v-text-field
                                                    v-model="ShowAllDuplicates[i].e.dataFromExcel"
                                                    :class="{ notInModel: ShowAllDuplicates[i].e.error, duplicateModel: ShowAllDuplicates[i].e.duplicate, duplicateBatchModel: ShowAllDuplicates[i].e.duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                    @paste="onPaste"
                                                    @change="getAllData()"
                                                    @click="$event.target.focus()"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="py-0 ma-0">
                                                <v-text-field
                                                    v-model="ShowAllDuplicates[i].e.dataFromExcel2"
                                                    :class="{ notInModel: ShowAllDuplicates[i].e.error, duplicateModel: ShowAllDuplicates[i].e.duplicate, duplicateBatchModel: ShowAllDuplicates[i].e.duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="ma-0">
                                                <v-btn
                                                    style="height:20px; width:20px;"
                                                    color="primary"
                                                    fab
                                                    dark
                                                    small
                                                    @click="deleteDuplicatesInModel(ShowAllDuplicates[i].e.dataFromExcel, i)"
                                                >
                                                    <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-if="showDuplicateFromBatch">
                                        <v-row class="mx-0 pa-0 ma-0" v-for="( ShowAllDuplicateFromBatch, i ) in  ShowAllDuplicateFromBatchs" :key="i">
                                            <v-col cols="5" class="pa-0 ma-0">
                                                <v-text-field
                                                    v-model="ShowAllDuplicateFromBatchs[i].e.dataFromExcel"
                                                    :class="{ notInModel: ShowAllDuplicateFromBatchs[i].e.error, duplicateModel: ShowAllDuplicateFromBatchs[i].e.duplicate, duplicateBatchModel: ShowAllDuplicateFromBatchs[i].e.duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                    @paste="onPaste"
                                                    @click="$event.target.focus()"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="py-0 ma-0">
                                                <v-text-field
                                                    v-model="ShowAllDuplicateFromBatchs[i].e.dataFromExcel2"
                                                    :class="{ notInModel: ShowAllDuplicateFromBatchs[i].e.error, duplicateModel: ShowAllDuplicateFromBatchs[i].e.duplicate, duplicateBatchModel: ShowAllDuplicateFromBatchs[i].e.duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="ma-0">
                                                <v-btn
                                                    style="height:20px; width:20px;"
                                                    color="primary"
                                                    fab
                                                    dark
                                                    small
                                                    @click="deleteDuplicatesFromBatchInModel(ShowAllDuplicateFromBatchs[i].e.dataFromExcel, i)"
                                                >
                                                    <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-if="!showError && !showDuplicate && !showDuplicateFromBatch">
                                        <v-row class="mx-0 pa-0 ma-0" v-for="( text_area_field, i ) in  text_area_fields" :key="i">
                                            <v-col cols="5" class="pa-0 ma-0">
                                                <v-text-field
                                                    v-model="text_area_fields[i].dataFromExcel"
                                                    :class="{ notInModel: text_area_fields[i].error, duplicateModel: text_area_fields[i].duplicate, duplicateBatchModel: text_area_fields[i].duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                    @paste="onPaste"
                                                    @change="getAllData()"
                                                    @click="$event.target.focus()"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="py-0 ma-0">
                                                <v-text-field
                                                    v-model="text_area_fields[i].dataFromExcel2"
                                                    :class="{ notInModel: text_area_fields[i].error, duplicateModel: text_area_fields[i].duplicate, duplicateBatchModel: text_area_fields[i].duplicateBatch }"
                                                    :autofocus="batch_addition.dialog"
                                                    class="InModel"
                                                    color="primary"
                                                    rows="1"
                                                    outlined
                                                    dense
                                                    small
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="ma-0">
                                                <v-btn
                                                    style="height:20px; width:20px;"
                                                    color="primary"
                                                    fab
                                                    dark
                                                    small
                                                    @click="clearItem(i)"
                                                >
                                                    <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                                <v-row class="mt-2">
                                    <v-col cols="12">
                                        <v-btn :disabled="isDisabledButton" class="float-right ml-2" @click="submittedBtn()">Submit</v-btn>
                                        <v-btn class="float-right" dark color="secondary" @click="clearFields()">Clear</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text v-else>
                                <div class="text-center">
                                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>

                    <v-simple-table dense class="dynamic_list_table" style="overflow-y: scroll">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center mx-2" width="12%">Model</th>
                                    <th class="text-center mx-2" width="40%">Name</th>
                                    <th class="text-center mx-2" width="7%">UOM</th>
                                    <th class="text-center mx-2" width="7%">Type</th>
                                    <th class="text-center mx-2" width="22%">Category</th>
                                    <th class="text-center mx-2" width="6%">Qty</th>
                                    <th class="text-center mx-2" width="5%">Stocks</th>
                                    <th class="text-center mx-2" width="3%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <span v-for="(adjustmentItem, i) in  adjustmentItems" :key="i" class="d-contents">
                                    <tr>
                                        <td>
                                            <v-text-field
                                                v-model="adjustmentItems[i].item_model"
                                                class="d-inline-block small mt-2 centered-input"
                                                style="width:100%"
                                                background-color="grey lighten-2"
                                                placeholder="Select Model"
                                                color="black"
                                                outlined
                                                dense
                                                hide-details
                                                readonly
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="adjustmentItems[i].item_name"
                                                background-color="grey lighten-2"
                                                color="black"
                                                dense
                                                hide-details
                                                outlined
                                                readonly
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="adjustmentItems[i].item_uom"
                                                class="alignText centered-input"
                                                background-color="grey lighten-2"
                                                color="black"
                                                outlined
                                                dense
                                                hide-details
                                                readonly
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="adjustmentItems[i].item_type"
                                                class="alignText centered-input"
                                                background-color="grey lighten-2"
                                                color="black"
                                                outlined
                                                dense
                                                hide-details
                                                readonly
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="adjustmentItems[i].item_category"
                                                class="alignText centered-input"
                                                background-color="grey lighten-2"
                                                color="black"
                                                outlined
                                                dense
                                                hide-details
                                                readonly
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="adjustmentItems[i].qty"
                                                class="alignText centered-input"
                                                outlined
                                                dense
                                                hide-details
                                                @keyup="validate(i)"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="adjustmentItems[i].remain_stocks"
                                                class="alignText centered-input"
                                                background-color="grey lighten-2"
                                                color="black"
                                                outlined
                                                dense
                                                hide-details
                                                readonly
                                            ></v-text-field>
                                        </td>
                                        <td class="text-center">
                                            <v-btn
                                                style="height:20px; width:20px;"
                                                color="primary"
                                                fab
                                                dark
                                                small
                                                @click="removeAdjustmentItemByIndex(i)"
                                            >
                                                <v-icon dark small style="font-size:12px">mdi-minus</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </span>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-card-text>
            <div class="border-top">
                <div class="card-body py-4 d-flex justify-content-center">
                    <v-btn @click="actions == 'Submit' ? addNewAdjustment() : updateAdjustment()">
                        {{ actions }}
                    </v-btn>

                </div>
            </div>
        </v-card>

</template>
<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
import buttons from '../../Buttons/DialogButton.vue'
import buttons_components from '../../../../../assets/js/components-js.js'
import FileUploadComponentVue from '@/views/main/Shared/FileUploadComponent.vue'
export default {
    components: {
        buttons,
        buttons_components,
        FileUploadComponentVue,
    },
    props: [
        'selectedAction'
    ],
    data() {
        return {
            buttons: buttons_components.buttons,
            loaded: false,
            users: [],
            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 15,
            search: '',
            headers: [
                { text: 'Ref#', align: 'start', value: 'reference_num' },
                { text: 'Warehouse', align: 'start', value: 'warehouse_name' },
                { text: 'Remarks', align: 'start', value: 'remarks' },
                { text: 'Requestor', align: 'start', value: 'requestor_name' },
                { text: 'Created By', align: 'start', value: 'created_by_name' },
                { text: 'Status', align: 'center', value: 'status_text' },
                { text: 'Processed By', align: 'start', value: 'processed_by_name' },
                { text: 'Process Date', align: 'start', value: 'processed_date' },
                { text: 'Actions', align: 'center', value: 'actions', sortable: false }
            ],
            viewUserId: '',
            userAccess: {
                view: true,
                create: true,
                edit: true,
                delete: true
            },
            dialog: false,
            access: {
                department_code: 'MD',
                module: 'users',
                action: 'create'
            },
            loaded: false,
            warehouse_list: [],
            user_list: [],
            requestor_select: '',
            requestor_name: '',
            requestor_id: '',
            iif_ref_no: '',
            created_by_id: '',
            created_by_name: '',
            warehouse_id: '',
            remarks: '',
            adjust_stock: 1,
            adjust_movement: 1,
            adjustmentItems: [],
            adjustmentItem: {
                item_id: '',
                item_name: '',
                item_uom: '',
                item_type: '',
                item_category: '',
                qty: ''
            },
            max_adjustment_items_count: 999,
            viewAdjustmentId: '',
            action: this.selectedAction,
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: []
            },
            isDone: true,
            hasError: false,
            text_area_fields: [],
            text_area_field: {
                dataFromExcel: '',
                dataFromExcel2: ''
            },
            dataFromExcel: '',
            dataFromExcel2: '',
            isViewEdit: false,
            isDisabledButton: false,
            listOfNotInModels: [],
            listOfDuplicates: [],
            counterBatch: 1,
            max_items_count: 999,
            batchCounter: 0,
            lastBatchModel: [],
            duplicateFromBatch: [],
            tempAdjustmentItems: [],
            showError: false,
            showDuplicate: false,
            showDuplicateFromBatch: false,
            ShowNotInModel: {
                dataFromExcel: '',
                dataFromExcel2: ''
            },
            ShowNotInModels: [],
            ShowAllDuplicate: {
                dataFromExcel: '',
                dataFromExcel2: ''
            },
            ShowAllDuplicates: [],
            ShowAllDuplicateFromBatch: {
                dataFromExcel: '',
                dataFromExcel2: ''
            },
            ShowAllDuplicateFromBatchs: [],
            retrieved: false,
            showLoader: false,
            isSingleAdded: false,
            addEditDialog: false,
            loading: false,
            uploadedFiles:null
        }
    },
    mounted() {
        if (this.adjustmentItems.length == 0) {
            this.addAdjustmentItem()
        }
        this.$store.dispatch("getCurrentUser", {userId: this.USERACCOUNT_LOAD.id})
        this.setDefaultUser()
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id);

    },
    computed: {
        ...mapGetters([
            'GET_ALL_STOCK',
            'GET_STOCK_ADJUSMENT',
            'DIALOGS',
            'ACTION',
            'GET_WAREHOUSE_LIST',
            'GET_ALL_USER',
            'GET_CURRENT_USER',
            'USERACCOUNT_LOAD',
            'USER_ACCESS'
        ]),
        actions() {
            if (this.ACTION == 'Submit') {
                // this.adjustmentItems = [];
                // this.warehouse_id    = ''
                // this.remarks         = ''
                // this.requestor_id    = ''
                // this.requestor_name  = ''
                // if(this.adjustmentItems.length == 0){
                //     this.addAdjustmentItem();
                // }
                this.counterBatch = 0;
                return 'Submit'
            } else {
                this.GET_STOCK_ADJUSMENT.forEach((e) => {
                    this.warehouse_id = e.warehouse_id
                    this.remarks = e.remarks
                    this.requestor_id = e.requestor_id
                    this.iif_ref_no = e.iif_ref_no
                    this.requestor_name = e.requestor_name
                    this.adjustmentItems = e.adjustmentItems
                    this.adjustment_id = e.id
                    this.adjust_stock = e.adjust_stock
                    this.adjust_movement = e.adjust_movement
                    if (e.adjustmentItems != null && e.adjustmentItems.length > 0) {
                        e.adjustmentItems.forEach((item, key) => {
                            e.adjustmentItems[key].item_model = item.model
                            e.adjustmentItems[key].item_name = item.item_name
                            e.adjustmentItems[key].item_uom = item.uom
                            e.adjustmentItems[key].item_type = item.type
                            e.adjustmentItems[key].item_category = item.category
                            e.adjustmentItems[key].qty = item.qty_type == 0 ? '-' + item.quantity : item.quantity
                        })
                    }
                })
                this.counterBatch = 1;
                return 'Update'
            }
        }
    },
    methods: {
        setDefaultUser(){
            this.GET_CURRENT_USER.map(e =>{
                this.created_by_id = e.value
                this.requestor_id = e.value
                this.created_by_name = e.text
            })
        },
       async onSelectRequestor() {
            let payload = {
                requestor_id: this.requestor_id
            }
            await this.$store.dispatch('getUsernameOnSelect', payload).then(response => {
                this.requestor_name = response.data[0].name;
            })
        },
        closeDialog() {
            this.$store.commit('DIALOG', false);
            this.clearFields();
        },
        addAdjustmentItem() {
            if (this.adjustmentItems.length < this.max_adjustment_items_count) {
                this.adjustmentItems.push({
                    item_id: '',
                    item_name: '',
                    item_uom: '',
                    item_type: '',
                    item_category: '',
                    qty: '',
                })
            }
        },
        openBatchDialog() {
            if (this.warehouse_id == '' || this.requestor_id == '') {
                swal.fire(
                    '',
                    'Please input required fields',
                    'warning'
                )
                return false;
            }
            else {
                if (this.adjustmentItems.length == 1) {
                    this.lastBatchModel.length = 0;
                    this.lastBatchModel = [];
                }
                this.batch_addition.dialog = true;
                this.isDone = true;
                this.text_area_fields.length = 0;
                this.batch_addition.data = [];
                this.listOfNotInModels = [];
                this.listOfNotInModels.length = 0;
                this.hasError = false;
                this.listOfDuplicates = [];
                this.listOfDuplicates.length = 0;
                this.duplicateFromBatch = [];
                this.duplicateFromBatch.length = 0;
                this.isDisabledButton = false;
                this.showError = false;
                this.showDuplicate = false;
                this.showDuplicateFromBatch = false;
                this.ShowNotInModels = [];
                this.ShowNotInModels.length = 0;
                this.ShowAllDuplicates = [];
                this.ShowAllDuplicates.length = 0;
                this.ShowAllDuplicateFromBatchs = [];
                this.ShowAllDuplicateFromBatchs.length = 0;
                this.showLoader = false;
                this.dataFromExcel = '';
                this.dataFromExcel2 = '';
            }
        },
        onPaste(evt) {
            this.loading = true
            let that = this;
            this.batch_addition.data = []
            var pastedText = evt.clipboardData.getData('Text');

            if (!pastedText) {
                return false;
            }
            else {
                var rows = pastedText.split("\r\n")
                var cells = '';
                if (this.text_area_fields.length > 1) {
                    this.text_area_fields.pop();
                }
                for (var y in rows) {
                    if (y != rows.length - 1) {
                        cells = rows[y].split("\t");
                        this.text_area_fields.push({
                            dataFromExcel: cells[0],
                            dataFromExcel2: cells[1],
                            error: false,
                            duplicate: false,
                            duplicateBatch: false
                        })
                        if (y == rows.length - 1) {
                            this.text_area_fields.push({
                                dataFromExcel: cells[0],
                                dataFromExcel2: cells[1],
                                error: false,
                                duplicate: false,
                                duplicateBatch: false

                            })
                        }
                        this.batch_addition.data.push(Object.assign({}, {
                            model: cells[0],
                            qty: cells[1],
                            // foc: cells[2],
                            id: '',
                            skip: false,
                        }))
                    }
                }
                that.getAllData();
            }
        },
        myFunction() {
            this.batch_addition.dialog = true;
        },
        closeBatchAdditionDialog() {
            this.batch_addition.dialog = false;
            this.loading = false;
        },
        getAllData() {
            this.showError = false;
            this.showDuplicate = false;
            this.showDuplicateFromBatch = false;
            this.showLoader = true;
            let that = this;
            let checkModel = [];
            this.listOfNotInModels = [];
            this.listOfNotInModels.length = 0;
            this.listOfDuplicates = [];
            this.listOfDuplicates.length = 0;
            this.duplicateFromBatch = [];
            this.duplicateFromBatch.length = 0;
            let payload = {
                items: this.text_area_fields.map(e => { return e.dataFromExcel.toUpperCase() }),
                isChecking: true,
                forSA: 'forSA',
                warehouse_id: this.warehouse_id
            }
            this.$store.dispatch('getItemsByModel', payload).then(response => {
                //True Resposne
                this.tempAdjustmentItems = response.data;

                response.data = response.data.map(e => { return e.model })
                let lowerCaseResponseModel = [];

                response.data.forEach(e => {
                    lowerCaseResponseModel.push(e.toLowerCase());
                })
                let models = this.text_area_fields.map(e => { return e.dataFromExcel });

                if (this.batchCounter > 0) {
                    checkModel = this.text_area_fields.map(e => {
                        return e.dataFromExcel;
                    })
                    checkModel.forEach((e, i) => {
                        if (that.lastBatchModel.includes(e)) {
                            that.text_area_fields[i].duplicateBatch = true;
                            that.duplicateFromBatch.push(e);
                        }
                        else {
                            that.text_area_fields[i].duplicateBatch = false;
                        }
                    })
                }
                let showItemsAsString = that.duplicateFromBatch.join("\r\n");

                if (that.duplicateFromBatch.length > 0) {
                    swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'These models already exist in your adjustment  \n \n' + showItemsAsString.split(',').join("\r\n"),
                    })
                }
                let filteredModel = models.filter(function (e, i) {
                    if (models.indexOf(e) != i) {
                        that.text_area_fields[i].duplicate = true;
                        that.listOfDuplicates.push(e);
                    } else {
                        that.text_area_fields[i].duplicate = false;
                    }
                });

                this.text_area_fields.forEach((e, i) => {
                    if (!response.data.includes(e.dataFromExcel) && !lowerCaseResponseModel.includes(e.dataFromExcel.toLowerCase())) {
                        this.listOfNotInModels.push(e.dataFromExcel);
                        this.text_area_fields[i].error = true;
                    }
                    else {
                        this.text_area_fields[i].error = false;
                    }
                })
                if (this.listOfNotInModels.length > 0 || this.listOfDuplicates.length > 0 || this.duplicateFromBatch.length > 0) {
                    this.isDisabledButton = true
                    this.hasError = true
                } else {
                    this.isDisabledButton = false
                    this.hasError = false
                }
                this.showLoader = false;
                this.loading = false;
            })
            this.isDone = false;
            // this.closeBatchAdditionDialog();
            // setTimeout(this.myFunction, 1);
        },
        getSingleData() {
            let that = this;
            this.batchCounter++;
            this.closeBatchAdditionDialog();
            this.batch_addition.dialog = false;
            let models = this.tempAdjustmentItems.map(e => {
                return e.model.toUpperCase();
            });

            if (this.adjustmentItems.length < this.max_items_count) {

                if (this.counterBatch == 0 && this.isSingleAdded == false) {
                    this.adjustmentItems.pop();
                    this.counterBatch++;
                    this.isSingleAdded = true;
                }
                for (var i = 0; i < 1; i++) {
                    this.adjustmentItems.push({
                        item_model: this.dataFromExcel.toUpperCase(),
                        item_id: this.tempAdjustmentItems[i].id,
                        item_name: this.tempAdjustmentItems[i].name,
                        item_uom: this.tempAdjustmentItems[i].item_uom_text,
                        item_type: this.tempAdjustmentItems[i].item_type_text,
                        item_category: this.tempAdjustmentItems[i].item_category_text,
                        remain_stocks: this.tempAdjustmentItems[i].warehouse_stock_quantity,
                        qty: this.dataFromExcel2,
                    })
                }
                models.forEach(e => {
                    that.lastBatchModel.push(e)
                });
            }
        },
        submittedBtn() {
            if (this.isDone == true) {
                let singleDataArray = [];
                this.showError = false;
                this.showDuplicate = false;
                this.showDuplicateFromBatch = false;
                this.showLoader = true;
                let that = this;
                let checkModel = [];
                this.listOfNotInModels = [];
                this.listOfNotInModels.length = 0;
                this.listOfDuplicates = [];
                this.listOfDuplicates.length = 0;
                this.duplicateFromBatch = [];
                this.duplicateFromBatch.length = 0;
                singleDataArray.push(this.dataFromExcel);
                let payload = {
                    items: singleDataArray.map(e => { return e.toUpperCase() }),
                    isChecking: true,
                    forSA: 'forSA',
                    warehouse_id: this.warehouse_id
                }
                this.$store.dispatch('getItemsByModel', payload).then(response => {
                    let listOfModels = this.adjustmentItems.map(e => { return e.item_model });
                    let checkSingleInputDuplicate = listOfModels.includes(this.dataFromExcel.toUpperCase());
                    if (response.data.length == 0) {
                        swal.fire({
                            icon: 'error',
                            title: 'Item not Found',
                        })
                    }
                    else {
                        if (checkSingleInputDuplicate) {
                            swal.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                text: this.dataFromExcel + ' already exist in your adjustment',
                            })
                        }
                        else {
                            this.tempAdjustmentItems = response.data;
                            this.getSingleData();
                        }
                    }
                })
            }
            else {
                let that = this;
                let models = this.tempAdjustmentItems.map(e => {
                    return e.model.toUpperCase();
                });
                if (this.adjustmentItems.length < this.max_items_count) {
                    if (this.counterBatch == 0) {
                        this.adjustmentItems.pop();
                    }
                    let tempArr = []
                    for (let i = 0; i < this.tempAdjustmentItems.length; i++) {
                        tempArr.push({
                            item_model: this.text_area_fields[i].dataFromExcel.toUpperCase(),
                            item_id: this.tempAdjustmentItems[i].id,
                            item_name: this.tempAdjustmentItems[i].name,
                            item_uom: this.tempAdjustmentItems[i].item_uom_text,
                            item_type: this.tempAdjustmentItems[i].item_type_text,
                            item_category: this.tempAdjustmentItems[i].item_category_text,
                            remain_stocks: this.tempAdjustmentItems[i].warehouse_stock_quantity,
                            qty: this.text_area_fields[i].dataFromExcel2,
                        })
                    }
                    this.adjustmentItems = tempArr.concat(this.adjustmentItems);
                    models.forEach(e => {
                        that.lastBatchModel.push(e)
                    });
                    this.counterBatch++;
                    this.batchCounter++; // for Checking duplicates from last Batch
                    this.batch_addition.dialog = false;
                }
            }
        },
        clearItem(i) {
            let temp = [];
            let tempArr = [];
            if (this.text_area_fields.length == 1) {
                this.clearFields();
            }
            else {
                if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

                }
                else {
                    this.hasError = false;
                    this.isDisabledButton = false;
                }
                this.text_area_fields.forEach((e, index) => {
                    if (i != index) {
                        temp.push(e);

                        let checkingOfError = this.listOfNotInModels.includes(this.text_area_fields[i].dataFromExcel)
                        let checkingOfDuplicate = this.listOfDuplicates.includes(this.text_area_fields[i].dataFromExcel)
                        let checkingOfBatchDuplicate = this.duplicateFromBatch.includes(this.text_area_fields[i].dataFromExcel)

                        if (checkingOfError && !checkingOfBatchDuplicate && !checkingOfDuplicate) {
                            this.listOfNotInModels.pop();
                        }
                        if (checkingOfDuplicate && !checkingOfError && !checkingOfBatchDuplicate) {
                            this.listOfDuplicates.pop();
                        }
                        if (checkingOfBatchDuplicate && !checkingOfError && !checkingOfDuplicate) {
                            this.duplicateFromBatch.pop();
                        }
                    }
                })
                this.text_area_fields = temp;
            }
        },
        clearFields() {
            this.isDone = true;
            this.text_area_fields.length = 0;
            this.batch_addition.data = [];
            this.listOfNotInModels = [];
            this.listOfNotInModels.length = 0;
            this.listOfDuplicates = [];
            this.listOfDuplicates.length = 0;
            this.duplicateBatch = [];
            this.duplicateBatch.length = 0;
            this.text_area_fields = [];
            this.hasError = false;
            this.duplicateFromBatch = [];
            this.duplicateFromBatch.length = 0;
            this.isDisabledButton = false;
            this.showError = false;
            this.showDuplicate = false;
            this.showDuplicateFromBatch = false;
            this.ShowNotInModels = [];
            this.ShowNotInModels.length = 0;
            this.ShowAllDuplicates = [];
            this.ShowAllDuplicates.length = 0;
            this.ShowAllDuplicateFromBatchs = [];
            this.ShowAllDuplicateFromBatchs.length = 0;
            this.showLoader = false;
            this.dataFromExcel = '';
            this.dataFromExcel2 = '';
            this.adjust_stock = 1;
            this.adjust_movement = 1;
        },
        addNewAdjustment() {
            if (this.uploadedFiles == null) {
                swal.fire({
                        title: "Please Upload Images or Files",
                        icon: "warning",
                    })
                    .then((confirm) => {
                        this.loading.state = false;
                    });
            }
            else if (!this.adjustmentItems[0].item_id == '' && !this.adjustmentItems[0].qty == '') {
                swal.fire({
                    title: 'Are you sure you want to Create Stock Adjustment?',
                    text: 'Make sure that all information are correct',
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',

                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        const data = new FormData();

                        const config = {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        };

                        if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                            let files = this.uploadedFiles.attachments;

                            for (let i = 0; i < _.compact(files).length; i++) {
                                data.append("files[]", files[i]);
                            }
                        }
                        data.append('adjustmentItems',JSON.stringify(this.adjustmentItems))

                        data.append('warehouse_id',this.warehouse_id)
                        data.append('requestor_id',this.requestor_id)
                        data.append('iif_ref_no',this.iif_ref_no)
                        data.append('requestor_name',this.requestor_name)
                        data.append('remarks',this.remarks)
                        data.append('created_by_id',this.created_by_id)
                        data.append('created_by_name',this.created_by_name)
                        data.append('adjust_stock',this.adjust_stock)
                        data.append('adjust_movement',this.adjust_movement)
                        
                        let payload = {
                            params: data,
                            config: config,
                        };
            
                        this.$store.dispatch('addNewStockAdjustment', payload)
                            .then(response => {
                                let data = response.data
                                if (data.error) {
                                    swal.fire(
                                        '',
                                        data.error,
                                        'question'
                                    )
                                    return false
                                }
                                this.clearFields()
                                this.$store.commit("DIALOG", false);
                                this.$store.commit('RESET_TABLE', true)
                                // this.$store.dispatch('getAllStockAdjustment');
                                this.counterBatch = 0
                                this.action = 'success'
                                swal.fire({
                                    icon: 'success',
                                    title: '',
                                    text: response.data.msg,
                                })
                            });
                    }
                });
            } else {
                swal.fire(
                    '',
                    'Please Fill Required Fields',
                    'warning'
                )

            }
        },
        showNotInModel() {
            let that = this;
            if (this.ShowNotInModels.length == 0) {
                if (this.showError == true) {
                    this.text_area_fields.filter(function (e, i) {
                        if (e.error == true) {
                            that.ShowNotInModels.push({ e, i });
                        }
                    });
                }
            }
        },
        showAllDuplicate() {
            let that = this;
            if (this.ShowAllDuplicates.length == 0) {
                if (this.showDuplicate == true) {
                    this.text_area_fields.filter(function (e, i) {
                        if (e.duplicate == true) {
                            that.ShowAllDuplicates.push({ e, i });
                        }
                    });
                }
            }
        },
        showAllDuplicateFromBatch() {
            let that = this;
            if (this.ShowAllDuplicateFromBatchs.length == 0) {
                if (this.showDuplicateFromBatch == true) {
                    this.text_area_fields.filter(function (e, i) {
                        if (e.duplicateBatch == true) {
                            that.ShowAllDuplicateFromBatchs.push({ e, i });
                        }
                    });
                }
            }
        },
        deleteNotInModel(deleteModel, i) {
            let that = this;
            let tempArr = [];
            if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

            }
            else {
                this.isDisabledButton = false;
            }
            let temp = that.text_area_fields.filter(e => {
                if (e.dataFromExcel == deleteModel) {

                }
                else {
                    this.listOfNotInModels.pop();
                    return e;
                }
            })
            this.ShowNotInModels.forEach((e, index) => {
                if (i != index) {
                    tempArr.push(e);
                }
            })
            this.ShowNotInModels = tempArr;
            that.text_area_fields = temp;
        },
        deleteDuplicatesInModel(deleteModel, i) {
            let that = this;
            let tempArr = [];
            if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

            }
            else {
                this.isDisabledButton = false;
            }
            let temp = that.text_area_fields.filter(e => {
                if (e.dataFromExcel == deleteModel) {

                }
                else {
                    this.listOfDuplicates.pop();
                    return e;
                }
            })
            this.ShowAllDuplicates.forEach((e, index) => {
                if (i != index) {
                    tempArr.push(e);
                }
            })

            this.ShowAllDuplicates = tempArr;
            that.text_area_fields = temp;
        },
        deleteDuplicatesFromBatchInModel(deleteModel, i) {
            let that = this;
            let tempArr = [];
            if (this.listOfNotInModels.length > 1 || this.listOfDuplicates.length > 1 || this.duplicateFromBatch.length > 1) {

            }
            else {

                this.isDisabledButton = false;
            }
            let temp = that.text_area_fields.filter(e => {
                if (e.dataFromExcel == deleteModel) {

                }
                else {
                    this.duplicateFromBatch.pop();
                    return e;
                }
            })
            this.ShowAllDuplicateFromBatchs.forEach((e, index) => {
                if (i != index) {
                    tempArr.push(e);
                }
            })
            this.ShowAllDuplicateFromBatchs = tempArr;
            that.text_area_fields = temp;
        },
        clearSingleAddition() {
            this.dataFromExcel = '';
            this.dataFromExcel2 = '';
        },
        viewAdjustment(id) {
            this.viewAdjustmentId = id
        },
        closeView(action = false) {
            this.viewAdjustmentId = 0
            if (action == 'updateStatusSuccess') {
                this.$store.dispatch('getAllStockAdjustment');
              
            }
        },
        closeAddEditDialog() {
            this.clearFields();
            this.$store.commit('DIALOG', false);
        },
        removeAdjustmentItemByIndex(index) {
            let temp = [];
            if (this.adjustmentItems.length > 1) {
                this.adjustmentItems.splice(index, 1);
                temp = this.adjustmentItems.map(e => { return e.item_model })
                this.lastBatchModel = temp;
            }
            else if (this.adjustmentItems.length == 1) {
                this.adjustmentItems.splice(index, 1);
                this.adjustmentItems.push({
                    item_id: '',
                    item_name: '',
                    item_uom: '',
                    item_type: '',
                    item_category: '',
                    qty: '',
                })
                this.isSingleAdded = false;
                this.counterBatch = 0;
            }
        },
        updateAdjustment() {
            if (!this.adjustmentItems[0].item_id == '') {
                swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to Update Stock Adjustment?',
                    text: 'Make sure that all information are correct',
                    showConfirmButton: true,
                    confirmButtonText: 'Confirm',
                    reverseButtons: true,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        const data = new FormData();

                        const config = {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        };
                        
                        if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                            let files = this.uploadedFiles.attachments;

                            for (let i = 0; i < _.compact(files).length; i++) {
                                data.append("files[]", files[i]);
                            }
                        }
                        data.append('adjustmentItems',JSON.stringify(this.adjustmentItems))

                        data.append('warehouse_id',this.warehouse_id)
                        data.append('requestor_id',this.requestor_id)
                        data.append('iif_ref_no',this.iif_ref_no)
                        data.append('requestor_name',this.requestor_name)
                        data.append('remarks',this.remarks)
                        data.append('adjustment_id',this.adjustment_id)
                        data.append('adjust_stock',this.adjust_stock)
                        data.append('adjust_movement',this.adjust_movement)
                        
                        let payload = {
                            params: data,
                            config: config,
                        };

                        this.$store.dispatch('updateAdjustment', payload).then(response => {
                            let data = response.data
                            if (data.error) {
                                swal.fire(
                                    '',
                                    data.error,
                                    'warning'
                                )
                                return false
                            }
                            this.clearFields()
                            this.$store.commit("DIALOG", false);
                            this.$store.commit('RESET_TABLE', true)
                            // this.$store.dispatch('getAllStockAdjustment');
                            this.action = 'success'
                            this.counterBatch = 0
                            swal.fire(
                                '',
                                response.data.msg,
                                'success'
                            )

                        });
                    }
                });
            } else {
                swal.fire('', 'Please Fill Required Fields', 'warning');
            }
        },
        uploadedData(data) {
            if (!data) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = null;
            this.$refs.fileUpload.reset();
        },
        toggleAdjustStock() {
            this.adjust_stock = !this.adjust_stock ? 0 : 1;
        },
        toggleAdjustMovement() {
            this.adjust_movement = !this.adjust_movement ? 0 : 1;
        },
    },
    watch: {
        DIALOGS(val) {
            if (this.ACTION == 'Submit') {
                this.adjustmentItems = [];
                this.warehouse_id = ''
                this.remarks = ''
                this.requestor_id = ''
                this.iif_ref_no = ''
                this.requestor_name = ''
                if (this.adjustmentItems.length == 0) {
                    this.addAdjustmentItem();
                }
            }
        },
        GET_CURRENT_USER:{
            handler(val){
                this.created_by_id = val[0].value
                this.requestor_id = val[0].value
                this.created_by_name = val[0].text
            }
        },
        'batch_addition.dialog': {
            handler(val){
                if (val == false) {
                    this.loader = false
                }
            },
            immediate:true
        },
        adjust_stock: function(val) {
            if (val === 0 && this.adjust_movement === 0) {
                this.adjust_movement = 1;
            }
        },
        adjust_movement: function(val) {
            if (val === 0 && this.adjust_stock === 0) {
                this.adjust_stock = 1; 
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "adjustment"){
                        this.userAccess.adjustment = true;
                    }
                });
                }
            }
        },
    }
}
</script>
<style scoped>
    .centered-input >>> input {
        text-align: center
    }
</style>
