<template>
    <v-container class="bg-bluish-gray">
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>

        <v-app id="my_vue_app" class="pa-2">
            <v-row no-gutters>
                <v-col sm="12" md="4">
                    <v-autocomplete
                        v-model="filters.item_ids"
                        :items="dropdowns.items.list"
                        class="d-inline-block small w-100"
                        label="Model"
                        placeholder="Select Model"
                        dense
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="6">
                    <v-autocomplete
                        v-model="filters.item_ids"
                        :items="dropdowns.items.list"
                        class="d-inline-block small w-100"
                        label="Name"
                        placeholder="Select Name"
                        item-text="name"
                        dense
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="12" md="2" class="d-flex justify-content-end" style="gap: 5px">
                    <v-btn @click="approveOverride()">Excel</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.item_type_ids"
                        :items="dropdowns.item_types.list"
                        :disabled="$store.state.Items.is_sp"
                        class="d-inline-block small w-100"
                        label="Type"
                        placeholder="Select Disc Type"
                        dense
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        auto-select-first
                        hide-details
                        clearable
                        @blur="getItems()"
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="4">
                    <v-autocomplete
                        v-model="filters.item_category_ids"
                        :items="dropdowns.item_categories.list"
                        class="d-inline-block small w-100"
                        label="Category"
                        placeholder="Select Item Category"
                        dense
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        auto-select-first
                        hide-details
                        clearable
                        @blur="getItems()"
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.item_brand_ids"
                        :items="dropdowns.item_brands.list"
                        class="d-inline-block small w-100"
                        label="Brand"
                        placeholder="Select Brand"
                        dense
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        auto-select-first
                        hide-details
                        clearable
                        @blur="getItems()"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col sm="12" md="12" lg="12" xl="12" class="d-flex flex-row" style="gap: 15px">
                    <v-checkbox
                        v-model="filters.is_pricelist"
                        :true-value="1"
                        :false-value="0"
                        label="Pricelist"
                        class="m-0 d-flex align-items-center"
                        hide-details
                    ></v-checkbox>
                    <v-checkbox
                        v-if="filters.is_pricelist == '0'"
                        v-model="filters.has_images"
                        :true-value="1"
                        :false-value="0"
                        label="Images"
                        class="m-0 d-flex align-items-center"
                        hide-details
                    ></v-checkbox>
                    <v-checkbox
                        v-model="filters.show_stocks"
                        :true-value="1"
                        :false-value="0"
                        label="Show Stocks"
                        class="m-0 d-flex align-items-center"
                        hide-details
                    ></v-checkbox>
                    <!-- <v-checkbox
                        v-if="GET_USER_COSTING"
                        v-model="filters.show_cost"
                        :true-value="1"
                        :false-value="0"
                        label="Show Cost"
                        class="m-0 d-flex align-items-center"
                        hide-details
                    ></v-checkbox> -->
                    <v-checkbox
                        v-if="userAccess.sp_price"
                        v-model="filters.sp_checkbox"
                        :true-value="1"
                        :false-value="0"
                        label="Include SP"
                        class="m-0 d-flex align-items-center"
                        hide-details
                    ></v-checkbox>
                </v-col>
            </v-row>
            <v-divider class="ma-1"></v-divider>
            <v-row no-gutters class="d-flex align-items-center" style="gap: 20px">
                <v-col sm="12" md="4">
                    <v-autocomplete
                        v-model="filters.item_criteria"
                        :items="dropdowns.item_criteria.list"
                        label="Criteria"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col lg="3">
                    <v-autocomplete
                        v-model="filters.item_status"
                        :items="dropdowns.item_status.list"
                        label="Item Status"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                </v-col>
                <v-col sm="12" md="3" v-if="filters.item_criteria == 1">
                    <v-autocomplete
                        v-model="filters.date_option"
                        :items="dropdowns.date_option.list"
                        class="d-inline-block w-100 small"
                        label="New Items Container Received Date"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                        @input="showDatePicker"
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2" v-if="toggles.custom_date && filters.item_criteria == 1">
                    <v-menu
                        v-model="toggles.date_from"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date_from"
                                v-on="on"
                                label="Date From"
                                append-icon="mdi-calendar-clock"
                                readonly
                                dense
                                hide-details
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date_from" @input="toggles.date_from = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col sm="12" md="2" v-if="toggles.custom_date && filters.item_criteria == 1">
                    <v-menu
                        v-model="toggles.date_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="filters.date_to"
                                v-on="on"
                                label="Date To"
                                append-icon="mdi-calendar-clock"
                                readonly
                                dense
                                hide-details
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date_to" @input="toggles.date_to = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <ConfirmOverrideComponent
                :approve_override_dialog="override.override_dialog"
                :departments_allowed="override.override_departments_allowed"
                :heading="override.heading"
                @closeConfirmOverride="closeConfirmOverride"
            ></ConfirmOverrideComponent>
        </v-app>
    </v-container>
</template>

<script>
import ConfirmOverrideComponent from '@/views/main/Utils/ConfirmOverrideComponent.vue'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import moment from 'moment'
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver');

export default {
    components: {
        ConfirmOverrideComponent,
    },
    props: {
        is_inactive_items:{
            type:Boolean,
            default:()=>false
        },
        is_sp:{
            type:Boolean,
            default:()=>false
        },
        addSp:{
            type:Boolean,
            default:()=>false
        },
    },
    data() {
        return {
            loaded: true,
            defaults: {
                date_option: 5,
                date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                item_ids: [],
                item_type_ids: [1, 2],
                item_category_ids: [],
                item_criteria: 0,
                active: 1,
                is_pricelist: 1,
                has_images: 1,
                show_stocks: 0,
            },
            toggles: {
                date_from: false,
                date_to: false,
                custom_date: true,
            },
            loading: {
                items: true,
                item_types: true,
                item_categories: true,
                item_brands: true,
            },
            filters: {
                date_option: 5,
                date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                item_ids: [],
                item_type_ids: [1, 2],
                item_category_ids: [],
                item_brand_ids: [],
                item_criteria: 0,
                active: 1,
                is_pricelist: 1,
                has_images: 1,
                show_stocks: 0,
                show_cost: 0,
                item_status:1,
                sp_checkbox: 0,
            },
            dropdowns: {
                items: {
                    list: [],
                },
                item_types: {
                    list: [],
                },
                item_categories: {
                    list: [],
                },
                item_brands: {
                    list: [],
                },
                date_option: {
                    list: [
                        { value: 1, text: 'Past Week' },
                        { value: 2, text: 'Past Month' },
                        { value: 3, text: 'Past 2 Months' },
                        { value: 4, text: 'Past Quarter' },
                        { value: 5, text: 'Custom' },
                    ],
                },
                item_criteria: {
                    list: [
                        { value: 0, text: 'Include All Items' },
                        { value: 1, text: 'Show Only New Items' },
                        { value: 2, text: 'Exclude Items with no Received Container yet' },
                    ],
                },
                item_status: {
                    list: [
                        { value: 0, text: 'Inactive' },
                        { value: 1, text: 'Active' },
                        { value: -1, text: 'Phased out' },
                    ],
                },
            },
            override: {
                heading: 'Item List',
                override_dialog: false,
                override_departments_allowed: ['SM', 'OP', 'EX'],
                override_authenticated: false,
                override_confirm: false,
                errors: [],
            },

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                sp_price: false,
                cost: false,
            },

            dateToday: null,
        }
    },
    async mounted() {
        await this.loadDropdowns()
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    },
    computed: {
        ...mapGetters([
            'GET_ITEMS',
            'GET_ITEM_CATEGORIES',
            'GET_ITEM_TYPES',
            'GET_ITEM_BRAND',
            'GET_EXPORT_ITEM_MASTER_TO_EXCEL',
            'USER_ACCESS',
            'GET_USER_COSTING',
        ]),
        categoryText(){
            if(this.filters.item_category_ids.length == 1){
                let text = this.dropdowns.item_categories.list.find(x=> x.value == this.filters.item_category_ids[0]).text
                text = " [" + text +"] "
                return text
            } else{
                return null
            }
        }
    },
    methods: {
        async loadDropdowns() {
            await this.$store.dispatch('getItemTypes')
            await this.$store.dispatch('getItemCategories')
            await this.$store.dispatch('getItemBrand')
            this.filterItemTypes()
            this.getItems()
        },
        getItems() {
            this.$store.dispatch('getItems', {
                type_ids: this.filters.item_type_ids,
                category_ids: this.filters.item_category_ids,
                brand_ids: this.filters.item_brand_ids,
            });
        },
        filterItemTypes() {
            if (this.is_sp || this.$store.state.Items.is_sp) {
                this.filters.item_type_ids = [3]
            } else {
                this.filters.item_type_ids = [1, 2]
            }
        },
        showDatePicker() {
            this.filters.date_option == 5 ? this.toggles.custom_date = true : this.toggles.custom_date = false
        },
        resetFields() {
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
        async exportItemMasterToExcel() {
            let that = this
            let error = false

            that.loaded = false

            let date = new Date();
            this.dateToday = date.toISOString().split('T')[0];
            const workbook = new ExcelJS.Workbook();

            const rawDataSheetName = 'Raw Data'

            switch (this.filters.date_option) {
                case 1:
                    this.filters.date_from = moment().subtract(7, 'd').format('YYYY-MM-DD')
                    this.filters.date_to = moment().format('YYYY-MM-DD')
                    break
                case 2:
                    this.filters.date_from = moment().subtract(1, 'months').format('YYYY-MM-DD')
                    this.filters.date_to = moment().format('YYYY-MM-DD')
                    break
                case 3:
                    this.filters.date_from = moment().subtract(2, 'months').format('YYYY-MM-DD')
                    this.filters.date_to = moment().format('YYYY-MM-DD')
                    break
                case 4:
                    this.filters.date_from = moment().subtract(3, 'months').format('YYYY-MM-DD')
                    this.filters.date_to = moment().format('YYYY-MM-DD')
                    break
            }

            let columns = [
                { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'MODEL', key: 'model', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'DESCRIPTION', width: 57, key: 'description', style: { alignment: { vertical: 'top', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                { header: 'SRP', key: 'selling_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }, pricelist: true, raw_data: true },
                { header: 'COST (USD)', key: 'cost_in_foreign', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }, pricelist: true, raw_data: true },
                { header: 'SP', key: 'sp_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }, pricelist: this.filters.sp_checkbox, raw_data: this.filters.sp_checkbox},
                { header: 'PART#', key: 'part_num', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'MOQ', key: 'moq', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                { header: 'MB QTY', key: 'master_box_qty', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                { header: 'SB QTY', key: 'small_box_qty', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                { header: 'ACTIVE', key: 'active', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'NEW MODELS', key: 'new_model', width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'FOC', key: 'foc', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'TOTAL STOCKS', key: 'total_stocks', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: true, raw_data: true },
                { header: 'REMARKS', key: 'remarks', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'DISCONTINUE ORDER DATE', key: 'discontinue_order_date', width: 16, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                { header: 'LIMIT ORDER', key: 'limit_order', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
            ]
            let filtered_columns = columns

            if (this.filters.show_stocks == 0) {
                filtered_columns = filtered_columns.filter(f => {
                    return f.key !== 'total_stocks'
                })
            }

            if (this.filters.show_cost == 0) {
                filtered_columns = filtered_columns.filter(f => {
                    return f.key !== 'cost_in_foreign'
                })
            }

            let payload = {
                filters: this.filters,
            }

            await this.$store.dispatch('exportItemMasterToExcel', payload).then(async response => {
                let worksheet = workbook.addWorksheet(rawDataSheetName)
                let data = this.filters.is_pricelist == 1 ? this.GET_EXPORT_ITEM_MASTER_TO_EXCEL : this.GET_EXPORT_ITEM_MASTER_TO_EXCEL.flatMap(x => x.items)
                let setWorksheetColumns = (worksheet) => {
                    worksheet.columns = filtered_columns.filter(this.filters.is_pricelist == 1 ? f => f.pricelist : f => f.raw_data)
                }
                let borderStyles = {
                    top: { style: 'medium', color: { argb: '00000000' } },
                    left: { style: 'medium', color: { argb: '00000000' } },
                    bottom: { style: 'medium', color: { argb: '00000000' } },
                    right: { style: 'medium', color: { argb: '00000000' } }
                }

                if (this.filters.is_pricelist == 1) {
                    for await (let category of data) {
                        let worksheet = workbook.addWorksheet(category.name, {
                            pageSetup: {
                                fitToPage: true,
                                fitToWidth: 1,
                                fitToHeight: 0,
                                margins: {
                                    left: 0.5, right: 0.25,
                                    top: 0.75, bottom: 0.75,
                                    header: 0.3, footer: 0.3
                                },
                                printTitlesRow: '1:1',
                            }
                        })
                        setWorksheetColumns(worksheet);

                        let pos = 1;
                        for await (let item of category.items) {
                            item.selling_price = parseFloat(item.selling_price);
                            item.sp_price = parseFloat(item.sp_price);
                            item.total_stocks = parseInt(item.total_stocks)
                            item.limit_order = parseInt(item.total_stocks)

                            worksheet.addRow(item);

                            if (this.filters.has_images === 1) {
                                await this.addImageToWorksheet(workbook, worksheet, item, pos);
                            }

                            pos++;
                        }

                        worksheet.getRow(1).height = 30;
                        worksheet.getRow(1).font = {
                            bold: true,
                        }
                        worksheet.getRow(1).alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                            wrapText: true
                        }
                    }

                    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                            cell.border = borderStyles;
                        });
                    });

                    worksheet.getRow(`0${1}`).font = { bold: true };
                } else {
                    let worksheet = workbook.getWorksheet(rawDataSheetName);
                    setWorksheetColumns(worksheet);

                    let pos = 1;
                    for (let item of data) {
                        item.selling_price = parseFloat(item.selling_price);
                        item.sp_price = parseFloat(item.sp_price);
                        item.total_stocks = parseInt(item.total_stocks)

                        worksheet.addRow(item);

                        if (this.filters.has_images === 1) {
                            await this.addImageToWorksheet(workbook, worksheet, item, pos);
                        }
                        pos++;
                    }

                    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                            cell.border = borderStyles;
                        });
                    });

                    worksheet.getRow(`0${1}`).font = { bold: true };

                    if (this.filters.has_images != 1) {
                        worksheet.spliceColumns(1, 1)
                    }
                }
            }).catch(e => {
                swal.fire('Error !', '', 'error')
                console.log(e)
                that.loaded = true
                error = true
            })

            if (this.filters.is_pricelist == 1) {
                workbook.removeWorksheet(rawDataSheetName)
                workbook.eachSheet((ws) => {
                    if (ws.rowCount == 1) {
                        workbook.removeWorksheet(ws.name)
                    }
                })
            }

            if (!error) {
                workbook.xlsx.writeBuffer().then((data) => {
                    that.loaded = true

                    const file_name = `Items ${this.filters.is_pricelist == 1 ? 'PriceList' : 'RawData'}${this.categoryText != null ? this.categoryText : ' '}${this.dateToday}.xlsx`
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    FileSaver.saveAs(blob, file_name);
                });
            }

            this.$store.commit('SAMPLE', '');
        },
        async addImageToWorksheet(workbook, worksheet, item, pos){
            let height = 80;
            let width = 60;
            let aspectRatio = null;

            worksheet.getRow(pos + 1).height = height;

            let image = await new Promise((resolve) => {
                let img = new Image();
                img.src = item.base64img;
                img.onload = () => resolve(img);
            });

            aspectRatio = image.naturalWidth / image.naturalHeight;
            let imageHeight = aspectRatio * width;

            let finalImage = workbook.addImage({
                base64: item.base64img,
                extension: 'jpg',
            });

            worksheet.addImage(finalImage, {
                tl: { col: 0.1, row: pos + 0.1 },
                br: { col: 1, row: worksheet.rowCount },
                ext: { width: width, height: height },
                editAs: 'oneCell',
            });

            // if (item.base64NewItemTag !== null) {
            //     let finalImageNewItem = workbook.addImage({
            //         base64: item.base64NewItemTag,
            //         extension: 'jpg',
            //     });

            //     worksheet.addImage(finalImageNewItem, {
            //         tl: { col: 0, row: pos },
            //         ext: { width: 50, height: 50 },
            //         editAs: 'undefined',
            //     });
            // }

            let row = worksheet.getRow(worksheet.rowCount);
            row.width = width
            if (imageHeight > worksheet.getRow(pos + 1).height) {
                worksheet.getRow(pos + 1).height = imageHeight;
            }
        },
        approveOverride() {
            if (this.filters.show_stocks && this.filters.show_cost) {
                this.override.heading = 'Item List w/ Stocks and Cost'
            } else if (this.filters.show_stocks && !this.filters.show_cost) {
                this.override.heading = 'Item List w/ Stocks'
            } else if (!this.filters.show_stocks && this.filters.show_cost) {
                this.override.heading = 'Item List w/ Cost'
            }

            if (this.filters.show_stocks || this.filters.show_cost) {
                this.override.heading
                this.override.override_dialog = true
            } else {
                this.exportItemMasterToExcel()
            }
        },
        async closeConfirmOverride(user_id, authenticated = false) {
            if (authenticated) {
                document.activeElement.blur();
                this.override.override_dialog = false
                this.override.override_confirm = true
                this.exportItemMasterToExcel()
            } else {
                this.override.override_dialog = false
            }
        },
    },
    watch: {
        'filters.is_pricelist': {
            handler(val) {
                if (val == 1) {
                    this.filters.has_images = 1
                }
            },
        },
        'GET_ITEMS': {
            handler(val) {
                this.dropdowns.items.list = val
                this.loading.items = false
            },
        },
        'GET_ITEM_TYPES': {
            handler(val) {
                this.dropdowns.item_types.list = val
                this.loading.item_types = false
            }
        },
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.dropdowns.item_categories.list = val
                this.loading.item_categories = false
            }
        },
        'GET_ITEM_BRAND': {
            handler(val) {
                this.dropdowns.item_brands.list = val
                this.loading.item_brands = false
            }
        },
        USER_ACCESS: {
            handler(val) {
                val.forEach((e) => {
                    if (e.actions_code == "view") {
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "edit") {
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "delete") {
                        this.userAccess.delete = true;
                    }
                    if (e.actions_code == "show_special_price") {
                        this.userAccess.sp_price = true;
                    }
                });
            },
        },

    }
};
</script>

