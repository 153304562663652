<template>
  <v-container>
      <ComponentHeader
        :button="true"
        :title="'Top Performing Concept Store'"
        :type="'Sales'"
      ></ComponentHeader>
      <ReportBodyComponent
        totals
      >
      <template #first-row>
        <v-card-text>
            <v-row>
                <v-col lg="3">
                  <v-checkbox
                    v-model="byDateRange"
                    label="By Date Range"
                    color="white"
                    hide-details
                    @click="ByDateRangeCheckBox(byDateRange)"
                  ></v-checkbox>
                </v-col>
                <v-col class="col-sm-6 col-md-3" id="date_from" v-if="byDateRange">
                    <label class="float-left control-label col-form-label">Date From</label>
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </v-col>
                <v-col class="col-sm-6 col-md-3" id="date_to" v-if="byDateRange">
                    <label class="float-left control-label col-form-label">Date To</label>
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </v-col>
            </v-row>
        </v-card-text>
    </template>
    <template #second-row>
      <v-card-text>
          <v-row>
              <v-col lg="2">
                  <v-checkbox
                    v-model="byPeriod"
                    label="By Period"
                    color="white"
                    hide-details
                    @click="byPeriodCheckBox(byPeriod)"
                ></v-checkbox>
              </v-col>
              <v-col  class="col-sm-6 col-md-4" v-if="byPeriod">
                  <label
                    for="periodValue"
                    class="float-left control-label col-form-label"
                  ></label>
                  <v-autocomplete
                    dense
                    outlined
                    :items="period_list"
                    v-model="periodValue"
                    item-value="value"
                    item-text="text"
                    placeholder="Select Period"
                    class="d-inline-block small"
                    @change="periodValue()"
                  >
                  </v-autocomplete>
              </v-col>
          </v-row>
      </v-card-text>
     </template>
      </ReportBodyComponent>
    <!-- ------------------------------------------------------------------------------------- -->
  </v-container>
</template>

<script>
import ComponentHeader from "@/views/main/layouts/Reports/ReportsHeaderComponent.vue";
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import moment from "moment";
import swal from "sweetalert2";
const ExcelJS = require("exceljs");
const FileSaver = require("file-saver");

export default {
  data() {
    return {
      errors: [],
      customer_id: "",
      customers_list: [],
      periodValue: "",
      period_list: [
        { text: "Monthly", value: "monthly" },
        { text: "Quarterly", value: "quarterly" },
        { text: "Semi-Annual", value: "semiAnnual" },
        { text: "Annual", value: "annual" },
      ],

      yearList: [],
      dataList: [],
      loaded: true,
      load: false,
      date_from: moment().clone().startOf("month").format("YYYY-MM-DD"),
      date_to: moment().clone().endOf("month").format("YYYY-MM-DD"),
      net_sales_total_amount: "",
      gross_sales_total_amount: "",
      adjustments_total_amount: "",
      customer_count: 0,
      byDateRange: true,
      byPeriod: false,
      dataLoad: false,
      row: null,
      menu2: false,
      menu3: false,
      yearValue: moment().year(),
      showLineChart: false,

      showdateRangeTable: true,
      monthlyTable: false,
      quarterlyTable: false,
      semiAnnualTable: false,

      annual: false,
      options: {},
      totalAmount: [],
      totalAmountAnnual: [],
      lineChart: false,
      pieChart: false,

      categoriesXaxis: [],

      totalPie: [],

      pieChartDataAnnual: {
        series: [],
        plotOptions: {
          pie: {
            customScale: 1,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
        },
        legend: {
          fontSize: "18px",
        },

        tooltip: {
          style: "14px",
          y: {
            formatter: (val) => {
              if (
                val !== "" ||
                val !== undefined ||
                val !== 0 ||
                val !== "0" ||
                val !== null
              ) {
                return val
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return 0;
              }
            },
          },
        },
        labels: [],
      },
      pieChartData: {
        series: [],
        plotOptions: {
          pie: {
            customScale: 1,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
        },
        legend: {
          fontSize: "18px",
        },

        tooltip: {
          style: "14px",
          y: {
            formatter: (val) => {
              if (
                val !== "" ||
                val !== undefined ||
                val !== 0 ||
                val !== "0" ||
                val !== null
              ) {
                return val
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return 0;
              }
            },
          },
        },
        labels: [],
      },
      showAnuualChart: false,
      byDateRangeShow: false,
      byPeriodShow: false,
      lineChartData: {
        series: [
          {
            name: "",
            data: [],
          },
        ],
        xaxis: {
          categories: [],
        },
        legend: {
          formatter: function (seriesName, opts) {
            return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              if (
                val !== "" ||
                val !== undefined ||
                val !== 0 ||
                val !== "0" ||
                val !== null
              ) {
                return val
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
              } else {
                return 0;
              }
            },
          },
        },
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
          },
          title: {
            text: "Page Statistics",
            align: "left",
          },
          legend: {
            tooltipHoverFormatter: function (val, opts) {
              return (
                val +
                " - " +
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                ""
              );
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6,
            },
          },
          xaxis: {
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM 'yy",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            categories: [],
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            ],
          },
          // grid: {
          //   "#f1f1f1",
          // },
        },
      },
      dateRangeData: [],
      dataOption: "byDateRange",

      itemsPerPageOptions: [5, 10, 20, 50, 100],
      itemsPerPage: 10,
      search: "",
      awaitingSearch: false,
      headers: [],
      headers_byDateRange: [
        { text: "Customer", align: "left", value: "customer_name" },
        {
          text: "Total Amount",
          align: "right",
          value: "total_amount_customer",
        },
      ],
      headers_byPeriod_monthly: [
        { text: "Customer", align: "left", value: "customer_name" },
        { text: "January", align: "right", value: "monthly_january" },
        { text: "February", align: "right", value: "monthly_february" },
        { text: "March", align: "right", value: "monthly_march" },
        { text: "April", align: "right", value: "monthly_april" },
        { text: "May", align: "right", value: "monthly_may" },
        { text: "June", align: "right", value: "monthly_june" },
        { text: "July", align: "right", value: "monthly_july" },
        { text: "August", align: "right", value: "monthly_august" },
        { text: "September", align: "right", value: "monthly_september" },
        { text: "October", align: "right", value: "monthly_october" },
        { text: "November", align: "right", value: "monthly_november" },
        { text: "December", align: "right", value: "monthly_december" },
        { text: "Total Amount", align: "right", value: "monthly_total_amount" },
      ],
      headers_byPeriod_quarterly: [
        { text: "Customer", align: "left", value: "customer_name" },
        {
          text: "January-March",
          align: "right",
          value: "quarterly_january_march",
        },
        { text: "April-June", align: "right", value: "quarterly_april_june" },
        {
          text: "July-September",
          align: "right",
          value: "quarterly_july_september",
        },
        {
          text: "October-December",
          align: "right",
          value: "quarterly_october_december",
        },
        {
          text: "Total Amount",
          align: "right",
          value: "quarterly_total_amount",
        },
      ],
      headers_byPeriod_semiAnnual: [
        { text: "Customer", align: "left", value: "customer_name" },
        {
          text: "January-June",
          align: "right",
          value: "semiAnnual_january_june",
        },
        {
          text: "July-December",
          align: "right",
          value: "semiAnnual_july_december",
        },
        { text: "Amount", align: "right", value: "semiAnnual_total_amount" },
      ],
      headers_byPeriod_annual: [
        { text: "Customer", align: "left", value: "customer_name" },
        { text: "Total Amount", align: "right", value: "annual_total_amount" },
      ],
      initialLoad: false,
    };
  },

  computed: {
    years() {
      const year = this.yearValue;
      this.yearList = Array.from(
        { length: year - 1999 },
        (value, index) => year - index
      );
    },
  },

  components: {
    ComponentHeader,
    ReportBodyComponent
  },

  mounted: function () {
    this.years;
  },

  methods: {
    getTopPerformingConceptStore() {
      this.loaded = false;
      if (this.periodValue == "monthly" && this.byPeriod) {
        this.monthlyTable = true;
        this.showdateRangeTable = false;
        this.quarterlyTable = false;
        this.semiAnnualTable = false;
        this.showAnuualChart = false;
      } else if (this.periodValue == "quarterly" && this.byPeriod) {
        this.quarterlyTable = true;
        this.showdateRangeTable = false;
        this.monthlyTable = false;
        this.semiAnnualTable = false;
        this.showAnuualChart = false;
      } else if (this.periodValue == "semiAnnual" && this.byPeriod) {
        this.semiAnnualTable = true;
        this.showdateRangeTable = false;
        this.quarterlyTable = false;
        this.monthlyTable = false;
        this.showAnuualChart = false;
      } else if (this.periodValue == "annual" && this.byPeriod) {
        this.showdateRangeTable = false;
        this.quarterlyTable = false;
        this.monthlyTable = false;
        this.semiAnnualTable = false;
        this.showLineChart = false;
        this.showAnuualChart = true;
      } else {
        this.showdateRangeTable = false;
        this.quarterlyTable = false;
        this.monthlyTable = false;
        this.semiAnnualTable = false;
        this.showLineChart = false;
        this.showAnuualChart = false;
      }

      this.periodValue == ""
        ? (this.periodValue = "monthly")
        : (this.periodValue = this.periodValue);

      let data = {
        date_from: this.date_from,
        date_to: this.date_to,
        periodValue: this.periodValue,
        yearValue: this.yearValue,
        dataOption: this.dataOption,
        byPeriod: this.byPeriod,
        byDateRange: this.byDateRange,
        filename: "Sales By Classifications",
        url: "top-performing-concept-store",
      };
      this.$store.dispatch("reports", data).then((response) => {
        if (response.status == 200) {
          this.net_sales_total_amount = response.data.net_sales_total_amount;
          this.gross_sales_total_amount =
            response.data.gross_sales_total_amount;
          this.adjustments_total_amount =
            response.data.adjustments_total_amount;
          this.customer_count = response.data.customer_count;

          if (this.customer_count != 0) {
            this.loaded = true;
            this.load = true;

            if (this.dataOption == "byDateRange") {
              this.totalAmount = [];
              this.dateRangeData = response.data.dataList;
              this.net_sales_total_amount =
                response.data.net_sales_total_amount;
              this.gross_sales_total_amount =
                response.data.gross_sales_total_amount;
              this.adjustments_total_amount =
                response.data.adjustments_total_amount;

              let pieNames = [];

              this.dateRangeData.forEach((e) => {
                this.totalAmount.push(parseFloat(e.total_amount_customer));
                pieNames.push(e.customer_name);
              });
              this.pieChartData = {
                labels: pieNames,
                colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
                ],
                fill: {
                  colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
                  ],
                },
                markers: {
                  colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
                  ],
                },
                tooltip: {
                  style: "14px",
                  y: {
                    formatter: (val) => {
                      if (
                        val !== "" ||
                        val !== undefined ||
                        val !== 0 ||
                        val !== "0" ||
                        val !== null
                      ) {
                        return val
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                      } else {
                        return 0;
                      }
                    },
                  },
                },
              };

              if (this.byDateRange && this.totalAmount.length > 0) {
                this.byDateRangeShow = true;
                this.showdateRangeTable = true;
              }
            } else {
              // this.fillingLineChartData(response.data.customerSalesRecord);
            }
          } else {
            swal.fire("No data", "Please check the date range", "error");
            this.load = false;
            this.loaded = true;
          }
        } else {
          swal.fire("An error occured", "Please check you data", "error");
        }
      });
    },

    // fillingLineChartData(data) {
    //   if (this.periodValue == "monthly") {
    //     this.showLineChart = true;
    //     let salesMonthly = [];
    //     data.forEach((e) => {
    //       let monthlyAmount = [];
    //       e.monthlySales.forEach((amount) => {
    //         monthlyAmount.push(amount[0].total_amount);
    //       });
    //       let obj = {
    //         name: e.name,
    //         data: monthlyAmount,
    //       };
    //       salesMonthly.push(obj);
    //     });
    //     this.lineChartData = {
    //       series: salesMonthly,
    //       xaxis: {
    //         categories: [
    //           "Jan",
    //           "feb",
    //           "March",
    //           "April",
    //           "May",
    //           "June",
    //           "July",
    //           "Aug",
    //           "Sept",
    //           "Oct",
    //           "Nov",
    //           "Dec",
    //         ],
    //       },
    //       yaxis: {
    //         labels: {
    //           formatter: (val) => {
    //             if (
    //               val !== "" ||
    //               val !== undefined ||
    //               val !== 0 ||
    //               val !== "0" ||
    //               val !== null
    //             ) {
    //               return val
    //                 .toFixed(2)
    //                 .toString()
    //                 .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    //             } else {
    //               return 0;
    //             }
    //           },
    //         },
    //       },
    //       colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
    //       ],
    //       fill: {
    //         colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
    //         ],
    //       },
    //       markers: {
    //         colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
    //         ],
    //       },
    //       tooltip: {
    //         style: "14px",
    //         y: {
    //           formatter: (val) => {
    //             if (
    //               val !== "" ||
    //               val !== undefined ||
    //               val !== 0 ||
    //               val !== "0" ||
    //               val !== null
    //             ) {
    //               return val
    //                 .toFixed(2)
    //                 .toString()
    //                 .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    //             } else {
    //               return 0;
    //             }
    //           },
    //         },
    //       },
    //     };
    //     this.monthlyDataTable(data, "monthly");
    //   } else if (this.periodValue == "quarterly") {
    //     this.showLineChart = true;
    //     let salesQuarterly = [];
    //     data.forEach((e) => {
    //       let quarterlyAmount = [];
    //       e.quarterlyAmount.forEach((amount) => {
    //         quarterlyAmount.push(amount);
    //       });
    //       let obj = {
    //         name: e.name,
    //         data: quarterlyAmount,
    //       };
    //       salesQuarterly.push(obj);
    //     });
    //     this.lineChartData = {
    //       series: salesQuarterly,
    //       xaxis: {
    //         categories: ["Jan-March", "April-June", "July-Sept", "Oct-Dec"],
    //       },
    //     };
    //     this.monthlyDataTable(data, "quarterly");
    //   } else if (this.periodValue == "semiAnnual") {
    //     this.showLineChart = true;
    //     let salesSemiAnual = [];
    //     data.forEach((e) => {
    //       let semiAnualAmount = [];
    //       e.semiAnualAmount.forEach((amount) => {
    //         semiAnualAmount.push(amount);
    //       });
    //       let obj = {
    //         name: e.name,
    //         data: semiAnualAmount,
    //       };
    //       salesSemiAnual.push(obj);
    //     });
    //     this.lineChartData = {
    //       series: salesSemiAnual,
    //       xaxis: {
    //         categories: ["Jan", "Dec"],
    //       },
    //     };
    //     this.monthlyDataTable(data, "semiAnnual");
    //   } else if (this.periodValue == "annual") {
    //     this.monthlyDataTable(data, "annual");
    //   }
    // },

    monthlyDataTable(data, type) {
      if (type == "monthly") {
        this.monthlyTable = true;
        let dataTableRecord = [];

        data.forEach((e) => {
          let obj = {
            customer_name: e.name,
            monthly_january: e.monthlySales[0][0].total_amount,
            monthly_february: e.monthlySales[1][0].total_amount,
            monthly_march: e.monthlySales[2][0].total_amount,
            monthly_april: e.monthlySales[3][0].total_amount,
            monthly_may: e.monthlySales[4][0].total_amount,
            monthly_june: e.monthlySales[5][0].total_amount,
            monthly_july: e.monthlySales[6][0].total_amount,
            monthly_august: e.monthlySales[7][0].total_amount,
            monthly_september: e.monthlySales[8][0].total_amount,
            monthly_october: e.monthlySales[9][0].total_amount,
            monthly_november: e.monthlySales[10][0].total_amount,
            monthly_december: e.monthlySales[11][0].total_amount,
            monthly_total_amount: e.annual[0],
          };
          dataTableRecord.push(obj);
        });

        this.dataList = dataTableRecord;
      } else if (type == "quarterly") {
        let dataTableRecord = [];

        data.forEach((e) => {
          let obj = {
            customer_name: e.name,
            quarterly_january_march: e.quarterlyAmount[0],
            quarterly_april_june: e.quarterlyAmount[1],
            quarterly_july_september: e.quarterlyAmount[2],
            quarterly_october_december: e.quarterlyAmount[3],
            quarterly_total_amount: e.annual,
          };
          dataTableRecord.push(obj);
        });
        this.dataList = dataTableRecord;
      } else if (type == "semiAnnual") {
        let dataTableRecord = [];

        data.forEach((e) => {
          let obj = {
            customer_name: e.name,
            semiAnnual_january_june: e.semiAnualAmount[0],
            semiAnnual_july_december: e.semiAnualAmount[1],
            semiAnnual_total_amount: e.annual[0],
          };
          dataTableRecord.push(obj);
        });

        this.dataList = dataTableRecord;
      } else if (type == "annual") {
        let customerNames = [];
        let customerTotalSales = [];
        let dataTableRecord = [];

        data.forEach((e) => {
          let obj = {
            customer_name: e.name,
            annual_total_amount: e.annual[0],
          };
          customerNames.push(e.name);
          customerTotalSales.push(e.annual[0]);
          dataTableRecord.push(obj);
        });
        this.dataList = dataTableRecord;
        this.pieChartDataAnnual = {
          labels: customerNames,
          colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
          ],
          fill: {
            colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
            ],
          },
          markers: {
            colors: ["#F76E11","#FF9F45","#FFBC80","#FC4F4F","#313552","#B8405E","#2EB086","#EEE6CE","#219F94","#C1DEAE","#F2F5C8","#E8E8A6","#1A1A40","#270082","#7A0BC0","#FA58B6","#FFE162","#FF6464","#91C483",
            ],
          },
          tooltip: {
            style: "14px",
            y: {
              formatter: (val) => {
                if (
                  val !== "" ||
                  val !== undefined ||
                  val !== 0 ||
                  val !== "0" ||
                  val !== null
                ) {
                  return val
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return 0;
                }
              },
            },
          },
        };
        this.totalAmountAnnual = customerTotalSales;
      }
    },

    async exportTopPerformingConceptStoreToExcel() {
      if (this.load) {

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Top Performing Concept Store");

        if(this.byDateRange){
            worksheet.columns = [
            { header: "Customer", key: "customer_name" },
            {
                  header: "Total Amount",
                  key: "total_amount_customer",
                  style: {
                    numFmt:'#,##0.00',
                  },
              },
          ];

          for(let index in this.dateRangeData){
            this.dateRangeData[index].total_amount_customer = parseInt(this.dateRangeData[index].total_amount_customer)
                worksheet.addRow(this.dateRangeData[index]);
          }
          this.chartURI1 = await this.$refs.byDateRangePie.chart.dataURI().then(uri => {
                  var myBase64Image  = uri.imgURI

                  let finalImage = workbook.addImage({
                      base64: myBase64Image,
                      extension: 'png',
                  });
                  worksheet.addImage(finalImage, `D2:T27`);
                })
        }else{

          if(this.dataList.length > 0) {

              let headers = Object.keys(this.dataList[0])
              headers = headers.map(e=>{
                  return { header:this.upperCaseFirstLetter(e.replace('monthly_','')),key:e,style:e.includes('total') || e.includes('monthly_') ? {
                      numFmt:'#,##0.00'
                  }:'' }
              })

              worksheet.columns = headers

              worksheet.getRow(26).values = headers.map(e=>{ return e.header});
              this.dataList.forEach((e,i)=>{
                console.log(e);
                e.monthly_january = Number (e.monthly_january)
                e.monthly_february = Number (e.monthly_february)
                e.monthly_march = Number (e.monthly_march)
                e.monthly_april = Number (e.monthly_april)
                e.monthly_may = Number (e.monthly_may)
                e.monthly_june = Number (e.monthly_june)
                e.monthly_july = Number (e.monthly_july)
                e.monthly_august = Number (e.monthly_august)
                e.monthly_september = Number (e.monthly_september)
                e.monthly_october = Number (e.monthly_october)
                e.monthly_november = Number (e.monthly_november)
                e.monthly_december = Number (e.monthly_december)
                e.quarterly_january_march = Number (e.quarterly_january_march)
                e.quarterly_april_june = Number (e.quarterly_april_june)
                e.quarterly_july_september = Number (e.quarterly_july_september)
                e.quarterly_october_december = Number (e.quarterly_october_december)
                e.quarterly_total_amount = Number (e.quarterly_total_amount)
                worksheet.insertRow(27,e);
              })
              worksheet.getRow(26).font = { bold: true }
              worksheet.getRow(1).values = []

              if(this.periodValue!='annual'){
                this.chartURI2 = await this.$refs.lineChartRef.chart.dataURI().then(uri => {
                  var myBase64Image  = uri.imgURI

                  let finalImage = workbook.addImage({
                      base64: myBase64Image,
                      extension: 'png',
                  });
                  worksheet.addImage(finalImage, `A1:O24`);
                  })
            }
            else{
                this.chartURI3 = await this.$refs.annualPieChart.chart.dataURI().then(uri => {
                  var myBase64Image  = uri.imgURI
                  let finalImage = workbook.addImage({
                      base64: myBase64Image,
                      extension: 'png',
                  });
                  worksheet.addImage(finalImage, `A1:O24`);
                  })
            }

          } else {
            swal.fire('Error!',' No data !','error')
          }
        }

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                FileSaver.saveAs(blob, `Top Performing Concept Store By Period ${this.date_from} - ${this.date_to}.xlsx`);
            });

        // if(this.byDateRange){
        //     this.chartURI1 = this.$refs.byDateRangePie.chart.dataURI().then(uri => {
        //         var imgURI = uri.imgURI
        //         var blob = uri.blob
        //         if (blob) {
        //             navigator.msSaveOrOpenBlob(blob,'.png');
        //         } else {
        //             this.collectImgs(imgURI,'Piechart')
        //         }
        //     })
        // }
        // else{
        //     if(this.periodValue!='annual'){
        //         this.chartURI2 = this.$refs.lineChartRef.chart.dataURI().then(uri => {
        //             var imgURI = uri.imgURI
        //             var blob = uri.blob
        //             if (blob) {
        //                 navigator.msSaveOrOpenBlob(blob,'.png');
        //             } else {
        //                 this.collectImgs(imgURI,'Linechart')
        //             }
        //         })
        //     }
        //     else{
        //         this.chartURI3 = this.$refs.annualPieChart.chart.dataURI().then(uri => {
        //             var imgURI = uri.imgURI
        //             var blob = uri.blob
        //             if (blob) {
        //                 navigator.msSaveOrOpenBlob(blob,'.png');
        //             } else {
        //                 this.collectImgs(imgURI,'AnnualPieChart')
        //             }
        //         })
        //     }
        // }
      } else {
        swal.fire("No data to export", "Please filter data first", "error");
      }
    },
    upperCaseFirstLetter(str){
        const arr = str.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        str = arr.join(" ");
        return str
    },

    collectImgs(val, fileName) {
      axios
        .post("/saveTopPerformingChartImgs", {
          imgURI: val,
          fileName: fileName,
        })
        .then((response) => {
        //   window.open(
        //     `/exportTopPerformingConceptStoreToExcel/?date_from=${this.date_from}&date_to=${this.date_to}&byDateRange=${this.byDateRange}&byPeriod=${this.byPeriod}&periodValue=${this.periodValue}&yearValue=${this.yearValue}`,
        //     "_blank"
        //   );
        });
    },

    thousandSeprator(amount) {
      if (
        amount !== "" ||
        amount !== undefined ||
        amount !== 0 ||
        amount !== "0" ||
        amount !== null
      ) {
        return amount
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return amount;
      }
    },
    resetFields() {
      this.errors = [];
      this.customer_id = "";
      this.date_from = moment().clone().startOf("month").format("YYYY-MM-DD");
      this.date_to = moment().clone().endOf("month").format("YYYY-MM-DD");
      this.sales_total_amount = "";
      this.customer_count = "";
      this.yearValue = moment().year();
      this.periodValue = "";
      this.byDateRangeShow = false;
      this.annual = false;
      this.load = false;
    },
    byPeriodCheckBox(checked) {
      if (checked) {
        this.byDateRange = false;
        this.dataOption = "byPeriod";
        this.byDateRangeShow = false;
        this.load = false;
      }
    },
    ByDateRangeCheckBox(check) {
      if (check) {
        this.showdateRangeTable = true;
        this.byPeriod = false;
        this.monthlyTable = false;
        this.quarterlyTable = false;
        this.semiAnnualTable = false;
        this.dataOption = "byDateRange";
        this.showHeading = false;
        this.showAnuualChart = false;
        this.load = false;
      }
      if (check && this.totalAmount.length > 0) {
        this.byDateRangeShow = true;
      }
    },

    getFilters(){
      let payload = {}
      if(dataOptions == 'byDateRange'){
        payload = {
          date_from:this.date_from,
          date_from:this.date_to,
          headers:this.headers_byDateRange
        }
      }else{
        payload = {
          periodValue:this.periodValue,
          // headers:
        }
      }
    }
  },
};
</script>
  <style>
.col {
  padding: 2px !important;
}

.card-body {
  padding: 3px !important;
}
</style>
