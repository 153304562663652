<template>
    <div class="w-100">
         <v-app id="my_vue_app">
            <v-card>
                <v-row class="ma-3">
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="2" class="text-right" v-if="tab_name == 'is_all'">
                        <!-- <a href="#" :class="`ml-1 badge badge-all ${status_id == -1 ? `selected` : `non-selected`}`" @click="status_id = -1; getAllSalesQuotations()">all</a>
                        <a href="#" :class="`ml-1 badge badge-saved ${status_id == 0 ? `selected` : `non-selected`}`" @click="status_id = 0; getAllSalesQuotations()">saved</a>
                        <a href="#" :class="`ml-1 badge badge-converted ${status_id == 1 ? `selected` : `non-selected`}`" @click="status_id = 1; getAllSalesQuotations()">converted</a> -->
                        <!-- <a href="#" :class="`ml-1 badge badge-warning ${status_id == 2 ? `selected` : `non-selected`}`" @click="status_id=2; getAllSalesQuotations()">rejected</a> -->
                        <!-- <a href="#" :class="`ml-1 badge badge-danger ${status_id == 3 ? `selected` : `non-selected`}`" @click="status_id=3; getAllSalesQuotations()">canceled</a> -->
                        <!-- <a href="#" :class="`ml-1 badge badge-completed ${status_id == 4 ? `selected` : `non-selected`}`" @click="status_id=4; getAllSalesQuotations()">deleted</a> -->
                    </v-col>
                </v-row>

                <v-row class="mx-3 my-1">
                    <v-col xs="12" sm="12" md="2" lg="2">
                        <v-autocomplete
                            v-model="filters.discrepancy_type_id"
                            :items="GET_ALL_DISCREPANCY_SELECTION"
                            dense
                            outlined
                            hide-details
                            label="Discrepancy Type"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col xs="12" sm="12" md="2" lg="2">
                        <v-autocomplete
                            v-model="filters.issuer_id"
                            :items="GET_ALL_USER"
                            item-value="id"
                            item-text="name"
                            dense
                            outlined
                            hide-details
                            label="Issuer"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col xs="12" sm="12" md="2" lg="2">
                        <v-btn class="ml-1" @click="getAllContainerDiscrepancy()">Filter</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="2" class="mr-2 align-items-end text-right">
                        <v-btn class="ml-1" @click="exportToExcel()">Export</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-3 my-1">
                    <v-col sm="6" md="2" id="date_from">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_range[0]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date From"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_range[0]" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col sm="6" md="2" id="date_to">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_range[1]"
                                    v-on="on"
                                    dense
                                    outlined
                                    hide-details
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    label="Date To"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_range[1]" @input="menu3 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col sm="12" md="2" class="mr-2 align-items-end">
                        <v-text-field
                            v-model="search"
                            single-line
                            hide-details
                            label="Search CD # / Container #"
                            append-icon="mdi-magnify" 
                            @keydown.enter="getAllContainerDiscrepancy()"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-container fluid>
                    <v-data-table 
                    :loading="loading"
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :server-items-length="totalContainerDiscrepancy"
                    :items-per-page="10"
                    >
                        <template v-slot:[`item.status`]="{ item }">
                            <span :class="component_details.statuses[item.status].class">{{ component_details.statuses[item.status].name }}</span>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span>{{ item.created_at | formatDate }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <span class="text-nowrap">
                                <v-btn text icon color="orange" v-if="userAccess.view">
                                    <v-icon class="btn-action" small @click="viewContainerDiscrepancy(item.id)">mdi-eye
                                    </v-icon>
                                </v-btn>
                                <v-btn text icon color="blue" v-if="(item.status == 0 || (item.discrepancy_type_id == 2 && item.status == 1)) && userAccess.edit">
                                    <v-icon class="btn-action" small @click="editContainerDiscrepancy(item)">mdi-pencil
                                    </v-icon>
                                </v-btn>
                                <v-btn text icon color="red" v-if="userAccess.delete">
                                    <v-icon class="btn-action" small @click="deleteContainerDiscrepancy(item.id)">mdi-delete
                                    </v-icon>
                                </v-btn>
                            </span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </v-app>
        <ContainerDiscrepancyViewComponentVue :viewContainerDiscrepancyId="viewContainerDiscrepancyId" @closeView="closeView" :component_details="component_details"></ContainerDiscrepancyViewComponentVue>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex'
import swal from 'sweetalert2';
import ContainerDiscrepancyViewComponentVue from "./ContainerDiscrepancyViewComponent.vue";
export default {
    components: {
        ContainerDiscrepancyViewComponentVue
    },
    props: [
        'tab_name',
        'component_details'
    ],
    data() {
        return {
            loading: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            headers: [
                { text: 'CD Num ', align: 'left', value: 'container_discrepancy_num', },
                { text: 'Container Num', align: 'left', value: 'container_num', },
                { text: 'Discrepancy Type', align: 'left', value: 'discrepancy_type_name', },
                { text: 'Status', align: 'center', value: 'status', },
                { text: 'Issuer', align: 'center', value: 'created_by_name', },
                { text: 'Confirmed By', align: 'center', value: 'confirmed_by_name', },
                { text: 'Approved By', align: 'center', value: 'approved_by_name', },
                { text: 'Date Issued', align: 'center', sortable: false, value: 'created_at', },
                { text: 'Actions', align: 'center', sortable: false, value: 'action', },
            ],
            menu2:false,
            menu3:false,
            filters:{
                date_range:[
                    moment().clone().startOf('year').format('YYYY-MM-DD'),
                    moment().clone().endOf('month').format('YYYY-MM-DD')
                ],
                discrepancy_type_id:0,
                issuer_id:0
            },
            items:[],
            totalContainerDiscrepancy:0,
            options:{},
            viewContainerDiscrepancyId:0,
            userAccess:{
                view:false,
                edit:false,
                delete:false
            }
        }
    },
    computed: {
        ...mapGetters([
           'GET_ALL_CONTAINER_DISCREPANCY',
           'GET_ALL_DISCREPANCY_SELECTION',
           'GET_ALL_USER',
           'USER_ACCESS',
           'GET_EXPORT_CONTAINER_DISCREPANCY',
        ])
    },
    methods: {
        getAllContainerDiscrepancy(){
            this.loading = true
            this.items = []
            let payload = {
                url:'get-all-container-discrepancy',
                mutation:'ALL_CONTAINER_DISCREPANCY',
                tab_name: this.tab_name,
                search:this.search,
                filters:this.filters,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
            }
            this.$store.dispatch('getContainerDiscrepancy',payload)
        },
        editContainerDiscrepancy(item){
            this.$emit('showEditModal',item)
        },
        viewContainerDiscrepancy(id){
            this.viewContainerDiscrepancyId = id
        },
        closeView(action = false){
            this.viewContainerDiscrepancyId = 0
            if(action == 'updateStatusSuccess'){
                this.getAllContainerDiscrepancy()
            }
        },
        deleteContainerDiscrepancy(id){
            swal.fire({
                title: "",
                text: "Are you sure you want to Delete?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let payload = {
                        url:`delete-container-discrepancy/${id}`
                    }
                    this.$store.dispatch('postContainerDiscrepancy',payload).then(response=>{
                        swal.fire(response.data.msg,'',response.data.icon)
                        this.getAllContainerDiscrepancy()
                    })
                }
            });    
        },
        checkAccess(){
            this.userAccess.view = false;
            this.userAccess.edit = false;
            this.userAccess.delete = false;
            this.USER_ACCESS.forEach(e => {
                if (e.actions_code == 'view') {
                    this.userAccess.view = true;
                }
                if (e.actions_code == 'edit') {
                    this.userAccess.edit = true;
                }
                if (e.actions_code == 'delete') {
                    this.userAccess.delete = true;
                }
            });
        },
        async exportToExcel(){
            swal.fire({
                title: 'Loading Please wait...',
                icon: 'info',
                allowOutsideClick: false,
            });
            swal.showLoading();
            let payload = {
                url: 'export-container-discrepancy',
                filters:this.filters,
                mutation:'EXPORT_CONTAINER_DISCREPANCY',
            }
            await this.$store.dispatch('getContainerDiscrepancy',payload)
        },
    },
    mounted() {
        this.getAllContainerDiscrepancy()
        this.checkAccess()
    },
    watch: {
        tab_name: {
            handler(val){
                this.getAllContainerDiscrepancy()
            }
        },
        options: {
            handler() {
                this.getAllContainerDiscrepancy()
            },
            deep: true,
        },
        GET_ALL_CONTAINER_DISCREPANCY: {
            handler(val) {
                this.items = val.data.data;
                this.totalContainerDiscrepancy = val.data.total;
                this.loading = false
            }
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                    this.checkAccess()
                }
            },
        },
        GET_EXPORT_CONTAINER_DISCREPANCY:{
            handler(val){
                
                const ExcelJS = require('exceljs')
                const workbook = new ExcelJS.Workbook();

                const worksheet = workbook.addWorksheet('Raw Data')
              
                worksheet.columns = [
                    { header:'CD NUM ', key: 'container_discrepancy_num', },
                    { header:'CONTAINER NUM', key: 'container_num', },
                    { header:'DISCREPANCY TYPE', key: 'discrepancy_type_name', },
                    { header:'STATUS', key: 'status', },
                    { header:'ISSUER', key: 'created_by_name', },
                    { header:'CONFIRMED BY', key: 'confirmed_by_name', },
                    { header:'APPROVED BY', key: 'approved_by_name', },
                    { header:'MODEL', sortable: false, key: 'model', },
                    { header:'ITEM NAME', sortable: false, key: 'name', },
                    { header:'TYPE', sortable: false, key: 'item_type_name', },
                    { header:'UOM', sortable: false, key: 'item_uom_name', },
                    { header:'QTY RECEIVED (MB)', sortable: false, key: 'quantity_master_box',style: { numFmt: '#,##0' } },
                    { header:'MB QTY', sortable: false, key: 'quantity_master_box_conv',style: { numFmt: '#,##0' } },
                    { header:'UOM QTY', sortable: false, key: 'quantity',style: { numFmt: '#,##0' } },
                    { header:'DISCREPANCY', sortable: false, key: 'discrepancy',style: { numFmt: '#,##0' } },
                    { header:'RECEIVED UOM', sortable: false, key: 'received_quantity',style: { numFmt: '#,##0' } },
                ]
                
                val.forEach(e=>{
                    e.container_discrepancy_items.forEach(x=>{
                        x.container_discrepancy_num = e.container_discrepancy_num
                        x.container_num = e.container_num
                        x.discrepancy_type_name = e.discrepancy_type_name
                        x.status = this.component_details.statuses[e.status].name
                        x.created_by_name = e.created_by_name
                        x.confirmed_by_name = e.confirmed_by_name
                        x.approved_by_name = e.approved_by_name

                        worksheet.addRow(x);
                    })
                })
                worksheet.getRow(`0${1}`).font = { bold: true }
                    
                let exportName = `Container Discrepancy`

                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    saveAs(blob, `${exportName} ${this.filters.date_range[0]} - ${this.filters.date_range[1]}}.xlsx`);
                    swal.close()
                });
            }
        }
    }
};
</script>
