const buttons = {
  actions: {
    view: 'view',
    create: 'create',
    edit: 'edit',
    delete: 'delete',
    access: 'access',
    addbranddiscount: 'addbranddiscount',
    filter: 'filter',
    report: 'report'
  },
  icons: {
    view: 'mdi-eye',
    edit: 'mdi-square-edit-outline',
    delete: 'mdi-trash-can'
  },
  colors: {
    create: '#497E7E',
    view: 'orange',
    edit: 'primary',
    delete: 'red',
    addbranddiscount: ''
  },
  type: {
    text: true,
    icon: true
  },
};

export default {
  buttons,
  methods: {
    checkAccess(dep, mod, axn) {
      axios
        .get('checkAccess', {
          params: {
            department_code: dep,
            module: mod,
            action: axn,
          },
        })
        .then(response => {
          this.userAccess[axn] = response.data;
        })
    },
    decimalOnly($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    numberOnly($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    thousandSeprator(amount) {
      if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
        return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return amount;
      }
    },
    getTwoDecimals(amount, places = 2) {
      let formatted_amount = ''
      if (isNaN(amount)) {
        if (amount != null && amount != '') {
          formatted_amount = parseFloat(amount.replace(/,/g, ''));
          formatted_amount = Math.trunc(formatted_amount * Math.pow(10, places)) / Math.pow(10, places)
        }
      } else {
        formatted_amount = Math.trunc(amount * Math.pow(10, places)) / Math.pow(10, places);
      }

      if (formatted_amount == '') {
        return 0.0;
      }
      return this.thousandSeprator(formatted_amount.toFixed(places));
    },
    getDatesFromRange(startDate, endDate, return_type = 'array') {
      const dates = [];
      let currDate = moment(startDate).startOf('day');
      const lastDate = moment(endDate).startOf('day');

      while (currDate.diff(lastDate) <= 0) {
        switch (return_type) {
          case 'array':
            dates.push(currDate.clone().format('YYYY-MM-DD'));
          break;
          case 'object':
            dates.push(
              Object.assign(
                {},
                {
                  value: currDate.clone().format('YYYY-MM-DD'),
                  text: currDate.clone().format('YYYY-MM-DD (dddd)'),
                }
              )
            );
            break
        default:
        }

        currDate = currDate.add(1, 'days')
      }

      return dates;
    }
  },
};
