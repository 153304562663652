<template>
    <v-container fluid class="bg-transparent">
        <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-end" style="gap: 10px">
                <v-btn v-if="selected_id.length > 0 && userAccess.cf_print == true" @click="printPDF(1)">PDF</v-btn>
                <v-btn @click="tab_name === 'is_transmittal'? exportTransmittal() : getAllCheckerForms('xlsx')">Export</v-btn>
            </v-col>
        </v-row>
        <v-row dense class="mt-2">
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <span v-if="!['is_transmittal'].includes(tab_name)">
                            <v-row dense>
                                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                    <v-autocomplete
                                        v-model="customer_id"
                                        :items="GET_CUSTOMERS_LIST_SELECTION"
                                        label="Select Customer"
                                        dense
                                        outlined
                                        hide-details
                                        auto-select-first
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                                    <v-autocomplete
                                        v-model="user_id"
                                        :items="GET_ALL_USERS_SELECT"
                                        label="Select User"
                                        item-text="name"
                                        dense
                                        outlined
                                        hide-details
                                        auto-select-first
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="1" lg="1" xl="1" class="d-flex ml-2">
                                    <v-btn @click="getAllCheckerForms()">Filter</v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="text-right" v-if="tab_name === 'is_all'">
                                    <a :class="`ml-1 badge badge-all ${status_id == -1 ? `selected` : `non-selected`}`" @click="status_id = -1">all</a>
                                    <a :class="`ml-1 badge badge-preparing ${status_id == 6 ? `selected` : `non-selected`}`" @click="status_id = 6">preparing</a>
                                    <a :class="`ml-1 badge badge-checking ${status_id == 0 ? `selected` : `non-selected`}`" @click="status_id = 0">checking</a>
                                    <a :class="`ml-1 badge badge-checked ${status_id == 1 ? `selected` : `non-selected`}`" @click="status_id = 1">checked</a>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                    <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="date_range_filter[0]"
                                                v-on="on"
                                                label="Date From"
                                                append-icon="mdi-calendar-clock"
                                                dense
                                                outlined
                                                hide-details
                                                readonly
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_range_filter[0]" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                                    <v-menu
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="date_range_filter[1]"
                                                v-on="on"
                                                label="Date To"
                                                append-icon="mdi-calendar-clock"
                                                dense
                                                outlined
                                                hide-details
                                                readonly
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_range_filter[1]" @input="menu3 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="mr-2 align-items-end">
                                    <v-text-field
                                        v-model="search"
                                        label="Search CF# / WPF# / SO#"
                                        single-line
                                        hide-details
                                        @keydown.enter="getAllCheckerForms()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="mr-2 align-items-end">
                                    <v-text-field
                                        v-model="searchItem"
                                        label="Search Item Model / Name"
                                        single-line
                                        hide-details
                                        append-outer-icon="mdi-magnify"
                                        @click:append-outer="getAllCheckerForms()"
                                        @keydown.enter="getAllCheckerForms()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-data-table
                                        v-model="dataTable"
                                        :headers="headers"
                                        :items="checkerForms"
                                        :loading="!loaded"
                                        :search="search"
                                        :options.sync="options"
                                        :server-items-length="totalCheckerForms"
                                        :show-select="showCheckbox"
                                        :footer-props="{
                                            itemsPerPageOptions: itemsPerPageOptions,
                                        }"
                                        class="w-100"
                                        disable-sort
                                        @input="getSelectedID"
                                    >
                                        <template v-slot:[`item.customer_name`]="{ item }">
                                            <span style="font-size: 10px !important">
                                                {{ item.customer_name }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.total_amount | currency }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.created_at`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ item.created_at | formatDateTime }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.printed`]="{ item }">
                                            <span class="badge badge-primary" v-if="item.printed">Yes</span>
                                            <span class="badge badge-danger" v-else>No</span>
                                        </template>

                                        <template v-slot:[`item.packing_list_status`]="{ item }">
                                            <span class="badge badge-primary" v-if="item.packing_list_status != null">Yes</span>
                                            <span class="badge badge-danger" v-else>No</span>
                                        </template>

                                        <template v-slot:[`item.status_text`]="{ item }">
                                            <span class="badge badge-prepared" v-if="item.status_text == 'prepared'">{{ item.status_text }}</span>
                                            <span class="badge badge-checking" v-if="item.status_text == 'checking'">{{ item.status_text }}</span>
                                            <span class="badge badge-checked" v-else-if="item.status_text == 'checked'">{{ item.status_text }}</span>
                                        </template>

                                        <template v-slot:[`item.action`]="{ item }">
                                            <span class="text-nowrap" v-if="userAccess.view">
                                                <v-btn text icon color="orange">
                                                    <v-icon class="btn-action" small @click="viewCheckerForm(item.id)">mdi-eye</v-icon>
                                                </v-btn>
                                                <v-btn text icon color="blue" v-if="userAccess.edit && [0, 6].includes(item.status)">
                                                    <v-icon class="btn-action" small @click="editCheckerForm(item.id)">mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-btn text icon color="red" v-if="userAccess.delete">
                                                    <v-icon class="btn-action" small @click="currentCheckerFormId = item.id; override.approve_override_delete_dialog = true;">mdi-delete</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                    </v-data-table>
                                    <!-- <div v-if="convertBtn" class="table-footer-prepend d-flex pl-2 align-center" style="margin-top:-52px; height:58px;">
                                        <v-btn :loading="btn_loader" @click="status_id == 0 ? confirmMultipleCF() : convertMultiplePL() ">{{status_id == 0 ? 'PRINT AND CONFIRM' : 'CONVERT TO DR'}}</v-btn>
                                    </div> -->
                                </v-col>
                            </v-row>
                        </span>
                        <span v-else>
                            <v-row>
                                <v-col cols="12" class="d-flex flex-row-reverse">
                                    <v-tooltip bottom color="#f69f1a">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                :loading="!loaded"
                                                fab
                                                class="mr-4"
                                                color="#f69f1a"
                                                text
                                                small
                                                @click="getTransmittal()"
                                            >
                                            <v-icon>mdi-sync</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>REFRESH</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row dense class="mt-4">
                                <v-spacer></v-spacer>
                                <v-col cols="12" lg="3" class="mr-4 pr-4 ">
                                    <v-text-field
                                        v-model="transmittal.search"
                                        label="Search Transmittal#"
                                        append-icon="mdi-magnify"
                                        dense
                                        @change="getTransmittal()"
                                        @click:append="getTransmittal()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table
                                        v-model="dataTable"
                                        :headers="transmittal_headers"
                                        :items="transmittalRecords"
                                        :loading="!loaded"
                                        :search="search"
                                        :options.sync="options"
                                        :server-items-length="totalTransmittalRecords"
                                        class="w-100"
                                        disable-sort
                                        :footer-props="{
                                            itemsPerPageOptions: itemsPerPageOptions,
                                        }"
                                    >
                                        <template v-slot:[`item.date_printed`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ item.date_printed | formatDateTime }}
                                            </span>
                                        </template>
                                        <!-- <template v-slot:[`item.id`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ item.id.toString().padStart(5, '0') }}
                                            </span>
                                        </template> -->
                                        <template v-slot:[`item.action`]="{ item }">
                                            <span class="text-nowrap" v-if="userAccess.view">
                                                <v-btn text icon color="orange">
                                                    <v-icon class="btn-action" small @click="viewCheckerFormTransmittal(item)">mdi-eye</v-icon>
                                                </v-btn>
                                                <!-- <v-btn text icon color="blue" v-if="userAccess.edit">
                                                    <v-icon class="btn-action" small @click="editCheckerForm(item.id)">mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-btn text icon color="red" v-if="userAccess.delete">
                                                    <v-icon class="btn-action" small @click="currentCheckerFormId = item.id; override.approve_override_delete_dialog = true;">mdi-delete</v-icon>
                                                </v-btn> -->
                                            </span>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- VIEW INPUT DATE AND REMARKS -->
        <v-dialog v-model="before_print_dialog" persistent max-width="40%" scrollable>
            <v-card>
                <v-row dense class="m-0">
                    <v-card-title>
                        <span class="headline">Input Release Date and Remarks</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-center">
                                    CF#
                                </th>
                                <th class="text-center">
                                    Release Date
                                </th>
                                <th class="text-center">
                                    Release Remarks
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="(
                                item, i
                                ) in selected_for_print_pdf"
                                :key="i"
                                >
                                    <td>
                                        <v-text-field
                                        v-model="item.cf"
                                        :background-color="'#CFD8DC'"
                                        label="CF#"
                                        dense
                                        outlined
                                        readonly
                                        >
                                        </v-text-field>
                                    </td>
                                    <td>
                                        <v-menu
                                        v-model="item.menu4"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="item.complete_date"
                                                    v-on="on"
                                                    label="Release Date"
                                                    append-icon="mdi-calendar-clock"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    readonly
                                                    class="mx-2"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="item.complete_date" @change="applyToAll(item, 1)" @input:date="item.menu4 = false"></v-date-picker>
                                        </v-menu>
                                    </td>
                                    <td>
                                        <v-text-field
                                        v-model="item.remarks"
                                        label="Release Remarks"
                                        dense
                                        outlined
                                        @change="applyToAll(item, 2)"
                                        >
                                        </v-text-field>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-row dense>
                        <v-col cols="12" class="d-flex flex-row-reverse">
                            <v-btn
                            small
                            class="ma-2 pa-4"
                            @click="submitInput(selected_for_print_pdf)"
                            >
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <ConfirmOverrideComponentVue
            :approve_override_dialog="override.approve_override_delete_dialog"
            :departments_allowed="override.override_departments_allowed"
            :heading="override.override_heading"
            @closeConfirmOverride="closeConfirmOverride"
        ></ConfirmOverrideComponentVue>

        <!-- VIEW MODAL-->
        <CheckerFormViewComponentVue
            :viewCheckerFormId="viewCheckerFormId"
            :tab_name="tab_name"
            :viewTransmittalId="viewTransmittalId"
            :viewTransmittalItem="viewTransmittalItem"
            @closeView="closeView"
            @getAllCheckerForms="getAllCheckerForms"
        ></CheckerFormViewComponentVue>
    </v-container>
</template>
<script>
import ConfirmOverrideComponentVue from "@/views/main/Utils/ConfirmOverrideComponent.vue";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import CheckerFormViewComponentVue from "./CheckerFormViewComponent.vue";
import moment from "moment";
import swal from "sweetalert2";
import { mapGetters } from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable'
const ExcelJS = require("exceljs");
const FileSaver = require("file-saver");

export default {
    mixins: [
        SharedFunctionsComponent,
    ],
    props: [
        'checkerFormsActionResult',
        'tab_name',
        'print',
        'user_access'
    ],
    components: {
        ConfirmOverrideComponentVue,
        CheckerFormViewComponentVue
    },
    data() {
        return {
            override: {
                approve_override_delete_dialog: false,
                errors: [],
                override_departments_allowed: ['OP', 'EX', 'IT'],
                override_heading: 'Delete Checker Form',
                override_authenticated: false,
            },
            actionResult: {
                model: 'CheckerForms',
                action: '',
            },
            loaded: true,
            totalCheckerForms: 0,
            options: {},
            checkerForms: [],
            transmittalRecords: [],
            totalTransmittalRecords: 0,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            search: '',
            awaitingSearch: false,
            headers: [
                { text: 'CF#', value: 'id' },
                { text: 'WPF#', value: 'wp_list_id' },
                { text: 'SO#', value: 'order_num' },
                { text: 'Warehouse', align: 'center', value: 'warehouse_code' },
                { text: 'Customer', value: 'customer_name' },
                { text: 'Status', align: 'center', value: 'status_text' },
                { text: 'Date Issued', value: 'created_at' },
                { text: 'Checker', value: 'checker_name' },
                { text: 'Completed By', value: 'completed_by_name' },
                { text: 'Date Completed', value: 'complete_date' },
                { text: 'Correct Picking', value: 'picking_correction' },
                { text: 'Printed', value: 'printed' },
                { text: 'Converted', align: 'center', value: 'packing_list_status' },
                { text: 'Actions', align: 'center', value: 'action' },
            ],
            transmittal_headers: [
                { text: 'TRANSMITTAL#', align: 'left', value: 'series' },
                { text: 'PRINTED BY', align: 'left', value: 'printed_by' },
                { text: 'DATE PRINTED', align: 'left', value: 'date_printed' },
                { text: 'Actions', align: 'center', value: 'action', sortable: false },
            ],

            viewCheckerFormId: '',
            viewTransmittalId: '',
            currentCheckerFormId: '',
            viewTransmittalItem:[],

            customers_list: [],
            customer_id: '',

            date_range_filter: [
                moment().clone().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
                moment().clone().endOf('month').format('YYYY-MM-DD'),
            ],
            status_id: -1,
            menu2: false,
            menu3: false,
            menu4: false,
            searchItem: '',
            users_list: [],
            user_id: '',

            userAccess: {
                view: false,
                edit: false,
                delete: false,
                cf_print: false
            },

            dataTable: [],
            convertBtn: false,
            showCheckbox: false,
            status: 0,
            selected_id: [],
            selected_checker: [],

            btn_loader: false,
            errorIds: [],

            loading: {
                customer_selection: true,
                user_selection: true,
            },
            selected_for_print_pdf: [],
            before_print_dialog: false,
            show_again_date: 0,
            show_again_remarks: 0,
            saved_tf: '',
            transmittal:{
                search: ''
            }
        }
    },
    methods: {
        async convertMultiplePL() {
            this.btn_loader = true;
            await swal
                .fire({
                    title: "",
                    text: "Are you sure you want to Convert this to DR?",
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let id = this.selected_id;
                        let length = id.length - 1;
                        id.forEach(async (cf_id, index) => {
                            let payload = {
                                url: "convertToPackingList/" + cf_id,
                            };
                            this.$store.dispatch("postCF", payload).then(async (response) => {
                                let data = response.data;
                                if (data.error) {
                                    swal.fire(
                                        "CF # " + cf_id,
                                        data.error + "\n\nConversion Stopped",
                                        "warning"
                                    );
                                    this.getAllCheckerForms();
                                    return false;
                                } else if (length == index) {
                                    swal.fire(
                                        "Selected Checker Form Converted Succesfully!",
                                        data.msg,
                                        "success"
                                    );
                                    this.getAllCheckerForms();
                                }
                            });
                        });
                    }
                    this.btn_loader = false;
                });
        },
        async confirmMultipleCF() {
            this.btn_loader = true;
            await swal
                .fire({
                    title: "",
                    text: "Are you sure you want to update selected Checker Form?",
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#397373",
                    cancelButtonColor: "grey",
                    confirmButtonText: "Confirm",
                    showCloseButton: true,
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        let payload = {
                            selected_id: this.selected_id,
                            url: "getCFDetails",
                        };
                        this.$store.dispatch("getCF", payload).then(async (response) => {
                            let data = response.data;
                            if (data != "") {
                                for (const index in data.data) {
                                    await new Promise((resolve) => {
                                        let update_payload = {
                                            status: 1,
                                            url: "updateCheckerFormStatus/" + this.print.cf_id,
                                        };
                                        this.$store
                                            .dispatch("postCF", update_payload)
                                            .then((response) => {
                                                if (response) {
                                                    this.renderPDF(
                                                        "print",
                                                        "_conversion",
                                                        this.print.cf_id
                                                    );
                                                    this.print.no_of_items = 0;
                                                    resolve();
                                                }
                                            });
                                    });
                                }
                                swal.fire(
                                    "Selected Checker Form Update Status Success",
                                    "",
                                    "success"
                                );
                                this.dataTable = [];
                                this.btn_loader = false;
                                this.getAllCheckerForms();
                            }
                        });
                    }
                    this.btn_loader = false;
                });
        },
        getSelectedID(val) {
            this.selected_for_print_pdf =  val.map(function (e) {
                return {cf: e.id, so: parseInt(e.order_num), so_id: e.so_id, customer: e.customer_name, date: moment(e.complete_date).format('YYYY-MM-DD'), remarks: e.remarks}
            });
            this.selected_id = val.map(function (e) {
                return e.id;
            });
            this.selected_checker = val.map(function (e) {
                return e.checker_name;
            });
            this.selected_checker.filter((i) => {
                if (i == "") {
                    swal.fire("Checker cannot be blank", "", "warning");
                    const index = this.dataTable.indexOf(this.dataTable[i]);
                    this.dataTable.splice(index, 1);
                    return false;
                }
            });
            this.convertBtn = this.dataTable.length > 0;
        },
        renderPDF(pdfAction, type, cf_id) {
            var doc = new jsPDF("p", "mm", "letter");

            doc.fromHTML($("#div_logo" + type).get(0), 7, 4, { width: 600 });
            doc.fromHTML($("#div_checker_form_num" + type).get(0), 142, 4, {
                width: 400,
            });

            doc.autoTable({
                html: "#customer_details" + type,
                theme: "plain",
                margin: { top: 47, left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 30 },
                    1: { cellWidth: 124 },
                    2: { cellWidth: 16 },
                    3: { cellWidth: 25 },
                },
            });

            if (this.print_no_price == false) {
                doc.autoTable({
                    html: "#items_details" + type,
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: { left: 6, right: 6 },
                    theme: "grid",
                    headStyles: {
                        halign: "center",
                        fillColor: "#ffffff",
                        textColor: "#000000",
                        lineWidth: 0.2,
                        lineColor: "#000000",
                    },
                    columnStyles: {
                        0: { cellWidth: 9, halign: "center", textColor: "#000000" },
                        1: { cellWidth: 13, halign: "center", textColor: "#000000" },
                        2: { cellWidth: 14, halign: "center", textColor: "#000000" },
                        3: { cellWidth: 29, halign: "center", textColor: "#000000" },
                        4: { halign: "left", textColor: "#000000" },
                        5: { cellWidth: 25, halign: "right", textColor: "#000000" },
                        6: { cellWidth: 25, halign: "right", textColor: "#000000" },
                    },
                    bodyStyles: {
                        lineColor: "#000000",
                    },
                });
            } else {
                doc.autoTable({
                    html: "#items_details_without_price" + type,
                    startY: doc.autoTable.previous.finalY + 3,
                    margin: { left: 6, right: 6 },
                    theme: "grid",
                    headStyles: {
                        halign: "center",
                        fillColor: "#ffffff",
                        textColor: "#000000",
                        lineWidth: 0.2,
                        lineColor: "#000000",
                    },
                    columnStyles: {
                        0: { cellWidth: 11, halign: "center", textColor: "#000000" },
                        1: { cellWidth: 17, halign: "center", textColor: "#000000" },
                        2: { cellWidth: 17, halign: "center", textColor: "#000000" },
                        3: { cellWidth: 17, halign: "center", textColor: "#000000" },
                        4: { cellWidth: 27, halign: "center", textColor: "#000000" },
                        5: { halign: "left", textColor: "#000000" },
                    },
                    bodyStyles: {
                        lineColor: "#000000",
                    },
                });
            }

            if (this.print_no_price == false) {
                doc.autoTable({
                    html: "#total_details" + type,
                    theme: "plain",
                    startY: 207,
                    margin: { top: 37, left: 6, right: 6 },
                    columnStyles: {
                        0: { cellWidth: 115, textColor: "#000000" },
                        1: { cellWidth: 35, halign: "right", textColor: "#000000" },
                        2: { cellWidth: 12, textColor: "#000000" },
                        3: { cellWidth: 35, halign: "right", textColor: "#000000" },
                    },
                });
            }

            doc.autoTable({
                html: "#num_of_items" + type,
                theme: "plain",
                tableWidth: 116,
                startY: 225,
                margin: { top: 37, left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 38, textColor: "#000000" },
                    1: { cellWidth: 38, textColor: "#000000" },
                    2: { cellWidth: 38, textColor: "#000000" },
                },
            });

            var footer_y = 235;

            doc.autoTable({
                html: "#footer" + type,
                theme: "grid",
                tableWidth: 106,
                startY: footer_y,
                margin: { left: 6, right: 6 },
                columnStyles: {
                    0: {
                        cellWidth: 38,
                        halign: "center",
                        valign: "bottom",
                        textColor: "#000000",
                    },
                },
                bodyStyles: {
                    lineColor: "#000000",
                },
            });

            let finalY = doc.lastAutoTable.finalY; // The y position on the page
            doc.setFontSize(7);
            doc.setTextColor("#000000");
            doc.text(190, finalY, "Page 1 of 1");

            let payload = {
                url: "checkerForms/print/" + cf_id,
            };
            this.$store.dispatch("postCF", payload).then((response) => { });

            if (pdfAction == "download") {
                doc.save("Checker_Form " + cf_id + ".pdf");
            } else if (pdfAction == "print") {
                doc.autoPrint();
                window.open(doc.output("bloburl"), "_blank");
            }
        },
        getAllCheckerForms(type = 'list') {
            this.loaded = false;

            if (type == 'list') {
                this.checkerForms = []
            }

            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
                searchItem: this.searchItem,
                tab_name: this.tab_name,
                customer_id: this.customer_id,
                date_range_filter: this.date_range_filter,
                status_filter: this.status_id,
                for_export: type == 'xlsx' ? 1 : 0,
                url: "checkerForms/getAll",
            }

            this.$store.dispatch("getCF", payload).then((response) => {
                if (type == 'list') {
                    this.checkerForms = response.data.data.data;
                    this.totalCheckerForms = response.data.data.total;
                    this.checkAccess();

                    if (this.tab_name !== "is_all") {
                        this.showCheckbox = true;
                    }
                } else if (type == 'xlsx') {
                    const workbook = new ExcelJS.Workbook()
                    const summary = workbook.addWorksheet('Summary')
                    const detailed = workbook.addWorksheet('Detailed')

                    summary.columns = [
                        { header: 'CF#', key: 'id' },
                        { header: 'WPF#', key: 'wp_list_id' },
                        { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                        { header: 'Warehouse', key: 'warehouse_code' },
                        { header: 'Customer', key: 'customer_name' },
                        { header: 'Status', key: 'status_text' },
                        { header: 'Date Issued', key: 'created_at' },
                        { header: 'Checker', key: 'checker_name' },
                        { header: 'Completed By', key: 'completed_by_name' },
                        { header: 'Printed', key: 'printed' },
                        { header: 'Correct Picking', key: 'picking_correction' },
                        { header: 'Date Completed', key: 'complete_date', style: { numFmt: 'm/d/yyyy' } },
                        { header: 'Converted', key: 'packing_list_status' },
                    ]

                    response.data.summary.forEach((e, i) => {
                        e.picking_correction = e.picking_correction ? 'YES' : e.picking_correction !== null ? 'NO' : '';
                        e.complete_date = [null, '0000-00-00'].includes(e.complete_date) ? '' : this.formatDate(e.complete_date);

                        summary.addRow(e);
                    })

                    detailed.columns = [
                        { header: 'CF#', width: 10, key: 'id' },
                        { header: 'Checker Name', width: 16, key: 'checker_name' },
                        { header: 'WPF#', width: 10, key: 'wp_list_id' },
                        { header: 'Warehouse', width: 10, key: 'warehouse_code' },
                        { header: 'SO#', width: 10, key: 'order_num', style: { numFmt: '@' } },
                        { header: 'Customer Name', width: 24, key: 'customer_name' },
                        { header: 'Status', width: 16, key: 'status_text' },
                        { header: 'Date Issued', width: 20, key: 'created_at', style: { numFmt: 'mm/dd/yyyy hh:mm AM/PM' } },
                        { header: 'Complete Date', key: 'complete_date', style: { numFmt: 'mm/dd/yyyy' } },
                        { header: 'Printed', width: 16, key: 'printed' },
                        { header: 'Converted', width: 8, key: 'packing_list_status' },
                        { header: 'Model', width: 16, key: 'model' },
                        { header: 'Name', width: 20, key: 'name' },
                        { header: 'Type', width: 8, key: 'type' },
                        { header: 'Category', width: 8, key: 'category' },
                        { header: 'UOM', width: 10, key: 'uom' },
                        { header: '# Of Boxes', width: 8, key: 'num_of_box' },
                        { header: 'Quantity', width: 8, key: 'quantity' },
                        { header: 'Quantity To Ship', width: 8, key: 'quantity_to_ship' },
                        { header: 'Picking Correction', width: 20, key: 'picking_correction' },
                        { header: 'Correct Picking Reason', width: 24, key: 'correct_picking_reason' },
                        { header: 'Start Date', width: 16, key: 'start_date', style: { numFmt: 'mm/dd/yyyy' } },
                        { header: 'End Date', width: 16, key: 'end_date', style: { numFmt: 'mm/dd/yyyy' } },
                        { header: 'Duration', width: 16, key: 'durations' },
                    ]

                    response.data.detailed.forEach((e, i) => {
                        e.picking_correction = e.picking_correction ? 'YES' : e.picking_correction !== null ? 'NO' : '';
                        e.complete_date = [null, '0000-00-00'].includes(e.complete_date) ? '' : this.formatDate(e.complete_date);

                        detailed.addRow(e);
                    })

                    let company_name = this.customers_list.filter((e) => {
                        if (e.value == this.customer_id) {
                            return e.text;
                        }
                    })

                    let file_name =
                        this.customer_id == null || this.customer_id == ""
                            ? " - ALL"
                            : " - " + company_name[0].text

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                        })

                        FileSaver.saveAs(blob, `Checker Forms ${file_name}.xlsx`)
                    })
                }

                this.loaded = true
            }).catch(error => {
                this.loaded = true
            })
        },
        viewCheckerForm(id) {
            this.viewCheckerFormId = id;
        },
        viewCheckerFormTransmittal(item) {
            this.viewTransmittalId = item.id;
            this.viewTransmittalItem = item;
        },
        editCheckerForm(id) {
            this.$emit("showEditModal", id);
        },
        deleteCheckerForm(id) {
            if (this.override.override_authenticated) {
                swal
                    .fire({
                        title: "",
                        text: "Are you sure you want to Delete?",
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: "#397373",
                        cancelButtonColor: "grey",
                        confirmButtonText: "Confirm",
                        showCloseButton: true,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            let payload = {
                                url: "checkerForms/" + id,
                            };
                            this.$store
                                .dispatch("deleteCF", payload)
                                .then((response) => {
                                    if (response.data.status == "success") {
                                        this.getAllCheckerForms();
                                    }
                                    swal.fire(response.data.msg, { icon: response.data.status });
                                })
                                .catch((error) => {
                                    swal.fire("An Error Occured", error, "error");
                                });
                        }
                    });
            }
        },
        closeView(action = false) {
            this.viewCheckerFormId = 0;
            if (
                action == "convertSuccess" ||
                action == "updateStatusSuccess" ||
                action == "printSuccess"
            ) {
                this.getAllCheckerForms();
            }
        },
        closeConfirmOverride(user_id, authenticated = false) {
            this.override.approve_override_delete_dialog = false;
            if (authenticated) {
                this.override.override_authenticated = true;
                this.deleteCheckerForm(this.currentCheckerFormId);
            }
        },
        searchInput() {
            this.getAllCheckerForms({ query: this.search });
        },
        checkAccess() {
            // this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id).then(response=>{
            let val = this.user_access;
            val.forEach((e) => {
                if (e.actions_code == "edit") {
                    this.userAccess.edit = true;
                } else if (e.actions_code == "delete") {
                    this.userAccess.delete = true;
                } else if (e.actions_code == "view") {
                    this.userAccess.view = true;
                }else if (e.actions_code == "cf_print") {
                    this.userAccess.cf_print = true;
                }
            });
            // });
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e => {
                    this.date_range_filter[0] = atob(this.$route.query.currentYear);
                })
                this.getAllCheckerForms();
            }
        },
        printPDF(num){
            if(num == 1){
                this.before_print_dialog = true
                this.selected_for_print_pdf.forEach(e => {
                    e.remarks = '',
                    e.complete_date = '';
                });
            }
            if(num !== 1){
                const doc = new jsPDF("p", "mm", "letter");
                let ingcoPhLogo = new Image();
                ingcoPhLogo.src = "/images/ingcoph-logo.png";
                doc.addImage(ingcoPhLogo, "png", 10, 12.7, 120, 20);
                doc.setFontSize(14);
                doc.line( 7, 35, 7, 10)
                doc.line( 150, 35, 150, 10)
                doc.line( 209, 35, 209, 10)
                doc.line(7, 10, 209, 10)
                doc.autoTable({
                    startY: 35,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        textColor: '#FF0000',
                        halign: 'center',
                        // cellWidth: 143
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth: 143, fontStyle:'bold'}, 1: {cellWidth:59, fontStyle:'bold'}},
                    columns: [
                        { header: 'TRANSMITTAL FORM', dataKey: 'TRANSMITTAL FORM' },
                        { header: `TRANSMITTAL#${this.saved_tf}`, dataKey: '' },
                    ],
                    body: [{}],
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#FFFFFF',
                        lineColor: '#FFFFFF',
                    },

                })
                // let lastAutoTableY = doc.lastAutoTable.finalY
                doc.autoTable({
                    startY: 42.5,
                    theme: 'plain',
                    styles: {
                        lineWidth: 0.1,
                        lineColor: '#000000',
                        halign: 'center',
                    },
                    bodyStyles: {
                        fillColor: false,
                        textColor: '#000000',
                    },
                    margin: {left: 7, right: 6},
                    columnStyles: { 0:{cellWidth:83}, 1: {cellWidth:15}, 2: {cellWidth:15}, 3: { cellWidth:30}, 4: {cellWidth:59, halign: 'center'}},
                    body: this.selected_for_print_pdf.reduce((row, detail)=>{
                        row.push(Object.assign({},{
                            cf:                     detail.cf,
                            so:                     detail.so,
                            customer:               detail.customer,
                            date:                   detail.complete_date,
                            remarks:                detail.remarks
                        }));
                        return row;
                    }, []),
                    columns: [
                        { header: 'CUSTOMER', dataKey: 'customer' },
                        { header: 'SO#', dataKey: 'so' },
                        { header: 'CF#', dataKey: 'cf' },
                        { header: 'RELEASE DATE & TIME', dataKey: 'date' },
                        { header: 'REMARKS', dataKey: 'remarks'},
                    ],
                })
                doc.save('CF Print.pdf')
                this.dataTable = [];
            }
        },
        async applyToAll(data, num){
            if(data.complete_date !== null && data.complete_date !== '' && this.show_again_date == 0 && num === 1){
              await swal.fire({
                icon: "question",
                text: "Do you want to apply this date on other selected CF?",
                input: "checkbox",
                inputPlaceholder: "Don't show this again",
                inputValue: this.show_again_date,
                reverseButtons:true,
                showDenyButton:true,
                returnInputValueOnDeny:true,
                confirmButtonColor: "#397373",
                denyButtonColor: "grey",
                inputValidator: (r) => {
                    this.show_again_date = r
                }
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let selected = this.selected_for_print_pdf.map(e => e);
                        selected.forEach((e) => {
                            if(e.complete_date === '') {
                                e.complete_date = data.complete_date;
                            }
                        });
                    }
                });
            }
            if(data.remarks !== null && data.remarks !== '' && this.show_again_remarks == 0 && num === 2){
             await swal.fire({
                icon: "question",
                text: "Do you want to apply this remarks on other selected CF?",
                input: "checkbox",
                inputPlaceholder: "Don't show this again",
                inputValue: this.show_again_remarks,
                reverseButtons:true,
                showDenyButton:true,
                returnInputValueOnDeny:true,
                confirmButtonColor: "#397373",
                denyButtonColor: "grey",
                inputValidator: (r) => {
                    this.show_again_remarks = r
                }
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let selected = this.selected_for_print_pdf.map(e => e);
                        selected.forEach((e) => {
                            if(e.remarks === '') {
                                e.remarks = data.remarks;
                            }
                        });
                    }
                });
            }
        },
        submitInput(){
            let payload = {
                url: '/savePtPrintDetails',
                items: this.selected_for_print_pdf,
                from: 2
            }
            this.$store.dispatch('partsTransferPost', payload).then((response)=>{
                this.saved_tf = response.data.series
                this.printPDF(2);
                swal.fire('Save and Print Successful', '', 'success')
            });
            this.closeDialog();
        },
        closeDialog(){
            this.before_print_dialog = false;
            this.show_again_date = 0;
            this.show_again_remarks = 0;
        },
        getTransmittal(){
            // this.loaded = false;
            // this.transmittalRecords = [];
            // let payload = {
            //     url: 'getPtPrintDetails',
            //     from: 2,
            //     page: this.options.page,
            //     itemsPerPage: this.options.itemsPerPage,
            //     search: this.transmittal.search.replace(/^0+/, '')
            // }
            // this.$store.dispatch('partsTransferGet', payload).then((response)=>{
            //     let res = response.data.data
            //     this.transmittalRecords = res.data
            //     this.totalTransmittalRecords = res.total
            //     this.loaded = true;
            // })
        },
        exportTransmittal(){
            // let that = this;
            // that.loaded = false;
            // that.transmittalRecords = [];
            // let payload = {
            //     url: 'exportTransmittal',
            //     print_type: 'CF'
            // }
            // const workbook = new ExcelJS.Workbook();
            // that.$store.dispatch('partsTransferGet', payload).then((response) => {
            //     let export_data = response.data;
            //     const worksheet = workbook.addWorksheet("Transmittal Sheet");
            //         worksheet.columns = [
            //             { header: "Transmittal#", width: 12, key: "transmittal_num" },
            //             { header: "CF#", width: 8, key: "module_id" },
            //             { header: "SO#", width: 8, key: "order_num" },
            //             { header: "Customer Name", width: 25, key: "customer_name" },
            //             { header: "Warehouse", width: 11, key: "warehouse" },
            //             { header: "Release Date", width: 13,  key: "release_date_time" },
            //         ];

            //     const headerRow = worksheet.getRow(1);
            //     headerRow.eachCell((cell) => {
            //         cell.font = { bold: true };
            //         cell.alignment = { horizontal: 'center' };
            //     });

            //      worksheet.eachRow((row) => {
            //         row.eachCell((cell) => {
            //             cell.alignment = { horizontal: 'center' };
            //         });
            //     });

            //     export_data.forEach((detail) => {
            //         worksheet.addRow(detail)
            //     })
            //     workbook.xlsx.writeBuffer().then((data) => {
            //         const blob = new Blob([data], {
            //             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            //         });
            //         FileSaver.saveAs(blob, `CF Transmittal.xlsx`);
            //     });
            //     that.getTransmittal();
            //     that.loaded = true;
            // })
        }
    },
    mounted() {
        this.checkAccess();
        this.getSearchFromUrl();
        // this.getTransmittal();
    },
    watch: {
        checkerFormsActionResult: function (val) {
            if (this.checkerFormsActionResult == "success") {
                this.getAllCheckerForms();
            }
        },
        options: {
            handler() {
                this.getAllCheckerForms();
                this.getTransmittal();
            },
            deep: true,
        },
        status_id(val) {
            this.showCheckbox = val === -1 ? false : true;
        },
        // user_access:{
        //     handler(val){
        //         console.log(val)
        //         val.forEach(e => {
        //             if (e.actions_code == 'edit') {
        //                 this.userAccess.edit = true;
        //             } else if (e.actions_code == 'delete') {
        //                 this.userAccess.delete = true;
        //             } else if (e.actions_code == 'view') {
        //                 this.userAccess.view = true;
        //             }
        //         });
        //     }
        // }
    },
    computed: {
        ...mapGetters([
            "GET_CUSTOMERS_LIST_SELECTION",
            "GET_ALL_USERS_SELECT",
            "USER_ACCESS",
        ]),
        date_range_filter_text() {
            return this.date_range_filter.values.join("  to  ");
        },
    },
};
</script>
<style scoped>
</style>
