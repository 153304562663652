<template>
     <div class="tabs-body-color ma-0 pa-0">
        <v-btn class="my-3" @click="openAddEditDialog()" v-if="userAccess.create_type">
            Add
        </v-btn>
    <v-container class="ma-0 pa-0">
        <v-dialog v-model="debit_memo.dialog" width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="text-lg font-semibold" v-text="`Add New Debit Memo Type`"></span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeAddEditDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                            v-model="debit_memo.data.code"
                            outlined
                            dense
                            hide-details
                            label="Code"
                            aria-required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                            v-model="debit_memo.data.name"
                            outlined
                            dense
                            hide-details
                            label="Name"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn v-if="action == 'add'" @click="saveNewDebitMemoType()">
                        Submit
                    </v-btn>
                    <v-btn v-else-if="action == 'edit'" @click="updateDebitMemoType()">
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DebitMemoTypeTableComponent ref="refreshTable" @showEditDialog="showEditDialog"></DebitMemoTypeTableComponent>
    </v-container>
    </div>
</template>

<script>
import swal from 'sweetalert2';
import DebitMemoTypeTableComponent from './DebitMemoTypeTableComponent.vue';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            debit_memo:{
                dialog:false,
                data:{
                    code:'',
                    name:'',
                },
            },
            action:'',
            editedDebitMemoTypeId:'',
            userAccess:{
                create_type:false
            }
        }
    },
    methods:{
        openAddEditDialog(){
            this.action = 'add'
            this.resetFields(this.debit_memo.data)
            this.debit_memo.dialog = true;
        },
        closeAddEditDialog(){
            this.debit_memo.dialog = false;
        },
        saveNewDebitMemoType(){
            if(this.isCompleteData(this.debit_memo.data)){
                let payload = {
                    data:this.debit_memo.data,
                    url:'saveNewDebitMemoType'
                }
                this.$store.dispatch('postDebitMemoType',payload).then(response=>{
                    swal.fire(response.data.msg,'',response.data.icon)
                    this.closeAddEditDialog();
                    this.$refs.refreshTable.getAllDebitMemoType();
                })
            }
            else{
                swal.fire('Please Fill out Information','','warning')
            }
        },
        updateDebitMemoType(){
            if(this.isCompleteData(this.debit_memo.data)){
                let payload = {
                    data:this.debit_memo.data,
                    id:this.editedDebitMemoTypeId,
                    url:'updateDebitMemoType'
                }
                this.$store.dispatch('postDebitMemoType',payload).then(response=>{
                    swal.fire(response.data.msg,'',response.data.icon)
                    this.closeAddEditDialog();
                    this.resetFields(this.debit_memo.data)
                    this.$refs.refreshTable.getAllDebitMemoType();
                })
            }
            else{
                swal.fire('Please Fill out Information','','warning')
            }
        },
        resetFields(object){
            for (const key in object) {
                let val = ''
                if (typeof object[key] == 'string') val = '';
                if (typeof object[key] == 'boolean') val = false;
                if (typeof object[key] == 'object') val = [];
                if (typeof object[key] == 'number') val = 0;
                object[key] = val
            }
        },
        isCompleteData(data){
            let hasMissingData = false
            for(const key in data) {
                if(data[key] === "") {
                    hasMissingData = true
                }  
            }
            return !hasMissingData
        },
        showEditDialog(id){
            this.action = 'edit'
            this.editedDebitMemoTypeId = id
            if(id != null && id > 0){
                let payload = {
                    id:id,
                    url:'viewDebitMemoType'
                }
                this.$store.dispatch('getDebitMemoType',payload).then(response=>{
                    this.debit_memo.data = response.data
                    this.debit_memo.dialog=true
                })
            }
        }
    },
    components:{
        DebitMemoTypeTableComponent
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id);
    },
    watch:{
        USER_ACCESS:{
            handler(val){
                this.userAccess.create_type = false;
                if (val != "fail") {
                    val.forEach((e) => {
                        if (e.actions_code == "create_type") {
                            this.userAccess.create_type = true;
                        }
                    });
                }
            }
        }
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    }
}
</script>

<style>

</style>