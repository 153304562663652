<template>
   <v-container fluid>
        <header-component
            :button="true"
            :filterd="filters"
            :title="'Sales By Classifications'"
            :type="'Sales'"
        ></header-component>
        <ReportBodyComponent
            totals
            :headers="classifications.headers"
        >
        <template #first-row>
            <v-card-text>
                <v-row>
                    <v-col lg="3">
                        <label class="float-left control-label col-form-label">Date From</label>
                        <v-menu
                            v-model="filters.date_from_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_from" @input="filters.date_from_menu = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                    <v-col lg="3">
                        <label class="float-left control-label col-form-label">Date To</label>
                        <v-menu
                            v-model="filters.date_to_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_to"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filters.date_to" @input="filters.date_to_menu = false"></v-date-picker>
                        </v-menu>
                        <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
        </template>
    </ReportBodyComponent>
   </v-container>
</template>

<script>
    import HeaderComponent from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    import moment from 'moment';
    import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
    import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
    import axios from 'axios';
    import swal from 'sweetalert2'
    const ExcelJS = require('exceljs')
    const FileSaver = require('file-saver');
    export default {
        data(){
            return {

                loaded: true,
                filters:{
                    date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                    date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                    date_from_menu:false,
                    date_to_menu:false,
                },

                errors:[],

                piechart: {},

                lineChart:{
                    series: [{
                        name: "Desktops",
                        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                    }],
                    chartOptions: {
                        xaxis: {
                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                        }
                    },
                },
                sales:{
                    net_sales_total_amount:0,
                    gross_sales_total_amount:0,
                    net_adjustment:0,
                },
                classifications:{
                    data:[],
                    headers: [
                        { text: 'Customer', align: 'left',width:'40%', value: 'name', },
                        { text: 'Classification', align: 'left', value: 'classification', },
                        { text: 'Salesman', align: 'left', value: 'salesman', },
                        { text: 'Total Amount', align: 'right', value: 'total_amount', },
                    ],
                    totalCustomerCount:0,
                    line_chart:[],
                    filtered: false,
                    hasData:false,
                }
            }
        },
        components: {
            SharedFunctionsComponent,
            HeaderComponent,
            ReportBodyComponent
        },
        mounted(){
            this.getFilters()
            this.$store.commit('FILTER_VALUE',this.getFilters())
        },

        methods: {
            list(){
                let that = this

                that.loaded = false
                let series = []
                let labels = []
                let linechart_series = []
                let linechart_date = []

                let data = {
                        date_from: this.filters.date_from,
                        date_to: this.filters.date_to,
                        filename: 'Sales By Classifications',
                        url: 'sales-by-classification/multi-table',
                    }

                this.$store.dispatch('reports',data).then(response=>{
                    if(response.data.data.reduce((a, b) => +a + +b.customer_count, 0) == 0){
                        swal.fire('No Data', '', 'error')
                        that.classifications.hasData = false;
                        that.loaded = true
                        return false
                    }

                    that.classifications.hasData = true;

                    that.classifications.totalCustomerCount = response.data.data.reduce((a, b) => +a + +b.customer_count, 0)
                    that.sales.net_adjustment               = response.data.adjustments_total_amount
                    that.sales.gross_sales_total_amount     = response.data.gross_sales_total_amount
                    that.sales.net_sales_total_amount       = response.data.net_sales_total_amount
                    that.classifications.data               = response.data.data

                    that.classifications.data.forEach(e => {
                        series.push(parseFloat(e.total_per_page))
                        labels.push(e.name)
                        let handler = {
                            data:[],
                            name:e.code
                        };

                        response.data.date_array.forEach(dates => {
                                let total = 0;
                                e.line_chart.forEach(date=>{
                                    if(response.data.date_type == 'daily'){
                                        if(Date.parse(date.date) >= Date.parse(dates[0]) && Date.parse(date.date) <= Date.parse(dates[1])){
                                            total += date.total_amount
                                        }
                                    }else{
                                        if(response.data.date_type == 'monthly'){
                                            if(new Date(date.date).getMonth() == new Date(dates).getMonth()){
                                                total += date.total_amount
                                            }
                                        }else{
                                            if(new Date(date.date).getFullYear() == new Date(dates).getFullYear()){
                                                total += date.total_amount
                                            }
                                        }
                                    }

                                });
                                handler.data.push(total)

                                if(response.data.date_type == 'daily'){
                                      linechart_date.push(new Date(dates[0]).getDate() + '-' + new Date(dates[1]).getDate())
                                }else{
                                    if(response.data.date_type == 'monthly'){
                                        linechart_date.push(new Date(dates).getMonth() == 0 ? 12 : new Date(dates).getMonth())
                                    }
                                    if(response.data.date_type == 'year'){
                                        linechart_date.push(new Date(dates).getFullYear())
                                    }
                                }
                            })

                        linechart_series.push(handler)
                    });

                    that.piechart = {
                        series:series,
                        labels:labels,
                        tooltip:{
                            y:{
                                formatter: (val) => {
                                    if (val !== '' || val !== undefined || val !== 0 || val !== '0' || val !== null) {
                                        return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                    } else {
                                        return 0
                                    }
                                },
                            }
                        },
                        colors:['#F76E11','#FF9F45','#FFBC80','#FC4F4F','#313552','#B8405E','#2EB086','#EEE6CE','#219F94','#C1DEAE','#F2F5C8','#E8E8A6','#1A1A40','#270082', '#7A0BC0','#FA58B6','#FFE162','#FF6464','#91C483'],
                        markers:{
                            colors:['#F76E11','#FF9F45','#FFBC80','#FC4F4F','#313552','#B8405E','#2EB086','#EEE6CE','#219F94','#C1DEAE','#F2F5C8','#E8E8A6','#1A1A40','#270082', '#7A0BC0','#FA58B6','#FFE162','#FF6464','#91C483'],
                        },
                        fill:{
                            colors: ['#F76E11','#FF9F45','#FFBC80','#FC4F4F','#313552','#B8405E','#2EB086','#EEE6CE','#219F94','#C1DEAE','#F2F5C8','#E8E8A6','#1A1A40','#270082','#7A0BC0','#FA58B6','#FFE162','#FF6464','#91C483'],
                        },
                    }

                    that.lineChart = {
                        series:linechart_series,
                        xaxis: {
                            categories: linechart_date,
                        },
                    tooltip:{
                            y:{
                                formatter: (val) => {
                                    if (val !== '' || val !== undefined || val !== 0 || val !== '0' || val !== null) {
                                        return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                    } else {
                                        return 0
                                    }
                                },
                            }
                    },
                    legends:{
                            tooltip:{
                                y:{
                                    formatter: (val) => {
                                        if (val !== '' || val !== undefined || val !== 0 || val !== '0' || val !== null) {
                                            return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                        } else {
                                            return 0
                                        }
                                    },
                                },
                                x:{
                                    formatter: (val) => {
                                        if (val !== '' || val !== undefined || val !== 0 || val !== '0' || val !== null) {
                                            return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                        } else {
                                            return 0
                                        }
                                    },
                                }
                            },
                    },
                        yaxis:{
                            labels:{
                                formatter: (val) => {
                                    if (val !== '' || val !== undefined || val !== 0 || val !== '0' || val !== null) {
                                        return val.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                                    } else {
                                        return 0
                                    }
                                },
                            }
                        },
                        colors:['#F76E11','#FF9F45','#FFBC80','#FC4F4F','#313552','#B8405E','#2EB086','#EEE6CE','#219F94','#C1DEAE','#F2F5C8','#E8E8A6','#1A1A40','#270082', '#7A0BC0','#FA58B6','#FFE162','#FF6464','#91C483'],
                        markers:{
                            colors:['#F76E11','#FF9F45','#FFBC80','#FC4F4F','#313552','#B8405E','#2EB086','#EEE6CE','#219F94','#C1DEAE','#F2F5C8','#E8E8A6','#1A1A40','#270082', '#7A0BC0','#FA58B6','#FFE162','#FF6464','#91C483'],
                        },
                        fill:{
                            colors: ['#F76E11','#FF9F45','#FFBC80','#FC4F4F','#313552','#B8405E','#2EB086','#EEE6CE','#219F94','#C1DEAE','#F2F5C8','#E8E8A6','#1A1A40','#270082','#7A0BC0','#FA58B6','#FFE162','#FF6464','#91C483'],
                        },
                    }
                    that.classifications.filtered = true;
                    that.loaded = true
                })
            },
            getFilters(){
                let data = {
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                    filename: 'Sales By Classifications',
                    url: 'sales-by-classification/multi-table',
                    headers: [
                        { text: 'Customer', align: 'left',width:'40%', value: 'name', },
                        { text: 'Classification', align: 'left', value: 'classification', },
                        { text: 'Salesman', align: 'left', value: 'salesman', },
                        { text: 'Total Amount', align: 'right', value: 'total_amount', },
                    ],
                }

                return data
            },

            async exportSalesByClassificationToExcel(){
              let that = this
              that.loaded = false
                this.$store.dispatch('reports',{
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                    url: 'sales-by-classification/multi-table',
                }).then(response=>{
                    let data = []
                    const workbook = new ExcelJS.Workbook()
                    const worksheet = workbook.addWorksheet('Sales By Classification')

                    response.data.data.forEach(e=>{
                        e.data.map(element=>{

                            data.push(element)
                        })
                    })

                    worksheet.columns = [
                        { header:'Customers',key: 'name' },
                        { header:'Classifications',key: 'classification' },
                        { header:'salesman',key:'salesman'},
                        { header:'Total Amount',key: 'total_amount',style:{
                          numFmt: '#,###.#0'
                        }},
                    ]

                    data.forEach(e=>{
                      e.total_amount = parseInt(e.total_amount)
                      worksheet.addRow(e)
                    })

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        FileSaver.saveAs(blob, `Sales By Classification ${that.filters.date_from} - ${that.filters.date_to}.xlsx`);
                    });

                  that.loaded = true
                })
                // if(this.loaded){
                //     new Promise(resolve=>{
                //         this.chartURI1 = this.$refs.pieImg.chart.dataURI().then(uri => {
                //             var imgURI = uri.imgURI
                //             var blob = uri.blob
                //             if (blob) {
                //                 navigator.msSaveOrOpenBlob(blob,'.png');
                //             } else {
                //                 resolve(this.collectImgs(imgURI,'Piechart'))
                //             }
                //         })
                //     })

                //     new Promise(resolve=>{
                //         this.chartURI2 = this.$refs.lineImg.chart.dataURI().then(uri => {
                //             var imgURI = uri.imgURI
                //             var blob = uri.blob
                //             if (blob) {
                //                 navigator.msSaveOrOpenBlob(blob,'.png');
                //             } else {
                //                 resolve(this.collectImgs(imgURI,'Linechart'))
                //             }
                //         })
                //     })
                // }else{
                //   swal.fire('No data to export','Please filter data first','error')
                // }
            },
            async collectImgs(val,fileName){

                await axios.post('/saveSalesByClassificationsChartImgs',{
                        imgURI      : val,
                        fileName    : fileName
                    }).then(response=>{
                        // window.open(`/exportSalesByClassificationToExcel/?date_from=${this.date_from}&date_to=${this.date_to}`,'_)blank')
                    })

                    return true;
            },

            getFilters(){
                let data = {

                        date_from: this.filters.date_from,
                        date_to: this.filters.date_to,
                        filename:'Sales By classifications',
                        url: 'sales-by-customer-detailed',
                        headers: [
                            { text: 'Customer', align: 'left',width:'40%', value: 'name', },
                            { text: 'Classification', align: 'left', value: 'classification', },
                            { text: 'Salesman', align: 'left', value: 'salesman', },
                            { text: 'Total Amount', align: 'right', value: 'total_amount', },
                        ],
                    }
                return data
            },
            resetFields(){
                this.errors                     = []
                this.loaded                     = true
                this.classifications.filtered   = false
                this.filters.date_from                  = moment().clone().startOf('month').format('YYYY-MM-DD')
                this.filters.date_to                    = moment().clone().endOf('month').format('YYYY-MM-DD')
            },
        },
        watch:{
            'filters':{
                handler(val){
                    this.$store.commit('FILTER_VALUE',this.getFilters())
                },
                deep:true
            }
        }
    };
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
