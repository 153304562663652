<template>
    <v-container fluid>

        <ComponentHeader
            :button="true"
            :title="'Sales Quotations'"
            :type="'Inventory'"
        ></ComponentHeader>

            <v-container fluid class="bg-bluish-gray default-container">
                <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>
                    <v-row class="pa-2">
                        <v-col lg="3">

                            <v-autocomplete :items="GET_CUSTOMERS_LIST_SELECTION"
                            :loading="loading.customer_selection"
                            :disabled="loading.customer_selection"
                            v-model="selectedCustomers"
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            return-object
                            outlined
                            dense
                            item-value="value"
                            item-text="text"
                            background-color="white"

                                label="Select Customers"
                                @select="onSelectCustomers"
                                class="small"
                                >
                            </v-autocomplete>
                        </v-col>
                        <v-col lg="3">

                            <v-autocomplete outlined dense :items="GET_SALESMAN_SELECTION"
                                :loading="loading.salesman_selection"
                                :disabled="loading.salesman_selection"
                                v-model="salesman_id"
                                item-value="value"
                                item-text="text"
                                background-color="white"
                                label="Select Salesman"
                                class="small"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col lg="3">

                            <v-autocomplete outlined dense :items="GET_ALL_USERS_SELECT"
                                    :loading="loading.user_selection"
                                    :disabled="loading.user_selection"
                                    v-model="user_id"
                                    item-value="id"
                                    item-text="name"
                                    label="Select User"
                                    background-color="white"
                                >
                            </v-autocomplete>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn small class="ml-1" :loading="!loaded" v-on:click="resetFields()">CLEAR</v-btn>
                            <v-btn small class="ml-1" :loading="!loaded" v-on:click="exportSalesQuotation()">EXPORT</v-btn>
                            <v-btn small class="ml-1" :loading="!loaded" v-on:click="getSalesQuotation()">FILTER</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="ml-2" lg="3" id="date_from">
                            <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date_from"
                                        readonly
                                        outlined
                                        dense
                                        background-color="white"
                                        label="Date From"
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                            </v-menu>
                            <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </v-col>
                        <v-col lg="3" id="date_to">

                            <v-menu
                                v-model="menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date_to"
                                        readonly
                                        outlined
                                    dense
                                    background-color="white"
                                    label="Date To"
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                            </v-menu>
                            <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </v-col>
                        <v-col lg="3">
                        <v-autocomplete outlined dense :items="filterOption"
                                background-color="white"
                                v-model="selectedOption"
                                item-value="value"
                                item-text="text"
                                label="Select Status"
                                class=" small"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <div class="w-100"  v-if="dataItems != ''">
                        <v-card v-show="loaded">

                            <v-container fluid class="mt-4">

                                <div class="container" v-for="(d, i) in dataItems" :key="i" v-show="loaded">
                                    <div class="row">
                                        <div class="col-12 d-flex no-block align-items-cnter">
                                            <h6 class="report-title">Month of {{ d.month_name }} {{ d.year }}</h6>
                                        </div>
                                    </div>

                                    <v-app id="my_vue_app">
                                        <v-card>
                                            <v-container fluid>
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="d.sales_quotation"
                                                    :items-per-page="10"
                                                    class="w-100"
                                                    dense
                                                >
                                                <template v-slot:[`item.total_amount`] = "{item}">
                                                    {{ item.total_amount | currency }}
                                                </template>
                                                <template v-slot:[`item.created_at`] = "{item}">
                                                    {{ item.created_at | formatDateTime }}
                                                </template>
                                                <template v-slot:[`item.action`]="{ item }">
                                                    <span class="text-nowrap">
                                                        <v-btn text icon color="orange" :loading="btn_loader">
                                                            <v-icon class="btn-action" small @click="viewSalesQuotation(item.id)">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </span>
                                                </template>
                                                <template v-slot:[`item.status_text`]="{ item }">
                                                    <span class="badge badge-saved" v-if="item.status_text=='saved'">{{ item.status_text }}</span>
                                                    <span class="badge badge-purple" v-else-if="item.status_text=='converted'">{{ item.status_text }}</span>
                                                </template>
                                              </v-data-table>
                                            </v-container>
                                        </v-card>
                                    </v-app>
                                </div>
                            </v-container>
                        </v-card>
                    </div>

                    <v-dialog v-model="view_item_dialog" persistent max-width="100%" scrollable>
                        <v-card>
                            <v-row class="m-0">
                                <v-card-title>
                                    <span class="headline">Sales Quotation Details</span>
                                </v-card-title>
                                <v-col cols="pull-right-10 p-2">
                                    <v-btn text icon color="gray" class="float-right" @click="clearSalesQuotationItems(); $emit('closeView');">
                                        <v-icon>mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card-text>
                                <v-row class="m-0">
                                    <v-container fluid>
                                        <v-row>
                                            <div class="col-sm-12 col-md-8 text-left text-dark">
                                                <div class="row ml-2">
                                                    <div class="col-sm-12 col-md-8 p-0">{{ `${ !!sales_quotation.service ? 'Spare Parts' : 'Sales' } Quotation No: ${sales_quotation.quote_num}` }}</div>
                                                    <div class="col-sm-12 col-md-4 p-0">Issuer: {{ sales_quotation.issuer_name }}</div>
                                                </div>
                                                <div class="row ml-2">
                                                    <div class="col-sm-12 col-md-8 p-0">Customer: {{ sales_quotation.customer_name }}</div>
                                                    <div class="col-sm-12 col-md-4 p-0">Date Issued: {{ sales_quotation.created_at | formatDateTime }}</div>
                                                </div>
                                                <div class="row ml-2">
                                                    <div class="col-sm-12 col-md-8 p-0">Remarks: {{ sales_quotation.remarks }}</div>
                                                    <div class="col-sm-12 col-md-4 p-0"  v-if="sales_quotation.convert_date != null">Convert Date: {{ sales_quotation.convert_date | formatDateTime }}</div>
                                                </div>
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-row>

                                <v-data-table
                                    :headers="selectedHeaders"
                                    :items="salesQuotationItems"
                                    :items-per-page="500"
                                    hide-default-footer
                                    class="w-100"
                                >
                                    <template v-slot:[`item.foc`]="{ item }">
                                        <v-tooltip :disabled="!item.foc" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <td
                                                    class="side-borders-white px-0"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <span v-if="item.foc=='1'">Yes</span>
                                                    <span v-else-if="item.foc=='0'">No</span>
                                                </td>
                                            </template>
                                            <span>{{ item.foc_remarks }}</span>
                                        </v-tooltip>
                                    </template>

                                    <template v-slot:[`item.display_disc_price`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.display_disc_price | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.display_srp`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.display_srp | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.display_final_price`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.display_final_price | currency }}
                                        </span>
                                    </template>

                                    <template v-slot:[`item.display_total_amount`]="{ item }">
                                        <span class="text-nowrap">
                                            &#x20b1;{{ item.display_total_amount | currency }}
                                        </span>
                                    </template>

                                </v-data-table >

                                <v-row class="m-0">
                                    <v-container fluid>
                                        <v-row>
                                            <div class="col-sm-12 col-md-12 pull-right text-right text-dark">
                                                Total Amount: &#x20b1;{{ sales_quotation.total_amount | currency }}
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-dialog>


            </v-container>

    </v-container>
</template>

<script>
    import { saveAs } from 'file-saver';
    const ExcelJS = require('exceljs')
    const FileSaver = require('file-saver');
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue';
export default {
    components: {
        ComponentHeader
    },
    mixins: [ SharedFunctionsComponentVue ],
    data(){
        return {

            date_from: moment().clone().startOf('month').format('2021-01-DD'),
            date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
            menu2: false,
            menu3: false,

            headers: [
                {
                    text: 'Quote Num',
                    align: 'start',
                    width:"10%",
                    sortable: false,
                    value: 'quote_num',
                },
                { text: 'Customer', width:"15%", align:'left', value: 'customer_name' },
                { text: 'PO Num', width:"8%", value: 'customer_po_num' },
                { text: 'Remarks', width:"18%", value: 'remarks' },
                { text: 'Salesman', width:"7%", value: 'salesman_name' },
                { text: 'Amount', width:"7%", value: 'total_amount' },
                { text: 'Status', width:"7%", value: 'status_text' },
                { text: 'Date Issued', width:"13%", value: 'created_at' },
                { text: 'Issuer', width:"10%", value: 'issuer_name' },
                { text: 'Action', width:"5%", value: 'action' }
            ],

            view_item_dialog: false,
            selectedHeaders: [],
            sales_quotation:[],
            salesQuotationItems:[],
            salesQuotationItemsHeaders: [
                { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                { text: 'FOC', align: 'left', value: 'foc', sortable: false},
                { text: 'SRP', align: 'left', value: 'display_srp', sortable: false},
                { text: 'Disc Price', align: 'left', value: 'display_disc_price', sortable: false},
                { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                { text: 'Price', align: 'right', value: 'display_final_price', width: '15%', sortable: false},
                { text: 'Total', align: 'right', value: 'display_total_amount', width: '15%', sortable: false},
            ],

            stockCardSalesQuotationItemsHeaders: [
                { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false},
                { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false},
                { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false},
                { text: 'FOC', align: 'left', value: 'foc', sortable: false},
                { text: 'SRP', align: 'left', value: 'display_srp', sortable: false},
                { text: 'Disc Price', align: 'left', value: 'display_disc_price', sortable: false},
                { text: 'Qty', align: 'left', value: 'quantity', sortable: false},
                { text: 'SC Qty', align: 'left', value: 'sc_quantity', sortable: false},
                { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false},
                { text: 'Price', align: 'right', value: 'display_final_price', width: '15%', sortable: false},
                { text: 'Total', align: 'right', value: 'display_total_amount', width: '15%', sortable: false},
            ],


            selectedOption:-1,
            filterOption:
            [
                { value: 0, text: 'Saved' },
                { value: 1, text: 'Converted' },
                { value: -1, text: 'All' }
            ],

            selectedCustomers:[],
            customers_list:[],
            salesman_list:[],
            salesman_id:'',
            users_list:[],
            user_id:'',
            errors:[],

            loaded:true,
            dataItems:[],

            btn_loader:false,
            service: 0,

            loading:{
                customer_selection      : true ,
                salesman_selection      : true ,
                user_selection          : true
            }
        }
    },
    mounted: function (){
        // this.getCustomers()
        this.$store.dispatch('getAllCustomers')
        this.$store.dispatch('getAllSelectUsers')
        this.$store.dispatch('getAllsalesmanSelection')
        // this.getSalesmanList()
        // this.getUsers()

    },
    computed:{
        ...mapGetters([
            'GET_CUSTOMERS_LIST_SELECTION','GET_SALESMAN_SELECTION','GET_ALL_USERS_SELECT'
        ]),
    },
    methods:{
        getSalesQuotation(){
            let filters = {
                    date_from:          this.date_from,
                    date_to:            this.date_to,
                    customer_id:        this.selectedCustomers.map(e=>{return e.value}),
                    salesman_id:        this.salesman_id,
                    user_id:            this.user_id,
                    status_id:          this.selectedOption,
                    url:                'getSalesQuotation'
            }

            this.loaded = false
            this.$store.dispatch('reports',filters).then(response => {
                this.dataItems = response.data
                this.loaded    = true
            }).catch(err=>{
                console.log(err)
            });
        },
        viewSalesQuotation(id){
            this.btn_loader = true
            this.$store.dispatch('viewSalesQuotation',id).then(response => {
                let data = response.data

                if(data.error){
                    swal.fire("", data.error, "warning");
                    return false
                }

                this.sales_quotation     = data.sales_quotation
                this.salesQuotationItems = data.sales_quotation.sales_quotation_items

                if(data.sales_quotation.stock_card){
                    this.selectedHeaders = this.stockCardSalesQuotationItemsHeaders
                }else{
                    this.selectedHeaders = this.salesQuotationItemsHeaders
                }

                this.view_item_dialog = true
                this.btn_loader = false

            })
        },
        exportSalesQuotation(){
            let from = 'Report'
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Sales Quotation Report');
            this.loaded = false
            this.$store.dispatch('reports',{
                url:'exportSalesQuotation',
                customer_id:this.selectedCustomers.map(e=>{return e.value}),
                salesman_id:this.salesman_id,
                user_id: this.user_id,
                date_from: this.date_from,
                date_to: this.date_to,
                from:from,
                status:this.selectedOption
            }).then(response =>{
                let data = response.data.data

                worksheet.columns = [
                    { header: 'QUOTE NUM', key: 'quote_num' },
                    { header: 'CUSTOMER', key: 'customer_name' },
                    { header: 'PO NUM', key: 'customer_po_num' },
                    { header: 'REMARKS', key: 'remarks' },
                    { header: 'SALESMAN', key: 'salesman_name' },
                    { header: 'AMOUNT', key: 'total' },
                    { header: 'STATUS', key: 'status_text' },
                    { header: 'DATE ISSUED', key: 'created_at' },
                    { header: 'ISSUER', key: 'issuer_name' },
                    { header: 'PROMO', key: 'promo_id' },
                    { header: 'SC', key: 'stock_card' },
                    { header: 'MODEL', key: 'model' },
                    { header: 'ITEM NAME', key: 'name' },
                    { header: 'TYPE', key: 'type' },
                    { header: 'FOC', key: 'foc' },
                    { header: 'SRP', key: 'srp' },
                    { header: 'DISC PRICE', key: 'disc_price' },
                    { header: 'QUANTITY', key: 'quantity' },
                    { header: 'UOM', key: 'uom' },
                    { header: 'FINAL PRICE', key: 'disc_price' },
                    { header: 'TOTAL', key: 'item_total' },
                ]

                data.forEach((e,i)=>{
                    e.promo_id = e.promo_id == 0 || e.promo_id == null ? 'NO' : 'YES';
                    e.foc = e.foc == 0 ? 'NO' : 'YES';
                    e.stock_card = e.stock_card == 0 ? 'NO' : 'YES';
                    worksheet.addRow(e)

                    //Date Formatting
                    let date = new Date(e.created_at);
                    let month = ('0' + (date.getMonth() + 1)).slice(-2);
                    let day = ('0' + date.getDate()).slice(-2);
                    let year = date.getFullYear();
                    let hour = ('0' + date.getHours()).slice(-2);
                    let minute = ('0' + date.getMinutes()).slice(-2);
                    let second = ('0' + date.getSeconds()).slice(-2);

                    let formattedDate = `${month}/${day}/${year} ${hour}:${minute}:${second}`;

                    //Date Issued
                    worksheet.getCell(`H${i + 2}`).value = formattedDate

                    //Amount
                    worksheet.getCell(`F${i + 2}`).value = parseFloat(e.total)
                    worksheet.getCell(`F${i + 2}`).numFmt = '#,##0.00'

                    //SRP
                    worksheet.getCell(`P${i + 2}`).value = parseFloat(e.srp)
                    worksheet.getCell(`P${i + 2}`).numFmt = '#,##0.00'

                    //DISC PRICE
                    worksheet.getCell(`T${i + 2}`).value = parseFloat(e.disc_price)
                    worksheet.getCell(`T${i + 2}`).numFmt = '#,##0.00'

                    //FINAL PRICE
                    worksheet.getCell(`Q${i + 2}`).value = parseFloat(e.disc_price)
                    worksheet.getCell(`Q${i + 2}`).numFmt = '#,##0.00'

                    //TOTAL
                    worksheet.getCell(`U${i + 2}`).value = parseFloat(e.item_total)
                    worksheet.getCell(`U${i + 2}`).numFmt = '#,##0.00'
                })

                worksheet.getRow(`0${1}`).font = { bold: true };


                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    saveAs(blob, `SalesQuotationReport From ${this.date_from} To ${this.date_to} .xlsx`);
                    this.loaded = true
                })

            }).catch(error=>{
                console.log(error)
                swal.fire('Error', ' - An error occured', 'error')
                });
        },
        clearSalesQuotationItems(){
            this.salesQuotationItems = []
            this.sales_quotation     = ''
            this.view_item_dialog    = false
        },
        resetFields(){
            this.customer_id         = ''
            this.salesman_id         = ''
            this.user_id             = ''
            this.date_from           = moment().clone().startOf('month').format('YYYY-MM-DD')
            this.date_to             = moment().clone().endOf('month').format('YYYY-MM-DD')
            this.selectedOption      = -1
            this.dataItems           = []
        },
        onSelectCustomers (items) {
            this.selectedCustomers = items
        },
        // getSalesmanList(){

        //         this.users_list = GET_ALL_USERS_SELECT

        // },
    },
    watch:{
        'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loading.customer_selection = false
            },
        },
        'GET_SALESMAN_SELECTION':{
            handler(val){
                this.loading.salesman_selection = false
            },
        },
        'GET_ALL_USERS_SELECT':{
            handler(val){
                this.loading.user_selection = false
            },
        }
    }

}
</script>

<style>

</style>
