
import salesquotationnew from '@/views/main/modules/Sales/SalesQuotations/SalesQuotationComponentMain'
import repository from '@/api/credentials'
const routes = [{
    path: '/sales/sales-quotation/:id',
    name: 'salesquotation',
    props: true,
    component: salesquotationnew,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales Quotations',
        service: "0",
    },
}];



export default routes;
