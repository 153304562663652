<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.refresher;
  },
  methods: {},
  computed: {
    ...mapGetters([
      "USERACCOUNT_LOAD",
      "LINK_ACTIVE",
    ]),
    refresher() {
      if(this.USERACCOUNT_LOAD.length != 0){
        this.$store.dispatch("credentials");
      }
    },
  },
};
</script>

