<template>
    <div>
        <v-app>
            <v-dialog v-model="container_discrepancy_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Container Discrepancy Details</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="clearContainerDiscrepancyItems();">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row class="m-0">
                            <v-container fluid>
                                <v-row>
                                    <div class="col-sm-12 col-md-12 text-left text-dark">
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-4 p-0">CD #: {{ container_discrepancies.container_discrepancy_num }}</div>
                                            <div class="col-sm-12 col-md-4 p-0">Remarks: {{ container_discrepancies.remarks }}</div>
                                            <div class="col-sm-12 col-md-4 p-0">Issuer: {{ container_discrepancies.created_by_name }}</div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-4 p-0">Date Issued: {{ container_discrepancies.created_at | formatDateTime }}</div>
                                            <div class="col-sm-12 col-md-4 p-0">Status: <span v-if=!!container_discrepancies.status :class="component_details.statuses[container_discrepancies.status].class">{{ component_details.statuses[container_discrepancies.status].name }}</span></div>
                                            <div class="col-sm-12 col-md-4 p-0">Warehouse: {{ container_discrepancies.warehouse_name }}</div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-4 p-0">Container: {{ container_discrepancies.container_num }}</div>
                                            <div class="col-sm-12 col-md-4 p-0">Discrepancy Type: {{ container_discrepancies.discrepancy_type_name }}</div>
                                            <div class="col-sm-12 col-md-4 p-0" v-if="container_discrepancies.discrepancy_type_id == 2">Reference Doc: {{ container_discrepancies.reference_doc }}</div>
                                        </div>
                                        <div class="row">
                                            <v-spacer></v-spacer>
                                            <v-col class="text-right">
                                                <BaseFilesViewer :containerDiscrepancyId="viewContainerDiscrepancyId"/>
                                            </v-col>
                                        </div>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-row>
    
                        <v-data-table
                            :headers="headers"
                            :items="container_discrepancies.container_discrepancy_items"
                            hide-default-footer
                            class="w-100"
                        >
                        </v-data-table>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-row class="mt-3 d-flex justify-content-center">
                                    <v-col v-if="container_discrepancies.status == 0 && userAccess.confirm" class="text-center">
                                        <span class="m-1"><v-btn @click="updateContainerDiscrepancyStatus(1)">Confirm</v-btn></span>
                                    </v-col>
                                    <v-col v-if="(container_discrepancies.status == 1) && userAccess.reject" class="text-center">
                                        <span class="m-1"><v-btn color="red" @click="updateContainerDiscrepancyStatus(0)">Reject</v-btn></span>       
                                    </v-col>
                                    <v-col v-if="userAccess.approve &&(container_discrepancies.status == 1 && container_discrepancies.discrepancy_type_id == 1) || (container_discrepancies.discrepancy_type_id == 2 && container_discrepancies.status == 1)" class="text-center">
                                        <span class="m-1" ><v-btn @click="updateContainerDiscrepancyStatus(2)">Approve</v-btn></span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>

                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
    </template>
    <script>
        import jsPDF from 'jspdf';
        import swal from 'sweetalert2';
        import { mapGetters } from 'vuex';
        import BaseFilesViewer from "../../Accounting/Base/BaseFilesViewer.vue";
        export default {
            components: {
                BaseFilesViewer
            },
            props: [
                'component_details',
                'viewContainerDiscrepancyId',
            ],
            async mounted(){
                this.checkAccess()
            },
            data() {
                return {
                    container_discrepancy_dialog:false,
                    container_discrepancies:[],
                    headers:[
                        { text: 'Model', align: 'left', value: 'model', width: '15%', sortable: false},
                        { text: 'Name', align: 'left', value: 'name', width: '35%', sortable: false},
                        { text: 'Type', align: 'left', value: 'item_type_name', sortable: false},
                        { text: 'UOM', align: 'left', value: 'item_uom_name', sortable: false},
                        { text: 'QTY RECEIVED (MB)', align: 'center', value: 'quantity_master_box', sortable: false},
                        { text: 'QTY/MB', align: 'center', value: 'quantity_master_box_conv', sortable: false},
                        { text: 'MB QTY', align: 'center', value: 'quantity_master_box', sortable: false},
                        { text: 'UOM QTY', align: 'center', value: 'quantity', sortable: false},
                        { text: 'DISCREPANCY', align: 'center', value: 'discrepancy', sortable: false},
                    ],
                    approvedItemReplacement:false,
                    userAccess:{
                        confirm:false,
                        approve:false,
                        reject:false,
                    },
                }
            },
            methods: {
                clearContainerDiscrepancyItems(){
                    this.container_discrepancy_dialog = false
                    this.$emit('closeView')
                },
                updateContainerDiscrepancyStatus(status){
                    let counter = 0
                    let exceed_counter = 0
                    let message = '';
                    if(this.container_discrepancies.discrepancy_type_id == 2){
                        message = 'Note: '
                        message += status == 1 ? 'This action will affect existing stocks by taking the discrepancy out.' : 'This action will affect existing stocks by adding the received qty.'
                        if(status == 2){
                            this.container_discrepancies.container_discrepancy_items.forEach(e=>{
                                if(e.received_quantity <= 0){
                                    counter++;
                                }
                                if(e.received_quantity > e.discrepancy){
                                    exceed_counter++
                                }
                            })
                        }
                    }
                        
                    if(counter > 0){
                        swal.fire('Received Quantity Cannot be Zero','','warning')
                        return false; 
                    }
                    if(exceed_counter > 0){
                        swal.fire('Received Quantity Cannot be Greater than Discrepancy','','warning')
                        return false; 
                    }
                    swal.fire({
                        title: "Are you sure you want to Proceed?",
                        text: message,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor: 'grey',
                        confirmButtonText: 'Proceed',
                        showCloseButton: true,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            let payload = {
                                status:status,
                                url:`update-container-discrepancy-status/${this.viewContainerDiscrepancyId}`
                            }
                            this.$store.dispatch('postContainerDiscrepancy',payload).then(response=>{
                                swal.fire(response.data.msg,'',response.data.icon)
                                this.clearContainerDiscrepancyItems()
                                this.$emit('closeView','updateStatusSuccess')
                            })
                        }
                    });
                },
                checkAccess(){
                    this.userAccess.confirm = false;
                    this.userAccess.approve = false;
                    this.userAccess.reject = false;
                    this.USER_ACCESS.forEach(e => {
                        if (e.actions_code == 'confirm') {
                            this.userAccess.confirm = true;
                        }
                        if (e.actions_code == 'approve') {
                            this.userAccess.approve = true;
                        }
                        if (e.actions_code == 'reject') {
                            this.userAccess.reject = true;
                        }
                    });
                }
            },
            watch: {
                viewContainerDiscrepancyId: function(val){
                    if(val != -1){
                        if(this.viewContainerDiscrepancyId != null && this.viewContainerDiscrepancyId > 0){
                           this.container_discrepancy_dialog = true
                           let payload = {
                                url:`view-container-discrepancy/${val}`,
                                mutation:'SELECTED_CONTAINER_DISCREPANCY',
                            }
                           this.$store.dispatch('getContainerDiscrepancy',payload)
                        }
                    }
                },
                GET_SELECTED_CONTAINER_DISCREPANCY:{
                    handler(val){
                        this.container_discrepancies = val[0];
                       
                        this.headers = [
                            { text: 'Model', align: 'left', value: 'model', width: '15%', sortable: false},
                            { text: 'Name', align: 'left', value: 'name', width: '35%', sortable: false},
                            { text: 'Type', align: 'left', value: 'item_type_name', sortable: false},
                            { text: 'UOM', align: 'left', value: 'item_uom_name', sortable: false},
                            { text: 'QTY RECEIVED (MB)', align: 'center', value: 'quantity_master_box', sortable: false},
                            { text: 'QTY/MB', align: 'center', value: 'quantity_master_box_conv', sortable: false},
                            { text: 'MB QTY', align: 'center', value: 'quantity_master_box', sortable: false},
                            { text: 'UOM QTY', align: 'center', value: 'quantity', sortable: false},
                            { text: 'DISCREPANCY', align: 'center', value: 'discrepancy', sortable: false},
                        ]
                        if(this.container_discrepancies.discrepancy_type_id == 2){
                            this.headers.push(
                                { text: 'RECEIVED UOM', align: 'center', value: 'received_quantity', sortable: false},
                            )
                        }
                    }
                },
                USER_ACCESS: {
                    handler(val) {
                        if(val != "fail"){
                            this.checkAccess()
                        }
                    },
                },
            },
            computed: {
                ...mapGetters([
                    'GET_SELECTED_CONTAINER_DISCREPANCY',
                    'USER_ACCESS'
                ]),
            }
    
        };
    
    </script>
    <style>
        /* .v-avatar{
            margin: 15px 50px !important;
        }
        #client_modal_card{
            min-height: 160px !important;
        } */
    </style>
    