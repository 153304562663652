<template>
    <v-container>
    <ComponentHeadert
      :title="'Sales By Region'"
      :type="'Sales'"
      :button="true"
    ></ComponentHeadert>

    <ReportBodyComponent
    @resetFields="resetFields"
    multi-table
    :headers="headers"
    >
    <template #first-row>

                <v-card-text>
                    <v-row>
                        <v-col lg="3">

                            <v-autocomplete
                                :items = "SALES_BY_REGION"
                                multiple
                                chips
                                outlined
                                dense
                                v-model="filters.regCode"
                                item-value="id"
                                item-text="regDesc"
                                background-color="white"
                                label="Select By Region"

                            >
                            </v-autocomplete>
                        </v-col>

                        <v-col lg="3">

                            <v-autocomplete
                                :items = "GET_CUSTOMERS_SELECTION"
                                multiple
                                chips
                                outlined
                                dense
                                v-model="filters.customer_ids"
                                item-value="value"
                                item-text="text"
                                background-color="white"
                                label="Select Customer"

                            >
                            </v-autocomplete>
                        </v-col>


                    </v-row>
                </v-card-text>
    </template>

    <template #second-row>
                <v-card-text>
                    <v-row>


                    </v-row>

                    <v-row class="mt-6">
                        <v-col lg="3">

                          <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              min-width="290px"
                          >
                              <template v-slot:activator="{ on }">
                                  <v-text-field
                                      readonly
                                      outlined
                                      dense
                                      append-icon="mdi-calendar-clock"
                                      v-on="on"
                                      label="Date From"
                                      v-model="filters.date_from"
                                  ></v-text-field>
                              </template>
                              <v-date-picker  v-model="filters.date_from" @input="menu2 = false"></v-date-picker>
                          </v-menu>
                          <!-- <span  class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                      </v-col>
                      <v-col lg="3">

                          <v-menu
                              v-model="menu3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              min-width="290px"
                          >
                              <template v-slot:activator="{ on }">
                                  <v-text-field
                                      readonly
                                      outlined
                                      dense
                                      append-icon="mdi-calendar-clock"
                                      v-on="on"
                                      label="Date to"
                                      v-model="filters.date_to"
                                  ></v-text-field>
                              </template>
                              <v-date-picker  v-model="filters.date_to"  @input="menu3 = false"></v-date-picker>
                          </v-menu>
                          <!-- <span class="text-warning" v-bind:key="errors">{{errors}}</span> -->
                      </v-col>
                    </v-row>
                </v-card-text>
    </template>

    </ReportBodyComponent>


    </v-container>
 </template>

<script>
import ComponentHeadert from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import moment from 'moment'
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import { mapGetters } from 'vuex'


export default {
    mixins: [ SharedFunctionsComponent ],
    data(){
        return{

            expanded: [],
            errors: [],
            loaded: true,


            menu2: false,
            menu3: false,
            isResetField: null,

            filters:{

                    customer_ids: '',
                    regCode: [],
                    date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                    date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                    search: '',

            },

            sale_by_region:[],
            customers:[],
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10 ,15, 50, 100, 250],
            itemsPerPage: 10,

            headers: [
                    { text: 'Customers', align: 'left', value: 'customer', width: '80%'},
                    { text: 'Total Amount', align: 'left', value: 'total_amount', width: '20%'},

            ],


        }
    },
    components:{
        ComponentHeadert,
        ReportBodyComponent

    },
    computed:{
        ...mapGetters([
        'SALES_BY_REGION',
        'GET_CUSTOMERS_SELECTION'
        ])
    },
    methods:{
        resetFields(){
              this.errors                                   = []
              this.filters.date_from                        = moment().clone().startOf('month').format('YYYY-MM-DD')
              this.filters.date_to                          = moment().clone().endOf('month').format('YYYY-MM-DD')
              this.filters.regCode                       = ''
              this.filters.customer_ids                     = ''



          },
          getFilters(){

            this.loaded = false
            let data = {
                date_from:            this.filters.date_from,
                date_to:              this.filters.date_to,
                customers:            this.filters.customer_ids,
                region_id:            this.filters.regCode,
                search:               this.search,
                filename              :'Sales By Region',
                headers:              this.headers,
                url:                  'getSalesByRegion'

            }

            return data
        },


    },
    mounted(){
        this.getFilters()
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getSalesByRegionReports')
        this.$store.commit('FILTER_VALUE',this.getFilters())


    },
    watch:{
        'filters': {
              handler () {
                this.$store.commit('FILTER_VALUE',this.getFilters())

              },
              deep: true,
        },


    }


}
</script>

<style>

</style>
