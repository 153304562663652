<template>
    <v-dialog v-model="GET_APPROVAL_DIALOG" width="60%" persistent :retain-focus="false">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
                <v-row class="px-3 py-2" dense no-gutters> <!-- Dialog header -->
                    <v-col cols="11">
                        <v-card-title class="pa-0 pl-3">
                            <span class="text-h5">Customer Changes Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1">
                        <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="px-3 py-2" dense no-gutters> <!-- Dialog header -->
                    <v-col cols="6">
                        <v-card-title class="pa-0 pl-3">
                            {{ customer_name }}
                        </v-card-title>
                    </v-col>
                </v-row>
                <v-divider class="pa-0 ma-0"></v-divider>
                <v-row class="px-3 overflow-auto" dense no-gutters> <!-- Other Details -->
                    <v-col cols="12">
                        <v-simple-table>
                            <thead class="position-sticky">
                                <tr>
                                    <th width="32%">Property</th>
                                    <th width="35%">Old Value</th>
                                    <th></th>
                                    <th width="35%">New Value</th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-card  class="overflow-auto" style="height:400px;" elevation="0">
                            <v-simple-table>
                                <tbody>
                                    <tr v-for="(columnKey , i) in GET_CUSTOMER_CHANGES.keys_to_compare" :key="i">
                                        <td
                                            v-if="GET_CUSTOMER_CHANGES.old_customer_info[columnKey.column_name] != GET_CUSTOMER_CHANGES.new_customer_info[columnKey.column_name] && columnKey.type != 'arrayObject'"
                                            class="pa-2 border-0"
                                        >
                                            <v-text-field dense outlined v-model="columnKey.column_display" readonly></v-text-field>
                                        </td>
                                        <data-comparison-component-vue
                                            v-if="GET_CUSTOMER_CHANGES.old_customer_info[columnKey.column_name] != GET_CUSTOMER_CHANGES.new_customer_info[columnKey.column_name]"
                                            :columnDetails="columnKey"
                                            :notIncludedArrObjectKeys="notIncludedKeys"
                                            :columnData="GET_CUSTOMER_CHANGES.old_customer_info"
                                            :baseData="GET_CUSTOMER_CHANGES"
                                            :oldKeyObj="'old_customer_info'"
                                            :newKeyObj="'new_customer_info'"
                                        >
                                        </data-comparison-component-vue>
                                        <td
                                            class="pa-2 border-0"
                                            v-if="GET_CUSTOMER_CHANGES.old_customer_info[columnKey.column_name] != GET_CUSTOMER_CHANGES.new_customer_info[columnKey.column_name] && columnKey.type != 'arrayObject'">
                                            <v-icon color="blue">mdi-arrow-right-bold</v-icon>
                                        </td>
                                        <data-comparison-component-vue
                                            v-if="GET_CUSTOMER_CHANGES.old_customer_info[columnKey.column_name] != GET_CUSTOMER_CHANGES.new_customer_info[columnKey.column_name]"
                                            :columnDetails="columnKey"
                                            :notIncludedArrObjectKeys="notIncludedKeys"
                                            :columnData="GET_CUSTOMER_CHANGES.new_customer_info"
                                            :baseData="GET_CUSTOMER_CHANGES"
                                            :oldKeyObj="'old_customer_info'"
                                            :newKeyObj="'new_customer_info'"
                                        >
                                        </data-comparison-component-vue>
                                    </tr>
                                    <tr v-if="with_discount_changes">
                                        <td class="pa-2 border-0"> <v-text-field dense outlined readonly value="Discounts"></v-text-field></td>
                                        <td>
                                            <v-col cols="12" class="px-1" v-for="(discount_card, i) in old_discount_cards" :key="i">
                                                <v-card class="pt-1" elevation="0" outlined>
                                                    <v-row dense no-gutters>
                                                        <v-col cols="12">
                                                            <v-card-subtitle class="px-2 py-2">
                                                                <span style="display: inline-block">
                                                                    <v-autocomplete
                                                                        v-model="brand_discount[i]"
                                                                        :items="GET_BRAND_DISCOUNT"
                                                                        :disabled="disable_view"
                                                                        label="Brand Discount"
                                                                        class="text-caption brand_discount"
                                                                        background-color="white"
                                                                        dense
                                                                        hide-details
                                                                        auto-select-first
                                                                        persistent-placeholder
                                                                        @change="brand_change(i)"
                                                                    ></v-autocomplete>
                                                                </span>
                                                                <span class="float-right mx-1 my-2">
                                                                    <v-btn
                                                                        :disabled="disable_view"
                                                                        style="height: 20px; width: 20px"
                                                                        class="float-right"
                                                                        text
                                                                        icon
                                                                        @click="removeDiscount(i)"
                                                                    >
                                                                        <v-icon>mdi-close-circle</v-icon>
                                                                    </v-btn>
                                                                </span>
                                                            </v-card-subtitle>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="pa-0 ma-0"></v-divider>
                                                    <v-row dense no-gutters>
                                                        <v-card-text class="pa-1 bg-ingco-alt">
                                                            <v-col cols="12" v-for="(discount_type, n) in GET_DISCOUNT_TYPE" :key="n">
                                                                <v-text-field
                                                                    v-model="old_discount_text_fields[i].arr[n]"
                                                                    :value="old_discount_text_fields[i].arr[n]"
                                                                    :disabled="disable_view"
                                                                    :prefix="discount_type.text"
                                                                    background-color="white"
                                                                    class="py-1"
                                                                    suffix="%"
                                                                    outlined
                                                                    dense
                                                                    hide-details
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-card-text>
                                                    </v-row>
                                                </v-card>
                                            </v-col>
                                        </td>
                                        <td class="pa-2 border-0"><v-icon color="blue">mdi-arrow-right-bold</v-icon></td>
                                        <td class="pa-2 border-0">
                                            <v-col cols="12" class="px-1" v-for="(discount_card, i) in new_discount_cards" :key="i">
                                                <v-card class="pt-1" elevation="0" outlined>
                                                    <v-row dense no-gutters>
                                                        <v-col cols="12">
                                                            <v-card-subtitle class="px-2 py-2">
                                                                <span style="display: inline-block">
                                                                    <v-autocomplete
                                                                        v-model="brand_discount[i]"
                                                                        :items="GET_BRAND_DISCOUNT"
                                                                        :disabled="disable_view"
                                                                        label="Brand Discount"
                                                                        class="text-caption brand_discount"
                                                                        background-color="white"
                                                                        dense
                                                                        hide-details
                                                                        auto-select-first
                                                                        persistent-placeholder
                                                                        @change="brand_change(i)"
                                                                    ></v-autocomplete>
                                                                </span>
                                                                <span class="float-right mx-1 my-2">
                                                                    <v-btn
                                                                        :disabled="disable_view"
                                                                        style="height: 20px; width: 20px"
                                                                        class="float-right"
                                                                        text
                                                                        icon
                                                                        @click="removeDiscount(i)"
                                                                    >
                                                                        <v-icon>mdi-close-circle</v-icon>
                                                                    </v-btn>
                                                                </span>
                                                            </v-card-subtitle>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider class="pa-0 ma-0"></v-divider>
                                                    <v-row dense no-gutters>
                                                        <v-card-text class="pa-1 bg-ingco-alt">
                                                            <v-col cols="12" v-for="(discount_type, n) in GET_DISCOUNT_TYPE" :key="n">
                                                                <v-text-field
                                                                    v-model="new_discount_text_fields[i].arr[n]"
                                                                    :value="new_discount_text_fields[i].arr[n]"
                                                                    :disabled="disable_view"
                                                                    :prefix="discount_type.text"
                                                                    background-color="white"
                                                                    class="py-1"
                                                                    suffix="%"
                                                                    outlined
                                                                    dense
                                                                    hide-details
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-card-text>
                                                    </v-row>
                                                </v-card>
                                            </v-col>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-for="(changed, i) in address_changes_arr" :key="i">
                                    <h6 class="ml-2 pb-0">{{changed.arrChanges.section_key}}</h6>
                                    <tr v-for="(changedArray, indexes) in changed.arrChanges.changesTemp" :key="indexes">
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                v-model="changedArray.key_text"
                                                outlined
                                                dense
                                                readonly
                                                >
                                            </v-text-field>
                                        </td>
                                        <td class="pa-2 border-0">
                                                <v-text-field
                                                :value="changedArray.old_value"
                                                dense
                                                outlined
                                                readonly
                                                >
                                                </v-text-field>
                                        </td>
                                        <td class="pa-2 border-0"><v-icon color="blue">mdi-arrow-right-bold</v-icon></td>
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                :value=" changedArray.new_value"
                                                dense
                                                outlined
                                                readonly
                                            >
                                            </v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="changesInClusters.length > 0">
                                    <h6>Clusters</h6>
                                    <tr v-for="(changed, index) in changesInClusters" :key="index">
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                :value="changed.action"
                                                outlined
                                                dense
                                                readonly
                                                >
                                            </v-text-field>
                                        </td>
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                :value="changed.action === 'Removed' ? changed.name : ''"
                                                outlined
                                                dense
                                                readonly
                                                >
                                            </v-text-field>
                                        </td>
                                        <td class="pa-2 border-0"><v-icon color="blue">mdi-arrow-right-bold</v-icon></td>
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                :value="changed.action === 'Added' ? changed.name : ''"
                                                dense
                                                outlined
                                                readonly
                                            >
                                            </v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="changesInSubClusters.length > 0">
                                    <h6>Sub Clusters</h6>
                                    <tr v-for="(changed, index) in changesInSubClusters" :key="index">
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                :value="changed.action"
                                                outlined
                                                dense
                                                readonly
                                                >
                                            </v-text-field>
                                        </td>
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                :value="changed.action === 'Removed' ? changed.name : ''"
                                                outlined
                                                dense
                                                readonly
                                                >
                                            </v-text-field>
                                        </td>
                                        <td class="pa-2 border-0"><v-icon color="blue">mdi-arrow-right-bold</v-icon></td>
                                        <td class="pa-2 border-0">
                                            <v-text-field
                                                :value="changed.action === 'Added' ? changed.name : ''"
                                                dense
                                                outlined
                                                readonly
                                            >
                                            </v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider class="px-0 ma-0"></v-divider>
                <v-card-actions class="text-center">
                    <v-row class="ma-1">
                        <v-col cols="12">
                            <v-btn v-if="actions == 'View' && userAccess.approve_customer && GET_ACTIVE_TAB == 'for_approval'" class="float-center"
                                @click="approveCustomer()">
                                <span>Approve</span>
                            </v-btn>
                            <v-btn v-if="actions != 'View'" class="float-center"
                                @click="actions == 'Submit' ? addNewCustomer() : updateCustomer()">
                                <span>{{ actions }}</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-dialog v-model="collectibles.dialog" max-width="75%" persistent>
                <v-card>
                    <v-row class="px-3 py-2" dense no-gutters> <!-- Dialog header -->
                        <v-col cols="11">
                            <v-card-title class="pa-0 pl-3">
                                <span class="text-h5">Collectibles</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="1">
                            <v-btn text icon small color="gray" class="float-right" @click="collectibles.dialog = false">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="pa-0 ma-0"></v-divider>
                    <v-row class="ma-0 pa-0">
                        <v-col class="pa-2 ma-2">
                            <ReportsCardsComponentVue :totals="collectibles.card.totals" :cards="collectibles.card.cards" style="width:100%;"></ReportsCardsComponentVue>
                        </v-col>
                    </v-row>
                    <v-card-text class="mt-5">
                        <v-row class="mx-1">
                            <v-col>
                                <v-data-table
                                sort-by.sync="selected_month"
                                sort-desc.sync="true"
                                :items="collectibles.items"
                                :headers="collectibles.total_headers"
                                item-key="selected_month"
                                show-expand
                                single-expand
                                dense
                                >
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-data-table :headers="collectibles.headers"
                                            :items="item.statement_of_accounts"
                                            hide-default-footer
                                            dark
                                            dense
                                            disable-pagination
                                        >
                                            <template v-slot:[`item.sales_total`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.sales_total | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.selected_month`]="{ item }">
                                                <span class="text-nowrap">

                                                    {{ item.selected_month | formatMonthSoa}}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.due_date`]="{ item }">
                                                <span class="text-nowrap">

                                                    {{ item.due_date | formatMonthSoa}}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.terms`]="{ item }">
                                                <span class="text-nowrap" v-if="item.terms == 0">
                                                    {{ 'CASH' }}
                                                </span>
                                                <span class="text-nowrap" v-else>
                                                    {{ item.terms }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.gross_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.gross_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.discount_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.discount_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.total_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.paid_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.paid_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.balance_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.balance_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.addition_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.addition_amount | currency }}
                                                </span>
                                            </template>

                                            <template v-slot:[`item.deduction_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.deduction_amount | currency }}
                                                </span>
                                            </template>
                                            <template v-slot:[`item.age`]="{ item }">
                                                <span class="text-nowrap">
                                                    {{ agingDate(item.due_date) }}
                                                </span>
                                            </template>
                                        </v-data-table >

                                    </td>
                                </template>
                                <template v-slot:[`item.total_gross_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_gross_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.total_dm_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_dm_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.total_cm_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_cm_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.total_balance_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_balance_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.total_amount`]="{ item }">
                                    <span class="text-nowrap">
                                        &#x20b1;{{ item.total_amount | currency }}
                                    </span>
                                </template>

                                <template v-slot:[`item.selected_month`]="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.selected_month | formatMonthSoa}}
                                    </span>
                                </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-form>
    </v-dialog>
</template>
<script>
// import buttons from "../../Buttons/SubdialogButton.vue";
import buttons_components from "../../../../../assets/js/components-js.js";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import FileUploadComponentVue from "@/views/main/Shared/FileUploadComponent.vue";
import BaseFilesViewerVue from "@/views/main/modules/MasterData/Base/BaseFilesViewer.vue"
import moment from "moment";
import ReportsCardsComponentVue from '../../Reports/ReportsCardsComponent.vue';
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import DataComparisonComponentVue from '@/views/main/Shared/DataComparisonComponent.vue';
export default {
    mixins: [buttons_components,SharedFunctionsComponentVue],
    components: { FileUploadComponentVue,BaseFilesViewerVue,ReportsCardsComponentVue, DataComparisonComponentVue },
    data() {
        return {
            valid: true,
            tab: 0,
            // buttons: buttons_components.buttons,
            editCustomerId: "",
            add_edit_customer_dialog: false,
            tabs: [
                { text: 'Sales', access: 'edit_customer_sales'},
                { text: 'Accounting', access: 'edit_customer_accounting'},
                { text: 'Exec', access: 'edit_customer_exec'},
            ],

            customer_information: {
                type: "bus",
                id: "",
                company_names: "",
                last_name: "",
                first_name: "",
                middle_name: "",
                deliver_instructions: "",
                shipping_via: "",
                credit_limit: "",
                tin: { tin: "" },
                vat_rate: "",
                classification: "",
                customer_category_id: "",
                annual_target: "",
                terms: "",
                sales_man: "",
                sales_coor: "",
                remarks: "",
                terms: "",
                salesman_id: "",
                sales_coordinator_id: "",
                remarks: "",
                accounting_remarks: "",
                credit_limit: "",
                vat_rate: "",
                store: 0,
                recycle_so: 0,
                special: 0,
                is_special: 0,
                vip: 0,
                is_active: 1,
                is_store: 0,
                is_print_no_price: 1,
                recycle_so: 0,
                approved_by_sales: 0,
                approved_by_acct: 0,
                approved_by_exec: 0,
                affiliate_customer_id: null,
                auto_cancel_sales_order_on_dispatch: 0,
                tel_1: [],
                tel_2: [],
                tel_3: [],
                fax: [],
                mobile_1: [],
                mobile_2: [],
                mobile_3: [],
                email: [],
                hold:0,
                customer_code_id:0,
                is_mother_customer: 0,
            },
            other_contacts_sales:
            {
                contact_type: "Sales",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            },
            other_contacts_accounting: {
                contact_type: "Accounting",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            },
            cust_id: "",
            action: "",
            tin: "",
            sss: "",
            philhealth: "",
            pagibig: "",
            brand_id: "",
            sales_target_amount: "0.00",
            discount_card_count: 1,
            max_discounts_count: 999,
            max_shipping_address_count: 5,
            max_billing_address_count: 5,
            max_company_address_count: 5,
            add_brand_discount_dialog: false,
            tmp: "true",
            actionResult: {
                model: "Customer",
                action: "",
            },
            counts: 0,
            // other_contacts_sales:
            // {
            //     contact_type: "Sales",
            //     id: "",
            //     email: "",
            //     mobile_1: "",
            //     mobile_2: "",
            //     tel_1: "",
            //     tel_2: "",
            //     tel_3: "",
            //     fax: "",
            // },
            // other_contacts_accounting: {
            //     contact_type: "Accounting",
            //     id: "",
            //     email: "",
            //     mobile_1: "",
            //     mobile_2: "",
            //     tel_1: "",
            //     tel_2: "",
            //     tel_3: "",
            //     fax: "",
            // },
            count: {
                shipping: 0,
                billing: 0,
            },
            classification_id: null,
            country_code: { shipping: [], billing: [] },
            reg_code: { shipping: [], billing: [] },
            province_code: { shipping: [], billing: [] },
            city_code: { shipping: [], billing: [] },
            comp_address: { shipping: [], billing: [] },
            contact_person: { shipping: [], billing: [] },
            contact_number: { shipping: [], billing: [] },
            reg_list: { shipping: [], billing: [] },
            province_list: { shipping: [], billing: [] },
            city_list: { shipping: [], billing: [] },
            address_trash: { shipping: [], billing: [] },
            address_id: {
                shipping: [],
                billing: [],
            },
            discount_cards: [],
            new_discount_cards: [],
            old_discount_cards: [],
            discount_id: [],
            discount_trash: [],
            valid_ids: [],
            brand_discounts: [],
            brand_discount: [],
            brand_name: '',
            brand_list: [],
            countries: [],
            regions: [],
            provinces: [],
            cities: [],
            customer_list: [],
            salesman_list: [],
            errors: {
                company_names: [(v) => !!v || "Company Name is Required"],
                first_name: [(v) => !!v || "First Name is Required"],
                last_name: [(v) => !!v || "Last Name is Required"],
                classification: [(v) => !!v || "Classification is Required"],
            },
            addCompAddress: [],
            shipping_counter: [],
            billing_counter: [],
            comp_id: [],
            discount_text_fields: [],
            old_discount_text_fields: [],
            new_discount_text_fields: [],
            discount_types: [],
            discount_card_selected: [],
            brand_selection: [],
            classification_list: [],
            sales_coordinator_list: [],
            disable_view: false,
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                edit_approve_sales: false,
                edit_approve_acct: false,
                edit_approve_exec: false,
                edit_customer_sales: false,
                edit_customer_accounting: false,
                edit_customer_exec: false,
                delete_file: false,
                approve_customer: false
            },
            total_card_details:{
                total_collectibles:0,
                credit_limit:'',
                total_used:0,
                remaining:0,
                vip:'',
                loading:false
            },
            uploadedFiles:null,
            collectibles:{
                dialog:false,
                headers:[
                    { text: 'Soa ID', align: 'left', value: 'id', },
                    { text: 'Soa Month', align: 'left', value: 'selected_month', },
                    { text: 'Due Month', align: 'left', value: 'due_date', },
                    { text: 'Gross Amount', align: 'left', value: 'gross_amount', },
                    { text: 'DM Amount', align: 'left', value: 'addition_amount', },
                    { text: 'CM Amount', align: 'left', value: 'deduction_amount', },
                    { text: 'Net Amount', align: 'left', value: 'total_amount', },
                    { text: 'Balance Amount', align: 'left', value: 'balance_amount', },
                    { text: 'Agent', align: 'left', value: 'salesman_name', },
                    { text: 'Status', align: 'left', value: 'status_name', },
                    { text: 'Terms', align: 'left', value: 'terms', },
                    { text: 'Age (Day)', align: 'left', value: 'age', },
                ],
                total_headers:[
                    { text: 'SOA MONTH', align: 'left', value: 'selected_month', },
                    { text: 'GROSS AMOUNT', align: 'right', value: 'total_gross_amount', },
                    { text: 'ADDITION AMOUNT', align: 'right', value: 'total_dm_amount', },
                    { text: 'DEDUCTION AMOUNT', align: 'right', value: 'total_cm_amount', },
                    { text: 'TOTAL NET AMOUNT', align: 'right', value: 'total_amount', },
                    { text: 'TOTAL BALANCE AMOUNT', align: 'right', value: 'total_balance_amount', },
                ],
                items:[],
                card:{
                    cards: [
                        { title: 'Total Gross Amount', icon: 'mdi-cash', icon_class: 'total-amount', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_gross_amount' },
                        { title: 'Total DM Amount', icon: 'mdi-cash-plus', icon_class: 'total-amount-booked', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_cm_amount' },
                        { title: 'Total CM Amount', icon: 'mdi-cash-minus', icon_class: 'total-target', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_dm_amount' },
                        { title: 'Total Net Amount', icon: 'mdi-cash-check', icon_class: 'total-customer', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_amount' },
                        { title: 'Total Balance Amount', icon: 'mdi-cash-sync', icon_class: 'total-pending-so', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_balance_amount' },
                        { title: 'Total Overdue Amount', icon: 'mdi-cash-clock', icon_class: 'total-overdue', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_overdue' },
                    ],
                    totals: {
                        total_cm_amount: 0,
                        total_dm_amount: 0,
                        total_gross_amount: 0,
                        total_balance_amount: 0,
                        total_amount: 0,
                        total_overdue: 0,
                    },
                },
            },
            customer_changes: [],
            currency_sign: null,
            customer_name: '',
            with_discount_changes: false,
            with_address_changes: false,
            address_changes_arr: [],
            notIncludedKeys: ['id', 'customer_id', 'customer_code', 'province_code', 'country_code', 'region_code', 'city_code', 'type_address', 'created_at', 'updated_at', 'index', 'customer_temp_id']
        };
    },
    computed: {
        ...mapGetters([
            "GET_BRAND_DISCOUNT",
            "GET_CUSTOMER_ID",
            "GET_DISCOUNT_TYPE",
            "GET_ALL_COUNTRIES",
            "GET_ALL_REGIONS",
            "GET_ALL_PROVINCE",
            "GET_ALL_CITIES",
            "GET_AFFLIATE_CUSTOMER",
            "GET_BRAND_ARR",
            "ACTION",
            "DIALOGS",
            "GET_SALESMAN_DROPDOWN",
            "GET_SALESMANCOOR_DROPDOWN",
            "SELECTED_CUSTOMER",
            "GET_CLASSIFICATIONS_DROPDOWN",
            "USERACCOUNT_LOAD",
            "GET_CUSTOMER_CATEGORY_SELECTION",
            "USER_ACCESS",
            "GET_CUSTOMER_CODE_DROPDOWN",
            "GET_ACTIVE_TAB",
            "GET_CUSTOMER_CHANGES",
            "GET_APPROVAL_DIALOG"
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                // this.userAccess = {
                //     edit_approve_exec: true,
                //     edit_customer_sales: true,
                //     edit_customer_accounting: true,
                // }

                this.disable_view = false;
                this.discount_types = this.GET_DISCOUNT_TYPE;

                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disable_view = false;
                this.tmp = 'true';
                this._access();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disable_view = true;
                this.getallCustomerChanges();
                return "View";
            }
        },
        
        // This will return an array of changes in clusters (Added and Removed).
        changesInClusters() {
            const added = this.GET_CUSTOMER_CHANGES.new_customer_info.clusters.filter(
                newCluster => !this.GET_CUSTOMER_CHANGES.old_customer_info.clusters.some(
                    oldCluster => newCluster.code === oldCluster.code
                )
            ).map(cluster => ({ ...cluster, action: 'Added' }));
            const removed = this.GET_CUSTOMER_CHANGES.old_customer_info.clusters.filter(
                oldCluster => !this.GET_CUSTOMER_CHANGES.new_customer_info.clusters.some(
                    newCluster => oldCluster.code === newCluster.code
                )
            ).map(cluster => ({ ...cluster, action: 'Removed' }));
            return added.concat(removed);
        },

        // This will return an array of changes in sub clusters (Added and Removed).
        changesInSubClusters() {
            const added = this.GET_CUSTOMER_CHANGES.new_customer_info.sub_clusters.filter(
                newCluster => !this.GET_CUSTOMER_CHANGES.old_customer_info.sub_clusters.some(
                    oldCluster => newCluster.code === oldCluster.code
                )
            ).map(cluster => ({ ...cluster, action: 'Added' }));
            const removed = this.GET_CUSTOMER_CHANGES.old_customer_info.sub_clusters.filter(
                oldCluster => !this.GET_CUSTOMER_CHANGES.new_customer_info.sub_clusters.some(
                    newCluster => oldCluster.code === newCluster.code
                )
            ).map(cluster => ({ ...cluster, action: 'Removed' }));
            return added.concat(removed);
        },
    },
    mounted() {
        this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
        if (this.shipping_counter.length == 0) {
            this.addShippingAddress();
        }
        if (this.billing_counter.length == 0) {
            this.addBillingAddress();
        }
        if (this.discount_cards.length == 0) {
            this.adddiscounts();
        }
    },
    methods: {
        changesText(key, value, i){
            let result = null;
            let boolean_keys = ['active', 'approved_by_acct', 'approved_by_exec', 'approved_by_sales', 'auto_cancel_sales_order_on_dispatch', 'hold', 'is_mother_customer' ,'print_no_price' ,'recycle_so' ,'special', 'store' ,'vip']
            if(boolean_keys.includes(key)){
                return value == 0 ? 'NO' : 'YES'
            }
            // if(value == null || value == 0){
            //     this.customer_changes.splice(i, 1);
            // }
            try{
                switch(key){
                    case 'salesman_id':
                        return value != null || value != 0 ? this.GET_SALESMAN_DROPDOWN.find(x=>x.value == value).text : ''
                    case 'classification_id':
                        return value != null || value != 0 ? this.GET_CLASSIFICATIONS_DROPDOWN.data.find(x=>x.value == value).text : ''
                    case 'sales_coordinator_id':
                        return value != null || value != 0 ? this.GET_SALESMANCOOR_DROPDOWN.find(x=>x.value == value).text : ''
                    case 'customer_category_id':
                        return value != null || value != 0 ?  this.GET_CUSTOMER_CATEGORY_SELECTION.find(x=>x.value == value).text : ''
                    case 'customer_code_id':
                        return value != null || value != 0 ? this.GET_CUSTOMER_CODE_DROPDOWN.data.find(x=>x.value == value).text : ''
                    case 'affiliate_customer_id':
                        return value != null || value != 0 ? this.GET_AFFLIATE_CUSTOMER.find(x=>x.value == value).text : ''
                    default:
                        return value;
                    // check if obect or array
                    // if(this.isStringConvertibleToJSON(result)){
                    //     result = JSON.parse(result)
                    //     if(Array.isArray(result)){
                    //         if(result.length > 0){

                    //             if(key == 'billing_addresses'){
                    //                 let keys = Object.keys(result[0])
                    //                 let v = result.map(e=>{
                    //                     let text = ''
                    //                     keys.forEach(el=>{
                    //                         text = text + ' - ' +e[el]
                    //                     })
                    //                     return text
                    //                 })
                    //             return v;
                    //             }

                    //         }else{
                    //             return result
                    //         }
                    //     }
                    // }
                    }
                }
            catch (error) {
                console.log(error)
                console.log(key)
                console.log(value)
            }
        },
        getallCustomerChanges() {
            if(this.GET_APPROVAL_DIALOG){

                let that = this;

                this.customer_information = this.SELECTED_CUSTOMER;
                this.customer_information.company_names = this.SELECTED_CUSTOMER.company_name
                this.discount_types = this.GET_DISCOUNT_TYPE;


                // this.updateCities(0,'shipping')
                this.SELECTED_CUSTOMER.shipping_add.forEach((e, key) => {

                    this.address_id.shipping[key] = { id: e.id };
                    this.comp_address.shipping[key] = { address: e.address };
                    this.contact_number.shipping[key] = { ct_num: e.contact_number };
                    this.country_code.shipping[key] = { code: e.country_code };
                    this.reg_code.shipping[key] = { code: e.region_code };
                    this.province_code.shipping[key] = { code: e.province_code };
                    this.city_code.shipping[key] = { code: e.city_code };
                    this.contact_person.shipping[key] = {
                        person: e.contact_person != null ? e.contact_person : "",
                    };
                    this.reg_list.shipping.push({ list: this.GET_ALL_REGIONS });
                    this.province_list.shipping.push({ list: this.GET_ALL_PROVINCE });
                    this.city_list.shipping.push({ list: this.GET_ALL_CITIES });

                    this.shipping_counter[key] = key;
                    this.updateProvinces(key, 'shipping');
                    this.updateCities(key, 'shipping');
                    this.count.shipping++;

                });
                this.SELECTED_CUSTOMER.billing_add.forEach((e, key) => {

                    this.address_id.billing[key] = { id: e.id };
                    this.comp_address.billing[key] = { address: e.address };
                    this.contact_number.billing[key] = { ct_num: e.contact_number };
                    this.country_code.billing[key] = { code: e.country_code };
                    this.reg_code.billing[key] = { code: e.region_code };
                    this.province_code.billing[key] = { code: e.province_code };
                    this.city_code.billing[key] = { code: e.city_code };
                    this.contact_person.billing[key] = {
                        person: e.contact_person != null ? e.contact_person : "",
                    };
                    this.reg_list.billing.push({ list: this.GET_ALL_REGIONS });
                    this.province_list.billing.push({ list: this.GET_ALL_PROVINCE });
                    this.city_list.billing.push({ list: this.GET_ALL_CITIES });
                    this.billing_counter[key] = key;
                    this.updateProvinces(key,'billing')
                    this.updateCities(key,'billing')
                    this.count.billing++;
                });
                this.SELECTED_CUSTOMER.customer_discount.forEach((e, key) => {
                    that.GET_BRAND_DISCOUNT.find(element_dc => {

                        if (element_dc.value == e.brand_id) {
                            this.brand_name = element_dc.text

                        }
                    });
                    this.discount_id[key] = {
                        cust_id: e.customer_id,
                        brand_id: e.brand_id,
                    };
                    this.discount_cards[key] = key;
                    this.discount_text_fields[key] = {
                        arr: [],
                    };
                    this.brand_discount[key] = e.brand_id;
                    e.discount_value.forEach((value, keys) => {
                        this.GET_DISCOUNT_TYPE.forEach((dtype, index) => {
                            if (value.discount_type_id == dtype.value) {
                                this.discount_text_fields[key].arr[index] = 0
                                this.discount_text_fields[key].arr[index] =
                                    value.discount_value;

                            }
                        });
                    });
                });

                if (this.shipping_counter.length == 0) {
                    this.addShippingAddress();
                }
                if (this.billing_counter.length == 0) {
                    this.addBillingAddress();
                }
                if (this.discount_cards.length == 0) {
                    this.adddiscounts();
                }
            }
        },
        adddiscounts() {
            this.discount_cards.push(this.discount_card_count++);
            let count = 0;

            this.discount_cards.forEach((e, key) => {
                count = key;
            });
            this.discount_text_fields[count] = {
                arr: [],
            };
            this.discount_types.forEach((e) => {
                this.discount_text_fields[count].arr.push("");
            });

        },
        brand_change(index) {
            this.GET_BRAND_DISCOUNT.find(e => {
                if (e.value == this.brand_discount[index]) {
                    this.brand_name = e.text
                }
            })
        },
        closeDialog() {
            this.tmp = "false";
            this.valid = false;
            this.customer_changes = []
            this.$store.commit("APPROVAL_DIALOG", false);
            // this.$store.commit("DIALOG", false);
            this.$store.commit("EDIT_CUSTOMER", []);

            if (this.DIALOGS == false) {
                this.billing_counter = [];
                this.shipping_counter = [];
                this.discount_cards = [];
                this.address_id.shipping = [];
                this.address_id.billing = [];
            }
            this.with_discount_changes = false
        },
        removeDiscount(key) {
            let that = this;
            this.tmp = "false";
            if (that.discount_text_fields[key].arr.length > 0) {
                this.discount_trash.push(this.discount_id[key]);
                that.discount_text_fields.splice(key, 1);
                this.brand_discount.splice(key, 1);
                this.discount_cards.splice(key, 1);
            }
        },
        addShippingAddress() {
            if (this.shipping_counter.length < this.max_company_address_count) {
                this.tmp = "true";
                let count = 0;
                this.shipping_counter.push(this.count.shipping++);
                this.shipping_counter.forEach((e, key) => {
                    count = key;
                });
                this.comp_address.shipping[count] = { address: "" };
                this.contact_number.shipping[count] = { ct_num: "" };
                this.country_code.shipping[count] = { code: "608" };
                this.reg_code.shipping[count] = { code: "" };
                this.province_code.shipping[count] = { code: "" };
                this.city_code.shipping[count] = { code: "" };
                this.contact_person.shipping[count] = { person: "" };
                this.reg_list.shipping.push({ list: this.GET_ALL_REGIONS });
                this.province_list.shipping.push({ list: this.GET_ALL_PROVINCE });
                this.city_list.shipping.push({ list: this.GET_ALL_CITIES });
            }
        },
        addBillingAddress() {
            if (this.billing_counter.length < this.max_company_address_count) {
                this.tmp = "true";
                let count = 0;
                this.billing_counter.push(this.count.billing++);
                this.billing_counter.forEach((e, key) => {
                    count = key;
                });
                this.comp_address.billing[count] = { address: "" };
                this.contact_number.billing[count] = { ct_num: "" };
                this.country_code.billing[count] = { code: "608" };
                this.reg_code.billing[count] = { code: "" };
                this.province_code.billing[count] = { code: "" };
                this.city_code.billing[count] = { code: "" };
                this.contact_person.billing[count] = { person: "" };
                this.reg_list.billing.push({
                    list: this.GET_ALL_REGIONS,
                });
                this.province_list.billing.push({
                    list: this.GET_ALL_PROVINCE,
                });
                this.city_list.billing.push({
                    list: this.GET_ALL_CITIES,
                });
            }
        },
        removeBillingAddress() {
            this.tmp = "false";
            if (this.billing_counter.length > 0) {
                const deleteaddress = this.billing_counter.pop();
                this.address_trash.billing.push({
                    id: this.address_id.billing[deleteaddress].id,
                });
            }
        },
        removeShippingAddress() {
            this.tmp = "false";
            if (this.shipping_counter.length == 0) {
                this.addShippingAddress();
            }
            if (this.shipping_counter.length > 0) {
                const deleteaddress = this.shipping_counter.pop();

                this.address_trash.shipping.push({
                    id: this.address_id.shipping[deleteaddress].id,
                });
            }
        },
        updateRegions(key, address_type) {
            if (address_type == "billing") {
                if (this.country_code.billing[key].code == "608") {
                    this.reg_code.billing[key].code = this.GET_ALL_REGIONS.regCode;
                    this.province_code.billing[key].code = this.GET_ALL_PROVINCE.provCode;
                    this.city_code.billing[key].code = this.GET_ALL_CITIES.citymunCode;
                    this.reg_list.billing[key].list = this.GET_ALL_REGIONS;
                    this.province_list.billing[key].list = this.GET_ALL_PROVINCE;
                    this.city_list.billing[key].list = this.GET_ALL_CITIES;
                } else {
                    this.reg_code.billing[key].code = "";
                    this.province_code.billing[key].code = "";
                    this.city_code.billing[key].code = "";
                    this.reg_list.billing[key].list = [];
                    this.province_list.billing[key].list = [];
                    this.city_list.billing[key].list = [];
                }
            } else if (address_type == "shipping") {
                if (this.country_code.shipping[key].code == "608") {
                    this.reg_code.shipping[key].code = this.GET_ALL_REGIONS.regCode;
                    this.province_code.shipping[key].code =this.GET_ALL_PROVINCE.provCode;
                    this.city_code.shipping[key].code = this.GET_ALL_CITIES.citymunCode;
                    this.reg_list.shipping[key].list = this.GET_ALL_REGIONS;
                    this.province_list.shipping[key].list = this.GET_ALL_PROVINCE;
                    this.city_list.shipping[key].list = this.GET_ALL_CITIES;
                } else {
                    this.reg_code.shipping[key].code = "";
                    this.province_code.shipping[key].code = "";
                    this.city_code.shipping[key].code = "";
                    this.reg_list.shipping[key].list = [];
                    this.province_list.shipping[key].list = [];
                    this.city_list.shipping[key].list = [];
                }
            }
        },
        updateProvinces(key, address_type) {
            if (address_type == "billing") {
                if (
                    this.reg_code.billing[key].code != null &&
                    this.reg_code.billing[key].code != ""
                ) {
                    var regCode = this.reg_code.billing[key].code;
                    this.province_list.billing[key].list = this.GET_ALL_PROVINCE.filter(
                        function (province) {
                            return province.regCode == regCode;
                        }
                    );

                    if (this.province_code.billing[key].code != "") {
                        var selectedProvCode = this.province_code.billing[key].code;
                        var result = this.province_list.billing[key].list.find(
                            (province) => {
                                return province.value == selectedProvCode;
                            }
                        );

                        if (result == null) {
                            this.province_code.billing[key].code = "";
                            this.updateCities(key, "billing");
                        }
                    } else if (this.city_code.billing[key].code != "") {
                        var selectedCityMunCode = this.city_code.billing[key].code;
                        var result = this.city_list.billing[key].list.find((city) => {
                            return city.value == selectedCityMunCode;
                        });

                        if (result != null) {
                            this.province_code.billing[key].code = result.provCode;
                            this.updateCities(key, "billing");
                        }
                    }
                } else {
                    this.province_list.billing[key].list = "";
                    this.province_code.billing[key].code = "";
                    this.updateCities(key, "billing");
                }
            } else if (address_type == "shipping") {

                if (
                    this.reg_code.shipping[key].code != null &&
                    this.reg_code.shipping[key].code != ""
                ) {
                    var regCode = this.reg_code.shipping[key].code;

                        this.province_list.shipping[key].list = this.GET_ALL_PROVINCE.filter(
                            function (province) {
                                return province.regCode == regCode;
                            }
                        );


                    if (this.province_code.shipping[key].code != "") {
                        var selectedProvCode = this.province_code.shipping[key].code;
                        var result = this.province_list.shipping[key].list.find(
                            (province) => {
                                return province.value == selectedProvCode;
                            }
                        );

                        if (result == null) {
                            this.province_code.shipping[key].code = "";
                            this.updateCities(key, "shipping");
                        }
                    } else if (this.city_code.shipping[key].code != "") {
                        var selectedCityMunCode = this.city_code.shipping[key].code;
                        var result = this.city_list.shipping[key].list.find((city) => {
                            return city.value == selectedCityMunCode;
                        });

                        if (result != null) {
                            this.province_code.shipping[key].code = result.provCode;
                            this.updateCities(key, "shipping");
                        }
                    }
                } else {
                    this.province_list.shipping[key].list = "";
                    this.province_code.shipping[key].code = "";
                    this.updateCities(key, "billing");
                }
            }
        },
        updateCities(key, address_type) {
            if (address_type == "billing") {
                if (
                    this.province_code.billing[key].code != null &&
                    this.province_code.billing[key].code != ""
                ) {
                    var provCode = this.province_code.billing[key].code;
                    this.city_list.billing[key].list = this.GET_ALL_CITIES.filter(
                        function (city) {
                            return city.provCode == provCode;
                        }
                    );
                    var selectedCityCode = this.city_code.billing[key].code;
                    var result = this.city_list.billing[key].list.find((city) => {
                        return city.value == selectedCityCode;
                    });
                    if (result == null) {
                        this.city_code.billing[key].code = "";
                    }
                } else {
                    this.city_list.billing[key].list = [];
                    this.city_code.billing[key].code = "";
                }
            } else if (address_type == "shipping") {
                if (
                    this.province_code.shipping[key].code != null &&
                    this.province_code.shipping[key].code != ""
                ) {
                    var provCode = this.province_code.shipping[key].code;
                    this.city_list.shipping[key].list = this.GET_ALL_CITIES.filter(
                        function (city) {
                            return city.provCode == provCode;
                        }
                    );
                    var selectedCityCode = this.city_code.shipping[key].code;
                    var result = this.city_list.shipping[key].list.find((city) => {
                        return city.value == selectedCityCode;
                    });
                    if (result == null) {
                        this.city_code.shipping[key].code = "";
                    }
                } else {
                    this.city_list.shipping[key].list = [];
                    this.city_code.shipping[key].code = "";
                }
            }

            this.selectRegion(key, address_type);
        },
        selectRegion(key, address_type) {
            if (address_type == "billing") {
                if (
                    this.city_code.billing[key].code != null &&
                    this.city_code.billing[key].code != ""
                ) {
                    var citymun_code = this.city_code.billing[key].code;
                    var selectedCity = this.city_list.billing[key].list.filter(function (
                        city
                    ) {
                        return city.value == citymun_code;
                    });
                    if (selectedCity.length > 0) {
                        this.reg_code.billing[key].code = selectedCity[0].regDesc;
                        this.updateProvinces(key, "billing");
                    }
                } else if (
                    this.province_code.billing[key].code != null &&
                    this.province_code.billing[key].code != ""
                ) {
                    var prov_code = this.province_code.billing[key].code;
                    var selectedProv = this.province_list.billing[key].list.filter(
                        function (prov) {
                            return prov.value == prov_code;
                        }
                    );
                    if (selectedProv.length > 0) {
                        this.reg_code.billing[key].code = selectedProv[0].regCode;
                    }
                }
            } else if (address_type == "shipping") {
                if (
                    this.city_code.shipping[key].code != null &&
                    this.city_code.shipping[key].code != ""
                ) {
                    var citymun_code = this.city_code.shipping[key].code;
                    var selectedCity = this.city_list.shipping[key].list.filter(function (
                        city
                    ) {
                        return city.value == citymun_code;
                    });
                    if (selectedCity.length > 0) {
                        this.reg_code.shipping[key].code = selectedCity[0].regDesc;
                        this.updateProvinces(key, "shipping");
                    }
                } else if (
                    this.province_code.shipping[key].code != null &&
                    this.province_code.shipping[key].code != ""
                ) {
                    var prov_code = this.province_code.shipping[key].code;
                    var selectedProv = this.GET_ALL_PROVINCE.filter(function (prov) {
                        return prov.value == prov_code;
                    });
                    if (selectedProv.length > 0) {
                        this.reg_code.shipping[key].code = selectedProv[0].regCode;
                    }
                }
            }
        },
        addNewCustomer() {
            const that = this
            const address_type = {
                shipping: "shipping",
                billing: "billing",
            }

            if (!this.customer_information.is_mother_customer) {
                if (this.uploadedFiles == null) {
                    Swal.fire({
                        title: "Please Upload Images or Files",
                        icon: "warning",
                    })

                    return false
                }

                // let classifications_validation = that.userAccess.edit_customer_accounting ? null : that.customer_information.classification
                this.$refs.form.validate();
                const cpname_swal_text = this.customer_information.company_names
                    ? ""
                    : "Company Name";
                // const classification_swal_text = classifications_validation
                //     ? ""
                //     : "Classification";
                const fname_swal_text = this.customer_information.first_name
                    ? ""
                    : "First Name";
                const lname_swal_text = this.customer_information.last_name
                    ? ""
                    : "Last Name";

                const array_text = [
                    cpname_swal_text,
                    // classification_swal_text,
                    fname_swal_text,
                    lname_swal_text,
                ];

                const filtered = array_text.filter(function (el) {
                    return el.replace(",", "");
                });

                if (
                    !that.customer_information.company_names &&
                    !that.customer_information.last_name &&
                    !that.customer_information.first_name
                    // !classifications_validation
                ) {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: "warning",
                        allowOutsideClick: false,
                    });

                    return false
                }
            }

            const data_company_information = {
                customer: that.customer_information,
                other_contacts_sales: that.other_contacts_sales,
                other_contacts_accounting: that.other_contacts_accounting,
                tin: JSON.stringify(this.customer_information.tin),
                action: 'create'
            };

            this.$store.dispatch("addcustomers", data_company_information).then((response) => {
            // this.$store.dispatch("addCustomerTempRecord", data_company_information).then((response) => {
                const data_address = {
                    billing_address_id: this.address_id,
                    cust_id: response,
                    type: address_type,
                    shipping_counter: that.shipping_counter,
                    billing_counter: that.billing_counter,
                    countries: that.country_code,
                    regions: that.reg_code,
                    province: that.province_code,
                    cities: that.city_code,
                    address: that.comp_address,
                    contact_number: that.contact_number,
                    contact_person: that.contact_person,
                    trash: that.address_trash,
                    approved: 0
                };
                const data_discount = {
                    trash: this.discount_trash,
                    cust_id: response,
                    brand_dc: that.brand_discount,
                    brand_name: that.brand_name,
                    dc_type: that.discount_types,
                    dc_arr: that.discount_cards,
                    dc_text_arr: that.discount_text_fields,
                    action: 'create',
                    approved: 0
                };
                if (that.discount_cards.length > 0) {
                    this.$store.dispatch("adddiscount", data_discount);
                }

                this.$store.dispatch("addshippingaddress", data_address);
                this.$store.dispatch("addbillingaddress", data_address);
                this.uploadCustomerFile(response);
                this.$store.commit("NEW_CUSTOMER", true);
                this.tmp = "false";
                // this.adddiscounts();
            });
        },
        updateCustomer() {
            const that = this;
            const address_type = {
                shipping: "shipping",
                billing: "billing",
            };

            if (!this.customer_information.is_mother_customer) {
                this.$refs.form.validate();
                const cpname_swal_text = this.customer_information.company_names
                    ? ""
                    : "Company Name";
                // const classification_swal_text = this.customer_information.classification
                //     ? ""
                //     : "Classification";
                const fname_swal_text = this.customer_information.first_name
                    ? ""
                    : "First Name";
                const lname_swal_text = this.customer_information.last_name
                    ? ""
                    : "Last Name";

                const array_text = [
                    cpname_swal_text,
                    // classification_swal_text,
                    fname_swal_text,
                    lname_swal_text,
                ];

                const filtered = array_text.filter(function (el) {
                    return el.replace(",", "");
                });

                if (
                    !that.customer_information.company_names &&
                    !that.customer_information.last_name &&
                    !that.customer_information.first_name
                    // !classifications_validation
                ) {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: "warning",
                        allowOutsideClick: false,
                    });

                    return false
                }
            }

            const data_company_information = {
                customer: that.customer_information,
                other_contacts_sales: that.other_contacts_sales,
                other_contacts_accounting: that.other_contacts_accounting,
                tin: JSON.stringify(this.customer_information.tin),
                action:'update'
            };

            this.$store.dispatch(this.GET_ACTIVE_TAB == 'for_approval' ? 'updatecustomers': 'addcustomers', data_company_information).then((response) => {
            // this.$store.dispatch("addCustomerTempRecord", data_company_information).then((response) => {
                const data_address = {
                    billing_address_id: this.address_id,
                    cust_id: this.GET_ACTIVE_TAB == 'for_approval' ? this.SELECTED_CUSTOMER.id :response,
                    type: address_type,
                    shipping_counter: that.shipping_counter,
                    billing_counter: that.billing_counter,
                    countries: that.country_code,
                    regions: that.reg_code,
                    province: that.province_code,
                    cities: that.city_code,
                    address: that.comp_address,
                    contact_number: that.contact_number,
                    contact_person: that.contact_person,
                    trash: that.address_trash,
                    approved: 0
                };
                const data_discount = {
                    cust_id: this.GET_ACTIVE_TAB == 'for_approval' ? this.SELECTED_CUSTOMER.id :response,
                    brand_dc: that.brand_discount,
                    brand_name: that.brand_name,
                    dc_type: that.discount_types,
                    dc_arr: that.discount_cards,
                    dc_text_arr: that.discount_text_fields,
                    trash: this.discount_trash,
                    action: 'update',
                    approved: 0
                };

                this.$store.dispatch("adddiscount", data_discount);
                this.$store.dispatch("addbillingaddress", data_address);
                this.$store.dispatch("addshippingaddress", data_address);

                this.uploadCustomerFile();

                this.$store.commit("NEW_CUSTOMER", true);
                this.tmp = "true";

                this.address_id.shipping = [];
                this.address_id.billing = [];
                that.shipping_counter = [];
                that.billing_counter = [];
            });
        },
        _access() {
            let val = this.USER_ACCESS;
            if (val != "fail") {
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                this.userAccess.edit_approve_sales = false;
                this.userAccess.edit_approve_acct = false;
                this.userAccess.edit_approve_exec = false;
                this.userAccess.edit_customer_sales = false;
                this.userAccess.edit_customer_accounting = false;
                this.userAccess.edit_customer_exec = false;
                this.userAccess.delete_file = false;
                this.userAccess.approve_customer = false;
                val.forEach(e => {
                    if (e.actions_code == 'edit') {
                        this.userAccess.edit = true;
                    } else if (e.actions_code == 'delete') {
                        this.userAccess.delete = true;
                    } else if (e.actions_code == 'view') {
                        this.userAccess.view = true;
                    } else if (e.actions_code == 'create') {
                        this.userAccess.create = true;
                    } else if (e.actions_code == 'edit_approve_sales') {
                        this.userAccess.edit_approve_sales = true;
                    } else if (e.actions_code == 'edit_approve_acct') {
                        this.userAccess.edit_approve_acct = true;
                    } else if (e.actions_code == 'edit_approve_exec') {
                        this.userAccess.edit_approve_exec = true;
                    }
                    else if (e.actions_code == 'edit_customer_sales') {
                        this.userAccess.edit_customer_sales = true;
                    }
                    else if (e.actions_code == 'edit_customer_accounting') {
                        this.userAccess.edit_customer_accounting = true;
                    }
                    else if (e.actions_code == 'edit_customer_exec') {
                        this.userAccess.edit_customer_exec = true;
                    }
                    else if (e.actions_code == 'delete_file') {
                        this.userAccess.delete_file = true;
                    }
                    else if (e.actions_code == 'approve_customer') {
                        this.userAccess.approve_customer = true;
                    }
                });
            }
        },
        resetfields() {
            this.customer_information = {
                type: "bus",
                id: "",
                company_names: "",
                last_name: "",
                first_name: "",
                middle_name: "",
                deliver_instructions: "",
                shipping_via: "",
                credit_limit: "",
                tin: { tin: "" },
                vat_rate: "",
                classification: "",
                annual_target: "",
                terms: "",
                sales_man: "",
                sales_coor: "",
                remarks: "",
                terms: "",
                salesman_id: "",
                sales_coordinator_id: "",
                remarks: "",
                credit_limit: "",
                store: 0,
                recycle_so: 0,
                special: 0,
                is_special: 0,
                vip: 0,
                is_active: 1,
                is_store: 0,
                is_print_no_price: 1,
                recycle_so: 0,
                approved_by_sales: 0,
                approved_by_acct: 0,
                approved_by_exec: 0,
                affiliate_customer_id: null,
                auto_cancel_sales_order_on_dispatch: 0,
                tel_1: [],
                tel_2: [],
                tel_3: [],
                fax: [],
                mobile_1: [],
                mobile_2: [],
                mobile_3: [],
                email: [],
                is_mother_customer: 0,
            };
            this.other_contacts_sales =
            {
                contact_type: "Sales",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            }
            this.other_contacts_accounting = {
                contact_type: "Accounting",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            },
            this.total_card_details={
                total_collectibles:0,
                credit_limit:'',
                total_used:0,
                remaining:0,
                vip:'',
                loading:true,
            },
            this.currency_sign = '₱'

            if (this.shipping_counter.length == 0) {
                this.addShippingAddress();
            }
            if (this.billing_counter.length == 0) {
                this.addBillingAddress();
            }
            if (this.discount_cards.length == 0) {
                this.adddiscounts();
            }
        },
        getCardDetails() {
            if (this.ACTION !== 'Submit') {
                this.total_card_details.loading = true
                this.$store.dispatch("getCustomerCardDetails", this.SELECTED_CUSTOMER.id).then(response=>{
                    let data = response.data
                    this.total_card_details.total_collectibles = parseFloat(data.collectibles_and_credit_limit[0].total_collectibles)
                    this.total_card_details.credit_limit = data.credit_limit[0].credit_limit
                    this.total_card_details.vip = data.credit_limit[0].vip
                    this.total_card_details.total_used = parseFloat(data.total_used[0].total_used)
                    if(data.credit_limit[0].credit_limit != 'vip'){
                        this.currency_sign = '₱'
                        this.total_card_details.remaining = parseFloat(this.total_card_details.credit_limit - (this.total_card_details.total_used + this.total_card_details.total_collectibles ))
                    }
                    else{
                        this.currency_sign = '∞'
                        this.total_card_details.remaining = 0
                    }

                    this.total_card_details.loading = false
                }).catch((err)=>{
                    this.total_card_details.loading = false

                });
            }
        },
        uploadedData(data) {
            if (!data) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = data;
        },
        showCollectiblesByCustomer(){
            this.collectibles.dialog = true
            this.collectibles.items = []
            this.collectibles.card.totals = {
                total_cm_amount: 0,
                total_dm_amount: 0,
                total_gross_amount: 0,
                total_balance_amount: 0,
                total_amount: 0,
            }
            let payload = {
                customer_id:this.customer_information.id
            }
            this.$store.dispatch('showCustomerCollectibles',payload).then(response=>{
                this.collectibles.items = response.data
                let soa_month =  _.groupBy(this.collectibles.items, 'selected_month');
                let test = Object.values(soa_month)
                let grouping = []
                let date_today = moment().format('YYYY-MM-DD');
                let overdue_container = []
                test.forEach(e=>{
                    let a = {}
                    this.collectibles
                    let selected_month = ''
                    e.forEach(x=>{
                        selected_month = x.selected_month
                        x.deduction_amount = Number (x.deduction_amount)
                        x.addition_amount = Number (x.addition_amount)
                        x.balance_amount = Number (x.balance_amount)
                        x.gross_amount = Number (x.gross_amount)
                        x.total_amount = Number (x.total_amount)
                        if(moment(x.due_date).isBefore(date_today)){
                            overdue_container.push(x.balance_amount)
                        }
                    })

                    Object.assign(a,{
                        statement_of_accounts: e,
                        total_cm_amount: _.sumBy(e,'deduction_amount'),
                        total_dm_amount: _.sumBy(e,'addition_amount'),
                        total_gross_amount: _.sumBy(e,'gross_amount'),
                        total_balance_amount: _.sumBy(e,'balance_amount'),
                        total_amount: _.sumBy(e,'total_amount'),
                        selected_month: selected_month
                    })
                    this.collectibles.card.totals.total_gross_amount += _.sumBy(e,'gross_amount')
                    this.collectibles.card.totals.total_balance_amount += _.sumBy(e,'balance_amount')
                    this.collectibles.card.totals.total_amount += _.sumBy(e,'total_amount')
                    this.collectibles.card.totals.total_dm_amount += _.sumBy(e,'deduction_amount')
                    this.collectibles.card.totals.total_cm_amount += _.sumBy(e,'addition_amount')
                    this.collectibles.card.totals.total_overdue = _.sum(overdue_container)

                    grouping.push(a)
                })
                this.collectibles.items = grouping;
            })
        },
        agingDate(due_date) {
            if(!!due_date) {
                let startDay = moment(due_date);

                let endDay = moment();

                let days =  moment(endDay).diff(moment(startDay), 'days');
                if(days <= 0){
                    days = 0
                    return days
                }
                else{
                    return days
                }
            }
            return '';
        },
        approveCustomer(){
            this.getallCustomerChanges();
            const that = this;
            const address_type = {
                shipping: "shipping",
                billing: "billing",
            };
            let payload = {
                id: this.SELECTED_CUSTOMER.id,
                approved: 1,
                action: this.SELECTED_CUSTOMER.action
            }
            this.$store.dispatch('addcustomers',payload).then((res)=>{
                const data_discount = {
                    trash: this.discount_trash,
                    cust_id: this.SELECTED_CUSTOMER.customer_id ?? res,
                    brand_dc: that.brand_discount,
                    brand_name: that.brand_name,
                    dc_type: that.discount_types,
                    dc_arr: that.discount_cards,
                    dc_text_arr: that.discount_text_fields,
                    action: 'create',
                    approved: 1
                };

                this.$store.dispatch("adddiscount", data_discount);
                const data_address = {
                    billing_address_id: this.address_id,
                    cust_id: this.SELECTED_CUSTOMER.customer_id,
                    type: address_type,
                    shipping_counter: that.shipping_counter,
                    billing_counter: that.billing_counter,
                    countries: that.country_code,
                    regions: that.reg_code,
                    province: that.province_code,
                    cities: that.city_code,
                    address: that.comp_address,
                    contact_number: that.contact_number,
                    contact_person: that.contact_person,
                    trash: that.address_trash,
                    approved: 1
                };

                this.$store.dispatch("addshippingaddress", data_address);
                this.$store.dispatch("addbillingaddress", data_address);

                // Sending request to add clusters for the target customer.
                let payload = {
                    customer_id: this.SELECTED_CUSTOMER.customer_id,
                    clusters: this.changesInClusters,
                    sub_clusters: this.changesInSubClusters,
                }
                this.$store.dispatch("addCustomerCluster", payload);
            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                );
                console.log(err)
            })
            this.$store.commit("APPROVAL_DIALOG", false);
            this.$store.commit("NEW_CUSTOMER", true);
            this.$store.dispatch("getAllTempCustomer")
            this.tmp = "true";
        },
        getAllChanges(){
            let that = this;
            this.address_changes_arr = []
            let address_arr_key = [
                'customer_billing_address',
                'customer_shipping_address',
            ];
            let notIncluded = ['created_by_user', 'code', 'updated_by_user', 'shipping_addresses', 'tel_1',
                'tel_2',
                'tel_3',
                'fax',
                'mobile_1',
                'mobile_2',
                'mobile_3',
                'email',
                'billing_addresses', 'all_shipping_addresses', 'other_contacts', 'id', 'created_at', 'updated_at', 'customer_category_id', 'customer_code', 'customer_classification', 'customer_category', 'salesman_id']
            this.customer_name = this.GET_CUSTOMER_CHANGES.old_customer_info.company_name;
            Object.keys(this.GET_CUSTOMER_CHANGES.old_customer_info).forEach((k) => {
                if (notIncluded.includes(k)) {
                    return;
                }

                // Convert k to the desired format (replace underscores and capitalize first letters)
                const formattedKey = k.replace(/_/g, ' ').replace(/\bid\b/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());

                // If object is not an array and doesn't have properties
                if (
                    this.GET_CUSTOMER_CHANGES.old_customer_info[k] !== this.GET_CUSTOMER_CHANGES.new_customer_info[k] &&
                    (!Array.isArray(this.GET_CUSTOMER_CHANGES.old_customer_info[k]))
                ) {
                    // Assigning discounts on v-models
                    if(k == 'discounts'){
                        if(this.GET_CUSTOMER_CHANGES.old_customer_info.discounts != this.GET_CUSTOMER_CHANGES.new_customer_info.discounts){
                            this.with_discount_changes = true
                            this.GET_CUSTOMER_CHANGES.old_customer_info.customer_discount.forEach(async (e, key) => {
                                that.GET_BRAND_DISCOUNT.find(element_dc => {
                                    if (element_dc.value == e.brand_id) {
                                        this.brand_name = element_dc.text
                                    }
                                });

                                this.old_discount_cards[key] = key;
                                this.old_discount_text_fields[key] = {
                                    arr: [],
                                };
                                this.brand_discount[key] = e.brand_id;
                                await e.discount_value.forEach((value, keys) => {
                                    this.GET_DISCOUNT_TYPE.forEach((dtype, index) => {
                                        if (value.discount_type_id == dtype.value) {
                                            this.old_discount_text_fields[key].arr[index] = 0
                                            this.old_discount_text_fields[key].arr[index] =
                                                value.discount_value;
                                        }
                                    });
                                });
                            });
                            this.GET_CUSTOMER_CHANGES.new_customer_info.customer_discount.forEach(async (e, key) => {
                                that.GET_BRAND_DISCOUNT.find(element_dc => {
                                    if (element_dc.value == e.brand_id) {
                                        this.brand_name = element_dc.text
                                    }
                                });

                                this.new_discount_cards[key] = key;
                                this.new_discount_text_fields[key] = {
                                    arr: [],
                                };
                                this.brand_discount[key] = e.brand_id;
                                await e.discount_value.forEach((value, keys) => {
                                    this.GET_DISCOUNT_TYPE.forEach((dtype, index) => {
                                        if (value.discount_type_id == dtype.value) {
                                            this.new_discount_text_fields[key].arr[index] = 0
                                            this.new_discount_text_fields[key].arr[index] =
                                                value.discount_value;
                                        }
                                    });
                                });
                            });
                        }else{
                            this.with_discount_changes = false
                        }
                    }
                    else if(k != 'valid_ids'){
                        this.customer_changes.push({
                            key_text: formattedKey,
                            key: k,
                            old_value: this.GET_CUSTOMER_CHANGES.old_customer_info[k],
                            new_value: this.GET_CUSTOMER_CHANGES.new_customer_info[k],
                        });
                    }
                }
                // If value is string
                if(typeof this.GET_CUSTOMER_CHANGES.old_customer_info[k] == 'string'){
                    let old_val = ''
                    let new_val = ''

                    if(this.isStringConvertibleToJSON(this.GET_CUSTOMER_CHANGES.old_customer_info[k]) && this.isStringConvertibleToJSON(this.GET_CUSTOMER_CHANGES.new_customer_info[k]) ){
                        old_val =  JSON.parse(this.GET_CUSTOMER_CHANGES.old_customer_info[k]) ?? ''
                        new_val =  JSON.parse(this.GET_CUSTOMER_CHANGES.new_customer_info[k]) ?? ''
                    }else{
                        old_val = ''
                        new_val = ''
                    }
                    switch(k){
                        case 'valid_ids':
                            if(old_val.tin != new_val.tin){
                                this.customer_changes.push({
                                    key_text: 'TIN',
                                    key: 'TIN',
                                    old_value: old_val.tin ,
                                    new_value: new_val.tin ,
                                });
                            }
                            break;
                        case 'other_contacts':
                    }
                }
                // If it is an array
                else if (Array.isArray(this.GET_CUSTOMER_CHANGES.old_customer_info[k]) && address_arr_key.includes(k) ) {
                    const oldArray = this.GET_CUSTOMER_CHANGES.old_customer_info[k];
                    const newArray = this.GET_CUSTOMER_CHANGES.new_customer_info[k];
                    let notIncludedArray = this.notIncludedKeys;
                    let includedKeys = ['current_address', 'permanent_address', 'mobile_number']
                    let addressformattedKey = k.replace(/_/g, ' ').replace(/\bid\b/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
                    let capitalize = s => s && s[0].toUpperCase() + s.slice(1)

                    // Check if the arrays have different lengths
                    if (oldArray.length !== newArray.length) {
                        for (let i = 0; i < (oldArray.length > newArray.length ? oldArray.length : newArray.length); i++) {
                            let has_changes = false;
                            let arrayTemp = {
                                section_key: addressformattedKey,
                                changesTemp: []
                            };
                            let arrKeys = oldArray.length > newArray.length ? oldArray[i] : newArray[i];
                            Object.keys(arrKeys).forEach((x)=>{
                                let oldVal = oldArray[i] == undefined ? '' : oldArray[i][x];
                                let newVal = newArray[i] == undefined ? '' : newArray[i][x];
                                let xKey = x.replace(/_/g, ' ').replace(/\bid\b/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
                                // let xKey = `(${i + 1}) `+ x.replace(/_/g, ' ').replace(/\bid\b/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
                                if (oldVal != newVal && !notIncludedArray.includes(x)) {
                                    let objChanges = {
                                        key_text: includedKeys.includes(xKey) ? addressformattedKey: capitalize(xKey).replace(/\bNum\b/g, 'No.').replace(/\bPrev\b/g, 'Previous'),
                                        key: k,
                                        old_value: oldVal,
                                        new_value: newVal,
                                        section_key: addressformattedKey
                                    };
                                    arrayTemp.changesTemp.push(objChanges)
                                    has_changes = true;
                                }
                            })
                            if(has_changes){
                                this.address_changes_arr.push({
                                    arrChanges: arrayTemp,
                                    isArray: true,
                                });
                            }
                        }
                    } else {
                        // Iterate through the arrays and compare elements
                        for (let i = 0; i < (oldArray.length != 0 ? oldArray.length : newArray.length); i++) {
                            let has_changes = false;
                            let arrayTemp = {
                                section_key: addressformattedKey,
                                changesTemp: []
                            };
                            Object.keys(oldArray[i]).forEach((x) => {
                                if (oldArray[i][x] != newArray[i][x] && !notIncludedArray.includes(x)) {
                                    // let objChanges = [{
                                    //     key_text: includedKeys.includes(k) ? addressformattedKey: capitalize(x).replace(/\bNum\b/g, 'No.').replace(/\bPrev\b/g, 'Previous'),
                                    //     key: k,
                                    //     old_value: oldArray[i][x],
                                    //     new_value: newArray[i][x],
                                    //     section_key: addressformattedKey
                                    // }];
                                    let xKey = x.replace(/_/g, ' ').replace(/\bid\b/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
                                    // let xKey = `(${i + 1}) `+ x.replace(/_/g, ' ').replace(/\bid\b/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
                                    let objChanges = {
                                        key_text: includedKeys.includes(k) ? addressformattedKey: capitalize(xKey).replace(/\bNum\b/g, 'No.').replace(/\bPrev\b/g, 'Previous'),
                                        key: k,
                                        old_value: oldArray[i][x],
                                        new_value: newArray[i][x],
                                        section_key: addressformattedKey
                                    };
                                    arrayTemp.changesTemp.push(objChanges)
                                    // this.employee_changes.push({
                                    //     arrChanges: objChanges,
                                    //     isArray: true,
                                    // });
                                    has_changes = true
                                }
                            });
                            if(has_changes){
                                this.address_changes_arr.push({
                                    arrChanges: arrayTemp,
                                    isArray: true,
                                });
                            }
                        }
                    }
                }
            });
        },
        formatKey(key){
            let formattedKey = key.replace(/_/g, ' ').replace(/\bid\b/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())
            // console.log(formattedKey)
            return formattedKey
        },
        checkValues(key, index, column_name){
            let oldVal =  this.GET_CUSTOMER_CHANGES.old_customer_info[column_name][index][key] ?? '';
            let newVal =  this.GET_CUSTOMER_CHANGES.new_customer_info[column_name][index][key] ?? '';

            if(oldVal != newVal){
                return true;
            }
            else{
                return false;
            }
        }
    },

    created() {
        this.getCardDetails();
        if (this.ACTION == 'Update') {
            // this.getallCustomerChanges();
        }
        if (this.tmp == "false") {
            this.resetfields();
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (val) {
                    // this.getCardDetails();
                    // this.$store.dispatch("getBranddiscount");
                    // this.$store.dispatch("discounttype");
                    // this.$store.dispatch("getAffliatecustomer");
                    // this.$store.dispatch("getSalesmanDropDown");
                    // this.$store.dispatch("getSalesmanCoorDropDown");
                    // this.$store.dispatch("getCountries");
                    // this.$store.dispatch("getRegions");
                    // this.$store.dispatch("getProvince");
                    // this.$store.dispatch("getCities");
                    this.$store.dispatch("getCustomerCode");
                    // this.$store.dispatch("getClassification");
                    if (this.tmp == "false") {
                        this.resetfields();
                    }
                    else if (this.tmp == "true") {
                        this.getallCustomerChanges();
                    }

                }
            },
        },
        GET_CUSTOMER_ID: {
            handler(val) {
                this.cust_id = val;
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                this._access();
            },
        },
        GET_CUSTOMER_CHANGES:{
            handler(val){
                this.getAllChanges();
            }
        },
        // GET_APPROVAL_DIALOG:{
        //     handler(val){
        //         this.getAllChanges();
        //     }
        // }
    },
};
</script>
<style lang="scss" scoped>
// div ::v-deep .v-label.v-label--active.theme--light {
//     left: 0 !important;
//     right: auto !important;
// }

// div ::v-deep .v-input__slot fieldset legend {
//     margin-left: 10px !important;
//     width: auto;
// }
</style>
