<template>
    <div>
        <!-- <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app> -->
        <v-app id="view_client_modal">
            <v-dialog v-model="container_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-card-title>
                                <span class="headline">Container Details</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="clearContainerItems(); $emit('closeView');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <v-row class="m-0">
                            <v-container fluid>
                                <v-row>
                                    <div class="col-sm-12 col-md-6 text-left text-dark">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <ContainerBaseFileViewerVue class="pa-0" :container_id="viewContainerId" />
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <!-- <div class="col-sm-12 col-md-8 p-0">Doc Num: {{ container.doc_num }}</div> -->
                                            <div class="col-sm-12 col-md-8 p-0">Container: {{ container.container_num }} </div>
                                            <div class="col-sm-12 col-md-4 p-0">Issuer: {{ container.user_name }}</div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">Warehouse: {{ container.warehouse_name }}</div>
                                            <div class="col-sm-12 col-md-4 p-0">Date Issued: {{ container.created_at | formatDateTime }}</div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">Remarks: {{ container.remarks }}</div>
                                            <div class="col-sm-12 col-md-4 p-0">Status: {{ container.status_text }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-8 p-0">Broker: {{ container.broker }}</div>
                                            <div class="col-sm-12 col-md-4 p-0">PO Number: {{ container.po_number }}
                                            </div>
                                        </div>
                                        <div class="row ml-2">
                                            <div class="col-sm-12 col-md-12 p-0"></div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 pull-right text-right">
                                        <!-- <v-btn class="ma-1" @click="sendEmail()" :loading="loading_send">Send Email</v-btn> -->
                                        <v-btn class="ma-1" @click="renderPDF('download', 'shipment receiving')">Shipment Receiving Form</v-btn>
                                        <v-menu
                                            transition="slide-y-transition"
                                            bottom
                                            nudge-top="-40"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                :loading="loading_send"
                                                class="ma-1"
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                Send Email
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                v-for="(item, i) in attachmentType"
                                                :key="i"
                                                >
                                                <v-list-item-title style="cursor:pointer;" @click="sendEmail(item.type)">{{ item.title }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-btn class="ma-1" @click="exportToExcel('download')">Export to Excel</v-btn>
                                        <v-btn class="ma-1" @click="renderPDF('download')">Download PDF</v-btn>
                                    </div>
                                </v-row>
                            </v-container>
                        </v-row>
                        <v-row class="table-container">
                            <v-data-table :headers="containerItemsHeaders" :items="containerItems" :items-per-page="500"
                                hide-default-footer class="w-100 flex-table" :loading="!loaded" style="max-height:55vh;" fixed-header>
                                <template v-slot:item="{ item }">
                                    <tr style="pointer-events: none;" :class="{
                                        'inactive-container-item': item.item.active === 0,
                                        'unequal-upload-container-item': item.po_num != null && item.import == 1 && (item.uploaded_mb_qty != item.quantity_master_box_conv),
                                        'searched-container-item': item.model.includes(searchText) || item.name.includes(searchText),
                                        'over-receive-container-item': item.received_type == 1 ?
                                            item.quantity_master_box > item.quantity_received :
                                            item.quantity_master_box > (item.quantity_received / item.quantity_master_box_conv),
                                        'under-receive-container-item': item.received_type == 1 ?
                                            item.quantity_master_box < item.quantity_received :
                                            item.quantity_master_box < (item.quantity_received / item.quantity_master_box_conv)
                                    }" :key="item.id">

                                        <td v-text="item.po_num"></td>
                                        <td v-text="item.model"></td>
                                        <td v-text="item.name"></td>
                                        <td v-text="item.item_uom_text"></td>
                                        <td v-text="item.item_type_text"></td>
                                        <td class="text-center" v-text="item.quantity"></td>
                                        <td class="text-center" v-text="item.quantity_master_box"></td>
                                        <!-- <td class="text-center" v-text="item.uploaded_mb_qty"></td> -->
                                        <td class="text-center"
                                            v-text="item.received_type == 1 ? item.quantity_received : item.quantity_received / item.quantity_master_box_conv">
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-row>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <span class="m-1">
                                    <v-btn :loading="loading_approve" @click="approveContainer()" v-if="ACCESS_INBOUND.includes('approve_container') &&
                                    container.status_text == 'pending' &&
                                    !is_receiving &&
                                    container.received_date !== null">
                                        Approve
                                    </v-btn>
                                </span>
                                <span class="m-1">
                                    <v-btn :loading="loading_reject" color="error" @click="rejectContainer()" v-if="ACCESS_INBOUND.includes('reject_container') &&
                                    container.status_text == 'pending' &&
                                    !is_receiving">
                                        Reject
                                    </v-btn>
                                </span>
                                <span class="m-1">
                                    <v-btn :loading="loading_receive" @click="receiveContainer()" v-if="ACCESS_INBOUND.includes('receive_container') &&
                                    container.status_text == 'pending' &&
                                    is_receiving &&
                                    container.received_date === null">
                                        Receive
                                    </v-btn>
                                </span>
                            </div>
                        </div>
                    </v-card-text>

                </v-card>
            </v-dialog>

            <v-container fluid style="display:none;">
                <div>
                    <table id="container_details">
                        <tbody>
                            <tr>
                                <td>Warehouse:</td>
                                <td>{{ container.warehouse_name }}</td>
                                <td>Container:</td>
                                <td>{{ container.container_num }}</td>
                            </tr>
                            <tr>
                                <td>Remarks:</td>
                                <td>{{ container.remarks }}</td>
                                <td>Issuer:</td>
                                <td>{{ container.user_name }}</td>
                            </tr>
                            <tr>
                                <td>Doc Num</td>
                                <td>{{ container.doc_num }}</td>
                                <td>Date Issued:</td>
                                <td>{{ container.created_at | formatDate }}</td>
                            </tr>
                            <tr>
                                <td>Broker</td>
                                <td>{{ container.broker }}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- for shipment receiving form -->
                    <table id="container_details_for_shipment_receiving">
                        <tbody>
                            <tr>
                                <td>Warehouse:</td>
                                <td>{{ container.warehouse_name }}</td>
                                <td>Container:</td>
                                <td>{{ container.container_num }}</td>
                            </tr>
                            <tr>
                                <td>Remarks:</td>
                                <td>{{ container.remarks }}</td>
                                <td>Issuer:</td>
                                <td>{{ container.user_name }}</td>
                            </tr>
                            <tr>
                                <td>Broker</td>
                                <td>{{ container.broker }}</td>
                                <td>Date Issued:</td>
                                <td>{{ container.created_at | formatDate }}</td>
                            </tr>
                            <tr>
                                <td>Confirmed Date</td>
                                <td>{{ container.confirm_date ? container.confirm_date.split(' ')[0].replace(/-/g, '/') : '' }}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table id="items_details">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Model</th>
                                <th>Name</th>
                                <th>UOM</th>
                                <th>Type</th>
                                <th>QTY (UOM)</th>
                                <th>QTY/MB</th>
                                <th>QTY (MB)</th>
                                <th>QTY RCV (MB)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(containerItem, index ) in containerItems" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ containerItem.model }}</td>
                                <td>{{ containerItem.name }}</td>
                                <td>{{ containerItem.item_uom_text }}</td>
                                <td>{{ containerItem.item_type_text }}</td>
                                <td>{{ containerItem.quantity }}</td>
                                <td>{{ containerItem.quantity_master_box_conv }}</td>
                                <td>{{ containerItem.quantity_master_box }}</td>
                                <td>{{ container.received_date != null ? containerItem.quantity_received: '' }}</td>
                            </tr>
                            <tr>
                                <td colspan="9">***** NOTHING FOLLOWS *****</td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- for shipment receiving form -->
                    <table id="signatory" style="display: none;">
                        <thead>
                            <tr>
                                <th colspan="2">Received by</th>
                                <th>Validated by</th>
                                <th>Approved by</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td><td></td>
                                <td>Hazel Umali</td>
                                <td>Dianne Orleans</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-container>
        </v-app>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2'
import jsPDF from 'jspdf';
import moment from 'moment';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver');
import ContainerBaseFileViewerVue from '@/views/main/modules/Operations/Containers/base/ContainerBaseFileViewer.vue';

export default {
    props: [
        'viewContainerId',
        'searchText',
        'is_receiving',
        'user_access'
    ],
    components:{
        ContainerBaseFileViewerVue
    },
    data() {
        return {
            containerItems: [],
            containerItemsHeaders: [
                { text: 'PO Num', align: 'left', value: 'po_num', width: '10%' },
                { text: 'Model', align: 'left', value: 'model', width: '20%' },
                { text: 'Name', align: 'left', value: 'name', width: '45%' },
                { text: 'UOM', align: 'left', value: 'item_uom_text' },
                { text: 'Type', align: 'left', value: 'item_type_text' },
                { text: 'Quantity UOM', align: 'center', value: 'quantity' },
                { text: 'Quantity MB', align: 'center', value: 'quantity_master_box' },
                // { text: 'Uploaded MB QTY', align: 'center', value: 'uploaded_mb_qty' },
                { text: 'Quantity Received (MB)', align: 'center', value: 'quantity_received' },
            ],

            loaded: true,
            container_dialog: false,
            container: {
                doc_num: '',
                container_num: '',
                warehouse_id: '',
                warehouse_name: '',
                remarks: '',
                user_name: '',
                created_at: '',
                status: '',
                status_text: '',
                broker: '',
                po_number: ''
            },

            errors: [],
            userAccess: {
                approve: false,
                reject: false,
                receive: false
            },

            loading_approve: false,
            loading_reject: false,
            loading_receive: false,
            loading_send: false,
            blob_url:null,
            with_attachment:0,
            attachmentType: [
                {title:"EXCEL", type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
                {title:"PDF", type:'application/pdf'},
            ]
        }
    },
    computed: {
        ...mapGetters([
            'RECEIVE_CONTAINERS',
            'APPROVE_CONTAINERS',
            'REJECT_CONTAINERS',
            'EXPORT_CONTAINERS',
            'GET_ALL_CONTAINERS',
            'ACCESS_INBOUND'
        ]),
    },
    methods: {
        toggleLoadingButtons(val = false) {
            this.loading_approve = val
            this.loading_reject = val
            this.loading_receive = val
        },
        approveContainer() {
            this.toggleLoadingButtons(true)
            let inactiveItems = this.containerItems.filter(
                item => item.item.active === 0
            ).map(
                item => {
                    return `- ${item.model} : ${item.name}`;
                }
            );

            let impartialReceiveItems = this.containerItems.filter(
                item => item.quantity_master_box != item.quantity_received
            ).map(
                item => {
                    return `- ${item.model} : ${item.name} \n`
                }
            )
            let confirmInactive = `<br><br>Confirming approval will activate these inactive items.<br>${inactiveItems.join('<br>')}`

            let impartialReceive = `<br><br>Confirming approval will receive partially received items.<br><br>${impartialReceiveItems.join('<br>')}`

            let confirmApproval = 'Are you sure you want to Approve?';
            swal.fire({
                title: '',
                html: confirmApproval + (inactiveItems.length ? confirmInactive : '') + (impartialReceiveItems.length ? impartialReceive : ''),
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: 'Ok',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '397373',
                cancelButtonColor: 'grey',
                reverseButtons: true
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('approveContainers', this.viewContainerId).then(response => {
                            let data = this.APPROVE_CONTAINERS

                            if (data.error) {
                                swal.fire("", 'Approve Container Failed', "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeContainerStatus')

                            this.container_dialog = false

                            swal.fire("", "Approve Container Succesfful", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
        },
        rejectContainer() {
            this.toggleLoadingButtons(true)
            swal.fire({
                title: "",
                text: "Are you sure you want to Reject?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Reject',
                reverseButtons: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch('rejectContainers', this.viewContainerId).then(response => {
                            let data = this.REJECT_CONTAINERS
                            if (data.error) {
                                swal.fire("", "Reject Container Failed", "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()
                            this.$emit('changeContainerStatus')

                            this.container_dialog = false

                            swal.fire("", "Container Rejected", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
        },
        receiveContainer() {
            this.toggleLoadingButtons(true)
            swal.fire({
                title: "",
                text: "Are you sure you want to Receive? This will non-amendable afterwards.",
                icon: "warning",
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonText: 'Confirm',
                confirmButtonColor: '#397373',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonColor: 'grey',

            })
                .then((result) => {
                    if (result.isConfirmed) {

                        this.$store.dispatch('receiveContainers', this.viewContainerId).then(response => {
                            let data = this.RECEIVE_CONTAINERS
                            if (data.error) {
                                swal.fire("", "Container Receive Failed", "warning");
                                this.toggleLoadingButtons(false)
                                return false
                            }
                            this.resetFields()

                            this.container_dialog = false

                            swal.fire("", "Container Receive Successful", "success");
                            this.toggleLoadingButtons(false)
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            this.toggleLoadingButtons(false)
                        })
                    } else {
                        this.toggleLoadingButtons(false)
                    }
                });
        },
        clearContainerItems() {
            this.resetFields()
            this.container_dialog = false
        },
        async exportToExcel(exportType) {
            let that = this
            let self = this;

            const workbook = new ExcelJS.Workbook();
            let headerStyle = {
                style: { font: { bold: true, name: 'Comic Sans MS' } },

            }
            that.loaded = false
            const payload = {
                container_id: this.viewContainerId
            }
            await this.$store.dispatch('exportContainers', payload).then(async response => {
                const worksheet = workbook.addWorksheet('Raw Data')
                worksheet.columns = [
                    { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: false },
                    { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                    { header: 'NAME', key: 'name', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                    { header: 'DESCRIPTION', key: 'description', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: false },
                    { header: 'CONTAINER #', key: 'container_num', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'DATE RECEIVED', key: 'date_received', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'QTY (UOM)', key: 'quantity', width: 15, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'QTY/MB', key: 'master_box_qty', width: 10, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'QTY (MB)', key: 'mb_qty', width: 10, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'QTY RCV (MB)', key: 'quantity_received', width: 15, style: { numFmt: '#,##0', alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                    { header: 'SRP', key: 'srp', style: { numFmt: '#,##0.00' ,alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                ]
                let pos = 1;

                for (let i in this.EXPORT_CONTAINERS) {
                    this.EXPORT_CONTAINERS[i].srp = parseFloat(this.EXPORT_CONTAINERS[i].srp)
                    worksheet.addRow(this.EXPORT_CONTAINERS[i]);

                    let img = document.createElement('img');
                    let base64 = ''

                    await this.getBase64Image(`${process.env.VUE_APP_API_HOST}/img/items?image=${this.EXPORT_CONTAINERS[i].model}`, (res) => {
                        img.setAttribute('data', res);

                    }).then(response => {
                        base64 = response
                    })

                    // let finalImage = workbook.addImage({
                    //     base64: base64,
                    //     extension: 'jpg',
                    // });

                    // // worksheet.addImage(finalImage, `A${pos}:A${pos}`);
                    // worksheet.getRow(pos).height = 250;
                    // worksheet.addImage(finalImage, {
                    //     tl: { col: 0.99, row: pos + 0.9 },
                    //     ext: { width: 150, height: 150 },
                    //     editAs: 'undefined'
                    // });

                    let height = 80;
                    let width = 60;
                    let aspectRatio = null;

                    worksheet.getRow(pos + 1).height = height;

                    let image = await new Promise((resolve) => {
                        let img = new Image();
                        img.src = base64;
                        img.onload = () => resolve(img);
                    });
                    aspectRatio = image.naturalWidth / image.naturalHeight;
                    let imageHeight = aspectRatio * width;

                    let finalImage = workbook.addImage({
                        base64: base64,
                        extension: 'jpg',
                    });

                    worksheet.addImage(finalImage, {
                        tl: { col: 0.1, row: pos + 0.1 },
                        br: { col: 1, row: worksheet.rowCount },
                        ext: { width: width, height: height },
                        editAs: 'twoCell',
                    });

                    // if (item.base64NewItemTag !== null) {
                    //     let finalImageNewItem = workbook.addImage({
                    //         base64: item.base64NewItemTag,
                    //         extension: 'jpg',
                    //     });

                    //     worksheet.addImage(finalImageNewItem, {
                    //         tl: { col: 0, row: pos },
                    //         ext: { width: 50, height: 50 },
                    //         editAs: 'undefined',
                    //     });
                    // }

                    let row = worksheet.getRow(worksheet.rowCount);
                    row.width = width
                    if (imageHeight > worksheet.getRow(pos + 1).height) {
                        worksheet.getRow(pos + 1).height = imageHeight;
                    }

                    pos++;

                    // worksheet.getCell(`H${i + 2}`).value = parseFloat(this.EXPORT_CONTAINERS[i].srp)
                    // worksheet.getCell(`H${i + 2}`).numFmt = '₱#,##0.00'
                }
                worksheet.getRow(1).height = 30;
                let borderStyles = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
                worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                    row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                        cell.border = borderStyles;
                    });
                });
                worksheet.getRow(`0${1}`).font = { bold: true }
                // worksheet.getColumn('G').numFmt = '#,##0'
                // worksheet.getColumn('G').alignment = { horizontal: 'right' };
            }).catch(e => {
                swal.fire('Error !', '', 'error')
                console.log(e)
                that.loaded = true
            })
            that.loaded = true
                workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                    if(exportType == 'download'){
                        FileSaver.saveAs(blob, `${this.container.container_num}.xlsx`);
                    }else{
                        let filename = 'Container#' + this.container.container_num + '.xlsx';
                        let containerNum = 'Container#' +this.container.container_num;
                        var reader  = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function () {
                            this.blob_url = reader.result;
                            if(self.with_attachment == 1){
                                let payload = {
                                    attachment: reader.result,
                                    filename:   filename,
                                    mimeType:   blob.type,
                                    container_id: self.viewContainerId,
                                    containerNum: containerNum,
                                }
                            self.$store.dispatch('sendContainerEmail',payload).then((res)=>{
                                    swal.fire(res.data.text,"",res.data.icon)
                                    self.loading_send = false;
                                }).catch(error => {
                                    swal.fire("An Error Occured", error, "error");
                                    self.loading_send = false;
                                })
                            }
                            else{
                                self.$store.dispatch('sendContainerEmail').then((res)=>{
                                    swal.fire(res.data.text,"",res.data.icon)
                                    self.loading_send = false;
                                }).catch(error => {
                                    swal.fire("An Error Occured", error, "error");
                                    self.loading_send = false;
                                })
                            }
                        };
                    }
                });

        },
        renderPDF(pdfAction, type) {
            var doc = new jsPDF('p', 'mm', 'letter')
            let table_height = 0 ;

            let self = this;
            doc.setFontSize(14)
            doc.text(6, 15, type === 'shipment receiving' ? 'Shipment Receiving Form' : 'Container Details')

            doc.autoTable({
                html: type === 'shipment receiving' ? '#container_details_for_shipment_receiving' : '#container_details',
                theme: 'plain',
                margin: { top: 20, left: 6, right: 6 },
                columnStyles: {
                    0: { cellWidth: 35 },
                    1: { cellWidth: 90 },
                    2: { cellWidth: 26 },
                    3: { cellWidth: 50 },
                }
            });

            doc.autoTable({
                html: '#items_details',
                startY: doc.autoTable.previous.finalY + 3,
                margin: { top: 50,left: 6, right: 6 , bottom: 40 },
                theme: 'grid',
                headStyles: {
                    halign: 'center',
                    valign: 'middle',
                    fillColor: '#ffffff',
                    textColor: '#000000',
                    lineWidth: .2,
                    lineColor: '#000000',
                },
                styles: {
                    fontSize: 8
                },
                columnStyles: {
                    0: { cellWidth: 11, halign: 'center', fontSize: 8, textColor: '#000000' },
                    1: { cellWidth: 27, halign: 'center', fontSize: 7, textColor: '#000000' },
                    2: { halign: 'center', fontSize: 7, textColor: '#000000' },
                    3: { cellWidth: 17, halign: 'center', fontSize: 8, textColor: '#000000' },
                    4: { cellWidth: 11, halign: 'center', fontSize: 8, textColor: '#000000' },
                    5: { cellWidth: 18, halign: 'center', fontSize: 8, textColor: '#000000' },
                    6: { cellWidth: 18, halign: 'center', fontSize: 8, textColor: '#000000' },
                    7: { cellWidth: 18, halign: 'center', fontSize: 8, textColor: '#000000' },
                    8: { cellWidth: 18, halign: 'center', fontSize: 8, textColor: '#000000' },
                },
                bodyStyles: {
                    lineColor: '#000000',
                },
                pageBreak:'auto',
                didDrawPage:(d) => {
                    table_height = d.cursor.y
                },
            });

            const totalPages = doc.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i); // Set the current page
                if(i > 1){
                    doc.setFontSize(14)
                    doc.text(6, 15, type === 'shipment receiving' ? 'Shipment Receiving Form' : 'Container Details')
                    doc.autoTable({
                        html: type === 'shipment receiving' ? '#container_details_for_shipment_receiving' : '#container_details',
                        theme: 'plain',
                        margin: { top: 20, left: 6, right: 6 },
                        columnStyles: {
                            0: { cellWidth: 35 },
                            1: { cellWidth: 90 },
                            2: { cellWidth: 26 },
                            3: { cellWidth: 50 },
                        }
                    });
                }

                let pageNumber = "Page " + i + " of " + totalPages;
                doc.setFontSize(7);
                doc.setTextColor("#000000");
                doc.text(190, 270, pageNumber);
            }

            if (type === 'shipment receiving') {
                doc.autoTable({
                    html: '#signatory',
                    startY: 246,
                    margin: {
                        left: 6, right: 6,
                    },
                    theme: 'plain',
                    headStyles: {
                        halign: 'center',
                        valign: 'middle',
                        fillColor: '#ffffff',
                        textColor: '#000000',
                        lineWidth: .2,
                        lineColor: '#000000',
                    },
                    columnStyles: {
                        0: { cellWidth: 50, halign: 'center', textColor: '#000000', lineWidth: .2, lineColor: '#000000', cellPadding: {top: 6, bottom: 1,} },
                        1: { cellWidth: 50, halign: 'center', textColor: '#000000', lineWidth: .2, lineColor: '#000000', cellPadding: {top: 6, bottom: 1,} },
                        2: { halign: 'center', textColor: '#000000', lineWidth: .2, lineColor: '#000000', cellPadding: {top: 6, bottom: 1,}, },
                        3: { halign: 'center', textColor: '#000000', lineWidth: .2, lineColor: '#000000', cellPadding: {top: 6, bottom: 1,}, },
                    },
                });
            }

            if (pdfAction == 'download') {
                // doc.save('Container ' + this.container.doc_num + '.pdf');
                doc.save(this.container.container_num + '.pdf');
            } else if (pdfAction == 'print') {
                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');
            }
            else if (pdfAction == 'sendEmail'){
                let filename = 'Container#' + this.container.container_num + '.pdf';
                let containerNum = 'Container#' + this.container.container_num;

                let blob = doc.output('blob')
                var reader  = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    this.blob_url = reader.result;
                    if(self.with_attachment == 1){
                        let payload = {
                            attachment: reader.result,
                            filename:   filename,
                            mimeType:   blob.type,
                            container_id: self.viewContainerId,
                            containerNum: containerNum
                        }
                    self.$store.dispatch('sendContainerEmail',payload).then((res)=>{
                            swal.fire(res.data.text,"",res.data.icon)
                            self.loading_send = false;
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            self.loading_send = false;
                        })
                    }
                    else{
                        self.$store.dispatch('sendContainerEmail').then((res)=>{
                            swal.fire(res.data.text,"",res.data.icon)
                            self.loading_send = false;
                        }).catch(error => {
                            swal.fire("An Error Occured", error, "error");
                            self.loading_send = false;
                        })
                    }
                };
            }
        },

        async getBase64Image(src, callback) {
            let img = new Image();
            img.src = src
            img.crossOrigin = "Anonymous"
            let imageHandler = new Promise(resolve => {
                img.onload = function () {
                    let canvas = document.createElement("canvas");
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);

                    let dataUrl = canvas.toDataURL("image/jpg");
                    resolve(dataUrl);
                }
            })
            return imageHandler
        },
        resetFields() {
            this.containerItems = []
            this.container = {
                doc_num: '',
                container_num: '',
                warehouse_id: '',
                warehouse_name: '',
                remarks: '',
                user_name: '',
                created_at: '',
                status: '',
                status_text: '',
                broker: '',
                confirm_date: '',
            }
        },
        async sendEmail(fileType){
            this.loading_send = true;
            // this.renderPDF('sendEmail')
            if(fileType == 'application/pdf'){
                this.renderPDF('sendEmail');
            }else{
                this.exportToExcel('sendEmail');
            }
        },
    },
    watch: {
        viewContainerId: function (val) {
            this.errors = []

            if (val != -1) {
                if (this.viewContainerId != null && this.viewContainerId > 0) {
                    this.container_dialog = true
                    this.loaded = false

                    this.$store.dispatch('viewContainers', this.viewContainerId).then(response => {
                        let data = response.data

                        this.container.doc_num = data.doc_num
                        this.container.container_num = data.container_num
                        this.container.warehouse_id = data.warehouse_id
                        this.container.warehouse_name = data.warehouse_name
                        this.container.remarks = data.remarks
                        this.container.user_name = data.user_name
                        this.container.created_at = data.created_at
                        this.container.status = data.status
                        this.container.status_text = data.status_text
                        this.container.received_by = data.received_by
                        this.container.received_date = data.received_date
                        this.container.confirm_date = data.confirm_date
                        this.container.broker = data.broker
                        this.containerItems = data.container_items
                        this.container.po_number = data.po_number,
                        this.receive_type = data.receive_type
                    // this.container_dialog = true
                        this.loaded = true
                        this.with_attachment = data.with_attachment
                    }).catch(error => {
                        if (error.response.status == 422) {
                            this.errors = error.response.data.errors
                        }
                        this.loaded = true
                    });
                }
            }

        },
        USER_ACCESS:{
            handler(val){
                val.forEach((e) => {
                    if (e.actions_code == "approve"){
                        this.userAccess.approve = true;
                    }
                    if (e.actions_code == "reject"){
                        this.userAccess.reject = true;
                    }
                    if (e.actions_code == "receive"){
                        this.userAccess.receive = true;
                    }
                });
            }
        },
        containerItems:{
            handler(val){
                if(val.length != 0){
                    let withUploadedErr = false;
                    val.forEach(e=>{
                        if(e.po_num != null && e.import == 1){
                            if((e.uploaded_mb_qty != e.quantity_master_box_conv)){
                                withUploadedErr = true;
                            }
                        }
                    })

                    if(withUploadedErr){
                        swal.fire(
                            "There are items whose MB Qty do not match the uploaded data!",
                            "Please see items highlighted in orange.",
                            "warning"
                        )
                    }
                }
            }
        }
    }
}
</script>
<style>
.v-avatar {
    margin: 15px 50px !important;
}

#client_modal_card {
    min-height: 160px !important;
}

.table-container {
  display: flex;
  margin-top: 20px;
  flex-grow: 1;
  overflow: hidden;
}

.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.flex-table > div {
  width: 100%;
}
</style>
