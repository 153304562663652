<template>
    <v-container fluid class="">
        <h3 class="page-title" style="color: black">Vehicles</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs" class="ma-0">
                <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12" show-arrows>
                    <v-tab ripple>List</v-tab>
                    <v-tab ripple>Types</v-tab>
                    <v-tabs-items v-model="tab" touchless>
                        <v-tab-item>
                            <VehiclesComponent ></VehiclesComponent>
                        </v-tab-item>
                        <v-tab-item>
                            <VehicleTypeComponent ></VehicleTypeComponent>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import VehiclesComponent from './VehiclesComponent.vue';
import VehicleTypeComponent from './VehicleTypeComponent.vue'
export default {
    data() {
        return {
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
            },
            tab:null
        }
    },
    components: {
        VehiclesComponent,
        VehicleTypeComponent
    },
}
</script>

<style>

</style>
