<template>
    <div class="w-100 p-3">
      <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
      </v-app>

      <v-app id="my_vue_app">
        <v-card v-show="loaded" elevation="4">
          <v-card-title>
            <v-row dense no-gutters tag="div" style="align-items: right">
              <v-col md="5"> </v-col>
              <v-col md="3">
                <v-text-field dense v-model="search" label="Search Outbound" single-line hide-details> </v-text-field>
              </v-col>
              <v-col md="3">
                <v-text-field dense v-model="searchItem" label="Search Item Name/Model" single-line hide-details>
                </v-text-field>
              </v-col>
              <v-col md="1" style="text-align: end">
                <v-icon @click="searchInput()">mdi-magnify</v-icon>
              </v-col>
            </v-row>
          </v-card-title>

          <v-container fluid>
            <v-data-table
                :headers="headers"
                :items="Outbound"
                :search="search"
                :options.sync="options"
                :server-items-length="totalOutbound"
                :items-per-page="10"
                :footer-props="{
                    showFirstLastPage: true,
                }"
                class="w-100"
            >
              <template v-slot:[`item.created_at`]="{ item }">
                <span class="text-nowrap">
                  {{ item.created_at | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.confirm_date`]="{ item }">
                <span class="text-nowrap">
                  {{ item.confirm_date | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.received_date`]="{ item }">
                <span class="text-nowrap">
                  {{ item.received_date | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.status_text`]="{ item }">
                <span class="badge bg-warning" v-if="item.status_text == 'pending'">{{ item.status_text }}</span>
                <span class="badge bg-primary" v-else-if="item.status_text == 'approved'">{{ item.status_text }}</span>
                <span class="badge bg-success" v-else-if="item.status_text == 'completed'">{{ item.status_text }}</span>
                <span class="badge bg-danger" v-else-if="item.status_text == 'rejected'">{{ item.status_text }}</span>
                <span class="badge bg-danger" v-else-if="item.status_text == 'cancelled'">{{ item.status_text }}</span>
                <span class="badge bg-dark" v-else-if="item.status_text == 'deleted'">{{ item.status_text }}</span>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-btn text icon color="orange" v-if="userAccess.view">
                  <v-icon class="btn-action" small @click="viewOutbound(item.id)">mdi-eye</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.edit && item.status == 0 && !is_receiving">
                  <v-icon class="btn-action" small @click="editOutbound(item.id)">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text icon color="blue" v-if="userAccess.receive && item.status == 0 && is_receiving && item.received_date === null">
                  <v-icon class="btn-action" small @click="editOutbound(item.id, true)">mdi-clipboard-check</v-icon>
                </v-btn>
                <v-btn text icon color="red" v-if="userAccess.delete && item.status == 0 && !is_receiving">
                  <v-icon class="btn-action" small @click="deleteOutbound(item.id)">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>
      <OutboundViewComponent
        :viewOutboundId="viewOutboundId"
        :searchText="searchItem"
        :is_receiving="is_receiving"
        @closeView="closeView"
        @changeOutboundStatus="changeOutboundStatus"
        :user_access="user_access"
      >
      </OutboundViewComponent>
    </div>
  </template>

  <script>
  import OutboundViewComponent from '../../../../layouts/dialogs/Operations/Outbound/OutboundViewComponent.vue'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex';
  export default {
    components: {
      OutboundViewComponent,
    },
    props: ['outboundActionResult', 'is_pending', 'is_receiving', 'is_approved', 'radioGroup','user_access'],
    mounted: function () {
      this.getSearchFromUrl();
      this.checkAccess()
    },
    data() {
      return {
        actionResult: {
          model: 'Outbound',
          action: '',
        },
        loaded: false,
        totalOutbound: 0,
        options: {},
        Outbound: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 10,
        search: '',
        searchItem: null,
        awaitingSearch: false,
        radioValue: '',
        headers: [
          { text: 'Doc Num', align: 'left', value: 'doc_num' },
          { text: 'Outbound Num', align: 'left', value: 'outbound_num' },
          { text: 'Warehouse', align: 'left', value: 'warehouse_name' },
          { text: 'Broker', align: 'left', value: 'broker' },
          { text: 'Date Created', align: 'left', value: 'created_at' },
          { text: 'Created By', align: 'left', value: 'created_by_name' },
          { text: 'Received By', align: 'left', value: 'received_by_name' },
          { text: 'Receive Date', align: 'left', value: 'received_date' },
          { text: 'Confirm Date', align: 'left', value: 'confirm_date' },
          { text: 'Status', align: 'left', value: 'status_text' },
          { text: 'Actions', align: 'center', value: 'action', sortable: false },
        ],

        viewOutboundId: '',

        userAccess: {
          view: false,
          edit: false,
          delete: false,
          receive: false,
        },
      };
    },
    methods: {
      getAllOutbound() {
        this.loaded = false;
        const payload = {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          search: this.search,
          searchItem: this.searchItem,
          is_pending: this.is_pending,
          is_receiving: this.is_receiving,
          is_approved: this.is_approved,
          radio_value: this.radioValue
        }
        this.$store.dispatch('getAllOutbound', payload).then(response => {
          this.Outbound = response.data.data;
          this.loaded = true;
          this.totalOutbound = response.data.total;
          this.checkAccess();
        });
      },
      getSearchFromUrl() {
        if (document.location.href.includes('search-model=')) {
          let item = document.location.href.split('search-model=');
          item = item[1].split('&')
          this.searchItem = item[0];
          this.searchInput();
        }
      },
      viewOutbound(id) {
        this.viewOutboundId = id;
      },
      editOutbound(id, is_receiving = false) {
        this.$emit('showEditModal', id, is_receiving);
      },
      deleteOutbound(id) {
        swal
          .fire({
            title: '',
            text: 'Are you sure you want to Delete?',
            icon: 'warning',
            showConfirmButton: true,
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            showCloseButton: true,
          confirmButtonColor: '397373',
            cancelButtonColor: 'grey',
            reverseButtons: true,
        })
          .then(result => {
            if (result.isConfirmed) {
              this.$store
                .dispatch('deleteOutbound', id)
                .then(response => {
                  swal.fire('', 'Outbound Deleted Successfully', 'success');
                this.getAllOutbound()
              }).catch(error => {
                  swal.fire('An Error Occured', error, 'error')
              })
            }
          });
      },
      closeView() {
        this.viewOutboundId = 0;
      },
      changeOutboundStatus() {
        this.viewOutboundId = 0;
        this.getAllOutbound();
      },
      searchInput() {
        this.getAllOutbound({ query: this.search });
      },
      addKeyListener() {
        window.addEventListener(
          'keyup',
          function (e) {
            if (e.keyCode == 13) {
            this.searchInput();
            $('input').blur()
                  }
          }).bind(this)
      },
      checkAccess(){
          // this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response=>{
              let val = this.user_access;
              this.userAccess.view = false;
              this.userAccess.edit = false;
              this.userAccess.receive = false;
              this.userAccess.delete = false;
              if(val != "fail"){
              val.forEach((e) => {
                  if (e.actions_code == "view"){
                      this.userAccess.view = true;
                  }
                  if (e.actions_code == "edit"){
                      this.userAccess.edit = true;
                  }
                  if (e.actions_code == "receive"){
                      this.userAccess.receive = true;
                  }
                  if (e.actions_code == "delete"){
                      this.userAccess.delete = true;
                  }
              });
              }
          // })
      },
    },
    computed:{
      ...mapGetters([
        'GET_ALL_OUTBOUND',
        'USER_ACCESS'
      ])
    },
    watch: {
      radioGroup: function (val) {
        this.radioValue = val;
        this.getAllOutbound();
      },
      outboundActionResult: function (val) {
        if (this.outboundActionResult == 'success') {
          this.getAllOutbound();
        }
      },
      options: {
        handler() {
          this.getAllOutbound();
        },
        deep: true,
      },
    },
  };
  </script>
