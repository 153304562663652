import { ipAddress } from 'vuelidate/lib/validators';
import api from './api'

export default {
  useraccount() {
    return api.get('credentials/user')
  },
  register(params) {
    return api.post('credentials/register', params);
  },
  login(params) {
    return api.post('credentials/login', params);
  },
  logout() {
    return api.post('credentials/logout')
  },
  authentication() {
    return api.get('credentials/authenticated')
  },
  checkAccessUser(params) {
    return api.get('credentials/checkAccessUser/' + params);
  },
  sidenav() {
    return api.get('main/sidebar')
  },
  forgotPassword(params) {
    return api.post('credentials/forgotpassword', params);
  },
  resetPassword(params){
    return api.post('credentials/resetpassword', params);
  },
  sendEmail(params){
    return api.post('credentials/send-email', params);
  },
  sendCode(params){
    return api.post('credentials/send-code', params);
  },
};
