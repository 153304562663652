
import Dispatches from "@/views/main/modules/Logistics/Dispatches/DispatchesGroupComponent"
import repository from "@/api/credentials";

const routes = [{
    path: "/logistics/dispatches/:id",
    name: "logisticdispatch",
    component: Dispatches,
    beforeEnter: (to, from, next) => {
        repository.authentication().then(() => {
            next();
        }).catch(() => {
            localStorage.removeItem('token')
            return next({ name: 'login' })
        })
    },
    meta: {
        requiresAuth: true,
        title: 'Dispatch',
    }
}]

export default routes;
