<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
          <span class="text-h5">Warehouse Personnel Information</span>
          <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
              <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="pa-2">
              <v-col cols="12" sm="8">
                <v-text-field outlined dense label="Personnel Name" v-model="form.name"  required :disabled="disable_view"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="form.wpf" label="WPF" color="primary" true-value="1" false-value="0" :disabled="disable_view"></v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="form.cf" label="CF" color="primary" true-value="1" false-value="0"  :disabled="disable_view"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
          <v-row class="ma-1">
            <v-col cols="12">
              <v-btn class="float-center" v-if="this.WAREHOUSE_PERSONNEL.ACTION == 'add'" @click="Addnewuser()" :disabled="disable_view">
                <span>Submit</span>
              </v-btn>
              <v-btn class="float-center" v-if="this.WAREHOUSE_PERSONNEL.ACTION == 'update'" @click="Updateuser()" :disabled="disable_view">
                <span>Update</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  import swal from 'sweetalert2'
  export default {
    data() {
      return {
        valid: false,
        dialog: false,
        disable_view:false,
        access: {
          department_code: 'MD',
          module: 'users',
          action: 'create'
        },
        form: {
          name: null,
          wpf: 0,
          cf: 0,
          id: null
        },
      };
    },
     computed: {
      ...mapGetters([
        'USER_ACCESS',
        'GET_ALL_WAREHOUSE_PERSONNEL',
        'WAREHOUSE_PERSONNEL',
        'WAREHOUSE_PERSONNEL_GENERIC'
      ]),
      action(){
        if(this.WAREHOUSE_PERSONNEL.ACTION == 'update'){
            this.form.name = this.GET_ALL_WAREHOUSE_PERSONNEL[0].name
            this.form.id = this.GET_ALL_WAREHOUSE_PERSONNEL[0].id
          }
      }
    },
    methods: {
      Addnewuser() {
        let payload = {
          name: this.form.name,
          cf: this.form.cf,
          wpf: this.form.wpf
        }
        this.$store.dispatch('addWarehousePersonnel', payload).then(response=>{
            swal.fire('',response.data.message,response.data.icons)
            this.closeDialog()
            this.$store.commit('RELOAD_WAREHOUSE_PERSONNEL', true)
        }).catch(err=>{
            swal.fire('Error','Please insert Warehouse Personnel Name','error')
        });
      },
      Updateuser(){
        let payload = {
          id: this.form.id,
          name: this.form.name,
          cf: this.form.cf,
          wpf: this.form.wpf
        }
        this.$store.dispatch('updateWarehousepersonnel', payload).then(response => {
          swal.fire('',response.data.msg,response.data.status)
          this.$store.commit('RELOAD_WAREHOUSE_PERSONNEL', true)
          this.closeDialog()
        })
      },
      closeDialog() {
          this.$store.commit('WAREHOUSE_PERSONNEL_DIALOG', {
          TRIGGER: false,
          ACTION: '',
          BUTTON: ''
        });
        this.reset();
      },
      reset(){
        this.form.name = ''
        this.form.cf = 0 
        this.form.wpf = 0
      },
      getSelected(){
        if(!!this.WAREHOUSE_PERSONNEL_GENERIC.SELECTED){
          this.form = this.WAREHOUSE_PERSONNEL_GENERIC.SELECTED
        }else{
          this.reset()
        }
      }
    },
    mounted() {
      this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
      this.getSelected()
    },
    watch:{
      'WAREHOUSE_PERSONNEL_GENERIC.SELECTED':{
        handler(val){
            this.form.name = val.name
            this.form.id = val.id
        },
        deep: true
      }
    },
  };
  </script>
  