<template>
    <sales-quotation-component-group :service="service"></sales-quotation-component-group>
</template>
<script>
import SalesQuotationComponentGroup from '@/views/main/modules/Sales/SalesQuotations/SalesQuotationGroupComponent.vue'
export default {
    data() {
        return {
            tab: null,
            service:this.$router.currentRoute.meta.service
        }
    },
    components: {
        SalesQuotationComponentGroup,
    },
}
</script>
