<template>
    <div class="tabs-body-color">
          <v-card class="m-10 px-3">
            <v-card-text>
                <v-row v-if="!fromView">

                    <v-col lg="5">

                        <v-autocomplete
                            v-model="users.selected"
                            :items="GET_ALL_USER"
                            outlined
                            dense
                            small-chips
                            deletable-chips
                            label="Select User"
                            multiple
                        >
                        </v-autocomplete>

                    </v-col>
                    <v-col lg="5">
                        <v-autocomplete
                            v-model="filters.department.selected"
                            :items="GET_LIST_DEPARTMENT"
                            outlined
                            dense
                            item-value="value"
                            item-text="text"
                            small-chips
                            deletable-chips
                            label="Select Department"
                            multiple
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col lg="1">
                        <v-btn @click="save"> Save </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else>
                        <v-row class="m-0">
                            <v-card-title>
                                <span class="headline">{{ WAREHOUSE_USER.SELECTED.name }}</span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right"
                                    @click="closeDialog();">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                </v-row>
                <v-row>
                    <v-col lg="1">
                        <v-checkbox
                            v-model="warehouses.noneService"
                            true-value="1"
                            false-value="0"
                            label="None Service"
                        ></v-checkbox>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col lg="1">
                        <v-checkbox
                            v-model="warehouses.service"
                            true-value="1"
                            false-value="0"
                            label="Service"
                        ></v-checkbox>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col lg="1">
                        <v-checkbox
                            v-model="warehouses.all"
                            true-value="1"
                            false-value="0"
                            label="All"
                        ></v-checkbox>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row>
                    <v-col
                        :lg="fromView ? '3' : '1'"
                        v-for="(warehouse, index) in GET_WAREHOUSE_LIST"
                        :key="index"
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-checkbox
                                        v-model="warehouse.selected"
                                        true-value="1"
                                        false-value="0"
                                        :label="warehouse.code"
                                    ></v-checkbox>
                                </span>
                            </template>
                            <span>{{ warehouse.name }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col lg="12" class="d-flex justify-content-center">

                        <v-btn @click="save" v-if="fromView"> Save </v-btn>
                    </v-col>
                </v-row>
              </v-card-text>
          </v-card>
    </div>
  </template>
  <script>
  import { mapGetters } from "vuex";
  import swal from "sweetalert2";
  export default {
        props:{
            fromView:{
                default:()=>false,
                type:Boolean
            }
        },
    data() {
      return {
        warehouses: {
            selection: null,
            selected: null,
            service: "0",
            noneService: "0",
            all: "0",
            loaded:false,
            dialog:false
        },
        users: {
            selection: [],
            selected: [],
            limit: 10,
            search: "",
            all:'0'
        },
        filters:{
            users:{
                selection:[],
                selected:[]
            },
            department:{
                selected:[]
            }
        },
        loaded: true,
        settings: [],
        add: true,
        dataTable:{
          headers:[
              { text:'Name', value:'name'},
              { text:'Department', value:'department'},
              { text:'Job Title',value:'job_title'},
              { text:'Warehouses', value:'warehouses'}
          ],
        }
      };
    },
    async created() {

      await this.$store.dispatch('getAllSelectUsers').then(r=>this.filters.users.selection = r)
      this.$store.dispatch('getDepartment')
    },

    methods: {
      resetWarehouse() {
        let warehouses = [];
        warehouses = this.GET_WAREHOUSE_LIST.map((e) => {
          e.selected = "0";
          return e;
        });
        this.$store.commit('WAREHOUSE_LIST',warehouses)
        this.warehouses = {
          selection: warehouses,
          selected: null,
          service: "0",
          noneService: "0",
          all: "0",
        };
      },

      async save() {

        if(!await this.alert('Warning !','Are you sure you want to save changes?')){
            return false
        }

        let selectedUser = this.WAREHOUSE_USER.DIALOG ? [ this.WAREHOUSE_USER.SELECTED.id ] : this.users.selected
        let selectedWarehouse = this.GET_WAREHOUSE_LIST.filter(
          (e) => e.selected == 1
        );

        if(selectedUser.length == 0 && this.filters.department.selected.length == 0){
            swal.fire('Error!',' - Please select warehouse and user','error')
            return false
        }
        let response = "";
        let type = "";
        await this.$store
          .dispatch("warehouseUser", {
            users: selectedUser,
            warehouse: selectedWarehouse.map((e) => e.id),
            url: "warehouse-user/store",
            method: "post",
            departments:this.filters.department.selected
          })
          .then(async (response) => {
            this.WAREHOUSE_USER.DIALOG ? '' : this.resetWarehouse()
            response = response;
            type = "success";
            this.$store.commit('WAREHOUSE')
            this.$store.dispatch('getAllSelectUsers').then(r=>this.filters.users.selection = r)
            this.$store.commit('WAREHOUSE_USER_REFRESH',true)
            this.$store.commit('WAREHOUSE_USER_DIALOG',false)
          })
          .catch((err) => {
            console.log(err);
            response = err.response.data.message;
            type = err.response.data.type;
          });
        swal.fire(type.toUpperCase(), response, type);
      },

      closeDialog(){
        this.$store.commit('WAREHOUSE_USER_DIALOG',false)
      },

      async alert(title = '',text = '',icon = 'warning'){
        let response = false
        await swal.fire({
            text:text,
            title:title,
            icon:icon,
            showCancelButton:true,
            reverseButtons:true
        }).then((confirm)=>{
            if(confirm.isConfirmed){
                return response = true
            }
            return false
        })
        return response
      }

    },
    computed: {
      ...mapGetters([
        "GET_WAREHOUSE_LIST",
        "GET_WAREHOUSE_LOADED",
        "USERS_LIST_LOAD",
        "GET_LIST_DEPARTMENT",
        "WAREHOUSE_USER",
        "GET_ALL_USER"]),

      sortedWarehouseCode() {
        return this.GET_WAREHOUSE_LIST.sort((a, b) =>
          a.code.localeCompare(b.code)
        );
      },
    },
    watch: {
        'GET_WAREHOUSE_LIST': {
            handler(val) {
                let warehouses = val
                warehouses.forEach(element => {
                    element.selected = '0'
                });
                this.$store.commit('WAREHOUSE_LIST',warehouses)
            },
            deep:true
        },
      "warehouses.service": {
        handler(val) {
          if (val == 0) {
            this.resetWarehouse();
          } else {
            this.warehouses.selection = this.GET_WAREHOUSE_LIST.map((e) => {
              if (e.service == 1) {
                  e.selected = "1";
              }
              return e;
            });
            this.warehouses.selection = this.warehouses.selection.sort((a, b) => {
              return b.selected - a.selected;
            });
          }
        },
      },
      "warehouses.noneService": {
        handler(val) {
          if (val == 0) {
            this.resetWarehouse();
          } else {
            this.warehouses.selection = this.GET_WAREHOUSE_LIST.map((e) => {
              if (e.service != 1) {
                  e.selected = "1";
              }
              return e;
            });
            this.warehouses.selection = this.warehouses.selection.sort((a, b) => {
              return b.selected - a.selected;
            });
          }
        },
      },
      "warehouses.all": {
        handler(val) {
          if (val == 0) {
              this.resetWarehouse();
          } else {
            this.warehouses.selection = this.GET_WAREHOUSE_LIST.map((e) => {
                e.selected = "1";
                return e;
            });
            this.warehouses.selection = this.warehouses.selection.sort((a, b) => {
                return a.id - b.id;
            });
          }
        },
      },
      'WAREHOUSE_USER.TAB':{
        handler(val){
            this.resetWarehouse();
        }
      }
    },
  };
  </script>

