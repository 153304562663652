<template>
    <v-app id="view_client_modal">
        <v-dialog v-model="sales_order_items_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Sales Order Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right"
                            @click="clearSalesOrderItems()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="text-left text-dark">
                            <v-row class="ml-2">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                    Sales Order No: {{ sales_order.order_num }}
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0">
                                    Issuer: {{ sales_order.issuer_name }}
                                </v-col>
                            </v-row>
                            <v-row class="ml-2">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                    Customer: {{ sales_order.customer_name }}
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0">
                                    Date Issued: {{ sales_order.created_at | formatDateTime }}
                                </v-col>
                            </v-row>
                            <v-row class="ml-2" v-if="sales_order.approved_by != null">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                    Approved (Sales) By: {{ sales_order.approved_by_name }}
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0">
                                    Approved (Sales) Date: {{ sales_order.approve_date }}
                                </v-col>
                            </v-row>
                            <v-row class="ml-2" v-if="sales_order.acc_approved_by != null">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                    Approved (Acct) By: {{ sales_order.acc_approved_by_name }}
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0">
                                    Approved (Acct) Date: {{ sales_order.acc_approved_date }}
                                </v-col>
                            </v-row>
                            <v-row class="ml-2">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                    Remarks: {{ sales_order.remarks }}
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0" v-if="sales_order.convert_date != null">
                                    Convert Date: {{ sales_order.convert_date | formatDateTime }}
                                </v-col>
                            </v-row>
                            <v-row class="ml-2">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                    PO#: {{ sales_order.customer_po_num }}
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="p-0" v-if="sales_order.stock_card == 1">
                                    Stock Card
                                </v-col>
                            </v-row>
                            <v-row class="ml-2">
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="p-0">
                                    Notes: {{ sales_order.notes }}
                                </v-col>
                            </v-row>
                            <v-row class="ml-2" v-if="sales_order.promo_name != ''">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="p-0">
                                    Promo: {{ sales_order.promo_name }}
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="ml-2 mt-4" v-if="discounts.length > 0 && (sales_order.promo_id == '' || sales_order.promo_id == null) && (sales_order.special != 1 || (sales_order.special == 1 && item_type != 'PT'))">
                                <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                                    <v-row class="p-0">
                                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="py-0 mb-3" v-for="(disc, index) in itemTypeDiscounts" :key="index">
                                            <span>{{ item_type + ': ' + disc }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-data-table
                        v-model="selectedItems"
                        :headers="ACTION.includes('cancel') ? selectedHeaders : selectedHeaders.filter(header => header.value !== 'qty_to_cancel')"
                        :items="salesOrderItems"
                        :items-per-page="500"
                        :expanded.sync="expanded_details.expanded"
                        :show-select="showSelect ? true : false"
                        :show-expand="false"
                        :item-class="checkRowBg"
                        item-key="id"
                        class="w-100"
                        hide-default-footer
                        disable-sort
                    >
                         <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                            <v-simple-checkbox v-if="item.unserved_qty > 0"
                                :value="isSelected"
                                @input="select($event)"
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:[`item.foc`]="{ item }">
                            <v-tooltip :disabled="!item.foc" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="side-borders-white px-0" v-bind="attrs" v-on="on">
                                        <span v-if="item.foc == '1'">Yes</span>
                                        <span v-else-if="item.foc == '0'">No</span>
                                    </td>
                                </template>
                                <span>{{ item.foc_remarks }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.display_disc_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_disc_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_srp`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1; {{ item.display_srp | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_final_price`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_final_price | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.display_total_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.display_total_amount | currency }}
                            </span>
                        </template>
                        <template v-slot:[`item.qty_to_cancel`]="{ item }">
                            <span class="text-nowrap" v-for="(so_item, i) in selectedItems" :key="i">
                                <v-text-field
                                v-if="so_item.id === item.id && so_item.unserved_qty > 0"
                                v-model="item.qty_to_cancel"
                                dense
                                outlined
                                class="center-input"
                                @keypress="countingNumberOnly"
                                >
                                </v-text-field>
                            </span>
                        </template>

                        <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                            <td v-if="item.original_item_id != 0" class="text-start">
                                <v-btn
                                    :class="{'v-data-table__expand-icon--active': isExpanded}"
                                    class="v-data-table__expand-icon"
                                    color="black"
                                    text
                                    icon
                                    x-small
                                    @click="expand(!isExpanded)"
                                >
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </td>
                        </template>
                    </v-data-table>

                    <v-row class="m-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pull-right text-right text-dark">
                            Total Amount: &#x20b1;{{ sales_order.total_amount | currency }}
                        </v-col>
                        <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="text-left">
                            <div class="card-body py-4 d-flex" v-if="ACTION.includes('view')">
                                <!-- <span class="m-1"><v-btn v-if="sales_order.status==3" @click="convertToWpList()" :disabled="(sales_order.status != 3 && sales_order.status != 10) || !userAccess.convert_to_wpl" >Convert to WPF</v-btn></span> -->
                                <!-- <span class="m-1">
                                    <v-btn v-if="!service" @click="checkCreditLimit()"
                                        :disabled="(sales_order.status != 3 && sales_order.status != 10) || !userAccess.convert_to_wpl">
                                        Convert to WPF </v-btn>
                                </span> -->
                                <!-- <span class="m-1" v-show="sales_order.stock_card == 1"><v-btn @click="convertToPackingList()" :disabled="sales_order.status != 3 || sales_order.status == 12 || !userAccess.convert_to_packing_list" >Convert to DR</v-btn></span> -->
                                <span class="m-1">
                                    <v-btn @click="print_no_price = false; renderPDF('print');" v-if="enableButton">Print</v-btn>
                                </span>
                                <span class="m-1">
                                    <v-btn @click="print_no_price = true; renderPDF('print'); " v-if="enableButton">Print No Price</v-btn>
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="pull-right text-right text-dark" v-if="sales_order.status != 4">
                            Served Amount: &#x20b1;{{ sales_order.total_amount_served | currency }}
                            <br>
                            Unserved Amount Total: &#x20b1;{{ sales_order.total_amount_with_without_stocks | currency }}
                            <br><br>
                            Unserved Amount with Stocks: &#x20b1;{{ sales_order.total_amount_with_stocks | currency }}
                            <br>
                            Unserved Amount without Stocks: &#x20b1;{{ sales_order.total_amount_without_stocks | currency }}
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="col-sm-12 col-md-4 pull-right text-right text-dark" v-else>
                            <b>FULLY SERVED!</b>
                        </v-col>
                    </v-row>

                    <div class="border-top">
                        <div class="card-body py-2 d-flex justify-content-center" v-if="ACTION.includes('cancel')">
                            <span class="m-1">
                                <v-btn v-if="[0, 3, 10, 16, 17].includes(sales_order.status)" @click="initiateOverride('cancelSOItem', viewSalesOrderId, true)">
                                    {{'Cancel Selected Items'}}
                                </v-btn>
                                <v-btn @click="initiateOverride('cancelSO',viewSalesOrderId, true)">
                                    {{'Cancel SO'}}
                                </v-btn>
                            </span>
                        </div>
                        <span v-if="ACTION.includes(['view'])">
                            <div class="card-body py-4 d-flex justify-content-between" v-if="sales_order.status == 0">
                                <span class="m-1"></span>
                                <span class="m-1 d-flex align-items-center">
                                    <v-btn @click="updateSalesOrderStatus(1)" v-if="enableButton">Submit</v-btn>
                                </span>
                                <span class="m-1 d-flex align-items-center">
                                    <v-switch v-model="sales_order.rush" :disabled="sales_order.status != 0" label="Rush" color="primary" input-value="1" class="m-0" dense hide-details></v-switch>
                                </span>
                            </div>
                            <div class="card-body py-4 d-flex justify-content-between" v-if="![0].includes(sales_order.status)">
                                <span class="m-1"></span>
                                <span class="m-1 d-flex align-items-center"
                                    v-if="(userAccess.approval_exec || userAccess.approval_exec2) && sales_order.status == 11">
                                    <v-btn @click="updateSalesOrderStatus(9)" :disabled="sales_order.status != 11">
                                        Approve (Exec)</v-btn>
                                </span>
                                <span class="m-1 d-flex align-items-center"
                                    v-if="userAccess.approval_sales && (sales_order.status == 1 || sales_order.status == 9)">
                                    <v-btn @click="updateSalesOrderStatus(2)"
                                        :disabled="!userAccess.approval_sales || (sales_order.status != 1 && sales_order.status != 9)">
                                        Approve (Sales)</v-btn>
                                </span>
                                <span class="m-1 d-flex align-items-center"
                                    v-else-if="userAccess.approval_stores && (sales_order.status == 1 || sales_order.status == 9)">
                                    <v-btn @click="updateSalesOrderStatus(2)"
                                        :disabled="!userAccess.approval_stores || (sales_order.status != 1 && sales_order.status != 9 || sales_order.store_customer == 1 && store_manager != 1)">
                                        Approve (Stores)</v-btn>
                                </span>
                                <span v-if="userAccess.approval_acct && sales_order.status == 2">
                                    <!-- <span class="m-1">
                                        <v-btn @click="viewCreditDialog()"
                                            :disabled="!userAccess.approval_acct || (sales_order.status != 2)">View Credit Limit</v-btn>
                                    </span>
                                    <span class="m-1">
                                        <v-btn @click="viewCreditTermDialog()"
                                            :disabled="!userAccess.approval_acct || (sales_order.status != 2)">View Credit Terms</v-btn>
                                    </span> -->
                                    <span class="m-1">
                                        <v-btn @click="updateSalesOrderStatus(3)"
                                            :disabled="!userAccess.approval_acct || (sales_order.status != 2)">Approve (Accounting)</v-btn>
                                    </span>
                                    <!-- <span class="m-1 d-flex align-items-center" v-if="sales_order.status != 6 && sales_order.status != 4 && sales_order.status != 8"><v-btn @click="override.approve_override_cancel_dialog=true" color='error'>Cancel</v-btn></span> -->
                                    <!-- <span class="m-1 d-flex align-items-center" v-if="userAccess.approve_credit_limit && sales_order.status == 14">
                                        <v-btn @click="checkCreditLimit(true)">Approve Credit Limit</v-btn>
                                    </span> -->
                                    <!-- <span class="m-1 d-flex align-items-center" v-if="userAccess.approve_credit_term && sales_order.status == 15">
                                        <v-btn @click="convertToWpList()">Approve Credit Terms</v-btn>
                                    </span> -->
                                </span>
                                <!-- <span class="m-1 d-flex align-items-center" v-if="[3, 10, 17].includes(sales_order.status)">
                                    <v-btn @click="updateSalesOrderStatus(16)">Submit For Schedule</v-btn>
                                </span> -->
                                <span class="m-1 d-flex align-items-center" v-if="[3].includes(sales_order.status) && userAccess.convert_to_packing_list">
                                    <v-btn @click="convertToPackingList()">Convert To DR</v-btn>
                                </span>
                                <span class="ml-1 d-flex align-items-center">
                                    <v-switch v-model="sales_order.rush" :disabled="sales_order.status != 0" label="Rush" color="primary" input-value="1" class="m-0" dense hide-details></v-switch>
                                </span>
                            </div>
                        </span>
                    </div>
                </v-card-text>

                <v-container fluid style="display:none;">
                    <div id="div_logo">
                        <div style="font-size: 12px; line-height: 1.9">
                            <p v-if="service == 0">
                                <span style="font-size: 18px; font-weight: bold;">
                                    D GREAT EAST INDUSTRIAL SUPPLY CORPORATION
                                </span>
                                <br>420 Del Monte Ave, Brgy. Siena, Quezon City
                                <br>Cell No: +63 999 889 5739
                                <br>Tel. No.: +63 2 8711-6742 / +63 2 7717-4059
                                <br>
                            </p>
                            <p v-else>
                                <span style="font-size: 18px; font-weight: bold;">
                                    D GREAT EAST INDUSTRIAL SUPPLY CORPORATION
                                </span>
                                <br>420 Del Monte Ave, Brgy. Siena, Quezon City
                                <br>Cell No: +63 999 889 5739
                                <br>Tel. No.: +63 2 8711-6742 / +63 2 7717-4059
                            </p>
                        </div>
                    </div>
                    <div id="div_sales_order_num" style="font-size: 12px; line-height: 1.9">
                        <div
                            style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">
                            &nbsp;</div>
                        <p><span style="font-size: 18px; font-weight: bold;">Sales Order</span><br><b>SO # {{
                                sales_order.order_num
                        }}</b></p>
                    </div>

                    <div>
                        <table id="customer_details">
                            <tbody>
                                <tr>
                                    <td>Customer:</td>
                                    <td>{{ sales_order.customer_name | uppercase }} </td>
                                    <td>Date:</td>
                                    <td>{{ sales_order.created_at | formatDate }} </td>
                                </tr>
                                <tr>
                                    <td>Address:</td>
                                    <td>{{ sales_order.shipping_address_text | uppercase }}</td>
                                    <td>Terms:</td>
                                    <td>{{ sales_order.terms == 0 ? "CASH" : sales_order.terms + " days" }}</td>
                                </tr>
                                <tr>
                                    <td>Remarks:</td>
                                    <td>{{ sales_order.remarks }}</td>
                                    <td>PO#:</td>
                                    <td>{{ sales_order.customer_po_num }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-for="(items,i) in print.salesOrderItems" :key="i">
                        <table :id="'items_details' + i">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>QTY</th>
                                    <th>UNITS</th>
                                    <th>MODEL</th>
                                    <th>DESCRIPTION</th>
                                    <th>UNIT PRICE</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index ) in items" :key="index">
                                    <td>{{ item.index + 1 }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.item.item_uom_text }}</td>
                                    <td>{{ item.item.model }}</td>
                                    <td>{{ item.item.name }}</td>
                                    <td v-if="item.total_amount > 0">{{ item.display_srp | currency }}</td>
                                    <td v-else>FOC</td>
                                    <td v-if="item.total_amount > 0">{{ computePrintOutItemTotal(item.srp,
                                            item.quantity) | currency
                                    }}</td>
                                    <td v-else>FOC</td>
                                </tr>
                                <tr v-if="print.salesOrderItems.length == (i + 1)">
                                    <td colspan="7">***** NOTHING FOLLOWS *****</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <div v-for="(items, i) in print.salesOrderItems" :key="i">
                            <table :id="'items_details_without_price' + i">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>QTY</th>
                                        <th>UNITS</th>
                                        <th>MODEL</th>
                                        <th>DESCRIPTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in items" :key="index">
                                        <td>{{ item.index + 1 }}</td>
                                        <td>{{ item.quantity }}</td>
                                        <td>{{ item.item.item_uom_text }}</td>
                                        <td>{{ item.item.model }}</td>
                                        <td>{{ item.item.name }}</td>
                                    </tr>
                                    <tr v-if="print.salesOrderItems.length == (i + 1)">
                                        <td colspan="5">***** NOTHING FOLLOWS *****</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div v-for="(items, i) in print.salesOrderItems" :key="i">
                            <table :id="'total_details' + i">
                                <tbody class="flex text-right">
                                    <tr>
                                        <td> GROSS SALES: </td>
                                        <td> {{ sales_order.gross_amount | currency }} </td>
                                    </tr>
                                    <tr>
                                        <td> Less - Discount: </td>
                                        <td> {{ sales_order.discount_amount | currency }} </td>
                                    </tr>
                                    <tr>
                                        <td> TOTAL SALES: </td>
                                        <td> {{ sales_order.display_total_amount | currency }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <table id="num_of_items">
                            <tbody>
                                <tr>
                                    <td><span>No. of Items: {{ this.no_of_items }}</span></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table id="footer">
                            <tbody>
                                <tr>
                                    <td>Issued By</td>
                                    <td>Checked By</td>
                                </tr>
                                <tr>
                                    <td><br><br>{{ sales_order.issuer_name }}</td>
                                    <td><br><br></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </v-container>

                <v-dialog v-model="view_credit_dialog" persistent max-width="450" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-card-title>
                                <span class="headline">View Credit Limit</span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="closeViewCredit()" v-if="enableButton">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-0" />
                        <v-card-text>
                            <div>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th style="font-size: large;" class="text-left">
                                                    <span class="badge badge-saved" v-if="credit_limit.status == 'under'">Status: {{ credit_limit.status }} limit</span>
                                                    <span class="badge badge-danger" v-if="credit_limit.status == 'over'">Status: {{ credit_limit.status }} limit</span>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Customer Credit Limit:</td>
                                                <td>₱{{ credit_limit.customer_base | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Collectibles:</td>
                                                <td>₱{{ credit_limit.total_collectibles | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Used:</td>
                                                <td>₱{{ credit_limit.total_used | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td>Total SO for Approval:</td>
                                                <td>₱{{ credit_limit.total_so_for_approval | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td>Total SO on Process:</td>
                                                <td>₱{{ credit_limit.total_so_on_process | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td>Current SO Amount:</td>
                                                <td>₱{{ credit_limit.current_so_amount | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td>Remaining Credit Limit:</td>
                                                <td>₱{{ credit_limit.remaining | currency }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-divider />
                                <div style="text-align:right" class="pb-4">
                                    <v-btn @click="proceedApproved()" v-if="enableButton">PROCEED</v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="view_credit_term_dialog" persistent max-width="800" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-card-title>
                                <span class="headline">View Credit Terms</span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="view_credit_term_dialog = false">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <b>Compute Overdue Ammount: {{ soa.datatable.total_amount | currency }}</b>
                            <v-data-table
                                :items="soa.datatable.items"
                                :headers="soa.datatable.headers"
                                :loading="!soa.datatable.loaded"
                                dense
                            >
                                <template  v-slot:[`item.created_at`]="{ item }">
                                    <span>{{ agingDate(item.created_at) }}</span>
                                </template>
                                <!-- <template  v-slot:[`item.soa_date`]="{ item }">
                                    <span>{{items }}</span>
                                </template> -->
                            </v-data-table>
                        </v-card-text>
                            <div style="text-align:right" class="p-4">
                                <v-btn @click="proceedApproved()" v-if="enableButton">PROCEED</v-btn>
                            </div>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="confirm_overide_remarks" persistent width="34%" scrollable>
                    <v-card>
                        <v-row>
                            <v-card-title>
                                <span class="headline m-2" style="text-align:left">Cancel Remarks</span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-3">
                                <v-btn text icon color="gray" class="float-right" @click="()=>{confirm_overide_remarks = !confirm_overide_remarks}">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text class="m-2">
                            <v-textarea
                                v-model="reason.text"
                                dense
                                outlined
                                placeholder="Reason for Cancel"
                                height="60"
                            >
                            </v-textarea>
                            <div class="mt-2" style="text-align:right">
                                <v-btn @click="confirmReason()">Submit</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>


                <confirm-override-component :approve_override_dialog="override.dialog"
                    :departments_allowed="override.departments_allowed" :heading="override.heading"
                    @closeConfirmOverride="closeConfirmOverride"></confirm-override-component>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ConfirmOverrideComponent from '@/views/main/Utils/ConfirmOverrideComponent.vue';
import ServiceFunctionsComponent from '@/views/main/Shared/ServiceFunctionsComponent.vue';
import jsPDF from 'jspdf'
import swal from 'sweetalert2'
import moment from 'moment'
import { mapGetters } from "vuex";

export default {
    mixins: [
        SharedFunctionsComponent,
        ServiceFunctionsComponent,
    ],
    components: {
        ConfirmOverrideComponent,
    },
    props:{
        viewSalesOrderId: {
            default: () => 0,
            type: Number,
        },
        service: {
            default: () => 0,
            type: Number,
        },
        enableButton: {
            default: () => true,
            type: Boolean,
        },
    },
    mounted() {
        this.checkAccess()
        this.checkStoreManager()
        this.$store.dispatch('getItemTypes')
    },
    data() {
        return {
            override: {
                dialog: false,
                heading: "Sales Order",
                departments_allowed: ["SM", "AC", "EX", "IT"],
                authenticated: false,
                action: "",
            },
            reason: {
                text: "",
                confirm: false
            },
            confirm_overide_remarks: false,
            currentSalesOrderId: 0,
            salesOrderItems: [],
            selectedHeaders: [],
            salesOrderItemsHeaders: [
                { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false },
                { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false },
                { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false },
                { text: 'FOC', align: 'left', value: 'foc', sortable: false },
                { text: 'SRP', align: 'left', value: 'display_srp', sortable: false },
                { text: 'Disc Price', align: 'left', value: 'display_disc_price', sortable: false },
                { text: 'Qty', align: 'left', value: 'quantity', sortable: false },
                { text: 'Served Qty', align: 'left', value: 'quantity_served', sortable: false },
                { text: 'Unserved Qty', align: 'left', value: 'unserved_qty', sortable: false },
                { text: 'Canceled Qty', align: 'left', value: 'quantity_canceled', sortable: false },
                { text: 'Prepared Qty', align: 'left', value: 'prepared_quantity', sortable: false },
                { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false },
                { text: 'Override/Final Price', align: 'right', value: 'display_final_price', width: '15%', sortable: false },
                { text: 'Total', align: 'right', value: 'display_total_amount', width: '15%', sortable: false },
                { text: 'Qty to Cancel', align: 'center', value: 'qty_to_cancel', width: '15%', sortable: false },
            ],

            stockCardsalesOrderItemsHeaders: [
                { text: 'Model', align: 'left', value: 'item.model', width: '15%', sortable: false },
                { text: 'Name', align: 'left', value: 'item.name', width: '35%', sortable: false },
                { text: 'Type', align: 'left', value: 'item.item_type_text', sortable: false },
                { text: 'FOC', align: 'left', value: 'foc', sortable: false },
                { text: 'SRP', align: 'left', value: 'display_srp', sortable: false },
                { text: 'Disc Price', align: 'left', value: 'display_disc_price', sortable: false },
                { text: 'Qty', align: 'left', value: 'quantity', sortable: false },
                { text: 'Served Qty', align: 'left', value: 'quantity_served', sortable: false },
                { text: 'Unserved Qty', align: 'left', value: 'unserved_qty', sortable: false },
                { text: 'Canceled Qty', align: 'left', value: 'quantity_canceled', sortable: false },
                { text: 'Prepared Qty', align: 'left', value: 'prepared_quantity', sortable: false },
                { text: 'SC Qty', align: 'left', value: 'sc_quantity', sortable: false },
                { text: 'UOM', align: 'left', value: 'item.item_uom_text', sortable: false },
                { text: 'Price', align: 'right', value: 'display_final_price', width: '15%', sortable: false },
                { text: 'Total', align: 'right', value: 'display_total_amount', width: '15%', sortable: false },
            ],

            loaded: true,
            sales_order_items_dialog: false,
            sales_order: '',
            sales_order_customer: '',
            discounts: [],
            itemTypeDiscounts: [],
            errors: [],
            print_no_price: false,
            showSelect: false,
            selectedItems: [],
            userAccess: {
                approval_sales: false,
                approval_acct: false,
                approval_exec: false,
                convert_to_wpl: false,
                approval_exec2: false,
                cancel: false,
                order_delivery_schedule: false,
                convert_to_packing_list: false,
            },
            store_manager: false,
            item_type: '',
            status_action_text: '',

            view_credit_dialog: false,
            view_credit_term_dialog: false,
            credit_limit: {
                status: null,
                customer_base: 0,
                total_collectibles: 0,
                total_used: 0,
                total_so_for_approval: 0,
                total_so_on_process: 0,
                current_so_amount: 0,
                remaining: 0,
            },

            soa:{
                datatable:{
                    items:[],
                    headers:[
                        { text:'SOA ID',value:'id' },
                        { text:'NET',value:'total_amount_formatted' },
                        { text:'BALANCE',value:'balance_amount' },
                        { text:'SOA DATE',value:'soa_date' },
                        { text:'SOA DUE DATE',value:'soa_due_date' },
                        { text:'AGE(DAY)',value:'created_at' },
                    ],
                    loaded:false,
                    total_amount:0
                }
            },
            expanded_details:{
                loading:false,
                expanded: [],
            },
            print:{
                salesOrderItems: []
            },
        }
    },
    methods: {
        checkRowBg(item) {
            return item.override_amount > 0 ? 'overrideBg' : ''
        },
        proceedApproved() {
            if (this.credit_limit.status == 'over') {
                let over_amount = (parseFloat(this.credit_limit.total_used) + parseFloat(this.credit_limit.current_so_amount)) - parseFloat(this.credit_limit.remaining)
                swal.fire({
                    title: `This order will exceed remaining credit limit by ${this.getTwoDecimals(over_amount)}. Do you want to Proceed?`,
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
                }).then(answer => {
                    if (answer.isConfirmed) {
                        this.updateSalesOrderStatus(3)
                        this.view_credit_dialog = false
                    }
                })
            } else {
                this.updateSalesOrderStatus(3)
            }
        },
        viewCreditDialog() {
            let payload = {
                sales_order_id: this.viewSalesOrderId,
                url: 'checkCreditLimit'
            }
            this.$store.dispatch('salesOrdersGet', payload).then(response => {
                if (response.data.status == 'vip') {
                    swal.fire("This Customer is VIP", "", "warning");
                    return false
                } else {
                    Object.assign(this.credit_limit, {
                        status: response.data.status,
                        customer_base: response.data.customer_base,
                        total_collectibles: response.data.total_collectibles,
                        total_used: response.data.total_used,
                        total_so_for_approval: response.data.total_so_for_approval,
                        total_so_on_process: response.data.total_so_on_process,
                        current_so_amount: response.data.current_so_amount,
                        remaining: response.data.remaining,
                    })

                    this.sales_order_items_dialog = false
                    this.view_credit_dialog = true
                }
            })
        },
        viewCreditTermDialog() {
            this.view_credit_term_dialog = true
            this.soa.datatable.loaded = false
            this.soa.datatable.items = []
            let total_paid_amount = 0
            let total_conputed_amount = 0
            let payload = {
                customer_id: this.sales_order.customer_id,
                total_amount: this.sales_order.total_amount,
                url: 'viewCreditTerm/' + this.sales_order.customer_id
            }
            this.$store.dispatch('salesOrdersGet', payload).then(response => {
                if (response.data.status == 'vip') {
                    swal.fire("This Customer is VIP", "", "warning");
                    return false
                } else {
                    // this.sales_order_items_dialog = false
                    this.soa.datatable.items = response.data.map(e=>{
                        e.soa_date =  moment(e.soa_date).format('YYYY-MM')
                        total_paid_amount += parseFloat(e.paid_amount)
                        total_conputed_amount += parseFloat(e.total_amount)
                        return e
                    })
                    this.soa.datatable.loaded = true
                    this.soa.datatable.total_amount = total_conputed_amount - total_paid_amount
                }

            }).catch(e=>{
                console.log(e)
            })

        },
        closeViewCredit() {
            this.view_credit_dialog = false
            this.sales_order_items_dialog = true
        },
        checkAccess() {
            this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id).then(response => {
                let val = response
                val.forEach(e => {
                    if (e.actions_code == 'approval_acct') {
                        this.userAccess.approval_acct = true;
                    }
                    if (e.actions_code == 'approval_sales') {
                        this.userAccess.approval_sales = true;
                    }
                    if (e.actions_code == 'approval_exec') {
                        this.userAccess.approval_exec = true;
                    }
                    if (e.actions_code == 'convert_to_wpl') {
                        this.userAccess.convert_to_wpl = true
                    }
                    if (e.actions_code == 'convert_to_packing_list') {
                        this.userAccess.convert_to_packing_list = true
                    }
                });
            });
        },
        checkStoreManager() {
            this.loaded = false
            let payload = {
                url: 'checkStoreManager'
            }
            this.$store.dispatch('checkIfManager', payload).then(response => {
                this.store_manager = response.data;
                this.loaded = true
            });
        },
        initiateOverride(action = null, id, dialog_status) {
            switch (action) {
                case "cancelSO":
                    this.override.heading = "Cancel Sales Order";
                    this.override.departments_allowed = ["SM", "AC", "EX", "IT"];
                break;
                case "cancelSOItem":
                    this.override.heading = `Cancel SO Items`;
                    this.override.departments_allowed = ["SM", "AC", "EX", "IT"];
                break;
            }
            this.override.dialog = dialog_status;
            this.override.action = action;
            this.currentSalesOrderId = id;
        },
        closeConfirmOverride(user_id, authenticated = false) {
            this.override.dialog = false
            if (authenticated) {
                this.override.authenticated = true
                    // this.convertToWpList()
                switch (this.override.action) {
                case "cancelSO":
                    this.cancelSo()
                break;
                case "cancelSOItem":
                    this.cancelItem(this.currentSalesOrderId);
                break;
                }
            }
        },
        cancelSo(){
            this.errors = [];
            swal.fire({
                title: "",
                text: "Are you sure you want to cancel Sales Order?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                confirmButtonText: "Confirm",
                showCloseButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.confirm_overide_remarks = true
                }
            });
        },
        checkCreditLimit(approve = false) {
            let payload = {
                url: 'checkCreditLimit',
                customer_id: this.sales_order.customer_id,
                total_amount: this.sales_order.total_amount,
            }
            this.$store.dispatch('salesOrdersGet', payload).then(response => {
                if (response.data.status == 'over') {
                    if (approve) {
                        let over_amount = (parseFloat(response.data.running_amount) + parseFloat(response.data.current_amount)) - parseFloat(response.data.credit_limit_amount)
                        swal.fire({
                            title: `This order will exceed remaining credit limit by ${this.getTwoDecimals(over_amount)}. Continue?`,
                            icon: "warning",
                            showConfirmButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            allowOutsideClick: false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor: 'grey',
                            confirmButtonText: 'Confirm',
                            showCloseButton: true,
                        }).then(result => {
                            if (result.isConfirmed) {
                                // this.override.heading = 'Allow Over Credit Limit'
                                // this.override.action = 'credit_limit'
                                // this.override.dialog = true

                                if (this.checkCreditTerms(true).status === 'over') {
                                    axios.post('/updateSalesOrderStatus/' + this.viewSalesOrderId, {
                                        status: 15,
                                    }).then(response => {
                                        let data = response.data

                                        if (data.error) {
                                            swal.fire("", data.error, "warning");
                                            return false
                                        }
                                        this.resetFields()

                                        swal.fire("For Credit Terms Approval", "Customer has overdue SOA balance, need Accounting approval", "success");

                                        this.sales_order_items_dialog = false
                                        this.$emit('closeView', 'updateStatusSuccess')
                                    });
                                } else {
                                    this.convertToWpList()
                                }
                            }
                        })
                    } else {
                        axios.post('/updateSalesOrderStatus/' + this.viewSalesOrderId, {
                            status: 14,
                        }).then(response => {
                            let data = response.data

                            if (data.error) {
                                swal.fire("", data.error, "danger");
                                return false
                            }
                            this.resetFields()

                            swal.fire("For Credit Limit Approval!", "SO amount will exceed available credit limit, need Accounting approval", "warning");

                            this.sales_order_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                        });
                    }
                } else {
                    this.checkCreditTerms()
                }
            })
        },
        checkCreditTerms(checking = false) {
            let payload = {
                url: 'checkCreditTerms',
                customer_id: this.sales_order.customer_id,
            }

            this.$store.dispatch('salesOrdersGet', payload).then(response => {
                if (checking) {
                    return response.data
                } else {
                    if (response.data.status == 'over') {
                        let unpaid_amount = parseFloat(response.data.unpaid_amount)
                        swal.fire({
                            title: `Customer still has unpaid overdue accounts amounting to ${this.getTwoDecimals(unpaid_amount)}. Continue?`,
                            icon: "warning",
                            showConfirmButton: true,
                            showCancelButton: true,
                            reverseButtons: true,
                            allowOutsideClick: false,
                            confirmButtonColor: '#397373',
                            cancelButtonColor: 'grey',
                            confirmButtonText: 'Confirm',
                            showCloseButton: true,
                        }).then(confirm => {
                            if (confirm.isConfirmed) {
                                // this.override.heading = 'Allow Over Credit Terms'
                                // this.override.action = ''
                                // this.override.dialog = true

                                let payload = {
                                    url: 'updateSalesOrderStatus/' + this.viewSalesOrderId,
                                    status: 15,
                                }

                                axios.post('salesOrdersPost', payload).then(response => {
                                    let data = response.data

                                    if (data.error) {
                                        swal.fire("", data.error, "warning");
                                        return false
                                    }
                                    this.resetFields()

                                    swal.fire("", data.msg, "success");

                                    this.sales_order_items_dialog = false
                                    this.$emit('closeView', 'updateStatusSuccess')
                                });
                            }
                        })
                    } else {
                        this.convertToWpList()
                    }
                }
            })
        },
        async updateSalesOrderStatus(newStatus) {
            this.errors = []
            this.status_action_text = 'update'
            var convert_to_cash = false
            var approve_pl = false

            if (this.sales_order_customer.approved_by_acct == 1 && newStatus == 3) {
                if (this.sales_order.terms > 0) {
                    await swal.fire({
                        title: "Customer is on hold for accounting approval. ",
                        text: "Do you want to make this a CASH Sales Order?",
                        icon: "info",
                        showConfirmButton: true,
                        confirmButtonText: 'Term',
                        showCancelButton: true,
                        cancelButtonText: 'Cash'
                    }).then(response => {
                        if (response.isConfirmed) {
                            convert_to_cash = true
                        }
                    })
                }

                await swal.fire({
                    title: "Approval of DR Printing is Available.",
                    text: "Do you want to enable for this SO?",
                    icon: "info",
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    cancelButtonText: 'No'
                }).then(response => {
                    if (response.isConfirmed) {
                        approve_pl = true
                    }
                })

                await swal.fire({
                    title: "",
                    text: "Are you sure you want to " + this.status_action_text + " Sales Order?",
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
                }).then((willUpdate) => {
                    if (willUpdate.isConfirmed) {
                        let payload = {
                            status: newStatus,
                            convert_to_cash: convert_to_cash,
                            approve_pl: approve_pl,
                            customer_id: this.sales_order.customer_id,
                            total_amount: this.sales_order.total_amount,
                            sales_order_id: this.viewSalesOrderId,
                            url: 'updateSalesOrderStatus/' + this.viewSalesOrderId
                        }
                        this.$store.dispatch('salesOrdersPost', payload).then(response => {
                            let data = response.data

                            if (data.error) {
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.sales_order_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                        });
                    }
                });
            } else {
                swal.fire({
                    title: "",
                    text: "Are you sure you want to " + this.status_action_text + " Sales Order?",
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor: 'grey',
                    confirmButtonText: 'Confirm',
                    showCloseButton: true,
                }).then((willUpdate) => {
                    if (willUpdate.isConfirmed) {

                        let payload = {
                            status: newStatus,
                            convert_to_cash: convert_to_cash,
                            customer_id: this.sales_order.customer_id,
                            total_amount: this.sales_order.total_amount,
                            sales_order_id: this.viewSalesOrderId,
                            rush: this.sales_order.rush,
                            url: 'updateSalesOrderStatus/' + this.viewSalesOrderId
                        }

                        this.$store.dispatch('salesOrdersPost', payload).then(response => {
                            let data = response.data

                            if (data.error) {
                                swal.fire("", data.error, "warning");
                                return false
                            }
                            this.resetFields()

                            swal.fire("", data.msg, "success");

                            this.sales_order_items_dialog = false
                            this.$emit('closeView', 'updateStatusSuccess')
                        });
                    }
                });
            }
        },
        convertToWpList() {
            this.errors = []
            swal.fire({
                title: "",
                text: "Are you sure you want to Convert this to WPF?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            }).then((willConvert) => {
                if (willConvert.isConfirmed) {

                    let payload = {
                        url: 'convertToWpList/' + this.viewSalesOrderId
                    }

                    this.$store.dispatch('salesOrdersPost', payload).then(response => {
                        let data = response.data

                        if (data.error) {
                            swal.fire("", data.error, "warning");
                            return false
                        }
                        this.resetFields()

                        swal.fire("", data.msg, "success");

                        this.sales_order_items_dialog = false
                        this.$emit('closeView', 'convertSuccess')
                    });
                }
            });
        },
        convertToPackingList() {
            this.errors = []
            swal.fire({
                title: "",
                text: "Are you sure you want to Convert this to DR?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: '#397373',
                cancelButtonColor: 'grey',
                confirmButtonText: 'Confirm',
                showCloseButton: true,
            }).then((willConvert) => {
                if (willConvert.isConfirmed) {
                    this.$store.dispatch('salesOrdersPost', {
                        url: 'converSOStockCardToPackingList/' + this.viewSalesOrderId,
                    }).then(response => {
                        let data = response.data

                        if (data.error) {
                            swal.fire("", data.error, "warning");
                            return false
                        }
                        this.resetFields()

                        swal.fire("", data.msg, "success");

                        this.sales_order_items_dialog = false
                        this.$emit('closeView', 'convertSuccess')
                    });
                }
            });
        },
        renderPDF(pdfAction) {
            var doc = new jsPDF('p', 'mm', 'letter');
            var margin_left = 9;
            var margin_right = 9;

            this.print.salesOrderItems.forEach((item, i) =>{
                doc.setFont('courier', 'bold').setFontSize(13).text(6, 13, `D GREAT EAST INDUSTRIAL SUPPLY CORPORATION`)

                if (this.service == 1) {
                    doc.setFont('courier', 'italic').setFontSize(10).text(6, 18, `2 D Arellano St. Bagong Barrio, Caloocan City \nLandline:7002-3394\nSpare Parts Mobile / Viber No.: \nEmail:Ingcocares@ingco.com.ph"`)
                } else {
                    doc.setFont('courier', 'italic').setFontSize(10).text(6, 18, `420 Del Monte Ave, Brgy. Siena, Quezon City \nCell No.: +63 999 889 5739 \nTel. No.: +63 2 8711-6742 / +63 2 7717-4059`)
                }

                doc.setFont('courier', 'bold').setFontSize(13).text(162, 13, "Sales Order")
                doc.setFont('courier', 'bold').setFontSize(11).text(162, 18, "SO#: "+this.sales_order.order_num)

                let customer_details_top = this.service ? 43 : 30

                doc.autoTable({
                    html: '#customer_details',
                    theme: 'plain',
                    margin: { top: customer_details_top, left: margin_left, right: margin_right },
                    styles: {
                        font: 'courier',
                        cellPadding: 0,
                    },
                    columnStyles: {
                        0: { cellWidth: 23 },
                        1: { cellWidth: 130 },
                        2: { cellWidth: 16 },
                        3: { cellWidth: 25 },
                    }
                });

                if (this.print_no_price == false) {
                    doc.autoTable({
                        html: `#items_details${i}`,
                        startY: doc.autoTable.previous.finalY + 3,
                        margin: { left: margin_left, right: margin_right },
                        theme: 'grid',
                        headStyles: {
                            halign: 'center',
                            fillColor: '#ffffff',
                            textColor: '#000000',
                            lineWidth: .2,
                            lineColor: '#000000',
                            fontStyle: 'bold',
                            fontSize: 9,
                            font: 'courier',
                        },
                        columnStyles: {
                            0: {cellWidth: 7, halign:'center', valign: 'middle',  textColor: '#000000', fontSize: 8},
                            1: {cellWidth: 12, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                            2: {cellWidth: 14, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                            3: {cellWidth: 31, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                            4: {cellWidth: 82, halign:'left', valign: 'middle', textColor: '#000000', fontSize: 8},
                            5: {cellWidth: 23, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8},
                            6: {cellWidth: 25, halign:'center', valign: 'middle', textColor: '#000000', fontSize: 8}
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                            font: 'courier',
                            cellPadding: 1.60,
                        }
                    });
                } else {
                    doc.autoTable({
                        html: `#items_details_without_price${i}`,
                        startY: doc.autoTable.previous.finalY + 3,
                        margin: { left: margin_left, right: margin_right },
                        theme: 'grid',
                        headStyles: {
                            halign:'center',
                            fillColor: '#ffffff',
                            textColor: '#000000',
                            lineWidth: .2,
                            lineColor: '#000000',
                            fontSize: 9,
                            font: 'courier',
                            fontStyle: 'bold',
                            cellPadding: 0
                        },
                        columnStyles: {
                            0: {cellWidth: 11, halign:'center', textColor: '#000000', fontSize: 8},
                            1: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                            2: {cellWidth: 17, halign:'center', textColor: '#000000', fontSize: 8},
                            3: {cellWidth: 27, halign:'center', textColor: '#000000', fontSize: 8},
                            4: {cellWidth: 118, halign:'left', textColor: '#000000', fontSize: 8},
                        },
                        bodyStyles: {
                            lineColor: '#000000',
                            font: 'courier'
                        }
                    });
                }
                if (this.print_no_price == false) {
                    doc.autoTable({
                        html: `#total_details${i}`,
                        theme: 'plain',
                        // startY: 213,
                        startY: 213,
                        styles:{
                            lineWidth: .2,
                            lineColor: '#000000',
                            font: 'courier'
                        },
                        margin: {top: 37, left: 107, right: margin_right},
                        columnStyles: {
                            0: { cellWidth: 48, halign: "left", textColor: "#000000" },
                            1: { cellWidth: 48, halign: "right", textColor: "#000000" },
                            2: { cellWidth: 48, halign: "right", textColor: "#000000" },
                            // 1: { cellWidth: 48, halign: "right", textColor: "#000000" },
                        }
                    });
                }

                doc.autoTable({
                    html: "#substitution_items",
                    theme: "plain",
                    // pageBreak: "auto",
                    startY: 195,
                    margin: { top: 37, left: 6, right: 6 },
                    styles: {
                        font: 'courier'
                    },
                    columnStyles: {
                    0: { cellWidth: 110, textColor: "#000000", fontSize:8, },
                    },
                    didParseCell: function (data) {
                        var rows = data.table.body;
                        data.cell.styles.setFont = ['Helvetica', 'italic'];
                    }
                });

                let footer = doc.lastAutoTable.finalY;
                doc.autoTable({
                    html: '#num_of_items',
                    theme: 'plain',
                    startY: 215,
                    styles:{
                        font: 'courier'
                    },
                    margin: { top: 37, left: 6, right: 6 },
                    columnStyles: {
                        0: { cellWidth: 110, textColor: '#000000' },
                        1: { cellWidth: 40, halign: 'right', textColor: '#000000' },
                        2: { cellWidth: 12, textColor: '#000000' },
                        3: { cellWidth: 35, halign: 'right', textColor: '#000000' },
                    }
                });
                doc.autoTable({
                    html: '#footer',
                    theme: 'grid',
                    tableWidth: 100,
                    startY: 245,
                    styles: {
                        font: 'courier',
                        fontSize: 8
                    },
                    margin: { left: margin_left, right: margin_right },
                    columnStyles: {
                        0: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        1: {cellWidth: 38, halign:'center', textColor: '#000000'},
                        2: {cellWidth: 38, halign:'center', textColor: '#000000'},
                    },
                    bodyStyles: {
                        lineColor: '#000000',
                    }
                });

                let finalY = doc.lastAutoTable.finalY;

                if(this.print.salesOrderItems.length != (i + 1) ){
                    doc.addPage(1)
                }
            })


            if (pdfAction == 'download') {
                doc.save('SalesOrder ' + this.sales_order.order_num + '.pdf');
            } else if (pdfAction == 'print') {
                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');
            }

        },
        clearSalesOrderItems() {
            this.resetFields()
            this.sales_order_items_dialog = false
            this.selectedItems = []
            this.showSelect = false
            this.$emit('closeView');
        },
        resetFields() {
            this.salesOrderItems = []
            this.sales_order = ''
            this.discounts = []
            this.itemTypeDiscounts = []
        },
        getItemTypeDiscounts() {
            let str = ''

            this.itemTypeDiscounts = []
            let first_item = this.salesOrderItems[0]
            this.item_type = first_item.item.item_type_text

            if (this.discounts.length > 0) {
                let brand_discount = this.discounts.find(f => f.brand_id == first_item.item.brand_id)
                if (typeof brand_discount !== 'undefined') {
                    brand_discount.discounts.forEach((discount, index) => {
                        if (!isNaN(discount[this.item_type.toLowerCase()])) {
                            if (index > 0) {
                                str = str + '/'
                            }
                            str += discount[this.item_type.toLowerCase()].toString()
                        }
                    })
                }
            }

            this.itemTypeDiscounts.push(str)
        },
        computePrintOutItemTotal(srp, qty) {
            return Math.round(parseFloat(srp) * qty).toFixed(2)
        },
        cancelItem(id){
                let isValid = this.selectedItems.every(e => e.qty_to_cancel <= e.unserved_qty)
                if(!isValid){
                    swal.fire('Warning!', 'The Qty to Cancel cannot be greater than Unserved Qty', 'warning')
                }
                else if(this.selectedItems.length > 0){
                    let selected = this.selectedItems.map(e =>{
                        return {
                            so_item_id:     e.id,
                            qty_to_cancel:  parseInt(e.qty_to_cancel),
                            sales_order_id: e.sales_order_id,
                            quantity: e.quantity,
                            quantity_served: e.quantity_served,
                            quantity_canceled: e.quantity_canceled,
                            unserved_qty: e.unserved_qty - parseInt(e.qty_to_cancel),
                            price: e.display_final_price,
                            total: e.display_total_amount
                        }
                    })

                    let total_quantity          = this.salesOrderItems.reduce((subtotal, current) => parseInt(subtotal) + parseInt(current.quantity), 0)
                    let total_quantity_served   = this.salesOrderItems.reduce((subtotal, current) => parseInt(subtotal) + parseInt(current.quantity_served), 0)
                    let canceled                = this.salesOrderItems.reduce((subtotal, current) => parseInt(subtotal) + parseInt(current.quantity_canceled), 0)
                    let total_unserved_qty      = this.salesOrderItems.reduce((subtotal, current) => parseInt(subtotal) + parseInt(current.unserved_qty), 0)
                    let to_cancel               = this.selectedItems.reduce((subtotal, current) => parseInt(subtotal) + parseInt(current.qty_to_cancel), 0)
                    let total_quantity_canceled = canceled + to_cancel
                    let total_serve_and_cancel = total_quantity_canceled + total_quantity_served

                    let status = 0
                    if(total_quantity_served === 0 && total_quantity_canceled === total_quantity){
                        status = 6
                    }else if(total_quantity_served > 0 && total_unserved_qty === 0 || total_serve_and_cancel == total_quantity){
                        status = 4
                    }else{
                        status = null
                    }
                    const payload = {
                        selected: selected,
                        id:  id,
                        status: status,
                        total_quantity: total_quantity,
                        total_quantity_served: total_quantity_served,
                        total_quantity_canceled: total_quantity_canceled,
                        url: `cancelSalesOrderItem/${id}`
                    }
                    swal.fire({
                        title: `Are you sure you want to cancel the selected items?`,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor: 'grey',
                        confirmButtonText: 'Confirm',
                        showCloseButton: true,
                    }).then(answer => {
                        if (answer.isConfirmed) {
                            this.$store.dispatch('salesOrdersPost', payload).then(response =>{
                                swal.fire('Selected Items has been canceled!', '', 'info')
                                this.selectedItems = []
                                this.clearSalesOrderItems();
                            })
                        }else{
                            this.showSelect = true
                        }
                    })
                }
                else{
                    swal.fire('There is no selected Item', 'Please select an item to proceed!', 'warning')
                }
                this.showSelect = false
                this.$store.commit("REFRESH", true)
        },
        countingNumberOnly ($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            let inputValue = $event.target.value;
            if ((inputValue === '' && keyCode === 48) || keyCode === 45 || keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        confirmReason(){
            if (this.reason.text !== null && this.reason.text !== '') {
                let payload = {
                    url: "cancelSalesOrder/" + this.currentSalesOrderId,
                    reason: this.reason.text,
                };
                this.$store.dispatch("salesOrdersPost", payload).then((response) => {
                    let data = response.data;

                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false;
                    }
                    swal.fire({
                        title: "",
                        html: data.msg,
                        icon: "success",
                    });
                    this.reason.text = '';
                    this.sales_order_items_dialog = false;
                    this.getAllSalesOrders();
                    this.confirm_overide_remarks = false;
                    this.$store.commit("REFRESH", true);
                });
            }else{
                swal.fire('Reason for cancel is required!', '', 'warning')
            }
        },
        fixItemInPdf(array, linesPerPage) {
            let clean_array = [];
            let temp = [];
            let linesRemaining = linesPerPage;
            let totalLines = 0;
            array.forEach((e, i) => {
                e.index = i;
                const modelLines = Math.ceil(e.item.model.length / 16)
                const nameLines = Math.ceil(e.item.name.length / 47);
                totalLines += Math.max(modelLines, nameLines);
                if (totalLines <= linesRemaining) {
                    temp.push(e);
                } else {
                    clean_array.push(temp);
                    temp = [e];
                    totalLines = nameLines;
                }
                if (totalLines === linesPerPage || array.length === (i + 1)) {
                    clean_array.push(temp);
                    temp = [];
                    linesRemaining = linesPerPage;
                    totalLines = 0;
                }
            });
            return clean_array;
        },
    },
    watch: {
        viewSalesOrderId: function (val) {
            if (val != -1) {

                if (this.viewSalesOrderId != null && this.viewSalesOrderId > 0) {
                    let params = {
                        url: 'viewSalesOrder/' + this.viewSalesOrderId
                    }
                    this.$store.dispatch('salesOrdersGet', params).then(response => {
                        let data = response.data
                        if (data.error) {
                            swal.fire("", data.error, "warning");
                            return false
                        }
                        this.sales_order = data.sales_order
                        this.salesOrderItems =  data.sales_order.sales_order_items
                        this.print.salesOrderItems = this.fixItemInPdf(this.salesOrderItems, this.PRINT_NO_OF_LINES);
                        this.sales_order_customer = data.customer

                        this.sales_order.issuer_name = this.sales_order.issuer_name.replace(/ .*/, '')

                        if (data.sales_order.discounts != '') {
                            this.discounts = JSON.parse(data.sales_order.discounts)
                        }

                        if (data.sales_order.stock_card) {
                            this.selectedHeaders = this.stockCardsalesOrderItemsHeaders
                        } else {
                            this.selectedHeaders = this.salesOrderItemsHeaders
                        }

                        this.getItemTypeDiscounts()
                        this.sales_order_items_dialog = true
                    }).catch(error => {
                        console.log(error)
                        this.errors = error.response.data.errors
                    })
                }
            }
        },
        showSelect:{
            handler(val){
                if(val){
                    this.showSelect = this.showSelect ? true : false;
                }
            }
        }
    },
    computed: {
        ...mapGetters([
            "ACTION",
            'PRINT_NO_OF_LINES',
        ]),
        no_of_items: function () {
            if(this.salesOrderItems.length > 0 && this.ACTION.includes('cancel')){
                this.showSelect = true
                let array = this.salesOrderItems.filter(e=>e.unserved_qty > 0)
                this.selectedItems = array
            }
            if (this.salesOrderItems != null && this.salesOrderItems.length > 0) {
                var count = 0;
                this.salesOrderItems.forEach((item) => {
                    count += item.quantity
                })
                return this.thousandSeprator(count)
            } else {
                return 0
            }
        },
    },
}
</script>
<style>
.v-avatar {
    margin: 15px 50px !important;
}

#client_modal_card {
    min-height: 160px !important;
}
.center-input input {
    text-align: center;
}
.overrideBg {
    background-color: lightyellow;
}
</style>
