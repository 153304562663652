<template>
  <v-container class="m-3" style="overflow:hidden">
      <v-row>
        <v-col class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">NAVIGATION DRAWER SETTING</h4>
        </v-col>
      </v-row>
      <v-row>
        <div class="btn-toolbar my-2">
          <v-btn @click="adddialogs()">
              <v-icon>mdi-plus</v-icon>
              Add
          </v-btn>
        </div>
      </v-row>
      <v-row>
        <v-col lg="12">
          <navigation-drawer-table></navigation-drawer-table>
        </v-col>
      </v-row>
    <dialogs :cp="components" :width="1000" :scrollable="true"></dialogs>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// import buttons from '@/views/main/layouts/Buttons/DialogButton.vue'
// import buttons_components from '@/assets/js/components-js'
import editNavigationDrawer from '@/views/main/layouts/dialogs/SystemSettings/NavigationDrawer.vue'
import NavigationDrawerTable from './tables/NavigationDrawer.vue'
import Dialogs from '@/views/main/layouts/dialogs/Dialog.vue'
export default {
  components: {
    // buttons,
    // buttons_components,
    NavigationDrawerTable,
    editNavigationDrawer,
    Dialogs,
  },
  data() {
    return {
      // buttons: buttons_components.buttons,
      loaded: false,
      departments: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 15,
      search: '',
      viewDepartmentId: '',
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          },
        ],
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      selected: {},
      components: editNavigationDrawer,
    }
  },
   methods: {
    adddialogs() {
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'Submit')
    }
  },
};
</script>
<style>
td{
  padding: 0;
}
</style>
