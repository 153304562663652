<template>
  <v-card elevation="20">
    <v-col
      class="d-flex no-block align-items-center justify-content-start pa-2"
      v-if="userAccess.create"
    >
      <!-- <buttons
        v-if="stat_text != 0"
        :action="buttons.actions.create"
        :button_icon="buttons.icons.create"
        :color="buttons.colors.create"
        @click="adddialog"
      ></buttons> -->
      <div class="row" v-if="stat_text != 0">
        <div class="col-12 d-flex no-block align-items-center">
            <div class="btn-toolbar">
                <v-btn @click="adddialog()">
                    <v-icon>mdi-plus</v-icon>
                    Add
                </v-btn>
            </div>
        </div>
      </div>
    </v-col>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Search" single-line hide-details>
      </v-text-field>
      <v-icon @click="searchInput()">'mdi-trash-can</v-icon>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="item"
      :search="search"
      :items-per-page="10"
      class="w-100"
      :multi-sort="true"
      :loading="!loaded"
      loading-text="Loading...Please wait"
    >
      <template v-slot:[`item.Active`]="{ item }">
        <span class="badge bg-primary text-white" v-if="item.active">Yes</span>
        <span class="badge bg-danger text-white" v-else>No</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
          v-if="userAccess.view"
          :action="buttons.actions.view"
          :button_icon="buttons.icons.view"
          :color="buttons.colors.view"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        ></buttons>
        <buttons
          v-if="userAccess.edit"
          :action="buttons.actions.edit"
          :button_icon="buttons.icons.edit"
          :color="buttons.colors.edit"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editVendor(item, item.id)"
        ></buttons>
        <buttons
          v-if="userAccess.delete"
          :action="buttons.actions.delete"
          :button_icon="buttons.icons.delete"
          :color="buttons.colors.delete"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="deleteVendor(item.id)"
        ></buttons> -->
        <v-btn text icon color="orange" v-if="userAccess.view" >
          <v-icon class="btn-action" small @click="viewVendor(item)">mdi-eye</v-icon>
        </v-btn>
        <v-btn text icon color="blue" v-if="userAccess.edit" >
            <v-icon class="btn-action" small @click="editVendor(item)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn text icon color="red" v-if="userAccess.delete" >
            <v-icon class="btn-action" small @click="deleteVendor(item.id)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";
// import buttons from "../../../layouts/Buttons/DialogButton.vue";
// import buttons_components from "../../../../../assets/js/components-js.js";
export default {
  props: ["stat_text", "active","component_vendor"],
  // components: { buttons, buttons_components },
  data() {
    return {
      // buttons: buttons_components.buttons,
      loaded: false,
      totalCustomers: 0,
      options: {},
      customers: [],
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPage: 10,
      search: "",
      awaitingSearch: false,
      headers: [
        { text: "Company Name", align: "left", value: "vendor_name" },
        { text: "Name", align: "left", value: "first_name" },
        { text: "Active", align: "left", value: "Active" },
        { text: "vendor Type", align: "left", value: "vendor_type_name" },
        { text: "Actions", align: "center", value: "action", sortable: false },
      ],
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      status_text: this.stat_text,
      selected: {},
      item: [],
    };
  },
  mounted() {
    this.$store.dispatch(
      "checkAccessUser",
      this.$router.currentRoute.params.id
    );
    this.getAllVendor();
  },
  methods: {
    getAllVendor() {
      const payload = {
        search: this.search,
        status_text: this.status_text,
      };
      this.$store.dispatch("getallvendor", payload).then((response) => {
        this.loaded = true;
        this.item = response.data;
        this.$store.commit("NEW_VENDOR", false);
      });
    },
    searchInput() {
      this.getAllVendor({ query: this.search });
    },
    viewVendor(item, itemID) {
      // console.log(item)
      this.$store.commit("EDIT_VENDORS", { item: item, id: itemID });
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "View");
    },
    editVendor(item, itemID) {
      // console.log(item)
      this.$store.commit("EDIT_VENDORS", { item: item, id: itemID });
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "Update");
    },
    deleteVendor(item) {
      const params = {
        id: item,
        is_active: this.active,
      };
      swal
        .fire({
          icon: "warning",
          text: "Are you sure you want to Delete?",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "#397373",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("DeleteVendor", params).then((success) => {
              swal.fire({
                text: "Delete Vendor Successful!",
                icon: "success",
              });
             for(let x in this.component_vendor){
              this.$store.commit("NEW_VENDOR", true);
             }

            });


          }
        });
    },
    adddialog() {
      this.$store.commit("DIALOG", true);
      this.$store.commit("ACTION", "Submit");
    },
  },
  computed: {
    ...mapGetters([
      "DIALOGS",
      "GET_ALL_VENDOR",
      "ACTION",
      "USER_ACCESS",
      "NEW_VENDOR",
    ]),
  },
  watch: {
    options: {
      handler() {
        this.getAllVendor();
      },
      deep: true,
    },
    NEW_VENDOR: {
      handler(val) {
        if (val) {
          this.item = [];
          this.$store.commit("DIALOG", false);
          this.getAllVendor();
          swal.fire({
            title: "Success",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });

        }
      },
      deep: true,
    },
    USER_ACCESS: {
      handler(val) {
        if(val != "fail"){
           this.userAccess.create = false;
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
        val.forEach(e => {
          if (e.actions_code == 'create') {
            this.userAccess.create = true;
          }else if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
      },
    },
  },
};
</script>
