<template>
  <v-container fluid>
    <v-card-title>
      <v-row>
        <v-col md="16" class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">CUSTOMERS</h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-container>
      <v-row>
        <template>
          <v-tabs v-model="tab" color="cyan" dark slider-color="cyan" show-arrows background-color="#424242" class="mx-2">
            <v-tabs-slider color="cyan"></v-tabs-slider>
                <v-tab ripple v-for="(item, i) in items" :key="item" class="ml-3" @click="tabs(i)">{{ item }}</v-tab>
                <v-spacer></v-spacer>
                <v-tab @click="tabs(component_customer.length - 1)">For Approval</v-tab>
            </v-tabs>
        </template>
      </v-row>
    </v-container>
    <v-container class="ma-0 pa-2">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(table, index) in component_customer" :key="index">
          <keep-alive>
            <component :is="table.components" :cp_text="table.status_text" :component_customer="component_customer">
            </component>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <dialogs :cp="components" :scrollable="true" :width="dialog_width" :retainfocus="false"></dialogs>
  </v-container>
</template>
<script>
import customer from "./tables/CustomerComponent.vue";
import customerclassification from "./tables/CustomerClassificationsComponent.vue";
import Dialogs from "../../layouts/dialogs/Dialog.vue";
import customerDialog from "../../layouts/dialogs/Masterdata/CustomerComponent.vue";
import classificationDialog from "../../layouts/dialogs/Masterdata/CustomerClassificationComponent.vue";
import CustomerCategoryDialog from "../../layouts/dialogs/Masterdata/CustomerCategoryComponent.vue";
import CustomerCategory from "@/views/main/modules/MasterData/tables/CustomerCategoryComponent.vue";
import CustomerTemp from "@/views/main/modules/MasterData/tables/CustomerTempComponent.vue"

import CustomerCode from "./tables/CustomerCodeComponent.vue";
import CustomerCodeDialog from "../../layouts/dialogs/Masterdata/CustomerCodeComponent.vue";
import CustomerApprovalDialog from "@/views/main/layouts/dialogs/Masterdata/CustomerApprovalComponent.vue"

export default {
  components: {
    customer,
    customerclassification,
    Dialogs,
    classificationDialog,
    customerDialog,
    CustomerCode,
    CustomerCodeDialog,
    CustomerApprovalDialog
  },
  data() {
    return {
      components: '',
      customer_component: customerDialog,
      classification: classificationDialog,
      tab: 0,
      items: ['ACTIVE', 'INACTIVE', 'ALL', 'GC', 'CATEGORY', 'CLASSIFICATION', 'CODE'],
      component_customer: [
        {
          components: customer,
          status_text: 'is_active',
          dialog: customerDialog,
          dialog_width: '60%',
        },
        {
          components: customer,
          status_text: 'is_inactive',
          dialog: customerDialog,
          dialog_width: '60%',
        },
        {
          components: customer,
          status_text: 'all',
          dialog: customerDialog,
          dialog_width: '60%',
        },
        {
          components: customer,
          status_text: 'is_mother',
          dialog: customerDialog,
          dialog_width: '60%',
        },
        {
          components: CustomerCategory,
          dialog: CustomerCategoryDialog,
          dialog_width: '40%',
        },
        {
          components: customerclassification,
          dialog: classificationDialog,
          dialog_width: '40%',
        },
        {
          components: CustomerCode,
          dialog: CustomerCodeDialog,
          dialog_width: '40%',
        },
        {
          components: CustomerTemp,
          status_text: "for_approval",
          dialog: customerDialog,
          dialog_width: "60%",
        },
      ],
      dialog_width: '70%',
    };
  },
  mounted() {
    this.tabs(0);
    this.$store.dispatch('getBranddiscount');
    this.$store.dispatch('discounttype');
    this.$store.dispatch('getAffliatecustomer');
    this.$store.dispatch('getSalesmanDropDown');
    this.$store.dispatch('getSalesmanCoorDropDown');
    this.$store.dispatch('getCountries');
    this.$store.dispatch('getRegions');
    this.$store.dispatch('getProvince');
    this.$store.dispatch('getCities');
    this.$store.dispatch('getClassification');
    this.$store.dispatch('getCustomerCode');
    this.$store.dispatch('getallcustomerCategory');
    this.$store.commit('LINK_ACTIVE', window.location.pathname.split('/')[3]);
  },
  methods: {
    tabs(key) {
      this.components = this.component_customer[key].dialog;
      this.dialog_width = this.component_customer[key].dialog_width;
      this.$store.commit('ACTIVE_TAB',this.component_customer[key].status_text)
    },
  },
};
</script>
