import DebitMemo from "@/views/main/modules/Accounting/DebitMemo/DebitMemoGroupComponent.vue"
import repository from "@/api/credentials";

const routes = [{
    path: "/accounting/debit-memo/:id",
    name: "acctdebit",
    component: DebitMemo,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Debit Memo',
    }
}]

export default routes;
