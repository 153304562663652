var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none"}},[_vm._m(0),_vm._m(1),_c('table',{attrs:{"id":"parts_needed"}},[_c('tbody',[_vm._m(2),_vm._m(3),_vm._l((_vm.parts_needed),function(x,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(x.name))]),_c('td',[_vm._v(" "+_vm._s(x.part_num))]),_c('td',[_vm._v(" "+_vm._s(x.description))]),_c('td',[_vm._v(" "+_vm._s(x.serve_qty))])])}),_vm._l((_vm.remaining_row),function(x){return _c('tr',{key:x},[_c('td'),_c('td'),_c('td'),_c('td')])})],2)]),_vm._m(4),_c('table',{attrs:{"id":"signatories"}},[_c('tbody',[_vm._m(5),_c('tr',[_c('td',[_c('u',[_vm._v(_vm._s(this.sc_repair_forms.prepared_by))]),_c('br'),_vm._v("Repair Admin")]),_c('td',[_vm._v(_vm._s(this.sc_repair_forms.assigned_technician)),_c('br'),_vm._v("Repair Technician")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('table',{attrs:{"id":"tech_report"}},[_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Tech Report No:"),_c('br')])]),_c('tr',[_c('td',[_vm._v("Model No:")]),_c('td',[_vm._v("Dealer:")])]),_c('tr',[_c('td',[_vm._v("Serial No:")]),_c('td',[_vm._v("Customer:")])]),_c('tr',[_c('td',[_vm._v("Date Purchased:")]),_c('td',[_vm._v("Sold On-Line:")])]),_c('tr',[_c('td',[_vm._v("Date Received:")]),_c('td',[_vm._v("Repair Form No:")])]),_c('tr',[_c('td',[_vm._v("Warranty:")]),_c('td',[_vm._v("Technician:")])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Complaint:")])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Symptom Tested:")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{attrs:{"id":"tech_diagnosis"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Tech Diagnosis:")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v("Parts Needed")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("SP Name")]),_c('td',[_vm._v("SP No")]),_c('td',[_vm._v("Description")]),_c('td',[_vm._v("Qty")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{attrs:{"id":"recommendation"}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Recommendation:")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Prepared by: "),_c('br')]),_c('td',[_vm._v("Diagnosed by: "),_c('br')]),_c('td',[_vm._v("Reviewed By:"),_c('br'),_vm._v("Kim Capulong"),_c('br'),_vm._v("Technical Support")]),_c('td',[_vm._v("Approved by: "),_c('br'),_vm._v("Rena Magdadaro "),_c('br'),_vm._v(" Service Operation Manager")])])
}]

export { render, staticRenderFns }