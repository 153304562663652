
import InventoryCountComponent from '@/views/main/modules/Reports/InventoryReports/InventoryCountComponent'
import ItemMovementComponent from '@/views/main/modules/Reports/InventoryReports/ItemMovementComponent'
import DailyMovementReportComponent from '@/views/main/modules/Reports/InventoryReports/DailyMovementReportComponent'
import PendingSCByPLComponent from '@/views/main/modules/Reports/InventoryReports/PendingSCByPLComponent';
import PendingSCByItemsComponent from '@/views/main/modules/Reports/InventoryReports/PendingSCByItemsComponent'
import PendingSCByCustomersComponent from '@/views/main/modules/Reports/InventoryReports/PendingSCByCustomersComponent'
import StockRequestReportComponent from '@/views/main/modules/Reports/InventoryReports/StockRequestReportComponent'
import StockTransferReportComponent from '@/views/main/modules/Reports/InventoryReports/StockTransferReportComponent'
import DailyWarehouseInventoryRerportComponent from '@/views/main/modules/Reports/InventoryReports/DailyWarehouseInventoryRerportComponent'
import TrucksPerDayReportComponent from '@/views/main/modules/Reports/InventoryReports/TrucksPerDayReportComponent'
import SalesQuotationReportComponent from '@/views/main/modules/Reports/InventoryReports/SalesQuotationReportComponent'
import ContainerReportComponent from '@/views/main/modules/Reports/InventoryReports/ContainerReportComponent'
import OrderItemWithStocksReportComponent from '@/views/main/modules/Reports/InventoryReports/OrderItemWithStocksReportComponent'
import ShipmentPerItemReport from '@/views/main/modules/Reports/InventoryReports/ShipmentPerItemReport'
import ItemsWithNewModelsComponent from '@/views/main/modules/Reports/InventoryReports/ItemsWithNewModelsComponent'
import InactiveItemsReport from '@/views/main/modules/Reports/InventoryReports/InactiveItemsActualVsVirtualReportComponent'
import ItemsWhichContainerLastArrivedComponent from '@/views/main/modules/Reports/InventoryReports/ItemsWhichContainerLastArrivedComponent'
import ItemWithStocksReportComponent from '@/views/main/modules/Reports/InventoryReports/ItemWithStocksReportComponent'
import WarehouseStocksVsItemMovement from '@/views/main/modules/Reports/InventoryReports/WarehouseStocksVsItemMovement'
import repository from "@/api/credentials";

const routes = [{
    path: 'reports/inventory/inventory-counts/:id',
    name: 'inventory-counts',
    component: InventoryCountComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Inventory Count Report',
    }
},
{
    path: 'reports/inventory/item-movements/:id',
    name: 'item-movements',
    component: ItemMovementComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Item Movement Report',
    }
},
{
    path: 'reports/inventory/daily-movement-reports/:id',
    name: 'daily-movement-reports',
    component: DailyMovementReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Daily Movement Report',
    }
},
{
    path: 'reports/inventory/pending-stock-card-by-customers/:id',
    name: 'pending-stockcard-by-customers',
    component: PendingSCByCustomersComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By Customer Report',
    }
},
{
    path: 'reports/inventory/pending-stock-card-by-items/:id',
    name: 'pending-stockcard-by-items',
    component: PendingSCByItemsComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By Item Report',
    }
},
{
    path: 'reports/inventory/pending-stock-card-by-pl/:id',
    name: 'pending-stockcard-by-pl',
    component: PendingSCByPLComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By DR Report',
    }
},
{
    path: 'reports/inventory/stock-request-reports/:id',
    name: 'stock-request-reports',
    component: StockRequestReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Stock Request Report',
    }
},
{
    path: 'reports/inventory/stock-transfer-reports/:id',
    name: 'stock-transfer-reports',
    component: StockTransferReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Stock Transfer Report',
    }
},
{
    path: 'reports/inventory/daily-warehouse-inventory-reports/:id',
    name: 'daily-warehouse-inventory-reports',
    component: DailyWarehouseInventoryRerportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Daily Warehouse Inventory Report',
    }
},
{
    path: 'reports/inventory/trucks-per-day-reports/:id',
    name: 'trucks-per-day-reports',
    component: TrucksPerDayReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Trucks Per Day Report',
    }
},
{
    path: 'reports/inventory/shipment-per-item-reports/:id',
    name: 'shipment-per-item-reports',
    component: ShipmentPerItemReport,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Shipment Per Item Report',
    }
},
{
    path: 'reports/inventory/sales-quotaions-reports/:id',
    name: 'sales-quotations-reports',
    component: SalesQuotationReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales Quotations Report',
    }
},
{
    path: 'reports/inventory/container-item-reports/:id',
    name: 'container-item-reports',
    component: ContainerReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Container Report',
    }
},
{
    path: 'reports/inventory/order-items-with-stock-reports/:id',
    name: 'order-items-with-stock-reports',
    component: OrderItemWithStocksReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Order Item With Stocks Report',
    }
},
{
    path: 'reports/inventory/items-with-new-models-report/:id',
    name: 'items-with-new-models-report',
    component: ItemsWithNewModelsComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Items With New Models Report',
    },
},
{
    path: 'reports/inventory/inactive-items-reports/:id',
    name: 'inactive-items-reports',
    component: InactiveItemsReport,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Inactive Items: Actual vs Virtual Stocks',
    },
},
{
    path: 'reports/inventory/items-which-container-last-arrived/:id',
    name: 'items-which-container-last-arrived',
    component: ItemsWhichContainerLastArrivedComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Items Which Container Last Arrived',
    },
},
{
    path: 'reports/inventory/items-based-on-stock-range/:id',
    name: 'items-based-on-stock-range',
    component: ItemWithStocksReportComponent,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Items Based on Stock Range',
    },
},
{
    path: 'reports/inventory/warehouse-stocks-vs-item-movement/:id',
    name: 'warehouse-stocks-vs-item-movement',
    component: WarehouseStocksVsItemMovement,
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Warehouse Stocks vs Item Movement',
    },
},
]

export default routes;
