<template>
  <div class="tabs-body-color">
    <!-- <div class="container">
            <div class="row d-flex justify-content-center align-items-center bg-light" style="border:3px solid red; display:none" id="warehouse-form">
                <div id="heading" class="col-10 d-flex">
                    <div class="d-flex justify-content-end w-100 align-items-end">
                        <span style="font-size:20px;"><b>WTF:</b></span><span class="text-danger" id="srf-number">wtfnumber</span>
                    </div>
                </div>
                <div id="headingInformation" class="col-10">
                    <table class="w-100 pdf-table">
                        <tr>
                            <td>FROME:</td>
                            <td>DATE:</td>
                        </tr>
                        <tr>
                            <td>TO:</td>
                            <td>ID#:</td>
                        </tr>
                    </table>
                    <table class="w-100 pdf-table">
                        <tr>
                            <td>TRUCK:</td>
                            <td>DRIVER:</td>
                            <td>PAHENANTE:</td>
                        </tr>
                    </table>
                </div>
                <div id="table" class="col-10" >
                    <table class="w-100 pdf-table pdf-table-data-headings">
                        <tr>
                            <th style="text-align:center">MODEL</th>
                            <th style="text-align:center">DESCRIPTION</th>
                            <th style="text-align:center">QTY BY BOX</th>
                            <th style="text-align:center">QTY By PC/UNIT</th>
                        </tr>
                    </table>
                </div>
                <div id="footer" class="col-10">
                    <div class="row d-flex justify-content-between">
                        <p>TRANSFERED BY :</p>
                        <p>ENCODED BY :</p>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <p>________________</p>
                        <p>________________</p>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <p>RECEIVED/DOUBLE CHECKED BY:</p>
                        <p>DATE ENCODED :</p>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <p>________________</p>
                        <p>________________</p>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="container">
      <div class="row">
        <div class="col-10 d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">Stock Transfers</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-10 d-flex no-block align-items-center">
          <div class="btn-toolbar">
            <v-btn
              v-on:click="
                editedIndex = -1;
                pickStockRequests();
              "
            >
              <v-icon>mdi-plus</v-icon>Pick Stock Request To Serve
            </v-btn>
          </div>
        </div>
        <div class="col-2 d-flex no-block align-items-center">
          <v-autocomplete
            v-model="view_type"
            :items="['Warehouse', 'Daily']"
            dense
            filled
            label="View"
            background-color="white"
          ></v-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <v-switch v-model="warehouse.virtual" dark label="NONE VIRTUAL">
          </v-switch>
        </div>
      </div>

      <!-- PICK STOCK REQUEST -->
      <v-dialog
        v-model="stockRequests.dialog"
        scrollable
        persistent
        max-width="1270px"
        style="z-index: 70"
      >
        <v-card>
          <v-row class="m-0">
            <v-card-title>
              <span
                class="text-lg font-semibold"
                v-text="`Select Stock Request To Serve`"
              ></span>
            </v-card-title>
            <v-col cols="pull-right-10 p-2">
              <v-btn
                text
                icon
                color="gray"
                class="float-right"
                @click="stockRequests.dialog = false"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="stockRequests.loading"
            indeterminate
            color="green"
          ></v-progress-linear>
          <v-row dense no-gutters tag="div">
            <v-col sm="6"> </v-col>
            <v-col sm="6">
              <v-row
                dense
                no-gutters
                tag="form"
                class="d-flex"
                @submit.prevent="getStockRequests()"
              >
                <v-text-field
                  dense
                  autocomplete="off"
                  v-model="stockRequests.datatable.search"
                  label="Search SRF Number"
                  single-line
                  hide-details
                >
                </v-text-field>
                <v-icon @click="getStockRequests()">mdi-magnify</v-icon>
              </v-row>
            </v-col>
          </v-row>

          <v-card-text
            style="max-height: 460px; border: 1px solid #e0e0e0; padding: 0"
          >
            <v-data-table
              v-model="stockRequests.selected"
              :headers="stockRequests.datatable.headers"
              :items="stockRequests.data"
              :options.sync="stockRequests.datatable.pagination.options"
              :items-per-page="
                stockRequests.datatable.pagination.perPageDefault
              "
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [10, 25],
              }"
              :single-select="true"
              item-key="id"
              show-select
              dense
              style="border: 1px solid #e0e0e0; margin: 0"
            >
              <template v-slot:item.date="{ item }">
                <span v-text="Moment(item.date).format('lll')"></span>
              </template>
              <template v-slot:item.status="{ item }">
                <span
                  :class="`badge ${Lodash.get(
                    STOCK_REQUEST_STATUS_CLASS,
                    item.status,
                    'badge-pending'
                  )}`"
                  v-text="
                    Lodash.get(
                      STOCK_REQUEST_STATUS_DESCRIPTION,
                      item.status,
                      'STATUS NOT FOUND'
                    )
                  "
                >
                </span>
              </template>
              <template v-slot:item.remaining_grand_total="{ item }">
                <span v-text="formatNumber(item.remaining_grand_total)"></span>
              </template>
            </v-data-table>

            <v-divider></v-divider>
            <!-- <div v-if="errors.stock_request_id.length" v-for="error in errors.stock_request_id" class="red--text" v-bind:key="error" v-text="error"></div>
                        <div v-if="errors.details.length" v-for="error in errors.details" class="red--text" v-bind:key="error" v-text="error"></div> -->

            <!-- <v-row tag="div" align="center" no-gutters class="px-2">
                            <v-col class="d-flex pr-2" cols="3">
                                <v-row dense no-gutters tag="div">
                                    <v-col dense tag="div" sm="12" class="d-flex justify-space-between">
                                        <div class="font-weight-medium" v-text="`Serving Warehouse`"></div>
                                        <v-row no-gutters dense tag="div" align="end" justify="end" class="stock-request-text-xs">
                                            <div v-for="error in errors.warehouse_id" class="red--text" v-bind:key="error" v-text="error"></div>
                                        </v-row>
                                    </v-col>
                                    <v-col dense tag="div" sm="12">
                                        <v-autocomplete
                                            :disabled="!stockRequests.selected.length || loading.state"
                                            hide-details
                                            item-text="text"
                                            item-value="value"
                                            class="stock-transfer-text-sm"
                                            :items="warehousesOtherThanRequesting"
                                            v-model="warehouse.value"
                                            dense
                                            outlined
                                            flat
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row> -->

                    </v-card-text>

                    <v-card-actions class="d-flex justify-end">
                        <v-btn
                            small
                            :loading="loading.state"
                            :disabled="!stockRequests.selected.length"
                            v-on:click="create(stockRequests.selected[0])">
                            <v-icon>mdi-plus</v-icon>
                            Create Stock Transfer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- STOCK TRANSFER DIALOG -->
            <v-dialog v-model="dialog" scrollable persistent style="z-index: 50;">
                <form>
                    <v-card>
                        <v-row class="m-0">
                            <v-card-title>
                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                <span v-if="!loading.state" class="text-lg font-semibold" v-text="`${editedStockTransfer.stock_transfer_form_number}`"></span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-progress-linear
                            v-if="loading.state"
                            indeterminate
                            color="green"
                        ></v-progress-linear>
                        <v-card-text style="max-height: 760px; border:1px solid #e0e0e0;">
                            <v-row tag="div" align="center" no-gutters>
                                <v-col dense cols="6">
                                    <span v-text="`Source :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.serving_warehouse}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Acknowledged By :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.acknowledged}`"></span>
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="[STOCK_TRANSFER_STATUS.PREPARING,
                                    STOCK_TRANSFER_STATUS.SHIPPING,
                                    STOCK_TRANSFER_STATUS.CONFIRM_RECEIVED].indexOf(editedStockTransfer.latest_status) >= 0 && !editing"
                                tag="div"
                                align="center"
                                no-gutters>
                                <v-col dense cols="6">
                                    <span v-text="`Remarks :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.transfer_remarks || ''}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Confirm Received On Device :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="`${editedStockTransfer.confirm_received_on_device || ''}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Vehicle :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state && editedStockTransfer.shipper" class="font-weight-bold" v-text="`${editedStockTransfer.shipper.vehicle.name}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Plate # :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state && editedStockTransfer.shipper" class="font-weight-bold" v-text="`${editedStockTransfer.shipper.vehicle.plate_number}`"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Driver :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>

                                    <v-chip v-if="!loading.state && editedStockTransfer.shipper" class="m-1" small outlined>
                                        <v-icon x-small left color="gray">mdi-account</v-icon>
                                        <span class="font-weight-bold" v-text="`${editedStockTransfer.shipper.driver ? editedStockTransfer.shipper.driver : ''}`"></span>
                                    </v-chip>
                                </v-col>
                                <v-col dense cols="12">
                                    <v-row dense no-gutters tag="div" align="center">
                                        <span v-text="`Helpers :`"></span>&nbsp;
                                        <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                        <div v-if="editedStockTransfer.shipper">
                                            <span
                                                v-if="!loading.state"
                                                v-for="(helper, index) in editedStockTransfer.shipper.helpers"
                                                :key="`${index}-${helper}`"
                                                class="mr-1">
                                            <v-chip class="mr-1" small outlined>
                                                <v-icon x-small left color="gray">mdi-account</v-icon>
                                                <span v-text="helper" class="mr-1"></span>
                                            </v-chip>
                                        </span>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="[STOCK_TRANSFER_STATUS.PREPARING,
                                    STOCK_TRANSFER_STATUS.SHIPPING,
                                    STOCK_TRANSFER_STATUS.CONFIRM_RECEIVED].indexOf(editedStockTransfer.latest_status) >= 0 && editing"
                                tag="div"
                                align="center"
                                no-gutters>
                                <v-col dense cols="12" class="d-flex justify-center" style="min-height: 2.5rem">
                                    <v-row dense no-gutters tag="div" align="center">
                                        <span v-text="`Helpers :`"></span>&nbsp;
                                        <span v-for="(helper, index) in editedStockTransfer.editedShipper.helpers " :key="`${index}-${helper}`" class="m-1">
                                            <v-chip class="mr-1" small outlined>
                                                <v-icon x-small left color="gray">mdi-account</v-icon>
                                                <span v-text="helper" class="mr-1"></span>
                                                <v-icon x-small right @click="removeHelper(index)">mdi-minus</v-icon>
                                            </v-chip>
                                        </span>
                                        <v-chip v-show="inputHelper.state" class="m-1" small outlined>
                                            <v-icon x-small left color="gray">mdi-account</v-icon>
                                            <input
                                                style="width: 120px; border:0 !important; outline: none;"
                                                type="text"
                                                v-focus="inputHelper.state"
                                                :value="inputHelper.value"
                                                @keyup.enter="addHelper"
                                                @keyup.esc="inputHelper.state = false;"
                                                @blur="inputHelper.state = false;"/>
                                        </v-chip>

                                        <v-btn
                                            rounded
                                            outlined
                                            v-if="!inputHelper.state"
                                            @click="inputHelper.state = true; inputHelper.value = null"
                                            style="border:1px solid gray"
                                            x-small
                                            class="ml-2">
                                            Add Helper
                                            <v-icon x-small>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-col>
                                <v-col class="d-flex pr-2" cols="6">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12" class="d-flex">
                                            <v-row no-gutters dense tag="div" class="stock-request-text-xs">
                                                <div v-for="error in errors[`shipper.driver`]" class="red--text" v-bind:key="error" v-text="error"></div>
                                            </v-row>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <input
                                                autocomplete="off"
                                                :disabled="loading.state"
                                                v-model="editedStockTransfer.editedShipper.driver"
                                                style="border:1px solid #e0e0e0;"
                                                type="text"
                                                class="form-control"
                                                placeholder="ENTER DRIVER">
                                        </v-col>
                                    </v-row >
                                </v-col>
                                <v-col class="d-flex pr-2" cols="6">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12" class="d-flex">
                                            <v-row no-gutters dense tag="div" class="stock-request-text-xs">
                                                <div v-for="error in errors[`shipper.vehicle_id`]" class="red--text" v-bind:key="error" v-text="error"></div>
                                            </v-row>
                                        </v-col>
                                        <v-col dense tag="div" sm="12">
                                            <v-autocomplete
                                                :disabled="loading.state"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                :items="vehicles.selection"
                                                v-model="vehicles.value"
                                                dense
                                                outlined
                                                flat
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="d-flex pr-2" cols="12">
                                    <v-row dense no-gutters tag="div">
                                        <v-col dense tag="div" sm="12">
                                            <input
                                                autocomplete="off"
                                                :disabled="loading.state"
                                                v-model="editedStockTransfer.transfer_remarks"
                                                style="border:1px solid #e0e0e0;"
                                                type="text"
                                                class="form-control"
                                                placeholder="REMARKS">
                                        </v-col>
                                    </v-row >
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <v-row tag="div" align="center" no-gutters>
                                <v-col dense cols="6">
                                    <span v-text="`Destination :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="editedStockTransfer.requesting_warehouse"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Requested By :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="editedStockTransfer.requested_by"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Stock Request :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="editedStockTransfer.stock_request_form_number"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Date Requested :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="Moment(editedStockTransfer.claimant.date).format('lll')"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Remarks :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="editedStockTransfer.remarks"></span>
                                </v-col>
                                <v-col dense cols="6">
                                    <span v-text="`Expected Date :`"></span>&nbsp;
                                    <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                    <span v-if="!loading.state" class="font-weight-bold" v-text="editedStockTransfer.expected_date"></span>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <div>
                                <span class="font-weight-bold" v-text="`Items To Prepare`"></span>&nbsp;
                                <span class="font-weight-light" v-text="`[${editedStockTransfer.details.length}]`"></span>
                            </div>
                            <v-simple-table fixed-header dense class="overflow-y-auto" style="border:1px solid #e0e0e0; max-height: 283px;" id="pdfTable">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">ITEM MODEL</th>
                                            <th class="text-left">ITEM NAME</th>
                                            <th class="text-left">LOCATION</th>

                                            <th class="text-right"  v-if="!loading.state && editedStockTransfer.service != 1">
                                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                                <span v-text="`${editedStockTransfer.serving_warehouse_code} INVENTORY (MB)`"></span>
                                            </th>
                                            <th class="text-right">
                                                <v-progress-circular v-if="loading.state" size="16" indeterminate color="gray"></v-progress-circular>
                                                <span v-if="!loading.state" v-text="`${editedStockTransfer.serving_warehouse_code} INVENTORY (UOM)`"></span>
                                            </th>
                                            <th class="text-right" v-if="editedStockTransfer.service != 1">M-BOX</th>

                                            <th v-if="editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span v-bind="attrs" v-on="on">
                                                    REM (MB)
                                                  </span>
                                                </template>
                                                <span>Remaining (MB)</span>
                                              </v-tooltip>
                                            </th>

                                            <!-- <th class="text-right">REM (UOM)</th> -->
                                            <th class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                      REM (UOM)
                                                    </span>
                                                    </template>
                                                <span>Remaining (UOM)</span>
                                              </v-tooltip>
                                            </th>

                                            <th v-if="editing" class="text-right">{{editedStockTransfer.service == 1 || editedStockTransfer.claimant.allow_butal == 1 ? 'UOM QTY' : 'MB QTY'}}</th>


                                            <!-- <th v-if="!editing && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right">PREP (MB)</th> -->
                                            <th v-if="!editing && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span v-bind="attrs" v-on="on">
                                                    PREP (MB)
                                                  </span>
                                                </template>
                                                <span>Prepared (MB)</span>
                                              </v-tooltip>
                                            </th>

                                            <!-- <th v-if="!editing " class="text-right">PREP (UOM)</th> -->
                                            <th v-if="!editing " class="text-right" >
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span v-bind="attrs" v-on="on">
                                                    PREP (UOM)
                                                  </span>
                                                </template>
                                                <span>Prepared (UOM)</span>
                                              </v-tooltip>
                                            </th>

                                            <!-- <th v-if="editedStockTransfer.latest_status == 3 && !editing && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right">REC (MB)</th> -->
                                            <th v-if="editedStockTransfer.latest_status == 3 && !editing && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span  v-bind="attrs" v-on="on">
                                                    REC (MB)
                                                  </span>
                                                </template>
                                                <span>Received (MB)</span>
                                              </v-tooltip>
                                            </th>

                                            <!-- <th v-if="editedStockTransfer.latest_status == 3 && !editing" class="text-right">REC (UOM)</th> -->
                                            <th v-if="editedStockTransfer.latest_status == 3 && !editing" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span v-bind="attrs" v-on="on">
                                                    REC (UOM)
                                                  </span>
                                                </template>
                                                <span>Received (UOM)</span>
                                              </v-tooltip>
                                            </th>

                                            <!-- <th v-if="editedStockTransfer.service != 1 && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1 && editedStockTransfer.latest_status < 2" class="text-right">{{ 'VAR QTY(MB)'}}</th> -->
                                            <th v-if="editedStockTransfer.service != 1 && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1 && editedStockTransfer.latest_status < 2" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span v-bind="attrs" v-on="on">
                                                    {{ 'VAR QTY(MB)'}}
                                                  </span>
                                                </template>
                                                <span>Variance Quantity (MB)</span>
                                              </v-tooltip>
                                            </th>
                                            <!-- <th v-if="editedStockTransfer.latest_status < 2" class="text-right">{{ 'VAR QTY(UOM)'}}</th> -->
                                            <th v-if="editedStockTransfer.latest_status < 2" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span v-bind="attrs" v-on="on">
                                                    {{ 'VAR QTY(UOM)'}}
                                                  </span>
                                                </template>
                                                <span>Variance Quantity (UOM)</span>
                                              </v-tooltip>
                                            </th>

                                            <!-- <th v-if="!editing && editedStockTransfer.latest_status == 3 && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right">{{'CAN QTY(MB)' }}</th> -->
                                            <th v-if="!editing && editedStockTransfer.latest_status == 3 && editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span  v-bind="attrs" v-on="on">
                                                    {{'CAN QTY(MB)' }}
                                                  </span>
                                                </template>
                                                <span>Cancelled Quantity (MB)</span>
                                              </v-tooltip>
                                            </th>
                                            <!-- <th v-if="!editing && editedStockTransfer.latest_status == 3" class="text-right">{{'CAN QTY(UOM)'}}</th> -->
                                            <th v-if="!editing && editedStockTransfer.latest_status == 3" class="text-right">
                                              <v-tooltip top color="#f69f1a">
                                                <template v-slot:activator="{ on, attrs }">
                                                  <span v-bind="attrs" v-on="on">
                                                    {{'CAN QTY(UOM)'}}
                                                  </span>
                                                </template>
                                                <span>Cancelled Quantity (UOM)</span>
                                              </v-tooltip>
                                            </th>
                                            <!-- <th v-if="editedStockTransfer" class="text-right">VARIANCE (UOM)</th> -->
                                            <th v-if="editing" class="text-left">REMARKS</th>
                                            <th v-if="editing" class="text-left">CANCEL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(detail, index) in editedStockTransfer.details"
                                                :key="detail.id"
                                                :class="{'bg-gray' : detail.cancelled, 'cannot-serve': detail.serving_warehouse_inventory < detail.remaining_uom_quantity}"
                                            >

                                            <td v-text="detail.model"></td>
                                            <td v-text="detail.name"></td>
                                            <td v-text="detail.item_location"></td>

                                            <td v-if="editedStockTransfer.service != 1" class="text-right" v-text="`${thousandSeprator(Math.ceil((detail.initial_serving_warehouse_stocks / detail.master_box_qty)))}`"></td>
                                            <td class="text-right" v-text="`${thousandSeprator(detail.initial_serving_warehouse_stocks)}`"></td>

                                            <td v-if="editedStockTransfer.service != 1" class="text-right" v-text="`${thousandSeprator(detail.master_box_qty)}`"></td>

                                            <td v-if="editedStockTransfer.service != 1 && editedStockTransfer.claimant.allow_butal != 1" class="text-right" v-text="`${thousandSeprator(Math.ceil(detail.remaining_uom_quantity/detail.master_box_qty))}`"></td>
                                            <td class="text-right" v-text="`${thousandSeprator(detail.remaining_uom_quantity)}`"></td>

                                            <!-- <td v-if="editing && editedStockTransfer.service == 1">
                                                <select v-model="detail.request_type" class="form-control stock-request-items" style="cursor:pointer; margin:1px 0px 1px !important; height: 1.5rem !important; padding:0 !important;" :disabled="detail.cancelled">
                                                    <option :value="0" v-text="Lodash.get(ENUMS.STOCK_REQUEST_TYPE_DESCRIPTION, 0)"></option>
                                                    <option :value="1" v-text="Lodash.get(ENUMS.STOCK_REQUEST_TYPE_DESCRIPTION, 1)"></option>
                                                    <option :value="2" v-text="Lodash.get(ENUMS.STOCK_REQUEST_TYPE_DESCRIPTION, 2)"></option>
                                                </select>
                                            </td> -->

                      <td v-if="editing" class="text-right">
                        <input
                          class="form-control stock-transfer-items float-right"
                          style="
                            text-align: right;
                            outline: none;
                            cursor: pointer;
                            margin: 1px 0px 1px !important;
                            height: 1.5rem !important;
                            padding: 0 5px !important;
                          "
                          type="number"
                          min="0"
                          v-model="detail.request_type_quantity"
                          @click="$event.target.focus()"
                          @focus="$event.target.select()"
                          @keypress="pressQuantity($event)"
                          @keyup="
                            checkQuantity(
                              index,
                              editedStockTransfer.details,
                              detail
                            )
                          "
                          @blur="
                            blurQuantity(
                              editedStockTransfer.details,
                              index,
                              'prepared_uom_quantity'
                            )
                          "
                        />
                      </td>
                      <td
                        v-if="
                          !editing &&
                          editedStockTransfer.service != 1 &&
                          editedStockTransfer.claimant.allow_butal != 1
                        "
                        class="text-right"
                        v-text="
                          `${thousandSeprator(
                            detail.prepared_master_box_quantity
                          )}`
                        "
                      ></td>
                      <td
                        v-if="!editing"
                        class="text-right"
                        v-text="
                          `${thousandSeprator(detail.prepared_uom_quantity)}`
                        "
                      ></td>

                      <td
                        v-if="
                          editedStockTransfer.latest_status == 3 &&
                          !editing &&
                          editedStockTransfer.service != 1 &&
                          editedStockTransfer.claimant.allow_butal != 1
                        "
                        class="text-right"
                        v-text="
                          `${thousandSeprator(
                            Math.ceil(
                              detail.received_uom_quantity /
                                detail.master_box_qty
                            )
                          )}`
                        "
                      ></td>
                      <td
                        v-if="
                          editedStockTransfer.latest_status == 3 && !editing
                        "
                        class="text-right"
                        v-text="
                          `${thousandSeprator(detail.received_uom_quantity)}`
                        "
                      ></td>

                      <td
                        v-if="editing"
                        class="text-right"
                        v-text="
                          `${thousandSeprator(
                            (detail.request_type == 1
                              ? detail.remaining_master_box_quantity
                              : detail.remaining_uom_quantity) -
                              detail.request_type_quantity
                          )}`
                        "
                      ></td>
                      <td
                        v-if="
                          editing &&
                          editedStockTransfer.claimant.allow_butal != 1 &&
                          editedStockTransfer.service != 1
                        "
                        class="text-right"
                        v-text="
                          `${thousandSeprator(
                            (detail.request_type == 0
                              ? detail.remaining_master_box_quantity
                              : detail.remaining_uom_quantity) -
                              detail.request_type_quantity *
                                detail.master_box_qty
                          )}`
                        "
                      ></td>

                      <td
                        v-if="
                          !editing &&
                          editedStockTransfer.latest_status < 2 &&
                          editedStockTransfer.claimant.allow_butal != 1 &&
                          editedStockTransfer.service != 1
                        "
                        class="text-right"
                        v-text="
                          `${thousandSeprator(
                            detail.remaining_master_box_quantity -
                              detail.prepared_master_box_quantity
                          )}`
                        "
                      ></td>
                      <td
                        v-if="!editing && editedStockTransfer.latest_status < 2"
                        class="text-right"
                        v-text="
                          `${thousandSeprator(
                            detail.remaining_uom_quantity -
                              detail.prepared_uom_quantity
                          )}`
                        "
                      ></td>

                      <td
                        v-if="
                          editedStockTransfer.latest_status == 3 &&
                          editedStockTransfer.claimant.allow_butal != 1
                        "
                        class="text-right"
                        v-text="
                          `${thousandSeprator(
                            detail.cancelled_master_box_quantity
                          )}`
                        "
                      ></td>
                      <td
                        v-if="editedStockTransfer.latest_status == 3"
                        class="text-right"
                        v-text="
                          `${thousandSeprator(detail.cancelled_uom_quantity)}`
                        "
                      ></td>

                      <td v-if="editing" class="text-left">
                        <input
                          class="form-control stock-transfer-items float-right"
                          style="
                            text-align: right;
                            outline: none;
                            cursor: pointer;
                            margin: 1px 0px 1px !important;
                            height: 1.5rem !important;
                            padding: 0 5px !important;
                          "
                          min="0"
                          :placeholder="
                            stockRequestTotalUomComputer(
                              detail,
                              detail.request_type,
                              detail.request_type_quantity
                            ) -
                              detail.remaining_uom_quantity ==
                            0
                              ? 'No need to enter remarks'
                              : 'Please Enter remarks'
                          "
                          :disabled="
                            stockRequestTotalUomComputer(
                              detail,
                              detail.request_type,
                              detail.request_type_quantity
                            ) -
                              detail.remaining_uom_quantity ==
                              0 || detail.cancelled
                          "
                          v-model="detail.remarks"
                          @click="$event.target.focus()"
                          @focus="$event.target.select()"
                        />
                      </td>
                      <td>
                        <v-icon
                          center
                          @click="
                            removeItem(
                              editedStockTransfer,
                              detail.id,
                              detail.model + '-' + detail.name,
                              index,
                              editedStockTransfer.details
                            )
                          "
                          :color="detail.cancelled ? 'gray' : 'error'"
                          x-large
                          v-if="editing"
                          >fas fa-minus</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div
                v-for="error in errors.details"
                class="red--text"
                v-bind:key="error"
                v-text="error"
              ></div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="!editing"
                :hidden="
                  !editedStockTransfer.shipper ||
                  !enableShipIf(editedStockTransfer)
                "
                :loading="loading.state"
                small
                @click="printItemPreparation(editedStockTransfer)"
              >
                <v-icon>mdi-download</v-icon> Prepare Print Out
              </v-btn>
              <!-- <v-btn
                                :loading="loading.state"
                                small
                                v-on:click="printItemPreparation(editedStockTransfer, false)">
                                <i class="mdi-download mr-1"></i>Prepare Only Capable
                            </v-btn> -->
                            <v-btn
                                v-if="editedStockTransfer.latest_status === 0 && !editing"
                                :loading="loading.state"
                                small
                                @click="approve(stockTransfers.data[editedIndex])">Approve
                            </v-btn>
                            <v-btn
                                v-if="!editing"
                                :hidden="!editedStockTransfer.shipper || !enableShipIf(editedStockTransfer)"
                                :loading="loading.state"
                                small
                                @click="ship(stockTransfers.data[editedIndex],editedStockTransfer.details)">
                                <v-icon>mdi-truck</v-icon> Ship
                            </v-btn>
                            <v-btn
                                v-if="editing"
                                :loading="loading.state"
                                small
                                v-on:click="update()">Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </form>
            </v-dialog>

            <!-- STOCK TRANSFER LIST -->

            <v-progress-linear
                v-if="loading.state"
                indeterminate
                color="green"
            ></v-progress-linear>
            <v-app>
                <v-tabs v-model="tab" id="group-tab" color="cyan" dark slider-color="cyan" background-color="#424242" class="col-12" show-arrows>
                    <v-tab ripple v-for="(warehouse, index) in warehouse.vTabOptions" :key="warehouse.value" @click="getFilteredValues(warehouse,selectedStatus);" >{{warehouse.code}}</v-tab>
                    <v-tabs-items v-model="tab" >
                        <v-row no-gutters dense tag="div">
                            <v-tab-item class="w-100"  touchless v-for="(warehouse, index) in warehouse.vTabOptions" :key="warehouse.value">
                                <v-card class="w-100">
                                    <v-row>
                                        <v-tabs v-model="tab1" id="group-tab" color="gray" light slider-color="cyan" class="col-12 ma-2" show-arrows>
                                            <v-tab ripple v-for="(status, index) in  filters.status.selection" :key="status.value" @click="getFilteredValues(warehouse,status.value); selectedStatus=status.value" >{{status.text}}</v-tab>
                                            <v-tabs-items v-model="tab1">
                                                <v-tab-item class="w-100"  touchless v-for="(status, index) in  filters.status.selection" :key="status.value">
                                                    <v-row dense no-gutters tag="div" class="px-2 pt-2">
                                                        <v-col sm="3">

                                                        </v-col>
                                                        <v-col sm="3">
                                                            <!-- <v-autocomplete
                                                                v-model="filters.warehouse.selected"
                                                                :items="filters.warehouse.selection"
                                                                dense
                                                                hide-details
                                                                multiple
                                                                placeholder="Filter Warehouse"
                                                                class="text-md text-gray-700"
                                                                style="font-size: 0.8rem !important">
                                                                <template v-slot:selection="{ item, index }">
                                                                    <v-chip x-small v-if="index <= 1">
                                                                        <span v-text="item.text"></span>
                                                                    </v-chip>
                                                                    <span v-if="index === 2" class="caption" v-text="`+ ${filters.warehouse.selected.length - 2} more`"></span>
                                                                </template>
                                                                <template v-slot:prepend-item>
                                                                    <v-row align="center" dense no-gutters tag="div" class="d-flex flex-grow px-3 pb-1">
                                                                        <div>
                                                                            <v-icon
                                                                                @click="toggleFilterSelection(filters.warehouse)"
                                                                                :color="filters.warehouse.selected.length > 0 ? 'indigo darken-4' : ''">
                                                                                {{ filterSelectionIcon(filters.warehouse.selection, filters.warehouse.selected) }}
                                                                            </v-icon>
                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                @input="searchFilterSelection(filters.warehouse)"
                                                                                v-model="filters.warehouse.search"
                                                                                type="text"
                                                                                placeholder="Search Warehouse"
                                                                                style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                                                                class="form-control" />
                                                                        </div>
                                                                    </v-row>
                                                                    <v-divider class="m-0 p-0"></v-divider>
                                                                </template>
                                                                <template v-slot:item="{ active, item, attrs, on }">
                                                                    <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                                                        <v-row dense no-gutters align="center">
                                                                            <input class="mr-1" type="checkbox" :checked="active" />
                                                                            <div class="caption" v-text="item.text"></div>
                                                                        </v-row>
                                                                    </v-list-item>
                                                                </template>
                                                            </v-autocomplete> -->
                                                        </v-col>
                                                        <v-col sm="3">
                                                            <!-- <v-autocomplete
                                                                v-model="filters.status.selected"
                                                                :items="filters.status.selection"
                                                                dense
                                                                hide-details
                                                                multiple
                                                                placeholder="Filter Status"
                                                                class="text-md text-gray-700"
                                                                style="font-size: 0.8rem !important">
                                                                <template v-slot:selection="{ item, index }">
                                                                    <v-chip x-small v-if="index <= 1">
                                                                        <span v-text="item.text"></span>
                                                                    </v-chip>
                                                                    <span v-if="index === 2" class="caption" v-text="`+ ${filters.status.selected.length - 2} more`"></span>
                                                                </template>
                                                                <template v-slot:prepend-item>
                                                                    <v-row align="center" dense no-gutters tag="div" class="d-flex flex-grow px-3 pb-1">
                                                                        <div>
                                                                            <v-icon
                                                                                @click="toggleFilterSelection(filters.status)"
                                                                                :color="filters.status.selected.length > 0 ? 'indigo darken-4' : ''">
                                                                                {{ filterSelectionIcon(filters.status.selection, filters.status.selected) }}
                                                                            </v-icon>
                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                @input="searchFilterSelection(filters.status)"
                                                                                v-model="filters.status.search"
                                                                                type="text"
                                                                                placeholder="Search Status"
                                                                                style="text-align: left; font-size: .8rem !important; outline: none; height: 1.5rem !important;"
                                                                                class="form-control" />
                                                                        </div>
                                                                    </v-row>
                                                                    <v-divider class="m-0 p-0"></v-divider>
                                                                </template>
                                                                <template v-slot:item="{ active, item, attrs, on }">
                                                                    <v-list-item dense v-on="on" v-bind="attrs" #default="{ active }">
                                                                        <v-row dense no-gutters align="center">
                                                                            <input class="mr-1" type="checkbox" :checked="active" />
                                                                            <div class="caption" v-text="item.text"></div>
                                                                        </v-row>
                                                                    </v-list-item>
                                                                </template>
                                                            </v-autocomplete> -->
                                                        </v-col>
                                                        <v-col sm="3">
                                                            <v-row dense no-gutters tag="form" class="d-flex" @submit.prevent="list()">
                                                                <v-text-field
                                                                    dense
                                                                    autocomplete="off"
                                                                    v-model="search"
                                                                    label="Search SRF Number"
                                                                    single-line
                                                                    hide-details>
                                                                </v-text-field>
                                                                <v-icon @click="list()">mdi-magnify</v-icon>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row no-gutters dense tag="div" class="mx-2 mt-2">
                                                        <v-col>
                                                            <v-data-table
                                                            :headers="stockTransfers.headers"
                                                            :items="stockTransfers.data"
                                                            :options.sync="pagination.options"
                                                            :items-per-page="10"
                                                            :footer-props="{
                                                                showFirstLastPage: true,
                                                                itemsPerPageOptions: [10, 25]
                                                            }"
                                                            :server-items-length="pagination.total">
                                                            <template v-slot:item.status_description="{ item }">
                                                                <span
                                                                    :class="`badge ${Lodash.get(STOCK_TRANSFER_STATUS_CLASS, item.status_description, 'badge-pending')}`"
                                                                    v-text="Lodash.get(STOCK_TRANSFER_STATUS_DESCRIPTION, item.status_description, 'STATUS NOT FOUND')">
                                                                </span>
                                                            </template>
                                                            <template v-slot:item.action="{ item }">
                                                                <span class="text-nowrap">
                                                                    <v-btn text icon color="orange">
                                                                        <v-icon class="btn-action" small @click="view(item)">mdi-eye</v-icon>
                                                                    </v-btn>
                                                                    <v-btn v-if="[STOCK_TRANSFER_STATUS.PREPARING, STOCK_TRANSFER_STATUS.SHIP_CANCELLED].indexOf(item.latest_status) >= 0" text icon color="blue">
                                                                        <v-icon class="btn-action" small @click="edit(item)">mdi-pencil</v-icon>
                                                                    </v-btn>
                                                                    <v-btn text icon color="red">
                                                                        <v-icon class="btn-action" small @click="destroy(item)">mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </span>
                                                            </template>
                                                            </v-data-table>
                                                        </v-col>
                                                    </v-row>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-tabs>
                                    </v-row>

                  <!--<v-row no-gutters dense tag="div" class="mt-2">
                                        <v-col md="6" sm="12" v-for="(stockRequest, index) in stockRequests" :key="stockRequest.id">
                                            <v-card outlined height="325px" class="p-2">
                                                <v-row v-if="true" no-gutters dense tag="div" class="d-flex justify-space-between">
                                                    <span class="overline" v-text="stockRequest.stock_request_form_number"></span>
                                                    <span
                                                        :class="`badge ${Lodash.get(STOCK_REQUEST_STATUS_CLASS, stockRequest.status, 'badge-pending')}`"
                                                        v-text="Lodash.get(STOCK_REQUEST_STATUS_DESCRIPTION, stockRequest.status, 'STATUS NOT FOUND')">
                                                        </span>
                                                    <span class="overline" v-text="Moment(stockRequest.date).format('lll')"></span>
                                                </v-row>

                                                <v-row no-gutters dense tag="div" class="d-flex justify-space-between text-lg font-bold text-gray-dark">
                                                    <div><span v-text="`${stockRequest.warehouse.name}`"></span>&nbsp;<span class="text-lg overline" v-text="stockRequest.warehouse.code"></span></div>
                                                </v-row>

                                                <v-row no-gutters dense tag="div" class="d-flex">
                                                    <div><span class="stock-request-text-xs overline" v-text="`Requested by : `"></span><span class="overline" v-text="stockRequest.user.name"></span></div>
                                                </v-row>
                                                <v-simple-table fixed-header dense height="197px" class="overflow-y-auto"  style="border:1px solid #e0e0e0;">
                                                    <template v-slot:default>
                                                        <thead>
                                                        <tr>
                                                            <th class="text-left">MODEL</th>
                                                            <th class="text-left">NAME</th>
                                                            <th class="text-left">REQUESTED</th>
                                                            <th class="text-left">REMAINING</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(detail, index) in stockRequest.details" :key="detail.id">
                                                            <td>{{ detail.item.model }}</td>
                                                            <td>{{ detail.item.name }}</td>
                                                            <td>{{ detail.requested_formatted }}</td>
                                                            <td>{{ detail.remaining_formatted }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                                <div class="d-flex justify-end mt-2">
                                                    <v-btn v-if="[STOCK_REQUEST_STATUS.APPROVED, STOCK_REQUEST_STATUS.IN_PROGRESS, STOCK_REQUEST_STATUS.COMPLETED].indexOf(stockRequest.status) >= 0" @click="showReceivables(stockRequest)" small outlined text class="d-flex items-center ml-1"><span>Receivable</span></v-btn>
                                                    <v-btn @click="view(stockRequest)" small outlined text class="d-flex items-center ml-1"><v-icon small color="orange" class="mr-1">mdi-eye</v-icon><span>View</span></v-btn>
                                                    <v-btn v-if="[STOCK_REQUEST_STATUS.FOR_APPROVAL, STOCK_REQUEST_STATUS.CANCELLED].indexOf(stockRequest.status) >= 0" @click="approve(stockRequest)" small outlined text class="d-flex items-center ml-1"><span>Approve</span></v-btn>
                                                    <v-btn v-if="[STOCK_REQUEST_STATUS.APPROVED].indexOf(stockRequest.status) >= 0" @click="cancel(stockRequest)" small outlined text class="d-flex items-center ml-1"><span>Cancel</span></v-btn>
                                                    <v-btn v-if="[STOCK_REQUEST_STATUS.FOR_APPROVAL, STOCK_REQUEST_STATUS.CANCELLED].indexOf(stockRequest.status) >= 0" @click="edit(stockRequest)" small outlined text class="d-flex items-center ml-1"><v-icon small color="blue" class="mr-1">mdi-pencil</v-icon><span>Edit</span></v-btn>
                                                    <v-btn v-if="[STOCK_REQUEST_STATUS.FOR_APPROVAL, STOCK_REQUEST_STATUS.CANCELLED].indexOf(stockRequest.status) >= 0" @click="destroy(stockRequest, index)" small outlined text class="d-flex items-center ml-1"><v-icon small color="red" class="mr-1">mdi-delete</v-icon><span>Delete</span></v-btn>
                                                </div>
                                            </v-card>
                                        </v-col>
                                        <v-col v-if="!loading.state && !stockRequests.length" md="12" >
                                            <p>No Record Found</p>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters dense tag="div" class="mt-4" justify="end">
                                        <v-pagination :disabled="loading.state" v-model="pagination.page" :length="pagination.totalPage" :total-visible="7"></v-pagination>
                                    </v-row>-->
                </v-card>
              </v-tab-item>
            </v-row>
          </v-tabs-items>
        </v-tabs>
      </v-app>
    </div>
  </div>
</template>

<script>
import SharedFunctionsComponentVue from "@/views/main/Shared/SharedFunctionsComponent.vue";
import moment from "moment";
import swal from "sweetalert2";
import { mapGetters } from "vuex";
import jsPDF from "jspdf";

export default {
  name: "InventoryTransferComponent",
  mixins: [SharedFunctionsComponentVue],

  mounted() {
    let that = this;

    // that.getWarehouses();
    // that.warehousesOtherThanRequesting()
    that.getVehicles();

    // let selectedStatus = (Uri.hasValueAndProperty('hash', 'status'))
    //     ? eval(Uri.toObject('hash')['status'])
    //     : [0];

    that.getFilteredValues();
    that.filters.status.selection = that.filters.status.data;

    // that.search = (Uri.hasValueAndProperty('hash', 'stfNumber')) ? Uri.toObject('hash')['stfNumber'] : '';

    that.getWarehouseRequest();
    this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
  },

  data() {
    return {
      loading: {
        title: "Please wait",
        state: false,
      },
      warehouse: {
        selection: [
          {
            value: null,
            text: "NONE SELECTED",
          },
        ],
        value: null,
        vTabOptions: [],
        virtual: false,
      },
      vehicles: {
        selection: [
          {
            value: null,
            text: "SELECT VEHICLE",
          },
        ],
        value: null,
      },
      dialog: false,

      stockRequests: {
        loading: false,
        datatable: {
          search: "",
          headers: [
            {
              text: "SRF NUMBER",
              value: "stock_request_form_number",
              sortable: false,
            },
            {
              text: "REQUESTING WAREHOUSE",
              value: "requesting_warehouse",
              sortable: false,
            },
            {
              text: "SERVING WAREHOUSE",
              value: "serving_warehouse",
              sortable: false,
            },
            { text: "DATE", value: "date", align: "left", sortable: false },
            { text: "STATUS", value: "status", sortable: false },
            // {text: 'REMAINING', value: 'remianing_transfer', align:'right', sortable: false},
            { text: "REMARKS", value: "remarks", sortable: false },
          ],
          pagination: {
            options: {},
            total: 0,
            perPageDefault: 5,
          },
        },
        dialog: false,
        data: [],
        selected: [],
      },

      stockTransfers: {
        headers: [
          {
            text: "STF NUMBER",
            value: "stock_transfer_form_number",
            sortable: false,
          },
          {
            text: "SRF NUMBER",
            value: "stock_request_form_number",
            sortable: false,
          },
          { text: "ACKNOWLEDGED BY", value: "ackowledge_by", sortable: false },
          { text: "SOURCE", value: "serving_warehouse", sortable: false },
          {
            text: "DESTINATION",
            value: "requesting_warehouse",
            sortable: false,
          },
          { text: "REMARKS", value: "transfer_remarks", sortable: false },
          {
            text: "STATUS DATETIME",
            align: "right",
            value: "status_date",
            sortable: false,
          },
          { text: "STATUS", value: "status_description", sortable: false },
          {
            text: "ACTIONS",
            align: "center",
            value: "action",
            sortable: false,
          },
        ],
        data: [],
        stockTransferDetailsId: null,
        preparedQuantityGrandTotal: 0,
      },
      selectedStatus: -2,

      search: "",
      isManager: false,
      filters: {
        status: {
          search: "",
          data: [
            { text: "ON GOING", value: -2 },
            { text: "ALL STATUSES", value: -1 },
            { text: "PREPARING", value: 1 },
            { text: "SHIPPING", value: 2 },
            { text: "CONFIRM RECEIVED", value: 3 },
          ],
          selection: [],
          selected: [],
        },
        warehouse: {
          search: "",
          data: [],
          selection: [],
          selected: [],
        },
      },

      pagination: {
        options: {},
        total: 0,
      },

      errors: [],
      editedIndex: -1,
      editing: false,
      editedStockTransfer: {
        stock_transfer_form_number: "",
        approved: 0,
        claimant: {
          warehouse: {},
          user: {},
        },
        details: [],
        id: null,
        latest_status: 0,
        latest_status_detail: {},
        remarks: null,
        shipper: {
          vehicle_id: null,
          driver: null,
          helpers: [],
        },
        editedShipper: {
          vehicle_id: null,
          driver: null,
          helpers: [],
        },
        status: [],
        warehouse_id: null,
        warehouse: {},
        user: {},
        confirm_received_on_device: null,
        // ship:
      },

      tab: null,
      tab1: null,

      inputHelper: {
        state: false,
        value: null,
      },
      dates: [],
      view_type: "Warehouse",
      STOCK_TRANSFER_STATUS: {
        FOR_APPROVAL: 0,
        PREPARING: 1,
        SHIPPING: 2,
        CONFIRM_RECEIVED: 3,
        SHIP_CANCELLED: 4,
      },
      STOCK_REQUEST_STATUS: {
        FOR_APPROVAL: 0,
        APPROVED: 1,
        PREPARING: 2,
        COMPLETED: 3,
        CANCELLED: 4,
        PENDING_VARIANCE: 5,
        READY_TO_RECEIVE: 6,
        READY_FOR_RECEIVING: 7,
      },

      STOCK_REQUEST_STATUS_CLASS: {
        0: "badge-gray",
        1: "badge-approved",
        2: "badge-partial",
        3: "badge-completed",
        4: "badge-purple",
        5: "badge-danger",
        6: "badge-primary",
        7: "badge-warning",
      },
      STOCK_TRANSFER_STATUS_DESCRIPTION: {
        0: "FOR APPROVAL",
        1: "PREPARING",
        2: "SHIPPING",
        3: "CONFIRM RECEIVED",
        4: "SHIPPING CANCELLED",
      },

      STOCK_TRANSFER_STATUS_DESCRIPTION: {
        0: "FOR APPROVAL",
        1: "PREPARING",
        2: "SHIPPING",
        3: "CONFIRM RECEIVED",
        4: "SHIPPING CANCELLED",
      },

      STOCK_TRANSFER_STATUS_CLASS: {
        0: "badge-pending",
        1: "badge-preparing",
        2: "badge-submitted",
        3: "badge-completed",
        4: "badge-converted",
      },

      STOCK_REQUEST_RECEIVABLE_STATUS_DESCRIPTION: {
        0: "FOR APPROVAL",
        1: "PREPARING",
        2: "SHIPPING",
        3: "CONFIRM RECEIVED",
        4: "SHIPPING CANCELLED",
      },

      STOCK_REQUEST_STATUS_DESCRIPTION: {
        0: "FOR APPROVAL",
        1: "APPROVED",
        2: "PREPARING",
        3: "COMPLETED",
        4: "CANCELLED",
        5: "PENDING VARIANCE",
        6: "READY TO RECEIVE",
        7: "READY FOR RECEIVING",
      },

      STOCK_REQUEST_TYPE: {
        PER_UOM: 0,
        MASTER_BOX: 1,
        SMALL_BOX: 2,
      },

      STOCK_REQUEST_TYPE_DESCRIPTION: {
        0: "PER UOM",
        1: "MASTER BOX",
        2: "SMALL BOX",
      },
      service: this.$router.currentRoute.meta.service,
    };
  },

  computed: {
    ...mapGetters([
      "GET_VEHICLES",
      "GET_TRANSFER_WAREHOUSE",
      "GET_ALL_REQUEST",
      "GET_STOCK_TRANSFER",
      "GET_REQUEST_DETAILS",
      "GET_TRANSFER_DETAILS",
      "GET_ALL_WAREHOUSES",
    ]),
    warehousesOtherThanRequesting() {
      let that = this;
      let selection = [];

      if (!that.stockRequests.selected.length) {
        selection = that.warehouse.selection;
      } else {
        selection = that.warehouse.selection.filter(
          (warehouse) =>
            parseInt(warehouse.value) !==
            parseInt(that.stockRequests.selected[0].warehouse_id)
        );
      }

      return selection;
    },
  },

  directives: {
    focus(el, { value }) {
      if (value) {
        el.focus();

        if (el.type !== "number") {
          el.setSelectionRange(0, el.value.trim().length);
        }
      }
    },
  },

  methods: {
    getWarehouseRequest() {
      let that = this;

      this.$store.dispatch("getTransferWarehouse").then((response) => {
        let selection = this.GET_TRANSFER_WAREHOUSE.data.reduce(
          (selection, warehouse) => {
            selection.push({
              value: warehouse.value,
              text: warehouse.name,
            });

            return selection;
          },
          []
        );
        that.warehouse.vTabOptions = this.GET_TRANSFER_WAREHOUSE.data;

        that.selectedStatus = that.filters.status.selection
          .map((object) => object.value)
          .indexOf(selection[0].value);
        that.filters.warehouse.selected = [selection[0].value];
        // that.selectedStatus = selection[0].value
        that.tab1 = 0;
        that.list();
      });
    },

    selectedAllFilterModuleOptions(selection, selected) {
      return selected.length === selection.length;
    },

    selectedSomeFilterModuleOptions(selection, selected) {
      return (
        selected.length > 0 &&
        !this.selectedAllFilterModuleOptions(selection, selected)
      );
    },
    varianceAutoCompute(toBePrepare, detail, type) {
      if (type == 0) {
        return detail.remaining_uom_quantity - toBePrepare < 0
          ? (detail.remaining_uom_quantity - toBePrepare) * -1
          : detail.remaining_uom_quantity - toBePrepare;
      } else if (type == 1) {
        return detail.remaining_uom_quantity * detail.master_box_qty -
          toBePrepare <
          0
          ? (toBePrepare * detail.master_box_qty - toBePrepare) * -1
          : detail.remaining_uom_quantity * detail.master_box_qty - toBePrepare;
      } else if (type == 2) {
        return detail.remaining_uom_quantity * detail.small_box_qty -
          toBePrepare <
          0
          ? (toBePrepare * detail.small_box_qty - toBePrepare) * -1
          : detail.remaining_uom_quantity * detail.small_box_qty - toBePrepare;
      }
    },

    filterSelectionIcon(selection, selected) {
      if (this.selectedAllFilterModuleOptions(selection, selected))
        return "mdi-close-box";
      if (this.selectedSomeFilterModuleOptions(selection, selected))
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    toggleFilterSelection(filter) {
      this.$nextTick(() => {
        if (
          this.selectedAllFilterModuleOptions(filter.selection, filter.selected)
        ) {
          filter.selected = [];
        } else {
          filter.selected = filter.selection.map((filter) => filter.value);
        }
      });
    },

    searchFilterSelection(filter) {
      if (!filter.search) {
        filter.selection = filter.data;
      }

      filter.selection = filter.data.filter((data) => {
        return (
          data.text.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
        );
      });
    },

    downloadPdf(stockTransfer) {
      let doc = new jsPDF("p", "mm", "letter");
      let fileName = `WAREHOUSE FORM.pdf`;
      let vehicle = stockTransfer.shipper
        ? `${stockTransfer.shipper.vehicle.name} [${stockTransfer.shipper.vehicle.plate_number}]`
        : "";

      let driver = stockTransfer.shipper
        ? `${stockTransfer.shipper.driver}`
        : "";

      let helpers = stockTransfer.shipper
        ? `${stockTransfer.shipper.helpers.join(", ")}`
        : "";

      doc.setFontSize(12);
      doc.autoTable({
        theme: "plain",
        styles: {
          lineWidth: 0.1,
          lineColor: "#7E7E7E",
        },
        headStyles: {
          fillColor: false,
          textColor: "#7E7E7E",
        },
        bodyStyles: {
          fillColor: false,
          textColor: "#7E7E7E",
        },
        columnStyles: { 0: { fontStyle: "bold" }, 2: { halign: "right" } },
        columns: [
          { header: "MODELL", dataKey: "model" },
          { header: "DESCRIPTION", dataKey: "name" },
          { header: "QTY By BOX", dataKey: "remaining_uom_quantity" },
          { header: "QTY By PC/UNIT", dataKey: "prepared_uom_quantity" },
        ],
        body: stockTransfer.details.reduce((row, detail) => {
          let preparation = Object.assign(
            {},
            {
              model: detail.model,
              name: detail.name,
              remaining_uom_quantity: detail.remaining_uom_quantity,
            }
          );

          if (includeIncapable) {
            row.push(preparation);
          } else {
            if (
              detail.serving_warehouse_inventory >=
              detail.remaining_uom_quantity
            ) {
              row.push(preparation);
            }
          }

          return row;
        }, []),
      });
    },

    resetForm() {
      let that = this;

      that.warehouse.value = null;
      that.vehicles.value = null;

      that.editedStockTransfer = {
        ...Object.assign(
          {},
          {
            approved: 0,
            claimant: {
              warehouse: {},
              user: {},
            },
            details: [],
            id: null,
            latest_status: 0,
            latest_status_detail: {},
            remarks: null,
            shipper: {
              vehicle_id: null,
              driver: null,
              helpers: [],
            },
            editedShipper: {
              vehicle_id: null,
              driver: null,
              helpers: [],
            },
            status: [],
            warehouse_id: null,
            warehouse: {},
            user: {},
            confirm_received_on_device: null,
          }
        ),
      };
    },

    closeDialog() {
      let that = this;
      that.editedIndex = -1;
      if (that.loading.state) {
        return false;
      }

      that.errors = [];
      that.dialog = false;
      that.editing = false;
      that.resetForm();
    },

    openDialog() {
      let that = this;

      if (that.loading.state) {
        return false;
      }

      that.dialog = true;
    },

    toggleLoading() {
      this.loading.state = !this.loading.state;
    },

    getWarehouses(no_virtual = false) {
      let payload = {
        no_virtual: no_virtual,
      };

      this.$store.dispatch("getAllWarehouses", payload).then((response) => {
        this.warehouse.selection = this.GET_ALL_WAREHOUSES;

        let selection = this.GET_ALL_WAREHOUSES.reduce(
          (selection, warehouse) => {
            selection.push({
              value: warehouse.value,
              text: warehouse.name,
              code: warehouse.code,
            });

            return selection;
          },
          []
        );
        // this.warehouse.vTabOptions = this.warehouse.selection.slice(1,this.warehouse.selection.length)
        this.filters.warehouse.selection = selection;
        this.filters.warehouse.data = selection;
        this.warehouse.vTabOptions = selection;
      });
    },

    getFilteredValues(filter = 0, status = -2) {
      let that = this;

      if (that.view_type == "Warehouse") {
        that.filters.warehouse.selected = [filter.value];
        that.date = null;
      } else {
        that.date = filter.date;
        that.filters.warehouse.selected = null;
      }

      that.filters.status.selected = [status];
      this.list();
    },

    getVehicles() {
      this.$store.dispatch("getVehicle").then((response) => {
        this.vehicles.selection = [
          {
            id: null,
            name: "SELECT VEHICLE",
          },
        ].concat(this.GET_VEHICLES);
      });
    },

    getStockRequests(page = 1) {
      let that = this;

      that.stockRequests.datatable.pagination.options.page = page;

      if (that.stockRequests.loading) {
        return false;
      }

      that.stockRequests.loading = true;
      let payload = {
        page: page,
        itemsPerPage:
          that.stockRequests.datatable.pagination.options.itemsPerPage ||
          that.stockRequests.datatable.pagination.perPageDefault,
        srfNumber: that.stockRequests.datatable.search,
      };

      this.$store.dispatch("getAllRequest", payload).then((response) => {
        this.isManager = that.GET_ALL_REQUEST.isManager;
        that.stockRequests.data = that.GET_ALL_REQUEST.stock_requests.data;
        that.stockRequests.datatable.pagination.total =
          that.GET_ALL_REQUEST.stock_requests.total;
        that.stockRequests.loading = false;
      });
    },

    pickStockRequests() {
      let that = this;

      that.resetStockRequests();
      that.toggleStockRequests();
      that.getStockRequests();
    },

    resetStockRequests() {
      let that = this;
      that.stockRequests.data = [];
      that.stockRequests.selected = [];
    },

    toggleStockRequests() {
      let that = this;
      that.stockRequests.dialog = !that.stockRequests.dialog;
    },

    async list(page = 1) {
      let that = this;

      that.pagination.options.page = page;

      if (that.filters.status.selected[0] == -1) {
        that.filters.status.selected = [
          that.STOCK_REQUEST_STATUS.FOR_APPROVAL,
          that.STOCK_REQUEST_STATUS.APPROVED,
          that.STOCK_REQUEST_STATUS.PREPARING,
          that.STOCK_REQUEST_STATUS.COMPLETED,
          that.STOCK_REQUEST_STATUS.CANCELLED,
          that.STOCK_REQUEST_STATUS.PENDING_VARIANCE,
          that.STOCK_REQUEST_STATUS.READY_TO_RECEIVE,
          that.STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
        ];
      }

      if (that.filters.status.selected[0] == -2) {
        that.filters.status.selected = [
          that.STOCK_REQUEST_STATUS.FOR_APPROVAL,
          that.STOCK_REQUEST_STATUS.APPROVED,
          that.STOCK_REQUEST_STATUS.PREPARING,
          that.STOCK_REQUEST_STATUS.CANCELLED,
          that.STOCK_REQUEST_STATUS.PENDING_VARIANCE,
          that.STOCK_REQUEST_STATUS.READY_TO_RECEIVE,
          that.STOCK_REQUEST_STATUS.READY_FOR_RECEIVING,
        ];
      }

      if (that.loading.state) {
        return false;
      }

      that.toggleLoading();
      let payload = {
        page: page,
        itemsPerPage: that.pagination.options.itemsPerPage,
        stfNumber: that.search,
        status: that.filters.status.selected,
        warehouse_id: that.filters.warehouse.selected,
        stockTransferDetailsId: that.stockTransfers.stockTransferDetailsId,
        date: that.date,
      };

      await this.$store.dispatch("stockTransfer", payload).then((response) => {
        that.isManager = that.GET_STOCK_TRANSFER.isManager;
        that.stockTransfers.data = that.GET_STOCK_TRANSFER.stock_transfers.data;
        that.pagination.total = that.GET_STOCK_TRANSFER.stock_transfers.total;
        that.toggleLoading();
      });
    },

    printItemPreparation(stockTransfer, includeIncapable = true) {

        let pdf = new jsPDF("p", "mm", "letter");
        let date = new Date("YYYY");
        let black = "black";

        let formNumberSpaced = stockTransfer.stock_transfer_form_number;
        let formNumber = stockTransfer.stock_transfer_form_number
            .replace(" ", "")
            .replace("#", "");
        let fileName = `${formNumber}.pdf`;

        const itemsPerPage = 10;
        const totalItems = stockTransfer.details.length;

        for (let page = 1; page <= Math.ceil(totalItems / itemsPerPage); page++) {
             // Add a new page for each set of 10 items
            let vehicle = stockTransfer.shipper
            ? `${stockTransfer.shipper.vehicle.name} [${stockTransfer.shipper.vehicle.plate_number}]`
            : "";

            let driver = stockTransfer.shipper
            ? `${stockTransfer.shipper.driver}`
            : "";
            let helpers =
            stockTransfer.shipper.helpers.length > 0
                ? `${stockTransfer.shipper.helpers.join(",")}`
                : "";
            let stfNumer = "WTF:" + stockTransfer.stock_transfer_form_number;

            pdf.setFontSize(12);

            pdf.text(stfNumer, 160, 10, "left");
            let ingcoPhLogo = new Image();
            ingcoPhLogo.src = "/images/ingcoph-logo.png";
            pdf.addImage(ingcoPhLogo, "png", 55, 25, 90, 20);

            pdf.setFont(undefined, "bold");
            pdf.text("WAREHOUSE TRANSMITTAL FORM", 100, 53, "center");
            pdf.text(`FROM:`, 10, 65);
            pdf.text(`TO:`, 120, 65);
            pdf.text(`DATE:`, 10, 72);
            pdf.text(`ID#:`, 90, 72);
            pdf.text(`RF#:`, 150, 72);
            pdf.text(`TRUCK:`, 10, 79);
            pdf.text(`DRIVER:`, 90, 79);
            pdf.text(`PAHENANTE:`, 150, 79);

            pdf.setFont(undefined, "normal");
            pdf.text(stockTransfer.serving_warehouse, 25, 65);
            pdf.text(stockTransfer.requesting_warehouse, 130, 65);

            pdf.text(moment().format("YYYY-MM-DD").toString(), 28, 72);
            pdf.text(stockTransfer.id.toString(), 100, 72);
            pdf.text(stockTransfer.rf_number ?? '', 160, 72);

            pdf.text(`${stockTransfer.shipper.vehicle.name}`, 28, 79);
            pdf.text(stockTransfer.shipper.driver, 110, 79);
            pdf.text(helpers, 180, 79);


            const startIdx = (page - 1) * itemsPerPage;
            const endIdx = startIdx + itemsPerPage;
            const itemsOnPage = stockTransfer.details.slice(startIdx, endIdx);

            pdf.autoTable({
            theme: "plain",
            styles: {
                lineWidth: 0.1,
                lineColor: "black",
            },
            headStyles: {
                fillColor: "yellow",
                textColor: "black",
                textType: "bold",
                headerBackgroundColor: "#F7FF00",
            },
            bodyStyles: {
                fillColor: false,
                textColor: "#7E7E7E",
            },
            margin: { top: 90, left: 7, right: 6 },
            columnStyles: {
                0: { fontStyle: "bold" },
                2: { halign: "right" },
                0: { textColor: black },
                1: { textColor: black },
                2: { textColor: black },
                3: { textColor: black },
                4: { textColor: black },
            },
            columns: [
                { header: "MODEL", dataKey: "model" },
                { header: "DESCRIPTION", dataKey: "name" },
                { header: "Item Location", dataKey: "item_location" },
                { header: "QTY By BOX", dataKey: "prepared_master_box" },
                { header: "QTY By PC/UNIT", dataKey: "prepared_uom_quantity" },
                { header: "RECEIVED QTY", dataKey: "received_qty" },
            ],
            body: itemsOnPage.reduce((row, detail) => {
                let prepared_master_box = "";
                if (detail.cancelled == true) {
                prepared_master_box = "Cancelled";
                } else {
                prepared_master_box =
                    (detail.prepared_uom_quantity / detail.master_box_qty) % 1 != 0
                    ? (
                        detail.prepared_uom_quantity / detail.master_box_qty
                        ).toFixed(2)
                    : detail.prepared_uom_quantity / detail.master_box_qty;
                }

                let preparation = Object.assign(
                {},
                {
                    model: detail.model,
                    name: detail.name,
                    item_location: detail.item_location,
                    prepared_master_box:
                    detail.cancelled == true ? 0 : Math.ceil(prepared_master_box),
                    prepared_uom_quantity:
                    detail.cancelled == true
                        ? "Cancelled"
                        : detail.prepared_uom_quantity.toString(),
                    received_qty: detail.cancelled == true ? "Cancelled" : "",
                }
                );
                row.push(preparation);

                return row;
            }, []),
            });

            pdf.setTextColor("black");
            pdf.setFontSize(10);
            pdf.text(
                `Page ${page} of ${Math.ceil(totalItems / itemsPerPage)}`,
                14,
                pdf.internal.pageSize.height - 7
            );

            pdf.setPage(page);

            if(page != Math.ceil(totalItems / itemsPerPage)){
              pdf.addPage();
            }

        }

        if(stockTransfer.service == 1){
        pdf.text("PREPARED BY :", 10, 220, "left");
        pdf.text("______________________________", 10, 235, "left");
        pdf.text("RECEIVED/DOUBLE CHECKED BY:", 10, 250, "left");
        pdf.text("______________________________", 10, 265, "left");
        pdf.text("CHECKED BY:", 100, 220, "left");
        pdf.text("______________________________", 100, 235, "left");
        pdf.text("DATE CODED:", 100, 250, "left");
        pdf.text("______________________________", 100, 265, "left");
        }else{
        pdf.text("TRANSFERED BY :", 10, 220, "left");
        pdf.text("______________________________", 10, 235, "left");
        pdf.text("RECEIVED/DOUBLE CHECKED BY:", 10, 250, "left");
        pdf.text("______________________________", 10, 265, "left");
        pdf.text("ENCODED BY:", 100, 220, "left");
        pdf.text("______________________________", 100, 235, "left");
        pdf.text("DATE CODED:", 100, 250, "left");
        pdf.text("______________________________", 100, 265, "left");
        }

        pdf.save(fileName);

    },

    async create(stockRequest) {
      let that = this;
      if (that.loading.state) {
        return false;
      }

      let item_details = [];
      let payload = {
        id: stockRequest.id,
        params: {
          serving_warehouse_id: stockRequest.initial_serving_warehouse_id,
          requesting_warehouse_id: stockRequest.requesting_warehouse_id,
          status:stockRequest.status,
          fromTransfer:1
        },
      };

      await this.$store.dispatch("requestDetails", payload).then((response) => {
        item_details = this.GET_REQUEST_DETAILS.data;
      });

      let stockTransfer = Object.assign(
        {},
        {
          stock_request_id: stockRequest.id,
          warehouse_id: stockRequest.initial_serving_warehouse_id,
          approved: 1,
          details: item_details.reduce((details, detail) => {
            let remaining = detail.remaining_transfer;
            if (remaining > 0) {
              details.push(
                Object.assign(
                  {},
                  {
                    item_id: detail.item_id,
                    remaining_uom_quantity: remaining,
                    approved: 1,
                  }
                )
              );
            }

            return details;
          }, []),
        }
      );

      that.toggleLoading();
      this.$store
        .dispatch("transferCreate", stockTransfer)
        .then((response) => {
          if (response.data.exist) {
            swal.fire("Already exist", response.data.message, "error");
            that.toggleLoading();
            return false;
          }
          swal.fire({
            title: `Stock Request Acknowledged : ${response.data.stock_transfer.stock_transfer_form_number}`,
            text: "Stock transfer is now waiting for shipment.",
          });

          that.resetForm();
          that.errors = [];
          that.toggleLoading();
          that.toggleStockRequests();
          that.list();
        })
        .catch((error) => {
          that.errors = error.response.data.errors;

          let errorMessages = [];

          Object.keys(that.errors).forEach((key) => {
            if (key === "authorization") {
              that.closeDialog();
            }

            that.errors[key].forEach((message) => {
              errorMessages.push(`- ${message}`);
            });
          });

          swal.fire({
            title: error.response.data.message,
            text: errorMessages.join("\n"),
            icon: "warning",
            dangerMode: true,
          });

          that.toggleLoading();
        });
    },

    async getStockRequests(page = 1) {
      let that = this;

      that.stockRequests.datatable.pagination.options.page = page;

      if (that.stockRequests.loading) {
        return false;
      }

      that.stockRequests.loading = true;
      let payload = {
        page: page,
        itemsPerPage:
          that.stockRequests.datatable.pagination.options.itemsPerPage ||
          that.stockRequests.datatable.pagination.perPageDefault,
        srfNumber: that.stockRequests.datatable.search,
        status: [7],
      };


      await that.$store.dispatch('getAllStockRequestId',payload).then(response =>{
        that.stockRequests.datatable.pagination.total = response.data.stockRequestId.length
        let ids = this.arraySlice(response.data.stockRequestId,that.pagination.options.itemsPerPage)
        let stockRequestIds = ids[page -1]
        let payload = {
            stockRequestIds: stockRequestIds,
        }
        if(payload.stockRequestIds == undefined){
            that.stockRequests.loading = false;
        }
        else{
            that.$store.dispatch('getAllRequest',payload).then((response) => {
                that.isManager              = response.data.isManager;
                that.stockRequests.data     = response.data.stockRequestId;
                that.stockRequests.loading = false;
            });
        }

    })
    },

    approve(stockTransfer) {
      if (!stockTransfer) {
        return false;
      }

      swal
        .fire({
          title: `Confirm Stock Transfer Approve : ${stockTransfer.stock_transfer_form_number}`,
          text: ``,
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "grey",
          confirmButtonText: "Confirm",
          showCloseButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let that = this;

            if (that.loading.state) {
              return false;
            }
            that.toggleLoading();

            this.$store
              .dispatch("transferApprove", stockTransfer.id)
              .then((response) => {
                swal.fire({
                  title: `Stock Transfer Approved : ${stockTransfer.stock_transfer_form_number}`,
                  text: "Stock Transfer is now ready for preparation, you can now input prepared item count upon editting.",
                });
                that.resetForm();
                that.errors = [];
                that.dialog = false;
                that.list();
                that.toggleLoading();
                that.getWarehouses();
              })
              .catch((error) => {
                that.errors = error.response.data.errors;

                let errorMessages = [];

                Object.keys(that.errors).forEach((key) => {
                  that.errors[key].forEach((message) => {
                    errorMessages.push(`- ${message}`);
                  });
                });

                swal.fire({
                  title: error.response.data.message,
                  text: errorMessages.join("\n"),
                  icon: "warning",
                  dangerMode: true,
                });
              });
          }
        });
    },

    async edit(stockTransfer) {
      let that = this;

      that.editing = true;
      await that.view(stockTransfer);
    },

    update() {
      let that = this;

      if (that.loading.state) {
        return false;
      }

      that.toggleLoading();

      let servingQuantityIsHigherThanInventory =
        that.editedStockTransfer.details.filter(
          (detail) =>
            detail.prepared_uom_quantity > detail.serving_warehouse_inventory
        ).length;

      that.stockTransfers.preparedQuantityGrandTotal =
        that.editedStockTransfer.details.reduce((sum, detail) => {
          detail.prepared_uom_quantity = that.stockRequestTotalUomComputer(
            detail,
            detail.request_type,
            detail.request_type_quantity
          );
          sum += parseInt(detail.prepared_uom_quantity);
          return sum;
        }, 0);
      that.stockTransfers.preparedQuantityGrandTotal;
      if (that.stockTransfers.preparedQuantityGrandTotal < 1) {
        that.alertWarning(`Must Prepare at least 1 item.`);
        that.toggleLoading();
        return false;
      }

      if (servingQuantityIsHigherThanInventory) {
        that.alertWarning(
          `Prepared Quantity Cannot be Greater than Warehouse Inventory`
        );
        that.toggleLoading();
        return false;
      }

      let stockTransfer = Object.assign(
        {},
        {
          ...that.editedStockTransfer,
          remarks: that.editedStockTransfer.transfer_remarks,
          shipper: {
            driver: that.editedStockTransfer.editedShipper.driver,
            helpers: that.editedStockTransfer.editedShipper.helpers,
            vehicle_id: that.vehicles.value,
            remarks: that.editedStockTransfer.transfer_remarks,
            id: that.editedStockTransfer.id,
          },
          details: that.editedStockTransfer.details.reduce(
            (details, detail) => {
              if (detail.request_type == 1) {
                details.push(
                  Object.assign(
                    {},
                    {
                      id: detail.id,
                      prepared_uom_quantity:
                        detail.request_type_quantity * detail.master_box_qty,
                      prepared_master_box_quantity:
                        detail.request_type_quantity,
                      // prepared_small_box_quantity:    (detail.request_type_quantity * detail.master_box_qty)/detail.small_box_qty,
                      remarks:
                        detail.remaining_uom_quantity -
                          detail.request_type_quantity ==
                        0
                          ? ""
                          : detail.remarks,
                      variance:
                        detail.remaining_uom_quantity -
                        detail.request_type_quantity * detail.master_box_qty,
                      cancelled: detail.cancelled,
                    }
                  )
                );
              } else if (detail.request_type == 2) {
                details.push(
                  Object.assign(
                    {},
                    {
                      id: detail.id,
                      prepared_uom_quantity:
                        detail.request_type_quantity * detail.small_box_qty,
                      prepared_master_box_quantity:
                        (detail.request_type_quantity * detail.small_box_qty) /
                        detail.master_box_qty,
                      // prepared_small_box_quantity:    detail.request_type_quantity,
                      remarks:
                        detail.remaining_uom_quantity -
                          detail.request_type_quantity ==
                        0
                          ? ""
                          : detail.remarks,
                      // variance:                       detail.remaining_uom_quantity - (detail.request_type_quantity * detail.small_box_qty),
                      cancelled: detail.cancelled,
                    }
                  )
                );
              } else {
                details.push(
                  Object.assign(
                    {},
                    {
                      id: detail.id,
                      prepared_uom_quantity: detail.request_type_quantity,
                      prepared_master_box_quantity:
                        detail.request_type_quantity / detail.master_box_qty,
                      // prepared_small_box_quantity:    detail.request_type_quantity / detail.small_box_qty,
                      remarks:
                        detail.remaining_uom_quantity -
                          detail.request_type_quantity ==
                        0
                          ? ""
                          : detail.remarks,
                      variance:
                        detail.remaining_uom_quantity -
                        detail.request_type_quantity,
                      cancelled: detail.cancelled,
                    }
                  )
                );
              }

              return details;
            },
            []
          ),
        }
      );
      let checkRemarks = false;
      stockTransfer.details.every((item) => {
        if (!item.remarks && item.variance != 0 && !item.cancelled) {
          checkRemarks = false;
          return false;
        } else {
          checkRemarks = true;
          return true;
        }
      });

      if (!checkRemarks) {
        swal.fire(
          "Remarks Needed",
          "Please include remarks on the uncompleted items",
          "error"
        );
        that.toggleLoading();
        return false;
      }
      let payload = {
        id: stockTransfer.id,
        stockTransfer: stockTransfer,
      };

      this.$store
        .dispatch("transferUpdate", payload)
        .then((response) => {
          swal.fire({
            title: `Stock Transfer Updated : ${response.data.stock_transfer_form_number}`,
            text: "Shipping information and items updated.",
          });

          that.dialog = false;
          that.editing = false;
          that.resetForm();
          that.errors = [];
          that.toggleLoading();
          that.list();
        })
        .catch((error) => {
          that.errors = error.response.data.errors;

          let errorMessages = [];

          Object.keys(that.errors).forEach((key) => {
            if (key === "authorization") {
              that.closeDialog();
            }

            that.errors[key].forEach((message) => {
              errorMessages.push(`- ${message}`);
            });
          });

          swal.fire({
            title: error.response.data.message,
            text: errorMessages.join("\n"),
            icon: "warning",
            dangerMode: true,
          });
          that.toggleLoading();
        });
    },

    destroy(stockTransfer) {
      swal
        .fire({
          title: "Confirm Delete",
          text: `${stockTransfer.stock_transfer_form_number}`,
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "grey",
          confirmButtonText: "Confirm",
          showCloseButton: true,
        })
        .then((result) => {
          if (result) {
            let that = this;

            if (that.loading.state) {
              return false;
            }

            that.toggleLoading();

            that.$store
              .dispatch("transferDestroy", stockTransfer.id)
              .then((response) => {
                swal.fire({ title: "Stock Transfer Deleted." });
                that.dialog = false;
                that.editing = false;
                that.resetForm();
                that.errors = [];
                that.toggleLoading();
                that.list();
              })
              .catch((error) => {
                that.errors = error.response.data.errors;

                let errorMessages = [];

                Object.keys(that.errors).forEach((key) => {
                  that.errors[key].forEach((message) => {
                    errorMessages.push(`- ${message}`);
                  });
                });

                swal.fire({
                  title: error.response.data.message,
                  text: errorMessages.join("\n"),
                  icon: "warning",
                  dangerMode: true,
                });

                that.toggleLoading();
              });
          }
        });
    },

    alertWarning(message) {
      swal.fire({
        title: "Unprocessable",
        text: message,
        icon: "warning",
        dangerMode: true,
      });
    },

    async view(stockTransfer) {
      let that = this;
      that.dialog = true;
      that.editedIndex = that.stockTransfers.data.indexOf(stockTransfer);
      that.toggleLoading();
      let payload = {
        id: stockTransfer.id,
        params: {
          serving_warehouse_id: stockTransfer.serving_warehouse_id,
          requesting_warehouse_id: stockTransfer.requesting_warehouse_id,
          stock_request_id: stockTransfer.sr_id,
          status: stockTransfer.status_description,
        },
      };
      await that.$store
        .dispatch("transferDetails", payload)
        .then((response) => {
          that.editedStockTransfer.details = response.data.details.filter(
            (e) => {
              e.remaining_master_box_quantity =
                e.remaining_uom_quantity / e.master_box_qty;
              e.prepared_master_box_quantity =
                e.prepared_uom_quantity / e.master_box_qty;
              e.received_master_box_quantity =
                e.received_uom_quantity / e.master_box_qty;
              e.cancelled_master_box_quantity =
                e.cancelled_uom_quantity / e.master_box_qty;
              e.latest_status = stockTransfer.status_description;
              e.request_type = stockTransfer.allow_butal == 1 ? 0 : 1;
              e.request_type_quantity = stockTransfer.isService == 1
                ? e.remaining_uom_quantity
                : 0;
              e.remarks = "";
              e.cancelled = e.status == "Cancelled" ? true : false;
              return e;
            }
          );

          let ob = { name: "", plate_number: "" };
          that.vehicles.value =  response.data.shipper.length > 0 ? response.data.shipper[0].vehicle_id : null;
          that.editedStockTransfer.editedShipper.driver = response.data.shipper.length > 0 ? response.data.shipper[0].driver : '';
          that.editedStockTransfer.id = stockTransfer.id;
          that.editedStockTransfer.shipper.driver =
            response.data.shipper.length > 0
              ? response.data.shipper[0].driver
              : "";
          that.editedStockTransfer.shipper.vehicle =
            response.data.shipper.length > 0
              ? response.data.shipper[0].vehicle[0]
              : ob;
          that.editedStockTransfer.shipper.helpers =
            response.data.shipper.length > 0
              ? response.data.shipper[0].helpers
              : [];
          that.editedStockTransfer.remarks = stockTransfer.remarks;
          that.editedStockTransfer.requesting_warehouse =
            stockTransfer.requesting_warehouse;
          that.editedStockTransfer.serving_warehouse =
            stockTransfer.serving_warehouse;
          that.editedStockTransfer.confirm_received_on_device =
            stockTransfer.confirm_received_on_device;
          that.editedStockTransfer.acknowledged = stockTransfer.acknowledged;
          that.editedStockTransfer.stock_request_form_number =
            stockTransfer.stock_request_form_number;
          that.editedStockTransfer.stock_transfer_form_number =
            stockTransfer.stock_transfer_form_number;
          that.editedStockTransfer.requested_by = stockTransfer.requested_by;
          that.editedStockTransfer.latest_status =
            stockTransfer.status_description;
          that.editedStockTransfer.serving_warehouse_code =
            stockTransfer.serving_warehouse_code;
          that.editedStockTransfer.expected_date = stockTransfer.expected_date;
          that.editedStockTransfer.transfer_remarks =
            stockTransfer.transfer_remarks;
          that.editedStockTransfer.claimant = response.data.claimant;
          that.editedStockTransfer.service = stockTransfer.isService;
          that.editedStockTransfer.rf_number = stockTransfer.rf_number;

          if (that.editedStockTransfer.shipper.helpers.length > 0) {
            that.editedStockTransfer.shipper.helpers = JSON.parse(
              that.editedStockTransfer.shipper.helpers
            );
          }
        });

      that.toggleLoading();
    },

    enableShipIf(stockTransfer) {
      let isEitherPreparingOrShipCancelled =
        [
          this.STOCK_TRANSFER_STATUS.PREPARING,
          this.STOCK_TRANSFER_STATUS.SHIP_CANCELLED,
        ].indexOf(stockTransfer.latest_status) >= 0;

      let preparedQuantityGrandTotal = stockTransfer.details.reduce(
        (sum, detail) => {
          sum += parseInt(detail.prepared_uom_quantity);
          return sum;
        },
        0
      );

      return isEitherPreparingOrShipCancelled && preparedQuantityGrandTotal > 0;
    },

    ship(stockTransfer, cancelledItem) {
      let that = this;

      if (that.loading.state) {
        return false;
      }

      let cancelStatusArray = cancelledItem.map((e) => {
        e.cancelled;
      });

      if (cancelStatusArray.includes(false)) {
        swal.fire(
          "Process denied",
          "Please let your manager ship the items",
          "error"
        );
        return false;
      }

      stockTransfer.details = cancelledItem.filter((e) => {
        return e.cancelled == false;
      });

      swal
        .fire({
          title: `Confirm Stock Transfer Ship : ${stockTransfer.stock_transfer_form_number}`,
          text: ``,
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          confirmButtonColor: "#397373",
          cancelButtonColor: "grey",
          confirmButtonText: "Confirm",
        })
        .then((confirm) => {
          if (confirm.isConfirmed) {
            that.toggleLoading();

            let payload = {
              id: stockTransfer.id,
              params: {
                warehouse: stockTransfer.serving_warehouse_id,
                details: stockTransfer.details,
              },
            };
            this.$store
              .dispatch("transferShip", payload)
              .then((response) => {
                swal.fire({
                  title: `Stock Transfer Shipped : ${response.data.stock_transfer_form_number}`,
                  text: "Stock transfer is now shipping.",
                });

                that.dialog = false;
                that.editing = false;
                that.resetForm();
                that.errors = [];
                that.toggleLoading();
                that.list();
              })
              .catch((error) => {
                that.errors = error.response.data.errors;

                let errorMessages = [];

                Object.keys(that.errors).forEach((key) => {
                  if (key === "authorization") {
                    that.closeDialog();
                  }

                  that.errors[key].forEach((message) => {
                    errorMessages.push(`- ${message}`);
                  });
                });

                swal.fire({
                  title: error.response.data.message,
                  text: errorMessages.join("\n"),
                  icon: "warning",
                  dangerMode: true,
                });
                that.toggleLoading();
              });
          }
        });
    },

    cancelShip(stockTransfer) {
      let that = this;

      if (that.loading.state) {
        return false;
      }

      that.toggleLoading();

      that.$store
        .dispatch("transferRecall", stockTransfer.id)
        .then((response) => {
          swal.fire({
            title: `Stock Transfer Shipping Cancelled : ${response.data.stock_transfer_form_number}`,
            text: "Shipping aborted.",
          });

          that.dialog = false;
          that.editing = false;
          that.resetForm();
          that.errors = [];
          that.toggleLoading();
          that.list();
        })
        .catch((error) => {
          that.errors = error.response.data.errors;

          let errorMessages = [];

          Object.keys(that.errors).forEach((key) => {
            if (key === "authorization") {
              that.closeDialog();
            }

            that.errors[key].forEach((message) => {
              errorMessages.push(`- ${message}`);
            });
          });

          swal.fire({
            title: error.response.data.message,
            text: errorMessages.join("\n"),
            icon: "warning",
            dangerMode: true,
          });
          that.toggleLoading();
        });
    },

    formatNumber(num) {
      let num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },

    pressQuantity(event, index, detail) {
      let charCode = event.which ? event.which : event.keyCode;

      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    checkQuantity(index, item, detail) {
      let that = this;
      let checker =
        that.editedStockTransfer.claimant.allow_butal == 1
          ? 0
          : detail.request_type;

      if (checker == 1) {
        if (
          parseInt(detail.request_type_quantity) >
          parseInt(
            item[index].remaining_uom_quantity / item[index].master_box_qty
          )
        ) {
          this.editedStockTransfer.details[index].request_type_quantity =
            this.editedStockTransfer.details[index].remaining_uom_quantity /
            item[index].master_box_qty;
          swal.fire(
            "Not allowed",
            "- Cannot ship quantity more than requested",
            "warning"
          );
        }
      } else if (checker == 0) {
        if (
          parseInt(detail.request_type_quantity) >
          parseInt(item[index].remaining_uom_quantity)
        ) {
          this.editedStockTransfer.details[index].request_type_quantity =
            this.editedStockTransfer.details[index].remaining_uom_quantity /
            item[index].master_box_qty;
          swal.fire(
            "Not allowed",
            "- Cannot ship quantity more than requested",
            "warning"
          );
        }
      }
    },

    blurQuantity(list, index, key) {
      let quantity = list[index][key];

      list[index][key] =
        !isNaN(quantity) && quantity && Math.abs(parseInt(quantity)) > 0
          ? Math.abs(parseInt(quantity))
          : 0;
    },

    stockRequestTotalUomComputer(item, requestType, requestQuantity) {
      let total = 0;

      switch (requestType) {
        case this.STOCK_REQUEST_TYPE.PER_UOM:
          total = requestQuantity;
          break;
        case this.STOCK_REQUEST_TYPE.MASTER_BOX:
          total = requestQuantity * item.master_box_qty;
          break;
        case this.STOCK_REQUEST_TYPE.SMALL_BOX:
          total = requestQuantity * item.small_box_qty;
          break;
        // if user input is uom
        case 5:
          total = requestQuantity / item.master_box_qty;
          break;
        // if user input is MB
        case 6:
          total = requestQuantity;
          break;
      }

      return total;
    },

    removeHelper(index) {
      let that = this;
      that.editedStockTransfer.editedShipper.helpers.splice(index, 1);
    },

    addHelper(event) {
      let that = this;

      let helper = event.target.value;

      that.editedStockTransfer.editedShipper.helpers.push(helper);

      this.inputHelper.state = false;
    },
    async removeItem(
      editedStockRequest,
      stockTransferDetailsId,
      itemName,
      index,
      detail
    ) {
      await swal
        .fire({
          title: `Confirm Cancelation of item: ${itemName} \nfrom ${editedStockRequest.stock_transfer_form_number}`,
          text: ``,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((confirm) => {
          if (confirm) {
            detail[index].cancelled = !detail[index].cancelled;
            detail[index].request_type_quantity = 0;
            detail[index].remarks = "";

            this.$store
              .dispatch("transferRemoveItem", stockTransferDetailsId)
              .then((response) => {
                if (response.status == 200) {
                  swal.fire(
                    `Success` + detail[index].cancelled ? "Removed" : "Added",
                    response.data.message,
                    "success"
                  );
                  this.$forceUpdate();
                } else {
                  swal.fire("An Erro occured", "", "error");
                }
              })
              .catch((e) => {});
          }
        });
    },
  },

  watch: {
    "pagination.options": {
      handler() {
        this.list(this.pagination.options.page);
      },
      deep: true,
    },
    "stockRequests.datatable.pagination.options": {
      handler() {
        this.warehouse.value = null;
        this.getStockRequests(
          this.stockRequests.datatable.pagination.options.page
        );
      },
      deep: true,
    },
    "stockRequests.selected": {
      handler() {
        this.warehouse.value = null;
      },
    },
    "warehouse.virtual": {
      handler(val) {
        this.getWarehouses(val);
      },
    },

    view_type: function (val) {
      if (val == "Warehouse") {
        this.getWarehouses();
      } else {
        this.filters.warehouse.selected = null;
        this.warehouse.vTabOptions = [];
        let dateBefore = [];
        let dateAfter = [];

        for (let i = 1; i <= 10; i++) {
          dateAfter.push({
            code: moment().add(i, "days").format("MM/DD"),
            date: moment().add(i, "days").format("YYYY-MM-DD"),
          });
          dateBefore.push({
            code: moment().subtract(i, "days").format("MM/DD"),
            date: moment().subtract(i, "days").format("YYYY-MM-DD"),
          });
        }

        dateBefore = dateBefore.reverse();
        dateBefore.push({
          code: moment().format("MM/DD"),
          date: moment().format("YYYY-MM-DD"),
        });

        this.warehouse.vTabOptions = dateBefore.concat(dateAfter);
        this.warehouse.vTabOptions.forEach((e, i) => {
          e.value = i;
          if (moment().format("MM/DD") == e.code) {
            this.tab = 15;
            this.date = e.date;
          }
        });
        this.selectedStatus = -2;
        this.tab1 = -2;
      }
    },
  },
};
</script>

<style scoped>
.stock-transfer-text-xs {
  font-size: 0.8rem !important;
  line-height: 1rem;
}

.stock-transfer-text-sm {
  font-size: 1rem !important;
  line-height: 1rem;
}

.cannot-serve {
  background-color: #f1f1f1;
}

.stock-transfer-items {
  font-size: 0.8rem !important;
}
.warehouse-form {
  border: 1px solid black;
}
.pdf-table,
.pdf-table tr,
.pdf-table td {
  border: 1px solid black;
  border-collapse: collapse;
}

.pdf-table-data-headings {
  background-color: yellow;
}
/* .warehouse-form{
    width:100px
} */
</style>
