<template>
    <v-container fluid>
        <v-card-title>
            <v-row>
                <v-col class="d-flex no-block align-items-center">
                    <h4 class="page-title" style="color: black">CLUSTER</h4>
                </v-col>
            </v-row>
        </v-card-title>
        <v-container>
            <v-row>
                <v-tabs v-model="tab" color="cyan" dark slider-color="cyan" background-color="#424242" show-arrows>
                    <v-tabs-slider color="cyan"></v-tabs-slider>
                    <v-tab ripple v-for="(item, i) in items" :key="item" @click="tabs(i)">
                        <span> {{ item }}</span>
                    </v-tab>
                </v-tabs>
            </v-row>
        </v-container>
        <v-container class="ma-0 pa-0">
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(table, index) in component_cluster" :key="index">
                    <keep-alive>
                        <component :is="table.components" :component_cluster="component_cluster"></component>
                    </keep-alive>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
        <dialogs :cp="components" :scrollable="true" :width="dialog_width"></dialogs>
    </v-container>
</template>
<script>
import cluster from './tables/ClusterComponent.vue'
import subcCluster from './tables/SubClusterComponent.vue'
import Dialogs from '../../layouts/dialogs/Dialog.vue'
import clusterDialog from '../../layouts/dialogs/Masterdata/ClusterComponent.vue'
import subClusterDialog from '../../layouts/dialogs/Masterdata/SubClusterComponent.vue'

export default {
    components: {
        cluster,
        subcCluster,
        clusterDialog,
        subClusterDialog,
        Dialogs,
    },
    data() {
        return {
            components: '',
            cluster: clusterDialog,
            subCluster: subClusterDialog,

            tab: null,
            items: [
                'MAIN',
                'SUB',
            ],
            component_cluster: [
                {
                    components: cluster,
                },
                {
                    components: subcCluster,
                },
            ],
            dialogType: '',
            dialog_width: ''
        };
    },
    mounted() {
        this.tabs(0);
        this.$store.commit('LINK_ACTIVE', window.location.pathname.split("/")[3])
    },
    methods: {
        tabs(key) {
            if (key == 0) {
                this.components = this.cluster;
                let width = '100%'
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs', 'sm':
                        width = '100%'
                        break
                    case 'md', 'lg', 'xl':
                        width = '40%'
                        break
                    default:
                        break
                }

                this.dialog_width = width
            } else if (key == 1) {
                this.components = this.subCluster;
                let width = '100%'
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs', 'sm':
                        width = '100%'
                        break
                    case 'md', 'lg', 'xl':
                        width = '40%'
                        break
                    default:
                        break
                }

                this.dialog_width = width
            }
        },
    },
};
</script>
