<template>
  <v-container>
    <v-row>
      <v-col md="16" class="d-flex no-block align-items-center ma-3">
        <h4 class="page-title" style="color: black">{{ $route.meta.title }}</h4>
      </v-col>
    </v-row>
    <v-row v-for="(report, i) in reports" :key="i">
      <v-col lg="3" sm="12" class="pa-3" v-for="(button, i) in report" :key="i">
        <reportbutton
          :button_title="button.title"
          :button_icon="button.icon"
          :button_route="button.route"
          @click="salesReportsRoutes(button.route)"
        >
        </reportbutton>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import reportbutton from "@/views/main/layouts/Buttons/ReportButtonComponent";
export default {
  data() {
    return {
      reports: [],
    };
  },
  props: [],
    methods:{
        changebutton(val){
            this.reports = []
            if(val == 'sales'){
                this.reports =  [
                [
                    {
                        icon:'mdi-account',
                        title:'Sales By Customers',
                        route:'sales-by-customers'
                    },
                    {
                        icon:'mdi-account',
                        title:'Sales By Customer (detailed)',
                        route:'sales-by-customer-detailed'
                    },
                    {
                        icon:'mdi-account',
                        title:'Sales By Customer Delivery Receipts',
                        route:'sales-by-customer-packing-lists'
                    },
                    {
                        icon:'mdi-account',
                        title:'Sales By Items',
                        route:'sales-by-items'
                    },
                ],
                [
                    {
                        icon:'mdi-account',
                        title:'Sales By Classifications',
                        route:'sales-by-classifications'
                    },
                    {
                        icon:'mdi-account',// not yet finish
                        title:'Sales By Region',//
                        route:'sales-by-region'//
                    },
                    {
                        icon:'mdi-account',
                        title:'Top Performing Concept Store',
                        route:'top-performing-concept-store'
                    },
                    {
                        icon:'mdi-account',
                        title:'Top/Poor Selling Items',
                        route:'top-poor-selling'
                    },
                ],
                [
                    {
                        icon:'mdi-account',
                        title:'Pending Stock Card By DR',
                        route:'pending-stock-Card-by-pL'
                    },
                    {
                        icon:'mdi-account',
                        title:'Customer Pending Orders By SO',
                        route:'customer-pending-orders-by-so'
                    },
                    {
                        icon:'mdi-account',
                        title:'Customer Pending Orders By Items',
                        route:'customer-pending-orders-by-items'
                    },
                    {
                        icon:'mdi-account',
                        title:'Pending Orders By Items',
                        route:'pending-orders-by-items'
                    },
                ],
                [
                  {
                      icon:'mdi-account',
                      title:'Customer Sold unsold',
                      route:'customer-sold-unsold'
                  },
                  {

                    icon:'mdi-account',
                      title:'New Items',
                      route:'new-item'
                  },
                  {
                      icon:'mdi-account',
                      title:'SO Converted To WPF',
                      route:'so-converted-to-wpf'
                  },
                  {
                      icon:'mdi-account',
                      title:'Sales Order Per Items',
                      route:'sales-order-per-items'
                  },
                ],
                [
                  {
                      icon:'mdi-account',
                      title:'Booked vs Invoiced Orders',
                      route:'booked-vs-invoiced-items'
                  },
                  {
                    icon:'mdi-account',
                    title:'Item Sales Details',
                    route:'item-sales-details'
                  },
                  {
                    icon:'mdi-account',
                    title:'Sales By Customer (Detailed) Comparison',
                    route:'sales-by-customer-detailed-comparison'
                  },
                  //Added Item Sales Details Count
                  {
                    icon:'mdi-account',
                    title:'Item Sales Details Count',
                    route:'item-sales-details-count'
                  },
                ],
                [
                    {
                        icon:'mdi-account',
                        title:'Sales By Clusters',
                        route:'sales-by-clusters'
                    }
                ],
                [
                  {
                      icon:'mdi-account',
                      title:'Pending Order Stock Analysis',
                      route:'pending-order-stock-analysis'
                  },
                ]
            ];

            }else if(val == 'accounting'){
                this.reports =  [
                    [
                        {
                            icon:'mdi-account',
                            title:'Credit Memo Detailed',
                            route:'credit-memo-detailed-component'
                        },
                        {
                            icon:'mdi-account',
                            title:'Debit Memo Detailed',
                            route:'debit-memo-detailed-component'
                        },
                        {
                            icon:'mdi-account',
                            title:'SOA Payment Status Report',
                            route:'soa-payment-status-report'
                        },
                        {
                            icon:'mdi-account',
                            title:'NET A/R Report',
                            route:'net-ar-report'
                        },
                        {
                            icon:'mdi-account',
                            title:'Collection Report',
                            route:'cheque-report'
                        },
                        {
                            icon:'mdi-account',
                            title:'Cheque Deposit Report',
                            route:'cheque-deposit-report'
                        },
                        {
                            icon:'mdi-account',
                            title:'Bank Transaction Report',
                            route:'bank-transaction-report'
                        },
                        {
                            icon:'mdi-account',
                            title:'Bounced Cheque Report',
                            route:'bounced-cheque-report'
                        },
                        {
                            icon:'mdi-account',
                            title:'Credit Memo China Discrepancy Report',
                            route:'credit-memo-china-discrepancy-report'
                        },
                        {
                            icon:'mdi-account',
                            title:'Unconfirmed Delivery Receipt (> 5days)',
                            route:'unconfirmed-packing-list-greater-5days'
                        },
                    ]
                ];
            }else if(val == 'inventory'){
              this.reports = [
                [
                    {
                        icon:'fa fa-user m-b-5',
                        title:'Inventory Counts',
                        route:'inventory-counts'
                    },
                    {
                        icon:'fa fa-user m-b-5',
                        title:'Item Movements',
                        route:'item-movements'
                    },
                    {
                        icon:'fa fa-user m-b-5',
                        title:'Daily Movement Reports',
                        route:'daily-movement-reports'
                    },
                    {
                        icon:'fa fa-user m-b-5',
                        title:'Pending Stockcard By Customers',
                        route:'pending-stockcard-by-customers'
                    },
                ],
                [
                    {
                        icon:'fa fa-user m-b-5',
                        title:'Pending Stockcards By Items',
                        route:'pending-stockcard-by-items'
                    },
                    {
                        icon:'fa fa-user m-b-5',// not yet finish
                        title:'Pending Stock Card By DR',//
                        route:'pending-stockcard-by-pl'//
                    },
                ],
                [
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Stock Request Reports',
                      route:'stock-request-reports'
                  },
                  {
                    icon:'fa fa-user m-b-5',
                      title:'Stock Transfer Reports',
                      route:'stock-transfer-reports'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Daily Warehouse Inventory Reports',
                      route:'daily-warehouse-inventory-reports'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Trucks Per Day Reports',
                      route:'trucks-per-day-reports'
                  },
                ],
                [
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Shipment Per Item Reports',
                      route:'shipment-per-item-reports'
                  },
                  {
                    icon:'fa fa-user m-b-5',
                      title:'Sales Quotation Reports',
                      route:'sales-quotations-reports'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Container Per Item Reports',
                      route:'container-item-reports'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Trucks Per Day Reports',
                      route:'trucks-per-day-reports'
                  },
                ],
                [
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Order Items With Stock Reports',
                      route:'order-items-with-stock-reports'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Items With New Models Report',
                      route:'items-with-new-models-report'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Inactive Items: Actual vs Virtual Stocks',
                      route:'inactive-items-reports'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Items Which Container Last Arrived',
                      route:'items-which-container-last-arrived'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Items Based on Stock Range',
                      route:'items-based-on-stock-range'
                  },
                  {
                      icon:'fa fa-user m-b-5',
                      title:'Warehouse Stocks vs Item Movement',
                      route:'warehouse-stocks-vs-item-movement'
                  },
                ],
              ]
            }
            else if(val == 'admin'){
                this.reports = [
                    [{
                        icon:'mdi-file-document',
                        title:'ASSET MANAGEMENT',
                        route:'assetmanagementreport'
                    },
                    {
                        icon:'mdi-file-document',
                        title:'FAS REPORTS',
                        route:'sales-by-customers'
                    }],
                ]
            }
        },

        salesReportsRoutes(route){
            this.$router.push({
                name:route
            })
        }
    },

    components:{
        reportbutton
    },

    mounted(){
        this.$store.commit("LINK_ACTIVE", this.$router.app._route.params.id);
        this.changebutton(this.$route.meta.type)
        // this.$store.dispatch('getAllcustomersSelection')
        // this.$store.dispatch('getAllCustomers')
        // this.$store.dispatch('getAllsalesmanSelection')
        // this.$store.dispatch('getAllitemSelectionDefault')
        // this.$store.dispatch('getItemTypes')
        // this.$store.dispatch('getItemCategories')
        // this.$store.dispatch('getAllItemCategories');
        // this.$store.dispatch('getAllcustomerClassificationSelection')
        // this.$store.dispatch('getItems')
        // this.$store.dispatch('getItemBrand')
        // this.$store.dispatch('getAllWarehouses')

    },

    watch:{
        '$route.meta.type':{
            handler(val){
                this.changebutton(val)
            }
        }
    }
  }

</script>
