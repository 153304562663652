<template>
    <v-card class="mt-5 pa-0">
        <v-row class="pa-2">
            <v-spacer></v-spacer>
            <v-col cols="3" class="mx-4 pt-4">
                <v-text-field
                    label="Search CM#"
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-2">
            <v-col>
                <v-data-table
                    :items="items"
                    :headers="headers"
                    :loading="loading"
                    :options.sync="options"
                    :server-items-length="totalCreditMemoHistory"
                    :items-per-page="10"
                    :footer-props="{
                        showFirstLastPage: true,
                    }"
                >
                <template v-slot:[`item.action`]="{ item }">
                    <span class="text-nowrap">
                        <v-btn text icon color="orange" v-if="userAccess.view">
                            <v-icon class="btn-action" small
                                @click="viewCreditMemo(item)">mdi-eye</v-icon>
                        </v-btn>
                        <v-btn text icon color="blue" v-if="item.approved_by == null && userAccess.edit">
                            <v-icon class="btn-action" small
                                @click="editCreditMemo(item)">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon color="red" v-if="item.approved_by == null && userAccess.cancel">
                            <v-icon class="btn-action" small
                                @click="cancelCreditMemoHistory(item)">mdi-cancel</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <span v-if="item.approved_by == null" class="badge white--text bg-secondary">saved</span>
                    <span v-else-if="!!item.approved_by" class="badge white--text bg-primary">approved</span>
                </template>
                <template v-slot:[`item.approved_date`]="{ item }">
                    {{item.approved_date | formatDate}}
                </template>
                <template v-slot:[`item.soa_id`]="{ item }">
                    {{item.soa_id}}
                </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    props:['status'],
    data(){
        return{
            items:[],
            headers: [
                { text: "CM#", value: "credit_memo_num", align: "center" },
                { text: "CM DATE", value: "credit_memo_date", align: "center" },
                { text: "CUSTOMER", value: "company_name", align: "center" },
                { text: "OLD SOA MONTH", align: "center", value: "old_soa_month_year" },
                { text: "NEW SOA MONTH", align: "center", value: "new_soa_month_year" },
                { text: "SOA ID", align: "center", value: "soa_id"},
                { text: "REQUESTOR", align: "center", value: "requestor_by_name"},
                { text: "APPROVED BY", align: "center", value: "approved_by_name"},
                { text: "APPROVED DATE", align: "center", value: "approved_date"},
                { text: "STATUS", align: "center", value: "status"},
                { text: "ACTIONS", align: "center", value: "action" },
            ],
            loading:false,
            options: {},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPage: 10,
            totalCreditMemoHistory:0,
            userAccess:{
                view:false,
                edit:false,
                cancel:false
            },
            search:''
        }
    },
    methods:{
        getAll(){
            this.loading = true
            this.items = []
            this.$store.dispatch('getCreditMemoHistory',{
                url:'get-all-credit-memo-history',
                status:this.status,
                page:this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                search: this.search,
            }).then(response=>{
                let data = response.data.data
                this.items = data.data
                this.totalCreditMemoHistory = data.total
                this.loading = false
            })
        },
        viewCreditMemo(item){
            this.$emit('showAddEditDialog',item,'view')
        },
        editCreditMemo(item){
            this.$emit('showAddEditDialog',item,'edit')
        },
        addKeyListener(){
            window.addEventListener("keyup", function(e) {
                if(e.keyCode == 13){
                    this.getAll();
                }
            }.bind(this));
        },
        cancelCreditMemoHistory(item){
            swal.fire({
                title: "",
                text: "Are you sure you want to cancel this Credit Memo Change Date?",
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                confirmButtonColor: "#397373",
                cancelButtonColor: "grey",
                confirmButtonText: "Yes",
                showCloseButton: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('postCreditMemoHistory',{
                        id:item.id,
                        url:'cancel-credit-memo-history',
                    }).then(response=>{
                        swal.fire('',response.data.msg,response.data.icon)
                        if(response.data.icon == 'success'){
                            this.getAll();
                        }
                    })
                }
            });
        },
        checkAccess() {
            let val = this.USER_ACCESS;
            if (val != "fail") {
                val.forEach((e) => {
                    if (e.actions_code == "view") {
                        this.userAccess.view = true;
                    }
                    if (e.actions_code == "edit") {
                        this.userAccess.edit = true;
                    }
                    if (e.actions_code == "cancel") {
                        this.userAccess.cancel = true;
                    }
                });
            }
        },
    },
    mounted(){
        this.getAll();
        this.addKeyListener();
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS'
        ])
    },
    watch:{
        status() {
            this.getAll();
        },
        options: {
            handler () {
                this.getAll()
            },
            deep: true,
        },
        USER_ACCESS() {
            this.checkAccess();
        },
    }
}
</script>

<style>

</style>
