<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
        <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
            <span class="text-h5">Warehouse Information</span>
            <v-row class="m-0">
            <v-col cols="pull-right-10 p-2">
                <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-container class="pb-0 mb-0">
                <v-row>
                    <v-col col="12">
                        <v-text-field
                            outlined
                            dense
                            :disabled="disable_view" v-model="form.name"
                            :rules="errors.name"
                            label="Warehouse Name"
                            height="10"
                            required
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12">
                        <v-text-field outlined :disabled="disable_view" v-model="form.code" label="Code" :rules="errors.code" dense required>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col col="12" sm="2.5">
                        <v-switch :disabled="disable_view" v-model="form.active" label="Active"></v-switch>
                    </v-col>
                    <v-col col="12" sm="2.5">
                        <v-switch :disabled="disable_view" v-model="form.default" label="Default"></v-switch>
                    </v-col>
                    <v-col col="12" sm="2.5">
                        <v-switch :disabled="disable_view" v-model="form.virtual" label="Virtual"></v-switch>
                    </v-col>
                    <v-col col="12" sm="2.5">
                        <v-switch :disabled="disable_view" v-model="form.allow_butal" label="Allow Butal"></v-switch>
                    </v-col>
                    <v-col col="12" sm="2.5">
                        <v-switch :disabled="disable_view" v-model="form.actual" label="Actual"></v-switch>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-center">
            <v-row class="ma-1">
                <v-col cols="12" v-if="actions != 'View'">
                    <v-btn class="float-center" @click="actions == 'Submit' ? addWarehouse() : UpdateWarehouse()" :disabled="disable_view">
                    <span>{{ actions }}</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
  props: ['action'],
  data() {
    return {
      valid: false,
      form: {
        name: '',
        code: '',
        active: 1,
        default: 0,
        virtual: 0,
        allow_butal: 0,
        actual: 0
      },
      item: '',
      errors: {
        name: [v => !!v == 'The name field is required'],
        code: [v => !!v == 'The code field is required'],
      },
      disable_view:false,
    };
  },
  computed: {
    ...mapGetters(['DIALOGS', 'ACTION', , 'GET_SELECTED_WAREHOUSE']),
    actions() {
      if (this.ACTION == 'Submit') {
        this.disable_view = false;
        this.form = {

          name: null,
          code: null,
          active: 1,
          default: 0,
          virtual: 0,
          allow_butal: 0,
        };
        return 'Submit';
      } else if (this.ACTION == 'Update') {
        this.disable_view = false;
            this.getWarehouseEdit();
        return 'Update';
      }else if (this.ACTION == 'View') {
        this.disable_view = true;
            this.getWarehouseEdit();
        return 'View';
      }
    },
  },
  mounted() {},
  methods: {
    getWarehouseEdit(){
        this.form = {
          name: this.GET_SELECTED_WAREHOUSE.name,
          code: this.GET_SELECTED_WAREHOUSE.code,
          active: this.GET_SELECTED_WAREHOUSE.active,
          default: this.GET_SELECTED_WAREHOUSE.default,
          virtual: this.GET_SELECTED_WAREHOUSE.virtual,
          allow_butal: this.GET_SELECTED_WAREHOUSE.allow_butal,
          actual: this.GET_SELECTED_WAREHOUSE.actual,
        }
        this.item = this.GET_SELECTED_WAREHOUSE
    },
    closeDialog() {
      this.$store.commit('DIALOG', false);
    },
    addWarehouse() {
      this.$refs.form.validate();

      const code_swal_text = this.form.code ? '' : 'Code'
      const name_swal_text = this.form.name ? '' : 'Warehouse Name'
      const array_text = [name_swal_text, code_swal_text]
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if (this.form.code && this.form.name) {
        this.$store.dispatch('addNewwarehouse', this.form);
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: 'warning',
          allowOutsideClick: false,
        });
      }
    },
    UpdateWarehouse() {
      this.$refs.form.validate();
      this.$store.dispatch('Updatewarehouse', {
        id: this.item.id,
        payload: this.form,
      });
    },
    resetFields() {
      this.form = {
        name: null,
        code: null,
        active: 1,
        default: 0,
        virtual: 0,
        allow_butal: 0,
      }
    }
  },
};
</script>
