<template>
<div>
    <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
    </v-app>
    <v-app id="view_client_modal">
        <v-dialog v-model="promos_dialog" persistent max-width="98%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">Promo Details</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="promos_dialog=false; $emit('closeView');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row class="m-0">
                        <v-container fluid>
                            <v-row>
                                <div class="col-sm-12 col-md-8 text-left text-dark">
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Promo No: {{ promo.id }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Name: {{ promo.name }}</div>
                                    </div>
                                    <div class="row ml-2">
                                        <div class="col-sm-12 col-md-8 p-0">Code: {{ promo.code }}</div>
                                    </div>
                                </div>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-data-table
                        :headers="promoItemHeaders"
                        :items="promo.promo_items"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                        dense
                    >
                        <template v-slot:[`item.cash_only`]="{ item }">
                            <span class="badge badge-primary" v-if="item.cash_only">Yes</span>
                            <span class="badge badge-danger" v-else>No</span>
                        </template>

                    </v-data-table >
                    <br><br>
                    <v-data-table
                        :headers="customerPromoHeaders"
                        :items="promo.customer_promos"
                        :items-per-page="500"
                        hide-default-footer
                        class="w-100"
                        dense
                    >
                        <template v-slot:[`item.cash_price`]="{ item }">
                            <span class="badge badge-primary" v-if="item.cash_price">Yes</span>
                            <span class="badge badge-danger" v-else>No</span>
                        </template>

                        <template v-slot:[`item.disabled`]="{ item }">
                            <span class="badge badge-primary" v-if="item.disabled">Yes</span>
                            <span class="badge badge-danger" v-else>No</span>
                        </template>

                        <template v-slot:[`item.discount_rate`]="{ item }">
                            <span>{{ item.discount_rate }}%</span>
                        </template>

                    </v-data-table >
                </v-card-text>

                <br><br>

            </v-card>
        </v-dialog>

      
    </v-app>
</div>
</template>
<script>
    // import SharedFunctionsComponent from '../Shared/SharedFunctionsComponent.vue'
    import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
    
    export default {
        mixins: [SharedFunctionsComponentVue],
        props: [
            'viewPromoId',
        ],
        mounted(){

        },
        data() {
            return {
                promos: [],

                promoItemHeaders: [
                    { text: 'Model', align: 'left', value: 'item_model', width: '15%', sortable: false},
                    { text: 'Name', align: 'left', value: 'item_name', width: '35%', sortable: false},
                    { text: 'Qty From', align: 'left', value: 'quantity_from', sortable: false},
                    { text: 'Lowest Price', align: 'center', value: 'lowest_price', sortable: false},
                    { text: 'Cash Only', align: 'center', value: 'cash_only', sortable: false},
                ],

                customerPromoHeaders: [
                    { text: 'Customer', align: 'left', value: 'customer_name', width: '35%', sortable: false},
                    { text: 'Discount Rate', align: 'left', value: 'discount_rate', sortable: false},
                    { text: 'Disabled', align: 'center', value: 'disabled', sortable: false},
                ],

                loaded: true,
                promos_dialog: false,
                promo: '',
                errors: [],
            }
        },
        methods: {
            resetFields(){
                this.promo = ''
            },
        },
        watch: {
            viewPromoId: function(val){
                if(val != -1){
                    if(this.viewPromoId != null && this.viewPromoId > 0){
                        this.loaded = false
                        let payload = {
                            url: 'getPromo/'+this.viewPromoId
                        }
                        this.$store.dispatch('getPromos',payload).then(response => {
                            let data = response.data

                            this.promo  = data.promo
                            this.loaded = true
                            this.promos_dialog = true
                        }).catch(error => {

                        });
                    }
                }

            }
        },

    };

</script>
<style scoped>
    .v-avatar{
        margin: 15px 50px !important;
    }
    #client_modal_card{
        min-height: 160px !important;
    }
</style>
