<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Salesman Information</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="pa-5">
            <v-spacer></v-spacer>
            <v-col cols="6">
              <image-upload-component @uploadedImage="uploadedImage" v-if="ACTION == 'Submit' || ACTION == 'Update'"></image-upload-component>
              <v-img :src="GET_SELECTED_SALESMAN_HEAD.salesman_profile_base64" v-if="ACTION == 'View'"></v-img>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="form.name"
                :rules="errors.name"
                :disabled="disable_view"
                label="Salesman Name"
                placeholder="Salesman Name"
                hide-details="auto"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="form.user"
                :items="GET_ALL_USERS_SELECT"
                :rules="errors.user"
                :disabled="disable_view"
                label="Users"
                placeholder="Users"
                hide-details="auto"
                dense
                outlined
                auto-select-first
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="form.salesman_position"
                :items="GET_ALL_SALESMANPOSITIONS_SELECT"
                :rules="errors.salesman_position"
                :disabled="disable_view"
                label="Position"
                placeholder="Position"
                hide-details="auto"
                dense
                outlined
                required
                auto-select-first
                @change="checkSalesmanHead(form.salesman_position)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="form.salesman_head"
                :items="GET_ALL_SALESMANHEAD_SELECT"
                :rules="errors.salesman_head"
                :disabled="isDisable"
                label="Head"
                placeholder="Head"
                hide-details="auto"
                dense
                outlined
                required
                auto-select-first
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
                <v-autocomplete
                    v-model="form.clusters"
                    :items="GET_CLUSTER_SELECTION"
                    :rules="errors.clusters"
                    :disabled="disable_view"
                    label="Clusters"
                    placeholder="Clusters"
                    hide-details="auto"
                    dense
                    outlined
                    multiple
                    required
                    small-chips
                    deletable-chips
                    auto-select-first
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
                <v-autocomplete
                    v-model="form.sub_clusters"
                    :items="GET_SUB_CLUSTER_SELECTION"
                    :rules="errors.sub_clusters"
                    :disabled="disable_view"
                    label="Sub Clusters"
                    placeholder="Sub Clusters"
                    hide-details="auto"
                    dense
                    outlined
                    multiple
                    required
                    small-chips
                    deletable-chips
                    auto-select-first
                ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.location"
                :rules="errors.location"
                :disabled="disable_view"
                label="Locations"
                placeholder="Location"
                hide-details="auto"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.region"
                :items="GET_ALL_REGIONS"
                :rules="errors.region"
                :disabled="disable_view"
                label="Region"
                placeholder="Region"
                hide-details="auto"
                dense
                outlined
                single
                required
                deletable-chips
                auto-select-first
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.monthly_target"
                :rules="errors.monthly_target"
                :disabled="disable_view"
                type="number"
                label="Monthly Target"
                placeholder="Monthly Target"
                hide-details="auto"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="form.sales_coordinator"
                :disabled="disable_view"
                style="display: inline-block"
                text="Sales Coordinator"
                dense
              />
              <span style="font-size: 14px" class="px-1">Sales Coordinator</span>
            </v-col>
          </v-row>

        </v-container>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-row class="ma-1">
          <v-col cols="12" v-if="actions !='View'">
            <v-btn class="float-center" @click="actions == 'Submit' ? addSalesman() : EditSalesman()" :disabled="disable_view"  >
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>

import ImageUploadComponent from '@/views/main/Shared/ImageUploadComponent.vue';
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'

export default {
  data () {
    return {
      valid:false,
      dialog: false,
      isDisable: true,
      form: {
        name: null,
        user: null,
        salesman_position: null,
        salesman_head: null,
        location: null,
        sales_coordinator: false,
        salesman_profile: null,
        region: null,
        monthly_target: null,
        clusters: [],
        sub_clusters: [],
      },
      item: [],
      errors: {
        name: [v => !!v || 'The salesman name is required'],
        user: [v => !!v || 'The user name is required'],
        salesman_position: [v => !!v || 'The position is required'],
        salesman_head: [v => !!v || 'The head name is required'],
        location: [v => !!v || 'The location name is required'],
        // salesman_profile: [v => !!v || 'The Profile name is required'],
        region: [v => !!v || 'The region is required'],
        monthly_target: [v => !!v || 'The Monthly Target is required'],
        clusters: [v => !!v || 'Cluster is required'],
        sub_clusters: [v => !!v || 'Sub cluster is required'],
      },
      disable_view:false,
    }
  },
  components:{
    ImageUploadComponent
  },
  methods: {
    addSalesman () {
      // navigator.geolocation.getCurrentPosition(async (position)=>{
      //     await this.$store.dispatch('getCurrentCity',{
      //         latitude:position.coords.latitude,
      //         longitude:position.coords.longitude,
      //     }).then(response=>{
      //         this.form.location = `${response.data.city}, ${response.data.principalSubdivision}`
      //         this.form.latitude = response.data.latitude
      //         this.form.longitude = response.data.longitude
      //     })
      // })

      this.$refs.form.validate()
      const name_swal_text = this.form.name ? '' : 'Name';
      const user_swal_text = this.form.user ? '' : 'User'
      const pos_swal_text = this.form.salesman_position ? '' : 'Position'
      const head_swal_text = this.form.salesman_head ? '' : 'Head'
      const loc_swal_text = this.form.location ? '' : 'Location'
      const salesman_profile_swal_text = this.form.salesman_profile ? '' : 'Profile'
      const salesman_region_swal_text = this.form.region ? '' : 'Region'
      const salesman_monthly_target_swal_text = this.form.monthly_target ? '' : 'Montly Target'
      const salesman_clusters_swal_text = this.form.clusters ? '' : 'Clusters'
      const array_text = [name_swal_text, user_swal_text, pos_swal_text, head_swal_text, loc_swal_text , salesman_profile_swal_text, salesman_region_swal_text , salesman_monthly_target_swal_text, salesman_clusters_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if (
        this.form.name &&
        this.form.user &&
        this.form.salesman_position &&
        this.form.salesman_head &&
        this.form.location &&
        // this.form.salesman_profile &&
        this.form.region &&
        this.form.monthly_target &&
        this.form.clusters
      )
      {
        swal
        .fire({
          text: 'Are you sure you want to create salesman?',
          icon: 'warning',
          showCancelButton: true,
        })
        .then(confirm => {
          if (confirm.isConfirmed) {
            this.$store.dispatch('addsalesman', this.form);
          }
        }) } else { swal.fire({
        text: `Fill Required Fields (${filtered})`,
        icon: 'warning',
        allowOutsideClick: false,
      });
      }
    },
    EditSalesman () {
      this.$refs.form.validate()
      const name_swal_text = this.form.name ? '' : 'Name';
      const user_swal_text = this.form.user ? '' : 'User';
      const pos_swal_text = this.form.salesman_position ? '' : 'Position'
      const head_swal_text = this.form.salesman_head ? '' : 'Head'
      const loc_swal_text = this.form.location ? '' : 'Location'
      const clusters_swal_text = this.form.clusters ? '' : 'Clusters'
      const sub_clusters_swal_text = this.form.sub_clusters ? '' : 'Sub Clusters'
      const array_text = [name_swal_text, user_swal_text, pos_swal_text, head_swal_text, loc_swal_text, clusters_swal_text, sub_clusters_swal_text];
      const filtered = array_text.filter(function (el) {
        return el.replace(',', '');
      })
      if (
        this.form.name &&
        this.form.user &&
        this.form.salesman_position &&
        this.form.salesman_head &&
        this.form.location &&
        // this.form.salesman_profile &&
        this.form.region &&
        this.form.monthly_target &&
        this.form.clusters &&
        this.form.sub_clusters
      ) {
        swal
          .fire({
            text: 'Are you sure you want to edit salesman?',
            icon: 'warning',
            showCancelButton: true
          })
          .then(confirm => {
            if (confirm.isConfirmed) {
              console.log(this.form);
              this.$store.dispatch('editsalesman', {
                id: this.items.id,
                payload: this.form
              });
            }
          })
      } else {
       swal.fire({
        text: `Fill Required Fields (${filtered})`,
        icon: 'warning',
        allowOutsideClick: false,
      });
      }
    },
    closeDialog () {
      this.$store.commit('SALESMAN_DIALOG', false)
      this.$store.commit('DIALOG', false)
      this.resetfields()
    },
    resetfields () {
      this.form = {
        name: null,
        user: null,
        salesman_position: null,
        salesman_head: null,
        location: null,
        sales_coordinator: false,
        region: null,
        monthly_target: null,
        salesman_profile: null,
        clusters: null,
      };
    },
    getAllSalesmanEdit(){
        this.form = {
          name: this.GET_SELECTED_SALESMAN_HEAD.name,
          user: this.GET_SELECTED_SALESMAN_HEAD.user_id,
          salesman_position: this.GET_SELECTED_SALESMAN_HEAD.salesman_position_id,
          salesman_head: this.GET_SELECTED_SALESMAN_HEAD.salesman_head_id,
          location: this.GET_SELECTED_SALESMAN_HEAD.location,
          sales_coordinator: this.GET_SELECTED_SALESMAN_HEAD.sales_coordinator,
          region: this.GET_SELECTED_SALESMAN_HEAD.region_code,
          monthly_target: this.GET_SELECTED_SALESMAN_HEAD.monthly_target,
          salesman_profile: this.GET_SELECTED_SALESMAN_HEAD.salesman_profile_base64,
          clusters: this.GET_SELECTED_SALESMAN_HEAD.clusters ? this.GET_SELECTED_SALESMAN_HEAD.clusters.map(m => m.id) : [],
          sub_clusters: this.GET_SELECTED_SALESMAN_HEAD.sub_clusters ? this.GET_SELECTED_SALESMAN_HEAD.sub_clusters.map(m => m.id) : [],
        };
        this.items = this.GET_SELECTED_SALESMAN_HEAD
    },
    uploadedImage(data){
      this.form.salesman_profile = data.uploadedImage
    },
    checkSalesmanHead(salesman_position){
      this.$store.dispatch('getAllSalesmanHead',{salesman_position: salesman_position}).then(response => {
          this.isDisable = false
          if(salesman_position == 1){
            this.isDisable = true
          }
      });
    }
  },
  mounted () {
    this.$store.dispatch('getAllSelectUsers')
    this.$store.dispatch('getALLSalesmanPosition');
    this.$store.dispatch('getAllSalesmanHead');
    this.$store.dispatch('getRegions');
    this.$store.dispatch('getClusterSelection')
  },
  computed: {
    ...mapGetters([
      'GET_SELECTED_SALESMAN_HEAD',
      'GET_ALL_USERS_SELECT',
      'GET_ALL_SALESMANPOSITIONS_SELECT',
      'GET_ALL_SALESMANHEAD_SELECT',
      'ACTION',
      'GET_ALL_REGIONS',
      'GET_CLUSTER_SELECTION',
      'GET_SUB_CLUSTER_SELECTION',
    ]),
    actions () {
      if (this.ACTION == 'Submit') {
        this.getAllSalesmanEdit()
        return 'Submit'
      } else if(this.ACTION == 'Update'){
        this.getAllSalesmanEdit()
        return 'Update';
      }else if(this.ACTION == 'View'){
        this.disable_view = true;
        this.getAllSalesmanEdit()
        return 'View';
      }
    }
  },
  watch:{
    form:{
      handler(val){

      }
    },
    'form.clusters': {
      handler(val, oldVal) {
        if (oldVal.length > 0 && this.form.sub_clusters) {
          this.form.sub_clusters = this.form.sub_clusters.filter(sub_cluster => (
            this.GET_SUB_CLUSTER_SELECTION.some(element => element.value === sub_cluster && val.includes(element.parent))
          ));
        }

        this.$store.dispatch("getSubClusterSelection", {cluster_ids: val});
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>
