<template>
    <div>
        <v-card class="mt-2">
            <v-layout row class="mx-0 pt-3 px-2 align-items-center">
                <v-flex lg3 class="mr-2 ma-2">
                    <v-autocomplete
                        outlined
                        dense
                        :items="GET_CUSTOMERS_LIST_SELECTION"
                        v-model="selectedCustomer"
                        item-value="value"
                        item-text="text"
                        label="Customer"
                    />
                </v-flex>
                <v-flex lg3 class="align-items-center mb-1">
                    <!-- <v-btn @click="filterList()">Filter</v-btn> -->
                    <v-btn text  @click="clearFilter()">Clear</v-btn>
                </v-flex>
                <v-spacer />
                <v-flex align-self-start>
                    <div style="text-align:end">
                        <span class="badge bg-light mr-2" style="cursor:pointer" @click="filterByStatus(10)">all</span>
                        <span class="badge white--text bg-primary mr-2" style="cursor:pointer" @click="filterByStatus(5)">confirmed</span>
                        <span class="badge bg-warning mr-2" style="cursor:pointer" @click="filterByStatus(6)">partial</span>
                        <span class="badge white--text bg-success mr-2" style="cursor:pointer" @click="filterByStatus(7)">paid</span>
                        <span class="badge white--text bg-danger mr-2" style="cursor:pointer" @click="filterByStatus(8)">overdue</span>
                    </div>
                </v-flex>
            </v-layout>

            <v-card-title>
                <v-layout row class="mx-0">
                    <v-spacer />
                    <v-flex lg2>
                        <div style="text-align:end">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="SoaID"
                                single-line
                                hide-details
                                clearable
                                @keydown.enter="searchSoa()"
                                @click:clear="clearFilter()"
                            />
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="statementOfAccounts"
                    :search="search"
                    :server-items-length="totalStatementOfAccounts"
                    :options.sync="options"
                    :items-per-page="20"
                    :loading="loading"
                >
                    <template v-slot:[`item.total_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.total_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.paid_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.paid_amount | currency }}
                        </span>
                    </template>
                    <template v-slot:[`item.balance_amount`]="{ item }">
                        <span class="text-nowrap">
                            &#x20b1;{{ item.balance_amount | currency }}
                        </span>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <span v-if="item.status==5" class="badge badge-primary">confirmed</span>
                        <span v-else-if="item.status==6" class="badge badge-warning">partial</span>
                        <span v-else-if="item.status==7" class="badge badge-success">paid</span>
                        <span v-else-if="item.status==8" class="badge badge-danger">overdue</span>
                    </template>

                    <template v-slot:[`item.payment_method`]="{ item }">
                        <span class="badge badge-success" v-if="item.payment_method==0">cash</span>
                        <span class="badge badge-purple" v-else-if="item.payment_method==1">cheque</span>
                        <span class="badge badge-warning" v-else-if="item.payment_method==2">deposit</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <span class="text-nowrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="viewStatementOfAccount(item.id)">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn v-if="item.status != 7" text icon color="blue">
                                <v-icon class="btn-action" small @click="editStatementOfAccount(item)">mdi-pencil</v-icon>
                            </v-btn>
                            <!-- <v-btn v-if="item.status != 7" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteStatementOfAccount(item.id)">fas fa-trash-alt</v-icon>
                            </v-btn> -->
                        </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <SoaCustomerPaymentComponentVue />

        <ViewSoaCustomerPaymentDialogVue :soaId="soaId" @closeView="closeView" />
    </div>
</template>

<script>
import SoaCustomerPaymentComponentVue from '../PaymentsComponent/SoaCustomerPaymentComponent.vue';
import ViewSoaCustomerPaymentDialogVue from '../PaymentsComponent/ViewSoaCustomerPaymentDialogComponent.vue';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            headers: [
                { text: 'Soa Id', value: 'id', },
                { text: 'Customer', value: 'company_name' },
                { text: 'Due Month', value: 'due_date' },
                { text: 'Total Amount', value: 'total_amount' },
                { text: 'Paid Amount', value: 'paid_amount' },
                { text: 'Balance Amount', value: 'balance_amount' },
                { text: 'Prepared By', value: 'prepared_by_name' },
                { text: 'Confirmed At', value: 'confirmed_at' },
                { text: 'Completed By', value: 'completed_by_name' },
                { text: 'Complete Date', value: 'complete_date' },
                { text: 'Status', value: 'status' },
                { text: 'Payment Method', value: 'payment_method' },
                { text: 'Actions', value: 'actions' },
            ],
            statementOfAccounts: [],
            loading: true,
            options: {},
            search: '',
            page: 1,
            itemsPerPage: 10,
            totalStatementOfAccounts: 0,
            status: 10,
            customerList: [],
            selectedCustomer: '',
            soaId: '',
            userAccess: {
                    create  : false,
                    edit    : false,
                    view    : false,
                    delete  : false,
                },
            loadview:{
                customer_selection          : true,
            }
        }
    },
    components: {
        SoaCustomerPaymentComponentVue,
        ViewSoaCustomerPaymentDialogVue
    },
    async mounted() {
        await this.getAllStatementOfAccounts();
        // await this.getAllCustomer();
    },
    watch: {
        USER_ACCESS:{
            handler(val){
                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
                }
            }
        },
        menu() {
            if(this.menu == 'allocations') {
                this.loading = true;
                this.statementOfAccounts = [];
                this.getAllStatementOfAccounts();
            }
        },
        statementOfAccounts() {
            this.loading = true;
            if(this.statementOfAccounts.length > 0){
                this.loading = false;
            } else {
                this.loading = true;
            }
            this.$store.commit('clearPaymentsMenu');
        },
        successUpdate() {
            if(this.successUpdate) {
                this.soadId = '';
                this.getAllStatementOfAccounts();
            }
        },
        options(data) {
            this.loading = true;
            this.page = data.page;
            this.getAllStatementOfAccounts();
        },
        selectedCustomer() {
            if(!!this.selectedCustomer) {
                this.getAllStatementOfAccounts();
            } else {
                this.selectedCustomer = '';
            }
        },
        'GET_CUSTOMERS_LIST_SELECTION':{
            handler(val){
                this.loadview.customer_selection = false
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_LIST_SELECTION',
            'GET_SOA_GET_ALL_APPROVED',
            'USER_ACCESS'
        ]),
        successUpdate() {
            return this.$store.state.accounting.soaCustomerDetails.successUpdate;
        },
        menu() {
            return this.$store.state.accounting.payments.menu;
        }
    },
    methods: {
        clearFilter() {
            this.selectedCustomer = '';
            this.status = '';
            this.search = '';
            this.getAllStatementOfAccounts();
        },
        clearFields() {
            this.status = '';
            this.search = '';
        },
        searchSoa() {
            this.selectedCustomer = '';
            this.status = '';
            this.getAllStatementOfAccounts();
        },
        // getAllCustomer(){
        //     this.$store.dispatch('getAllCustomers').then((response)=>{ this.customerList= this.GET_CUSTOMERS_LIST_SELECTION })
        // },
        getAllStatementOfAccounts(){
            this.statementOfAccounts= []
            let payload = {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                status: this.status,
                customerId: this.selectedCustomer,
                search: this.search
            }
            this.$store.dispatch('soaGetAllApproved',payload).then(response => {
                this.statementOfAccounts = this.GET_SOA_GET_ALL_APPROVED.data;
                this.totalStatementOfAccounts = this.GET_SOA_GET_ALL_APPROVED.total
            })
        },
        filterByStatus(status) {
            this.status = status;
            this.statementOfAccounts = [];
            this.search = '';
            this.loading = true;
            this.getAllStatementOfAccounts();
        },
        viewStatementOfAccount(id) {
            this.soaId = id;
            this.$store.commit('custSoaPaymentDetailShow');
        },
        editStatementOfAccount(soa) {
            this.$store.commit('soaCustomerSetDetails', soa);
            this.$store.commit('soaShowCustDetailAddEditDialog');
        },
        deleteStatementOfAccount(id) {

        },
        closeView(confirm) {
            if(confirm) {
                this.soaId = '';
                this.getAllStatementOfAccounts();
            }
        }
    }
}
</script>

<style>

</style>
