<template>
  <v-container fluid>
    <v-card-title>
      <v-container fluid class="pa-0 ma-0">
      <v-row>
        <v-col class="d-flex no-block align-items-center">
          <h4 class="page-title" style="color: black">USERS</h4>
        </v-col>
      </v-row>
      <v-row>
        <!-- <buttons
            v-if="userAccess.create"
            :action="buttons.actions.create"
            :button_icon="buttons.icons.create"
            :color="buttons.colors.create"
            @click="adddialogs"
          >
          </buttons> -->
          <div class="row" v-if="userAccess.create">
            <div class="col-12 d-flex no-block align-items-center">
                <div class="btn-toolbar">
                    <v-btn @click="adddialogs()">
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </v-btn>
                </div>
            </div>
          </div>
      </v-row>
    </v-container>
    </v-card-title>
    <usersTable></usersTable>
    <dialogs :cp="components" :width="'40%'"></dialogs>
  </v-container>
</template>
<script>
import { computed } from 'vue'
import usersTable from './tables/UsersComponent.vue'
import userDialog from '../../layouts/dialogs/Masterdata/UsersComponent.vue'
// import buttons from '../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../assets/js/components-js.js'
import Dialogs from '../../layouts/dialogs/Dialog.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    // buttons,
    usersTable,
    // buttons_components,
    Dialogs,
    userDialog,
  },
  data() {
    return {
      color: 'orange',
      action: 'add',
      dialog_text: false,
      dialog_icon: 'mdi-plus',
      components: userDialog,
      // buttons: buttons_components.buttons,
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
    this.$store.commit('LINK_ACTIVE',window.location.pathname.split("/")[3])
  },
  methods: {
    adddialogs() {
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'Submit')
      this.$store.commit('EDIT_DEPARTMENTS')
    }
  },
  computed: {
    ...mapGetters(['DIALOGS', 'USER_ACCESS']),
  },
  watch: {
    USER_ACCESS: {
      handler(val) {
         this.userAccess.create = false;
         if(val != "fail"){
        val.forEach(e => {
          if (e.actions_code == 'create') {
            this.userAccess.create = true;
          }
        });
         }
      },
    },
  },
}
</script>
