<template>

<v-container fluid>

    <ComponentHeader
        :button="true"
        title='Pending Stock Card by Customer'
        type='Inventory'
    ></ComponentHeader>

    <v-text-field v-if="!loaded" color="info" loading disabled></v-text-field>

    <v-container fluid class="bg-bluish-gray  default-container">
        <v-row class="pa-2">
            <v-col class="col-sm-12 col-md-6">
                <v-autocomplete outlined dense :items="GET_CUSTOMERS_SELECTION"
                    :loading="loading.customer_selection"
                    :disabled="loading.customer_selection"
                    background-color="white"
                    v-model="customer_id"
                    item-value="value"
                    item-text="text"
                    placeholder="Select Customer"
                    class="small"
                >
                </v-autocomplete>
            </v-col>
            <v-col class="text-right">
                <v-btn small class="ml-1" v-on:click="resetFields()">Clear</v-btn>
                <v-btn small class="ml-1" v-on:click="getPendingSCByCustomers()">Filter</v-btn>
            </v-col>
        </v-row>
        <v-row class="row pa-2">
            <!--<div class="col-sm-12 col-md-4">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <v-checkbox
                            v-model="confirmed"
                            label="confirmed"
                            color="white"
                            input-value="1"
                            hide-details
                        ></v-checkbox>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <v-checkbox
                            v-model="unconfirmed"
                            label="unconfirmed"
                            color="white"
                            input-value="1"
                            hide-details
                        ></v-checkbox>
                    </div>
                </div>
            </div>-->
            <v-col lg="3" id="date_from">

                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="date_from"
                            readonly
                            outlined
                                    dense
                                    background-color="white"
                                    label="Date From"
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                </v-menu>
                <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
            </v-col>
            <v-col lg="3" id="date_to">

                <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="date_to"
                            readonly
                            outlined
                                    dense
                                    background-color="white"
                                    label="Date To"
                            append-icon="mdi-calendar-clock"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                </v-menu>
                <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
            </v-col>
        </v-row>

        <div class="w-100">
            <v-card v-show="loaded">
                <!--<v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                    >
                    </v-text-field>
                    <v-icon @click="searchInput()">fas fa-search</v-icon>
                </v-card-title>-->

                <v-container fluid>
                    <div class="row" v-if="stockCards.length > 0">
                        <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="px-2">
                            <v-card
                                class="total-cards"
                                elevation="2"
                            >
                                <v-card-text
                                    class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #28a745;">
                                        <v-icon class="text-white" style="font-size:40px">mdi-cart</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-0">Sales Total</p>
                                        <b style="color:black">&#x20b1;{{ sales_total_amount | currency }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="px-2">
                            <v-card
                                class="total-cards"
                                elevation="2"
                            >
                                <v-card-text
                                    class="d-flex justify-content-start align-items-center">
                                    <div class="card-icon pt-3 pb-3 pl-2 pr-2 rounded" style="background-color: #FFC107;">
                                        <v-icon class="text-white" style="font-size:40px">mdi-account-group</v-icon>
                                    </div>
                                    <div class="card-details ml-2">
                                        <p class="mb-0">Customers</p>
                                        <b style="color:black">{{ customer_count }}</b>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <div class="clearfix hidden-md-up"></div>

                    </div>

                    <v-data-table
                        :headers="headers"
                        :items="stockCards"
                        :search="search"
                        :options.sync="options"
                        :server-items-length="totalSalesReport"
                        :items-per-page="20"
                        :footer-props="{
                            itemsPerPageOptions: itemsPerPageOptions
                        }"
                        class="w-100"
                        dense
                    >

                        <template v-slot:[`item.total_amount_customer`]="{ item }">
                            <span class="text-nowrap">
                                {{ item.total_amount_customer | currency }}
                            </span>
                        </template>

                    </v-data-table>

                </v-container>
            </v-card>
        </div>
    </v-container>

</v-container>

</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
    export default {
        data(){
            return {
                errors: [],
                customer_id: '',
                customers_list: [],
                //salesman_id: '',
                //salesman_list: [],
                loaded: true,
                //confirmed: true,
                //unconfirmed: false,
                date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                sales_total_amount: '',
                customer_count: '',
                //confirmed_count: '',
                //unconfirmed_count: '',

                row: null,
                menu2: false,
                menu3: false,

                totalSalesReport: 0,
                options: {},
                stockCards: [],
                itemsPerPageOptions: [5, 10, 20, 50, 100],
                itemsPerPage: 10,
                search: '',
                awaitingSearch: false,
                headers: [
                    { text: 'Customer', align: 'left', value: 'customer_name', },
                    { text: 'Amount', align: 'right', value: 'total_amount_customer', },
                ],
                pl_headers: [
                    { text: 'Date', align: 'left', value: 'packing_list_date', },
                    { text: 'Customer', align: 'left', value: 'customer_name', },
                    { text: 'Remarks', align: 'left', value: 'remarks', },
                    { text: 'Salesman', align: 'left', value: 'salesman_name', },
                    { text: 'DR#', align: 'left', value: 'packing_list_num', },
                    { text: 'Amount', align: 'right', value: 'total_amount', },
                ],
                json_data: [],
                json_meta: [
                    [
                        {
                            'key': 'charset',
                            'value': 'utf-8'
                        }
                    ]
                ],
                initialLoad: false,

                loading:{
                        customer_selection      : true

                }
            }
        },
        components: {
            ComponentHeader
        },
        mounted: function (){
            this.getCustomers()
            // this.getSalesmanList()

        },
        computed:{
            ...mapGetters([
                'GET_CUSTOMERS_SELECTION',
            ])
        },
        methods: {
            getCustomers(){
                    this.$store.dispatch('getAllcustomersSelection')
                    this.customers_list = this.GET_CUSTOMERS_SELECTION
                    this.customers_list.push({
                        value: 'all',
                        text: 'Select All'
                    })
            },
            /*getSalesmanList(){
                axios.get('/getSalesmanList',{

                }).then(response => {
                    this.salesman_list = response.data
                });
            },*/
            getPendingSCByCustomers(){
                this.loaded = false
                let payload = {
                    customer_id:    this.customer_id,
                    page:           this.options.page,
                    itemsPerPage:   this.options.itemsPerPage,
                    sortBy:         this.options.sortBy,
                    sortDesc:       this.options.sortDesc,
                    search:         this.search,
                    //:    this.unconfirmed,
                    //confirmed:      this.confirmed,
                    //salesman_id:    this.salesman_id,
                    date_from:      this.date_from,
                    date_to:        this.date_to,
                    //is_packing_list_report: this.is_packing_list_report
                    url:            "getPendingSCByCustomers"
                }
                this.$store.dispatch('reports',payload).then(response => {
                    let data = response.data
                    this.stockCards             = data.stockCards
                    this.sales_total_amount     = data.sales_total_amount
                    this.loaded                 = true
                    this.totalSalesReport       = data.total
                    this.customer_count         = data.customer_count

                    this.initialLoad = true
                });
            },
            thousandSeprator(amount) {
                if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return amount;
                }
            },
            resetFields(){
                this.errors                 = []
                this.customer_id            = ''
                //this.salesman_id            = ''
                //this.confirmed              = true
                //this.unconfirmed            = false
                this.date_from              = ''
                this.date_to                = ''
                this.sales_total_amount     = ''
                this.customer_count         = ''
                //this.confirmed_count        = ''
                //this.unconfirmed_count      = ''
            }
        },
        watch: {
            options: {
                handler () {
                    if(this.initialLoad == true){
                        this.getPendingSCByCustomers()
                    }
                },
                deep: true,
            },
            'GET_CUSTOMERS_SELECTION':{
            handler(val){

                this.loading.customer_selection = false
            }
        },

        }
    };
</script>
<style>
    .col{
        padding: 2px !important;
    }

    .card-body{
        padding: 3px !important;
    }
</style>
