    <template>

    <v-container>
        <ComponentHeader
                :button="true"
                :title="'SO Converted To WPF'"
                :type="'Sales'"
        ></ComponentHeader>
    <v-row>
        <div class="container bg-bluish-gray default-container m-3">
        <v-app id="load_bar" v-if="!loaded">
            <v-text-field color="info" loading disabled></v-text-field>
        </v-app>

        <v-app id="my_vue_app" class="bg-bluish-gray">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <v-autocomplete
                        background-color="white"
                        :items="GET_CUSTOMERS_SELECTION"
                        :loading="loading.customer_selection"
                        :disabled="loading.customer_selection"
                        multiple
                        deletable-chips
                        chips
                        outlined
                        dense
                        v-model="selectedCustomers"
                        label="Select Customers"
                        class="d-inline-block w-100">
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-3">
                    <v-autocomplete
                        background-color="white"
                        outlined
                        dense
                        :items="GET_SALESMAN_SELECTION"
                        :loading="loading.salesman_selection"
                        :disabled="loading.salesman_selection"
                        v-model="salesman_id"
                        item-value="value"
                        item-text="text"
                        placeholder="Select Salesman"
                        class="d-inline-block small"
                    >
                    </v-autocomplete>
                </div>
                <div class="col-sm-12 col-md-3 text-right">
                    <v-btn  v-on:click="resetFields()">Clear</v-btn>
                    <v-btn  v-on:click="exportConvertedWPF()">Export</v-btn>
                    <v-btn  v-on:click="getSOConvertedToWPF()">Filter</v-btn>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3" id="date_from">
                    <label class="float-left control-label col-form-label">Date From</label>
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_from"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </div>
                <div class="col-sm-6 col-md-3" id="date_to">
                    <label class="float-left control-label col-form-label">Date To</label>
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                readonly
                                append-icon="mdi-calendar-clock"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                    <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                </div>
            </div>


            <div class="w-100"  v-if="dataItems != ''">
                <v-card v-show="loaded">

                    <v-container fluid>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-3">
                                <div class="info-box mb-3">
                                    <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">Customers</span>
                                        <span class="info-box-number">{{ customer_count }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix hidden-md-up"></div>
                        </div>

                        <div class="container" v-for="(d, i) in dataItems" :key="i" v-show="loaded">
                            <div class="row">
                                <div class="col-12 d-flex no-block align-items-cnter">
                                    <h6 class="report-title">{{ d.company_name }}</h6>
                                </div>
                            </div>

                            <v-app id="my_vue_app">
                                <v-card>
                                    <v-container fluid>
                                        <v-data-table
                                        :headers="headers"
                                        :items="d.data_items"
                                        class="w-100"
                                        dense
                                        >
                                        <template v-slot:[`item.so_date`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ item.so_date | formatDate }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.date_created`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ item.date_created | formatDate }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ item.total_amount | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.action`]="{ item }">
                                            <v-btn text icon color="orange">
                                                <v-icon class="btn-action" small @click="viewConvertedWPF(item)">fas fa-eye</v-icon>
                                            </v-btn>
                                    </template>
                                    </v-data-table>
                                    </v-container>
                                </v-card>
                            </v-app>
                        </div>
                    </v-container>
                </v-card>
            </div>

            <div class="text-center">

                <v-dialog v-model="view_dialog" persistent max-width="98%" scrollable>
                    <v-card>
                        <v-row class="m-0">
                            <v-card-title>
                                <span class="headline">Converted in WPF</span>
                            </v-card-title>
                            <v-col cols="pull-right-10 p-2">
                                <v-btn text icon color="gray" class="float-right" @click="clearDialog(); $emit('closeView');">
                                    <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-card-text>
                            <v-data-table
                                :headers="view_headers"
                                :items="view_items"
                                :items-per-page="500"
                                hide-default-footer
                                class="w-100"
                            >
                            <template v-slot:[`item.srp`]="{ item }">
                                <span class="text-nowrap">
                                    {{ item.srp | currency }}
                                </span>
                            </template>
                            <template v-slot:[`item.amount`]="{ item }">
                                <span class="text-nowrap">
                                    {{ item.amount | currency }}
                                </span>
                            </template>
                            <template v-slot:[`item.view_total_amount`]="{ item }">
                                <span class="text-nowrap">
                                    {{ item.view_total_amount | currency }}
                                </span>
                            </template>

                            </v-data-table >

                            <v-divider></v-divider>
                                <v-row class="m-0">
                                    <v-container fluid>
                                        <v-row>
                                            <div class="col-sm-12 col-md-8 text-left">
                                                <div class="row ml-2">

                                                </div>
                                                <div class="row ml-2">

                                                </div>

                                            </div>
                                            <div class="col-sm-12 col-md-4 pull-right text-right">
                                                <h5>Total Amount: &#x20b1;{{ viewing_total_amount | currency }}</h5>
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </v-app>
    </div>
    </v-row>
    </v-container>




    </template>

    <script>
    import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue';
    import moment from 'moment';
    const ExcelJS = require('exceljs')
    const FileSaver = require('file-saver');
    import swal from 'sweetalert2'
    import { mapGetters } from 'vuex';
    export default {
        data(){
            return {
                errors: [],
                loaded: true,

                customers_list:[],
                customer_count:'',
                selectedCustomers: [],
                salesman_id: '',
                salesman_list: [],
                view_dialog: false,
                viewing_total_amount:'',

                date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
                date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                menu2: false,
                menu3: false,

                dataItems:[],
                items: [],
                headers: [
                    { text: 'SO #', width:'15%', value: 'so_num'},
                    { text: 'SO Date', width:'15%', value: 'so_date', },
                    { text: 'WPF #', width:'15%', value: 'wpf_num', },
                    { text: 'Date Created', width:'20%', value: 'date_created' },
                    { text: 'Total Amount', width:'20%', value: 'total_amount' },
                    { text: 'Actions', width:'10%', value: 'action' }
                ],

                view_headers:[
                    { text: 'Model', align: 'left', value: 'model'},
                    { text: 'Name', align: 'left', value: 'name'},
                    { text: 'UOM', align: 'left', value: 'uom'},
                    { text: 'Type', align: 'left', value: 'type'},
                    { text: 'Category', align: 'left', value: 'category'},
                    { text: 'Quantity', align: 'left', value: 'quantity'},
                    { text: 'SRP', align: 'left', value: 'srp', },
                    { text: 'FOC', align: 'left', value: 'foc', },
                    { text: 'Amount', align: 'left', value: 'amount' },
                    { text: 'Total Amount', align: 'right', value: 'view_total_amount' },
                ],
                view_items: [],

                loading:{
                        customer_selection      : true,
                        salesman_selection      : true,
                }
            }
        },
        components: {
            ComponentHeader
        },
        mounted: function (){
            this.$store.dispatch('getAllcustomersSelection')
            this.$store.dispatch('getAllsalesmanSelection')
        },
        computed:{
            ...mapGetters([
                    'GET_SALESMAN_SELECTION',
                    'GET_CUSTOMERS_SELECTION',
                ]),
        },
        methods: {
            upperCaseFirstLetter(str){
                    const arr = str.split(" ");
                    for (var i = 0; i < arr.length; i++) {
                        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                    str = arr.join(" ");
                    return str
                },
                clearDialog(){
                    this.view_dialog = false
                    this.view_items = []
                },
                onSelectCustomers (items) {
                    this.selectedCustomers = items
                },
                async getSOConvertedToWPF(){
                        this.loaded = false
                        await this.$store.dispatch('reports',{
                            date_from:     this.date_from,
                            date_to:       this.date_to,
                            customer_id:   this.selectedCustomers.map(e=>{return e.value}),
                            salesman_id:   this.salesman_id,
                            url:           'getSOConvertedToWPF'
                        }).then(response => {
                            this.dataItems              = response.data.customers
                            this.customer_count         = response.data.customer_count
                            this.loaded                 = true
                        })
                    },

                viewConvertedWPF(item)
                {
                    this.$store.dispatch('reports',{
                            so_id:     item.id,
                            url:        'viewSOConvertedToWPF'
                    }).then(response => {
                        this.view_items           = response.data.details
                        this.viewing_total_amount = response.data.total_amount;
                        this.view_dialog          = true;
                    })
                },

                exportConvertedWPF(){
                this.loaded = false
                const workbook = new ExcelJS.Workbook();
                this.$store.dispatch('reports',{
                    date_from:     this.date_from,
                    date_to:       this.date_to,
                    customer_id:   this.selectedCustomers.map(e=>{return e.value}),
                    salesman_id:   this.salesman_id,
                    url:           'exportSOConvertedToWPF'
                }).then(response => {
                    const worksheet = workbook.addWorksheet('SO Converted To WPF')
                    let headers = [
                        { header:'Customers', key:'company_name' },
                        { header:'SO Num', key:'so_num' },
                        { header:'SO Date', key:'so_date' },
                        { header:'WPF Num', key:'wpf_date' },
                        { header:'Date Created', key:'date_created' },
                        { header:'Model', key:'model' },
                        { header:'Name', key:'name' },
                        { header:'UOM', key:'uom' },
                        { header:'Type', key:'type' },
                        { header:'Category', key:'category' },
                        { header:'Quantity', key:'quantity' },
                        { header:'SRP', key:'srp' },
                        { header:'FOC', key:'foc' },
                        { header:'Amount', key:'amount' },
                        { header:'Total Amount', key:'total_amount' },

                    ]

                    worksheet.columns = headers

                    response.data.forEach(e => {
                        worksheet.addRow(e);

                    });

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        FileSaver.saveAs(blob, `SO Converted To WPF ${this.date_from} - ${this.date_to}.xlsx`);
                    });
                    this.loaded                 = true
                }).catch(e=>{
                    console.log(e)
                    swal.fire('Error!','- An error occured','error')
                    this.loaded                 = true
                })

                },

                resetFields()
                {
                    this.errors =[],
                    this.loaded = true,
                    this.dataItems = [],
                    this.items = [],
                    this.customers_lista = [],
                    this.customer_count = '',
                    this.selectedCustomers = [],
                    this.salesman_id = '',
                    this.salesman_list = [],
                    this.view_dialog = false,
                    this.date_from = moment().clone().startOf('month').format('YYYY-MM-DD'),
                    this.date_to = moment().clone().endOf('month').format('YYYY-MM-DD'),
                    this.menu2 = false,
                    this.menu3 = false
                }
            },
            watch:{
                'GET_CUSTOMERS_SELECTION':{
                    handler(val){
                        this.customers_list = val
                        this.loading.customer_selection = false
                    }
                },
                'GET_SALESMAN_SELECTION':{
                    handler(val){
                        this.salesman_list = val
                        this.loading.salesman_selection = false
                    }
                },
            }
    }
    </script>
