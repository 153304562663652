<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <div class="row">
                <div class="col-12 align-items-center px-0" v-if="userAccess.create">
                    <div class="d-flex btn-toolbar justify-content-between">
                        <v-btn v-if="tab_name === 0" data-modal-id="addDispatchModal" @click="showAddDispatch()">
                            Add
                        </v-btn>
                        <v-btn class="ml-auto" data-modal-id="addDispatchModal" @click="exportDispatch()" :loading="loading_button"
                            v-if="userAccess.export_dispatch">
                            Export
                        </v-btn>
                    </div>

                </div>

            </div>

            <div class="row p-0">
                <!-- Dispatches Table -->
                <DispatchesTableComponentVue :tab_name="tab_name" :dispatchesActionResult="action" @showEditModal="showEditModal">
                </DispatchesTableComponentVue>
            </div>
        </v-container>

        <v-app id="addEditDispatchModal">
            <v-dialog v-model="add_edit_dispatch_dialog" persistent :max-width="dispatch_status === 1 ? '600px' : '98%'" scrollable>
                <v-card v-if="dispatch_status !== 1">
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Dispatch Information</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="add_edit_dispatch_dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text v-if="loaded == true">
                        <form class="form-horizontal" action="/dispatches" method="post" @submit.prevent="onSubmit">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <label for="user_id"
                                            class="float-left control-label col-form-label">Vehicle</label>
                                        <v-autocomplete :items="vehicles" :loading="loading.vehicle_selection"
                                            :disabled="loading.vehicle_selection" :readonly="dispatch_status === 1" dense outlined v-model="vehicle_id"
                                            item-text="name" item-value="id" placeholder="Select Vehicle"
                                            @input="getVehicleCBM()" class="d-inline-block w-100">
                                        </v-autocomplete>
                                        <span v-for="errors in errors.vehicle_id" class="text-warning" :key="errors">{{
                                            errors
                                        }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-3" >
                                        <label for="dispatcher_name"
                                            class="float-left control-label col-form-label">Dispatcher</label>
                                        <input type="text" class="form-control" id="code" placeholder="Dispatcher" :disabled="user.details.is_service" :readonly="dispatch_status === 1"
                                            v-model="dispatcher_name">
                                        <span v-for="errors in errors.dispatcher_name" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>

                                    <div class="col-sm-12 col-md-3">
                                        <label for="forwarder_name"
                                            class="float-left control-label col-form-label">Forwarder Name</label>
                                        <input type="text" class="form-control" id="code" placeholder="Forwarder Name"
                                            v-model="forwarder_name" :disabled="disabled_field" :readonly="dispatch_status === 1" required>
                                        <span v-for="errors in errors.forwarder_name" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>

                                    <div class="col-sm-12 col-md-3" v-if="this.USERACCOUNT_LOAD.super_admin == 1 || this.USERACCOUNT_LOAD.manager == 1">
                                        <label for="negotiated_price"
                                            class="float-left control-label col-form-label">Negotiated Price</label>
                                        <input type="number" class="form-control" id="code" :disabled="disabled_field" :readonly="dispatch_status === 1" placeholder="0.00"
                                            v-model="negotiated_price">
                                        <span v-for="errors in errors.negotiated_price" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6 col-md-3" id="dispatch_date">
                                        <label for="Dispatch Date">
                                            <label class="float-left control-label col-form-label">Dispatch Date</label>
                                        </label>
                                        <v-menu :disabled="dispatch_status === 1" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="dispatch_datetime" readonly
                                                    append-icon="mdi-calendar-clock" v-on="on" placeholder="Dispatch Date"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="dispatch_date" :readonly="dispatch_status === 1"></v-date-picker>
                                            <v-time-picker format="24hr" v-model="dispatch_time"
                                                @input="mergeDateTime()" :readonly="dispatch_status === 1"></v-time-picker>
                                        </v-menu>
                                        <span v-for="errors in errors.dispatch_datetime" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>

                                   <div class="col-sm-6 col-md-3" id="actual">
                                        <label for="Actual Date of Arrival">
                                            <label class="float-left control-label col-form-label">ATA</label>
                                        </label>
                                        <v-menu :disabled="dispatch_status === 1" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="actual.date_time" readonly
                                                    append-icon="mdi-calendar-clock" v-on="on" placeholder="Actual Date of Arrival"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="actual.date" :readonly="dispatch_status === 1"></v-date-picker>
                                            <v-time-picker format="24hr" v-model="actual.time"
                                                @input="mergeActualDateTime()" :readonly="dispatch_status === 1"></v-time-picker>
                                        </v-menu>
                                        <span v-for="errors in errors.actual" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>

                                    <div class="col-sm-6 col-md-3" id="estimated">
                                        <label for="Estimated Date of Arrival">
                                            <label class="float-left control-label col-form-label">ETA</label>
                                        </label>
                                        <v-menu v-model="menu4"
                                         :close-on-content-click="false"
                                         :nudge-right="40"
                                         :disabled="disabled_field"
                                            transition="scale-transition" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="estimated.date_time" readonly
                                                    append-icon="mdi-calendar-clock" v-on="on" placeholder="Estimated Date of Arrival"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="estimated.date"></v-date-picker>
                                            <v-time-picker format="24hr" v-model="estimated.time"
                                                @input="mergeEstimatedDateTime()"></v-time-picker>
                                        </v-menu>
                                        <span v-for="errors in errors.estimated" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>
                                    <div class="col-sm-6 col-md-3" id="estimated" v-if="!user.details.is_service">
                                        <label for="Estimated Date of Arrival">
                                            <label class="float-left control-label col-form-label">Dispatch #</label>
                                        </label>
                                        <v-autocomplete
                                            :items="dispatch_list_ids"
                                            v-model="dispatch_ids"
                                            label="Select Dispatch id"
                                            multiple
                                            @change="getDispatchDetails()"
                                            :readonly="dispatch_status === 1"
                                        >

                                        </v-autocomplete>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <label for="driver_name"
                                            class="float-left control-label col-form-label">Driver</label>
                                        <input type="text" class="form-control" id="driver_name" placeholder="Driver"
                                            v-model="driver_name" :readonly="dispatch_status === 1">
                                        <span v-for="errors in errors.driver_name" class="text-warning" :key="errors">{{
                                            errors
                                        }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <label for="helper"
                                            class="float-left control-label col-form-label">Helper</label>
                                        <input type="text" class="form-control" id="helper" placeholder="Helper"
                                            v-model="helper" :readonly="dispatch_status === 1">
                                        <span v-for="errors in errors.helper" class="text-warning" :key="errors">{{
                                            errors
                                        }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <label for="helper_2" class="float-left control-label col-form-label">Helper
                                            2</label>
                                        <input type="text" class="form-control" id="helper_2" placeholder="Helper 2"
                                            v-model="helper_2" :readonly="dispatch_status === 1">
                                        <span v-for="errors in errors.helper_2" class="text-warning" :key="errors">{{
                                            errors
                                        }}</span>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <label for="helper_3" class="float-left control-label col-form-label">Helper
                                            3</label>
                                        <input type="text" class="form-control" id="helper_3" placeholder="Helper 3"
                                            v-model="helper_3" :readonly="dispatch_status === 1">
                                        <span v-for="errors in errors.helper_3" class="text-warning" :key="errors">{{
                                            errors
                                        }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-md-4">
                                        <v-textarea label="Remarks" no-resize rows="2" :readonly="dispatch_status === 1" v-model="remarks"
                                            class="p-0"></v-textarea>
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <v-textarea label="Additional Remarks" no-resize rows="2" :readonly="dispatch_status === 1" v-model="additional_remarks"
                                            class="p-0"></v-textarea>
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <v-textarea label="Guard Name" no-resize rows="2" :readonly="dispatch_status === 1" v-model="guard_name"
                                            class="p-0"></v-textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3">
                                        <label class="info-box-text">Remaining: {{ cbm_remaining }}</label>
                                    </div>

                                    <div class="col-sm-12 col-md-3">
                                        <label class="info-box-text">Items CBM: {{ cbm_total }}</label>
                                    </div>

                                    <div class="col-sm-12 col-md-3">
                                        <label class="info-box-text">Vehicle CBM: {{ vehicle_cbm }}</label>
                                    </div>
                                </div>
                                <span v-for="(card,i) in cards.titles" :key="i">
                                    <v-card outlined class="p-2" v-if="userAccess[card.model]">
                                        <v-card-title>
                                            {{ card.title }}
                                        </v-card-title >
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="float-right mx-2">
                                                    <v-btn fab dark small color="primary" style="height:20px; width:20px;"
                                                        @click="addDispatchItem(i)" :disabled="dispatch_status === 1">
                                                        <v-icon dark small style="font-size:12px">
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn fab dark small color="primary" style="height:20px; width:20px;"
                                                        @click="removeDispatchItem(i)" :disabled="dispatch_status === 1">
                                                        <v-icon dark small style="font-size:12px">
                                                            mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <v-simple-table dense class="dynamic_list_table">
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center mx-2" :width="card.title === 'Delivery Receipt' ? '41%' : '55%'">Customer</th>
                                                                <th class="text-center mx-2" width="14%">#</th>
                                                                <th v-if="card.title === 'Delivery Receipt'" class="text-center mx-2" width="14%">CF#</th>
                                                                <th class="text-center mx-2" width="14%">SO#</th>
                                                                <!-- <th class="text-center mx-2" width="7%">CBM</th> -->
                                                                <th class="text-center mx-2" width="14%">No. of Boxes</th>
                                                                <th class="text-center mx-2" width="3%"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr  v-for="(input,index) in card.inputs" :key="index">
                                                                <td>
                                                                    <v-autocomplete
                                                                        v-model="input.customer_id"
                                                                        dense outlined
                                                                        :disabled="!user.details.is_service && card.service == 1" :readonly="dispatch_status === 1"
                                                                        :items="GET_CUSTOMERS_SELECTION"
                                                                        item-value="value" item-text="text"
                                                                        placeholder="Select Customer"
                                                                        class="d-inline-block small w-100"
                                                                        @input="selectCustomer(i,index, card)">
                                                                    </v-autocomplete>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        <v-text-field
                                                                            v-if="(card.title === 'Spare Parts' || card.title === 'Delivery Receipt') && (!user.details.is_service && card.service == 1)"
                                                                            :value="input[input.id] ? input[input.id].map(element => element.text) : ''"
                                                                            :disabled="!user.details.is_service && card.service == 1"
                                                                            background-color="#ECEFF1"
                                                                            class="d-inline-block small w-100"
                                                                            :readonly="dispatch_status === 1"
                                                                            outlined
                                                                            dense
                                                                        />
                                                                        <v-autocomplete
                                                                            v-else
                                                                            v-model="input[input.id]"
                                                                            :disabled="!user.details.is_service && card.service == 1" :readonly="dispatch_status === 1"
                                                                            :items="input.list"
                                                                            :placeholder="'Select '+card.text"
                                                                            class="d-inline-block small w-100"
                                                                            outlined
                                                                            dense
                                                                            multiple
                                                                            return-object
                                                                            @input="onSelectPLs(i,index, input[input.id],input)"
                                                                        ></v-autocomplete>
                                                                    </span>
                                                                </td>
                                                                <td v-if="card.title === 'Delivery Receipt'">
                                                                    <span>
                                                                        <v-text-field
                                                                            v-model="input.cf_num"
                                                                            :disabled="!user.details.is_service && card.service == 1"
                                                                            :placeholder="'CF# '+'('+card.text+')'"
                                                                            background-color="#ECEFF1"
                                                                            class="d-inline-block small w-100"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                        ></v-text-field>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        <v-text-field
                                                                            v-if="card.title === 'Spare Parts' || card.title === 'Delivery Receipt'"
                                                                            :value="input[input.id] ? input[input.id].map(element => element.so_nums) : ''"
                                                                            disabled
                                                                            background-color="#ECEFF1"
                                                                            class="d-inline-block small w-100"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                        />
                                                                        <v-text-field
                                                                            v-else
                                                                            v-model="input.so_num"
                                                                            :disabled="!user.details.is_service && card.service == 1"
                                                                            :placeholder="'SO# '+'('+card.text+')'"
                                                                            background-color="#ECEFF1"
                                                                            class="d-inline-block small w-100"
                                                                            readonly
                                                                            outlined
                                                                            dense
                                                                        ></v-text-field>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        v-model="input.no_of_boxes"
                                                                        :disabled="!user.details.is_service && card.service == 1" :readonly="dispatch_status === 1"
                                                                        class="form-control text-center d-inline-block"
                                                                        type="text"
                                                                        @keypress="numberOnly">
                                                                </td>

                                                                <td class="text-center">
                                                                    <v-btn fab dark small color="primary"
                                                                        style="height:20px; width:20px;"
                                                                        :disabled="dispatch_status === 1"
                                                                        @click="removeDispatchItemByIndex(i,index)">
                                                                        <v-icon dark small style="font-size:12px">
                                                                            mdi-minus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </div>
                                    </v-card>
                                </span>
                            </div>
                            <div class="border-top">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn @click="addNewDispatch()" v-if="action == 'Add'" :loading="loading.button">Submit</v-btn>
                                    <v-btn @click="editDispatch()" v-else-if="action == 'Edit'" :loading="loading.button">Update</v-btn>
                                </div>
                            </div>
                        </form>
                    </v-card-text>
                    <v-card-text v-else-if="loaded == false">
                        <div class="text-center">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card v-else> <!-- for edit confirmed -->
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Dispatch ID: {{ editDispatchId }}</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="add_edit_dispatch_dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-text v-if="loaded == true">
                        <form class="form-horizontal" action="/dispatches" method="post" @submit.prevent="onSubmit">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6" id="actual">
                                        <label for="Actual Date of Arrival">
                                            <label class="float-left control-label col-form-label">ATA</label>
                                        </label>
                                        <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="actual.date_time" readonly
                                                    append-icon="mdi-calendar-clock" v-on="on" placeholder="Actual Date of Arrival"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="actual.date"></v-date-picker>
                                            <v-time-picker format="24hr" v-model="actual.time"
                                                @input="mergeActualDateTime()"></v-time-picker>
                                        </v-menu>
                                        <span v-for="errors in errors.actual" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>

                                    <div class="col-6" id="estimated">
                                        <label for="Estimated Date of Arrival">
                                            <label class="float-left control-label col-form-label">ETA</label>
                                        </label>
                                        <v-menu v-model="menu4"
                                         :close-on-content-click="false"
                                         :nudge-right="40"
                                            transition="scale-transition" min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="estimated.date_time" readonly
                                                    append-icon="mdi-calendar-clock" v-on="on" placeholder="Estimated Date of Arrival"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="estimated.date"></v-date-picker>
                                            <v-time-picker format="24hr" v-model="estimated.time"
                                                @input="mergeEstimatedDateTime()"></v-time-picker>
                                        </v-menu>
                                        <span v-for="errors in errors.estimated" class="text-warning"
                                            :key="errors">{{ errors }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="border-top">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn class="mr-8" color="error" @click="add_edit_dispatch_dialog = false" v-if="action == 'Edit' && dispatch_status === 1">Cancel</v-btn>
                                    <v-btn @click="addNewDispatch()" v-if="action == 'Add'" :loading="loading.button">Submit</v-btn>
                                    <v-btn @click="editDispatch()" v-else-if="action == 'Edit'" :loading="loading.button">Update</v-btn>
                                </div>
                            </div>
                        </form>
                    </v-card-text>
                    <v-card-text v-else-if="loaded == false">
                        <div class="text-center">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-app>

    </div>
</template>

<script>
import DispatchesTableComponentVue from './tables/DispatchesTableComponent.vue'
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
    mixins: [SharedFunctionsComponentVue],
    props: [
        'tab_name'
    ],
    data() {
        return {
            editDispatchId: '',
            add_edit_dispatch_dialog: false,
            dispatch_id: '',
            dispatch_date: '',
            dispatch_time: '',
            actual:{
                date: '',
                time: '',
                date_time: ''
            },
            estimated: {
                date: '',
                time: '',
                date_time: ''
            },
            dispatch_status: null,
            dispatch_datetime: '',
            vehicle_id: '',
            dispatcher_name: '',
            driver_name: '',
            dispatch_ids:[],
            dispatch_list_ids:[],
            helper: '',
            helper_2: '',
            helper_3: '',
            remarks: '',
            additiona_remarks: '',
            dispatchItems: [],
            dispatchItem: {
                customer_name: '',
                customer_id: '',
                quantity: '',
                filtered_packing_lists: [],
                selected_pls: [],
                cbm: 0,
            },
            vehicles: [],
            packingLists: [],
            customers_list: [],

            cbm_remaining: 0,
            vehicle_cbm: 0,
            cbm_total: 0,

            errors: [],
            action: '',
            actionResult: {
                model: 'Dispatch',
                action: ''
            },
            loaded: false,
            menu2: false,
            menu3: false,
            menu4:false,
            max_dispatch_items_count: 50,
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                export_dispatch: false,
                packing_list:false,
                spare_parts:false,
                stock_card:false,
            },
            loading: {
                vehicle_selection: true,
                customer_selection: true,
                button:false
            },
            loading_button: false,
            export_arr: {
                packing_lists: [],
                stock_cards: [],
            },
            statuses: null,
            boolean_export: false,
            validate_tpl: null,
            disabled_field: true,
            forwarder_name: '',
            required_field: false,
            negotiated_price: 0,
            additional_remarks: '',
            guard_name:'',
            estimated_date_input: false,
            cards:{
                titles:[
                    { text:'Stock Card', service:0,},
                    { text:'Delivery Receipt', service:0,},
                    // { text:'Delivery Receipt', service:1,},
                    // { text:'Spare Parts', service:1,},
                ],
            },
            user:{
                exceptions:[328,5],
                details:{}
            },

        }
    },
    components: {
        DispatchesTableComponentVue
    },
    mounted() {
        this.getCurrentUser()
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch(
            'checkAccessUser',
            this.$router.currentRoute.params.id
        );
        this.$store.dispatch('getAllcustomersSelection')
    },
    computed: {
        ...mapGetters([
            'GET_VEHICLES',
            'POST_GET_CUSTOMERS',
            'GET_PACKING_LISTS',
            'GET_VEHICLE_CBM',
            'GET_ITEM_CBM',
            'GET_STORE_DISPATCHES',
            'GET_DISPATCH_EDIT_INFO',
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_CUSTOMERS_SELECTION'
        ]),
    },
    methods: {
        onSelectPLs(i,index,card,a) {
            this.$forceUpdate()
            // card.forEach(e=>{
            //     e.number_of_boxes = e.num_of_box
            // })
            // debugger
            this.cards.titles[i].inputs[index].no_of_boxes = this.sumArraybyAttribute(card,'number_of_boxes')
            let cf_num = card.map(e=>e.cf_num);
            let so_num = card.map(e=>e.so_num);
            this.$set(this.cards.titles[i].inputs[index], 'cf_num', cf_num)
            this.$set(this.cards.titles[i].inputs[index], 'so_num', so_num)
        },
        async selectCustomer(key,index, card, action = '') {

            await this.$store.dispatch('dispatchPost',{
                url:'dropdowns',
                customer_id:card.inputs[index].customer_id,
                title:card.title
            }).then(response =>{

                this.cards.titles[key].inputs[index].list = response.data

                if(action == 'edit' && response.data.length > 0){
                    this.cards.titles[key].inputs[index][this.cards.titles[key].id].forEach(e=>{

                        if (this.cards.titles[key].inputs[index].list.find(x=> x.text == e.value)) {
                            e.text = this.cards.titles[key].inputs[index].list.find(x=> x.text == e.value).text
                            e.value = this.cards.titles[key].inputs[index].list.find(x=> x.text == e.value).value
                        }
                    })
                }

                if (this.cards.titles[key].inputs[index][this.cards.titles[key].id].length > 0) {
                    let cfs = [];
                    let sos = [];

                    this.cards.titles[key].inputs[index][this.cards.titles[key].id] = this.cards.titles[key].inputs[index][this.cards.titles[key].id]
                    .map((item1, i) => {
                        const matched = response.data.find(item2 => item1.value == item2.value);
                        if (matched) {
                            if (this.cards.titles[key].id === 'packing_list_id') cfs.push(this.cards.titles[key].inputs[index].cf_num[i]);
                            sos.push(this.cards.titles[key].inputs[index].so_num[i]);
                            return item1;
                        }
                    }).filter(item => item != null);

                    this.cards.titles[key].inputs[index].cf_num = cfs;
                    this.cards.titles[key].inputs[index].so_num = sos;
                }

            })
            this.$forceUpdate()
            return
        },
        addDispatchItem(i) {
            if (this.cards.titles[i].inputs.length < this.max_dispatch_items_count) {
                this.cards.titles[i].inputs.push({
                    no_of_boxes:0,
                    customer_id:'',
                    cbm:0,
                    [this.cards.titles[i].id]:'',
                    id:this.cards.titles[i].id,
                    cf_num:this.cards.titles[i].cf_num,
                    so_num:this.cards.titles[i].so_num,
                })
            } else {
                swal.fire('',`Only ${this.max_dispatch_items_count} items is allowed`,'error');
            }
            this.$forceUpdate()
        },
        removeDispatchItem(i) {

            if (this.cards.titles[i].inputs.length > 1) {
                this.cards.titles[i].inputs.pop();
            }
        },
        removeDispatchItemByIndex(i,index) {
            if (this.cards.titles[i].inputs.length > 1) {
                this.cards.titles[i].inputs = this.cards.titles[i].inputs.filter((e,x)=>{
                    if(index != x){
                        return e
                    }
                })
            }
            this.$forceUpdate()
        },
        showAddDispatch() {
            this.add_edit_dispatch_dialog = true
            this.action = 'Add'
        },
        addNewDispatch() {
            if( this.disabled_field == false){
                if(!this.forwarder_name){
                    swal.fire('','The Forwarder Name is required','warning');
                    return
                }
            }

            this.loading.button = true
            this.errors = []
            // let vehicle = (Number(this.vehicle_cbm) + Number((this.vehicle_cbm * 0.10)))
            let cards = this.cards.titles.filter(e=>{
                if(!!e.hasOwnProperty('inputs')){
                    e.inputs.filter(el=>{
                        el.list = []
                        return el
                    })
                }
                return e
            })
            this.loading.button = true
                let payload = {
                    dispatch_datetime: this.dispatch_datetime,
                    vehicle_id: this.vehicle_id,
                    dispatcher_name: this.dispatcher_name,
                    driver_name: this.driver_name,
                    helper: this.helper,
                    helper_2: this.helper_2,
                    helper_3: this.helper_3,
                    remarks: this.remarks,
                    vehicle_cbm: this.vehicle_cbm,
                    cbm_total: this.cbm_total,
                    cbm_remaining: this.cbm_remaining,
                    dispatchItems: this.dispatchItems,
                    forwarder_name: this.forwarder_name,
                    additional_remarks: this.additional_remarks,
                    actual_datetime: this.actual.date_time,
                    estimated_datetime: this.estimated.date_time,
                    negotiated_price: this.negotiated_price,
                    cards:cards,
                    guard_name:this.guard_name,
                    dispatch_ids:this.dispatch_ids
                }

                this.$store.dispatch('storeDispatches', payload).then(response => {
                    let data = response.data
                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    this.resetFields()
                    this.add_edit_dispatch_dialog = false
                    this.loading.button = false
                    this.action = 'success'
                    swal.fire('', response.data.msg, "success");
                    this.add_edit_dispatch_dialog = false
                }).catch(error => {
                    this.loading.button = false
                    this.errors = error.response.data.errors
                });
            // if (this.cbm_total > vehicle) {
            //     swal.fire({
            //         title: 'Items CBM exceed Are you sure do you want to Proceed?',
            //         text: 'Make sure that all information are correct',
            //         icon: 'warning',
            //         showConfirmButton: true,
            //         showCancelButton: true,
            //         reverseButtons: true,
            //         allowOutsideClick: false,
            //         confirmButtonColor: '#397373',
            //         cancelButtonColor: 'grey',
            //         confirmButtonText: 'Confirm',

            //     }).then((result) => {
            //         if (result.isConfirmed) {
            //             this.loading.button = true
            //             this.cards.titles.filter(e=>{
            //                 let count = e.inputs.reduce((accumulator, object) => {
            //                     return accumulator + object.no_of_boxes;
            //                 }, 0)

            //                 if(count == 0 ){
            //                     return e
            //                 }
            //             })

            //             let payload = {
            //                 dispatch_datetime: this.dispatch_datetime,
            //                 vehicle_id: this.vehicle_id,
            //                 dispatcher_name: this.dispatcher_name,
            //                 driver_name: this.driver_name,
            //                 helper: this.helper,
            //                 helper_2: this.helper_2,
            //                 helper_3: this.helper_3,
            //                 remarks: this.remarks,
            //                 vehicle_cbm: this.vehicle_cbm,
            //                 cbm_total: this.cbm_total,
            //                 cbm_remaining: this.cbm_remaining,
            //                 dispatchItems: this.dispatchItems,
            //                 forwarder_name: this.forwarder_name,
            //                 additional_remarks: this.additional_remarks,
            //                 actual_datetime: this.actual.date_time,
            //                 estimated_datetime: this.estimated.date_time,
            //                 negotiated_price: this.negotiated_price,
            //                 cards:cards
            //             }
            //             this.$store.dispatch('storeDispatches', payload).then(response => {
            //                 let data = response.data
            //                 if (data.error) {
            //                     swal.fire("", data.error, "warning");
            //                     this.loading.button = false
            //                     return false
            //                 }
            //                 this.resetFields()
            //                 this.add_edit_dispatch_dialog = false
            //                 this.action = 'success'
            //                 this.loading.button = false
            //                 swal.fire('', response.data.msg, "success");
            //                 this.loading.button = false
            //             }).catch(error => {
            //                 if (error.response.status == 422) {
            //                     this.loading.button = false
            //                     this.errors = error.response.data.errors
            //                     this.loading.button = false
            //                 }
            //             });
            //         }
            //         else{
            //             this.loading.button = false
            //         }
            //     })
            // } else {


            // }

        },
        async showEditModal(value) {
            this.action = 'Edit'
            this.editDispatchId = value
            this.structureData()

            if (this.editDispatchId != null && this.editDispatchId > 0) {
                this.getPackingLists()

                await this.$store.dispatch('getDispatchEditInfo', this.editDispatchId).then(response => {
                    let data = this.GET_DISPATCH_EDIT_INFO

                    this.dispatch_status = data.status
                    this.dispatch_id = data.dispatch_id
                    this.dispatch_datetime = data.dispatch_date
                    this.vehicle_id = data.vehicle_id
                    this.dispatcher_name = data.dispatcher_name
                    this.driver_name = data.driver_name
                    this.helper = data.helper
                    this.helper_2 = data.helper_2
                    this.helper_2 = data.helper_2
                    this.remarks = data.remarks
                    this.vehicle_cbm = data.vehicle_cbm
                    this.cbm_total = data.total_items_cbm
                    this.cbm_remaining = data.remaining_cbm
                    this.dispatchItems = data.dispatch_items
                    this.actual.date_time = data.actual_datetime
                    this.estimated.date_time = data.estimated_datetime
                    this.negotiated_price = data.negotiated_price
                    this.forwarder_name = data.forwarder_name
                    this.additional_remarks = data.additional_remarks
                    this.guard_name = data.guard_name

                    this.$forceUpdate()

                    if (this.dispatchItems != null && this.dispatchItems.length > 0) {

                        this.cards.titles = this.cards.titles.filter((e,i)=>e.hasOwnProperty('inputs'))

                        this.cards.titles.forEach(el=>{
                            this.dispatchItems.forEach((e, key) => {
                                if(el.hasOwnProperty('inputs')){
                                    const cf_nums = JSON.parse(e.cf_ids);
                                    const so_nums = JSON.parse(e.so_ids);
                                    if(!!e.stock_card_ids && el.model == 'stock_card'){
                                        el.inputs.push({
                                            cbm:0,
                                            customer_id:e.customer_id,
                                            stock_card_id:JSON.parse(e.stock_card_ids).map((el, i)=>{
                                                return {
                                                    value:parseInt(el),
                                                    so_num: so_nums[i],
                                                    customer_id:e.customer_id,
                                                    num_of_box:e.quantity,
                                                    number_of_boxes:e.quantity
                                                }
                                            }),
                                            no_of_boxes:e.quantity,
                                            id:'stock_card_id',
                                            cf_num:JSON.parse(e.cf_ids),
                                            so_num:JSON.parse(e.so_ids),
                                        })
                                    }

                                    else if(!!e.sc_delivery_ids && el.model == 'delivery_receipt'){
                                        el.inputs.push({
                                            cbm:0,
                                            customer_id:e.customer_id,
                                            delivery_receipt_id:JSON.parse(e.sc_delivery_ids).map(el=>{
                                                return {
                                                    value:parseInt(el),
                                                    customer_id:e.customer_id,
                                                    num_of_box:e.quantity,
                                                    number_of_boxes:e.quantity
                                                }
                                            }),
                                            no_of_boxes:e.quantity,
                                            id:'delivery_receipt_id'
                                        })
                                    }

                                    else if(!!e.pl_ids && el.model == 'packing_list' && e.service == 0){
                                        el.inputs.push({
                                            cbm:0,
                                            customer_id:e.customer_id,
                                            packing_list_id:JSON.parse(e.pl_ids).map((el, i)=>{
                                                return {
                                                    value:parseInt(el),
                                                    cf_num: cf_nums[i],
                                                    so_num: so_nums[i],
                                                    customer_id:e.customer_id,
                                                    num_of_box:e.quantity,
                                                    number_of_boxes:e.quantity
                                                }
                                            }),
                                            no_of_boxes:e.quantity,
                                            id:'packing_list_id',
                                            cf_num:JSON.parse(e.cf_ids),
                                            so_num:JSON.parse(e.so_ids),
                                        })
                                    }
                                    else if(!!e.pl_ids && el.model == 'spare_parts' && e.service == 1){
                                        const pl_nums = JSON.parse(this.dispatchItems[key].pl_nums);
                                        const so_nums = JSON.parse(this.dispatchItems[key].so_nums);
                                        el.inputs.push({
                                            cbm:0,
                                            customer_id:e.customer_id,
                                            spare_parts_id:JSON.parse(e.pl_ids).map((el, i)=>{
                                                return {
                                                    so_nums: so_nums[i],
                                                    text: pl_nums[i],
                                                    value:parseInt(el),
                                                    customer_id:e.customer_id,
                                                    num_of_box:e.quantity,
                                                    number_of_boxes:e.quantity
                                                }
                                            }),
                                            no_of_boxes:e.quantity,
                                            id:'spare_parts_id'
                                        })
                                    }
                                }
                            })
                        })

                        this.cards.titles.forEach((e,i)=>{
                            e.inputs.length > 1 ? e.inputs.shift() : ''
                            e.inputs.forEach((el,index)=>{
                                this.selectCustomer(i,index,e,'edit')
                                if(el.hasOwnProperty('num_of_box')){
                                    el.number_of_boxes = el.number_of_boxes
                                }
                            })
                        })
                    }
                    this.add_edit_dispatch_dialog = true
                    this.disabled_field = false
                }).catch(error => {
                    console.log(error)
                });

            }
        },
        async getDispatchDetails(){
            let temp_array = [
                    { text:'Delivery Receipt', service:1,},
                    { text:'Spare Parts', service:1,},
                ]
            temp_array.forEach(e=>{
                if(this.user.details.is_service){
                    if(e.service == 0){
                        return;
                    }
                }
                let title = e.text.toLowerCase().replaceAll(' ','_')
                let id = title+'_id'
                Object.assign(e,{
                    model:title,
                    title:e.text,
                    [id]:'',
                    id:id,

                    inputs:[
                        {
                            no_of_boxes:0,
                            customer_id:'',
                            cbm:0,
                            [id]:'',
                            id:id,
                            list:[],
                            // so_num: []
                        }
                    ]
                })
            })
            let keys = this.cards.titles
            let cards = keys.filter((e,i)=>e.hasOwnProperty('inputs'))
            await this.$store.dispatch('dispatchGet',{
                url:'dispatch-details',
                dispatch_id:this.dispatch_ids
            }).then(response=>{

                this.$forceUpdate()

                cards.forEach(el=>{

                    response.data.forEach((e, key) => {

                        if(el.hasOwnProperty('inputs')){

                            if(!!e.sc_delivery_ids && el.model == 'delivery_receipt'){

                                const scd_nums = JSON.parse(e.scd_nums);
                                const so_nums = JSON.parse(e.so_nums);

                                temp_array.find(f=>f.model == el.model).inputs.push({
                                    cbm:0,
                                    customer_id:e.customer_id,
                                    delivery_receipt_id:JSON.parse(e.sc_delivery_ids).map(el=>{
                                        return {
                                            value:parseInt(el),
                                            customer_id:e.customer_id,
                                            num_of_box:e.quantity,
                                            text:scd_nums[i],
                                            so_nums:so_nums[i],
                                            number_of_boxes: e.quantity,
                                            disaptch_item_id:e.id

                                        }
                                    }),
                                    no_of_boxes:e.quantity,
                                    id:'delivery_receipt_id'
                                })
                            }

                            else if(!!e.pl_ids && el.model == 'spare_parts' && e.service == 1){

                                const pl_nums = JSON.parse(e.pl_nums);
                                const so_nums = JSON.parse(e.so_nums);

                                temp_array.find(f=>f.model == el.model).inputs.push({
                                    cbm:0,
                                    customer_id:e.customer_id,
                                    spare_parts_id:JSON.parse(e.pl_ids).map((el, i)=>{

                                        return {
                                            value:parseInt(el),
                                            customer_id:e.customer_id,
                                            num_of_box:e.quantity,
                                            text:pl_nums[i],
                                            so_nums:so_nums[i],
                                            number_of_boxes: e.quantity,
                                            disaptch_item_id:e.id
                                        }
                                    }),
                                    no_of_boxes:e.quantity,
                                    id:'spare_parts_id'
                                })

                            }
                        }
                    })
                })

                this.cards.titles = this.cards.titles.filter(e=>{
                    if(!['Spare Parts','Delivery Receipt'].includes(e.title)){
                        return e
                    }
                })

                this.cards.titles = this.cards.titles.concat(temp_array)
                this.cards.titles.forEach((e,i)=>{
                    e.inputs.length > 1 ? e.inputs.shift() : ''
                    e.inputs.forEach((el,index)=>{
                        this.selectCustomer(i,index,e,'edit')
                    })
                })
                this.$forceUpdate()

            }).catch(err =>{

                this.cards.titles = this.cards.titles.filter(e=>{
                    if(!['Spare Parts','Delivery Receipt'].includes(e.title)){
                        return e
                    }
                })

                this.cards.titles = this.cards.titles.concat(temp_array)
                console.log(err.response.data.message)
            })
        },
        editDispatch() {

            this.errors = []

            let vehicle = (Number(this.vehicle_cbm) + Number((this.vehicle_cbm * 0.10)))

            // if (this.cbm_total > vehicle) {
            //     swal.fire({
            //         title: 'Items CBM exceed Are you sure do you want to Proceed?',
            //         text: 'Make sure that all information are correct',
            //         icon: 'warning',
            //         showConfirmButton: true,
            //         showCancelButton: true,
            //         reverseButtons: true,
            //         allowOutsideClick: false,
            //         confirmButtonColor: '#397373',
            //         cancelButtonColor: 'grey',
            //         confirmButtonText: 'Confirm',
            //     }).then((result) => {

            //         if (result.isConfirmed) {
            //             let payload = {
            //                 id: this.editDispatchId,
            //                 dispatch_datetime: this.dispatch_datetime,
            //                 vehicle_id: this.vehicle_id,
            //                 dispatcher_name: this.dispatcher_name,
            //                 driver_name: this.driver_name,
            //                 helper: this.helper,
            //                 helper_2: this.helper_2,
            //                 helper_3: this.helper_3,
            //                 remarks: this.remarks,
            //                 vehicle_cbm: this.vehicle_cbm,
            //                 cbm_total: this.cbm_total,
            //                 cbm_remaining: this.cbm_remaining,
            //                 dispatchItems: this.dispatchItems,
            //                 forwarder_name: this.forwarder_name,
            //                 additional_remarks: this.additional_remarks,
            //                 actual_datetime: this.actual.date_time,
            //                 estimated_datetime: this.estimated.date_time,
            //                 negotiated_price: this.negotiated_price,
            //                 guard_name:this.guard_name,
            //                 dispatch_ids:this.dispatch_ids
            //             }
            //             this.$store.dispatch('updateDispatchInfo', payload).then(async response => {
            //                 let data = response.data
            //                 if (data.error) {
            //                     swal.fire("", data.error, "warning");
            //                     return false
            //                 }

            //                 let cards = this.cards.titles.filter(e=>{
            //                     e.inputs.filter(el=>{

            //                         el[e.id].forEach(element=>{
            //                             element = {
            //                                 ...element,
            //                                 text:el.list.find(x=>x.value == element.value).text
            //                             }
            //                         })

            //                         el.list = []
            //                         return el
            //                     })

            //                 })

            //                 await this.$store.dispatch('dispatchPost',{
            //                     dispatch_id:this.editDispatchId,
            //                     cards:cards,
            //                     url:'update-items'
            //                 })
            //                 this.resetFields()
            //                 this.add_edit_dispatch_dialog = false
            //                 this.editDispatchId = 0
            //                 this.action = 'success'
            //                 swal.fire(response.data.msg, { icon: "success", });
            //             }).catch(error => {
            //                 if (error.response.status == 422) {
            //                     this.errors = error.response.data.errors
            //                 }
            //                 this.action = 'Edit'
            //             });
            //         }
            //     })
            // } else {
                let payload = {
                    id: this.editDispatchId,
                    dispatch_datetime: this.dispatch_datetime,
                    vehicle_id: this.vehicle_id,
                    dispatcher_name: this.dispatcher_name,
                    driver_name: this.driver_name,
                    helper: this.helper,
                    helper_2: this.helper_2,
                    helper_3: this.helper_3,
                    remarks: this.remarks,
                    vehicle_cbm: this.vehicle_cbm,
                    cbm_total: this.cbm_total,
                    cbm_remaining: this.cbm_remaining,
                    dispatchItems: this.dispatchItems,
                    forwarder_name: this.forwarder_name,
                    additional_remarks: this.additional_remarks,
                    actual_datetime: this.actual.date_time,
                    estimated_datetime: this.estimated.date_time,
                    negotiated_price: this.negotiated_price,
                    guard_name:this.guard_name
                }
                this.$store.dispatch('updateDispatchInfo', payload).then(async response => {

                    let data = response.data
                    if (data.error) {
                        swal.fire("", data.error, "warning");
                        return false
                    }

                    let cards = this.cards.titles.filter(e=>{

                        e.inputs.filter(el=>{
                            if(!!el[e.id]){
                                el[e.id].forEach(element=>{
                                    element = {
                                        ...element,
                                        text: el.list.find(x=>x.value == element.value) ? el.list.find(x=>x.value == element.value).value : null
                                    }
                                })
                                el.list = []
                                return el
                            }
                        })
                        // if(e.inputs.reduce((accumulator, object) => {
                        //     return accumulator + object.no_of_boxes;
                        // }, 0) > 0 ){

                        // }
                        return e
                    })

                    await this.$store.dispatch('dispatchPost',{
                        dispatch_id:this.editDispatchId,
                        cards:cards,
                        url:'update-items'
                    })
                    this.resetFields()
                    this.add_edit_dispatch_dialog = false
                    this.editDispatchId = 0
                    this.action = 'success'
                    swal.fire('', response.data.msg, "success");
                }).catch(error => {
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors
                    }
                    this.action = 'Edit'
                });
            // }
        },
        getVehicles() {
            this.$store.dispatch('getVehicle').then(response => {
                this.vehicles = this.GET_VEHICLES
            })
        },
        async getVehicleCBM() {
            let payload = {
                vehicle_id: this.vehicle_id
            }
            await this.$store.dispatch('getVehicleCBM', payload).then(response => {
                this.vehicle_cbm = this.GET_VEHICLE_CBM[0].cbm
                this.cbm_remaining = this.vehicle_cbm - this.cbm_total
                this.validate_tpl = this.GET_VEHICLE_CBM[0].tpl
                this.getCustomers()
            });
        },
        getPackingLists() {
            let payload = {
                params: {
                    check_dispatch: true,
                    editDispatchId: this.editDispatchId,
                    status: 5,
                    checkSOPickUp: true,
                }
            }
            this.$store.dispatch('getPackingList', payload).then(response => {
                this.packingLists = this.GET_PACKING_LISTS
                if (this.packingLists.length > 0) {
                    this.getCustomers()
                }
            });
        },
        getCustomers() {
            let packingListIds = this.packingLists.map(a => a.value);
            let payload = {
                withPrintedPL: true,
                packingLists: packingListIds
            }
            // this.$store.dispatch('customerSelection', payload).then(response => {
            //     this.customers_list = response.data
            // });
            this.loading.customer_selection = false
        },
        mergeDateTime() {
            this.dispatch_datetime = moment(this.dispatch_date.concat(" " + this.dispatch_time)).format('YYYY-MM-DD HH:mm')
            this.menu2 = false
        },
        mergeActualDateTime(){
            this.actual.date_time = moment(this.actual.date.concat(" " + this.actual.time)).format('YYYY-MM-DD HH:mm')
            this.menu3 = false
        },
        mergeEstimatedDateTime(){
            this.estimated.date_time = moment(this.estimated.date.concat(" " + this.estimated.time)).format('YYYY-MM-DD HH:mm')
            this.menu4 = false
        },
        resetFields() {
            this.errors = []
            this.dispatch_id = ''
            this.dispatch_date = ''
            this.dispatch_time = ''
            this.dispatch_datetime = ''
            this.vehicle_id = ''
            this.dispatcher_name = ''
            this.driver_name = ''
            this.helper = ''
            this.helper_2 = ''
            this.helper_3 = ''
            this.remarks = ''
            this.vehicle_cbm = 0
            this.cbm_total = 0
            this.cbm_remaining = 0
            this.dispatchItems = []
            this.vehicles = []
            this.customers_list = []
            this.packingLists = []
            this.forwarder_name = ''
            this.actual.date_time = ''
            this.estimated.date_time = ''
            this.additional_remarks = ''
            this.negotiated_price = 0

        },
        async exportDispatch() {
            this.loading_button = true;
            await this.$store.dispatch('exportdispatch').then(async (response) => {
                this.export_arr.packing_lists = [...response.data.data.packing_lists];
                this.export_arr.stock_cards = [...response.data.data.stock_cards];
                this.statuses = response.data.statuses
            }).catch(e => {

            });
            this.boolean_export = true;
        },

        structureData(){
            this.cards.titles.forEach(e=>{
                if(this.user.details.is_service){
                    if(e.service == 0){
                        return;
                    }
                }
                let title = e.text.toLowerCase().replaceAll(' ','_')
                let id = title+'_id'
                Object.assign(e,{
                    model:title,
                    title:e.text,
                    [id]:'',
                    id:id,

                    inputs:[
                        {
                            no_of_boxes:0,
                            customer_id:'',
                            cbm:0,
                            [id]:'',
                            id:id,
                            list:[],
                            cf_num: '',
                            so_num: '',
                        }
                    ]
                })
            })
        },
        async getCurrentUser(){
            await this.$store.dispatch('currentUser',{
                url:'current-user'
            }).then(response=>{
                this.user.details = response
            })
        },
        getSparePartsDispatches(){
            this.$store.dispatch('dispatchGet',{
                url:'service-dispatches'
            }).then(response=>{
                this.dispatch_list_ids = response.data
            })
        }
    },
    watch: {
        'boolean_export': {
            handler(val) {
                if (val) {
                    this.loading_button = false;
                    const workbook = new ExcelJS.Workbook()

                    const packing_lists = workbook.addWorksheet('Delivery Receipts');
                    packing_lists.columns = [
                        { header: 'DISPATCH#', key: 'dispatch_id', width: 13 },
                        { header: 'DISPATCH DATE', style: { numFmt: 'mm/dd/yyyy' }, key: 'dispatch_date', width: 20 },
                        { header: 'STATUS', key: 'status' },
                        { header: 'CUSTOMER', key: 'company_name', width: 25 },
                        { header: 'VEHICLE', key: 'vehicle_name' },
                        { header: 'DR#', key: 'pl_number' },
                        { header: 'CF#', key: 'cf_number' },
                        { header: 'SO#', key: 'so_number' },
                        { header: 'SO DATE', key: 'so_date', style: { numFmt: 'mm/dd/yyyy' } },
                        { header: 'IS SC?', key: 'is_sc' },
                        { header: '# OF BOXES', key: 'quantity' },
                        // { header: 'Amount', style: { numFmt: '#,##0.00' }, key: 'total_amount' },
                    ];
                    this.export_arr.packing_lists.forEach(element => {
                        element.dispatch_date = new Date(Date.parse(element.dispatch_date.replace(' ', 'T')+'.000Z'));
                        element.so_date = new Date(Date.parse(element.so_date));
                        packing_lists.addRow(element);
                    });

                    const stock_cards = workbook.addWorksheet('Stock Cards');
                    stock_cards.columns = [
                    { header: 'DISPATCH#', key: 'dispatch_id', width: 13 },
                        { header: 'DISPATCH DATE', style: { numFmt: 'mm/dd/yyyy' }, key: 'dispatch_date', width: 20 },
                        { header: 'STATUS', key: 'status' },
                        { header: 'CUSTOMER', key: 'company_name', width: 25 },
                        { header: 'VEHICLE', key: 'vehicle_name' },
                        { header: 'SC#', key: 'sc_number' },
                        { header: 'DR#', key: 'pl_number' },
                        { header: 'CF#', key: 'cf_number' },
                        { header: 'SO#', key: 'so_number' },
                        { header: 'SO DATE', key: 'so_date', style: { numFmt: 'mm/dd/yyyy' } },
                        { header: '# OF BOXES', key: 'quantity' },
                        // { header: 'Amount', style: { numFmt: '#,##0.00' }, key: 'total_amount' },
                    ]
                    this.export_arr.stock_cards.forEach(element => {
                        element.dispatch_date = new Date(Date.parse(element.dispatch_date.replace(' ', 'T')+'.000Z'));
                        element.so_date = new Date(Date.parse(element.so_date));
                        stock_cards.addRow(element);
                    });

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        saveAs(blob, `Dispatches.xlsx`);
                    });

                    this.boolean_export = false;
                }
            }
        },
        add_edit_dispatch_dialog() {
            this.getSparePartsDispatches()
            if (this.add_edit_dispatch_dialog == true) {
                this.getVehicles();
                this.getPackingLists();
                if(this.action != 'Edit'){

                    this.structureData()
                    if(this.user.details){
                        this.dispatcher_name = this.user.details.data.name
                    }
                }
                this.loaded = true
            }
        },
        'GET_VEHICLES': {
            handler(val) {
                this.dealersList = val.data
                this.loading.vehicle_selection = false
            }
        },
        USER_ACCESS: {
            handler(val) {
                if (val != "fail") {
                    this.userAccess.create = false;
                    this.userAccess.export_dispatch = false;
                    val.forEach((e) => {
                        if (e.actions_code == "create") {
                            this.userAccess.create = true;
                        }
                        else if (e.actions_code == "export_dispatch") {
                            this.userAccess.export_dispatch = true;
                        }
                        else if (e.actions_code == "packing_list_create") {
                            this.userAccess.packing_list = true;
                        }
                        else if (e.actions_code == "delivery_receipt_create") {
                            this.userAccess.delivery_receipt = true;
                        }
                        else if (e.actions_code == "stock_card_create") {
                            this.userAccess.stock_card = true;
                        }
                        else if (e.actions_code == "spare_parts_create") {
                            this.userAccess.spare_parts = true;
                        }
                    });
                }
            }
        },
        // 'dispatch_datetime':{
        //     handler(val){
        //         if(!val){
        //             this.estimated.date_time = ''
        //         }
        //         else{
        //             if(this.validate_tpl == 0){
        //                 this.estimated.date_time = this.dispatch_date + ' 11:59 PM'
        //             }
        //             else{
        //                 this.estimated.date_time = ''
        //             }
        //         }
        //     }
        // },
        // 'validate_tpl':{
        //     handler(val){
        //         console.log(val)
        //         if(val == 1){
        //             this.disabled_field = false


        //         }else{
        //             this.estimated_date_input = true
        //             this.disabled_field = true
        //             this.estimated.date_time =  this.dispatch_datetime
        //         }
        //     }
        // },
        'vehicle_id':{
            handler(val){
                let vehicle = this.vehicles.find(e=>e.id == val)

                if(!vehicle){
                    return
                }

                if(vehicle.tpl == 1){
                    this.forwarder_name = ''
                    this.negotiated_price = 0
                    this.disabled_field = false
                    this.estimated.date_time =  ''


                }else{
                    this.forwarder_name = ''
                    this.negotiated_price = 0
                    this.disabled_field = true
                    this.estimated.date_time =  this.dispatch_datetime
                }
            }
        },
        'action': {
            handler(val) {
                if (val === 'Add') this.dispatch_status = null;
            },
        },
    },
};
</script>

<style>
.info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: medium;
}
</style>
