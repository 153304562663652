<template>
    <v-container fluid class="pa-3">
      <h3 class="page-title" style="color: black">Warehouse Preparation Form</h3>
        <v-container class="ma-0">
            <v-app id="item-tabs">
                <v-tabs v-model="tab" id="group-tab" color="cyan" slider-color="cyan" background-color="#424242" dark class="col-12" show-arrows>
                    <v-tab ripple>PREPARING</v-tab>
                    <v-tab ripple>PARTIALLY PREPARED</v-tab>
                    <v-tab ripple>FOR PRINT</v-tab>
                    <v-tab ripple>PREPARED</v-tab>
                    <v-tab ripple>CONVERTED</v-tab>
                    <v-tab ripple>ALL</v-tab>
                    <v-tabs-items v-model="tab" touchless>
                        <!-- FOR PREP TAB -->
                        <v-tab-item>
                            <WarehousePreparationComponentVue :tab_name="'is_preparing'" :print="print"></WarehousePreparationComponentVue>
                        </v-tab-item>

                        <!-- FOR PARTIAL TAB -->
                        <v-tab-item>
                            <WarehousePreparationComponentVue :tab_name="'is_partial'" :print="print"></WarehousePreparationComponentVue>
                        </v-tab-item>

                        <!-- FOR PRINT TAB -->
                        <v-tab-item>
                            <WarehousePreparationComponentVue :tab_name="'is_for_print'" :print="print"></WarehousePreparationComponentVue>
                        </v-tab-item>

                        <!-- PREPARED TAB -->
                        <v-tab-item>
                            <WarehousePreparationComponentVue :tab_name="'is_prepared'" :print="print"></WarehousePreparationComponentVue>
                        </v-tab-item>

                        <!-- CONVERTED TAB -->
                        <v-tab-item>
                            <WarehousePreparationComponentVue :tab_name="'is_converted'" :print="print"></WarehousePreparationComponentVue>
                        </v-tab-item>

                        <!-- ALL TAB -->
                        <v-tab-item>
                            <WarehousePreparationComponentVue :tab_name="'is_all'" :print="print"></WarehousePreparationComponentVue>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-app>
        </v-container>
    </v-container>
</template>

<script>
import WarehousePreparationComponentVue from './WarehousePreparationComponent.vue'
export default {
    data(){
        return {
            tab: null,
            print:{
                wp_id:'',
                so_num:'',
                so_terms:'',
                item:[],
                so:[],
                created:'',
                so_remarks:'',
                preparer_name:'',
                no_of_items:0,
            },
        }
    },
    components: {
        WarehousePreparationComponentVue
    },
    methods: {
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                    if (e == 'wpfsConfirmed') {
                        this.tab = 3
                    }
                })
            }
        },
    },
    mounted(){
        this.$store.dispatch('getAllCustomers')
        this.$store.dispatch('getAllSelectUsers')
        this.$store.dispatch('getAllWarehouses',{})
        this.$store.commit("LINK_ACTIVE", window.location.pathname.split("/")[3]);
        this.$store.dispatch('checkAccessUser',this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
    }
};
</script>
<style>
    #group-tab{
        padding: 0 !important;
    }
</style>
