<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card style="overflow-x: hidden;">
            <v-row class="px-3 py-2" dense no-gutters> <!-- Dialog header -->
                <v-col cols="11">
                    <v-card-title class="pa-0 pl-3">
                        <span class="text-h5">Customer Information</span>
                    </v-card-title>
                </v-col>
                <v-col cols="1">
                    <v-btn text icon small color="gray" class="float-right" @click="closeDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="pa-0 ma-0"></v-divider>
            <v-row class="px-3 py-2" dense no-gutters> <!-- Tabs Selection (Sales, Accounting, Exec), Company & Owner Name, Affiliates, Credit Info -->
                <v-col cols="8">
                    <v-tabs v-model="tab" color="cyan" background-color="#424242" slider-color="cyan" class="mx-2" dark show-arrows>
                        <v-tabs-slider color="cyan"></v-tabs-slider>
                        <v-tab v-for="(item, i) in tabs" :key="i" v-if="actions == 'View' || actions == 'Submit' || userAccess[item.access]">{{ item.text }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(item, i) in tabs" :key="i" v-if="actions == 'View' || actions == 'Submit' || userAccess[item.access]" class="pt-3 pb-2">
                            <v-row class="mx-2" dense>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="customer_information.company_names"
                                        :readonly="disable_view"
                                        :rules="errors.company_names"
                                        label="Company Name"
                                        required
                                        dense
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mx-2" dense>
                                <v-col cols="3" v-if="GET_ACTIVE_TAB == 'is_mother'">
                                    <v-checkbox
                                        v-model="customer_information.is_mother_customer"
                                        :true-value="1"
                                        :false-value="0"
                                        :disabled="customer_information.is_mother_customer == 1"
                                        dense
                                        hide-details
                                    >
                                        <template v-slot:label>
                                            <span class="m-0">{{ "Is Mother Customer" }}</span>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col :cols="GET_ACTIVE_TAB == 'is_mother' ? 9 : 12">
                                    <v-autocomplete
                                        v-if="!customer_information.is_mother_customer"
                                        v-model="customer_information.affiliate_customer_id"
                                        :items="GET_AFFLIATE_CUSTOMER"
                                        :readonly="disable_view"
                                        label="Mother Customer"
                                        dense
                                        outlined
                                        hide-details
                                        auto-select-first
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="mx-2" dense v-if="!customer_information.is_mother_customer">
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="customer_information.last_name"
                                        :readonly="disable_view"
                                        label="Owner Last Name"
                                        dense
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="customer_information.first_name"
                                        :readonly="disable_view"
                                        :rules="errors.first_name"
                                        label="Owner First Name"
                                        dense
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="customer_information.middle_name"
                                        :readonly="disable_view"
                                        label="Owner Middle Name"
                                        dense
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
                <v-col cols="4">
                    <v-card elevation="10" color="#424242" rounded v-if="actions == 'View' || actions == 'Update' ">
                        <v-card-text v-if="total_card_details.loading" class="d-flex justify-content-center">
                            <v-row class="fill-height" align-content="center" justify="center">
                                <v-col class="text-subtitle-1 text-center" cols="12">
                                    <span class="text-white">Loading Please Wait</span>
                                </v-col>
                                <v-col cols="6">
                                    <v-progress-linear
                                        color="orange"
                                        indeterminate
                                        rounded
                                        height="6"
                                    ></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else class="ma-0 pa-2 text-white">
                            <table style="float:center">
                                <tr>
                                    <td><b>Total Collectibles: </b></td>
                                    <td class="pl-5">&#8369;{{ total_card_details.total_collectibles | currency }}</td>
                                    <td class="pl-5"><v-btn color="info" text icon x-small @click="showCollectiblesByCustomer()"><v-icon>mdi-information-outline</v-icon></v-btn></td>
                                </tr>
                                <tr>
                                    <td><b>Credit Limit:</b></td>
                                    <td class="pl-5 d-flex align-items-center">
                                        <span :style="!!total_card_details.vip ? 'font-size: 24px;' : 'font-size: 14px;'">
                                            {{currency_sign}}&nbsp;
                                        </span>
                                        <span v-if="total_card_details.vip == ''">
                                            {{ total_card_details.credit_limit | currency}}
                                        </span>
                                        <v-chip v-if="!!total_card_details.vip" x-small color="orange" class="ma-0 pa-2">
                                            <span class="text-white">{{ total_card_details.vip }}</span>
                                        </v-chip>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Total Used:</b> </td>
                                    <td class="pl-5">&#8369;{{ total_card_details.total_used | currency }}</td>
                                </tr>
                                <tr>
                                    <td><b>Remaining:</b> </td>
                                    <td class="pl-5 d-flex align-items-center">
                                        <span :style="!!total_card_details.vip ? 'font-size: 24px;' : 'font-size: 14px;'">
                                            {{currency_sign}}&nbsp;
                                        </span>
                                        <span v-if="total_card_details.vip == ''">
                                            {{ total_card_details.remaining | currency}}
                                        </span>
                                        <v-chip v-if="!!total_card_details.vip" x-small color="orange" class="ma-0 pa-2"  >
                                            <span class="text-white">{{ total_card_details.vip }}</span>
                                        </v-chip>
                                    </td>
                                </tr>
                            </table>
                        </v-card-text>
                    </v-card>
                    <v-row>
                        <v-col class="text-right mt-3">
                            <div class="d-flex justify-content-end align-item-end">
                                <span class="mx-2" v-if="actions != 'View'">
                                    Upload File
                                    <FileUploadComponentVue class="mx-1" ref="fileUpload" @uploadedData="uploadedData" @reset="clearFileUpload" />
                                </span>
                                <BaseFilesViewerVue v-if="actions != 'Submit'" class="mr-3 ml-2 mt-1" :customerId="customer_information.id" :delete="userAccess.delete_file && actions == 'Update'"/>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="px-0 py-1 ma-0"></v-divider>
            <v-row class="px-3 overflow-auto" dense no-gutters> <!-- Other Details -->
                <v-col cols="12">
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-if="actions == 'View' || actions == 'Submit' || userAccess.edit_customer_sales">
                            <v-row v-if="!!false" class="px-3 py-1" dense no-gutters> <!-- Customer Business/Individual -->
                                <!-- v-if="!customer_information.is_mother_customer" -->
                                <v-col cols="12">
                                    <v-radio-group
                                        v-model="customer_information.type"
                                        :readonly="disable_view"
                                        class="mt-0"
                                        row
                                        mandatory
                                        hide-details
                                    >
                                        <v-radio label="Business" value="bus"></v-radio>
                                        <v-radio label="Individual" value="ind"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Shipping Addresses -->
                                <v-col cols="12">
                                    <v-card class="bg-ingco-alt py-3" elevation="0">
                                        <v-row dense no-gutters>
                                            <v-col cols="12">
                                                <v-card-subtitle class="px-2 py-0">
                                                    <span class="text-white">Shipping Address</span>
                                                    <span class="float-right mx-2">
                                                        <v-btn
                                                            :disabled="disable_view"
                                                            style="height: 20px; width: 20px"
                                                            color="primary"
                                                            fab
                                                            dark
                                                            small
                                                            @click="addShippingAddress()"
                                                        >
                                                            <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            :disabled="disable_view"
                                                            style="height: 20px; width: 20px"
                                                            color="primary"
                                                            fab
                                                            dark
                                                            small
                                                            @click="removeShippingAddress()"
                                                        >
                                                            <v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
                                                        </v-btn>
                                                    </span>
                                                </v-card-subtitle>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="pa-0 my-2 border-white"></v-divider>
                                        <v-row v-for="(shipping_address, i) in shipping_counter" :key="i" class="px-3" dense no-gutters v-if="!customer_information.is_mother_customer">
                                            <v-col cols="2">
                                                <v-autocomplete
                                                    v-model="country_code.shipping[i].code"
                                                    :items="GET_ALL_COUNTRIES"
                                                    placeholder="Select Country"
                                                    background-color="white"
                                                    dense
                                                    readonly
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="updateRegions(i, 'shipping')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-autocomplete
                                                    v-model="reg_code.shipping[i].code"
                                                    :items="reg_list.shipping[i].list"
                                                    :readonly="disable_view"
                                                    placeholder="Select Region"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="updateProvinces(i, 'shipping')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-autocomplete
                                                    v-model="province_code.shipping[i].code"
                                                    :items="province_list.shipping[i].list"
                                                    :readonly="disable_view"
                                                    placeholder="Select Province"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="updateCities(i, 'shipping')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-autocomplete
                                                    v-model="city_code.shipping[i].code"
                                                    :items="city_list.shipping[i].list"
                                                    :readonly="disable_view"
                                                    placeholder="Select City / Municipality"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="selectRegion(i, 'shipping')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="comp_address.shipping[i].address"
                                                    :id="'company_address_' + i"
                                                    :readonly="disable_view"
                                                    placeholder="Address"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3" v-if="customer_information.type == 'bus'">
                                                <v-text-field
                                                    v-model="contact_person.shipping[i].person"
                                                    :id="'shipping_contact_person_' + i"
                                                    :readonly="disable_view"
                                                    placeholder="Contact Person"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="contact_number.shipping[i].ct_num"
                                                    :id="'shipping_contact_number_' + i"
                                                    :readonly="disable_view"
                                                    placeholder="Contact Number"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <hr class="m-2 w-100 border-white" v-if="shipping_counter.length > 1 && i !== shipping_counter.length - 1" />
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-if="!!false" class="px-3 py-1" dense no-gutters> <!-- Delivery Instructions and Shipping Via -->
                                <!-- v-if="!customer_information.is_mother_customer" -->
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="customer_information.deliver_instructions"
                                        :readonly="disable_view"
                                        label="Delivery Instructions"
                                        outlined
                                        dense
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="customer_information.shipping_via"
                                        :readonly="disable_view"
                                        label="Shipping Via"
                                        outlined
                                        dense
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Other Contacts: Sales -->
                                <v-col cols="12">
                                    <v-card class="bg-ingco-alt py-3" elevation="0">
                                        <v-row dense no-gutters>
                                            <v-col cols="12">
                                                <v-card-subtitle class="px-2 py-0">
                                                    <span class="text-white">Other Contacts: Sales</span>
                                                </v-card-subtitle>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="pa-0 my-2 border-white"></v-divider>
                                        <v-row class="px-3" dense no-gutters>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="other_contacts_sales.email"
                                                    :readonly="disable_view"
                                                    label="Email"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_sales.mobile_1"
                                                    :readonly="disable_view"
                                                    label="Mobile"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_sales.mobile_2"
                                                    :readonly="disable_view"
                                                    label="Mobile 2"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_sales.tel_1"
                                                    :readonly="disable_view"
                                                    label="Tel 1"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_sales.tel_2"
                                                    :readonly="disable_view"
                                                    label="Tel 2"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_sales.tel_3"
                                                    :readonly="disable_view"
                                                    label="Tel 3"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_sales.fax"
                                                    :readonly="disable_view"
                                                    label="Fax"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-if="!!false" class="px-3 py-1" dense no-gutters> <!-- Category, Classification, Annual Target -->
                                <v-col cols="4" v-if="!customer_information.is_mother_customer">
                                    <v-autocomplete
                                        v-model="customer_information.customer_category_id"
                                        :items="GET_CUSTOMER_CATEGORY_SELECTION"
                                        :rules="errors.classification"
                                        :readonly="disable_view"
                                        label="Category"
                                        dense
                                        outlined
                                        hide-details
                                        auto-select-first
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="4" v-if="!customer_information.is_mother_customer">
                                    <v-autocomplete
                                        v-model="customer_information.classification"
                                        :items="GET_CLASSIFICATIONS_DROPDOWN.data"
                                        :rules="errors.classification"
                                        :readonly="disable_view"
                                        label="Classification"
                                        dense
                                        outlined
                                        hide-details
                                        auto-select-first
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="customer_information.annual_target"
                                        :readonly="disable_view"
                                        :rules="errors.annual_target"
                                        label="Annual Sales Target"
                                        dense
                                        outlined
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters> <!-- Salesman, Sales Coordinator -->
                                <v-col cols="3">
                                    <v-autocomplete
                                        v-model="customer_information.sales_man"
                                        :items="GET_SALESMAN_DROPDOWN"
                                        :readonly="disable_view"
                                        :rules="errors.sales_man"
                                        label="Salesman"
                                        dense
                                        outlined
                                        hide-details
                                        auto-select-first
                                    ></v-autocomplete>
                                </v-col>
                                <!-- <v-col cols="3">
                                    <v-autocomplete
                                        v-model="customer_information.clusters"
                                        :items="GET_CLUSTER_SELECTION"
                                        :rules="errors.cluster"
                                        :readonly="disable_view"
                                        label="Clusters"
                                        placeholder="Clusters"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        multiple
                                        required
                                        small-chips
                                        deletable-chips
                                    ></v-autocomplete>
                                </v-col> -->
                                <!-- <v-col cols="3">
                                    <v-autocomplete
                                        v-model="customer_information.sub_clusters"
                                        :items="GET_SUB_CLUSTER_SELECTION"
                                        :rules="errors.cluster"
                                        :readonly="disable_view"
                                        label="Sub Clusters"
                                        placeholder="Sub Clusters"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        multiple
                                        required
                                        small-chips
                                        deletable-chips
                                    ></v-autocomplete>
                                </v-col> -->
                                <!-- <v-col cols="3">
                                    <v-autocomplete
                                        v-model="customer_information.sales_coor"
                                        :items="GET_SALESMANCOOR_DROPDOWN"
                                        :readonly="disable_view"
                                        label="Sales Coordinator"
                                        dense
                                        outlined
                                        hide-details
                                        auto-select-first
                                    ></v-autocomplete>
                                </v-col> -->
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters> <!-- Sales Remarks -->
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="customer_information.remarks"
                                        :readonly="disable_view"
                                        label="Remarks"
                                        height="90"
                                        outlined
                                        no-resize
                                        hide-details
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="GET_ACTIVE_TAB == 'is_mother' && (ACTION == 'View' || ACTION == 'Update')">
                                <v-col cols="9">
                                    <strong>Affiliates:</strong>
                                </v-col>
                                <v-col cols="3" class="text-right" v-if="ACTION == 'Update'">
                                    <v-btn small @click="openAffiliatesDialog()">
                                        Select Affiliates
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="GET_ACTIVE_TAB == 'is_mother' && (ACTION == 'View' || ACTION == 'Update')">
                                <v-col cols="12">
                                    <v-card>
                                        <v-data-table
                                            :headers="affiliate_headers"
                                            :items="affiliate_items"
                                            :items-per-page="10"
                                            :loading="loaded"
                                            class="w-100"
                                            loading-text="Loading... Please wait"
                                            hide-default-footer
                                            pa-2
                                        >
                                            <template v-slot:item.action="{ item }" v-if="ACTION == 'Update'">
                                                <v-btn text icon color="red">
                                                    <v-icon class="btn-action" small @click="removeAffiliate(item)">mdi-minus-circle</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:item.vip="{ item }">
                                                <span class="badge bg-primary text-white" v-if="item.vip">Yes</span>
                                                <span class="badge bg-danger text-white" v-else>No</span>
                                            </template>

                                            <template v-slot:item.special="{ item }">
                                                <span class="badge bg-primary text-white" v-if="item.special">Yes</span>
                                                <span class="badge bg-danger text-white" v-else>No</span>
                                            </template>

                                            <template v-slot:item.is_active="{ item }">
                                                <span class="badge bg-primary text-white" v-if="item.is_active">Yes</span>
                                                <span class="badge bg-danger text-white" v-else>No</span>
                                            </template>

                                            <template v-slot:item.is_store="{ item }">
                                                <span class="badge bg-primary text-white" v-if="item.is_store">Yes</span>
                                                <span class="badge bg-danger text-white" v-else>No</span>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Recycle SO, Approval, Store, Auto Cancel SO on Dispatch -->
                                <v-col cols="5" class="d-flex flex-row justify-content-between">
                                    <v-switch
                                        v-model="customer_information.recycle_so"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Recycle SO"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                    <v-switch
                                        v-if="actions == 'View' || userAccess.edit_approve_sales"
                                        v-model="customer_information.approved_by_sales"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Approve Sales"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                    <!-- <v-switch
                                        v-model="customer_information.is_store"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Store"
                                        class="my-0"
                                        hide-details
                                        dense
                                    ></v-switch> -->
                                    <!-- <v-switch
                                        v-model="customer_information.auto_cancel_sales_order_on_dispatch"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Cancel SO on Dispatch"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch> -->
                                    <v-switch
                                        v-model="customer_information.override_moq"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Override MOQ"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Discounting -->
                                <v-col cols="12">
                                    <!-- <buttons
                                        :action="buttons.actions.addbranddiscount"
                                        :color="buttons.colors.addbranddiscount"
                                        @click="adddiscounts"
                                    ></buttons> -->
                                    <v-btn @click="adddiscounts" small :disabled="disable_view">
                                        <v-icon>mdi-plus</v-icon>ADD DISCOUNT
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-row dense no-gutters>
                                        <v-col cols="6" class="px-1" v-for="(discount_card, i) in discount_cards" :key="i">
                                            <v-card class="pt-1" elevation="0" outlined>
                                                <v-row dense no-gutters>
                                                    <v-col cols="12">
                                                        <v-card-subtitle class="px-2 py-2">
                                                            <span style="display: inline-block">
                                                                <v-autocomplete
                                                                    v-model="brand_discount[i]"
                                                                    :items="GET_BRAND_DISCOUNT"
                                                                    :readonly="disable_view"
                                                                    label="Brand Discount"
                                                                    class="text-caption brand_discount"
                                                                    background-color="white"
                                                                    dense
                                                                    hide-details
                                                                    auto-select-first
                                                                    persistent-placeholder
                                                                    @change="brand_change(i)"
                                                                ></v-autocomplete>
                                                            </span>
                                                            <span class="float-right mx-1 my-2">
                                                                <v-btn
                                                                    :disabled="disable_view"
                                                                    style="height: 20px; width: 20px"
                                                                    class="float-right"
                                                                    text
                                                                    icon
                                                                    @click="removeDiscount(i)"
                                                                >
                                                                    <v-icon>mdi-close-circle</v-icon>
                                                                </v-btn>
                                                            </span>
                                                        </v-card-subtitle>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="pa-0 ma-0"></v-divider>
                                                <v-row dense no-gutters>
                                                    <v-card-text class="pa-1 bg-ingco-alt">
                                                        <v-col cols="12" v-for="(discount_type, n) in discount_types" :key="n">
                                                            <v-text-field
                                                                v-model="discount_text_fields[i].arr[n]"
                                                                :value="discount_text_fields[i].arr[n]"
                                                                :readonly="disable_view"
                                                                :prefix="discount_type.text"
                                                                background-color="white"
                                                                class="py-1"
                                                                suffix="%"
                                                                outlined
                                                                dense
                                                                @keypress="numberAndSlashOnly"
                                                                hide-details
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-card-text>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item v-if="(actions == 'View' || actions == 'Submit' || userAccess.edit_customer_accounting)">
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Billing Addresses -->
                                <v-col cols="12">
                                    <v-card class="bg-ingco-alt py-3" elevation="0">
                                        <v-row dense no-gutters>
                                            <v-col cols="12">
                                                <v-card-subtitle class="px-2 py-0">
                                                    <span class="text-white">Billing Address</span>
                                                    <span class="float-right mx-2">
                                                        <v-btn
                                                            :disabled="disable_view"
                                                            style="height: 20px; width: 20px"
                                                            color="primary"
                                                            fab
                                                            dark
                                                            small
                                                            @click="addBillingAddress()"
                                                        >
                                                            <v-icon dark small style="font-size: 12px">mdi-plus</v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            :disabled="disable_view"
                                                            style="height: 20px; width: 20px"
                                                            color="primary"
                                                            fab
                                                            dark
                                                            small
                                                            @click="removeBillingAddress()"
                                                        >
                                                            <v-icon dark small style="font-size: 12px">mdi-minus</v-icon>
                                                        </v-btn>
                                                    </span>
                                                </v-card-subtitle>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="pa-0 my-2 border-white"></v-divider>
                                        <v-row v-for="(billing_address, i) in billing_counter" :key="i" class="px-3" dense no-gutters>
                                            <v-col cols="2">
                                                <v-autocomplete
                                                    v-model="country_code.billing[i].code"
                                                    :items="GET_ALL_COUNTRIES"
                                                    placeholder="Select Country"
                                                    background-color="white"
                                                    dense
                                                    readonly
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="updateRegions(i, 'billing')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-autocomplete
                                                    v-model="reg_code.billing[i].code"
                                                    :items="reg_list.billing[i].list"
                                                    :readonly="disable_view"
                                                    placeholder="Select Region"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="updateProvinces(i, 'billing')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-autocomplete
                                                    v-model="province_code.billing[i].code"
                                                    :items="province_list.billing[i].list"
                                                    :readonly="disable_view"
                                                    placeholder="Select Province"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="updateCities(i, 'billing')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-autocomplete
                                                    v-model="city_code.billing[i].code"
                                                    :items="city_list.billing[i].list"
                                                    :readonly="disable_view"
                                                    placeholder="Select City / Municipality"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    auto-select-first
                                                    @input="selectRegion(i, 'billing')"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="comp_address.billing[i].address"
                                                    :id="'company_address_' + i"
                                                    :readonly="disable_view"
                                                    placeholder="Address"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3" v-if="customer_information.type == 'bus'">
                                                <v-text-field
                                                    v-model="contact_person.billing[i].person"
                                                    :id="'billing_contact_person_' + i"
                                                    :readonly="disable_view"
                                                    placeholder="Contact Person"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="contact_number.billing[i].ct_num"
                                                    :id="'billing_contact_number_' + i"
                                                    :readonly="disable_view"
                                                    placeholder="Contact Number"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <hr class="m-2 w-100 border-white" v-if="billing_counter.length > 1 && i !== billing_counter.length - 1" />
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Other Contacts: Accounting -->
                                <v-col cols="12">
                                    <v-card class="bg-ingco-alt py-3" elevation="0">
                                        <v-row dense no-gutters>
                                            <v-col cols="12">
                                                <v-card-subtitle class="px-2 py-0">
                                                    <span class="text-white">Other Contacts: Accounting</span>
                                                </v-card-subtitle>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="pa-0 my-2 border-white"></v-divider>
                                        <v-row class="px-3" dense no-gutters>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="other_contacts_accounting.email"
                                                    :readonly="disable_view"
                                                    label="Email"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_accounting.mobile_1"
                                                    :readonly="disable_view"
                                                    label="Mobile"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_accounting.mobile_2"
                                                    :readonly="disable_view"
                                                    label="Mobile 2"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_accounting.tel_1"
                                                    :readonly="disable_view"
                                                    label="Tel 1"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_accounting.tel_2"
                                                    :readonly="disable_view"
                                                    label="Tel 2"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_accounting.tel_3"
                                                    :readonly="disable_view"
                                                    label="Tel 3"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="other_contacts_accounting.fax"
                                                    :readonly="disable_view"
                                                    label="Fax"
                                                    background-color="white"
                                                    dense
                                                    outlined
                                                    hide-details
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- TIN, VAT Rate, Credit Limit & Terms -->
                                <v-col cols="3">
                                    <v-text-field
                                        v-model="customer_information.tin.tin"
                                        :readonly="disable_view"
                                        label="Tax identification Number"
                                        dense
                                        outlined
                                        hide-details
                                    ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="3">
                                    <v-text-field
                                        v-model="customer_information.vat_rate"
                                        :readonly="disable_view"
                                        label="VAT Rate"
                                        placeholder="0"
                                        suffix="%"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress="decimalOnly"
                                    ></v-text-field>
                                </v-col> -->
                                <!-- <v-col cols="3">
                                    <v-text-field
                                        v-model="customer_information.credit_limit"
                                        :readonly="disable_view"
                                        label="Credit Limit"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress="decimalOnly"
                                    ></v-text-field>
                                </v-col> -->
                                <v-col cols="3">
                                    <v-text-field
                                        v-model="customer_information.terms"
                                        :readonly="disable_view"
                                        label="Terms"
                                        placeholder="0"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress="decimalOnly"
                                    ></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters> <!-- Accounting Remarks -->
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="customer_information.accounting_remarks"
                                        :readonly="disable_view"
                                        label="Remarks"
                                        height="90"
                                        outlined
                                        no-resize
                                        hide-details
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Special, SP Price, Print No Price, Approval, Active -->
                                <v-col cols="8" class="d-flex flex-row justify-content-between">
                                    <v-switch
                                        v-model="customer_information.special"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="SP Price"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                    <v-switch
                                        v-model="customer_information.vip"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="VIP"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                    <v-switch
                                        v-model="customer_information.is_print_no_price"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Print No Price"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                    <v-switch
                                        v-if="actions == 'View' || userAccess.edit_approve_acct"
                                        v-model="customer_information.approved_by_acct"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Approve Acct"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                    <v-switch
                                        v-model="customer_information.is_active"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Active"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                    <!-- <v-switch
                                        v-model="customer_information.hold"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Hold Customer"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch> -->
                                </v-col>
                                <v-col cols="4">
                                    <v-autocomplete
                                        v-model="customer_information.customer_code_id"
                                        dense
                                        outlined
                                        class="mx-2"
                                        label="Code"
                                        :items="GET_CUSTOMER_CODE_DROPDOWN.data"
                                        >
                                        <template v-slot:selection="data">
                                            <v-chip small left :color="data.item.color" size="8" style="border:1px solid !important;"></v-chip>
                                            <span class="ml-2">{{ data.item.text }}</span>
                                        </template>
                                        <template v-slot:item="data">
                                            <v-chip small left :color="data.item.color" size="8" style="border:1px solid !important;"></v-chip>
                                            <span class="ml-2">{{ data.item.text }}</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item v-if="actions == 'View' || actions == 'Submit' || userAccess.edit_customer_exec">
                            <v-row class="px-3 py-1" dense no-gutters v-if="!customer_information.is_mother_customer"> <!-- Approval -->
                                <v-col cols="8">
                                    <v-switch
                                        v-if="actions == 'View' || actions == 'Submit' || userAccess.edit_approve_exec"
                                        v-model="customer_information.approved_by_exec"
                                        :disabled="disable_view"
                                        :true-value="1"
                                        :false-value="0"
                                        label="Approve Exec"
                                        class="my-0"
                                        dense
                                        hide-details
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
            <v-divider class="px-0 ma-0"></v-divider>
            <v-card-actions class="text-center">
                <v-row class="ma-1">
                    <v-col cols="12">
                        <v-btn v-if="actions == 'View' && userAccess.approve_customer && GET_ACTIVE_TAB == 'for_approval'" class="float-center"
                            @click="approveCustomer()">
                            <span>Approve</span>
                        </v-btn>
                        <v-btn v-if="actions != 'View'" class="float-center" :disabled="buttonLoad" :loading="buttonLoad"
                            @click="actions == 'Submit' ? addNewCustomer() : updateCustomer()">
                            <span>{{ actions }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="collectibles.dialog" max-width="75%" persistent>
            <v-card>
                <v-row class="px-3 py-2" dense no-gutters> <!-- Dialog header -->
                    <v-col cols="11">
                        <v-card-title class="pa-0 pl-3">
                            <span class="text-h5">Collectibles</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1">
                        <v-btn text icon small color="gray" class="float-right" @click="collectibles.dialog = false">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="pa-0 ma-0"></v-divider>
                <v-row class="ma-0 pa-0">
                    <v-col class="pa-2 ma-2">
                        <ReportsCardsComponentVue :totals="collectibles.card.totals" :cards="collectibles.card.cards" style="width:100%;"></ReportsCardsComponentVue>
                    </v-col>
                </v-row>
                <v-card-text class="mt-5">
                    <v-row class="mx-1">
                        <v-col>
                            <v-data-table
                            sort-by.sync="selected_month"
                            sort-desc.sync="true"
                            :items="collectibles.items"
                            :headers="collectibles.total_headers"
                            item-key="selected_month"
                            show-expand
                            single-expand
                            dense
                            >
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <v-data-table :headers="collectibles.headers"
                                        :items="item.statement_of_accounts"
                                        hide-default-footer
                                        dark
                                        dense
                                        disable-pagination
                                     >
                                        <template v-slot:[`item.sales_total`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.sales_total | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.selected_month`]="{ item }">
                                            <span class="text-nowrap">

                                                {{ item.selected_month | formatMonthSoa}}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.due_date`]="{ item }">
                                            <span class="text-nowrap">

                                                {{ item.due_date | formatMonthSoa}}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.terms`]="{ item }">
                                            <span class="text-nowrap" v-if="item.terms == 0">
                                                {{ 'CASH' }}
                                            </span>
                                            <span class="text-nowrap" v-else>
                                                {{ item.terms }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.gross_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.gross_amount | currency }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.discount_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.discount_amount | currency }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.total_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.total_amount | currency }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.paid_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.paid_amount | currency }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.balance_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.balance_amount | currency }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.addition_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.addition_amount | currency }}
                                            </span>
                                        </template>

                                        <template v-slot:[`item.deduction_amount`]="{ item }">
                                            <span class="text-nowrap">
                                                &#x20b1;{{ item.deduction_amount | currency }}
                                            </span>
                                        </template>
                                        <template v-slot:[`item.age`]="{ item }">
                                            <span class="text-nowrap">
                                                {{ agingDate(item.due_date) }}
                                            </span>
                                        </template>
                                    </v-data-table >

                                </td>
                            </template>
                            <template v-slot:[`item.total_gross_amount`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.total_gross_amount | currency }}
                                </span>
                            </template>

                            <template v-slot:[`item.total_dm_amount`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.total_dm_amount | currency }}
                                </span>
                            </template>

                            <template v-slot:[`item.total_cm_amount`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.total_cm_amount | currency }}
                                </span>
                            </template>

                            <template v-slot:[`item.total_balance_amount`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.total_balance_amount | currency }}
                                </span>
                            </template>

                            <template v-slot:[`item.total_amount`]="{ item }">
                                <span class="text-nowrap">
                                    &#x20b1;{{ item.total_amount | currency }}
                                </span>
                            </template>

                            <template v-slot:[`item.selected_month`]="{ item }">
                                <span class="text-nowrap">
                                    {{ item.selected_month | formatMonthSoa}}
                                </span>
                            </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="affiliatesDialog" max-width="75%" persistent>
            <v-card>
                <v-row class="px-3 py-2" dense no-gutters> <!-- Dialog header -->
                    <v-col cols="11">
                        <v-card-title class="pa-0 pl-3">
                            <span class="text-h5">Select Affiliates</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="1">
                        <v-btn text icon small color="gray" class="float-right" @click="closeAffiliatesDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="pa-0 ma-0"></v-divider>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-spacer/>
                        <v-col cols="3">
                            <v-text-field
                                v-model="affiliateSearch"
                                placeholder="Search"
                                prepend-icon="mdi-magnify"
                                @change="searchInput()"
                                @click:prepend-icon="searchInput()"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="mx-1">
                        <v-col>
                            <v-data-table
                                v-model="selectedAffiliates"
                                :headers="affiliate_selection_headers"
                                :items="affiliates_selection"
                                :search="affiliateSearch"
                                :options.sync="options"
                                :items-per-page="10"
                                :server-items-length="affiliates_total_selection"
                                :loading="affiliateLoader"
                                item-key="id"
                                class="w-100"
                                loading-text="Loading... Please wait"
                                dense
                                show-select
                            >
                                <template v-slot:item.vip="{ item }">
                                    <span class="badge bg-primary text-white" v-if="item.vip">Yes</span>
                                    <span class="badge bg-danger text-white" v-else>No</span>
                                </template>

                                <template v-slot:item.special="{ item }">
                                    <span class="badge bg-primary text-white" v-if="item.special">Yes</span>
                                    <span class="badge bg-danger text-white" v-else>No</span>
                                </template>

                                <template v-slot:item.is_active="{ item }">
                                    <span class="badge bg-primary text-white" v-if="item.is_active">Yes</span>
                                    <span class="badge bg-danger text-white" v-else>No</span>
                                </template>

                                <template v-slot:item.is_store="{ item }">
                                    <span class="badge bg-primary text-white" v-if="item.is_store">Yes</span>
                                    <span class="badge bg-danger text-white" v-else>No</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-card-text class="text-center">
                            <v-btn @click="addSelectedAffiliates()" :disabled="affiliates_selection == 0" :loading="affiliates_selection == 0">
                                Add Selected
                            </v-btn>
                        </v-card-text>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
// import buttons from "../../Buttons/SubdialogButton.vue";
import buttons_components from "../../../../../assets/js/components-js.js";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import FileUploadComponentVue from "@/views/main/Shared/FileUploadComponent.vue";
import BaseFilesViewerVue from "@/views/main/modules/MasterData/Base/BaseFilesViewer.vue"
import moment from "moment";
import ReportsCardsComponentVue from '../../Reports/ReportsCardsComponent.vue';
export default {
    mixins: [buttons_components],
    components: { FileUploadComponentVue,BaseFilesViewerVue,ReportsCardsComponentVue },
    data() {
        return {
            valid: true,
            tab: 0,
            // buttons: buttons_components.buttons,
            editCustomerId: "",
            add_edit_customer_dialog: false,
            tabs: [
                { text: 'Sales', access: 'edit_customer_sales'},
                { text: 'Accounting', access: 'edit_customer_accounting'},
                { text: 'Exec', access: 'edit_customer_exec'},
            ],

            customer_information: {
                type: "bus",
                id: "",
                company_names: "",
                last_name: "",
                first_name: "",
                middle_name: "",
                deliver_instructions: "",
                shipping_via: "",
                credit_limit: 0,
                tin: { tin: "" },
                vat_rate: 0,
                classification: 1,
                customer_category_id: 1,
                annual_target: 0,
                terms: "",
                sales_man: "",
                sales_coor: null,
                remarks: "",
                terms: "",
                salesman_id: "",
                sales_coordinator_id: "",
                remarks: "",
                accounting_remarks: "",
                credit_limit: "",
                vat_rate: "",
                store: 0,
                recycle_so: 0,
                special: 0,
                is_special: 0,
                vip: 1,
                is_active: 1,
                is_store: 0,
                is_print_no_price: 0,
                recycle_so: 0,
                approved_by_sales: 0,
                approved_by_acct: 0,
                approved_by_exec: 0,
                affiliate_customer_id: null,
                auto_cancel_sales_order_on_dispatch: 0,
                tel_1: [],
                tel_2: [],
                tel_3: [],
                fax: [],
                mobile_1: [],
                mobile_2: [],
                mobile_3: [],
                email: [],
                hold:0,
                customer_code_id:1,
                is_mother_customer: 0,
                override_moq: 0,
                clusters: [],
                sub_clusters: [],
            },
            other_contacts_sales:
            {
                contact_type: "Sales",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            },
            other_contacts_accounting: {
                contact_type: "Accounting",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            },
            cust_id: "",
            action: "",
            tin: "",
            sss: "",
            philhealth: "",
            pagibig: "",
            brand_id: "",
            sales_target_amount: "0.00",
            discount_card_count: 1,
            max_discounts_count: 999,
            max_shipping_address_count: 5,
            max_billing_address_count: 5,
            max_company_address_count: 5,
            add_brand_discount_dialog: false,
            tmp: "true",
            actionResult: {
                model: "Customer",
                action: "",
            },
            counts: 0,
            // other_contacts_sales:
            // {
            //     contact_type: "Sales",
            //     id: "",
            //     email: "",
            //     mobile_1: "",
            //     mobile_2: "",
            //     tel_1: "",
            //     tel_2: "",
            //     tel_3: "",
            //     fax: "",
            // },
            // other_contacts_accounting: {
            //     contact_type: "Accounting",
            //     id: "",
            //     email: "",
            //     mobile_1: "",
            //     mobile_2: "",
            //     tel_1: "",
            //     tel_2: "",
            //     tel_3: "",
            //     fax: "",
            // },
            count: {
                shipping: 0,
                billing: 0,
            },
            classification_id: null,
            country_code: { shipping: [], billing: [] },
            reg_code: { shipping: [], billing: [] },
            province_code: { shipping: [], billing: [] },
            city_code: { shipping: [], billing: [] },
            comp_address: { shipping: [], billing: [] },
            contact_person: { shipping: [], billing: [] },
            contact_number: { shipping: [], billing: [] },
            reg_list: { shipping: [], billing: [] },
            province_list: { shipping: [], billing: [] },
            city_list: { shipping: [], billing: [] },
            address_trash: { shipping: [], billing: [] },
            address_id: {
                shipping: [],
                billing: [],
            },
            discount_cards: [],
            discount_id: [],
            discount_trash: [],
            valid_ids: [],
            brand_discounts: [],
            brand_discount: [],
            brand_name: '',
            brand_list: [],
            countries: [],
            regions: [],
            provinces: [],
            cities: [],
            customer_list: [],
            salesman_list: [],
            errors: {
                company_names: [(v) => !!v || "Company Name is Required"],
                first_name: [(v) => !!v || "First Name is Required"],
                last_name: [(v) => !!v || "Last Name is Required"],
                classification: [(v) => !!v || "Classification is Required"],
                annual_target: [(v) => !!v || "Annual Sales Target is Required"],
                sales_man: [(v) => !!v || "SalesMan is Required"],
                clusters: [v => !!v || 'Cluster is required'],
                sub_clusters: [v => !!v || 'Sub cluster is required'],
            },
            addCompAddress: [],
            shipping_counter: [],
            billing_counter: [],
            comp_id: [],
            discount_text_fields: [],
            discount_types: [],
            discount_card_selected: [],
            brand_selection: [],
            classification_list: [],
            sales_coordinator_list: [],
            disable_view: false,
            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                edit_approve_sales: false,
                edit_approve_acct: false,
                edit_approve_exec: false,
                edit_customer_sales: false,
                edit_customer_accounting: false,
                edit_customer_exec: false,
                delete_file: false,
                approve_customer: false
            },
            total_card_details:{
                total_collectibles:0,
                credit_limit:'',
                total_used:0,
                remaining:0,
                vip:'',
                loading:false
            },
            uploadedFiles:null,
            collectibles:{
                dialog:false,
                headers:[
                    { text: 'Soa ID', align: 'left', value: 'id', },
                    { text: 'Soa Month', align: 'left', value: 'selected_month', },
                    { text: 'Due Month', align: 'left', value: 'due_date', },
                    { text: 'Gross Amount', align: 'left', value: 'gross_amount', },
                    { text: 'DM Amount', align: 'left', value: 'addition_amount', },
                    { text: 'CM Amount', align: 'left', value: 'deduction_amount', },
                    { text: 'Net Amount', align: 'left', value: 'total_amount', },
                    { text: 'Balance Amount', align: 'left', value: 'balance_amount', },
                    { text: 'Agent', align: 'left', value: 'salesman_name', },
                    { text: 'Status', align: 'left', value: 'status_name', },
                    { text: 'Terms', align: 'left', value: 'terms', },
                    { text: 'Age (Day)', align: 'left', value: 'age', },
                ],
                total_headers:[
                    { text: 'SOA MONTH', align: 'left', value: 'selected_month', },
                    { text: 'GROSS AMOUNT', align: 'right', value: 'total_gross_amount', },
                    { text: 'ADDITION AMOUNT', align: 'right', value: 'total_dm_amount', },
                    { text: 'DEDUCTION AMOUNT', align: 'right', value: 'total_cm_amount', },
                    { text: 'TOTAL NET AMOUNT', align: 'right', value: 'total_amount', },
                    { text: 'TOTAL BALANCE AMOUNT', align: 'right', value: 'total_balance_amount', },
                ],
                items:[],
                card:{
                    cards: [
                        { title: 'Total Gross Amount', icon: 'mdi-cash', icon_class: 'total-amount', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_gross_amount' },
                        { title: 'Total DM Amount', icon: 'mdi-cash-plus', icon_class: 'total-amount-booked', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_cm_amount' },
                        { title: 'Total CM Amount', icon: 'mdi-cash-minus', icon_class: 'total-target', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_dm_amount' },
                        { title: 'Total Net Amount', icon: 'mdi-cash-check', icon_class: 'total-customer', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_amount' },
                        { title: 'Total Balance Amount', icon: 'mdi-cash-sync', icon_class: 'total-pending-so', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_balance_amount' },
                        { title: 'Total Overdue Amount', icon: 'mdi-cash-clock', icon_class: 'total-overdue', sm: 0, md: 0, lg: 3, prefix: '&#x20b1; ', suffix: '', filter: 'currency', value: 'total_overdue' },
                    ],
                    totals: {
                        total_cm_amount: 0,
                        total_dm_amount: 0,
                        total_gross_amount: 0,
                        total_balance_amount: 0,
                        total_amount: 0,
                        total_overdue: 0,
                    },
                },
            },
            currency_sign: null,
            affiliate_headers: [
                { text: 'Company', align: 'left', value: 'company_name', sortable: false },
                { text: 'Owner', align: 'left', value: 'full_name', sortable: false },
                { text: 'Salesman', align: 'left', value: 'salesman_names', sortable: false },
                { text: 'VIP', align: 'left', value: 'vip', sortable: false },
                { text: 'Special', align: 'left', value: 'special', sortable: false },
                { text: 'Store', align: 'left', value: 'is_store', sortable: false },
                { text: 'Active', align: 'left', value: 'is_active', sortable: false },
                { text: 'Actions', align: 'center', value: 'action', sortable: false }
            ],
            affiliate_selection_headers: [
                { text: 'Company', align: 'left', value: 'company_name', sortable: false },
                { text: 'Owner', align: 'left', value: 'full_name', sortable: false },
                { text: 'Salesman', align: 'left', value: 'salesman_names', sortable: false },
                { text: 'VIP', align: 'left', value: 'vip', sortable: false },
                { text: 'Special', align: 'left', value: 'special', sortable: false },
                { text: 'Store', align: 'left', value: 'is_store', sortable: false },
                { text: 'Active', align: 'left', value: 'is_active', sortable: false }
            ],
            affiliate_items: [],
            loaded: false,
            affiliateLoader: false,
            affiliatesDialog: false,
            affiliates_selection: [],
            affiliates_total_selection: 0,
            options: {},
            affiliateSearch: '',
            selectedAffiliates: [],
            itemsPerPageOptions: [5, 10, 15],
            itemsPerPage: 10,
            selected_affiliate_ids: [],
            buttonLoad: false
        };
    },
    computed: {
        ...mapGetters([
            "GET_BRAND_DISCOUNT",
            "GET_CUSTOMER_ID",
            "GET_DISCOUNT_TYPE",
            "GET_ALL_COUNTRIES",
            "GET_ALL_REGIONS",
            "GET_ALL_PROVINCE",
            "GET_ALL_CITIES",
            "GET_AFFLIATE_CUSTOMER",
            "GET_BRAND_ARR",
            "ACTION",
            "DIALOGS",
            "GET_SALESMAN_DROPDOWN",
            "GET_SALESMANCOOR_DROPDOWN",
            "SELECTED_CUSTOMER",
            "GET_CLASSIFICATIONS_DROPDOWN",
            "USERACCOUNT_LOAD",
            "GET_CUSTOMER_CATEGORY_SELECTION",
            "USER_ACCESS",
            "GET_CUSTOMER_CODE_DROPDOWN",
            "GET_ACTIVE_TAB",
            "GET_CLUSTER_SELECTION",
            "GET_SUB_CLUSTER_SELECTION",
            "GET_IS_MOTHER",
        ]),
        actions() {
            if (this.ACTION == "Submit") {
                // this.userAccess = {
                //     edit_approve_exec: true,
                //     edit_customer_sales: true,
                //     edit_customer_accounting: true,
                // }

                this.disable_view = false;
                this.discount_types = this.GET_DISCOUNT_TYPE;

                return "Submit";
            } else if (this.ACTION == "Update") {
                this.disable_view = false;
                this.tmp = 'true';
                this._access();
                return "Update";
            } else if (this.ACTION == "View") {
                this.disable_view = true;

                this.getAllCustomerData();
                return "View";
            }
        },
    },
    mounted() {
        this.$store.dispatch("checkAccessUser", this.$router.currentRoute.params.id);
        if (this.shipping_counter.length == 0) {
            this.addShippingAddress();
        }
        if (this.billing_counter.length == 0) {
            this.addBillingAddress();
        }
        if (this.discount_cards.length == 0) {
            this.adddiscounts();
        }
        this.$store.dispatch('getClusterSelection')
    },
    methods: {
        getAllCustomerData() {
            if (this.tmp == "true" && this.DIALOGS) {
                let that = this;

                this.customer_information = JSON.parse(JSON.stringify(this.SELECTED_CUSTOMER));

                this.affiliate_items = this.SELECTED_CUSTOMER.affiliates;
                let tempClusters = this.customer_information.clusters ? this.customer_information.clusters.map(cluster => {
                    return cluster.id;
                }) : [];
                this.customer_information.clusters = tempClusters;
                this.customer_information.sub_clusters = this.SELECTED_CUSTOMER.sub_clusters.map(sub_cluster => sub_cluster.id);

                this.customer_information.company_names = this.SELECTED_CUSTOMER.company_name
                this.discount_types = this.GET_DISCOUNT_TYPE;
                if (typeof this.customer_information.tin == 'object') {
                    this.customer_information.tin = this.customer_information.tin
                } else {
                    this.customer_information.tin = JSON.parse(this.customer_information.tin)
                }
                this.SELECTED_CUSTOMER.other_contacts.filter((e) => {

                    if (that.other_contacts_sales.contact_type == e.contact_type) {
                        that.other_contacts_sales.email = e.email;
                        that.other_contacts_sales.mobile_1 = e.mobile_1;
                        that.other_contacts_sales.mobile_2 = e.mobile_2;
                        that.other_contacts_sales.tel_1 = e.tel_1;
                        that.other_contacts_sales.tel_2 = e.tel_2;
                        that.other_contacts_sales.tel_3 = e.tel_3;
                        that.other_contacts_sales.fax = e.fax;
                        that.other_contacts_sales.id = e.id;
                    }
                    if (that.other_contacts_accounting.contact_type == e.contact_type) {
                        that.other_contacts_accounting.email = e.email;
                        that.other_contacts_accounting.mobile_1 = e.mobile_1;
                        that.other_contacts_accounting.mobile_2 = e.mobile_2;
                        that.other_contacts_accounting.tel_1 = e.tel_1;
                        that.other_contacts_accounting.tel_2 = e.tel_2;
                        that.other_contacts_accounting.tel_3 = e.tel_3;
                        that.other_contacts_accounting.fax = e.fax;
                        that.other_contacts_accounting.id = e.id;
                    }

                });

                // this.updateCities(0,'shipping')
                this.SELECTED_CUSTOMER.shipping_add.forEach((e, key) => {
                    this.address_id.shipping[key] = { id: e.id };
                    this.comp_address.shipping[key] = { address: e.address };
                    this.contact_number.shipping[key] = { ct_num: e.contact_number };
                    this.country_code.shipping[key] = { code: e.country_code };
                    this.reg_code.shipping[key] = { code: e.region_code };
                    this.province_code.shipping[key] = { code: e.province_code };
                    this.city_code.shipping[key] = { code: e.city_code };
                    this.contact_person.shipping[key] = {
                        person: e.contact_person != null ? e.contact_person : "",
                    };
                    this.reg_list.shipping.push({ list: this.GET_ALL_REGIONS });
                    this.province_list.shipping.push({ list: this.GET_ALL_PROVINCE });
                    this.city_list.shipping.push({ list: this.GET_ALL_CITIES });

                    this.shipping_counter[key] = key;
                    this.updateProvinces(key, 'shipping');
                    this.updateCities(key, 'shipping');
                    this.count.shipping++;

                });
                this.SELECTED_CUSTOMER.billing_add.forEach((e, key) => {

                    this.address_id.billing[key] = { id: e.id };
                    this.comp_address.billing[key] = { address: e.address };
                    this.contact_number.billing[key] = { ct_num: e.contact_number };
                    this.country_code.billing[key] = { code: e.country_code };
                    this.reg_code.billing[key] = { code: e.region_code };
                    this.province_code.billing[key] = { code: e.province_code };
                    this.city_code.billing[key] = { code: e.city_code };
                    this.contact_person.billing[key] = {
                        person: e.contact_person != null ? e.contact_person : "",
                    };
                    this.reg_list.billing.push({ list: this.GET_ALL_REGIONS });
                    this.province_list.billing.push({ list: this.GET_ALL_PROVINCE });
                    this.city_list.billing.push({ list: this.GET_ALL_CITIES });
                    this.billing_counter[key] = key;
                    this.updateProvinces(key,'billing')
                    this.updateCities(key,'billing')
                    this.count.billing++;
                });
                this.SELECTED_CUSTOMER.customer_discount.forEach((e, key) => {
                    that.GET_BRAND_DISCOUNT.find(element_dc => {

                        if (element_dc.value == e.brand_id) {
                            this.brand_name = element_dc.text

                        }
                    });
                    this.discount_id[key] = {
                        cust_id: e.customer_id,
                        brand_id: e.brand_id,
                    };
                    this.discount_cards[key] = key;
                    this.discount_text_fields[key] = {
                        arr: [],
                    };
                    this.brand_discount[key] = e.brand_id;
                    e.discount_value.forEach((value, keys) => {
                        this.GET_DISCOUNT_TYPE.forEach((dtype, index) => {
                            if (value.discount_type_id == dtype.value) {
                                this.discount_text_fields[key].arr[index] = 0
                                this.discount_text_fields[key].arr[index] =
                                    value.discount_value;

                            }
                        });
                    });
                });

                if (this.shipping_counter.length == 0) {
                    this.addShippingAddress();
                }
                if (this.billing_counter.length == 0) {
                    this.addBillingAddress();
                }
                if (this.discount_cards.length == 0) {
                    this.adddiscounts();
                }
            }
        },
        adddiscounts() {
            this.discount_cards.push(this.discount_card_count++);
            let count = 0;

            this.discount_cards.forEach((e, key) => {
                count = key;
            });
            this.discount_text_fields[count] = {
                arr: [],
            };
            this.discount_types.forEach((e) => {
                this.discount_text_fields[count].arr.push("");
            });

        },
        brand_change(index) {
            this.GET_BRAND_DISCOUNT.find(e => {
                if (e.value == this.brand_discount[index]) {
                    this.brand_name = e.text
                }
            })
        },
        closeDialog() {
            this.buttonLoad = false;
            this.tmp = "false";
            this.valid = false;
            this.tab = 0;

            this.$store.commit("DIALOG", false);
            this.$store.commit("EDIT_CUSTOMER", []);

            if (this.DIALOGS == false) {
                this.billing_counter = [];
                this.shipping_counter = [];
                this.discount_cards = [];
                this.address_id.shipping = [];
                this.address_id.billing = [];
            }
            this.clearFileUpload();
            this.resetAffiliateFields()
            this.$store.commit('IS_MOTHER', 0)
        },
        removeDiscount(key) {
            let that = this;
            this.tmp = "false";
            if (that.discount_text_fields[key].arr.length > 0) {
                this.discount_trash.push(this.discount_id[key]);
                that.discount_text_fields.splice(key, 1);
                this.brand_discount.splice(key, 1);
                this.discount_cards.splice(key, 1);
            }
        },
        addShippingAddress() {
            if (this.shipping_counter.length < this.max_company_address_count) {
                this.tmp = "true";
                let count = 0;
                this.shipping_counter.push(this.count.shipping++);
                this.shipping_counter.forEach((e, key) => {
                    count = key;
                });
                this.comp_address.shipping[count] = { address: "" };
                this.contact_number.shipping[count] = { ct_num: "" };
                this.country_code.shipping[count] = { code: "608" };
                this.reg_code.shipping[count] = { code: "" };
                this.province_code.shipping[count] = { code: "" };
                this.city_code.shipping[count] = { code: "" };
                this.contact_person.shipping[count] = { person: "" };
                this.reg_list.shipping.push({ list: this.GET_ALL_REGIONS });
                this.province_list.shipping.push({ list: this.GET_ALL_PROVINCE });
                this.city_list.shipping.push({ list: this.GET_ALL_CITIES });
            }
        },
        addBillingAddress() {
            if (this.billing_counter.length < this.max_company_address_count) {
                this.tmp = "true";
                let count = 0;
                this.billing_counter.push(this.count.billing++);
                this.billing_counter.forEach((e, key) => {
                    count = key;
                });
                this.comp_address.billing[count] = { address: "" };
                this.contact_number.billing[count] = { ct_num: "" };
                this.country_code.billing[count] = { code: "608" };
                this.reg_code.billing[count] = { code: "" };
                this.province_code.billing[count] = { code: "" };
                this.city_code.billing[count] = { code: "" };
                this.contact_person.billing[count] = { person: "" };
                this.reg_list.billing.push({
                    list: this.GET_ALL_REGIONS,
                });
                this.province_list.billing.push({
                    list: this.GET_ALL_PROVINCE,
                });
                this.city_list.billing.push({
                    list: this.GET_ALL_CITIES,
                });
            }
        },
        removeBillingAddress() {
            this.tmp = "false";
            if (this.billing_counter.length > 0) {
                const deleteaddress = this.billing_counter.pop();
                this.address_trash.billing.push({
                    id: this.address_id.billing[deleteaddress].id,
                });
            }
        },
        removeShippingAddress() {
            this.tmp = "false";
            if (this.shipping_counter.length == 0) {
                this.addShippingAddress();
            }
            if (this.shipping_counter.length > 0) {
                const deleteaddress = this.shipping_counter.pop();

                this.address_trash.shipping.push({
                    id: this.address_id.shipping[deleteaddress].id,
                });
            }
        },
        updateRegions(key, address_type) {
            if (address_type == "billing") {
                if (this.country_code.billing[key].code == "608") {
                    this.reg_code.billing[key].code = this.GET_ALL_REGIONS.regCode;
                    this.province_code.billing[key].code = this.GET_ALL_PROVINCE.provCode;
                    this.city_code.billing[key].code = this.GET_ALL_CITIES.citymunCode;
                    this.reg_list.billing[key].list = this.GET_ALL_REGIONS;
                    this.province_list.billing[key].list = this.GET_ALL_PROVINCE;
                    this.city_list.billing[key].list = this.GET_ALL_CITIES;
                } else {
                    this.reg_code.billing[key].code = "";
                    this.province_code.billing[key].code = "";
                    this.city_code.billing[key].code = "";
                    this.reg_list.billing[key].list = [];
                    this.province_list.billing[key].list = [];
                    this.city_list.billing[key].list = [];
                }
            } else if (address_type == "shipping") {
                if (this.country_code.shipping[key].code == "608") {
                    this.reg_code.shipping[key].code = this.GET_ALL_REGIONS.regCode;
                    this.province_code.shipping[key].code =this.GET_ALL_PROVINCE.provCode;
                    this.city_code.shipping[key].code = this.GET_ALL_CITIES.citymunCode;
                    this.reg_list.shipping[key].list = this.GET_ALL_REGIONS;
                    this.province_list.shipping[key].list = this.GET_ALL_PROVINCE;
                    this.city_list.shipping[key].list = this.GET_ALL_CITIES;
                } else {
                    this.reg_code.shipping[key].code = "";
                    this.province_code.shipping[key].code = "";
                    this.city_code.shipping[key].code = "";
                    this.reg_list.shipping[key].list = [];
                    this.province_list.shipping[key].list = [];
                    this.city_list.shipping[key].list = [];
                }
            }
        },
        updateProvinces(key, address_type) {

            if (address_type == "billing") {

                if (
                    this.reg_code.billing[key].code != null &&
                    this.reg_code.billing[key].code != ""
                ) {
                    var regCode = this.reg_code.billing[key].code;
                    this.province_list.billing[key].list = this.GET_ALL_PROVINCE.filter(
                        function (province) {
                            return province.regCode == regCode;
                        }
                    );

                    if (this.province_code.billing[key].code != "") {
                        var selectedProvCode = this.province_code.billing[key].code;
                        var result = this.province_list.billing[key].list.find(
                            (province) => {
                                return province.value == selectedProvCode;
                            }
                        );

                        if (result == null) {
                            this.province_code.billing[key].code = "";
                            this.updateCities(key, "billing");
                        }
                    } else if (this.city_code.billing[key].code != "") {
                        var selectedCityMunCode = this.city_code.billing[key].code;
                        var result = this.city_list.billing[key].list.find((city) => {
                            return city.value == selectedCityMunCode;
                        });

                        if (result != null) {
                            this.province_code.billing[key].code = result.provCode;
                            this.updateCities(key, "billing");
                        }
                    }
                } else {
                    this.province_list.billing[key].list = "";
                    this.province_code.billing[key].code = "";
                    this.updateCities(key, "billing");
                }
            } else if (address_type == "shipping") {

                if (
                    this.reg_code.shipping[key].code != null &&
                    this.reg_code.shipping[key].code != ""
                ) {
                    var regCode = this.reg_code.shipping[key].code;

                        this.province_list.shipping[key].list = this.GET_ALL_PROVINCE.filter(
                            function (province) {
                                return province.regCode == regCode;
                            }
                        );


                    if (this.province_code.shipping[key].code != "") {
                        var selectedProvCode = this.province_code.shipping[key].code;
                        var result = this.province_list.shipping[key].list.find(
                            (province) => {
                                return province.value == selectedProvCode;
                            }
                        );

                        if (result == null) {
                            this.province_code.shipping[key].code = "";
                            this.updateCities(key, "shipping");
                        }
                    } else if (this.city_code.shipping[key].code != "") {
                        var selectedCityMunCode = this.city_code.shipping[key].code;
                        var result = this.city_list.shipping[key].list.find((city) => {
                            return city.value == selectedCityMunCode;
                        });

                        if (result != null) {
                            this.province_code.shipping[key].code = result.provCode;
                            this.updateCities(key, "shipping");
                        }
                    }
                } else {
                    this.province_list.shipping[key].list = "";
                    this.province_code.shipping[key].code = "";
                    this.updateCities(key, "billing");
                }
            }
        },
        updateCities(key, address_type) {
            if (address_type == "billing") {
                if (this.province_code.billing[key]) {
                    if (
                        this.province_code.billing[key].code != null &&
                        this.province_code.billing[key].code != ""
                    ) {
                        var provCode = this.province_code.billing[key].code;
                        this.city_list.billing[key].list = this.GET_ALL_CITIES.filter(
                            function (city) {
                                return city.provCode == provCode;
                            }
                        );
                        var selectedCityCode = this.city_code.billing[key].code;
                        var result = this.city_list.billing[key].list.find((city) => {
                            return city.value == selectedCityCode;
                        });
                        if (result == null) {
                            this.city_code.billing[key].code = "";
                        }
                    } else {
                        this.city_list.billing[key].list = [];
                        this.city_code.billing[key].code = "";
                    }
                }
            } else if (address_type == "shipping") {
                if (
                    this.province_code.shipping[key].code != null &&
                    this.province_code.shipping[key].code != ""
                ) {
                    var provCode = this.province_code.shipping[key].code;
                    this.city_list.shipping[key].list = this.GET_ALL_CITIES.filter(
                        function (city) {
                            return city.provCode == provCode;
                        }
                    );
                    var selectedCityCode = this.city_code.shipping[key].code;
                    var result = this.city_list.shipping[key].list.find((city) => {
                        return city.value == selectedCityCode;
                    });
                    if (result == null) {
                        this.city_code.shipping[key].code = "";
                    }
                } else {
                    this.city_list.shipping[key].list = [];
                    this.city_code.shipping[key].code = "";
                }
            }

            this.selectRegion(key, address_type);
        },
        selectRegion(key, address_type) {
            if (address_type == "billing") {
                if (this.city_code.billing[key]) {
                    if (
                        this.city_code.billing[key].code != null &&
                        this.city_code.billing[key].code != ""
                    ) {
                        var citymun_code = this.city_code.billing[key].code;
                        var selectedCity = this.city_list.billing[key].list.filter(function (
                            city
                        ) {
                            return city.value == citymun_code;
                        });
                        if (selectedCity.length > 0) {
                            this.reg_code.billing[key].code = selectedCity[0].regDesc;
                            this.updateProvinces(key, "billing");
                        }
                    } else if (
                        this.province_code.billing[key].code != null &&
                        this.province_code.billing[key].code != ""
                    ) {
                        var prov_code = this.province_code.billing[key].code;
                        var selectedProv = this.province_list.billing[key].list.filter(
                            function (prov) {
                                return prov.value == prov_code;
                            }
                        );
                        if (selectedProv.length > 0) {
                            this.reg_code.billing[key].code = selectedProv[0].regCode;
                        }
                    }
                }
            } else if (address_type == "shipping") {
                if (
                    this.city_code.shipping[key].code != null &&
                    this.city_code.shipping[key].code != ""
                ) {
                    var citymun_code = this.city_code.shipping[key].code;
                    var selectedCity = this.city_list.shipping[key].list.filter(function (
                        city
                    ) {
                        return city.value == citymun_code;
                    });
                    if (selectedCity.length > 0) {
                        this.reg_code.shipping[key].code = selectedCity[0].regDesc;
                        this.updateProvinces(key, "shipping");
                    }
                } else if (
                    this.province_code.shipping[key].code != null &&
                    this.province_code.shipping[key].code != ""
                ) {
                    var prov_code = this.province_code.shipping[key].code;
                    var selectedProv = this.GET_ALL_PROVINCE.filter(function (prov) {
                        return prov.value == prov_code;
                    });
                    if (selectedProv.length > 0) {
                        this.reg_code.shipping[key].code = selectedProv[0].regCode;
                    }
                }
            }
        },
        async addNewCustomer() {
            const that = this
            that.buttonLoad = true;
            const address_type = {
                shipping: "shipping",
                billing: "billing",
            }
            const swal_texts = [];

            if (!this.customer_information.is_mother_customer) {
                // if (this.uploadedFiles == null) {
                //     Swal.fire({
                //         title: "Please Upload Images or Files",
                //         icon: "warning",
                //     })

                //     return false
                // }

                // let classifications_validation = that.userAccess.edit_customer_accounting ? null : that.customer_information.classification
                this.$refs.form.validate();
                const cpname_swal_text = this.customer_information.company_names
                    ? ""
                    : "Company Name";
                // const classification_swal_text = classifications_validation
                //     ? ""
                //     : "Classification";
                const fname_swal_text = this.customer_information.first_name
                    ? ""
                    : "First Name";
                const lname_swal_text = this.customer_information.last_name
                    ? ""
                    : "Last Name";

                const array_text = [
                    cpname_swal_text,
                    // classification_swal_text,
                    fname_swal_text,
                    lname_swal_text,
                ];

                const filtered = array_text.filter(function (el) {
                    return el.replace(",", "");
                });

                if (
                    !that.customer_information.company_names &&
                    !that.customer_information.last_name &&
                    !that.customer_information.first_name
                    // !classifications_validation
                ) {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: "warning",
                        allowOutsideClick: false,
                    });

                    return false
                }
            } else {
                this.$refs.form.validate();
                const compname_swal_text = this.customer_information.company_names
                ? ""
                : "Company Name";
                // const annual_target_swal_text = this.customer_information.annual_target
                // ? ""
                // : "Annual Target";
                const sales_man_swal_text = this.customer_information.sales_man
                ? ""
                : "Sales Man";

                if (this.tab == 0) {
                    swal_texts.push(
                        compname_swal_text,
                        // annual_target_swal_text,
                        sales_man_swal_text
                    )
                } else {
                    swal_texts.push(
                        compname_swal_text
                    )
                }

                const filtered = swal_texts.filter(function (el) {
                    return el.replace(",", "");
                });

                if (this.tab == 0) {
                    if (!that.customer_information.company_names ||
                        // !that.customer_information.annual_target ||
                        !that.customer_information.sales_man) {

                        Swal.fire({
                            text: `Fill Required Fields (${filtered})`,
                            icon: "warning",
                            allowOutsideClick: false
                        });

                        return false;
                    }
                } else {
                    if (!that.customer_information.company_names) {
                        Swal.fire({
                            text: `Fill Required Fields (${filtered})`,
                            icon: "warning",
                            allowOutsideClick: false
                        });

                        return false;
                    }
                }
            }

            const data_company_information = {
                customer: that.customer_information,
                other_contacts_sales: that.other_contacts_sales,
                other_contacts_accounting: that.other_contacts_accounting,
                tin: JSON.stringify(this.customer_information.tin),
                action: 'create'
            };

            await this.$store.dispatch("addcustomers", data_company_information).then(response => {
            // this.$store.dispatch("addCustomerTempRecord", data_company_information).then((response) => {
                const data_address = {
                    billing_address_id: this.address_id,
                    cust_id: response,
                    type: address_type,
                    shipping_counter: that.shipping_counter,
                    billing_counter: that.billing_counter,
                    countries: that.country_code,
                    regions: that.reg_code,
                    province: that.province_code,
                    cities: that.city_code,
                    address: that.comp_address,
                    contact_number: that.contact_number,
                    contact_person: that.contact_person,
                    trash: that.address_trash,
                    approved: 0
                };

                const data_discount = {
                    trash: this.discount_trash,
                    cust_id: response,
                    brand_dc: that.brand_discount,
                    brand_name: that.brand_name,
                    dc_type: that.discount_types,
                    dc_arr: !that.brand_discount.length ? [] : that.discount_cards,
                    dc_text_arr: that.cleanInput(that.discount_text_fields),
                    action: 'create',
                    approved: 0
                };

                if (!this.customer_information.is_mother_customer) {
                    if (that.brand_discount.length) {
                        this.$store.dispatch("adddiscount", data_discount);
                    }
                    this.$store.dispatch("addshippingaddress", data_address);
                    this.$store.dispatch("addbillingaddress", data_address);
                }
                this.uploadCustomerFile(response);
                this.$store.commit("NEW_CUSTOMER", true);
                this.tmp = "false";
                // this.adddiscounts();
                this.closeDialog();
            });
        },
        updateCustomer() {
            const that = this;
            that.buttonLoad = true;
            const address_type = {
                shipping: "shipping",
                billing: "billing",
            };
            const swal_texts = [];

            if (!this.customer_information.is_mother_customer) {
                this.$refs.form.validate();
                const cpname_swal_text = this.customer_information.company_names
                    ? ""
                    : "Company Name";
                // const classification_swal_text = this.customer_information.classification
                //     ? ""
                //     : "Classification";
                const fname_swal_text = this.customer_information.first_name
                    ? ""
                    : "First Name";
                const lname_swal_text = this.customer_information.last_name
                    ? ""
                    : "Last Name";

                const array_text = [
                    cpname_swal_text,
                    // classification_swal_text,
                    fname_swal_text,
                    lname_swal_text,
                ];

                const filtered = array_text.filter(function (el) {
                    return el.replace(",", "");
                });

                if (
                    !that.customer_information.company_names &&
                    !that.customer_information.last_name &&
                    !that.customer_information.first_name
                    // !classifications_validation
                ) {
                    Swal.fire({
                        text: `Fill Required Fields (${filtered})`,
                        icon: "warning",
                        allowOutsideClick: false,
                    });

                    return false
                }
            } else {
                this.$refs.form.validate();
                const compname_swal_text = this.customer_information.company_names
                ? ""
                : "Company Name";
                // const annual_target_swal_text = this.customer_information.annual_target
                // ? ""
                // : "Annual Target";
                const sales_man_swal_text = this.customer_information.sales_man
                ? ""
                : "Sales Man";

                if (this.tab == 0) {
                    swal_texts.push(
                        compname_swal_text,
                        // annual_target_swal_text,
                        sales_man_swal_text
                    )
                } else {
                    swal_texts.push(
                        compname_swal_text
                    )
                }

                const filtered = swal_texts.filter(function (el) {
                    return el.replace(",", "");
                });

                if (this.tab == 0) {
                    if (!that.customer_information.company_names ||
                        // !that.customer_information.annual_target ||
                        !that.customer_information.sales_man) {

                        Swal.fire({
                            text: `Fill Required Fields (${filtered})`,
                            icon: "warning",
                            allowOutsideClick: false
                        });

                        return false;
                    }
                } else {
                    if (!that.customer_information.company_names) {
                        Swal.fire({
                            text: `Fill Required Fields (${filtered})`,
                            icon: "warning",
                            allowOutsideClick: false
                        });

                        return false;
                    }
                }
            }

            const data_company_information = {
                customer: that.customer_information,
                other_contacts_sales: that.other_contacts_sales,
                other_contacts_accounting: that.other_contacts_accounting,
                tin: JSON.stringify(this.customer_information.tin),
                action:'update',
                affiliates: that.affiliate_items
            };
            this.$store.dispatch(this.GET_ACTIVE_TAB == 'for_approval' ? 'updatecustomers': 'addcustomers', data_company_information).then(async (response) => {
            // this.$store.dispatch("addCustomerTempRecord", data_company_information).then((response) => {
                const data_address = {
                    billing_address_id: this.address_id,
                    cust_id: this.GET_ACTIVE_TAB == 'for_approval' ? this.SELECTED_CUSTOMER.id :response,
                    type: address_type,
                    shipping_counter: that.shipping_counter,
                    billing_counter: that.billing_counter,
                    countries: that.country_code,
                    regions: that.reg_code,
                    province: that.province_code,
                    cities: that.city_code,
                    address: that.comp_address,
                    contact_number: that.contact_number,
                    contact_person: that.contact_person,
                    trash: that.address_trash,
                    approved: 0,
                }

                const data_discount = {
                    cust_id: this.GET_ACTIVE_TAB == 'for_approval' ? this.SELECTED_CUSTOMER.id :response,
                    brand_dc: that.brand_discount,
                    brand_name: that.brand_name,
                    dc_type: that.discount_types,
                    dc_arr: !that.brand_discount.length ? [] : that.discount_cards,
                    dc_text_arr: await that.cleanInput(that.discount_text_fields),
                    trash: this.discount_trash,
                    action: 'update',
                    approved: 0,
                }

                if (!this.customer_information.is_mother_customer) {
                    if (that.brand_discount.length) {
                        this.$store.dispatch("adddiscount", data_discount);
                    }
                    this.$store.dispatch("addbillingaddress", data_address);
                    this.$store.dispatch("addshippingaddress", data_address);
                }

                this.uploadCustomerFile(response);

                this.$store.commit("NEW_CUSTOMER", true);
                this.tmp = "true";

                this.address_id.shipping = [];
                this.address_id.billing = [];
                that.shipping_counter = [];
                that.billing_counter = [];
                // this.selected_affiliate_ids = [];
                this.resetAffiliateFields();
                this.closeDialog()
            });
        },
        _access() {
            let val = this.USER_ACCESS;
            if (val != "fail") {
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                this.userAccess.edit_approve_sales = false;
                this.userAccess.edit_approve_acct = false;
                this.userAccess.edit_approve_exec = false;
                this.userAccess.edit_customer_sales = false;
                this.userAccess.edit_customer_accounting = false;
                this.userAccess.edit_customer_exec = false;
                this.userAccess.delete_file = false;
                this.userAccess.approve_customer = false;
                val.forEach(e => {
                    if (e.actions_code == 'edit') {
                        this.userAccess.edit = true;
                    } else if (e.actions_code == 'delete') {
                        this.userAccess.delete = true;
                    } else if (e.actions_code == 'view') {
                        this.userAccess.view = true;
                    } else if (e.actions_code == 'create') {
                        this.userAccess.create = true;
                    } else if (e.actions_code == 'edit_approve_sales') {
                        this.userAccess.edit_approve_sales = true;
                    } else if (e.actions_code == 'edit_approve_acct') {
                        this.userAccess.edit_approve_acct = true;
                    } else if (e.actions_code == 'edit_approve_exec') {
                        this.userAccess.edit_approve_exec = true;
                    }
                    else if (e.actions_code == 'edit_customer_sales') {
                        this.userAccess.edit_customer_sales = true;
                    }
                    else if (e.actions_code == 'edit_customer_accounting') {
                        this.userAccess.edit_customer_accounting = true;
                    }
                    else if (e.actions_code == 'edit_customer_exec') {
                        this.userAccess.edit_customer_exec = true;
                    }
                    else if (e.actions_code == 'delete_file') {
                        this.userAccess.delete_file = true;
                    }
                    else if (e.actions_code == 'approve_customer') {
                        this.userAccess.approve_customer = true;
                    }
                });
            }
        },
        resetfields() {
            this.customer_information = {
                type: "bus",
                id: "",
                company_names: "",
                last_name: "",
                first_name: "",
                middle_name: "",
                deliver_instructions: "",
                shipping_via: "",
                credit_limit: "",
                tin: { tin: "" },
                vat_rate: "",
                classification: "",
                annual_target: "",
                terms: "",
                sales_man: "",
                sales_coor: "",
                remarks: "",
                terms: "",
                salesman_id: "",
                sales_coordinator_id: "",
                remarks: "",
                credit_limit: "",
                store: 0,
                recycle_so: 0,
                special: 0,
                is_special: 0,
                vip: 0,
                is_active: 1,
                is_store: 0,
                is_print_no_price: 1,
                recycle_so: 0,
                approved_by_sales: 0,
                approved_by_acct: 0,
                approved_by_exec: 0,
                affiliate_customer_id: null,
                auto_cancel_sales_order_on_dispatch: 0,
                tel_1: [],
                tel_2: [],
                tel_3: [],
                fax: [],
                mobile_1: [],
                mobile_2: [],
                mobile_3: [],
                email: [],
                is_mother_customer: 0,
                override_moq: 0,
                clusters: [],
            };
            this.other_contacts_sales =
            {
                contact_type: "Sales",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            }
            this.other_contacts_accounting = {
                contact_type: "Accounting",
                id: "",
                email: "",
                mobile_1: "",
                mobile_2: "",
                tel_1: "",
                tel_2: "",
                tel_3: "",
                fax: "",
            },
            this.total_card_details={
                total_collectibles:0,
                credit_limit:'',
                total_used:0,
                remaining:0,
                vip:'',
                loading:true,
            },
            this.currency_sign = '₱'

            if (this.shipping_counter.length == 0) {
                this.addShippingAddress();
            }
            if (this.billing_counter.length == 0) {
                this.addBillingAddress();
            }
            if (this.discount_cards.length == 0) {
                this.adddiscounts();
            }
        },
        getCardDetails() {
            if (this.ACTION !== 'Submit') {
                this.total_card_details.loading = true
                this.$store.dispatch("getCustomerCardDetails", this.GET_ACTIVE_TAB == 'for_approval' ? this.SELECTED_CUSTOMER.customer_id : this.SELECTED_CUSTOMER.id).then(response=>{
                    let data = response.data
                    this.total_card_details.total_collectibles = parseFloat(data.collectibles_and_credit_limit[0].total_collectibles)
                    this.total_card_details.credit_limit = data.credit_limit[0].credit_limit
                    this.total_card_details.vip = data.credit_limit[0].vip
                    this.total_card_details.total_used = parseFloat(data.total_used[0].total_used)
                    if(data.credit_limit[0].credit_limit != 'vip'){
                        this.currency_sign = '₱'
                        this.total_card_details.remaining = parseFloat(this.total_card_details.credit_limit - (this.total_card_details.total_used + this.total_card_details.total_collectibles ))
                    }
                    else{
                        this.currency_sign = '∞'
                        this.total_card_details.remaining = 0
                    }

                    this.total_card_details.loading = false
                }).catch((err)=>{
                    this.total_card_details.loading = false

                });
            }
        },
        uploadedData(data) {
            if (!data) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = data;
        },
        clearFileUpload(confirm) {
            if (confirm) {
                this.uploadedFiles = null;
            }
            this.uploadedFiles = null;
            this.$refs.fileUpload.reset();
        },
        uploadCustomerFile(id = null){
            const data = new FormData();

            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            if (!!this.uploadedFiles && this.uploadedFiles.attachments.length > 0) {
                let files = this.uploadedFiles.attachments;

                for (let i = 0; i < _.compact(files).length; i++) {
                    data.append("files[]", files[i]);
                }
            }
            data.append("customer_id",!!id ? id : this.customer_information.id)
            let payload = {
                params: data,
                config: config,
            };

            this.$store.dispatch('uploadCustomerTempFile', payload).then(response=>{
                this.clearFileUpload()
            })
        },
        showCollectiblesByCustomer(){
            this.collectibles.dialog = true
            this.collectibles.items = []
            this.collectibles.card.totals = {
                total_cm_amount: 0,
                total_dm_amount: 0,
                total_gross_amount: 0,
                total_balance_amount: 0,
                total_amount: 0,
            }
            let payload = {
                customer_id:this.customer_information.id
            }
            this.$store.dispatch('showCustomerCollectibles',payload).then(response=>{
                this.collectibles.items = response.data
                let soa_month =  _.groupBy(this.collectibles.items, 'selected_month');
                let test = Object.values(soa_month)
                let grouping = []
                let date_today = moment().format('YYYY-MM-DD');
                let overdue_container = []
                test.forEach(e=>{
                    let a = {}
                    this.collectibles
                    let selected_month = ''
                    e.forEach(x=>{
                        selected_month = x.selected_month
                        x.deduction_amount = Number (x.deduction_amount)
                        x.addition_amount = Number (x.addition_amount)
                        x.balance_amount = Number (x.balance_amount)
                        x.gross_amount = Number (x.gross_amount)
                        x.total_amount = Number (x.total_amount)
                        if(moment(x.due_date).isBefore(date_today)){
                            overdue_container.push(x.balance_amount)
                        }
                    })

                    Object.assign(a,{
                        statement_of_accounts: e,
                        total_cm_amount: _.sumBy(e,'deduction_amount'),
                        total_dm_amount: _.sumBy(e,'addition_amount'),
                        total_gross_amount: _.sumBy(e,'gross_amount'),
                        total_balance_amount: _.sumBy(e,'balance_amount'),
                        total_amount: _.sumBy(e,'total_amount'),
                        selected_month: selected_month
                    })
                    this.collectibles.card.totals.total_gross_amount += _.sumBy(e,'gross_amount')
                    this.collectibles.card.totals.total_balance_amount += _.sumBy(e,'balance_amount')
                    this.collectibles.card.totals.total_amount += _.sumBy(e,'total_amount')
                    this.collectibles.card.totals.total_dm_amount += _.sumBy(e,'deduction_amount')
                    this.collectibles.card.totals.total_cm_amount += _.sumBy(e,'addition_amount')
                    this.collectibles.card.totals.total_overdue = _.sum(overdue_container)

                    grouping.push(a)
                })
                this.collectibles.items = grouping;
            })
        },
        agingDate(due_date) {
            if(!!due_date) {
                let startDay = moment(due_date);

                let endDay = moment();

                let days =  moment(endDay).diff(moment(startDay), 'days');
                if(days <= 0){
                    days = 0
                    return days
                }
                else{
                    return days
                }
            }
            return '';
        },
        approveCustomer(){
            const that = this;
            const address_type = {
                shipping: "shipping",
                billing: "billing",
            };
            let payload = {
                id: this.SELECTED_CUSTOMER.id,
                approved: 1,
                action: this.SELECTED_CUSTOMER.action
            }
            this.$store.dispatch('addcustomers',payload).then((res)=>{
                const data_discount = {
                    trash: this.discount_trash,
                    cust_id: this.SELECTED_CUSTOMER.customer_id ?? res,
                    brand_dc: that.brand_discount,
                    brand_name: that.brand_name,
                    dc_type: that.discount_types,
                    dc_arr: !that.discount_text_fields.length ? [] : that.discount_cards,
                    dc_text_arr: that.cleanInput(that.discount_text_fields),
                    action: 'create',
                    approved: 1
                };

                if (that.discount_text_fields.length) {
                    this.$store.dispatch("adddiscount", data_discount);
                }

                const data_address = {
                    billing_address_id: this.address_id,
                    cust_id: this.SELECTED_CUSTOMER.customer_id ?? res,
                    type: address_type,
                    shipping_counter: that.shipping_counter,
                    billing_counter: that.billing_counter,
                    countries: that.country_code,
                    regions: that.reg_code,
                    province: that.province_code,
                    cities: that.city_code,
                    address: that.comp_address,
                    contact_number: that.contact_number,
                    contact_person: that.contact_person,
                    trash: that.address_trash,
                    approved: 1
                };

                this.$store.dispatch("addshippingaddress", data_address);
                this.$store.dispatch("addbillingaddress", data_address);

            }).catch((err)=>{
                Swal.fire(
                    "Error",
                    "",
                    "error"
                );
                console.log(err)
            })

            this.$store.commit("NEW_CUSTOMER", true);
            this.tmp = "true";
            this.resetfields()

        },
        openAffiliatesDialog(){
            this.affiliatesDialog = true;
            this.getCustomers()
        },
        getCustomers(){
            this.affiliates_selection = [];
            this.affiliateLoader = true;
            let payload = {
                search: this.affiliateSearch,
                status_text: 'for_affiliate',
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc
            }
            this.$store.dispatch('getallcustomer', payload).then((response) => {
                let data = response.data.data;
                this.affiliates_selection = data.data;
                this.affiliates_total_selection = data.total;
                this.affiliateLoader = false;
            }).catch((error) => {
                console.log(error);
            });
            this.selectedAffiliates = this.affiliate_items
        },
        closeAffiliatesDialog(){
            this.affiliateSearch = '';
            this.affiliatesDialog = false;
        },
        searchInput(){
            this.options.page = 1;
            this.getCustomers({ query: this.affiliateSearch });
        },
        addSelectedAffiliates(){
            this.affiliate_items = this.selectedAffiliates

            this.closeAffiliatesDialog();

            Swal.fire({
                text: 'Affiliates Added',
                icon: "success",
                allowOutsideClick: false,
            });
        },
        removeAffiliate(item){
            this.affiliate_items = this.affiliate_items.filter(e=>{
                if(item.id != e.id){
                    return e
                }
            })
            this.$forceUpdate();
        },
        resetAffiliateFields(){
            this.affiliate_items = [];
            this.selectedAffiliates = [];
        },
        numberAndSlashOnly ($event) {
            const allowedCharacters = /^[0-9\/]*$/;
            const inputChar = $event.key;
            if (!allowedCharacters.test(inputChar)) {
                $event.preventDefault();
            }
        },
        async cleanInput(inputArr) {
            // const cleanedArr = inputArr.map(item => {
            //     const cleanedItemArr = item.arr.map(e => {
            //         // Replace multiple forward slashes with single forward slash
            //         let cleanedItem = e.replace(/\/{2,}/g, '/');
            //         // Remove any trailing special characters
            //         cleanedItem = cleanedItem.replace(/[^\d/]$/, '');
            //         // Remove forward slashes at the beginning and end
            //         cleanedItem = cleanedItem.replace(/^\/+|\/+$/g, '');
            //         return cleanedItem;
            //     });
            //     return cleanedItemArr;
            // });
            // return [cleanedArr];
            let cleanedArr = inputArr

            for (const e in cleanedArr) {
                for (const a in e) {
                    a.replace(/\/{2,}/g, '/').replace(/[^\d/]$/, '').replace(/^\/+|\/+$/g, '')
                }
            }

            return cleanedArr
        }
    },

    created() {
        this.getCardDetails();
        if (this.ACTION == 'Update') {
            this.getAllCustomerData();
        }
        if (this.tmp == "false") {
            this.resetfields();
        }
    },
    watch: {
        DIALOGS: {
            handler(val) {
                if (val) {
                    this.getCardDetails();
                    this.$store.dispatch("getBranddiscount");
                    this.$store.dispatch("discounttype");
                    this.$store.dispatch("getAffliatecustomer");
                    this.$store.dispatch("getSalesmanDropDown");
                    this.$store.dispatch("getSalesmanCoorDropDown");
                    this.$store.dispatch("getCountries");
                    this.$store.dispatch("getRegions");
                    this.$store.dispatch("getProvince");
                    this.$store.dispatch("getCities");
                    this.$store.dispatch("getCustomerCode");
                    this.$store.dispatch("getClassification");
                    if (this.tmp == "false") {
                        this.resetfields();
                    }
                    else if (this.tmp == "true") {
                        this.getAllCustomerData();

                    }

                }
            },
        },
        'tmp': {
            handler(val) {
                if (this.ACTION == 'Update') {
                    this.getAllCustomerData();
                }
            },
        },
        GET_CUSTOMER_ID: {
            handler(val) {
                this.cust_id = val;
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                this._access();
            },
        },
        options: {
            handler(){
                this.getCustomers();
            },
            deep: true
        },
        GET_IS_MOTHER: {
            handler(val){
                if (val == 1) {
                    this.customer_information.is_mother_customer = val
                } else {
                    this.customer_information.is_mother_customer = 0
                }
            },
            immediate: true,
        },
        'customer_information.clusters': {
            handler(val, oldVal) {
                if (oldVal.length > 0) {
                    this.customer_information.sub_clusters = this.customer_information.sub_clusters.filter(sub_cluster => (
                        this.GET_SUB_CLUSTER_SELECTION.some(element => element.value === sub_cluster && val.includes(element.parent))
                    ));
                }

                this.$store.dispatch("getSubClusterSelection", {cluster_ids: val});
            },
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
// div ::v-deep .v-label.v-label--active.theme--light {
//     left: 0 !important;
//     right: auto !important;
// }

// div ::v-deep .v-input__slot fieldset legend {
//     margin-left: 10px !important;
//     width: auto;
// }
</style>
