<template>
    <v-container class="tabs-body-color ma-0 pa-0">
        <v-btn @click="showAddEditDialog('')" class="mt-2" v-if="userAccess.create">
            <v-icon>
                mdi-plus
            </v-icon>
            Add
        </v-btn>
        <MemoViewComponentVue :view_memo_details="viewMemoDetails" :memo_type="memoType"></MemoViewComponentVue>
        <v-dialog v-model="addEditDialog" max-width="35%" persistent>
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="8">
                            Change Delivery Receipt Date
                        </v-col>
                        <v-col cols="pull-right 4">
                            <v-btn text icon color="gray" class="float-right" @click="closeDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="px-0 my-0"></v-divider>
                <v-card-text>
                    <v-row class="my-2">
                        <v-col cols="12">
                            <v-row class="mt-5 mx-auto">
                                <v-col cols='12'>
                                    <v-autocomplete
                                        :readonly="isReadOnly"
                                        outlined
                                        dense
                                        :items="GET_CUSTOMER_WITH_PACKING_LIST_SELECTION" v-model="packing_list.customer_id"
                                        label="Customer"
                                        @change="onSelectCustomer()"
                                        >

                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        :readonly="isReadOnly"
                                        outlined
                                        dense
                                        :items="packing_list_num_selection" v-model="packing_list.packing_list_history_items"
                                        item-value="value" item-text="text" label="Delivery Receipt #"
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="onSelectPL()"
                                        :disabled="!packing_list.customer_id">
                                        <template #append>
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                        :close-on-content-click="false" :nudge-right="40"
                                        v-model="date_picker.menu" transition="scale-transition"
                                        :readonly="isReadOnly"
                                        min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined dense class=""
                                                label="New Delivery Receipt Date" type="text"
                                                placeholder="Select Year-Month"
                                                v-model="packing_list.new_packing_list_date" readonly
                                                v-on="on" v-bind="attrs">
                                                <template #append>
                                                    <span style="color:red">*</span>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                            :readonly="isReadOnly"
                                            v-model="packing_list.new_packing_list_date"
                                            @change="formatDate('soa_month_year',packing_list.new_packing_list_date)"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        :readonly="isReadOnly"
                                        v-model="packing_list.reason"
                                        outlined
                                        dense
                                        rows="2"
                                        label="Reason"
                                    >
                                        <template #append>
                                            <span style="color:red">*</span>
                                        </template>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" >
                            <v-divider></v-divider>
                            <v-row class="mt-2 mx-auto">
                                <v-col>
                                    <h5 class="my-0 pa-0 px-2">
                                        Delivery Receipt Details
                                    </h5>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5 mx-auto">
                                <v-col>
                                    <v-card class="rounded-lg">
                                        <v-card-text>
                                            <v-data-table
                                            :items="selected_pl"
                                            :headers="headers"
                                            dense>
                                            <template v-slot:[`item.action`]="{ item }">
                                                <span class="text-nowrap">
                                                    <v-btn text icon color="orange">
                                                        <v-icon class="btn-action" small
                                                            @click="viewPL(item.value)">mdi-eye</v-icon>
                                                    </v-btn>
                                                </span>
                                            </template>
                                            <template v-slot:[`item.total_amount`]="{ item }">
                                                <span class="text-nowrap">
                                                    &#x20b1;{{ item.total_amount | currency }}
                                                </span>
                                            </template>
                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="border-top">
                    <div class="card-body py-4 d-flex justify-content-center">
                        <v-btn @click="addPackingListHistory()" v-if="action=='add'">Submit</v-btn>
                        <v-btn @click="updatePackingListHistory()" v-else-if="action == 'edit' && isApprove == null">Update</v-btn>
                        <v-btn @click="approvePackingListHistory()" v-else-if="action == 'view' && isApprove == null && userAccess.approve">Approve DR</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PackingListHistoryTableComponentVue :status="status" @showAddEditDialog="showItems" ref="plHistoryTable"></PackingListHistoryTableComponentVue>
        <PackingListsViewComponent
        :viewPackingListId="viewPackingListId"
        @closeView="closeView"
        :is_preparation="false"
        :is_stockcard="'0'"
        ></PackingListsViewComponent>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import PackingListHistoryTableComponentVue from './PackingListHistoryTableComponent.vue'
import moment from 'moment'
import swal from 'sweetalert2'
import MemoViewComponentVue from '../Base/MemoViewComponent.vue';
import PackingListsViewComponent  from '../../Sales/PackingList/PackingListsViewComponent.vue';
export default {
    props:['status'],
    data(){
        return{
            addEditDialog:false,
            customer:{
                value:0,
            },
            action:'',
            date_picker:{
                menu:false,
            },
            packing_list:{
                new_packing_list_date:null,
                customer_id:0,
                packing_list_history_items:[],
                reason:'',
            },
            selected_pl:[],
            isReadOnly:false,
            isApprove:null,
            userAccess:{
                create:false,
                approve:false
            },
            viewMemoDetails:[],
            memoType:'',
            viewMemoItems:[],
            packing_list_num_selection:[],
            viewPackingListId:0,
            headers: [
                { text: "DR#", value: "text", align: "left" },
                { text: "Total Amount", value: "total_amount", align: "left" },
                { text: "ACTION", align: "center", value: "action" },
            ],
            today:moment().format('YYYY-MM-DD')
        }
    },
    mounted(){

    },
    components:{
        PackingListHistoryTableComponentVue,
        MemoViewComponentVue,
        PackingListsViewComponent
    },
    methods:{
        async showAddEditDialog(item){
            this.addEditDialog = true
            this.action = 'add'
            this.isReadOnly = false
            this.selected_pl = []
            if(!!item){
                this.packing_list = item;
                this.packing_list.packing_list_history_items = item.packing_list_history_items.map(e=>e.packing_list_id)
                await this.onSelectCustomer();
                await this.onSelectPL()
            }
            else{
                this.clearFields();
            }

        },
        closeDialog(){
            this.addEditDialog = false
            this.clearFields();
            this.$refs.plHistoryTable.getAll();
        },
        formatDate(date_field, date_string) {
            if (date_field == "soa_month_year") {
                this.date_picker.soa_month_year =
                    moment(date_string).format("YYYY-MM-dd");
                this.date_picker.menu = false;
            }
        },
        async onSelectCustomer(){
            this.packing_list_num_selection = []
            if(!!this.packing_list.customer_id){
                await this.$store.dispatch('getAllPackingListNumSelection',{url:'getAllPackingListNumSelection',customer_id:this.packing_list.customer_id}).then(response=>{
                    this.packing_list_num_selection = response.data
                })
            }

        },
        async onSelectPL(){
            this.selected_pl = []
            await this.GET_PACKING_LIST_NUM_SELECTION.forEach(e=>{
                this.packing_list.packing_list_history_items.forEach(x=>{
                    if(e.value == x){
                        this.selected_pl.push(e)
                    }
                })
            })
        },
        addPackingListHistory(){
           this.savePackingListHistory()
        },
        savePackingListHistory(){
            this.$store.dispatch('postPackingListHistory',{
                packing_list:this.packing_list,
                url:'save-packing-list-history',
            }).then(response=>{
                swal.fire('',response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.closeDialog();
                    this.$refs.plHistoryTable.getAll();
                }
            })
        },
        clearFields(){
            this.packing_list = {
                new_packing_list_date:null,
                customer_id:0,
                reason:'',
            }
            this.selected_pl = []
        },
        showItems(item,action){
            this.isApprove = item.approved_by
            this.showAddEditDialog(item);
            this.action = action
            if(this.action == 'view'){
                this.isReadOnly = true
            }

        },
        approvePackingListHistory(){
            this.$store.dispatch('postPackingListHistory',{
                packing_list:this.packing_list,
                url:'approve-packing-list-history',
            }).then(response=>{
                swal.fire('',response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.closeDialog();
                    this.$refs.plHistoryTable.getAll();
                }
            })
        },
        updatePackingListHistory(){
            this.$store.dispatch('postPackingListHistory',{
                packing_list:this.packing_list,
                url:'update-packing-list-history',
            }).then(response=>{
                swal.fire('',response.data.msg,response.data.icon)
                if(response.data.icon == 'success'){
                    this.closeDialog();
                    this.$refs.plHistoryTable.getAll();
                }
            })
        },
        checkAccess() {
            let val = this.USER_ACCESS;
            if (val != "fail") {
                val.forEach((e) => {
                    if (e.actions_code == "create") {
                        this.userAccess.create = true;
                    }
                    if (e.actions_code == "approve") {
                        this.userAccess.approve = true;
                    }
                });
            }
        },
        viewPL(id){
            this.viewPackingListId = id
        },
        closeView(action = false){
            this.viewPackingListId = 0
        },
    },
    computed:{
        ...mapGetters([
            'GET_CUSTOMER_WITH_PACKING_LIST_SELECTION',
            'GET_PACKING_LIST_NUM_SELECTION',
            'USER_ACCESS'
        ])
    },
    watch:{
        USER_ACCESS: function () {
            this.checkAccess();
        },
    }

}
</script>

<style>

</style>
