<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row class="pt-2">
                <v-col cols="12" class="d-flex no-block align-items-center">
                    <div class="btn-toolbar" v-if="userAccess.create">
                        <v-btn class="ml-1 mb-2" @click="showAddDialog()">
                            <v-icon>mdi-plus</v-icon>Add
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-row class="m-2">
                                <v-spacer></v-spacer>
                                <v-col sm="12" md="4" class="mr-3 align-items-end">
                                    <v-text-field
                                        id="searchBar"
                                        v-model="search"
                                        label="Search Currency / Name"
                                        single-line
                                        hide-details
                                        append-icon="mdi-magnify"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    :search="search"
                                    :items-per-page="10"
                                    :loading="loading"
                                    :disable-sort="true"
                                >
                                    <template v-slot:[`item.default_local`]="{ item }">
                                        <span class="badge white--text bg-primary" v-if="item.default_local">Yes</span>
                                        <span class="badge white--text bg-danger" v-else>No</span>
                                    </template>
                                    <template v-slot:[`item.default_foreign`]="{ item }">
                                        <span class="badge white--text bg-primary" v-if="item.default_foreign">Yes</span>
                                        <span class="badge white--text bg-danger" v-else>No</span>
                                    </template>
                                    <template v-slot:[`item.currency_updated_at`]="{ item }">
                                        <span class="text-nowrap">
                                            {{ item.currency_updated_at | formatDate }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn text icon color="blue">
                                            <v-icon class="btn-action" small @click="getCurrencyDetails(item.id)" v-if="userAccess.edit">mdi-square-edit-outline</v-icon>
                                        </v-btn>
                                        <v-btn text icon color="red">
                                            <v-icon class="btn-action" small @click="deleteCurrency(item.id)" v-if="userAccess.delete">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-container>
                    </v-card>
                </v-col>

                <v-dialog v-model="addCurrencyDialog" persistent max-width="25%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">{{ action }} Currency</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="closeAddDialog()">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="mt-0" />

                    <v-card-text>
                        <v-row class="mt-2">
                            <v-col sm="12" md="12">
                                <div>
                                    <v-text-field
                                    v-model="currency"
                                    dense
                                    label="Currency *"
                                    outlined />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12">
                                <div>
                                    <v-text-field
                                    v-model="currency_name"
                                    dense
                                    label="Currency Name: *"
                                    outlined />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="">
                            <v-col sm="12" md="12">
                                <div>
                                    <v-text-field
                                    @keypress="decimalOnly"
                                    v-model="exchange_rate"
                                    dense
                                    outlined
                                    label="Exchange Rate: *"/>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="6">
                                <v-switch
                                    v-model="default_local"
                                    label="Default Local"
                                    color="primary"
                                    input-value="1"
                                ></v-switch>
                            </v-col>
                            <v-col sm="12" md="6">
                                <v-switch
                                    v-model="default_foreign"
                                    label="Default Foreign"
                                    color="primary"
                                    input-value="1"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />

                    <div style="text-align:center" class="pb-4">
                        <v-btn v-if="action=='Add'" :loading="loading" :disabled="loading" @click="saveCurrency()">Save</v-btn>
                        <v-btn v-if="action=='Edit'" :loading="loading" :disabled="loading" @click="saveCurrency()">Update</v-btn>
                    </div>
                </v-card>
                </v-dialog>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { required } from 'vuelidate/lib/validators';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
    mixins: [
        SharedFunctionsComponentVue
    ],
    props: [
        'refreshData'
    ],
    data() {
        return {
            tab: null,
            loading: false,
            search: '',
            items: [],
            default_local:0,
            default_foreign:0,

            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Currency', value: 'code' },
                { text: 'Exchange Rate', value: 'exchange_rate' },
                { text: 'Default Local', value: 'default_local' },
                { text: 'Default Foreign', value: 'default_foreign' },
                { text: 'Updated At', value: 'currency_updated_at' },
                { text: 'Updated By', value: 'currency_updated_by'},
                { text: 'Actions', value: 'action' },
            ],

            addCurrencyDialog: false,
            currency:'',
            currency_name:'',
            exchange_rate:'',
            action:'',
            currency_id:'',
            userAccess: {
                    create  : false,
                    edit    : false,
                    view    : false,
                    delete  : false,
                },
        }
    },
    mounted() {
        this.getAllCurrency()
        this.checkAccess()
    },
    computed:{
        ...mapGetters([
            'GET_SHOW_CURRENCY',
            'GET_CURRENCY_DETAILS',
            'USER_ACCESS'
        ])
    },
    validations: {
        currency: {
            required
        },
        currency_name: {
            required
        },
        exchange_rate: {
            required
        },
    },
    methods:{
        checkAccess(){
            let val = this.USER_ACCESS;
            if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                });
            }
        },
        getAllCurrency(){
            this.loading = true
            this.$store.dispatch('showCurrency').then(response => {
                    this.items = this.GET_SHOW_CURRENCY
                    this.loading = false
            })
        },
        showAddDialog(){
            this.action = 'Add'
            this.addCurrencyDialog = true
        },
        closeAddDialog(){
            this.addCurrencyDialog = false
            this.action = ''
            this.currency = ''
            this.currency_name = ''
            this.exchange_rate = ''
            this.default_local = 0
            this.default_foreign = 0
        },
        getCurrencyDetails(id){
            let payload = {
                currency_id : id
            }
            this.$store.dispatch('getCurrencyDetails',payload).then(response => {
                    this.currency           = this.GET_CURRENCY_DETAILS.name
                    this.currency_name      = this.GET_CURRENCY_DETAILS.code
                    this.exchange_rate      = this.GET_CURRENCY_DETAILS.exchange_rate  != null ? this.GET_CURRENCY_DETAILS.exchange_rate : '0.00'
                    this.default_local      = this.GET_CURRENCY_DETAILS.default_local
                    this.default_foreign    = this.GET_CURRENCY_DETAILS.default_foreign
                    this.currency_id        = id
                    this.action = 'Edit'
                    this.addCurrencyDialog  = true;
            })
        },
        saveCurrency() {
            this.loading = true

            this.$v.$touch();

            let titleMsg   = this.action == 'Add' ? 'Are you sure you want to Save Currency?' : 'Are you sure you want to Update Currency?'
            let currencyID = this.action == 'Add' ? this.currency_id = -1 : this.currency_id

            if(!this.$v.$error) {
                swal.fire({
                    title: titleMsg,
                    text: 'Do you want to update the costing of all items as well?',
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton  :  true,
                    reverseButtons: true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                }).then(async result => {
                    let updateItemCosting = false
                    if (result.isConfirmed) {
                        updateItemCosting = true
                    }

                    let payload = {
                        currency_id      :  currencyID,
                        currency         :  this.currency,
                        currency_name    :  this.currency_name,
                        exchange_rate    :  this.exchange_rate,
                        default_local    :  this.default_local,
                        default_foreign  :  this.default_foreign,
                        update_item_costing: updateItemCosting,
                    }

                    await this.$store.dispatch('addCurrency',payload).then(response=>{
                        if(!!response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.closeAddDialog();
                            this.getAllCurrency();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                });
            } else {
                swal.fire('','Please Fill Required Fields','warning');
            }

            this.loading = false
        },
        deleteCurrency(item) {
            swal.fire({
                title: 'Are you sure you want to Delete Currency?',
                icon: 'warning',
                showConfirmButton:true,
                showCancelButton  :  true,
                reverseButtons:true,
                allowOutsideClick :  false,
                confirmButtonColor: '#397373',
                cancelButtonColor : 'grey',
                confirmButtonText:'Confirm',
                showCloseButton:true,
            }).then((result)=>{
                if(result.isConfirmed) {
                    let payload = {
                        currency_id: item
                    }
                    this.$store.dispatch('deleteCurrency',payload).then(response=>{
                        if(!!response.data.error) {
                            swal.fire("", response.data.error, "error");
                        } else {
                            this.getAllCurrency();
                            swal.fire("", response.data.msg, "success");
                        }
                    });
                }
            });
        }
    },
    watch:{
        USER_ACCESS:{
            handler(val){

                this.userAccess.create = false;
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                if(val != "fail"){
                val.forEach((e) => {
                    if (e.actions_code == "create"){
                        this.userAccess.create = true;
                    }
                    else if (e.actions_code == "edit"){
                        this.userAccess.edit = true;
                    }
                    else if (e.actions_code == "view"){
                        this.userAccess.view = true;
                    }
                    else if (e.actions_code == "delete"){
                        this.userAccess.delete = true;
                    }
                 });
                }
            },
        },
        'refreshData': {
            handler(val) {
                if (val) {
                    this.getAllCurrency()
                }
            }
        }
    }
};
</script>
