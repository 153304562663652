<template>
    <div class="w-100 p-3">
      <v-app id="load_bar" v-if="!loaded">
        <v-text-field color="info" loading disabled></v-text-field>
      </v-app>

      <v-app id="my_vue_app">
        <v-card v-show="loaded" elevation="4">
          <v-card-title>
            <v-row dense no-gutters tag="div" style="align-items: right">
              <v-col cols="2">
                  <v-autocomplete
                      :items="GET_WAREHOUSE_LIST"
                      item-value="value"
                      v-model="warehouse_id"
                      label="Warehouse"
                      placeholder="Select Warehouse"
                      dense
                      outlined
                      hide-details
                      auto-select-first
                      color="#b3d4fc"
                      class="w-100"
                      clearable
                      @change="getAllContainers()"
                  ></v-autocomplete>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="2">
                <v-text-field
                  dense
                  v-model="search"
                  label="Search Container# / Broker"
                  single-line
                  hide-details
                  @keydown.enter="getAllContainers()"
                >
                </v-text-field>
              </v-col>
              <v-col md="2">
                <v-text-field
                  dense
                  v-model="searchItem"
                  label="Search Item Name/Model"
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  @click:append="getAllContainers()"
                  @keydown.enter="getAllContainers()"
                >
                </v-text-field>
              </v-col>

            </v-row>
          </v-card-title>

          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="containers"
              :search="search"
              :options.sync="options"
              :server-items-length="totalContainers"
              :items-per-page="10"
              class="w-100"
            >
              <template v-slot:[`item.created_at`]="{ item }">
                <span class="text-nowrap">
                  {{ item.created_at | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.confirm_date`]="{ item }">
                <span class="text-nowrap">
                  {{ item.confirm_date | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.received_date`]="{ item }">
                <span class="text-nowrap">
                  {{ item.received_date | formatDate }}
                </span>
              </template>

              <template v-slot:[`item.status_text`]="{ item }">
                <span
                  class="badge bg-warning"
                  v-if="item.status_text == 'pending'"
                  >{{ item.status_text }}</span
                >
                <span
                  class="badge bg-primary"
                  v-else-if="item.status_text == 'approved'"
                  >{{ item.status_text }}</span
                >
                <span
                  class="badge bg-success"
                  v-else-if="item.status_text == 'completed'"
                  >{{ item.status_text }}</span
                >
                <span
                  class="badge bg-danger"
                  v-else-if="item.status_text == 'rejected'"
                  >{{ item.status_text }}</span
                >
                <span
                  class="badge bg-danger"
                  v-else-if="item.status_text == 'cancelled'"
                  >{{ item.status_text }}</span
                >
                <span
                  class="badge bg-dark"
                  v-else-if="item.status_text == 'deleted'"
                  >{{ item.status_text }}</span
                >
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-btn text icon color="orange" v-if="ACCESS_INBOUND.includes('view_container')">
                  <v-icon class="btn-action" small @click="viewContainer(item.id)"
                    >mdi-eye</v-icon
                  >
                </v-btn>
                <v-btn
                  text
                  icon
                  color="blue"
                  v-if="ACCESS_INBOUND.includes('edit_container') && item.status == 0 && !is_receiving"
                >
                  <v-icon class="btn-action" small @click="editContainer(item.id)"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn
                  text
                  icon
                  color="blue"
                  v-if="
                    ACCESS_INBOUND.includes('receive_container') &&
                    item.status == 0 &&
                    is_receiving &&
                    item.received_date === null
                  "
                >
                  <v-icon
                    class="btn-action"
                    small
                    @click="editContainer(item.id, true)"
                    >mdi-clipboard-check</v-icon
                  >
                </v-btn>
                <v-btn
                  text
                  icon
                  color="red"
                  v-if="ACCESS_INBOUND.includes('delete_container') && item.status == 0 && !is_receiving"
                >
                  <v-icon
                    class="btn-action"
                    small
                    @click="deleteContainer(item.id)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-app>
      <ContainersViewComponent
        :viewContainerId="viewContainerId"
        :searchText="searchItem"
        :is_receiving="is_receiving"
        @closeView="closeView"
        @changeContainerStatus="changeContainerStatus"
        :user_access="user_access"
      >
      </ContainersViewComponent>
    </div>
  </template>

  <script>
  import ContainersViewComponent from "../../../../layouts/dialogs/Operations/Containers/ContainersViewComponent.vue";
  import swal from "sweetalert2";
  import { mapGetters } from "vuex";
  export default {
    components: {
      ContainersViewComponent,
    },
    props: [
      "containersActionResult",
      "is_pending",
      "is_receiving",
      "is_approved",
      "radioGroup",
      "user_access",
    ],
    async mounted() {
      this.getSearchFromUrl();
      this.checkAccess();
      this.getAllContainers();
    },
    computed: {
      ...mapGetters(["USER_ACCESS","SELECTED_FILTER","ACCESS_INBOUND",'GET_WAREHOUSE_LIST']),
    },
    data() {
      return {
        actionResult: {
          model: "Containers",
          action: "",
        },
        loaded: false,
        totalContainers: 0,
        options: {},
        containers: [],
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPage: 10,
        search: "",
        searchItem: null,
        awaitingSearch: false,
        radioValue: "",
        headers: [
          // { text: "Doc Num", align: "left", value: "doc_num" },
          { text: "Container Num", align: "left", value: "container_num" },
          { text: "Warehouse", align: "left", value: "warehouse_name" },
          { text: "Broker", align: "left", value: "broker" },
          { text: "Date Created", align: "left", value: "created_at" },
          { text: "Created By", align: "left", value: "created_by_name" },
          { text: "Received By", align: "left", value: "received_by_name" },
          { text: "Receive Date", align: "left", value: "received_date" },
          { text: "Confirm Date", align: "left", value: "confirm_date" },
          { text: "Status", align: "left", value: "status_text" },
          { text: "Actions", align: "center", value: "action", sortable: false },
        ],

        viewContainerId: "",

        userAccess: {
          view: false,
          edit: false,
          delete: false,
          receive: false,
        },
        warehouse_id:'',
      };
    },
    methods: {
      getAllContainers(radio_value = '') {
        this.loaded = false;
        const payload = {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          search: this.search,
          searchItem: this.searchItem,
          is_pending: this.is_pending,
          is_receiving: this.is_receiving,
          is_approved: this.is_approved,
          radio_value: this.radioValue,
          warehouse_id: this.warehouse_id,
        };
        this.$store.dispatch("getAllContainers", payload).then((response) => {
          this.containers = response.data.data;
          this.loaded = true;
          this.totalContainers = response.data.total;
          this.checkAccess();
        });
      },
      getSearchFromUrl() {
        if (Object.keys(this.$route.query).length != 0) {
          // let item = document.location.search.split("&");
          // let model = item[0].split("=");
          // let replaceModel = model[1].replace("%3D%3D", "");
          // let status = item[1].split("=");
          // console.log(replaceModel);
            this.searchItem = atob(this.$route.query.search_model);
            this.status_id = atob(this.$route.query.status);
            this.searchInput();

        }
      },
      viewContainer(id) {
        this.viewContainerId = id;
      },
      editContainer(id, is_receiving = false) {
        this.$emit("showEditModal", id, is_receiving);
      },
      deleteContainer(id) {
        swal
          .fire({
            title: "",
            text: "Are you sure you want to Delete?",
            icon: "warning",
            showConfirmButton: true,
            confirmButtonText: "Confirm",
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonColor: "397373",
            cancelButtonColor: "grey",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch("deleteContainers", id)
                .then((response) => {
                  swal.fire("", "Container Deleted Successfully", "success");
                  this.getAllContainers();
                })
                .catch((error) => {
                  swal.fire("An Error Occured", error, "error");
                });
            }
          });
      },
      closeView() {
        this.viewContainerId = 0;
      },
      changeContainerStatus() {
        this.viewContainerId = 0;
        this.getAllContainers();
      },
      searchInput() {
        this.getAllContainers({ query: this.search });
      },
      checkAccess() {
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
      },
    },
    watch: {
      radioGroup: function (val) {
        this.radioValue = val;
        this.getAllContainers();
      },
      containersActionResult: function (val) {
        if (this.containersActionResult == "success") {
          this.getAllContainers();
        }
      },
      options: {
        handler() {
          this.getAllContainers();
        },
        deep: true,
      },
      USER_ACCESS:{
        handler(val){
              val.forEach((e) => {
                  if (e.actions_code == "view"){
                      this.userAccess.view = true;
                  }
                  if (e.actions_code == "edit"){
                      this.userAccess.edit = true;
                  }
                  if (e.actions_code == "receive"){
                      this.userAccess.receive = true;
                  }
                  if (e.actions_code == "delete"){
                      this.userAccess.delete = true;
                  }
              });
        }
      },
      'SELECTED_FILTER':{
        handler(val){
           this.getAllContainers(val)
        }
      }
    },
  };
  </script>
