<template>
    <div class="w-100 p-1">
        <v-app id="my_vue_app">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col sm="12" md="2">
                            <!-- </v-col> -->
                            <!-- <v-col cols="9" class="text-right">
                                <a small :class="`badge badge-all mx-1 ${filter.badge == null ? `selected` : `non-selected`}`" color="black" outlined @click="getStocksInquiry();">all</a>
                                <a small :class="`badge badge-has_stock mx-1 ${filter.badge == 'has_stock' ? `selected` : `non-selected`}`" color="primary" @click="getStocksInquiry('has_stock');">has stock</a>
                                <a small :class="`badge badge-low_level_stock mx-1 ${filter.badge == 'low_level_stock' ? `selected` : `non-selected`}`" color="yellow" @click="getStocksInquiry('low_level_stock');">low level stock</a>
                                <a small :class="`badge badge-out_of_stock mx-1 ${filter.badge == 'out_of_stock' ? `selected` : `non-selected`}`" color="red" @click="getStocksInquiry('out_of_stock');">out of stock</a>
                            </v-col> -->
                            <!-- <v-col sm="12" md="3"> -->
                                <v-autocomplete
                                    v-model="filters.warehouse_ids"
                                    :items="dropdowns.warehouses.lists"
                                    class="d-inline-block w-100 small"
                                    label="Warehouse"
                                    background-color="white"
                                    clearable
                                    small-chips
                                    deletable-chips
                                    outlined
                                    dense
                                    multiple
                                    auto-select-first
                                    hide-details
                                ></v-autocomplete>
                            </v-col>
                            <v-col sm="12" md="2">
                                <v-autocomplete
                                    v-model="filters.type_ids"
                                    :items="dropdowns.types.lists"
                                    class="d-inline-block w-100 small"
                                    label="Type"
                                    background-color="white"
                                    clearable
                                    small-chips
                                    deletable-chips
                                    outlined
                                    dense
                                    multiple
                                    auto-select-first
                                    hide-details
                                ></v-autocomplete>
                            </v-col>
                            <v-col sm="12" md="2">
                                <v-autocomplete
                                    v-model="filters.category_ids"
                                    :items="dropdowns.categories.lists"
                                    class="d-inline-block small"
                                    label="Category"
                                    background-color="white"
                                    clearable
                                    small-chips
                                    deletable-chips
                                    outlined
                                    dense
                                    multiple
                                    auto-select-first
                                    hide-details
                                ></v-autocomplete>
                            </v-col>
                            <v-col sm="12" md="2" class="d-flex justify-start">
                                <v-autocomplete
                                    v-model="filters.active"
                                    :items="activeState.dropdown"
                                    class="d-inline-block small"
                                    item-text="name"
                                    item-value="value"
                                    label="Active state"
                                    background-color="white"
                                    outlined
                                    dense
                                    hide-details
                                ></v-autocomplete>
                            </v-col>
                            <v-col sm="12" md="1"><v-btn @click="getStocksInquiry()">Filter</v-btn></v-col>
                            <v-col sm="12" md="3" class="d-flex justify-end">
                                <v-btn @click="openBatchDialog()" class="mr-2">Batch</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <div class="row col-sm-12 col-md-4 mr-3 align-items-end">
                                <v-text-field
                                    id="searchBar"
                                    v-model="filter.search"
                                    label="Search Model/Name"
                                    single-line
                                    hide-details
                                    @change="searchInput()"
                                >
                                </v-text-field>
                                <v-icon @click="searchInput()">mdi-magnify</v-icon>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-container fluid>
                    <v-data-table
                        :headers="datatable.headers"
                        :items="datatable.items"
                        :search="search"
                        :options.sync="datatable.options"
                        :server-items-length="datatable.totalItems"
                        :items-per-page="10"
                        :loading="loading.datatable"
                        :footer-props="{
                            itemsPerPageOptions: datatable.itemsPerPageOptions,
                            showFirstLastPage: true,
                        }"
                        loading-text="Loading... Please wait"
                        fixed-header
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text icon color="orange" v-if="userAccess.view">
                                <v-icon
                                    class="btn-action"
                                    small
                                    @click="viewItem(item.id)"
                                    >mdi-eye</v-icon
                                >
                            </v-btn>
                            <!-- <v-btn text icon color="red" v-if="userAccess.delete">
                            <v-icon
                                class="btn-action"
                                small
                                @click="deletePlantilla(item)"
                                >mdi-delete</v-icon
                            >
                            </v-btn> -->
                        </template>

                        <template v-slot:[`item.srp`]="{item}">
                            <span>{{item.srp | currency }}</span>
                        </template>

                        <template v-slot:[`item.image_path`]="{ item }">
                            <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
                        </template>

                        <template v-slot:[`item.stocks`]="{ item }">
                            <span class="badge" :style="`background-color:${stockColor(item.total_stocks)}; color:${itemStocks(item.total_stocks) == 'has stock' ? 'white': 'black'}`">{{itemStocks(item.total_stocks)}}</span>
                        </template>

                        <template v-slot:[`item.total_actual_stocks`]="{ item }">
                            <span>{{ thousandSeprator(item.total_actual_stocks) }}</span>
                        </template>

                        <template v-slot:[`item.active`]="{ item }">
                            <span :class="'badge badge-'+activeState.display[item.active].color"> <b>{{activeState.display[item.active].text}}</b></span>
                        </template>

                    </v-data-table>
                </v-container>
            </v-card>
             <!-- Batch Addition -->
            <v-dialog
                v-model="batch_addition.dialog"
                persistent
                max-width="30%"
                max-height="30%"
                scrollable
            >
                <v-card>

                    <v-row class="m-0">
                        <v-col>
                            <v-card-title>
                                <span class="headline">Batch Addition</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                                text
                                icon
                                color="gray"
                                class="float-right"
                                @click="
                                closeBatchAdditionDialog();
                                model = '';
                                "
                            >
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="ma-0">
                        <span v-if="hasError">
                            <span style="color: red">Red : </span>Model not
                            found in the system</span
                            >
                        <div
                        class="pt-4 pa-2"
                        style="
                            height: auto;
                            width: 100%;
                            overflow-y: hidden;
                            overflow-x: hidden;
                        "
                        >
                        <v-row>
                            <v-col cols="3" class="pa-0 ml-3 mb-5">
                                <v-menu
                                    bottom
                                    :close-on-content-click="false"
                                    max-width="400"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        >
                                            <v-icon>
                                                mdi-filter
                                            </v-icon>
                                            <v-badge
                                                :content="batchWarehouseIdsCount"
                                                :value="batchWarehouseIdsCount == 0 ? false : true"
                                                color="error"
                                                offset-x="0"
                                                offset-y="0"
                                                overlap

                                            ></v-badge>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item style="max-width: 400px; overflow:auto;">
                                            <v-list-item-action>
                                                <v-autocomplete
                                                v-model="filters.batch_warehouse_ids"
                                                :items="dropdowns.warehouses.lists"
                                                class="d-inline-block w-100 small"
                                                label="Warehouse"
                                                background-color="white"
                                                clearable
                                                small-chips
                                                deletable-chips
                                                outlined
                                                dense
                                                multiple
                                                auto-select-first
                                                hide-details
                                                ></v-autocomplete>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            </v-row>
                            <v-row
                                class="mx-0 pa-0 ma-0 overflow-auto"
                                v-for="(
                                text_area_field, i
                                ) in text_area_fields"
                                :key="i"
                            >
                                <v-col cols="9" class="pa-0 ma-0">
                                    <v-text-field
                                        v-model="text_area_field.model"
                                        @paste="onPaste"
                                        @click="$event.target.focus()"
                                        :class="{notInModel: text_area_fields[i].error}"
                                        :autofocus="batch_addition.dialog"
                                        color="primary"
                                        label="Model"
                                        rows="1"
                                        dense
                                        outlined
                                        small
                                    >
                                    </v-text-field>
                                </v-col>
                                <!-- <v-col cols="6" class="py-0 ma-0 pl-2">
                                    <v-text-field
                                        v-model="text_area_field.quantity"
                                        :class="{
                                            notInModel: text_area_field.error,
                                        }"
                                        label="Quantity"
                                        color="primary"
                                        rows="1"
                                        dense
                                        outlined
                                        small
                                    >
                                    </v-text-field>
                                </v-col> -->
                                <v-col class="ma-0">
                                    <v-btn
                                        @click="clearItem(i)"
                                        class="ma-2"
                                        fab
                                        dark
                                        small
                                        color="red"
                                        style="height: 20px; width: 20px"
                                    >
                                        <v-icon
                                        dark
                                        small
                                        style="font-size: 12px"
                                        >
                                        mdi-minus
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3">
                                    <v-btn
                                        @click="manualAddItem()"
                                        dark
                                        small
                                        color="primary"
                                        style="height: 20; width: 40px"
                                    >
                                        <span> Add </span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <div class="col-12">
                                <v-btn
                                @click="submittedBtn()"
                                :disabled="isDisabledButton"
                                :loading="batch_addition.loading"
                                class="float-right ml-2"
                                >
                                    <template v-slot:loader>
                                        <span class="custom-loader">
                                        <v-icon light color="white">mdi-cached</v-icon>
                                        </span>
                                    </template>
                                    Submit
                                </v-btn>
                                <v-btn
                                @click="clearFields()"
                                class="float-right"
                                dark
                                color="secondary"
                                >
                                Clear
                                </v-btn>
                            </div>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- VIEW MODAL-->
            <ItemsViewComponent
                :viewItemId="datatable.selected"
                @closeView="closeView"
            ></ItemsViewComponent>
        </v-app>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import moment from 'moment';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue"

const ExcelJS = require('exceljs')
const FileSaver = require('file-saver')

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    data(){
        return {
            loaded: false,
            totalItems: 0,
            options: {},
            items: [],
            itemsPerPageOptions: [5, 10, 15, 50, 100],
            itemsPerPage: 10,
            search: "",
            filter:{
                search:"",
                badge: 'all'
            },

            datatable:{
                headers:
                    [
                        { text: 'Image', value: 'image_path' , align: 'start', sortable: false},
                        { text: 'Model', value: 'model', align: 'start', sortable: false},
                        { text: 'Name', value: 'name', align: 'start', sortable: false},
                        { text: 'Type', value: 'item_type_text',  align: 'start', sortable: false},
                        { text: 'Category', value: 'item_category_text',  align: 'start', sortable: false},
                        { text: 'UOM', value: 'item_uom_text', align: 'start', sortable: false},
                        { text: 'SRP', value: 'srp', align: 'end', sortable: false},
                        { text: 'Active', value: 'active', align: 'center', sortable: false},
                        { text: 'Actual Stocks', value: 'total_actual_stocks', align: 'end', sortable: false},
                        { text: "Action", value:'action', align: "center", sortable: false},
                    ],
                items:[],
                options:{},
                totalItems: 0,
                itemsPerPageOptions: [5, 10, 15, 50, 100],
                itemsPerPage: 10,
                selected: ''
            },

            loading:{
                datatable:false
            },

            userAccess: {
                view: false,
                create: false,
                edit: false,
                delete: false,
                approve_reject_manpower_request:false
            },
            selected_status:'',
            isDepartmentView: 0,
            batch_addition: {
                dialog: false,
                data: [],
                excel_item_id: null,
                errors: [],
                loading:false
            },
            isDisabledButton: false,
            hasError: false,
            text_area_fields: {
                dataFromExcel: '',
                dataFromExcel2: '',
            },
            dropdown_dispatches:['getAllWarehouses', 'getItemTypes', 'getItemCategories'],
            dropdowns:{
                warehouses:{
                    lists: [],
                    loading: false
                },
                types:{
                    lists: [],
                    loading: false
                },
                categories:{
                    lists: [],
                    loading: false
                }
            },
            filters:{
                warehouse_ids: [],
                type_ids: [],
                category_ids: [],
                batch_warehouse_ids: [],
                active: 1,
            },
            activeState: {
                dropdown: [
                    {name: 'Active', value: 1},
                    {name: 'Inactive', value: 0},
                    {name: 'Both', value: -1},
                ],
                display: [
                    {text: 'No', color: 'danger'},
                    {text: 'Yes', color: 'success'},
                ],
            },
        }
    },
    mounted(){
        this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)
        this.getSearchFromUrl()
        this.getAllDropdowns()
    },
    computed:{
        ...mapGetters([
            'USER_ACCESS',
            'USERACCOUNT_LOAD',
            'GET_STOCKS_INQUIRY_DISPATCH',
            'GET_STOCK_INQUIRY_BATCH',
            'GET_ALL_WAREHOUSES',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
        ]),
        batchWarehouseIdsCount() {
            return this.filters.batch_warehouse_ids.length;
        }
    },
    components:{
        ItemsViewComponent
    },
    methods:{
        itemStocks(stocks){
            let stockText = ''
            if(stocks == 0){
                stockText = 'out of stock'
            }
            else if (stocks >= 6){
                stockText = 'has stock'
            }
            else{
                stockText = 'low level stock'
            }
            return stockText;
        },
        stockColor(stocks){
            let color = ''
            if(stocks == 0){
                color = '#eb4134'
            }
            else if (stocks >= 6){
                color = '#1976d2'
            }
            else{
                color = '#ffeb3b'
            }
            return color;
        },
        getStocksInquiry(filter = null){
            this.filter.badge = filter
            this.datatable.items = [];
            this.loading.datatable = true;
            let payload = {
                page            : this.datatable.options.page,
                itemsPerPage    : this.datatable.options.itemsPerPage,
                search          : this.filter.search,
                sortBy          : this.datatable.options.sortBy,
                sortDesc        : this.datatable.options.sortDesc,
                status          : this.selected_status,
                filter          : filter,
                warehouse_ids   : this.filters.warehouse_ids,
                type_ids        : this.filters.type_ids,
                category_ids    : this.filters.category_ids,
                active          : this.filters.active,
            };
            this.$store.dispatch(this.GET_STOCKS_INQUIRY_DISPATCH.get, payload).then((response) => {
                this.datatable.items                    = response.data;
                this.datatable.totalItems               = response.total
                this.loading.datatable                  = false;
            }).catch((err)=>{
                Swal.fire({
                    title: 'Error',
                    text: 'Network Error',
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.loading.datatable                  = false;
                console.log(err)
            });
        },
        searchInput(){
            this.datatable.options.page = 1
            this.getStocksInquiry({ query: this.search });
        },
        getSearchFromUrl() {
            if (Object.keys(this.$route.query).length != 0) {
                Object.keys(this.$route.query).forEach(e=>{
                if(e != 'for_approval')
                    this.filter.search = atob(this.$route.query[e]);
                })
                this.searchInput();
            }
        },
        closeBatchAdditionDialog() {
            this.batch_addition.dialog = false;
        },
        openBatchDialog() {
            this.batch_addition.dialog = true;
            this.isDone = true;
            this.text_area_fields = [];
            this.$store.commit('STOCK_INQUIRY_BATCH', [])
            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                model: "",
                quantity: "",
                error: false,
                });
            }
            this.batch_addition.data = [];
            this.listOfNotInModels = [];
            this.filters.batch_warehouse_ids = [];
            this.hasError = false;
        },
        checkImagePath(item) {
            return item == null
            ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
            : process.env.VUE_APP_API_HOST + "/" + item;
        },
        onPaste(evt) {
            let missingModelindexes = [];
            let missingModels = [];
            let that = this
            this.batch_addition.data = [];
            this.batch_addition.loading = true
            var pastedText = evt.clipboardData.getData("Text");
            if (!pastedText) {
                return false;
            } else {
                try{
                    var rows = pastedText.split("\r\n");
                    var cells = "";
                    for (var y in rows) {
                        if (y != rows.length - 1) {
                            cells = rows[y].split("\t");
                            this.text_area_fields.push({
                                model: cells[0],
                                // dataFromExcel2: cells[1],
                                error:false
                            })
                            if (y == rows.length - 1){
                                this.text_area_fields.push({
                                    model: cells[0],
                                    // dataFromExcel2: cells[1],
                                    error:false
                                })
                            }

                        }
                    }
                    let payload={
                        items: this.text_area_fields.map(e=>{ return e.model}),
                        isChecking: false,
                        forSI: 1,
                        warehouse_ids: this.filters.batch_warehouse_ids
                    }
                    this.$store.dispatch('getStocksInquiryBatch',payload).then(response => {
                        response.data = this.GET_STOCK_INQUIRY_BATCH.map(e=>{ return e.model})
                        this.text_area_fields.forEach((e, i)=>{
                            if(response.data.includes(e.model.toUpperCase())){
                            }
                            else{
                                this.listOfNotInModels.push(e.model.toUpperCase());
                                this.text_area_fields[i].error = true;
                            }
                        })
                        if(this.listOfNotInModels.length > 0){
                            this.isDisabledButton = true
                            this.hasError = true
                        }
                        this.batch_addition.loading = false
                    }).catch(() => {
                        Swal.fire({
                            title   : 'Request Failed',
                            text    : 'Internal server error!',
                            icon    : 'error'
                        })
                        this.batch_addition.loading = false
                    });
                    this.isDone = false;
                    this.closeBatchAdditionDialog();
                    setTimeout(this.myFunction, 1);
                }
                catch (error) {
                    Swal.fire({
                        title   : 'Warning',
                        text    : 'Please Check Your Copied values!',
                        icon    : 'warning'
                    })
                    this.batch_addition.loading = false
                }
            }
        },
        manualAddItem() {
            this.text_area_fields.push({
                model: "",
                quantity: "",
                error: false,
            });
        },
        myFunction() {
            this.text_area_fields.splice(0, 1);
            this.batch_addition.dialog = true;
        },
        async submittedBtn() {
            this.batch_addition.dialog = false;
            this.batch_addition.loading = false;
            this.generateExcel();
        },
        clearFields() {
        //  Clear Fields in Batch Addition
            this.isDone = true;
            this.text_area_fields = [];
            if (this.text_area_fields.length < 1) {
                this.text_area_fields.push({
                model: "",
                quantity: "",
                error: false,
                });
            }
            this.batch_addition.data = [];
            this.listOfNotInModels = [];
            this.isDisabledButton = false;
            this.hasError = false;
            this.batch_addition.loading = false;
        },
        clearItem(i) {
            let temp = [];
            if(this.text_area_fields.length == 1)
            {
                this.clearFields();
            }
            else{
                if(this.listOfNotInModels.length > 1 ){

                }
                else{
                    this.isDisabledButton = false;
                    this.hasError = false;
                }
                this.text_area_fields.forEach((e, index) => {
                    if (i != index) {
                        temp.push(e);
                        let checkingOfError = this.listOfNotInModels.includes(this.text_area_fields[i].dataFromExcel)
                        if(checkingOfError){
                            this.listOfNotInModels.pop();
                        }
                    }
                })
                this.text_area_fields = temp;
            }
        },
        async generateExcel(){
            let item_base64_arr = [];
            let currentDate = moment().format('YYYY-MM-DD')
            Swal.fire('Generating File Please Wait')
            Swal.showLoading()
            let filter = {};
            filter.active  = -1
            filter.item_ids = this.GET_STOCK_INQUIRY_BATCH.map(e => e.id )

            // Get Base64 of all Items
            let base64_payload = {
                url: 'getItemBase64',
                filters: filter
            }
            await this.$store.dispatch('reports',base64_payload).then(async response =>{
                item_base64_arr = response.data
            }).catch((err)=>{
                Swal.fire(
                    'Network Error',
                    'error'
                )
                console.log(err);
            });
            let data = this.GET_STOCK_INQUIRY_BATCH;
            const workbook = new ExcelJS.Workbook()
            const rawDataSheetName = `Items ${moment().format('YYYY-MM-DD')}`
            let worksheet = workbook.addWorksheet(rawDataSheetName)

            const columns = [
                { header: 'IMAGE', key: 'image', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, },
                { header: 'MODEL', key: 'model', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                { header: 'NAME', key: 'name', width: 50, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                { header: 'DESCRIPTION', key: 'description', width: 50, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } } },
                { header: 'UOM', key: 'item_uom_text', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                { header: 'TYPE', key: 'item_type_text', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                { header: 'CATEGORY', key: 'item_category_text', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                { header: 'MOQ', key: 'moq', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                { header: 'MB QTY', key: 'master_box_qty', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                { header: 'SB QTY', key: 'small_box_qty', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                { header: 'SRP', key: 'srp', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                { header: 'STOCKS', key: 'total_actual_stocks', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                { header: 'NEW MODEL', key: 'new_model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                // { header: 'NEW SRP', key: 'new_srp', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                { header: 'NEW MOQ', key: 'new_moq', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
            ]

            worksheet.columns = columns

            let pos = 1
            for await (const item of data) {
                item.base64img              = item_base64_arr.find((e)=> e.model == item.model).base64img;
                if(item.total_stocks >= 6){
                    item.total_stocks = 'has stocks'
                }
                else if (item.total_stocks == 0){
                    item.total_stocks = 'out of stocks'
                }
                else{
                    item.total_stocks = 'low'
                }
                item.new_model = item.item_sub.model != null ?  item.item_sub.model : ''
                item.new_srp = item.item_sub.selling_price != null ?  item.item_sub.selling_price : ''
                item.new_moq = item.item_sub.moq != null ?  item.item_sub.moq : ''
                item.srp  = parseInt(item.srp)
                worksheet.addRow(item)
                await this.addImageToWorksheet(workbook, worksheet, item, pos);
                pos++
            }

            worksheet.getRow(1).font = { bold: true };
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                const filename = `Stocks Inquiry as of ${currentDate}.xlsx`
                FileSaver.saveAs(blob, filename)
            })
            Swal.close()
        },
        async addImageToWorksheet(workbook, worksheet, item, pos){
            let height = 80;
            let width = 60;
            let aspectRatio = null;

            worksheet.getRow(pos + 1).height = height;

            let image = await new Promise((resolve) => {
                let img = new Image();
                img.src = item.base64img;
                img.onload = () => resolve(img);
            });
            aspectRatio = image.naturalWidth / image.naturalHeight;
            let imageHeight = aspectRatio * width;

            let finalImage = workbook.addImage({
                base64: item.base64img,
                extension: 'jpg',
            });

            worksheet.addImage(finalImage, {
                tl: { col: 0.1, row: pos + 0.1 },
                br: { col: 1, row: worksheet.rowCount },
                ext: { width: width, height: height },
                editAs: 'twoCell',
            });

            // if (item.base64NewItemTag !== null) {
            //     let finalImageNewItem = workbook.addImage({
            //         base64: item.base64NewItemTag,
            //         extension: 'jpg',
            //     });

            //     worksheet.addImage(finalImageNewItem, {
            //         tl: { col: 0, row: pos },
            //         ext: { width: 50, height: 50 },
            //         editAs: 'undefined',
            //     });
            // }

            let row = worksheet.getRow(worksheet.rowCount);
            row.width = width
            if (imageHeight > worksheet.getRow(pos + 1).height) {
                worksheet.getRow(pos + 1).height = imageHeight;
            }
        },
        closeView() {
            this.datatable.selected = 0
        },
        viewItem(item_id) {
            this.datatable.selected = item_id
        },
        getAllDropdowns(){
            this.dropdown_dispatches.forEach((e)=>{
                this.$store.dispatch(e)
            })
        }
    },
    watch:{
        'datatable.options': {
            handler() {
                this.getStocksInquiry();
            },
            deep: true,
        },
        USER_ACCESS: {
            handler(val) {
                if(val != "fail"){
                this.userAccess.edit = false;
                this.userAccess.delete = false;
                this.userAccess.view = false;
                this.userAccess.create = false;
                    val.forEach(e => {
                        if (e.actions_code == 'edit') {
                            this.userAccess.edit = true;
                        } else if (e.actions_code == 'delete') {
                            this.userAccess.delete = true;
                        } else if (e.actions_code == 'view') {
                            this.userAccess.view = true;
                        } else if (e.actions_code == 'create') {
                            this.userAccess.create = true;
                        }
                    });
                }
            }
        },
        GET_ALL_WAREHOUSES:{
            handler(val){
                this.dropdowns.warehouses.lists = val.filter((e)=>{
                    return e.actual == 1;
                });
            }
        },
        GET_ITEM_TYPES:{
            handler(val){
                this.dropdowns.types.lists = val;
            }
        },
        GET_ITEM_CATEGORIES:{
            handler(val){
                this.dropdowns.categories.lists = val;
            }
        }
    }
}
</script>
<style scoped>
.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}

.yellow-background {
    background-color: #f8bb86;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

.centered-input >>> input {
    text-align: center
}

.notInModel{
    background-color: #f55246;
}

.badge-low_level_stock{
    background-color: #ffeb3b !important;
    color: black !important;
}

.badge-has_stock{
    background-color: #1976d2 !important;
    color: white !important;
}

.badge-out_of_stock{
    background-color: #eb4134 !important;
    color: white !important;
}

.selected {
    opacity: 100%;
}

.non-selected {
    opacity: 40%;
}


</style>
