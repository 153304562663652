<template>
    <div class="container">
        <ComponentHeader :button="true" :title="'Customer Sold Unsold Items'" :type="'Sales'"></ComponentHeader>
        <div class="row">
            <div class="container bg-bluish-gray default-container  m-3">
                <v-app id="my_vue_app" class="bg-bluish-gray">
                    <v-row dense>
                        <v-col cols="4" class="mt-2 ml-3">
                            <v-autocomplete
                                v-model="selectedCustomers"
                                :items="GET_CUSTOMERS_SELECTION"
                                :loading="loading.customer_selection"
                                :disabled="loading.customer_selection"
                                multiple
                                deletable-chips
                                small-chips
                                outlined
                                dense
                                background-color="white"
                                label="Select Customers"
                                class="d-inline-block w-100"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" class="mt-2">
                            <v-autocomplete
                                v-model="salesman_id"
                                :items="GET_SALESMAN_SELECTION"
                                :loading="loading.salesman_selection"
                                :disabled="loading.salesman_selection"
                                outlined
                                dense
                                background-color="white"
                                label="Select Salesman"
                                class="d-inline-block w-100 small"
                            ></v-autocomplete>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="3" class="d-flex justify-content-end mt-2 mr-2 ">
                            <v-btn class="mx-2" :loading="!loaded" @click="resetFields()">Clear</v-btn>
                            <v-btn :loading="!loaded" @click="exportCustomerSoldUnsoldItems()">Excel</v-btn>
                            <v-btn class="mx-2" :loading="!loaded" @click="getCustomerSoldUnsoldItems()">Filter</v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="2" class="ml-3">
                            <v-autocomplete
                                v-model="item_type_ids"
                                :items="GET_ITEM_TYPES"
                                :loading="loading.item_type_selection"
                                :disabled="loading.item_type_selection"
                                multiple
                                deletable-chips
                                small-chips
                                outlined
                                dense
                                background-color="white"
                                label="Select Type"
                                class="d-inline-block w-100 small"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete
                                v-model="category_ids"
                                :items="GET_ITEM_CATEGORIES"
                                :loading="loading.item_category_selection"
                                :disabled="loading.item_category_selection"
                                multiple
                                deletable-chips
                                small-chips
                                outlined
                                dense
                                background-color="white"
                                label="Select Category"
                                class="d-inline-block w-100 small"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="2" class="ml-3">
                            <v-autocomplete
                                v-model="selectedOption"
                                :items="filterOption"
                                :loading="!filterOption"
                                :disabled="!filterOption"
                                outlined
                                dense
                                background-color="white"
                                label="Select DR/SO"
                                height="50%"
                                class="d-inline-block w-100 small"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="stockSelectedOption"
                                :items="stockOption"
                                :loading="!stockOption"
                                :disabled="!stockOption"
                                outlined
                                height="40"
                                dense
                                background-color="white"
                                label="Select w/o Stocks"
                                class="d-inline-block w-100 small"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete
                                v-model="sortingSelectedOption"
                                :items="sortingOption"
                                :loading="!sortingOption"
                                :disabled="!sortingOption"
                                outlined
                                height="40"
                                dense
                                background-color="white"
                                label="Sort by"
                                class="d-inline-block w-100 small"
                                @input="triggerByDateRangeFilter()"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <div class="col-sm-12 col-md-2 pa-0 ma-0">
                            <v-checkbox
                                v-model="cust_created_date"
                                :true-value="1"
                                :false-value="0"
                                label="From Customer Creation Date"
                                color="white"
                                hide-details
                                class="mt-0 pt-5"
                            ></v-checkbox>
                        </div>
                        <div class="col-sm-12 col-md-2 pa-0 ma-0">
                            <v-checkbox
                                v-model="notPurchase"
                                :true-value="1"
                                :false-value="0"
                                label="Not Purchase"
                                color="white"
                                hide-details
                            ></v-checkbox>
                        </div>
                        <div class="col-sm-12 col-md-2 pa-0 ma-0">
                            <v-checkbox
                                v-model="byDateRange"
                                :true-value="1"
                                :false-value="0"
                                label="By Date Range"
                                color="white"
                                hide-details
                                class="mt-0 pt-5"
                                @click="ByDateRangeCheckBox(byDateRange)"
                            ></v-checkbox>
                        </div>
                        <div
                            class="col-sm-12 col-md-3 ma-0 py-0 pl-4 pr-0 d-flex align-items-end "
                            id="date_from"
                            v-if="byDateRange"
                        >
                            <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date_from"
                                        v-on="on"
                                        readonly
                                        dense
                                        hide-details="message"
                                        label="Date from: "
                                        append-icon="mdi-calendar-clock"
                                        class="ma-0 py-0 px-2"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_from" @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="col-sm-12 col-md-3 ma-0 pa-0 d-flex align-items-end" id="date_to" v-if="byDateRange">
                            <v-menu
                                v-model="menu3"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date_to"
                                        v-on="on"
                                        readonly
                                        dense
                                        label="Date to: "
                                        hide-details="message"
                                        append-icon="mdi-calendar-clock"
                                        class="ma-0 py-0 px-2"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_to" @input="menu3 = false"></v-date-picker>
                            </v-menu>
                        </div>
                    </v-row>
                    <ReportsCardsComponentVue
                        :setClass="'mx-auto mt-6 mb-2'"
                        :totals="totals"
                        :cards="cards"
                        style="width:100%;"
                    ></ReportsCardsComponentVue>
                    <div class="w-100" v-if="dataItems != ''">
                        <v-card v-show="loaded">
                            <v-container fluid>
                                <v-app id="load_bar" v-if="!loaded">
                                    <v-text-field color="info" loading disabled></v-text-field>
                                </v-app>

                                <div class="container" v-for="(d, i) in dataItems" :key="i" v-show="loaded">
                                    <div class="row">
                                        <div class="col-12 d-flex no-block align-items-cnter">
                                            <h6 class="report-title">{{ d.company_name }} - {{ d.item_count }} Items</h6>
                                        </div>
                                    </div>

                                    <v-app id="my_vue_app">
                                        <v-card>
                                            <v-container fluid>
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="d.data_items"
                                                    :search="search"
                                                    :items-per-page="10"
                                                    class="w-100"
                                                    dense
                                                >
                                                    <template v-slot:[`item.srp`]="{ item }">
                                                        {{ item.srp | currency }}
                                                    </template>
                                                    <template v-slot:[`item.image`]="{ item }">
                                                        <img
                                                            :src="checkImagePath(item.image)"
                                                            alt="image"
                                                            style="width: 50px; height: 50px;"
                                                        >
                                                    </template>
                                                </v-data-table>
                                            </v-container>
                                        </v-card>
                                    </v-app>
                                </div>
                            </v-container>
                        </v-card>
                    </div>
                </v-app>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import { mapGetters } from 'vuex';
import swal from 'sweetalert2'
import ReportsCardsComponentVue from '@/views/main/layouts/Reports/ReportsCardsComponent.vue'
const ExcelJS = require('exceljs')
const FileSaver = require('file-saver');

export default {
    mixins: [SharedFunctionsComponent],
    data() {
        return {
            search: '',
            headers: [
                { text: 'Image', align: 'center', value: 'image' },
                { text: 'Model', align: 'start', sortable: false, value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'description' },
                { text: 'Type', value: 'type' },
                { text: 'Category', value: 'category' },
                { text: 'SRP', value: 'srp' },
                { text: 'MOQ', value: 'moq' },
                { text: 'Stocks', align: 'center', value: 'item_stocks' },
            ],
            errors: [],
            cust_created_date: '',
            customers_list: [],
            customersList: [],
            customer_count: 0,
            salesman_id: '',
            salesman_list: [],
            selectedCustomers: [],
            item_type_list: [],
            item_type_ids: [1, 2],
            category_list: [],
            category_ids: [],
            item_base64_arr: [],
            totals: {
                customer_count: 0,
            },

            notPurchase: 1,
            byDateRange: 0,
            date_range: '',
            purchaseVal: '',
            dataItems: [],

            route_name: '',

            selectedOption: 1,
            filterOption:
                [
                    { value: 1, text: 'Delivery Receipt' },
                    { value: 2, text: 'Sales Order' },
                ],

            stockSelectedOption: '',
            stockOption:
                [
                    { value: 1, text: 'With Stocks' },
                    { value: 2, text: 'Without Stocks' },
                    { value: 3, text: 'Both' },
                ],

            sortingSelectedOption: 1,
            sortingOption:
                [
                    { value: 1, text: 'Item Type' },
                    { value: 2, text: 'Item Category' },
                    { value: 3, text: 'New Items' },
                    { value: 4, text: 'Latest Container' },
                ],

            loaded: true,

            date_from: moment().clone().startOf('month').format('YYYY-MM-DD'),
            date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
            menu2: false,
            menu3: false,

            options: {},
            itemsPerPageOptions: [5, 10, 15, 50, 100, 250],
            itemsPerPage: 10,

            loading: {
                item_category_selection: true,
                item_type_selection: true,
                customer_selection: true,
                salesman_selection: true,
            },

            cards:
                [
                    { title: 'Customer (C)', icon: 'mdi-account-multiple', icon_class: 'total-amount-booked', cols: 6, sm: 6, md: 2, lg: 2, xl: 2, filter: '', value: 'customer_count' },
                ],
        }
    },
    components: {
        ComponentHeader,
        ReportsCardsComponentVue,
    },
    async mounted() {
        await this.$store.dispatch('getAllcustomersSelection')
        await this.$store.dispatch('getAllsalesmanSelection')
        await this.$store.dispatch('getItemTypes')
        await this.$store.dispatch('getItemCategories')
    },
    computed: {
        ...mapGetters([
            'GET_SALESMAN_SELECTION',
            'GET_CUSTOMERS_SELECTION',
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES'
        ])
    },
    methods: {
        ByDateRangeCheckBox() {
            this.byDateRange == false ? this.date_range = 0 : this.date_range = 1;
        },
        async exportCustomerSoldUnsoldItems() {
            swal.fire('Exporting File Please Wait')
            swal.showLoading()
            this.loaded = false
            this.item_base64_arr = [];
            if (this.selectedCustomers === '') {
                swal.fire("", 'Select Customer First', "warning");
                this.loaded = true
                return false
            }

            const filters = {
                item_type_ids: this.item_type_ids,
                item_category_ids: this.category_ids,

            }
            let base64_payload = {
                url: 'getItemBase64',
                filters: filters,
            }
            await this.$store.dispatch('reports', base64_payload).then(async response => {
                this.item_base64_arr = response.data
            }).catch((err) => {
                swal.fire(
                    'Network Error',
                    'error'
                )
                console.log(err);
            });

            const workbook = new ExcelJS.Workbook();
            this.notPurchase == true ? this.purchaseVal = 1 : this.purchaseVal = 0;
            let creationCheck = this.cust_created_date == true ? 1 : '';

            await this.$store.dispatch('reports', {
                customer_id: this.selectedCustomers,
                salesman_id: this.salesman_id,
                type_id: this.item_type_ids,
                category_id: this.category_ids,
                table_filter: this.selectedOption,
                notPurchase: this.purchaseVal,
                date_range: this.date_range,
                date_from: this.date_from,
                date_to: this.date_to,
                cust_creation_date: creationCheck,
                stocks_filter: this.stockSelectedOption,
                url: 'exportCustomerSoldUnsoldItems'
            }).then(async response => {

                for await (let e of response.data.data) {

                    let pos = 1;
                    let worksheet = workbook.addWorksheet(e.company_name);
                    let headers = [
                        { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, },
                        { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'NAME', key: 'name', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'UOM', key: 'uom_name', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'TYPE', key: 'type', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false } } },
                        { header: 'SRP', key: 'srp', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0.00' } },
                        { header: 'MOQ', key: 'moq', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                        { header: 'STOCKS', key: 'item_stocks', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: false }, numFmt: '#,##0' } },
                    ]
                    worksheet.columns = headers

                    for await (let item of e.data_items) {
                        item.srp = parseFloat(item.srp);
                        item.item_stocks = item.item_stocks ? parseInt(item.item_stocks) : 0;
                        worksheet.addRow(item)
                        await this.addImageToWorksheet(workbook, worksheet, item, pos);
                        pos++;
                    }
                    this.loaded = true
                }
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64' });
                    FileSaver.saveAs(blob, `Customer Sold Unsold ${moment().format('YYYY-MM-DD')}.xlsx`);
                    swal.close()
                });

            }).catch(e => {
                console.log(e)
                swal.fire('Error', ' - An error occured', 'error')
                this.loaded = true
            });


        },
        async addImageToWorksheet(workbook, worksheet, items, pos) {
            let height = 80;
            let width = 60;
            let aspectRatio = null;

            worksheet.getRow(pos + 1).height = height;

            let image = await new Promise((resolve) => {
                let img = new Image();
                img.src = items.base64img;
                img.onload = () => resolve(img);
            });

            aspectRatio = image.naturalWidth / image.naturalHeight;
            let imageHeight = aspectRatio * width;

            let finalImage = workbook.addImage({
                base64: items.base64img,
                extension: 'jpg',
            });

            worksheet.addImage(finalImage, {
                tl: { col: 0.1, row: pos + 0.1 },
                br: { col: 1, row: worksheet.rowCount },
                ext: { width: width, height: height },
                editAs: 'twoCell',
            });

            let row = worksheet.getRow(worksheet.rowCount);
            row.width = width
            if (imageHeight > worksheet.getRow(pos + 1).height) {
                worksheet.getRow(pos + 1).height = imageHeight;
            }
        },

        getCustomerSoldUnsoldItems() {
            this.loaded = false

            if (this.selectedCustomers == '') {
                swal.fire("", 'Select Customer First', "warning");
                this.loaded = true
                return false
            }

            this.notPurchase == true ? this.purchaseVal = 1 : this.purchaseVal = 0;
            let creationCheck = this.cust_created_date == true ? 1 : '';

            this.$store.dispatch('reports', {
                cust_creation_date: creationCheck,
                not_purchase: this.purchaseVal,
                sort_filter: this.sortingSelectedOption,
                stocks_filter: this.stockSelectedOption,
                table_filter: this.selectedOption,
                customer_id: this.selectedCustomers,
                salesman_id: this.salesman_id,
                category_id: this.category_ids,
                type_id: this.item_type_ids,
                date_from: this.date_from,
                date_to: this.date_to,
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                date_range: this.date_range,
                url: 'getCustomerSoldUnsoldItems'
            }).then(response => {
                this.dataItems = response.data.customers
                this.totals.customer_count = response.data.customer_count
                this.loaded = true
            });
        },
        triggerByDateRangeFilter() {
            if ([3, 4].includes(this.sortingSelectedOption)) {
                this.byDateRange = 1
            }
        },
        resetFields() {
            this.errors = []
            this.customers_list = []
            this.customersList = []
            this.customer_count = 0
            this.salesman_id = ''
            this.salesman_list = []
            this.selectedCustomers = []
            this.item_type_list = []
            this.item_type_ids = []
            this.notPurchase = true
            this.byDateRange = false
            this.dataItems = []

            this.getCustomers()
            this.getSalesmanList()
            this.getItemTypes()
        },
    },
    watch: {
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.category_list = val.data
                this.loading.item_category_selection = false

            }
        },
        'GET_ITEM_TYPES': {
            handler(val) {
                this.item_type_list = val.data
                this.loading.item_type_selection = false

            }
        },
        'GET_CUSTOMERS_SELECTION': {
            handler(val) {
                this.customers_list = val
                this.loading.customer_selection = false
            }
        },
        'GET_SALESMAN_SELECTION': {
            handler(val) {
                this.salesman_list = val
                this.loading.salesman_selection = false
            }
        },
    }
}
</script>

<style scope>
.col {
    padding: 2px !important;
}

.card-body {
    padding: 3px !important;
}

.card-icon {
    height: 70px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.total-customer {
    background-color: #1976d2;
}

.card-details {
    height: 70px;
    padding-left: 9px;
    padding-top: 20px;
    line-height: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.total-cards {
    margin: 4px;
}

.v-btn {
    margin-right: 2px;
}

label {
    margin-bottom: 0px !important;
}

::v-deep .v-label {
    margin-bottom: 0px !important;
}

::v-deep .v-input--selection-controls {
    margin-top: 10px !important;
    padding-top: 0px !important;
}
</style>
