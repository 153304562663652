<template>
    <div class="tabs-body-color">
        <v-container fluid>
            <v-row>
                <CheckerFormTableComponentVue
                    :checkerFormsActionResult="action"
                    :tab_name="tab_name"
                    :user_access="USER_ACCESS"
                    :print="null"
                    @showEditModal="showEditModal"
                ></CheckerFormTableComponentVue>
            </v-row>
        </v-container>

        <v-app id="addEditCheckerFormModal">
            <v-dialog v-model="add_edit_checkerform_dialog" persistent max-width="98%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-card-title>
                            <span class="headline">Checker Form</span>
                        </v-card-title>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right" @click="add_edit_checkerform_dialog = false; resetFields(); $emit('closeModal');">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                    </v-row>
                    <v-card-text v-if="loaded==true">
                        <form class="form-horizontal" action="/checkerforms" method="post" v-on:submit.prevent="onSubmit">
                            <div class="card-body">
                                <div class="row m-0 text-dark" v-if="action=='Edit'">
                                    <div class="col-sm-12 col-md-4 text-left">
                                        <div class="d-flex flex-column">
                                            <span>CF#: {{ this.checker_form.id }}</span>
                                            <span>WPF#: {{ this.checker_form.wp_list_id }}</span>
                                            <span>SO#: {{ this.checker_form.sales_order_num }}</span>
                                            <span>Issuer: {{ this.checker_form.issuer_name }}</span>
                                            <span>Date Issued: {{ this.checker_form.created_at | formatDateTime }}</span>
                                            <span>Start Date: {{ this.checker_form.start_date | formatDateTime24hours }}</span>
                                            <span>End Date: {{ this.checker_form.end_date | formatDateTime24hours }}</span>
                                            <span>Durations: {{ this.checker_form.durations }}</span>
                                            <span>Correct Picking Reason:{{ this.checker_form.correct_picking_reason}}</span>
                                            <v-checkbox
                                                v-model="enable_scan"
                                                input-value="1"
                                                label="Enable Scan"
                                                class="p-2">
                                            </v-checkbox>
                                            <base-qr-reader v-if="enable_scan==1" @scanResult="scanResult" @closeQrScannerDialog="closeQrScannerDialog" :autoOpenCamera="autoOpenCamera" :autoOpen="true" :scanner="scanner"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-8 text-right">
                                        <div class="row d-flex justify-end">
                                            <div class="col-sm-12 col-md-2">
                                                <v-text-field
                                                    v-model="num_of_box"
                                                    outlined
                                                    dense
                                                    id="num_of_box"
                                                    label="No. of boxes"
                                                    class="w-100"
                                                    >
                                                </v-text-field>
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <v-autocomplete :items="GET_ALL_WAREHOUSE_PERSONNEL_CHECKER"
                                                    v-model="checker_name"
                                                    outlined
                                                    dense
                                                    item-value="name"
                                                    item-text="name"
                                                    label="Select Checker"
                                                    class="w-100"
                                                    :isDisabled="true">
                                                </v-autocomplete>
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <v-autocomplete :items="GET_ALL_WAREHOUSES"
                                                    v-model="checker_form.warehouse_id"
                                                    outlined
                                                    dense
                                                    item-value="value"
                                                    item-text="text"
                                                    label="Select Warehouse"
                                                    class="w-100"
                                                    :isDisabled="true">
                                                </v-autocomplete>
                                            </div>
                                        </div>
                                        <v-row class="d-flex justify-end">
                                            <div class="col-sm-12 col-md-3">
                                                <span class="d-flex justify-space-between"><strong>Correct Picking</strong><v-icon v-if="isVisible" small @click="clearCorrectPicking()">mdi-close</v-icon></span>
                                                <v-radio-group inline class="d-flex flex-row" v-model="correction_picking">
                                                    <v-row>
                                                       <v-col class="d-flex align-center justify-start pl-2">
                                                        <v-radio value="0"></v-radio><span>No</span>
                                                       </v-col>
                                                       <v-col class="d-flex align-center justify-start">
                                                        <v-radio value="1"></v-radio><span>Yes</span>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="d-flex flex-column pl-2 pr-2" v-if="ReasonisVisible">
                                                        <label class="text-caption">Please specify:</label>
                                                        <v-text-field
                                                        v-model="correct_picking_reason"
                                                        dense
                                                        outlined
                                                        label="Reason"
                                                        ></v-text-field>
                                                    </v-row>
                                                </v-radio-group>
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <v-text-field
                                                    v-model="remarks"
                                                    outlined
                                                    dense
                                                    id="remarks"
                                                    label="Remarks"
                                                    class="w-400"
                                                    >
                                                </v-text-field>
                                            </div>
                                        </v-row>
                                        <v-row class="d-flex justify-end">
                                            <div class="col-sm-12 col-md-3">
                                                <v-text-field
                                                    v-model="start_date"
                                                    outlined
                                                    dense
                                                    label="Start Date and Time"
                                                    placeholder="MM-DD-YYYY 24:00"
                                                    class="w-400"
                                                    >
                                                </v-text-field>
                                            </div>
                                            <div class="col-sm-12 col-md-3">
                                                <v-text-field
                                                    v-model="end_date"
                                                    outlined
                                                    dense
                                                    label="End Date and Time"
                                                    placeholder="MM-DD-YYYY 24:00"
                                                    class="w-400"
                                                    >
                                                </v-text-field>
                                            </div>
                                        </v-row>
                                    </div>
                                </div>

                            </div>

                            <v-card
                                outlined
                                class="p-2"
                            >
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <v-simple-table dense
                                            class="dynamic_list_table">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-center mx-2" width="7%">MOQ</th>
                                                        <th class="text-center mx-2" width="7%">Qty to Ship</th>
                                                        <th class="text-center mx-2" width="7%" v-if="enable_scan==1">Qty Scanned</th>
                                                        <th class="text-center mx-2" width="7%">Qty</th>
                                                        <th class="text-center mx-2" width="4%" v-if="stock_card">SC Qty</th>
                                                        <th class="text-center mx-2" width="7%">UOM</th>
                                                        <th class="text-center mx-2" width="12%">Model</th>
                                                        <th class="text-center mx-2" width="22%">Name</th>
                                                        <th class="text-center mx-2" width="5%">Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(checkerFormItem, i) in checkerFormItems"
                                                        :key="i"
                                                    >
                                                        <td>
                                                            <input type="text" class="form-control text-center" v-model="checkerFormItems[i].item.moq" readonly>
                                                        </td>
                                                        <td>
                                                            <!-- <input type="text" class="form-control text-center" v-model="checkerFormItems[i].quantity_served" @keypress="numberOnly"
                                                                @input="changeQuantity(i)" @blur="checkMoq(i)"> -->
                                                            <input type="text" class="form-control text-center" v-model="checkerFormItems[i].quantity_served" @keypress="numberOnly"
                                                                @input="changeQuantity(i)">
                                                        </td>
                                                        <td class="d-flex justify-content-between align-items-center" v-if="enable_scan==1">
                                                            <input style="width:90%" type="text" class="form-control text-center" v-model="checkerFormItems[i].scanned_qty" readonly>
                                                                   <!-- <v-btn
                                                                        fab
                                                                        dark
                                                                        small
                                                                        color="primary"
                                                                        style="height:20px; width:20px;"
                                                                        @click="checkerFormItems[i].scanned_qty--"
                                                                    >
                                                                        <v-icon dark small style="font-size:12px">
                                                                            fas fa-minus
                                                                        </v-icon>
                                                                    </v-btn> -->
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center" v-model="checkerFormItems[i].quantity" readonly>
                                                        </td>
                                                        <td v-if="stock_card">
                                                            <input type="text" class="form-control text-center" v-model="checkerFormItems[i].sc_quantity" readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center small" v-model="checkerFormItems[i].item_uom_text" readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center small" v-model="checkerFormItems[i].model" readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center small" v-model="checkerFormItems[i].name" readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" class="form-control text-center small" v-model="checkerFormItems[i].item_type_text" readonly>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>
                                </div>
                            </v-card>
                            <div class="border-top">
                                <div class="card-body py-4 d-flex justify-content-center">
                                    <v-btn  v-on:click="addNewCheckerForm()" v-if="action=='Add'">Submit</v-btn>
                                    <v-btn  v-on:click="editCheckerForm()" v-else-if="action=='Edit'">Update</v-btn>
                                </div>
                            </div>
                        </form>
                    </v-card-text>
                    <v-card-text v-else-if="loaded==false">
                        <div class="text-center">
                            <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                            ></v-progress-circular>
                        </div>
                    </v-card-text>

                </v-card>
            </v-dialog>
        </v-app>

    </div>
    </template>

    <script>
        import SharedFunctionsComponentVue from '@/views/main/Shared/SharedFunctionsComponent.vue';
        import CheckerFormTableComponentVue from './CheckerFormTableComponent.vue';
        import swal from 'sweetalert2';
        import { mapGetters } from 'vuex';
        import moment from 'moment';
        export default {
            mixins: [SharedFunctionsComponentVue],
            data(){
                return {
                    stock_card: false,
                    checker_form: '',
                    num_of_box:'',

                    editCheckerFormId: '',
                    add_edit_checkerform_dialog: false,

                    checker_form_num: '',
                    checker_form_date: '',

                    user_id: '',
                    status: '',
                    created_at: '',
                    issuer_name: '',

                    checker_name: '',
                    remarks: '',
                    start_date: null,
                    end_date: null,
                    correct_picking_reason: '',

                    checkerFormItems: [],
                    order: {
                        quantity_served: '',
                        quantity: '',
                        item_id: '',
                        type: '',
                        item: {
                            id: '',
                            model: '',
                            name: '',
                            description: '',
                            type_id: '',
                            category_id: '',
                            uom_id: '',
                            item_uom_text: '',
                            selling_price: '',
                            sp_price: '',
                            moq: ''

                        },
                    },
                    enable_scan:0,
                    autoOpenCamera:0,
                    items_list: [],
                    type_list: [],
                    warehouses_list: [],
                    errors: [],
                    action: '',
                    actionResult : {
                        model : 'Checker Form',
                        action : ''
                    },
                    loaded: false,
                    scanner:{
                        item_model:'',
                        item_barcode:0,
                        quantity:0
                    },
                    correction_picking: null,
                    isVisible: false,
                    ReasonisVisible: false,
                }
            },
            components: {
                CheckerFormTableComponentVue
            },
            props: [
                'tab_name',
            ],
            methods: {
                checkMoq(key){
                    if(this.checkerFormItems[key].quantity_served != '' && this.checkerFormItems[key].moq != ''){
                        var moq = parseInt(this.checkerFormItems[key].item.moq);
                        if(moq != null && moq > 0){
                            if(this.checkerFormItems[key].quantity_served % moq > 0){

                                var upperQty = this.checkerFormItems[key].quantity_served
                                var lowerQty = this.checkerFormItems[key].quantity_served
                                while(upperQty % moq > 0){
                                    upperQty++
                                }
                                while(lowerQty % moq > 0){
                                    lowerQty--
                                }
                                var suggestion = ''
                                if(lowerQty == 0){
                                    suggestion = "\n Suggestion: "+upperQty
                                }else{
                                    suggestion = "\n Suggestion: "+upperQty+" or "+ lowerQty
                                }
                                swal.fire("", "Quantity should be divisible by "+moq+suggestion, "warning");
                                this.checkerFormItems[key].quantity_served = ''
                            }
                        }
                    }
                },
                removeOrderByIndex(index){
                    this.checkerFormItems.splice(index, 1)
                },
                changeQuantity(key){
                    let qty = 0;

                    qty = this.stock_card ? parseInt(this.checkerFormItems[key].sc_quantity) : parseInt(this.checkerFormItems[key].quantity)
                    if(parseInt(this.checkerFormItems[key].quantity_served) > qty){
                        this.checkerFormItems[key].quantity_served = qty
                    }
                },

                closeQrScannerDialog(confirm) {
                    if(confirm) {

                    }
                    return false
                },
                scanResult(result) {
                    let that = this
                    if(!!result){
                        that.checkerFormItems.forEach(e => {
                            if (e.item.barcode == result) {
                                if (e.quantity_served < e.scanned_qty + 1) {
                                    swal.fire('Warining !','Quantity can not be exceed !','warning')
                                    return false
                                }
                                e.scanned_qty += 1

                                that.scanner.quantity = e.scanned_qty
                                that.scanner.item_model = e.model
                            }
                        })
                    }else{
                        swal.fire('Error!','Item Undefiend','error')
                    }
                },
                selectModel(key, model_id, is_editable = false) {
                    if(model_id != null && model_id != ''){
                        let payload = model_id

                        this.$store.dispatch('getItemEditInfo',payload).then(response => {
                            let data = response.data

                            this.checkerFormItems[key].item.name          = data.name
                            this.checkerFormItems[key].item.model         = data.model
                            this.checkerFormItems[key].item.sp_price      = data.sp_price
                            this.checkerFormItems[key].item.selling_price = data.selling_price
                            this.checkerFormItems[key].item.type_id       = data.type_id
                            this.checkerFormItems[key].type               = data.item_type_text
                            this.checkerFormItems[key].item.item_uom_text = data.item_uom_text
                            this.checkerFormItems[key].item.moq           = data.moq

                            if(is_editable){
                                this.checkerFormItems[key].is_editable = true
                            }

                        }).catch(error => {

                        });
                    }
                    $("input").blur();
                },

                showAddCheckerForm(){
                    this.add_edit_checkerform_dialog = true
                    this.action                    = 'Add'
                },
                showEditModal(value){
                    this.action = 'Edit'
                    this.editCheckerFormId = value

                    if(this.editCheckerFormId != null && this.editCheckerFormId > 0){
                        let payload = {
                            url: 'viewCheckerForm/'+this.editCheckerFormId
                        }
                        this.$store.dispatch('getCF',payload).then(response => {
                            let data = response.data

                            if(data.error){
                                swal.fire("", data.error, "warning");
                                return false
                            }

                            this.checker_form            = data.checker_form
                            this.checkerFormItems        = data.checker_form.checker_form_items
                            this.stock_card              = data.stock_card
                            this.num_of_box              = data.checker_form.num_of_box

                            this.checker_name            = data.checker_form.checker_name
                            this.remarks                 = data.checker_form.remarks

                            this.loaded = true
                            this.add_edit_checkerform_dialog = true
                        }).catch(error => {

                        });
                    }
                },
                editCheckerForm(){

                    // for(let i in this.checkerFormItems){
                    //    if(this.checkerFormItems[i].quantity_served < 1 || this.checkerFormItems[i].quantity_served == undefined){
                    //         swal.fire('Error','Unable to proceed quantity serve must be greater than 0.','error')
                    //         return false
                    //    }
                    // }

                    this.errors = []
                    let proceed = true
                    if(this.enable_scan == 1){
                        this.checkerFormItems.every(e=>{
                            if(e.quantity_served > e.scanned_qty || e.scanned_qty % e.quantity != 0){
                                proceed = false
                                return false
                            }else{
                                e.quantity_served = e.scanned_qty
                                return true
                            }
                        })
                    }

                    if(proceed == false){
                        swal.fire('Warning !' ,'Quantity scanned should be divisible Qty\n Please double check the Qty','warning')
                        return false
                    }
                    let object = {}
                    object.startTime  = new moment(this.start_date);
                    object.endTime    =  new moment(this.end_date);

                    object.durationMs = object.endTime - object.startTime;
                    object.minutes    = Math.floor(object.durationMs / 1000 / 60) % 60;
                    object.hours      = Math.floor(object.durationMs / 1000 / 60 / 60);
                    object.durations  = object.hours + " hr/s"+ " & " + object.minutes + " minutes";

                    let payload = {
                        checker_form_id:        this.checker_form.id,
                        checkerFormItems:       this.checkerFormItems,
                        warehouse_id:           this.checker_form.warehouse_id,
                        checker_name:           this.checker_name,
                        remarks:                this.remarks,
                        num_of_box:             this.num_of_box,
                        start_date:             object.startTime.format('YYYY:MM:DD HH:mm'),
                        end_date:               object.endTime.format('YYYY:MM:DD HH:mm'),
                        durations:              object.durations,
                        correction_picking:     this.correction_picking,
                        correct_picking_reason: this.correct_picking_reason,
                        url:                'checkerForms/'+this.editCheckerFormId
                    }
                    this.$store.dispatch('putCF',payload).then(response => {
                        let data = response.data
                        if(data.error){
                            swal.fire("", data.error, "warning");
                            return false
                        }

                        this.resetFields()
                        this.add_edit_checkerform_dialog = false
                        this.editCheckerFormId           = 0

                        this.action = 'success'
                        swal.fire("", "Edit Checker Form Successful!", "success");

                    }).catch(error => {
                    console.log(error)
                    });

                },
                resetFields(){
                    this.errors              = []
                    this.checker_form_num    = ''
                    this.checker_form_date   = ''
                    this.user_id             = ''
                    this.status              = ''
                    this.created_at          = ''
                    this.issuer_name         = ''
                    this.checkerFormItems    = []
                    this.num_of_box          = 0
                    this.correction_picking  = ''
                    this.end_date            = null
                    this.start_date          = null
                },
                clearCorrectPicking(){
                    this.correction_picking = ''
                }
            },
            mounted(){
                this.$store.dispatch('getAllwarehousepersonnelChecker');
            },
            computed:{
                ...mapGetters([
                    'GET_ALL_WAREHOUSES',
                    'USER_ACCESS',
                    'GET_ALL_WAREHOUSE_PERSONNEL_CHECKER'
                ])
            },
            watch:{
                add_edit_checkerform_dialog:function(){
                    if(this.add_edit_checkerform_dialog == true){
                        if(this.action == 'Add'){
                            this.checkerFormItems = []
                            this.loaded = true
                        }
                    }
                },
                enable_scan(val){
                    val == 1 ? this.$store.commit('closeQrScannerDialog') : this.$store.commit('showQrScannerDialog');
                },
                'correction_picking':{
                    handler(val){
                        if(!val.length){
                            this.isVisible = false
                            this.ReasonisVisible = false
                        }else{
                            this.isVisible = true
                            if(val == 0){
                                this.ReasonisVisible = true
                                }else{
                                this.ReasonisVisible = false
                            }
                        }
                    }
                }
            },

        };
    </script>
