<template>
    <v-container fluid>
        <ComponentHeader
            :button="true"
            :filterd="filters"
            :title="'Pending Stock card By DR'"
            :type="'Sales'"
        ></ComponentHeader>
        <ReportBodyComponent
            :headers="headers"
        >
            <template #first-row>
                <v-card-text>
                    <v-row>
                        <v-col lg="5" style="padding:3px">
                            <v-autocomplete
                                v-model="filters.customers.selected"
                                :items="GET_CUSTOMERS_SELECTION"
                                :loading="loading.customer_selection"
                                :disabled="loading.customer_selection"
                                outlined
                                dense
                                background-color="white"
                                label="Select Customer"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>

            </template>
            <template #second-row>
                <v-card-text>
                    <v-row>
                        <v-col lg="3">
                            <label class="float-left control-label col-form-label">Date From</label>
                            <v-menu
                                v-model="filters.date_from_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="filters.date_from"
                                    readonly
                                    append-icon="mdi-calendar-clock"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                                <v-date-picker v-model="filters.date_from" @input="filters.date_from_menu = false"></v-date-picker>
                            </v-menu>
                            <span v-for="errors in errors.date_from" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </v-col>
                        <v-col lg="3">
                            <label class="float-left control-label col-form-label">Date To</label>
                            <v-menu
                                v-model="filters.date_to_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_to"
                                        readonly
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_to" @input="filters.date_to_menu = false"></v-date-picker>
                            </v-menu>
                            <span v-for="errors in errors.date_to" class="text-warning" v-bind:key="errors">{{errors}}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
        </ReportBodyComponent>

    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ReportBodyComponent from '@/views/main/layouts/Reports/ReportsBodyComponent.vue'
import moment from 'moment';
import { mapGetters } from 'vuex';
    export default {
        data(){
            return{
                errors: [],
                filters:{
                    date_from:moment().clone().startOf('month').format('YYYY-MM-DD'),
                    date_to: moment().clone().endOf('month').format('YYYY-MM-DD'),
                    date_from_menu: false,
                    date_to_menu: false,
                    customers:{
                        selection:null,
                        selected:'',
                    },
                    salesman:{
                        selected:'',
                        selection:null
                    },
                    confirmed:'1',
                    unconfirmed:'1',
                },
                selected:{
                    customers:'',
                    salesman:''
                },

                loading:{
                        customer_selection : true
                },
                headers: [
                    { text: 'SC', align: 'left', value: 'sc', },
                    { text: 'SC date', align: 'left', value: 'sc_date', },
                    { text: 'DR', align: 'left', value: 'pl', },
                    { text: 'DR date', align: 'left', value: 'pl_date' },
                    { text: 'Customers', align: 'center', value: 'company_name', },
                    { text: 'Model', align: 'center', value: 'model', },
                    { text: 'Name', align: 'center', value: 'name', },
                    { text: 'Category', align: 'center', value: 'category', },
                    { text: 'Type', align: 'center', value: 'type', },
                    { text: 'UOM', align: 'center', value: 'uom', },
                    { text: 'Qty', align: 'right', value: 'quantity', },
                    { text: 'SC Qty', align: 'right', value: 'total_sc_qty', },
                    { text: 'status', align: 'right', value: 'status', },
                ],
            }
        },
        mounted(){
            this.$store.dispatch('getAllcustomersSelection')
            this.getFilters()

        },
        methods:{
            getFilters(){
                let data = {
                        customers: this.filters.customers.selected,
                        date_from: this.filters.date_from,
                        date_to: this.filters.date_to,
                        filename: 'Pending Stock card By DR',
                        url: 'getPendingSCByPL',
                        headers: [
                            { text: 'SC', align: 'left', value: 'sc', },
                            { text: 'SC date', align: 'left', value: 'sc_date', },
                            { text: 'DR', align: 'left', value: 'pl', },
                            { text: 'DR date', align: 'left', value: 'pl_date' },
                            { text: 'Customers', align: 'center', value: 'company_name', },
                            { text: 'Model', align: 'center', value: 'model', },
                            { text: 'Name', align: 'center', value: 'name', },
                            { text: 'Category', align: 'center', value: 'category', },
                            { text: 'Type', align: 'center', value: 'type', },
                            { text: 'UOM', align: 'center', value: 'uom', },
                            { text: 'Qty', align: 'right', value: 'quantity', },
                            { text: 'SC Qty', align: 'right', value: 'total_sc_qty', },
                            { text: 'status', align: 'right', value: 'status', },
                        ],
                    }
                return data
            }
        },

        computed:{
            ...mapGetters([
                'GET_SALESMAN_SELECTION',
                'GET_CUSTOMERS_SELECTION',
                'GET_SALES_REPORT_DATA'
            ]),
        },

        components:{
            ComponentHeader,
            ReportBodyComponent,
        },

        watch:{
            'filters':{
                handler(){
                    this.$store.commit('FILTER_VALUE',this.getFilters())
                },
                deep:true
            },
            'GET_CUSTOMERS_SELECTION':{
                handler(val){
                    this.filters.customers.selection = val
                    this.loading.customer_selection = false
                }
            },
            'GET_SALESMAN_SELECTION':{
                handler(val){
                    this.filters.salesman.selection = val
                }
            }
        }
    }
</script>
