<template>
<div class="tabs-body-color">

    <div class="container">
        <div class="row">
            <div class="col-12 d-flex no-block align-center">
                <div class="btn-toolbar">
                    <!-- <v-btn data-modal-id="addTypeModal" v-on:click="showAddType()">
                        <i class="fas fa-plus"></i>Add
                    </v-btn> -->
                    <!-- <buttons class="ml-3"
                            :action="buttons.actions.create"
                            :button_icon="buttons.icons.create"
                            :color="buttons.colors.create"
                            @click="showAddType()"
                        >
                    </buttons> -->
                    <div class="row my-1">
                        <div class="">
                            <div class="btn-toolbar">
                                <v-btn class="" @click="showAddType()" v-if="userAccess.includes('discountType_create')">
                                    <v-icon>mdi-plus</v-icon>
                                    Add
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- Types Table -->
            <TypesTableComponentVue :userAccess="userAccess" :itemTypesActionResult="action" @showEditModal="showEditModal"></TypesTableComponentVue>
        </div>
    </div>

    <v-app id="addEditTypeModal">
        <v-dialog v-model="add_edit_type_dialog" persistent max-width="70%" scrollable>
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Discount Type Information</span>
                        </v-card-title>
                    </v-col>
                    
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="add_edit_type_dialog = false; resetFields(); $emit('closeModal');">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-card-text>
                    <form class="form-horizontal" action="/itemTypes" method="post" v-on:submit.prevent="onSubmit">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <label for="name" class="float-left control-label col-form-label">Type Name</label>
                                    <input type="text" class="form-control" id="name" placeholder="Type Name" v-model="name">
                                    <span v-for="errors in errors.name" class="text-warning" v-bind:key="errors">{{errors}}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-2">
                                    <v-switch
                                        v-model="is_active"
                                        label="active"
                                        color="primary"
                                        input-value="1"
                                    ></v-switch>
                                </div>
                            </div>

                        </div>

                        <div class="border-top">
                            <div class="card-body py-4 d-flex justify-content-center">
                                <v-btn  v-on:click="addNewType()" v-if="action=='Add'">Submit</v-btn>
                                <v-btn  v-on:click="editType()" v-else-if="action=='Edit'">Update</v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
    
</div>
</template>

<script>
    import TypesTableComponentVue from '../tables/TypesTableComponent.vue';
    // import buttons from '@/views/main/layouts/Buttons/DialogButton.vue';
    // import buttons_components from '@/assets/js/components-js.js';
    import swal from 'sweetalert2';
    import {mapGetters} from 'vuex';
    export default {
        data(){
            return {
                editTypeId: '',
                add_edit_type_dialog: false,
                name: '',
                is_active: '',
                errors: [],
                action: '',
                actionResult : {
                    model : 'Type',
                    action : ''
                },
                userAccess: []
                // buttons:buttons_components.buttons,
            }
        },
        components: {
            TypesTableComponentVue,
            // buttons,
            // buttons_components,
        },
        computed:{
            ...mapGetters([
                'GET_SHOW_ITEM_TYPES',
                'USER_ACCESS'
            ])
        },
        async mounted() {
        await this.$store.dispatch('checkAccessUser', this.$router.currentRoute.params.id)

        },  
        methods: {
            showAddType(){
                this.add_edit_type_dialog = true
                this.action = 'Add'
                this.is_active = 1
            },
            addNewType(){
                this.errors = []
                let payload = {
                    name:   this.name,
                    active: this.is_active,
                }
                this.$store.dispatch('storeItemTypes',payload).then(response => {
                    this.resetFields()
                    this.add_edit_type_dialog = false
                    this.$store.dispatch('getAllItemTypes');
                    this.action = 'success'
                    swal.fire("", "Add Type Successful!", "success");
                }).catch(error => {
                    // if(error.response.status == 422){
                    //     this.errors = error.response.data.errors
                    // }
                    swal.fire("", "Add Type Failes!", "success");
                });
            },
            showEditModal(value){
                this.action = 'Edit'
                this.editTypeId = value

                if(this.editTypeId != null && this.editTypeId > 0){
                    
                    this.$store.dispatch('showItemTypes',this.editTypeId).then(response => {              
                        let data = this.GET_SHOW_ITEM_TYPES

                        this.name                       = data.name
                        this.is_active                  = data.active
                        this.add_edit_type_dialog       = true
                    }).catch(error => {

                    });
                }
            },
            editType(){
                this.errors = []
                let payload = {
                    type_id:this.editTypeId,
                    name:   this.name,
                    active: this.is_active,
                }
                
                this.$store.dispatch('updateItemTypes',payload).then(response => {
                    this.resetFields()
                    this.add_edit_type_dialog = false
                    this.editTypeId           = 0
                    this.action               = 'success'
                    swal.fire("", "Edit Type Successful!", "success");
                    this.$store.dispatch('getAllItemTypes');
                }).catch(error => {
                    // if(error.response.status == 422){
                    //     this.errors = error.response.data.errors
                    // }
                    swal.fire("", "Edit Type Failed!", "error");
                    this.action = 'Edit'
                });
            },
            resetFields(){
                this.errors              = []
                this.name                = ''
                this.is_active           = 1
            },

        },
        watch: {
            USER_ACCESS:{
                handler(val){
                    this.userAccess = val.map(e=>e.actions_code);
                }
            },
        }
    };
</script>
<style>
    .v-text-field.v-text-field--enclosed .v-text-field__details{
        display: none;
    }
</style>