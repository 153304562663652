<template>
    <v-dialog v-model="soaCustPaymentDetailDialog" persistent max-width="98%">
        <v-card>
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Customer Payment Detail</span>
                    </v-card-title>
                </v-col>

                <v-col cols="pull-right-10 p-2">
                    <v-btn text icon color="gray" class="float-right" @click="soaCloseCustDetailAddEditDialog()">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <div class="px-4 pb-4">
                <div>
                    <span>SOA ID: </span>
                    <span>{{ soaId }}</span>
                </div>
                <div>
                    <span>Company Name: </span>
                    <span>{{ companyName }}</span>
                </div>
                <div>
                    <span>Payment Method: </span>
                    <span class="badge badge-success" v-if="paymentMethod == 0">cash</span>
                    <span class="badge badge-purple" v-else-if="paymentMethod == 1">cheque</span>
                    <span class="badge badge-warning" v-else-if="paymentMethod == 2">deposit</span>
                </div>
                <div>
                    <span>Due Date: </span>
                    <span>{{ dueMonth }}</span>
                </div>
                <div>
                    <span>Status: </span>
                    <span v-if="status==5" class="badge badge-primary">confirmed</span>
                    <span v-else-if="status==6" class="badge badge-warning">partial</span>
                    <span v-else-if="status==7" class="badge badge-success">paid</span>
                    <span v-else-if="status==8" class="badge badge-danger">overdue</span>
                </div>
                <!-- <div>
                    <span>Total Amount: </span>
                    <span>&#x20b1;{{ soaTotalAmount | currency }}</span>
                </div>
                <div>
                    <span>Paid Amount: </span>
                    <span>&#x20b1;{{ soaTotalPaidAmount | currency }}</span>
                </div>
                <div>
                    <span>Balance Amount: </span>
                    <span>&#x20b1;{{ balanceTotalAmount | currency }}</span>
                </div> -->
            </div>

            <v-card class="pa-2 mx-4 mb-4">
                <div>
                    Payment Details
                </div>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="customerPaymentDetails"
                        v-model="selectedCustomerPaymentDetails"
                    >
                        <template v-slot:[`item.gross_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.gross_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.wht_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.wht_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.allocate_wht`]="{ item }">
                            <v-checkbox
                                hide-details
                                dense
                                v-model="item.wht_checked"
                                style="margin-top:0 !important; padding-top:0 !important;display:inline-block"
                                @change="enableWhtAllocation(item)"
                                :disabled="item.wht_amount == 0"
                            />
                            <span class="text-nowrap">
                                <input
                                    type="text"
                                    :min="0"
                                    :max="item.wht_amount"
                                    v-model="item.allocate_wht"
                                    dense
                                    outlined
                                    style="
                                        text-align:right;
                                        cursor:pointer;
                                        margin:1px 0px 1px !important;
                                        height: 1.5rem !important;
                                        padding:0 5px !important;
                                        border: 1px solid black;
                                        border-radius: 6px;
                                        display:inline-block
                                    "
                                    :readonly="!item.wht_checked"
                                    @keypress="decimalOnly"
                                    @keypress.enter="getAllocatedWht(item)"
                                    @focus="$event.target.select()"
                                />
                            </span>
                        </template>

                        <template v-slot:[`item.other_adj_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.other_adj_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.total_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.total_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.remaining_amount`]="{ item }">
                            <span class="text-nowrap">
                                &#x20b1;{{ item.remaining_amount | currency }}
                            </span>
                        </template>

                        <template v-slot:[`item.allocate_amount`]="{ item }">
                            <v-checkbox
                                hide-details
                                dense
                                v-model="item.checked"
                                style="margin-top:0 !important; padding-top:0 !important;display:inline-block"
                                @change="enableCustomerPaymentAllocation(item)"
                                :disabled="balanceTotalAmount == allocateTotalAmount && !item.checked"
                            />
                            <span class="text-nowrap">
                                <!-- <VueNumberFormat
                                    v-model="item.allocate_amount"
                                    style="border: 1px solid black"
                                    @input="getAllocatedAmount(item)"
                                ></VueNumberFormat> -->
                                <input
                                    type="text"
                                    :min="0"
                                    :max="getMaxAmount(item)"
                                    v-model="item.allocate_amount"
                                    dense
                                    outlined
                                    style="
                                        text-align:right;
                                        cursor:pointer;
                                        margin:1px 0px 1px !important;
                                        height: 1.5rem !important;
                                        padding:0 5px !important;
                                        border: 1px solid black;
                                        border-radius: 6px;
                                        display:inline-block
                                    "
                                    :readonly="!item.checked"
                                    @keypress="decimalOnly"
                                    @keypress.enter="getAllocatedAmount(item)"
                                    @blur="getAllocatedAmount(item)"
                                    @focus="$event.target.select()"
                                />
                            </span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>

            <div style="text-align:end" class="px-4 pb-4">
                <div>
                    <span style="display:inline-block">Gross SOA Amount:</span>
                    <span style="display:inline-block">&#x20b1; {{ soaTotalAmount | currency }}</span>
                </div>
                <div>
                    <span style="display:inline-block">Paid Total Amount:</span>
                    <span style="display:inline-block">&#x20b1; {{ soaTotalPaidAmount | currency }}</span>
                </div>
                <div>
                    <span style="display:inline-block">Subtotal:</span>
                    <span style="display:inline-block">&#x20b1; {{ soaTotalAmount - soaTotalPaidAmount | currency }}</span>
                </div>
                <div>
                    <span style="display:inline-block">Allocate Total Amount:</span>
                    <span style="display:inline-block">&#x20b1; {{ allocateTotalAmount | currency }}</span>
                </div>
                <div class="pb-4">
                    <span style="display:inline-block">Allocate WHT Amount:</span>
                    <span style="display:inline-block">&#x20b1; {{ allocateWHTAmount | currency }}</span>
                </div>
                <div>
                    <span style="display:inline-block">Remaining Balance:</span>
                    <span style="display:inline-block">&#x20b1; {{ remainingBalance | currency }}</span>
                </div>
            </div>

            <v-divider class="mt-0" />

            <div style="text-align:center" class="pb-4">
                <v-btn :disabled="disableSubmit()" @click="submit()">Submit</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            headers: [
                { text: 'Ref#', width: '84px', value: 'reference_number' },
                { text: 'Customer', value: 'customer_name' },
                { text: 'Bank', value: 'bank_name' },
                { text: 'Payment type', value: 'payment_type_name' },
                { text: 'Payment Name', value: 'cheque_name' },
                { text: 'Payment Date', value: 'payment_date' },
                { text: 'Gross Amount', value: 'gross_amount' },
                { text: 'WHT Amount', value: 'wht_amount' },
                { text: 'Allocate WHT', value: 'allocate_wht' },
                { text: 'ADJ Amount', value: 'other_adj_amount' },
                { text: 'Remaining Amount', value: 'remaining_amount' },
                { text: 'Allocate Amount', value: 'allocate_amount' },
                { text: 'Created At', value: 'created_at' },
            ],
            customerPaymentDetails: [],
            selectedCustomerPaymentDetails: [],
            soaId: '',
            companyName: '',
            soaTotalAmount: '',
            soaTotalPaidAmount: '',
            dueMonth: '',
            statusText: '',
            status: '',
            paymentMethod: '',
            allocateTotalAmount: '0',
            // allocateWHTAmount: '0',
            balanceTotalAmount: '0',
            // remainingBalance: '0',
            num: {
                decimal: ".",
                separator: ",",
                prefix: "$ ",
                precision: 2,
                masked: false,
            },
            userAccess: {
                    create  : false,
                    edit    : false,
                    view    : false,
                    delete  : false,
                },
        }
    },
    components: {
    },
    computed: {
        ...mapGetters([
            'GET_CUST_PAYMENT_DET_BY_CUSTOMER',
            'USER_ACCESS'
        ]),
        soaCustPaymentDetailDialog() {
            return this.$store.state.accounting.soaCustomerDetails.soaCustPaymentDetailDialog;
        },
        soaDetails() {
            return this.$store.state.accounting.soaCustomerDetails.soaDetails;
        },
        remainingBalance() {
            // if(parseFloat(this.allocateTotalAmount) > 0) {
            //     return parseFloat(this.balanceTotalAmount) - parseFloat(this.allocateTotalAmount);
            // }

            // return parseFloat(0);
            // return (parseFloat(this.balanceTotalAmount) - parseFloat(this.allocateTotalAmount)) - parseFloat(this.allocateWHTAmount);
            return Number(this.soaTotalAmount) -
                Number(this.soaTotalPaidAmount) -
                Number(this.allocateTotalAmount) -
                Number(this.allocateWHTAmount)
        },
        allocateWHTAmount() {
            let filtered = this.customerPaymentDetails.filter(x=>x.wht_checked)
            let amt = [];
            let sumFiltered = 0;

            if(filtered.length > 0) {
                let amt = filtered.map(x=>{
                    return Number(x.allocate_wht) == 0 ? Number(x.allocate_wht) : Number(x.allocate_wht.replaceAll(',',''));
                });

                sumFiltered = _.sum(amt);

                return Number(sumFiltered);
            }

            return Number(sumFiltered);
        }
    },
    watch: {
        soaDetails() {
            if(!!this.soaDetails) {
                const { id, customer_id, company_name, total_amount, due_date, status, payment_method, paid_amount, balance_amount } = this.soaDetails;

                this.soaId = id;
                this.companyName = company_name;
                this.soaTotalAmount = total_amount;
                this.soaTotalPaidAmount = paid_amount;
                this.balanceTotalAmount = balance_amount;
                this.dueMonth = due_date;
                this.status = status;
                this.paymentMethod = payment_method;

                this.getCustomerPaymentDetails(customer_id);
            }
        },
    },
    methods: {
        clearFields() {
            this.soadId = '';
            this.companyName = '';
            this.soaTotalAmount = '';
            this.soaTotalPaidAmount = '';
            this.dueMonth = '';
            this.statusText = '';
            this.status = '';
            this.paymentMethod = '';
            this.allocateTotalAmount = 0;
            this.balanceTotalAmount = 0;
            this.customerPaymentDetails = [];
        },
        soaCloseCustDetailAddEditDialog() {
            this.$store.commit('soaCustomerClearDetails');
            this.$store.commit('soaCloseCustDetailAddEditDialog');
            this.clearFields();
        },
        getCustomerPaymentDetails(id) {
            this.$store.dispatch('getCustPaymentDetByCustomer',id).then(response=>{
                let data = this.GET_CUST_PAYMENT_DET_BY_CUSTOMER;
                let modified = [];

                if(data.length > 0) {
                    data.forEach(x=>{
                        modified.push(
                            Object.assign({
                                allocate_amount: '0',
                                checked: false,
                                allocate_wht: '0',
                                wht_checked: false,
                            }, x)
                        )
                    });
                }

                this.customerPaymentDetails = modified;
            });
        },
        enableCustomerPaymentAllocation(item) {
            let custPaymentDetail = this.customerPaymentDetails.find(x=>x.id == item.id);

            if(item.checked) {
                let filtered = this.customerPaymentDetails.filter(x=>x.checked && x.id != item.id)
                let amt = [];
                let sumFiltered = 0;

                if(filtered.length > 0) {
                    let amt = filtered.map(x=>{
                        return Number(x.allocate_amount) == 0 ? Number(x.allocate_amount) : Number(x.allocate_amount.replaceAll(',',''));
                    });

                    sumFiltered = _.sum(amt);
                }

                if(Number(item.remaining_amount) < Number(this.balanceTotalAmount)) {
                    if(amt.length > 0) {
                        custPaymentDetail.allocate_amount = this.getTwoDecimals(parseFloat(this.balanceTotalAmount) - sumFiltered);
                    } else {
                        if(filtered.length > 0) {
                            let amnt = filtered.map(x=>{
                                Number(x.allocate_amount) == 0 ? Number(x.allocate_amount) : Number(x.allocate_amount.replaceAll(',',''));
                            });

                            let sumFiltr = _.sum(amnt);

                            if(Number(item.remaining_amount) < Number(this.balanceTotalAmount) - sumFiltr) {
                                custPaymentDetail.allocate_amount = this.getTwoDecimals(Number(item.remaining_amount));
                            } else {
                                custPaymentDetail.allocate_amount = this.getTwoDecimals(Number(this.balanceTotalAmount) - sumFiltr)
                            }
                        } else {
                            custPaymentDetail.allocate_amount = this.getTwoDecimals(Number(item.remaining_amount));
                        }
                    }
                } else {
                    if(amt.length > 0) {
                        custPaymentDetail.allocate_amount = this.getTwoDecimals(Number(this.balanceTotalAmount) - sumFiltered);
                    } else {
                        let result = this.getTwoDecimals(Number(this.balanceTotalAmount) - Number(this.allocateTotalAmount))
                        custPaymentDetail.allocate_amount = result;
                    }
                }
            } else {
                custPaymentDetail.allocate_amount = this.getTwoDecimals(Number(0));
            }

            this.selectedCustTotalPayment();
        },
        enableWhtAllocation(item) {
            let filtered = this.customerPaymentDetails.filter(x=>x.wht_checked && x.id != item.id)
            let amt = [];
            let sumFiltered = 0;

            if(filtered.length > 0) {
                let amt = filtered.map(x=>{
                    return Number(x.allocate_wht) == 0 ? Number(x.allocate_wht) : Number(x.allocate_wht.replaceAll(',',''));
                });

                sumFiltered = _.sum(amt);
            }

            let custPaymentDetail = this.customerPaymentDetails.find(x=>x.id == item.id);

            if(item.wht_checked) {
                custPaymentDetail.allocate_wht = this.getTwoDecimals(Number(item.wht_amount));
                // this.allocateWHTAmount += Number(item.wht_amount.replaceAll(',',''));
            } else {
                custPaymentDetail.allocate_wht = this.Number(parseFloat(0));
                // this.allocateWHTAmount -= Number(parseFloat(0));
            }
        },
        getMaxAmount(item) {
            // let checker = Number(this.soaTotalAmount) <= Number(item.remaining_amount)
            // if(checker) {
            //     return Number(this.soaTotalAmount);
            // }

            // return Number(item.remaining_amount);
            return this.getTwoDecimals(200)
        },
        selectedCustTotalPayment() {
            let totalAmount = this.customerPaymentDetails.map(x=>{
                return Number(x.allocate_amount) == 0 ? Number(x.allocate_amount) : Number(x.allocate_amount.replaceAll(',',''));
            });

            this.allocateTotalAmount = _.sum(totalAmount);

            // this.remainingBalance = Number(this.balanceTotalAmount) - Number(this.allocateTotalAmount);
        },
        getAllocatedAmount(item) {
            let custPaymentDetail = this.customerPaymentDetails.find(x=>x.id == item.id);

            let filtered = _.compact(this.customerPaymentDetails).filter(x=>{
                return x.id != custPaymentDetail.id
            });

            let totalAllocated = filtered.map(x=>{
                return Number(x.allocate_amount) == 0 ? Number(x.allocate_amount) : Number(x.allocate_amount.replaceAll(',',''));
            });

            let allocateTotalAmount = _.sum(totalAllocated);

            let remainingBalance = Number(this.balanceTotalAmount) - allocateTotalAmount;

            let allocateAmount = Number(item.allocate_amount);
            let remainingAmount = item.remaining_amount;

            let allocateIsGreaterRemain = allocateAmount >= remainingAmount;
            let allocateIsGreaterRemainBal = allocateAmount >= remainingBalance;

            if(remainingBalance > 0) {
                if(allocateIsGreaterRemain) {
                    if(remainingBalance >= remainingAmount) {
                        custPaymentDetail.allocate_amount = this.getTwoDecimals(remainingAmount);
                    } else {
                        if(allocateAmount >= remainingBalance) {
                            custPaymentDetail.allocate_amount = this.getTwoDecimals(remainingBalance);
                        } else {
                            custPaymentDetail.allocate_amount = this.getTwoDecimals(item.allocate_amount);
                        }
                    }
                } else if(allocateIsGreaterRemainBal) {
                    custPaymentDetail.allocate_amount = this.getTwoDecimals(remainingBalance);
                }  else {
                    if(allocateAmount >= remainingBalance) {
                        custPaymentDetail.allocate_amount = this.getTwoDecimals(remainingBalance);
                    } else {
                        custPaymentDetail.allocate_amount = this.getTwoDecimals(item.allocate_amount);
                    }
                }
            } else {
                if(remainingBalance == 0) {
                    custPaymentDetail.allocate_amount = this.getTwoDecimals(Number(remainingBalance));
                } else {
                    custPaymentDetail.allocate_amount = '0';
                }
            }

            this.selectedCustTotalPayment();
        },
        getAllocatedWht(item) {
            let custPaymentDetail = this.customerPaymentDetails.find(x=>x.id == item.id);

            if(!!item) {
                if(item.allocate_wht > 0) {
                    let allocatedWht = item.allocate_wht.replaceAll(',','');
                    let whtAmount = item.wht_amount.replaceAll(',','');

                    if(Number(allocatedWht) >= Number(whtAmount)) {
                        custPaymentDetail.allocate_wht = this.getTwoDecimals(Number(whtAmount));
                    } else if(Number(allocatedWht) <= 0) {
                        custPaymentDetail.allocate_wht = this.getTwoDecimals(Number(0));
                    } else {
                        custPaymentDetail.allocate_wht = this.getTwoDecimals(Number(allocatedWht));
                    }
                }
            }
        },
        disableSubmit() {
            if(
                (Number(this.allocateTotalAmount) + Number(this.allocateWHTAmount) <= Number(this.soaTotalAmount) - Number(this.soaTotalPaidAmount)) && (this.customerPaymentDetails.length > 0) && ((Number(this.allocateTotalAmount) + Number(this.allocateWHTAmount)) > 0)
            ) {
                return false;
            }

            return true;
        },
        decimalOnly ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        thousandSeprator (amount) {
            if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        },
        getTwoDecimals (amount) {
            var formatted_amount = ''
            if(isNaN(amount)){
                if(amount != null && amount != ''){
                    formatted_amount = Number(amount.replace(/,/g, ''))
                    formatted_amount = Math.trunc(formatted_amount * Math.pow(10, 3)) / Math.pow(10, 3)
                }
            }else{
                formatted_amount = Math.trunc(amount * Math.pow(10, 3)) / Math.pow(10, 3)
            }

            if (formatted_amount == '') {
                return 0.00
            }
            return this.thousandSeprator(formatted_amount.toFixed(2))
        },
        submit() {
            let selectedCustPaymentDetails = this.customerPaymentDetails.filter(x=>{
                let num = x.allocate_amount + x.allocate_wht;
                return (!!x.checked || !!x.wht_checked) && Number(num.toString().replaceAll(',','')) > Number(0);
            })

            if(selectedCustPaymentDetails.length > 0) {
                swal.fire({
                    title: 'Are you sure you want to Add Customer Payment?',
                    text: 'Make sure that all information are correct',
                    icon: 'warning',
                    showConfirmButton:true,
                    showCancelButton  :  true,
                    reverseButtons:true,
                    allowOutsideClick :  false,
                    confirmButtonColor: '#397373',
                    cancelButtonColor : 'grey',
                    confirmButtonText:'Confirm',
                    showCloseButton:true,
                }).then((result)=>{
                    if(result.isConfirmed) {
                        let payload = {
                            soaId: this.soaId,
                            paymentDetails: selectedCustPaymentDetails
                        }

                        this.$store.dispatch('updateCustomerSoaPayments',payload).then(response=>{
                            if(response.data.error) {
                                swal.fire('', response.data.msg, 'error');
                            } else {
                                this.soaCloseCustDetailAddEditDialog();
                                this.selectedCustTotalPayment();
                                this.$store.commit('soaCustomerPaymentSuccessTrue');
                                swal.fire("", response.data.msg, "success").then(ok=>{
                                    if(ok) {
                                        this.$store.commit('soaCustomerPaymentSuccessFalse');
                                    }
                                });
                            }
                        });
                    }
                });
            } else {
                swal.fire('', 'Please Select Payments', 'warning');
            }
        }
    }
}
</script>

<style>

</style>
