<template>
  <v-card class="mt-4">
      <v-row>
          <v-col class="text-right mr-9 pr-9" xs="12" md="12">
              <a :class="`ml-1 badge badge-saved          ${chips.includes(1)? `selected` : `non-selected`}`"       @click="selectChip(1)">All</a>
              <a :class="`ml-1 badge badge-saved          ${chips.includes(2) ? `selected` : `non-selected`}`"      @click="selectChip(2)">active</a>
              <a :class="`ml-1 badge badge-for-exec       ${chips.includes(3) ? `selected` : `non-selected`}`"      @click="selectChip(3)">default</a>
              <a :class="`ml-1 badge badge-for-sales      ${chips.includes(4) ? `selected` : `non-selected`}`"      @click="selectChip(4)">virtual</a>
              <a :class="`ml-1 badge badge-for-accounting ${chips.includes(5) ? `selected` : `non-selected`}`"      @click="selectChip(5)">allow butal</a>
              <a :class="`ml-1 badge badge-exec-approved  ${chips.includes(6) ? `selected` : `non-selected`}`"      @click="selectChip(6)">actual</a>
          </v-col >
      </v-row>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" @change="getAllWarehouses()" @click:append="getAllWarehouses()" label="Search" single-line hide-details append-icon="mdi-magnify"> </v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="warehouses"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{
            showFirstLastPage: true,
        }"
        class="w-100"
    >
      <template v-slot:[`item.active`]="{ item }">
        <span class="badge bg-primary text-white" v-if="item.active">Yes</span>
        <span class="badge bg-danger text-white" v-else>No</span>
      </template>

      <template v-slot:[`item.default`]="{ item }">
        <span class="badge bg-primary text-white" v-if="item.default">Yes</span>
        <span class="badge bg-danger text-white" v-else>No</span>
      </template>

      <template v-slot:[`item.virtual`]="{ item }">
        <span class="badge bg-primary text-white" v-if="item.virtual">Yes</span>
        <span class="badge bg-danger text-white" v-else>No</span>
      </template>

      <template v-slot:[`item.allow_butal`]="{ item }">
        <span class="badge bg-primary text-white" v-if="item.allow_butal">Yes</span>
        <span class="badge bg-danger text-white" v-else>No</span>
      </template>
      <template v-slot:[`item.actual`]="{ item }">
        <span class="badge bg-primary text-white" v-if="item.actual">Yes</span>
        <span class="badge bg-danger text-white" v-else>No</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <!-- <buttons
          v-if="userAccess.view"
          :action="buttons.actions.view"
          :button_icon="buttons.icons.view"
          :color="buttons.colors.view"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        >
        </buttons>
        <buttons
          v-if="userAccess.edit"
          :action="buttons.actions.edit"
          :button_icon="buttons.icons.edit"
          :color="buttons.colors.edit"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="editdialogs(item)"
        >
        </buttons>
        <buttons
          v-if="userAccess.delete"
          :action="buttons.actions.delete"
          :button_icon="buttons.icons.delete"
          :color="buttons.colors.delete"
          :button_text="buttons.type.text"
          :icon="buttons.type.icon"
          @click="deleteWarehouse(item.id)"
        >
        </buttons> -->
        <v-btn text icon color="orange" v-if="userAccess.view" >
          <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
        </v-btn>
        <v-btn text icon color="blue" v-if="userAccess.edit" >
            <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn text icon color="red" v-if="userAccess.delete" >
            <v-icon class="btn-action" small @click="deleteWarehouse(item.id)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
// import buttons from '../../../layouts/Buttons/DialogButton.vue'
// import buttons_components from '../../../../../assets/js/components-js.js'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
export default {
  components: {
    // buttons,
    // buttons_components,
  },
  data() {
    return {
      search: '',
      // buttons: buttons_components.buttons,
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Code', align: 'left', value: 'code' },
        { text: 'Active', align: 'left', value: 'active' },
        { text: 'Default', align: 'left', value: 'default' },
        { text: 'Virtual', align: 'left', value: 'virtual' },
        { text: 'Allow Butal', align: 'left', value: 'allow_butal' },
        { text: 'Actual', align: 'left', value: 'actual' },
        { text: 'Actions', align: 'center', value: 'action', sortable: false },
      ],
      userAccess: {
        view: false,
        create: false,
        edit: false,
        delete: false,
      },
      options:{},
      warehouses: [],
      totalItems: null,
      loading: false,
    filters: {
      all: '',
      active: '',
      default: '',
      virtual: '',
      allowbutal: '',
      actual: '',
    },
    chips:[],
    }
  },
  mounted() {
    this.getAllWarehouses();
  },
  computed: {
    ...mapGetters(['USER_ACCESS', 'NEW_WAREHOUSE']),
  },
  methods: {
    viewdialogs(item) {
      this.$store.commit('SELECTED_WAREHOUSE', item);
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'View')
    },
    editdialogs(item) {
      this.$store.commit('SELECTED_WAREHOUSE', item);
      this.$store.commit('DIALOG', true);
      this.$store.commit('ACTION', 'Update')
    },
    deleteWarehouse(item) {
      swal
        .fire({
          title: '',
          text: 'Are you sure you want to Delete?',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#397373',
          cancelButtonColor: '#397373'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('deletewarehouse', item).then(success => {
              swal.fire({
                title: 'Success',
                icon: 'success',
                timer: 1500,
              });
            })
          }
        });
    },
    getAllWarehouses(){
        this.loading = true
        this.warehouses = []

        this.$store.dispatch('getWarehouse',{
          filters:      this.chips,
          page:         this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          search:       this.search,
        }).then((res)=>{
          this.warehouses = res.data
          this.totalItems = res.total
          this.loading     = false
        })
    },
    selectChip(chipId) {
    const index = this.chips.indexOf(chipId);
     if (index !== -1) {
      this.chips.splice(index, 1);
      this.chips.push(1);
      } else {
        this.chips = [];
        this.chips.push(chipId);
      }

      this.filters.active = this.chips.includes(2) ? 2: '';
      this.filters.default = this.chips.includes(3) ? 3 : '';
      this.filters.virtual = this.chips.includes(4) ? 4 : '';
      this.filters.allowbutal = this.chips.includes(5) ? 5 : '';
      this.filters.actual = this.chips.includes(6) ? 6 : '';
      this.filters.all = '';
      this.getAllWarehouses();
    },

  },
  watch: {
    NEW_WAREHOUSE: {
      handler(val) {
        if (val) {
          this.$store.commit('DIALOG', false);
          swal.fire({
            title: 'Success',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          });
          this.getAllWarehouses();
          this.$store.commit('NEW_WAREHOUSE', false);
        }
      },
    },
    USER_ACCESS: {
      handler(val) {
        if(val != "fail"){
           this.userAccess.edit = false;
           this.userAccess.delete = false;
           this.userAccess.view = false;
        val.forEach(e => {
          if (e.actions_code == 'edit') {
            this.userAccess.edit = true;
          } else if (e.actions_code == 'delete') {
            this.userAccess.delete = true;
          } else if (e.actions_code == 'view') {
            this.userAccess.view = true;
          }
        });
        }
      }
    },
    'options': {
      handler(val) {
          this.getAllWarehouses()
      }
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
</style>
