<template>
  <!--Modal + Add Above-->
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
        <span class="text-h5">Navigation Drawer Settings</span>
        <v-row class="m-0">
          <v-col cols="pull-right-10 p-2">
            <v-btn
              text
              icon
              small
              color="gray"
              class="float-right"
              @click="closeDialog()"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pt-5">
          <v-row>
            <v-col>
              <v-text-field
                label="Navigation Name"
                required
                v-model="parameters.navigation_name"
                outlined
                dense
                :rules="errors.navigation_name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Code"
                required
                v-model="parameters.navigation_code"
                outlined
                dense
                :rules="errors.navigation_code"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="pa-0">
                <v-card-title class="pr-0 pt-1 pr-1 pb-0 border-bottom">
                  <v-row class="m-0">
                    <v-col cols="pull-right-10">
                      <v-btn
                        fab
                        dark
                        small
                        color="primary"
                        style="height: 20px; width: 20px"
                        @click="addNavigationLink()"
                        class="float-right"
                      >
                        <v-icon dark small style="font-size: 12px"
                          >mdi-plus</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-simple-table height="300px">
                    <!--Internal Table ? why?-->
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Router Name</th>
                        <th>Hierarchy</th>
                        <th>Department</th>
                        <th>Display</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          navigation_items, i
                        ) in parameters.navigation_item"
                        :key="i"
                      >
                        <td>
                          <v-text-field
                            label="Navigation Link Name"
                            required
                            v-model="navigation_items.name"
                            outlined
                            dense
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            label="Route Name"
                            required
                            v-model="navigation_items.route"
                            outlined
                            dense
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            label="Hierarchy"
                            required
                            v-model="navigation_items.hierarchy"
                            outlined
                            dense
                          ></v-text-field>
                        </td>
                        <td>
                          <v-autocomplete
                          v-model="navigation_items.other_department_id"
                          :items="GET_DEPARTMENT_ROLE_LOAD"
                          :loading="loading.department_selection"
                          :disabled="loading.department_selection"
                          label="Departments"
                        >
                       </v-autocomplete>
                        </td>
                        <td>
                          <v-checkbox v-model="navigation_items.display"></v-checkbox>
                        </td>
                       
                        <td>
                          <v-btn
                            icon
                            dark
                            small
                            color="red"
                            @click="removeNavigationLink(i)"
                          >
                            <v-icon dark>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="parameters.navigation_department"
                :items="GET_DEPARTMENT_ROLE_LOAD"
                :loading="loading.department_selection"
                :disabled="loading.department_selection"
                @change="filter_department_for_table"
                label="Departments"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="parameters.navigation_icon"
                :items="GET_ICONS_LIST"
                :loading="loading.icon_selection"
                :disabled="loading.icon_selection"
                
                label="Icons"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-switch
                v-model="form.active"
                label="active"
                color="primary"
                input-value="1"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-title class="text-center border">
        <v-row class="ma-1">
          <v-col cols="12">
            <v-btn
              class="float-center"
              @click="
                actions == 'Submit' ? addNavigation() : UpdateNavigation()
              "
            >
              <span>{{ actions }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </v-form>
  <!--Modal + Add Above-->


</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  props: ["action"],
  data() {
    return {
      valid: true,
      form: {
        name: null,
        code: null,
        manager: [],
        show_hide:false,
        active: 1,
      },
      errors: {
        navigation_name: [(v) => !!v || "Name is Required"],
        navigation_code: [(v) => !!v || "Code is Required"],
      },
      item: [],
      parameters: {
        navigation_item: [],
        navigation_name: "",
        navigation_icon: "",
        navigation_code: "",
        navigation_department: "",
        navigation_display:false,
        actions: [],
      },
      trash: [],
      tag: "",
      tags: [],
      tmp_var: true,
      side_menu_bar_id: "",
      trash: {
        trash_1: [],
        trash_2: [],
      },

      loading: { 
                department_selection  : true,
                icon_selection        : true
      }
    };
  },
  computed: {
    ...mapGetters([
      "GET_ALL_MANAGERS",
      "DIALOGS",
      "SELECTED_DEPARTMENT",
      "GET_DEPARTMENT_ROLE_LOAD",
      "GET_ICONS_LIST",
      "ACTION",
      ,
      "GET_EDIT_NAVIGATION_DRAWER",
    ]),
    actions() {
      if (this.ACTION == "Submit") {
        this.parameters.navigation_item = [];
        return "Submit";
      } else {
        this.getEditNavigation();
        
        return "Update";
      }
    },
  },
  mounted() {
    this.$store.dispatch("getSelectionDepartmentRole");
    this.$store.dispatch("IconsList");
  },
  methods: {
    filter_department_for_table(){
       this.parameters.navigation_item.filter((e,i)=>{
        if( e.other_department_id == '')
        {
          e.other_department_id = this.parameters.navigation_department;
        } 
        else if (e.other_department_id === null)
        {
          e.other_department_id = this.parameters.navigation_department;
        }
      })
    },
    closeDialog() {
      this.tmp_var = false;
      this.$store.commit("EDIT_NAVIGATION_DRAWER", []);
      this.trash = {
        trash_1: [],
        trash_2: [],
      };
      this.$store.commit("DIALOG", false);
    },
    getAllNavigation(){
        this.$store.dispatch('NavigationDrawerList');
    },
    getEditNavigation(){
      this.side_menu_bar_id = this.GET_EDIT_NAVIGATION_DRAWER.id;
        this.parameters.navigation_name =
          this.GET_EDIT_NAVIGATION_DRAWER.name;
        this.parameters.navigation_code = this.GET_EDIT_NAVIGATION_DRAWER.code;
        this.parameters.navigation_department =
          this.GET_EDIT_NAVIGATION_DRAWER.department_id;
        this.parameters.navigation_icon = parseInt(
          this.GET_EDIT_NAVIGATION_DRAWER.icon_id
        );
        if (this.tmp_var) {
          this.parameters.navigation_item = [];
          this.GET_EDIT_NAVIGATION_DRAWER.children.forEach(
            (e, i) => {
              this.parameters.navigation_item[i] = {
                name: e.name,
                route: e.route_name,
                side_menu_link_id: e.id,
                hierarchy : e.hierarchy,
                display:    e.display,
                other_department_id:    e.other_department_id,
                actions: [
                  {
                    name: "",
                    code: "",
                    action_id: "",
                  },
                ],
              };
              e.actions.forEach((val, key) => {
                this.parameters.navigation_item[i].actions[key] = {
                  name: val.actions,
                  code: val.code,
                  action_id: val.id,
                };
              });
            }
          );
        }

        this.tmp_var = true;
    },
    addNavigationLink() {
      this.tmp_var = false;
      this.parameters.navigation_item.push({
        name: "",
        route: "",
        side_menu_link_id: "",
        hierarchy:"",
        display:true,
        other_department_id:"",
        actions: [
          {
            name: "",
            code: "",
            action_id: "",
          },
        ],
      });
    },
    addActions(key) {
      this.tmp_var = false;
      this.parameters.navigation_item[key].actions.push({
        name: "",
        code: "",
        action_id: "",
      });
    },
    removeNavigationLink(key) {
      this.tmp_var = false;
      this.trash.trash_1.push(
        this.parameters.navigation_item[key].side_menu_link_id
      );
      this.parameters.navigation_item.splice(key, 1);
    },
    removeAction(key, index) {
      this.tmp_var = false;
      this.trash.trash_2.push(
        this.parameters.navigation_item[key].actions[index].action_id
      );
      this.parameters.navigation_item[key].actions.splice(index, 1);
    },
    addNavigation() {
      const navigation_name = this.parameters.navigation_name
        ? ""
        : "Navigation Name";
      const navigation_code = this.parameters.navigation_code
        ? ""
        : "Navigation Code";
      const navigation_department = this.parameters.navigation_department
        ? ""
        : "Department";
      const navigation_icon = this.parameters.navigation_icon
        ? ""
        : "Navigation Icon";

      const array_text = [
        navigation_name,
        navigation_code,
        navigation_department,
        navigation_icon,
      ];

      const filtered = array_text.filter(function (el) {
        return el.replace(",", "");
      });

      if (
        this.parameters.navigation_name &&
        this.parameters.navigation_code &&
        this.parameters.navigation_department &&
        this.parameters.navigation_icon
      ) {
        if (this.parameters.navigation_item.length == 0) {
          Swal.fire({
            text: "Navigation link is required",
            icon: "warning",
            allowOutsideClick: false,
          });
        } else {
          this.$store.dispatch("addNavigationDrawer", this.parameters);
          this.getAllNavigation();
          Swal.fire({
            title:'Success',
            icon:'success',
            timer:1500
          })
          this.$store.commit('DIALOG',false);
        }
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    },
    UpdateNavigation() {
      const navigation_name = this.parameters.navigation_name
        ? ""
        : "Navigation Name";
      const navigation_code = this.parameters.navigation_code
        ? ""
        : "Navigation Code";
      const navigation_department = this.parameters.navigation_department
        ? ""
        : "Department";
      const navigation_icon = this.parameters.navigation_icon
        ? ""
        : "Navigation Icon";

      const array_text = [
        navigation_name,
        navigation_code,
        navigation_department,
        navigation_icon,
      ];

      const filtered = array_text.filter(function (el) {
        return el.replace(",", "");
      });

      if (
        this.parameters.navigation_name &&
        this.parameters.navigation_code &&
        this.parameters.navigation_department &&
        this.parameters.navigation_icon
      ) {
        if (this.parameters.navigation_item.length == 0) {
          Swal.fire({
            text: "Navigation link is required",
            icon: "warning",
            allowOutsideClick: false,
          });
        } else {
          this.$store.dispatch("updateNavigationDrawer", {
            trash: this.trash,
            side_menu_bar_id: this.side_menu_bar_id,
            params: this.parameters,
          });
          this.getAllNavigation();
        }
      } else {
        Swal.fire({
          text: `Fill Required Fields (${filtered})`,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    },
  },
  watch: {
    DIALOGS: {
      handler(val) {
        if (val) {
          this.tmp_var = true;
          this.getEditNavigation();
          if (this.parameters.navigation_item.length == 0) {
            this.addNavigationLink();
          }
        }
      },
    },
    'GET_DEPARTMENT_ROLE_LOAD':{
            handler(val){
                this.loading.department_selection = false
            },
    },
    'GET_ICONS_LIST':{
            handler(val){
                this.loading.icon_selection = false
            },
    }

  },
};
</script>
